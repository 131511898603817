import { Select } from 'antd';
import React, { useState } from 'react';
import { ConfirmationModalState } from '../../../../OpEditor';
import { FilterEditors, FilterListing } from '../FilterListing/FilterListing';
import { EventSource, FilterValuesAndSetter } from '../TriggerContents/TriggerContents';
import { Root } from './SourceEditorStyles';

interface SourceEditorProps extends FilterValuesAndSetter {
  sources: EventSource[] | null;
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
  error?: any;
  loading?: boolean;
}

function SourceEditor(props: SourceEditorProps) {
  const { sources, error, loading, values, setConfirmationModal, setFieldValue } = props,
    eventSourceId = 'eventSourceId',
    allKey = 'all',
    [selectedId, setSelectedId] = useState<string | null>(handleAllSources(values[eventSourceId]));

  function handleAllSources(id) {
    if (!id) {
      return allKey;
    }

    return id;
  }

  function determineInvalidFilters(updatedId): FilterListing[] {
    return FilterEditors.filter((filterType) => {
      if (updatedId === allKey) {
        return false;
      }

      if (filterType.sourceWhitelist && filterType.sourceWhitelist.indexOf(updatedId) === -1) {
        return values[filterType.valueKey] && values[filterType.valueKey]?.length > 0;
      }

      return false;
    });
  }

  function onChange(updatedId) {
    const invalidFilters = determineInvalidFilters(updatedId);

    if (invalidFilters.length) {
      setConfirmationModal({
        title: 'Do you wish to continue?',
        description: (
          <>
            This source selection will invalidate and clear the following filters:
            <ul>
              {invalidFilters.map((filterType) => (
                <li key={filterType.valueKey} data-testid="clear-warning">
                  {filterType.label}
                </li>
              ))}
            </ul>
          </>
        ),
        onConfirm: () => {
          acceptChanges(updatedId, invalidFilters);
          setConfirmationModal(null); // close modal
        }
      });
    } else {
      acceptChanges(updatedId);
    }
  }

  function acceptChanges(updatedId, invalidFilters?: FilterListing[]) {
    const newValue = updatedId === allKey ? undefined : updatedId;
    setSelectedId(updatedId);

    setFieldValue(eventSourceId, newValue);

    // clear invalid filters
    invalidFilters?.forEach((filterType) => {
      setFieldValue(filterType.valueKey, []);
    });
  }

  return (
    <Root>
      <div className="select-wrapper">
        {!loading && (
          <Select value={selectedId} onChange={onChange}>
            <Select.Option value={allKey}>All Sources</Select.Option>
            {error && (
              <Select.Option disabled key="error" title="Could not load sources">
                Could not load sources
              </Select.Option>
            )}
            {!loading &&
              sources?.map((eventSource) => {
                const { id, name } = eventSource;
                return (
                  <Select.Option key={id} title={name}>
                    {name}
                  </Select.Option>
                );
              })}
          </Select>
        )}
      </div>
    </Root>
  );
}

export default SourceEditor;

import styled from 'styled-components';

export const VendorLabelRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;

  .vendor-icon {
    margin-right: 0.25rem;
    transform: translateY(2px);
    width: 24px;
  }

  .trigger-name {
    word-break: break-all;
  }
`;

import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { SelectLabelsDiv } from './SelectLabels.styles';
import { Select } from 'antd';
import ErrorAlert from 'components/ui/ErrorAlert';
import DelayedLoader from 'designSystem/DelayedLoader/DelayedLoader';
import { CloudLabelUl } from 'designSystem/CloudLabel/CloudLabel';
import { Detector } from 'typings';

export const UNIQUE_ASSESSMENT_LABELS = gql`
  query UniqueAssessmentLabels {
    uniqueAssessmentLabels
  }
`;

interface SelectLabelsProps {
  detector: Detector;
  onLabelChange: Function;
  initialAssessor: any;
}

function caseInsensitiveSort(a: string, b: string) {
  return a.localeCompare(b, undefined, { sensitivity: 'accent' });
}

function SelectLabels(props: SelectLabelsProps) {
  const {
      initialAssessor,
      detector: { labels: currentSelection },
      onLabelChange
    } = props,
    systemLabels = initialAssessor?.labels || [],
    { data, loading, error, refetch } = useQuery(UNIQUE_ASSESSMENT_LABELS),
    [filteredCurrentSelection, setFilteredCurrentSelection] = useState<string[]>(),
    [children, setChildren] = useState<JSX.Element>();

  function filterOutSystemLabels(label) {
    return !systemLabels?.includes(label);
  }

  function setSelectOptions() {
    const childArray: JSX.Element[] = [];

    data.uniqueAssessmentLabels
      .filter(filterOutSystemLabels)
      .sort(caseInsensitiveSort)
      .forEach((label: string) => {
        childArray.push(<Select.Option key={label}>{label}</Select.Option>);
      });

    // @ts-ignore
    setChildren(childArray);
  }

  function onChange(update) {
    onLabelChange(update);
  }

  useEffect(() => {
    data && setSelectOptions();
  }, [data]);

  useEffect(() => {
    setFilteredCurrentSelection(currentSelection?.filter(filterOutSystemLabels));
  }, [currentSelection]);

  return (
    <SelectLabelsDiv>
      {loading && <DelayedLoader />}
      {error && <ErrorAlert message={'Could not load labels'} error={error} onClose={() => refetch()} />}
      <div className="system-label-wrap">
        <div className="sub-label">System Labels (read-only)</div>
        {systemLabels ? (
          <CloudLabelUl>
            {systemLabels.map((label) => (
              <li key={label}>{label}</li>
            ))}
          </CloudLabelUl>
        ) : (
          <>none</>
        )}
      </div>
      <div className="sub-label">User Labels</div>
      <div className="select-wrap">
        <Select
          value={filteredCurrentSelection}
          style={{ width: '100%' }}
          mode="tags"
          placeholder="Select labels or type to create"
          onChange={onChange}
        >
          {children}
        </Select>
      </div>
    </SelectLabelsDiv>
  );
}

export default SelectLabels;

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route, Location } from 'react-router-dom';
// import { EventDetailModal } from './modals';
import { IssueDetailModal } from './modals';

export interface ModalProps extends RouteComponentProps {
  previousLocation: Location;
}

// used to stop main router location from updating.
// there might be a more dry/succinct way to add these.
export const modalPaths = ['/assessor', '/issues/', '/issue/'];

function ModalsRouter(props: ModalProps) {
  const { previousLocation } = props;

  return (
    <Switch>
      <Route
        path="/issues/:id"
        render={(props) => <IssueDetailModal {...props} previousLocation={previousLocation} />}
      />
      <Route
        path="/issue/:id"
        render={(props) => <IssueDetailModal {...props} previousLocation={previousLocation} />}
      />
    </Switch>
  );
}

export default ModalsRouter;

import { Select } from 'antd';
import React from 'react';
import countriesAndStates from './CountriesAndStates.json';

interface CoutrySelectProps {
  name: string;
  value: any;
  onChange: (value: any) => void;
  onBlur: () => void;
}

function CountrySelect(props: CoutrySelectProps) {
  const { onChange, value, onBlur } = props;
  const { countries } = countriesAndStates as any;

  return (
    <Select
      value={value || 'default'}
      style={{ width: '100%' }}
      onBlur={onBlur}
      onChange={selected => {
        const selectedStr = selected ? selected.toString() : undefined;
        onChange(selectedStr === 'default' ? undefined : selectedStr);
      }}
    >
      <Select.Option value="default">Select Country</Select.Option>
      {countries.map((item, _index) => {
        return (
          <Select.Option key={`${item.code}`} value={item.name}>
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default CountrySelect;

import gql from 'graphql-tag';

export const RECIPIENT_FIELDS = gql`
  fragment RecipientFields on Recipient {
    id
    name
    type
    config
  }
`;

export default RECIPIENT_FIELDS;

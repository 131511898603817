import { Permissions, PERMISSION_LIST } from '@disruptops/neo-core/dist/permissions';
import React, { ReactNode, useContext, useMemo } from 'react';
import { ConfiguredOp, Issue, Maybe, Project } from 'typings';
import AuthContext from './AuthContext';

interface PermissionDefinition {
  id: string;
  name: string;
  key?: string; // not sure we should use this for anything...
}

// This is provided via trinity api
interface EffectivePermission {
  id: string;
  name: string;
  projects?: Maybe<string[]>;
  __typename?: Maybe<string>;
}

interface IsPermissionAuthorizedResult {
  permissionId: string;
  isAuthorized: boolean;
  requiredProjectIds: string[];
  failedProjectIds: string[];
  permissionDefinition: Maybe<PermissionDefinition>;
}

export type FailedPermissions = IsPermissionAuthorizedResult[] | null;

export interface IsAuthorizedResults {
  failedPermissions: FailedPermissions;
  results: IsPermissionAuthorizedResult[];
}

export interface AuthorizePermissionsResults extends IsAuthorizedResults {
  isAuthorized: boolean; // all requiredPermissions must be met.
}

interface DefaultProps {
  permission?: 'read' | 'write'; //defaults to 'write'
}

export interface RequiredPermission {
  permissionId: string;
  projectIds: string | string[]; // should be able to handle single or array. Should be able to accept "*" for all/root
}

interface Props extends DefaultProps {
  requiredPermissions?: RequiredPermission[] | null;
  entityId?: string;
  entity?: Issue | ConfiguredOp | Project | any; //??

  entityType?: 'issue' | 'configured_op' | 'project' | 'client'; //Might be able to be inferred from __typename
  children: (authorizationResults: AuthorizePermissionsResults) => ReactNode;
}

// react render component.
// there are still cases where we'll need this as react hooks can't be used in render functions and callbacks.
function Authorizor(props: Props) {
  const { requiredPermissions, children } = props;

  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: requiredPermissions || null
  });

  return <>{children(authz)}</>;
}

interface AuthorizorAuthorizePermissionsResults extends AuthorizePermissionsResults {
  message: null | string;
}

export function failedPermissionsToAuthErrorMessage(failedPermissions: FailedPermissions) {
  if (!failedPermissions) return null;

  const failedPermissionsString = failedPermissions
    .map((failedPermissions) => {
      return failedPermissions?.permissionDefinition?.name;
    })
    .filter((permissionName) => (permissionName ? true : false))
    .join(', ');

  return `Missing permissions: ${failedPermissionsString}`;
}

// A slightly simpler API for the below hook
// undefined projectId assumes all projects
export function useAuthorizor(
  permissionIds: Permissions | Permissions[],
  projectIds?: string[] | '*'
): AuthorizorAuthorizePermissionsResults {
  permissionIds = Array.isArray(permissionIds) ? permissionIds : [permissionIds];

  const { isAuthorized, failedPermissions, results } = useAuthorizeRequiredPermissions({
    requiredPermissions: permissionIds.map((permissionId) => ({
      permissionId: permissionId.toString(),
      projectIds: projectIds ? projectIds : []
    }))
  });

  const message = failedPermissionsToAuthErrorMessage(failedPermissions);

  return {
    isAuthorized,
    failedPermissions,
    results,
    message
  };
}

export function authorizePermissionsWithoutHooks(
  authContext: any,
  permissionIds: Permissions | Permissions[],
  projectIds?: string[] | '*'
): AuthorizePermissionsResults {
  permissionIds = Array.isArray(permissionIds) ? permissionIds : [permissionIds];

  const me = authContext?.me || null;
  const effectivePermissions: EffectivePermission[] | null = me?.effective_permissions || null;
  const requiredPermissions = permissionIds.map((permissionId) => ({
    permissionId: permissionId.toString(),
    projectIds: projectIds ? projectIds : []
  }));

  // console.log( { permissionIds, requiredPermissions });

  return authorizePermissions(requiredPermissions, effectivePermissions);
}

// custom react hook
// custom react hooks must start with "use"
export function useAuthorizeRequiredPermissions(params: {
  requiredPermissions: RequiredPermission[] | null;
}): AuthorizePermissionsResults {
  const { requiredPermissions } = params;

  const authContext = useContext(AuthContext) || null;
  const me = authContext?.me || null;
  const effectivePermissions: EffectivePermission[] | null = me?.effective_permissions || null;

  const authorizedResults = useMemo(() => authorizePermissions(requiredPermissions || null, effectivePermissions), [
    requiredPermissions && JSON.stringify(requiredPermissions), // passing the entire objects in seemed to re run function even though they didn't change.
    effectivePermissions && JSON.stringify(effectivePermissions)
  ]);

  return authorizedResults;
}

export function authorizePermissions(
  requiredPermissions: RequiredPermission[] | null,
  effectivePermissions: EffectivePermission[] | null
): AuthorizePermissionsResults {
  if (!requiredPermissions)
    return {
      isAuthorized: true,
      failedPermissions: null,
      results: [] // did not check any permissions.
    };

  const isAuthorizedResults: IsAuthorizedResults = requiredPermissions.reduce(
    (authResultsAcc: IsAuthorizedResults, requiredPermission) => {
      // find matching effective permission from /user request.
      const effectivePermission = effectivePermissions
        ? effectivePermissions.find((item) => item.id === requiredPermission.permissionId)
        : null;

      // props accept single ID as well as array. This makes sure we're dealing with an array moving forward.
      const requiredProjectIds = Array.isArray(requiredPermission.projectIds)
        ? requiredPermission.projectIds
        : [requiredPermission.projectIds];

      // make sure effective permission includes each required project ID
      const failedProjects = checkEffectivePermissionAgainstRequiredProjectIds(requiredProjectIds, effectivePermission);

      // find permission definition
      const permissionDefinition = PERMISSION_LIST.find((p) => p.id === requiredPermission.permissionId) || null;

      // return each result to give developer more context if something fails.
      const result: IsPermissionAuthorizedResult = {
        isAuthorized: Boolean(effectivePermission && failedProjects.length === 0),
        failedProjectIds: failedProjects,
        permissionId: requiredPermission.permissionId,
        permissionDefinition,
        requiredProjectIds
      };

      if (!effectivePermission || failedProjects.length > 0) {
        // find permission definition that has name.
        return {
          ...authResultsAcc,
          isAuthorized: false,
          failedPermissions: authResultsAcc.failedPermissions
            ? [...authResultsAcc.failedPermissions, result]
            : [result],
          results: [...authResultsAcc.results, result]
        };
      }

      return { ...authResultsAcc, results: [...authResultsAcc.results, result] };
    },
    {
      failedPermissions: null,
      results: []
    }
  );

  return {
    isAuthorized: !Boolean(isAuthorizedResults.failedPermissions && isAuthorizedResults.failedPermissions.length > 0),
    ...isAuthorizedResults
  };
}

// should return array of failed project Ids.
export function checkEffectivePermissionAgainstRequiredProjectIds(
  requiredProjectIds: string[],
  effectivePermission?: EffectivePermission | null
): string[] {
  if (!effectivePermission) return requiredProjectIds;

  const failedProjectIds = requiredProjectIds.reduce((acc: string[], requiredProjectId) => {
    if (!effectivePermission.projects) return [...acc, requiredProjectId];

    if (effectivePermission.projects.includes('*')) return acc; // user has global permission

    // projectId is not in effective permission
    if (!effectivePermission.projects.includes(requiredProjectId)) return [...acc, requiredProjectId];

    return acc;
  }, []);

  return failedProjectIds;
}

export default Authorizor;

import Badge from 'designSystem/Badge/Badge';
import { BadgeGroupDiv } from 'designSystem/Badge/Badge.styles';
import React from 'react';

function IssuesSummary({ summary }) {
  const { openIssueCount, resolvedIssueCount, exemptedIssueCount } = summary || {};
  return (
    <BadgeGroupDiv className="badge-group">
      {!!openIssueCount && (
        <div className="badge-group__item">
          <Badge modifier="warning" count={openIssueCount} />
          <div className="badge-group__item-label">open</div>
        </div>
      )}
      {!!exemptedIssueCount && (
        <div className="badge-group__item">
          <Badge count={exemptedIssueCount} />
          <div className="badge-group__item-label">exempted</div>
        </div>
      )}
      {!!resolvedIssueCount && (
        <div className="badge-group__item">
          <Badge modifier="success" count={resolvedIssueCount} />
          <div className="badge-group__item-label">resolved</div>
        </div>
      )}
    </BadgeGroupDiv>
  );
}

export default IssuesSummary;

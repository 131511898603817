import React from 'react';
import Paper from 'components/ui/Paper';
import styled from 'styled-components';
import JSONTree from 'components/ui/JSONTree';
import { Button } from 'antd';
const ButtonGroup = Button.Group;
const JSONContainer = styled.div`
  padding: 16px;
  font-size: 14px;
  background: #eee;
`;

const Root = styled.div``;
const Header = styled.div`
  display: flex;
  padding: 16px 16px 0;
`;
const Content = styled.div`
  padding: 16px;
`;
const Label = styled.h3`
  font-weight: 600;
  flex: 1;
`;

export interface Props {
  dopsRawJSON: string | undefined;
  detailTable?: React.ReactNode | null;
}

enum viewOptions {
  JSON,
  DETAIL_TABLE
}

interface State {
  activeView: viewOptions;
}

class ResourceDetailsCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeView: props.detailTable ? viewOptions.DETAIL_TABLE : viewOptions.JSON
    };
  }

  handleViewClick(selected: viewOptions) {
    return () => {
      this.setState({
        activeView: selected
      });
    };
  }

  render() {
    const { activeView } = this.state;
    const { dopsRawJSON, detailTable } = this.props;
    if (!dopsRawJSON) return null;

    return (
      <Root>
        <Paper>
          <Header>
            <Label>Resource details</Label>
            {detailTable && (
              <ButtonGroup>
                <Button
                  onClick={this.handleViewClick(viewOptions.DETAIL_TABLE)}
                  disabled={activeView === viewOptions.DETAIL_TABLE}
                >
                  Table
                </Button>
                <Button onClick={this.handleViewClick(viewOptions.JSON)} disabled={activeView === viewOptions.JSON}>
                  JSON
                </Button>
              </ButtonGroup>
            )}
          </Header>
          <Content>
            {activeView === viewOptions.JSON && (
              <JSONContainer>
                <JSONTree data={JSON.parse(dopsRawJSON)} depth={1} />
              </JSONContainer>
            )}

            {activeView === viewOptions.DETAIL_TABLE && detailTable}
          </Content>
        </Paper>
      </Root>
    );
  }
}

export default ResourceDetailsCard;

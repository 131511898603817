import gql from 'graphql-tag';
import ASSESSMENT_GUARDRAILS_FRAGMENT from './guardrailsFragment';

const ASSESSMENT_DETAIL_QUERY = gql`
  query AssessmentDetail($id: [String]!) {
    assessments(id: $id) {
      nodes {
        id
        labels
        name
        lastRanAt
        projectId
        targetProjectIds
        isEnabled
        isReadOnly
        isActive @client
        staticConfiguration
        schedule
        dynamicConfiguration {
          id
          key
          node {
            id
            entityType
            entityId
            key
            value
          }
        }
        createdAt
        updatedAt
        ...AssessmentGuardrail
        assessor {
          id
          name
          cloudVendor
          overview
          labels
          issueDefinitions {
            id
            name
            description
            severity
            key
            actors {
              id
              name
              key
              description
              parameters {
                name
                key
                type
                inputCode
                configCode
                defaultValue
                description
                placeholder
                many
                options
                required
              }
            }
          }
          parameters {
            type
            name
            key
            name
            inputCode
            configCode
            defaultValue
            defaultValue
            description
            placeholder
            many
            options
            required
          }
          eventTriggers {
            criteria {
              eventType
            }
          }
        }
      }
    }
  }

  ${ASSESSMENT_GUARDRAILS_FRAGMENT}
`;

export default ASSESSMENT_DETAIL_QUERY;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import * as Yup from 'yup';
import { Input, Button, Alert as AntAlert } from 'antd';
import gql from 'graphql-tag';
import { Mutation, MutationFunction } from 'react-apollo';

import { Form, FormField } from 'components/ui/Form';

import OnboardingPanel from '../../components/OnboardingPanel';
import { FormikActions } from 'formik';

const RESET_PASSWORD = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) @rest(method: "POST", path: "/auth/forgot-password-confirmation")
  }
`;

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required('Required'),
  code: Yup.string().required('Required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required')
});

interface FormFields {
  email: string;
  code: string;
  password: string;
  confirmPassword: string;
}

interface Props extends RouteComponentProps {}
interface State {
  successEmail: string | null;
  error: any;
}

const FormContent = styled.div`
  width: 100%;
`;

const FormActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .submit-btn {
    margin-bottom: 16px;
  }
`;

const Alert = styled(AntAlert)`
  .login-link {
    display: inline-block;
    margin-left: 8px;
  }
`;

class ResetPasswordPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { successEmail: null, error: null };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(resetPassword: MutationFunction) {
    return async (values: Partial<FormFields>, actions: FormikActions<any>) => {
      try {
        this.setState({ error: null });

        const { email, code, password } = values as FormFields;
        const input = {
          username: email,
          code,
          password
        };
        await resetPassword({ variables: { input } });

        actions.resetForm();

        this.setState({ successEmail: email });
      } catch (e) {
        this.setState({ error: e }, () => {
          actions.setSubmitting(false);
        });
      }
    };
  }

  render() {
    const { successEmail, error } = this.state;
    const initEmail = (this.props.location.state && this.props.location.state.email) || '';

    return (
      <OnboardingPanel withLogo title="Reset password">
        {!successEmail ? (
          <Mutation mutation={RESET_PASSWORD}>
            {resetPassword => (
              <Form
                initialValues={{
                  email: initEmail,
                  code: '',
                  password: '',
                  confirmPassword: ''
                }}
                validationSchema={ValidationSchema}
                onSubmit={this.handleSubmit(resetPassword)}
              >
                {formikBag => {
                  const { canSubmit, isSubmitting } = formikBag;
                  return (
                    <FormContent>
                      {error && (
                        <Alert
                          type="error"
                          showIcon
                          message="There was a problem updating your password. Please double check the email address and confirmation code submitted."
                        />
                      )}

                      <FormField name="email" label="Email">
                        {({ name, value, handleChange, handleBlur }) => (
                          <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                        )}
                      </FormField>
                      <FormField name="code" label="Confirmation code">
                        {({ name, value, handleChange, handleBlur }) => (
                          <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                        )}
                      </FormField>
                      <FormField name="password" label="New password">
                        {({ name, value, handleChange, handleBlur }) => (
                          <Input
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type="password"
                          />
                        )}
                      </FormField>
                      <FormField name="confirmPassword" label="Confirm password">
                        {({ name, value, handleChange, handleBlur }) => (
                          <Input
                            name={name}
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            type={'password'}
                          />
                        )}
                      </FormField>
                      <FormActions>
                        <Button
                          className="submit-btn"
                          type="primary"
                          htmlType="submit"
                          disabled={!canSubmit || isSubmitting}
                        >
                          Submit
                        </Button>
                        <Link to="/login">{'Back to login'}</Link>
                      </FormActions>
                    </FormContent>
                  );
                }}
              </Form>
            )}
          </Mutation>
        ) : (
          <Alert
            type="success"
            className="success-alert"
            showIcon
            message={
              <div>
                <p>
                  {'Your password has successfully been updated.'}
                  <Link
                    className="login-link"
                    to={{
                      pathname: '/login',
                      state: {
                        email: successEmail
                      }
                    }}
                  >
                    Login
                  </Link>
                </p>
              </div>
            }
          />
        )}
      </OnboardingPanel>
    );
  }
}

export default ResetPasswordPage;

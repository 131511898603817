import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { List } from 'antd';
import styled from 'styled-components';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

import { UserGroup, UserGroupRole } from 'typings';

import AuthContext from 'components/app/Auth/AuthContext';
import ButtonWithDisabledTooltip from 'components/buttons/ButtonWithDisabledTooltip';
import { useAuthorizor } from 'components/app/Auth/Authorizor';

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  userGroup: UserGroup;
}

function UserGroupRoleList(props: Props) {
  const { userGroup } = props;

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_USER_GROUPS);

  return (
    <List
      dataSource={userGroup.roles || []}
      rowKey="id"
      header={
        <ListHeader>
          <h3>Roles</h3>

          <ButtonWithDisabledTooltip
            onClick={() => history.push(`/organization-settings/group-management/${userGroup.id}/edit`)}
            size="small"
            disabled={!isAuthorized}
            disabledMessage={authErrorMessage || ''}
            tooltipPlacement="left"
          >
            Edit
          </ButtonWithDisabledTooltip>
        </ListHeader>
      }
      renderItem={(userGroupRole: UserGroupRole) => {
        const project = userGroupRole.project_id ? authContext?.lookupProject(userGroupRole.project_id) : undefined;

        return (
          <List.Item key={userGroupRole.id}>
            <List.Item.Meta
              title={userGroupRole.name}
              description={project ? project.name : 'Organization (No scope)'}
            />
          </List.Item>
        );
      }}
    />
  );
}

export default UserGroupRoleList;

import React from 'react';
import { Alert } from 'antd';
import { FailedPermissions, failedPermissionsToAuthErrorMessage } from './Authorizor';

interface Props {
  failedPermissions: FailedPermissions;
}

function AuthorizationErrorAlert(props: Props) {
  const { failedPermissions } = props;

  return (
    <Alert
      type="error"
      showIcon
      message="Permission Error"
      description={failedPermissionsToAuthErrorMessage(failedPermissions)}
    ></Alert>
  );
}

export default AuthorizationErrorAlert;

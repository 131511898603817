import React, { useState } from 'react';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import { Select } from 'antd';

const QUERY = gql`
  query IssueDefinitionsForSelect {
    issueDefinitions {
      nodes {
        id
        name
      }
    }
  }
`;

interface IssueDefinitionFromQuery {
  id: string;
  name: string;
}

interface Props {
  value?: string | null;
  onChange: (selectedId: string | null) => void;
  onBlur: () => null | void | any;
}

function IssueDefinitionSelect(props: Props) {
  const [searchStr, setSearchStr] = useState<string | null>(null);
  const { value, onChange, onBlur } = props;

  return (
    <Query query={QUERY}>
      {({ loading, error, data }) => (
        <QueryResult loading={loading} error={error} data={data} loadingHeight={50} loadingCenterVertically={false}>
          {() => {
            const issueDefinitions: IssueDefinitionFromQuery[] = data.issueDefinitions.nodes;
            const filteredIssueDef: IssueDefinitionFromQuery[] = searchStr
              ? issueDefinitions.filter(item => {
                  return Boolean(item.name.toLowerCase().indexOf(searchStr.toLowerCase()) >= 0);
                })
              : issueDefinitions;

            return (
              <Select
                value={value || 'NONE_SELECTED'}
                showSearch
                onSearch={val => setSearchStr(val)}
                filterOption={false}
                onChange={val => onChange(val !== 'NONE_SELECTED' ? val : null)}
                onBlur={onBlur}
              >
                <Select.Option value="NONE_SELECTED">{'Select Issue Type'}</Select.Option>
                {filteredIssueDef.map((item, idx) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

export default IssueDefinitionSelect;

import { Checkbox, message, Popover, Switch } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import Paginator from 'components/app/Paginator';
import ArchiveOpModal from 'components/ops/ArchiveOpModal';
import DeleteOpModal from 'components/ops/DeleteOpModal';
import { RecipientIcon, RecipientLabels, RecipientTypes } from 'components/recipients/common';
import DopeIcon from 'components/ui/DopeIcon';
import IconButton from 'components/ui/IconButton';
import { CenteredContainer } from 'components/ui/NeoPage';
import { PopoverMenuItem } from 'components/ui/Popover';
import Table from 'components/ui/Table';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import { OP_FIELDS } from 'queries/fragments/opFields';
import React, { useState } from 'react';
import { Mutation, Query, useMutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AutomationEventDefinition, CloudAccount, Function, Maybe, Op, PageInfo, Project, Recipient } from 'typings';
import { SAVE_OP_MUTATION, validateRawOp } from '../OpDetail/OpEditor';
import OP_DECISION_INTEGRATION_DEFINITIONS from '../OpDetail/sections/OpDecisionSection/definitions';
import OpItemFilters from './OpItemFilters';

const OP_LIST = gql`
  query OpList($pageNumber: Int, $pageSize: Int, $sortBy: String, $sortDirection: SortDirection, $isArchived: Boolean) {
    ops(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      isArchived: $isArchived
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        ...OpFields
      }
    }

    projects {
      items {
        name
        project_id
      }
    }

    accounts {
      count
      nextToken
      items {
        account_id
        assumerole_account_id
        name
        nickname
        provider
      }
    }

    eventSources {
      nodes {
        id
        key
        name
        cloudVendor
      }
    }

    recipients {
      items {
        id
        type
        config
      }
    }
  }
  ${OP_FIELDS}
`;

const SHOW_ARCHIVED_OPS = 'showArchivedOps';
const FILTERS = [
  {
    clientFiltered: false, // ??
    qsKey: SHOW_ARCHIVED_OPS,
    name: SHOW_ARCHIVED_OPS, // not sure how this is different than qs key.
    label: 'Show Archived Guardrail Activity', // this shouldn't show up?
    many: false
  }
];

const SORT_BY_MAP = {
  name: 'name',
  isEnabled: 'is_enabled'
};

const SORT_ORDER_MAP = {
  ascend: 'ASC',
  descend: 'DESC'
};

const Root = styled.div`
  .filter-tag {
    padding: 2px 7px;
    margin-right: 4px;

    .filter-tag-content {
      display: flex;

      > * {
        flex-grow: 0;
      }

      > svg {
        display: inline-block;
        margin-right: 2px;
        margin-top: 2px;
      }
    }
  }
  .filter-options {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
  }
  .icon {
    display: inline-block;
    margin: 0px 4px 0px 0px;
    opacity: 0.5;
    position: relative;
    top: 2px;
  }
  .vendor-icon {
    margin: 3px 6px 0px 0px;
    // opacity: 0.5;
    position: relative;
  }
  .vendor-icon.aws {
    margin-top: -2px;
    margin-right: 3px;
    margin-left: -3px;
  }
  .long-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .break-word {
    word-wrap: break-word;
    display: block;
  }
  .no-break {
    white-space: nowrap;
  }
  .trigger-column {
    display: flex;
    flex-wrap: nowrap;
  }
`;

interface Props extends RouteComponentProps {}

function renderVendorIcon(vendor: Maybe<string>) {
  if (vendor && vendor === 'AWS') return <DopeIcon name="AWS" className="vendor-icon aws" size={24} />;
  else if (vendor && vendor === 'Azure') return <DopeIcon name="AZURE" className="vendor-icon" size={24} />;
  else if (vendor && vendor === 'DisruptOps')
    return <DopeIcon name="DISRUPT_OPS_FAVICON_BLACK" className="vendor-icon" size={16} />;
  return null;
}

function renderDecision(decisionType: string, item: Op) {
  const { decisionIntegration } = item;

  const decisionIntegrationDefinition = decisionIntegration
    ? OP_DECISION_INTEGRATION_DEFINITIONS.find((def) => def.key === decisionIntegration) || null
    : null;

  const popoverContent = (
    <>
      {!decisionIntegrationDefinition && (
        <>
          Op will automatically run configured Action(s) if an
          <br />
          event matching the criteria of the Trigger is discovered.
        </>
      )}
      {decisionIntegrationDefinition && (
        <div>
          <span>{decisionIntegrationDefinition.title}</span>
        </div>
      )}
      {decisionIntegrationDefinition && decisionType === 'WAIT_FOR_USER_RESPONSE' && (
        <>
          Wait for user response.
          <br />
          Op will NOT proceed and take action without user response via {decisionIntegrationDefinition.name} message.
        </>
      )}
    </>
  );

  return (
    <Popover title="Decision" content={popoverContent} placement="left">
      {decisionIntegrationDefinition && (
        <div className="break-word">
          <span className="no-break">
            {decisionIntegrationDefinition.icon}
            {decisionIntegrationDefinition.name} Decision
          </span>
        </div>
      )}
      {!decisionIntegration && <>Auto</>}
    </Popover>
  );
}

function renderTrigger(eventDefinition: AutomationEventDefinition | null) {
  const { name, vendor = null, vendorProduct } = eventDefinition || {};
  const content = (
    <div className="break-word">
      <span className="no-break">
        {vendor && (
          <p>
            <b>Vendor:</b> {vendor}
          </p>
        )}
        {vendorProduct && (
          <p>
            <b>Product:</b> {vendorProduct}
          </p>
        )}
        {name && (
          <p>
            <b>Event:</b> {name}
          </p>
        )}
        {!name && <p>All Findings</p>}
      </span>
    </div>
  );
  return (
    <Popover title="Trigger" content={content} placement="left">
      <div className="trigger-column">
        <div>{renderVendorIcon(vendor)}</div>
        <div className="break-word">{name || 'All Findings'}</div>
      </div>
    </Popover>
  );
}

function renderAction(func: Function | null, op: Op, recipients: Recipient[]) {
  if (!func) return 'No Action selected';
  const { name: functionName, effects } = func;
  const isAlert = functionName === 'DisruptOps Alert';
  const actionName = isAlert ? 'Alert' : functionName;
  const title = `Action: ${actionName}`;

  const { configuration } = op;
  const alertRecipientSource = configuration?.find((c) => c.key === 'alertRecipientSource')?.value;
  const alertRecipientId = configuration?.find((c) => c.key === 'alertRecipientId')?.value;
  const recipient = recipients?.find((r) => r.id === alertRecipientId);

  const recipientIcon = <RecipientIcon recipient={recipient} size={12} />;
  // Project Default, channel name, etc
  const recipientLabel =
    alertRecipientSource === 'PROJECT'
      ? 'Project Default'
      : recipient?.type && RecipientLabels[recipient.type]?.(recipient);
  const recipientType = recipient?.type && RecipientTypes[recipient.type];

  const content = (
    <>
      <p>{!isAlert && effects && `Effects: ${effects.join(', ')}`}</p>
      <p>{isAlert && `Alert Recipient: ${recipientType ?? ''} ${recipientLabel}`}</p>
    </>
  );

  return (
    <>
      {op.decisionType && renderDecision(op.decisionType, op)}
      <Popover title={title} content={content} placement="left" trigger="hover">
        <div className="no-break">
          <DopeIcon className={'icon'} name="FUNCTION" /> {actionName}
        </div>
        {isAlert && (
          <div className="no-break">
            {recipientIcon}
            {recipientLabel}
          </div>
        )}
      </Popover>
    </>
  );
}

function OpList(props: Props) {
  const [saveOp, saveOpResults] = useMutation(SAVE_OP_MUTATION);
  const [opToArchive, setOpToArchive] = useState<Op | null>(null);
  const [opToDelete, setOpToDelete] = useState<Op | null>(null);

  return (
    <Root>
      <Paginator pageSize={100} filters={FILTERS}>
        {(pageRenderProps) => {
          const {
            pageNumber,
            pageSize,
            sortBy,
            sortDirection,
            updateSearchParams,
            updateFilter,
            filterValues
          } = pageRenderProps;
          const showArchivedOps = filterValues[SHOW_ARCHIVED_OPS] === 'true';
          const queryVariables = {
            pageNumber,
            pageSize,
            sortBy,
            sortDirection,
            isArchived: showArchivedOps ? undefined : false
          };

          return (
            <CenteredContainer>
              <div className="filter-options">
                <Checkbox
                  children="Hide archived Ops"
                  checked={!showArchivedOps}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    // console.log({ checked });
                    updateFilter(SHOW_ARCHIVED_OPS, checked ? 'false' : 'true');
                  }}
                />
              </div>
              {/* SEARCH IS NOT SUPPORTED YET */}
              {/* <FilterRow>
                    <FilterItem label="Search">
                      <SearchFilter search={search} onSearchChange={updateSearch} />
                    </FilterItem>
                  </FilterRow> */}
              <Query query={OP_LIST} variables={queryVariables} fetchPolicy="cache-and-network">
                {({ loading, data, error, refetch }) => {
                  return (
                    <>
                      <QueryResult loading={loading} data={data} error={error}>
                        {() => {
                          const ops: Op[] = data.ops.nodes;
                          const pageInfo: PageInfo = data.ops.pageInfo;
                          const projects: Project[] = data.projects.items;
                          const accounts: CloudAccount[] = data.accounts.items;
                          const eventSources: any[] = data.eventSources.nodes;
                          const recipients: Recipient[] = data.recipients.items;

                          const columns: ColumnProps<Op>[] = [
                            {
                              title: 'Enabled',
                              key: 'isEnabled',
                              dataIndex: 'isEnabled',
                              sorter: true, // refer to "SORTER_MAP"
                              render: (isEnabled, op) => {
                                return (
                                  <Switch
                                    checked={isEnabled}
                                    loading={saveOpResults.loading}
                                    disabled={saveOpResults.loading}
                                    onChange={async (checked) => {
                                      if (!checked) {
                                        // if !checked disable
                                        const { id, name, projectId } = op;
                                        const variables = {
                                          id,
                                          opInput: {
                                            name, // required by resolver...
                                            projectId,
                                            isEnabled: false
                                          }
                                        };

                                        try {
                                          await saveOp({ variables });

                                          message.success('Op was disabled.');
                                        } catch (err) {
                                          message.error('There was a problem disabling this Op.');
                                        }
                                      } else {
                                        // ensure user has permissions

                                        // ensure that op is complete
                                        const opToSave = await validateRawOp(op, true);
                                        if (opToSave) {
                                          const variables = {
                                            id: op.id,
                                            opInput: {
                                              ...opToSave,
                                              isEnabled: true
                                            }
                                          };

                                          try {
                                            await saveOp({ variables });

                                            message.success('Op was enabled');
                                          } catch (e) {
                                            message.error('There was a problem enabling this Op.');
                                          }
                                        } else {
                                          message.warning('Op must be completed before enabling.');
                                        }
                                      }
                                    }}
                                  />
                                );
                              }
                            },
                            {
                              title: 'Name',
                              key: 'name',
                              dataIndex: 'name',
                              sorter: true,
                              render: (name, item) => {
                                const { archivedAt } = item;
                                const nameOrDefault = name || 'Untitled Op';

                                return (
                                  <Popover title="Name" content={name} placement="right">
                                    <Link to={`/ops1/details/${item.id}`}>
                                      {archivedAt && <DopeIcon className={'icon'} name="CANCEL" />}
                                      {nameOrDefault}
                                    </Link>
                                  </Popover>
                                );
                              }
                            },
                            {
                              title: 'Scope/Ownership',
                              key: 'projectId',
                              dataIndex: 'projectId',
                              render: (projectId, item) => {
                                const project: Project | undefined = projects.find((p) => p.project_id === projectId);
                                const { name } = project || {};
                                const title = `${project ? 'Project: ' : ''}${name ? name : ''}`;
                                return (
                                  <Popover title="Scope/Ownership" content={title} placement="left">
                                    <div className="break-word">
                                      <DopeIcon className={'icon'} name="PROJECT" />
                                      {project ? project.name : projectId}
                                    </div>
                                  </Popover>
                                );
                              }
                            },
                            {
                              title: 'Trigger',
                              key: 'trigger',
                              dataIndex: 'eventDefinition',
                              render: (eventDefinition: AutomationEventDefinition | null) => {
                                return renderTrigger(eventDefinition);
                              }
                            },
                            {
                              title: 'Filters',
                              key: 'filters',
                              dataIndex: 'filters',
                              render: (filters, item) => (
                                <OpItemFilters
                                  item={item}
                                  projects={projects}
                                  accounts={accounts}
                                  eventSources={eventSources}
                                />
                              )
                            },
                            {
                              title: 'Action',
                              key: 'action',
                              dataIndex: 'function',
                              render: (func: Function, item) => {
                                return renderAction(func, item, recipients);
                              }
                            },
                            {
                              key: 'options',
                              render: (_val, item) => {
                                return (
                                  <Popover
                                    placement="rightBottom"
                                    content={
                                      <div className="menu">
                                        <PopoverMenuItem label={'Edit'} link={`/ops1/details/${item.id}`} />
                                        {item.archivedAt ? (
                                          <Mutation
                                            mutation={gql`
                                              mutation UnarchiveOpFromOpList($id: String) {
                                                unarchiveOp(id: $id) {
                                                  id
                                                  archivedAt
                                                }
                                              }
                                            `}
                                          >
                                            {(unarchiveOp, { loading: unarchiving }) => {
                                              return (
                                                <PopoverMenuItem
                                                  label={unarchiving ? 'Unarchiving' : 'Unarchive'}
                                                  disabled={unarchiving}
                                                  onClick={async () => {
                                                    try {
                                                      await unarchiveOp({
                                                        variables: { id: item.id }
                                                      });

                                                      message.success('Op has been unarchived.');
                                                    } catch (e) {
                                                      message.error('There was an error unarchiving this Op.');
                                                    }
                                                  }}
                                                />
                                              );
                                            }}
                                          </Mutation>
                                        ) : (
                                          <PopoverMenuItem
                                            onClick={() => {
                                              setOpToArchive(item);
                                            }}
                                            disabled={loading}
                                            label={!loading ? 'Archive' : 'Archiving'}
                                          />
                                        )}
                                        {item.archivedAt && (
                                          <PopoverMenuItem
                                            onClick={() => setOpToDelete(item)}
                                            disabled={loading}
                                            label={'Delete'}
                                          />
                                        )}
                                      </div>
                                    }
                                    trigger="click"
                                  >
                                    <IconButton type="ghost" size="small">
                                      <DopeIcon name="MORE_VERTICAL" />
                                    </IconButton>
                                  </Popover>
                                );
                              }
                            }
                          ].filter((c) => c.key);

                          return (
                            <Table
                              antTableProps={{
                                rowKey: 'id',
                                columns,
                                loading: loading,
                                dataSource: ops,
                                locale: {
                                  emptyText: 'No matching Assessments'
                                },
                                onChange: (pagination, filters, sorter, a) => {
                                  updateSearchParams({
                                    sortBy: SORT_BY_MAP[sorter.columnKey] || 'updated_at',
                                    sortDirection: SORT_ORDER_MAP[sorter.order] || SORT_ORDER_MAP.descend,
                                    page: pagination.current || 1
                                  });
                                },
                                pagination: {
                                  pageSize,
                                  current: pageInfo && pageInfo.current,
                                  total: pageInfo && pageInfo.total,
                                  onChange: (pageNumber) => {
                                    updateSearchParams({
                                      page: pageNumber
                                    });
                                  }
                                }
                              }}
                            />
                          );
                        }}
                      </QueryResult>
                      <ArchiveOpModal
                        opToArchive={opToArchive}
                        onCancel={() => setOpToArchive(null)}
                        onComplete={() => {
                          refetch();
                          setOpToArchive(null);
                        }}
                        entityName="Op"
                      />
                      <DeleteOpModal
                        opToDelete={opToDelete}
                        onCancel={() => setOpToDelete(null)}
                        onComplete={() => {
                          refetch();
                          setOpToDelete(null);
                        }}
                      />
                    </>
                  );
                }}
              </Query>
            </CenteredContainer>
          );
        }}
      </Paginator>
    </Root>
  );
}

export default OpList;

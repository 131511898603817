import React from 'react';
import styled from 'styled-components';
import DopeIcon from 'components/ui/DopeIcon';
import { CloudVendor } from 'typings';

const DisruptOpsIconRoot = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-color: ${(p) => p.theme.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

type Size = string | number | undefined;
type CloudVendorIconFunc = (size: Size) => React.ReactNode;

const CLOUD_VENDOR_ICON_MAP: { [key in CloudVendor]: CloudVendorIconFunc } = {
  AWS: (size) => <DopeIcon name="AWS" size={size || 30} title="AWS" />,
  aws: (size) => <DopeIcon name="AWS" size={size || 30} title="AWS" />,
  Azure: (size) => <DopeIcon name="AZURE" size={size || 30} title="Azure" />,
  azure: (size) => <DopeIcon name="AZURE" size={size || 30} title="Azure" />,
  Jira: (size) => <DopeIcon name="JIRA" size={size || 26} title="Jira" />,
  DisruptOps: (size) => (
    <DisruptOpsIconRoot style={{ width: size, height: size }}>
      <DopeIcon name="DISRUPT_OPS_FAVICON_WHITE" size={size ? +size - 4 : 18} title="DisruptOps" />
    </DisruptOpsIconRoot>
  )
};

interface Props {
  vendor: CloudVendor | string | null;
  size?: Size;
}

function CloudVendorIcon(props: Props) {
  const { vendor, size } = props;

  if (!vendor) return null;

  return <>{CLOUD_VENDOR_ICON_MAP[vendor](size)}</>;
}

export default CloudVendorIcon;

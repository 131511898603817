import React, { ReactNode, ReactNodeArray } from 'react';
import styled from 'styled-components';
import { CenteredContainer } from 'components/ui/NeoPage';
import { CSSTransitionGroup } from 'react-transition-group';

const Title = styled.h2`
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 12px;
`;

const Message = styled.div`
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;

  p {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ActionsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  > * {
    margin: 0 8px;
  }
  a {
    font-weight: 600;
  }
`;

const Root = styled.div`
  margin: 72px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GraphicWrap = styled.div`
  margin-bottom: 8px;
`;

export interface Props {
  graphic?: string | ReactNode;
  title?: string;
  message?: string | ReactNode;
  actions?: ReactNode | ReactNodeArray;
}

function EmptyState(props: Props) {
  const { graphic, title, message, actions } = props;

  return (
    <CSSTransitionGroup
      transitionName="enter-fade-in"
      transitionAppear={true}
      transitionAppearTimeout={500}
      transitionEnter={false}
      transitionLeave={false}
    >
      <Root>
        <CenteredContainer size="sm">
          <Content>
            {graphic && <GraphicWrap>{graphic}</GraphicWrap>}
            {title && <Title>{title}</Title>}
            {message && <Message>{message}</Message>}
            {actions && <ActionsWrap>{actions}</ActionsWrap>}
          </Content>
        </CenteredContainer>
      </Root>
    </CSSTransitionGroup>
  );
}

export default EmptyState;

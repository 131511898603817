import { Tooltip } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import React from 'react';
import { HorizontalLabelDiv } from './HorizontalLabel.styles';

interface HorizontalLabelProps {
  label: string;
  form?: boolean;
  helpText?: string;
  required?: boolean;
  children: any;
}

function HorizontalLabel(props: HorizontalLabelProps) {
  const { form, label, children, helpText, required } = props,
    classes: string[] = [],
    labelContent = (
      <>
        {required && <>*</>}
        {label}
        {helpText && (
          <>
            {' '}
            <Tooltip title={helpText} placement="right">
              <span className="trigger">
                <DopeIcon name="QUESTION" size="12" />
              </span>
            </Tooltip>
          </>
        )}
      </>
    );

  if (helpText) {
    classes.push('has-help');
  }

  if (form) {
    classes.push('form-mode');
  }

  return (
    <HorizontalLabelDiv className={classes.join(' ')}>
      <div className="label" data-testid="label">
        {labelContent}
      </div>
      <div className="value" data-testid="value">
        {children}
      </div>
    </HorizontalLabelDiv>
  );
}

export default HorizontalLabel;

import React from 'react';
import { FormField } from 'components/ui/Form';
import { Select } from 'antd';
import { GuardrailConfigurationInputProps } from '../../trigger/definitions';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import ProgressCircular from 'components/ui/ProgressCircular';
import { AutomationEventDefinition } from 'typings';

const EVENT_DEFINITIONS = gql`
  query EventDefinitionsForGuardrails($vendor: String) {
    eventDefinitions(pageSize: 100, vendor: $vendor) {
      nodes {
        id
        name
        key
        description
        overview
        criteria {
          path
          patterns
        }
        filters {
          key
          type
          required
          name
          options {
            name
            path
            patterns
            foundByProduct
          }
        }
      }
    }
  }
`;

interface FilterInput {
  eventDefinitionId: string;
  path: string;
  patterns: any;
}

export function eventDefinitionToFilterInput(eventDef: AutomationEventDefinition): FilterInput | null {
  // returns FilterInput
  const { id, filters, name } = eventDef;

  const filter = (filters && filters[0]) || null;
  const option = (filter && filter.options && filter.options[0]) || null;

  if (!option || !option.path) return null;

  const filterInput = {
    eventDefinitionId: id,
    eventDefinitionName: name,
    path: option.path,
    patterns: option.patterns
  };

  return filterInput;
}

function AwsSecurityHubFindingFilterInput(props: GuardrailConfigurationInputProps) {
  const { name } = props;

  return (
    <Query query={EVENT_DEFINITIONS} variables={{ vendor: 'AWS' }}>
      {({ data, loading, error }) => {
        if (!data && loading) return <ProgressCircular height={50} />;

        const eventDefinitions: AutomationEventDefinition[] = data.eventDefinitions.nodes;

        return (
          <FormField name={name} label="Finding Type">
            {({ value, handleBlur, formikContext: { setFieldValue } }) => {
              return (
                <Select
                  value={(value && value.eventDefinitionId) || null}
                  placeholder={'Select Finding Type'}
                  onChange={value => {
                    const matchingEventDef = eventDefinitions.find(item => item.id === value) || null;

                    if (matchingEventDef) {
                      setFieldValue(name, eventDefinitionToFilterInput(matchingEventDef));
                    }
                  }}
                  onBlur={handleBlur}
                >
                  {eventDefinitions.map(item => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              );
            }}
          </FormField>
        );
      }}
    </Query>
  );
}

export default AwsSecurityHubFindingFilterInput;

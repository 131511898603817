import styled from 'styled-components';

export const Root = styled.div`
  .choose-type {
    padding-bottom: 0.25rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid ${(p) => p.theme.grey200};
  }

  .choose-type__label {
    margin-right: 1rem;
    font-weight: bold;
  }

  .section-label {
    text-transform: uppercase;
    color: ${(p) => p.theme.grey400};
  }

  .available-filters > * {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .add-filter-type {
    margin-top: 2rem;
  }

  .filter-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0.25rem;

    > *:first-child {
      margin-right: 1rem;
    }

    i {
      margin-right: 0.5rem;
    }
  }
`;

import { ColumnProps } from 'antd/lib/table';

import { FilterDefinition, FilterValues } from 'typings';

import {
  allResources,
  awsEC2Instances,
  awsEC2NetworkInterfaces,
  awsEC2SecurityGroups,
  awsIAMUsers,
  awsS3Buckets
} from './resourceTypeConfigs';

// make sure that all DetailTables use the same props
export interface ResourceDetailTableProps {
  dopsResourceUid: string;
}

export interface ResourceTypeConfigItem {
  key: string; // unique namespaced ID for resource type across all cloud environments
  label: string; // Not sure if there's a use case for this.
  labelSingular: string;
  labelPlural: string;

  // query for ResourcesTable... could be called tableQuery?
  tableQuery: (resourceType: string) => any; // gql tableQuery
  tableColumnsConfig: ColumnProps<any>[]; // could be tableColumns
  tableQueryVariables?: (resourceType: string, qs: FilterValues) => any; // parse searchParams and return object // could be tableQueryVariables?
  rowKey?: string;

  filters?: FilterDefinition[];

  // optional field in Detail view.
  detailTable?: (props: ResourceDetailTableProps) => JSX.Element;
}

type ResourceTypeHash = {
  allResources: ResourceTypeConfigItem;
  [key: string]: ResourceTypeConfigItem;
};

// maybe we could generate this on load?
const resourceTypesHash: ResourceTypeHash = {
  allResources: allResources,
  'AWS::EC2::Instance': awsEC2Instances,
  'AWS::EC2::NetworkInterface': awsEC2NetworkInterfaces,
  'AWS::EC2::SecurityGroup': awsEC2SecurityGroups,
  'AWS::IAM::User': awsIAMUsers,
  'AWS::S3::Bucket': awsS3Buckets
};

export default resourceTypesHash;

import React from 'react';
import ErrorAlert from 'components/ui/ErrorAlert';
import { isNull } from 'util';

export interface Props {}

interface State {
  hasError: boolean;
  error: any;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
    error: isNull
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error);

    const trackJs = (window as any).trackJs;
    trackJs.track(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorAlert error={this.state.error} />;
    }

    return this.props.children;
  }
}

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { CenteredContainer } from 'components/ui/NeoPage';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { Table, Tooltip, Row, Col } from 'antd';
import styled from 'styled-components';
import { CloudAccount } from 'typings';
import QueryResult from 'components/util/QueryResult';
import { TableWrap, TableExpandedRow, TableHeader, TableHeaderActions } from 'components/ui/Table';
import DopeIcon from 'components/ui/DopeIcon';
import CloseButton from 'components/buttons/CloseButton';
import { rawToDashed } from 'utilities/resourceType';
import PageHeader from 'components/ui/PageHeader';
import VerticalTable from 'components/ui/Table/VerticalTable';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import AuthorizationErrorAlert from 'components/app/Auth/AuthorizationErrorAlert';

const Root = styled.div`
  .verical-align-top {
    vertical-align: top;
  }

  .resource-type-col {
    padding-left: 8px !important;
  }

  .ant-table-small > .ant-table-title {
    padding: 0 8px;
    border-bottom: none;
  }

  .nested-table-header {
    background-color: transparent;
    padding: 16px 8px;

    .nested-table-title {
      margin-bottom: 0;
    }
  }

  .expand-column {
    vertical-align: center;
  }

  .total-table-label {
    font-weight: 600;
    color: ${(p) => p.theme.grey900};
  }

  .total-table-value {
    padding-right: 24px;
    text-align: end;
  }
`;

const ExpandMoreLink = styled(Link)`
  color: ${(p) => p.theme.grey600};
  display: block;

  &.expanded {
    transform: rotateZ(180deg);
  }
`;

const LICENSE_STATS_QUERY = gql`
  query LicenseStats {
    license_stats {
      billable_count
      billable_price
      non_billable_count
      account_license_stats {
        billable_count
        billable_price
        non_billable_count
        dops_client_id
        dops_cloud_account_id
        dops_internal_account_id
        dops_resource_type_license_stats {
          billable_count
          non_billable_count
          billable_price
          dops_resource_type
        }
      }
    }

    accounts {
      items {
        account_id
        assumerole_account_id
        name
        nickname
        provider
      }
    }
  }
`;

interface ResourceTypeLicenseStats {
  billable_count: number;
  non_billable_count: number;
  billable_price: number;
  dops_resource_type: string;
}

interface AccountLicenseStats {
  billable_count: number;
  non_billable_count: number;
  billable_price: number;
  dops_client_id: string; // not used
  dops_cloud_account_id: string;
  dops_internal_account_id: string; // uuid
  dops_resource_type_license_stats: ResourceTypeLicenseStats[];
}

interface LicenseStats {
  billable_count: number;
  billable_price: number;
  non_billable_count: number;
  account_license_stats: AccountLicenseStats[];
}

interface Props
  extends RouteComponentProps<{
    accountId?: string;
  }> {}

function Billing(props: Props) {
  const accountId = props.match.params.accountId || null;

  const { isAuthorized, failedPermissions } = useAuthorizor(Permissions.VIEW_CLIENTS);
  const { loading, error, data } = useQuery(LICENSE_STATS_QUERY, { skip: !isAuthorized });

  return (
    <QueryResult loading={loading} data={data} error={error}>
      {() => {
        const accountHash: {
          [key: string]: CloudAccount;
        } =
          data?.accounts?.items?.reduce((acc, item) => {
            acc[item.account_id] = item;

            return acc;
          }, {}) || {};

        const licenseStats: LicenseStats = data?.license_stats || {};

        return (
          <Root>
            <CenteredContainer size="fluid">
              <PageHeader title="Billing" titleLevel={2} />
              {!isAuthorized ? (
                <AuthorizationErrorAlert failedPermissions={failedPermissions} />
              ) : (
                <Row gutter={32}>
                  <Col md={24} xxl={8}>
                    <TableWrap panelTitle="Total counts">
                      <VerticalTable
                        dataSource={licenseStats}
                        type="review"
                        classes={{
                          labelCol: 'total-table-label',
                          valueCol: 'total-table-value'
                        }}
                        rows={[
                          {
                            key: 'total-count',
                            dataIndex: 'billable_count',
                            label: 'Total billable resource count',
                            render: (billableCount) => billableCount.toLocaleString()
                          },
                          {
                            key: 'total-nonbillable-count',
                            dataIndex: 'non_billable_count',
                            label: 'Total non billable resource count',
                            render: (nonBillableCount) => nonBillableCount.toLocaleString()
                          }
                        ]}
                      />
                    </TableWrap>
                  </Col>

                  <Col md={24} xxl={16}>
                    <TableWrap panelTitle="Resource counts by account">
                      <Table
                        dataSource={licenseStats?.account_license_stats || []}
                        rowKey={'dops_internal_account_id'}
                        pagination={false}
                        className="overflow-visible hide-expansion-icon"
                        expandedRowKeys={accountId ? [accountId] : []}
                        expandedRowRender={(accountLicenseStat) => {
                          return (
                            <TableExpandedRow>
                              <Table
                                title={() => (
                                  <TableHeader
                                    classes={{
                                      root: 'nested-table-header'
                                    }}
                                  >
                                    <h3 className="nested-table-title">{accountLicenseStat.dops_cloud_account_id}</h3>
                                    <TableHeaderActions>
                                      <CloseButton
                                        onClose={() => {
                                          props.history.push('/organization-settings/billing');
                                        }}
                                      />
                                    </TableHeaderActions>
                                  </TableHeader>
                                )}
                                size="small"
                                dataSource={accountLicenseStat.dops_resource_type_license_stats}
                                rowKey="dops_resource_type"
                                columns={[
                                  {
                                    title: 'Resource type',
                                    key: 'resource-type',
                                    dataIndex: 'dops_resource_type'
                                  },
                                  {
                                    title: 'Billable count',
                                    dataIndex: 'billable_count',
                                    key: 'billable-count',
                                    render: (billableCount) => billableCount.toLocaleString()
                                  },
                                  {
                                    title: 'Non billable count',
                                    dataIndex: 'non_billable_count',
                                    key: 'non-billable-count',
                                    render: (nonBillableCount) => nonBillableCount.toLocaleString()
                                  },
                                  {
                                    dataIndex: 'dops_resource_type',
                                    key: 'view-resource-type',
                                    width: 50,
                                    render: (resourceType) => (
                                      <Tooltip title="View in inventory" placement="left">
                                        <Link
                                          to={`/inventory/resources/${rawToDashed(resourceType)}?account=${
                                            accountLicenseStat.dops_internal_account_id
                                          }`}
                                        >
                                          <DopeIcon size={14} name="EXTERNAL_LINK" />
                                        </Link>
                                      </Tooltip>
                                    )
                                  }
                                ]}
                                pagination={false}
                              />
                            </TableExpandedRow>
                          );
                        }}
                        columns={[
                          {
                            title: 'Account nickname',
                            dataIndex: 'dops_internal_account_id',
                            key: 'nickname',
                            render: (uuid) => {
                              const account = accountHash[uuid] || null;

                              return (account && account.nickname) || '--';
                            }
                          },
                          {
                            title: 'Account ID',
                            dataIndex: 'dops_cloud_account_id',
                            key: 'account-id'
                          },
                          {
                            title: 'Cloud provider',
                            dataIndex: 'dops_internal_account_id',
                            key: 'cloud-provider',
                            render: (uuid) => {
                              const account = accountHash[uuid] || null;

                              return (account && account.provider) || '--';
                            }
                          },
                          {
                            title: 'Billable count',
                            dataIndex: 'billable_count',
                            key: 'billable-count',
                            align: 'center',
                            render: (billableCount) => billableCount.toLocaleString()
                          },
                          {
                            title: 'Non billable count',
                            dataIndex: 'non_billable_count',
                            key: 'non-billable-count',
                            align: 'center',
                            render: (nonBillableCount) => nonBillableCount.toLocaleString()
                          },
                          {
                            dataIndex: 'dops_internal_account_id',
                            className: 'expand-column',
                            key: 'expand',
                            width: 50,
                            render: (uuid) => (
                              <ExpandMoreLink
                                className={accountId === uuid ? 'expanded' : null}
                                to={
                                  accountId === uuid
                                    ? '/organization-settings/billing'
                                    : `/organization-settings/billing/${uuid}`
                                }
                              >
                                <DopeIcon size={20} name="EXPAND_MORE" />
                              </ExpandMoreLink>
                            )
                          },
                          {
                            dataIndex: 'dops_internal_account_id',
                            key: 'view-account',
                            width: 50,
                            render: (uuid) => (
                              <Tooltip title="View in inventory" placement="left">
                                <Link to={`/inventory/resources?account=${uuid}`}>
                                  <DopeIcon size={14} name="EXTERNAL_LINK" />
                                </Link>
                              </Tooltip>
                            )
                          }
                        ]}
                      />
                    </TableWrap>
                  </Col>
                </Row>
              )}
            </CenteredContainer>
          </Root>
        );
      }}
    </QueryResult>
  );
}

export default Billing;

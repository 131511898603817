import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Button, Popconfirm, message } from 'antd';

import DopeIcon from 'components/ui/DopeIcon';

const REMOVE_EXEMPTION = gql`
  mutation RemoveExemption($issueIds: [String]!) {
    updateIssueExemptions(issueIds: $issueIds, isExempted: false) {
      id
      isExempted
      exemptedReason
    }
  }
`;

export interface Props {
  issueId: string;
  onComplete?: () => any;
}

function RemoveExemptionButton(props: Props) {
  const { onComplete } = props;

  return (
    <Mutation mutation={REMOVE_EXEMPTION}>
      {removeExemption => {
        return (
          <Popconfirm
            title="Remove exemption"
            trigger={'click'}
            onConfirm={async () => {
              const variables = {
                issueIds: [props.issueId]
              };

              await removeExemption({
                variables
              });

              message.success('Issue exemption removed');

              if (onComplete) onComplete();
            }}
            placement="bottomLeft"
          >
            <Button>
              <DopeIcon name="ISSUE_REMOVE_EXEMPT" />
              Remove Exemption
            </Button>
          </Popconfirm>
        );
      }}
    </Mutation>
  );
}

export default RemoveExemptionButton;

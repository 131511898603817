import React from 'react';
import { InputNumber } from 'antd';
import validator from 'services/validator';
import { size, FunctionInputBaseProps } from 'typings';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
`;

const schema = validator.number().integer();

export const inputDefinition = {
  schema,
  defaultValue: undefined
};

interface Props extends FunctionInputBaseProps {
  name: string;
  value: number;
  setFieldTouched: (name: string, touched: boolean) => any | void;
  setFieldValue: (name: string, value: any) => any | void;
  placeholder?: string;
  size?: size;
}

function IntegerInput(props: Props) {
  const { name, value, setFieldTouched, setFieldValue, placeholder = '7', size = 'large' } = props;
  const handleChange = value => setFieldValue(name, value || null);
  const handleBlur = () => setFieldTouched(name, true);

  return (
    <Root>
      <InputNumber
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        size={size}
        placeholder={placeholder}
        style={{ width: '100%' }}
      />
    </Root>
  );
}

export default IntegerInput;

import { Popover } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import React from 'react';
import { CloudAccount, Op, Project } from 'typings';
import { truncate } from 'components/recipients/common';

interface Props {
  item: Op;
  projects: Project[];
  accounts: CloudAccount[];
  eventSources: any[];
}

function OpItemFilters(props: Props) {
  const { item, projects, accounts, eventSources } = props;

  const {
    triggerProjectIds,
    accountIds,
    accountLabels,
    environments,
    regions,
    eventSourceId,
    severity,
    // assessmentId,
    resourceType,
    tags,
    jsonPaths
  } = item;

  const projectFilters =
    triggerProjectIds?.map((projectId) => {
      const project: Project | undefined = projects.find((p) => p.project_id === projectId);
      const { name } = project || {};
      return {
        type: 'Project',
        iconName: 'PROJECT',
        iconTitle: 'Project',
        text: project ? name : projectId
      };
    }) || [];

  const accountFilters =
    accountIds?.map((accountId) => {
      const account = accounts.find((a) => a.account_id === accountId);
      const { nickname, name, assumerole_account_id } = account || {};
      return {
        type: 'Account',
        iconName: 'CLOUD_ACCOUNT',
        iconTitle: 'Cloud Account',
        text: nickname || name || assumerole_account_id || accountId
      };
    }) || [];

  const accountLabelFilters =
    accountLabels?.map((label) => {
      return {
        type: 'Label',
        iconName: 'LABEL',
        iconTitle: 'Cloud Account Label',
        text: label
      };
    }) || [];

  const environmentFilters =
    environments?.map((environment) => {
      return {
        type: 'Environment',
        iconName: 'FILTER',
        iconTitle: 'Environment',
        text: environment
      };
    }) || [];

  const regionFilters =
    regions?.map((region) => {
      return {
        type: 'Region',
        iconName: 'REGION',
        iconTitle: 'Region',
        text: region
      };
    }) || [];

  const eventSource: any | undefined = eventSources.find((s) => s.id === eventSourceId);

  const eventSourceFilters =
    (eventSourceId && [
      {
        type: 'Source',
        iconName: 'FILTER',
        iconTitle: 'Event Source',
        text: eventSource?.name || eventSourceId
      }
    ]) ||
    [];

  const severityFilters =
    severity?.map((s) => {
      return {
        type: 'Severity',
        iconName: 'FILTER',
        iconTitle: 'Severity',
        text: s
      };
    }) || [];

  const resourceTypeFilters =
    resourceType?.map((r) => {
      return {
        type: 'Resource',
        iconName: 'FILTER',
        iconTitle: 'Resource Type',
        text: r
      };
    }) || [];

  const tagFilters =
    tags?.map((tag) => {
      return {
        type: 'Tag',
        iconName: 'FILTER',
        iconTitle: 'Resource Tag',
        text: `${tag.key}: ${tag.value}`
      };
    }) || [];

  const customFilters =
    jsonPaths?.map((customFilter) => {
      return {
        type: 'Custom',
        iconName: 'FILTER',
        iconTitle: 'Custom',
        text: truncate(`${customFilter.path} = ${customFilter.patterns.join(', ')}`, {}),
        popoverText: `${customFilter.path} = ${customFilter.patterns.join(', ')}`
      };
    }) || [];

  const filterItems: any[] = [
    ...projectFilters,
    ...accountFilters,
    ...accountLabelFilters,
    ...environmentFilters,
    ...regionFilters,
    ...eventSourceFilters,
    ...severityFilters,
    ...resourceTypeFilters,
    ...tagFilters,
    ...customFilters
  ];

  return (
    <Popover
      title="Filters"
      content={
        <div>
          {filterItems.map((filterItem, index) => (
            <div key={index}>
              <b>{filterItem.type}</b>: {filterItem.popoverText || filterItem.text}
            </div>
          ))}
        </div>
      }
      placement="left"
    >
      {filterItems.slice(0, 5).map((filterItem, index) => (
        <div key={index} className="no-break">
          <DopeIcon className={'icon'} name={filterItem.iconName} title={filterItem.iconTitle} /> {filterItem.text}
        </div>
      ))}
      {filterItems.length > 5 && <div>{filterItems.length - 5} more...</div>}
    </Popover>
  );
}

export default OpItemFilters;

import React, { useContext } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { Modal, Button, Input, message } from 'antd';
import { ModalProps } from 'antd/lib/modal';

import { Project } from 'typings';

import { Form, FormField } from 'components/ui/Form';
import AuthContext from 'components/app/Auth/AuthContext';
import ErrorAlert from 'components/ui/ErrorAlert';
import ProjectSelect from 'components/project/ProjectSelect';
import { PROJECT_TREE_QUERY } from 'components/project/ProjectTree';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import FormItem from 'antd/lib/form/FormItem';
import { refreshCognitoSession } from '../../../../../services/auth';

const NEW_PROJECT_MUTATION = gql`
  mutation NewProjectModal($input: PostProjectInput!) {
    newProject(input: $input) @rest(type: "ClientProject", path: "/api/v2/projects", method: "POST") {
      client_id
      project_id

      parent_id
      is_system_controlled

      name
      description

      accounts_list {
        account_id
      }

      created
      updated
    }
  }
`;

const antModalProps: ModalProps = {
  title: 'Create Project',
  width: 1000
};

interface FormFields {
  name: string;
  parent_id: string;
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required')
});

interface Props {
  rootProjectId?: string;
  visible: boolean;
  onCloseModal: () => void;
}

function CreateProjectModal(props: Props) {
  const { visible, onCloseModal } = props;

  const history = useHistory();
  const authContext = useContext(AuthContext);
  const rootProjectId = props.rootProjectId || authContext?.me?.client?.root_project_id;

  const { isAuthorized, message: authErrorMessage } = useAuthorizor(
    Permissions.MODIFY_PROJECTS,
    rootProjectId ? [rootProjectId] : undefined
  );

  const [newProject, { error }] = useMutation(NEW_PROJECT_MUTATION, {
    update: (cache, result) => {
      const cacheResult = cache.readQuery<any>({ query: PROJECT_TREE_QUERY });
      cache.writeQuery({
        query: PROJECT_TREE_QUERY,
        data: {
          projects: [...cacheResult.projects, result.data.newProject]
        }
      });
    }
  });

  if (!visible) return null;

  return (
    <Form
      allowCleanSubmits={false}
      validationSchema={formSchema}
      initialValues={{
        name: '',
        parent_id: rootProjectId
      }}
      onSubmit={async (formValues: Partial<FormFields>, formActions) => {
        try {
          const { name, parent_id } = formValues;
          const input = {
            name,
            parent_id
          };

          const response = await newProject({ variables: { input } });
          const project: Project = response.data.newProject;

          message.success('Created new project');
          await refreshCognitoSession();

          onCloseModal();
          history.push(`/projects/${project.project_id}`);
        } catch (e) {
          message.error('Failed to create project');
        } finally {
          formActions.setSubmitting(false);
        }
      }}
    >
      {(formProps) => {
        const { canSubmit, isSubmitting, submitForm } = formProps;

        return (
          <Modal
            visible
            {...antModalProps}
            closable
            onCancel={onCloseModal}
            footer={
              <>
                <Button onClick={onCloseModal} disabled={isSubmitting}>
                  {'Cancel'}
                </Button>

                <Button
                  onClick={submitForm}
                  type="primary"
                  disabled={!isAuthorized || !canSubmit || isSubmitting}
                  loading={isSubmitting}
                >
                  {'Create Project'}
                </Button>
              </>
            }
          >
            <>
              {(error || !isAuthorized) && (
                <FormItem>
                  {error && <ErrorAlert message="Error creating project" error={error}></ErrorAlert>}

                  {!isAuthorized && <ErrorAlert message={`${authErrorMessage} for selected Parent Project.`} />}
                </FormItem>
              )}

              <FormField name="name" label="Project name">
                {({ name, value, handleBlur, handleChange }) => (
                  <Input name={name} value={value} onBlur={handleBlur} onChange={handleChange} />
                )}
              </FormField>

              <FormField name={`parent_id`} label="Parent project">
                {({ name, value, handleBlur, handleChange }) => (
                  <ProjectSelect
                    many={false}
                    value={value}
                    name={name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    permissions={Permissions.MODIFY_PROJECTS}
                  />
                )}
              </FormField>
            </>
          </Modal>
        );
      }}
    </Form>
  );
}

export default CreateProjectModal;

import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import Table from 'components/ui/Table';
import Panel from 'components/ui/Panel';
import PageHeader from 'components/ui/PageHeader';
import Paragraph from 'antd/lib/typography/Paragraph';
import { Button, message, Icon, Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import DopeIcon from 'components/ui/DopeIcon';
import ErrorAlert from 'components/ui/ErrorAlert';
import DeleteAPIKeyButton from 'app/sections/Authenticated/pages/OrganizationSettings/pages/Integration/components/DeleteAPIKeyButton';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import AuthorizationErrorAlert from 'components/app/Auth/AuthorizationErrorAlert';
import { COLLECTOR_URL } from 'constants/runtimeConfig';
import DateTime from 'components/ui/DateTime';
import CreateApiKeyModal from 'app/sections/Authenticated/pages/OrganizationSettings/pages/Integration/components/CreateAPIKeyButton';

const CLIENT_API_KEYS_QUERY = gql`
  query ClientAPIKeys {
    client {
      api_keys
      structured_api_keys{
        apiKey
        label
        createdAt
      }
      client_id
    }
  }
`;

const Root = styled.div`
  .provision-actions-row {
    margin-bottom: 24px;
    display: flex;

    > * {
      margin-right: 8px;
    }
  }
`;

interface Props {
  provisionUrl: string;
}

interface ProvisionModalProps extends ModalProps {
  tfvarsFile: string;
  content: string;
  templateUrl: string;
}

class ProvisionTerraformModal extends React.Component<ProvisionModalProps> {
  state = { visible: false };
  title = this.props.title;

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Button type="primary" size="small" ghost onClick={this.showModal}>
          Use Terraform
        </Button>
        <Modal
          title={this.title}
          visible={this.state.visible}
          closable
          onCancel={() => { this.hideModal() }}
          onOk={() => { this.hideModal() }}
        >
          <p>{this.props.content}</p>
          <CopyToClipboard text={this.props.templateUrl} onCopy={() => message.success('Copied to clipboard')}>
            <Button className="btn-with-icon">
              <DopeIcon name="COPY" />
              {'Copy Template Url'}
            </Button>
          </CopyToClipboard>
          <CopyToClipboard text={this.props.tfvarsFile} onCopy={() => message.success('Copied to clipboard')}>
            <Button className="btn-with-icon">
              <DopeIcon name="COPY" />
              {'Copy tfvars to Clipboard'}
            </Button>
          </CopyToClipboard>
        </Modal>
      </>
    );
  }
}


function ProvisionInstructions({ provisionUrl }: Props) {
  const { isAuthorized, failedPermissions } = useAuthorizor(Permissions.VIEW_CLIENT_API_KEYS);
  const { loading, error, data, refetch } = useQuery(CLIENT_API_KEYS_QUERY, { skip: !isAuthorized });

  const structuredKeys = data?.client?.structured_api_keys || [];
  const clientId = data?.client?.client_id ? data.client.client_id : '';
  const collectorUrl = `${COLLECTOR_URL}/event`;

  const [isCreatingApiKey, setIsCreatingApiKey] = useState<boolean>(false);

  const provisionTemplateUrl = provisionUrl.match('templateURL=(.*)/dops_cf_(.*).template');
  const trustedAccount = provisionUrl.match('TrustedAccount=(.*)&param_ExternalId');
  const externalId = provisionUrl.match('ExternalId=(.*)&param_ProvisionerAccount');
  const provisionerAccount = provisionUrl.match('ProvisionerAccount=(.*)&param_ProvisionerExternalId');
  const provisionerExternalId = provisionUrl.match('ProvisionerExternalId=(.*)');

  return (
    <Root>
      <Paragraph strong>Instructions</Paragraph>

      <ol>
        <Paragraph>
          {
            'There are two steps to the provisioning process. The first is mandatory and it sets up the priveleges needed for DisruptOps to monitor your account.'
          }
        </Paragraph>

        <Paragraph>
          {
            'The second step optionally configures Security Hub to forward its findings to DisruptOps. Make sure you are logged in to the AWS Console using the account entered above. You must be logged in as a user account or role with IAM priveleges.'
          }
        </Paragraph>

        <li>
          <Paragraph>
            <strong>{'Configure account access'}</strong> (mandatory) -
            {
              'Navigate to the URL provided by DisruptOps in the same browser you have logged into AWS. This will take you to the CloudFormation console with all required information filled in.'
            }
          </Paragraph>

          <div className="provision-actions-row">
            <a href={provisionUrl} target="_blank" rel="noopener noreferrer">
              <Button ghost type="primary" size="small">
                Open in new tab
              </Button>
            </a>

            <CopyToClipboard
              text={provisionUrl}
              onCopy={() => {
                message.success('Provision URL Copied to clipboard');
              }}
            >
              <Button className="btn-with-icon" type="primary" size="small" ghost>
                <Icon type="copy" style={{ transform: 'scale(0.8)', cursor: 'pointer' }} />
                {'Copy to clipboard'}
              </Button>
            </CopyToClipboard>

            <ProvisionTerraformModal
              title="Terraform Instructions"
              content="Copy the link to the Terraform template. You will also need to copy the tfvars text and provide that when you use the template."
              tfvarsFile={`profile = "default"
TrustedAccount = ${trustedAccount !== null ? trustedAccount[1] : ""}
ExternalId = ${externalId !== null ? externalId[1] : ""}
ProvisionerAccount = ${provisionerAccount !== null ? provisionerAccount[1] : ""}
ProvisionerExternalId = ${provisionerExternalId !== null ? provisionerExternalId[1] : ""}`}
              templateUrl={provisionTemplateUrl ? provisionTemplateUrl[1] + '/dops_terraform_' + provisionTemplateUrl[2] + '.tf' : ""}
            ></ProvisionTerraformModal>

          </div>
        </li>

        <Paragraph>
          {
            'Check the box to allow creation of IAM resources and the CloudFormation will do the following (you can also download it for review first):'
          }
          <ul>
            <li>
              <Paragraph>
                {
                  'Create a DisruptOps Provisioner role and grants cross-account access to our provisioner account. The Provisioner has full IAM rights but is permission boundary locked to only adjust permissions on our DisruptOps Worker role. This allows us to adjust permissions as we add new Ops. As the customer you always "own" the permission policy in your account and are in full control.'
                }
              </Paragraph>
            </li>

            <li>
              <Paragraph>
                {
                  'Create a DisruptOps Worker role and grants access to our production system. The worker is provisioned with only the permissions needed for supported Ops, and is permission boundary restricted from modifying the Provisioner role.'
                }
              </Paragraph>
            </li>
          </ul>
        </Paragraph>

        <li>
          <strong>{'Configure Security Hub'}</strong> (optional) -
          <Paragraph>
            {'This will forward all Security Hub findings to DisruptOps. You may use CloudFormation or Terraform.'}
          </Paragraph>
          {!isAuthorized ? (
            <AuthorizationErrorAlert failedPermissions={failedPermissions} />
          ) : (
              <>
                <Paragraph>
                  {`Click to generate an API key to forward Security Hub events to DisruptOps. Then select your deployment option and copy the link to the CloudFormation or Terraform templates. For CloudFormation the link includes the API key as a parameter. For Terraform you will also need to copy the tfvars text and provide that when you use the template.\n`}
                </Paragraph>
                <Paragraph>
                  {`Run the Terraform StackSet file with the command 'terraform apply -parallelism=1' when deploying more than one Stack. If you do not want the StackSet to deploy to all regions, edit the list within the Terraform file before execution. If you wish to run this through automation instead of the console, please contact support.\n`}
                </Paragraph>
                <PageHeader
                  title="API Keys and Event Forwarder Files"
                  titleLevel={3}
                  actions={
                    <Button
                      onClick={() => {
                        setIsCreatingApiKey(true);
                      }}
                    >
                      Create API Key
                    </Button>}
                ></PageHeader>
                <b>Client ID:</b> {clientId}
                <Panel rounded>
                  {error ? (
                    <ErrorAlert error={error} />
                  ) : (
                      <Table
                        antTableProps={{
                          rowKey: (row: any) => row.value,
                          dataSource: structuredKeys.map((key) => ({
                            value: key,
                            link: `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=https://dopsprovision8394402prod.s3-us-west-2.amazonaws.com/event-collector-cf-template&stackName=DisruptOps-Event-Collector&param_ClientID=${clientId}&param_ClientApiKey=${key.apiKey}&param_CollectorURL=${collectorUrl}`,
                            terraform: `https://dopsprovision8394402prod.s3-us-west-2.amazonaws.com/dops-event-collector.tf`,
                            terraformStackSet: `https://dopsprovision8394402prod.s3-us-west-2.amazonaws.com/dops-event-collector-stackset.tf`,
                            tfvars: `profile            = "default"
ClientID           = ${clientId}
ClientApiKey       = ${key.apiKey}
CollectorURL       = ${collectorUrl}`
                          })),
                          columns: [
                            {
                              title: 'label',
                              key: 'label',
                              dataIndex: 'value.label'
                            },
                            {
                              title: 'Key',
                              key: 'apiKey',
                              dataIndex: 'value.apiKey'
                            },
                            {
                              title: 'CloudFormation Link',
                              align: 'center',
                              key: 'createStackLink',
                              dataIndex: 'link',
                              render: (stackLink) => {
                                return (
                                  <CopyToClipboard text={stackLink} onCopy={() => message.success('Copied to clipboard')}>
                                    <Button className="btn-with-icon">
                                      <DopeIcon name="COPY" />
                                      {'Copy to clipboard'}
                                    </Button>
                                  </CopyToClipboard>
                                );
                              }
                            },
                            {
                              title: 'Terraform Stack Link',
                              align: 'center',
                              key: 'terraform',
                              dataIndex: 'terraform',
                              render: (tfFile) => {
                                return (
                                  <CopyToClipboard text={tfFile} onCopy={() => message.success('Copied to clipboard')}>
                                    <Button className="btn-with-icon">
                                      <DopeIcon name="COPY" />
                                      {'Copy to clipboard'}
                                    </Button>
                                  </CopyToClipboard>
                                );
                              }
                            },
                            {
                              title: 'Terraform StackSet Link',
                              align: 'center',
                              key: 'terraformStackSet',
                              dataIndex: 'terraformStackSet',
                              render: (tfFile) => {
                                return (
                                  <CopyToClipboard text={tfFile} onCopy={() => message.success('Copied to clipboard')}>
                                    <Button className="btn-with-icon">
                                      <DopeIcon name="COPY" />
                                      {'Copy to clipboard'}
                                    </Button>
                                  </CopyToClipboard>
                                );
                              }
                            },
                            {
                              title: 'Terraform tfvars',
                              align: 'center',
                              key: 'tfvars',
                              dataIndex: 'tfvars',
                              render: (tfvarsFile) => {
                                return (
                                  <CopyToClipboard text={tfvarsFile} onCopy={() => message.success('Copied to clipboard')}>
                                    <Button className="btn-with-icon">
                                      <DopeIcon name="COPY" />
                                      {'Copy to clipboard'}
                                    </Button>
                                  </CopyToClipboard>
                                );
                              }
                            },
                            {
                              title: 'Created At',
                              key: 'createdAt',
                              dataIndex: 'value.createdAt',
                              render: (createdAt) => {
                                return <DateTime dateTime={new Date(createdAt)} format="local" />;
                              }
                            },
                            {
                              title: 'Delete',
                              align: 'right',
                              key: 'delete',
                              render: (text, record, index) => {
                                return (
                                  <DeleteAPIKeyButton apiKey={record.value.apiKey} onDelete={() => refetch()}></DeleteAPIKeyButton>
                                );
                              }
                            }
                          ],
                          style: {
                            width: '100%'
                          },
                          loading: loading ? true : false
                        }}
                      ></Table>
                    )}
                </Panel>
              </>
            )}
          <CreateApiKeyModal
            onCreate={() => refetch()}
            visible={isCreatingApiKey}
            onCloseModal={() => setIsCreatingApiKey(false)}
          />
        </li>
      </ol>
    </Root >
  );
}

export default ProvisionInstructions;

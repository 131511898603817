import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Root = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const BreadcrumbLink = styled(NavLink)`
  display: inline-block;
  color: ${p => p.theme.primary600};

  &.disabled {
    color: ${p => p.theme.grey500};
    pointer-events: none;
  }
`;

const Divider = styled.div`
  width: 24px;
  text-align: center;
  padding: 0 8px;
`;

export interface Breadcrumb {
  label: string;
  to: string;
  exact?: boolean;
  disabled?: boolean;
}

interface Props {
  breadcrumbs: Breadcrumb[];

  classes?: {
    root?: string;
    breadcrumb?: string;
  };
}

function PageBreadcrumbs(props: Props) {
  const { breadcrumbs, classes = {} } = props;
  return (
    <Root className={`page-breadcrumbs ${classes.root}`}>
      {breadcrumbs.map((item, idx) => {
        const { to, label, disabled = false } = item;

        return (
          <React.Fragment key={`breadcrumb-${idx}`}>
            <BreadcrumbLink
              className={`breadcrumb-link ${disabled ? 'disabled' : ''}`}
              activeClassName={'active'}
              to={to}
            >
              {label}
            </BreadcrumbLink>

            {idx < breadcrumbs.length - 1 && <Divider>{'/'}</Divider>}
          </React.Fragment>
        );
      })}
    </Root>
  );
}

export default PageBreadcrumbs;

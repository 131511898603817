import { Typography } from 'antd';
// dops imports
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { Recipient } from 'typings';
import { RECIPIENT_TYPE_DEFINITIONS } from './common';

const RECIPIENTS_QUERY = gql`
  query RecipientsForRecipientList {
    recipients {
      from
      total
      size
      items {
        id
        name
        type
        config
      }
    }
  }
`;

type ActionRenderFunc = (recipient: Recipient) => React.ReactNode;

interface RecipientListItemAction {
  render: ActionRenderFunc;
}

interface RecipientListProps {
  actions?: RecipientListItemAction[];
}

function RecipientList(props: RecipientListProps) {
  const { actions } = props;
  const queryResults = useQuery(RECIPIENTS_QUERY);

  const supportedRecipientTypeDefinitions = RECIPIENT_TYPE_DEFINITIONS;

  return (
    <QueryResult {...queryResults}>
      {() => {
        const recipients: Recipient[] | null = queryResults?.data?.recipients?.items || null;

        if (!recipients || recipients.length === 0) return <Typography.Text>{'No Recipients found.'}</Typography.Text>;

        return (
          <>
            {recipients.map((recipient, idx) => {
              const recipientDefinition =
                supportedRecipientTypeDefinitions.find((d) => d.key === recipient.type) || null;

              if (!recipientDefinition) return null;

              return (
                <RecipientListItem
                  key={`${recipient.id}_${idx}`}
                  icon={recipientDefinition.icon}
                  label={recipientDefinition.getLabel(recipient)}
                  actions={
                    actions ? (
                      <>
                        {actions.map((action) => {
                          return action.render(recipient);
                        })}
                      </>
                    ) : null
                  }
                />
              );
            })}
          </>
        );
      }}
    </QueryResult>
  );
}

// COMPONENT: RECIPIENT LIST ITEM
const RecipientListItemRoot = styled.div`
  margin-bottom: 24px;
  padding: 16px 8px;
  display: flex;
  border-radius: 4px;
  display: flex;
  border: 1px solid ${(p) => p.theme.grey300};

  .recipient-list-item-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .recipient-list-item-main {
    margin-right: 16px;
    flex: 1 0 auto;
  }

  .recipient-list-item-label {
    font-weight: 600;
    font-size: 16px;
  }
`;

interface RecipientListItemProps {
  icon?: React.ReactNode;
  label: React.ReactNode;
  actions?: React.ReactNode;
}

function RecipientListItem(props: RecipientListItemProps) {
  const { icon, label, actions } = props;

  return (
    <RecipientListItemRoot>
      {icon && <div className="recipient-list-item-icon">{icon}</div>}
      <div className="recipient-list-item-main">
        <div className="recipient-list-item-label">{label}</div>
      </div>

      {actions && <div className="recipient-list-item-action">{actions}</div>}
    </RecipientListItemRoot>
  );
}

export default RecipientList;

import React from 'react';
import { RouteComponentProps } from 'react-router';

import { getSlackAuthorizationRedirectURI, parseVerificationCode } from 'services/slack/auth';

import ProgressCircular from 'components/ui/ProgressCircular';
import SlackContext from 'components/slack/SlackContext';
import { client } from 'services/graphql';
import { SLACK_AUTHORIZATION_MUTATION } from '../Notifications/gql';
import { CenteredContainer } from 'components/ui/NeoPage';
import ErrorAlert from 'components/ui/ErrorAlert';

interface Props extends RouteComponentProps<any> {}

interface State {
  error?: readonly any[] | null;
  loading: boolean;
}

class AuthenticateSlackTargetPage extends React.Component<Props, State> {
  static contextType = SlackContext;

  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      loading: false
    };

    this.authenticateTarget = this.authenticateTarget.bind(this);
  }

  componentDidMount() {
    this.authenticateTarget();
  }

  async authenticateTarget() {
    this.setState({ error: null, loading: true });

    const code = parseVerificationCode(this.props.location.search);
    if (!code) {
      return;
    }

    const { data, errors } = await client.mutate({
      mutation: SLACK_AUTHORIZATION_MUTATION,
      variables: {
        request: {
          code: code,
          redirect_uri: getSlackAuthorizationRedirectURI()
        }
      }
    });

    if (errors) {
      this.setState({ error: errors, loading: false });
      return;
    }

    if (data && data.authorize && data.authorize.ok) {
      if (window.opener) {
        const authorizationResponse = {
          ...data.authorize,
          source: 'slack-auth'
        };
        const message = JSON.stringify(authorizationResponse);
        window.opener.postMessage(message);
      }
    } else {
      this.setState({
        error: [
          {
            message: 'An unknown error was encountered.'
          }
        ],
        loading: false
      });
    }

    this.setState({ loading: false });
  }

  render() {
    const { error, loading } = this.state;

    if (error) {
      return (
        <CenteredContainer>
          <ErrorAlert message="An error was encountered authorizing Slack." error={error} />
        </CenteredContainer>
      );
    }

    if (loading) {
      return (
        <CenteredContainer>
          <ProgressCircular />
        </CenteredContainer>
      );
    }

    return null;
  }
}

export default AuthenticateSlackTargetPage;

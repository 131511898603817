import React, { useState } from 'react';
import ReactJSONTree from 'react-json-tree';
import { Button, message, Form } from 'antd';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import TextArea from 'antd/lib/input/TextArea';

const ButtonGroup = Button.Group;

const Root = styled.div`
  position: relative;

  .json-tree-container {
    background-color: #eeeeee;
    padding: 2px 16px;
  }

  .action-button-group {
    position: absolute;
    top: 10px;
    right: 16px;
    z-index: 1;
  }
`;

const theme = {
  scheme: 'brewer',
  author: 'timothée poisot (http://github.com/tpoisot)',
  base00: '#eeeeee', //base00: '#0c0d0e',
  base01: '#2e2f30',
  base02: '#515253',
  base03: '#737475',
  base04: '#959697',
  base05: '#b7b8b9',
  base06: '#dadbdc',
  base07: '#fcfdfe',
  base08: '#e31a1c',
  base09: '#e6550d',
  base0A: '#dca060',
  base0B: '#31a354',
  base0C: '#80b1d3',
  base0D: '#3182bd',
  base0E: '#756bb1',
  base0F: '#b15928'
};

interface Props {
  data: any;
  depth?: number;
  maxDepth?: number;
  showExpandButton?: boolean;
  showCopyButton?: boolean;
  copySuccessMessage?: string;
  onKeyClick?: (path: string[]) => any | void;
  onValueClick?: (value: string, path: string[]) => any | void;
  isEditable?: boolean;
  isEditingInitially?: boolean;
}

function JSONTree(props: Props) {
  const {
    data,
    showExpandButton = true,
    showCopyButton = true,
    onKeyClick,
    onValueClick,
    copySuccessMessage,
    isEditable = false,
    isEditingInitially = false
  } = props;

  // Re-rendering the entire ReactJSONTree component (using key)
  // seems to be the only way to get shouldExpandNode to always work
  const [{ key, depth }, setJSONTreeState] = useState<{
    key: number;
    depth: number;
  }>({
    key: 0,
    depth: props.depth || 7
  });

  const [isEditing, setIsEditing] = useState<boolean>(isEditingInitially || false);
  const [jsonData, setJSONData] = useState<any>(data);
  const [jsonStringForEditing, setJSONStringForEditing] = useState<string>(JSON.stringify(jsonData, null, 2));
  const [errorParsingJSON, setErrorParsingJSON] = useState<boolean>(false);

  const handleExpandTree = () => {
    const { maxDepth = 20 } = props;

    setJSONTreeState({
      depth: maxDepth,
      key: key + 1
    });
  };

  const handleEdit = () => setIsEditing(true);
  const handleDoneEditing = () => {
    try {
      const jsonData = JSON.parse(jsonStringForEditing);
      setErrorParsingJSON(false);
      setJSONData(jsonData);
      setIsEditing(false);
    } catch (err) {
      console.error(err);
      setErrorParsingJSON(true);
    }
  };

  const showActions = showCopyButton || (showExpandButton && checkDepth(data, depth) > depth);

  return (
    <Root>
      {isEditing ? (
        <div>
          <div className="action-button-group">
            <ButtonGroup>
              {isEditable && (
                <Button ghost type="primary" size="small" onClick={handleDoneEditing}>
                  Done Editing JSON
                </Button>
              )}
            </ButtonGroup>
          </div>

          <Form.Item
            validateStatus={errorParsingJSON ? 'error' : 'success'}
            help={errorParsingJSON && 'Error parsing JSON.'}
          >
            <TextArea
              style={{ minHeight: '44px', padding: '10px 11px' }}
              value={jsonStringForEditing}
              onChange={(e) => {
                setJSONStringForEditing(e.target.value);
              }}
              autoSize
            ></TextArea>
          </Form.Item>
        </div>
      ) : (
        <div className="json-tree-container">
          {showActions && (
            <div className="action-button-group">
              <ButtonGroup>
                {showExpandButton && checkDepth(data, depth) > depth && (
                  <Button ghost onClick={handleExpandTree} type="primary" size="small">
                    Expand all
                  </Button>
                )}

                {showCopyButton && (
                  <CopyToClipboard
                    text={JSON.stringify(data, null, 2)}
                    onCopy={() => message.success(copySuccessMessage || 'Successfully copied to clipboard')}
                  >
                    <Button ghost type="primary" size="small">
                      Copy
                    </Button>
                  </CopyToClipboard>
                )}

                {isEditable && (
                  <Button ghost type="primary" size="small" onClick={handleEdit}>
                    Edit JSON
                  </Button>
                )}
              </ButtonGroup>
            </div>
          )}

          <ReactJSONTree
            key={key}
            data={{ ...jsonData }}
            theme={theme}
            invertTheme={false}
            shouldExpandNode={(keyName, data, level) => level < depth}
            labelRenderer={(path) => {
              return (
                <strong>
                  {onKeyClick ? (
                    <button
                      className="link-button"
                      onClick={(e) => {
                        e.stopPropagation();
                        onKeyClick(path.reverse());
                      }}
                    >
                      {path[0]}
                    </button>
                  ) : (
                    path[0]
                  )}
                  :
                </strong>
              );
            }}
            valueRenderer={(raw, value, ...pathArray) => {
              return (
                <span>
                  {onValueClick ? (
                    <button
                      className="link-button"
                      onClick={() => {
                        onValueClick(value, pathArray.reverse());
                      }}
                    >
                      {raw}
                    </button>
                  ) : (
                    raw
                  )}
                </span>
              );
            }}
          />
        </div>
      )}
    </Root>
  );
}

function checkDepth(obj: any, depthLimit: number) {
  let level = 1;
  let key;

  for (key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    if (typeof obj[key] === 'object') {
      let depth = checkDepth(obj[key], depthLimit) + 1;
      level = Math.max(depth, level);
    }

    if (level > depthLimit) {
      return level;
    }
  }

  return level;
}

export default JSONTree;

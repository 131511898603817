import React from 'react';
import DopeIcon from 'components/ui/DopeIcon';
import { CloudVendorIcon } from 'components/cloudVendors';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { ConfirmationModalState } from '../../../../OpEditor';
import { AutomationEventDefinition, Op } from 'typings';
import { EditorSectionBanner } from 'app/sections/Ops/Editor/EditorSectionBanner';
import { GhostButton } from 'designSystem/GhostButton/GhostButton';

interface TriggerHeaderProps {
  eventDefinition: AutomationEventDefinition | null;
  op: Op | undefined;
  isAllFindingsSelected: boolean;
  editingState: any[];
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
}

function TriggerHeader(props: TriggerHeaderProps) {
  const { eventDefinition, op, setConfirmationModal, editingState } = props,
    [editingTrigger, setEditingTrigger] = editingState,
    icon = eventDefinition ? (
      <CloudVendorIcon vendor={eventDefinition.vendor} />
    ) : (
      <DopeIcon name="TRIGGER" size={20} />
    ),
    existingProjectId = op?.projectId || null,
    authz = useAuthorizeRequiredPermissions({
      requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: existingProjectId || '*' }]
    }),
    isAuthorized = existingProjectId ? authz.isAuthorized : true,
    triggerTitle = editingTrigger ? 'Select Trigger' : eventDefinition?.name;

  function onEditClick(e) {
    e.preventDefault();
    setConfirmationModal({
      title: 'Clear Trigger',
      description: 'This section will be cleared and any filter settings will be lost.',
      onConfirm: () => {
        setEditingTrigger(true);
        setConfirmationModal(null); // close modal
      }
    });
  }

  return (
    <EditorSectionBanner>
      <div>{icon}</div>
      <span data-testid="event-name" className="title">
        {triggerTitle || 'All Findings'}
      </span>
      {isAuthorized && !editingTrigger ? (
        <GhostButton onClick={onEditClick}>change trigger...</GhostButton>
      ) : (
        <GhostButton onClick={() => setEditingTrigger(false)}>cancel change</GhostButton>
      )}
    </EditorSectionBanner>
  );
}

export default TriggerHeader;

import { Badge, Collapse, Icon, Typography } from 'antd';
import Arn from 'aws-arn';
import DateTime from 'components/ui/DateTime';
import DopeIcon from 'components/ui/DopeIcon';
import IpAddress from 'components/ui/IpAddress';
import JSONTree from 'components/ui/JSONTree';
import { getEventDetails, getUserIdentityDetails } from 'components/util/EventDetail';
import gql from 'graphql-tag';
import { PROJECT_DETAIL } from 'queries';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { Event } from 'typings';
import {
  asffToInventoryResourceType,
  getResourceTypeFromKey,
  getResourceUrl,
  ResourceType,
  RESOURCE_TYPES_QUERY
} from 'utilities/inventory';
import EventDetailDescriptions, { objectToSectionItems } from './components/EventDetailDescriptions';
import ErrorBoundary from 'components/ui/ErrorBoundary';

const { Panel } = Collapse;
const { Paragraph, Title } = Typography;

const EVENT_DETAIL_ACCOUNT_BY_ID = gql`
  query account($id: ID!) {
    account(id: $id) {
      account_id
      nickname
      name
      provider
      environment
      labels
    }
  }
`;

const Root = styled.div`
  .ant-page-header-heading-title {
    font-size: 21px;
  }
`;

interface Props {
  event: Event;
}

function EventDescription(props: Props) {
  const { event } = props;
  const { eventData = {} } = event || {};
  const { context, message } = eventData || {};
  const { detail } = message || {};

  const {
    region,
    eventSourceName,
    projectId,
    accountId,
    cloudAccountId,
    product,
    firstObservedAt,
    lastObservedAt,
    createdAt,
    updatedAt,
    dopsSeverity,
    severityName,
    severityPercent,
    severityStatus,
    time,
    configRuleName,
    detailType,
    eventName,
    eventSource,
    sourceIPAddress
  } = getEventDetails(event);

  if (eventData?.context?.awsAccountId !== eventData?.context?.cloudAccountId) {
    eventData.context.awsAccountId = eventData.context.cloudAccountId;
  }

  const { arn: userIdentityArn, principalId, userType, mfaAuthenticated, userName } = getUserIdentityDetails(
    detail?.userIdentity
  );

  // query inventory resource types
  const { data: resourceTypesData } = useQuery(RESOURCE_TYPES_QUERY);
  const resourceTypes: ResourceType[] = resourceTypesData?.resource_types;

  // query the account for the event
  const { data: accountData } = useQuery(EVENT_DETAIL_ACCOUNT_BY_ID, {
    variables: { id: accountId },
    skip: !accountId
  });
  const { account } = accountData || {};

  // query the project for the event
  const { data: projectData } = useQuery(PROJECT_DETAIL, {
    variables: { id: projectId },
    skip: !projectId
  });
  const project = projectData?.project || {};

  // account info to display
  const { nickname: cloudAccountNickName } = account || {};

  // project info to display
  const { name: projectName } = project;

  const { messageType, requestParameters } = detail || {};
  const { resourcesSet } = requestParameters || {};
  const { items } = resourcesSet || {};
  const resourceIds = items?.map((item) => item.resourceId).filter((resourceId) => !!resourceId); // filter out falsy resource IDs

  const { complianceType, resourceChangeType } = context || {};

  // asff event info to display
  const asffEvent = detail || event.eventData?.detail;

  // asff finding info to display
  // TODO: refactor out to collector?
  // TODO: handle more than one finding per event?
  const finding = asffEvent?.findings?.[0];

  // asff finding resource info to display
  // TODO: handle more than one resource per finding?
  const resource = finding?.Resources?.[0] || finding?.resources?.[0];
  const resourceId = resource?.Id || resource?.id || context?.resourceId;
  // determine if the resource is a dops inventory type
  // parse the resourceId to an ARN to get its ID (itemKey)
  // TODO: handle non-aws events/findings/resources?
  const resourceArn = Arn.parse(resourceId);
  const resourceItemKey = resourceArn?.resource?.id || resourceId;
  const resourceType = resource?.Type || resource?.type || context?.resourceType;
  // convert from asff resource type to inventory resource type (cloudformation type for aws resources)
  const inventoryResourceType = asffToInventoryResourceType(resourceType) || resourceType;
  const isInventoryResource =
    resourceTypes &&
    inventoryResourceType &&
    getResourceTypeFromKey(resourceTypes, inventoryResourceType) &&
    accountId &&
    resourceType &&
    resourceItemKey &&
    region;
  const resourceInventoryUrl =
    isInventoryResource &&
    getResourceUrl({ accountId, itemType: inventoryResourceType, itemKey: resourceItemKey, region });
  // extract details from asff resource: $.message.detail.findings[0].Resources[0].Details.${resourceType}
  //                                 ex: $.message.detail.findings[0].Resources[0].Details.AwsIamAccessKey
  const resourceDetails =
    resource &&
    resourceType &&
    (resource.Details?.[resourceType] ||
      resource.details?.[resourceType] ||
      resource.details?.[resourceType.toLowerCase()]);

  const accountLink = accountId && (
    <a href={`/cloud-accounts/${accountId}`} target="_blank" rel="noopener noreferrer">
      <DopeIcon name="CLOUD_ACCOUNT" />{' '}
      {cloudAccountNickName
        ? `${cloudAccountNickName} (${
            eventData?.context?.cloudAccountId ? eventData.context.cloudAccountId : cloudAccountId
          })`
        : cloudAccountId || accountId}{' '}
    </a>
  );

  // define the sections we'd like to display
  // build items for resource details
  const resourceDetailItems = objectToSectionItems(resourceDetails);

  const descriptionSections = [
    {
      title: dopsSeverity ? 'Severity & Location' : 'Location',
      items: [
        {
          label: 'Severity',
          value: dopsSeverity && (
            <span title={`${dopsSeverity} (1-5) ${severityPercent}`}>
              <Badge status={severityStatus} text={severityName} />
            </span>
          )
        },
        { label: 'Source', value: eventSourceName },
        { label: 'Account', value: accountLink },
        {
          label: 'Project',
          value: projectId && (
            <a href={`/projects/${projectId}`} target="_blank" rel="noopener noreferrer">
              <DopeIcon name="PROJECT" /> {projectName || projectId}
            </a>
          )
        },
        { label: 'Region', value: region },
        { label: 'Product', value: product },
        { label: 'Message Type', value: messageType },
        { label: 'Compliance Type', value: complianceType },
        { label: 'Config Rule Name', value: configRuleName }
      ]
    },
    {
      title: 'Resource',
      items: [
        ...(resourceIds?.map((resourceId) => {
          return { label: 'Resource ID', value: resourceId };
        }) || []),
        {
          label: 'Resource ID',
          value: resourceInventoryUrl ? (
            <a
              href={resourceInventoryUrl}
              target="_black"
              rel="noopener noreferrer"
              title={`Search DisruptOps Resource Inventory for "${resourceId}"`}
            >
              {resourceId} <Icon type="search" />
            </a>
          ) : (
            resourceId
          )
        },
        {
          label: 'Resource Type',
          value: resourceType && (
            <>
              {resourceType}{' '}
              {inventoryResourceType && resourceType !== inventoryResourceType && `(${inventoryResourceType})`}
            </>
          )
        },
        { label: 'Change Type', value: resourceChangeType },
        ...resourceDetailItems
      ]
    },
    {
      title: 'User Identity',
      items: [
        { label: 'Type', value: userType },
        { label: 'User Name', value: userName },
        { label: 'Principal ID', value: principalId },
        { label: 'MFA', value: mfaAuthenticated },
        { label: 'ARN', value: userIdentityArn }
      ]
    },
    {
      title: 'Details',
      items: [
        { label: 'Type', value: detailType },
        { label: 'Source', value: eventSource },
        { label: 'Name', value: eventName },
        {
          label: 'IP Address',
          value: sourceIPAddress && <IpAddress ipAddress={sourceIPAddress} />
        }
      ]
    },
    {
      title: 'Timeline',
      items: [
        { label: 'First Observed', value: firstObservedAt && <DateTime dateTime={firstObservedAt} /> },
        { label: 'Last Observed', value: lastObservedAt && <DateTime dateTime={lastObservedAt} /> },
        { label: 'Created At', value: createdAt && <DateTime dateTime={createdAt} /> },
        { label: 'Updated At', value: updatedAt && <DateTime dateTime={updatedAt} /> },
        { label: 'Time', value: !createdAt && time && <DateTime dateTime={time} /> }
      ]
    }
  ];

  return (
    <ErrorBoundary>
      <Root className="event-detail">
        <EventDetailDescriptions hideFalsyItemsAndSections={true} sections={descriptionSections} />

        {eventData && (
          <>
            <Paragraph style={{ marginTop: '16px' }}>
              <Title level={4} style={{ fontSize: '16px', fontWeight: 700 }}>
                Full Event
              </Title>
            </Paragraph>
            {/* 
              collapse if any descriptions were displayed above
              expand if there weren't any descriptions displayed above 
            */}
            <Collapse style={{ marginBottom: '16px' }}>
              <Panel header="Raw Event Details" key="eventData">
                <JSONTree data={eventData} depth={10} />
              </Panel>
            </Collapse>
          </>
        )}
      </Root>
    </ErrorBoundary>
  );
}

export default EventDescription;

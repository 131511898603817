import React, { useContext } from 'react';
import { Assessment, Assessor, AttachedDynamicConfiguration } from 'typings';
import { useHistory } from 'react-router-dom';
import EditFilters from './EditFilters/EditFilters';
import { DetailPanelScollable, DetailPanelScrollableSection } from 'designSystem/DetailPanel/DetailPanel';
import EditorGeneral from './EditorGeneral/EditorGeneral';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { SubmitButtonWrapDiv } from 'designSystem/EditorPanel/EditorPanel.styles';
import { Button, message } from 'antd';
import { useMutation } from 'react-apollo';
import { FunctionParameter } from 'typings';
import { typedPropertyOrDefault } from 'utilities/helpers';
import { cleanFields, generateFormSchema, initializeFunctionParametersFormValues } from './DetectorEditor.helpers';
import { initFormDynamicConfigs, parseFormValuesIntoFunctionConfiguration } from 'utilities/function/formTransforms';
import AuthContext from 'components/app/Auth/AuthContext';
import useQueryString from '../DetectorsCommon/useQueryString/useQueryString';
import { SAVE_DETECTOR_MUTATION, ADD_DETECTOR_MUTATION } from '../DetectorsCommon/DetectorsGql/DetectorsGql';
import useDetectorVariants from '../DetectorsCommon/useDetectorVariants/useDetectorVariants';

const initialValidationRules = {
  name: Yup.string().required('Name is required')
};

export interface Detector extends Assessment {
  dynamicConfiguration: AttachedDynamicConfiguration[];
  assessor: Assessor;
  projectId: string;
}

export interface EditorProps {
  detector: Detector;
  createMode?: boolean;
}

function DetectorEditor(props: EditorProps) {
  const { detector, createMode } = props,
    variants = useDetectorVariants(),
    initialAssessor = detector.assessor,
    history = useHistory(),
    [saveDetector] = useMutation(SAVE_DETECTOR_MUTATION),
    [addDetector] = useMutation(ADD_DETECTOR_MUTATION),
    saveButtonText = createMode ? `Add ${variants.variantName} With This Configuration` : 'Save Changes',
    emptyParameters: FunctionParameter[] = [],
    parameters = typedPropertyOrDefault(detector.assessor.parameters, emptyParameters),
    formSchema = generateFormSchema(parameters, initialValidationRules),
    authContext = useContext(AuthContext),
    defaultProjectId = authContext?.userRootProjectId,
    queryString = useQueryString(),
    { staticConfiguration, dynamicConfiguration } = detector,
    initialStaticConfigs = initializeFunctionParametersFormValues(parameters, staticConfiguration),
    initialValues = {
      id: detector.id,
      name: detector.name,
      labels: detector.labels,
      projectId: createMode ? defaultProjectId : detector.projectId,
      isReadOnly: detector.isReadOnly,
      functionId: detector.functionId,
      staticConfiguration: detector.staticConfiguration,
      targetProjectIds: detector.targetProjectIds || [],
      ...initialStaticConfigs,
      dynamicConfiguration: initFormDynamicConfigs(dynamicConfiguration)
    };

  function onClose() {
    history.push(`${variants.basePath}/${detector.id}${queryString}`);
  }

  async function onSubmit(formValues: any) {
    const operation = createMode ? addDetector : saveDetector,
      baseValues = cleanFields({
        ...formValues,
        ...parseFormValuesIntoFunctionConfiguration(formValues, parameters)
      });

    let result, detectorId: string, variables;

    variables = createMode
      ? {
          input: {
            ...baseValues,
            // assessor: detector.assessor,
            id: undefined,
            assessorId: detector.assessor.id
          }
        }
      : {
          id: formValues.id,
          assessmentInput: {
            ...baseValues
          }
        };

    try {
      result = await operation({ variables });
      detectorId = createMode ? result.data.createAssessment.id : result.data.updateAssessment.id;

      const location = {
        pathname: `${variants.basePath}/${detectorId}`,
        savedDetector: true
      };

      history.push(location);

      message.success(`${variants.variantName} was successfully saved`);
    } catch (e) {
      message.error(`There was an error saving this ${variants.variantName}`, e);
    }
  }

  return (
    <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onSubmit}>
      {(formikProps) => {
        const { isSubmitting, errors } = formikProps;
        return (
          <Form>
            <SubmitButtonWrapDiv className={createMode ? 'fullscreen' : null}>
              <Button
                disabled={Object.keys(errors).length > 0 || isSubmitting}
                type="primary"
                block
                className="square"
                loading={isSubmitting}
                htmlType="submit"
              >
                {saveButtonText}
              </Button>
              <button className="cancel-btn" type="button" onClick={() => onClose()}>
                cancel changes
              </button>
            </SubmitButtonWrapDiv>
            <DetailPanelScollable>
              <DetailPanelScrollableSection title="General">
                <EditorGeneral formikProps={formikProps} initialAssessor={initialAssessor} />
              </DetailPanelScrollableSection>
              <DetailPanelScrollableSection title="Filters">
                <EditFilters parameters={parameters} formikProps={formikProps} />
              </DetailPanelScrollableSection>
            </DetailPanelScollable>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DetectorEditor;

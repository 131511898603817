import CloseButton from 'designSystem/Badge/CloseButton/CloseButton';
import React, { ReactNode, ReactNodeArray } from 'react';
import { EditorPanelDiv } from './EditorPanel.styles';

interface EditorPanelProps {
  children: ReactNode | ReactNodeArray;
  onClose: Function;
  panelTitle: string;
}

function EditorPanel(props: EditorPanelProps) {
  const { children, onClose, panelTitle } = props;

  return (
    <EditorPanelDiv>
      <CloseButton onClose={onClose} />
      <h1 className="editor-heading">{panelTitle}</h1>
      {children}
    </EditorPanelDiv>
  );
}

export default EditorPanel;

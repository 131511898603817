import EventDetail from 'components/events/EventDetail';
import DopeIcon from 'components/ui/DopeIcon';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import QueryResult from 'components/util/QueryResult';
import React from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Location } from 'react-router-dom';
import styled from 'styled-components';
import {
  AUTOMATION_EVENT_DETAIL_QUERY,
  CLASSIFIED_EVENT_DETAIL_QUERY
} from '../../components/ModalsRouter/modals/EventDetailModal/gql';

const Root = styled.div`
  .centered-container {
    padding-bottom: 96px; // to get the intercom button out of the way of the pagination controls
    padding-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

interface Props
  extends RouteComponentProps<{
    eventId: string;
  }> {
  previousLocation: Location;
}

function EventDetailPage(props: Props) {
  const { location, match } = props;
  const {
    params: { eventId }
  } = match;

  const eventFromState =
    location?.state?.event?.eventId === eventId || location?.state?.event?.id === eventId ? location.state.event : null;

  const automationEventResults = useQuery(AUTOMATION_EVENT_DETAIL_QUERY, {
    variables: {
      eventId: eventId
    },
    skip: eventFromState ? true : false
  });

  const classifiedEventResults = useQuery(CLASSIFIED_EVENT_DETAIL_QUERY, {
    variables: {
      eventId: eventId
    },
    skip: eventFromState || automationEventResults?.loading || automationEventResults?.data?.nodes?.[0]
  });

  const event =
    eventFromState ||
    automationEventResults?.data?.automationEvents?.nodes?.find((n) => n.id === eventId) ||
    classifiedEventResults?.data?.classifiedEvents?.nodes?.find((n) => n.id === eventId || n.eventId === eventId);

  const asffEvent = event?.eventData?.message?.detail || event?.eventData?.detail;
  const asffTitle = asffEvent?.findings?.[0].Title || asffEvent?.findings?.[0].title;

  const name = asffTitle || event?.eventDefinitionName || 'Event Details';

  // don't set data until both have finished loading
  const data =
    !automationEventResults?.loading &&
    !classifiedEventResults?.loading &&
    (automationEventResults?.data || classifiedEventResults?.data);

  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            icon={<DopeIcon name="EVENT" size={20} />}
            sectionTitle="Events"
            sectionTitleLinkTo="/events"
            backLinkTo="/events"
            backLinkTooltipText="Back to events"
            title={name}
          />
        }
      >
        <ErrorBoundary>
          <CenteredContainer>
            {event ? (
              <EventDetail event={event} />
            ) : (
              <QueryResult
                data={data}
                loading={automationEventResults?.loading || classifiedEventResults?.loading}
                error={automationEventResults?.error || classifiedEventResults?.error}
              >
                {() => {
                  return <EventDetail event={event} />;
                }}
              </QueryResult>
            )}
          </CenteredContainer>
        </ErrorBoundary>
      </NeoPage>
    </Root>
  );
}

export default EventDetailPage;

import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';

import { ExistingNotificationRecipient } from 'typings';
import QueryResult from 'components/util/QueryResult';
import RECIPIENT_LIST_QUERY from 'queries/recipientListQuery';
import { useHistory } from 'react-router-dom';
import { recipientListTypes, DEFAULT_PROJECT_UUID } from './RecipientSelectListTypes';
import DopeIcon from '../ui/DopeIcon';

interface RecipientDisplayProps {
  recipientId?: string;
  addProjectDefault?: boolean;
  type?: string[] | undefined;
  onSelect: (recipientId?: string) => void;
  onClear: () => void;
  canModify?: boolean;
  canDelete?: boolean;
  onDelete?: () => void;
}

export default function RecipientSelection(props: RecipientDisplayProps) {
  const { type, onSelect, recipientId, addProjectDefault = false } = props;

  if (recipientId === DEFAULT_PROJECT_UUID) {
    const recipient = {
      type: 'PROJECT',
      id: DEFAULT_PROJECT_UUID
    };
    return RecipeientListItem(recipient, props);
  } else if (recipientId) {
    return QueryRecipienetItem(props);
  } else return <SelectRecipient onSelect={onSelect} type={type} addProjectDefault={addProjectDefault} />;
}

function QueryRecipienetItem(props: RecipientDisplayProps) {
  const { recipientId } = props;

  const { loading: recipientsLoading, error, data: recipientData } = useQuery(RECIPIENT_LIST_QUERY, {
    variables: { id: recipientId }
  });

  return (
    <QueryResult
      loading={recipientsLoading}
      error={error}
      data={recipientData}
      dataCheck={false}
      parseNodes={(data) => data?.recipients?.items}
      expectSingleNode
      loadingCenterVertically={false}
      loadingHeight={70}
    >
      {(recipient: ExistingNotificationRecipient) => {
        return RecipeientListItem(recipient, props);
      }}
    </QueryResult>
  );
}

function RecipeientListItem(recipient, props: RecipientDisplayProps) {
  const { onClear, canModify = true, canDelete, onDelete } = props;

  const ListItem = recipientListTypes[recipient.type];

  return (
    ListItem && (
      <ListItem
        recipient={recipient}
        action={
          <>
            <Button disabled={!canModify} onClick={() => onClear()}>
              {'Select different Recipient'}
            </Button>
            {canDelete && onDelete && (
              <>
                &nbsp;
                <Button onClick={() => onDelete()} className="btn-with-icon" size="large">
                  {<DopeIcon name="DELETE" size={20} />}
                </Button>
              </>
            )}
          </>
        }
      />
    )
  );
}

const SelectRecipientRoot = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

interface SelectRecipientProps {
  type?: string[];
  addProjectDefault: boolean;
  onSelect: (recipientId: string) => void;
}

function SelectRecipient(props: SelectRecipientProps) {
  const { onSelect, type, addProjectDefault } = props;
  const history = useHistory();

  const variables = type ? { variables: { type: type } } : {};
  const { loading: recipientsLoading, error, data: recipientData } = useQuery(RECIPIENT_LIST_QUERY, variables);

  return (
    <QueryResult
      loading={recipientsLoading}
      error={error}
      data={recipientData}
      parseNodes={(data) => data?.recipients?.items || []}
      loadingCenterVertically={false}
      loadingHeight={300}
    >
      {(recipients: ExistingNotificationRecipient[]) => {
        if (addProjectDefault) {
          recipients = [
            {
              type: 'PROJECT',
              id: DEFAULT_PROJECT_UUID
            },
            ...recipients
          ];
        }

        return (
          <SelectRecipientRoot>
            {recipients.map((recipient) => {
              const ListItem = recipientListTypes[recipient.type];

              return (
                ListItem && (
                  <ListItem
                    key={recipient.id}
                    recipient={recipient}
                    action={
                      <Button onClick={() => onSelect(recipient.id)} type="primary">
                        {'Use this Recipient'}
                      </Button>
                    }
                  />
                )
              );
            })}
            {
              <AddRecipientRoot
                onClick={async () => {
                  history.push(`/organization-settings/notifications/recipients`);
                }}
              >
                <div className="init-connection-label">{'+ Add Recipient'}</div>
              </AddRecipientRoot>
            }
          </SelectRecipientRoot>
        );
      }}
    </QueryResult>
  );
}

const AddRecipientRoot = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }

  .init-connection-label {
    color: ${(p) => p.theme.primary};
    font-weight: 600;
  }
`;

import { useState, useEffect } from 'react';

function debounce(fn) {
  let timer;

  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn();
    }, 300);
  };
}

function useTableHeight(ref) {
  const [tableHeight, setTableHeight] = useState(200);

  function handleResize() {
    const topOffset = ref.current?.getBoundingClientRect().top || 0,
      headroom = 140,
      height = window.innerHeight,
      y = height - headroom - topOffset;

    setTableHeight(y);
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize);
    window.addEventListener('resize', debouncedHandleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return tableHeight;
}

export function useFixedHeight(ref, headroom) {
  const [height, setHeight] = useState(0);

  function handleResize() {
    const topOffset = ref.current?.getBoundingClientRect().top || 0,
      y = window.innerHeight - headroom - topOffset;

    setHeight(y);
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize);
    window.addEventListener('resize', debouncedHandleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return height;
}

export default useTableHeight;

import styled from 'styled-components';

export const DetectorsPage = styled.div`
  .valign-center {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;

    > * {
      display: block;
      margin-left: 1rem;
      margin-bottom: 0;
    }

    > *:first-child {
      margin-left: 0;
    }
  }

  .create-enter {
    opacity: 0.01;
    transform: translateY(-4rem);
  }

  .create-enter.create-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 200ms ease-out, transform 200ms ease-out;
  }

  .create-leave {
    opacity: 1;
  }

  .create-leave.create-leave-active {
    opacity: 0.01;
    transform: translateY(-4rem);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
`;

import React, { SyntheticEvent, useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Collapse, Icon, Typography, Button, message, Pagination } from 'antd';

import NeoPage, { TitleBar, CenteredContainer } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import PageHeader from 'components/ui/PageHeader';
import QueryResult from 'components/util/QueryResult';
import { FilterItem, FilterRow } from 'components/app/FilterBar/components';
import { LabelsFilter, SearchFilter } from 'components/app/FilterBar/filters';
import Markdown from 'components/ui/Markdown';
import usePaginator from 'components/app/Paginator/usePaginator';
import { CloudVendorIcon } from 'components/cloudVendors';
import { CloudVendor } from 'typings';
import ProjectOwnershipModal from 'components/project/ProjectOwnershipModal';
import { Permissions } from '@disruptops/neo-core';

interface AssessorFromQuery {
  id: string;
  cloudVendor: CloudVendor;
  name: string;
  key: string;
  description: string;
  overview: string;
  labels: string[];
}

const ASSESSORS_QUERY = gql`
  query Assessors($pageNumber: Int!, $pageSize: Int!, $search: String, $labels: [String]) {
    assessors(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: "name"
      sortDirection: ASC
      search: $search
      labels: $labels
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        id
        name
        key
        labels
        cloudVendor
        description
        overview
      }
    }
  }
`;

const Root = styled.div`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 40px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 24px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 24px 16px 16px 40px;
  }
`;

const AssessorCardHeaderRoot = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: no-wrap;
  .assessment-icon-wrap {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .assessor-card-header-title {
    font-size: 16px;
    padding-right: 16px;

    flex: 1;
  }

  .assessor-card-header-desc {
    flex: 2;
    padding-right: 16px;
  }
`;

const UNIQUE_ASSESSOR_LABELS = gql`
  query AssessmentLabelsForAddAssessment {
    uniqueAssessorLabels
  }
`;

const ADD_ASSESSMENT_MUTATION = gql`
  mutation AddAssessment($input: AssessmentCreateInput!) {
    createAssessment(assessmentInput: $input) {
      id
    }
  }
`;

function AddAssessment(props: RouteComponentProps) {
  const { history } = props;

  const { pageNumber, pageSize, search, filters, updateSearch, replaceFilter, updatePagination } = usePaginator();

  const { networkStatus, error, data } = useQuery(ASSESSORS_QUERY, {
    variables: {
      search,
      labels: filters.labels,
      pageNumber,
      pageSize
    }
  });

  const [assessorId, setAssessorId] = useState<string | undefined>();
  const [createAssessment, { loading: isAddingAssessment }] = useMutation(ADD_ASSESSMENT_MUTATION);

  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            sectionTitle="Detectors"
            icon={<DopeIcon name="ASSESSMENT" size="20" />}
            sectionTitleLinkTo={'/detectors'}
            title="Add Detectors"
            backLinkTo={`/detectors`}
            backLinkTooltipText="Back to Detectors"
          />
        }
      >
        <CenteredContainer size="lg">
          <PageHeader title={'Select Detector'} titleLevel={2} />

          <FilterRow>
            <FilterItem label="Search">
              <SearchFilter search={search || ''} onSearchChange={updateSearch} />
            </FilterItem>

            <FilterItem label="Labels">
              <LabelsFilter
                value={(Array.isArray(filters.labels) && filters.labels) || []}
                query={UNIQUE_ASSESSOR_LABELS}
                getLabelsRootFromQueryResult={(data) => data && data.uniqueAssessorLabels}
                onChange={(value) => {
                  replaceFilter('labels', value);
                }}
              />
            </FilterItem>
          </FilterRow>

          <QueryResult
            loading={networkStatus <= 2}
            data={networkStatus <= 2 ? null : data}
            error={error}
            parseNodes={(data) => data?.assessors?.nodes}
          >
            {(assessors: AssessorFromQuery[]) => (
              <Collapse
                bordered={false}
                expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
              >
                {assessors.map((assessor) => {
                  const handleAddAssessor = async (e: SyntheticEvent) => {
                    e.stopPropagation();
                    setAssessorId(assessor.id);
                  };

                  return (
                    <Collapse.Panel
                      key={assessor.id}
                      header={
                        <AssessorCardHeaderRoot>
                          <div className="assessment-icon-wrap">
                            <CloudVendorIcon vendor={assessor.cloudVendor} />
                          </div>

                          <Typography.Title level={4} className="assessor-card-header-title">
                            {assessor.name}
                          </Typography.Title>

                          <Typography.Text className="assessor-card-header-desc">
                            {assessor.description}
                          </Typography.Text>

                          <Button onClick={handleAddAssessor} loading={isAddingAssessment}>
                            Add
                          </Button>
                        </AssessorCardHeaderRoot>
                      }
                      style={{
                        backgroundColor: '#fff',
                        borderRadius: '4px',
                        marginBottom: '16px',
                        border: '1px solid #ddd'
                      }}
                    >
                      <Markdown source={assessor.overview || ''} />

                      <Button type={'primary'} loading={isAddingAssessment} onClick={handleAddAssessor}>
                        {'Add Detector'}
                      </Button>
                    </Collapse.Panel>
                  );
                })}
              </Collapse>
            )}
          </QueryResult>

          <Pagination
            current={pageNumber}
            pageSize={pageSize}
            total={data?.assessors?.pageInfo?.total}
            onChange={(page) => updatePagination({ pageNumber: page })}
            style={{ float: 'right' }}
          />
        </CenteredContainer>
      </NeoPage>

      <ProjectOwnershipModal
        title="Set Detector Ownership"
        visible={assessorId ? true : false}
        requireSelection={false}
        loading={isAddingAssessment}
        requiredPermissions={Permissions.MODIFY_ASSESSMENTS}
        permissionsExplanations="Only users with permissions to this project or a parent project can view (View Detector) or change (Modify Detector) this Detector. Projects which you do not have Modify Detector for are disabled."
        onChange={async (projectId) => {
          const variables = {
            input: {
              assessorId: assessorId,
              projectId: projectId,
              isEnabled: false
            }
          };

          try {
            const result = await createAssessment({
              variables
            });
            const newAssessment: { id: string } = result.data.createAssessment;

            message.success('Detector was successfully created');

            history.push(`/detectors/${newAssessment.id}`);
          } catch (e) {
            message.error('There was an error adding this detector');
          }
        }}
        onCancel={() => {
          setAssessorId(undefined);
        }}
      />
    </Root>
  );
}

export default AddAssessment;

import styled from 'styled-components';

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 18px 24px;
  > * {
    margin-left: 16px;
  }
`;

export default ModalFooter;

import React from 'react';
import * as Yup from 'yup';
import { Radio, InputNumber } from 'antd';

import { FormField } from 'components/ui/Form';
import DopeIcon from 'components/ui/DopeIcon';
import { SlackRecipientControl } from 'components/slack';

import { OpDecisionIntegrationDefinition } from './index';

const RadioGroup = Radio.Group;

const WAIT_FOR_USER_RESPONSE = 'WAIT_FOR_USER_RESPONSE';

const SLACK_DECISION_INTEGRATION_DEFINITION: OpDecisionIntegrationDefinition = {
  key: 'SLACK',
  name: 'Slack',
  title: 'Control with Slack message',
  description: 'Control the execution of actions via a Slack message to a channel of your choosing.',
  icon: <DopeIcon name="SLACK" className="icon" />,
  formComponent: SlackDecisionForm,
  validationShape: {
    decisionType: Yup.string().required('Decision type is required'),
    decisionExpiration: Yup.number().integer(), // optional?
    decisionRecipientId: Yup.string().required('Recipient is required')
  },
  defaultValues: {
    decisionType: WAIT_FOR_USER_RESPONSE
  }
};

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px'
};

function SlackDecisionForm() {
  // Description: to be used as form component for slack decision integration definition.
  return (
    <>
      <FormField name="decisionRecipientId" label="Recipient">
        {(fieldRenderProps) => {
          const {
            value,
            name,
            formikContext: { setFieldValue, setFieldTouched }
          } = fieldRenderProps;

          return (
            <SlackRecipientControl
              recipientId={value}
              onClear={() => {
                setFieldTouched(name, false);
                setFieldValue(name, undefined);
              }}
              onSelect={(recipientId) => {
                setFieldValue(name, recipientId);
                setTimeout(() => setFieldTouched(name, true), 1);
              }}
            />
          );
        }}
      </FormField>

      <FormField name="decisionType" label="Timing">
        {({ value, name, handleChange }) => {
          return (
            <RadioGroup name={name} value={value} onChange={handleChange}>
              <Radio style={radioStyle} value={WAIT_FOR_USER_RESPONSE}>
                {'Wait for user response. Op will NOT proceed and take action without user response via Slack Message.'}
              </Radio>
            </RadioGroup>
          );
        }}
      </FormField>

      <FormField
        name="decisionExpiration"
        label="Expiration (minutes)"
        extra="Cancel all actions if no decision is made after alotted time post trigger event."
      >
        {({ name, value, handleChange, handleBlur }) => {
          return <InputNumber name={name} value={value} onChange={handleChange} onBlur={handleBlur} />;
        }}
      </FormField>
    </>
  );
}

export default SLACK_DECISION_INTEGRATION_DEFINITION;

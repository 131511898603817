import React from 'react';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { ButtonProps } from 'antd/es/button';
import ButtonWithDisabledTooltip from 'components/buttons/ButtonWithDisabledTooltip';

function AddTeamMemberButton(props: ButtonProps) {
  const { ...buttonProps } = props;
  const { isAuthorized, message } = useAuthorizor(Permissions.MODIFY_USERS, []);

  return (
    <ButtonWithDisabledTooltip
      {...buttonProps}
      disabled={!isAuthorized}
      disabledMessage={message || ''}
      tooltipPlacement="left"
    >
      Add team member
    </ButtonWithDisabledTooltip>
  );
}

export default AddTeamMemberButton;

import { CloudVendorIcon } from 'components/cloudVendors';
import React from 'react';
import { Op } from 'typings';
import { VendorLabelRow } from '../../OpsCommon/VendorLabelRow.styles';
import DefaultActionDetail from './DefaultActionDetail/DefaultActionDetail';
import DisruptOpsAlert from './DisruptOpsAlert/DisruptOpsAlert';
import { Link } from 'react-router-dom';

interface ComponentProps {
  op: Op;
  contextData?: any;
  editLink: string;
}

function OpDetailAction(props: ComponentProps) {
  const { op, contextData, editLink } = props,
    indexedConfigs: object | undefined = op.configuration?.reduce((prev, current) => {
      prev[current.key] = current;
      return prev;
    }, {});

  let icon, detail;

  if (op.function) {
    icon = <CloudVendorIcon vendor={op.function.cloudVendor} size={20} />;
  } else {
    return (
      <>
        No Action has been configured. <Link to={editLink}>Configure Op</Link>
      </>
    );
  }

  if (op.function?.id === '83e14127-9f64-46be-9ca7-c17ac74f34fc') {
    detail = <DisruptOpsAlert op={op} indexedConfigs={indexedConfigs} contextData={contextData} />;
  } else {
    detail = (
      <DefaultActionDetail
        parameters={op.function?.parameters}
        indexedConfigs={indexedConfigs}
        contextData={contextData}
      />
    );
  }

  return (
    <>
      <VendorLabelRow>
        <span className="vendor-icon">{icon}</span>
        {op.function?.name}
      </VendorLabelRow>
      <div className="action-output">{detail}</div>
    </>
  );
}

export default OpDetailAction;

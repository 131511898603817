import React, { useState } from 'react';
import { Button, Popover, Input, Icon, Tag } from 'antd';
import gql from 'graphql-tag';
import validator from 'services/validator';

import styled from 'styled-components';
import DopeIcon from 'components/ui/DopeIcon';
import Form from 'components/ui/Form';

import { getIn, FormikActions, FormikValues } from 'formik';

import { Query } from 'react-apollo';
import { useMutation } from '@apollo/react-hooks';
import QueryResult from 'components/util/QueryResult';

const UNIQUE_ASSESSMENT_LABELS = gql`
  query UniqueAssessmentLabels {
    uniqueAssessmentLabels
  }
`;

const UPDATE_ASSESSMENT_LABELS = gql`
  mutation UpdateAssessmentLabels($id: ID!, $assessmentInput: AssessmentUpdateInput) {
    updateAssessment(id: $id, assessmentInput: $assessmentInput) {
      id
      labels
      __typename
    }
  }
`;

const InputRoot = styled.div`
  width: 300px;

  .existing-labels {
    padding-bottom: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .labels-list {
    max-height: 400px;
    overflow-y: auto;
  }

  .labels-search-wrapper,
  .new-labels-preview {
    margin-bottom: 4px;
  }
`;

const LabelRowRoot = styled.div`
  padding: 0 8px;
  cursor: pointer;
  line-height: 2em;

  &.assessor {
    background-color: #e6f7ff;
    color: #1890ff;

    &:hover {
      background-color: #91d5ff;
    }
  }

  &.applied {
    background-color: ${p => p.theme.grey200};

    &:hover {
      background-color: ${p => p.theme.grey300};
    }
  }

  &:hover {
    background-color: ${p => p.theme.grey300};
  }
`;

interface LabelRowProps {
  label: string;
  applied: boolean;
  assessor: boolean;
  onClick: () => void;
}

function LabelRow(props: LabelRowProps) {
  const { label, applied, assessor, onClick } = props;

  let rootClasses = 'label-row';
  if (assessor) rootClasses += ' assessor';
  else if (applied) rootClasses += ' applied';

  return (
    <LabelRowRoot onClick={onClick} className={rootClasses}>
      {label}
    </LabelRowRoot>
  );
}

const schema = validator.object().shape({
  search: validator.string().min(2, 'Search Must be ')
});

interface Props {
  assessmentId: string;
  appliedLabels?: string[] | null;
  assessorLabels?: string[] | null;
}

function AddAssessmentLabelsButton(props: Props) {
  const { assessmentId } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [updateAssessment] = useMutation(UPDATE_ASSESSMENT_LABELS);

  const appliedLabels = props.appliedLabels || [];
  const assessorLabels = props.assessorLabels || [];

  const addRemoveLabel = async (label: string, applied: boolean) => {
    const newLabels = applied ? [...appliedLabels, label] : appliedLabels.filter(l => l !== label);
    const uniqueLabels = Array.from(new Set(newLabels));

    await updateAssessment({
      variables: {
        id: props.assessmentId,
        assessmentInput: {
          labels: uniqueLabels
        }
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateAssessment: {
          id: assessmentId,
          labels: uniqueLabels,
          __typename: 'Assessment'
        }
      }
    });

    return uniqueLabels;
  };

  return (
    <Form
      className="assessment-labels-form"
      initialValues={{
        search: '',
        labels: appliedLabels
      }}
      validationSchema={schema}
      onSubmit={async (values: FormikValues, actions: FormikActions<any>) => {
        const { search } = values;

        const newLabels = await addRemoveLabel(search, true);

        actions.resetForm({
          search: '',
          labels: newLabels
        });

        setVisible(false);
      }}
    >
      {formRenderProps => {
        const { values, errors, setFieldValue, setFieldTouched, submitForm, resetForm } = formRenderProps;
        const search = getIn(values, 'search');
        const labels = getIn(values, 'labels'); // shouldn't change?

        return (
          <Popover
            visible={visible}
            onVisibleChange={visible => {
              if (visible) {
                setVisible(true);
              } else {
                setVisible(false);

                resetForm({
                  search: '',
                  labels: appliedLabels
                });
              }
            }}
            trigger="click"
            placement="leftTop"
            content={
              <InputRoot>
                <Query query={UNIQUE_ASSESSMENT_LABELS}>
                  {({ loading, data, error }) => (
                    <QueryResult loading={loading} data={data} error={error}>
                      {() => {
                        const allLabels: string[] = data.uniqueAssessmentLabels || [];
                        const validNewLabel = search && !errors.search && !allLabels.includes(search) ? search : null;
                        const { existingLabels, unusedLabels } = allLabels
                          .filter(label => {
                            if (!search) return true;

                            const searchLower = search.toLowerCase();
                            const labelLower = label.toLowerCase();

                            return labelLower.includes(searchLower);
                          })
                          .reduce(
                            (
                              acc: {
                                existingLabels: string[];
                                unusedLabels: string[];
                              },
                              l
                            ) => {
                              if ((labels && labels.includes(l)) || (assessorLabels && assessorLabels.includes(l))) {
                                acc.existingLabels.push(l);
                              } else {
                                acc.unusedLabels.push(l);
                              }

                              return acc;
                            },
                            {
                              existingLabels: [],
                              unusedLabels: []
                            }
                          );

                        // appliedLabels

                        return (
                          <>
                            <div className="labels-search-wrapper">
                              <Input
                                suffix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                title="Add or Remove Labels"
                                value={search}
                                onChange={e => {
                                  if (e && e.target) {
                                    setFieldValue('search', e.target.value);
                                  }
                                }}
                                onBlur={() => {
                                  setFieldTouched('search', true);
                                }}
                                onPressEnter={async () => {
                                  if (!validNewLabel) return;

                                  submitForm();
                                }}
                              />
                            </div>

                            {validNewLabel && (
                              <div className="new-labels-preview">
                                <div className="preview-title">{'"Enter" to create new label'}</div>
                                <Tag>{validNewLabel}</Tag>
                              </div>
                            )}

                            <div className="labels-list">
                              {existingLabels && existingLabels.length > 0 && (
                                <div className="existing-labels">
                                  {existingLabels.map((label, idx) => {
                                    const isAssessorLabel = assessorLabels && assessorLabels.includes(label);
                                    const applied = Boolean((appliedLabels && appliedLabels.includes(label)) || isAssessorLabel);

                                    return (
                                      <LabelRow
                                        key={`${label}_${idx}`}
                                        label={label}
                                        applied={applied}
                                        assessor={isAssessorLabel}
                                        onClick={() => addRemoveLabel(label, !applied)}
                                      />
                                    );
                                  })}
                                </div>
                              )}

                              {unusedLabels && unusedLabels.length > 0 && (
                                <div className="filtered-labels">
                                  {unusedLabels.map((label, idx) => {
                                    const isAssessorLabel = assessorLabels && assessorLabels.includes(label);
                                    const applied = Boolean((appliedLabels && appliedLabels.includes(label)) || isAssessorLabel);

                                    return (
                                      <LabelRow
                                        key={`${label}_${idx}`}
                                        label={label}
                                        applied={applied}
                                        assessor={isAssessorLabel}
                                        onClick={() => addRemoveLabel(label, !applied)}
                                      />
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          </>
                        );
                      }}
                    </QueryResult>
                  )}
                </Query>
              </InputRoot>
            }
          >
            <Button
              className="btn-with-icon"
              size={'small'}
              type="ghost"
              onClick={e => {
                setVisible(true);
              }}
            >
              <DopeIcon name="EDIT" />
              {'Edit Labels'}
            </Button>
          </Popover>
        );
      }}
    </Form>
  );
}

export default AddAssessmentLabelsButton;

import { FormatRecipient } from 'app/sections/Ops/OpsCommon/RecipientFormat/RecipientFormat';
import React from 'react';

interface ComponentProps {
  indexedConfigs: any;
  recipients: any;
}

interface ButtonConfig {
  property: string;
  label: string;
}

function AlertButtons(props: ComponentProps) {
  const { indexedConfigs, recipients } = props,
    buttonConfigs: ButtonConfig[] = [
      {
        property: 'includeCreateOpButton',
        label: 'Automate future responses to this event'
      },
      { property: 'includeDismissButton', label: 'Dismiss from channel' },
      { property: 'includeCreateTicketButton', label: 'Create Ticket' },
      { property: 'includeRecommendedActionsButton', label: 'Recommended Actions' },
      { property: 'includeMoreActions', label: 'More Actions' }
    ],
    buttonTypes = buttonConfigs.map((buttonConfig) => {
      if (buttonConfig.property === 'includeCreateTicketButton' && indexedConfigs[buttonConfig.property].value) {
        const recipientId = indexedConfigs.ticketRecipientId.value;

        return (
          <li key={buttonConfig.property}>
            {buttonConfig.label}: <FormatRecipient recipientId={recipientId} recipients={recipients} />
          </li>
        );
      } else if (indexedConfigs[buttonConfig.property].value) {
        return <li key={buttonConfig.property}>{buttonConfig.label}</li>;
      }

      return null;
    });

  return <ul className="no-bullets">{buttonTypes}</ul>;
}

export default AlertButtons;

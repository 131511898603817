import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Location } from 'react-router-dom';
import { Modal, Alert, Typography } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import EventDetail from 'components/events/EventDetail';
import { useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import { AUTOMATION_EVENT_DETAIL_QUERY, CLASSIFIED_EVENT_DETAIL_QUERY } from './gql';

const NoAlertEventWraper = styled.div`
  padding: 64px 24px 24px;
`;

const baseModalProps: ModalProps = {
  footer: false,
  visible: true,
  width: 1200,
  style: {
    maxWidth: '90%'
  }
};

interface Props
  extends RouteComponentProps<{
    eventId: string;
  }> {
  previousLocation: Location;
}

function EventDetailModal(props: Props) {
  const { location, match } = props;
  const {
    params: { eventId }
  } = match;

  const eventFromState = location.state && location.state.event ? location.state.event : null;

  const automationEventDetailResults = useQuery(AUTOMATION_EVENT_DETAIL_QUERY, {
    variables: {
      eventId
    },
    skip: eventFromState ? true : false
  });

  const classifiedEventDetailResults = useQuery(CLASSIFIED_EVENT_DETAIL_QUERY, {
    variables: {
      eventId
    },
    skip: eventFromState || automationEventDetailResults.loading || automationEventDetailResults.data?.nodes?.[0]
  });

  const event =
    eventFromState ||
    automationEventDetailResults.data?.automationEvents?.nodes[0] ||
    classifiedEventDetailResults.data?.automationEvents?.nodes[0];

  if (!event) {
    return (
      <NoAlertEventWraper>
        <Alert
          type="warning"
          message="No matching Event found"
          description={<Typography>{`There was no Event with the ID: "${eventId}" found.`}</Typography>}
        />
      </NoAlertEventWraper>
    );
  }

  const handleCloseModal = () => {
    const { location, history, previousLocation } = props;

    location.state && location.state.onModalClose
      ? history.push(location.state.onModalClose)
      : history.push(previousLocation);
  };

  return (
    <Modal {...baseModalProps} onCancel={handleCloseModal}>
      {event ? (
        <EventDetail event={event} />
      ) : (
        <QueryResult
          data={automationEventDetailResults.data || classifiedEventDetailResults.data}
          loading={automationEventDetailResults.loading || classifiedEventDetailResults.loading}
          error={automationEventDetailResults.error || classifiedEventDetailResults.error}
        >
          {() => {
            return <EventDetail event={event} />;
          }}
        </QueryResult>
      )}
    </Modal>
  );
}

export default EventDetailModal;

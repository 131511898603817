import { SlackConfig } from 'components/slack/SlackContext';

const SLACK_BASE_URL = 'https://slack.com';

const SLACK_AUTHORIZE_URL = `${SLACK_BASE_URL}/oauth/authorize`;

const AUTH_TIMEOUT = 10 * 60 * 1000;

export function getSlackAuthorizationRedirectURI() {
  return `${window.location.origin}/authenticate-slack-target`;
}

interface AuthorizeOptions {
  incomingWebhook?: boolean;
  config?: SlackConfig;
}

interface SlackIncomingWebhook {
  channel?: string;
  url?: string;
}

export interface SlackAuthorizedData {
  incoming_webhook?: SlackIncomingWebhook;
  team_name?: string;
  source?: string;
}

export function authorize(options: AuthorizeOptions = {}): Promise<SlackAuthorizedData> {
  const { incomingWebhook = false, config = {} as SlackConfig } = options;

  const configScopes = (config && config.scopes) || [];

  const scopes = incomingWebhook ? [...configScopes, 'incoming-webhook'] : [...configScopes];

  const redirectURI = getSlackAuthorizationRedirectURI();
  const qs = new URLSearchParams();

  if (config.clientId) qs.append('client_id', config.clientId);
  qs.append('scope', scopes.join(','));
  qs.append('redirect_uri', redirectURI);

  const slackAuthURL = `${SLACK_AUTHORIZE_URL}?${qs.toString()}`;
  const windowName = 'SLACK_AUTH';

  return new Promise((resolve, reject) => {
    const childWindow = window.open(slackAuthURL, windowName, 'centerscreen,chrome=yes,height=740,width=740');

    const handleTimeout = () => {
      cleanup();
      reject(new Error(`Slack Authorization timed out after ${AUTH_TIMEOUT}ms.`));
    };

    const timeoutId = setTimeout(handleTimeout, AUTH_TIMEOUT);

    const handleMessage = messageEvent => {
      clearTimeout(timeoutId);

      if (messageEvent.data && typeof messageEvent.data === 'string') {
        try {
          const data: SlackAuthorizedData = JSON.parse(messageEvent.data);
          if (data && data.source && data.source === 'slack-auth') {
            cleanup();
            resolve(data);
          }
        } catch (e) {
          return reject(e);
        }
      }
    };

    //non-arrow function because the recursion here requires
    //one of these functions to be declared after it is used
    function cleanup() {
      if (childWindow) childWindow.close();
      window.removeEventListener('message', handleMessage);
    }

    window.addEventListener('message', handleMessage);
  });
}

export function parseVerificationCode(search: string): string | null {
  const qs = new URLSearchParams(search);

  const code = qs.get('code');

  return code;
}

// export function exchangeCodeForAccessToken(code: string, config: SlackConfig) {
//   const redirectURI = getSlackAuthorizationRedirectURI();

//   const formData = new FormData();
//   formData.append('client_id', config.clientId);
//   formData.append('client_secret', config.clientSecret);
//   formData.append('redirect_uri', redirectURI);
//   formData.append('code', code);

//   return axios(`${SLACK_BASE_URL}/api/oauth.access`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/x-www-form-urlencoded'
//     },
//     data: formData
//   });
// }

import React from 'react';
import styled from 'styled-components';
import { Function, Issue, CloudAccount } from 'typings';
import { FormikValues } from 'formik';
import * as Yup from 'yup';
import { Button } from 'antd';
import { ModalFooter, ModalHeader, ModalBody } from 'components/ui/Modal';
import { Form, FormGroup } from 'components/ui/Form';
import DopeIcon from 'components/ui/DopeIcon';
import TaskParameterInputSet, { buildOpSetShape } from 'components/function/FunctionParameterInputSet';
import DynamicConfigProvider from 'components/dynamicConfig/DynamicConfigProvider';

const Root = styled.div`
  .action-meta {
    margin-bottom: 32px;
  }
`;

interface Props {
  actionTask: Function;
  issues: Issue[];
  account: CloudAccount;
  savedFormResults: any;
  onSubmit: (values: FormikValues) => void;
  onBack: () => any;
  onCancel?: () => any;
}

function ConfigureAction(props: Props) {
  const { actionTask, issues, savedFormResults = {}, onSubmit, onCancel, onBack } = props;

  const defaultValues = actionTask.parameters
    ? actionTask.parameters.reduce((acc, p) => {
        if (p.defaultValue !== null) acc[p.key] = p.defaultValue;

        return acc;
      }, {})
    : {};

  const initialValues = {
    ...defaultValues,
    ...savedFormResults
  };

  const taskSetShape = actionTask.parameters ? buildOpSetShape(actionTask.parameters, true) : {};

  const schema = Yup.object().shape({
    ...taskSetShape
    // add in delayed action stuff;
  });

  return (
    <DynamicConfigProvider>
      {() => (
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            onSubmit(values);
          }}
          allowCleanSubmits
          validationSchema={schema}
        >
          {formRenderProps => {
            const { canSubmit } = formRenderProps;

            return (
              <Root>
                <ModalHeader title="Configure action" />

                <ModalBody>
                  {actionTask.parameters && actionTask.parameters.length > 0 && (
                    <>
                      <p>
                        Configure the action <b>{actionTask.name} </b>
                        {issues && issues.length === 1 && (
                          <>
                            to take on the issue <b>{issues[0].name}</b>
                          </>
                        )}
                      </p>
                      <FormGroup>
                        <TaskParameterInputSet
                          {...formRenderProps}
                          showDynamicConfigOptions
                          parameters={actionTask.parameters}
                        />
                      </FormGroup>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  {onCancel && <Button onClick={onCancel}>Cancel</Button>}
                  <Button className="btn-with-icon" onClick={onBack}>
                    <DopeIcon style={{ marginTop: '-2px' }} name="ARROW_BACK" />
                    Back
                  </Button>
                  <Button htmlType="submit" type="primary" disabled={!canSubmit}>
                    Review
                  </Button>
                </ModalFooter>
              </Root>
            );
          }}
        </Form>
      )}
    </DynamicConfigProvider>
  );
}

export default ConfigureAction;

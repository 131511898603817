import { Anchor, BackTop, Col, Row, Spin } from 'ant4x';
import ErrorAlert from 'components/ui/ErrorAlert';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import EventCountByAccount from './EventCountByAccount';
import EventCountByAccountLineChart from './EventCountByAccountLineChart';
import EventCountByName from './EventCountByName';
import EventCountByRegion from './EventCountByRegion';
import EventCountBySource from './EventCountBySource';
import EventCountLineChart from './EventCountLineChart';
import EventsSectionItem from './EventsSection';

// import EventCountBySourceBarChart from './EventCountBySourceBarChart';
// import EventCountByNamePieChart from './EventCountByNamePieChart';

const { Link } = Anchor;

const EVENTS_STATS_ACCOUNTS_QUERY = gql`
  query CloudAccountQuery {
    accounts {
      items {
        account_id
        assumerole_account_id
        name
        nickname
        policy_code
        provider
      }
    }
  }
`;

const Root = styled.div`
  color: rgba(0, 0, 0, 0.65);

  a {
    color: rgb(103, 58, 183);
  }

  th,
  td {
    padding: 8px;
  }
`;

interface Props {}

export default function EventsStats(props: Props) {
  // get accounts, so we can show their names instead of ids
  const { data, error, loading } = useQuery(EVENTS_STATS_ACCOUNTS_QUERY);
  const { accounts: accountsWrapper } = data || {};
  const { items: accounts } = accountsWrapper || {};

  console.log({ accounts });

  return (
    <Root id="progress">
      <BackTop />
      <Row>
        <Col span={2}>
          <Anchor offsetTop={56} style={{ backgroundColor: '#fafafa' }}>
            <Link href="#top" title="Total" />
            <Link href="#accounts" title="Accounts" />
            {/* <Link href="#rootUser" title="Root" /> */}
            <Link href="#regions" title="Regions" />
            <Link href="#services" title="Services" />
            <Link href="#methods" title="Methods" />
          </Anchor>
        </Col>
        <Col span={22}>
          {error && <ErrorAlert error={error} />}

          {loading && <Spin style={{ marginRight: '8px' }} />}

          {!loading && accounts && (
            <>
              <EventsSectionItem id="total" title="Total">
                <EventCountLineChart />
              </EventsSectionItem>

              <EventsSectionItem id="accounts" title="Accounts">
                <EventCountByAccountLineChart accounts={accounts} />
                <EventCountByAccount accounts={accounts} />
              </EventsSectionItem>

              <EventsSectionItem id="regions" title="Regions">
                <EventCountByRegion />
              </EventsSectionItem>

              <EventsSectionItem id="services" title="Services">
                {/* <EventCountBySourceBarChart /> */}
                <EventCountBySource />
              </EventsSectionItem>

              <EventsSectionItem id="methods" title="Methods">
                {/* <EventCountByNamePieChart /> */}
                <EventCountByName />
              </EventsSectionItem>

              <div style={{ paddingBottom: 800 }} />
            </>
          )}
        </Col>
      </Row>
    </Root>
  );
}

import React from 'react';
import { Input, message, Divider } from 'antd';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { FormikActions } from 'formik';
import { useMutation } from 'react-apollo';

import { ClientProject } from 'typings';

import ErrorAlert from 'components/ui/ErrorAlert';
import Form, { FormField } from 'components/ui/Form';
import { InfoPanelContent } from 'components/ui/NeoPage';
import DeleteProjectCard from '../DeleteProjectCard';
import ProjectSettingsCard from '../ProjectSettingsCard';
import FormItem from 'antd/lib/form/FormItem';
import ProjectSelect from 'components/project/ProjectSelect';
import Space from 'components/ui/Space';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import ButtonWithDisabledTooltip from 'components/buttons/ButtonWithDisabledTooltip';
import { RootDynamicConfiguration } from './index';
import { refreshCognitoSession } from 'services/auth';

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required')
});

interface FieldValues {
  name?: string;
  parent_id?: string;
}

interface Props {
  project: ClientProject;
}

const UPDATE_PROJECT = gql`
  mutation UpdateProjectForProjectInfoPanel($input: UpdateProjectInput!, $id: String!) {
    updateProject(input: $input, id: $id)
      @rest(type: "ClientProject", path: "/api/v2/projects/{args.id}", method: "PUT") {
      name
      project_id
      parent_id
      created
      updated
    }
  }
`;

function ProjectSettings(props: Props) {
  const { project } = props;

  const authz = useAuthorizor(Permissions.MODIFY_PROJECTS, [project.project_id]);
  const [updateProject, { error: updateError }] = useMutation(UPDATE_PROJECT, {});

  const handleSubmit = async (values: FieldValues, actions: FormikActions<any>) => {
    const { project_id } = project;
    const { name, parent_id } = values;
    const input = {
      name,
      project_id,
      parent_id
    };

    try {
      await updateProject({ variables: { id: project_id, input } });
      await refreshCognitoSession();

      actions.setSubmitting(false);
      message.success('Project successfully updated');
    } catch (e) {
      actions.setSubmitting(false);

      message.error('There was an error updating this project');
    }
  };

  return (
    <Form
      initialValues={{
        name: project.name,
        parent_id: project.parent_id
      }}
      validationSchema={formSchema}
      allowCleanSubmits={false}
      onSubmit={handleSubmit}
    >
      {(formRenderProps) => {
        const { canSubmit, isSubmitting } = formRenderProps;
        return (
          <InfoPanelContent>
            {updateError && <ErrorAlert error={updateError} />}

            <Space direction="vertical" size={8}>
              {project.parent_id && (
                <div>
                  <FormField name="name" label="Name">
                    {({ name, value, handleChange, handleBlur }) => (
                      <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                    )}
                  </FormField>

                  <FormField name={`parent_id`} label="Parent project">
                    {({ name, value, handleBlur, handleChange }) => {
                      return (
                        <ProjectSelect
                          many={false}
                          value={value}
                          name={name}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          projectId={project.project_id}
                          permissions={Permissions.MODIFY_PROJECTS}
                        />
                      );
                    }}
                  </FormField>

                  <FormItem>
                    <ButtonWithDisabledTooltip
                      type="primary"
                      style={{ float: 'right' }}
                      htmlType="submit"
                      disabled={!authz.isAuthorized || !canSubmit || isSubmitting}
                      disabledMessage={authz.message || ''}
                      loading={isSubmitting}
                    >
                      Save
                    </ButtonWithDisabledTooltip>
                  </FormItem>
                  <Divider />
                </div>
              )}

              <ProjectSettingsCard project={project} isSubmitting={isSubmitting} />
              {project.parent_id ? (
                <>
                  <Divider />
                  <FormItem>
                    <DeleteProjectCard project={project} />
                  </FormItem>
                </>
              ) : (
                <RootDynamicConfiguration />
              )}
            </Space>
          </InfoPanelContent>
        );
      }}
    </Form>
  );
}

export default ProjectSettings;

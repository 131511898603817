import React, { useState, ReactNode } from 'react';
import styled from 'styled-components';

/**
 * Note there is a lot of room for improvement here.
 * Ideally you wouldn't have to specify a max height and the height would just be dependent of the child content.
 */
const CollapsePanelRoot = styled.div<{
  maxHeight: number;
}>`
  .collapse-panel-content {
    overflow: hidden;
    max-height: ${p => `${p.maxHeight}px`};
    transition: max-height 0.5s ease;
  }

  &.collapsed .collapse-panel-content {
    max-height: 0px;
  }

  .collapsed-panel-trigger {
    padding: 3px;
    display: flex;
    color: ${p => p.theme.primary500};
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
  }
`;

interface CollapsePanelProps {
  maxHeight: number; // TODO: Make this optional
  children: ReactNode;
  collapsedTriggerContent: ReactNode;
  openTriggerContent: ReactNode;
  defaultCollapsed?: boolean;
  classes?: {
    root?: string;
    trigger?: string;
  };
}

export function CollapsePanel(props: CollapsePanelProps) {
  const {
    maxHeight = 400,
    children,
    defaultCollapsed = true,
    collapsedTriggerContent,
    openTriggerContent,
    classes = {}
  } = props;
  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed);

  return (
    <CollapsePanelRoot maxHeight={maxHeight} className={`${collapsed ? 'collapsed' : ''} ${classes.root || ''}`}>
      <div className="collapse-panel-content">{children}</div>
      <div className={`collapsed-panel-trigger ${classes.trigger || ''}`} onClick={() => setCollapsed(!collapsed)}>
        {collapsed ? collapsedTriggerContent : openTriggerContent}
      </div>
    </CollapsePanelRoot>
  );
}

import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { Button, Input, message, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { Form, FormField } from 'components/ui/Form';
import ErrorAlert from 'components/ui/ErrorAlert';
import * as Yup from 'yup';
import { ModalProps } from 'antd/lib/modal';

interface Props {
  visible: boolean;
  onCreate: () => any | void;
  onCloseModal: () => void;
}

const GENERATE_API_KEY_MUTATION = gql`
  mutation CreateAPIKey($label: String) {
    generateAPIKey(label: $label)
  }
`;

const antModalProps: ModalProps = {
  title: 'Create API Key',
  width: 1000
};

const formSchema = Yup.object().shape({
  label: Yup.string().required('Required')
});

interface FormFields {
  label: string;
}

function CreateApiKeyModal(props: Props) {
  const { visible, onCreate, onCloseModal } = props;

  const [generateAPIKey, result] = useMutation(GENERATE_API_KEY_MUTATION);
  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_CLIENT_API_KEYS);

  if (!visible) return null;

  return (
    <Form
      allowCleanSubmits={false}
      validationSchema={formSchema}
      initialValues={{
        label: ''
      }}
      onSubmit={async (formValues: Partial<FormFields>, formActions) => {
        try {
          const { label } = formValues;

          await generateAPIKey({ variables: { label } });

          message.success('Created new api key');
          onCreate();
          onCloseModal()

        } catch (e) {
          message.error(`Failed to create create api key: ${e}`);
        } finally {
          formActions.setSubmitting(false);
        }
      }}
    >
      {(formProps) => {
        const { canSubmit, isSubmitting, submitForm } = formProps;

        return (
          <Modal
            visible
            {...antModalProps}
            closable
            onCancel={onCloseModal}
            footer={
              <>
                <Button onClick={onCloseModal} disabled={isSubmitting}>
                  {'Cancel'}
                </Button>

                <Button
                  onClick={submitForm}
                  type="primary"
                  disabled={!isAuthorized || !canSubmit || isSubmitting}
                  loading={isSubmitting}
                >
                  {'Create API Key'}
                </Button>
              </>
            }
          >
            <>
              {(result.error || !isAuthorized) && (
                <FormItem>
                  {result.error && <ErrorAlert message="Error creating new api key" error={result.error}></ErrorAlert>}

                  {!isAuthorized && <ErrorAlert message={`${authErrorMessage}`} />}
                </FormItem>
              )}

              <FormField name="label" label="Api Key Label">
                {({ name, value, handleBlur, handleChange }) => (
                  <Input name={name} value={value} onBlur={handleBlur} onChange={handleChange} />
                )}
              </FormField>
            </>
          </Modal>
        );
      }}
    </Form>
  );
}

export default CreateApiKeyModal;

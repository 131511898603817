import React, { useEffect, useState } from 'react';
import { Select, Tree } from 'antd';
import useProjectTree from 'components/project/ProjectTree/useProjectTree';
import { buildTree } from './TreeHelper';
import DelayedLoader from 'designSystem/DelayedLoader/DelayedLoader';
import ErrorAlert from 'components/ui/ErrorAlert';
import { TreeNodeNormal } from 'antd/lib/tree/Tree';
import { GhostButton } from 'designSystem/GhostButton/GhostButton';

interface ProjectEditorProps {
  values: any;
  rootProjectId: string;
  onChange: Function;
}

function ProjectEditor(props: ProjectEditorProps) {
  const { values, rootProjectId, onChange } = props,
    { projectTree, loading, error, getProject } = useProjectTree({
      rootProjectId
    }),
    [valuesObj, setValuesObj] = useState<{ key: string; label: string }>(),
    [showCloudAccounts, setShowCloudAccounts] = useState<boolean>(false),
    [treeData, setTreeData] = useState<TreeNodeNormal[]>();

  function onTreeCheck(checkedKeys) {
    onChange(checkedKeys.checked);
  }

  function onSelectChange(change) {
    onChange(change.map((v) => v.key));
  }

  function toggleCloudAccounts() {
    setShowCloudAccounts(!showCloudAccounts);
  }

  function customMenu() {
    return (
      <div style={{ maxHeight: '260px', overflowY: 'auto' }} onMouseDown={(e) => e.preventDefault()}>
        <GhostButton onClick={toggleCloudAccounts}>
          {showCloudAccounts ? 'hide cloud accounts' : 'show cloud accounts'}
        </GhostButton>
        <Tree
          onCheck={onTreeCheck}
          checkStrictly
          selectedKeys={values}
          autoExpandParent
          treeData={treeData}
          checkable
        />
      </div>
    );
  }

  useEffect(() => {
    if (error) {
      return;
    }

    const latestValues = values?.map((v) => {
      const project = getProject(v);

      return {
        key: v,
        label: project?.name || v
      };
    });

    setValuesObj(latestValues);
  }, [values, loading]);

  useEffect(() => {
    setTreeData(buildTree(projectTree, showCloudAccounts));
  }, [loading, showCloudAccounts]);

  return (
    <>
      {loading && <DelayedLoader />}
      {error && <ErrorAlert message="could not load projects" />}
      {!loading && !error && (
        <Select onChange={onSelectChange} labelInValue value={valuesObj} mode="multiple" dropdownRender={customMenu} />
      )}
    </>
  );
}

export default ProjectEditor;

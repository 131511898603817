import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { Icon, Tooltip } from 'antd';

import { FilterDefinition } from 'typings';

const Root = styled.div`
  display: inline-flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  background-color: lavender;
  color: ${(p) => p.theme.grey900};

  a {
    color: ${(p) => p.theme.grey900};
  }

  .filter-brick-value,
  .filter-brick-label,
  .filter-brick-remove {
    padding: 2px 8px;
    border: 1px solid ${(p) => p.theme.grey800};
  }

  .filter-brick-value {
    border-left: 0;
    border-right: 0;
  }

  .filter-brick-label {
    padding-top: 3px;
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .filter-brick-remove {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

interface Props {
  label: string;
  filter: FilterDefinition;
  filterValue: any;
  onRemove?: (e: MouseEvent) => any;
}

const ICON_MAP = {
  tag: 'tags',
  projectId: 'project',
  region: 'global',
  tagKey: 'tag',
  accountId: 'cloud',
  is_public: 'unlock'
};

function FilterBrick(props: Props) {
  const { label, filterValue, onRemove } = props;
  const filter = props.filter || {};
  const { filterBrickReadView } = filter;
  const FilterBrickReadView = filterBrickReadView || null;

  return (
    <Root>
      <div className="filter-brick-label">
        <Tooltip title={filter.label || filter.name}>
          <Icon type={ICON_MAP[filter.name] || 'filter'} />
        </Tooltip>
      </div>
      <div className="filter-brick-value">
        {FilterBrickReadView ? (
          <FilterBrickReadView filterDefinition={filter} value={filterValue} />
        ) : (
          label || filterValue
        )}
      </div>
      <div className="filter-brick-remove">
        <button
          className="link-button"
          onClick={(e) => {
            return onRemove && onRemove(e);
          }}
        >
          <Icon style={{ fontSize: '12px', lineHeight: '12px', verticalAlign: 'middle' }} type="close" />
        </button>
      </div>
    </Root>
  );
}

export default FilterBrick;

import gql from 'graphql-tag';
import ISSUE_FRAGMENT from './fragments/issue';

export const IGNORED_ISSUE_LIST_QUERY = gql`
  query AllIgnoredIssues {
    ignoredIssues @rest(type: "[Issue]", path: "/issues?status=ignored") {
      ...issueFields
    }
  }

  ${ISSUE_FRAGMENT}
`;

export default IGNORED_ISSUE_LIST_QUERY;

import { Button } from 'antd';
import { CardSection, GridCard } from 'components/ui/Card';
import DopeIcon from 'components/ui/DopeIcon';
import Label from 'components/ui/Label';
import Markdown from 'components/ui/Markdown';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import truncate from 'truncate';

const AUTOMATION_FUNCTION_FIELDS_FOR_FUNCTION_DETAIL = gql`
  fragment AutomationFunctionFieldsForFunctionDetail on AutomationFunction {
    id
    key
    name
    cloudVendor
    description
    overview
    version
    releaseChannel
    labels
    multiplexFields
    isDeprecated
    createdBy
    createdAt
    updatedAt
    effects
    revertFunction {
      functionId
    }
    inputSchema
    parameters {
      name
      key
      type
      inputCode
      configCode
      defaultValue
      description
      placeholder
      many
      options
      required
    }
    registration {
      arn
      active
      type
      clientContext
      updatedAt
      createdAt
      qualifier
      isLocal
      method
      invocationType
    }
    eventFunctions {
      eventDefinitionId
      functionInputMappings {
        key
        valuePath
      }
    }
  }
`;

export const AUTOMATION_FUNCTION_QUERY = gql`
  query automationFunction($id: [String]) {
    automationFunctions(id: $id) {
      nodes {
        ...AutomationFunctionFieldsForFunctionDetail
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_FUNCTION_DETAIL}
`;

const Root = styled.div`
  .ui-label {
    margin: 0px 6px 0px 0px;
  }

  .labels-card-section {
    margin-bottom: 0px;
  }

  .labels-div {
    margin-bottom: 16px;
  }

  .input-schema-pre {
    white-space: pre-wrap;
  }

  .array-item-list {
    margin-left: 16px;
  }

  .page-header {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .centered-container {
    padding-bottom: 16px;
    padding-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .function-results {
    margin-top: 16px;
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

function FunctionDetail(props: Props) {
  const functionId = props.match.params.id;
  const { history } = props;

  return (
    <Query query={AUTOMATION_FUNCTION_QUERY} variables={{ id: [functionId] }}>
      {({ data, loading, queryError }) => (
        <QueryResult
          data={data}
          loading={loading}
          loadingMessage="Loading Function"
          error={queryError}
          expectSingleNode={true}
          parseNodes={(data) => data.automationFunctions.nodes}
          entityName="AutomationFunction"
        >
          {(automationFunction) => {
            const maxDescriptionLength = 75;
            const {
              name,
              description,
              overview,
              cloudVendor,
              labels,
              version,
              releaseChannel,
              isDeprecated,
              revertFunction,
              effects,
              inputSchema
            } = automationFunction;
            // don't bother showing the description if it's the same as the overview (as most generated functions are)
            const showDescription =
              description &&
              (!overview ||
                !(description.substring(0, maxDescriptionLength) !== overview.substring(0, maxDescriptionLength)));

            return (
              <Root>
                <NeoPage
                  titleBar={
                    <TitleBar
                      icon={<DopeIcon name="FUNCTION" size={20} />}
                      sectionTitle="Functions"
                      sectionTitleLinkTo="/dev/functions"
                      backLinkTo="/dev/functions"
                      backLinkTooltipText="Back to functions library"
                      title={name}
                      actions={[
                        <Button
                          key="run"
                          title="Edit &amp; Run Function"
                          type="primary"
                          onClick={() =>
                            history.push({
                              pathname: `/dev/functions/${functionId}/run`
                            })
                          }
                        >
                          Run
                        </Button>
                      ]}
                    />
                  }
                >
                  <CenteredContainer>
                    <GridCard>
                      <CardSection label="Cloud Vendor" description={cloudVendor} />
                      <CardSection label="Name" description={name} />
                      {showDescription && (
                        <CardSection label="Description" description={description && truncate(description, 75)} />
                      )}
                      {labels && (
                        <>
                          <CardSection label="Labels" classes={{ root: 'labels-card-section' }} />
                          <div className="labels-div">
                            {labels.map((l, idx) => {
                              return (
                                <Label
                                  key={`${l}_${idx}`}
                                  label={l}
                                  onClick={() => {
                                    const searchParams = new URLSearchParams();
                                    searchParams.set('labels', l);

                                    history.push({
                                      pathname: '/dev/functions',
                                      search: searchParams.toString()
                                    });
                                  }}
                                />
                              );
                            })}
                          </div>
                        </>
                      )}
                      <CardSection label="Version" description={version} />
                      {releaseChannel && <CardSection label="Release Channel" description={releaseChannel} />}
                      {isDeprecated && <CardSection label="Deprecated" description={isDeprecated} />}
                      {revertFunction && <CardSection label="Revertible" description="Yes" />}
                      {effects && effects.length > 0 && (
                        <>
                          <CardSection label="Effects" classes={{ root: 'labels-card-section' }} />
                          <div className="labels-div">
                            {effects.map((l, idx) => {
                              return <Label key={`${l}_${idx}`} label={l} />;
                            })}
                          </div>
                        </>
                      )}
                    </GridCard>
                    <GridCard>
                      {overview && (
                        <>
                          <CardSection label="Overview" />
                          <div className="markdown-wrapper">
                            <Markdown source={overview} />
                          </div>
                        </>
                      )}
                    </GridCard>
                    {inputSchema && (
                      <GridCard>
                        <CardSection label="Input Schema" />
                        <pre className="input-schema-pre">{JSON.stringify(inputSchema, null, 2)}</pre>
                      </GridCard>
                    )}
                  </CenteredContainer>
                </NeoPage>
              </Root>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

export default FunctionDetail;

import React from 'react';
import { Dropdown, Menu, Spin, Icon } from 'antd';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { EXPORT_ISSUES_MUTATION } from 'components/issue/IssueList/IssueList';

const DOWNLOAD_REPORT_MUTATION = gql`
  mutation GenerateCompliancePolicyReport($reportType: ReportType, $policyTemplateId: String!, $accountId: [String]) {
    generateReport(reportType: $reportType, entityId: $policyTemplateId, accountId: $accountId) {
      url
      fileName
    }
  }
`;

interface OnDownloadProps {
  downloadURL: string;
  fileName: string;
  generating: boolean;
}

interface Props {
  policyTemplateId: string;
  accountIds?: string[];
  issueDefinitionIds?: string[];
  onLoading: () => any;
  onDownload: (props: OnDownloadProps) => any;
  onError?: (error: ApolloError) => any;
  disabled?: boolean;
}

function CompliancePolicyDownloadButton(props: Props) {
  const { policyTemplateId, onLoading, onDownload, onError, issueDefinitionIds, accountIds, disabled } = props;

  return (
    <Mutation
      mutation={DOWNLOAD_REPORT_MUTATION}
      variables={{ policyTemplateId, accountId: accountIds }}
      onCompleted={(data) => {
        onDownload({ ...data.generateReport, downloadURL: data.generateReport.url, generating: true });
      }}
      onError={onError}
    >
      {(downloadReport, { loading, client }) => {
        const menu = (
          <Menu
            onClick={(e) => {
              onLoading();

              const variables = {
                isResolved: false,
                isExempted: false,
                policyTemplateId,
                issueDefinitionId: issueDefinitionIds,
                accountId: accountIds,
                reportType: e.key
              };

              e.key === 'ISSUES_CSV'
                ? client
                    .mutate({
                      mutation: EXPORT_ISSUES_MUTATION,
                      variables
                    })
                    .then((result) => {
                      const url = result.data.exportIssues.url;

                      onDownload({ downloadURL: url, fileName: url, generating: false });
                    })
                : downloadReport({
                    variables
                  });
            }}
          >
            <Menu.Item key="COMPLIANCE_POLICY_TEMPLATE" disabled={disabled}>
              Report without Issues (.pdf)
            </Menu.Item>
            <Menu.Item key="COMPLIANCE_POLICY_TEMPLATE_WITH_ISSUES" disabled={disabled}>
              Report with Issues (.pdf)
            </Menu.Item>
            <Menu.Item key="ISSUES_CSV" disabled={disabled}>
              Just Issues (.csv)
            </Menu.Item>
          </Menu>
        );
        return (
          <>
            <Dropdown.Button
              disabled={loading || disabled}
              icon={loading ? <Spin size="small" /> : <Icon type="ellipsis" />}
              onClick={(e) => {
                onLoading();
                downloadReport({
                  variables: {
                    policyTemplateId,
                    accountId: accountIds,
                    reportType: 'COMPLIANCE_POLICY_TEMPLATE'
                  }
                });
              }}
              overlay={menu}
            >
              Export
            </Dropdown.Button>
          </>
        );
      }}
    </Mutation>
  );
}

export default CompliancePolicyDownloadButton;

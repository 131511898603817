import React from 'react';
import styled from 'styled-components';

const OpSectionTitleRoot = styled.div`
  display: flex;

  .op-section-title-title {
    margin-right: 16px;
  }
`;

interface OpSectionTitleProps {
  title: string;
  action?: React.ReactNode;
}

function OpSectionTitle(props: OpSectionTitleProps) {
  const { title, action } = props;

  return (
    <OpSectionTitleRoot className="op-section-title">
      <span className="op-section-title-title">{title}</span>

      {action && <div className="op-section-title-action">{action}</div>}
    </OpSectionTitleRoot>
  );
}

export default OpSectionTitle;

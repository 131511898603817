import { Button, Form, Input, Modal, Spin } from 'antd';
import { FunctionParameterInputSet } from 'components/function';
import { FormField } from 'components/ui/Form';
import { FormRenderProps } from 'components/ui/Form/Form';
import { Field, getIn } from 'formik';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import uuid from 'uuid';
import { AUTOMATION_FUNCTION_BY_ID } from '../gql';
import { ContextArgs, parseFormValuesFromAutomationFunction } from '../OpEditor';
import { SelectAction } from './OpActionSection';

const { confirm } = Modal;

interface Props {
  name: string;
  contextArgs: ContextArgs;
  formRenderProps: FormRenderProps;
  onChange?: (event: any) => any;
}

export default function AlertDelayedAction(props: Props) {
  const { name, contextArgs, formRenderProps, onChange } = props;

  const { values } = formRenderProps;
  const alertAction = getIn(values, name);

  const [isSelectActionModalVisible, setIsSelectActionModalVisible] = useState<boolean>(false);
  const [isEditActionModalVisible, setIsEditActionModalVisible] = useState<boolean>(false);

  const functionId = alertAction?.functionId;

  const { loading: functionLoading, error: functionError, data: functionData } = useQuery(AUTOMATION_FUNCTION_BY_ID, {
    variables: {
      id: functionId
    },
    skip: !functionId,
    fetchPolicy: 'cache-first'
  });
  const automationFunctionToConfigure = functionData?.automationFunctions?.nodes?.[0] || null;

  return (
    <Form.Item>
      <Field name={name}>
        {({ field, form }) => {
          const { setFieldValue } = form;

          return (
            <>
              {!alertAction ? (
                <div>
                  None
                  <Button
                    icon="plus"
                    size="small"
                    title="Set Delayed Action"
                    style={{ marginLeft: '4px', padding: '0px', paddingTop: '3px' }}
                    onClick={() => setIsSelectActionModalVisible(true)}
                  />
                </div>
              ) : (
                <div key={alertAction.id}>
                  {alertAction.name}
                  <Button
                    icon="edit"
                    title="Edit Delayed Action"
                    size="small"
                    style={{ marginLeft: '4px', padding: '0px', paddingTop: '3px' }}
                    onClick={() => {
                      setIsEditActionModalVisible(true);
                    }}
                  />
                  {alertAction && (
                    <Button
                      icon="close"
                      title="Delete Delayed Action"
                      size="small"
                      style={{ marginLeft: '4px', padding: '0px', paddingTop: '3px' }}
                      onClick={() => {
                        confirm({
                          title: `Delete Delayed Action '${alertAction.name}'?`,
                          content: `Are you sure you want to delete the delayed action '${alertAction.name}?`,
                          onOk() {
                            setFieldValue(name, null);
                            onChange && onChange(null);
                          },
                          onCancel() {}
                        });
                      }}
                    />
                  )}
                </div>
              )}

              <Modal
                width="800px"
                title="Select Action"
                visible={isSelectActionModalVisible}
                onCancel={() => setIsSelectActionModalVisible(false)}
                footer={[
                  <Button key="back" onClick={() => setIsSelectActionModalVisible(false)}>
                    Cancel
                  </Button>
                ]}
              >
                <SelectAction
                  contextArgs={contextArgs}
                  onSelectAction={(args) => {
                    const automationFunction = args?.automationFunction;
                    if (!automationFunction) return;

                    const { actionConfiguration } = parseFormValuesFromAutomationFunction(automationFunction);

                    const newAction = {
                      id: uuid.v4(),
                      name: automationFunction.name,
                      functionId: automationFunction.id,
                      function: automationFunction,
                      configuration: {
                        ...actionConfiguration
                      }
                    };

                    console.log({ newAction, actionConfiguration, values });

                    setFieldValue(name, newAction);
                    setIsSelectActionModalVisible(false);
                    setIsEditActionModalVisible(true);
                    onChange && onChange(newAction);
                  }}
                  hideAlertAction={true}
                />
              </Modal>
              <Modal
                width="800px"
                title={`Configure Action`}
                visible={isEditActionModalVisible}
                onCancel={() => {
                  setIsEditActionModalVisible(false);
                }}
                footer={[
                  <Button
                    key="back"
                    onClick={() => {
                      setIsEditActionModalVisible(false);
                    }}
                  >
                    Close
                  </Button>
                ]}
              >
                <Form.Item>
                  <FormField name={`${name}.name`} label="Action Name">
                    {({ name, value, handleChange, handleBlur }) => {
                      return <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />;
                    }}
                  </FormField>
                </Form.Item>
                {functionLoading ? (
                  <Spin tip="Loading action..." />
                ) : functionError?.message ? (
                  <>{functionError.message}</>
                ) : automationFunctionToConfigure?.parameters ? (
                  <FunctionParameterInputSet
                    {...formRenderProps}
                    parameters={automationFunctionToConfigure.parameters}
                    allowJSONPathInput
                    staticConfigPathPrefix={`${name}.configuration.`}
                    jsonpathConfigPathPrefix={`${name}.configuration.jsonpathConfiguration.`}
                    dynamicConfigPathPrefix={`${name}.configuration.dynamicConfiguration.`}
                  />
                ) : (
                  <div>No parameters to configure</div>
                )}
              </Modal>
            </>
          );
        }}
      </Field>
    </Form.Item>
  );
}

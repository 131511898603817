import React from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';

const Root = styled.div`
  .section {
    margin-top: 16px;
  }
`;

interface Props {
  id: string;
  title: string;
  divider?: boolean;
}

export default function EventsSectionItem(props: React.PropsWithChildren<Props>) {
  const { children, id, title, divider = true } = props;

  return (
    <Root>
      <h3 id={id} className="section">
        {title}{' '}
        <a href="#top">
          <i className="fas fa-angle-up" style={{ color: 'black', fontSize: 'small' }}></i>
        </a>
      </h3>
      {children}
      {divider && <Divider />}
    </Root>
  );
}

import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useMutation } from 'react-apollo';
import { Form, FormField } from 'components/ui/Form';
import { Input, message, Alert, Button, Row, Col } from 'antd';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import ErrorAlert from 'components/ui/ErrorAlert';
import { restRequest } from '../../../../../utilities/rest';

const CREATE_CLIENT = gql`
  mutation CreateClient($clientId: String!, $input: FirstTimeSetupInput!) {
    createClient(input: $input) 
      @rest(path: "/api/v2/clients/", method: "POST") {
        client_id
      }
  }
`;

interface IFormFields {
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  phone: string;
}

function getHubspotPayload(values: IFormFields) : any {
  const { firstName, lastName, companyName, email, jobTitle, phone } = values || {} as any;

  return {
    fields: [
      {
        name: 'firstname',
        value: firstName
      },
      {
        name: 'lastname',
        value: lastName
      },
      {
        name: 'company',
        value: companyName
      },
      {
        name: 'email',
        value: email
      },
      {
        name: 'jobtitle',
        value: jobTitle
      },
      {
        name: 'phone',
        value: phone
      },
    ]
  };
}

interface Props extends RouteComponentProps<any> {
  inviteUsername?: string;
  inviteCompanyName?: string;
}

function NewAccountEmbeddedShort(props: Props) {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const billingId = params.has('awsCustomerId') ? params.get('awsCustomerId') : undefined;
  const [registeredEmail, setRegisteredEmail] = useState<string | null>(null);
  const [createClient, { error, loading }] = useMutation(CREATE_CLIENT);

  if (registeredEmail) {
    return (
      <Alert
        type="success"
        message={`An email has been sent to ${registeredEmail} with instructions to setup your new DisruptOps account.`}
      />
    );
  }

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
      .strict(true)
      .trim('Leading or trailing whitespace characters are not allowed.')
      .email('Valid email address required.')
      .required('Required')
  });

  return (
    <>
      {error && <ErrorAlert error={error} />}
      <Form
        allowCleanSubmits={false}
        validationSchema={validationSchema}
        initialValues={{
          companyName: '',
          firstName: '',
          lastName: '',
          email: '',
          jobTitle: '',
          phone: '',
        }}
        onSubmit={async (values: any, actions: any) => {
          const { companyName, firstName, lastName, email } = values;

          try {
            const response = await createClient({
              variables: {
                input: {
                  company_name: companyName,
                  first_name: firstName,
                  last_name: lastName,
                  username: email,
                  email: email,
                  billingId: billingId,
                  billingVendor: billingId ? 'aws' : undefined
                }
              }
            });

            if (response.errors)
              throw new Error(response.errors[0].message);

            setRegisteredEmail(email);

            const portalId = '4832527';
            const formId = '946c3c86-0817-4e51-a952-c5ee091513da';
            await restRequest({
              url: `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
              method: 'POST',
              payload: getHubspotPayload(values)
            });
          } catch (e) {
            message.error('There was a problem registering your new account.', e.message);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(formRenderProps) => {
          const { canSubmit, isSubmitting, submitForm } = formRenderProps;
          const gutter = 20;
          const span = 12;

          return (
            <div>
              <Row gutter={gutter}>
                <Col span={span}>
                  <FormField name='firstName' label='First Name' className='.firstName'>
                    {({ name, value, handleChange, handleBlur }) => (
                      <Input autoFocus name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                    )}
                  </FormField>
                </Col>
                <Col span={span}>
                  <FormField name='lastName' label='Last Name' className='.lastName'>
                    {({ name, value, handleChange, handleBlur }) => (
                      <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                    )}
                  </FormField>
                </Col>
              </Row>

              <Row>
                <FormField name='companyName' label='Company Name' className='.companyName'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>
              </Row>

              <Row>
                <FormField name='email' label='Email' className='.email'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>
              </Row>

              <Row>
                <FormField name='jobTitle' label='Job Title' className='.jobtitle'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>
              </Row>

              <Row>
                <FormField name='phone' label='Phone' className='.phone'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>
              </Row>

              <Button
                className='.submitButton'
                onClick={submitForm}
                type="primary"
                disabled={!canSubmit || loading || isSubmitting}
                loading={loading || isSubmitting}
              >
                Submit
              </Button>
            </div>
          );
        }}
      </Form>
    </>
  );
}

export default NewAccountEmbeddedShort;

import React, { useState } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { Select } from 'antd';
import { FilterTypeProps } from '../FilterListing/FilterListing';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { EditingRegions } from './RegionEditorStyles';

interface Region {
  name: string;
}

interface RegionsData {
  regions: Region[];
}

export const REGION_LIST_QUERY = gql`
  query AWSRegionList {
    regions {
      name
    }
  }
`;

function RegionEditor(props: FilterTypeProps) {
  const { values, filterType } = props,
    [softEdit, setSoftEdit] = useState<SoftEdit | null>(null),
    [regions, setRegions] = useState(values[filterType.valueKey]),
    [negate, setNegate] = useState(values.triggerFilters?.negateRegions ? 'true' : 'false'),
    onRegionChange = (values) => {
      setRegions(values);
      setSoftEdit({
        key: filterType.valueKey,
        value: values
      });
    },
    updateNegate = (update) => {
      setNegate(update);
      setSoftEdit({
        key: 'triggerFilters.negateRegions',
        value: update === 'true'
      });
    },
    { data, loading, error } = useQuery<RegionsData>(REGION_LIST_QUERY, {
      fetchPolicy: 'cache-and-network'
    }),
    display = (
      <>
        {negate === 'true' && <span data-testid="negate">Does not include</span>}
        <ul data-testid="region-list">
          {values[filterType.valueKey]?.map((v) => (
            <li data-testid="value-item" key={v}>
              {v}
            </li>
          ))}
        </ul>
      </>
    ),
    editor = (
      <EditingRegions>
        <div className="negator-wrap">
          <Select data-testid="negator" value={negate} onChange={updateNegate}>
            <Select.Option value="false">Includes</Select.Option>
            <Select.Option value="true">Does not include</Select.Option>
          </Select>
        </div>
        <Select data-testid="region-editor" mode="multiple" onChange={onRegionChange} value={regions}>
          {data?.regions.map(({ name }) => (
            <Select.Option key={name} value={name}>
              {name}
            </Select.Option>
          ))}
        </Select>
      </EditingRegions>
    );

  return <FilterRow {...props} display={display} editor={editor} softEdit={softEdit} loading={loading} error={error} />;
}

export default RegionEditor;

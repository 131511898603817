import styled from 'styled-components';

export const ProjectChipStyles = styled.div`
  display: inline-block;

  .icon-shift {
    display: inline-block;
    transform: translateY(2px);
    margin-right: 0.25rem;
  }
`;

import React, { useState, useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';

import NeoPage, { TitleBar, TitleBarButton, InfoPanel } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import { CenteredContainer } from 'components/ui/NeoPage';
import { CloudAccount, ClientProject } from 'typings';

import ProjectTree from 'components/project/ProjectTree';
import CreateProjectModal from './CreateProjectModal';
import AuthContext from 'components/app/Auth/AuthContext';
import { useQuery } from 'react-apollo';
import { useFeatureFlag, Feature } from 'components/app/FeatureFlag';
import gql from 'graphql-tag';
import QueryResult from 'components/util/QueryResult';

import { ProjectAssessments, ProjectCloudAccounts, ProjectSettings, ProjectDynamicConfiguration } from './tabs';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Tooltip } from 'antd';

export const PROJECT_MANAGER_QUERY = gql`
  query ProjectInfoPanel($id: String!) {
    project(id: $id) @rest(type: "ClientProject", path: "/api/v2/projects/{args.id}", method: "GET") {
      name
      project_id
      parent_id
      accounts_list @type(name: "ClientAccount") {
        account_id
        name
        nickname
        provider
        assumerole_account_id
        policy_code
      }
    }
  }
`;

const Root = styled.div`
  overflow: hidden;

  .project-tree-sidenav {
    height: 100vh;
    overflow-y: auto;
    padding: 32px 20px 0px;
    border-right: 1px solid rgb(202, 202, 202);
  }

  .project-manager-page-inner {
    border-left: 1px solid rgb(202, 202, 202);
  }

  .project-manager-page-inner-header {
    padding: 20px;
  }

  .project-manager-page-inner-body {
  }
`;

export interface ProjectTreeNode {
  name: string;
  project_id: string;
  accounts?: CloudAccount[];
  children?: ProjectTreeNode[];
}

export interface ProjectPageProps extends RouteComponentProps<{ id?: string }> {}

function ProjectManagerPage(props: RouteComponentProps<{ id?: string }>) {
  const { match, history } = props;

  const [initialProjectId, setInitialProjectId] = useState<string | undefined>();
  const [isCreatingProject, setIsCreatingProject] = useState<boolean>(false);
  const authContext = useContext(AuthContext);

  const projectId = match.params.id || initialProjectId;

  const qr = useQuery(PROJECT_MANAGER_QUERY, {
    variables: {
      id: projectId || authContext?.rootProjectId
    },
    skip: !projectId
  });

  const authz = useAuthorizor(Permissions.MODIFY_PROJECTS);

  const { loading, error, data } = qr;
  const featureFlags = useFeatureFlag([Feature.DYNAMIC_CONFIG_PROJECT_LEVEL]);

  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            sectionTitle="Projects"
            icon={<DopeIcon name="PROJECT" size={20} />}
            sectionTitleLinkTo={'/projects'}
            actions={
              authz.isAuthorized ? (
                <TitleBarButton
                  icon={<DopeIcon name="ADD" />}
                  antButtonProps={{}}
                  onClick={() => {
                    setIsCreatingProject(true);
                  }}
                >
                  Add
                </TitleBarButton>
              ) : (
                <Tooltip placement="bottom" trigger="hover" title={authz.message}>
                  <div>
                    <TitleBarButton
                      icon={<DopeIcon name="ADD" />}
                      antButtonProps={{}}
                      //disabled
                      onClick={() => {
                        setIsCreatingProject(true);
                      }}
                    >
                      Add
                    </TitleBarButton>
                  </div>
                </Tooltip>
              )
            }
          />
        }
        sidenav={
          <div className="project-tree-sidenav">
            <CenteredContainer size="fluid">
              <ProjectTree
                onSelect={(newSelectedKeys) => {
                  const newId = newSelectedKeys.find((newKey) => newKey !== projectId);
                  if (newId) history.push(`/projects/${newId}`);
                }}
                selectedProjectIds={projectId ? [projectId] : []}
                onProjectsLoaded={(allProjects) => {
                  const systemControlledProject = allProjects.find((project) => project.is_system_controlled);
                  const nullParentIdProject = allProjects.find((project) => project.parent_id === null);
                  const firstProject = allProjects[0];

                  const project = systemControlledProject || nullParentIdProject || firstProject;
                  setInitialProjectId(project.project_id);
                }}
              />
            </CenteredContainer>
          </div>
        }
      >
        <QueryResult
          loading={!projectId || loading}
          data={data && data.project && data.project.project_id === projectId ? data : undefined}
          error={error}
          parseNodes={(data) => {
            return data?.project;
          }}
        >
          {(project: ClientProject) => {
            if (!project) return <div />;

            return (
              <InfoPanel
                title={project.name}
                navConfig={[
                  { label: 'Cloud Accounts', to: `/projects/${projectId}/cloud-accounts` },
                  { label: 'Detectors', to: `/projects/${projectId}/detectors` },
                  {
                    label: 'Dynamic Configuration',
                    hidden: !featureFlags[Feature.DYNAMIC_CONFIG_PROJECT_LEVEL],
                    to: `/projects/${projectId}/dynamic-configuration`
                  },
                  { label: 'Settings', to: `/projects/${projectId}/settings` }
                ]}
              >
                <Switch>
                  <Route
                    path="/projects/:projectId/cloud-accounts"
                    render={() => <ProjectCloudAccounts project={project} />}
                  />

                  <Route
                    path={'/projects/:projectId/detectors'}
                    render={(routeProps) => <ProjectAssessments {...routeProps} />}
                  />

                  <Route
                    path={'/projects/:projectId/dynamic-configuration'}
                    render={(routeProps) => <ProjectDynamicConfiguration project={project} {...routeProps} />}
                  />

                  <Route
                    path={'/projects/:projectId/settings'}
                    render={() => {
                      if (!authContext) return null;

                      return <ProjectSettings project={project} />;
                    }}
                  />

                  <Route render={() => <Redirect to={`/projects/${projectId}/cloud-accounts`} />} />
                </Switch>
              </InfoPanel>
            );
          }}
        </QueryResult>
      </NeoPage>

      <CreateProjectModal
        key={isCreatingProject.toString()} // Causes re-render and clears error state when modal is closed and re-oped
        rootProjectId={projectId}
        visible={isCreatingProject}
        onCloseModal={() => setIsCreatingProject(false)}
      />
    </Root>
  );
}

export default ProjectManagerPage;

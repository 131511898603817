import React from 'react';
import NeoPage, { TitleBar, TitleBarButton, CenteredContainer } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import gql from 'graphql-tag';
import AssessmentTable from 'components/assessment/AssessmentTable';
import { DataProxy } from 'apollo-cache';
import { OperationVariables } from 'apollo-client';
import { FetchResult } from 'apollo-link';

const ASSESSMENTS_QUERY = gql`
  query AssessmentList(
    $search: String
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $labels: [String]
  ) {
    assessments(
      search: $search
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      labels: $labels
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        id
        labels
        name
        isEnabled
        isReadOnly
        staticConfiguration
        schedule
        assessor {
          id
          name
          cloudVendor
          description
          type
          version
          labels
          parameters {
            name
            key
            type
            inputCode
            configCode
            defaultValue
            description
            placeholder
            many
            options
            required
          }
        }
        lastRanAt
        issueStats {
          openIssueCount
          exemptedIssueCount
          resolvedIssueCount
        }
        installedGuardrails {
          id
          isEnabled
        }
      }
    }
  }
`;

function deleteAssessmentUpdateCache(assessmentId: string, variables: OperationVariables) {
  return (cache: DataProxy, result: FetchResult<any>) => {
    const query = ASSESSMENTS_QUERY;
    const data: any = cache.readQuery({ query, variables });

    if (data && data.assessments && data.assessments.nodes) {
      data.assessments.nodes = data.assessments.nodes.filter((item) => item.id !== assessmentId);
    }

    cache.writeQuery({
      query,
      data,
      variables
    });
  };
}

function AssessmentsPage() {
  return (
    <NeoPage
      titleBar={
        <TitleBar
          icon={<DopeIcon name="ASSESSMENT" size={20} />}
          sectionTitle="Detectors"
          sectionTitleLinkTo="/detectors"
          actions={
            <TitleBarButton linkTo="/detectors/add" icon={<DopeIcon name="ADD" />}>
              New
            </TitleBarButton>
          }
        />
      }
    >
      <CenteredContainer>
        <AssessmentTable query={ASSESSMENTS_QUERY} onAssessmentDelete={deleteAssessmentUpdateCache} />
      </CenteredContainer>
    </NeoPage>
  );
}

export default AssessmentsPage;

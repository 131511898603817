import React from 'react';
import styled from 'styled-components';
import { ScopedDynamicConfig } from 'typings';
import FunctionReadView from 'components/function/FunctionReadView';

const Root = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;
const ScopeGroupWrap = styled.div``;
const ScopeGroupLabel = styled.h5`
  padding: 4px 24px;
  margin-bottom: 0;
  border-bottom: 1px solid ${p => p.theme.grey200};
  border-top: 1px solid ${p => p.theme.grey200};
  background-color: ${p => p.theme.grey50};
`;
const SettingListItem = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  padding: 12px 24px;
  cursor: pointer;
  &:hover {
    background-color: rgba(224, 209, 254, 0.41);
  }

  .ant-card-meta-title {
    margin-bottom: 0;
  }
`;
const SettingRowTitle = styled.div`
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
`;
const SettingRowDescription = styled.div`
  color: rgba(0, 0, 0, 0.45);
`;
const SettingInfoCol = styled.div`
  flex: 1;
`;
const SettingValueCol = styled.div`
  flex: 1;
  padding-left: 16px;
  display: flex;
  justify-content: flex-end;
`;

interface Props {
  scopedDynamicConfigs: ScopedDynamicConfig[];
  onSelect: (value: any) => void;
}

function DynamicConfigList(props: Props) {
  const { scopedDynamicConfigs, onSelect } = props;

  return (
    <Root>
      {scopedDynamicConfigs.map(item => {
        const { scopeLabel, scopeKey, options } = item;

        return (
          <ScopeGroupWrap key={scopeKey}>
            <ScopeGroupLabel>{scopeLabel}</ScopeGroupLabel>
            {options.map(option => {
              return option.savedItem && option.savedItem.value ? (
                <SettingListItem
                  key={option.name}
                  onClick={() => {
                    onSelect(option.savedItem);
                  }}
                >
                  <SettingInfoCol>
                    <SettingRowTitle>{option.name}</SettingRowTitle>
                    <SettingRowDescription>{option.description}</SettingRowDescription>
                  </SettingInfoCol>
                  <SettingValueCol>
                    {option.savedItem && option.savedItem.value && (
                      <FunctionReadView functionParameter={option} value={option.savedItem.value} />
                    )}
                  </SettingValueCol>
                </SettingListItem>
              ) : null;
            })}
          </ScopeGroupWrap>
        );
      })}
    </Root>
  );
}

export default DynamicConfigList;

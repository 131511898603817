import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  height: 1px;
  border-bottom-color: ${p => p.theme.grey100};
  border-bottom-style: solid;
`;

export interface Props {
  height?: number | string;
  classes?: {
    root?: string;
  };
}

function Divider(props: Props) {
  const { height = 1, classes = {} } = props;

  return (
    <Root
      style={{
        borderBottomWidth: typeof height === 'string' ? height : `${height}px`
      }}
      className={classes.root || ''}
    />
  );
}

export default Divider;

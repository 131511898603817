import ErrorBoundary from 'components/ui/ErrorBoundary';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import React from 'react';
import { Route, Switch } from 'react-router';
import styled from 'styled-components';
import Guide from './Guide';

const Root = styled.div``;

function GettingStarted() {
  return (
    <NeoPage titleBar={<TitleBar title={'Getting Started'} />}>
      <Root>
        <CenteredContainer leftAlign>
          <ErrorBoundary>
            <Switch>
              <Route path="/getting-started" component={Guide} />
              <Route path="/" component={Guide} />
            </Switch>
          </ErrorBoundary>
        </CenteredContainer>
      </Root>
    </NeoPage>
  );
}

export default GettingStarted;

import React, { useState } from 'react';

import { Button } from 'antd';
import styled from 'styled-components';
import { Assessor } from 'typings';
import { GridCard, CardSection } from 'components/ui/Card';
import DopeIcon from 'components/ui/DopeIcon';
import Markdown from 'components/ui/Markdown';

const Root = styled.div`
  .more-btn {
    display: flex;
    align-items: center;

    &.show-more svg {
      transform: rotateZ(180deg);
    }
  }

  .markdown-wrapper {
    margin-top: 32px;
  }
`;

interface Props {
  assessor: Assessor;
}

function AssessorDetailsCard(props: Props) {
  const [showMore, setShowMore] = useState(false);
  const { assessor } = props;

  return (
    <Root>
      <GridCard title="General" icon={<DopeIcon size={22} name="OP_TEMPLATES" />}>
        <CardSection label="Name" description={assessor.name} />
        {assessor.cloudVendor && <CardSection label="Cloud Vendor" description={assessor.cloudVendor} />}
        {assessor.description && <CardSection label="Description" description={assessor.description} />}

        {assessor.overview && (
          <>
            <Button
              className={`more-btn${showMore ? ' show-more' : ''}`}
              onClick={() => {
                setShowMore(!showMore);
              }}
              type="primary"
              ghost
            >
              {showMore ? 'Less' : 'More'}
              <DopeIcon name="EXPAND_MORE" />
            </Button>
            {showMore && (
              <div className="markdown-wrapper">
                <Markdown source={assessor.overview} />
              </div>
            )}
          </>
        )}
      </GridCard>
    </Root>
  );
}

export default AssessorDetailsCard;

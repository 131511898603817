import React, { ReactNode, ReactNodeArray } from 'react';
import styled from 'styled-components';
import { CSSTransitionGroup } from 'react-transition-group';
import Main from './Main';

const Root = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
`;

export const SidenavPanel = styled.div`
  position: relative;
`;

interface Props {
  sidenav?: ReactNode;
  titleBar?: ReactNode;
  titleBarHasNav?: boolean;
  children: ReactNode | ReactNodeArray;
}

function NeoPage(props: Props) {
  const { children, sidenav, titleBar, titleBarHasNav = false } = props;

  let rootClassNames = 'neo-page';

  if (titleBar) rootClassNames += ' has-titlebar';

  if (titleBar && titleBarHasNav) rootClassNames += ' neo-page-title-bar-has-nav';

  return (
    <CSSTransitionGroup
      transitionName="page"
      transitionAppear={true} // when mounted
      transitionAppearTimeout={300}
      transitionEnter={false}
      transitionLeave={false}
    >
      <Root className={rootClassNames}>
        {sidenav && <SidenavPanel>{sidenav}</SidenavPanel>}
        <Main titleBar={titleBar ? titleBar : null}>{children}</Main>
      </Root>
    </CSSTransitionGroup>
  );
}

export default NeoPage;

import React, { useState } from 'react';
import { AdminNavDiv } from './AdminNav.styles';
import { Link } from 'react-router-dom';
import { SettingFilled, UpOutlined } from '@ant-design/icons';
import MainNavItem from '../MainNav/MainNavItem/MainNavItem';

interface AdminNavProps {
  collapsed: boolean;
}

function AdminNav(props: AdminNavProps) {
  const [subMenuOpen, setSubMenuOpen] = useState(false),
    { collapsed } = props,
    adminLinksClasses = collapsed ? 'admin-links collapsed' : 'admin-links';

  function toggleSubMenu(e) {
    setSubMenuOpen(!subMenuOpen);
  }

  function checkToClose(e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setSubMenuOpen(false);
    }
  }

  function openMenuIfFocused() {
    if (!subMenuOpen) {
      setSubMenuOpen(true);
    }
  }

  return (
    <AdminNavDiv className={subMenuOpen ? 'open' : null} onBlur={checkToClose}>
      <div className={adminLinksClasses}>
        <ul className="no-bullets" onClick={toggleSubMenu}>
          <li>
            <Link to="/events" onFocus={openMenuIfFocused}>
              Events
            </Link>
          </li>
          <li>
            <Link to="/organization-settings/notifications" onFocus={openMenuIfFocused}>
              Notifications
            </Link>
          </li>
          <li>
            <Link to="/organization-settings/notifications/recipients" onFocus={openMenuIfFocused}>
              Recipients
            </Link>
          </li>
          <li>
            <Link to="/cloud-accounts" onFocus={openMenuIfFocused}>
              Cloud Accounts
            </Link>
          </li>
          <li>
            <Link to="/projects" onFocus={openMenuIfFocused}>
              Projects/Teams
            </Link>
          </li>
          <li>
            <Link to="/activity" onFocus={openMenuIfFocused}>
              Activity
            </Link>
          </li>
          <li>
            <Link to="/organization-settings/" onFocus={openMenuIfFocused}>
              Organization Settings
            </Link>
          </li>
        </ul>
      </div>
      <button onClick={toggleSubMenu} className="toggle-trigger">
        <MainNavItem icon={<SettingFilled />}>
          <div className="contents">
            Admin
            <UpOutlined className="caret-up" />
          </div>
        </MainNavItem>
      </button>
    </AdminNavDiv>
  );
}

export default AdminNav;

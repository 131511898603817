import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import NeoPage, { TitleBar } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';

import CreateGuardrailButton from 'components/guardrails/CreateGuardrailButton';

import GuardrailList from './GuardrailList';
import GuardrailsActivity from './GuardrailsActivity';
import GuardrailDetail from '../GuardrailDetail';

interface GuardrailsSectionProps
  extends RouteComponentProps<{
    tab?: string;
  }> {}

function GuardrailsSection(props: GuardrailsSectionProps) {
  const { history } = props;

  return (
    <NeoPage
      titleBarHasNav
      titleBar={
        <TitleBar
          sectionTitle="Guardrails"
          icon={<DopeIcon name="GUARDRAIL" size={20} />}
          actions={
            <CreateGuardrailButton
              onNewGuardrailCreated={(guardrail) => {
                history.push(`/guardrails/details/${guardrail.id}`);
              }}
            />
          }
          navConfig={[
            {
              label: 'Guardrails',
              to: '/guardrails/list'
            },
            {
              label: 'Activity',
              to: '/guardrails/activity'
            }
          ]}
        />
      }
    >
      <Switch>
        <Route path="/guardrails/list" component={GuardrailList} />
        <Route path="/guardrails/activity" component={GuardrailsActivity} />
        <Route path="/guardrails/new" component={GuardrailDetail} />
        <Route path="/guardrails/details/:id" component={GuardrailDetail} />
        <Route render={() => <Redirect to="/guardrails/list" />} />
      </Switch>
    </NeoPage>
  );
}

export default GuardrailsSection;

import React from 'react';
import { MutationFunction } from 'react-apollo';

import { NewGuardrail } from 'typings';

import styled from 'styled-components';
import Meta, { MetaRowGroup } from 'components/ui/Meta';
import DateTime from 'components/ui/DateTime';

const Root = styled.div``;

interface Props {
  guardrail: NewGuardrail;
  saveGuardrailMutationFn: MutationFunction;
}

function GuardrailMetaColumn(props: Props) {
  const { guardrail } = props;

  return (
    <Root className="guardrail-meta-column">
      <MetaRowGroup>
        {guardrail.createdAt && (
          <Meta label={'Created:'}>
            <DateTime format="local" dateTime={guardrail.createdAt} />
          </Meta>
        )}
        {guardrail.archivedAt && (
          <Meta label={'Archived:'}>
            <DateTime format="local" dateTime={guardrail.archivedAt} />
          </Meta>
        )}
        {!guardrail.archivedAt && guardrail.updatedAt && (
          <Meta label={'Last updated:'}>
            <DateTime format="local" dateTime={guardrail.updatedAt} />
          </Meta>
        )}
      </MetaRowGroup>
    </Root>
  );
}

export default GuardrailMetaColumn;

import React from 'react';
import { FormField } from 'components/ui/Form';
import { GuardrailConfigurationInputProps } from '../../trigger/definitions';
import AssessmentSelector from 'app/sections/Authenticated/pages/OrganizationSettings/pages/Notifications/components/NotificationForm/AssessmentSelector';

function AssessmentFilterInput(props: GuardrailConfigurationInputProps) {
  const { name } = props;

  return (
    <FormField name={name} label="Select Detector">
      {({ value, handleChange, handleBlur }) => {
        // IT PROBABLY MAKES SENSE TO LIMIT ASSESSMENTS THAT ACTUALLY MATCH THE SELECTED ISSUE DEFINITION?
        // IF ISSUE DEFINITION CHANGES, SHOULD FILTERS GET WIPED?
        return <AssessmentSelector value={value} onChange={handleChange} onBlur={handleBlur} />;
      }}
    </FormField>
  );
}

export default AssessmentFilterInput;

import gql from 'graphql-tag';

export const ROLE_LIST_QUERY = gql`
  query RoleList($perPage: Number = 20) {
    roleList(perPage: $perPage) @rest(type: "RoleListPayload", path: "/roles?per_page={args.perPage}") {
      items {
        id
        key

        name
        description

        is_system_controlled

        created
        updated
      }
      page
      per_page
      total
    }
  }
`;

export default ROLE_LIST_QUERY;

import React from 'react';
import { Button } from 'antd';
import { useQuery } from 'react-apollo';

import { ExistingNotificationRecipient } from 'typings';
import QueryResult from 'components/util/QueryResult';
import RECIPIENTS_LIST_QUERY from 'queries/recipientListQuery';

import MSTeamsRecipientList from './MSTeamsRecipientList';
import MSTeamsRecipientListItem from './MSTeamsRecipientListItem';

interface Props {
  recipientId?: string;
  onSelect: (recipientId?: string) => void;
  onClear: () => void;
}

export function MSTeamsRecipientControl(props: Props) {
  const { recipientId, onSelect, onClear } = props;

  const results = useQuery(RECIPIENTS_LIST_QUERY, {
    variables: { id: recipientId },
    skip: !recipientId,
    fetchPolicy: 'cache-and-network'
  });

  if (!recipientId) {
    return <MSTeamsRecipientList onSelect={onSelect} />;
  }

  return (
    <QueryResult
      {...results}
      skip={!recipientId}
      parseNodes={data => data?.recipients?.items}
      expectSingleNode
      loadingCenterVertically={false}
      loadingHeight={70}
    >
      {singleNode => {
        const recipient: ExistingNotificationRecipient = singleNode;

        return (
          <MSTeamsRecipientListItem
            recipient={recipient}
            action={<Button onClick={() => onClear()}>{'Select different Recipient'}</Button>}
          />
        );
      }}
    </QueryResult>
  );
}

export default MSTeamsRecipientControl;

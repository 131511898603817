import React from 'react';
import { Select, Button } from 'antd';
import * as Yup from 'yup';

import Form, { FormField } from 'components/ui/Form';
import { NewGuardrail } from 'typings';

import { GuardrailActionsSectionProps } from '../GuardrailActionsSection';
import ACTION_PROVIDER_DEFINITIONS, {
  getActionProviderKeyFromGuardrail,
  GuardrailActionProvider
} from '../definitions';
import { GuardrailEditorStep } from '../../../GuardrailEditor';
import { getIn } from 'formik';
import GuardrailSelectActionFunction from './GuardrailSelectActionFunction';
import FeatureFlag, { Feature } from 'components/app/FeatureFlag';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

const schema = Yup.object().shape({
  functionId: Yup.string('Please select Action'),
  automationFunctionId: Yup.string('Please select Action')
});

function getInitialValuesFromGuardrail(guardrail: NewGuardrail): any {
  const { functionId, automationFunctionId } = guardrail;
  const actionProviderKey = getActionProviderKeyFromGuardrail(guardrail);

  const initialValues = {
    actionProviderKey,
    functionId: functionId || undefined,
    automationFunctionId: automationFunctionId || undefined
  };

  return initialValues;
}

function GuardrailSelectAction(props: GuardrailActionsSectionProps) {
  const { setActiveStep, guardrail, saveGuardrailMutationFn } = props;

  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [
      {
        permissionId: Permissions.MODIFY_GUARDRAILS,
        projectIds: '*' // we will replace this with the guardrail.project_id when it exists.
      }
    ]
  });

  return (
    <Form
      validationSchema={schema}
      initialValues={getInitialValuesFromGuardrail(guardrail)}
      onSubmit={async (values, actions) => {
        const { functionId, automationFunctionId, actionProviderKey, jsonpathConfiguration } = values;

        const actionProvider = ACTION_PROVIDER_DEFINITIONS.find(i => i.key === actionProviderKey) || null;
        if (!actionProvider) return;

        const guardrailInput: any = {
          id: guardrail.id
        };

        if (actionProvider.usesAutomationFunctions) {
          guardrailInput.automationFunctionId = automationFunctionId;
        } else {
          guardrailInput.functionId = functionId;
        }

        if (jsonpathConfiguration) {
          guardrailInput.jsonpathConfiguration = jsonpathConfiguration;
        }

        const variables = {
          guardrail: guardrailInput
        };

        await saveGuardrailMutationFn({ variables });

        actions.setSubmitting(false);

        setActiveStep(GuardrailEditorStep.CONFIGURE_ACTION);
      }}
    >
      {formRenderProps => {
        const { canSubmit, isSubmitting, values } = formRenderProps;
        const actionProviderKey = getIn(values, 'actionProviderKey') || null;

        return (
          <>
            <FormField name="actionProviderKey" label="Action type">
              {({ value, handleChange, handleBlur }) => {
                return (
                  <FeatureFlag features={[Feature.GUARDRAILS_AUTOMATION_FUNCTION_ACTIONS]}>
                    {resolvedFlags => {
                      const availableActionProviders: GuardrailActionProvider[] = resolvedFlags[
                        Feature.GUARDRAILS_AUTOMATION_FUNCTION_ACTIONS
                      ]
                        ? ACTION_PROVIDER_DEFINITIONS
                        : ACTION_PROVIDER_DEFINITIONS.filter(item => item.key !== 'AUTOMATION_ACTION');

                      return (
                        <Select
                          value={value || undefined}
                          placeholder="Select Action type"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={!authz.isAuthorized}
                        >
                          {availableActionProviders.map(actionProvider => (
                            <Select.Option key={actionProvider.key} value={actionProvider.key}>
                              {actionProvider.label}
                            </Select.Option>
                          ))}
                        </Select>
                      );
                    }}
                  </FeatureFlag>
                );
              }}
            </FormField>

            {actionProviderKey && (
              <GuardrailSelectActionFunction
                guardrail={guardrail}
                actionProviderKey={actionProviderKey}
                authZ={authz}
              />
            )}
            <div>
              <Button
                block
                type="primary"
                disabled={!authz.isAuthorized || !canSubmit || isSubmitting}
                htmlType={'submit'}
                loading={isSubmitting}
              >
                {'Save and Continue'}
              </Button>
            </div>
          </>
        );
      }}
    </Form>
  );
}

export default GuardrailSelectAction;

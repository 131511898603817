import React from 'react';
import styled from 'styled-components';
import { Config, ConfigSetting } from 'typings';
import JSONTree from 'components/ui/JSONTree';
import { FunctionReadView } from 'components/function';
import { ALL_SETTINGS } from 'components/dynamicConfig/DynamicConfigProvider';
import DopeIcon from 'components/ui/DopeIcon';

import { Popover } from 'antd';
import { PopoverMenuItem } from 'components/ui/Popover';

const Root = styled.div`
  position: relative;
  background-color: ${p => p.theme.primary400};
  border-radius: 10px;
  padding: 10px;
  padding-right: 30px;
  line-height: 1em;

  .config-meta {
    color: #fff;
    display: inline-block;
    margin-bottom: 4px;

    .dynamic-config-icon {
      display: inline-block;
      margin-right: 6px;
    }
  }

  .config-value {
    padding: 6px 4px;
    font-size: 14px;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
  }

  .actions-menu {
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 50%;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      cursor: pointer;
    }

    .menu-icon {
      color: #fff;
    }
  }
`;

interface ConfigMetaProps {
  entityLabel?: string | null;
  settingLabel: string | null;
}

function ConfigMeta(props: ConfigMetaProps) {
  const { entityLabel, settingLabel } = props;

  return (
    <div className="config-meta">
      <DopeIcon name={'DYNAMIC_CONFIG_SELECT'} size={14} className="dynamic-config-icon" />
      {entityLabel && <span className="entity-label">{`${entityLabel}: `}</span>}
      {settingLabel && <span className="setting-label">{settingLabel}</span>}
    </div>
  );
}

interface ConfigValueProps {
  value: any;
  configSetting: ConfigSetting | null;
}

function ConfigValue(props: ConfigValueProps) {
  const { value, configSetting } = props;

  return (
    <div className="config-value">
      {configSetting ? <FunctionReadView value={value} functionParameter={configSetting} /> : <JSONTree data={value} />}
    </div>
  );
}

interface Props {
  attachedDynamicConfiguration: Config;
  showActionsMenu?: boolean;
  onRemove?: () => void;
  // onChange?: () => void;
  // onManageDynamicConfiguration?: () => void;
}
/**
 *
 * Actions:
 * - onManageDynamicConfiguration?:
 *
 */
function AttachedDynamicConfiguration(props: Props) {
  const {
    attachedDynamicConfiguration: { entityType, key, value },
    showActionsMenu = false,
    onRemove
  } = props;

  let entityLabel: string | null = null;
  const configSetting = ALL_SETTINGS.find(s => s.key === key) || null;

  if (entityType === 'ORGANIZATION') entityLabel = 'Root';
  if (entityType === 'PROJECT') entityLabel = 'Project';

  return (
    <Root>
      <ConfigMeta entityLabel={entityLabel} settingLabel={configSetting ? configSetting.name : key} />
      <ConfigValue value={value} configSetting={configSetting} />

      {showActionsMenu && (
        <Popover
          placement={'bottomLeft'}
          content={
            <div>
              {onRemove && <PopoverMenuItem label="Use custom value" onClick={onRemove} />}
              <PopoverMenuItem
                label="Manage Project Setting"
                link={`/projects/root/settings?editing=${key}`}
                target="_blank"
              />
            </div>
          }
          trigger="click"
        >
          <div className="actions-menu">
            <DopeIcon name="ARROW_DOWN" className="menu-icon" size={18} />
          </div>
        </Popover>
      )}
    </Root>
  );
}

export default AttachedDynamicConfiguration;

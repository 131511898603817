import { ApolloLink, NextLink } from 'apollo-link';

export default new ApolloLink((operation, forward: NextLink) => {
  if (operation.variables) {
    operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename);
  }

  return forward(operation);
});

function omitTypename(key: string, value: any) {
  return key === '__typename' ? undefined : value;
}

import { Button, Icon } from 'antd';
import ErrorAlert from 'components/ui/ErrorAlert';
import React, { useEffect, useState } from 'react';
import DelayedLoader from 'designSystem/DelayedLoader/DelayedLoader';
import { FilterType } from '../FilterListing/FilterListing';
import { Root } from './FilterRowStyles';

export interface SoftEdit {
  key: string;
  value: any;
}

interface FilterRowProps {
  filterType: FilterType;
  setFieldValue(field: string, value: any): void;
  display: React.ReactFragment;
  editor: React.ReactFragment;
  softEdit: SoftEdit | null;
  onDelete?: Function;
  loading?: boolean;
  error?: any;
  inEditMode?: boolean;
}

function FilterRow(props: FilterRowProps) {
  const { filterType, setFieldValue, display, editor, softEdit, onDelete, loading, error, inEditMode } = props,
    [editMode, setEditMode] = useState(inEditMode);

  function makeChanges() {
    if (!softEdit) return;

    setFieldValue(softEdit.key, softEdit.value);
  }

  function clearFilter() {
    setFieldValue(filterType.valueKey, []);
    onDelete && onDelete(filterType);
  }

  useEffect(makeChanges, [softEdit]);

  useEffect(() => {
    setEditMode(inEditMode);
  }, [inEditMode]);

  return (
    <Root>
      <div className={editMode ? 'filter-type-label is-editing' : 'filter-type-label'}>
        <div data-testid="filter-type-label" className="label">
          {filterType.label}
        </div>
      </div>
      <div className="main-contents">
        {error && <ErrorAlert showIcon={true} message={`Could not load ${filterType.label}`} error={error} />}
        {loading && <DelayedLoader />}
        {!editMode && !loading && !error && (
          <div data-testid="display-pane" className="display-pane">
            {display}
          </div>
        )}
        {editMode && !loading && !error && (
          <div data-testid="editor-pane" className="editor-pane">
            {editor}
          </div>
        )}
      </div>

      {editMode && (
        <div className="actions">
          <Button data-testid="clear-filter" type="link" onClick={clearFilter}>
            <Icon type="delete" />
          </Button>
        </div>
      )}
    </Root>
  );
}

export default FilterRow;

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { Sidenav, TitleBar } from 'components/ui/NeoPage';
import NeoPage from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';

import { Billing, GroupManagementPage, TeamManagementPage } from './pages';

import { NewNotificationPage, NotificationDetailPage, NotificationSection } from './pages/Notifications';
import IntegrationPage from './pages/Integration/Integration';

const navPages = [
  {
    label: 'Team management',
    to: '/organization-settings/team-management'
  },
  {
    label: 'Group management',
    to: '/organization-settings/group-management'
  },
  {
    label: 'Notifications',
    to: '/organization-settings/notifications'
  },
  {
    label: 'Integration',
    to: '/organization-settings/integration'
  },
  {
    label: 'Billing',
    to: '/organization-settings/billing'
  }
];

function OrganizationSettingsScreen(props: RouteComponentProps) {
  return (
    <NeoPage
      titleBar={
        <TitleBar
          sectionTitle="Organization settings"
          sectionTitleLinkTo="/organization-settings"
          icon={<DopeIcon name="ORGANIZATION" size="22" />}
        />
      }
      sidenav={<Sidenav navConfig={navPages} />}
    >
      <Switch>
        <Route path="/organization-settings/team-management" component={TeamManagementPage} />
        <Route path="/organization-settings/group-management/:groupId?" component={GroupManagementPage} />

        <Route path="/organization-settings/notifications/new" component={NewNotificationPage} />
        <Route path="/organization-settings/notifications/recipients" component={NotificationSection} />
        <Route path="/organization-settings/notifications/:id" component={NotificationDetailPage} />
        <Route path="/organization-settings/notifications" component={NotificationSection} />
        <Route path="/organization-settings/integration" component={IntegrationPage} />

        <Route path="/organization-settings/billing/:accountId?" component={Billing} />
        <Route path="/organization-settings" render={() => <Redirect to="/organization-settings/team-management" />} />
      </Switch>
    </NeoPage>
  );
}

export default OrganizationSettingsScreen;

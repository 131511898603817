import React from 'react';
import styled from 'styled-components';
import { Maybe } from 'typings';
import DopeIcon from 'components/ui/DopeIcon';

const Root = styled.div`
  display: flex;
  position: relative;
  padding-right: 24px;

  .header-title {
    margin-right: 8px;

    color: ${(p) => p.theme.grey600};
    font-weight: 500;
  }

  .header-content {
    display: flex;
    flex: 1;

    &,
    & .ant-typography {
      color: ${(p) => p.theme.grey900};
    }
  }

  .status-icon {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 18px;
    line-height: 20px;
    width: 34px;
    height: 20px;
    margin-top: -10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &.error {
      color: ${(p) => p.theme.error};
    }
  }
`;

interface Props {
  headerTitle?: Maybe<React.ReactNode | string>;
  headerContent?: Maybe<React.ReactNode | string>;

  classes?: Maybe<{
    root?: Maybe<string>;
  }>;
  errorMessage?: Maybe<string>;
}

function CollapseHeader(props: Props) {
  const classes = props.classes || {};
  const { headerTitle, headerContent, errorMessage } = props;

  return (
    <Root className={classes.root || ''}>
      {headerTitle && <div className="header-title">{headerTitle}</div>}
      {headerContent && <div className="header-content">{headerContent}</div>}

      {errorMessage && (
        <div className="status-icon error">
          <DopeIcon name="ERROR" />
        </div>
      )}
    </Root>
  );
}

export default CollapseHeader;

import React from 'react';
import { DetectorGeneralProps } from '../General';
import Markdown from 'components/ui/Markdown';
import { Collapse } from 'antd';
import { MoreInfoDiv } from './MoreInfo.styles';

const { Panel } = Collapse;

function DetectorMoreInfo(props: DetectorGeneralProps) {
  const { detector } = props;

  let overview = detector.assessor?.overview;

  if (overview) {
    overview = overview.replace('## Overview', '').replace('### Description:', '').trim();
  }

  return (
    <MoreInfoDiv>
      {overview && (
        <Collapse>
          <Panel header="more info" key={1}>
            <Markdown source={overview} />
          </Panel>
        </Collapse>
      )}
    </MoreInfoDiv>
  );
}

export default DetectorMoreInfo;

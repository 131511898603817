import gql from 'graphql-tag';

export const SLACK_SETTINGS_QUERY = gql`  
query slackSettings {
  slackSettings {
    appId
    clientId
  }
}`;

export const SLACK_AUTHORIZATION_MUTATION = gql`
  mutation authorize($request: SlackAuthorizationRequest!) {
    authorize(request: $request) {
      access_token
      incoming_webhook {
        channel
        channel_id
        configuration_url
        url
      }
      ok
      scope
      team_id
      team_name
      user_id
    }
  }
`;

import gql from 'graphql-tag';

const IAM_USERS_QUERY = gql`
  query IAMUserList($dops_full_text: String, $from: Int!, $size: Int!, $dops_internal_account_ids: [String]) {
    iam_users(
      dops_full_text: $dops_full_text
      from: $from
      size: $size
      dops_internal_account_ids: $dops_internal_account_ids
    ) {
      from
      size
      total_count
      results {
        user_id
        dops_name
        dops_resource_id
        dops_resource_type
        dops_resource_uid
        dops_internal_account_id
        groups {
          name
          arn
          group_id
          create_date
        }
        password_last_used
        access_keys {
          access_key_id
          status
          create_date
          id
        }
        mfa_devices {
          serial_number
          user_name
          enable_date
        }
        dops_issue_summary {
          open_count
        }
      }
    }
  }
`;

export default IAM_USERS_QUERY;

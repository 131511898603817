import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

function DelayedLoader() {
  const [stillLoading, setStillLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setStillLoading(true), 1000);

    return function cleanup() {
      clearTimeout(timer);
    };
  }, []);

  return <>{stillLoading && <Spin />}</>;
}

export default DelayedLoader;

import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Button, Popconfirm, message, Tooltip } from 'antd';

import { USER_LIST_QUERY } from 'queries';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { ButtonProps } from 'antd/lib/button';
import { DataProxy } from 'apollo-cache';

export interface Props extends ButtonProps {
  username: string;
  queryVariables: any;
}

const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($email: String!) {
    deleteUser(username: $username) @rest(type: "Delete", path: "/invite/{args.username}", method: "DELETE")
  }
`;

function DeleteUserButton(props: Props) {
  const { username, queryVariables, ...buttonProps } = props;

  const { isAuthorized, message: missingPermissionsMessage } = useAuthorizor(Permissions.MODIFY_USERS, []);
  const [deleteUser, { loading }] = useMutation(DELETE_USER_MUTATION);

  const updateCache = (proxy: DataProxy) => {
    const query = USER_LIST_QUERY;

    const oldData: any = proxy.readQuery({ query, variables: queryVariables });
    const {
      userList: { page, total, per_page, items, __typename }
    } = oldData;

    const data = {
      userList: {
        page,
        total: total - 1,
        per_page,
        items,
        __typename
      }
    };

    if (data && data.userList && data.userList.items) {
      data.userList.items = data.userList.items.filter((item) => item.username !== username);
    }

    proxy.writeQuery({ query, data, variables: queryVariables });
  };

  return !isAuthorized ? (
    <Tooltip title={missingPermissionsMessage || ''} placement="left">
      <Button {...buttonProps} icon="delete" disabled />
    </Tooltip>
  ) : (
    <Popconfirm
      title={`Are you sure you want to delete user ${username}?`}
      okText="Delete"
      onConfirm={async () => {
        await deleteUser({
          variables: { username },
          update: updateCache
        });

        message.success(`Successfully deleted user ${username}.`);
      }}
      placement={'left'}
    >
      <Button icon="delete" disabled={loading} loading={loading} />
    </Popconfirm>
  );
}

export default DeleteUserButton;

import React, { SFC, Children } from 'react';
import ProgressCircular from 'components/ui/ProgressCircular';
import ErrorAlert from 'components/ui/ErrorAlert';
import { CenteredContainer } from 'components/ui/NeoPage';

interface QueryResultProps {
  data?: any;
  dataCheck?: boolean;
  loading: boolean;
  error?: Error;
  children: (entities?: any) => any; //Hack, apparently SFC doesn't support returning a function that returns children
  loadingMessage?: string | React.ReactNode;
  errorMessage?: string;
  entityName?: string;
  loadingCenterVertically?: boolean;
  loadingHeight?: string | number;
  size?: 'small' | 'large' | 'default';
  expectSingleNode?: boolean;
  parseNodes?: (data: any) => any[];

  skip?: boolean; //
}

const QueryResult: SFC<QueryResultProps> = (props: QueryResultProps) => {
  const {
    loading,
    loadingMessage,
    error,
    children,
    errorMessage,
    data,
    dataCheck,
    entityName,
    loadingCenterVertically = true, // defaults to true.
    loadingHeight = 300,
    size = 'large',
    expectSingleNode = false,
    parseNodes,
    skip = false
  } = props;

  if (skip) return Children.only(children());

  const entityLoadingMessage = entityName && `Loading ${entityName.toLowerCase()}`;
  const entityErrorMessage = entityName && `There was an error loading ${entityName.toLowerCase()}`;

  const hasData = data && Object.keys(data).length > 0;
  const hasTheRightData = dataCheck && hasData;

  if (error) {
    return (
      <CenteredContainer>
        <ErrorAlert message={errorMessage || entityErrorMessage} error={error} />
      </CenteredContainer>
    );
  }

  if (loading && !hasTheRightData) {
    return (
      <ProgressCircular
        centerVertically={loadingCenterVertically}
        height={loadingHeight}
        title={loadingMessage || entityLoadingMessage || ''}
        size={size}
      />
    );
  }

  if (expectSingleNode && parseNodes) {
    let nodes;

    try {
      nodes = parseNodes(data);
    } catch (err) {
      return (
        <CenteredContainer>
          <ErrorAlert message="An error occurred parsing Query results." error={err} />
        </CenteredContainer>
      );
    }

    if (!nodes || nodes.length === 0) {
      const err = new Error(`${entityName ? entityName : 'Entity'} not found.`);

      return (
        <CenteredContainer>
          <ErrorAlert error={err} />
        </CenteredContainer>
      );
    }

    if (nodes.length > 1) {
      const err = new Error(`More entities (${nodes.length}) than expected (1) were returned from Query.`);

      return (
        <CenteredContainer>
          <ErrorAlert error={err} />
        </CenteredContainer>
      );
    }

    return Children.only(children(nodes[0]));
  }

  if (parseNodes) {
    try {
      const nodes = parseNodes(data);

      return Children.only(children(nodes));
    } catch (e) {
      return (
        <CenteredContainer>
          <ErrorAlert message="An error occurred parsing Query results." error={e} />
        </CenteredContainer>
      );
    }
  }

  return Children.only(children());
};

export default QueryResult;

import { Popover } from 'antd';
import { SnsRecipientControl } from 'app/sections/Authenticated/pages/OrganizationSettings/pages/Notifications/components/NotificationForm/SnsRecipient';
import EmailRecipientFormControls from 'components/email/EmailRecipientFormControls';
import JiraRecipientFormControls from 'components/jira/JiraRecipientFormControls';
import MSTeamsRecipientFormControls from 'components/msTeams/MSTeamsRecipientFormControls';
import SlackRecipientFormControls from 'components/slack/SlackRecipientFormControls';
import DopeIcon from 'components/ui/DopeIcon';
import { FormField } from 'components/ui/Form';
import { FormRenderProps } from 'components/ui/Form/Form';
import React from 'react';
import { Link } from 'react-router-dom';
import { Recipient } from 'typings';
import * as Yup from 'yup';
import theme from '../../constants/theme';

export interface RecipientFormControlProps {
  formRenderProps: FormRenderProps;
  prefix?: string; // 'recipient[0].config
}

export interface RecipientTypeDefinition {
  key: string; // SLACK | SNS
  label: string;
  icon: React.ReactNode;
  getLabel: (recipient: Recipient) => string;
  formControls: (props: RecipientFormControlProps) => JSX.Element;
  configValidationShape?: any;
}

interface RecipientIconProps {
  recipient: any;
  missingProjectDefault?: boolean;
  projectId?: string;
  size?: number;
}

export function RecipientIcon(props: RecipientIconProps) {
  const { recipient, missingProjectDefault = false, projectId, size = 20 } = props;
  return (
    <>
      {!recipient && missingProjectDefault && (
        <Popover
          title={'Project Default Not Found'}
          content={'Please configure a project default or select another recipient.'}
          placement="top"
        >
          <Link to={`/projects/${projectId}/settings`}>
            <DopeIcon name="WARNING" size={20} title="Project Default" color={theme.error} />
          </Link>
        </Popover>
      )}
      {!recipient && !missingProjectDefault && <DopeIcon name="PROJECT" size={size} title="Project Default" />}
      {recipient?.type === 'JIRA' && <DopeIcon className="icon" name="JIRA" size={size} title="Jira" />}
      {recipient?.type === 'MS_TEAMS' && (
        <DopeIcon className="icon" name="MICROSOFT_TEAMS" size={size} title="Microsoft Teams" />
      )}
      {recipient?.type === 'SLACK' && <DopeIcon className="icon" name="SLACK" size={size} title="Slack" />}
      {recipient?.type === 'SNS' && <DopeIcon className="icon" name="AWS_SNS" size={size} title="AWS SNS" />}
      {recipient?.type === 'EMAIL' && <DopeIcon className="icon" name="ENVELOPE" size={size} title="EMAIL" />}
    </>
  );
}

export const RecipientLabels = {
  JIRA: (recipient) =>
    `Project: ${recipient.config?.projectKey}${
      recipient.config?.priority ? `, Priority: ${recipient.config?.priority}` : ''
    }`,
  MS_TEAMS: (recipient) => recipient.config?.context?.channelName,
  SLACK: (recipient) => recipient.config.channel,
  SNS: (recipient) => recipient.config.topicArn,
  EMAIL: (recipient) => recipient.config?.toAddresses?.join('; ')
};

export const RecipientTypes = {
  JIRA: 'Jira',
  MS_TEAMS: 'Microsoft Teams',
  SLACK: 'Slack',
  SNS: 'AWS SNS',
  EMAIL: 'Email'
};

export const RECIPIENT_TYPE_DEFINITIONS: RecipientTypeDefinition[] = [
  {
    key: 'SLACK',
    label: 'Slack',
    icon: <DopeIcon name="SLACK" size={26} />,
    getLabel: (recipient) => {
      const { channel, username } = recipient.config;

      return `Slack: ${channel} (${username})`;
    },
    formControls: SlackRecipientFormControls,
    configValidationShape: Yup.object()
      .shape({
        channel: Yup.string().required(),
        url: Yup.string().required(),
        username: Yup.string().required()
      })
      .required()
  },
  {
    key: 'JIRA',
    label: 'Jira',
    icon: <DopeIcon name="JIRA" size={26} />, // change.
    getLabel: (recipient) => {
      const { projectKey, priority } = recipient.config;

      return `Jira Project: ${projectKey} (${priority})`;
    },
    formControls: JiraRecipientFormControls,
    configValidationShape: Yup.object()
      .shape({
        url: Yup.string().required(),
        projectKey: Yup.string().required(),
        projectType: Yup.string().required(),
        priority: Yup.string(),
        user: Yup.string().required(),
        apiToken: Yup.string().required()
      })
      .required()
  },
  {
    key: 'SNS',
    label: 'SNS',
    icon: <DopeIcon name="MESSAGE" size={26} />, // change.
    getLabel: (recipient) => {
      const { topicArn } = recipient.config;

      return `SNS: ${topicArn}`;
    },
    formControls: SNSRecipientFormControls,
    configValidationShape: Yup.object()
      .shape({
        topicArn: Yup.string().required()
      })
      .required()
  },
  {
    key: 'MS_TEAMS',
    label: 'Microsoft Teams Connector',
    icon: <DopeIcon name="MICROSOFT_TEAMS" size={26} />,
    getLabel: (recipient) => {
      const { channelName, teamName } = recipient.config.context;

      return `Microsoft Teams Connector: #${channelName} (${teamName})`;
    },
    formControls: MSTeamsRecipientFormControls
  },
  {
    key: 'EMAIL',
    label: 'Email',
    icon: <DopeIcon name="ENVELOPE" size={26} />,
    getLabel: (recipient) => {
      const { toAddresses } = recipient.config;

      return `Email: (${toAddresses.join('; ')})`;
    },
    formControls: EmailRecipientFormControls
  }
];

function SNSRecipientFormControls(props: RecipientFormControlProps) {
  const { prefix = 'config' } = props;
  return (
    <FormField name={`${prefix}.topicArn`} label="Topic ARN">
      {(formFieldProps) => {
        const { value, handleChange, handleBlur } = formFieldProps;

        return <SnsRecipientControl value={value} onChange={handleChange} onBlur={handleBlur} />;
      }}
    </FormField>
  );
}

export function truncate(
  value: string | null | undefined,
  options: { maxLength?: number; ellipsis?: string }
): string | null | undefined {
  if (!value) {
    return value;
  }

  const { maxLength = 25, ellipsis = '...' } = options;

  if (value.length <= maxLength) {
    return value;
  }

  return `${value.substring(0, maxLength)}${ellipsis}`;
}

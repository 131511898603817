import React from 'react';
import { Select as AntSelect } from 'antd';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import { size } from 'typings';

const REGIONS_QUERY = gql`
  query RegionList {
    regions {
      name
    }
  }
`;

interface Props {
  name: string;
  value: string[] | string;
  placeholder?: string;

  multiple?: boolean;
  allowClear?: boolean;

  setFieldTouched: (name: string, touched: boolean) => any;
  setFieldValue: (name: string, value: any) => any;
  size?: size;
}

function AWSRegionSelect(props: Props) {
  const {
    name,
    value,
    setFieldTouched,
    setFieldValue,
    multiple = false,
    size = 'large',
    placeholder,
    allowClear = true
  } = props;

  const handleChange = newValue => setFieldValue(name, newValue);
  const handleBlur = () => setFieldTouched(name, true);

  return (
    <Query query={REGIONS_QUERY}>
      {results => {
        const { loading, error, data } = results;

        return (
          <AntSelect
            mode={!loading && multiple ? 'multiple' : 'default'}
            size={size}
            value={value}
            style={{ width: '100%' }}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={loading || !!error}
            placeholder={!error ? placeholder || 'All regions' : 'Error loading regions'}
            loading={loading}
            allowClear={allowClear}
          >
            {!loading &&
              data &&
              data.regions &&
              data.regions.map(region => {
                return <AntSelect.Option key={region.name}>{region.name}</AntSelect.Option>;
              })}
          </AntSelect>
        );
      }}
    </Query>
  );
}

export default AWSRegionSelect;

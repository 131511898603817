import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Input, Button, Alert } from 'antd';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { Mutation, MutationFunction } from 'react-apollo';

import OnboardingPanel from '../../components/OnboardingPanel';
import { Form, FormField } from 'components/ui/Form';
import { FormikActions } from 'formik';

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($input: ForgotPasswordInput) {
    forgotPassword(input: $input) @rest(method: "POST", path: "/auth/forgot-password")
  }
`;

const FormSchema = Yup.object().shape({
  username: Yup.string().required('Required')
});

interface FormFields {
  username: string;
}

const FormContent = styled.div`
  width: 100%;

  .success-alert p {
    margin-bottom: 0;
  }

  .reset-link {
    display: inline-block;
    margin-left: 8px;
  }
`;

const ActionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .submit-btn {
    margin-bottom: 16px;
  }
`;

interface Props {}

interface State {
  successUsername: string | null;
  error: any;
}

class RecoverAccountPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      successUsername: null,
      error: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(forgotPassword: MutationFunction) {
    return async (values: Partial<FormFields>, actions: FormikActions<any>) => {
      try {
        this.setState({ error: null });

        const { username } = values as FormFields;
        const input = { username };
        await forgotPassword({ variables: { input } }); // return "OK"

        this.setState({ successUsername: username });

        actions.setSubmitting(false);
      } catch (error) {
        this.setState({ error }, () => {
          actions.setSubmitting(false);
        });
      }
    };
  }

  render() {
    const { successUsername, error } = this.state;

    return (
      <OnboardingPanel withLogo title="Recover password">
        <Mutation mutation={FORGOT_PASSWORD}>
          {forgotPassword => (
            <Form
              validationSchema={FormSchema}
              initialValues={{ email: '' }}
              onSubmit={this.handleSubmit(forgotPassword)}
            >
              {formikBag => {
                const { canSubmit, isSubmitting } = formikBag;

                return (
                  <FormContent>
                    {successUsername && (
                      <Alert
                        className="success-alert"
                        type="success"
                        showIcon
                        message={
                          <p>
                            {`An email has been sent to the address associate with username: "${successUsername}" with a confirmation code to reset your password.`}
                            <Link
                              className={'reset-link'}
                              to={{
                                pathname: '/reset-password',
                                state: {
                                  email: successUsername
                                }
                              }}
                            >
                              {'Reset password'}
                            </Link>
                          </p>
                        }
                      />
                    )}

                    {error && (
                      <Alert
                        type="error"
                        showIcon
                        message="This username could not be confirmed. Please make sure the username submitted is registered with DisruptOps"
                      />
                    )}

                    <FormField name="username" label="Username">
                      {({ name, value, handleChange, handleBlur }) => (
                        <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} required />
                      )}
                    </FormField>

                    <ActionsWrap>
                      <Button
                        disabled={!canSubmit || isSubmitting}
                        type="primary"
                        htmlType="submit"
                        className="submit-btn"
                      >
                        {'Submit'}
                      </Button>
                      <Link to="/login">{'Return to login'}</Link>
                    </ActionsWrap>
                  </FormContent>
                );
              }}
            </Form>
          )}
        </Mutation>
      </OnboardingPanel>
    );
  }
}

export default RecoverAccountPage;

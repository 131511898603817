import Auth from '@aws-amplify/auth';
import * as Cognito from 'amazon-cognito-identity-js';
import jwtDecode from 'jwt-decode';

import api from './api';
import cache from './graphql/cache';
import graphqlClient from './graphql/client';

import {
  CognitoIdToken,
  CognitoAccessToken,
  CognitoRefreshToken,
  ICognitoUserSessionData
} from 'amazon-cognito-identity-js';

const CognitoUser = Cognito.CognitoUser as any;

Auth.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
});

export function parseUserPoolId(idToken: any) {
  if (!idToken) return;

  let tokenIssuerURL;

  try {
    tokenIssuerURL = new URL(idToken.iss);
  } catch (e) {
    return;
  }

  return tokenIssuerURL.pathname.replace(/^\//, '');
}

function configAuth() {
  const id_token = localStorage.getItem('id_token');

  if (!id_token) return null;

  let idToken;

  try {
    idToken = jwtDecode(id_token);
  } catch (e) {
    return;
  }

  const userPoolId = parseUserPoolId(idToken);

  return Auth.configure({
    userPoolId,
    userPoolWebClientId: idToken.aud
  });
}

export function getAccessToken() {
  configAuth();

  return Auth.currentSession().then((session: any) =>
    session && session.accessToken ? session.accessToken : undefined
  );
}

export function getIdentity(): any {
  if (!configAuth()) return Promise.resolve();

  return new Promise((resolve, reject) => {
    Auth.currentAuthenticatedUser()
      .then((data) => {
        if (!data) return resolve(createEmptyIdentity());
        const { username, pool, attributes } = data;
        const { email } = attributes;
        const { userPoolId } = pool;

        const identity = {
          user_id: username,
          email: email,
          user_pool_id: userPoolId,
          __typename: 'Identity'
        };

        resolve(identity);
      })
      .catch((err) => {
        resolve(createEmptyIdentity());
      });
  }).then((identity) => {
    //This is structured like this so that we can update the cache no matter the results (empty or not)
    return new Promise((resolve, reject) => {
      updateIdentityInCache(identity);
      resolve(identity);
    });
  });
}

interface TokenBundle {
  refresh_token: string;
  access_token: string;
  id_token: string;
  ws_token: string;
}

export async function createSession(tokenBundle: TokenBundle) {
  return new Promise((resolve, reject) => {
    const { id_token, access_token, refresh_token, ws_token } = tokenBundle;

    if (!id_token || !access_token || !refresh_token) return reject(new Error('Could not create session'));

    localStorage.setItem('ws_token', ws_token);
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('id_token', id_token);
    localStorage.setItem('refresh_token', refresh_token);

    const idToken = jwtDecode(id_token);
    const tokenIssuerURL = new URL(idToken.iss);

    const cognitoPool = new Cognito.CognitoUserPool({
      ClientId: idToken.aud,
      UserPoolId: tokenIssuerURL.pathname.replace(/^\//, '')
    });

    const cognitoUser = new CognitoUser({
      Username: idToken['cognito:username'],
      Pool: cognitoPool
    });

    cognitoUser.authenticateUserInternal(
      {
        AuthenticationResult: {
          IdToken: id_token,
          AccessToken: access_token,
          RefreshToken: refresh_token
        }
      },
      {},
      {
        onSuccess: (result) => {
          configAuth();
          resolve(result);
        }
      }
    );
  });
}

interface Credentials {
  username: string;
  password: string;
}

// signin with username password
export async function signIn(credentials: Credentials) {
  const { username, password } = credentials;
  const response = await api.signIn(username, password);
  const { trinity_data, access_token, refresh_token, id_token } = response.data;

  if (!id_token || !access_token || !refresh_token) {
    throw new Error('Login failed.');
  }

  await createSession({ refresh_token, access_token, id_token, ws_token: trinity_data });

  return getIdentity();
}

export async function signOut() {
  localStorage.removeItem('ws_token');
  localStorage.removeItem('access_token');
  localStorage.removeItem('id_token');
  localStorage.removeItem('refresh_token');

  localStorage.setItem('redirectAfterLogin', window.location.pathname);

  updateIdentityInCache(createEmptyIdentity());
  await Auth.signOut();
  await api.signOut();

  // We have to call both of these because clearStore
  // does not trigger the `onResetStore` callback.
  // Calling resetStore alone will refetch all the queries
  // Which is not what you want when signing out.
  graphqlClient.clearStore();
  graphqlClient.resetStore();

  // Technically, if we're clearing all the data correctly,
  // this should no longer be necessary
  window.location.href = '/login';
}

function updateIdentityInCache(identity: any) {
  if (identity.custom) delete identity.custom;
  cache.writeData({
    id: 'Identity',
    data: identity || null
  });
}

export function createEmptyIdentity() {
  return {
    user_id: null,
    email: null,
    user_pool_id: null,
    __typename: 'Identity'
  };
}

/**
 * Refreshes the current user's Cognito session.
 * This will generate a new id and access token, causing the Public API authorizer to expire the user's Authorization
 * header value from it's cache, and refresh the user's DisruptOps JWT from Trinity API's token exchange.
 *
 * We should call this method anytime accounts, permissions, or projects are mutated.
 * The net effect is to ensure the behavior of the application immediately reflects the current state of authZ,
 * which is stored in that
 */
export async function refreshCognitoSession(): Promise<ICognitoUserSessionData | undefined> {
  const currentUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  try {
    const updatedSession = await new Promise(async (resolve, reject) => {
      currentUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
        if (err) return reject(err);
        return resolve(session);
      });
    });

    const { idToken, accessToken, refreshToken } = updatedSession as any;

    localStorage.setItem('id_token', idToken.jwtToken);
    localStorage.setItem('access_token', accessToken.jwtToken);
    localStorage.setItem('refresh_token', refreshToken ? refreshToken.token : '');

    // TODO: Figure out if ws_token is still used, and if so how can we get that back?
    // It's a funky mashup returned from the trinity login, so we'll need to either kill it, or update the token-exchange
    // adding that to it's payload, not sure what other options we have.

    return Promise.resolve({
      IdToken: new CognitoIdToken({ IdToken: idToken.jwtToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: accessToken.jwtToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: refreshToken.token })
    });
  } catch (err) {
    console.error(`Failed to refresh the user's Cognito session. Error: ${err.message}`);
  }

  return Promise.resolve(undefined);
}

import React from 'react';
import styled from 'styled-components';
import PageBreadcrumbs, { Breadcrumb } from './PageBreadcrumbs';

const Root = styled.div`
  margin-bottom: 8px;

  &.level-1 {
    margin-bottom: 16px;
    margin-top: 28px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const PageTitle = styled.h1`
  flex: 1;
  margin-bottom: 0;
  vertical-align: middle;

  .level-1 & {
    font-size: 30px;
    line-height: 1;
  }

  .level-3 & {
    font-size: 18px;
  }
`;

const ActionsWrap = styled.div`
  display: flex;

  > * {
    margin-left: 8px;
  }
`;

interface Props {
  title?: React.ReactNode | null;
  titleLevel?: 1 | 2 | 3;
  actions?: React.ReactNode | React.ReactNodeArray;
  breadcrumbs?: Breadcrumb[];
  classes?: {
    root?: string;
    actions?: string;
  };
}

function PageHeader(props: Props) {
  const { title, titleLevel = 1, actions, breadcrumbs, classes = {} } = props;

  return (
    <Root className={`page-header ${classes.root || ''} level-${titleLevel}`}>
      {breadcrumbs && <PageBreadcrumbs breadcrumbs={breadcrumbs} />}
      <TitleRow>
        {title && <PageTitle className={`page-title level-${titleLevel}`}>{title}</PageTitle>}
        {actions && <ActionsWrap className={`page-header-actions ${classes.actions || ''}`}>{actions}</ActionsWrap>}
      </TitleRow>
    </Root>
  );
}

export default PageHeader;

import React, { useState } from 'react';
import gql from 'graphql-tag';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Mutation, Query } from 'react-apollo';
import { Switch, message, Popover, Tag, Tooltip, Checkbox, Modal, Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';

import { NewGuardrail, GuardrailTrigger, Maybe, PageInfo } from 'typings';

import Paginator from 'components/app/Paginator';
import DopeIcon from 'components/ui/DopeIcon';
import IconButton from 'components/ui/IconButton';
import { CenteredContainer } from 'components/ui/NeoPage';
import { PopoverMenuItem } from 'components/ui/Popover';
import Table from 'components/ui/Table';
import QueryResult from 'components/util/QueryResult';

import { GUARDRAIL_FIELDS } from 'queries/fragments/guardrailFields';
import { ValidationError } from 'services/graphql/state/guardrails';
import { findTriggerEventTypeDefinition } from 'components/guardrails/GuardrailEditor/sections/trigger/definitions';
import styled from 'styled-components';
import DeleteGuardrailModal from 'components/guardrails/DeleteGuardrailModal';
import ArchiveGuardrailModal from 'components/guardrails/ArchiveGuardrailModal';

const GUARDRAIL_LIST = gql`
  query GuardrailList(
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $isArchived: Boolean
  ) {
    guardrails(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      isArchived: $isArchived
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        ...GuardrailFields
      }
    }
  }
  ${GUARDRAIL_FIELDS}
`;

const SHOW_ARCHIVED_GUARDRAILS = 'showArchivedGuardrails';
const FILTERS = [
  {
    clientFiltered: false, // ??
    qsKey: SHOW_ARCHIVED_GUARDRAILS,
    name: SHOW_ARCHIVED_GUARDRAILS, // not sure how this is different than qs key.
    label: 'Show Archived Guardrail Activity', // this shouldn't show up?
    many: false
  }
];

const SORT_BY_MAP = {
  name: 'name',
  isEnabled: 'is_enabled'
};

const SORT_ORDER_MAP = {
  ascend: 'ASC',
  descend: 'DESC'
};

const Root = styled.div`
  .filter-tag {
    padding: 2px 7px;
    margin-right: 4px;

    .filter-tag-content {
      display: flex;

      > * {
        flex-grow: 0;
      }

      > svg {
        display: inline-block;
        margin-right: 2px;
        margin-top: 2px;
      }
    }
  }
  .filter-options {
    margin-bottom: 8px;
    display: flex;
    justify-content: flex-end;
  }
`;

interface Props extends RouteComponentProps {}

function GuardrailList(props: Props) {
  const [guardrailToArchive, setGuardrailToArchive] = useState<NewGuardrail | null>(null);
  const [guardrailToDelete, setGuardrailToDelete] = useState<NewGuardrail | null>(null);

  return (
    <Root>
      <Paginator pageSize={100} filters={FILTERS}>
        {pageRenderProps => {
          const {
            pageNumber,
            pageSize,
            sortBy,
            sortDirection,
            updateSearchParams,
            updateFilter,
            filterValues
          } = pageRenderProps;
          const showArchivedGuardrails = filterValues[SHOW_ARCHIVED_GUARDRAILS] === 'true';
          const queryVariables = {
            pageNumber,
            pageSize,
            sortBy,
            sortDirection,
            isArchived: showArchivedGuardrails ? undefined : false
          };

          return (
            <CenteredContainer>
              <div className="filter-options">
                <Checkbox
                  children="Hide archived Guardrails"
                  checked={!showArchivedGuardrails}
                  onChange={e => {
                    const checked = e.target.checked;
                    // console.log({ checked });
                    updateFilter(SHOW_ARCHIVED_GUARDRAILS, checked ? 'false' : 'true');
                  }}
                />
              </div>
              {/* SEARCH IS NOT SUPPORTED YET */}
              {/* <FilterRow>
                    <FilterItem label="Search">
                      <SearchFilter search={search} onSearchChange={updateSearch} />
                    </FilterItem>
                  </FilterRow> */}
              <Query query={GUARDRAIL_LIST} variables={queryVariables} fetchPolicy="cache-and-network">
                {({ loading, data, error, refetch }) => {
                  return (
                    <>
                      <QueryResult loading={loading} data={data} error={error}>
                        {() => {
                          const guardrails: NewGuardrail[] = data.guardrails.nodes;
                          const pageInfo: PageInfo = data.guardrails.pageInfo;

                          const columns: ColumnProps<NewGuardrail>[] = [
                            {
                              title: 'Name',
                              key: 'name',
                              dataIndex: 'name',
                              sorter: true,
                              render: (name, item) => {
                                return (
                                  <Link to={`/guardrails/details/${item.id}`}>
                                    {item.archivedAt && '(ARCHIVED) - '}
                                    {name || 'Untitled Guardrail'}
                                  </Link>
                                );
                              }
                            },
                            {
                              title: 'Trigger Type',
                              key: 'trigger',
                              dataIndex: 'trigger',
                              render: (trigger: GuardrailTrigger) => {
                                // could we memoize this?
                                if (!trigger) return 'Issue Created';

                                const triggerDefinition = findTriggerEventTypeDefinition(
                                  trigger.eventSource,
                                  trigger.eventType
                                );

                                if (!triggerDefinition) return 'Issue Created';

                                return triggerDefinition.name;
                              }
                            },
                            {
                              title: 'Action',
                              key: 'action',
                              dataIndex: 'actor',
                              render: (actor: Function) => {
                                if (!actor) return 'No Action selected';

                                return actor.name;
                              }
                            },
                            {
                              title: 'Filters',
                              key: 'filters',
                              dataIndex: 'trigger',
                              render: (trigger, guardrail) => {
                                const { filtersConfiguration } = guardrail;

                                if (!trigger || !filtersConfiguration) return '--';

                                const triggerDefinition = findTriggerEventTypeDefinition(
                                  trigger.eventSource,
                                  trigger.eventType
                                );

                                if (!triggerDefinition || !triggerDefinition.filters) return '--';

                                return (
                                  <>
                                    {triggerDefinition.filters.map(filter => {
                                      const { key } = filter;

                                      if (!filter.collapseTitleReadView || !filtersConfiguration[key]) return null;

                                      const tag = (
                                        <Tag className="filter-tag" key={filter.key}>
                                          <div className={'filter-tag-content'}>
                                            <DopeIcon name="FILTER" />
                                            {filter.collapseTitleReadView({ value: filtersConfiguration[key] })}
                                          </div>
                                        </Tag>
                                      );

                                      return filter.getCollapseTitle ? (
                                        <Tooltip
                                          placement="left"
                                          title={filter.getCollapseTitle(filtersConfiguration[key])}
                                          key={filter.key}
                                        >
                                          {tag}
                                        </Tooltip>
                                      ) : (
                                        tag
                                      );
                                    })}
                                  </>
                                );
                              }
                            },
                            {
                              title: 'Enabled',
                              key: 'isEnabled',
                              dataIndex: 'isEnabled',
                              sorter: true, // refer to "SORTER_MAP"
                              render: (isEnabled, item) => {
                                return (
                                  <Mutation
                                    mutation={gql`
                                      mutation EnableGuardrailFromGuardrails($guardrailId: ID!, $isEnabled: Boolean!) {
                                        enableGuardrail(guardrailId: $guardrailId, isEnabled: $isEnabled) @client {
                                          validationError {
                                            errors
                                          }
                                          updatedGuardrail {
                                            id # In theory this should update the UI.
                                            isEnabled
                                          }
                                        }
                                      }
                                    `}
                                  >
                                    {(enableGuardrail, { loading }) => (
                                      <Switch
                                        checked={isEnabled}
                                        loading={loading}
                                        onChange={async checked => {
                                          const result = await enableGuardrail({
                                            variables: {
                                              guardrailId: item.id,
                                              isEnabled: checked
                                            }
                                          });

                                          const updateResult: {
                                            updatedGuardrail?: Maybe<NewGuardrail>;
                                            validationError?: Maybe<ValidationError>;
                                          } = result.data.enableGuardrail;

                                          if (updateResult.validationError) {
                                            message.error(
                                              'Guardrail could not be enabled. Make sure it is properly configured'
                                            );
                                          } else {
                                            message.success(`Guardail was ${checked ? 'enabled' : 'disabled'}`);
                                          }
                                        }}
                                      />
                                    )}
                                  </Mutation>
                                );
                              }
                            },
                            {
                              key: 'options',
                              render: (_val, item) => {
                                return (
                                  <Popover
                                    placement="rightBottom"
                                    content={
                                      <div className="menu">
                                        <PopoverMenuItem label={'Edit'} link={`/guardrails/details/${item.id}`} />
                                        {item.archivedAt ? (
                                          <Mutation
                                            mutation={gql`
                                              mutation UnarchiveGuardrailFromGuardrailList($id: ID!) {
                                                unarchiveGuardrail(id: $id) {
                                                  id
                                                  archivedAt
                                                }
                                              }
                                            `}
                                          >
                                            {(unarchiveGuardrail, { loading: unarchiving }) => {
                                              return (
                                                <PopoverMenuItem
                                                  label={unarchiving ? 'Unarchiving' : 'Unarchive'}
                                                  disabled={unarchiving}
                                                  onClick={async () => {
                                                    try {
                                                      await unarchiveGuardrail({
                                                        variables: { id: item.id }
                                                      });

                                                      message.success('Guardrail has been unarchived.');
                                                    } catch (e) {
                                                      message.error('There was an error unarchiving this Guardrail.');
                                                    }
                                                  }}
                                                />
                                              );
                                            }}
                                          </Mutation>
                                        ) : (
                                          <PopoverMenuItem
                                            onClick={() => {
                                              setGuardrailToArchive(item);
                                            }}
                                            disabled={loading}
                                            label={!loading ? 'Archive' : 'Archiving'}
                                          />
                                        )}
                                        {item.archivedAt && (
                                          <PopoverMenuItem
                                            onClick={() => setGuardrailToDelete(item)}
                                            disabled={loading}
                                            label={'Delete'}
                                          />
                                        )}
                                      </div>
                                    }
                                    trigger="click"
                                  >
                                    <IconButton type="ghost" size="small">
                                      <DopeIcon name="MORE_VERTICAL" />
                                    </IconButton>
                                  </Popover>
                                );
                              }
                            }
                          ];

                          return (
                            <Table
                              antTableProps={{
                                rowKey: 'id',
                                columns,
                                loading: loading,
                                dataSource: guardrails,
                                locale: {
                                  emptyText: 'No matching Assessments'
                                },
                                onChange: (pagination, filters, sorter, a) => {
                                  updateSearchParams({
                                    sortBy: SORT_BY_MAP[sorter.columnKey] || 'updated_at',
                                    sortDirection: SORT_ORDER_MAP[sorter.order] || SORT_ORDER_MAP.descend,
                                    page: pagination.current || 1
                                  });
                                },
                                pagination: {
                                  pageSize,
                                  current: pageInfo && pageInfo.current,
                                  total: pageInfo && pageInfo.total,
                                  onChange: pageNumber => {
                                    updateSearchParams({
                                      page: pageNumber
                                    });
                                  }
                                }
                              }}
                            />
                          );
                        }}
                      </QueryResult>

                      <Mutation
                        mutation={gql`
                          mutation ArchiveGuardrail($id: ID!) {
                            archiveGuardrail(id: $id) {
                              id
                              archivedAt
                            }
                          }
                        `}
                      >
                        {(archiveGuardrail, { loading, archiving }) => {
                          return (
                            <Modal
                              visible={Boolean(guardrailToArchive)}
                              title={`Are you sure you want to archive '${
                                guardrailToArchive ? guardrailToArchive.name || 'Untitled' : ''
                              }'?`}
                              closable
                              onCancel={() => setGuardrailToArchive(null)}
                              footer={
                                <>
                                  <Button
                                    onClick={async () => {
                                      try {
                                        await archiveGuardrail({
                                          variables: { id: guardrailToArchive && guardrailToArchive.id }
                                        });

                                        message.success('Guardrail was successfully archived.');
                                        refetch(); // update guardrails in current query
                                        setGuardrailToArchive(null); // close modal
                                      } catch (e) {
                                        message.error('There was an error archiving this Guardrail');
                                      }
                                    }}
                                    disabled={loading || archiving}
                                    type={'danger'}
                                  >
                                    {archiving ? 'Archiving' : 'Archive'}
                                  </Button>
                                  <Button onClick={() => setGuardrailToArchive(null)}>{'Cancel'}</Button>
                                </>
                              }
                            >
                              {`Warning! Archiving the '${
                                guardrailToArchive ? guardrailToArchive.name || 'Untitled' : ''
                              }' Guardrail will place it in a disabled state.`}
                            </Modal>
                          );
                        }}
                      </Mutation>
                      <ArchiveGuardrailModal
                        guardrailToArchive={guardrailToArchive}
                        onCancel={() => setGuardrailToArchive(null)}
                        onComplete={() => {
                          refetch();
                          setGuardrailToArchive(null);
                        }}
                      />
                      <DeleteGuardrailModal
                        guardrailToDelete={guardrailToDelete}
                        onCancel={() => setGuardrailToDelete(null)}
                        onComplete={() => {
                          refetch();
                          setGuardrailToDelete(null);
                        }}
                      />
                    </>
                  );
                }}
              </Query>
            </CenteredContainer>
          );
        }}
      </Paginator>
    </Root>
  );
}

export default GuardrailList;

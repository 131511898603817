import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { Tag, Tooltip } from 'antd';
import QueryStringContext from '../util/QueryString/QueryStringContext';

export interface TagItem {
  key: string;
  value: string;
}

const ResourceTag = styled(Tag)`
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export interface Props {
  tags: TagItem[];
}

const TagWrap = styled.div`
  margin-top: 4px;
  display: inline-block;
`;

const Tags = styled.div``;

function TagList(props: Props) {
  let tags = props.tags || [];
  tags.sort();

  return (
    <Tags>
      {tags.map(function(tag: any, index: number) {
        const tagString = `${tag.key}:${tag.value}`;

        return (
          <TagWrap key={index}>
            <QueryStringContext.Consumer>
              {({ cloneURLSearchParams, replaceURLSearchParams }) => (
                <Tooltip title={tagString}>
                  <ResourceTag color="blue">
                    <a
                      onClick={(event: MouseEvent) => {
                        event.preventDefault();
                        event.stopPropagation();

                        const urlSearchParams = cloneURLSearchParams();

                        urlSearchParams.append('tag', encodeTag(tag));
                        urlSearchParams.delete('p');

                        replaceURLSearchParams(urlSearchParams, {
                          pathname: '/inventory/resources'
                        });
                      }}
                      href="/inventory/resources"
                    >
                      {tag.key}
                    </a>
                    :
                    <a
                      href="/inventory/resources"
                      onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();

                        const urlSearchParams = cloneURLSearchParams();

                        urlSearchParams.append('tag', encodeTag(tag));
                        urlSearchParams.delete('p');

                        replaceURLSearchParams(urlSearchParams, {
                          pathname: '/inventory/resources'
                        });
                      }}
                    >
                      {tag.value}
                    </a>
                  </ResourceTag>
                </Tooltip>
              )}
            </QueryStringContext.Consumer>
          </TagWrap>
        );
      })}
    </Tags>
  );
}

function encodeTag(tag: TagItem, valueOnly: boolean = false) {
  const tagArr = valueOnly ? [tag.key] : [tag.key, tag.value];
  return btoa(JSON.stringify(tagArr));
}

export default TagList;

import { Button, Form, Input, Modal, Spin, Tooltip } from 'antd';
import { FunctionParameterInputSet } from 'components/function';
import { FormField } from 'components/ui/Form';
import { FormRenderProps } from 'components/ui/Form/Form';
import { Field, getIn } from 'formik';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import uuid from 'uuid';
import { AUTOMATION_FUNCTION_BY_ID } from '../gql';
import { ContextArgs, parseFormValuesFromAutomationFunction } from '../OpEditor';
import { SelectAction } from './OpActionSection';

const { confirm } = Modal;

interface Props {
  name: string;
  contextArgs: ContextArgs;
  formRenderProps: FormRenderProps;
}

export default function AlertUserActions(props: Props) {
  const { name, contextArgs, formRenderProps } = props;

  const { values } = formRenderProps;
  const alertActions = getIn(values, name) || [];

  const [isSelectActionModalVisible, setIsSelectActionModalVisible] = useState<boolean>(false);
  const [actionToConfigureIndex, setActionToConfigureIndex] = useState<number>(-1);

  const actionToConfigure =
    alertActions?.length > 0 && actionToConfigureIndex > -1 && alertActions[actionToConfigureIndex];

  const functionId = actionToConfigure?.functionId;

  const actionToConfigureFieldName = `${name}[${actionToConfigureIndex}]`;

  const { loading: functionLoading, error: functionError, data: functionData } = useQuery(AUTOMATION_FUNCTION_BY_ID, {
    variables: {
      id: functionId
    },
    skip: !functionId,
    fetchPolicy: 'cache-first'
  });
  const automationFunctionToConfigure = functionData?.automationFunctions?.nodes?.[0] || null;

  return (
    <Form.Item>
      <Field name={name}>
        {({ field, form }) => {
          const { setFieldValue } = form;

          return (
            <>
              <div>
                <Tooltip title="Manually define action buttons to be included in the alert.">
                  Add action button...
                  <Button
                    icon="plus"
                    size="small"
                    style={{ marginLeft: '4px', padding: '0px', paddingTop: '3px' }}
                    onClick={() => setIsSelectActionModalVisible(true)}
                  />
                </Tooltip>
              </div>
              {alertActions?.map((alertAction) => {
                return (
                  <div key={alertAction.id}>
                    {alertAction.name}
                    <Button
                      icon="edit"
                      title="Edit Action"
                      size="small"
                      style={{ marginLeft: '4px', padding: '0px', paddingTop: '3px' }}
                      onClick={() => {
                        const index = alertActions.indexOf(alertAction);
                        setActionToConfigureIndex(index);
                      }}
                    />
                    <Button
                      icon="close"
                      title="Delete Action"
                      size="small"
                      style={{ marginLeft: '4px', padding: '0px', paddingTop: '3px' }}
                      onClick={() => {
                        const index = alertActions.indexOf(alertAction);
                        if (index < 0) return;
                        confirm({
                          title: `Delete Action '${alertAction.name}'?`,
                          content: `Are you sure you want to delete the action '${alertAction.name}?`,
                          onOk() {
                            alertActions.splice(index, 1);
                            setFieldValue(name, [...alertActions]);
                          },
                          onCancel() {}
                        });
                      }}
                    />
                  </div>
                );
              })}

              <Modal
                width="800px"
                title="Select Action"
                visible={isSelectActionModalVisible}
                onCancel={() => setIsSelectActionModalVisible(false)}
                footer={[
                  <Button key="back" onClick={() => setIsSelectActionModalVisible(false)}>
                    Cancel
                  </Button>
                ]}
              >
                <SelectAction
                  contextArgs={contextArgs}
                  onSelectAction={(args) => {
                    const automationFunction = args?.automationFunction;
                    if (!automationFunction) return;

                    // get function input value jsonpaths
                    const { actionConfiguration } = parseFormValuesFromAutomationFunction(automationFunction);

                    const newAction = {
                      id: uuid.v4(),
                      name: automationFunction.name,
                      functionId: automationFunction.id,
                      function: automationFunction,
                      configuration: {
                        ...actionConfiguration
                      }
                    };

                    setFieldValue(name, [...alertActions, newAction]);
                    setIsSelectActionModalVisible(false);
                    setActionToConfigureIndex(alertActions.length);
                  }}
                  hideAlertAction={true}
                />
              </Modal>

              <Modal
                width="800px"
                title={`Configure Action`}
                visible={actionToConfigureIndex > -1}
                onCancel={() => {
                  setActionToConfigureIndex(-1);
                }}
                footer={[
                  <Button
                    key="back"
                    onClick={() => {
                      setActionToConfigureIndex(-1);
                    }}
                  >
                    Close
                  </Button>
                ]}
              >
                <Form.Item>
                  <FormField name={`${actionToConfigureFieldName}.name`} label="Action Name">
                    {({ name, value, handleChange, handleBlur }) => {
                      return <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />;
                    }}
                  </FormField>
                </Form.Item>
                {functionLoading ? (
                  <Spin tip="Loading action..." />
                ) : functionError?.message ? (
                  <>{functionError.message}</>
                ) : automationFunctionToConfigure?.parameters ? (
                  <FunctionParameterInputSet
                    {...formRenderProps}
                    parameters={automationFunctionToConfigure.parameters}
                    allowJSONPathInput
                    staticConfigPathPrefix={`${actionToConfigureFieldName}.configuration.`}
                    jsonpathConfigPathPrefix={`${actionToConfigureFieldName}.configuration.jsonpathConfiguration.`}
                    dynamicConfigPathPrefix={`${actionToConfigureFieldName}.configuration.dynamicConfiguration.`}
                  />
                ) : (
                  <div>No parameters to configure</div>
                )}
              </Modal>
            </>
          );
        }}
      </Field>
    </Form.Item>
  );
}

import React, { useState } from 'react';
import { Button, Checkbox } from 'antd';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import gql from 'graphql-tag';

import { ClientProject, CloudAccount } from 'typings';

import { CloudVendorIcon } from 'components/cloudVendors';
import Table from 'components/ui/Table';
import SpaceBetween from 'components/ui/SpaceBetween';
import ErrorAlert from 'components/ui/ErrorAlert';

import MoveCloudAccountsModal from '../MoveCloudAccountsModal';

interface Props {
  project: ClientProject;
}

const Root = styled.div`
  .table-header {
    position: relative;
  }

  .table-header-child,
  .table-actions {
    padding: 8px 16px;
  }

  .table-header-child {
    display: flex;
    align-items: center;
  }

  .table-header-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0;
    flex: 1;
  }

  .table-header-selection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${(p) => p.theme.primary300};
    opacity: 0;
    transition: opacity 0.25s ease;

    &.in {
      opacity: 1;
    }

    .table-header-title {
      color: #fff;
      font-weight: 500;
    }
  }
`;

const PROJECT_ACCOUNTS_QUERY = gql`
  query ProjectCloudAccounts($id: String!, $recursionDisabled: Boolean) {
    projectAccounts(id: $id, recursionDisabled: $recursionDisabled)
      @rest(
        type: "ClientAccount"
        path: "/api/v2/projects/{args.id}/accounts?recursion_disabled={args.recursionDisabled}"
        method: "GET"
      ) {
      account_id
      name
      nickname
      provider
      assumerole_account_id
      policy_code
    }
  }
`;

function ProjectCloudAccounts(props: Props) {
  const { project } = props;
  const { name } = project;

  const [selectedRowKeys, setSelectedKeys] = useState<string[] | number[] | undefined>([]);
  const [modalIsVisible, setModalVisible] = useState<boolean>(false);
  const [includeChildProjects, setIncludeChildProjects] = useState<boolean>(project.parent_id ? false : true); // default include for Root

  const handleRowSelection = (selectedRowKeys: string[] | number[] | undefined) =>
    setSelectedKeys(Array.isArray(selectedRowKeys) ? selectedRowKeys : []);
  const selectedCount = Array.isArray(selectedRowKeys) ? selectedRowKeys.length : 0;

  const { loading, error, data } = useQuery(PROJECT_ACCOUNTS_QUERY, {
    variables: {
      id: project.project_id,
      recursionDisabled: !includeChildProjects
    }
  });

  const cloudAccounts = data?.projectAccounts || project.accounts_list;

  return (
    <Root>
      <div className="table-header">
        <div className="table-header-child table-header-static">
          <h4 className="table-header-title">{`Cloud Accounts in Project: "${name}"`}</h4>
        </div>
      </div>

      <div className="table-actions">
        <SpaceBetween>
          <Button
            className="square"
            type="primary"
            onClick={() => setModalVisible(true)}
            disabled={selectedCount === 0}
          >
            {`Move ${selectedCount} ${selectedCount !== 1 ? 'Accounts' : 'Account'}`}
          </Button>

          <Checkbox
            checked={includeChildProjects}
            onChange={(e) => {
              setIncludeChildProjects(!includeChildProjects);
            }}
          >
            Include child projects
          </Checkbox>
        </SpaceBetween>
      </div>

      {error && <ErrorAlert error={error}></ErrorAlert>}

      <Table
        antTableProps={{
          rowSelection: {
            selectedRowKeys: selectedRowKeys || [],
            onChange: handleRowSelection
          },
          loading,
          dataSource: cloudAccounts || [],
          rowKey: 'account_id',
          columns: [
            {
              key: 'provider',
              dataIndex: 'provider',
              title: 'Vendor',
              align: 'center',
              render: (vendor) => <CloudVendorIcon vendor={vendor} />
            },
            {
              key: 'nickname',
              dataIndex: 'nickname',
              title: 'Account',
              render: (nickname, account: CloudAccount) => (
                <>
                  {nickname || '--'}
                  <br />
                  {account.assumerole_account_id}
                </>
              )
            },
            {
              key: 'policyCode',
              dataIndex: 'policy_code',
              title: 'Policy'
            }
          ],
          locale: { emptyText: 'No cloud accounts' },
          pagination: false
        }}
      />

      <MoveCloudAccountsModal
        project={project}
        visible={modalIsVisible}
        accountIds={selectedRowKeys ? (selectedRowKeys as Array<string | number>).map((k) => `${k}`) : []}
        onCloseModal={() => setModalVisible(false)}
        onAfterSave={() => {
          setSelectedKeys([]);
          setModalVisible(false);
        }}
      />
    </Root>
  );
}

export default ProjectCloudAccounts;

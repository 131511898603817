import React from 'react';
import { Select } from 'antd';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import ProgressCircular from 'components/ui/ProgressCircular';
import { ScopeIdSelectorProps } from '../../types';
import { FormField } from 'components/ui/Form';

import AssessmentSelector from './AssessmentSelector';
import { Assessment } from 'typings';

const ISSUE_TYPES_BY_ASSESSMENT_QUERY = gql`
  query IssueTypesByAssessment($id: [String]) {
    assessments(id: $id) {
      nodes {
        id
        name
        assessor {
          id
          name
          issueDefinitions {
            id
            name
            key
          }
        }
      }
    }
  }
`;

function AssessmentIssueSelector(props: ScopeIdSelectorProps) {
  const {
    formRenderProps: { setFieldValue },
    value
  } = props;

  const assessmentId = (value && value.assessmentId) || undefined;

  return (
    <>
      <FormField name="scope.assessmentId" label="Detector">
        {({ value, handleBlur, name }) => {
          return (
            <AssessmentSelector
              value={value}
              onBlur={handleBlur}
              onChange={(assessmentId) => {
                setFieldValue(name, assessmentId);
              }}
            />
          );
        }}
      </FormField>

      {assessmentId && (
        <Query query={ISSUE_TYPES_BY_ASSESSMENT_QUERY} variables={{ id: assessmentId }} fetchPolicy="cache-first">
          {(results) => {
            const { data, loading } = results;

            if (loading) return <ProgressCircular />;

            let assessment: Assessment | null = null;
            if (data.assessments && data.assessments.nodes && data.assessments.nodes[0]) {
              assessment = data.assessments.nodes[0];
            }

            const items =
              assessment && assessment.assessor && assessment.assessor.issueDefinitions
                ? assessment.assessor.issueDefinitions
                : [];

            return (
              <FormField name="scope.issueDefinitionId" label="Issue Type">
                {({ name, value, handleBlur }) => (
                  <Select
                    value={value || 'default'}
                    style={{ width: '100%' }}
                    onChange={(selected) => {
                      const selectedStr = selected ? selected.toString() : undefined;

                      setFieldValue(name, selectedStr === 'default' ? undefined : selectedStr);
                    }}
                    onBlur={handleBlur}
                  >
                    <Select.Option value="default">Select Issue Type</Select.Option>
                    {items.map((item, index) => {
                      return (
                        <Select.Option key={`${item.id}-${index}`} value={item.id}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </FormField>
            );
          }}
        </Query>
      )}
    </>
  );
}

export default AssessmentIssueSelector;

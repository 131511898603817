import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { NotificationRecipient } from '../types';

const StyledRow = styled(Row)`
  line-height: 1.5em;
`;

const Small = styled.h5`
  color: rgba(0, 0, 0, 0.45);
  padding: 0;
`;

export interface Props {
  recipient?: NotificationRecipient;
}

function NotificationRecipientUsage(props: Props) {
  const { recipient } = props;
  if (!recipient) return null;

  const hasUsageToShow = recipient.lastUsedAt || recipient.lastErrorAt || recipient.lastError;
  if (!hasUsageToShow) return null;

  return (
    <StyledRow style={{ marginBottom: '8px' }} gutter={16}>
      {recipient.lastUsedAt && (
        <Col lg={6}>
          <Small>
            <b>Last Used At</b>: {recipient.lastUsedAt ? new Date(recipient.lastUsedAt).toLocaleString() : null}
          </Small>
        </Col>
      )}

      {recipient.lastErrorAt && (
        <Col lg={6}>
          <Small>
            <b>Last Error At</b>: {recipient.lastErrorAt ? new Date(recipient.lastErrorAt).toLocaleString() : null}
          </Small>
        </Col>
      )}

      {recipient.lastError && (
        <Col lg={12}>
          <Small>
            <b>Last Error</b>: {recipient.lastError}
          </Small>
        </Col>
      )}
    </StyledRow>
  );
}

export default NotificationRecipientUsage;

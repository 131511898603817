import Base64 from 'crypto-js/enc-base64';
import md5 from 'crypto-js/md5';
import gql from 'graphql-tag';
import { rawToDashed } from './resourceType';

export const RESOURCE_TYPES_QUERY = gql`
  query resource_types {
    resource_types {
      name
      key
    }
  }
`;

export interface ResourceType {
  name: string;
  key: string;
}

export const ASFF_TO_INVENTORY_RESOURCE_TYPE = {
  AwsCloudFrontDistribution: '',
  AwsCodeBuildProject: '',
  AwsEc2Instance: 'AWS::EC2::Instance',
  AwsEc2NetworkInterface: 'AWS::EC2::NetworkInterface',
  AwsEc2SecurityGroup: 'AWS::EC2::SecurityGroup',
  AwsElasticsearchDomain: '',
  AwsElbv2LoadBalancer: 'AWS::ElasticLoadBalancingV2::LoadBalancer',
  AwsIamAccessKey: '',
  AwsIamRole: 'AWS::IAM::Role',
  AwsKmsKey: '',
  AwsLambdaFunction: 'AWS::Lambda::Function',
  AwsLambdaLayerVersion: '',
  AwsRdsDbInstance: 'AWS::RDS::DBInstance',
  AwsS3Bucket: 'AWS::S3::Bucket',
  AwsS3Object: '',
  AwsSnsTopic: 'AWS::SNS::Topic',
  AwsSqsQueue: 'AWS::SQS::Queue',
  AwsWafWebAcl: '',
  Container: '',
};

export function asffToInventoryResourceType(resourceType: string): string {
  if (!ASFF_TO_INVENTORY_RESOURCE_TYPE.hasOwnProperty(resourceType)) return '';
  return ASFF_TO_INVENTORY_RESOURCE_TYPE[resourceType];
}

export function getResourceTypeFromKey(resourceTypes: ResourceType[], key: string): ResourceType | undefined {
  return resourceTypes.find((t) => t.key === key);
}

interface ResourceUIDData {
  accountId: string;
  region?: string | null;
  itemType: string;
  itemKey: string;
}

export function generateUID(keyData: ResourceUIDData) {
  const { accountId, itemType, itemKey } = keyData;
  const region = keyData.region ? keyData.region : 'global';
  const unhashedUID = `${accountId}|${itemType}|${itemKey}|${region}`;

  const rawUID = md5(unhashedUID);
  const base64UID = Base64.stringify(rawUID);
  const base64WithoutPaddingUID = base64UID.replace(/=+$/g, '');

  //We do this to match python's base64.urlsafe_b64encode
  //https://docs.python.org/2/library/base64.html
  const urlReadyUID = base64WithoutPaddingUID.replace(/\+/g, '-').replace(/\//g, '_');

  return urlReadyUID;
}

export function getResourceUrl(keyData: ResourceUIDData, accountName?: string | null) {
  const { accountId, itemType, itemKey, region } = keyData;

  const itemUid = generateUID({
    accountId,
    itemType,
    itemKey,
    region
  });

  const itemQueryParams = new URLSearchParams();
  if (accountName) itemQueryParams.append('account', accountName);
  itemQueryParams.append('itemType', itemType);
  itemQueryParams.append('itemKey', itemKey);
  if (region) itemQueryParams.append('region', region);
  itemQueryParams.append('accountId', accountId);

  return `/inventory/resources/${rawToDashed(itemType)}/${itemUid}?${itemQueryParams.toString()}`;
}

interface ResourceLinkData {
  accountId?: string | null;
  region?: string | null;
  itemType?: string | null;
  itemKey?: string | null;
}

export function getSearchUrl(keyData: ResourceLinkData) {
  const { accountId, itemType, itemKey, region } = keyData;

  let searchable = false;

  let searchUrl = `/inventory/resources/`;
  const searchParams = new URLSearchParams();
  if (itemKey) {
    searchParams.append('s', itemKey);
    searchable = true;
  }
  if (itemType) {
    searchUrl += `${rawToDashed(itemType)}/`;
    searchable = true;
  }
  if (accountId) {
    searchParams.append('account', accountId);
    searchable = true;
  }
  if (region) {
    searchParams.append('region', region);
    searchable = true;
  }

  if (!searchable) return null;

  searchUrl += `?${searchParams.toString()}`;

  return searchUrl;
}

import gql from 'graphql-tag';

const ALL_SECURITY_GROUPS_QUERY = gql`
  query AWSSecurityGroupList(
    $from: Int!
    $size: Int!
    $dops_full_text: String
    $dops_internal_account_ids: [String]
    $dops_regions: [String]
    $tags: [TagInput]
  ) {
    security_groups(
      from: $from
      size: $size
      dops_full_text: $dops_full_text
      dops_internal_account_ids: $dops_internal_account_ids
      dops_regions: $dops_regions
      tags: $tags
    ) {
      from
      size
      total_count
      results {
        id
        dops_resource_id
        dops_resource_type
        dops_resource_uid
        dops_internal_account_id
        dops_name
        dops_region
        group_name
        group_id
        description
        tags {
          key
          value
        }
        dops_issue_summary {
          open_count
        }
      }
    }
  }
`;

export default ALL_SECURITY_GROUPS_QUERY;

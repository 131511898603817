import React, { SFC, ReactNode } from 'react';
import styled from 'styled-components';

import Panel from 'components/ui/Panel';
import Pane from 'components/ui/Pane';

interface Props {
  children: ReactNode;
  withLogo?: boolean;
  title?: string | null;
  classes?: {
    content?: string;
  };
}

const Background = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background-color: #f6f8fb;
  display: flex;
  align-items: start;
  justify-content: center;

  .ant-alert {
    margin-bottom: 16px;
  }
`;

const Content = styled.div`
  padding: 40px;
  width: 100%;
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const OnboardingPanel: SFC<Props> = (props: Props) => {
  const { title, classes = {} } = props;

  return (
    <Background>
      <Pane horizontalPadding="30%" verticalPadding="100px">
        <Panel withLogo={props.withLogo}>
          <Content className={classes.content || ''}>
            {title && <Title>{title}</Title>}
            {props.children}
          </Content>
        </Panel>
      </Pane>
    </Background>
  );
};

export default OnboardingPanel;

import React, { useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { CLOUD_ACCOUNT_LIST_QUERY } from 'queries';
import Select, { ModeOption, SelectProps } from 'antd/lib/select';
import { CloudAccount } from 'typings';

interface Props extends SelectProps {
  value?: string | string[];
  externalAccountId?: boolean;
  onChange: (value: any) => any;
  mode?: ModeOption;
  placeholder?: string;
  onBlur?: (event: any) => any;
}

function CloudAccountLabelsSelect(props: Props) {
  const { value } = props;

  const placeholder = props.placeholder ? props.placeholder : 'All Cloud Account Labels';

  const { loading, error, data } = useQuery(CLOUD_ACCOUNT_LIST_QUERY);

  const allCloudAccounts: CloudAccount[] = data?.accounts?.items || [];

  const allCloudAccountLabels = useMemo(() => {
    const allCloudAccountLabelsSet = allCloudAccounts.reduce((set, cloudAccount) => {
      const cloudAccountLabels = cloudAccount.labels || [];
      cloudAccountLabels.forEach((label) => set.add(label));

      return set;
    }, new Set<string>());

    return Array.from(allCloudAccountLabelsSet).sort();
  }, [allCloudAccounts]);

  return (
    <Select
      value={value === null ? undefined : value}
      style={{ width: '100%', minWidth: '200px' }}
      disabled={error ? true : false}
      placeholder={!error ? placeholder : 'Error loading Cloud Account Labels'}
      loading={loading}
      mode="tags"
      filterOption={(inputValue, option) => {
        if (!option || !option.props || !option.props.value) {
          return true;
        }
        return new RegExp(inputValue, 'i').test(option.props.value.toString());
      }}
      {...props}
    >
      {allCloudAccountLabels.map((label, i) => (
        <Select.Option key={`${i}_${label}`} value={label}>
          {label}
        </Select.Option>
      ))}
    </Select>
  );
}

export default CloudAccountLabelsSelect;

import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export interface Props {
  children: ReactNode | ReactNode[];
  classes?: {
    root?: string;
    content?: string;
  };
}

function TableHeaderActions(props: Props) {
  const { children, classes = {} } = props;

  return (
    <Root className={classes.root || ''}>
      <Content className={classes.content || ''}>{children}</Content>
    </Root>
  );
}

export default TableHeaderActions;

// NB: these will be compared, case-sensitive, to the shell environment variable REACT_APP_ENV
export enum Env {
  Local = 'local',
  Dev = 'dev',
  Stage = 'stage',
  Demo = 'demo',
  Prod = 'prod'
}

export const APP_ENV: string | undefined = process.env.REACT_APP_ENV;

export const IS_DEV = !['prod', 'stage', 'staging'].includes(process.env.REACT_APP_ENV || '');
export const IS_STAGING = ['staging', 'stage'].includes(process.env.REACT_APP_ENV || '');

export const API_URL: string = (() => {
  let url = '';

  switch (APP_ENV) {
    case Env.Dev:
      url = 'https://api.dev2.disruptops.com';
      break;

    case Env.Local:
      url = 'https://api.dev2.disruptops.com';
      break;

    case Env.Stage || Env.Demo:
      url = 'https://api.staging.disruptops.com';
      break;

    case Env.Prod:
      url = 'https://api.prod.disruptops.com';
      break;

    default:
      url = 'http://localhost';
      break;
  }

  // NOTE: Use npm run start-all-local to target a locally running authorizor and Trinity API.
  return process.env.REACT_APP_API_URL || url;
})();

export const PUSH_URL: string = (() => {
  switch (APP_ENV) {
    case Env.Local:
      return 'http://localhost:7999/live';
    case Env.Dev:
      return 'https://3708ae47.fanoutcdn.com/live';
    case Env.Stage:
      return 'https://d082652b.fanoutcdn.com/live';
    case Env.Prod:
      return 'https://33bac1d2.fanoutcdn.com/live';
    default:
      return '';
  }
})();

export const GRAPHQL_URL: string = (() => {
  switch (APP_ENV) {
    case Env.Local:
      return 'http://localhost:3330/graphql';
    case Env.Dev:
      return 'https://graph.dev2.disruptops.com/graphql';
    case Env.Stage || Env.Demo:
      return 'https://graph.staging.disruptops.com/graphql';
    case Env.Prod:
      return 'https://graph.prod.disruptops.com/graphql';
    default:
      return '';
  }
})();

export const APPSYNC_URL: string = (() => {
  switch (APP_ENV) {
    case Env.Local:
      return 'http://localhost:3330/graphql';
    case Env.Dev:
      return 'https://dev-apgw.disruptops.com/graphql';
    case Env.Stage || Env.Demo:
      return 'https://stage-apgw.disruptops.com/graphql';
    case Env.Prod:
      return 'https://apgw.disruptops.com/graphql';
    default:
      return '';
  }
})();

export const COLLECTOR_URL: string = (() => {
  switch (APP_ENV) {
    case Env.Local:
      // return 'http://localhost:3336';
      return 'https://collector.dev2.disruptops.com';
    case Env.Dev:
      return 'https://collector.dev2.disruptops.com';
    case Env.Stage || Env.Demo:
      return 'https://collector.staging.disruptops.com';
    case Env.Prod:
      return 'https://collector.prod.disruptops.com';
    default:
      return '';
  }
})();

const INTERCOM_APP_ID_MAP = {
  dev: 'qmw09slb',
  stage: 'qmw09slb',
  prod: 'y24gsequ'
};

export const INTERCOM_APP_ID: string = INTERCOM_APP_ID_MAP[APP_ENV || ''] || '';

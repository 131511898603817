export function getResultPropertyNameFromQuery(query: any) {
  try {
    if (query.definitions.length > 1) {
      throw new Error('Document has more than one definition in it');
    }
    if (query.definitions[0].selectionSet.selections.length > 1) {
      throw new Error('Document specifies more than one selection set');
    }
    return query.definitions[0].selectionSet.selections[0].name.value;
  } catch (e) {
    throw new Error('Could not get result property for query');
  }
}

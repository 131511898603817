import debounce from 'just-debounce-it';
import { Component, ReactNode } from 'react';
import { PushContext } from './PushContext';
import { PUSH_URL } from 'constants/runtimeConfig';

interface Props {
  children?: ReactNode;
  refetchTest: (message: any) => boolean;
  refetchFn: () => any | void;
  eventType?: string;
}

class PushFetch extends Component<Props> {
  static contextType = PushContext;
  refetch?: Function;

  constructor(p: Props) {
    super(p);

    this.handleEvent = this.handleEvent.bind(this);

    this.refetch = debounce(
      () => {
        this.props.refetchFn();
      },
      1000,
      {
        leading: false,
        trailing: true
      }
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.refetchFn !== this.props.refetchFn) {
      this.refetch = debounce(
        () => {
          this.props.refetchFn();
        },
        1000,
        {
          leading: false,
          trailing: true
        }
      );
    }
  }

  componentDidMount() {
    this.context.eventSource.on('message', this.handleEvent);
    this.context.eventSource.connect(PUSH_URL);
  }

  componentWillUnmount() {
    this.context?.eventSource?.removeListener('message', this.handleEvent);
    this.context?.eventSource?.close();
  }

  handleEvent(event: any) {
    const { refetchTest, refetchFn, eventType } = this.props;
    const testResult = (!eventType || eventType === event.eventType) && refetchTest ? refetchTest(event) : false;

    if (testResult && refetchFn && this.refetch) {
      return this.refetch();
    }
  }

  render() {
    const { children } = this.props;
    return children || null;
  }
}

export default PushFetch;

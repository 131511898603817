import gql from 'graphql-tag';

const USER_BY_ID_QUERY = gql`
  query UserForActivityDetail($id: ID) {
    userById(id: $id) @rest(type: "User", path: "/users/{args.id}", method: "GET") {
      id
      email
      username
    }
  }
`;

export default USER_BY_ID_QUERY;

import React from 'react';
import { Typography, Button } from 'antd';
import { Query } from 'react-apollo';

import Form from 'components/ui/Form';
import QueryResult from 'components/util/QueryResult';
import { FunctionParameterInputSet } from 'components/function';

import { ActionConfigurationInputProps, transformGuardrailIntoActionConfiguration } from '../definitions';
import ACTOR_FUNCTION_QUERY from 'components/guardrails/GuardrailEditor/gql/actorFunctionQuery';
import { Function } from 'typings';
import { buildSchemaFromActor } from '../definitions/disruptopsAction';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

function IssueActorConfigurationInput(props: ActionConfigurationInputProps) {
  const {
    guardrail,
    guardrail: { functionId: actorId }
  } = props;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ projectIds: '*', permissionId: Permissions.MODIFY_GUARDRAILS }]
  });

  if (!actorId) return <Typography.Text>Select Action before Configuring</Typography.Text>;

  return (
    <Query query={ACTOR_FUNCTION_QUERY} variables={{ id: actorId }}>
      {({ loading, data, error }) => (
        <QueryResult loading={loading} data={data} error={error}>
          {() => {
            const actor: Function = data.functions.nodes[0];
            if (!actor) return <div>Could not find Actor</div>;

            const { parameters } = actor;
            if (!parameters || parameters.length === 0)
              return <Typography.Text>{'This Action does not have any configuration parameters.'}</Typography.Text>;

            const schema = buildSchemaFromActor(actor);

            return (
              <Form
                validationSchema={schema}
                initialValues={transformGuardrailIntoActionConfiguration(guardrail)}
                allowCleanSubmits={false}
                onSubmit={async (values, actions) => {
                  const staticConfiguration = {};
                  const dynamicConfiguration = {};
                  const jsonpathConfiguration = {};

                  if (actor.parameters) {
                    actor.parameters.forEach(item => {
                      if (values.dynamicConfiguration && values.dynamicConfiguration[item.key]) {
                        dynamicConfiguration[item.key] = values.dynamicConfiguration[item.key].id;
                      } else if (values.jsonpathConfiguration && values.jsonpathConfiguration[item.key]) {
                        jsonpathConfiguration[item.key] = values.jsonpathConfiguration[item.key];
                      } else if (values[item.key]) {
                        staticConfiguration[item.key] = values[item.key];
                      }
                    });
                  }

                  await props.onSubmit({
                    staticConfiguration: Object.keys(staticConfiguration) ? staticConfiguration : null,
                    dynamicConfiguration: Object.keys(dynamicConfiguration) ? dynamicConfiguration : null,
                    jsonpathConfiguration: Object.keys(jsonpathConfiguration) ? jsonpathConfiguration : null
                  });

                  actions.setSubmitting(false);
                }}
              >
                {formRenderProps => {
                  const { canSubmit, isSubmitting } = formRenderProps;

                  return (
                    <div>
                      <FunctionParameterInputSet
                        {...formRenderProps}
                        showDynamicConfigOptions
                        parameters={parameters}
                        allowJSONPathInput
                      />
                      <div>
                        <Button
                          type="primary"
                          block
                          htmlType="submit"
                          disabled={!authz.isAuthorized || !canSubmit || isSubmitting}
                          loading={isSubmitting}
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  );
                }}
              </Form>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

export default IssueActorConfigurationInput;

import { FunctionParameter, Config, AttachedDynamicConfiguration } from 'typings';
import { FormikValues } from 'formik';
import validator from 'services/validator';
import { generateFieldSchema } from 'components/function/FunctionParameterInput';

export function initializeFunctionParametersFormValues(parameters: FunctionParameter[], values: any) {
  const passedValues = values || {};

  return parameters.reduce((acc: FormikValues, parameter) => {
    const { key } = parameter;
    const value = passedValues[key];

    acc[key] = value;
    if (!value && parameter.many) acc[key] = [];

    return acc;
  }, {});
}

export function generateFormSchema(parameters: FunctionParameter[]) {
  if (!parameters || parameters.length === 0) return;
  const formShape = parameters.reduce((shape, item) => {
    const schema = generateFieldSchema(item, true);

    if (!schema) return shape;

    shape[item.key] = schema;

    return shape;
  }, {});

  return validator.object().shape(formShape);
}

type DynamicConfigurationId = string;

interface FunctionConfigurationInput {
  staticConfiguration: any;
  dynamicConfiguration: {
    [parameter_key: string]: DynamicConfigurationId;
  };
}

export function parseFormValuesIntoFunctionConfiguration(
  values: FormikValues,
  parameters?: FunctionParameter[] | null
): FunctionConfigurationInput {
  const staticConfiguration = {};
  const dynamicConfiguration = {};

  if (parameters) {
    parameters.forEach((item) => {
      if (values.dynamicConfiguration && values.dynamicConfiguration[item.key]) {
        dynamicConfiguration[item.key] = values.dynamicConfiguration[item.key].id;
      } else if (values[item.key] !== undefined && values[item.key] !== null) {
        staticConfiguration[item.key] = values[item.key];
      }
    });
  }

  return {
    staticConfiguration,
    dynamicConfiguration
  };
}

interface FormDynamicConfiguration {
  [parameter_key: string]: Config;
}

export function initFormDynamicConfigs(
  attachedDynamicConfigs: AttachedDynamicConfiguration[]
): FormDynamicConfiguration {
  return attachedDynamicConfigs.reduce((a: FormDynamicConfiguration, i) => {
    if (i.key && i.node) a[i.key] = i.node;
    return a;
  }, {});
}

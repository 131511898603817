import React from 'react';
import { RouteComponentProps } from 'react-router';
import AutomationActionResultsList from 'components/activity/AutomationActionResultsList';

interface OpsActivityProps extends RouteComponentProps {}

function OpsActivity(props: OpsActivityProps) {
  return <AutomationActionResultsList defaultTriggerType={'OP'} {...props} />;
}

export default OpsActivity;

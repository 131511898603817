import React from 'react';
import { Badge } from 'antd';

interface Props {
  issueCount: number;
}

function IssueCountBadge({ issueCount }: Props) {
  return issueCount > 0 ? (
    <Badge
      count={issueCount}
      style={{
        backgroundColor: issueCount === 0 ? 'transparent' : '#faad14',
        color: issueCount === 0 ? '#999' : '#fff'
      }}
    />
  ) : (
    <span>{'-'}</span>
  );
}

export default IssueCountBadge;

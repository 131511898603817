import React, { useMemo } from 'react';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { Typography, Button } from 'antd';
import Form from 'components/ui/Form';
import { Query } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import { Function } from 'typings';
import { generateFieldSchema } from 'components/function/FunctionParameterInput';
import { FunctionParameterInputSet } from 'components/function';

import { transformGuardrailIntoActionConfiguration, ActionConfigurationInputProps } from '../definitions';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

const AUTOMATION_FUNCTION_PARAMETERS = gql`
  query AutomationFunctionParameters($id: [String]) {
    automationFunctions(id: $id) {
      nodes {
        id
        name
        key
        description
        registration {
          arn
          method
        }
        parameters {
          name
          key
          type
          inputCode
          configCode
          defaultValue
          description
          placeholder
          many
          options
          required
        }
      }
    }
  }
`;

function AutomationActionConfigurationInput(props: ActionConfigurationInputProps) {
  const {
    guardrail,
    guardrail: { automationFunctionId }
  } = props;
  const initialValues = useMemo(() => transformGuardrailIntoActionConfiguration(guardrail), [guardrail]);
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ projectIds: '*', permissionId: Permissions.MODIFY_GUARDRAILS }]
  });

  if (!automationFunctionId) return <Typography.Text>Select Action before Configuring</Typography.Text>;

  return (
    <Query query={AUTOMATION_FUNCTION_PARAMETERS} variables={{ id: [automationFunctionId] }}>
      {({ loading, data, error }) => {
        return (
          <QueryResult loading={loading} data={data} error={error}>
            {() => {
              const automationFunction: Function = data.automationFunctions.nodes[0];
              if (!automationFunction) return <div>Could not find Action</div>;

              const { parameters } = automationFunction;
              if (!parameters || parameters.length === 0)
                return <Typography.Text>{'This Action does not have any configuration parameters.'}</Typography.Text>;

              const schema = buildSchemaFromAutomationFunction(automationFunction);

              return (
                <Form
                  enableReinitialize
                  validationSchema={schema}
                  initialValues={initialValues}
                  allowCleanSubmits={false}
                  onSubmit={async (values, actions) => {
                    const staticConfiguration = {};
                    const dynamicConfiguration = {};
                    const jsonpathConfiguration = {};

                    if (automationFunction.parameters) {
                      automationFunction.parameters.forEach(item => {
                        if (values.dynamicConfiguration && values.dynamicConfiguration[item.key]) {
                          dynamicConfiguration[item.key] = values.dynamicConfiguration[item.key].id;
                        } else if (values.jsonpathConfiguration && values.jsonpathConfiguration[item.key]) {
                          jsonpathConfiguration[item.key] = values.jsonpathConfiguration[item.key];
                        } else if (values[item.key]) {
                          staticConfiguration[item.key] = values[item.key];
                        }
                      });
                    }

                    await props.onSubmit({
                      staticConfiguration: Object.keys(staticConfiguration) ? staticConfiguration : null,
                      dynamicConfiguration: Object.keys(dynamicConfiguration) ? dynamicConfiguration : null,
                      jsonpathConfiguration: Object.keys(jsonpathConfiguration) ? jsonpathConfiguration : null
                    });

                    actions.setSubmitting(false);
                  }}
                >
                  {formRenderProps => {
                    const { canSubmit, isSubmitting } = formRenderProps;

                    return (
                      <div>
                        <FunctionParameterInputSet
                          {...formRenderProps}
                          showDynamicConfigOptions
                          parameters={parameters}
                          allowJSONPathInput
                        />
                        <div>
                          <Button
                            type="primary"
                            block
                            htmlType="submit"
                            disabled={!authz.isAuthorized || !canSubmit || isSubmitting}
                            loading={isSubmitting}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </Form>
              );
            }}
          </QueryResult>
        );
      }}
    </Query>
  );
}

export function buildSchemaFromAutomationFunction(automationFunction: Function) {
  if (!automationFunction.parameters) return Yup.mixed();

  const shape = automationFunction.parameters.reduce((acc, parameter) => {
    const schema = generateFieldSchema(parameter, true);

    return schema ? { ...acc, [parameter.key]: schema } : acc;
  }, {});

  return Yup.object().shape(shape);
}

export default AutomationActionConfigurationInput;

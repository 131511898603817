// MOVE STYLED COMPONENTS TO SEP FILE
import styled from 'styled-components';
export const Root = styled.div`
  .table-header-content {
    justify-content: space-between;
  }

  td.tags-col {
    vertical-align: middle;
  }
`;

export const SearchWrap = styled.div`
  // had to restyle ant button to reverse overrides.
  flex: 1;
  margin-left: 16px;

  & .ant-btn {
    border-radius: 0 4px 4px 0;
  }
`;

export const TableTagWrap = styled.div`
  margin-top: 4px;
  display: inline-block;
`;

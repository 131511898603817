import DopeIcon from 'components/ui/DopeIcon';
import { Root } from './DetectorsBanner.styles';
import React from 'react';
import { Detector } from 'typings';

interface ComponentProps {
  detector: Detector | null;
}

const vendorSettings = {
  aws: {
    name: 'AWS',
    size: 30
  },
  azure: {
    name: 'AZURE',
    size: 27
  }
};

function DetectorBanner(props: ComponentProps) {
  const { detector } = props,
    cloudVendor = detector?.assessor?.cloudVendor.toLowerCase() || '',
    settings = vendorSettings[cloudVendor];

  return (
    <Root>
      {settings && (
        <div className="icon">
          <DopeIcon name={settings.name} size={settings.size} />
        </div>
      )}
      <div className="name">{detector?.name}</div>
    </Root>
  );
}

export default DetectorBanner;

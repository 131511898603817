import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export interface DetectorVariant {
  basePath: string;
  variantName: string;
  id: string;
}

export default function useDetectorVariants() {
  const location = useLocation(),
    defaultValue = {
      basePath: '/detectors',
      variantName: 'Detector',
      id: 'detector'
    },
    [variant, setVariant] = useState<DetectorVariant>(defaultValue);

  useEffect(() => {
    if (location.pathname.includes('posture-monitoring')) {
      setVariant({
        basePath: '/posture-monitoring',
        variantName: 'Check',
        id: 'posture-monitoring'
      });
      return;
    }

    if (location.pathname.includes('threat-monitoring')) {
      setVariant({
        basePath: '/threat-monitoring',
        variantName: 'Detector',
        id: 'threat-monitoring'
      });
      return;
    }

    setVariant(defaultValue);
  }, [location]);

  return variant;
}

import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import Table from 'components/ui/Table';
import { CenteredContainer } from 'components/ui/NeoPage';
import Panel from 'components/ui/Panel';
import Paragraph from 'antd/lib/typography/Paragraph';
import PageHeader from 'components/ui/PageHeader';
import ErrorAlert from 'components/ui/ErrorAlert';
import DeleteAPIKeyButton from './components/DeleteAPIKeyButton';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, message } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import AuthorizationErrorAlert from 'components/app/Auth/AuthorizationErrorAlert';
import { COLLECTOR_URL } from 'constants/runtimeConfig';
import DateTime from 'components/ui/DateTime';
import CreateApiKeyModal from './components/CreateAPIKeyButton';

const CLIENT_API_KEYS_QUERY = gql`
  query ClientAPIKeys {
    client {
      api_keys
      structured_api_keys{
        apiKey
        label
        createdAt
      }
      client_id
    }
  }
`;

function IntegrationPage(props: RouteComponentProps) {
  const { isAuthorized, failedPermissions } = useAuthorizor(Permissions.VIEW_CLIENT_API_KEYS);
  const { loading, error, data, refetch } = useQuery(CLIENT_API_KEYS_QUERY, { skip: !isAuthorized });

  const structuredKeys = data?.client?.structured_api_keys || [];
  const clientId = data?.client?.client_id ? data.client.client_id : '';
  const collectorUrl = `${COLLECTOR_URL}/event`;

  const [isCreatingApiKey, setIsCreatingApiKey] = useState<boolean>(false);

  return (
    <CenteredContainer>
      <PageHeader title="Security Hub Integration" titleLevel={2} />
      {!isAuthorized ? (
        <AuthorizationErrorAlert failedPermissions={failedPermissions} />
      ) : (
        <>
          <Paragraph>
            {`Click to generate an API key to forward Security Hub events to DisruptOps. Then select your deployment option and copy the link to the CloudFormation or Terraform templates. For CloudFormation the link includes the API key as a parameter. For Terraform you will also need to copy the tfvars text and provide that when you use the template.\n`}
          </Paragraph>
          <Paragraph>
            {`Run the Terraform StackSet file with the command 'terraform apply -parallelism=1' when deploying more than one Stack. If you do not want the StackSet to deploy to all regions, edit the list within the Terraform file before execution. If you wish to run this through automation instead of the console, please contact support.\n`}
          </Paragraph>
          <PageHeader
            title="API Keys and Event Forwarder Files"
            titleLevel={3}
            actions={
              <Button
                onClick={() => {
                  setIsCreatingApiKey(true);
                }}
                >
                Create API Key
              </Button>}
          ></PageHeader>
          <b>Client ID:</b> {clientId}
          <Panel rounded>
            {error ? (
              <ErrorAlert error={error} />
            ) : (
              <Table
                antTableProps={{
                  rowKey: (row: any) => row.value,
                  dataSource: structuredKeys.map((key) => ({
                    value: key,
                    link: `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=https://dopsprovision8394402prod.s3-us-west-2.amazonaws.com/event-collector-cf-template&stackName=DisruptOps-Event-Collector&param_ClientID=${clientId}&param_ClientApiKey=${key.apiKey}&param_CollectorURL=${collectorUrl}`,
                    terraform: `https://dopsprovision8394402prod.s3-us-west-2.amazonaws.com/dops-event-collector.tf`,
                    terraformStackSet: `https://dopsprovision8394402prod.s3-us-west-2.amazonaws.com/dops-event-collector-stackset.tf`,
                    tfvars: `profile            = "default"
ClientID           = ${clientId}
ClientApiKey       = ${key.apiKey}
CollectorURL       = ${collectorUrl}`
                  })),
                  columns: [
                    {
                      title: 'label',
                      key: 'label',
                      dataIndex: 'value.label'
                    },
                    {
                      title: 'Key',
                      key: 'apiKey',
                      dataIndex: 'value.apiKey'
                    },
                    {
                      title: 'CloudFormation Link',
                      align: 'center',
                      key: 'createStackLink',
                      dataIndex: 'link',
                      render: (stackLink) => {
                        return (
                          <CopyToClipboard text={stackLink} onCopy={() => message.success('Copied to clipboard')}>
                            <Button className="btn-with-icon">
                              <DopeIcon name="COPY" />
                              {'Copy to clipboard'}
                            </Button>
                          </CopyToClipboard>
                        );
                      }
                    },
                    {
                      title: 'Terraform Stack Link',
                      align: 'center',
                      key: 'terraform',
                      dataIndex: 'terraform',
                      render: (tfFile) => {
                        return (
                          <CopyToClipboard text={tfFile} onCopy={() => message.success('Copied to clipboard')}>
                            <Button className="btn-with-icon">
                              <DopeIcon name="COPY" />
                              {'Copy to clipboard'}
                            </Button>
                          </CopyToClipboard>
                        );
                      }
                    },
                    {
                      title: 'Terraform StackSet Link',
                      align: 'center',
                      key: 'terraformStackSet',
                      dataIndex: 'terraformStackSet',
                      render: (tfFile) => {
                        return (
                          <CopyToClipboard text={tfFile} onCopy={() => message.success('Copied to clipboard')}>
                            <Button className="btn-with-icon">
                              <DopeIcon name="COPY" />
                              {'Copy to clipboard'}
                            </Button>
                          </CopyToClipboard>
                        );
                      }
                    },
                    {
                      title: 'Terraform tfvars',
                      align: 'center',
                      key: 'tfvars',
                      dataIndex: 'tfvars',
                      render: (tfvarsFile) => {
                        return (
                          <CopyToClipboard text={tfvarsFile} onCopy={() => message.success('Copied to clipboard')}>
                            <Button className="btn-with-icon">
                              <DopeIcon name="COPY" />
                              {'Copy to clipboard'}
                            </Button>
                          </CopyToClipboard>
                        );
                      }
                    },
                    {
                      title: 'Created At',
                      key: 'createdAt',
                      dataIndex: 'value.createdAt',
                      render: (createdAt) => {
                        return <DateTime dateTime={new Date(createdAt)} format="local" />;
                      }
                    },
                    {
                      title: 'Delete',
                      align: 'right',
                      key: 'delete',
                      render: (text, record, index) => {
                        return (
                          <DeleteAPIKeyButton apiKey={record.value.apiKey} onDelete={() => refetch()}></DeleteAPIKeyButton>
                        );
                      }
                    }
                  ],
                  style: {
                    width: '100%'
                  },
                  loading: loading ? true : false
                }}
              ></Table>
            )}
          </Panel>
        </>
      )}
      <CreateApiKeyModal
        onCreate={() => refetch()}
        visible={isCreatingApiKey}
        onCloseModal={() => setIsCreatingApiKey(false)}
      />
    </CenteredContainer>
  );
}

export default IntegrationPage;

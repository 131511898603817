import React, { ReactNode, Component } from 'react';
import { FormikValues } from 'formik';
import Form, { FormRenderProps } from 'components/ui/Form/Form';

interface Props {
  initialValues: FormikValues;
  children: (formRenderProps: FormRenderProps) => ReactNode;
}

// currently this isn't doing anything that form doesn't already do...
class FunctionConfigForm extends Component<Props> {
  render() {
    const { initialValues, children } = this.props;

    return (
      <Form initialValues={initialValues}>
        {formBag => {
          return children(formBag);
        }}
      </Form>
    );
  }
}

export default FunctionConfigForm;

import styled from 'styled-components';

export const Root = styled.div`
  .badge-group {
    .badge-group__item {
      margin-right: 1rem;
    }

    .badge-group__item:first-child {
      width: 70px;
    }

    .badge-group__item:nth-child(2) {
      width: 110px;
    }
  }

  @media only screen and (max-width: 760px) {
    .badge-group {
      display: block;
    }

    .badge-group__item {
      width: 100%;
      margin-bottom: 0.25rem;
    }
  }
`;

import React from 'react';
import { Table } from 'antd';
import { Issue, CloudAccount } from 'typings';
import styled from 'styled-components';
import ReviewTableWrap from './components/ReviewTableWrap';
import { getCloudAccountProvider } from 'utilities/cloudAccount';

const Root = styled.div`
  td.title-col {
    color: ${p => p.theme.grey800};
    font-weight: 500;
  }
`;

interface Props {
  issues: Issue[];
  account?: CloudAccount;
}
const pageSize = 10;

function IssuesTable(props: Props) {
  const { issues, account } = props;

  const provider = getCloudAccountProvider(account);

  return (
    <Root>
      <ReviewTableWrap>
        <Table
          dataSource={issues}
          pagination={issues.length > pageSize ? { pageSize } : false}
          rowKey="id"
          columns={[
            {
              key: 'label',
              title: 'Name',
              dataIndex: 'name',
              className: 'title-col'
            },
            {
              title: 'Account',
              key: 'aws_account_id',
              dataIndex: 'awsAccountId',
              render: val => `${provider} ${val}`
            },
            {
              title: 'Account Nickname',
              key: 'account_nickname',
              dataIndex: 'awsAccountId',
              render: () => account && account.nickname
            },
            {
              title: 'Region',
              key: 'region',
              dataIndex: 'region'
            }
          ]}
        />
      </ReviewTableWrap>
    </Root>
  );
}

export default IssuesTable;

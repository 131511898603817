import React from 'react';
import { RouteComponentProps } from 'react-router';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { Project, Config } from 'typings';
import CONFIG_SETTINGS from 'fixtures/DynamicConfig';

import DynamicConfigSettings from 'components/dynamicConfig/DynamicConfigSettings';
import { CenteredContainer } from 'components/ui/NeoPage';
import QueryResult from 'components/util/QueryResult';

const DYNAMIC_CONFIG_BY_PROJECT = gql`
  query DynamicConfigByProject($projectId: String!) {
    project(id: $projectId) {
      id
      dynamicConfigurations {
        pageInfo {
          total
          current
          sortBy
        }
        nodes {
          id
          clientId
          entityType
          key
          value
        }
      }
    }
  }
`;

interface Props extends RouteComponentProps {
  project: Project;
}

function ProjectDynamicConfiguration(props: Props) {
  const {
    project: { project_id: projectId }
  } = props;

  const variables = { projectId };
  const { loading, error, data } = useQuery(DYNAMIC_CONFIG_BY_PROJECT, { variables });

  const handleMutationUpdate = (cache, result) => {
    const savedConfig =
      (result.data && result.data.updateDynamicConfiguration) ||
      (result.data && result.data.createDynamicConfiguration);

    const data: any = cache.readQuery({
      query: DYNAMIC_CONFIG_BY_PROJECT,
      variables
    });

    const existingConfig = data.project.dynamicConfigurations.nodes.find((i) => i.id === savedConfig.id) || null;

    if (!existingConfig) {
      data.project.dynamicConfigurations.nodes = [...data.project.dynamicConfigurations.nodes, savedConfig];

      cache.writeQuery({ query: DYNAMIC_CONFIG_BY_PROJECT, variables, data });
    }
  };

  const handleDelete = (cache, result) => {
    return;
    // console.log('update after delete', { result });
    // we can't remove
  };

  return (
    <QueryResult
      loading={loading}
      data={data}
      error={error}
      parseNodes={(data) => data?.project?.dynamicConfigurations?.nodes || []}
    >
      {(savedConfigs: Config[]) => (
        <CenteredContainer size="fluid">
          <DynamicConfigSettings
            savedConfigs={savedConfigs}
            configSettingGroups={CONFIG_SETTINGS}
            entityId={projectId}
            entityType="PROJECT"
            mutationUpdateFn={handleMutationUpdate}
            updateCacheOnDeleteFn={handleDelete}
          />
        </CenteredContainer>
      )}
    </QueryResult>
  );
}

export default ProjectDynamicConfiguration;

import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import { Field, FormikProps } from 'formik';
import React from 'react';
import ProjectEditor from '../../DetectorsCommon/ProjectEditor/ProjectEditor';
import useDetectorVariants from '../../DetectorsCommon/useDetectorVariants/useDetectorVariants';
import SelectLabels from '../SelectLabels/SelectLabels';

interface EditorGeneralProps {
  formikProps: FormikProps<any>;
  initialAssessor: any;
}

function EditorGeneral(props: EditorGeneralProps) {
  const {
      initialAssessor,
      formikProps: { values: detector, errors, touched, setFieldValue }
    } = props,
    variants = useDetectorVariants();

  function onOwnerChange(id) {
    setFieldValue('projectId', id);
  }

  function onLabelChange(labels) {
    setFieldValue('labels', labels);
  }

  return (
    <div>
      <HorizontalLabel label={`${variants.variantName} Name`} required form>
        <span className={errors.name && touched.name ? 'has-error' : undefined}>
          <Field name="name" className="ant-input" />
          {errors.name && touched.name ? <div className="ant-form-explain">{errors.name}</div> : null}
        </span>
      </HorizontalLabel>
      <HorizontalLabel label="Owner">
        <ProjectEditor projectId={detector.projectId} onChange={onOwnerChange} />
      </HorizontalLabel>
      <HorizontalLabel label="Labels">
        <SelectLabels onLabelChange={onLabelChange} detector={detector} initialAssessor={initialAssessor} />
      </HorizontalLabel>
    </div>
  );
}

export default EditorGeneral;

import styled from 'styled-components';

const ValueChip = styled.div`
  background-color: ${(p) => p.theme.primary500};
  margin-right: 6px;
  padding: 2px 8px;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  margin-top: -2px;

  svg {
    display: block;
    margin-right: 4px;
  }
`;

export default ValueChip;

import React from 'react';
import IssueDefinitionSelect from 'components/issue/IssueDefinitionSelect';
import { FormField } from 'components/ui/Form';
import { GuardrailConfigurationInputProps } from '../definitions';

function IssueCreatedTriggerInput(props: GuardrailConfigurationInputProps) {
  const { name } = props;

  return (
    <FormField name={`${name}.issueDefinitionId`} label="Select Issue Type">
      {({ value, handleChange, handleBlur }) => {
        return <IssueDefinitionSelect value={value} onChange={handleChange} onBlur={handleBlur} />;
      }}
    </FormField>
  );
}

export default IssueCreatedTriggerInput;

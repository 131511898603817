import React from 'react';
import { GuardrailConfigurationInputProps } from '../definitions';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { FormField } from 'components/ui/Form';

function SelectIssueDefinitionReadView(props: GuardrailConfigurationInputProps) {
  const { name } = props;

  return (
    <FormField name={name} wrapInFormItem={false}>
      {({ value }) => {
        if (!value.issueDefinitionId) return <>{null}</>;

        return (
          <Query
            fetchPolicy="cache-first"
            query={gql`
              query IssueDefinitionForSelectReadView($id: ID) {
                issueDefinitions(id: $id) {
                  nodes {
                    id
                    name
                  }
                }
              }
            `}
            variables={{ id: value.issueDefinitionId }}
          >
            {({ loading, error, data }) => {
              if (loading || error || !data.issueDefinitions || data.issueDefinitions.nodes.length === 0) return null;

              return <>{data.issueDefinitions.nodes[0].name}</>;
            }}
          </Query>
        );
      }}
    </FormField>
  );
}

export default SelectIssueDefinitionReadView;

import React, { useState, useEffect } from 'react';
import { NewGuardrail } from 'typings';

// MOVE interface here
import { ValidationError, validateGuardrail } from 'services/graphql/state/guardrails';

export interface GuardrailValidatorRenderProps {
  validating: boolean;
  validationError: null | ValidationError;
}

interface Props {
  guardrail: NewGuardrail;

  children: (renderProps: GuardrailValidatorRenderProps) => React.ReactNode | React.ReactNodeArray;
}

function GuardrailValidator(props: Props) {
  const { guardrail, children } = props;
  const [validating, setValidatingState] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<ValidationError | null>(null);

  useEffect(() => {
    const validateFn = async () => {
      setValidatingState(true);

      const validationError: ValidationError | null = await validateGuardrail(guardrail);

      setValidationError(validationError);
      setValidatingState(false);
    };

    validateFn();
  }, [guardrail]);

  const renderProps: GuardrailValidatorRenderProps = {
    validating,
    validationError
  };

  return <>{children(renderProps)}</>;
}

export default GuardrailValidator;

import React from 'react';
import styled from 'styled-components';
import { TriggerFilterCollapseReadViewProps } from '.';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { AutomationEventSource } from 'typings';
import { Spin } from 'antd';

const CollapseTitleReadViewRoot = styled.div`
  display: flex;
  flex: 1;
`;

const EVENT_SOURCE_BY_ID = gql`
  query eventSourceById($id: [String]) {
    eventSources(id: $id) {
      nodes {
        id
        key
        name
        description
        overview
        cloudVendor
        labels
        stages
        context
        criteria {
          path
          patterns
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export function EventSourcesCollapseReadView(props: TriggerFilterCollapseReadViewProps) {
  const { value } = props;

  const ids = Array.isArray(value) ? value : [value];

  const { loading, error, data } = useQuery(EVENT_SOURCE_BY_ID, {
    variables: { id: ids },
    fetchPolicy: 'cache-first'
  });

  if (!value) return <>{'Any'}</>;

  if (loading) return <Spin size="small" />;
  if (error || !data) return null;

  const eventSources: [AutomationEventSource] = data?.eventSources?.nodes || [];

  return (
    <CollapseTitleReadViewRoot>
      {eventSources.map((eventSource) => eventSource.name)}
    </CollapseTitleReadViewRoot>
  );
}

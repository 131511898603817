import styled from 'styled-components';

export const Editor = styled.div`
  .editor-row {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;

    input {
      max-width: 200px;

      &:last-of-type {
        margin-right: 1rem;
      }
    }

    .editor-row__spacer {
      padding: 0 0.5rem;
      font-size: 16px;
    }
  }
`;

import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { message } from 'antd';

import { Notification } from 'typings';

import { NOTIFICATION_FRAGMENT, NOTIFICATION_LIST_QUERY } from '../gql';
import { DeleteButton } from 'components/buttons';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

const DELETE_NOTIFICATION_MUTATION = gql`
  mutation deleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

interface Props {
  notification: Notification;
}

function DeleteNotificationButton(props: Props) {
  const { notification } = props;

  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_NOTIFICATIONS, '*');
  const [deleteNotification, { loading }] = useMutation(DELETE_NOTIFICATION_MUTATION, {
    refetchQueries: [{ query: NOTIFICATION_LIST_QUERY }]
  });

  return (
    <DeleteButton
      confirmMessage={`Are you sure you want to delete the notification ${notification.name} and all recipients?`}
      id={notification.id}
      loading={loading}
      disabled={!isAuthorized}
      disabledMessage={authErrorMessage || ''}
      tooltipPlacement="left"
      mode="default"
      onDelete={async (id) => {
        try {
          await deleteNotification({
            variables: { id: notification.id }
          });
          message.success(`Successfully deleted notification ${notification.name}.`);
        } catch (err) {
          message.error(`Error deleting notification ${notification.name}.`);
        }
      }}
    ></DeleteButton>
  );
}

export default DeleteNotificationButton;

import React from 'react';

function ObjectFieldTemplate(props) {
  return (
    <div key={props.key}>
      {props.title}
      {props.description}
      {props.properties.map(element => element.content)}
    </div>
  );
}

export default ObjectFieldTemplate;

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './styles/global.css';
import './styles/antOverrides.less';
import 'core-js/modules/es7.symbol.async-iterator';
import 'core-js/modules/es6.function.name';
import 'core-js/modules/es6.string.starts-with';
import 'core-js/modules/es6.array.from';
import 'core-js/modules/es6.promise';
import 'core-js/modules/es7.array.includes';

import App from './app/App';

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { AuthContext } from 'components/app/Auth/AuthContext';
import { authorizePermissionsWithoutHooks } from 'components/app/Auth/Authorizor';
import { Feature, generateFlags } from 'components/app/FeatureFlag';
import DopeIcon from 'components/ui/DopeIcon';
import IconButton from 'components/ui/IconButton';
import QueryString from 'components/util/QueryString';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { User } from 'typings';
import { DetectorsSection } from '../Detectors';
import OpsSection from '../Ops/OpsSection/OpsSection';
import MainSidenav from './components/MainSidenav';
import ModalsRouter, { modalPaths } from './components/ModalsRouter';
import SidenavController from './components/SidenavController';
import {
  AutomationActivity,
  CloudAccounts,
  CompliancePage,
  Dashboard,
  EventsPage,
  GettingStarted,
  Inventory,
  IssuesPage,
  ProjectManagerPage,
  ResourceDetail
} from './pages';
import AssessmentsSection from './pages/Assessments/AssessmentsSection';
import CloudAccountNew from './pages/CloudAccountNew';
import DevScreen from './pages/Dev';
import EventDetailPage from './pages/Events/EventDetailPage';
import EventsStatsPage from './pages/Events/EventsStatsPage';
import EventTakeActionPage from './pages/Events/EventTakeActionPage';
import { GuardrailDetail, GuardrailsSection } from './pages/Guardrails';
import { OpsSection as OldOpsSection } from './pages/Ops';
import OrganizationSettings from './pages/OrganizationSettings';
import AuthenticateSlackTarget from './pages/OrganizationSettings/pages/AuthenticateSlackTarget';
import Settings from './pages/Settings';
import SignOutPage from './pages/SignOut';

const Layout = styled.div`
  background-color: #fafafa;
  z-index: 1;
  min-height: 100vh;
  position: relative;
  transition: padding 0.2s ease;
  padding: 0;
  &.sidenav-permanent {
    padding-left: 68px;
    &.sidenav-open {
      padding-left: 240px;
    }
  }
  .menu-toggle {
    height: 56px;
    width: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 4px;
    top: 0;
    transition: all 0.2s ease;
    z-index: 100;
  }
  &.sidenav-permanent .menu-toggle {
    left: -56px;
  }
  .main-layout-content {
    /* width: ; */
  }
`;

declare global {
  interface Window {
    gtag?: (key: string, trackingId: string, config: { page_path: string }) => void;
  }
}

interface Props extends RouteComponentProps {
  user: User;
}

interface State {
  menuIsExpanded: boolean;
  menuIsPermanent: boolean;
  menuIsActive: boolean;
}

class AuthenticatedRouter extends React.Component<Props, State> {
  static contextType = AuthContext;

  previousLocation = this.props.location;

  constructor(props: Props) {
    super(props);

    if (testModalPath(props.location.pathname)) {
      this.previousLocation = { ...props.location };

      // set background view to dashboard if modal opens with fresh page.
      this.previousLocation.pathname = '/';
    }

    this.state = {
      menuIsPermanent: false,
      menuIsExpanded: false,
      menuIsActive: false
    };
  }

  componentWillUpdate(nextProps: Props) {
    const { location } = this.props;
    //set prevLocation if props.location is not modal
    if (nextProps.history.action !== 'POP' && !testModalPath(location.pathname)) {
      this.previousLocation = location;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {
    const { location } = this.props;
    if (!window.gtag) return;

    try {
      window.gtag('config', 'G-FKBMJNBM43', { page_path: location.pathname });
    } catch (err) {
      const { trackJs } = window as any;

      if (trackJs) {
        trackJs.track(err);
      }
    }
  }

  render() {
    const { location, user } = this.props;
    const isModal = testModalPath(location.pathname);
    const { me, rootProjectId } = this.context;
    const authorizePermissionResults = authorizePermissionsWithoutHooks(
      this.context,
      [Permissions.MODIFY_ACCOUNTS],
      rootProjectId
    );
    const { isAuthorized } = authorizePermissionResults;
    const showGettingStarted = isAuthorized && me?.authorized_accounts?.length <= 0;
    const featureFlags = generateFlags(
      [Feature.DETECTORS_NEW_DESIGN, Feature.EVENTS_STATS_DASHBOARD, Feature.OPS_NEW_DESIGN],
      this.context
    );

    return (
      <SidenavController>
        {(sidenavRenderProps) => {
          const { open, permanent, toggleSidenav } = sidenavRenderProps;
          let layoutClasses = 'main-layout';

          if (open) layoutClasses += ' sidenav-open';
          if (permanent) layoutClasses += ' sidenav-permanent';

          return (
            <Layout className={layoutClasses}>
              <div className="menu-toggle">
                <IconButton
                  type={'ghost'}
                  onClick={(e) => {
                    toggleSidenav(true);
                  }}
                >
                  <DopeIcon name="MENU" size={26} />
                </IconButton>
              </div>

              <MainSidenav sidenavRenderProps={sidenavRenderProps} user={user} />

              <div className="main-layout-content">
                <QueryString {...this.props} location={isModal ? this.previousLocation : location}>
                  <Switch location={isModal ? this.previousLocation : location}>
                    {this.context.isDisruptOpsUser && <Route path="/dev/:screen?" component={DevScreen} />}
                    <Route path="/issues:status?" component={IssuesPage} />

                    {featureFlags[Feature.EVENTS_STATS_DASHBOARD] ? (
                      <Route path="/events/stats" component={EventsStatsPage} />
                    ) : (
                      <Route path="/events" component={EventsPage} />
                    )}

                    <Route path="/events/:eventId/actions/:functionId" component={EventTakeActionPage} />
                    <Route path="/event/:eventId" component={EventDetailPage} />
                    <Route path="/events/:eventId" component={EventDetailPage} />
                    <Route path="/events:status?" component={EventsPage} />

                    <Route path="/cloud-accounts/add/:step?" component={CloudAccountNew} />

                    <Route path="/cloud-accounts" component={CloudAccounts} />

                    <Route path="/inventory/resources/:dopsResourceType/:dopsResourceUid" component={ResourceDetail} />
                    <Route path="/inventory/resources/:resourceType?" component={Inventory} />

                    <Route path="/inventory" render={() => <Redirect to="/inventory/resources" />} />

                    <Route path="/login" render={() => <Redirect to="/" />} />
                    <Route path="/logout" component={SignOutPage} />
                    <Route path="/organization-settings/:section?" component={OrganizationSettings} />
                    <Route path="/authenticate-slack-target" component={AuthenticateSlackTarget} />

                    <Route
                      path="/compliance"
                      render={() =>
                        user.client?.settings?.complianceEnabled ? <CompliancePage /> : <Redirect to="/" />
                      }
                    />

                    {featureFlags[Feature.OPS_NEW_DESIGN] ? (
                      <Route path="/ops" component={OpsSection} />
                    ) : (
                      <Route path="/ops/:tab?" component={OldOpsSection} />
                    )}

                    {featureFlags[Feature.OPS_NEW_DESIGN] && <Route path="/ops1/:tab?" component={OldOpsSection} />}

                    <Route path="/activity" component={AutomationActivity} />

                    {/* Not sure we need this any more? */}
                    <Route path="/guardrails/new" component={GuardrailDetail} />

                    <Route path="/guardrails/details/:id" component={GuardrailDetail} />

                    <Route path="/guardrails/:tab?" component={GuardrailsSection} />

                    {featureFlags[Feature.DETECTORS_NEW_DESIGN] ? (
                      <Route path="/detectors" component={DetectorsSection} />
                    ) : (
                      <Route path="/detectors" component={AssessmentsSection} />
                    )}

                    <Route path="/projects/:id?" component={ProjectManagerPage} />

                    <Route path="/settings" component={Settings} />

                    <Route path="/dashboard" component={Dashboard} />

                    <Route path="/getting-started" component={GettingStarted} />

                    {showGettingStarted ? (
                      <Route path="/" component={GettingStarted} />
                    ) : (
                      <Route path="/" component={Dashboard} />
                    )}
                  </Switch>
                </QueryString>

                {isModal && (
                  <Route
                    children={(routeProps) => <ModalsRouter {...routeProps} previousLocation={this.previousLocation} />}
                  />
                )}
              </div>
            </Layout>
          );
        }}
      </SidenavController>
    );
  }
}

/**
 * test if path is modal path.
 */
function testModalPath(path: string) {
  const l = modalPaths.length;
  let foundMatch = false;

  for (let i = 0; !foundMatch && i < l; i++) {
    const item = modalPaths[i];

    if (path.startsWith(item)) foundMatch = true;
  }

  return foundMatch;
}

export default AuthenticatedRouter;

import React from 'react';
import NeoPage, { TitleBar, CenteredContainer } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import gql from 'graphql-tag';
import FunctionTable from './FunctionTable';
import styled from 'styled-components';

const FUNCTIONS_QUERY = gql`
  query AutomationFunctions(
    $id: [String]
    $search: String
    $labels: [String]
    $cloudVendor: [String]
    $sortBy: String
    $sortDirection: SortDirection
    $pageNumber: Int
    $pageSize: Int
    $releaseChannel: [String]
    $isDisabled: Boolean
  ) {
    automationFunctions(
      id: $id
      search: $search
      labels: $labels
      cloudVendor: $cloudVendor
      sortBy: $sortBy
      sortDirection: $sortDirection
      pageNumber: $pageNumber
      pageSize: $pageSize
      releaseChannel: $releaseChannel
      isDisabled: $isDisabled
    ) {
      pageInfo {
        total
        current
        size
        sortBy
        sortDirection
      }
      nodes {
        id
        key
        name
        cloudVendor
        description
        overview
        version
        labels
        isDeprecated
        updatedAt
        effects
        revertFunction {
          functionId
        }
      }
    }
  }
`;

const Root = styled.div`
  .centered-container {
    padding-bottom: 96px; // to get the intercom button out of the way of the pagination controls
    padding-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

function FunctionsPage() {
  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            icon={<DopeIcon name="FUNCTION" size={20} />}
            sectionTitle="Functions"
            sectionTitleLinkTo="/dev/functions"
            backLinkTo="/dev"
            backLinkTooltipText="Back to dev section"
            // actions={
            //   <TitleBarButton linkTo="/functions/run" icon={<DopeIcon name="ADD" />}>
            //     Run
            //   </TitleBarButton>
            // }
          />
        }
      >
        <CenteredContainer>
          <FunctionTable
            query={FUNCTIONS_QUERY}
            getFunctionsRootFromQueryResult={(data) => data && data.automationFunctions}
          />
        </CenteredContainer>
      </NeoPage>
    </Root>
  );
}

export default FunctionsPage;

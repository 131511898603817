import React, { ReactNode, MouseEvent } from 'react';
import { Route } from 'react-router-dom';

interface Props {
  href: string;
  children: ReactNode;
}

function TableLink(props: Props) {
  const { href, children } = props;

  return (
    <Route
      children={({ history }) => (
        <a
          href={href}
          onClick={(e: MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
            history.push(href);
          }}
        >
          {children}
        </a>
      )}
    />
  );
}

export default TableLink;

import React from 'react';
import gql from 'graphql-tag';

import { Select } from 'antd';

import styled from 'styled-components';

import { Query } from 'react-apollo';

const UNIQUE_ASSESSMENT_LABELS = gql`
  query AssessmentLabelsForFilterBar {
    uniqueAssessmentLabels
  }
`;

const Root = styled.div``;

interface Props {
  value?: string[] | null;
  onChange: (value: string[]) => void;
  query?: string | null;
  getLabelsRootFromQueryResult?: (data: any) => string[];
}

function LabelsFilter(props: Props) {
  const { value, onChange, query = UNIQUE_ASSESSMENT_LABELS } = props;
  let { getLabelsRootFromQueryResult } = props;

  if (!getLabelsRootFromQueryResult) {
    getLabelsRootFromQueryResult = (data: any) => {
      return data && data.uniqueAssessmentLabels;
    };
  }

  return (
    <Root>
      <Query query={query}>
        {({ loading, data, error }) => {
          const labels = getLabelsRootFromQueryResult && getLabelsRootFromQueryResult(data);

          return (
            <Select
              mode="multiple"
              value={value || undefined}
              style={{ minWidth: '200px', maxWidth: '500px' }}
              onChange={onChange}
              dropdownMatchSelectWidth={false}
              placeholder={'Select label(s)'}
              maxTagCount={2}
              maxTagTextLength={48}
              maxTagPlaceholder={extraValues => {
                return <>+&nbsp;{extraValues.length}</>;
              }}
            >
              {!loading &&
                labels &&
                labels.map((o, idx) => {
                  return (
                    <Select.Option key={`${o}_${idx}`} value={o}>
                      {o}
                    </Select.Option>
                  );
                })}
            </Select>
          );
        }}
      </Query>
    </Root>
  );
}

export default LabelsFilter;

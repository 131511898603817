import React, { useState } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { FilterTypeProps } from '../FilterListing/FilterListing';
import CustomJSONPathFilterInput from 'components/events/CustomJSONPathFilterInput';
import { AutomationEventDefinition } from 'typings';

interface AdvancedEditorProps extends FilterTypeProps {
  eventDefinition: AutomationEventDefinition;
  eventFromConsole: any;
}

function AdvancedEditor(props: AdvancedEditorProps) {
  const { values, filterType, eventDefinition, eventFromConsole } = props,
    [softEdit, setSoftEdit] = useState<SoftEdit | null>({
      key: filterType.valueKey,
      value: values[filterType.valueKey]
    }),
    onChange = (update) => {
      setSoftEdit({
        key: filterType.valueKey,
        value: update
      });
    },
    editor = (
      <>
        <CustomJSONPathFilterInput
          name={filterType.valueKey}
          value={values[filterType.valueKey]}
          eventFromEventConsole={eventFromConsole}
          onChange={onChange}
          eventDefinition={eventDefinition}
        />
      </>
    );

  return (
    <>
      <FilterRow {...props} editor={editor} softEdit={softEdit} />
    </>
  );
}

export default AdvancedEditor;

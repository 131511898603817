import React from 'react';
import styled from 'styled-components';

// COMPONENT: AUTOMATION LIST ITEM META
const OpSectionListItemMetaRoot = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 1em;
  text-transform: uppercase;
  letter-spacing: 0.025em;

  &.small {
    font-size: 11px;
  }

  > * {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }
  .automation-list-item-meta-icon {
    margin-right: 4px;
  }

  .automation-list-item-meta-label,
  .automation-list-item-meta-value {
    color: #d4d4d4;
    white-space: nowrap;
  }

  .automation-list-item-meta-label {
    margin-right: 4px;
  }

  &.has-label .automation-list-item-meta-value {
    color: ${p => p.theme.grey800};
    text-transform: initial;
  }
`;

interface OpSectionListItemMetaProps {
  value: string;
  label?: string;
  icon?: React.ReactNode; // icons should be no larger than 14px?
  size?: 'small';
}

function OpSectionListItemMeta(props: OpSectionListItemMetaProps) {
  const { value, icon, label, size } = props;

  return (
    <OpSectionListItemMetaRoot className={`${label ? 'has-label' : ''} ${size || ''} `}>
      {icon && <div className="automation-list-item-meta-icon">{icon}</div>}
      {label && <div className="automation-list-item-meta-label">{label}</div>}
      <div className="automation-list-item-meta-value">{value}</div>
    </OpSectionListItemMetaRoot>
  );
}
export default OpSectionListItemMeta;

import { Spin } from 'antd';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { getChartColor } from '.';
import ErrorAlert from 'components/ui/ErrorAlert';

const query = gql`
  query classifiedEventCountByReceivedAt(
    $filters: ClassifiedEventStatFilters
    $interval: CalendarInterval
    $nextKey: DateTime
    $size: Int
  ) {
    classifiedEventCountByReceivedAt(filters: $filters, interval: $interval, nextKey: $nextKey, size: $size) {
      duration
      total
      nextKey
      items {
        key
        count
      }
    }
  }
`;

interface Props {}

export default function EventCountLineChart(props: Props) {
  const { data, error, loading } = useQuery(query, { variables: { interval: 'hour', size: 72 } });
  const { classifiedEventCountByReceivedAt } = data || {};
  const { items } = classifiedEventCountByReceivedAt || {};

  const timeFormatter = (tick) => {
    return new Date(tick).toLocaleTimeString();
  };

  const dateTimeFormatter = (tick) => {
    return new Date(tick).toLocaleString();
  };

  console.log({ data });

  return (
    <>
      {loading && <Spin style={{ marginRight: '8px' }} />}

      {error && <ErrorAlert error={error} />}

      {!loading && items?.length > 0 && (
        <>
          {/* <b>Total Events Per Minute</b>: */}
          <LineChart width={600} height={400} data={items}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="key" tickFormatter={timeFormatter} />
            <YAxis tickFormatter={(tick) => tick.toLocaleString()} />
            <Tooltip
              formatter={(value) => {
                return [value.toLocaleString(), 'Total'];
              }}
              labelFormatter={(label) => dateTimeFormatter(label)}
            />
            <Line type="monotone" dataKey="count" stroke={getChartColor(0)} dot={false} isAnimationActive={false} />
          </LineChart>
        </>
      )}
    </>
  );
}

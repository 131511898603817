import { Descriptions, Icon } from 'antd';
import DateTime from 'components/ui/DateTime';
import React from 'react';
import styled from 'styled-components';
import ErrorBoundary from 'components/ui/ErrorBoundary';

interface SectionItem {
  label: string;
  value: any;
}

interface Section {
  title: string;
  items: SectionItem[];
}

interface Props {
  sections: Section[];
  hideFalsyItemsAndSections: boolean;
}

const Root = styled.div`
  .ant-page-header-heading-title {
    font-size: 21px;
  }

  .ant-descriptions-title {
    margin-bottom: 4px;
  }

  .ant-descriptions-item {
    padding-bottom: 4px;
  }

  .kpi {
    border-left: 4px solid #46327e;
    padding-left: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

function EventDetailDescriptions(props: Props) {
  const { sections, hideFalsyItemsAndSections = true } = props;

  // any description items that don't have a value will get filtered out and won't be displayed

  // any description sets that don't have any items will get filtered out and won't be displayed

  const sectionsToDisplay = hideFalsyItemsAndSections
    ? sections.filter(({ items }) => items.some((i) => i.value))
    : sections;

  return (
    <ErrorBoundary>
      <Root>
        {sectionsToDisplay.map(({ items, title }, index) => {
          const itemsToDisplay = hideFalsyItemsAndSections ? items.filter((i) => i.value) : items;

          return (
            <ErrorBoundary>
              <Descriptions className="kpi" column={1} key={index} title={title}>
                {itemsToDisplay.map(({ label, value }, index) => (
                  <Descriptions.Item label={label} key={index}>
                    <ErrorBoundary>{value}</ErrorBoundary>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </ErrorBoundary>
          );
        })}
      </Root>
    </ErrorBoundary>
  );
}

const ipv4Regex = /^(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
export function getValueDisplay(key: string, value: any) {
  // array
  if (Array.isArray(value)) {
    return value
      .map((childValue) => getValueDisplay(key, childValue))
      .filter((childValue) => childValue !== null && childValue !== undefined)
      .reduce(
        (previousValue, currentValue) => (
          <>
            {previousValue}, {currentValue}
          </>
        ),
        ''
      );
  }

  // date
  if (value instanceof Date) {
    return <DateTime dateTime={value} />;
  }

  // date string
  if (typeof value === 'string' && !isNaN(Date.parse(String(value)))) {
    return <DateTime dateTime={String(value)} />;
  }

  // ipv4 address
  if (typeof value === 'string' && ipv4Regex.test(value)) {
    return (
      <a
        href={`/inventory/resources?s=${value}`}
        target="_blank"
        rel="noopener noreferrer"
        title={`Search DisruptOps Resource Inventory for IPv4 address "${value}"`}
      >
        {value} <Icon type="search" />
      </a>
    );
  }

  // ID string
  if (typeof key === 'string' && key.toLowerCase().endsWith('id')) {
    return (
      <a
        href={`/inventory/resources?s=${value}`}
        target="_blank"
        rel="noopener noreferrer"
        title={`Search DisruptOps Resource Inventory for "${value}"`}
      >
        {value} <Icon type="search" />
      </a>
    );
  }

  // UserName string
  if (typeof key === 'string' && key.toLowerCase() === 'username') {
    return (
      <a
        href={`/inventory/resources?s=${value}`}
        target="_blank"
        rel="noopener noreferrer"
        title={`Search DisruptOps Resource Inventory for "${value}"`}
      >
        {value} <Icon type="search" />
      </a>
    );
  }

  if (typeof value === 'object') return null;

  return value;
}

export function objectToSectionItems(obj: object) {
  return obj
    ? Object.entries(obj)?.map(([key, value]) => {
        return {
          label: key,
          value: getValueDisplay(key, value)
        };
      })
    : [];
}

export default EventDetailDescriptions;

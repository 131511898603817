import gql from 'graphql-tag';

const ASSESSMENT_GUARDRAILS_FRAGMENT = gql`
  fragment AssessmentGuardrail on Assessment {
    installedGuardrails {
      id
      createdAt
      assessmentId
      issueDefinitionId
      functionId # action id
      isEnabled
      staticConfiguration
      dynamicConfigurations {
        id
        key
        node {
          id
          entityType
          entityId
          key
          value
        }
      }
    }
  }
`;

export default ASSESSMENT_GUARDRAILS_FRAGMENT;

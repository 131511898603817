import { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import AuthContext from 'components/app/Auth/AuthContext';

class SignOutPage extends Component<RouteComponentProps> {
  static contextType = AuthContext;

  componentDidMount() {
    this.context.signOut();
  }
  render() {
    return null;
  }
}

export default SignOutPage;

import { useEffect, useState, useContext } from 'react';
import SSE from 'services/sse';
import AuthContext from 'components/app/Auth/AuthContext';

function useSSE(handleSSEMessage: (message: any) => any | void) {
  const authContext = useContext(AuthContext);
  const [sse, setSSE] = useState<SSE>();

  useEffect(() => {
    const sse = new SSE({
      debug: authContext?.devSettings?.isSSEDebuggingEnabled ? true : false
    });
    setSSE(sse);
  }, []);

  useEffect(() => {
    sse && sse.on('message', handleSSEMessage);
    return () => {
      sse && sse.removeListener('message', handleSSEMessage);
    };
  });

  return sse;
}

export default useSSE;

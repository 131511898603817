import React from 'react';
import styled from 'styled-components';
import { TriggerFilterCollapseReadViewProps } from '.';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { CloudAccount } from 'typings';
import { Spin } from 'antd';

const CollapseTitleReadViewRoot = styled.div`
  display: flex;
  flex: 1;
`;

export function AccountIdsCollapseReadView(props: TriggerFilterCollapseReadViewProps) {
  const { value } = props;

  if (!value) return <>{'Any'}</>;

  const chips = value
    .map((id) => (
    <AccountChip key={id} accountId={id} />
    ))
    .reduce((prev, curr) => [prev, ', ', curr]);

  return (
    <CollapseTitleReadViewRoot>
      {chips}
    </CollapseTitleReadViewRoot>
  );
}

const ACCOUNT_BY_ID = gql`
  query AccountById($id: ID!) {
    account(id: $id) {
      account_id
      nickname
      name
      provider
    }
  }
`;

interface AccountChipProps {
  accountId: string;
}

function AccountChip(props: AccountChipProps) {
  const { accountId } = props;
  const results = useQuery(ACCOUNT_BY_ID, {
    variables: { id: accountId },
    fetchPolicy: 'cache-first'
  });
  const { loading, data, error } = results;

  if (error || !data || loading) return <Spin size="small" />;

  const cloudAccount: CloudAccount = data.account;
  const { nickname, name, account_id } = cloudAccount;

  return (
    <>{nickname || name || account_id}</>
  );
}

import { message, Switch } from 'ant4x';
import DelayedLoader from 'designSystem/DelayedLoader/DelayedLoader';
import gql from 'graphql-tag';
import { OP_FIELDS } from 'queries/fragments/opFields';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { Op } from 'typings';
import { validateRawOp } from '../../Editor/OpDetail/OpEditor';

interface Props {
  op: Op;
}

export const SAVE_OP_MUTATION = gql`
  mutation SaveOpMutation($id: String, $opInput: OpInput!) {
    saveOp(id: $id, opInput: $opInput) {
      ...OpFields
    }
  }
  ${OP_FIELDS}
`;

export default function OpSwitch(props: Props) {
  const { op } = props;
  const { id, name, projectId, isEnabled, archivedAt, deletedAt } = op;
  const [saveOp, saveOpResults] = useMutation(SAVE_OP_MUTATION);
  const checked = isEnabled ?? false;
  const disabled = saveOpResults.loading || !!archivedAt || !!deletedAt,
    [opToSave, setOpToSave] = useState<boolean | Op | null>(),
    helpText = archivedAt
      ? 'Archived Ops cannot be enabled.'
      : deletedAt
      ? 'Deleted Ops cannot be enabled.'
      : undefined;

  async function setOpIsEnabled(isEnabled: boolean, op: Op) {
    // validate only if enabling
    // no validation required to disable
    if (isEnabled && !opToSave) {
      // show validation failed message and bail
      message.warning(`The op '${name}' must be completed before enabling.`);
      return;
    }

    const variables = {
      id,
      opInput: {
        name, // required by resolver...
        projectId,
        isEnabled
      }
    };

    try {
      await saveOp({ variables });

      message.success(`The op '${name}' was ${isEnabled ? 'enabled' : 'disabled'}.`);
    } catch (err) {
      message.error(`There was a problem updating the op '${name}'.`);
    }
  }

  useEffect(() => {
    validateRawOp(op, true).then((op) => setOpToSave(op));
  }, []);

  if (opToSave === null) {
    return <DelayedLoader />;
  }

  if (opToSave === false) {
    return null;
  }

  return (
    <Switch
      size="small"
      checked={checked}
      disabled={disabled}
      loading={saveOpResults.loading}
      title={helpText}
      onChange={async (checked, event) => {
        event.stopPropagation();
        await setOpIsEnabled(checked, op);
      }}
    />
  );
}

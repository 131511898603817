import React from 'react';
import * as Yup from 'yup';
import { getIn } from 'formik';
import { NewGuardrail } from 'typings';
import { Collapse, Button } from 'antd';

import { GridCard } from 'components/ui/Card';
import Form from 'components/ui/Form';
import DopeIcon from 'components/ui/DopeIcon';

import { GuardrailEditorState, GuardrailEditorStep } from '../../GuardrailEditor';
import { GuardrailTriggerEventTypeDefinition } from '../trigger/definitions';
import CollapseHeader from 'components/ui/Collapse/CollapseHeader';
import GridCardFooter from 'components/guardrails/GridCardFooter';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

const { Panel } = Collapse;

interface Props extends GuardrailEditorState {
  eventTypeDefinition: GuardrailTriggerEventTypeDefinition | null;
  setExpandedFilterKey: (selected: string) => void;
  expandedFilterKey: string | null;
  onSectionSubmitSuccess: (updatedGuardrail: NewGuardrail) => void;
  exampleTriggerEvent?: any;
}

function buildFiltersSectionSchema(eventTypeDefinition: GuardrailTriggerEventTypeDefinition | null) {
  if (!eventTypeDefinition || !eventTypeDefinition.filters) return Yup.object();

  const shape: any = {};

  eventTypeDefinition.filters.forEach((filter) => {
    if (filter.schema) {
      shape[filter.key] = filter.schema;
    }
  });

  return Yup.object().shape(shape);
}

function buildInitialValues(guardrail: NewGuardrail, eventTypeDefinition: GuardrailTriggerEventTypeDefinition | null) {
  const initValues = guardrail.filtersConfiguration || {};

  if (eventTypeDefinition && eventTypeDefinition.filters) {
    eventTypeDefinition.filters.forEach((filter) => {
      if (filter.getInitialValueFromGuardrail) {
        initValues[filter.key] = filter.getInitialValueFromGuardrail(guardrail);
      }
    });
  }

  return initValues;
}

function GuardrailFiltersSection(props: Props) {
  const {
    guardrail,
    eventTypeDefinition,
    exampleTriggerEvent,
    activeStep,
    expandedFilterKey,
    saveGuardrailMutationFn,
    setExpandedFilterKey,
    onSectionSubmitSuccess
  } = props;

  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [
      {
        permissionId: Permissions.MODIFY_GUARDRAILS,
        projectIds: '*'
      }
    ]
  });

  const sectionIsActive = activeStep === GuardrailEditorStep.CONFIGURE_FILTERS;

  const schema = buildFiltersSectionSchema(eventTypeDefinition);
  const initialValues = buildInitialValues(guardrail, eventTypeDefinition);

  return (
    <Form
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={async (formValues, actions) => {
        let guardrailToSave: any = {
          id: guardrail.id,
          filtersConfiguration: formValues
        };

        if (eventTypeDefinition && eventTypeDefinition.filters) {
          eventTypeDefinition.filters.forEach((filter) => {
            if (filter.transformGuardrailBeforeSaving)
              guardrailToSave = filter.transformGuardrailBeforeSaving(guardrailToSave);
          });
        }

        const variables = {
          guardrail: guardrailToSave
        };

        const result = await saveGuardrailMutationFn({ variables });
        const updatedGuardrail: NewGuardrail = result.data.saveGuardrail;

        actions.setSubmitting(false);

        onSectionSubmitSuccess(updatedGuardrail);
      }}
    >
      {(formRenderProps) => {
        const { isSubmitting, canSubmit } = formRenderProps;

        return (
          <GridCard
            title="Filters"
            icon={<DopeIcon name="FILTER" size={32} />}
            classes={{
              root: 'has-arrow'
            }}
            elevation={1}
            rounded
          >
            {(() => {
              if (!eventTypeDefinition)
                return <div className="grid-card-empty-content">Configure Trigger before configuring Filters</div>;

              if (!eventTypeDefinition.filters || eventTypeDefinition.filters.length === 0)
                return <div>{'This Trigger does not support any filters'}</div>;

              return (
                <div>
                  <Collapse
                    bordered={false}
                    activeKey={expandedFilterKey || undefined}
                    accordion
                    onChange={(selected) => {
                      if (Array.isArray(selected)) return;

                      setExpandedFilterKey(selected);
                    }}
                  >
                    {eventTypeDefinition.filters.map((filter) => {
                      const { collapseTitleReadView: CollapseTitleReadView } = filter;
                      const name = filter.key;
                      const FilterInput = filter.configurationInput;
                      const value = getIn(formRenderProps.values, filter.key);

                      const header = (
                        <CollapseHeader
                          headerTitle={filter.getCollapseTitle(value)}
                          headerContent={CollapseTitleReadView ? <CollapseTitleReadView value={value} /> : null}
                        />
                      );

                      return (
                        <Panel key={filter.key} header={header}>
                          <FilterInput name={name} guardrail={guardrail} event={exampleTriggerEvent} />
                        </Panel>
                      );
                    })}
                  </Collapse>

                  {sectionIsActive && (
                    <GridCardFooter>
                      <Button
                        block
                        htmlType="submit"
                        disabled={!authz.isAuthorized || !canSubmit || isSubmitting}
                        type="primary"
                        loading={isSubmitting}
                      >
                        {'Done Editing Filters'}
                      </Button>
                    </GridCardFooter>
                  )}
                </div>
              );
            })()}
          </GridCard>
        );
      }}
    </Form>
  );
}

export default GuardrailFiltersSection;

import React from 'react';
import { Select } from 'antd';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import ProgressCircular from 'components/ui/ProgressCircular';
import { ScopeIdSelectorProps } from '../../types';
import { FormField } from 'components/ui/Form';

const ACCOUNTS_QUERY = gql`
  query CloudAccountsForSelect {
    accounts {
      items {
        account_id
        name
        nickname
      }
    }
  }
`;

function AccountSelector(props: ScopeIdSelectorProps) {
  const {
    formRenderProps: { setFieldValue }
  } = props;

  return (
    <Query query={ACCOUNTS_QUERY} fetchPolicy="cache-first">
      {results => {
        const { data, loading } = results;

        if (loading) return <ProgressCircular />;

        return (
          <FormField name="scope.accountId" label="Account">
            {({ name, value, handleBlur }) => (
              <Select
                value={value || 'default'}
                style={{ width: '100%' }}
                onBlur={handleBlur}
                onChange={selected => {
                  const selectedStr = selected ? selected.toString() : undefined;

                  setFieldValue(name, selectedStr !== 'default' ? selectedStr : undefined);
                }}
              >
                <Select.Option value="default">Select Account</Select.Option>
                {data.accounts.items.map((item, index) => {
                  return (
                    <Select.Option key={`${item.account_id}-${index}`} value={item.account_id}>
                      {item.nickname || item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </FormField>
        );
      }}
    </Query>
  );
}

export default AccountSelector;

import { Icon, Popover, Spin } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';
import Text from 'antd/lib/typography/Text';
import useAxios from 'axios-hooks';
import React, { useEffect } from 'react';

interface Props {
  ipAddress?: string;
  popoverPlacement?: TooltipPlacement;
}

export default function IpAddress(props: Props) {
  const { ipAddress, popoverPlacement = 'right' } = props;

  // hook to request ip geolocation data from ipapi
  const [
    { data: ipData, loading: ipLoading, error: ipError },
    lookupIp
  ] = useAxios(`https://ipapi.co/${ipAddress}/json/`, { manual: true });

  // hook to execute ip lookup if an ip address is provided
  useEffect(() => {
    if (ipAddress) lookupIp();
  }, [ipAddress, lookupIp]);

  // list of ip data fields to display in text
  const ipAddressData = [ipData?.city, ipData?.region, ipData?.country_name].filter((d) => !!d);

  return (
    <>
      {/* raw ip address string */}
      {ipAddress}

      {/* loading indicator */}
      {ipLoading && <Spin size="small" />}

      {/* error indicator */}
      {(ipError || ipData?.error) && (
        <Popover content={ipError?.message || ipData?.reason}>
          <Icon type="exclamation-circle" style={{ marginLeft: '4px' }} />
        </Popover>
      )}

      {/* ip data display  */}
      {ipData && !ipData?.error && ipAddressData && (
        <Popover
          placement={popoverPlacement}
          content={
            <>
              <span className="title">Org: </span>
              {ipData?.org}
              <br />
              City: {ipData?.city}
              <br />
              Region: {ipData?.region}
              <br />
              Country: {ipData?.country_name}
              <br />
              Continent: {ipData?.continent_code}
              <br />
              Time Zone: {ipData?.timezone}
              <br />
              UTC Offset: {ipData?.utc_offset}
              <br />
            </>
          }
        >
          <Icon type="info-circle" style={{ marginLeft: '4px' }} />
          <Text type="secondary" style={{ marginLeft: '4px' }}>
            {ipAddressData.join(', ')}
          </Text>
        </Popover>
      )}
    </>
  );
}

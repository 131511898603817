import React from 'react';
import styled from 'styled-components';

const Root = styled.h2`
  margin-bottom: 32px;
`;

interface Props {
  title: string;
  classes?: {
    root?: string;
  };
}

function PageTitle(props: Props) {
  const { title, classes = {} } = props;

  return <Root className={classes.root || ''}>{title}</Root>;
}

export default PageTitle;

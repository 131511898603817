import { useProjectTree } from 'components/project/ProjectTree';
import DelayedLoader from 'designSystem/DelayedLoader/DelayedLoader';
import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import ProjectChip from 'designSystem/ProjectChip/ProjectChip';
import React, { ReactElement, useEffect, useState } from 'react';
import { Detector } from '../../DetectorEditor/DetectorEditor';
import LastRun from '../../DetectorsCommon/LastRun/LastRun';
import DetectorLabels from '../../DetectorsTable/DetectorLabels/DetectorLabels';
import DetectorMoreInfo from './MoreInfo/MoreInfo';
import DopeIcon from 'components/ui/DopeIcon';
import useDetectorVariants from '../../DetectorsCommon/useDetectorVariants/useDetectorVariants';
export interface DetectorGeneralProps {
  detector: Detector;
}

function General(props: DetectorGeneralProps) {
  const { detector } = props,
    variants = useDetectorVariants(),
    { getProject } = useProjectTree({ onProjectsLoaded }),
    [owner, setOwner] = useState<ReactElement>(<DelayedLoader />);

  function onProjectsLoaded() {
    const project = getProject(detector.projectId);
    if (project) {
      setOwner(<ProjectChip project={project} />);
    }
  }

  useEffect(() => {
    onProjectsLoaded();
  }, [detector]);

  return (
    <>
      <HorizontalLabel label="Last Run">
        <LastRun detector={detector} />
      </HorizontalLabel>
      <HorizontalLabel label="Owner">{owner}</HorizontalLabel>
      <HorizontalLabel label="Labels">
        <DetectorLabels detector={detector} />
      </HorizontalLabel>
      <HorizontalLabel label="Description">
        {detector.assessor?.description}
        <DetectorMoreInfo detector={detector} />
      </HorizontalLabel>
      <HorizontalLabel label="Enabled">{detector.isEnabled ? 'Yes' : 'No'}</HorizontalLabel>
      {detector.isReadOnly && (
        <div>
          <DopeIcon
            name="COMPLIANCE"
            size="12px"
            style={{ marginRight: '4px' }}
            title={`This ${variants.variantName} was created automatically by DisruptOps and can be disabled, but cannot be deleted.`}
          />
          This {variants.variantName} was created automatically by DisruptOps and can be disabled, but cannot be
          deleted.
        </div>
      )}
    </>
  );
}

export default General;

import React, { useState } from 'react';
import { Select as AntSelect, Button, Checkbox } from 'antd';
import styled from 'styled-components';
import DopeIcon from '../ui/DopeIcon';
import { useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import REGION_LIST_QUERY from '../../queries/awsRegionList';
interface RegionDisplayProps {
  regionsList?: Array<string>;
  addProjectDefault?: boolean;
  onSelect: (regionList?: Array<string>) => void;
  onClear: () => void;
  canModify?: boolean;
  canDelete?: boolean;
  onDelete?: () => void;
}

const RegionsSelectedRoot = styled.div`
  margin-bottom: 24px;
  padding: 6px;
  display: flex;
  &:hover {
    background-color: #f9f9f9;
  }

  .region-list-item-meta {
    flex: 1 1 auto;
  }

  .region-list-item-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .regions-edit-mode-btns {
    margin-top: 12px;
    align-self: flex-end;
  }

  .regions-action {
    margin-left: 16px;
    align-self: flex-end;
  }
`;

const RegionsEditButtons = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;

  .cancel-btn {
    margin-left: 4px;
  }
`;

export default function RegionSelection(props: RegionDisplayProps) {
  const { regionsList, canModify = true, onDelete, canDelete, onSelect } = props;
  const [editMode, setEditMode] = useState<boolean>(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState<boolean>(false);
  const { loading: regionsLoading, error, data: allRegions } = useQuery(REGION_LIST_QUERY);
  const allRegionsList = allRegions?.regions.map((region) => region.name);
  const defaultEditValue = regionsList ? regionsList : allRegionsList;
  let editRegionList = defaultEditValue;
  const handleChange = () => {
    setEditMode(false);
    onSelect(editRegionList);
  };
  if (!editMode) {
    return (
      <QueryResult
        loading={regionsLoading}
        error={error}
        data={allRegions}
        parseNodes={(data) => data?.regions?.items}
        loadingCenterVertically={false}
        loadingHeight={70}
      >
        {(regions) => {
          return (
            <RegionsSelectedRoot>
              <>
                <div className="region-list-item-icon">
                  <DopeIcon name="AWS" size={20} />
                </div>
                <div className="region-list-item-meta">
                  <SelectedRegionList label="Regions Enabled:" value={regionsList} allRegions={allRegionsList} />
                </div>

                <div className="regions-action">
                  <Button
                    disabled={!canModify}
                    onClick={() => {
                      setEditMode(true);
                    }}
                  >
                    {'Edit Regions'}
                  </Button>
                  {canDelete && onDelete && (
                    <>
                      &nbsp;
                      <Button onClick={() => onDelete()} className="btn-with-icon" size="large">
                        {<DopeIcon name="DELETE" size={20} />}
                      </Button>
                    </>
                  )}
                </div>
              </>
            </RegionsSelectedRoot>
          );
        }}
      </QueryResult>
    );
  }

  if (editMode)
    return (
      <>
        {regionsList && allRegionsList && regionsList?.length === allRegionsList?.length ? (
          <span>
            <Checkbox checked={true} disabled={true}>
              All Regions Enabled.
            </Checkbox>
          </span>
        ) : (
          <span>
            <Checkbox
              onChange={() => {
                setEditMode(false);
                onSelect(allRegionsList);
              }}
            >
              Enable All Regions.
            </Checkbox>
          </span>
        )}
        <AntSelect
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Please select"
          defaultValue={defaultEditValue}
          onChange={(regionList) => {
            editRegionList = regionList;
            if (editRegionList.length === 0) {
              setSaveButtonDisabled(true);
            } else {
              setSaveButtonDisabled(false);
            }
          }}
        >
          {allRegionsList?.map((region) => {
            return <AntSelect.Option key={region}>{region}</AntSelect.Option>;
          })}
        </AntSelect>
        <RegionsEditButtons>
          <Button className="save-btn" onClick={handleChange} type="primary" disabled={saveButtonDisabled}>
            {'Save Regions'}
          </Button>
          <Button
            className="cancel-btn"
            onClick={() => {
              setEditMode(false);
            }}
            type="primary"
          >
            {'Cancel'}
          </Button>
        </RegionsEditButtons>
      </>
    );
  return <>Loading</>;
}

const RegionListRoot = styled.div`
  line-height: 1.25em;
  margin-bottom: 2px;

  .region-list-label {
    font-weight: 600;
    display: inline-block;
    margin-top: 8px;
    margin-bottom: 9px;
    color: ${(p) => p.theme.grey800};
  }

  .regions-list {
    margin-top: 9px;
    display: flex;
  }

  .all-regions-indicator {
    font: 10px;
  }
`;

interface RegionListProps {
  label?: string;
  value: any;
  allRegions: Array<string>;
}

function SelectedRegionList(props: RegionListProps) {
  const { label, value, allRegions } = props;
  const regionsSelected = value ? value : allRegions;
  const regionsList = regionsSelected?.map((region) => <li className="region-list-item">{region}</li>);
  const regionsListMidpoint = regionsList?.length / 2 + 1;
  const regionsList1 = regionsList?.slice(0, regionsListMidpoint);
  const regionsList2 = regionsList?.slice(regionsListMidpoint);

  return (
    <RegionListRoot className="region-meta-item">
      {label && <span className="region-list-label">{label}</span>}
      <div className="regions-list">
        <ul>{regionsList1}</ul>
        <ul>{regionsList2}</ul>
      </div>
    </RegionListRoot>
  );
}

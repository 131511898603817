import React from 'react';
import { CloudAccount } from 'typings';
import { useMutation } from 'react-apollo';
import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';
import gql from 'graphql-tag';
import RoundButton from './RoundButton';
import { message } from 'antd';

const REFRESH_ACCOUNT_INVENTORY_MUTATION = gql`
  mutation RefreshAccountInventory($accountId: String!) {
    refreshAccountInventory(accountId: $accountId)
      @rest(path: "/api/v2/accounts/{args.accountId}/inventory/refresh", method: "PUT", bodyKey: "accountId")
  }
`;

interface Props {
  cloudAccount: CloudAccount;
}

function RefreshCloudAccountInventoryButton(props: Props) {
  const { cloudAccount } = props;

  const flags = useFeatureFlag([Feature.INVENTORY_REFRESH]);

  const [refreshAccountInventory, { loading }] = useMutation(REFRESH_ACCOUNT_INVENTORY_MUTATION);

  if (cloudAccount.provision_url) return null;
  if (!flags[Feature.INVENTORY_REFRESH]) return null;

  return (
    <RoundButton
      text="Refresh this account's inventory."
      tooltipPlacement="left"
      icon="INVENTORY_UPDATING"
      loading={loading}
      onClick={async () => {
        try {
          await refreshAccountInventory({
            variables: { accountId: cloudAccount.account_id }
          });
        } catch (err) {
          message.error("Error refreshing cloud account's inventory.");
        }
      }}
    />
  );
}

export default RefreshCloudAccountInventoryButton;

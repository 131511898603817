import styled from 'styled-components';

export const MainNavItemDiv = styled.div`
  padding: 0.25rem 1.25rem;
  color: ${(p) => p.theme.grey300};
  background-color: transparent;
  transition-property: background-color, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;

  .collapse-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .collapse-content {
    white-space: nowrap;
    width: 100%;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
  }

  .icon {
    margin-right: 0.5rem;
    font-size: 22px;
  }
`;

import * as Yup from 'yup';

import { GuardrailTriggerEventSourceDefinition, GuardrailTriggerEventTypeDefinition } from '.';
import { NewGuardrail } from 'typings';

import issueFromSingleAssessment from '../../filter/definitions/issueFromSingleAssessment';
import issueProjectPathMatchesScope from '../../filter/definitions/issueProjectPathMatchesScope';

import SelectIssueDefinitionReadView from '../components/SelectIssueDefinitionReadView';
import IssueCreatedTriggerInput from '../components/IssueCreatedTriggerInput';
import IssueCreatedSectionTitle from '../components/IssueCreatedSectionTitle';

// this needs to be moved to a more logical location.

const issueCreatedEventType: GuardrailTriggerEventTypeDefinition = {
  key: 'ISSUE_CREATED',
  sectionTitle: IssueCreatedSectionTitle,
  name: 'Issue Found',
  schema: Yup.object().shape({
    issueDefinitionId: Yup.string('Please select an Issue Type').required('Please select an Issue Type')
  }),
  getInitialConfigurationValuesFromGuardrail: (guardrail: NewGuardrail) => {
    const { issueDefinitionId } = guardrail;

    return {
      issueDefinitionId: issueDefinitionId || undefined
    };
  },
  transformGuardrailBeforeSaving: (guardrail: NewGuardrail) => {
    const issueDefinitionId =
      (guardrail.trigger && guardrail.trigger.configuration && guardrail.trigger.configuration.issueDefinitionId) ||
      null;

    return {
      ...guardrail,
      issueDefinitionId,
      trigger: guardrail.trigger
        ? {
            ...guardrail.trigger,
            configuration: {}
          }
        : null
    };
  },
  configurationInput: IssueCreatedTriggerInput,
  getConfigureCollapseTitle: () => 'Issue Type:',
  configureCollapseReadView: SelectIssueDefinitionReadView,
  filters: [issueProjectPathMatchesScope, issueFromSingleAssessment]
};

const disruptopsIssuesEventSource: GuardrailTriggerEventSourceDefinition = {
  key: 'DISRUPTOPS_ISSUES',
  name: 'DisruptOps Issues',

  // icon,
  eventTypes: [issueCreatedEventType]
};

export default disruptopsIssuesEventSource;

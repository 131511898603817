import { Button, message, Tooltip } from 'antd';
import AuthContext from 'components/app/Auth/AuthContext';
import HorizontalNavbar from 'components/app/HorizontalNavbar';
import AssessmentValidator from 'components/assessment/AssessmentValidator';
import DynamicConfigProvider from 'components/dynamicConfig/DynamicConfigProvider';
import ClickToEditInput from 'components/ui/ClickToEditInput';
import DopeIcon from 'components/ui/DopeIcon';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import PageHeader from 'components/ui/PageHeader';
import PushFetch from 'components/util/Push/PushFetch';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React, { useContext } from 'react';
import { Mutation, useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { ActiveTask } from 'typings';
import AssessmentMetaTable from './components/AssessmentMetaTable';
import ASSESSMENT_DETAIL_QUERY from './gql/assessmentDetailQuery';
import AssessmentIssuesTab from './tabs/AssessmentIssues';
import AssessmentOverviewTab from './tabs/AssessmentOverview';

// make assessor required.
const Root = styled.div`
  .neo-page {
    background-color: #fff;
  }

  .assessment-page-header {
    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;

    .centered-container {
      padding-bottom: 0;
    }
  }

  .centered-container {
    max-width: 1400px;
  }

  .layout-row {
    flex-direction: row-reverse;
  }

  .page-header-actions {
    align-items: center;
  }

  .assessment-detail-layout {
    display: grid;

    grid-template-columns: auto 32px 290px;
    grid-template-areas: 'router . meta';
  }

  .router-wrapper {
    grid-area: router;
  }
  .meta-wrapper {
    grid-area: meta;
  }
`;

const RUN_ASSESSMENT = gql`
  mutation runAssessment($id: ID!) {
    runAssessment(id: $id) {
      id
      clientId
      functionId
      taskId
      subjectId
      status
      createdAt
      updatedAt
    }
  }
`;

const SAVE_ASSESSMENT_NAME = gql`
  mutation UpdateAssessmentName($id: ID!, $assessmentInput: AssessmentUpdateInput!) {
    updateAssessment(id: $id, assessmentInput: $assessmentInput) {
      id
      clientId
      name
    }
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

function AssessmentDetail(props: Props) {
  const assessmentId = props.match.params.id;

  const authContext = useContext(AuthContext);

  const { data, error, loading, refetch } = useQuery(ASSESSMENT_DETAIL_QUERY, { variables: { id: [assessmentId] } });

  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            icon={<DopeIcon name="ASSESSMENT" size={20} />}
            sectionTitle="Detectors"
            sectionTitleLinkTo="/detectors"
          />
        }
      >
        <QueryResult
          data={data}
          loading={loading}
          error={error}
          expectSingleNode={true}
          parseNodes={(data) => data.assessments.nodes}
          entityName="Detector"
        >
          {(assessment) => {
            return (
              <PushFetch
                refetchTest={(envelope) => {
                  const { message } = envelope;
                  switch (envelope.eventType) {
                    case 'assessmentTaskCompleted': {
                      return message && message.assessmentId === assessmentId;
                    }

                    case 'issueCreated': {
                      return message.issue && message.issue.assessmentId === assessmentId;
                    }

                    case 'actionTaskCompleted': {
                      // not a great way right now to know if the issue was related to this assessment
                      return true;
                    }

                    default:
                      return false;
                  }
                }}
                refetchFn={refetch}
              >
                <AssessmentValidator assessment={assessment}>
                  {(validationBag) => {
                    const { isValid, isValidating } = validationBag;

                    return (
                      <div>
                        <div className="assessment-page-header">
                          <CenteredContainer>
                            <PageHeader
                              titleLevel={2}
                              title={
                                <Mutation mutation={SAVE_ASSESSMENT_NAME}>
                                  {(saveAssessmentName, { loading }) => {
                                    return (
                                      <ClickToEditInput
                                        initialValue={assessment.name}
                                        loading={loading}
                                        onSave={(assessmentName) =>
                                          saveAssessmentName({
                                            variables: {
                                              id: assessmentId,
                                              assessmentInput: {
                                                name: assessmentName
                                              }
                                            }
                                          })
                                        }
                                      />
                                    );
                                  }}
                                </Mutation>
                              }
                              actions={
                                !assessment.assessor?.eventTriggers && (
                                  <Mutation mutation={RUN_ASSESSMENT}>
                                    {(runAssessment, { loading }) => {
                                      const button = (
                                        <Button
                                          disabled={assessment.isActive || loading || isValidating || !isValid}
                                          loading={assessment.isActive || loading}
                                          onClick={async () => {
                                            const variables = {
                                              id: assessment.id
                                            };

                                            const response: any = await runAssessment({ variables });
                                            const dfunction: ActiveTask = response.data.runAssessment;
                                            if (authContext?.isDisruptOpsUser) {
                                              message.success(
                                                <>
                                                  Detector started successfully.{' '}
                                                  <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={`/dev/tasks/${dfunction.taskId}`}
                                                  >
                                                    Task: {dfunction.taskId}, Status: {dfunction.status}
                                                  </a>
                                                </>
                                              );
                                            } else {
                                              message.success(
                                                `Detector started successfully. (Task: ${dfunction.taskId}, Status: ${dfunction.status})`
                                              );
                                            }
                                          }}
                                        >
                                          <DopeIcon name="OP_RUN_NOW" />
                                          Run Detector
                                        </Button>
                                      );

                                      return isValid ? (
                                        button
                                      ) : (
                                        <Tooltip title="Filters must be properly configured before running detector">
                                          {button}
                                        </Tooltip>
                                      );
                                    }}
                                  </Mutation>
                                )
                              }
                            />
                            <HorizontalNavbar
                              type="tabs"
                              navConfig={[
                                {
                                  label: 'Settings',
                                  to: `/detectors/${assessment.id}/settings`
                                },
                                { label: 'Issues', to: `/detectors/${assessment.id}/issues` }
                              ]}
                            />
                          </CenteredContainer>
                        </div>
                        <CenteredContainer>
                          <div className="assessment-detail-layout">
                            <div className="router-wrapper">
                              <DynamicConfigProvider>
                                {() => (
                                  <Switch>
                                    <Route
                                      path={'/detectors/:assessmentId/issues'}
                                      render={(routeProps) => (
                                        <AssessmentIssuesTab {...routeProps} assessment={assessment} />
                                      )}
                                    />
                                    <Route
                                      path={'/detectors/:assessmentId/settings/:action?'}
                                      render={(routeProps) => (
                                        <AssessmentOverviewTab {...routeProps} assessment={assessment} />
                                      )}
                                    />
                                    <Route render={() => <Redirect to={`/detectors/${assessment.id}/settings`} />} />
                                  </Switch>
                                )}
                              </DynamicConfigProvider>
                            </div>
                            <div className="meta-wrapper">
                              <Route
                                path="/detectors/:assessmentId/:assessmentTab/:action?"
                                render={(rProps: RouteComponentProps) => (
                                  <AssessmentMetaTable
                                    {...rProps}
                                    assessment={assessment}
                                    validationBag={validationBag}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </CenteredContainer>
                      </div>
                    );
                  }}
                </AssessmentValidator>
              </PushFetch>
            );
          }}
        </QueryResult>
      </NeoPage>
    </Root>
  );
}

export default AssessmentDetail;

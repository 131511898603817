import React, { useEffect, useState } from 'react';
import { AutomationEventDefinition } from 'typings';
import EnvironmentEditor from '../EnvironmentEditor/EnvironmentEditor';
import RegionEditor from '../RegionEditor/RegionEditor';
import ProjectEditor from '../ProjectEditor/ProjectEditor';
import AccountEditor from '../AccoutEditor/AccountEditor';
import AccountLabelEditor from '../AccountLabelEditor/AccountLabelEditor';
import SeverityEditor from '../SeverityEditor/SeverityEditor';
import ResourceTypeEditor from '../ResourceTypeEditor/ResourceTypeEditor';
import ResourceTagsEditor from '../ResourceTagsEditor/ResourceTagsEditor';
import AdvancedEditor from '../AdvancedEditor/AdvancedEditor';
import { Button } from 'antd';
import { Root } from './FilterListingStyles';
import { FilterValuesAndSetter } from '../TriggerContents/TriggerContents';

interface FilterListingProps extends FilterValuesAndSetter {
  eventDefinition: AutomationEventDefinition | null;
  inEditMode: boolean;
}

export interface FilterType {
  valueKey: string;
  label: string;
}

export interface FilterListing extends FilterType {
  component: (props) => JSX.Element;
  sourceWhitelist?: string[];
}

export interface FilterTypeProps extends FilterValuesAndSetter {
  filterType: FilterType;
  eventDefinition?: AutomationEventDefinition | null;
}

const sourceIds = {
  disruptOpsGovernance: '7cc01081-2260-4176-86ae-092f98cb6aa0',
  cloudWatch: 'eaa12aa4-46e9-4f3c-98a2-2a43671a316b',
  securityHub: '5a228e04-b7b5-4cf1-82c7-ab657ddea0ae',
  awsfilterType: '137e8fe9-c3bb-4e11-8023-708893eeda57'
};

export const FilterEditors: FilterListing[] = [
  {
    valueKey: 'environments',
    label: 'Environments',
    component: EnvironmentEditor
  },
  {
    valueKey: 'regions',
    label: 'Regions',
    component: RegionEditor
  },
  {
    valueKey: 'triggerProjectIds',
    label: 'Projects',
    component: ProjectEditor
  },
  {
    valueKey: 'accountIds',
    label: 'Accounts',
    component: AccountEditor
  },
  {
    valueKey: 'accountLabels',
    label: 'Account Label',
    component: AccountLabelEditor
  },
  {
    valueKey: 'severity',
    label: 'Severity',
    component: SeverityEditor,
    sourceWhitelist: [sourceIds.securityHub, sourceIds.disruptOpsGovernance]
  },
  {
    valueKey: 'resourceType',
    label: 'Resource Type',
    component: ResourceTypeEditor,
    sourceWhitelist: [
      sourceIds.securityHub,
      sourceIds.disruptOpsGovernance,
      sourceIds.awsfilterType,
      sourceIds.cloudWatch
    ]
  },
  {
    valueKey: 'tags',
    label: 'Resource Tags',
    component: ResourceTagsEditor,
    sourceWhitelist: [sourceIds.securityHub]
  },
  {
    valueKey: 'jsonPaths',
    label: 'Advanced',
    component: AdvancedEditor
  }
];

function FilterListing(props: FilterListingProps) {
  const { values, inEditMode } = props;
  const [newFilterTypes, setNewFilterTypes] = useState<FilterListing[]>([]);

  const editorProps = {
    ...props,
    onDelete: onDelete,
    inEditMode: inEditMode
  };

  function shouldShowEditor(filterType: FilterListing): boolean {
    if (inEditMode && newFilterTypes.includes(filterType)) return true; // only show new filter types in edit mode

    return values[filterType.valueKey] && values[filterType.valueKey].length !== 0;
  }

  function availableFilterTypes(filterType: FilterListing): boolean {
    if (shouldShowEditor(filterType)) return false; // hide if already being shown

    if (!values.eventSourceId) return true; //show all filter types when source is set to All Sources

    if (!filterType.sourceWhitelist) {
      return true; // show if this filter type doesn't whitelist
    }

    if (filterType.sourceWhitelist && filterType.sourceWhitelist.includes(values.eventSourceId)) {
      return true; //show when whitelisted
    }

    return false;
  }

  function addNewFilterType(filterType) {
    setNewFilterTypes([...newFilterTypes, filterType]);
  }

  function onDelete(filterType) {
    if (newFilterTypes.includes(filterType)) {
      const idx = newFilterTypes.indexOf(filterType),
        workingArray = [...newFilterTypes];

      workingArray.splice(idx, 1);
      setNewFilterTypes(workingArray);
    }
  }

  function listNewFiltersLast(a: FilterListing, b: FilterListing) {
    if (newFilterTypes.includes(a) && newFilterTypes.includes(b)) {
      return newFilterTypes.indexOf(a) - newFilterTypes.indexOf(b);
    }

    if (newFilterTypes.includes(a)) {
      return 1;
    }

    if (newFilterTypes.includes(b)) {
      return -1;
    }

    return 0;
  }

  function onSourceIdChange() {
    // dump any newFilterTypes that don't match the new source
    const newList = newFilterTypes.filter((filterType) => {
      if (!filterType.sourceWhitelist) return true;

      return filterType.sourceWhitelist.includes(values.eventSourceId);
    });

    setNewFilterTypes(newList);
  }

  useEffect(onSourceIdChange, [values.eventSourceId]);

  return (
    <Root>
      <div className="filter-header">
        <div className="section-label">Filters</div>
      </div>
      <div>
        {FilterEditors.filter(shouldShowEditor)
          .sort(listNewFiltersLast)
          .map((filterEditor, i) => (
            <div key={i} data-testid={filterEditor.valueKey}>
              <filterEditor.component {...editorProps} filterType={filterEditor} />
            </div>
          ))}
      </div>
      {inEditMode && FilterEditors.filter(availableFilterTypes).length > 0 && (
        <div className="add-filter-type">
          <div className="choose-type">
            <span className="choose-type__label">Add More Filters</span>
          </div>
          <div className="available-filters">
            {FilterEditors.filter(availableFilterTypes).map((filterType, i) => (
              <Button key={i} onClick={() => addNewFilterType(filterType)}>
                {filterType.label}
              </Button>
            ))}
          </div>
        </div>
      )}
    </Root>
  );
}

export default FilterListing;

import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { Location } from 'react-router-dom';
import { Modal, Alert, Typography } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import IssueDetail from './IssueDetail';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Issue, CloudAccount } from 'typings';
import QueryResult from 'components/util/QueryResult';

import IssueActionStepper from 'components/issue/IssueActionStepper';
import PushFetch from 'components/util/Push/PushFetch';
import { ResourceType } from 'utilities/inventory';

const ISSUE_DETAIL = gql`
  query IssueDetail($id: String) {
    issues(id: $id) {
      pageInfo {
        total
        size
      }
      nodes {
        id
        isExempted
        isResolved
        isActing @client
        exemptedReason
        exemptedAt
        severity
        createdAt
        updatedAt
        accountId
        awsAccountId
        region
        name
        description
        overview
        detail
        itemKey
        itemType
        parentItemKey
        parentItemType
        assessment {
          id
          name
          assessor {
            id
            name
          }
        }
        issueDefinition {
          id
          name
          description
          actors {
            id
            type
            name
            key
            description
            overview
            effects
            parameters {
              name
              key
              type
              inputCode
              configCode
              defaultValue
              description
              placeholder
              many
              options
              required
            }
          }
        }
        actionResults {
          id
          clientId
          issueId
          issueWasResolved
          status
          detail
          createdAt
          updatedAt

          actor {
            id
            name
            description
          }
        }
      }
    }

    resource_types {
      name
      key
    }
  }
`;

const ACCOUNT = gql`
  query AccountForIssue($id: ID!) {
    account(id: $id) {
      account_id
      name
      nickname
      provider
    }
  }
`;

const NoAlertIssueWraper = styled.div`
  padding: 64px 24px 24px;
`;

const baseModalProps: ModalProps = {
  bodyStyle: {
    padding: '0'
  },
  footer: false,
  visible: true,
  width: 1200,
  style: {
    maxWidth: '90%'
  }
};

enum ActiveViewOption {
  DETAIL = 'DETAIL',
  TAKE_ACTION = 'TAKE_ACTION',
  MARK_EXEMPT = 'MARK_EXEMPT'
}

interface ViewConfigItem {
  renderFunction: (issue: Issue, resourceTypes: ResourceType[], account: CloudAccount) => React.ReactNode;
  label: string;
  modalWidth?: number;
}

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  previousLocation: Location;
}

interface State {
  activeView: ActiveViewOption;
}

class IssueDetailModal extends React.Component<Props, State> {
  viewConfig: {
    [ActiveViewOption.DETAIL]: ViewConfigItem;
    [ActiveViewOption.TAKE_ACTION]: ViewConfigItem;
  } = {
    [ActiveViewOption.DETAIL]: {
      label: 'Detail',
      renderFunction: this.renderDetail.bind(this)
    },
    [ActiveViewOption.TAKE_ACTION]: {
      label: 'Select action',
      renderFunction: this.renderTakeAction.bind(this)
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeView: ActiveViewOption.DETAIL
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  renderDetail(issue: Issue, resourceTypes: ResourceType[], account: CloudAccount) {
    return (
      <IssueDetail
        issue={issue}
        account={account}
        resourceTypes={resourceTypes}
        onClickTakeAction={() => {
          // change view to issueActionStepper
          this.setState({ activeView: ActiveViewOption.TAKE_ACTION });
        }}
      />
    );
  }

  renderTakeAction(issue: Issue, resourceTypes: ResourceType[], account: CloudAccount) {
    if (!issue.issueDefinition) return null;

    return (
      <IssueActionStepper
        issues={[issue]}
        account={account}
        issueDefinition={issue.issueDefinition}
        onBack={() => {
          this.setState({ activeView: ActiveViewOption.DETAIL });
        }}
        onBackLabel="Back to issue details"
        onComplete={() => {
          this.setState({ activeView: ActiveViewOption.DETAIL });
        }}
      />
    );
  }

  handleCloseModal() {
    const { location, history, previousLocation } = this.props;

    location.state && location.state.onModalClose
      ? history.push(location.state.onModalClose)
      : history.push(previousLocation);
  }

  render() {
    const { activeView } = this.state;

    const {
      match: {
        params: { id }
      }
    } = this.props;

    const activeConfig: ViewConfigItem = this.viewConfig[activeView];
    const { renderFunction } = activeConfig;

    return (
      <Modal {...baseModalProps} onCancel={this.handleCloseModal}>
        <Query query={ISSUE_DETAIL} variables={{ id }}>
          {({ data, loading, error, refetch }) => (
            <QueryResult data={data} loading={loading} error={error}>
              {() => {
                const resourceTypes: ResourceType[] = data.resource_types;
                const issue: Issue = data.issues.nodes[0];

                if (!issue)
                  return (
                    <NoAlertIssueWraper>
                      <Alert
                        type="warning"
                        message="No matching Issue found"
                        description={<Typography>{`There was no Issue with the ID: "${id}" found.`}</Typography>}
                      />
                    </NoAlertIssueWraper>
                  );

                return (
                  <Query query={ACCOUNT} variables={{ id: issue.accountId }}>
                    {({ data, loading, error }) => (
                      <QueryResult data={data} loading={loading} error={error}>
                        {() => {
                          const account: CloudAccount = data.account;
                          return (
                            <>
                              <PushFetch
                                eventType="actionTaken"
                                refetchTest={(envelope: any) => {
                                  if (!envelope || !envelope.message || !envelope.message.actionResult) return false;
                                  if (envelope.message.actionResult.issueId === issue.id) return true;
                                  return false;
                                }}
                                refetchFn={refetch}
                              />
                              {renderFunction(issue, resourceTypes, account)}
                            </>
                          );
                        }}
                      </QueryResult>
                    )}
                  </Query>
                );
              }}
            </QueryResult>
          )}
        </Query>
      </Modal>
    );
  }
}

export default IssueDetailModal;

import React from 'react';
import styled from 'styled-components';
const Root = styled.div`
  padding: 24px;
  background-color: #fafafa;
`;
interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  classes?: {
    root?: string;
  };
}

function ModalBody(props: Props) {
  const { children, classes = {} } = props;

  return <Root className={classes.root || ''}>{children}</Root>;
}

export default ModalBody;

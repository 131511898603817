import React from 'react';
import styled from 'styled-components';
import { ExistingNotificationRecipient, NotificationRecipient } from 'typings';
import DopeIcon from 'components/ui/DopeIcon';

interface MSTeamsRecipientListItemProps {
  recipient: ExistingNotificationRecipient | NotificationRecipient;
  action?: React.ReactNode;
}

const Root = styled.div`
  margin-bottom: 24px;
  padding: 6px;
  display: flex;
  &:hover {
    background-color: #f9f9f9;
  }

  .recipient-list-item-meta {
    flex: 1 1 auto;
  }

  .recipient-list-item-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .recipient-list-item-action {
    margin-left: 16px;
  }

  .recipient-list-item-meta-config {
    line-height: 1.25em;
    margin-bottom: 2px;

    .recipient-list-item-meta-config-label {
      font-weight: 600;
      display: inline-block;
      margin-right: 4px;
      color: ${p => p.theme.grey800};
    }
  }
`;

export function MSTeamsRecipientListItem(props: MSTeamsRecipientListItemProps) {
  const { recipient, action } = props;
  const config: any = recipient.config; // funky...

  return (
    <Root>
      <div className="recipient-list-item-icon">
        <DopeIcon name="MICROSOFT_TEAMS" size={20} />
      </div>
      <div className="recipient-list-item-meta">
        <div className="recipient-list-item-meta-config">
          <span className="recipient-list-item-meta-config-label">Team Name:</span>
          <span>{config?.context?.teamName}</span>
        </div>
        <div className="recipient-list-item-meta-config">
          <span className="recipient-list-item-meta-config-label">Channel Name:</span>
          <span>{config?.context?.channelName}</span>
        </div>
        <div className="recipient-list-item-meta-config">
          <span className="recipient-list-item-meta-config-label">Created By:</span>
          <span>{config?.context?.upn}</span>
        </div>
      </div>
      {action && <div className="recipient-list-item-action">{action}</div>}
    </Root>
  );
}

export default MSTeamsRecipientListItem;

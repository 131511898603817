import styled from 'styled-components';

export const AppMain = styled.div`
  display: flex;
  height: 100vh;
  position: relative;

  /* TODO: after new nav is complete, the .main-panel selector can be removed */
  .page-main,
  .main-panel {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;

    header {
      flex: 0 0 auto;
    }

    main {
      flex: 1 1 auto;
      overflow-y: auto;
    }
  }

  /* TODO: after new nav is complete, these rules are obsolete and should be deleted */
  .neo-title-bar {
    position: relative !important;
  }

  .with-titlebar {
    padding-top: 0;
  }
`;

import React from 'react';

interface ComponentProps {
  indexedConfigs: any;
}

export default function ExpirationAction(props: ComponentProps) {
  const { indexedConfigs } = props;

  return indexedConfigs.delayedAction?.value?.name ? (
    <>
      If no other action is taken, automatically take this action after expiration: <br />
      {indexedConfigs.delayedAction?.value?.name}
    </>
  ) : null;
}

import styled from 'styled-components';

export const GhostButton = styled.button`
  color: ${(p) => p.theme.primary};
  border: none;
  background: none;
  cursor: pointer;
  transition: color 0.2s ease;
  padding: 0.25rem 0.5rem;

  &:hover {
    color: ${(p) => p.theme.grey500};
  }
`;

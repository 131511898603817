import gql from 'graphql-tag';

import USER_GROUP_FRAGMENT from '../fragments/userGroup';

export const CREATE_USER_GROUP_MUTATION = gql`
  mutation CreateUserGroup($input: UserGroupInput!) {
    createUserGroup(input: $input) @rest(path: "/user-groups", method: "POST", type: "UserGroup") {
      ...groupFields
    }
  }

  ${USER_GROUP_FRAGMENT}
`;

export default CREATE_USER_GROUP_MUTATION;

import styled from 'styled-components';

export const ValueDisplayDiv = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(p) => p.theme.grey400};
  border-radius: 4px;

  button {
    border: none;
    background: none;
    cursor: pointer;
  }

  .enable-edit {
    flex-grow: 1;
    text-align: left;
  }
`;

import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: top;
  margin-bottom: 0.75rem;

  .filter-type-label {
    width: 18%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 0.5rem;
    height: 20px;
    transition: height 0.2s ease-out;
  }

  .filter-type-label.is-editing {
    height: 30px;
  }

  .main-contents {
    width: 77%;
  }

  .display-pane ul {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    list-style-type: none;
  }

  .actions {
    width: 5%;
    height: 30px;
    display: flex;
    align-items: center;
  }

  .ghost {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border-radius: 2px;

    &:hover {
      color: ${(p) => p.theme.primary};
    }

    &:focus {
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(103, 58, 183, 0.2);
    }

    &.edit-button {
      color: ${(p) => p.theme.grey400};
    }
  }

  .label {
    font-weight: bold;
    margin-right: 0.5rem;
    text-align: right;
  }
`;

import gql from 'graphql-tag';

export const ME_QUERY = gql`
  query UserContext {
    user {
      id
      email
      username
      first_name
      last_name
      client {
        created
        name
        settings {
          complianceEnabled
        }
      }
      created
      updated
    }
  }
`;

export default ME_QUERY;

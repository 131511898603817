import styled from 'styled-components';

export const Root = styled.div`
  .video-link {
    display: flex;
    align-items: center;

    a > * {
      margin-right: 0.5rem;
    }

    .external-link {
      transform: translateY(2px);
    }
  }

  .create-enter {
    opacity: 0.01;
    transform: translateY(-4rem);
  }

  .create-enter.create-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 200ms ease-out, transform 200ms ease-out;
  }

  .create-leave {
    opacity: 1;
  }

  .create-leave.create-leave-active {
    opacity: 0.01;
    transform: translateY(-4rem);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
`;

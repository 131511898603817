import React from 'react';
import DopeIcon from 'components/ui/DopeIcon';
import { Typography } from 'antd';
import { NodeTitleDiv } from './NodeTitle.styles';
import { ProjectTreeNode } from 'components/project/ProjectTree/useProjectTree';

interface Props {
  projectTreeNode: ProjectTreeNode;
  showCloudAccounts?: boolean;
}

function NodeTitle(props: Props) {
  const { projectTreeNode, showCloudAccounts } = props;

  return (
    <NodeTitleDiv>
      <DopeIcon
        size=".9em"
        className="tree-node-icon project"
        // name={nodeType === NodeType.project ? 'PROJECT' : 'CLOUD_ACCOUNT'}
        name="PROJECT"
      />
      <Typography.Text>
        {projectTreeNode.name}
        {projectTreeNode.accounts_list.length > 0 && showCloudAccounts && (
          <span className="cloud-account-list">
            <DopeIcon size=".9em" className="tree-node-icon cloud-account" name="CLOUD_ACCOUNT" />
            {projectTreeNode.accounts_list.map((account, i) => (
              <span className="cloud-account-name" key={i}>
                {account.nickname || account.name}
              </span>
            ))}
          </span>
        )}
      </Typography.Text>
    </NodeTitleDiv>
  );
}

export default NodeTitle;

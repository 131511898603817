import React from 'react';
import styled from 'styled-components';

import { Issue } from 'typings';

const StatusLabelWrapper = styled.span`
  padding: 4px 20px;
  border-radius: 4px;
  color: #fff;

  &.success {
    background-color: ${p => p.theme.success};
  }
  &.warning {
    background-color: ${p => p.theme.warning};
  }
  &.default {
    background-color: ${p => p.theme.grey500};
  }
`;

const STATUS_LABEL_MAP = {
  ignored: 'Exempt',
  resolved: 'Resolved',
  open: 'Open'
};

const STATUS_CLASS_MAP = {
  ignored: 'default' as 'default',
  resolved: 'success' as 'success',
  open: 'warning' as 'warning'
};

interface Props {
  issue: Issue;
}

export function getStatus(issue: Issue) {
  if (issue.isExempted) return 'ignored';
  if (issue.isResolved) return 'resolved';
  return 'open';
}

export function getStatusClassName(issue: Issue): 'default' | 'warning' | 'success' {
  const status = getStatus(issue);
  return STATUS_CLASS_MAP[status];
}

function StatusLabel(props: Props) {
  const { issue } = props;

  const status = getStatus(issue);

  return <StatusLabelWrapper className={getStatusClassName(issue)}>{STATUS_LABEL_MAP[status]}</StatusLabelWrapper>;
}

export default StatusLabel;

import React from 'react';

import DopeIcon from 'components/ui/DopeIcon';
import { CenteredContainer } from 'components/ui/NeoPage';
import PageHeader from 'components/ui/PageHeader';

function AboutPage() {
  return (
    <CenteredContainer leftAlign>
      <PageHeader title="About" titleLevel={2} />
      <div>
        <h1>About DisruptOps</h1>
        <p>
          <b>App version:</b> {process.env.REACT_APP_VERSION || 'Unknown'}
        </p>
        <p>
          <b>API version:</b> Unknown
        </p>
        <p>
          <DopeIcon name="COPYRIGHT" size="16px" /> {new Date().getFullYear()}. All rights reserved.
        </p>
      </div>
    </CenteredContainer>
  );
}

export default AboutPage;

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { APP_ENV, Env } from 'constants/runtimeConfig';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Alert, Divider } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Button } from 'antd';
import Input from 'components/ui/Input';

import OnboardingPanel from '../../components/OnboardingPanel';
import AuthContext from 'components/app/Auth/AuthContext';

const FormWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 380px;
  align-items: center;
  justify-content: space-around;
`;

const ActionsWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px;

  .submit-btn {
    margin-bottom: 16px;
  }
`;

const LoginForm = styled.form`
  width: 100%;
`;

const SubmitButtonWrap = styled.div``; // flex issue.

const AlertContainer = styled.div`
  margin-top: -40px;
  margin-bottom: 20px;
`;

const FormSchema = Yup.object().shape({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required')
});

const SIGNIN_MUTATION = gql`
  mutation Login($username: String, $password: String) {
    signIn(username: $username, password: $password) @client {
      email
      user_id
      user_pool_id
    }
  }
`;

interface Props extends RouteComponentProps<any> {}

class LoginPage extends React.Component<Props> {
  static contextType = AuthContext;

  constructor(props: Props) {
    super(props);

    this.handleSignIn = this.handleSignIn.bind(this);
  }

  async handleSignIn(results: any) {
    await this.context.refetch();

    const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
    localStorage.removeItem('redirectAfterLogin');

    this.props.history.push(redirectTo);
  }

  render() {
    const { location } = this.props;

    return (
      <OnboardingPanel withLogo title="Login">
        {location.state && location.state.userWasConfirmed && (
          <Alert message="Account Confirmed! Please Login to your Account." type="info" showIcon />
        )}
        <Mutation mutation={SIGNIN_MUTATION} onCompleted={this.handleSignIn}>
          {(signIn, results) => {
            const { loading, error } = results;

            return (
              <Formik
                initialValues={{
                  username: (location.state && location.state.username) || '',
                  password: ''
                }}
                onSubmit={async (values, actions) => {
                  try {
                    await signIn({ variables: values });
                  } catch (err) {
                    actions.setSubmitting(false);
                  }
                }}
                validationSchema={FormSchema}
              >
                {({ values, handleSubmit, handleChange, errors, touched, isSubmitting, handleBlur }) => (
                  <LoginForm onSubmit={handleSubmit}>
                    <FormWrap>
                      {error && (
                        <AlertContainer>
                          <Alert message="Unable to sign in to your account" type="error" showIcon />
                        </AlertContainer>
                      )}
                      <Input
                        title="Username"
                        value={values.username}
                        placeholder="Enter username"
                        onChange={handleChange}
                        name="username"
                        error={touched.username && errors.username ? (errors.username as string) : undefined}
                        onBlur={handleBlur}
                        autoFocus={true}
                        required
                      />
                      <Input
                        title="Password"
                        value={values.password}
                        placeholder=""
                        onChange={handleChange}
                        name="password"
                        type="password"
                        error={touched.password && errors.password ? (errors.password as string) : undefined}
                        onBlur={handleBlur}
                        required
                      />
                      <ActionsWrap>
                        <SubmitButtonWrap>
                          <Button
                            htmlType="submit"
                            type="primary"
                            size="large"
                            loading={loading || isSubmitting}
                            className="submit-btn"
                          >
                            Login to your account
                          </Button>
                        </SubmitButtonWrap>
                        <Link className="forgot-password-link" to="/recover-account">
                          {'Forgot your password?'}
                        </Link>
                        {APP_ENV && [Env.Stage, Env.Dev, Env.Local].includes(APP_ENV as Env) && (
                          <>
                            <Divider />
                            <Link className="new-account-link" to="/new-account">
                              {'Create an account'}
                            </Link>
                          </>
                        )}
                      </ActionsWrap>
                    </FormWrap>
                  </LoginForm>
                )}
              </Formik>
            );
          }}
        </Mutation>
      </OnboardingPanel>
    );
  }
}

export default LoginPage;

import styled from 'styled-components';

export const Root = styled.div`
  .ant-collapse-item {
    border-top-width: 0 !important;
  }

  .ant-collapse {
    margin-left: -1rem;
  }

  .ant-collapse-content {
    display: none !important;
  }
`;

import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  line-height: 1;
  margin-bottom: -0.5rem;

  .section-title-wrap {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
  }

  .trigger-label {
    text-transform: uppercase;
    color: ${(p) => p.theme.grey400};
  }

  .trigger-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .section-title {
    margin-right: 16px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
  }

  .icon-wrap {
    margin-right: 1rem;
    transform: translateY(10px);

    > svg {
      display: block;
      color: ${(p) => p.theme.grey400};
    }
  }
`;

import gql from 'graphql-tag';
import RECIPIENT_FIELDS from './fragments/recipientFields';

const RECIPIENTS_LIST_QUERY = gql`
  query RecipientList($id: String, $type: [RecipientTypes]) {
    recipients(id: $id, type: $type, size: 100) {
      from
      total
      size
      items {
        ...RecipientFields
      }
    }
  }

  ${RECIPIENT_FIELDS}
`;

export default RECIPIENTS_LIST_QUERY;

import React from 'react';
import { CloudAccount } from 'typings';
import QueryStringContext from 'components/util/QueryString/QueryStringContext';

interface Props {
  account: CloudAccount;
}

function CloudAccountLink(props: Props) {
  const { account } = props;

  if (!account) {
    return null;
  }

  return (
    <QueryStringContext.Consumer>
      {({ cloneURLSearchParams, replaceURLSearchParams }) => (
        <button
          className="link-button"
          onClick={(e) => {
            const { account_id } = account;
            if (!account_id) return;
            e.stopPropagation();

            const urlSearchParams = cloneURLSearchParams();

            urlSearchParams.append('account', account_id);
            urlSearchParams.delete('p');

            replaceURLSearchParams(urlSearchParams);
          }}
        >
          {account.nickname || account.name}
        </button>
      )}
    </QueryStringContext.Consumer>
  );
}

export default CloudAccountLink;

import React, { Component, ReactNode } from 'react';
import { Popover } from 'antd';

import { User } from 'typings';
import AuthContext from 'components/app/Auth/AuthContext';

import { combineFullName } from 'utilities/user';

import { PopoverMenuItem } from 'components/ui/Popover';
import Avatar from '../Avatar';
import { refreshCognitoSession } from '../../../../../services/auth';

interface Props {
  children: ReactNode;
  user: User;
}

interface State {
  popupVisible: boolean;
}

class SettingsPopover extends Component<Props, State> {
  static contextType = AuthContext;

  constructor(p: Props) {
    super(p);

    this.state = {
      popupVisible: false
    };

    this.hide = this.hide.bind(this);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
  }

  render() {
    const { children, user } = this.props;
    const { popupVisible } = this.state;
    const { signOut } = this.context;

    const { client, email } = user;
    const { name: clientName } = client;

    const popOverTitle = (
      <PopoverMenuItem
        icon={<Avatar size="large" />}
        label={combineFullName(user)}
        secondaryLabel={email}
        thirdRowLabel={clientName}
      />
    );

    const popOverOptions = (
      <div>
        <PopoverMenuItem
          label="Help Center"
          link="/feedback"
          onClick={(e) => {
            e.preventDefault();
            window.open('https://help.disruptops.com/help-center');
          }}
        />
        <PopoverMenuItem
          label="Send feedback"
          link="/feedback"
          onClick={(e) => {
            e.preventDefault();
            const { Intercom } = window as any;
            Intercom('showNewMessage', 'Feedback: \n\n');
          }}
        />
        <PopoverMenuItem label="Settings" link="/settings" onClick={this.hide} />
        { this.context.isDisruptOpsUser && <PopoverMenuItem label="Refresh Session" onClick={async () => await refreshCognitoSession()} /> }
        <PopoverMenuItem label="Log out" link="/login" onClick={() => signOut()} />
      </div>
    );

    return (
      <Popover
        placement="rightBottom"
        visible={popupVisible}
        onVisibleChange={this.handleVisibleChange}
        title={popOverTitle}
        content={popOverOptions}
        trigger="click"
      >
        {children}
      </Popover>
    );
  }

  handleVisibleChange(popupVisible: boolean) {
    this.setState({ popupVisible });
  }

  hide() {
    this.setState({
      popupVisible: false
    });
  }
}

export default SettingsPopover;

import React from 'react';
import styled from 'styled-components';

import { TabsProps } from 'antd/lib/tabs/index';
import { Tabs as AntTabs } from 'antd';

const Root = styled.div`
  margin-bottom: 24px;

  > .ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;
  }

  > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }

  > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }

  > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: #dfdfdf;
  }

  > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
    color: #333;
  }
`;

export interface Props extends TabsProps {}

function Tabs(props: Props) {
  return (
    <Root>
      <AntTabs {...props} />
    </Root>
  );
}

export default Tabs;

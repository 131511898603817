import React, { ReactNode } from 'react';
import { Resource } from 'typings';
import Paper from 'components/ui/Paper';
import Divider from 'components/ui/Divider';
import styled from 'styled-components';
import TagList from 'components/cloudResources/TagList';
import cloudResourceTypesConfig from 'components/cloudResources/cloudResourceTypesConfig';
import { Link } from 'react-router-dom';
import { rawToDashed } from 'utilities/resourceType';

export const Content = styled.div`
  padding: 16px;

  .divider {
    margin-bottom: 8px;
  }
`;

export interface Props {
  resource: Resource;
}

function ResourceSummaryPanel(props: Props) {
  const { resource } = props;

  const resourceType = resource.dops_resource_type || '';
  const account = resource.dops_cloud_account_id;
  const accountId = resource.dops_internal_account_id;

  const resourceTypeConfig = resourceType ? cloudResourceTypesConfig[resourceType] || null : null;

  return (
    <Paper>
      <Content>
        <Row label="ID" content={resource.dops_resource_id} />
        <Divider height={2} classes={{ root: 'divider' }} />
        <Row
          label="Cloud account"
          content={<Link to={`/inventory/resources?account=${accountId}`}>aws-{account}</Link>}
        />
        <Divider height={2} classes={{ root: 'divider' }} />
        <Row
          label="Resource type"
          content={
            <Link to={`/inventory/resources/${rawToDashed(resourceType)}`}>
              {resourceTypeConfig ? resourceTypeConfig.labelSingular : resourceType}
            </Link>
          }
          borderedContent
        />
        {resource.tags && (
          <>
            <Divider height={2} classes={{ root: 'divider' }} />
            <Row label="Tags" content={<TagList tags={resource.tags} />} fullWidthContent />
          </>
        )}
      </Content>
    </Paper>
  );
}

const RowRoot = styled.div`
  display: flex;
  margin-bottom: 8px;
  align-items: flex-start;

  &.full-width-content {
    flex-direction: column;
  }
`;

const RowLabel = styled.div`
  color: #888888;
  width: 100px;
  padding-right: 8px;

  &.bordered {
    padding-top: 2px;
  }

  .full-width-content & {
    width: 100%;
    margin-bottom: 8px;
  }
`;

const RowContent = styled.div`
  color: #333;

  &.bordered {
    border: 2px solid ${p => p.theme.grey100};
    padding: 0 4px;
  }
`;

interface RowProps {
  label: string;
  content: ReactNode;
  borderedContent?: boolean;
  fullWidthContent?: boolean;
}

function Row(props: RowProps) {
  const { label, content, borderedContent = false, fullWidthContent = false } = props;

  let rootClasses = '';
  if (fullWidthContent) rootClasses += ' full-width-content';

  return (
    <RowRoot className={rootClasses}>
      <RowLabel className={borderedContent ? 'bordered' : ''}>{label}</RowLabel>
      <RowContent className={borderedContent ? 'bordered' : ''}>{content}</RowContent>
    </RowRoot>
  );
}

export default ResourceSummaryPanel;

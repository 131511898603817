import React from 'react';
import { Typography } from 'antd';

import { RecipientFormControlProps } from 'components/recipients/common';
import { TeamsConfig } from 'typings';
import { FormField } from 'components/ui/Form';
import MSTeamsRecipientListItem from './MSTeamsRecipientListItem';

function MSTeamsRecipientFormControls(props: RecipientFormControlProps) {
  const { prefix = 'config' } = props;

  return (
    <FormField name={prefix} wrapInFormItem={false}>
      {({ value }) => {
        const config = value as TeamsConfig;

        if (config) {
          return (
            <MSTeamsRecipientListItem
              recipient={{
                type: 'MS_TEAMS',
                config
              }}
            />
          );
        }

        return (
          <>
            <Typography.Title level={4}>Instructions</Typography.Title>
            <Typography.Paragraph>
              {
                'At this time Microsoft Teams Recipients connectors must be created from within the Microsoft Teams App directly via a Connector.'
              }
            </Typography.Paragraph>
            <Typography.Paragraph>{'More instructions to come...'}</Typography.Paragraph>
          </>
        );
      }}
    </FormField>
  );
}

export default MSTeamsRecipientFormControls;

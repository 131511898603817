import React from 'react';
import DopeIcon from 'components/ui/DopeIcon';
import { ClientProject } from 'typings';
import ValueChip from 'components/ui/ValueChip';

interface ProjectChipProps {
  project: ClientProject;
}

function ProjectChip(props: ProjectChipProps) {
  const { project } = props;

  return (
    <ValueChip>
      <DopeIcon name="PROJECT" color="#fff" />
      {project?.name}
    </ValueChip>
  );
}

export default ProjectChip;

import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  font-weight: 600;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-size: 13px;

  > span {
    margin-left: 8px;
    display: block;
    padding: 3px;
    background-color: ${p => p.theme.grey100};
    border-radius: 4px;
    line-height: 1em;
  }
`;

interface TabLabelProps {
  label: string;
  count?: number;
  classes?: {
    root?: string;
  };
}

function TabLabel(props: TabLabelProps) {
  const { label, count, classes = {} } = props;
  return (
    <Root className={classes.root || ''}>
      {label}
      {count !== undefined && <span>{count}</span>}
    </Root>
  );
}

export default TabLabel;

import React, { useState } from 'react';
import { BooleanBoxDiv } from './BooleanBox.styles';

import { FilterInputProps } from '../SearchBar';
import { Button } from 'antd';

function BooleanBox(props: FilterInputProps) {
  const { filter, onChange, searchValues, acceptChanges } = props,
    control = [true, false],
    [state] = useState<boolean>(searchValues[filter.key]);

  function onClick(update) {
    onChange(filter.key, update);
    acceptChanges();
  }

  return (
    <BooleanBoxDiv>
      {control.map((c, i) => {
        return (
          <Button
            className="option-button"
            type={state?.toString() === c.toString() ? 'primary' : undefined}
            key={i}
            onClick={() => {
              onClick(c);
            }}
          >
            {c.toString()}
          </Button>
        );
      })}
    </BooleanBoxDiv>
  );
}

export default BooleanBox;

import React, { SFC } from 'react';
import { Input as AntInput } from 'antd';

import { SelectSubTitle } from '../Select';
import { InputTitle, InputUpper, InputOuter, ValidationErrorMessage } from './components';

interface InputProps {
  title?: string | React.ReactNode;
  subTitle?: string;
  extra?: string | React.ReactNode;
  rows?: number;
  onChange?: (event: any) => any;
  onPressEnter?: (event?: any) => any;
  placeholder?: string;
  width?: string;
  height?: string;
  marginTop?: string;
  name: string;
  value?: string;
  onBlur?: any;
  error?: string;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  autoComplete?: string;
  centerTitle?: boolean;
  autoFocus?: boolean;
  autoSize?: boolean;
  classes?: {
    inputOuter?: string;
  };
}

const Input: SFC<InputProps> = props => {
  const {
    title,
    extra,
    onChange,
    placeholder,
    onPressEnter,
    width,
    height,
    marginTop,
    name,
    value,
    onBlur,
    error,
    rows,
    disabled,
    type,
    required,
    autoComplete,
    subTitle,
    centerTitle,
    autoFocus,
    autoSize,
    classes = {}
  } = props;

  let inputOuterClasses = error ? 'withError' : '';
  if (classes.inputOuter) inputOuterClasses += ` ${classes.inputOuter}`; // mix with classes passed through props.

  return (
    <InputOuter
      width={width}
      height={height}
      className={inputOuterClasses}
      rows={rows}
      marginTop={marginTop}
      key={name}
    >
      <InputUpper>
        <InputTitle centered={centerTitle ? centerTitle : false}>{required ? `* ${title}` : title}</InputTitle>
        {centerTitle !== true && <ValidationErrorMessage>{error || extra}</ValidationErrorMessage>}
      </InputUpper>
      {rows || autoSize ? (
        <AntInput.TextArea
          name={name}
          defaultValue={value}
          rows={rows}
          autoSize={autoSize}
          placeholder={placeholder}
          onChange={onChange}
          onPressEnter={onPressEnter}
          onBlur={onBlur}
          disabled={disabled}
          autoFocus={autoFocus}
        />
      ) : (
        <AntInput
          name={name}
          defaultValue={value}
          // value={value}
          type={type}
          size="large"
          placeholder={placeholder}
          onChange={onChange}
          onPressEnter={onPressEnter}
          onBlur={onBlur}
          disabled={disabled}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
        />
      )}
      {centerTitle === true && error && <ValidationErrorMessage>{error || extra}</ValidationErrorMessage>}
      <SelectSubTitle>{subTitle}</SelectSubTitle>
    </InputOuter>
  );
};

export default Input;

import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { Assessment } from 'typings';

import { generateFormSchema } from 'utilities/function';

export interface ValidationBag {
  isValidating: boolean;
  isValid: boolean | null;
}

interface Props {
  assessment: Assessment;
  children: (validationBag: ValidationBag) => React.ReactNode | React.ReactNodeArray;
}

function AssessmentValidator(props: Props) {
  const { assessment, children } = props;

  const { isValidating, isValid } = useAssessmentValidator(assessment);

  return <>{children({ isValidating, isValid })}</>;
}

export interface AssessmentValidationState {
  isValidating: boolean;
  isValid: boolean | null;
}

export function useAssessmentValidator(assessment: Assessment) {
  const [assessmentValidationState, setAssessmentValidationState] = useState<AssessmentValidationState>({
    isValid: null,
    isValidating: true
  });

  const { assessor } = assessment;
  const staticConfiguration = assessment.staticConfiguration || {};
  const parameters = assessor?.parameters || [];

  useDeepCompareEffect(() => {
    if (!parameters || parameters.length === 0) {
      return setAssessmentValidationState({
        isValid: true,
        isValidating: false
      });
    }

    const schema = generateFormSchema(parameters);

    schema
      .isValid(staticConfiguration)
      .then(isValid => {
        setAssessmentValidationState({
          isValid,
          isValidating: false
        });
      })
      .catch(e => {
        setAssessmentValidationState({
          isValid: false,
          isValidating: false
        });
      });
  }, [parameters, staticConfiguration]);

  return assessmentValidationState;
}

export default AssessmentValidator;

import gql from 'graphql-tag';

import USER_GROUP_FRAGMENT from '../fragments/userGroup';

export const USER_GROUP_LIST_QUERY = gql`
  query UserGroupList {
    userGroupList @rest(type: "UserGroupListPayload", path: "/user-groups", method: "GET") {
      items {
        ...groupFields
      }

      page
      per_page
      total
    }
  }

  ${USER_GROUP_FRAGMENT}
`;

export default USER_GROUP_LIST_QUERY;

import { Button, Input, message } from 'antd';
import { FormField } from 'components/ui/Form';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { NotificationConfigurationComponentProps } from '../../types';
import NotificationRecipientUsage from '../NotificationRecipientUsage';

const Root = styled.div`
  .topic-arn-input {
    margin-bottom: 24px;
  }

  .policy-statement {
    line-height: 1.5em;
  }
  .preconfigured-box {
    .preconfigured-box-header {
      border-radius: 3px 3px 0 0;
      background-color: ${(p) => p.theme.grey300};
      display: flex;
      justify-content: flex-end;
      padding: 4px 8px;
    }

    pre {
      border-radius: 0 0 3px 3px;
      padding: 12px;
      background-color: ${(p) => p.theme.grey200};
    }
  }
`;

function buildPolicyStatement(arn: string): string | null {
  if (!arn) return null;

  let senderArn: string | null = null;
  const env = `${process.env.REACT_APP_ENV}`;

  if (env === 'local' || env === 'dev') {
    senderArn = 'arn:aws:iam::783954734792:role/notifications-dev-us-west-2-lambdaRole';
  } else if (env === 'stage' || env === 'staging' || env === 'demo') {
    senderArn = 'arn:aws:iam::130648943101:role/notifications-stage-us-east-1-lambdaRole';
  } else if (env === 'prod' || env === 'production') {
    senderArn = 'arn:aws:iam::090104963584:role/notifications-prod-us-west-2-lambdaRole';
  }

  return `{
    "Sid": "DisruptOpsSnsNotifications",
    "Effect": "Allow",
    "Principal": {
      "AWS": "${senderArn}"
    },
    "Action": [ "SNS:Publish" ],
    "Resource": "${arn}"
}`;
}

function SnsRecipient(props: NotificationConfigurationComponentProps) {
  const {
    name,
    formProps: { setFieldValue },
    recipient
  } = props;

  return (
    <Root>
      <FormField name={`${name}.topicArn`} label="SNS Configuration">
        {({ name, value, handleBlur }) => {
          return (
            <SnsRecipientControl onChange={(value) => setFieldValue(name, value)} onBlur={handleBlur} value={value} />
          );
        }}
      </FormField>
      <NotificationRecipientUsage recipient={recipient} />
    </Root>
  );
}

interface SnsRecipientControlProps {
  value: string | undefined;
  onChange: (value: string) => void;
  onBlur: () => void;
}

export function SnsRecipientControl(props: SnsRecipientControlProps) {
  const { value, onChange, onBlur } = props;
  const policyStatement = value ? buildPolicyStatement(value) : null;

  return (
    <Root>
      <Input
        placeholder="Topic ARN"
        value={value}
        onChange={(e) => {
          const value = e.target.value;
          onChange(value);
        }}
        onBlur={onBlur}
        className="topic-arn-input"
      />
      {policyStatement ? (
        <div className="policy-statement">
          <h4>Authorize DisruptOps to send messages to your SNS topic.</h4>
          <p>
            You will need to add a permission for a remote role (an AWS IAM role from the DisruptOps AWS account) to
            send messages to the SNS topic. Here is a suggested policy statement which you may copy into your own
            policy.
          </p>

          <div className="preconfigured-box">
            <div className="preconfigured-box-header">
              <CopyToClipboard
                text={policyStatement}
                onCopy={() => {
                  message.success('Policy copied to clipboard');
                }}
              >
                <Button style={{ paddingLeft: '0' }} type="link" icon="copy">
                  Copy to Clipboard
                </Button>
              </CopyToClipboard>
            </div>
            <pre>{policyStatement}</pre>
          </div>
        </div>
      ) : null}
    </Root>
  );
}

export default SnsRecipient;

import { Checkbox, Form } from 'antd';
import { Field } from 'formik';
import React from 'react';

interface CheckboxFormItemProps {
  name: string;
  children: any;
  extra?: any;
  style?: React.CSSProperties;
  onChanged?: (event: any) => any; // called after checkbox onChange event handler, for enabling/disabling other fields in response
}

function CheckboxFormItem(props: CheckboxFormItemProps) {
  const { name, children, extra, style, onChanged } = props;

  return (
    <Form.Item style={style}>
      <Field name={name}>
        {({ field, form }) => {
          const { setFieldValue } = form;
          const { name, value, other } = field;

          return (
            <>
              <Checkbox
                checked={value}
                onChange={(e) => {
                  setFieldValue(name, e.target.checked);
                  onChanged && onChanged(e);
                }}
                {...other}
              >
                {children}
              </Checkbox>
              {extra}
            </>
          );
        }}
      </Field>
    </Form.Item>
  );
}

export default CheckboxFormItem;

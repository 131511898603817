import React from 'react';
import { FunctionInputBaseProps } from 'typings';
import styled from 'styled-components';
import { FieldArray } from 'formik';
import { Form, Alert, Button } from 'antd';
import DynamicConfigSelectButton from 'components/function/DynamicConfigSelectButton';
import ManyFieldGroup from 'components/function/ManyFieldGroup';
import DopeIcon from 'components/ui/DopeIcon';

const FormItem = Form.Item;
const Root = styled.div``;
const CenteredActions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > * {
    margin: 0 8px;
  }
`;

interface Props {
  name: string;
  title?: string;
  opInputBaseProps: FunctionInputBaseProps;

  fieldRender: (baseProps: FunctionInputBaseProps) => React.ReactNode;
  extra?: string;
  required?: boolean;
  initialValue?: any;
  onCancelChanges: () => void;
}

function OpInputMany(props: Props) {
  const {
    fieldRender,
    name,
    opInputBaseProps, // props passed into each OpInput rendered
    required = false,
    initialValue,
    onCancelChanges,
    title,
    extra
  } = props;
  const { value, touched, error, dynamicConfigValues, onSelectDynamicConfig } = opInputBaseProps;

  return (
    <FieldArray name={name}>
      {({ remove, push }) => {
        return (
          <Root>
            <FormItem label={title} extra={extra} required={required}>
              {Array.isArray(value) &&
                value.map((i, idx) => {
                  const iValue = value[idx];
                  const iName = `${name}.${idx}`;
                  const iTouched = touched && touched[idx];
                  const iError = error && error[idx];

                  const iProps = {
                    ...opInputBaseProps,
                    name: iName,
                    value: iValue,
                    error: iError,
                    touched: iTouched,
                    manyIndex: idx,
                    onRemoveSelf: () => remove(idx)
                  };

                  return (
                    <FormItem
                      key={iName}
                      hasFeedback={touched && error}
                      help={(iTouched && iError) || undefined}
                      required={required}
                    >
                      <ManyFieldGroup
                        action={
                          <Button size="large" onClick={() => remove(idx)}>
                            <DopeIcon name="REMOVE" />
                          </Button>
                        }
                      >
                        {fieldRender(iProps)}
                      </ManyFieldGroup>
                    </FormItem>
                  );
                })}
              {Array.isArray(value) && value.length === 0 && (
                <Alert
                  message="Empty list"
                  description="Use the 'Add' button below to add an item to this list."
                  type="info"
                  showIcon
                  style={{ width: '80%', margin: '0 auto 30px' }}
                />
              )}
            </FormItem>

            <CenteredActions>
              <Button
                onClick={() => {
                  push(initialValue);
                }}
                className="altButton"
              >
                Add
              </Button>

              {onSelectDynamicConfig && dynamicConfigValues && dynamicConfigValues.length > 0 && (
                <DynamicConfigSelectButton
                  scopedDynamicConfigs={dynamicConfigValues}
                  onSelect={onSelectDynamicConfig}
                  type="button"
                />
              )}

              <Button onClick={onCancelChanges}>Cancel changes</Button>
            </CenteredActions>
          </Root>
        );
      }}
    </FieldArray>
  );
}

export default OpInputMany;

import { setContext } from 'apollo-link-context';
import { getAccessToken } from '../../auth';

const authLink = setContext((request, previousContext) => {
  return new Promise(async (resolve, reject) => {
    let accessToken;

    try {
      accessToken = await getAccessToken();
    } catch (e) {
      return resolve();
    }

    if (!accessToken) {
      previousContext.cache.writeData({
        id: 'Identity',
        data: {
          __typename: 'Identity'
        }
      });
      return resolve();
    }

    if (accessToken) {
      const authorization = `Bearer ${accessToken.getJwtToken()}`;

      resolve({
        headers: {
          authorization
        }
      });
    }
  });
});

export default authLink;

import React from 'react';

import { FunctionReadViewProps } from '../FunctionReadView';

function CIDRListDisplay(props: FunctionReadViewProps) {
  const { value, functionParameter } = props;

  if (value === null) return null;

  if (typeof value !== 'object') {
    return typeof value === 'string' ? <div>{value}</div> : null;
  }

  return (
    <>
      {value.map((item, idx) => (
        <div key={`${functionParameter.inputCode}_${idx}`}>{item}</div>
      ))}
    </>
  );
}

export default CIDRListDisplay;

import React from 'react';
import SectionHeading from 'designSystem/SectionHeading/SectionHeading';
import DashboardComponent from 'app/sections/Authenticated/pages/Dashboard/Dashboard';

function Dashboard() {
  return (
    <div>
      <SectionHeading title="Dashboard" />
      <DashboardComponent />
    </div>
  );
}

export default Dashboard;

import * as Yup from 'yup';
import AssessmentCollapseTitleReadView from '../components/AssessmentCollapseTitleReadView';
import AssessmentFilterInput from '../components/AssessmentFilterInput';
import { GuardrailFilterDefinition } from '.';

const issueFromSingleAssessment: GuardrailFilterDefinition = {
  key: 'issue_from_single_assessment', // TODO: change rules engine to match this key
  schema: Yup.string(), // if value == undefined : no RuleFilter should be created.
  required: false,
  configurationInput: AssessmentFilterInput,
  getCollapseTitle: (value: any) => 'Filter Issues by Detector:',
  collapseTitleReadView: AssessmentCollapseTitleReadView,
  transformGuardrailBeforeSaving: (guardrail) => {
    if (!guardrail.filtersConfiguration || !guardrail.filtersConfiguration.issue_from_single_assessment)
      return guardrail;
    const assessmentId = guardrail.filtersConfiguration.issue_from_single_assessment;
    const { issue_from_single_assessment, ...filtersConfigurationWithoutAssessmentId } = guardrail.filtersConfiguration;

    return {
      ...guardrail,
      assessmentId,
      filtersConfiguration: filtersConfigurationWithoutAssessmentId
    };
  },
  getInitialValueFromGuardrail: (guardrail) => {
    return guardrail.assessmentId || undefined;
  }
};

export default issueFromSingleAssessment;

import { ReactNode } from 'react';
import { SLACK_NOTIFICATION_INTEGRATION_DEFINITION } from './slack';
import { MS_TEAMS_NOTIFICATION_INTEGRATION_DEFINITION } from './ms-teams';

export const OP_NOTIFICATION_INTEGRATION_DEFINITIONS: OpNotificationIntegrationDefinition[] = [
  SLACK_NOTIFICATION_INTEGRATION_DEFINITION,
  MS_TEAMS_NOTIFICATION_INTEGRATION_DEFINITION
];

export interface OpNotificationIntegrationDefinition {
  key: string; // SLACK
  title: string; // "Send Slack Message"
  description?: string; //
  icon?: ReactNode;
  formComponent?: () => JSX.Element;
}

import { Select, Tooltip } from 'antd';
import { FilterItem, FilterRow } from 'components/app/FilterBar/components';
import { LabelsFilter, SearchFilter } from 'components/app/FilterBar/filters';
import Paginator from 'components/app/Paginator';
import DateTime from 'components/ui/DateTime';
import DopeIcon from 'components/ui/DopeIcon';
import IconButton from 'components/ui/IconButton';
import Label from 'components/ui/Label';
import Table from 'components/ui/Table';
import QueryResult from 'components/util/QueryResult';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import React from 'react';
import { OperationVariables, Query } from 'react-apollo';
import { Route } from 'react-router';
import { Link } from 'react-router-dom';
import truncate from 'truncate';
import { AutomationFunction, PageInfo } from 'typings';

const UNIQUE_FUNCTION_LABELS_QUERY = gql`
  query UniqueFunctionLabelsForFunctionTable {
    uniqueFunctionLabels
  }
`;

const SORT_BY_MAP = {
  name: 'name',
  cloudVendor: 'cloudVendor',
  releaseChannel: 'releaseChannel',
  isDeprecated: 'is_deprecated',
  updated_at: 'updated_at'
};

const SORT_ORDER_MAP = {
  ascend: 'ASC',
  descend: 'DESC'
};

interface Props {
  query: DocumentNode;
  variables?: OperationVariables;
  getFunctionsRootFromQueryResult: (
    data: any
  ) => {
    nodes: AutomationFunction[];
    pageInfo: PageInfo;
  };
}

const FILTERS = [
  {
    name: 'labels',
    label: 'Label',
    qsKey: 'labels',
    many: true
  },
  {
    name: 'cloudVendor',
    label: 'Cloud Vendor',
    qsKey: 'cloudVendor',
    many: true
  }
];

function FunctionTable(props: Props) {
  const { query, variables = {}, getFunctionsRootFromQueryResult } = props;

  return (
    <Paginator pageSize={20} filters={FILTERS}>
      {pageRenderProps => {
        const {
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          updateSearchParams,
          updateSearch,
          search,
          filterValues,
          pushFilters,
          replaceFilters
        } = pageRenderProps;

        const functionQueryVariables = {
          search,
          labels: filterValues.labels,
          cloudVendor: filterValues.cloudVendor,
          isDisabled: false,
          ...variables,
          sortBy: sortBy || 'name',
          sortDirection: sortDirection || 'ASC',
          pageNumber,
          pageSize
        };

        const allColumns = [
          {
            title: '',
            key: 'run',
            dataIndex: 'run',
            render: (text, item) => {
              return (
                <Route
                  render={({ history }) => {
                    return (
                      <IconButton
                        title="Edit &amp; Run Function"
                        size="small"
                        onClick={() =>
                          history.push({
                            pathname: `/dev/functions/${item.id}/run`
                          })
                        }
                      >
                        <DopeIcon name="PLAY" />
                      </IconButton>
                    );
                  }}
                />
              );
            }
          },
          {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            sorter: true,
            render: (text, item) => {
              const url = `/dev/functions/${item.id}`;

              return (
                <div>
                  <Link to={url}>{text}</Link>
                </div>
              );
            }
          },
          {
            title: 'Labels',
            key: 'labels',
            dataIndex: 'labels',
            render: labels => {
              if (!labels) return null;

              return (
                <div>
                  {labels &&
                    labels.map((l, idx) => (
                      <Label
                        key={`${l}_${idx}`}
                        onClick={() => {
                          replaceFilters({
                            search: '',
                            s: '',
                            labels: l
                          });
                        }}
                        label={l}
                      />
                    ))}
                </div>
              );
            }
          },
          {
            title: 'Description',
            key: 'description',
            dataIndex: 'description',
            render: (text, item) => {
              let truncatedDescription = '';
              try {
                truncatedDescription = text && truncate(text, 50);
              } catch (e) {
                console.error(e, { text });
              }
              return <Tooltip title={text}>{truncatedDescription}</Tooltip>;
            }
          },
          {
            title: 'Version',
            key: 'version',
            dataIndex: 'version'
          },
          {
            title: 'Updated',
            key: 'updatedAt',
            sorter: true,
            dataIndex: 'updatedAt',
            className: 'no-wrap',
            render: updatedAt => {
              if (!updatedAt) return '--';

              return <DateTime dateTime={updatedAt} />;
            }
          }
        ];

        const columns = allColumns;

        return (
          <>
            <FilterRow>
              <FilterItem label="Search">
                <SearchFilter search={search} onSearchChange={updateSearch} />
              </FilterItem>
              <FilterItem label="Cloud Vendor">
                <Select
                  mode="multiple"
                  value={(Array.isArray(filterValues.cloudVendor) && filterValues.cloudVendor) || []}
                  style={{ minWidth: '200px', maxWidth: '500px' }}
                  dropdownMatchSelectWidth={false}
                  placeholder={'Select cloud vendor(s)'}
                  maxTagCount={2}
                  maxTagTextLength={48}
                  maxTagPlaceholder={extraValues => {
                    return <>+&nbsp;{extraValues.length}</>;
                  }}
                  onChange={value => {
                    pushFilters('cloudVendor', value);
                  }}
                >
                  {['AWS', 'Azure'].map(vendor => {
                    return (
                      <Select.Option key={vendor} title={vendor}>
                        {vendor}
                      </Select.Option>
                    );
                  })}
                </Select>
              </FilterItem>
              <FilterItem label="Labels">
                <LabelsFilter
                  value={(Array.isArray(filterValues.labels) && filterValues.labels) || []}
                  onChange={value => {
                    pushFilters('labels', value);
                  }}
                  query={UNIQUE_FUNCTION_LABELS_QUERY}
                  getLabelsRootFromQueryResult={data => data && data.uniqueFunctionLabels}
                />
              </FilterItem>
            </FilterRow>
            <Query query={query} variables={functionQueryVariables}>
              {({ loading, data, error }) => (
                <QueryResult loading={loading} data={data} error={error}>
                  {() => {
                    const { nodes: functions, pageInfo } = getFunctionsRootFromQueryResult(data);

                    return (
                      <Table
                        antTableProps={{
                          loading,
                          dataSource: functions,
                          rowKey: 'id',
                          columns,
                          locale: {
                            emptyText: 'No matching Functions'
                          },
                          onChange: (pagination, filters, sorter, a) => {
                            updateSearchParams({
                              sortBy: SORT_BY_MAP[sorter.columnKey] || 'name',
                              sortDirection: SORT_ORDER_MAP[sorter.order] || SORT_ORDER_MAP.ascend,
                              page: pagination.current || 1
                            });
                          },
                          pagination: {
                            pageSize,
                            current: pageInfo && pageInfo.current,
                            total: pageInfo && pageInfo.total,
                            onChange: pageNumber => {
                              updateSearchParams({
                                page: pageNumber
                              });
                            }
                          }
                        }}
                      />
                    );
                  }}
                </QueryResult>
              )}
            </Query>
          </>
        );
      }}
    </Paginator>
  );
}

export default FunctionTable;

import styled from 'styled-components';

export const ProjectEditorDiv = styled.div`
  .change-owner {
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
  }
`;

import gql from 'graphql-tag';

const RESOURCE_TYPE_LIST_QUERY = gql`
  query ResourceTypeList {
    resource_types {
      key
      name
    }
  }
`;

export default RESOURCE_TYPE_LIST_QUERY;

import gql from 'graphql-tag';

export const PROJECT_DETAIL = gql`
  query Project($id: String!) {
    project(id: $id) @rest(type: "ClientProject", path: "/api/v2/projects/{args.id}", method: "GET") {
      name
      project_id
      created
      updated
    }
  }
`;

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Root = styled(NavLink)`
  padding: 8px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  &:hover,
  &.active {
    background-color: ${(p) => p.theme.grey900};
  }

  .primary-theme & {
    &:hover,
    &.active {
      background-color: ${(p) => p.theme.primary900};
      background-color: #321668;
    }
  }

  .light-theme & {
    &:hover {
      background-color: ${(p) => p.theme.grey200};
    }
    &.active {
      background-color: ${(p) => p.theme.primary50};
    }
  }

  &.active.is-title {
    background-color: transparent;
  }

  &:visited,
  &:focus {
    text-decoration: none;
  }
`;

const Label = styled.div`
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  // padding-left: 56px;

  .is-title & {
    font-weight: 400;
  }

  .icon-wrap + & {
    padding-left: 0;
  }
`;

const IconWrap = styled.div`
  width: 40px;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  color: #fff;
`;

interface Props {
  to: string;
  label: string;
  exact?: boolean;
  icon?: React.ReactNode | null;
  isSidenavTitle?: boolean;
  classes?: {
    root?: string;
    label?: string;
  };
}

function NavItem(props: Props) {
  const { label, to, exact = false, icon, classes = {}, isSidenavTitle = false } = props;

  return (
    <Root
      activeClassName="active"
      to={to}
      className={`nav-item ${isSidenavTitle ? 'is-title' : ''} ${classes.root || ''}`}
      exact={exact}
    >
      {icon && <IconWrap className="icon-wrap">{icon}</IconWrap>}
      <Label className={`nav-item-label ${classes.label || ''}`}>{label}</Label>
    </Root>
  );
}

export default NavItem;

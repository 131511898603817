import useDetectorVariants, {
  DetectorVariant
} from 'app/sections/Detectors/DetectorsCommon/useDetectorVariants/useDetectorVariants';
import PostureMonitoringBreadcrumbs from 'app/sections/PostureMonitoring/PostureMonitoringBreadcrumbs/PostureMonitoringBreadcrumbs';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ResourceDetail from '../../ResourceDetail';
import Inventory from '../Inventory';

function InventorySection() {
  const variants: DetectorVariant = useDetectorVariants(),
    isPostureMonitoring = variants.id === 'posture-monitoring';

  return (
    <>
      {isPostureMonitoring && <PostureMonitoringBreadcrumbs />}
      <Switch>
        <Route path="/posture-monitoring/inventory/:dopsResourceType/:dopsResourceUid" component={ResourceDetail} />
        <Route path="/posture-monitoring/inventory/:resourceType?" component={Inventory} />

        {/* legacy routes */}
        <Route path="/inventory/resources/:dopsResourceType/:dopsResourceUid" component={ResourceDetail} />
        <Route path="/inventory/resources/:resourceType?" component={Inventory} />
        {/* <Route path="/inventory" render={() => <Redirect to="/inventory/resources" />} /> */}
      </Switch>
    </>
  );
}

export default InventorySection;

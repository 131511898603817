import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import { IconNames } from 'components/ui/DopeIcon/DopeIcon';
import React, { useEffect } from 'react';
import { Root } from './PageHeading.styles';

interface PageHeadingProps {
  title: string;
  subTitle?: string | null;
  icon?: IconNames;
  helpText?: string;
  children?: any;
}

function PageHeading(props: PageHeadingProps) {
  const { title, subTitle, helpText, children, icon } = props;

  const titles = ['DisruptOps'];
  if (title) titles.push(title);
  if (subTitle) titles.push(subTitle);
  const documentTitle = titles.join(' | ');

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return (
    <Root>
      <div className="wrapper">
        {icon && <DopeIcon className="page-icon" name={icon} size={20} />}
        <h1>{title}</h1>
        {helpText && (
          <Tooltip placement="bottom" title={helpText} arrowPointAtCenter>
            <QuestionCircleOutlined />
          </Tooltip>
        )}
        {children && <div className="children">{children}</div>}
      </div>
    </Root>
  );
}

export default PageHeading;

import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'antd';
import styled from 'styled-components';
import DopeIcon from './DopeIcon';

import Label from 'components/ui/Label';

const { Meta } = Card;

const Root = styled.div`
  margin-bottom: 16px;

  .ant-card-body {
    padding-right: 0;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  .ant-card-meta {
    flex: 1;
  }

  .ui-label {
    margin: 8px;
  }

  > svg {
    display: block;
    font-size: 32px;
    display: block;
    margin: 0 8px;
  }
`;

export interface Props {
  linkTo?: string;
  onClick?: () => any;
  title?: string;
  description?: string;
  labels?: string[];
}

function CardNext(props: Props) {
  const { linkTo, onClick, title, description, labels } = props;

  const card = (
    <Root>
      <Card hoverable={Boolean(linkTo || onClick)} onClick={onClick}>
        <Content>
          <Meta title={title} description={description} />
          <DopeIcon name="CHEVRON_RIGHT" />
        </Content>
        <Content>
          {labels && (
            <p>
              Effects:
              {labels.map((l, idx) => (
                <Label key={`${l}_${idx}`} label={l} />
              ))}
            </p>
          )}
        </Content>
      </Card>
    </Root>
  );

  // if props.linkTo wrap in linkTo
  return linkTo ? <Link to={linkTo}>{card}</Link> : card;
}

export default CardNext;

import styled from 'styled-components';

export const Root = styled.div`
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 102;
  transition: width 0.2s ease;
  width: initial;

  &.permanent {
    width: 68px;

    &.open {
      width: 240px;
    }
  }

  .main-sidenav-drawer {
    background-color: #212121;
    z-index: 101;
    transition: all 0.2s ease;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    height: 100%;
    width: 240px;
    transform: translateX(-100%);
  }

  &.permanent {
    .main-sidenav-drawer  {
      transform: translateX(0);
      width: 68px;
      position: absolute;
    }

    &.open {
      .main-sidenav-drawer {
        width: 240px;
      }
    }
  }

  .branding {
    display: flex;
    padding: 0 16px;
    position: relative;
    align-items: center;
    height: 56px;
    min-height: 56px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
    
    .company-name {
      color: #fff;
      font-size: 22px;
      margin-bottom: 0px;
      white-space: nowrap;
      font-weight: 400;
      letter-spacing: 0.025em;
    }
  }

  .main-sidenav-menu {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .main-nav-item-link {
    display: block;

    &.active {
      color: #fff;
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 4px;
        background-color: ${p => p.theme.primary500};
      }
    }
  }

  .main-nav-item-body {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    position: relative;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    
    .nav-item-icon-wrap {
      width: 48px;
      min-width: 48px;
    }

    .nav-item-icon {
      transition: all 0.2s ease;
      margin-right: 0;
      width: 36px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .nav-item-label {
      white-space: nowrap;
      position: relative;
      opacity: 1;
      transition: opacity .2s ease;
      letter-spacing: 0.02em;
    }



    &:hover {
      background-color: #3c3c3c;
    }
  }

  &.open {
    .nav-item {
      justify-content: flex-start;
    }
  }

  &.permanent {
    .sidenav-toggle-trigger {
      display: flex;

      .sidenav-toggle-trigger-icon {
        transform: rotateZ(0deg);
      }
    }

    .company-name, .nav-item-label {
      opacity: 0;
      max-width: 0;
    }

    &.open {
      .nav-item-label, .company-name {
        opacity: 1;
      }

      .sidenav-toggle-trigger .sidenav-toggle-trigger-icon {
        transform: rotateZ(180deg);
      }
    }
  }

  .sidenav-toggle-trigger {
    flex: 1 0 auto;
    width: 100%;
    padding: 6px 16px;
    cursor: pointer;
    justify-content: center; 
    display: none; 

    .sidenav-toggle-trigger-icon {
      width: 36px;
      height: 44px;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      transform-origin: 50% 50%;
    }

    color: ${p => p.theme.grey200};

    svg {
      color: ${p => p.theme.grey400}
      opacity: 0.8;
    }
  
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }

  
  &.permanent.open {
    .sidenav-toggle-trigger {
      justify-content: flex-end;
    }
  }

  .drawer-mask {
    position: fixed;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.open {
    .main-sidenav-drawer {
      transform: translateX(0);
    }
  }
`;

export const LogoCollapsed = styled.div<{
  backgroundImage: string;
}>`
  display: block;
  width: 46px;
  min-width: 46px;
  height: 36px;
  background-size: auto 26px;
  background-position: left center;
  background-repeat: no-repeat;
  background-image: url(${p => p.backgroundImage});
`;

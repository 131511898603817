import React from 'react';
import SectionHeading from 'designSystem/SectionHeading/SectionHeading';
import { CloudFilled } from '@ant-design/icons';
import { DetectorsSection } from '../Detectors';

function PostureMonitoring() {
  return (
    <div>
      <SectionHeading title="Posture Monitoring" icon={<CloudFilled />} />
      <DetectorsSection />
    </div>
  );
}

export default PostureMonitoring;

import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  display: flex;
  padding: 16px 52px 16px 24px;
  height: 56px;
  align-items: center;
  border-bottom: 1px solid ${p => p.theme.grey100};
`;

const TitleWrap = styled.div``;
const Title = styled.h2`
  margin-bottom: 0;
  line-height: 1em;
`;
const Actions = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

interface Props {
  title?: string | React.ReactNode;
  actions?: React.ReactNodeArray;
  classes?: {
    root?: string;
    titleWrap?: string;
    title?: string;
    actionsWrap?: string;
  };
}

function ModalHeader(props: Props) {
  const { actions, title, classes = {} } = props;

  return (
    <Root className={classes.root || ''}>
      {title && (
        <TitleWrap className={classes.titleWrap || ''}>
          {typeof title === 'string' ? <Title>{title}</Title> : title}
        </TitleWrap>
      )}
      {actions && <Actions className={classes.actionsWrap || ''}>{actions}</Actions>}
    </Root>
  );
}

export default ModalHeader;

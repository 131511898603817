import { Checkbox, Form, Tooltip } from 'antd';
import RecipientSelectFormItem from 'components/recipients/RecipientSelectFormItem';
import CheckboxFormItem from 'components/ui/Form/CheckboxFormItem';
import { FormRenderProps } from 'components/ui/Form/Form';
import { MinutesInput } from 'components/ui/Form/MinutesInput';
import { Field } from 'formik';
import RECIPIENTS_LIST_QUERY from 'queries/recipientListQuery';
import React, { useEffect } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { FunctionParameter, Maybe } from 'typings';
import { ContextArgs } from '../OpEditor';
import AlertDelayedAction from './AlertDelayedAction';
import AlertUserActions from './AlertUserActions';

const Root = styled.div`
  .r-table {
    display: table;
    width: 100%;
  }
  .r-row {
    display: table-row;
  }
  .r-cell {
    display: table-cell;
  }
  .r-body {
    display: table-row-group;
  }

  .ant-form-item {
    margin-bottom: 0px;
    margin-left: 16px;
  }
  .ant-form-item-control {
    line-height: 32px;
  }

  .section {
    margin-top: 16px;
    color: ${(p) => p.theme.grey800};
  }
`;

interface Props {
  formRenderProps: FormRenderProps;
  parameters?: Maybe<FunctionParameter[]>;
  projectId?: string;
  contextArgs: ContextArgs;
}

function AlertActionEditor(props: Props) {
  const { formRenderProps, parameters, projectId, contextArgs } = props;
  const { values = {}, setValues, setFieldValue } = formRenderProps;
  const { actionConfiguration } = values;
  const {
    alertRecipientId,
    alertRecipientSource,
    includeCreateTicketButton,
    ticketRecipientId,
    ticketRecipientSource,
    isAlertExpirationEnabled,
    alertExpirationMinutes,
    isDelayedActionEnabled,
    // actionDelayMinutes,
    delayedAction
  } = actionConfiguration || {};

  // console.log({
  //   isAlertExpirationEnabled,
  //   alertExpirationMinutes,
  //   isDelayedActionEnabled,
  //   actionDelayMinutes,
  //   delayedAction
  // });

  const recipientsResults = useQuery(RECIPIENTS_LIST_QUERY);
  const { data: recipientsData } = recipientsResults;

  const recipient =
    alertRecipientSource === 'STATIC' &&
    recipientsData?.recipients?.items?.find((item) => item.id === alertRecipientId);

  const ticketRecipient =
    ticketRecipientSource === 'STATIC' &&
    recipientsData?.recipients?.items?.find((item) => item.id === ticketRecipientId);

  useEffect(() => {
    const newActionConfiguration = {
      ...actionConfiguration,
      ...parameters?.reduce((previous, current) => {
        const key = current.key;
        if (
          !actionConfiguration.hasOwnProperty(key) ||
          actionConfiguration[key] === null ||
          actionConfiguration[key] === undefined
        ) {
          previous[key] = current.defaultValue;
        }
        return previous;
      }, {})
    };

    const newValues = {
      ...values,
      actionConfiguration: {
        ...actionConfiguration,
        ...newActionConfiguration
      }
    };

    setValues(newValues);
  }, []);

  const recipientTypes = ['SLACK', 'MS_TEAMS', 'EMAIL'];

  return (
    <Root>
      <div className="section" style={{ marginTop: '0px' }}>
        Alert Channel
      </div>
      <RecipientSelectFormItem
        idFieldName="actionConfiguration.alertRecipientId"
        sourceFieldName="actionConfiguration.alertRecipientSource"
        projectId={projectId}
        recipientsResults={recipientsResults}
        recipient={recipient}
        recipientTypes={recipientTypes}
      />
      <div className="section">Alert Buttons</div>
      <CheckboxFormItem name="actionConfiguration.includeCreateOpButton">
        <Tooltip title="Include a button in the alert to create an Op in DisruptOps, allowing the user to automate future responses to the event.">
          Automate future responses to this event
        </Tooltip>
      </CheckboxFormItem>
      <CheckboxFormItem name="actionConfiguration.includeDismissButton">
        <Tooltip title="Include a button in the alert to dismiss all action buttons.">Dismiss from channel</Tooltip>
      </CheckboxFormItem>
      <CheckboxFormItem
        name="actionConfiguration.includeCreateTicketButton"
        extra={
          includeCreateTicketButton && (
            <RecipientSelectFormItem
              idFieldName="actionConfiguration.ticketRecipientId"
              sourceFieldName="actionConfiguration.ticketRecipientSource"
              projectId={projectId}
              recipientsResults={recipientsResults}
              recipient={ticketRecipient}
              recipientTypes={['JIRA']}
            />
          )
        }
      >
        <Tooltip title="Include a button in the alert to create a ticket in an external system, such as Jira.">
          Create Ticket
        </Tooltip>
      </CheckboxFormItem>
      <CheckboxFormItem name="actionConfiguration.includeRecommendedActionsButton">
        <Tooltip title="Include buttons in the alert for actions recommended by DisruptOps, based on the event.">
          Recommended Actions
        </Tooltip>
      </CheckboxFormItem>
      <CheckboxFormItem name="actionConfiguration.includeMoreActions">
        <Tooltip title="Include a button in the alert to open the event details in DisruptOps, from where additional actions can be taken.">
          More actions...
        </Tooltip>
      </CheckboxFormItem>
      <div className="section">Custom Action Buttons</div>
      <AlertUserActions
        name="actionConfiguration.alertActions"
        contextArgs={contextArgs}
        formRenderProps={formRenderProps}
      />
      <div className="section">Expiration</div>
      <Form.Item>
        <Field>
          {({ form }) => {
            const { setFieldValue } = form;

            return (
              <>
                <Checkbox
                  checked={isAlertExpirationEnabled || isDelayedActionEnabled}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    // only allow either isDelayedActionEnabled or isAlertExpirationEnabled
                    // isAlertExpirationEnabled is true if checked and there's no delayedAction
                    // isDelayedActionEnabled is true if checked and there is a delayedAction
                    const isAlertExpirationEnabled = checked && !delayedAction;
                    const isDelayedActionEnabled = checked && !!delayedAction;

                    setFieldValue('actionConfiguration.isAlertExpirationEnabled', isAlertExpirationEnabled);
                    setFieldValue('actionConfiguration.isDelayedActionEnabled', isDelayedActionEnabled);
                  }}
                >
                  Prevent actions from buttons after
                  <Field name="actionConfiguration.alertExpirationMinutes" type="number">
                    {({ field }) => {
                      return (
                        <MinutesInput
                          minutes={alertExpirationMinutes}
                          disabled={!isAlertExpirationEnabled && !isDelayedActionEnabled}
                          onChange={(e) => {
                            setFieldValue('actionConfiguration.alertExpirationMinutes', e.target.value);
                            setFieldValue('actionConfiguration.actionDelayMinutes', e.target.value);
                          }}
                        />
                      );
                    }}
                  </Field>
                </Checkbox>
              </>
            );
          }}
        </Field>
      </Form.Item>
      <>
        <div style={{ marginLeft: '16px', marginTop: '8px' }}>
          If no other action is taken, automatically take this action after expiration:
        </div>
        <AlertDelayedAction
          name="actionConfiguration.delayedAction"
          contextArgs={contextArgs}
          formRenderProps={formRenderProps}
          onChange={(newValue) => {
            setFieldValue(
              'actionConfiguration.isAlertExpirationEnabled',
              !newValue && (isAlertExpirationEnabled || isDelayedActionEnabled) // enable alert expiration if checkbox is checked and there's no delayed action specified
            );
            setFieldValue(
              'actionConfiguration.isDelayedActionEnabled',
              !!newValue && (isAlertExpirationEnabled || isDelayedActionEnabled) // enable delayed action if checkbox is checked and there is a delayed action specified
            );
          }}
        />
      </>
    </Root>
  );
}

export default AlertActionEditor;

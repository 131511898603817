import React from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;

  height: ${(p: { height: number | string }) => {
    const height = p.height;
    if (typeof height === 'number') {
      return `${height}px`;
    }

    return height;
  }};

  &.center-vertically {
    height: initial;
    padding-top: 35vh;
    padding-bottom: 35vh;
  }

  .ant-spin {
    margin-bottom: 18px;
  }
`;

const Title = styled.h2`
  color: dimgrey;
`;

export interface Props {
  height?: number | string; // 100 or '100px'
  size?: 'small' | 'default' | 'large';
  title?: string | React.ReactNode;
  classes?: {
    root?: string;
    title?: string;
  };

  centerVertically?: boolean; // overrides height and vertically centeres in vh.
}

// could evolve to allow more customization
function ProgressCircular(props: Props) {
  const { height = 'initial', size = 'default', title, classes = {}, centerVertically = false } = props;

  let titleRendered: null | React.ReactNode = null;

  if (title) {
    // if string render h2 else render whatever is passed in.
    titleRendered = typeof title === 'string' ? <Title className={classes.title || ''}>{title}</Title> : title;
  }

  let rootClasses = centerVertically ? 'center-vertically' : '';
  if (classes.root) rootClasses += ` ${classes.root}`;

  return (
    <Root height={height} className={rootClasses}>
      <Spin size={size} />
      {titleRendered && titleRendered}
    </Root>
  );
}

export default ProgressCircular;

import gql from 'graphql-tag';
import ISSUE_FRAGMENT from './fragments/issue';

export const RESOLVED_ISSUE_LIST_QUERY = gql`
  query ResolvedIssueList {
    resolvedIssues @rest(type: "[Issue]", path: "/issues?status=resolved") {
      ...issueFields
    }
  }

  ${ISSUE_FRAGMENT}
`;

export default RESOLVED_ISSUE_LIST_QUERY;

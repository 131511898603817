import React, { useEffect, useState } from 'react';
import InputBox from './InputBox/InputBox';
import BooleanBox from './BooleanBox/BooleanBox';
import { FilterWrapperSection, SearchBarDiv } from './SearchBar.styles';
import ValueDisplay from './ValueDisplay/ValueDisplay';
import { useHistory, useLocation } from 'react-router-dom';
import AddFilter from './AddFilter/AddFilter';
import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';

export type SearchBarFilter = { key: string; label: string; shortcut?: boolean; control?: 'input' | 'boolean' };
export type SearchBarConfig = SearchBarFilter[];

export interface FilterInputProps {
  onChange: Function;
  filter: SearchBarFilter;
  searchValues: object;
  acceptChanges: Function;
}

interface SearchBarProps {
  onSearch?: Function;
  config: SearchBarConfig;
  pageKey?: string;
}

function getInitialValuesFromQueryString(params: URLSearchParams, config: SearchBarConfig) {
  const initialSearchValues = {},
    initialActiveFilters: SearchBarConfig = [];

  config.forEach((c) => {
    const val = params.get(c.key);

    let local;

    if (val) {
      if (val === 'true') {
        local = true;
      } else if (val === 'false') {
        local = false;
      }

      initialActiveFilters.push(c);
      initialSearchValues[c.key] = local || val;
    }
  });

  return { initialSearchValues, initialActiveFilters };
}

function SearchBar(props: SearchBarProps) {
  const { onSearch, config, pageKey = 'page' } = props,
    history = useHistory(),
    location = useLocation(),
    params = new URLSearchParams(location.search),
    [stateConfig] = useState<SearchBarConfig>(config),
    initialValuesFromQueryString = getInitialValuesFromQueryString(params, stateConfig),
    shortcutConfig = stateConfig.find((c) => c.shortcut),
    [configInitialized, setConfigInitialized] = useState(false),
    [currentEditor, setCurrentEditor] = useState<SearchBarFilter | undefined>(),
    [searchValues, setSearchValues] = useState(initialValuesFromQueryString.initialSearchValues),
    [active, setActive] = useState<SearchBarConfig>(initialValuesFromQueryString.initialActiveFilters);

  const editorBoxMap = {
    input: InputBox,
    boolean: BooleanBox
  };

  function onFilterChange(key, val) {
    setSearchValues({
      ...searchValues,
      [key]: val
    });
  }

  function availableFilters(filter) {
    return !active.includes(filter);
  }

  function activeFilters(filter) {
    return active.includes(filter);
  }

  function activateFilter(filter) {
    setCurrentEditor(filter);
    setActive([...active, filter]);
  }

  function clearFilter(filter) {
    const values = { ...searchValues };

    delete values[filter.key];

    deactivateFilter(filter);
    setSearchValues(values);
  }

  function deactivateFilter(filter) {
    const i = active.indexOf(filter),
      arr = [...active];

    arr.splice(i, 1);

    setActive(arr);
  }

  function closeAllEditors() {
    setCurrentEditor(undefined);
  }

  function pushUpdates() {
    const searchArray = Object.keys(searchValues).map((k) => {
      return {
        key: k,
        val: searchValues[k]
      };
    });

    onSearch && onSearch(searchArray);
  }

  function activateShortcut(text) {
    if (!shortcutConfig) {
      return;
    }
    onFilterChange(shortcutConfig?.key, text);
  }

  useEffect(() => {
    // when search values change
    stateConfig.forEach((c) => {
      const val = searchValues[c.key];

      if (val === undefined || val === '') {
        params.delete(c.key);
      } else {
        params.set(c.key, val.toString());
      }
    });

    // reset page param, if any
    if (pageKey) {
      params.delete(pageKey);
    }

    // push updates to parent callback
    if (onSearch) {
      pushUpdates();
    }

    history.push(`${location.pathname}?${params.toString()}`);
  }, [searchValues]);

  useEffect(() => {
    if (configInitialized) {
      console.warn('Warning: Search Bar config changed after component rendered.');
    } else {
      setConfigInitialized(true);
    }
  }, [config]);

  return (
    <SearchBarDiv>
      {stateConfig.filter(activeFilters).map((filter, i) => {
        const Editor = filter.control ? editorBoxMap[filter.control] : editorBoxMap.input;
        return (
          <div className="filter-frame" key={i}>
            {currentEditor === filter ? (
              <FilterWrapperSection>
                <div className="header">
                  <h1>{filter.label}</h1>
                  <button className="cancel" onClick={closeAllEditors}>
                    <CloseOutlined />
                  </button>
                </div>
                <Editor
                  filter={filter}
                  searchValues={searchValues}
                  onChange={onFilterChange}
                  acceptChanges={closeAllEditors}
                />
                <div className="footer">
                  <button className="ok" onClick={closeAllEditors}>
                    <CheckCircleFilled />
                  </button>
                </div>
              </FilterWrapperSection>
            ) : (
              <ValueDisplay
                filter={filter}
                enableEdit={() => {
                  setCurrentEditor(filter);
                }}
                searchValues={searchValues}
                clearFilter={() => {
                  clearFilter(filter);
                }}
              />
            )}
          </div>
        );
      })}
      <AddFilter
        shortcutConfig={shortcutConfig}
        activateShortcut={activateShortcut}
        config={stateConfig.filter(availableFilters)}
        activateFilter={activateFilter}
      />
    </SearchBarDiv>
  );
}

export default SearchBar;

export function searchParamsToObject(params) {
  const result = {};

  for (let [key, value] of params) {
    value = value === 'true' ? true : value;
    value = value === 'false' ? false : value;

    result[key] = value;
  }
  return result;
}

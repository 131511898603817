import { Collapse, Descriptions, Icon, Table } from 'antd';
import DateTime from 'components/ui/DateTime';
import DopeIcon from 'components/ui/DopeIcon';
import ErrorAlert from 'components/ui/ErrorAlert';
import JSONTree from 'components/ui/JSONTree';
import NeoPage, { CenteredContainer, TitleBar, TitleBarButton } from 'components/ui/NeoPage';
import gql from 'graphql-tag';
import capitalize from 'just-capitalize';
import React from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

const { Panel } = Collapse;

const Root = styled.div`
  .centered-container {
    padding-top: 0px;
  }

  .status {
    font-weight: bold;
    &.succeeded {
      color: ${(p) => p.theme.success};
    }
    &.failed {
      color: ${(p) => p.theme.error};
    }
  }

  .ant-page-header-heading-title {
    font-size: 21px;
  }

  .ant-descriptions-title {
    margin-bottom: 4px;
  }

  .ant-descriptions-item {
    padding-bottom: 4px;
  }

  .kpi {
    border-left: 4px solid #46327e;
    padding-left: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

const TASK_DETAIL_QUERY = gql`
  query task($id: String!) {
    task(id: $id)
  }
`;

function TaskDetail(props: Props) {
  const id = props.match.params.id;

  const { data, loading, error, refetch } = useQuery(TASK_DETAIL_QUERY, {
    variables: {
      id
    },
    skip: !id
  });

  const rootTask = data?.task;

  const {
    action,
    childTasks,
    context,
    elapsedTime,
    endTime,
    expiration,
    name,
    runner,
    startTime,
    status,
    subTaskCounter,
    subTaskId
  } = rootTask || {};
  const { clientId, stage, publisher, contextId, requestor } = context || {};
  const {
    functionId,
    method,
    cloudVendor,
    multiplexFields,
    functionType,
    projectIds,
    arn,
    assessmentId,
    subjectId,
    inputValues
  } = action || {};

  childTasks?.sort((a, b) => {
    return a.name.localeCompare(b.name, undefined, { sensitivity: 'base', numeric: true });
  });

  const sections = [
    {
      title: 'Root Task',
      items: [
        { label: 'Name', value: name },
        { label: 'Status', value: <span className={`status ${status}`}>{status && capitalize(status)}</span> },
        { label: 'ID', value: subTaskId },
        { label: 'Runner', value: runner },
        { label: 'Elapsed', value: elapsedTime },
        { label: 'Started', value: <DateTime format="local" dateTime={startTime} /> },
        { label: 'Ended', value: <DateTime format="local" dateTime={endTime} /> },
        { label: 'Expiration', value: <DateTime format="local" dateTime={expiration} /> },
        { label: 'SubTaskCounter', value: subTaskCounter }
      ]
    },
    {
      title: 'Context',
      items: [
        { label: 'ClientId', value: clientId },
        { label: 'ContextID', value: contextId },
        { label: 'Stage', value: stage },
        { label: 'Publisher', value: publisher },
        { label: 'Requestor', value: requestor }
      ]
    },
    {
      title: 'Action',
      items: [
        { label: 'Function', value: functionId },
        { label: 'Method', value: method },
        { label: 'Vendor', value: cloudVendor },
        { label: 'Multiplex', value: multiplexFields?.join(', ') },
        { label: 'Type', value: functionType },
        { label: 'Projects', value: projectIds },
        { label: 'ARN', value: arn },
        { label: 'Detector', value: assessmentId },
        { label: 'Subject', value: subjectId },
        { label: 'Inputs', value: JSON.stringify(inputValues) }
      ]
    }
  ];

  const sectionsToDisplay = sections.filter(({ items }) => items.some((i) => i.value !== null));

  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            icon={<DopeIcon name="ACTIVITY" size={20} />}
            sectionTitle="Tasks"
            sectionTitleLinkTo="/dev"
            backLinkTo="/dev"
            backLinkTooltipText="Back to Dev Section"
            title={name}
            actions={
              <TitleBarButton icon={<Icon type="sync" spin={loading} />} onClick={() => refetch()} disabled={loading} />
            }
          />
        }
      >
        <CenteredContainer>
          {!loading && error && <ErrorAlert error={error} />}
          {!loading && rootTask && (
            <>
              {sectionsToDisplay.map(({ items, title }, index) => {
                const itemsToDisplay = items.filter((i) => i.value !== null);

                return (
                  <Descriptions className="kpi" column={1} key={index} title={title}>
                    {itemsToDisplay.map(({ label, value }, index) => (
                      <Descriptions.Item label={label} key={index}>
                        {value}
                      </Descriptions.Item>
                    ))}
                  </Descriptions>
                );
              })}
              <b>Child Tasks</b>
              <Table
                rowKey="subTaskId"
                size="small"
                dataSource={childTasks}
                locale={{ emptyText: 'No child tasks' }}
                columns={[
                  {
                    title: 'Name',
                    dataIndex: 'name'
                  },
                  {
                    title: 'Status',
                    dataIndex: 'status',
                    render: (id) => {
                      return <span className={`status ${status}`}>{status && capitalize(status)}</span>;
                    }
                  },
                  { title: 'Elapsed', dataIndex: 'elapsedTime' },
                  {
                    title: 'Started',
                    dataIndex: 'startTime',
                    render: (startTime) => <DateTime format="local" dateTime={startTime} />
                  },
                  {
                    title: 'Ended',
                    dataIndex: 'endTime',
                    render: (endTime) => <DateTime format="local" dateTime={endTime} />
                  },
                  {
                    title: 'Accounts',
                    dataIndex: 'action.accounts',
                    render: (accounts) =>
                      accounts
                        ?.map((account) => account.nickname || account.name || account.assumerole_account_id)
                        ?.join(', ')
                  },
                  {
                    title: 'Regions',
                    dataIndex: 'action.regionNames',
                    render: (regionNames) => regionNames?.join(', ')
                  }
                ]}
                pagination={{
                  pageSize: childTasks?.length,
                  hideOnSinglePage: true
                }}
                expandedRowRender={(childTask: any) => {
                  return <JSONTree data={childTask.data} depth={10} />;
                }}
              />
              <Collapse style={{ marginBottom: '16px' }} defaultActiveKey={['1']}>
                <Panel header="Raw Task Details" key="1">
                  <JSONTree data={rootTask} depth={10} />
                </Panel>
              </Collapse>
            </>
          )}
        </CenteredContainer>
      </NeoPage>
    </Root>
  );
}

export default TaskDetail;

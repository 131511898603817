import React, { useEffect, useState } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { FilterTypeProps } from '../FilterListing/FilterListing';
import { Button, Icon, Input } from 'antd';
import { Editor } from './ResourceTagsEditorStyles';

interface TagPair {
  key: string;
  value: string;
}

let debounceTimer;

function ResourceTagsEditor(props: FilterTypeProps) {
  const { values, filterType } = props,
    [softEdit, setSoftEdit] = useState<SoftEdit | null>({
      key: filterType.valueKey,
      value: values[filterType.valueKey]
    }),
    [localChanges, setLocalChanges] = useState<TagPair[] | null>(),
    debounceSoftEdit = (newArray) => {
      clearTimeout(debounceTimer);

      debounceTimer = setTimeout(() => {
        if (newArray.every((tagPair) => tagPair.key && tagPair.value)) {
          setSoftEdit({
            key: filterType.valueKey,
            value: newArray
          });
        }
      }, 500);
    },
    removeTag = (atIndex) => {
      const newArray = [...values[filterType.valueKey]];
      newArray.splice(atIndex, 1);

      setSoftEdit({
        key: filterType.valueKey,
        value: newArray
      });
    },
    addTag = () => {
      const newArray = [...values[filterType.valueKey]];

      newArray.push({
        key: '',
        value: ''
      });

      setLocalChanges(newArray);
    },
    updateValue = (index, property, value) => {
      const newArray = localChanges ? [...localChanges] : [];

      newArray[index][property] = value;

      setLocalChanges(newArray);
      debounceSoftEdit(newArray);
    },
    display = (
      <>
        <ul data-testid="tag-list">
          {values[filterType.valueKey]?.map((tagPair, i) => (
            <li data-testid="value-item" key={tagPair.key + i}>
              {tagPair.key} : {tagPair.value}
            </li>
          ))}
        </ul>
      </>
    ),
    editor = (
      <Editor>
        {localChanges?.map((tagPair, i) => (
          <div className="editor-row" key={i}>
            <Input placeholder="key" onChange={(e) => updateValue(i, 'key', e.target.value)} value={tagPair.key} />
            <span className="editor-row__spacer">:</span>
            <Input
              placeholder="value"
              onChange={(e) => updateValue(i, 'value', e.target.value)}
              value={tagPair.value}
            />
            <Button type="link" onClick={() => removeTag(i)}>
              <Icon type="delete" />
            </Button>
          </div>
        ))}
        <Button onClick={addTag}>
          <Icon type="plus" /> Add Resource Tag
        </Button>
      </Editor>
    );

  useEffect(() => {
    setLocalChanges(softEdit?.value);
  }, [softEdit]);

  return <FilterRow {...props} display={display} editor={editor} softEdit={softEdit} />;
}

export default ResourceTagsEditor;

import { ContextArgs } from 'app/sections/Ops/Editor/EditorUtils/EditorUtils';
import { FormRenderProps } from 'components/ui/Form/Form';
import React, { useEffect, useState } from 'react';
import { ConfirmationModalState, OnSelectTriggerFunc } from '../../../OpEditor';
import SelectTrigger from '../SelectTrigger';
import TriggerContents from './TriggerContents/TriggerContents';
import TriggerHeader from './TriggerHeader/TriggerHeader';

interface OpTriggerSectionProps {
  event: any;
  contextArgs: ContextArgs;
  onSelectTrigger: OnSelectTriggerFunc;
  isNewOp?: boolean;
  formRenderProps: FormRenderProps;
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
}

function OpTriggerSection(props: OpTriggerSectionProps) {
  const {
      contextArgs: { eventDefinition, isAllFindingsSelected, op },
      onSelectTrigger,
      formRenderProps,
      setConfirmationModal,
      event
    } = props,
    { values, setFieldValue } = formRenderProps,
    editingState = useState(false),
    [isEditingTrigger, setIsEditingTrigger] = editingState;

  useEffect(() => {
    setIsEditingTrigger(!eventDefinition && !isAllFindingsSelected);
  }, [eventDefinition, isAllFindingsSelected]);

  return (
    <>
      <TriggerHeader
        setConfirmationModal={setConfirmationModal}
        isAllFindingsSelected={isAllFindingsSelected}
        eventDefinition={eventDefinition}
        editingState={editingState}
        op={op}
      />
      {isEditingTrigger ? (
        <SelectTrigger onSelect={onSelectTrigger} />
      ) : (
        <div data-testid="trigger-contents">
          <TriggerContents
            values={values}
            setFieldValue={setFieldValue}
            eventDefinition={eventDefinition}
            eventFromEventConsole={event}
            isAllFindingsSelected={isAllFindingsSelected}
            setConfirmationModal={setConfirmationModal}
          />
        </div>
      )}
    </>
  );
}

export default OpTriggerSection;

import uniq from 'just-unique';
import { Component, ReactNode } from 'react';

import { FilterValues, FilterDefinition } from 'typings';

import QueryStringContext from 'components/util/QueryString/QueryStringContext';

interface UpdateSearchParams {
  sortBy?: string;
  sortDirection?: string;
  page?: string | number;
}

export interface PaginatorRenderProps {
  s: string; //alias for search
  search: string;
  pageNumber: number;
  pageSize: number;
  pageFrom: number;
  filters: FilterDefinition[];
  filterValues: FilterValues;
  fv: FilterValues; //alias for filterValues
  updateSearch: (search: string) => any | void;
  pushFilters: (key: string | FilterValues, value?: string | string[]) => any | void; //Add new filter to query string, won't duplicate key+value, but will duplicate keys
  replaceFilters: (key: string | FilterValues, value?: string | string[]) => any | void; //Replaces all existing query string paramters with what is provided
  updateFilter: (key: string, value?: string | string[]) => void;
  updatePage: (pageNumber: number) => any | void;
  nextPage: () => any | void; //alias to updatePage(pageNumber + 1)
  prevPage: () => any | void; //alias to updatePage(pageNumber - 1)
  urlSearchParams: URLSearchParams; //Current search string passed to URLSearchParams
  sortBy: string;
  updateSortBy: (sortBy: string) => any | void;

  updateSearchParams: (params: UpdateSearchParams) => void;

  sortDirection: string;
  updateSortDirection: (sortDirection: string) => any | void;
}

interface Props {
  filters?: any[];
  pageSize?: number;
  children: (props: PaginatorRenderProps) => ReactNode;
}

class Paginator extends Component<Props> {
  static contextType = QueryStringContext;

  prevPageNumber?: number;

  constructor(p: Props) {
    super(p);

    this.updateSearch = this.updateSearch.bind(this);
    this.updateFilter = this.updateFilter.bind(this);
    this.updatePage = this.updatePage.bind(this);
    this.updateSortBy = this.updateSortBy.bind(this);
    this.pushFilters = this.pushFilters.bind(this);
    this.replaceFilters = this.replaceFilters.bind(this);
    this.updateSortDirection = this.updateSortDirection.bind(this);
    this.updateSearchParams = this.updateSearchParams.bind(this);
  }

  componentDidMount() {
    const pageNumber = this.getPageNumber();

    this.prevPageNumber = pageNumber;
  }

  componentDidUpdate() {
    const pageNumber = this.getPageNumber();

    if (this.prevPageNumber !== pageNumber) {
      window.scrollTo(0, 0);
      this.prevPageNumber = pageNumber;
    }
  }

  getPageNumber() {
    const { urlSearchParams } = this.context;
    const pageNumber = parseInt(urlSearchParams.get('p'), 10) || 1;
    return pageNumber;
  }

  getSortBy() {
    const { urlSearchParams } = this.context;
    const sortBy = urlSearchParams.get('sort');
    return sortBy;
  }

  getSortDirection() {
    const { urlSearchParams } = this.context;
    const sortDirection = urlSearchParams.get('sortDir');
    return sortDirection;
  }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const { children, filters = [], pageSize = 20 } = this.props;
    const { urlSearchParams } = this.context;

    const search = urlSearchParams.getAll('s').join(' ');
    const pageNumber = this.getPageNumber();
    const pageFrom = (pageNumber - 1) * pageSize;

    const filterValues = filters.reduce((fv, filter) => {
      const { many, name, qsKey } = filter;
      const qsFilterValue = many ? urlSearchParams.getAll(qsKey) : urlSearchParams.get(qsKey);
      const filterValue = !Array.isArray(qsFilterValue) || qsFilterValue.length > 0 ? qsFilterValue : null;

      fv[name] = filterValue;

      return fv;
    }, {});

    const renderProps: PaginatorRenderProps = {
      s: search,
      search,
      filters: filters,
      filterValues,
      fv: filterValues,
      pageNumber,
      pageSize,
      pageFrom,
      updateSearch: this.updateSearch,
      pushFilters: this.pushFilters,
      replaceFilters: this.replaceFilters,
      updateFilter: this.updateFilter,
      updatePage: this.updatePage,
      nextPage: this.nextPage,
      prevPage: this.prevPage,
      sortBy: this.getSortBy(),
      updateSortBy: this.updateSortBy,
      urlSearchParams,
      sortDirection: this.getSortDirection(),
      updateSortDirection: this.updateSortDirection,
      updateSearchParams: this.updateSearchParams
    };

    return children(renderProps);
  }

  isQS(qs: string | FilterValues, value?: string | string[]) {
    if (typeof qs === 'string' && value) return false;
    return true;
  }

  cloneURLSearchParams() {
    const { urlSearchParams } = this.context;

    return new URLSearchParams(urlSearchParams.toString());
  }

  updateSearch(search: string) {
    const { replaceURLSearchParams } = this.context;

    const updatedSearchParams = this.cloneURLSearchParams();

    search === '' ? updatedSearchParams.delete('s') : updatedSearchParams.set('s', search);
    updatedSearchParams.delete('p');

    replaceURLSearchParams(updatedSearchParams);
  }

  getFilterValuesArray(filterValues: FilterValues, filterName: string): string[] {
    const pushedFilterValues = filterValues[filterName];
    if (!pushedFilterValues) return [];
    return Array.isArray(pushedFilterValues) ? pushedFilterValues : [pushedFilterValues];
  }

  updateFilter(filterName: string, filterValue?: null | string | string[]) {
    const { replaceURLSearchParams } = this.context;

    const updatedSearchParams = this.cloneURLSearchParams();

    updatedSearchParams.delete(filterName);

    const valueArray = Array.isArray(filterValue) ? filterValue : [filterValue];
    const uniqueFilterValues = uniq(valueArray);

    uniqueFilterValues.forEach((fv) => updatedSearchParams.append(filterName, fv)); // should not happen if !many?

    updatedSearchParams.delete('p');

    replaceURLSearchParams(updatedSearchParams);
    this.scrollTop();
  }

  pushFilters(filterName: string | FilterValues, filterValues?: string | string[]) {
    const currentFilters = this.props.filters || [];
    const { urlSearchParams, replaceURLSearchParams } = this.context;

    const updatedSearchParams = this.cloneURLSearchParams();

    if (typeof filterName === 'string' && filterValues) {
      updatedSearchParams.delete(filterName);

      const fv = Array.isArray(filterValues) ? filterValues : [filterValues, ...urlSearchParams.getAll(filterName)];
      const uniqueFilterValues = uniq(fv);

      uniqueFilterValues.forEach((filterValue) => updatedSearchParams.append(filterName, filterValue));
    } else {
      const fv = filterName as FilterValues;
      const filterKeys = Object.keys(fv);

      filterKeys.forEach((filterName) => {
        if (filterName === 's' && fv.s !== '') {
          updatedSearchParams.set(filterName, fv.s);
          return;
        }

        const filterValues = uniq([...this.getFilterValuesArray(fv, filterName)]);

        updatedSearchParams.delete(filterName);

        filterValues.forEach((filterValue) => {
          updatedSearchParams.append(filterName, filterValue);
        });
      });
    }

    currentFilters.forEach((filter: FilterDefinition) => {
      if (!filter.many) {
        const filterValueArray = updatedSearchParams.getAll(filter.qsKey);
        const lastFilterValue = filterValueArray[filterValueArray.length - 1];
        if (lastFilterValue) {
          updatedSearchParams.set(filter.qsKey, lastFilterValue);
        }
      }
    });

    updatedSearchParams.delete('p');

    replaceURLSearchParams(updatedSearchParams);
    this.scrollTop();
  }

  replaceFilters(filterName: string | FilterValues, filterValues?: string | string[]) {
    const { urlSearchParams, replaceURLSearchParams } = this.context;

    const updatedSearchParams = new URLSearchParams();

    if (!filterValues) {
      replaceURLSearchParams(updatedSearchParams);
      this.scrollTop();
      return;
    }
    //Preserve search on filter change
    updatedSearchParams.set('s', urlSearchParams.getAll('s').join(' '));

    if (typeof filterName === 'string' && filterValues) {
      const fv = Array.isArray(filterValues) ? filterValues : [filterValues];

      fv.forEach((filterValue) => updatedSearchParams.append(filterName, filterValue));
    } else {
      const fv = filterName as FilterValues;
      const filterKeys = Object.keys(fv);

      filterKeys.forEach((filterName) => {
        if (filterName === 's') {
          updatedSearchParams.set(filterName, fv.s);
          return;
        }

        const filterValues = this.getFilterValuesArray(fv, filterName);

        filterValues.forEach((filterValue) => {
          updatedSearchParams.append(filterName, filterValue);
        });
      });
    }

    replaceURLSearchParams(updatedSearchParams);
    this.scrollTop();
  }

  updateSortBy(sortBy: string) {
    const { replaceURLSearchParams } = this.context;

    const updatedSearchParams = this.cloneURLSearchParams();

    updatedSearchParams.set('sort', sortBy);

    replaceURLSearchParams(updatedSearchParams);
    this.scrollTop();
  }

  updateSortDirection(sortDirection: string) {
    const { replaceURLSearchParams } = this.context;

    const updatedSearchParams = this.cloneURLSearchParams();

    updatedSearchParams.set('sortDir', sortDirection);

    replaceURLSearchParams(updatedSearchParams);
    this.scrollTop();
  }

  updatePage(pageNumber: number) {
    const { replaceURLSearchParams } = this.context;

    const updatedSearchParams = this.cloneURLSearchParams();

    updatedSearchParams.set('p', pageNumber.toString());

    replaceURLSearchParams(updatedSearchParams);
    this.scrollTop();
  }

  updateSearchParams(params: UpdateSearchParams) {
    const { sortBy, sortDirection, page } = params;
    const updatedSearchParams = this.cloneURLSearchParams();
    const { replaceURLSearchParams } = this.context;

    if (sortBy) updatedSearchParams.set('sort', sortBy);
    if (sortDirection) updatedSearchParams.set('sortDir', sortDirection);
    if (page) updatedSearchParams.set('p', page.toString());

    replaceURLSearchParams(updatedSearchParams);
    this.scrollTop();
  }

  nextPage() {
    return this.updatePage(this.getPageNumber() + 1);
  }

  prevPage() {
    return this.updatePage(Math.max(this.getPageNumber() - 1, 1));
  }
}

interface QueryResultPagedData<TData> {
  [key: string]: {
    total: number;
    page: number;
    per_page: number;
    items: TData[];
  };
}

interface PageData<TData> {
  total: number;
  pageNumber: number;
  pageSize: number;
  items: TData[];
}

const DEFAULT_PAGE_DATA = {
  total: 0,
  pageNumber: 0,
  pageSize: 25,
  items: []
};

export function parsePageData(data: QueryResultPagedData<any>, pageKey?: string): PageData<any> {
  if (!data) return { ...DEFAULT_PAGE_DATA };

  const keys = Object.keys(data);

  if (pageKey && keys.indexOf(pageKey) === -1) {
    return { ...DEFAULT_PAGE_DATA };
  }

  const keyIndex = pageKey && keys.indexOf(pageKey) > 0 ? keys.indexOf(pageKey) : 0;

  if (!keys || !keys[keyIndex]) return { ...DEFAULT_PAGE_DATA };

  const pagedData = data[keys[keyIndex]];

  if (!pagedData) return { ...DEFAULT_PAGE_DATA };

  const { items, per_page, page, total } = pagedData;

  if (!items || !per_page || !page || !total) {
    return { ...DEFAULT_PAGE_DATA };
  }

  return {
    items,
    pageSize: per_page,
    pageNumber: page,
    total
  };
}

export default Paginator;

import React from 'react';
import { Tooltip, Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { TooltipPlacement } from 'antd/lib/tooltip';

interface ButtonWithDisabledTooltipProps extends ButtonProps {
  disabledMessage: string;
  tooltipPlacement?: TooltipPlacement;
}

function ButtonWithDisabledTooltip(props: ButtonWithDisabledTooltipProps) {
  const { disabledMessage, disabled, children, tooltipPlacement = 'top', ...buttonProps } = props;

  return disabled ? (
    <Tooltip title={disabledMessage} placement={tooltipPlacement}>
      <Button {...buttonProps} disabled>
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button {...buttonProps}>{children}</Button>
  );
}

export default ButtonWithDisabledTooltip;

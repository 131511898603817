import React, { useContext } from 'react';
import AuthContext from 'components/app/Auth/AuthContext';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';

import { NewGuardrail, Maybe, NewGuardrailInput } from 'typings';
import DopeIcon from 'components/ui/DopeIcon';
import { Button } from 'antd';

const BUTTON_TEXT = 'Create';

const SAVE_GUARDRAIL = gql`
  mutation CreateGuardrail($guardrail: GuardrailInput!) {
    saveGuardrail(guardrail: $guardrail) {
      id
      isEnabled
      createdBy
      name
      description
      trigger {
        eventSource
        eventType
        configuration
      }
      filtersConfiguration
      staticConfiguration
      dynamicConfigurations {
        id
        key
        node {
          id
          value
          key
          entityType
          entityId
        }
      }
    }
  }
`;

interface CreateGuardrailButtonProps {
  onNewGuardrailCreated: (guardrail: NewGuardrail) => void;
  buttonText?: Maybe<string>;
  guardrailInput?: Maybe<NewGuardrailInput>;
}

function CreateGuardrailButton(props: CreateGuardrailButtonProps) {
  const authContext = useContext(AuthContext) || null;
  const user = (authContext && authContext.me) || null;
  const {
    onNewGuardrailCreated,
    guardrailInput = { projectId: user?.client?.root_project_id },
    buttonText = BUTTON_TEXT
  } = props;

  return (
    <Mutation mutation={SAVE_GUARDRAIL}>
      {(saveGuardrail, { loading }) => {
        return (
          <Button
            loading={loading}
            onClick={async () => {
              const result = await saveGuardrail({ variables: { guardrail: guardrailInput } });

              const newGuardrail = result.data.saveGuardrail;

              onNewGuardrailCreated(newGuardrail);
            }}
            className="btn-with-icon"
          >
            <DopeIcon name="ADD" />
            {buttonText}
          </Button>
        );
      }}
    </Mutation>
  );
}

export default CreateGuardrailButton;

import React from 'react';
import SectionHeading from 'designSystem/SectionHeading/SectionHeading';
import { PlayCircleFilled } from '@ant-design/icons';
import { OpsSection } from '../Ops';

function Automation() {
  return (
    <div>
      <SectionHeading title="Automation" icon={<PlayCircleFilled />} />
      <OpsSection />
    </div>
  );
}

export default Automation;

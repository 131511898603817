import gql from 'graphql-tag';

const AWS_TAG_LIST_QUERY = gql`
  query AWSTagList {
    tags {
      key
      value
    }
  }
`;

export default AWS_TAG_LIST_QUERY;

import debounce from 'just-debounce-it';
import styled from 'styled-components';
import React, { ReactNode, RefObject, KeyboardEvent } from 'react';
import { Input, Icon, Select, Dropdown, Menu } from 'antd';
import { Query } from 'react-apollo';

import { FilterDefinition, size, FilterOption } from 'typings';
import { ClickParam } from 'antd/lib/menu';

const Root = styled.div`
  position: relative;
  flex: 1;
`;

interface Props {
  addonBefore?: ReactNode;
  addonAfter?: ReactNode;
  search: string;
  debounceDuration?: number; // ms
  onSearchChange: (searchString: string) => any;
  placeholder?: string;
  classes?: {
    root?: string;
    input?: string;
  };
  size?: size;
  rounded?: boolean;
  dark?: boolean;
  filters?: FilterDefinition[];
  minWidth?: number;
}

interface State {
  searchString: string;
  filterSearchString?: string;
  selectionStart?: number;
  selectionEnd?: number;
  cursorPosition?: number;
  cursorAtEnd: boolean;
  isFiltering: boolean;
  matchedFilterName?: string;
  isFocused: boolean;
  matchedAt?: number;
  resetSearchProp: boolean;
}

class SearchFilter extends React.Component<Props, State> {
  onSearchChangeDebounced: () => any;
  isWaitingForDebounce: boolean = false;
  triggerRegex?: RegExp;
  inputRef: RefObject<any>;
  selectRef: RefObject<any>;

  constructor(props: Props) {
    super(props);

    const AUTOCOMPLETE_WAIT_DURATION = props.debounceDuration || 500;

    this.state = {
      isFocused: false,
      selectionStart: 0,
      selectionEnd: 0,
      cursorAtEnd: false,
      cursorPosition: undefined,
      isFiltering: false,
      matchedFilterName: undefined,
      matchedAt: undefined,
      ...this.mapPropsToState(this.props),
      resetSearchProp: false
    };

    this.inputRef = React.createRef();
    this.selectRef = React.createRef();

    this.onSearchChangeDebounced = debounce(this.onSearchChange, AUTOCOMPLETE_WAIT_DURATION);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.addFilter = this.addFilter.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount() {
    const newState = this.mapPropsToState(this.props);

    if (newState.searchString !== this.state.searchString) {
      this.setState(newState);
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: State) {
    const currentFilters = this.props.filters;
    const previousFilters = prevProps.filters;

    if (currentFilters !== previousFilters) {
      this.setTriggerRegex();
    }

    const currentSearchProp = this.props.search;
    const previousSearchProp = prevProps.search;

    if (currentSearchProp !== previousSearchProp || this.state.resetSearchProp) {
      const newState = this.mapPropsToState(this.props);

      if (newState.searchString !== this.state.searchString) {
        await this.setState(newState);
        this.onSearchChange();
      }
    }

    const { searchString, matchedAt, isFiltering, cursorPosition } = this.state;
    const currentSearchStringState = searchString;
    const previousSearchStringState = prevState.searchString;

    if (currentSearchStringState !== previousSearchStringState && isFiltering && matchedAt) {
      this.setState({
        filterSearchString: currentSearchStringState.substr(matchedAt, cursorPosition).trim()
      });
    }
  }

  mapPropsToState({ search }: Props) {
    return {
      searchString: search || '',
      resetSearchProp: false
    };
  }

  getTriggerRegex() {
    const { filters } = this.props;
    if (!filters) return;
    if (this.triggerRegex) return this.triggerRegex;
    return this.setTriggerRegex();
  }

  setTriggerRegex() {
    const { filters = [] } = this.props;
    const triggers = filters.map((filter) => filter.name);

    this.triggerRegex = triggers.length !== 0 ? new RegExp(`(${triggers.join('|')}):\\s?$`) : undefined;

    return this.triggerRegex;
  }

  doesTriggerMatch(value: string) {
    const { cursorPosition, cursorAtEnd } = this.state;
    const match = this.getTriggerRegex();

    if (!match) return false;

    return match.test(cursorAtEnd ? value : value.slice(0, cursorPosition));
  }

  getTriggerMatch(value: string) {
    const { cursorPosition, cursorAtEnd } = this.state;
    const match = this.getTriggerRegex();

    if (!match) return undefined;

    const result = match.exec(cursorAtEnd ? value : value.slice(0, cursorPosition));
    return result && result[1] ? result[1] : undefined;
  }

  handleClick(e: any) {
    const { value, selectionStart, selectionEnd } = e.target;

    this.updateInputState(value, selectionStart, selectionEnd, {
      isFocused: true
    });
  }

  handleChange(e: any) {
    const { value = '', selectionStart = 0, selectionEnd = 0 } = e.target;
    this.updateInputState(value, selectionStart, selectionEnd, { isFocused: true });
  }

  async handleSelect(value: any) {
    const { searchString, matchedAt, cursorPosition } = this.state;

    let newSearchString = searchString;

    if (matchedAt) {
      newSearchString = `${searchString.substring(0, matchedAt)} ${value} ${searchString.substring(
        cursorPosition || searchString.length
      )}`;
    }

    await this.updateInputState(newSearchString, newSearchString.length, newSearchString.length, {
      isFocused: true,
      isFiltering: false,
      matchedFilterName: undefined,
      resetSearchProp: true
    });

    this.onSearchChange(newSearchString);
  }

  async addFilter(menuItem: ClickParam) {
    const { filters = [] } = this.props;
    const { searchString } = this.state;

    const filter = filters.find((filter) => filter.qsKey === menuItem.key);

    if (!filter) return;

    const { length } = searchString;

    const searchIsEmptyOrHasSpaceAtEnd = searchString === '' || searchString[length - 1] === ' ';

    const searchWithSpaceAtEnd = searchIsEmptyOrHasSpaceAtEnd ? searchString : `${searchString} `;

    const newSearchString = `${searchWithSpaceAtEnd}${filter.name}:`;

    await this.updateInputState(newSearchString, newSearchString.length, newSearchString.length, {
      isFocused: true
    });

    if (this.inputRef.current) this.inputRef.current.focus();
  }

  handleFocus(e: any) {
    const { value = '', selectionStart = 0, selectionEnd = 0 } = e.target;
    this.updateInputState(value, selectionStart, selectionEnd, {
      isFocused: true
    });
  }

  handleBlur(e: any) {
    const { value = '', selectionStart = 0, selectionEnd = 0 } = e.target;
    this.updateInputState(value, selectionStart, selectionEnd, {
      isFocused: false
    });
  }

  async updateInputState(
    value: string = '',
    selectionStart: number = 0,
    selectionEnd: number = 0,
    extraState: any = {}
  ) {
    const { isFiltering, matchedFilterName, matchedAt } = this.state;

    const cursorPosition = selectionEnd === selectionStart ? selectionEnd : undefined;
    const cursorAtEnd = cursorPosition ? cursorPosition === value.length : false;

    const doesTriggerMatch = this.doesTriggerMatch(value);

    await this.setState({
      searchString: value,
      isFiltering: isFiltering || doesTriggerMatch,
      matchedFilterName: isFiltering && matchedFilterName ? matchedFilterName : this.getTriggerMatch(value),
      matchedAt: !isFiltering && doesTriggerMatch ? selectionEnd : matchedAt,
      selectionStart,
      selectionEnd,
      cursorPosition,
      cursorAtEnd: extraState.isFocused === false ? false : cursorAtEnd,
      ...extraState
    });
  }

  handleEnter(e: any) {
    // add to stop from submitting parent form.
    e.preventDefault();

    const { isFiltering = false, matchedFilterName } = this.state;
    //allow dates to fire off of enter after the user enters a valid a datetime
    //todo: add date editor to make easy for user
    const isFilterDate =
      matchedFilterName === 'updatedAtStart' ||
      matchedFilterName === 'updatedAtEnd' ||
      matchedFilterName === 'createdAtStart' ||
      matchedFilterName === 'createdAtEnd'
        ? true
        : false;
    if (!isFiltering || isFilterDate) this.onSearchChange();
  }

  // debounced function to fire after user stops typing for alotted time
  onSearchChange(search?: string) {
    const { searchString } = this.state;

    this.props.onSearchChange(search || searchString);
  }

  handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    const { isFiltering, matchedAt } = this.state;
    const { keyCode } = e;

    if (keyCode === 13 && isFiltering) {
      e.preventDefault();
    }
    if (keyCode === 27) {
      this.setState({
        isFiltering: false,
        matchedFilterName: undefined
      });
    }
    if (keyCode === 8 && matchedAt) {
      const { value, selectionEnd } = e.currentTarget;
      const cursorEnd = selectionEnd || value.length;

      if (matchedAt && cursorEnd <= matchedAt) {
        this.setState({
          isFiltering: false,
          matchedFilterName: undefined
        });
      }
    }
    return (
      this.selectRef &&
      this.selectRef.current &&
      this.selectRef.current.rcSelect &&
      this.selectRef.current.rcSelect.onKeyDown(e)
    );
  }

  render() {
    const { searchString = '', isFiltering = false, matchedFilterName } = this.state;

    const {
      placeholder,
      classes = {},
      size = 'default',
      rounded = false,
      dark = false,
      addonBefore,
      addonAfter,
      filters = [],
      minWidth = 300
    } = this.props;

    let rootClasses = 'search';
    if (classes.root) rootClasses += ` ${classes.root}`;

    let inputClasses = classes.input || '';
    if (dark) inputClasses += ' dark';

    const filter = filters.find((filter) => filter.name === matchedFilterName);

    return (
      <Root className={rootClasses}>
        <Input
          style={{ minWidth: `${minWidth}px`, borderRadius: rounded ? '3px' : '0px' }}
          placeholder={placeholder || 'Search'}
          prefix={<Icon type="search" />}
          value={searchString}
          onChange={this.handleChange}
          onPressEnter={this.handleEnter}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          size={size}
          className={inputClasses}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          suffix={this.renderFilterDropdown()}
          onClick={this.handleClick}
          ref={this.inputRef}
        />

        {isFiltering && filter && filter.options && this.renderSelectFromOptions(filter.options)}

        {isFiltering && filter && filter.query && (
          <Query query={filter.query} skip={!filter.query}>
            {(results) => {
              const { error, loading, data } = results;
              if (loading || error || !data) return null;

              const { getListFromData } = filter;

              const list = (getListFromData ? getListFromData(data) : data) || [];

              return this.renderSelectFromData(list);
            }}
          </Query>
        )}
      </Root>
    );
  }

  filterOptions(options: any[]) {
    const { filters = [] } = this.props;
    const { filterSearchString, matchedFilterName } = this.state;
    if (!matchedFilterName) return options;
    if (!filterSearchString) return options;
    const filter = filters.find((filter) => filter.name === matchedFilterName);

    if (!filter) return options;
    if (!filterSearchString) return options;

    const filterSearchStringTest = new RegExp(filterSearchString, 'i');

    return options.filter((option) => filterSearchStringTest.test(option.label));
  }

  renderSelectFromData(list: any[]) {
    const { matchedFilterName, isFiltering, isFocused } = this.state;
    const { filters = [] } = this.props;

    if (!isFiltering || !matchedFilterName) return null;

    const filter = filters.find((filter) => filter.name === matchedFilterName);

    if (!filter) return null;

    const { transformDataToOption } = filter;

    if (!transformDataToOption) return null;

    const options = this.filterOptions(list.map(transformDataToOption));

    return (
      <Select
        ref={this.selectRef}
        style={{ zIndex: -1, position: 'absolute', top: 0, right: 0, left: 0, visibility: 'hidden' }}
        open={isFiltering && filter && isFocused ? true : false}
        size="large"
        onSelect={this.handleSelect}
      >
        {options.map((option) => {
          return <Select.Option key={option.key}>{option.label}</Select.Option>;
        })}
      </Select>
    );
  }

  renderSelectFromOptions(options: FilterOption[]) {
    const { matchedFilterName, isFiltering, isFocused } = this.state;

    const { filters = [] } = this.props;

    if (!isFiltering || !matchedFilterName) return null;

    const filter = filters.find((filter) => filter.name === matchedFilterName);

    if (!filter) return null;

    return (
      <Select
        ref={this.selectRef}
        style={{ zIndex: -1, position: 'absolute', top: 0, right: 0, left: 0, visibility: 'hidden' }}
        open={isFiltering && filter && isFocused ? true : false}
        size="large"
        onSelect={this.handleSelect}
      >
        {options.map((item) => (
          <Select.Option key={item.key}>{item.label}</Select.Option>
        ))}
      </Select>
    );
  }

  renderFilterDropdown() {
    const { filters = [] } = this.props;
    if (filters.length === 0) return null;

    return (
      <Dropdown
        overlay={
          <Menu onClick={this.addFilter}>
            {filters.map((filter) => (
              <Menu.Item key={filter.qsKey}>{filter.label}</Menu.Item>
            ))}
          </Menu>
        }
        placement="bottomRight"
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          Add filter <Icon type="caret-down" />
        </a>
      </Dropdown>
    );
  }
}

export default SearchFilter;

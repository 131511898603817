import capitalize from 'just-capitalize';
import React, { ReactNode, ChangeEvent, FocusEvent } from 'react';
import { FormikConsumer, FormikContext, getIn } from 'formik';
import * as Yup from 'yup';
import { Form, Tooltip } from 'antd';
import styled from 'styled-components';
import DopeIcon from '../DopeIcon';

export interface RenderProps {
  name: string;
  help?: string;
  touched: boolean;
  value: any;
  error?: string;
  label: string;
  hasFeedback: boolean;
  handleChange: (event: ChangeEvent<any> | any) => any | void;
  handleBlur: (event?: FocusEvent | any) => any | void;
  required: boolean;
  formikContext: FormikContext<any>;
}

interface Props {
  name: string;
  label?: string;
  hideLabel?: boolean; // didn't want to touch label before asking..
  extra?: string;
  children: (fieldProps: RenderProps) => ReactNode;
  className?: string;
  wrapInFormItem?: boolean; // default to true
  labelToolTipText?: string;
}

export const isFieldRequired = (formik: FormikContext<any>, name: string) => {
  const { validationSchema, values } = formik;

  if (!validationSchema) return false;

  let fieldValidationSchema;
  try {
    // `yup.reach` will retrieve a nested schema based on the provided path.
    fieldValidationSchema = Yup.reach(validationSchema, name, values);
  } catch (err) {
    return false;
  }

  const resolvedSchema = fieldValidationSchema ? fieldValidationSchema.resolve({ value: values }) : false;
  const tests = resolvedSchema ? resolvedSchema.describe().tests : false;

  if (!tests || tests.length === 0) return false;

  return !!tests.find((t) => t.name === 'required');
};

const LabelRoot = styled.div`
  display: inline-block;

  .label-flex {
    display: flex;
  }

  .label-text {
    margin-right: 4px;
  }

  .label-tooltip {
  }
`;

function FormField(props: Props) {
  const { className, children, name, extra, hideLabel = false, wrapInFormItem = true, labelToolTipText } = props;

  return (
    <FormikConsumer>
      {(formikContext: FormikContext<any>) => {
        const { setFieldValue, setFieldTouched } = formikContext;
        const touched = getIn(formikContext.touched, name) === true;
        const value = getIn(formikContext.values, name);
        const error = getIn(formikContext.errors, name) || undefined;

        const label = !props.label ? capitalize(name) : props.label;
        const hasFeedback = touched && error && typeof error === 'string' ? true : false;
        const help = hasFeedback ? error : undefined;
        const validateStatus = touched && error ? 'error' : undefined;

        const handleChange = (event: ChangeEvent<any> | any) => {
          setFieldValue(name, event && event.target ? event.target.value : event); //Support for antd Inputs and Selects (different APIs)
        };

        const handleBlur = (event?: FocusEvent) => {
          setFieldTouched(name, true);
        };

        const isRequired = isFieldRequired(formikContext, name);

        const renderProps = {
          name,
          touched,
          value,
          error,
          label,
          hasFeedback,
          handleChange,
          handleBlur,
          required: isRequired,
          formikContext
        };

        const labelJSX = (
          <LabelRoot>
            <div className="label-flex">
              <div className="label-text">{label}</div>
              {labelToolTipText && (
                <div className="label-tooltip">
                  <Tooltip title={labelToolTipText} placement="right">
                    <div className="tooltip-icon-wrap">
                      <DopeIcon name="QUESTION" size="12" />
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </LabelRoot>
        );

        return wrapInFormItem ? (
          <Form.Item
            required={isRequired}
            label={!hideLabel && labelJSX}
            help={help}
            validateStatus={validateStatus}
            extra={extra}
            className={className}
            colon={false}
          >
            {children(renderProps)}
          </Form.Item>
        ) : (
          children(renderProps)
        );
      }}
    </FormikConsumer>
  );
}

export default FormField;

import React from 'react';
import styled from 'styled-components';
import { OperationVariables, useMutation } from 'react-apollo';
import * as Yup from 'yup';
import { Button, Row, Col, message, Input, Switch } from 'antd';

import { UserGroup } from 'typings';
import ErrorAlert from 'components/ui/ErrorAlert';

import { CREATE_USER_GROUP_MUTATION, UPDATE_USER_GROUP_MUTATION } from '../../../gql';

import ProjectSelect from 'components/project/ProjectSelect';
import CloseButton from 'components/buttons/CloseButton';

import DeleteGroupButton from './DeleteGroupButton';
import { FormField, Form } from 'components/ui/Form';
import { FieldArray } from 'formik';
import RoleSelect from './RoleSelect';
import DopeIcon from 'components/ui/DopeIcon';

const Root = styled.div`
  .form-header {
    display: flex;
    justify-content: space-between;
  }

  .role-list {
    .role-list-item {
      overflow: hidden;
      padding: 20px 20px 0px;
      background-color: ${(p) => p.theme.grey100};
      margin-bottom: 20px;

      .role-list-item-title {
        display: flex;
        justify-content: space-between;

        .role-list-item-remove {
          border: 0;
          padding: 0;
          height: auto;
        }
      }

      .role-list-item-scope {
        border: 1px solid #ccc;
        padding: 20px 20px 0px;
      }
    }

    .role-list-actions {
      text-align: center;
    }
  }

  .form-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    .form-footer-right {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      > * {
        margin-left: 16px;
      }
    }
  }
`;

interface FormInput {
  name: string;
  user_ids: string[];
  roles: FormInputRoles[];
}

interface FormInputRoles {
  role_id: string;
  project_id?: string;
  scoped?: boolean;
}

const formSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  roles: Yup.array().of(
    Yup.object().shape({
      role_id: Yup.string().required('Required'),
      project_id: Yup.string().nullable(),
      scoped: Yup.boolean()
    })
  )
});

export interface Props {
  userGroup?: UserGroup;
  onCancel: () => any;
  onFinish: (userGroupId?: string | undefined) => any;
}

function makeEmptyRole(): FormInputRoles {
  return {
    role_id: ''
  };
}

function UserGroupForm(props: Props) {
  const { userGroup, onCancel, onFinish } = props;

  const id = userGroup && userGroup.id ? userGroup.id : undefined;
  const creating = id ? false : true;

  const userGroupRoles = userGroup && userGroup.roles && userGroup.roles.length > 0 ? userGroup.roles : undefined;

  const roles = userGroupRoles
    ? userGroupRoles.map((userGroupRole) => ({
        role_id: userGroupRole.role_id,
        project_id: userGroupRole.project_id,
        scoped: userGroupRole.project_id ? true : false
      }))
    : [makeEmptyRole()];

  const initialValues = {
    name: (userGroup && userGroup.name) || '',
    roles: roles
  };

  const [mutateUserGroup, { error }] = useMutation(creating ? CREATE_USER_GROUP_MUTATION : UPDATE_USER_GROUP_MUTATION);

  return (
    <Root>
      <Form
        validationSchema={formSchema}
        initialValues={initialValues}
        onSubmit={async (values: Partial<FormInput>, actions: any) => {
          const { name, roles } = values;

          let variables = {
            input: {
              name,
              roles
            }
          } as OperationVariables;

          if (userGroup && userGroup.id) {
            variables.userGroupId = userGroup.id;
          }

          try {
            const result = await mutateUserGroup({
              variables
            });

            const userGroupId = result.data?.createUserGroup?.id || result.data?.updateUserGroup?.id;

            message.success(`Group ${creating ? 'created' : 'updated'} successfully.`, 5);
            onFinish(userGroupId);
          } catch {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, canSubmit, errors }) => {
          return (
            <>
              <div className="form-header">
                <h2>{creating ? 'Create group' : 'Update group'}</h2>
                <CloseButton onClose={onCancel} />
              </div>

              {error && <ErrorAlert error={error} />}

              <Row type="flex" gutter={24}>
                <Col span={24}>
                  <FormField name="name">
                    {({ handleChange, handleBlur, name, value, required }) => (
                      <Input
                        onChange={handleChange}
                        name={name}
                        width="100%"
                        value={value}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        required={required || false}
                        size="large"
                      />
                    )}
                  </FormField>

                  <FieldArray
                    name="roles"
                    render={(arrayHelpers) => {
                      const { roles = [] } = values;

                      if (roles.length === 0) {
                        return null;
                      }

                      return (
                        <div className="role-list">
                          {roles.map((role, index) => {
                            return (
                              <div key={index} className="role-list-item">
                                <div className="role-list-item-title">
                                  <h3 className="role-list-item-title">Role #{index + 1}</h3>

                                  {roles.length !== 1 && (
                                    <button
                                      className="link-button role-list-item-remove"
                                      onClick={() => arrayHelpers.remove(index)}
                                      title="Remove role from user group"
                                    >
                                      <DopeIcon name="REMOVE" />
                                    </button>
                                  )}
                                </div>

                                <FormField name={`roles.${index}.role_id`} label="Role">
                                  {({ handleChange, handleBlur, value }) => (
                                    <RoleSelect value={value} onChange={handleChange} onBlur={handleBlur} />
                                  )}
                                </FormField>

                                <FormField name={`roles.${index}.scoped`} label="Scope">
                                  {({ handleChange, handleBlur, name, value }) => {
                                    const scoped = value;

                                    return (
                                      <div className="role-list-item-scope">
                                        <div>
                                          <Switch
                                            checked={value}
                                            onChange={(value) => {
                                              handleChange(value);
                                              handleBlur();
                                            }}
                                          />{' '}
                                          <span style={{ marginLeft: '5px' }}>
                                            Limit the group's access to selected projects
                                          </span>
                                        </div>

                                        <FormField name={`roles.${index}.project_id`} label="Project">
                                          {({ name, value, handleBlur, handleChange }) => {
                                            return (
                                              <ProjectSelect
                                                many={false}
                                                value={value}
                                                name={name}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                disabled={scoped === true ? false : true}
                                              />
                                            );
                                          }}
                                        </FormField>
                                      </div>
                                    );
                                  }}
                                </FormField>
                              </div>
                            );
                          })}

                          <div className="role-list-actions">
                            <Button onClick={() => arrayHelpers.push(makeEmptyRole())}>Add role</Button>
                          </div>
                        </div>
                      );
                    }}
                  />
                </Col>
              </Row>

              <div className="form-footer">
                <div>
                  {!creating && userGroup && <DeleteGroupButton onDelete={() => onFinish()} userGroup={userGroup} />}
                </div>

                <div className="form-footer-right">
                  <Button disabled={isSubmitting} onClick={onCancel}>
                    Cancel
                  </Button>

                  <Button disabled={!canSubmit} loading={isSubmitting} type="primary" htmlType="submit">
                    {creating ? 'Create' : 'Update'}
                  </Button>
                </div>
              </div>
            </>
          );
        }}
      </Form>
    </Root>
  );
}

export default UserGroupForm;

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export default function useOpPathname() {
  const location = useLocation(),
    [basePathname, setBasePathname] = useState('/ops');

  useEffect(() => {
    if (location.pathname.includes('alerts')) {
      setBasePathname('/alerts');
      return;
    }

    if (location.pathname.includes('automation')) {
      setBasePathname('/automation');
      return;
    }

    setBasePathname('/ops');
  }, [location]);

  return basePathname;
}

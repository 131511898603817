import React from 'react';

import DopeIcon from 'components/ui/DopeIcon';
import NeoPage, { TitleBar } from 'components/ui/NeoPage';
import QueryResult from 'components/util/QueryResult';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import VerticalTable from 'components/ui/Table/VerticalTable';
import DateTime from 'components/ui/DateTime';
import { AutomationActionResult, Issue, Op } from 'typings';
import { AUTOMATION_ACTION_RESULTS } from 'components/activity/AutomationActionResultsList';
import AutomationActivityDetail from './AutomationActivityDetail';

const Root = styled.div`
  border: 1px solid ${(p) => p.theme.grey300};
  margin-bottom: 20px;

  > * {
    border-bottom: 1px solid ${(p) => p.theme.grey300};
  }

  .vt-label-column {
    width: 175px;
    padding-right: 16px;
    text-align: right;
  }

  .table-wrap {
    padding: 12px 0;
  }

  .ui-label {
    margin: 0px 6px 0px 0px;
  }

  .labels-card-section {
    margin-bottom: 0px;
  }

  .labels-div {
    margin-bottom: 16px;
  }

  .input-schema-pre {
    white-space: pre-wrap;
  }

  .array-item-list {
    margin-left: 16px;
  }

  .page-header {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .centered-container {
    padding-bottom: 16px;
    padding-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .function-results {
    margin-top: 16px;
  }

  .action-result-status {
    &.success {
      color: ${(p) => p.theme.success};
    }
    &.error {
      color: ${(p) => p.theme.error};
    }
  }

  .guardrail-name {
    &.archived {
      span {
        color: ${(p) => p.theme.grey300};
      }
    }
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

function ActivityItem(props: Props) {
  const actionResultId = props.match.params.id;

  return (
    <Query query={AUTOMATION_ACTION_RESULTS} variables={{ id: [actionResultId] }}>
      {({ data, loading, queryError }) => (
        <QueryResult
          data={data}
          loading={loading}
          loadingMessage="Loading Action Result"
          error={queryError}
          expectSingleNode={true}
          parseNodes={(data) => data.automationActionResults.nodes}
          entityName="Action Result"
        >
          {(actionResult) => {
            let rows: any[] = [
              {
                key: 'createdAt',
                label: 'Date/Time',
                dataIndex: 'createdAt',
                render: (createdAt) => {
                  return createdAt ? <DateTime dateTime={createdAt} /> : '--';
                }
              },
              {
                title: 'Status',
                key: 'status',
                label: 'Status',
                dataIndex: 'status',
                render: (status) => <div className={`action-result-status ${status.toLowerCase()}`}>{status}</div>
              },
              {
                title: 'Trigger',
                key: 'triggerType',
                label: 'Trigger',
                dataIndex: 'triggerType',
                render: (triggerType, actionResult: AutomationActionResult) => {
                  const { guardrail, issue, invokedByUsername } = actionResult;
                  const { assessment } = issue || {};
                  if (triggerType === 'GUARDRAIL' && guardrail) {
                    return (
                      <div className={`guardrail-name ${guardrail.archivedAt ? 'archived' : ''}`}>
                        Guardrail: {guardrail.archivedAt && '(ARCHIVED) - '}
                        <Link to={`/guardrails/details/${guardrail.id}`}>{guardrail.name || 'Untitled'}</Link>
                      </div>
                    );
                  } else if ((triggerType === 'USER' && assessment) || assessment) {
                    return (
                      <>
                        {invokedByUsername || 'User'} from Detector:{' '}
                        <Link to={`/detectors/${assessment.id}`}>{assessment.name || 'Untitled'}</Link>
                      </>
                    );
                  } else if (triggerType === 'USER' && invokedByUsername) {
                    return `User: ${invokedByUsername}`;
                  } else if (triggerType) {
                    return triggerType;
                  } else {
                    return <>--</>;
                  }
                }
              },
              {
                title: 'Op',
                key: 'op',
                label: 'Op',
                dataIndex: 'op',
                render: (op: Op) => {
                  return op ? (
                    <div className={`op-name ${op.archivedAt ? 'archived' : ''}`}>
                      {op.archivedAt && '(ARCHIVED) - '}
                      <span>{op.name || 'Untitled'}</span>
                    </div>
                  ) : (
                    '--'
                  );
                }
              },
              {
                title: 'Source',
                key: 'event',
                label: 'Source',
                dataIndex: 'event',
                render: (event, actionResult) => (
                  <>
                    {event?.id && event?.eventData?.context?.eventDefinitionName ? (
                      <Link
                        to={{
                          pathname: `/event/${event.id}`,
                          state: { event: event }
                        }}
                      >
                        {event.eventData.context.eventDefinitionName}
                      </Link>
                    ) : actionResult.issueId ? (
                      <Link to={`/issue/${actionResult.issueId}`}>Source Issue</Link>
                    ) : event?.id ? (
                      <Link to={`/event/${event.id}`}>Unrecognized Event</Link>
                    ) : (
                      '--'
                    )}
                  </>
                )
              },
              {
                title: 'Action',
                key: 'action',
                label: 'Action',
                dataIndex: 'function',
                render: (func, actionResult) => {
                  return (func && func.name) || '--';
                }
              },
              {
                title: 'Issue',
                key: 'issue',
                label: 'Issue',
                dataIndex: 'issue',
                render: (issue: Issue) => {
                  if (!issue) return 'N/A';
                  return <Link to={`/issue/${issue.id}`}>{issue.name}</Link>;
                }
              }
            ];

            // Conditionally add external service details from alert actions
            actionResult.functionInput?.externalService &&
              rows.push(
                {
                  title: 'External Service',
                  key: 'externalService',
                  label: 'External Service',
                  dataIndex: 'functionInput',
                  render: (functionInput) => {
                    return (
                      <>
                        {functionInput?.externalService === 'slack' && (
                          <>
                            <DopeIcon
                              className="icon"
                              name="SLACK"
                              size={20}
                              style={{ paddingTop: '5px' }}
                              title="Slack"
                            />{' '}
                            Slack
                          </>
                        )}
                        {functionInput?.externalService === 'ms-teams' && (
                          <>
                            <DopeIcon
                              className="icon"
                              name="MICROSOFT_TEAMS"
                              size={20}
                              style={{ paddingTop: '5px' }}
                              title="Microsoft Teams"
                            />{' '}
                            Microsoft Teams
                          </>
                        )}
                      </>
                    );
                  }
                },
                {
                  title: 'Service Username',
                  key: 'initiatedBy',
                  label: 'Service Username',
                  dataIndex: 'functionInput',
                  render: (functionInput) => {
                    return (
                      <>
                        {functionInput?.externalService === 'slack' && <>{functionInput?.externalUserName}</>}
                        {functionInput?.externalService === 'ms-teams' && <>N/A</>}
                      </>
                    );
                  }
                },
                {
                  title: 'Service User ID',
                  key: 'initiatedByUserId',
                  label: 'Service User ID',
                  dataIndex: 'functionInput',
                  render: (functionInput) => {
                    return functionInput?.externalUserId ? functionInput?.externalUserId : 'N/A';
                  }
                }
              );

            return (
              <Root>
                <NeoPage
                  titleBar={
                    <TitleBar
                      icon={<DopeIcon name="ACTIVITY" size={20} />}
                      sectionTitle="Activity"
                      sectionTitleLinkTo="/activity"
                      backLinkTo="/activity"
                      backLinkTooltipText="Back to All Activity"
                    />
                  }
                >
                  <div className="table-wrap">
                    <VerticalTable dataSource={actionResult} rows={rows} classes={{ labelCol: 'vt-label-column' }} />
                  </div>
                  <AutomationActivityDetail actionResult={actionResult} />
                </NeoPage>
              </Root>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

export default ActivityItem;

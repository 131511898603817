import React from 'react';
import DopeIcon from 'components/ui/DopeIcon';
import { ClientProject, Project } from 'typings';
import { ProjectChipStyles } from './ProjectChip.styles';

interface ProjectChipProps {
  project: Project | ClientProject;
}

function ProjectChip(props: ProjectChipProps) {
  const { project } = props;

  return (
    <ProjectChipStyles>
      <span className="icon-shift">
        <DopeIcon name="PROJECT" color="#888" />
      </span>
      {project?.name}
    </ProjectChipStyles>
  );
}

export default ProjectChip;

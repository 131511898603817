import { IconNames } from 'components/ui/DopeIcon/DopeIcon';
import theme from './theme';
import { Issue } from 'typings';

interface IssueStatusDefinitions {
  all: IssueStatusDefinition;
  open: IssueStatusDefinition;
  resolved: IssueStatusDefinition;
  exempt: IssueStatusDefinition;
}

export interface IssueStatusDefinition {
  label: string;
  qsValue: string;
  color: string;
  icon: IconNames;
}

export const ISSUE_STATUS_DEFINITIONS: IssueStatusDefinitions = {
  all: {
    label: 'All',
    qsValue: 'all',
    color: theme.grey500,
    icon: 'ISSUES'
  },
  open: {
    label: 'Open',
    qsValue: 'open',
    color: theme.warning,
    icon: 'ISSUE_OPEN'
  },
  resolved: {
    label: 'Resolved',
    qsValue: 'resolved',
    color: theme.success,
    icon: 'ISSUE_RESOLVED'
  },
  exempt: {
    label: 'Exempt',
    qsValue: 'exempt',
    color: theme.grey500,
    icon: 'ISSUE_EXEMPT'
  }
};

export const ISSUE_STATUSES = Object.keys(ISSUE_STATUS_DEFINITIONS);

export function getStatusFromIssue(issue: Issue) {
  if (issue.isResolved) return 'resolved';
  if (issue.isExempted) return 'exempt';
  return 'open';
}

// DEPRECATED
export enum Severities {
  low = 'Low',
  moderate = 'Moderate',
  high = 'High',
  critical = 'Critical'
}

// DEPRECATED
export const SEVERITY_MAP = {
  0: Severities.low,
  1: Severities.low,
  2: Severities.moderate,
  3: Severities.moderate,
  4: Severities.moderate,
  5: Severities.moderate,
  6: Severities.moderate,
  7: Severities.moderate,
  8: Severities.moderate,
  9: Severities.moderate
};

export const ISSUE_SEVERITY = {
  1: 'Very Low',
  2: 'Low',
  3: 'Medium',
  4: 'High',
  5: 'Critical'
};

import InventorySection from 'app/sections/Authenticated/pages/Inventory/InventorySection/InventorySection';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import useDetectorVariants, { DetectorVariant } from '../DetectorsCommon/useDetectorVariants/useDetectorVariants';
import DetectorsController from '../DetectorsController/DetectorsController';

function DetectorsSection() {
  const variants: DetectorVariant = useDetectorVariants(),
    isPostureMonitoring = variants.id === 'posture-monitoring';

  return (
    <Switch>
      {/* old assessment link redirects */}
      <Redirect from="/assessments/add" to="/detectors/add" />
      <Redirect from="/assessments/:id" to="/detectors/:id" />
      <Redirect from="/assessments" to="/detectors" />

      {/* old list2 link redirects */}
      <Redirect from="/detectors/list2/add/:createStep?" to="/detectors/add/:createStep?" />
      <Redirect from="/detectors/list2/:id?/:editing?" to="/detectors/:id?/:editing?" />

      {/* inventory routes */}
      {isPostureMonitoring && <Route path={`${variants.basePath}/inventory`} component={InventorySection} />}

      {/* section routes */}
      <Route
        path={`${variants.basePath}/add/:createStep?`}
        render={(props) => <DetectorsController addDetector {...props} />}
      />
      <Route path={`${variants.basePath}/:id?/:editing?`} component={DetectorsController} />
    </Switch>
  );
}

export default DetectorsSection;

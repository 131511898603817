import React from 'react';
import { Modal, Button, message } from 'antd';
import { Op } from 'typings';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

interface Props {
  opToArchive: Op | null;
  onComplete?: () => void;
  onCancel?: () => void;
  entityName?: string;
}

function ArchiveOpModal(props: Props) {
  const { entityName = 'Op', opToArchive, onCancel, onComplete } = props;

  return (
    <Mutation
      mutation={gql`
        mutation ArchiveOp($id: String!) {
          archiveOp(id: $id) {
            id
            archivedAt
          }
        }
      `}
    >
      {(archiveOp, { loading, archiving }) => {
        return (
          <Modal
            visible={Boolean(opToArchive)}
            title={`Are you sure you want to archive '${opToArchive ? opToArchive.name || 'Untitled' : ''}'?`}
            closable
            onCancel={() => onCancel && onCancel()}
            footer={
              <>
                <Button
                  onClick={async () => {
                    try {
                      await archiveOp({
                        variables: { id: opToArchive && opToArchive.id }
                      });

                      message.success(`${entityName} was successfully archived.`);
                      onComplete && onComplete(); // update guardrails in current query
                    } catch (e) {
                      message.error('There was an error archiving this Op');
                    }
                  }}
                  disabled={loading || archiving}
                  type={'danger'}
                >
                  {archiving ? 'Archiving' : 'Archive'}
                </Button>
                <Button onClick={() => onCancel && onCancel()}>{'Cancel'}</Button>
              </>
            }
          >
            {`Warning! Archiving the "${
              opToArchive ? opToArchive.name || 'Untitled' : ''
            }" ${entityName} will place it in a disabled state.`}
          </Modal>
        );
      }}
    </Mutation>
  );
}

export default ArchiveOpModal;

import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import ErrorAlert from 'components/ui/ErrorAlert';
import { EventsTable } from 'components/events/EventsTable';
import { Event } from 'typings';
import EventDetail from 'components/events/EventDetail';
import DopeIcon from 'components/ui/DopeIcon';

const Root = styled.div`
  .run-op-error {
    margin-bottom: 20px;
  }

  .op-run-form-footer {
    display: flex;
    justify-content: space-between;

    .modal-buttons {
      text-align: right;

      > * {
        margin-left: 16px;
      }
    }
  }
`;

interface Props<T = any> {
  opId?: string | null;
  event?: T;
  eventDefinitionId?: string;
  onSelect?: (event: T) => void;
}

const RUN_OP_MUTATION = gql`
  mutation RunOp($id: String!, $event: JSONObject!) {
    runOp(id: $id, event: $event) {
      taskId
    }
  }
`;

function OpEventModal(props: Props) {
  const { opId, event, eventDefinitionId, onSelect } = props;

  const location = useLocation();
  const history = useHistory();
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(event || undefined);
  const [runOpMutation, runOpResult] = useMutation(RUN_OP_MUTATION);

  const isModalVisible = location.pathname.includes('/events');

  const handleClose = () => history.push(location.pathname.replace('/events', ''));

  return (
    <Modal
      title={
        <>
          {selectedEvent && (
            <Button
              shape="circle"
              type="link"
              style={{ marginRight: '4px' }}
              onClick={() => setSelectedEvent(undefined)}
            >
              <DopeIcon name="ARROW_BACK" />
            </Button>
          )}
          Event Console
        </>
      }
      visible={isModalVisible}
      onCancel={handleClose}
      width="80%"
      style={{ minWidth: '520px', maxWidth: '1200px', overflow: 'scroll' }}
      bodyStyle={{ maxHeight: '80vh', overflow: 'scroll' }}
      footer={null}
    >
      <Root>
        {runOpResult.error && (
          <ErrorAlert
            message="Running your Op against this Event failed."
            description={runOpResult.error?.graphQLErrors?.[0]?.message}
            className="run-op-error"
          />
        )}

        {!selectedEvent && (
          <EventsTable
            eventType="triggered"
            eventDefinitionId={eventDefinitionId}
            onEventSelected={(event: Event) => {
              setSelectedEvent(event);
            }}
          />
        )}

        {selectedEvent && (
          <>
            <EventDetail event={selectedEvent} />

            <div className="op-run-form-footer">
              <div />

              <div className="modal-buttons">
                <Button onClick={handleClose}>Cancel</Button>

                {onSelect && (
                  <Button
                    onClick={() => {
                      onSelect(selectedEvent.eventData);
                      handleClose();
                    }}
                  >
                    Select Event
                  </Button>
                )}

                {opId && (
                  <Button
                    type="danger"
                    loading={runOpResult.loading}
                    disabled={selectedEvent ? false : true}
                    onClick={async () => {
                      try {
                        await runOpMutation({
                          variables: {
                            id: opId,
                            event: selectedEvent.eventData
                          }
                        });
                      } catch (err) {
                        // console.error(err);
                      }
                    }}
                  >
                    Run Op
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
      </Root>
    </Modal>
  );
}

export default OpEventModal;

import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import React from 'react';
import JiraActionDetail from '../JiraActionDetail/JiraActionDetail';

interface ComponentProps {
  parameter: any;
  config: any | undefined;
  contextData: any;
}

function ActionDetailRow(props: ComponentProps) {
  const { parameter, config, contextData } = props;
  let recipient, val;

  // console.log(parameter, config, contextData);

  if (!config || config.value === false || config.value === '') {
    return <></>;
  }

  val = config.value;

  if (parameter.key === 'jira_recipient') {
    recipient = contextData.recipients.items.find((r) => r.id === val);
    return <JiraActionDetail jiraConfig={recipient.config} />;
  }

  return <HorizontalLabel label={parameter.name}>{JSON.stringify(val)}</HorizontalLabel>;
}

export default ActionDetailRow;

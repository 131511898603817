import DopeIcon from 'components/ui/DopeIcon';
import React from 'react';
import { OpSectionListItem } from '../components';
import { SelectActionProps } from './OpActionSection';

export const AlertAction = {
  id: '83e14127-9f64-46be-9ca7-c17ac74f34fc',
  key: 'disruptops-alert-function',
  name: 'Alert',
  description: '',
  labels: ['DisruptOps'],
  type: 'function',
  cloudVendor: 'DisruptOps',
  registration: {
    arn: '',
    method: '',
    __typename: 'FunctionRegistration'
  },
  parameters: [
    {
      name: 'Channel',
      key: 'alertRecipientId',
      type: 'string',
      inputCode: 'notification_recipient',
      configCode: null,
      defaultValue: '',
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Channel Source',
      key: 'alertRecipientSource',
      type: 'string',
      inputCode: null,
      configCode: null,
      defaultValue: 'PROJECT',
      description: null,
      placeholder: null,
      many: null,
      options: ['STATIC', 'PROJECT'],
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'More actions...',
      key: 'includeMoreActions',
      type: 'boolean',
      inputCode: null,
      configCode: null,
      defaultValue: true,
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Automate future responses to this event',
      key: 'includeCreateOpButton',
      type: 'boolean',
      inputCode: null,
      configCode: null,
      defaultValue: true,
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Dismiss from channel',
      key: 'includeDismissButton',
      type: 'boolean',
      inputCode: null,
      configCode: null,
      defaultValue: true,
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Create Ticket',
      key: 'includeCreateTicketButton',
      type: 'boolean',
      inputCode: null,
      configCode: null,
      defaultValue: true,
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Ticket Recipient',
      key: 'ticketRecipientId',
      type: 'string',
      inputCode: 'notification_recipient',
      configCode: null,
      defaultValue: '',
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Ticket Recipient Source',
      key: 'ticketRecipientSource',
      type: 'string',
      inputCode: null,
      configCode: null,
      defaultValue: 'PROJECT',
      description: null,
      placeholder: null,
      many: null,
      options: ['STATIC', 'PROJECT'],
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Recommended Actions',
      key: 'includeRecommendedActionsButton',
      type: 'boolean',
      inputCode: null,
      configCode: null,
      defaultValue: true,
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Expire',
      key: 'isAlertExpirationEnabled',
      type: 'boolean',
      inputCode: null,
      configCode: null,
      defaultValue: true,
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Expiration',
      key: 'alertExpirationMinutes',
      type: 'number',
      inputCode: null,
      configCode: null,
      defaultValue: 60,
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    },
    {
      name: 'Expiration',
      key: 'actionDelayMinutes',
      type: 'number',
      inputCode: null,
      configCode: null,
      defaultValue: 60,
      description: null,
      placeholder: null,
      many: null,
      options: null,
      required: false,
      __typename: 'FunctionParameter'
    }
  ],
  __typename: 'AutomationFunction'
};

function AlertActionListItem(props: SelectActionProps) {
  const { onSelectAction } = props;

  return (
    <OpSectionListItem
      key={AlertAction.id}
      title={AlertAction.name}
      description={AlertAction.description}
      icon={<DopeIcon name="DISRUPT_OPS_FAVICON_BLACK" size={30} />}
      onClick={() => {
        onSelectAction({ automationFunction: AlertAction });
      }}
    />
  );
}

export default AlertActionListItem;

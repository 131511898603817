import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { CenteredContainer } from 'components/ui/NeoPage';
import { Main, TitleBar } from 'components/ui/NeoPage';
import DopeIcon, { IconNames } from 'components/ui/DopeIcon/DopeIcon';

const Layout = styled.div`
  display: grid;
  grid-template-rows: repeat(auto-fit, 100px);
  grid-template-columns: repeat(auto-fit, 200px);
  grid-auto-rows: 100px;
  grid-auto-columns: 200px;
`;

const IconItem = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconLabel = styled.div`
  text-align: center;
`;

interface Props extends RouteComponentProps {}

function DopeIcons(props: Props) {
  return (
    <Main titleBar={<TitleBar title="Dope icons" />}>
      <CenteredContainer>
        <IconGroup label="Cloud Vendors">
          <DisplayIcon name="AWS" />
          <DisplayIcon name="AZURE" />
        </IconGroup>
        <IconGroup label="Disrupt Ops Icons">
          <DisplayIcon name="PROJECT" />
          <DisplayIcon name="COMPLIANCE" />
          <DisplayIcon name="INVENTORY" />
          <DisplayIcon name="SETTINGS" />
        </IconGroup>
        <IconGroup label="Ops">
          <DisplayIcon name="OP_TEMPLATES" />
          <DisplayIcon name="OP_TEMPLATE" />
          <DisplayIcon name="OP_RUN_NOW" />
          <DisplayIcon name="OP_RUNNING" />
        </IconGroup>
        <IconGroup label="Issues">
          <DisplayIcon name="ISSUES" />
          <DisplayIcon name="ISSUE_TAKE_ACTION" />
          <DisplayIcon name="ISSUE_MARK_EXEMPT" />
          <DisplayIcon name="ISSUE_REMOVE_EXEMPT" />
        </IconGroup>
        <IconGroup label="Action Status">
          <DisplayIcon name="SUCCESS" />
          <DisplayIcon name="WARNING" />
          <DisplayIcon name="ERROR" />
        </IconGroup>
        <IconGroup label="Activity">
          <DisplayIcon name="ACTIVITY_ALERTS_ON" />
          <DisplayIcon name="ACTIVITY_ALERTS_OFF" />
          <DisplayIcon name="ACTIVITY" />
        </IconGroup>
        <IconGroup label="General UX">
          <DisplayIcon name="CHEVRON_RIGHT" />
          <DisplayIcon name="NEXT_ARROW" />
          <DisplayIcon name="REGION" />
          <DisplayIcon name="ARROW_DOWN" />
          <DisplayIcon name="ARROW_BACK" />
          <DisplayIcon name="USER" />
          <DisplayIcon name="EXPAND_MORE" />
          <DisplayIcon name="ADD" />
          <DisplayIcon name="EDIT" />
          <DisplayIcon name="COPYRIGHT" />
          <DisplayIcon name="EVENT" />
          <DisplayIcon name="FUNCTION" />
        </IconGroup>
      </CenteredContainer>
    </Main>
  );
}

interface DisplayIconProps {
  name: IconNames;
}

// for display only.
function DisplayIcon(props: DisplayIconProps) {
  const { name } = props;
  return (
    <IconItem>
      <IconWrapper>
        <DopeIcon name={name} size={24} />
      </IconWrapper>
      <IconLabel>{name}</IconLabel>
    </IconItem>
  );
}

const IconGroupRoot = styled.div`
  margin-bottom: 80px;
  color: ${(p) => p.theme.grey800};
`;

const IconGroupLabel = styled.div`
  font-size: 14px;
  margin-bottom: 24px;
  color: ${(p) => p.theme.grey};
`;

interface IconGroupProps {
  label: string;
  children: React.ReactNode | React.ReactNodeArray;
}

// group together related Icons
function IconGroup(props: IconGroupProps) {
  const { label, children } = props;

  return (
    <IconGroupRoot>
      <IconGroupLabel>{label}</IconGroupLabel>
      <Layout>{children}</Layout>
    </IconGroupRoot>
  );
}

export default DopeIcons;

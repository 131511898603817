import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { message } from 'antd';

import { UserGroup, UserGroupMember } from 'typings';

import RemoveButton from 'components/buttons/RemoveButton';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

export interface Props {
  member: UserGroupMember;
  userGroup: UserGroup;
  disabled?: boolean;
  classes?: {
    root?: string;
    button?: string;
  };
  onRemove?: (user: UserGroupMember) => any | void;
  mutate?: boolean;
}

const REMOVE_USER_FROM_USER_GROUP_MUTATION = gql`
  mutation RemoveUserFromUserGroup($userGroupId: String!, $userId: String!) {
    removeUserFromGroup(userGroupId: $userGroupId, userId: $userId)
      @rest(path: "/user-groups/{args.userGroupId}/members/{args.userId}", method: "DELETE", type: "UserGroup") {
      name
    }
  }
`;

function RemoveMemberButton(props: Props) {
  const { userGroup, member, disabled, onRemove, mutate = true } = props;

  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_USER_GROUPS);
  const [removeUserFromGroup, { loading }] = useMutation(REMOVE_USER_FROM_USER_GROUP_MUTATION);

  const handleRemove = () => {
    if (onRemove) return onRemove(member);
  };

  return (
    <RemoveButton
      id={member.user_id}
      confirmMessage={`Are you sure you want to remove ${member.email} from ${userGroup.name}?`}
      disabled={!isAuthorized || disabled || loading}
      disabledMessage={authErrorMessage || ''}
      onRemove={async (userId) => {
        if (!mutate) return handleRemove();

        try {
          await removeUserFromGroup({
            variables: { userGroupId: userGroup.id, userId: userId }
          });

          message.success(`Successfully removed user, ${member.email} from ${userGroup.name}.`);

          handleRemove();
        } catch (err) {
          message.error(`Error occurred removing user, ${member.email} from ${userGroup.name}.`);
        }
      }}
    />
  );
}

export default RemoveMemberButton;

import React, { Component } from 'react';
import { Checkbox } from 'antd';

interface Props {
  name: string;
  value: boolean;
  setFieldTouched: (name: string, touched: boolean) => any | void;
  setFieldValue: (name: string, value: any) => any | void;

  placeholder?: String;
  defaultValue?: boolean;

  description: string;
}

export const inputDefinition = {
  defaultValue: false
};

class BooleanInput extends Component<Props> {
  render() {
    const { name, value, setFieldTouched, setFieldValue } = this.props;
    return (
      <Checkbox
        checked={value}
        defaultChecked={false}
        onChange={(event) => {
          setFieldValue(name, event.target.checked);
          setFieldTouched(name, true);
        }}
      />
    );
  }
}

export default BooleanInput;

import React from 'react';
import styled from 'styled-components';

import DynamicConfigProvider from 'components/dynamicConfig/DynamicConfigProvider';
import { Form } from 'components/ui/Form';
import { Divider, Typography } from 'antd';
import { CenteredContainer, Main, TitleBar } from 'components/ui/NeoPage';
import JSONTree from 'components/ui/JSONTree';
import Markdown from 'components/ui/Markdown';
import CONFIG_SETTINGS from 'fixtures/DynamicConfig';
import { generateSetSchema } from 'components/function/FunctionParameterInputSet';
import FunctionParameterInput from 'components/function/FunctionParameterInput';
import { FunctionReadView } from 'components/function';
import { FunctionParameter } from 'typings';
import { GridCard } from 'components/ui/Card';

const Root = styled.div`
  .doc-md {
    margin-bottom: 16px;
  }
`;

const JSONContainer = styled.div`
  padding: 20px;
  margin: 10px 0px 30px;
  font-size: 14px;
  background: #eee;
`;

const functionParameters: FunctionParameter[] = [
  {
    name: 'Define a service accessible via IP',
    key: 'ip_service',
    type: 'object' as 'object',
    description: 'Select common IP Services or provide a custom definition',
    inputCode: 'ip_service',
    many: true
  },
  {
    name: 'Checkbox (boolean)',
    key: 'boolean',
    type: 'boolean' as 'boolean',
    description: 'Check',
    inputCode: undefined
  },
  {
    name: 'Basic text input',
    key: 'text_input',
    type: 'string' as 'string',
    many: false
  },
  {
    name: 'Basic text input (many)',
    key: 'text_input_many',
    type: 'string' as 'string'
  },
  {
    name: 'TESTING DYNAMIC CONFIG',
    key: 'central_logging_account_id',
    type: 'string' as 'string',
    configCode: 'central_logging_account_id'
  },
  {
    name: 'Integer input',
    key: 'integer_input',
    type: 'integer' as 'integer'
  },
  {
    name: 'Short select',
    key: 'short_select',
    type: 'string' as 'string',
    options: ['a', 'b', 'c', 'd']
  },
  {
    name: 'Multi short select',
    key: 'multi_short_select',
    type: 'array' as 'array',
    options: ['a', 'b', 'c', 'd']
  },
  {
    name: 'Basic select',
    key: 'text_select',
    type: 'string' as 'string',
    options: ['a', 'b', 'c', 'd', 'e']
  },
  {
    name: 'Basic select with less than 4 items',
    key: 'basic_select_less_than_four',
    type: 'string' as 'string',
    inputCode: 'select',
    options: ['a', 'b', 'c']
  },
  {
    name: 'Multi select',
    key: 'text_multi_select',
    type: 'array' as 'array',
    options: ['a', 'b', 'c', 'd', 'e']
  },
  {
    name: 'Create tag',
    description: 'A single key, value pair tag to apply to existing snapshot',
    inputCode: 'aws_create_tag',
    key: 'aws_tag_list',
    type: 'object' as 'object'
  },
  {
    name: 'Single CIDR',
    inputCode: 'cidr',
    key: 'single_cidrs',
    type: 'string' as 'string'
  },
  {
    name: 'CIDR addresses of known networks',
    inputCode: 'cidr',
    key: 'known_cidrs',
    configCode: 'known_cidrs',
    type: 'string' as 'string',
    many: true
  },
  {
    name: 'AWS tags',
    inputCode: 'aws_tags',
    key: 'tags',
    type: 'array' as 'array'
  },
  {
    name: 'Select a region',
    inputCode: 'aws_regions',
    key: 'region_selected',
    options: [],
    type: 'string' as 'string'
  }
];

const schema = generateSetSchema(functionParameters);

const opInputAPIMD = `
# OpInput API
* \`title: string\` - The title displayed above the OpInput's control. Sometimes referred to as a label.
* \`name: string\` - The name (like the name for an HTML input), this is property where this OpInput's value will be stored on \`input_values\`.
* \`type: string ("boolean" | "string" | "number" | "array" | "object")\` - Defines the type of the value returned by this OpInput.
* \`input_code: string\` - Specifies a supported method of "upgrading" an OpInput to support more advanced functionality than just the HTML control. This tells the UI *how* to collect the associated \`input_value\`.
* \`config_code: string\` - Suggests to the UI that this OpInput expects a certain value from Config. This value should match one of the pre-defined values for \`Config.code\`, supported values are defined below.
* \`defaultValue: any\` - The default value to supply for this OpInput if no existing value, should conform to \`type\`.
* \`description: string\` - More information about the OpInput, rendered below the control.
* \`placeholder: string\` - Specify a placeholder for an OpInput that will be displayed when there is no existing value, would not be shown if a \`defaultValue\` is supplied.
* \`required: boolean\` - Whether or not to require a value for this OpInput.
* \`options: string[]\` - A list of options to render in the UI. Currently only supports strings.
* \`many: boolean\` - Whether or not to allow the user to add multiple of a control. This implicitly means that you will get an \`array\` back from this OpInput, and \`type\` now refers to the elements in that array. This also enables supplying an array to \`defaultValue\` to specify the values for the first \`n\` controls.
## Officially supported \`input_codes\`
* __aws_create_tag__ - A control for creating a new tag in AWS. Does not support selecting from existing tags.
* __aws_tags__ - A control for selecting an existing tag from a user's AWS account.
* __aws_regions__ - A control for selecting regions from AWS.
* __cidr__ - A control for entering a single CIDR. Useful to combine with \`many\`.
* __ip_service__ - A control to allow a user to specify a service available over IP and its associated ports.
* __select__ - Can be used to force rendering a Select box intead of radio buttons or checkboxes when the number of options supplied is less than 4.
## Deprecated \`input_codes\`
* __cidr_list__ - Still supported, move to __cidr__ + \`many: true\`
* __known_cidrs__ - Still supported, move to __cidr__ + \`many: true\` and Dynamic Config
* __tags__ - No longer supported, use __aws_tags__ instead.
* __regions__ - No longer supported, use __aws_regions__ instead.

## Pro-tips
* For any OpInput that uses a Select, you can specify a \`type\` of "array" and that will allow the user to select multiple values from the options.
`;

const DYNAMIC_CONFIG_MARKDOWN = `
  # Config
  "Config" (fka "Dynamic Config") allows a user to save values as variables to be used in appropriate FunctionParameterInputs throughout the app.
  Currently, DisruptOps manages a set list of supported "ConfigSettings". This currently can be found under \`/fixtures/DynamicConfig.ts\`. 
  This file generates the "Dynamic Configuration" section underneath the Root Project. This is where a user should centrally define the values they would like to be used throughout the App.
  Once the user has saved Configs the values should become available only to FunctionParameterInputs who's parameter has a "configCode", that matches the "key" of a saved Config Setting as well as matching "type", and "many" fields.

  The original idea was that OpDevelopers could add a "configCode" to parameters to allow Configs to be used. If the Setting does not exist in the fixture it will need to be added.
`;

interface Props {}

function FunctionInputs(props: Props) {
  return (
    <Main titleBar={<TitleBar title="Function Inputs" />}>
      <DynamicConfigProvider>
        {configBag => {
          return (
            <CenteredContainer>
              <GridCard>
                <Root>
                  <div className="doc-md">
                    <Markdown source={opInputAPIMD} />
                  </div>

                  <h2>Examples</h2>
                  <Form validationSchema={schema} onSubmit={() => true} initialValues={{}}>
                    {formikValues => {
                      const { touched, values, errors, setFieldValue, setFieldTouched, setFieldError } = formikValues;

                      return (
                        <>
                          {functionParameters.map((parameter: any, idx) => {
                            return (
                              <div key={idx} style={{ marginBottom: '50px' }}>
                                <h3>
                                  {parameter.name || parameter.inputCode || `Basic parameter for ${parameter.type}`}
                                </h3>
                                <h4>Definition</h4>
                                <JSONContainer>
                                  <JSONTree data={parameter} />
                                </JSONContainer>
                                <div style={{ marginBottom: '24px' }}>
                                  <h4>"FunctionParameterInput" component</h4>
                                  <FunctionParameterInput
                                    parameter={parameter}
                                    fieldName={parameter.name}
                                    value={values[parameter.key]}
                                    validationError={errors[parameter.key]}
                                    touched={touched[parameter.key] as boolean}
                                    setFieldError={setFieldError}
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    showDynamicConfigOptions
                                  />
                                </div>
                                <div>
                                  <h4>"FunctionReadView" component</h4>
                                  <FunctionReadView functionParameter={parameter} value={values[parameter.key]} />
                                </div>
                                <Divider />
                              </div>
                            );
                          })}
                        </>
                      );
                    }}
                  </Form>
                </Root>
              </GridCard>
              <GridCard>
                <Markdown source={DYNAMIC_CONFIG_MARKDOWN} />
                {CONFIG_SETTINGS.map(settingGroup => (
                  <div className="setting-group">
                    <Typography.Title level={4}>{`Setting Group: ${settingGroup.name}`}</Typography.Title>
                    <Typography.Text strong>Settings: </Typography.Text>
                    <JSONContainer>
                      <JSONTree data={settingGroup.configSettings} />
                    </JSONContainer>
                  </div>
                ))}
              </GridCard>
            </CenteredContainer>
          );
        }}
      </DynamicConfigProvider>
    </Main>
  );
}

export default FunctionInputs;

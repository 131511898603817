import styled from 'styled-components';

export const AddDetectorDiv = styled.div`
  color: #333;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 40px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 24px;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 24px 16px 16px 40px;
  }
`;

export const AssessorCardHeaderRoot = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: no-wrap;
  .assessment-icon-wrap {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }
  .assessor-card-header-title {
    font-size: 16px;
    padding-right: 16px;

    flex: 1;
  }

  .assessor-card-header-desc {
    flex: 2;
    padding-right: 16px;
  }
`;

import React from 'react';
import DetectorEditor, { EditorProps } from './DetectorEditor';
import { useHistory } from 'react-router-dom';
import EditorPanel from 'designSystem/EditorPanel/EditorPanel';
import useQueryString from '../DetectorsCommon/useQueryString/useQueryString';
import useDetectorVariants from '../DetectorsCommon/useDetectorVariants/useDetectorVariants';

function DetectorEditorPane(props: EditorProps) {
  const { detector } = props,
    variants = useDetectorVariants(),
    queryString = useQueryString(),
    history = useHistory(),
    panelTitle = `Configuring Detector: ${detector.name}`;

  function onClose() {
    history.push(`${variants.basePath}/${detector.id}${queryString}`);
  }

  return (
    <EditorPanel panelTitle={panelTitle} onClose={onClose}>
      <DetectorEditor detector={detector} />
    </EditorPanel>
  );
}

export default DetectorEditorPane;

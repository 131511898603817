import { Divider } from 'antd';
import ProgressCircular from 'components/ui/ProgressCircular';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';

export interface ConfirmationModalState {
  onConfirm: () => void;
  title: string;
  children: React.ReactNode;
}

const RECIPIENT_PROJECT_QUERY = gql`
  query RecipientProjectUsage($id: String!) {
    recipientProjectUsage(id: $id)
      @rest(type: "ProjectSettings", path: "/api/v2/projects/settings/{args.id}/", method: "GET") {
      project_id
      name
    }
  }
`;

const RECIPIENT_OP_QUERY = gql`
  query OpByRecipient($recipientId: String) {
    ops(recipientId: $recipientId) {
      nodes {
        id
        name
      }
    }
  }
`;

interface RecipientUsageProps {
  recipientId: string | undefined;
  setCanDeleteFunc;
}

export function RecipientUsage(props: RecipientUsageProps) {
  const { recipientId, setCanDeleteFunc } = props;

  const { loading: projectLoading, data: projectData } = useQuery(RECIPIENT_PROJECT_QUERY, {
    variables: { id: recipientId }
  });
  const { loading: opLoading, data: opData } = useQuery(RECIPIENT_OP_QUERY, {
    variables: { recipientId: recipientId }
  });
  if (projectLoading || opLoading) return <ProgressCircular />;

  const opUsage = opData?.ops?.nodes;
  const projectUsage = projectData?.recipientProjectUsage;

  if (projectUsage.length === 0 && opUsage.length === 0) setCanDeleteFunc(true);

  return (
    <>
      {projectUsage.length === 0 && opUsage.length === 0 ? (
        'Are you sure you want to delete this Recipient?'
      ) : (
        <>
          The following configurations are in use and will need to be removed before deletion:
          <Divider />
        </>
      )}
      {projectUsage.length > 0 && (
        <>
          Projects:
          <ul>
            {projectUsage.map((project) => (
              <li>
                <Link to={`/projects/${project.project_id}/settings`}>{project.name}</Link>
              </li>
            ))}
          </ul>
        </>
      )}
      {opUsage.length > 0 && (
        <>
          Ops:
          <ul>
            {opUsage.map((op) => (
              <li>
                <Link to={`/ops/details/${op.id}`}>{op.name}</Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </>
  );
}

import React, { useMemo } from 'react';
import ExpandedRow from '../ExpandedRow';
import Form, { FormField } from 'components/ui/Form';
import { Input, Row, Col, Select, Button, message } from 'antd';
import { CloudAccount } from 'typings';
import Space from 'components/ui/Space';
import SpaceBetween from 'components/ui/SpaceBetween';
import DeleteCloudAccountButton from 'components/cloudAccounts/DeleteCloudAccountButton';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import ErrorAlert from 'components/ui/ErrorAlert';
import * as Yup from 'yup';

const formSchema = Yup.object().shape({
  nickname: Yup.string().required(),
  environment: Yup.string(),
  labels: Yup.array().of(Yup.string())
});

const UPDATE_CLOUD_ACCOUNT_MUTATION = gql`
  mutation EditCloudAccount($id: String!, $input: ClientAccountInput!) {
    updateCloudAccount(id: $id, input: $input)
      @rest(type: "ClientAccount", path: "/api/v2/accounts/{args.id}", method: "PUT") {
      account_id
      nickname
      environment
      labels
    }
  }
`;

interface Props {
  cloudAccount: CloudAccount;
  environments: object;
  allCloudAccounts: CloudAccount[];
  onClose: () => void;
}

function EditCloudAccount(props: Props) {
  const { cloudAccount, environments, allCloudAccounts, onClose } = props;

  const { nickname, environment, labels } = cloudAccount;

  const allCloudAccountLabels = useMemo(() => {
    const allCloudAccountLabelsSet = allCloudAccounts.reduce((set, cloudAccount) => {
      const cloudAccountLabels = cloudAccount.labels || [];
      cloudAccountLabels.forEach((label) => set.add(label));

      return set;
    }, new Set<string>());

    return Array.from(allCloudAccountLabelsSet).sort();
  }, [allCloudAccounts]);

  const [updateCloudAccount, { error }] = useMutation(UPDATE_CLOUD_ACCOUNT_MUTATION, {});

  return (
    <ExpandedRow title={cloudAccount.assumerole_account_id} onClose={onClose}>
      <Form
        initialValues={{
          nickname,
          environment: environment ? environment : '',
          labels: labels || []
        }}
        validationSchema={formSchema}
        onSubmit={async (formValues, formActions) => {
          try {
            await updateCloudAccount({
              variables: {
                id: cloudAccount.account_id,
                input: {
                  ...formValues,
                  //coerce to null from empty string
                  environment: formValues.environment ? formValues.environment : null
                }
              }
            });

            message.success('Saved Cloud Account');
            setTimeout(onClose, 200);
          } catch (err) {
            message.error('Error saving Cloud Account');
          } finally {
            formActions.setSubmitting(false);
          }
        }}
      >
        {({ canSubmit, isSubmitting }) => (
          <>
            <Space size={16} direction="vertical">
              <Row gutter={24}>
                {error && (
                  <Col span={24}>
                    <ErrorAlert error={error} message="Error updating Cloud Account" />
                  </Col>
                )}

                <Col md={16} lg={8}>
                  <FormField name="nickname">
                    {({ value, handleBlur, handleChange }) => (
                      <Input type="string" value={value} onBlur={handleBlur} onChange={handleChange} />
                    )}
                  </FormField>
                </Col>

                <Col md={8} lg={6}>
                  <FormField name="environment">
                    {({ value, handleBlur, handleChange }) => (
                      <Select defaultActiveFirstOption value={value} onBlur={handleBlur} onChange={handleChange}>
                        <Select.Option value="">--</Select.Option>
                        {Object.entries(environments).map(([key, value]) => (
                          <Select.Option key={key} value={key}>
                            {value}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormField>
                </Col>

                <Col md={24} lg={10}>
                  <FormField name="labels">
                    {({ value, handleBlur, handleChange }) => (
                      <Select mode="tags" value={value} onBlur={handleBlur} onChange={handleChange}>
                        {allCloudAccountLabels.map((label, i) => (
                          <Select.Option key={`${i}_${label}`} value={label}>
                            {label}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </FormField>
                </Col>
              </Row>

              <SpaceBetween>
                <DeleteCloudAccountButton cloudAccount={cloudAccount} onDelete={() => onClose()}>
                  Delete Cloud Account
                </DeleteCloudAccountButton>

                <Space direction="horizontal" size={16}>
                  <Button onClick={() => onClose()}>Cancel</Button>

                  <Button htmlType="submit" type="primary" disabled={!canSubmit} loading={isSubmitting}>
                    Save
                  </Button>
                </Space>
              </SpaceBetween>
            </Space>
          </>
        )}
      </Form>
    </ExpandedRow>
  );
}

export default EditCloudAccount;

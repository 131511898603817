import React from 'react';

interface Props {
  indexedConfigs: any;
}

export default function AlertActions(props: Props) {
  const { indexedConfigs } = props;

  // console.log({ indexedConfigs });

  return (
    <ul className="no-bullets">
      {indexedConfigs?.alertActions?.value?.map((action, i) => (
        <li key={i}>{action.name}</li>
      ))}
    </ul>
  );
}

import { Input, Select } from 'antd';
import React, { CSSProperties, useState } from 'react';
import { isNumber } from 'util';
import humanizeDuration from 'humanize-duration';

const { Option } = Select;

interface Props {
  min?: number;
  style?: CSSProperties;
  disabled?: boolean;
  field?: any;
  minutes: number | null;
  step?: number | string;
  onChange?: (event: any) => any;
}

export function MinutesInput(props: Props) {
  const {
    minutes,
    min = 0,
    step = 'any',
    disabled = false,
    style = { width: '180px', marginLeft: '4px', marginRight: '4px' },
    onChange
  } = props;

  const [selectedUnit, setSelectedUnit] = useState('minutes');

  const minuteMultiplier = selectedUnit === 'days' ? (1 / 60) * 24 : selectedUnit === 'hours' ? 1 / 60 : 1;

  const [value, setValue] = useState<string | number>(minutes ? minutes * minuteMultiplier : '');

  // console.log({ minutes, value, selectedUnit });

  function onMinutesChanged(targetValue, unit) {
    const parsedValue = Number(targetValue);
    const newValue = isNaN(parsedValue) ? '' : parsedValue;
    setValue(newValue);

    // convert the new value to minutes
    const minuteMultiplier = unit === 'days' ? 60 * 24 : unit === 'hours' ? 60 : 1;
    const newMinutes = isNumber(newValue) ? Math.round(newValue * minuteMultiplier) : '';

    // console.log({
    //   targetValue,
    //   unit,
    //   parsedValue,
    //   newValue,
    //   minuteMultiplier,
    //   isNumber: isNumber(newValue)
    // });

    onChange &&
      onChange({
        target: {
          value: newMinutes
        }
      });
  }

  const plural = value !== 1 && 's';

  const duration = minutes && humanizeDuration(minutes * 60 * 1000);

  return (
    <>
      <Input
        type="number"
        min={min}
        step={step}
        style={style}
        value={value}
        disabled={disabled}
        addonAfter={
          <Select
            defaultValue={selectedUnit}
            disabled={disabled}
            style={{ width: '90px' }}
            onChange={(newUnit) => {
              setSelectedUnit(newUnit);
              onMinutesChanged(value, newUnit);
            }}
          >
            <Option value="minutes">minute{plural}</Option>
            <Option value="hours">hour{plural}</Option>
            <Option value="days">day{plural}</Option>
          </Select>
        }
        onChange={(e) => {
          onMinutesChanged(e.target.value, selectedUnit);
        }}
      />

      {duration && <span>&nbsp; ({duration})</span>}
    </>
  );
}

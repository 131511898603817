import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';

const Root = styled.div`
  margin-bottom: 40px;
`;
const LabelRow = styled.div`
  display: flex;
  margin-bottom: 24px;
  align-items: center;
`;
const Label = styled.h3`
  font-weight: 600;
  margin-right: 6px;
  margin-bottom: 0;
`;
const Content = styled.div``;

interface Props {
  label?: string;
  labelTooltipText?: string; // (?) tooltip
  children: React.ReactNode | React.ReactNodeArray;
  classes?: {
    label?: string;
    root?: string;
    content?: string;
  };
}

function FormGroup(props: Props) {
  const { label, labelTooltipText, children, classes = {} } = props;

  return (
    <Root className={classes.root || 'root'}>
      <LabelRow>
        {label && <Label className={classes.label || 'root'}>{label}</Label>}
        {labelTooltipText && (
          <Tooltip title={labelTooltipText} placement="rightBottom">
            <div>
              <DopeIcon name="QUESTION" size="12" />
            </div>
          </Tooltip>
        )}
      </LabelRow>
      <Content className={classes.content || 'root'}>{children}</Content>
    </Root>
  );
}

export default FormGroup;

import React, { ReactNode } from 'react';
import { Input, Divider } from 'antd';

import { size } from 'typings';
// import OpSectionTitle from '../../../../app/sections/Authenticated/pages/Ops/OpDetail/components/OpSectionTitle';
// import DopeIcon from '../../../ui/DopeIcon';
import { GridCard } from 'components/ui/Card';
import JiraRecipientControl from 'components/jira/JiraRecipientControl';
import DopeIcon from 'components/ui/DopeIcon';
import OpSectionTitle from 'app/sections/Ops/Editor/OpDetail/components/OpSectionTitle';

interface SimpleOpInputProps {
  placeholder?: string;

  name: string;
  value: string;
  size?: size;

  disabled?: boolean;
  addonAfter?: ReactNode;
  inputRef: (input: Input) => any | void;

  setFieldValue: (name: string, value: any) => any | void;
  setFieldTouched: (name: string, touched: boolean) => any;
}

function JiraNotificationRecipient(props: SimpleOpInputProps) {
  const { name, value, setFieldValue, setFieldTouched } = props;

  return (
    <GridCard
      // secondaryTitle="Jira Integration"
      icon={<DopeIcon name="ISSUE_TAKE_ACTION" size={20} />}
      title={<OpSectionTitle title={'Select Jira Integration'} />}
      elevation={1}
      rounded
    >
      <Divider style={{ margin: '2px 0 0' }} />

      <JiraRecipientControl
        recipientId={value}
        onSelect={(recipientId) => {
          setFieldValue(name, recipientId);
          setFieldTouched(name, true);
        }}
        onClear={() => {
          setFieldTouched(name, false);
          setFieldValue(name, undefined);
        }}
      />
    </GridCard>
  );
}

export default JiraNotificationRecipient;

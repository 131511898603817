import React from 'react';
import { ResourceDetailTableProps } from 'components/cloudResources/cloudResourceTypesConfig';
import gql from 'graphql-tag';
import DetailTable, { DetailRowProps } from 'components/cloudResources/DetailTable';

const AWS_EC2_SECURITY_GROUPS_DETAIL = gql`
  query AWSEC2SecurityGroupDetail($dopsResourceUid: String!) {
    security_groups(dops_resource_uid: $dopsResourceUid) {
      from
      size
      total_count
      results {
        group_name
        group_id
        description
        vpc_id
      }
    }
  }
`;

const rows: DetailRowProps[] = [
  {
    key: 'group_name',
    dataIndex: 'group_name',
    label: 'Group Name'
  },
  {
    key: 'group_id',
    dataIndex: 'group_id',
    label: 'Group ID'
  },
  {
    key: 'description',
    dataIndex: 'description',
    label: 'Description',
    render: description => description || '-'
  },
  {
    key: 'vpc_id',
    dataIndex: 'vpc_id',
    label: 'VPC ID'
  }
];

function AWSEC2SecurityGroupDetailTable(props: ResourceDetailTableProps) {
  const { dopsResourceUid } = props;

  return (
    <DetailTable
      query={AWS_EC2_SECURITY_GROUPS_DETAIL}
      queryName={'security_groups'}
      rows={rows}
      variables={{ dopsResourceUid }}
    />
  );
}

export default AWSEC2SecurityGroupDetailTable;

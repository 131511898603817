import { ApolloClient } from 'apollo-client';
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { ApolloLink } from 'apollo-link';

import { resolvers, typeDefs, getDefaults } from './state';
import restLink from './links/rest';
import authLink from './links/auth';
import errorLink from './links/error';
import httpLink from './links/http';
import omitTypename from './links/omitTypename';

import cache from './cache';

import { APP_ENV } from 'constants/runtimeConfig';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
};

const link = ApolloLink.from([authLink, omitTypename, restLink, httpLink]);

const client = new ApolloClient({
  link: errorLink.concat(link),
  cache,
  defaultOptions,
  connectToDevTools: APP_ENV !== 'prod',
  resolvers: resolvers,
  typeDefs: typeDefs
});

function writeDefaults() {
  return cache.writeData({ data: getDefaults() });
}

writeDefaults();

client.onResetStore(() => Promise.resolve(writeDefaults()));

export default client;

import styled from 'styled-components';

export const AddFilterDiv = styled.div`
  position: relative;
  flex-grow: 1;

  .trigger {
    width: 100%;
    text-align: left;
    font-style: italic;
    color: ${(p) => p.theme.grey600};
    border: none;
    background: none;
    cursor: pointer;
  }

  .selections {
    width: 200px;
    border: 1px solid ${(p) => p.theme.grey400};
    border-radius: 4px;
    padding: 0.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 1.5rem;
    left: 0;
    z-index: 100;
    background-color: #fafafa;
  }

  .selection {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .selection:last-child {
    margin-bottom: 0;
  }
`;

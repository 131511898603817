import React from 'react';
import gql from 'graphql-tag';
import { RouteComponentProps } from 'react-router';

import { Query } from 'react-apollo';

import QueryResult from 'components/util/QueryResult';

import { GUARDRAIL_FIELDS } from 'queries/fragments/guardrailFields';

import GuardrailDetailView from './GuardrailDetailView';

const GUARDRAIL_DETAIL = gql`
  query GuardrailById($id: [String]) {
    guardrails(id: $id) {
      nodes {
        ...GuardrailFields
      }
    }
  }
  ${GUARDRAIL_FIELDS}
`;

interface GuardrailDetailProps
  extends RouteComponentProps<{
    id?: string;
  }> {}

function GuardrailDetail(props: GuardrailDetailProps) {
  const {
    match: {
      params: { id }
    }
  } = props;

  return (
    <Query query={GUARDRAIL_DETAIL} variables={{ id }} skip={id ? false : true}>
      {({ loading, error, data }) => (
        <QueryResult
          loading={loading}
          error={error}
          data={data}
          expectSingleNode
          entityName="Guardrail"
          parseNodes={data => data.guardrails.nodes}
        >
          {guardrail => {
            return <GuardrailDetailView {...props} guardrail={guardrail} />;
          }}
        </QueryResult>
      )}
    </Query>
  );
}
export default GuardrailDetail;

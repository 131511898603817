import { Select } from 'antd';
import { ModeOption } from 'antd/lib/select';
import React from 'react';
import styled from 'styled-components';
import { EVENT_SOURCE_QUERY } from 'queries';
import { useQuery } from '@apollo/react-hooks';

const Root = styled.div``;

export interface Props {
  value?: string | string[];
  onChange: (value: any) => any;
  mode?: ModeOption;
  placeholder?: string;
  onBlur?: (event: any) => any;
  minWidth?: number;
}

function EventSourceFilter(props: Props) {
  const { value, onChange, onBlur, minWidth = 300 } = props;

  const mode = props.mode ? props.mode : 'default';
  const placeholder = props.placeholder ? props.placeholder : 'All';

  const { loading, error, data } = useQuery(EVENT_SOURCE_QUERY);

  const options = [
    <Select.Option key="All" value="">
      All
    </Select.Option>,
    ...(data?.eventSources?.nodes?.map((eventSource) => {
      return (
        <Select.Option key={eventSource.id} title={eventSource.name} value={eventSource.id}>
          {eventSource.name}
        </Select.Option>
      );
    }) || [])
  ];

  return (
    <Root>
      <Select
        value={value === null ? undefined : value}
        style={{ width: '100%', minWidth: `${minWidth}px` }}
        onChange={onChange}
        disabled={loading || !!error}
        placeholder={!error ? placeholder : 'Error loading event sources'}
        loading={loading}
        mode={mode}
        onBlur={onBlur}
        filterOption={(inputValue, option) => {
          if (!option || !option.props || !option.props.title) {
            return true;
          }
          return new RegExp(inputValue, 'i').test(option.props.title);
        }}
      >
        {!loading && data?.eventSources ? options : <Select.Option value="">Loading</Select.Option>}
      </Select>
    </Root>
  );
}

export default EventSourceFilter;

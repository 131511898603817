import React from 'react';
import * as Yup from 'yup';
import truncate from 'truncate';
import CustomJSONPathFilterInput from '../components/CustomJSONPathFilterInput';
import { GuardrailFilterDefinition } from '.';

const customFilterDefinition: GuardrailFilterDefinition = {
  key: 'CUSTOM_JSON_PATH', // issues are currently using lowercased keys...
  schema: Yup.array()
    .of(
      Yup.object().shape({
        path: Yup.string().required(),
        type: Yup.string().required(),
        patterns: Yup.array()
          .of(Yup.string())
          .required()
      })
    )
    .required(),
  required: false,
  configurationInput: CustomJSONPathFilterInput,
  getCollapseTitle: _value => 'Custom Filter with JSON Path:',
  collapseTitleReadView: ({ value }) => {
    if (!value) {
      return <></>;
    }
    const jsonString = JSON.stringify(value, null, 2);
    return (
      <span title={jsonString}>
        {truncate(jsonString, 50)}
      </span>
    );
  }
};

export default customFilterDefinition;

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import TableExpandedRow from './TableExpandedRow';

const Root = styled.div`
  padding: 16px;
  border-bottom: 1px solid #ddd;
  background-color: ${p => p.theme.grey100};
  // border-radius: 8px 8px 0 0;
  // overflow: hidden;
`;

const Content = styled.div`
  display: flex;
`;

const HeaderDrawer = styled(TableExpandedRow)`
  margin-left: -16px;
  margin-right: -16px;
`;

export interface Props {
  children: ReactNode;
  classes?: {
    root?: string;
    content?: string;
  };
  headerDrawerOpen?: boolean;
  headerDrawer?: ReactNode;
}

function TableHeader(props: Props) {
  const { headerDrawer, headerDrawerOpen, classes = {}, children } = props;

  return (
    <>
      <Root className={classes.root || ''}>
        <Content className={classes.content || ''}>{children}</Content>
      </Root>
      {headerDrawer && headerDrawerOpen && <HeaderDrawer>{headerDrawer}</HeaderDrawer>}
    </>
  );
}

export default TableHeader;

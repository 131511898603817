import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { Col, Collapse, Descriptions, Divider, Input, Row, Typography } from 'antd';
import AssessmentSelector from 'app/sections/Authenticated/pages/OrganizationSettings/pages/Notifications/components/NotificationForm/AssessmentSelector';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { CloudAccountFilter } from 'components/app/FilterBar/filters';
import EventSourceFilter from 'components/app/FilterBar/filters/EventSource';
import RegionFilter from 'components/app/FilterBar/filters/Region';
import ResourceTypeFilter from 'components/app/FilterBar/filters/ResourceType';
import SeverityFilter from 'components/app/FilterBar/filters/Severity';
import CloudAccountLabelsSelect from 'components/cloudAccounts/CloudAccountLabelsSelect';
import { CloudVendorIcon } from 'components/cloudVendors';
import EnvironmentSelect from 'components/environment/EnvironmentSelect';
import CustomJSONPathFilterInput from 'components/events/CustomJSONPathFilterInput';
import AssessmentCollapseTitleReadView from 'components/guardrails/GuardrailEditor/sections/filter/components/AssessmentCollapseTitleReadView';
import ProjectScopeCollapseTitleReadView from 'components/project/ProjectScopeCollapseTitleReadView';
import ProjectTreeSelect from 'components/project/ProjectTree/ProjectTreeSelect';
import { GridCard } from 'components/ui/Card';
import DopeIcon from 'components/ui/DopeIcon';
import { FormField } from 'components/ui/Form';
import { FormRenderProps } from 'components/ui/Form/Form';
import IconButton from 'components/ui/IconButton';
import Markdown from 'components/ui/Markdown';
import { eventSourceAwsConfig, eventSourceDisruptOpsGovernance } from 'constants/eventSources';
import { getSeverityName } from 'constants/ui';
import { getIn } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ConfirmationModalState, ContextArgs, GridCardContentPadding, OnSelectTriggerFunc } from '../../OpEditor';
import { AccountIdsCollapseReadView } from './definitions/filters/accountIds';
import { EventSourcesCollapseReadView } from './definitions/filters/eventSources';
import { TagsFilter } from './filters';
import SelectTrigger from './SelectTrigger';
import CheckboxFormItem from 'components/ui/Form/CheckboxFormItem';

const CollapsePanel = Collapse.Panel;

const Root = styled.div`
  .collapse-trigger {
    padding: 3px;
    display: flex;
    justify-content: center;
    color: ${(p) => p.theme.primary500};
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
  }
`;

const EventDefinitionCollapseRoot = styled.div`
  margin-top: 8px;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 46px;
  }

  .ant-descriptions-small .ant-descriptions-row > th,
  .ant-descriptions-small .ant-descriptions-row > td {
    padding-bottom: 2px;
  }

  .ant-descriptions-item-content {
    margin-bottom: 8px;
  }

  .ant-form-item {
    margin-bottom: 8px;
  }
`;

interface OpTriggerSectionProps {
  event: any;
  contextArgs: ContextArgs;
  onSelectTrigger: OnSelectTriggerFunc;
  formRenderProps: FormRenderProps;
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
}

const eventSourceIdKey = 'eventSourceId';
const triggerProjectIdsKey = 'triggerProjectIds';
const regionsKey = 'regions';
const negateRegionsKey = 'triggerFilters.negateRegions';
const accountIdsKey = 'accountIds';
const assessmentIdKey = 'assessmentId';
const accountEnvironmentsKey = 'environments';
const accountLabelsKey = 'accountLabels';
const jsonPathsKey = 'jsonPaths';
const severityKey = 'severity';
const resourceTypesKey = 'resourceType';
const tagsKey = 'tags';
const configRuleNameKey = 'triggerFilters.configRuleName';

function OpTriggerSection(props: OpTriggerSectionProps) {
  const {
    contextArgs: { eventDefinition, op, isAllFindingsSelected },
    onSelectTrigger,
    formRenderProps,
    setConfirmationModal,
    event
  } = props;

  const [isEditing, setIsEditing] = useState(false);

  const { overview, description, vendor, vendorProduct } = eventDefinition || {};
  const { values, setFieldValue } = formRenderProps;
  const { eventSourceId } = values;
  const isEventSourceDisruptOpsGovernance = eventSourceId === eventSourceDisruptOpsGovernance;
  const isEventSourceAwsConfig = eventSourceId === eventSourceAwsConfig;

  const existingProjectId = op?.projectId || null;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: existingProjectId || '*' }]
  });
  const isAuthorized = existingProjectId ? authz.isAuthorized : true;

  const opProjectId: string = values?.projectId;
  const environments = values?.[accountEnvironmentsKey] || [];
  const accountLabels = values?.[accountLabelsKey] || [];
  const regions = values?.[regionsKey] || [];
  const negateRegions = values?.triggerFilters?.negateRegions || false;
  const customFilters = values?.[jsonPathsKey] || [];
  const severity = values?.[severityKey] || [];
  const configRuleName = values?.triggerFilters?.configRuleName || '';
  const resourceTypes = values?.[resourceTypesKey] || [];
  const tags = values?.[tagsKey] || {};
  const tagEntries = tags ? Object.entries(tags) : [];

  // console.log({ negateRegions, values });

  return (
    <Root>
      <GridCard
        classes={{ root: 'has-arrow' }}
        secondaryTitle="Trigger"
        // should add a component to get trigger icon from eventDef.
        icon={
          eventDefinition ? <CloudVendorIcon vendor={eventDefinition.vendor} /> : <DopeIcon name="TRIGGER" size={20} />
        }
        title={
          !eventDefinition && !isAllFindingsSelected ? (
            'Select Trigger'
          ) : (
            <div className="section-title-wrap">
              <span className="section-title">{eventDefinition ? eventDefinition.name : 'All Findings'}</span>

              {isAuthorized && (
                <IconButton
                  type="ghost"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmationModal({
                      title: 'Clear Trigger',
                      description: 'This section will be cleared and any filter settings will be lost.',
                      onConfirm: () => {
                        onSelectTrigger({ eventDefinition: undefined, isAllFindingsSelected: false });
                        setConfirmationModal(null); // close modal
                      }
                    });
                  }}
                >
                  <DopeIcon name="REMOVE" />
                </IconButton>
              )}
            </div>
          )
        }
        elevation={1}
        rounded
      >
        {!isAllFindingsSelected && !eventDefinition ? (
          <GridCardContentPadding>
            <SelectTrigger onSelect={onSelectTrigger} />
          </GridCardContentPadding>
        ) : (
          <EventDefinitionCollapseRoot>
            <Collapse bordered={false} accordion>
              {(description || overview) && (
                <CollapsePanel key="details" header="Overview" style={{ paddingLeft: '2px' }}>
                  <GridCardContentPadding>
                    {description && <Typography.Text>{description}</Typography.Text>}

                    {overview && <Markdown source={overview} />}
                  </GridCardContentPadding>
                </CollapsePanel>
              )}
            </Collapse>

            <Divider style={{ margin: '2px 0px 0px' }} />

            <div style={{ margin: '16px 14px' }}>
              <h3>Filters:</h3>

              {!isEditing && (
                <Descriptions size="small" layout="vertical">
                  <Descriptions.Item label="Project">
                    <ProjectScopeCollapseTitleReadView value={getIn(values, triggerProjectIdsKey)} />
                  </Descriptions.Item>

                  <Descriptions.Item label="Account">
                    <AccountIdsCollapseReadView value={getIn(values, accountIdsKey)} />
                  </Descriptions.Item>

                  <Descriptions.Item label="Account Label">
                    {accountLabels.length > 0 ? accountLabels.join(', ') : 'Any'}
                  </Descriptions.Item>

                  <Descriptions.Item label="Environment">
                    {environments.length > 0 ? environments.join(', ') : 'Any'}
                  </Descriptions.Item>

                  <Descriptions.Item label={negateRegions ? 'Region Not In' : 'Region'}>
                    {regions.length > 0 ? regions.join(', ') : 'Any'}
                  </Descriptions.Item>

                  <Descriptions.Item label="Source">
                    <EventSourcesCollapseReadView value={getIn(values, eventSourceIdKey)} />
                  </Descriptions.Item>

                  {!isEventSourceAwsConfig && (
                    <Descriptions.Item label="Severity">
                      {severity.length > 0
                        ? severity.map((severity) => `${getSeverityName(severity)} (${severity})`).join(', ')
                        : 'Any'}
                    </Descriptions.Item>
                  )}

                  {isEventSourceAwsConfig ? (
                    <Descriptions.Item label="Config Rule Name">{configRuleName}</Descriptions.Item>
                  ) : null}

                  {vendor === 'DisruptOps' && vendorProduct === 'Governance' && (
                    <Descriptions.Item label="Detector">
                      <AssessmentCollapseTitleReadView value={getIn(values, assessmentIdKey)} />
                    </Descriptions.Item>
                  )}

                  <Descriptions.Item label="Resource Type">
                    {resourceTypes.length > 0 ? resourceTypes.join(', ') : 'Any'}
                  </Descriptions.Item>

                  {!isEventSourceDisruptOpsGovernance ? (
                    <Descriptions.Item label="Resource Tags">
                      {tagEntries && tagEntries.length > 0
                        ? tags.map(({ key, value }) => `${key}:${value}`).join(', ')
                        : 'Any'}
                    </Descriptions.Item>
                  ) : null}

                  <Descriptions.Item label="Custom JSONPaths">
                    {customFilters.length > 0
                      ? customFilters
                          .map((customFilter, index) => (
                            <span key={index} title={`${customFilter.path}=${customFilter.patterns.join(', ')}`}>
                              {customFilter.path}
                            </span>
                          ))
                          .reduce((previous, current) => (
                            <>
                              {previous}, {current}
                            </>
                          ))
                      : 'None'}
                  </Descriptions.Item>
                </Descriptions>
              )}

              {isEditing && (
                <>
                  <Row gutter={12}>
                    <Col xs={24} sm={24} md={24} lg={12}>
                      {/* Filter by Project(s) */}
                      <FormField name={triggerProjectIdsKey} label="Project:">
                        {({ value, handleChange, handleBlur }) => {
                          return (
                            <ProjectTreeSelect
                              rootProjectId={opProjectId}
                              value={value}
                              onChange={(projectIds) => {
                                if (!Array.isArray(projectIds)) return;
                                handleChange(projectIds);
                              }}
                              onBlur={handleBlur}
                            />
                          );
                        }}
                      </FormField>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12}>
                      {/* Filter by Cloud Account Ids */}
                      <FormField name={accountIdsKey} label={'Account:'}>
                        {({ value, handleChange, handleBlur }) => {
                          return (
                            <CloudAccountFilter
                              placeholder="Any Cloud Account"
                              mode={'multiple'}
                              value={value}
                              onChange={(value) => {
                                if (!value || !Array.isArray(value)) return;
                                handleChange(value.length > 0 ? value : undefined);
                              }}
                              onBlur={handleBlur}
                            />
                          );
                        }}
                      </FormField>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12}>
                      {/* Filter By Account Label(s)*/}
                      <FormField name={accountLabelsKey} label="Account Label:">
                        {({ value, handleChange, handleBlur }) => {
                          return (
                            <CloudAccountLabelsSelect
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Any Cloud Account Labels"
                            />
                          );
                        }}
                      </FormField>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12}>
                      {/* Filter By Environment */}
                      <FormField name={accountEnvironmentsKey} label="Environment:">
                        {({ value, handleChange, handleBlur }) => {
                          return (
                            <EnvironmentSelect
                              placeholder="Any Environment"
                              showEmptySelection={false}
                              mode="multiple"
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          );
                        }}
                      </FormField>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12}>
                      {/* Filter By Regions */}
                      <FormField name={regionsKey} label="Region:">
                        {({ value, handleChange, handleBlur }) => {
                          return (
                            <>
                              <CheckboxFormItem name={negateRegionsKey}>not in</CheckboxFormItem>
                              <RegionFilter
                                placeholder="Any Region"
                                value={value}
                                onChange={(value) => {
                                  if (!value || !Array.isArray(value)) return;
                                  handleChange(value.length > 0 ? value : undefined);
                                }}
                                onBlur={handleBlur}
                                mode={'multiple'}
                              />
                            </>
                          );
                        }}
                      </FormField>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12}>
                      {/* Filter By Source */}
                      <FormField name={eventSourceIdKey} label="Source:">
                        {({ value, handleChange, handleBlur }) => {
                          return (
                            <EventSourceFilter
                              value={value}
                              onChange={(value) => {
                                handleChange(value || undefined);

                                // wipe out the tags
                                if (value === eventSourceDisruptOpsGovernance) {
                                  setFieldValue('tags', []);
                                }
                              }}
                              onBlur={handleBlur}
                            />
                          );
                        }}
                      </FormField>
                    </Col>

                    {!isEventSourceAwsConfig && (
                      <Col xs={24} sm={24} md={24} lg={12}>
                        {/* Filter By Severity */}
                        <FormField name={severityKey} label="Severity:">
                          {({ value, handleChange, handleBlur }) => {
                            return (
                              <SeverityFilter
                                value={value}
                                onChange={(value) => {
                                  handleChange(value || undefined);
                                }}
                                onBlur={handleBlur}
                              />
                            );
                          }}
                        </FormField>
                      </Col>
                    )}

                    {isEventSourceAwsConfig && (
                      <Col xs={24} sm={24} md={24} lg={12}>
                        {/* Filter By Config Rule Name */}
                        <FormField name={configRuleNameKey} label="Config Rule Name:">
                          {({ name, value, handleChange, handleBlur }) => (
                            <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                          )}
                        </FormField>
                      </Col>
                    )}

                    {vendor === 'DisruptOps' && vendorProduct === 'Governance' && (
                      <Col xs={24} sm={24} md={24} lg={12}>
                        {/* Filter By Detector */}
                        <FormField name={assessmentIdKey} label="Detector:">
                          {({ value, handleChange, handleBlur }) => {
                            return <AssessmentSelector value={value} onChange={handleChange} onBlur={handleBlur} />;
                          }}
                        </FormField>
                      </Col>
                    )}

                    <Col xs={24} sm={24} md={24} lg={12}>
                      {/* Filter By Resource Type */}
                      <FormField name={resourceTypesKey} label="Resource Type:">
                        {({ value, handleChange, handleBlur }) => {
                          return (
                            <ResourceTypeFilter
                              value={value}
                              onChange={(value) => {
                                handleChange(value || undefined);
                              }}
                              onBlur={handleBlur}
                              eventSourceId={eventSourceId}
                            />
                          );
                        }}
                      </FormField>
                    </Col>

                    {!isEventSourceDisruptOpsGovernance ? (
                      <Col xs={24} sm={24} md={24} lg={12}>
                        {/* Filter By Resource Tags */}
                        <FormField name={tagsKey} label="Resource Tags (external findings only):">
                          {({ name, value, handleChange, handleBlur }) => {
                            return <TagsFilter name={name} value={value} onChange={handleChange} onBlur={handleBlur} />;
                          }}
                        </FormField>
                      </Col>
                    ) : null}

                    <Col span={24}>
                      {/* Filter by Custom JSONPath */}
                      <FormField name={jsonPathsKey} label="Custom JSONPaths:">
                        {({ value, name, handleChange }) => {
                          return (
                            <CustomJSONPathFilterInput
                              name={name}
                              value={value}
                              eventFromEventConsole={event}
                              onChange={handleChange}
                              eventDefinition={eventDefinition}
                            />
                          );
                        }}
                      </FormField>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </EventDefinitionCollapseRoot>
        )}

        {(isAllFindingsSelected || eventDefinition) && (
          <>
            <Divider style={{ margin: '2px 0px 0px' }} />

            <div className="collapse-trigger" onClick={() => setIsEditing(!isEditing)}>
              <div>{isEditing ? 'Hide Options' : 'Show Options'}</div>
            </div>
          </>
        )}
      </GridCard>
    </Root>
  );
}

export default OpTriggerSection;

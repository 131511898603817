import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import { OP_FIELDS } from 'queries/fragments/opFields';
import React from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { EventDefinitionFunction } from 'typings';
import { PLAYBOOK_BY_ID } from './gql';
import OpEditor from './OpEditor';

const OP_DETAIL = gql`
  query OpById($id: [String]) {
    ops(id: $id) {
      nodes {
        ...OpFields
      }
    }
  }
  ${OP_FIELDS}
`;

function OpDetail(
  props: RouteComponentProps<{
    id?: string;
  }>
) {
  const {
    match: {
      params: { id }
    },
    location: { search }
  } = props;

  const searchParams = new URLSearchParams(search);
  const fromPlaybookId = searchParams.has('fromPlaybook') ? searchParams.get('fromPlaybook') : undefined;
  const initTriggerId = searchParams.has('initTriggerId') ? searchParams.get('initTriggerId') : undefined;
  const allFindings = searchParams.has('allFindings') ? searchParams.get('allFindings') : undefined;
  const projectId = searchParams.has('projectId') ? searchParams.get('projectId') || undefined : undefined;
  const eventSourceId = searchParams.has('eventSourceId') ? searchParams.get('eventSourceId') || undefined : undefined;
  const accountId = searchParams.has('accountIds') ? searchParams.get('accountIds') || undefined : undefined;
  const severityString = searchParams.has('severity') ? searchParams.get('severity') || undefined : undefined;
  const severity = severityString ? parseInt(severityString, 10) : undefined;
  const regions = searchParams.has('regions') ? searchParams.get('regions') || undefined : undefined;
  const environments = searchParams.has('environments') ? searchParams.get('environments') || undefined : undefined;
  const resourceType = searchParams.has('resourceType') ? searchParams.get('resourceType') || undefined : undefined;
  const customFiltersPath = searchParams.has('customFiltersPath')
    ? searchParams.get('customFiltersPath') || undefined
    : undefined;
  const customFiltersPatterns = searchParams.has('customFiltersPatterns')
    ? searchParams.get('customFiltersPatterns') || undefined
    : undefined;

  const opResults = useQuery(OP_DETAIL, { variables: { id }, skip: !Boolean(id) });
  const playbookResults = useQuery(PLAYBOOK_BY_ID, {
    variables: { id: fromPlaybookId },
    skip: !fromPlaybookId
  });

  // Load existing Op;
  if (id)
    return (
      <QueryResult
        {...opResults}
        expectSingleNode
        entityName="Op"
        parseNodes={(data) => (data && data.ops && data.ops.nodes) || null}
      >
        {(op) => {
          return <OpEditor op={op} {...props} />;
        }}
      </QueryResult>
    );

  // Start Op from EventDefinitionFunction template
  if (fromPlaybookId)
    return (
      <QueryResult
        {...playbookResults}
        entityName="Playbook"
        expectSingleNode
        parseNodes={(data) => data?.playbooks?.nodes}
      >
        {(singleNode) => {
          const playbook: EventDefinitionFunction | null = singleNode;

          return <OpEditor playbook={playbook} {...props} />;
        }}
      </QueryResult>
    );

  // Blank Op
  return (
    <OpEditor
      initTriggerId={initTriggerId}
      allFindings={!!allFindings}
      initialProjectId={projectId}
      eventSourceId={eventSourceId}
      accountId={accountId}
      severity={severity}
      regions={regions}
      environments={environments}
      resourceType={resourceType}
      customFiltersPath={customFiltersPath}
      customFiltersPatterns={customFiltersPatterns}
    />
  );
}
export default OpDetail;

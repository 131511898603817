import React from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';

import NeoPage, { CenteredContainer, TitleBar, TitleBarButton } from 'components/ui/NeoPage';

import DopeIcon from 'components/ui/DopeIcon';
import CloudAccountsTable from 'components/cloudAccounts/CloudAccountsTable';

const Root = styled.div`
  .ant-table-row-expand-icon-cell,
  .ant-table-expand-icon-th {
    padding: 0;
    width: 0;
    min-width: 0;
    * {
      display: none;
    }
  }
  .ant-table-expanded-row {
    td:first-child {
      padding: 0;
      width: 0;
      min-width: 0;
      * {
        display: none;
      }
    }
  }
`;

function CloudAccounts(props: RouteComponentProps) {
  const { history } = props;
  const match = useRouteMatch(`/cloud-accounts/:cloudAccountId?/:expandedRowType?`);

  const { cloudAccountId, expandedRowType } = match.params;

  return (
    <NeoPage
      titleBar={
        <TitleBar
          icon={<DopeIcon name="CLOUD_ACCOUNT" size={20} />}
          sectionTitle={'Cloud accounts'}
          sectionTitleLinkTo="/cloud-accounts"
          title="Provisioned accounts"
          actions={
            <TitleBarButton icon={<DopeIcon name="ADD" />} linkTo="/cloud-accounts/add">
              {'Add account'}
            </TitleBarButton>
          }
        />
      }
    >
      <Root>
        <CenteredContainer leftAlign>
          <CloudAccountsTable
            expandedRowKeys={[cloudAccountId]}
            expandedRowType={expandedRowType}
            onProvisionCloudAccount={(cloudAccountId) => {
              history.push(`/cloud-accounts/${cloudAccountId}/provision`);
            }}
            onEditCloudAccount={(cloudAccountId) => {
              history.push(`/cloud-accounts/${cloudAccountId}/edit`);
            }}
            onCloseAll={() => {
              history.push(`/cloud-accounts/`);
            }}
          />
        </CenteredContainer>
      </Root>
    </NeoPage>
  );
}

export default CloudAccounts;

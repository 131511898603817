import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Formik } from 'formik';
import styled from 'styled-components';
import { Button, Row, Alert } from 'antd';
import * as Yup from 'yup';

import api from 'services/api';

import Input from 'components/ui/Input';

import { GridCard } from 'components/ui/Card';
import { CenteredContainer } from 'components/ui/NeoPage';
import PageHeader from 'components/ui/PageHeader';

const Root = styled.div`
  width: 100%;
`;

interface Props extends RouteComponentProps<any> {}

const inputHeight = '80px'; // WHY?

interface FormValues {
  newPassword: string;
  oldPassword: string;
  confirmPassword: string;
}

const AlertWrap = styled.div`
  margin-top: 10px;
`;

const FormSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Required'),
  newPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Required')
});

interface State {
  submitting: boolean;
  result: any;
}

class UpdatePasswordPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      submitting: false, // not needed
      result: undefined
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  async handleSubmit(formValues: FormValues, actions: any) {
    const { oldPassword, newPassword } = formValues;

    let success: boolean = false;
    try {
      const result = await api.updatePassword(oldPassword, newPassword);
      if (result.status === 200) success = true;
    } catch (e) {
      // could set state from here?
    } finally {
      // set State
      this.setState({ result: success ? 'success' : 'error' });

      // formik actions
      // resets isSubmitting and clears form.
      actions.resetForm({});
    }
  }

  handleCloseAlert() {
    this.setState({ result: null });
  }

  render() {
    const { result } = this.state;

    return (
      <CenteredContainer size="md" leftAlign>
        <PageHeader title="Update password" titleLevel={2} />
        <GridCard>
          <Root>
            <Formik
              initialValues={{
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
              }}
              onSubmit={this.handleSubmit}
              validationSchema={FormSchema}
            >
              {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, errors }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Input
                      type="password"
                      title="Current password"
                      value={values.oldPassword || ''}
                      height={inputHeight}
                      placeholder="..."
                      onChange={handleChange}
                      error={errors.oldPassword}
                      name="oldPassword"
                      onBlur={handleBlur}
                      required
                    />
                    <Input
                      type="password"
                      title="New password"
                      value={values.newPassword || ''}
                      height={inputHeight}
                      placeholder="..."
                      onChange={handleChange}
                      error={errors.newPassword}
                      name="newPassword"
                      onBlur={handleBlur}
                      required
                    />
                    <Input
                      type="password"
                      title="Confirm password"
                      value={values.confirmPassword || ''}
                      height={inputHeight}
                      placeholder="..."
                      onChange={handleChange}
                      error={errors.confirmPassword}
                      name="confirmPassword"
                      onBlur={handleBlur}
                      required
                    />

                    <Row justify="end" type="flex">
                      <Button type="primary" loading={isSubmitting} htmlType="submit">
                        {isSubmitting ? 'Saving...' : 'Save new password'}
                      </Button>
                    </Row>
                    {result && (
                      <AlertWrap>
                        <Alert
                          message={
                            result === 'success' ? 'Password updated successfully' : 'Failed to update password.'
                          }
                          closable
                          onClose={this.handleCloseAlert}
                          showIcon
                          type={result}
                        />
                      </AlertWrap>
                    )}
                  </form>
                );
              }}
            </Formik>
          </Root>
        </GridCard>
      </CenteredContainer>
    );
  }
}

export default UpdatePasswordPage;

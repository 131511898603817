import React from 'react';
import { TreeNodeNormal } from 'antd/lib/tree-select/interface';
import { ProjectTreeNode } from 'components/project/ProjectTree/useProjectTree';
import NodeTitle from './NodeTitle/NodeTitle';

export function buildTree(projectTree, showCloudAccounts): TreeNodeNormal[] {
  return projectTree.map((node) => convertProjectTreeNodeToAntTreeNode(node, undefined, showCloudAccounts));
}

function convertProjectTreeNodeToAntTreeNode(
  projectTreeNode: ProjectTreeNode,
  hasPermission?: (node: ProjectTreeNode) => boolean,
  showCloudAccounts?: boolean
): TreeNodeNormal {
  const disabled = hasPermission ? !hasPermission(projectTreeNode) : false;
  const children: TreeNodeNormal[] = [];

  const node: TreeNodeNormal = {
    key: projectTreeNode.project_id,
    title: <NodeTitle showCloudAccounts={showCloudAccounts} projectTreeNode={projectTreeNode} />,
    value: projectTreeNode.project_id,
    selectable: false,
    children: children,
    disabled: disabled
  };

  if (projectTreeNode.childProjects) {
    children.push(
      ...projectTreeNode.childProjects.map((p) =>
        convertProjectTreeNodeToAntTreeNode(p, hasPermission, showCloudAccounts)
      )
    );
  }

  return node;
}

import gql from 'graphql-tag';

const AUTOMATION_EVENT_LIST_QUERY = gql`
  query AutomationEventList(
    $pageNumber: Int = 1
    $pageSize: Int
    $search: String
    $region: [String]
    $cloudAccountId: [String]
    $eventDefinitionId: [String]
    $vendorProduct: String
    $eventSourceId: [String]
  ) {
    automationEvents(
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      region: $region
      cloudAccountId: $cloudAccountId
      eventDefinitionId: $eventDefinitionId
      vendorProduct: $vendorProduct
      eventSourceId: $eventSourceId
    ) {
      pageInfo {
        current
        total
        size
      }
      nodes {
        createdAt
        id
        eventType
        vendor
        vendorProduct
        region
        cloudAccountId
        contextId
        clientId
        publisher
        eventDefinitionId
        eventDefinitionName
        eventSourceId
        eventSource {
          id
          name
        }
        eventData
      }
    }
  }
`;

export default AUTOMATION_EVENT_LIST_QUERY;

import React from 'react';

import Form from 'react-jsonschema-form';
import styled from 'styled-components';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import ArrayFieldTemplate from './templates/ArrayFieldTemplate';
import ObjectFieldTemplate from './templates/ObjectFieldTemplate';
import FieldTemplate from './templates/FieldTemplate';
import TextWidget from './templates/TextWidget';
import CheckboxWidget from './templates/CheckboxWidget';
import { Button } from 'antd';

const Root = styled.div`
  .more-btn {
    display: flex;
    align-items: center;
    margin-top: 16px;

    &.show-more svg {
      transform: rotateZ(180deg);
    }
  }

  .btn-add {
    margin-left: 8px;
    margin-bottom: 8px;
  }
`;

interface Props {
  inputSchema: any;
  parameters: object;
  disabled?: boolean;
  submitDisabled?: boolean;
  onSubmit: (value: any) => any | void;
  onChange: (value: any) => any | void;
  onValidate?: (formData: any, errors: any) => any | void;
}

function FunctionInputSchema(props: Props) {
  const { inputSchema, parameters, onSubmit, onChange, onValidate, disabled, submitDisabled } = props;

  const schema = typeof inputSchema === 'string' ? JSON.parse(inputSchema) : inputSchema;

  const uiSchema = {
    '*': {
      'ui:options': {
        orderable: false
      }
    }
  };

  if (schema && schema.description) {
    schema.description = null;
  }

  const widgets = {
    TextWidget,
    CheckboxWidget
  };

  return (
    <Root>
      <ErrorBoundary>
        <div className="container-fluid">
          <Form
            disabled={disabled}
            schema={schema}
            formData={parameters}
            uiSchema={uiSchema}
            ArrayFieldTemplate={ArrayFieldTemplate}
            FieldTemplate={FieldTemplate}
            ObjectFieldTemplate={ObjectFieldTemplate}
            widgets={widgets}
            onChange={onChange}
            onSubmit={onSubmit}
            // onError={errors => console.log({errors})}
            validate={onValidate}
            // liveValidate={true}
          >
            <Button type="primary" htmlType="submit" disabled={disabled || submitDisabled}>
              Run Function
            </Button>
          </Form>
        </div>
      </ErrorBoundary>
    </Root>
  );
}

export default FunctionInputSchema;

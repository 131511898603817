import React from 'react';
import RadioGroup from 'antd/lib/radio/group';
import { Radio, Checkbox } from 'antd';
import styled from 'styled-components';

interface Props {
  name: string;
  value: number;
  setFieldTouched: (name: string, touched: boolean) => any | void;
  setFieldValue: (name: string, value: any) => any | void;
  placeholder?: String;
  options: any[];
  multiple?: boolean;
}

const Root = styled.div`
  .ant-checkbox-group-item,
  .ant-radio-wrapper {
    display: block;
    height: 30px;
    line-height: 30px;
  }
`;

function ShortSelect(props: Props) {
  const { name, value, options, multiple, setFieldTouched, setFieldValue } = props;

  const handleChange = value => {
    setFieldTouched(name, true);
    setFieldValue(name, value);
  };

  return (
    <Root>
      {multiple ? (
        <Checkbox.Group options={options} defaultValue={[]} onChange={handleChange} />
      ) : (
        <RadioGroup onChange={e => handleChange(e.target.value)} value={value}>
          {options.map(option => (
            <Radio key={option} value={option}>
              {option}
            </Radio>
          ))}
        </RadioGroup>
      )}
    </Root>
  );
}

export default ShortSelect;

import React from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'antd';
import { GridCard, CardSection, ChildCard } from 'components/ui/Card';
import { ConfiguredAssessment, FunctionParameter } from 'typings';
import { FunctionReadView } from 'components/function';
import EditAssessmentFiltersModal from './EditAssessmentFiltersModal';
import DopeIcon from 'components/ui/DopeIcon';
import AssessmentFiltersEditButton from './AssessmentFiltersEditButton';
import AttachedDynamicConfiguration from 'components/dynamicConfig/AttachedDynamicConfiguration';
import { typedPropertyOrDefault } from 'utilities/helpers';
import { useProjectTree } from 'components/project/ProjectTree';
import ProjectChip from 'components/project/ProjectChip';

interface Props {
  assessment: ConfiguredAssessment;
  linkPrefix?: string;
}

function AssessmentFiltersCard(props: Props) {
  const { assessment, linkPrefix = '' } = props;
  const { staticConfiguration, dynamicConfiguration } = assessment;
  const targetProjectIds = assessment.targetProjectIds || [];

  const emptyParameters: FunctionParameter[] = [];
  const parameters = typedPropertyOrDefault(assessment.assessor.parameters, emptyParameters);

  const { allProjects, getProject } = useProjectTree();
  const ownerProject = getProject(assessment.projectId);
  const targetProjects = targetProjectIds.map(getProject).filter((project) => (project ? true : false));

  return (
    <Route
      children={({ history, match }) => {
        const action = (match.params && match.params.action) || null;

        return (
          <>
            <GridCard
              title="Filters"
              icon={<DopeIcon size={22} name="FILTER" />}
              action={<AssessmentFiltersEditButton assessment={assessment} linkPrefix={linkPrefix} />}
            >
              <ChildCard key="triggerProjectIds" title="Project Scope">
                <Row gutter={32}>
                  <Col xl={12}>
                    <CardSection label="Description" description="Select the Scope for this Detector" />
                  </Col>

                  <Col xl={12}>
                    <CardSection
                      label="Value"
                      description={
                        <div style={{ display: 'flex' }}>
                          {targetProjects.length > 0 ? (
                            targetProjects.map((project) => {
                              if (!project) return null;
                              return <ProjectChip key={project.project_id} project={project} />;
                            })
                          ) : allProjects.length > 1 ? (
                            <>
                              {ownerProject ? (
                                <>
                                  <span>All Projects under&nbsp;&nbsp;</span>
                                  <ProjectChip project={ownerProject} />
                                </>
                              ) : null}
                            </>
                          ) : null}
                        </div>
                      }
                    />
                  </Col>
                </Row>
              </ChildCard>

              {parameters.map((parameter) => {
                const { name, description, key } = parameter;
                let value = null;

                if (staticConfiguration && staticConfiguration[key] !== undefined) {
                  value = staticConfiguration[key];
                }

                const matchingDynamicConfiguration =
                  (dynamicConfiguration && dynamicConfiguration.find((i) => i.key === key)) || null;

                return (
                  <ChildCard key={key} title={name || null}>
                    <Row gutter={32}>
                      {description && (
                        <Col xl={12}>
                          <CardSection label="Description" description={description} />
                        </Col>
                      )}

                      <Col xl={description ? 12 : 24}>
                        <CardSection
                          label="Value"
                          description={
                            matchingDynamicConfiguration && matchingDynamicConfiguration.node ? (
                              <AttachedDynamicConfiguration
                                attachedDynamicConfiguration={matchingDynamicConfiguration.node}
                              />
                            ) : (
                              <FunctionReadView functionParameter={parameter} value={value} />
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </ChildCard>
                );
              })}
            </GridCard>

            <EditAssessmentFiltersModal
              assessment={assessment}
              visible={action === 'edit-filters'}
              onCloseModal={() => {
                if (history.length && history.length > 1) {
                  history.goBack();
                } else {
                  history.replace(`${linkPrefix}/assessments/${assessment.id}`);
                }
              }}
            />
          </>
        );
      }}
    />
  );
}

export default AssessmentFiltersCard;

import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import DeleteButton from 'components/buttons/DeleteButton';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

interface Props {
  apiKey: string;
  onDelete: () => any | void;
}

const DESTROY_API_KEY_MUTATION = gql`
  mutation DestroyAPIKey($apiKey: String) {
    destroyAPIKey(apiKey: $apiKey)
  }
`;

function DeleteAPIKeyButton(props: Props) {
  const { apiKey, onDelete } = props;

  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_CLIENT_API_KEYS);
  const [destroyAPIKey, { loading }] = useMutation(DESTROY_API_KEY_MUTATION, { variables: { apiKey } });

  return (
    <DeleteButton
      id={apiKey}
      loading={loading}
      disabled={!isAuthorized}
      disabledMessage={authErrorMessage || ''}
      mode="default"
      onDelete={async () => {
        await destroyAPIKey();
        onDelete && onDelete();
      }}
    ></DeleteButton>
  );
}

export default DeleteAPIKeyButton;

export const awsAsffResourceTypes = [
  'AwsCloudFrontDistribution',
  'AwsCodeBuildProject',
  'AwsEc2Instance',
  'AwsEc2NetworkInterface',
  'AwsEc2SecurityGroup',
  'AwsElasticsearchDomain',
  'AwsElbv2LoadBalancer',
  'AwsIamAccessKey',
  'AwsIamRole',
  'AwsKmsKey',
  'AwsLambdaFunction',
  'AwsLambdaLayerVersion',
  'AwsRdsDbInstance',
  'AwsS3Bucket',
  'AwsS3Object',
  'AwsSnsTopic',
  'AwsSqsQueue',
  'AwsWafWebAcl',
  'Container',
  'Other'
];

export const awsCloudFormationResourceTypes = [
  'AWS::ACM::Certificate',
  'AWS::ApiGateway::RestApi',
  'AWS::ApiGateway::Stage',
  'AWS::ApiGatewayV2::Api',
  'AWS::ApiGatewayV2::Stage',
  'AWS::AutoScaling::AutoScalingGroup',
  'AWS::AutoScaling::LaunchConfiguration',
  'AWS::AutoScaling::ScalingPolicy',
  'AWS::AutoScaling::ScheduledAction',
  'AWS::CloudFormation::Stack',
  'AWS::CloudFront::Distribution',
  'AWS::CloudFront::StreamingDistribution',
  'AWS::CloudTrail::Trail',
  'AWS::CloudWatch::Alarm',
  'AWS::CodeBuild::Project',
  'AWS::CodePipeline::Pipeline',
  'AWS::DynamoDB::Table',
  'AWS::EC2::CustomerGateway',
  'AWS::EC2::EgressOnlyInternetGateway',
  'AWS::EC2::EIP',
  'AWS::EC2::FlowLog',
  'AWS::EC2::Host',
  'AWS::EC2::Instance',
  'AWS::EC2::InternetGateway',
  'AWS::EC2::NatGateway',
  'AWS::EC2::NetworkAcl',
  'AWS::EC2::NetworkInterface',
  'AWS::EC2::RouteTable',
  'AWS::EC2::SecurityGroup',
  'AWS::EC2::Subnet',
  'AWS::EC2::Volume',
  'AWS::EC2::VPC',
  'AWS::EC2::VPCEndpoint',
  'AWS::EC2::VPCEndpointService',
  'AWS::EC2::VPCPeeringConnection',
  'AWS::EC2::VPNConnection',
  'AWS::EC2::VPNGateway',
  'AWS::ElasticBeanstalk::Application',
  'AWS::ElasticBeanstalk::ApplicationVersion',
  'AWS::ElasticBeanstalk::Environment',
  'AWS::ElasticLoadBalancing::LoadBalancer',
  'AWS::ElasticLoadBalancingV2::LoadBalancer',
  'AWS::Elasticsearch::Domain',
  'AWS::IAM::Group',
  'AWS::IAM::Policy',
  'AWS::IAM::Role',
  'AWS::IAM::User',
  'AWS::KMS::Key',
  'AWS::Lambda::Function',
  'AWS::QLDB::Ledger',
  'AWS::RDS::DBCluster',
  'AWS::RDS::DBClusterSnapshot',
  'AWS::RDS::DBInstance',
  'AWS::RDS::DBSecurityGroup',
  'AWS::RDS::DBSnapshot',
  'AWS::RDS::DBSubnetGroup',
  'AWS::RDS::EventSubscription',
  'AWS::Redshift::Cluster',
  'AWS::Redshift::ClusterParameterGroup',
  'AWS::Redshift::ClusterSnapshot',
  'AWS::Redshift::ClusterSubnetGroup',
  'AWS::Redshift::EventSubscription',
  'AWS::S3::AccountPublicAccessBlock',
  'AWS::S3::Bucket',
  'AWS::SecretsManager::Secret',
  'AWS::ServiceCatalog::CloudFormationProduct',
  'AWS::ServiceCatalog::CloudFormationProvisionedProduct',
  'AWS::ServiceCatalog::Portfolio',
  'AWS::Shield::Protection',
  'AWS::ShieldRegional::Protection',
  'AWS::SNS::Topic',
  'AWS::SQS::Queue',
  'AWS::SSM::AssociationCompliance',
  'AWS::SSM::FileData',
  'AWS::SSM::ManagedInstanceInventory',
  'AWS::SSM::PatchCompliance',
  'AWS::WAF::RateBasedRule',
  'AWS::WAF::Rule',
  'AWS::WAF::RuleGroup',
  'AWS::WAF::WebACL',
  'AWS::WAFRegional::RateBasedRule',
  'AWS::WAFRegional::Rule',
  'AWS::WAFRegional::RuleGroup',
  'AWS::WAFRegional::WebACL',
  'AWS::WAFv2::ManagedRuleSet',
  'AWS::WAFv2::RuleGroup',
  'AWS::WAFv2::WebACL',
  'AWS::XRay::EncryptionConfig'
];

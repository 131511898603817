import React from 'react';
import Select, { SelectProps } from 'antd/lib/select';

export enum Environment {
  production = 'Production',
  staging = 'Staging',
  development = 'Development',
  testing = 'Testing/QA'
}

interface Props extends SelectProps {
  value: Environment;
  showEmptySelection?: boolean;
  placeholder?: string;
}

function EnvironmentSelect(props: Props) {
  const { showEmptySelection = true } = props;

  return (
    <Select {...props}>
      {showEmptySelection && <Select.Option value="">--</Select.Option>}
      {Object.entries(Environment).map(([key, value]) => (
        <Select.Option key={key} value={key}>
          {value}
        </Select.Option>
      ))}
    </Select>
  );
}

export default EnvironmentSelect;

import { Icon, Spin, Typography } from 'antd';
import { GridCard } from 'components/ui/Card';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

const QUERY = gql`
  query AccountsAndProjectsQuery {
    projects {
      items {
        project_id
        name
      }
    }

    accounts {
      items {
        account_id
      }
    }
  }
`;

export default function AccountsAndProjectsKpi() {
  const { loading, error, data } = useQuery(QUERY);

  const { projects = {} } = data || {};
  const allProjects = projects.items;
  const projectCount = allProjects?.length || 0;
  const cloudAccounts = data?.accounts?.items;
  const cloudAccountCount = cloudAccounts?.length || 0;

  return (
    <GridCard>
      <Typography.Paragraph className="kpi kpi4">
        {error ? (
          <Icon type="exclamation-circle" title={error.message} />
        ) : loading ? (
          <Title level={2} style={{ marginBottom: '8px' }}>
            <Spin />
          </Title>
        ) : (
          <Link to={`/cloud-accounts`}>
            <Title level={2} style={{ marginBottom: '8px' }}>
              {cloudAccountCount.toLocaleString()}
            </Title>
          </Link>
        )}
        <Link to={`/cloud-accounts`}>
          <Text>Accounts</Text>
        </Link>
        <br />
        <Link to={`/projects`}>
          <Text type="secondary">
            {projectCount ? (
              <>
                {projectCount.toLocaleString()} Project{projectCount === 1 ? '' : 's'}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </Text>
        </Link>
      </Typography.Paragraph>
    </GridCard>
  );
}

import React, { ReactNode, ReactNodeArray } from 'react';
import { CreatePanelDiv } from './CreatePanel.styles';
import { useHistory } from 'react-router-dom';
import CloseButton from 'designSystem/Badge/CloseButton/CloseButton';

interface CreatePanelProps {
  title: string;
  children: ReactNode | ReactNodeArray;
  closeLink?: string;
}

function CreatePanel(props: CreatePanelProps) {
  const { title, children, closeLink } = props;
  const history = useHistory();

  function onClose() {
    history.push(props.closeLink);
  }

  return (
    <CreatePanelDiv>
      <div className="header">
        {closeLink && <CloseButton onClose={onClose} />}
        <h2 className="header__title" data-testid="title">
          {title}
        </h2>
      </div>
      <div className="main-content">{children}</div>
    </CreatePanelDiv>
  );
}

export default CreatePanel;

import React, { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { RouteComponentProps } from 'react-router';

import { Mutation, MutationFunction } from 'react-apollo';
import { Typography, Switch, message, Alert } from 'antd';

import { NewGuardrail } from 'typings';

import DopeIcon from 'components/ui/DopeIcon';
import NeoPage, { CenteredContainer, TitleBar, TitleBarButton } from 'components/ui/NeoPage';

import { GUARDRAIL_FIELDS } from 'queries/fragments/guardrailFields';

import GuardrailEditor from 'components/guardrails/GuardrailEditor';
import GuardrailTitle from './components/GuardrailTitle';
import GuardrailValidator, { GuardrailValidatorRenderProps } from 'components/guardrails/GuardrailValidator';
import GuardrailMetaColumn from './components/GuardrailMetaColumn';
import ArchiveGuardrailModal from 'components/guardrails/ArchiveGuardrailModal';
import DeleteGuardrailModal from 'components/guardrails/DeleteGuardrailModal';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

const SAVE_GUARDRAIL = gql`
  mutation SaveGuardrailTrigger($guardrail: GuardrailInput!) {
    saveGuardrail(guardrail: $guardrail) {
      ...GuardrailFields
    }
  }
  ${GUARDRAIL_FIELDS}
`;

interface GuardrailDetailProps extends RouteComponentProps {
  guardrail: NewGuardrail;
}

const EnableGuardrailViewRoot = styled.div`
  display: flex;
  align-items: center;

  .enable-guardrail-message {
    margin-right: 8px;
  }
`;

interface EnableGuardrailViewProps extends GuardrailValidatorRenderProps {
  guardrail: NewGuardrail;
  saveGuardrailMutationFn: MutationFunction;
}

function EnableGuardrailView(props: EnableGuardrailViewProps) {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { guardrail, saveGuardrailMutationFn, validating, validationError } = props;

  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: '*' }]
  });

  return (
    <EnableGuardrailViewRoot>
      <Typography.Text className="enable-guardrail-message">
        {`This Guardrail `}
        {guardrail.archivedAt ? 'has been ' : 'is '}
        <strong>{guardrail.archivedAt ? 'ARCHIVED' : guardrail.isEnabled ? 'ON' : 'OFF'}</strong>
      </Typography.Text>
      {!guardrail.archivedAt && (
        <Switch
          checked={guardrail.isEnabled}
          loading={isSaving}
          onChange={async (checked) => {
            if (authz.isAuthorized) {
              setIsSaving(true);
              try {
                const variables = {
                  guardrail: {
                    id: guardrail.id,
                    isEnabled: checked
                  }
                };

                await saveGuardrailMutationFn({ variables });

                message.success(`Guardrail has been ${checked ? 'enabled' : 'disabled'}.`);
              } catch (e) {
                message.error('There was a problem enabling this Guardrail');
              } finally {
                setIsSaving(false);
              }
            } else {
              message.warning('You do not have effective permissions to edit Guardrail');
            }
          }}
          disabled={!authz.isAuthorized || isSaving || validating || validationError !== null}
        />
      )}
    </EnableGuardrailViewRoot>
  );
}

const Root = styled.div``;
const GridLayout = styled.div`
  display: grid;
  grid-template-columns: auto 24px 128px;
  grid-template-areas: 'main . meta';

  .guardrail-layout-main {
    grid-area: main;
  }

  .guardrail-meta-column {
    grid-area: meta;
  }
`;

enum ModalName {
  DELETE = 'DELETE',
  ARCHIVE = 'ARCHIVE'
}

function GuardrailDetailView(props: GuardrailDetailProps) {
  const [activeModal, setActiveModal] = useState<ModalName | null>(null);
  const { guardrail, history } = props;

  const locationState = props.history.location.state || {};
  const triggeringEvent = locationState.event;

  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: '*' }]
  });

  return (
    <GuardrailValidator guardrail={guardrail}>
      {(guardrailValidationRenderProps) => {
        return (
          <Mutation mutation={SAVE_GUARDRAIL}>
            {(saveGuardrail) => (
              <Root>
                <NeoPage
                  titleBarHasNav
                  titleBar={
                    <TitleBar
                      title="Guardrail Details"
                      sectionTitleLinkTo="/guardrails"
                      sectionTitle="Guardrails"
                      icon={<DopeIcon name="GUARDRAIL" size={20} />}
                      actions={
                        <>
                          <EnableGuardrailView
                            {...guardrailValidationRenderProps}
                            guardrail={guardrail}
                            saveGuardrailMutationFn={saveGuardrail}
                          />
                          <TitleBarButton
                            children="View Activity"
                            icon={<DopeIcon name="ACTIVITY" />}
                            linkTo={`/guardrails/activity?guardrailId=${guardrail.id}`}
                          />
                          {guardrail.archivedAt ? (
                            <>
                              <Mutation
                                mutation={gql`
                                  mutation UnarchiveGuardrailFromGuardrailDetail($id: ID!) {
                                    unarchiveGuardrail(id: $id) {
                                      id
                                      archivedAt
                                    }
                                  }
                                `}
                              >
                                {(unarchive, { loading: unarchiving }) => (
                                  <TitleBarButton
                                    icon={<DopeIcon name="UNARCHIVE" />}
                                    children={'Unarchive'}
                                    onClick={async () => {
                                      await unarchive({ variables: { id: guardrail.id } });

                                      message.success('Guardrail has been unarchived.');
                                    }}
                                    antButtonProps={{
                                      disabled: unarchiving
                                    }}
                                  />
                                )}
                              </Mutation>
                              <TitleBarButton
                                antButtonProps={{
                                  type: 'danger'
                                }}
                                icon={<DopeIcon name="DELETE" />}
                                children={'Delete'}
                                onClick={() => setActiveModal(ModalName.DELETE)}
                              />
                            </>
                          ) : (
                            <TitleBarButton
                              icon={<DopeIcon name="ARCHIVE" />}
                              children={'Archive'}
                              onClick={() => setActiveModal(ModalName.ARCHIVE)}
                              antButtonProps={{
                                disabled: !authz.isAuthorized
                              }}
                            />
                          )}
                        </>
                      }
                      actionsInSecondRow
                    />
                  }
                >
                  <CenteredContainer size="lg" leftAlign>
                    <GridLayout>
                      <div className="guardrail-layout-main">
                        {!authz.isAuthorized && (
                          <Alert
                            type="warning"
                            message={`You do not have effective permissions to edit Guardrail. You must have the "Modify Guardrails" permission for the Project of which this Guardrail is owned by.`}
                            style={{ marginBottom: '24px' }}
                          />
                        )}

                        <GuardrailTitle guardrail={guardrail} saveGuardrailMutationFn={saveGuardrail} />
                        <GuardrailEditor
                          guardrail={guardrail}
                          saveGuardrailMutationFn={saveGuardrail}
                          triggeringEvent={triggeringEvent}
                        />
                      </div>
                      <GuardrailMetaColumn guardrail={guardrail} saveGuardrailMutationFn={saveGuardrail} />
                    </GridLayout>
                  </CenteredContainer>
                </NeoPage>

                <ArchiveGuardrailModal
                  guardrailToArchive={activeModal === ModalName.ARCHIVE ? guardrail : null}
                  onComplete={() => setActiveModal(null)}
                  onCancel={() => setActiveModal(null)}
                />
                <DeleteGuardrailModal
                  guardrailToDelete={activeModal === ModalName.DELETE ? guardrail : null}
                  onComplete={() => {
                    setActiveModal(null);
                    history.push('/guardrails');
                  }}
                  onCancel={() => {
                    setActiveModal(null);
                  }}
                />
              </Root>
            )}
          </Mutation>
        );
      }}
    </GuardrailValidator>
  );
}
export default GuardrailDetailView;

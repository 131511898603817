import {
  getSeverityFromNormalized,
  getSeverityName,
  getSeverityPercent,
  getSeverityStatus,
  SeverityStatus
} from 'constants/ui';
import { Event } from 'typings';

export interface EventDetails {
  title: string;
  description: string;
  region: string;
  product: string;
  eventSourceId: string;
  eventSourceName: string;
  projectId: string;
  accountId: string;
  cloudAccountId: string;
  remediationUrl: string;
  remediationText: string;
  firstObservedAt: Date;
  lastObservedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  dopsSeverity: number;
  severityName: string;
  severityPercent: string;
  severityStatus: SeverityStatus;
  time: Date;
  configRuleName: string;
  detailType: string;
  eventName: string;
  eventSource: string;
  sourceIPAddress: string;
}

export function getUserIdentityDetails(userIdentity) {
  const { principalId, sessionContext, type, arn } = userIdentity || {};
  const { attributes, sessionIssuer } = sessionContext || {};
  const { mfaAuthenticated } = attributes || {};
  const { userName } = sessionIssuer || userIdentity || {};

  return {
    arn,
    mfaAuthenticated,
    principalId,
    userType: type,
    userName
  };
}

export function getEventDetails(event: Event): EventDetails {
  const { eventData = {}, eventDefinitionName } = event || {};
  const { context = {}, detail, message, source } = eventData || {};

  const {
    eventSourceId,
    eventSourceName,
    accountId,
    awsAccountId,
    region,
    projectPath,
    severity,
    rawSeverity,
    configRuleName
  } = context || {};

  const detailType = message?.['detail-type'];
  const { detail: messageDetail } = message || {};
  const { eventName, eventSource, sourceIPAddress } = messageDetail || {};

  const asffEvent = message?.detail || eventData?.detail || detail;
  const finding = asffEvent?.findings?.[0];

  const foundSeverity = rawSeverity || finding?.Severity || finding?.severity;

  const asffTitle = finding?.Title || finding?.title;
  const dopsSeverity =
    severity ||
    (foundSeverity?.Normalized && getSeverityFromNormalized(foundSeverity.Normalized)) ||
    (foundSeverity?.normalized && getSeverityFromNormalized(foundSeverity.normalized));
  const cloudAccountId = event?.cloudAccountId || awsAccountId;

  // TODO: refactor project path parsing out to core util?
  const projectPathSegments = projectPath?.split('/');
  const lastProjectPathSegment = projectPathSegments?.[projectPathSegments?.length - 1];
  const projectIdAndAccountId = lastProjectPathSegment?.split('#');
  const projectId = projectIdAndAccountId?.[0];

  const result = {
    title: asffTitle || eventDefinitionName || 'Event Details',
    description: finding?.Description || finding?.description,
    region: region || event?.region || eventData?.region,
    eventSourceId,
    eventSourceName: eventSourceName || (source === 'aws.securityhub' && 'AWS Security Hub'),
    projectId,
    accountId,
    cloudAccountId,
    product:
      finding?.ProductFields?.['aws/securityhub/ProductName'] ||
      finding?.productfields?.['aws/securityhub/productname'],
    remediationUrl: finding?.Remediation?.Recommendation?.Url || finding?.remediation?.recommendation?.url,
    remediationText: finding?.Remediation?.Recommendation?.Text || finding?.remediation?.recommendation?.text,
    firstObservedAt: finding?.FirstObservedAt || finding?.firstobservedat,
    lastObservedAt: finding?.LastObservedAt || finding?.lastobservedat,
    createdAt: finding?.CreatedAt || finding?.createdat,
    updatedAt: finding?.UpdatedAt || finding?.updatedat,
    dopsSeverity,
    severityName: dopsSeverity && getSeverityName(dopsSeverity),
    severityPercent: dopsSeverity && getSeverityPercent(dopsSeverity),
    severityStatus: getSeverityStatus(dopsSeverity),
    time: message?.time,
    configRuleName,
    detailType,
    eventName,
    eventSource,
    sourceIPAddress
  };

  return result;
}

import { Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { CardSection, GridCard } from 'components/ui/Card';
import DopeIcon from 'components/ui/DopeIcon';
import Label from 'components/ui/Label';
import Markdown from 'components/ui/Markdown';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React from 'react';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

export const AUTOMATION_EVENT_DEFINITION_FIELDS = gql`
  fragment AutomationEventDefinitionFields on EventDefinition {
    id
    key
    name
    description
    severity
    docUrl
    overview
    example
    labels
    context
    vendor
    vendorProduct
    criteria {
      path
      patterns
    }
    filters {
      key
      type
      name
      required
      options {
        name
        path
        foundByProduct
        patterns
      }
    }
    matchers {
      type
      path
      patterns
    }
    outputs
    createdAt
    createdBy
    updatedAt
  }
`;

export const AUTOMATION_EVENT_DEFINITION_QUERY = gql`
  query EventDefinitionsForDevEventDetail($id: [String]) {
    eventDefinitions(id: $id) {
      nodes {
        ...AutomationEventDefinitionFields
      }
    }
  }
  ${AUTOMATION_EVENT_DEFINITION_FIELDS}
`;

const Root = styled.div`
  .labels-card-section {
    margin-bottom: 0px;
  }

  .labels-div {
    margin-bottom: 16px;
  }

  .example-textarea {
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

function EventDetail(props: Props) {
  const eventId = props.match.params.id;
  const { history } = props;

  return (
    <Query query={AUTOMATION_EVENT_DEFINITION_QUERY} variables={{ id: [eventId] }}>
      {({ data, loading, queryError }) => (
        <QueryResult
          data={data}
          loading={loading}
          loadingMessage="Loading Event"
          error={queryError}
          expectSingleNode={true}
          parseNodes={(data) => data.eventDefinitions.nodes}
          entityName="AutomationEventDefinition"
        >
          {(item) => {
            const {
              name,
              description,
              severity,
              docUrl,
              overview,
              labels,
              example,
              context,
              createdAt,
              updatedAt
            } = item;

            const { vendor, vendorProduct: product } = context;

            let formattedExample = example;
            try {
              const exampleObj = JSON.parse(example);
              formattedExample = JSON.stringify(exampleObj, null, 2);
            } catch (e) {
              // console.error({e});
            }

            return (
              <Root>
                <NeoPage
                  titleBar={
                    <TitleBar
                      icon={<DopeIcon name="EVENT" size={20} />}
                      sectionTitle="Events"
                      sectionTitleLinkTo="/dev/events"
                      backLinkTo="/dev/events"
                      backLinkTooltipText="Back to events library"
                      title={name}
                      actions={[
                        <Button
                          key="send"
                          title="Edit &amp; Send Event"
                          type="primary"
                          onClick={() =>
                            history.push({
                              pathname: `/dev/events/${eventId}/send`
                            })
                          }
                        >
                          Send
                        </Button>
                      ]}
                    />
                  }
                >
                  <CenteredContainer>
                    <GridCard>
                      <CardSection label="Name" description={name} />
                      <CardSection label="Description" description={description} />
                      <CardSection label="Vendor" description={vendor} />
                      <CardSection label="Product" description={product} />
                      <CardSection label="Created" description={createdAt && new Date(createdAt).toLocaleString()} />
                      <CardSection label="Updated" description={updatedAt && new Date(updatedAt).toLocaleString()} />
                      {labels && (
                        <>
                          <CardSection label="Labels" classes={{ root: 'labels-card-section' }} />
                          <div className="labels-div">
                            {labels.map((l, idx) => {
                              return (
                                <Label
                                  key={`${l}_${idx}`}
                                  label={l}
                                  onClick={() => {
                                    const searchParams = new URLSearchParams();
                                    searchParams.set('labels', l);

                                    history.push({
                                      pathname: '/dev/events',
                                      search: searchParams.toString()
                                    });
                                  }}
                                />
                              );
                            })}
                          </div>
                        </>
                      )}
                      <CardSection label="Severity" description={severity} />
                      {docUrl && (
                        <>
                          <CardSection label="Documentation" />
                          <a href={docUrl} target="_blank" rel="noopener noreferrer">
                            {docUrl}
                          </a>
                        </>
                      )}
                    </GridCard>
                    {overview && (
                      <GridCard>
                        <>
                          <CardSection label="Overview" />
                          <div className="markdown-wrapper">
                            <Markdown source={overview} />
                          </div>
                        </>
                      </GridCard>
                    )}
                    {formattedExample && (
                      <GridCard>
                        <>
                          <CardSection label="Example" />
                          <TextArea className="example-textarea" value={formattedExample} readOnly autoSize />
                        </>
                      </GridCard>
                    )}
                  </CenteredContainer>
                </NeoPage>
              </Root>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

export default EventDetail;

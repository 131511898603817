import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Root = styled.div``;
const Content = styled.div`
  display: flex;

  > * {
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

export interface Props {
  children: ReactNode;
  classes?: {
    root?: string;
    content?: string;
  };
}

function TableFiltersWrap(props: Props) {
  const { children, classes = {} } = props;
  return (
    <Root className={classes.root || ''}>
      <Content className={classes.content || ''}>{children}</Content>
    </Root>
  );
}

export default TableFiltersWrap;

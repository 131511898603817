import NeoPage, { Sidenav } from 'components/ui/NeoPage';
import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import DevSettingsPage from './pages/DevSettingsPage';
import DopeIconsPage from './pages/DopeIcons';
import EventDetail from './pages/Events/EventDetail';
import Events from './pages/Events/Events';
import EventSend from './pages/Events/EventSend';
import FunctionInputs from './pages/FunctionInputs';
import FunctionDetail from './pages/Functions/FunctionDetail';
import FunctionRun from './pages/Functions/FunctionRun';
import Functions from './pages/Functions/Functions';
import TaskDetail from './pages/Tasks/TaskDetail';

interface Props extends RouteComponentProps<any> {}

function DevSection(props: Props) {
  const screen = props?.match?.params?.screen;

  const sidenav =
    screen === 'functions' || screen === 'events' ? null : (
      <Sidenav
        title="Dev"
        titleLinkTo={'/dev'}
        width={120}
        navConfig={[
          {
            label: 'Events',
            to: '/dev/events'
          },
          {
            label: 'Functions',
            to: '/dev/functions'
          },
          {
            label: 'Function Inputs',
            to: '/dev/function-inputs'
          },
          {
            label: 'Dope Icons',
            to: '/dev/icons'
          },
          {
            label: 'Settings',
            to: '/dev/settings'
          },
          {
            label: 'Tasks',
            to: '/dev/tasks'
          }
        ]}
      />
    );

  return (
    <NeoPage sidenav={sidenav}>
      <Switch>
        <Route path="/dev/events/:id/send" component={EventSend} />
        <Route path="/dev/events/:id" component={EventDetail} />
        <Route path="/dev/events" component={Events} />
        <Route path="/dev/functions/:id/run" component={FunctionRun} />
        <Route path="/dev/functions/:id" component={FunctionDetail} />
        <Route path="/dev/functions" component={Functions} />
        <Route path="/dev/function-inputs" component={FunctionInputs} />
        <Route path="/dev/icons" component={DopeIconsPage} />
        <Route path="/dev/settings" component={DevSettingsPage} />
        <Route path="/dev/tasks/:id" component={TaskDetail} />
        <Route path="/dev" render={() => <Redirect to="/dev/function-inputs" />} />
      </Switch>
    </NeoPage>
  );
}

export default DevSection;

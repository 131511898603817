import styled from 'styled-components';

export const BadgeDiv = styled.div`
  display: inline-block;
  margin-right: 0.25rem;
  padding: 0 0.5rem;
  line-height: 1.5rem;
  border-radius: 0.75rem;
  background-color: ${(p) => p.theme.grey200};

  &.success {
    background-color: #52c41a;
    color: white;
  }

  &.error {
    background-color: #ff4d4f;
    color: white;
  }

  &.warning {
    background-color: #faad14;
    color: white;
  }
`;

export const BadgeGroupDiv = styled.div`
  display: flex;

  .badge-group__item {
    margin-right: 1.5rem;
  }

  .badge-group__item:last-child {
    margin-right: 0;
  }

  .badge-group__item-label {
    display: inline-block;
  }
`;

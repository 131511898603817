import { onError, ErrorResponse } from 'apollo-link-error';
import handleUnauthorized from 'utilities/handleUnauthorized';

const errorLink = onError((errors: ErrorResponse) => {
  console.log(`GraphQL error`, { errors });
  const networkError = errors.networkError as any;
  if (networkError?.statusCode === 401 || networkError?.message === 'Failed to fetch')
    handleUnauthorized();
});

export default errorLink;

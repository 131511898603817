import React from 'react';
import { Modal, Button, message } from 'antd';
import { Op } from 'typings';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

interface Props {
  opToDelete: Op | null;
  onComplete?: () => void;
  onCancel?: () => void;
  entityName?: string;
}

function DeleteOpModal(props: Props) {
  const { entityName = 'Op', opToDelete, onComplete, onCancel } = props;

  return (
    <Mutation
      mutation={gql`
        mutation DeleteOp($id: String!) {
          deleteOp(id: $id)
        }
      `}
    >
      {(deleteOp, { loading, deleting }) => {
        return (
          <Modal
            visible={Boolean(opToDelete)}
            title={`Are you sure you want to delete '${opToDelete ? opToDelete.name || 'Untitled' : ''}'?`}
            closable
            onCancel={() => onCancel && onCancel()}
            footer={
              <>
                <Button
                  onClick={async () => {
                    try {
                      await deleteOp({
                        variables: { id: opToDelete && opToDelete.id }
                      });
                      message.success(`${entityName} was successfully deleted.`);
                      onComplete && onComplete();
                    } catch (e) {
                      message.error(`There was an error deleting this ${entityName}`);
                    }
                  }}
                  disabled={loading || deleting}
                  type={'danger'}
                >
                  {deleting ? 'Deleting' : 'Delete'}
                </Button>
                <Button onClick={() => onCancel && onCancel()}>{'Cancel'}</Button>
              </>
            }
          >
            <>{`Warning! Deleting the "${
              opToDelete ? opToDelete.name || 'Untitled' : ''
            }" ${entityName} is an irreversible action. Continuing will delete this ${entityName} and its related activity permanently.`}</>
          </Modal>
        );
      }}
    </Mutation>
  );
}

export default DeleteOpModal;

import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Button, message, Tooltip } from 'antd';

import { UserGroup } from 'typings';

import DeleteButton from 'components/buttons/DeleteButton';

const DELETE_USER_GROUP_MUTATION = gql`
  mutation DeleteUserGroup($userGroupId: String!) {
    deleteUserGroup(userGroupId: $userGroupId)
      @rest(path: "/user-groups/{args.userGroupId}", method: "DELETE", type: "UserGroup") {
      name
    }
  }
`;

interface Props {
  userGroup: UserGroup;
  classes?: {
    root?: string;
    button?: string;
  };
  onDelete: () => any | void;
  mutate?: boolean;
}

function DeleteGroupButton(props: Props) {
  const { userGroup, classes = {}, onDelete, mutate = true } = props;

  const [deleteUserGroup, { loading }] = useMutation(DELETE_USER_GROUP_MUTATION, {
    onError: () => message.error('Error deleting User Group.')
  });

  if (userGroup.is_system_controlled) {
    return (
      <Tooltip trigger="hover" title="Cannot delete a built-in group">
        <Button icon="delete" className={classes.button || ''} disabled />
      </Tooltip>
    );
  }

  const handleDelete = () => {
    onDelete();
  };

  return (
    <DeleteButton
      id={userGroup.id}
      disabled={loading}
      confirmMessage={`Are you sure you want to delete user group ${userGroup.name}?`}
      onDelete={async (userGroupId) => {
        if (!mutate) return handleDelete();

        await deleteUserGroup({
          variables: { userGroupId }
        });

        message.success(`Successfully deleted user group ${userGroup.name}.`);
        handleDelete();
      }}
    />
  );
}

export default DeleteGroupButton;

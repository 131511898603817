import gql from 'graphql-tag';
import * as Yup from 'yup';

import { client } from 'services/graphql';
import { NewGuardrail, Maybe } from 'typings';

import { GUARDRAIL_FIELDS } from 'queries/fragments/guardrailFields';
import ACTION_PROVIDER_DEFINITIONS from 'components/guardrails/GuardrailEditor/sections/action/definitions';
import TRIGGER_PROVIDER_DEFINITIONS, {
  findTriggerEventTypeDefinition
} from 'components/guardrails/GuardrailEditor/sections/trigger/definitions';
import { transformGuardrailIntoActionConfiguration } from 'components/guardrails/GuardrailEditor/sections/action/definitions';

export const typeDef = `
  type ValidationError {
    name: String
    errors: [String]
    path: String
    message: String 
    inner: [ValidationError] # THIS IS WHAT WE SHOULD BE USING?
  }

  type EnableGuardrailResponse {
    validationError: ValidationError    
    updatedGuardrail: Guardrail
  }

  extend type Mutation {
    enableGuardrail(guardrailId: ID!, isEnabled: Boolean!): EnableGuardrailResponse
  }
`;

export interface ValidationError {
  name?: Maybe<string>;
  errors?: Maybe<string[]>;
  path?: Maybe<string>;
  message?: Maybe<string>;
  inner?: Maybe<ValidationError[]>;
  __typename?: Maybe<string>;
}

function transformValidationError(validationError) {
  if (!validationError) return null;

  return {
    name: validationError.name || null,
    errors: validationError.errors || null,
    path: validationError.path || null,
    message: validationError.message || null,
    __typename: 'ValidationError',
    inner: validationError.inner ? validationError.inner.map(err => transformValidationError(err)) : null
  };
}

export const resolvers = {
  Mutation: {
    enableGuardrail: async (_, args) => {
      const { guardrailId, isEnabled } = args;

      if (isEnabled) {
        const response = await client.query({
          query: gql`
            query GuardrailById($id: [String]) {
              guardrails(id: $id) {
                nodes {
                  ...GuardrailFields
                }
              }
            }
            ${GUARDRAIL_FIELDS}
          `,
          variables: { id: guardrailId }
        });

        const guardrail: NewGuardrail | null =
          (response.data && response.data.guardrails.nodes && response.data.guardrails.nodes[0]) || null;

        if (!guardrail) throw new Error('Could not find guardrail by this ID');

        const validationError = await validateGuardrail(guardrail);

        if (validationError)
          return {
            validationError: transformValidationError(transformValidationError),
            updatedGuardrail: null,
            __typename: 'EnableGuardrailResponse'
          };
      }

      const saveResponse = await client.mutate({
        mutation: gql`
          mutation SaveGuardrail($guardrail: GuardrailInput!) {
            saveGuardrail(guardrail: $guardrail) {
              ...GuardrailFields
            }
          }
          ${GUARDRAIL_FIELDS}
        `,
        variables: {
          guardrail: {
            id: guardrailId,
            isEnabled
          }
        }
      });

      const updatedGuardrail: NewGuardrail | null = (saveResponse.data && saveResponse.data.saveGuardrail) || null;

      return {
        updatedGuardrail,
        validationError: null,
        __typename: 'EnableGuardrailResponse'
      };
    }
  }
};

// why can't I import this function?
export function getActionAppKeyFromGuardrail(guardrail: NewGuardrail): null | string {
  const { functionId, automationFunctionId } = guardrail;

  if (functionId !== null && functionId !== undefined && typeof functionId === 'string') {
    return 'DISRUPTOPS_ACTION';
  }

  if (automationFunctionId !== null && automationFunctionId !== undefined && typeof automationFunctionId === 'string') {
    return 'AUTOMATION_ACTION';
  }

  return null;
}

export async function validateGuardrail(guardrail: NewGuardrail) {
  const { trigger, functionId, automationFunctionId } = guardrail;
  const actionApp = functionId
    ? ACTION_PROVIDER_DEFINITIONS.find(item => item.key === 'DISRUPTOPS_ACTION') || null
    : automationFunctionId
    ? ACTION_PROVIDER_DEFINITIONS.find(item => item.key === 'AUTOMATION_ACTION') || null
    : null;

  const selectedEventSource =
    trigger && trigger.eventSource
      ? TRIGGER_PROVIDER_DEFINITIONS.find(item => item.key === trigger.eventSource) || null
      : null;

  const selectedEventType =
    trigger && trigger.eventSource && trigger.eventType
      ? findTriggerEventTypeDefinition(trigger.eventSource, trigger.eventType)
      : null;

  // BUILD TRIGGER SCHEMA
  const triggerShape: any = {
    eventSource: Yup.string()
      .oneOf(TRIGGER_PROVIDER_DEFINITIONS.map(item => item.key))
      .required('Event Source is required')
  };

  let eventTypeSchema = Yup.string('Event Type is required').required('Event type is required');
  if (selectedEventSource) {
    eventTypeSchema = eventTypeSchema.oneOf(selectedEventSource.eventTypes.map(item => item.key));
  }
  triggerShape.eventType = eventTypeSchema;

  // BUILD ACTION SCHEMA
  let functionIdSchema = Yup.string('Function Id must be a string')
    .required('Function Id is required')
    .typeError('Must select Action');

  if (selectedEventType && actionApp) {
    // ensure that selected action is compatable with trigger
    const availableActions = await actionApp.getAvailableActions(guardrail);

    if (availableActions && actionApp.key !== 'AUTOMATION_ACTION') {
      functionIdSchema = functionIdSchema.oneOf(
        availableActions.map(item => item.key),
        'Selected Action is not compatible with Trigger'
      );
    }
  }

  let actionValidationSchema: any = null;
  if (guardrail.functionId && actionApp && actionApp.getValidationSchema) {
    actionValidationSchema = await actionApp.getValidationSchema(guardrail);
  }

  // BUILD GUARDRAIL SHAPE
  const guardrailShape: any = {
    trigger: Yup.object()
      .shape(triggerShape)
      .required()
    // functionId: functionIdSchema
  };

  if (actionApp) {
    if (actionApp.usesAutomationFunctions) {
      guardrailShape.automationFunctionId = functionIdSchema;
    } else {
      guardrailShape.functionId = functionIdSchema;
    }
  } else {
    guardrailShape.functionId = functionIdSchema;
  }

  if (actionValidationSchema) {
    // TODO: make sure we are validating for saved dynamic configurations.
    guardrailShape.actionConfiguration = actionValidationSchema;
  }

  const schema = Yup.object().shape(guardrailShape);
  const guardrailToValidate = {
    ...guardrail,
    actionConfiguration: transformGuardrailIntoActionConfiguration(guardrail)
  };

  try {
    await schema.validate(guardrailToValidate, {
      abortEarly: false
    });

    return null; // VALID
  } catch (err) {
    return transformValidationError(err);
  }
}

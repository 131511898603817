import React, { SFC } from 'react';
import styled from 'styled-components';
import disruptOpsLogo from 'assets/images/disruptops_logo_full.png';

export interface Props {
  children: React.ReactNode;
  rounded?: boolean;
  backgroundColor?: string;
  withLogo?: boolean;
  className?: string;
}

const StyledDiv = styled.div`
  min-width: 380px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p: Props) => p.backgroundColor || '#FDFDFD'};
  border: 1px solid #e0e0e0;
  ${p => (p.rounded ? 'border-radius: 7px;' : '')};
  margin-bottom: 0px;
  padding: 1px;
`;

const Logo = styled.img`
  min-width: 260px;
  max-width: 60%;
  margin-top: 30px;
  padding: 30px 0px;
`;

const Panel: SFC<Props> = props => {
  const { children, withLogo } = props;
  return (
    <StyledDiv {...props}>
      {withLogo && <Logo src={disruptOpsLogo} alt="DisruptOps Logo" />}
      {children}
    </StyledDiv>
  );
};

export default Panel;

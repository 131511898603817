import { Progress } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';

const QUERY = gql`
  query openIssueCountByService {
    governanceStats {
      openIssueCountByService {
        days
        start
        results {
          cloudService
          count
        }
      }
    }
  }
`;

function OpenIssuesByService() {
  const { loading, error, data } = useQuery(QUERY);

  return (
    <QueryResult loading={loading} data={data} error={error} loadingCenterVertically={false}>
      {() => {
        const results = data?.governanceStats?.openIssueCountByService?.results || [];

        results.sort((a, b) => b.count - a.count);
        const findingCounts = results.slice(0, 15);

        const maxFindingCount = Math.max(...findingCounts.map((row) => row.count));

        return (
          <table>
            <tbody>
              {findingCounts.map((row, index) => {
                const percent = (row.count / maxFindingCount) * 100.0;
                const issuePercent = (row.issueCount / maxFindingCount) * 100.0;
                const eventPercent = (row.eventCount / maxFindingCount) * 100.0;
                const strokeColor = '#46327e';
                // console.log({ percent });
                return (
                  <tr key={`row${index}`}>
                    <td>
                      <Link
                        to={`/issues?status=open&cloudService=${row.cloudService}`}
                        style={{ color: '#5d94e4' }}
                        title={row.cloudService || 'N/A'}
                      >
                        {row.cloudService || 'N/A'}
                      </Link>
                    </td>

                    <td style={{ textAlign: 'right', paddingLeft: '8px' }}>
                      <Link to={`/issues?status=open&cloudService=${row.cloudService}`} style={{ color: '#5d94e4' }}>
                        {row.count.toLocaleString()}
                      </Link>
                    </td>

                    <td style={{ width: '99%', paddingLeft: '8px' }}>
                      <Link to={`/issues?status=open&cloudService=${row.cloudService}`} style={{ color: '#5d94e4' }}>
                        <Progress
                          percent={percent}
                          successPercent={Math.min(issuePercent, eventPercent)}
                          showInfo={false}
                          strokeColor={strokeColor}
                        />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      }}
    </QueryResult>
  );
}

export default OpenIssuesByService;

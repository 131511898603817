import styled from 'styled-components';

interface Props {
  width?: string;
  height?: string;
  rows?: number;
  marginTop?: string;
  marginBottom?: string;
}

const InputOuter = styled.div<Props>`
  display: flex;
  flex-flow: column nowrap;
  width: ${(p: { width?: string; height?: string; rows?: number; marginTop?: string }) => p.width || '100%'};
  height: ${p => p.height || (p.rows ? `${78 + 29 * p.rows}px` : 'auto')};
  ${p => (p.marginTop ? `margin-top: ${p.marginTop};` : '')};
  margin-bottom: ${p => p.marginBottom || '40px'};
`;

export default InputOuter;

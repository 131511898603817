import { MaybePromise, NewGuardrail, Maybe } from 'typings';
import { initFormDynamicConfigs } from 'utilities/function/formTransforms';
import disruptopsAction from './disruptopsAction';
import automationAction from './automationAction';

export interface GuardrailActionProvider {
  key: string; // 'TAKE_ACTION', 'SLACK', 'EMAIL'
  label: string;
  getAvailableActions: (guardrail: NewGuardrail) => MaybePromise<ActionDefinition[]>;
  getRecommendedActions?: (guardrail: NewGuardrail) => MaybePromise<ActionDefinition[]>;
  configurationInput: (props: ActionConfigurationInputProps) => JSX.Element;
  getValidationSchema?: (guardrail: NewGuardrail) => MaybePromise<any>;

  sectionReadView?: (props: SelectActionCollapseReadViewProps) => JSX.Element;
  selectActionReadView?: (props: SelectActionCollapseReadViewProps) => JSX.Element;
  usesAutomationFunctions?: boolean; // defaults to false. if true use guardrail.automationFunctionId
}

export interface ActionDefinition {
  // id: string; // this is function
  key: string; //
  label: string;
  description?: string | null;

  functionInputMappings?: FunctionInputMapping[] | null;
}

export interface FunctionInputMapping {
  key: string;
  valuePath: string;
}

export interface SelectActionCollapseReadViewProps {
  functionId?: Maybe<string>;
  automationFunctionId?: Maybe<string>;
}

export interface ActionConfigurationInputProps {
  guardrail: NewGuardrail;
  onSubmit: (configuration: any) => MaybePromise<void>;
}

export function transformGuardrailIntoActionConfiguration(guardrail: NewGuardrail) {
  const { staticConfiguration = {}, dynamicConfigurations = [], jsonpathConfiguration = {} } = guardrail;
  const initialValues = {
    ...staticConfiguration,
    jsonpathConfiguration,
    dynamicConfiguration: dynamicConfigurations ? initFormDynamicConfigs(dynamicConfigurations) : {}
  };
  return initialValues;
}

export function getActionProviderKeyFromGuardrail(guardrail: NewGuardrail): null | string {
  const { functionId, automationFunctionId } = guardrail;

  if (functionId !== null && functionId !== undefined && typeof functionId === 'string') {
    return 'DISRUPTOPS_ACTION';
  }

  if (automationFunctionId !== null && automationFunctionId !== undefined && typeof automationFunctionId === 'string') {
    return 'AUTOMATION_ACTION';
  }

  return null;
}

const ACTION_PROVIDER_DEFINITIONS: GuardrailActionProvider[] = [disruptopsAction, automationAction];

export default ACTION_PROVIDER_DEFINITIONS;

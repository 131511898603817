import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import NeoPage, { TitleBar } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';

import AutomationActivityList from './AutomationActivityList';
import AutomationActionResult from './AutomationActionResult';

interface ActivitySectionProps
  extends RouteComponentProps<{
    tab?: string;
  }> {}

function AutomationActivityRouter(props: ActivitySectionProps) {
  return (
    <NeoPage titleBar={<TitleBar sectionTitle="Activity" icon={<DopeIcon name="ACTIVITY" size={20} />} />}>
      <Switch>
        <Route path="/activity/:id" component={AutomationActionResult} />
        <Route path="/activity" component={AutomationActivityList} />
      </Switch>
    </NeoPage>
  );
}

export default AutomationActivityRouter;

import React from 'react';

import AssessmentSelector from './AssessmentSelector';

import { ScopeIdSelectorProps } from '../../types';

import { FormField } from 'components/ui/Form';

function ScopeSelectorAssessment(props: ScopeIdSelectorProps) {
  const {
    formRenderProps: { setFieldValue }
  } = props;

  return (
    <FormField name="scope.assessmentId" label="Detector">
      {({ name, value, handleBlur }) => {
        console.log({ value });

        return (
          <AssessmentSelector
            value={value}
            onChange={(selected) => {
              setFieldValue(name, selected);
            }}
            onBlur={handleBlur}
          />
        );
      }}
    </FormField>
  );
}

export default ScopeSelectorAssessment;

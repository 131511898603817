import React, { Component, ReactNode } from 'react';
import { History, Location } from 'react-router-dom';
import { Icon, Tooltip } from 'antd';
import styled from 'styled-components';

import { FilterDefinition, FilterValues } from 'typings';

import FilterBrick from './components/FilterBrick';

const Root = styled.div`
  margin-bottom: 8px;

  .filter-row {
    width: 100%;
    display: flex;
    // display: grid;
    // grid-template-columns: auto 1fr auto; // specific to "select" | "search" | "Button" layout
    margin-bottom: 16px;

    .ant-input {
      border-radius: 0;
    }

    .ant-btn {
      border-radius: 4px;
    }
  }

  .filter-brick-row {
    width: 100%;
    margin-bottom: 4px;

    .filters-label {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.025em;
      margin-right: 4px;
    }

    .filter-brick-wrap {
      padding-right: 12px;
      margin-right: 12px;
      border-right: 1px solid darkgray;
    }

    .filter-bricks-wrap .filter-brick-wrap:last-child {
      padding-right: 0;
      margin-right: 0;
      border-right: 0;
    }

    .clear-all-wrap {
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid darkgray;
    }

    .copy-to-clipboard {
      margin-left: 12px;
      padding-left: 12px;
      border-left: 1px solid darkgray;
    }
  }
`;

interface RenderProps {
  search: string;
  filters: FilterDefinition[];
  filterValues: FilterValues;
  handleFilterChange: (name: string, value: any) => any | void;
  handleSearchChange: (name: string) => any | void;
}

interface Props {
  search?: string;
  filterValues: FilterValues;
  filters?: FilterDefinition[];
  children?: (renderProps: RenderProps) => ReactNode;
  onFilterChange: (name: string | FilterValues, value?: string | string[]) => any | void;
  projectsHash?: any;
  accountsHash?: any;
  history: History;
  location: Location;
  copyToClipboard?: boolean;
}

class FilterBar extends Component<Props> {
  constructor(p: Props) {
    super(p);

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleClearAllFilters = this.handleClearAllFilters.bind(this);
    this.handleCopyToClipboard = this.handleCopyToClipboard.bind(this);
  }

  handleSearchChange(search: string) {
    const { filterValues, filters = [], onFilterChange } = this.props;

    const parsedFilterValues = this.parseSearch(search);
    const parsedFilterKeys = Object.keys(parsedFilterValues);

    const updatedFilters = parsedFilterKeys.reduce(
      (updatedFilters, filterKey) => {
        const filter = filters.find((filter) => filter.qsKey === filterKey);

        if (!filter) return updatedFilters;

        const existingValues = filterValues[filter.name] || [];
        const existingValueArray = Array.isArray(existingValues) ? existingValues : [existingValues];

        updatedFilters[filter.qsKey] = existingValueArray.concat([parsedFilterValues[filter.qsKey]]);

        return updatedFilters;
      },
      { s: parsedFilterValues.s || '' }
    );

    onFilterChange(updatedFilters as FilterValues);
  }

  handleFilterChange(filterQSKey: string) {
    const { onFilterChange } = this.props;

    return (value: any) => {
      onFilterChange(filterQSKey, value);
    };
  }

  handleFilterRemove(filterQSKey: string, filterQSValue: string) {
    const { filters = [], filterValues, onFilterChange } = this.props;

    const filter = filters.find((filter) => filter.name === filterQSKey);

    if (!filter) return;

    const filterValue = filterValues[filterQSKey] || [];
    const filterValueArray = Array.isArray(filterValue) ? filterValue : [filterValue];

    const newFilterValueArray = filterValueArray.filter((filterValue) => filterValue !== filterQSValue);

    onFilterChange(filter.qsKey, newFilterValueArray);
  }

  handleClearAllFilters() {
    const { history, location } = this.props;

    history.push(location.pathname);
  }

  handleCopyToClipboard() {
    const el = document.createElement('textarea');

    el.value = this.filtersToSearch();
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);

    el.select();
    document.execCommand('copy');

    document.body.removeChild(el);
  }

  render() {
    const { search = '', children, filters = [], filterValues, copyToClipboard = true } = this.props;
    const filterArray = this.buildFilterArray();

    return (
      <Root>
        {children && (
          <div className="filter-row">
            {children({
              search,
              filters,
              filterValues,
              handleFilterChange: this.handleFilterChange,
              handleSearchChange: this.handleSearchChange
            })}
          </div>
        )}
        <div className="filter-brick-row">
          {filterArray && filterArray.length > 0 ? (
            <>
              <span className="filters-label">Filters:&nbsp;</span>
              <span className="filter-bricks-wrap">{this.renderFilterBricks(filterArray)}</span>

              {copyToClipboard && (
                <span className="copy-to-clipboard">
                  <Tooltip title="Copy to clipboard">
                    <button className="link-button" title="Copy to clipboard" onClick={this.handleCopyToClipboard}>
                      <Icon type="snippets" />
                    </button>
                  </Tooltip>
                </span>
              )}

              {filterArray.length > 1 && (
                <span className="clear-all-wrap">
                  <button className="link-button" onClick={this.handleClearAllFilters}>
                    Clear all
                  </button>
                </span>
              )}
            </>
          ) : null}
        </div>
      </Root>
    );
  }

  buildFilterArray() {
    const { filterValues, filters = [], accountsHash, projectsHash } = this.props;

    const lookupMap = {
      accountId: accountsHash,
      projectId: projectsHash
    };

    const filtersWithValues = Object.keys(filterValues);

    const filterArray = filtersWithValues.reduce((allFiltersArray, filterName) => {
      const filterValue = filterValues[filterName];

      if (filterValue === null || filterValue === undefined) {
        return allFiltersArray;
      }

      const filter = filters.find((filter) => filter.name === filterName);

      const filterValueArray = Array.isArray(filterValue) ? filterValue : [filterValue];

      const filterArray = filterValueArray.map((filterValue, i) => {
        const transformValueToLabel =
          filter && filter.transformValueToLabel
            ? filter.transformValueToLabel(lookupMap[filter.name])
            : (filterValue) => filterValue;

        const matchingOption =
          filter && filter.options ? filter.options.find((item) => item.key === filterValue) : null;

        const label = matchingOption ? matchingOption.label : transformValueToLabel(filterValue);

        return {
          key: `${filterName}.${i}`,
          name: filterName,
          value: filterValue,
          label,
          filter
        };
      });

      return allFiltersArray.concat(filterArray);
    }, [] as any[]);

    return filterArray;
  }

  renderFilterBricks(filterArray: any[]) {
    return filterArray.map(({ key, label, value, filter }) => (
      <span key={key} className="filter-brick-wrap">
        <FilterBrick
          label={label}
          filter={filter}
          filterValue={value}
          onRemove={() => this.handleFilterRemove(filter.name, value)}
        />
      </span>
    ));
  }

  parseSearch(searchString: string) {
    const { filters = [] } = this.props;

    return parseSearch(searchString, filters);
  }

  filtersToSearch() {
    const { filterValues } = this.props;
    const filtersWithValues = Object.keys(filterValues);

    const searchFilters = filtersWithValues.reduce((searchFilters, filterName) => {
      const filterValue = filterValues[filterName];

      if (filterValue === null || filterValue === undefined) {
        return searchFilters;
      }

      const filterValueArray = Array.isArray(filterValue) ? filterValue : [filterValue];

      return `${searchFilters} ${filterValueArray.map((fv) => `${filterName}:${fv}`).join(' ')}`;
    }, '');

    return searchFilters;
  }
}

export function parseSearch(searchString: string, filters: FilterDefinition[]) {
  const search = filters.reduce((cleanSearchString, filter) => {
    const filterSyntax = `${filter.name}: `;
    if (cleanSearchString.indexOf(filterSyntax) > -1) {
      return cleanSearchString.replace(new RegExp(filterSyntax, 'g'), `${filter.name}:`);
    }
    return cleanSearchString;
  }, searchString.replace(/\s\s+/g, ' '));

  const searchArray = search.split(' ');

  const searchFilterValues = searchArray.reduce(
    (searchFilterValues, searchPart = '') => {
      const existingSearch = searchFilterValues.s;

      const filter = filters.find((filter) => {
        return searchPart.indexOf(`${filter.name}:`) > -1;
      });

      if (!filter) {
        searchFilterValues.s = existingSearch ? `${existingSearch} ${searchPart}` : searchPart;
        return searchFilterValues;
      }

      const filterValue = searchPart.replace(new RegExp(`${filter.name}:`, 'g'), '').trim();

      if (!filterValue || (filter.validateFilterTextValue && !filter.validateFilterTextValue(filterValue))) {
        searchFilterValues.s = existingSearch ? `${existingSearch} ${searchPart}` : searchPart;
        return searchFilterValues;
      }

      const transformedFilterValue = filter.transformKeyToQSValue
        ? filter.transformKeyToQSValue(filterValue)
        : filterValue;

      const { qsKey } = filter;

      if (searchFilterValues[qsKey]) {
        const searchFilterValue = Array.isArray(searchFilterValues[qsKey])
          ? searchFilterValues[qsKey]
          : [searchFilterValues[qsKey]];

        searchFilterValues[qsKey] = [...searchFilterValue, transformedFilterValue];
      } else {
        searchFilterValues[qsKey] = transformedFilterValue;
      }

      return searchFilterValues;
    },
    { s: '' } as any
  );

  return searchFilterValues;
}

export default FilterBar;

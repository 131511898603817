import styled from 'styled-components';

export const DiscoverableIssuesDiv = styled.div`
  table {
    width: 100%;
  }

  td {
    vertical-align: top;
  }

  th:first-child,
  td:first-child {
    padding-right: 1rem;
  }

  th:last-child,
  td:last-child {
    text-align: right;
  }

  tbody tr:nth-child(2n) {
    background-color: #f0f0f0;
  }

  tbody td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';

const Root = styled(Tag)`
  margin-right: 0;

  &.clickable {
    cursor: pointer;
  }
`;

interface LabelProps {
  label: string;
  color?: string;
  title?: string;
  onClick?: () => void;
  linkTo?: string;
  classes?: {
    root?: string;
  };
}

function Label(props: LabelProps) {
  const { label, color, title, onClick, linkTo, classes = {} } = props;
  let contents: React.ReactNode = label;

  if (linkTo) {
    contents = <Link to={linkTo}>{contents}</Link>;
  }

  let rootClasses = 'ui-label';
  if (onClick || linkTo) rootClasses += ' clickable';
  if (classes.root) rootClasses += ` ${classes.root}`;

  return (
    <Root onClick={onClick} className={rootClasses} color={color} title={title}>
      {contents}
    </Root>
  );
}

export default Label;

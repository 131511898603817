import { Button, Descriptions, message, PageHeader, Spin, Table, Typography } from 'antd';
import AuthContext from 'components/app/Auth/AuthContext';
import { FunctionParameterInputSet } from 'components/function';
import { buildSchemaFromAutomationFunction } from 'components/guardrails/GuardrailEditor/sections/action/components/AutomationActionConfigurationInput';
import { CardSection, GridCard } from 'components/ui/Card';
import ErrorAlert from 'components/ui/ErrorAlert';
import { Form } from 'components/ui/Form';
import { getEventDetails } from 'components/util/EventDetail';
import PushContext from 'components/util/Push/PushContext';
import gql from 'graphql-tag';
import jp from 'jsonpath';
import capitalize from 'just-capitalize';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import styled from 'styled-components';
import { ActionResult, AutomationFunction, Event } from 'typings';
import EventDescription from './EventDetail/EventDescription';
import { PUSH_URL } from '../../constants/runtimeConfig';

const { Paragraph } = Typography;

const EVENT_DEFINITION_FUNCTIONS = gql`
  query AutomationFunctionsByEventDefinitionForOpEditor($id: ID!) {
    eventDefinitionFunctions(eventDefinitionId: $id) {
      nodes {
        id
        eventFunctions {
          eventDefinitionId
          functionInputMappings {
            key
            valuePath
          }
        }
      }
    }
  }
`;

const TAKE_ACTION_ON_EVENT = gql`
  mutation runFunction(
    $id: String!
    $accountId: ID
    $regionName: String
    $configuration: [OpConfigurationInput]
    $eventId: String
    $event: JSONObject
  ) {
    runFunction(
      id: $id
      accountId: $accountId
      regionName: $regionName
      configuration: $configuration
      eventId: $eventId
      event: $event
    ) {
      taskId
    }
  }
`;

interface TaskResult {
  taskId: string;
  taskStartedAt: Date;
  result?: ActionResult;
}

interface TaskResultsState {
  taskResults: Array<TaskResult>;
}

const Root = styled.div`
  .ant-page-header-heading-title {
    font-size: 16px;
    font-weight: 700;
  }
`;

interface Props {
  event: Event;
  automationFunction: AutomationFunction;
}

export default function EventTakeAction(props: Props) {
  const { event, automationFunction } = props;
  const { id: automationFunctionId, parameters } = automationFunction || {};
  const { eventDefinitionId } = event;

  const authContext = useContext(AuthContext);

  // query the event definition functions for the event's EventDefinition
  const { data, loading, error } = useQuery(EVENT_DEFINITION_FUNCTIONS, {
    variables: {
      id: eventDefinitionId
    },
    skip: !eventDefinitionId
  });

  // mutation used to run the function when the user clicks the run button
  const [runFunction, { loading: isRunningFunction }] = useMutation(TAKE_ACTION_ON_EVENT);

  // state to keep track of the results of running the funciton
  const [taskResultsState, setTaskResultsState] = useState<TaskResultsState>();

  // push context used to listen for action taken messages
  const pushContext = useContext(PushContext);

  useEffect(() => {
    function handleMessage(messageEvent: any) {
      const { eventType, message } = messageEvent;
      if (eventType !== 'actionTaken') {
        console.log(`Skipping event type ${eventType}, not actionTaken`);
        return;
      }

      const { actionResult } = message;
      const { taskId } = actionResult;
      const { taskResults = [] } = taskResultsState || {};
      const matchingTaskResult = taskResults.find((result) => result.taskId === taskId);
      if (!matchingTaskResult) {
        console.log(`No matching task result for taskId: ${taskId}`);
        return;
      }

      matchingTaskResult.result = actionResult;

      setTaskResultsState({
        taskResults: [...taskResults]
      });
    }

    pushContext?.eventSource.on('message', handleMessage);
    pushContext?.eventSource.connect(PUSH_URL);

    return function cleanup() {
      pushContext?.eventSource.removeListener('message', handleMessage);
      pushContext?.eventSource.close();
    };
  }, [taskResultsState]);

  if (error) return <ErrorAlert error={error} />;
  if (loading || !event || !automationFunction) return <Spin />;

  const { eventData } = event;

  const {
    title,
    description,
    region,
    // eventSourceId,
    // eventSourceName,
    // projectId,
    accountId,
    cloudAccountId
    // product,
    // remediationUrl,
    // remediationText,
    // firstObservedAt,
    // lastObservedAt,
    // createdAt,
    // updatedAt,
    // dopsSeverity,
    // severityName,
    // severityPercent,
    // severityStatus,
  } = getEventDetails(event);

  const { taskResults = [] } = taskResultsState || {};

  const schema = buildSchemaFromAutomationFunction(automationFunction);

  // get the eventDefinitionFunction for the current function
  const eventDefinitionFunction = data?.eventDefinitionFunctions?.nodes?.find((n) => n.id === automationFunctionId)
    ?.eventFunctions?.[0];

  // get the function input mappings
  const { functionInputMappings } = eventDefinitionFunction || {};

  const fieldErrors: any[] = [];

  // reduce the function input mappings to an initial values object for the form
  const initialValues = functionInputMappings?.reduce((accumulator, inputMapping) => {
    const { key, valuePath } = inputMapping;

    let value = null;

    // execute the function input mapping on the event data
    // get a value from the event data instead of a jsonpath
    try {
      value = jp.value(eventData, valuePath);
    } catch (error) {
      // console.error(error);
      fieldErrors.push({ key, error });
    }

    // add the value to the initial values object
    accumulator[key] = value || valuePath;

    return accumulator;
  }, {});

  // console.log({ fieldErrors });

  return (
    <Root>
      <PageHeader title={`Event: ${title}`}>
        {description && <Paragraph>{description}</Paragraph>}

        <EventDescription event={event} />

        <Descriptions title={`Action: ${automationFunction.name}`}>
          <Descriptions.Item label="Description">{automationFunction.description}</Descriptions.Item>
        </Descriptions>

        <Descriptions column={1}>
          <Descriptions.Item label="Account">{cloudAccountId}</Descriptions.Item>
          <Descriptions.Item label="Region">{region}</Descriptions.Item>
        </Descriptions>

        <Form
          validationSchema={schema}
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            const configuration = Object.entries(values).map(([key, value]) => {
              return {
                key,
                value,
                type: 'STATIC'
              };
            });

            const variables = {
              id: automationFunctionId,
              accountId,
              regionName: region,
              configuration,
              eventId: event.id ? event.id : undefined, // set eventId if it's a "triggered" event that's already been stored in the db
              event: event.id ? undefined : event // set event if it's not a "triggered" event that had not been stored in the db
            };

            const response: any = await runFunction({
              variables: variables
            });
            const taskId = response.data.runFunction?.taskId;
            message.success(`Action started successfully. (Task: ${taskId})`);
            const result: TaskResult = {
              taskId,
              taskStartedAt: new Date()
            };
            taskResults.push(result);
            // console.log({ result });
            setTaskResultsState({
              taskResults
            });
          }}
        >
          {(formRenderProps) => {
            const { canSubmit } = formRenderProps;

            const { values } = formRenderProps;

            return (
              <>
                {parameters ? (
                  <FunctionParameterInputSet
                    {...formRenderProps}
                    parameters={parameters}
                    values={values}
                    showDynamicConfigOptions
                  />
                ) : (
                  <div>No parameters to Configure</div>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!canSubmit || isRunningFunction}
                  loading={isRunningFunction}
                  style={{ marginBottom: '12px' }}
                >
                  Take Action
                </Button>
                {taskResults && taskResults.length > 0 && (
                  <GridCard>
                    <CardSection label="Results" />
                    <Table
                      className="function-results"
                      pagination={false}
                      bordered
                      dataSource={taskResults}
                      rowKey={(v) => v.taskId}
                      columns={[
                        {
                          title: 'Started',
                          dataIndex: 'taskStartedAt',
                          key: 'taskStartedAt',
                          render: (taskStartedAt, record) => {
                            const { taskId } = record;
                            return authContext?.isDisruptOpsUser ? (
                              <a target="_blank" rel="noopener noreferrer" href={`/dev/tasks/${taskId}`}>
                                {taskStartedAt.toLocaleString()}
                              </a>
                            ) : (
                              taskStartedAt.toLocaleString()
                            );
                          }
                        },
                        {
                          title: 'Completed',
                          dataIndex: 'result',
                          key: 'completedAt',
                          render: (result) => {
                            const { createdAt, id: actionResultId } = result || {};
                            const date = createdAt && createdAt instanceof Date ? createdAt : new Date(createdAt);
                            const url = actionResultId
                              ? `/activity/${actionResultId}`
                              : automationFunctionId
                              ? `/activity?automationFunctionId=${automationFunctionId}`
                              : '/activity';
                            return result ? (
                              <a target="_blank" rel="noopener noreferrer" href={url}>
                                {date.toLocaleString()}
                              </a>
                            ) : (
                              <Spin />
                            );
                          }
                        },
                        {
                          title: 'Result',
                          dataIndex: 'result',
                          key: 'result',
                          render: (result) => {
                            const { status, id: actionResultId } = result || {};
                            const formattedStatus = status && capitalize(status);
                            const url = actionResultId
                              ? `/activity/${actionResultId}`
                              : automationFunctionId
                              ? `/activity?automationFunctionId=${automationFunctionId}`
                              : '/activity';
                            return result ? (
                              <a target="_blank" rel="noopener noreferrer" href={url}>
                                {formattedStatus}
                              </a>
                            ) : (
                              <Spin />
                            );
                          }
                        }
                      ]}
                    />
                  </GridCard>
                )}
              </>
            );
          }}
        </Form>
      </PageHeader>
    </Root>
  );
}

export function titleToId(title: string) {
  return title.replace(/ /g, '');
}

export function onTabChange(key) {
  document.getElementById(key)?.scrollIntoView({ behavior: 'smooth' });
}

export function getScrollableHeight(el: HTMLDivElement | null) {
  const top = el?.getBoundingClientRect().top || 0,
    height = window.innerHeight;

  console.log(top);

  return height - top - 16 * 4;
}

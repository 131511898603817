import React from 'react';
import { Modal, Button, message } from 'antd';
import { NewGuardrail } from 'typings';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

interface Props {
  guardrailToDelete: NewGuardrail | null;
  onComplete?: () => void;
  onCancel?: () => void;
  entityName?: string;
}

function DeleteGuardrailModal(props: Props) {
  const { entityName = 'Guardrail', guardrailToDelete, onComplete, onCancel } = props;

  return (
    <Mutation
      mutation={gql`
        mutation UninstallGuardrail($id: ID!) {
          uninstallGuardrail(id: $id)
        }
      `}
    >
      {(uninstallGuardrail, { loading, uninstalling }) => {
        return (
          <Modal
            visible={Boolean(guardrailToDelete)}
            title={`Are you sure you want to delete '${
              guardrailToDelete ? guardrailToDelete.name || 'Untitled' : ''
            }'?`}
            closable
            onCancel={() => onCancel && onCancel()}
            footer={
              <>
                <Button
                  onClick={async () => {
                    try {
                      await uninstallGuardrail({
                        variables: { id: guardrailToDelete && guardrailToDelete.id }
                      });
                      message.success(`${entityName} was successfully deleted.`);
                      onComplete && onComplete();
                    } catch (e) {
                      message.error('There was an error deleting this Guardrail');
                    }
                  }}
                  disabled={loading || uninstalling}
                  type={'danger'}
                >
                  {uninstalling ? 'Deleting' : 'Delete'}
                </Button>
                <Button onClick={() => onCancel && onCancel()}>{'Cancel'}</Button>
              </>
            }
          >
            <>{`Warning! Deleting the "${
              guardrailToDelete ? guardrailToDelete.name || 'Untitled' : ''
            }" ${entityName} is an irreversible action. Continuing will delete this Guardrail and its related activity permanently.`}</>
          </Modal>
        );
      }}
    </Mutation>
  );
}

export default DeleteGuardrailModal;

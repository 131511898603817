import gql from 'graphql-tag';

import USER_GROUP_FRAGMENT from '../fragments/userGroup';

export const UPDATE_USER_GROUP_MUTATION = gql`
  mutation UpdateUserGroup($userGroupId: String!, $input: UserGroupInput!) {
    updateUserGroup(userGroupId: $userGroupId, input: $input)
      @rest(path: "/user-groups/{args.userGroupId}", method: "PUT", type: "UserGroup") {
      ...groupFields
    }
  }

  ${USER_GROUP_FRAGMENT}
`;

export default UPDATE_USER_GROUP_MUTATION;

import React from 'react';
import { RecipientFormControlProps } from 'components/recipients/common';
import { useInitSlackAuth } from 'app/sections/Authenticated/pages/OrganizationSettings/pages/Notifications/components/NotificationForm/SlackRecipient/InitSlackAuthButton';
import { FormField } from 'components/ui/Form';
import { SlackRecipientListItem } from 'components/slack/SlackRecipientControl';
import { Button } from 'antd';

function SlackRecipientFormControls(props: RecipientFormControlProps) {
  const initSlackAuthProps = useInitSlackAuth();
  const { prefix = 'config' } = props;

  return (
    <>
      <FormField name={prefix} wrapInFormItem={false}>
        {fieldRenderProps => {
          const { value, handleChange, handleBlur } = fieldRenderProps;

          if (value) {
            return (
              <SlackRecipientListItem
                recipient={{
                  config: value,
                  type: 'SLACK'
                }}
              />
            );
          }

          return (
            <Button
              type="primary"
              onClick={async () => {
                if (!initSlackAuthProps.initSlackAuth) return;

                try {
                  const config = await initSlackAuthProps.initSlackAuth();
                  // set value
                  handleChange(config);
                  handleBlur();
                } catch (e) {
                  // init timed out?
                  return;
                }
              }}
            >
              {'Authorize Slack Recipient'}
            </Button>
          );
        }}
      </FormField>
    </>
  );
}

export default SlackRecipientFormControls;

import React from 'react';
import { Modal, Button, message } from 'antd';
import { NewGuardrail } from 'typings';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';

interface Props {
  guardrailToArchive: NewGuardrail | null;
  onComplete?: () => void;
  onCancel?: () => void;
  entityName?: string;
}

function ArchiveGuardrailModal(props: Props) {
  const { entityName = 'Guardrail', guardrailToArchive, onCancel, onComplete } = props;

  return (
    <Mutation
      mutation={gql`
        mutation ArchiveGuardrail($id: ID!) {
          archiveGuardrail(id: $id) {
            id
            archivedAt
          }
        }
      `}
    >
      {(archiveGuardrail, { loading, archiving }) => {
        return (
          <Modal
            visible={Boolean(guardrailToArchive)}
            title={`Are you sure you want to archive '${
              guardrailToArchive ? guardrailToArchive.name || 'Untitled' : ''
            }'?`}
            closable
            onCancel={() => onCancel && onCancel()}
            footer={
              <>
                <Button
                  onClick={async () => {
                    try {
                      await archiveGuardrail({
                        variables: { id: guardrailToArchive && guardrailToArchive.id }
                      });

                      message.success(`${entityName} was successfully archived.`);
                      onComplete && onComplete(); // update guardrails in current query
                    } catch (e) {
                      message.error('There was an error archiving this Guardrail');
                    }
                  }}
                  disabled={loading || archiving}
                  type={'danger'}
                >
                  {archiving ? 'Archiving' : 'Archive'}
                </Button>
                <Button onClick={() => onCancel && onCancel()}>{'Cancel'}</Button>
              </>
            }
          >
            {`Warning! Archiving the "${
              guardrailToArchive ? guardrailToArchive.name || 'Untitled' : ''
            }" ${entityName} will place it in a disabled state.`}
          </Modal>
        );
      }}
    </Mutation>
  );
}

export default ArchiveGuardrailModal;

import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Table } from 'antd';
import styled from 'styled-components';

import { Issue } from 'typings';

import EmptyState from 'components/ui/EmptyState';
import PushFetch from 'components/util/Push/PushFetch';
import { TableWrap } from 'components/ui/Table';
import QueryResult from 'components/util/QueryResult';
import DateTime from 'components/ui/DateTime';

const Root = styled.div`
  .table-wrap-root {
    margin-bottom: 0;
  }
  .table-wrap-panel {
    border: none;
  }
`;

const ISSUES_ON_RESOURCE = gql`
  query IssuesOnResource($itemId: [String]!, $isExempted: Boolean = false, $isResolved: Boolean = false) {
    issues(itemId: $itemId, isExempted: $isExempted, isResolved: $isResolved) {
      pageInfo {
        total
        current
        size
        sortDirection
        sortBy
      }
      nodes {
        id
        name
        region
        severity
        assessmentId
        parentItemId
        parentItemKey
        updatedAt
        createdAt
      }
    }
  }
`;

export interface Props {
  dopsResourceId: string;
  status: 'open' | 'resolved' | 'exempted';
  onIssueSelected: (issueId: string) => any;
  resourceByIdRefetch: () => any;
}

function ResourceIssueTable(props: Props) {
  const { status, dopsResourceId, resourceByIdRefetch } = props;

  return (
    <Query
      query={ISSUES_ON_RESOURCE}
      variables={{
        isResolved: status === 'resolved' ? true : false,
        isExempted: status === 'exempted' ? true : false,
        itemId: dopsResourceId
      }}
    >
      {({ data, loading, error, refetch }) => (
        <QueryResult data={data} loading={loading} error={error} entityName="issues" loadingCenterVertically={false}>
          {() => {
            return (
              <>
                <PushFetch
                  refetchFn={() => {
                    refetch();
                    resourceByIdRefetch();
                  }}
                  refetchTest={message => {
                    const { event, data: messageData } = message;

                    return event === 'issue' && messageData.event === 'updated';
                  }}
                />

                {!data.issues || !data.issues.nodes || data.issues.nodes.length === 0 ? (
                  <EmptyState title={`No ${status} issues discovered on resource`} />
                ) : (
                  <Root>
                    <TableWrap classes={{ root: 'table-wrap-root', panel: 'table-wrap-panel' }}>
                      <Table
                        rowClassName={() => 'cursor-pointer'}
                        rowKey="issue_id"
                        dataSource={data.issues.nodes}
                        onRow={(row: Issue) => ({
                          onClick: () => props.onIssueSelected(row.id)
                        })}
                        columns={[
                          {
                            title: 'Name',
                            key: 'name',
                            dataIndex: 'name'
                          },
                          {
                            title: 'Severity',
                            align: 'center',
                            key: 'severity',
                            dataIndex: 'severity',
                            render: severity => (severity ? severity : '-')
                          },
                          {
                            title: 'Last updated',
                            key: 'lastUpdated',
                            dataIndex: 'updatedAt',
                            render: dt => (dt ? <DateTime dateTime={new Date(dt)} /> : '-')
                          }
                        ]}
                      />
                    </TableWrap>
                  </Root>
                )}
              </>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

export default ResourceIssueTable;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

const Root = styled.div`
  .ant-btn {
    border: none;
    box-shadow: none;

    &:hover,
    &.ant-btn-background-ghost.ant-btn-primary:hover {
      background-color: ${(p) => p.theme.grey300} !important;
    }
  }
`;

interface TitleBarButtonProps {
  children?: React.ReactNode | React.ReactNodeArray;
  antButtonProps?: ButtonProps;
  icon?: React.ReactNode;

  loading?: boolean;
  disabled?: boolean;

  onClick?: () => void;
  linkTo?: string | null;

  classes?: {
    root?: string;
  };
}

function TitleBarButton(props: TitleBarButtonProps) {
  const { classes = {}, antButtonProps = {}, icon, onClick, linkTo, loading = false, disabled = false } = props;

  const btn = (
    <Button
      ghost
      loading={loading}
      type={'primary'}
      {...antButtonProps}
      className={`${antButtonProps.className || ''} ${icon ? 'btn-with-icon' : ''}`}
      onClick={() => {
        if (onClick) onClick();
      }}
      disabled={disabled}
    >
      {icon}
      {props.children}
    </Button>
  );

  return (
    <Root className={`title-bar-button ${classes.root || ''}`}>{!linkTo ? btn : <Link to={linkTo}>{btn}</Link>}</Root>
  );
}

export default TitleBarButton;

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { PageInfo } from 'typings';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import QueryResult from 'components/util/QueryResult';
import Paginator from 'components/app/Paginator';
import { ColumnProps } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import Table from 'components/ui/Table';
import Label from 'components/ui/Label';

import styled from 'styled-components';
import { FilterRow, FilterItem } from 'components/app/FilterBar/components';
import { SearchFilter, LabelsFilter } from 'components/app/FilterBar/filters';
import { useProjectTree } from 'components/project/ProjectTree';

const Root = styled.div`
  margin-bottom: 64px;

  .filter-row {
    margin: 0 16px 20px;
  }
`;

const ASSESSMENTS_BY_PROJECT = gql`
  query AssessmensByProjects(
    $pageNumber: Int
    $pageSize: Int
    $targetProjectIds: [String]
    $labels: [String]
    $search: String
  ) {
    assessments(
      targetProjectIds: $targetProjectIds
      labels: $labels
      pageSize: $pageSize
      pageNumber: $pageNumber
      search: $search
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        id
        name
        projectId
        targetProjectIds
        labels
        isEnabled
      }
    }
  }
`;

export interface AssessmentNode {
  id: string;
  name: string;
  labels?: string | undefined;
  isEnabled: boolean;
  projectId: string;
  targetProjectIds: string[] | undefined;
}

interface ProjectAssessmentsProps extends RouteComponentProps<{ projectId?: string }> {}

const FILTERS = [
  {
    name: 'labels',
    label: 'Label',
    qsKey: 'labels',
    many: true
  }
];

function ProjectAssessments(props: ProjectAssessmentsProps) {
  const {
    match: {
      params: { projectId }
    }
  } = props;

  const { getParentProjects } = useProjectTree();

  if (!projectId) return null;

  const parentProjects = getParentProjects(projectId);
  const parentProjectIds = parentProjects.map((project) => project.project_id);


  return (
    <Root>
      <Paginator pageSize={20} filters={FILTERS}>
        {(pageRenderProps) => {
          const {
            pageSize,
            pageNumber,
            updateSearchParams,
            replaceFilters,
            filterValues,
            updateSearch,
            pushFilters,
            search
          } = pageRenderProps;

          const columns: ColumnProps<AssessmentNode>[] = [
            {
              title: 'Name',
              key: 'name',
              dataIndex: 'name',
              sorter: true,
              render: (text, item) => {
                const assessmentURL = `/detectors/${item.id}`;
                return <Link to={assessmentURL}>{text}</Link>;
              }
            },
            {
              title: 'Owner',
              key: 'owner',
              dataIndex: 'projectId',
              render: (projectId: string) => {

                // find matching ancestor
                const matchingParentProject = parentProjects.find((parentProject) =>
                  projectId.includes(parentProject.project_id)
                );
                if (!matchingParentProject) return '---'; // this shouldn't happen

                // find node
                return matchingParentProject.name;
              }
            },
            {
              title: 'Scope',
              key: 'scope',
              dataIndex: 'targetProjectIds',
              render: (targetProjectIds: string[] | null) => {
                if (!projectId) return 'Root';
                const scope = targetProjectIds? targetProjectIds : [projectId];

                // find matching ancestor
                const matchingParentProject = parentProjects.find((parentProject) =>
                  scope.includes(parentProject.project_id)
                );
                if (!matchingParentProject) return '---'; // this shouldn't happen

                // find node
                return matchingParentProject.name;
              }
            },
            {
              title: 'Labels',
              key: 'labels',
              dataIndex: 'labels',
              render: (labels) => {
                if (!labels || labels.length === 0) return null;

                return (
                  <div>
                    {labels.map((l, idx) => (
                      <Label
                        key={`${l}_${idx}`}
                        onClick={() => {
                          replaceFilters({
                            search: '',
                            s: '',
                            labels: l
                          });
                        }}
                        label={l}
                      />
                    ))}
                  </div>
                );
              }
            }
          ];

          return (
            <>
              <FilterRow>
                <FilterItem label="Search">
                  <SearchFilter search={search} onSearchChange={updateSearch} />
                </FilterItem>
                <FilterItem label="Labels">
                  <LabelsFilter
                    value={(Array.isArray(filterValues.labels) && filterValues.labels) || []}
                    onChange={(value) => {
                      pushFilters('labels', value);
                    }}
                  />
                </FilterItem>
              </FilterRow>

              <Query
                query={ASSESSMENTS_BY_PROJECT}
                variables={{
                  pageNumber,
                  pageSize,
                  targetProjectIds: [...parentProjectIds, 'ROOT'],
                  labels: filterValues.labels || null,
                  search
                }}
              >
                {({ loading, data, error }) => (
                  <QueryResult loading={loading} data={data} error={error}>
                    {() => {
                      const pageInfo: PageInfo = data.assessments.pageInfo;
                      const assessments: AssessmentNode[] = data.assessments.nodes.filter(assessment => parentProjectIds.includes(assessment.projectId));

                      return (
                        <Table
                          antTableProps={{
                            loading,
                            dataSource: assessments,
                            rowKey: 'id',
                            columns,
                            locale: {
                              emptyText: 'No Detectors in effect'
                            },
                            onChange: (pagination) => {
                              updateSearchParams({
                                page: pagination.current || 1
                              });
                            },
                            pagination: {
                              pageSize,
                              current: pageInfo.current,
                              total: pageInfo.total,
                              onChange: (pageNumber) => {
                                updateSearchParams({
                                  page: pageNumber
                                });
                              }
                            }
                          }}
                        />
                      );
                    }}
                  </QueryResult>
                )}
              </Query>
            </>
          );
        }}
      </Paginator>
    </Root>
  );
}

export default ProjectAssessments;

import { Alert, Select, Spin, Tag } from 'antd';
import { FilterItem, FilterRow } from 'components/app/FilterBar/components';
import CloudAccountFilter from 'components/app/FilterBar/filters/CloudAccount';
import usePaginator from 'components/app/Paginator/usePaginator';
import DateTime from 'components/ui/DateTime';
import DopeIcon from 'components/ui/DopeIcon';
import ErrorAlert from 'components/ui/ErrorAlert';
import Markdown from 'components/ui/Markdown';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import PageHeader from 'components/ui/PageHeader';
import Table from 'components/ui/Table';
import PushFetch from 'components/util/Push/PushFetch';
import QueryResult from 'components/util/QueryResult';
import { SEVERITY_MAP } from 'constants/issue';
import { SORT_ORDER_MAP } from 'constants/ui';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { Query, useQuery } from 'react-apollo';
import { Route } from 'react-router';
import styled from 'styled-components';
import { Issue } from 'typings';
import CompliancePolicyDownloadButton from './components/DownloadButton';

const { Option } = Select;

const Root = styled.div`
  .page-header {
    margin: 0;
  }

  .page-title {
    vertical-align: middle;
  }

  .page-title .ant-tag {
    vertical-align: middle;
  }

  .compliance-filter-row {
    margin: 0;
    margin-right: -16px;
  }

  .policy-section {
    margin-bottom: 40px;
  }

  .download-alerts {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .policy-section-key {
    padding: 0 14px;
    font-weight: normal;
    font-size: 28pt;
    vertical-align: middle;
  }

  .policy-section-name {
    padding: 0 16px;
    vertical-align: middle;
  }

  .policy-control-key {
    width: 90px;
    text-align: center;
    font-weight: bold;
  }

  .policy-control-name {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .policy-control-description {
    margin-bottom: -1em;
  }

  .policy-control-open-issues {
    font-size: 20px;
    font-weight: 800;
    padding: 0 48px;
  }

  .policy-control-warning {
    color: ${(p) => p.theme.warning};
  }

  .policy-control-error {
    color: ${(p) => p.theme.error};
  }

  .policy-control-success {
  }

  .policy-section-control-issue-definition {
    margin-top: 12px;
    margin-bottom: 32px;
  }

  .policy-section-control-issue-definition .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .policy-section-control-issue-definition .overview-table-container {
    margin-bottom: 32px;
  }

  .controls-table-container .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .controls-table-container tr,
  .issues-table-container tr {
    cursor: pointer;
  }

  .controls-table-container tr table tr {
    cursor: default;
  }
`;

const POLICY_TEMPLATES_QUERY = gql`
  query PolicyTemplates($sortBy: String, $sortDirection: SortDirection) {
    policyTemplates(sortBy: $sortBy, sortDirection: $sortDirection) {
      nodes {
        id
        name
        key
        version
        description
        data {
          sections {
            key
            name
            description
            controls {
              key
              name
              description
              scored
              docUrl
              issueDefinitions {
                id
                key
                name
                description
                severity
                itemType
              }
            }
          }
        }
      }
    }
  }
`;

const POLICY_TEMPLATE_QUERY = gql`
  query PolicyTemplate($id: [ID], $accountId: [String], $sortBy: String, $sortDirection: SortDirection) {
    policyTemplates(id: $id, sortBy: $sortBy, sortDirection: $sortDirection) {
      nodes {
        id
        name
        key
        version
        description
        data {
          sections {
            key
            name
            description
            controls {
              key
              name
              description
              scored
              docUrl
              issueDefinitions {
                id
                key
                name
                description
                severity
                itemType
                statistics(accountId: $accountId) {
                  openCount
                }
              }
            }
          }
        }
      }
    }
  }
`;

const POLICY_CONTROL_TABLE_COLUMNS = [
  {
    title: '#',
    dataIndex: 'key',
    align: 'center' as 'center',
    width: '90px',
    render: (key) => {
      return <div className="policy-control-key">{key}</div>;
    }
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (name, row, index) => {
      return (
        <div>
          <div className="policy-control-name">
            {name}
            {row.docUrl && (
              <a href={row.docUrl} target="_blank" title="View documentation" rel="noopener noreferrer">
                &nbsp;
                <DopeIcon name="INFO_OUTLINE" color="rgba(0, 0, 0, 0.35)" />
              </a>
            )}
          </div>
          {row.description && (
            <div className="policy-control-description">
              <Markdown source={row.description} />
            </div>
          )}
        </div>
      );
    }
  },
  {
    title: 'Open Issues',
    dataIndex: 'issueDefinitions',
    align: 'center' as 'center',
    render: (issueDefinitions, row) => {
      const totalOpen = issueDefinitions.reduce((total, issueDefinition) => {
        if (!issueDefinition || !issueDefinition.statistics) {
          return total;
        }
        return total + issueDefinition.statistics.openCount;
      }, 0);

      const className = totalOpen === 0 ? 'success' : row.scored ? 'error' : 'warning';

      return (
        <div className="policy-control-open-issues">
          {issueDefinitions.length === 0 ? (
            <span>-</span>
          ) : (
            <span className={`policy-control-${className}`}>{totalOpen}</span>
          )}
        </div>
      );
    }
  }
];

const ISSUES_QUERY = gql`
  query Issues(
    $accountIds: [String]
    $issueDefinitionIds: [String]
    $isFromSystemControlledAssessment: Boolean
    $pageNumber: Int = 1
    $pageSize: Int = 10
    $sortBy: IssueSortProperties = UPDATED_AT
    $sortDirection: SortDirection = DESC
  ) {
    issues(
      isExempted: false
      isResolved: false
      accountId: $accountIds
      issueDefinitionId: $issueDefinitionIds
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      isFromSystemControlledAssessment: $isFromSystemControlledAssessment
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        id
        key
        name

        severity

        issueDefinitionId
        accountId
        awsAccountId
        region

        itemId
        itemKey
        itemType
        parentItemId
        parentItemKey
        parentItemType

        isExempted
        isResolved

        createdAt
        updatedAt
      }
    }
  }
`;

const ISSUES_TABLE_COLUMNS = [
  {
    title: 'Resource',
    dataIndex: 'itemKey'
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'REGION',
    width: '120px',
    align: 'center' as 'center',
    sorter: true,
    render: (region) => (region ? region : '-')
  },
  {
    title: 'Account',
    width: '140px',
    dataIndex: 'awsAccountId'
  },
  {
    title: 'First found',
    dataIndex: 'createdAt',
    key: 'CREATED_AT',
    sorter: true,
    render: (createdAt) => {
      return <DateTime dateTime={new Date(createdAt)} />;
    }
  },
  {
    title: 'Last observed',
    dataIndex: 'updatedAt',
    key: 'UPDATED_AT',
    sorter: true,
    render: (updatedAt) => {
      return <DateTime dateTime={new Date(updatedAt)} />;
    }
  }
];

const ISSUES_PAGE_SIZE = 10;

interface DownloadState {
  generating: boolean;
  downloadURL: string;
  fileName: string;
  error: string | null;
}

function CompliancePage() {
  const [policyTemplateId, setPolicyTemplateId] = useState<string>();

  const [downloadState, setDownloadState] = useState<DownloadState>({
    generating: false,
    downloadURL: '',
    fileName: '',
    error: null
  });

  const { filters, sortBy, sortDirection, replaceFilter } = usePaginator({
    defaultPageSize: 10,
    defaultSortBy: 'name',
    defaultSortDirection: 'ASC'
  });

  const { accountId, policy: policyKey = 'cis-benchmarks' } = filters || {};

  const selectedPolicyKey = Array.isArray(policyKey) && policyKey.length > 0 ? policyKey[0] : policyKey;

  const { loading: templateLoading, error: templateError, data: templateData } = useQuery(POLICY_TEMPLATE_QUERY, {
    skip: !policyTemplateId,
    variables: {
      id: policyTemplateId,
      accountId: accountId,
      sortBy,
      sortDirection
    }
  });

  const { loading: templatesLoading, error: templatesError, data: templatesData } = useQuery(POLICY_TEMPLATES_QUERY, {
    variables: {
      sortBy: 'name',
      sortDirection: 'ASC'
    }
  });

  useEffect(() => {
    if (!policyTemplateId) {
      let policyTemplate = templatesData?.policyTemplates?.nodes?.find((p) => p.key === selectedPolicyKey);
      if (!policyTemplate && templatesData?.policyTemplates?.nodes?.length > 0) {
        policyTemplate = templatesData.policyTemplates.nodes[0].id;
      }
      if (policyTemplate) {
        setPolicyTemplateId(policyTemplate.id);
      }
    }

    if (policyTemplate && (!selectedPolicyKey || selectedPolicyKey !== policyTemplate.key)) {
      replaceFilter('policy', policyTemplate.key);
      setPolicyTemplateId(policyTemplate.id);
    }
  }, [selectedPolicyKey, templatesData]);

  const policyTemplate = templatesData?.policyTemplates?.nodes?.find((p) => p.key === selectedPolicyKey);

  let sections = policyTemplate?.data?.sections || [];
  const issueDefinitionIds = sections.reduce((issueDefinitionIds, section) => {
    return section.controls.reduce((issueDefinitionIds, control) => {
      return control.issueDefinitions.reduce((issueDefinitionIds, issueDefinition) => {
        issueDefinitionIds.push(issueDefinition.id);
        return issueDefinitionIds;
      }, issueDefinitionIds);
    }, issueDefinitionIds);
  }, []);

  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            sectionTitle={'Compliance'}
            sectionTitleLinkTo="/compliance"
            icon={<DopeIcon name="COMPLIANCE" size={20} />}
          />
        }
      >
        <CenteredContainer size="xl" classes={{ root: 'policy-header-centered-container' }}>
          <>
            <PageHeader
              title={
                <>
                  <Select
                    dropdownMatchSelectWidth={false}
                    size="large"
                    disabled={templateLoading || templatesLoading}
                    value={selectedPolicyKey}
                    onChange={(value) => {
                      const selectedItem = templatesData?.policyTemplates?.nodes?.find((i) => i.key === value);
                      if (selectedItem) {
                        replaceFilter('policy', selectedItem.key);
                        setPolicyTemplateId(selectedItem.id);
                      }
                    }}
                  >
                    {templatesData?.policyTemplates?.nodes?.map((i) => (
                      <Option key={i.key} value={i.key}>
                        {i.name}
                      </Option>
                    ))}
                  </Select>
                  <>
                    &nbsp;&nbsp;
                    <Tag className="version-tag">{policyTemplate?.version}</Tag>
                  </>
                </>
              }
              actions={
                <CompliancePolicyDownloadButton
                  policyTemplateId={policyTemplate?.id}
                  accountIds={accountId as string[]}
                  issueDefinitionIds={issueDefinitionIds}
                  disabled={templateLoading || templatesLoading || !!templateError || !!templatesError}
                  onError={(err) => {
                    setDownloadState({
                      generating: false,
                      error: err.message,
                      downloadURL: '',
                      fileName: ''
                    });
                  }}
                  onLoading={() => {
                    setDownloadState({
                      generating: true,
                      error: null,
                      downloadURL: '',
                      fileName: ''
                    });
                  }}
                  onDownload={(props) => {
                    const { fileName, downloadURL, generating = true } = props;
                    setDownloadState({
                      generating,
                      error: null,
                      downloadURL,
                      fileName
                    });
                  }}
                />
              }
            />

            <QueryResult
              loading={templatesLoading || templateLoading}
              error={templatesError || templateError}
              entityName={templatesLoading ? 'Policies' : templateLoading ? 'Policy' : undefined}
            >
              {() => {
                const selectedPolicyTemplate = templateData?.policyTemplates?.nodes[0];
                sections = selectedPolicyTemplate?.data?.sections || [];
                return (
                  <>
                    <FilterRow
                      classes={{
                        root: 'compliance-filter-row',
                        content: 'filter-row-content filter-row-header'
                      }}
                    >
                      <FilterItem
                        label="Cloud account"
                        classes={{
                          content: 'filter-item-border-right'
                        }}
                      >
                        <CloudAccountFilter
                          onChange={(value) => value && replaceFilter('accountId', value)}
                          value={accountId}
                        />
                      </FilterItem>
                    </FilterRow>

                    <div className="download-alerts">
                      <PushFetch
                        refetchTest={(envelope: any) => {
                          return (
                            envelope.eventType === 'reportReady' && envelope.message.fileName === downloadState.fileName
                          );
                        }}
                        refetchFn={() => {
                          setDownloadState({
                            generating: false,
                            downloadURL: downloadState.downloadURL,
                            fileName: downloadState.fileName,
                            error: null
                          });
                        }}
                      >
                        {downloadState && downloadState.generating && (
                          <Alert
                            showIcon
                            message={
                              <>
                                Preparing report for download &nbsp; <Spin size="small" />
                              </>
                            }
                          />
                        )}
                        {downloadState && !downloadState.generating && downloadState.downloadURL && (
                          <Alert
                            showIcon
                            closable
                            type="success"
                            message={
                              <>
                                <span>Download is ready.</span>{' '}
                                <a href={downloadState.downloadURL} download>
                                  Click here to download.
                                </a>
                              </>
                            }
                          />
                        )}
                      </PushFetch>
                    </div>

                    {sections.length === 0
                      ? 'No Controls defined'
                      : sections.map((section) => {
                          return (
                            <div key={section.key} className="policy-section">
                              <h2>
                                <span className="policy-section-key">{section.key}</span>
                                <span className="policy-section-name">{section.name}</span>
                              </h2>
                              <div className="controls-table-container">
                                <Table
                                  antTableProps={{
                                    loading: templatesLoading || templateLoading,
                                    columns: POLICY_CONTROL_TABLE_COLUMNS,
                                    dataSource: section.controls.map((control) => ({
                                      ...control,
                                      key: control.key.includes('.') ? control.key : `${section.key}.${control.key}`
                                    })),
                                    pagination: false,
                                    defaultExpandAllRows: false,
                                    expandRowByClick: true,
                                    size: 'small',
                                    expandedRowRender: (control) => {
                                      return (
                                        <div>
                                          {control.issueDefinitions
                                            .filter((issueDefinition) => issueDefinition.statistics.openCount !== 0)
                                            .map((issueDefinition) => (
                                              <div
                                                key={issueDefinition.id}
                                                className="policy-section-control-issue-definition"
                                              >
                                                <h3>{issueDefinition.name}</h3>
                                                <p>{issueDefinition.description}</p>

                                                <Query
                                                  query={ISSUES_QUERY}
                                                  variables={{
                                                    issueDefinitionIds: [issueDefinition.id],
                                                    accountIds: accountId,
                                                    isFromSystemControlledAssessment: true
                                                  }}
                                                >
                                                  {(results) => {
                                                    const { loading, error, data, refetch } = results;
                                                    return (
                                                      <>
                                                        <div className="overview-table-container">
                                                          <Table
                                                            antTableProps={{
                                                              rowKey: 'key',
                                                              columns: [
                                                                {
                                                                  title: 'Resource Type',
                                                                  dataIndex: 'resourceTypes',
                                                                  align: 'center' as 'center',
                                                                  render: () => {
                                                                    if (loading) {
                                                                      return <Spin />;
                                                                    }

                                                                    const resourceTypes =
                                                                      data && data.issues
                                                                        ? data.issues.nodes.reduce(
                                                                            (resourceTypes, issue) => {
                                                                              if (
                                                                                resourceTypes.indexOf(
                                                                                  issue.itemType
                                                                                ) === -1
                                                                              ) {
                                                                                resourceTypes.push(issue.itemType);
                                                                              }
                                                                              return resourceTypes;
                                                                            },
                                                                            []
                                                                          )
                                                                        : [];

                                                                    return resourceTypes.length > 0
                                                                      ? resourceTypes.join(', ')
                                                                      : '-';
                                                                  }
                                                                },
                                                                {
                                                                  title: 'Severity',
                                                                  align: 'center' as 'center',
                                                                  dataIndex: 'severity',
                                                                  render: (severity) => {
                                                                    return SEVERITY_MAP[severity];
                                                                  }
                                                                },
                                                                {
                                                                  title: 'Open Issues',
                                                                  align: 'center' as 'center',
                                                                  dataIndex: 'openCount'
                                                                }
                                                              ],
                                                              dataSource: [
                                                                {
                                                                  key: '1',
                                                                  resourceTypes: [],
                                                                  severity: issueDefinition.severity,
                                                                  openCount: issueDefinition.statistics.openCount
                                                                }
                                                              ],
                                                              size: 'small',
                                                              pagination: false
                                                            }}
                                                          />
                                                        </div>
                                                        {error ? (
                                                          <ErrorAlert error={error} />
                                                        ) : (
                                                          <div className="issues-table-container">
                                                            <Route
                                                              render={({ history, location }) => (
                                                                <Table
                                                                  antTableProps={{
                                                                    rowKey: 'id',
                                                                    onRow: (record: Issue, rowIndex: number) => {
                                                                      return {
                                                                        onClick: (event) => {
                                                                          history.push(`/issue/${record.id}`, {
                                                                            onModalClosepath: location.pathname
                                                                          });
                                                                        }
                                                                      };
                                                                    },
                                                                    loading: loading,
                                                                    columns: ISSUES_TABLE_COLUMNS,
                                                                    dataSource:
                                                                      data && data.issues ? data.issues.nodes : [],
                                                                    size: 'small',
                                                                    onChange: async (pagination, filters, sorter) => {
                                                                      await refetch({
                                                                        pageNumber: pagination.current,
                                                                        sortBy: sorter.columnKey,
                                                                        sortDirection:
                                                                          SORT_ORDER_MAP[sorter.order] ||
                                                                          SORT_ORDER_MAP.descend
                                                                      });
                                                                    },
                                                                    pagination: {
                                                                      pageSize: ISSUES_PAGE_SIZE,
                                                                      total:
                                                                        data && data.issues
                                                                          ? data.issues.pageInfo.total
                                                                          : 0
                                                                    }
                                                                  }}
                                                                />
                                                              )}
                                                            />
                                                          </div>
                                                        )}
                                                      </>
                                                    );
                                                  }}
                                                </Query>
                                              </div>
                                            ))}
                                        </div>
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                  </>
                );
              }}
            </QueryResult>
          </>
        </CenteredContainer>
      </NeoPage>
    </Root>
  );
}

export default CompliancePage;

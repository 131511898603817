import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  margin-right: 14px;

  &.success {
    color: ${p => p.theme.success};
  }

  &.pending {
    color: ${p => p.theme.warning};
  }

  &.error {
    color: ${p => p.theme.error};
  }
`;

type ActionResultStatus = 'SUCCESS' | 'NOOP' | 'PENDING' | 'ERROR' | 'TIMEOUT';

interface Props {
  status: ActionResultStatus;
}

const STATUS_MAP: {
  [key in ActionResultStatus]: {
    icon: Exclude<ActionResultStatus, 'TIMEOUT'>;
    label: string;
  };
} = {
  SUCCESS: { label: 'success', icon: 'SUCCESS' },
  NOOP: { label: 'success', icon: 'NOOP' },
  PENDING: { label: 'pending', icon: 'PENDING' },
  ERROR: { label: 'error', icon: 'ERROR' },
  TIMEOUT: { label: 'error', icon: 'ERROR' }
};

function ActionResultIcon(props: Props) {
  const { status } = props;
  const mappedStatus = STATUS_MAP[status];

  const statusString = mappedStatus?.label || 'error';
  const statusIcon = mappedStatus?.icon || 'ERROR';

  return (
    <Root className={statusString}>
      <Tooltip title={`Action ${statusString}`} placement="top">
        <DopeIcon name={statusIcon} />
      </Tooltip>
    </Root>
  );
}

export default ActionResultIcon;

import styled from 'styled-components';

export const EditorPanelDiv = styled.div`
  padding: 1rem;

  .editor-heading {
    font-size: 20px;
  }
`;

export const SubmitButtonWrapDiv = styled.div`
  display: flex;
  margin-bottom: 1rem;

  & > *:first-child {
    margin-right: 1rem;
  }

  & > * {
    max-width: 200px;
  }

  &.fullscreen > * {
    max-width: none;
    width: auto;
  }

  .cancel-btn {
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;
  }
`;

import styled from 'styled-components';

export const TableExpandedRow = styled.div`
  padding: 16px;
  background-color: #fff;
  border-radius: 4px;
  top: -1px;
  position: relative;
  z-index: 1;
  margin-left: -32px;
  margin-right: -32px;
  border: 1px solid;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
  border-color: rgba(0, 0, 0, 0.09);
`;

export default TableExpandedRow;

import React from 'react';
import { useQuery } from 'react-apollo';
import { Table, Tag, Typography } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { EmailConfig, Notification, NotificationRecipient, SlackConfig, SnsConfig } from 'typings';

import Panel from 'components/ui/Panel';
import { TableHeader, TableHeaderActions } from 'components/ui/Table';

import EventScopeOptions, { EventScopeOption } from './fixtures/EventScopeOptions';
import EventTypeOptions, { EventTypeOption } from './fixtures/EventTypeOptions';
import QueryResult from 'components/util/QueryResult';
import NOTIFICATION_LIST_QUERY from './gql/notification-list-query';
import DeleteNotificationButton from './components/DeleteNotificationButton';
import ButtonWithDisabledTooltip from 'components/buttons/ButtonWithDisabledTooltip';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

const { Text } = Typography;

const EventScopeOptionsByKey = EventScopeOptions.reduce((acc: { [key: string]: EventScopeOption }, o) => {
  acc[o.key] = o;

  return acc;
}, {});

const EventTypeOptionsByKey = EventTypeOptions.reduce((acc: { [key: string]: EventTypeOption }, o) => {
  acc[o.key] = o;

  return acc;
}, {});

export const Root = styled.div`
  .table-header-actions {
    flex: 1;

    &_content {
      justify-content: flex-end;
    }
  }

  .ant-pagination {
    padding: 16px;
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }

  tr.ant-table-row:nth-child(even) {
    background-color: transparent;
  }
`;

function getRecipientLabel(notification: NotificationRecipient) {
  if (!notification || !notification.type) return 'No notification type set';
  const type = `${notification.type}`;

  if (type === 'SNS') {
    const snsConfig: SnsConfig = notification.config as SnsConfig;
    return `SNS: ${snsConfig.topicArn}`;
  }

  if (type === 'SLACK') {
    const slackConfig: SlackConfig = notification.config as SlackConfig;
    return `SLACK: ${slackConfig.channel}`;
  }

  if (type === 'EMAIL') {
    const emailConfig = notification.config as EmailConfig;
    return `EMAIL: ${emailConfig.toAddresses.join('; ')}`;
  }

  return type;
}

function NotificationListPage() {
  const variables = { search: '', from: 0, size: 100 }; // TODO: Wire up a paginator.

  const history = useHistory();
  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_NOTIFICATIONS, '*');

  const { loading, error, data } = useQuery(NOTIFICATION_LIST_QUERY, { variables });

  return (
    <QueryResult loading={loading} data={data} error={error}>
      {() => {
        const notifications = data?.notifications?.items || [];

        return (
          <Root>
            <Panel rounded>
              <Table
                dataSource={notifications.map((item) => ({ key: item.id, ...item }))}
                rowClassName={() => 'table-row'}
                title={() => (
                  <TableHeader>
                    <TableHeaderActions
                      classes={{ root: 'table-header-actions', content: 'table-header-actions_content' }}
                    >
                      <ButtonWithDisabledTooltip
                        disabled={!isAuthorized}
                        disabledMessage={authErrorMessage || ''}
                        tooltipPlacement="left"
                        onClick={() => {
                          history.push('/organization-settings/notifications/new');
                        }}
                      >
                        Add Notification
                      </ButtonWithDisabledTooltip>
                    </TableHeaderActions>
                  </TableHeader>
                )}
                columns={[
                  {
                    key: 'name',
                    title: 'Name',
                    render: (item: Notification) => (
                      <Link to={`/organization-settings/notifications/${item.id}`}>{item.name}</Link>
                    )
                  },
                  {
                    key: 'isEnabled',
                    dataIndex: 'isEnabled',
                    title: 'Enabled',
                    render: (isEnabled) => {
                      return <span>{isEnabled ? 'Enabled' : 'Disabled'}</span>;
                    }
                  },
                  {
                    key: 'eventTypes',
                    dataIndex: 'eventTypes',
                    title: 'Events',
                    render: (events) => {
                      return (
                        <>
                          {events.map((event, idx) => {
                            return (
                              <Text key={`events-${idx}`}>{EventTypeOptionsByKey[event]?.label || 'unknown'}</Text>
                            ); //should make this a function that returns the label or 'unknown'
                          })}
                        </>
                      );
                    }
                  },
                  {
                    key: 'scope',
                    dataIndex: 'scope',
                    title: 'Scope',
                    render: (scope) => {
                      return (
                        <Text key={`notification-scope-${scope}`}>
                          {EventScopeOptionsByKey[scope]?.label || 'unknown'}
                        </Text>
                      );
                    }
                  },
                  {
                    key: 'recipients',
                    dataIndex: 'recipients',
                    title: 'Recipients',
                    render: (recipients) => {
                      return (
                        <>
                          {recipients?.map((recipient, idx) => {
                            return <Tag key={`recipient-${idx}`}>{getRecipientLabel(recipient)}</Tag>;
                          })}
                        </>
                      );
                    }
                  },
                  {
                    key: 'delete',
                    render: (item: Notification) => {
                      return <DeleteNotificationButton notification={item} />;
                    }
                  }
                ]}
              />
            </Panel>
          </Root>
        );
      }}
    </QueryResult>
  );
}

export default NotificationListPage;

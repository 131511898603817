import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface CloseButtonProps {
  onClose: Function;
}

const CloseButtonButton = styled.button`
  float: right;
  background: none;
  border: none;
  cursor: pointer;
`;

function CloseButton(props: CloseButtonProps) {
  return (
    <CloseButtonButton className="close" onClick={() => props.onClose()}>
      <CloseOutlined />
    </CloseButtonButton>
  );
}

export default CloseButton;

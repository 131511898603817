import * as yup from 'yup';

const FORM_LOCALE = {
  mixed: {
    default: 'Value is invalid',
    notType: () => 'Value is invalid'
  },
  string: {
    default: 'Value is invalid'
  },
  number: {
    default: 'Value must be a valid number',
    // These match the format specified by Yup
    // eslint-disable-next-line
    min: 'Value must be greater than ${min}',
    // They are not meant to be template literals
    // eslint-disable-next-line
    max: 'Value must be less than ${max}',
    integer: 'Value must be a valid integer'
  }
};

yup.setLocale(FORM_LOCALE);

export default yup;

import React, { Component } from 'react';
import styled from 'styled-components';
import DopeIcon from 'components/ui/DopeIcon';

const Outer = styled.span``;

interface Props {
  size?: string;
}

interface State {
  popupVisible: boolean;
}

const UserAvatar = styled.span`
  border-radius: 100px;
  padding: 3px 3px;
  vertical-align: middle;
  line-height: 12px;
  display: inline-block;
  font-size: ${(p: { size: string }) => (p.size === 'large' ? '24px' : 'auto')};
  // background-color: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.grey200};
`;

class Avatar extends Component<Props, State> {
  hide() {
    this.setState({
      popupVisible: false
    });
  }

  render() {
    const { size } = this.props;

    return (
      <Outer>
        <UserAvatar size={size || ''}>
          <DopeIcon name="USER" />
        </UserAvatar>
      </Outer>
    );
  }
}

export default Avatar;

import { Alert, Button, Col, Collapse, PageHeader, Row, Typography } from 'antd';
import {
  ActionResultContent,
  ActionResultPanelHeader,
  ActionResultTitle,
  ArrowWrap,
  SubsectionTitle
} from 'app/sections/Authenticated/components/ModalsRouter/modals/IssueDetailModal/IssueDetail/IssueDetail';
import DateTime from 'components/ui/DateTime';
import DopeIcon from 'components/ui/DopeIcon';
import JSONTree from 'components/ui/JSONTree';
import Markdown from 'components/ui/Markdown';
import { getEventDetails } from 'components/util/EventDetail';
import PushFetch from 'components/util/Push/PushFetch';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Event } from 'typings';
import { ActionResultIcon } from './components';
import SelectFunction from './components/SelectFunction';
import TakeActionOnEventButton from './components/TakeActionOnEventButton';
import EventDescription from './EventDescription';
import { EVENT_ACTION_RESULTS_QUERY } from './gql';
import { Redirect } from 'react-router';
import { useFeatureFlag, Feature } from 'components/app/FeatureFlag';

const { Paragraph, Text, Title } = Typography;

const EVENT_DETAIL_ACCOUNT_BY_ID = gql`
  query account($id: ID!) {
    account(id: $id) {
      account_id
      nickname
      name
      provider
      environment
      labels
    }
  }
`;

const Root = styled.div`
  .ant-page-header-heading-title {
    font-size: 21px;
  }
`;

interface Props {
  event: Event;
}

function EventDetail(props: Props) {
  const history = useHistory();
  const { event } = props;
  const { id, eventDefinitionId } = event || {};

  const featureFlags = useFeatureFlag([Feature.OPS_NEW_DESIGN]);
  const opsNewDesign = featureFlags[Feature.OPS_NEW_DESIGN];

  const {
    title,
    description,
    region,
    eventSourceId,
    projectId,
    accountId,
    remediationUrl,
    remediationText,
    dopsSeverity
  } = getEventDetails(event);

  const eventId = id || event?.eventId;

  const issueId = event?.eventData?.message?.issue?.id;

  // query action result history for the event
  const {
    data: eventActionResultsData,
    loading: eventActionResultsLoading,
    refetch: eventActionResultsRefetch,
    error: eventActionResultsError
  } = useQuery(EVENT_ACTION_RESULTS_QUERY, {
    variables: {
      eventId
    },
    skip: !eventId || !!issueId
  });

  // query the account for the event
  const { data: accountData } = useQuery(EVENT_DETAIL_ACCOUNT_BY_ID, {
    variables: { id: accountId },
    skip: !accountId || !!issueId
  });
  const { account } = accountData || {};

  if (issueId && (event?.eventData?.eventType === 'issueFound' || event?.eventData?.eventType === 'issueCreated')) {
    return <Redirect to={`/issues/${event?.eventData?.message?.issue?.id}`} />;
  }

  if (!event) {
    return (
      <Alert
        type="warning"
        message="No matching Event found"
        description={<Typography>{`There was no matching event found.`}</Typography>}
      />
    );
  }

  // account info to display
  const { environment } = account || {};

  // asff event info to display
  const asffEvent = event.eventData?.message?.detail || event.eventData?.detail;

  // asff finding info to display
  // TODO: refactor out to collector?
  // TODO: handle more than one finding per event?
  const finding = asffEvent?.findings?.[0];

  // asff finding resource info to display
  // TODO: handle more than one resource per finding?
  const resource = finding?.Resources?.[0] || finding?.resources?.[0];
  const resourceType = resource?.Type || resource?.type;

  // query string params for op creation link
  const createOpQueryString = getCreateOpQueryString(
    eventDefinitionId,
    title,
    projectId,
    eventSourceId,
    accountId,
    region,
    dopsSeverity,
    environment,
    resourceType
  );

  const createOpUrl = opsNewDesign ? `/ops/create/confirm${createOpQueryString}` : `/ops/details${createOpQueryString}`;

  console.log({ opsNewDesign, createOpUrl });

  const pageHeaderButtons = [
    <a key="createOp" href={createOpUrl} target="_blank" rel="noopener noreferrer" title="Prevent future occurrences">
      <Button className="btn-with-icon" type={'primary'}>
        <DopeIcon name="ISSUE_TAKE_ACTION" />
        Create an automation
      </Button>
    </a>
  ];

  return (
    <Root className="event-detail">
      <QueryResult data={eventActionResultsData} loading={eventActionResultsLoading} error={eventActionResultsError}>
        {() => {
          const { automationActionResults = {} } = eventActionResultsData || {};
          const { nodes: actionResults } = automationActionResults || {};
          // const { nodes: automationResults } = automationEvents;

          return (
            <>
              <PushFetch
                eventType="actionTaken"
                refetchTest={(envelope: any) => {
                  if (!envelope || !envelope.message || !envelope.message.actionResult) return false;
                  if (!envelope || !envelope.message || !envelope.message.actionResult) return false;
                  if (envelope.message.actionResult.eventId === id) return true;
                  return false;
                }}
                refetchFn={eventActionResultsRefetch}
              />

              <PageHeader title={title} extra={pageHeaderButtons}>
                {description && <Paragraph>{description}</Paragraph>}

                <Row gutter={16}>
                  <Col lg={12}>
                    <EventDescription event={event} />
                  </Col>

                  <Col lg={12}>
                    {/* Take Action */}

                    <Paragraph>
                      <Title level={4}>Take Action</Title>
                      <Text>Take action on this finding</Text>
                    </Paragraph>

                    <SelectFunction
                      eventDefinitionId={eventDefinitionId}
                      onSelectAction={(automationFunction) =>
                        history.push(`/events/${eventId}/actions/${automationFunction.id}`)
                      }
                    />

                    {/* Action History  */}
                    {actionResults?.length > 0 && (
                      <>
                        <Paragraph>
                          <Title level={4} style={{ fontSize: '16px', fontWeight: 700 }}>
                            History
                          </Title>
                          <Text>Previous actions taken on this finding</Text>
                        </Paragraph>
                        <Collapse style={{ marginBottom: '16px' }}>
                          {actionResults.map((actionResult, idx) => {
                            return (
                              <Collapse.Panel
                                key={`action-result_${idx}`}
                                header={
                                  <ActionResultPanelHeader>
                                    <ActionResultIcon status={actionResult.status} />
                                    <ActionResultTitle>{actionResult.function.name}</ActionResultTitle>
                                    <DateTime dateTime={new Date(actionResult.updatedAt)} />
                                    <ArrowWrap>
                                      <DopeIcon name="EXPAND_MORE" />
                                    </ArrowWrap>
                                  </ActionResultPanelHeader>
                                }
                              >
                                <ActionResultContent>
                                  {actionResult.op && (
                                    <>
                                      <div className="section">
                                        <SubsectionTitle>Source OP</SubsectionTitle>
                                        <Link to={`/ops/details/${actionResult.op.id}`}>{actionResult.op.name}</Link>
                                      </div>
                                      <div className="section">
                                        <SubsectionTitle>Previous OP Actions</SubsectionTitle>
                                        <Link to={`/ops/activity?opId=${actionResult.op.id}`}>Op Activity</Link>
                                      </div>
                                    </>
                                  )}

                                  {actionResult.function && (
                                    <div className="section">
                                      <SubsectionTitle>Description</SubsectionTitle>
                                      <Markdown source={actionResult.function.description} />
                                    </div>
                                  )}

                                  {actionResult.functionInput && actionResult.status === 'PENDING' && (
                                    <div className="section">
                                      <SubsectionTitle>Action Inputs</SubsectionTitle>
                                      <JSONTree data={actionResult.functionInput} />
                                    </div>
                                  )}

                                  {actionResult.detail && delete actionResult.detail.source_event && (
                                    <div className="section">
                                      <SubsectionTitle>Action Details</SubsectionTitle>
                                      <JSONTree data={actionResult.detail} />
                                    </div>
                                  )}

                                  {actionResult.status === 'PENDING' && (
                                    <TakeActionOnEventButton
                                      actionResultId={actionResult.id}
                                      actionFunctionName={actionResult.function.name}
                                    />
                                  )}
                                </ActionResultContent>
                              </Collapse.Panel>
                            );
                          })}
                        </Collapse>
                      </>
                    )}
                    {(remediationText || remediationUrl) && (
                      <Paragraph>
                        <Title level={4}>Manual Remediation Steps</Title>
                        {remediationText && <Text>{remediationText}</Text>}
                        {remediationUrl && (
                          <Text>
                            <br />
                            <a target="_blank" rel="noopener noreferrer" href={remediationUrl}>
                              {remediationUrl} <DopeIcon name="EXTERNAL_LINK" />
                            </a>
                          </Text>
                        )}
                      </Paragraph>
                    )}
                  </Col>
                </Row>
              </PageHeader>
            </>
          );
        }}
      </QueryResult>
    </Root>
  );
}

export default EventDetail;

function getCreateOpQueryString(
  eventDefinitionId: string | undefined,
  title: any,
  projectId: any,
  eventSourceId: any,
  accountId: any,
  region: any,
  dopsSeverity: any,
  environment: any,
  resourceType: any
) {
  const createOpQueryStringParams: string[] = [];
  if (eventDefinitionId) createOpQueryStringParams.push(`initTriggerId=${eventDefinitionId}`);
  if (!eventDefinitionId && title) {
    createOpQueryStringParams.push('allFindings=true');
    createOpQueryStringParams.push('customFiltersPath=$.message.detail.findings[0].Title');
    createOpQueryStringParams.push(`customFiltersPatterns=${title}`);
  }
  if (projectId) createOpQueryStringParams.push(`projectId=${projectId}`);
  if (eventSourceId) createOpQueryStringParams.push(`eventSourceId=${eventSourceId}`);
  if (accountId) createOpQueryStringParams.push(`accountIds=${accountId}`);
  if (region) createOpQueryStringParams.push(`regions=${region}`);
  if (dopsSeverity) createOpQueryStringParams.push(`severity=${dopsSeverity}`);
  if (environment) createOpQueryStringParams.push(`environments=${environment}`);
  if (resourceType) createOpQueryStringParams.push(`resourceType=${resourceType}`);
  // if (labels) createOpQueryStringParams.push(`accountLabels=${labels}`);
  // if (resourceTags) createOpQueryStringParams.push(`tags=${resourceTags}`);
  const createOpQueryString = createOpQueryStringParams && `?${createOpQueryStringParams.join('&')}`;
  return createOpQueryString;
}

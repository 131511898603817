import React, { SFC } from 'react';
import styled from 'styled-components';

interface Props {
  borderColor?: string;
  borderWidth?: string;
  maxWidth?: string;
  className: string;
  height?: string;
  onClick?: () => any;
  opacity?: string;
  margin?: string;
  padding?: string;
}

// Container for a single Card's contents
const CardOuter = styled.div`
  justify-self: stretch;  
  opacity: ${(p: Props) => p.opacity || '1'};
  position: relative;
  border-style: solid;
  border-color: #ebebeb;
  border-width: 1px;
  ${(p: Props) => (p.height ? `height: ${p.height};` : '')};
  overflow: visible;
  z-index: 5;
  ${p => (p.maxWidth ? `max-width: ${p.maxWidth};` : '')}
  ${p => (p.margin ? `margin: ${p.margin};` : '')};
  ${p => (p.padding ? `padding: ${p.padding};` : '')};
  }
  
  &::after {
    content: "";
    border-radius: inherit;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, .15);
    opacity: 0;
    transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  &:hover{
    z-index: 7;
  }
  &:hover::after {
    opacity: 1;
    z-index : 6;
  }
`;

// This is necessary to clip the overflowing contents into the
// curved border. Originally the contents inherited the border-radius,
// but that created a small gap in the corner of the cards.
const CardInner = styled.div`
  position: relative;
  overflow: hidden;
  z-index: inherit;
  height: 100%;
  }
`;
const Card: SFC<Props> = props => {
  return (
    <CardOuter {...{ ...props, className: props.className + 'outer' }}>
      <CardInner {...{ ...props, className: props.className + 'inner' }} onClick={undefined}>
        {props.children}
      </CardInner>
    </CardOuter>
  );
};

export default Card;

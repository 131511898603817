import React from 'react';
import { MainNavItemDiv } from './MainNavItem.styles';

interface MainNavItemProps {
  icon: any;
  collapsed?: boolean;
  children: JSX.Element | string;
  title?: string;
}

function MainNavItem(props: MainNavItemProps) {
  const { icon, children, title } = props;
  return (
    <MainNavItemDiv>
      <div className="collapse-wrapper" title={title}>
        <div className="icon">{icon}</div>
        <div className="collapse-content">{children}</div>
      </div>
    </MainNavItemDiv>
  );
}

export default MainNavItem;

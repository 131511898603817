import { FormikProps } from 'formik';
import React from 'react';
import FunctionParameterInputSet from './FunctionParameterInputSet';
import ProjectEditor from 'designSystem/ProjectEditor/ProjectEditor';
import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';

interface Props {
  parameters: any;
  formikProps: FormikProps<any>;
}

function EditFilters(props: Props) {
  const {
    formikProps,
    parameters,
    formikProps: { values: detector, setFieldValue }
  } = props;

  function onProjectChange(change) {
    setFieldValue('targetProjectIds', change);
  }

  return (
    <>
      <HorizontalLabel label="Project Scope" form>
        <ProjectEditor
          onChange={onProjectChange}
          values={detector.targetProjectIds || [detector.projectId]}
          rootProjectId={detector.projectId}
        />
      </HorizontalLabel>

      <FunctionParameterInputSet parameters={parameters} {...formikProps} showDynamicConfigOptions />
    </>
  );
}

export default EditFilters;

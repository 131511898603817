import { Icon, Spin, Typography } from 'antd';
import { GridCard } from 'components/ui/Card';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

const QUERY = gql`
  query ResourcesAndOpsQuery {
    resource_group_and_count(fields: ["dops_resource_type"]) {
      field
      results {
        key
        count
      }
    }

    ops(isEnabled: true) {
      pageInfo {
        total
      }
    }
  }
`;

export default function ResourcesAndOpsKpi() {
  const { loading, error, data } = useQuery(QUERY);

  const resourceTypes = data?.resource_group_and_count?.results;
  const totalResourceCount = resourceTypes?.reduce((acc, item) => {
    acc += item.count;
    return acc;
  }, 0);

  const opCount = data?.ops?.pageInfo?.total;

  return (
    <GridCard>
      <Typography.Paragraph className="kpi kpi3">
        {error ? (
          <Icon type="exclamation-circle" title={error.message} />
        ) : loading ? (
          <Title level={2} style={{ marginBottom: '8px' }}>
            <Spin />
          </Title>
        ) : (
          <Link to={`/inventory/resources`}>
            <Title level={2} style={{ marginBottom: '8px' }}>
              {totalResourceCount.toLocaleString()}
            </Title>
          </Link>
        )}
        <Link to={`/inventory/resources`}>
          <Text>Resources</Text>
        </Link>
        <br />
        <Link to={`/ops`}>
          <Text type="secondary">
            {opCount ? (
              <>
                {opCount.toLocaleString()} Op{opCount === 1 ? '' : 's'}
              </>
            ) : (
              <>&nbsp;</>
            )}
          </Text>
        </Link>
      </Typography.Paragraph>
    </GridCard>
  );
}

import { InMemoryCache } from 'apollo-cache-inmemory';

function dataIdFromObject(data: any): string {
  const typename = data.__typename;
  if (typename === 'ConfiguredOp') return data.configured_op_id;
  if (typename === 'OpRun') return data.run_id;
  if (typename === 'AWSTag') return `AWSTag:${data.Key}:${data.Value}`;
  if (typename === 'OpSchedule') return data.scheduled_run_id;
  if (typename === 'Identity') return 'Identity';
  if (typename === 'Feed') return data.feed_id;
  if (typename === 'Project' || typename === 'ClientProject') {
    const { project_id } = data;

    if (project_id) return project_id; // legacy/trinity
  }
  if (typename === 'ClientAccount') return data.account_id;
  if (typename === 'UserGroup') return data.id;

  return data.id || data._id;
}

const cache = new InMemoryCache({ dataIdFromObject });

export default cache;

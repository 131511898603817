import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import { Button, Row, Alert } from 'antd';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ME_QUERY } from 'queries';

import { PanelSubtitle } from 'components/ui/Text';
import Input, { MultiInput } from 'components/ui/Input';
import QueryResult from 'components/util/QueryResult';
import { CenteredContainer } from 'components/ui/NeoPage';
import { GridCard } from 'components/ui/Card';
import PageHeader from 'components/ui/PageHeader';

const Root = styled.div`
  width: 100%;
`;

interface Props extends RouteComponentProps<any> {}

const inputHeight = '80px';

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  username: Yup.string()
    .email()
    .required('Required')
});

interface FormValues {
  firstName: string;
  lastName: string;
  username: string;
}

interface State {
  result: any;
}

const AlertWrap = styled.div`
  margin-top: 10px;
`;

class UpdateProfilePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      result: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);
  }

  async handleSubmit(formValues: FormValues, actions: any) {
    // no api call at this time.
    setTimeout(() => {
      // put action to save settings?
      this.setState({
        result: 'success' // or 'failure' ??
      });
      actions.setSubmitting(false);
      // actions.resetForm({});
    }, 1000);
  }

  handleCloseAlert() {
    this.setState({ result: null });
  }

  render() {
    const { result } = this.state;

    return (
      <CenteredContainer size={'md'} leftAlign>
        <PageHeader title="Profile" titleLevel={2} />
        <GridCard>
          <Root>
            <Query query={ME_QUERY}>
              {({ loading, error, data }) => (
                <QueryResult loading={loading} error={error} data={data}>
                  {() => {
                    const { user } = data;
                    return (
                      <Formik
                        initialValues={{
                          firstName: user.first_name,
                          lastName: user.last_name,
                          username: user.username
                        }}
                        onSubmit={this.handleSubmit}
                        validationSchema={FormSchema}
                      >
                        {({ values, handleChange, handleBlur, handleSubmit, isSubmitting, errors }) => (
                          <form onSubmit={handleSubmit}>
                            <PanelSubtitle>Update profile</PanelSubtitle>
                            <MultiInput>
                              <Input
                                title="First name"
                                width="49%"
                                name="firstName"
                                error={errors.firstName as string}
                                value={values.firstName}
                                height={inputHeight}
                                placeholder="First name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                              <Input
                                title="Last name"
                                width="49%"
                                name="lastName"
                                error={errors.lastName as string}
                                value={values.lastName}
                                height={inputHeight}
                                placeholder="Last name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                              />
                            </MultiInput>
                            <Input
                              title="Current email"
                              height={inputHeight}
                              name="username"
                              error={errors.username as string}
                              value={values.username}
                              placeholder="your@email.com"
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Row justify="end" type="flex">
                              <Button type="primary" loading={isSubmitting} htmlType="submit">
                                {isSubmitting ? 'Updating...' : 'Update profile'}
                              </Button>
                            </Row>
                            {result && (
                              <AlertWrap>
                                <Alert
                                  message={
                                    result === 'success'
                                      ? 'Profile successfully updated(fake)'
                                      : 'Failed to update profile.'
                                  }
                                  closable
                                  onClose={this.handleCloseAlert}
                                  showIcon
                                  type={result}
                                />
                              </AlertWrap>
                            )}
                          </form>
                        )}
                      </Formik>
                    );
                  }}
                </QueryResult>
              )}
            </Query>
          </Root>
        </GridCard>
      </CenteredContainer>
    );
  }
}

export default UpdateProfilePage;

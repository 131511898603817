import React from 'react';
import DopeIcon from 'components/ui/DopeIcon';
import { CloudVendorIcon } from 'components/cloudVendors';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { ConfirmationModalState, OnSelectTriggerFunc } from '../../../../OpEditor';
import IconButton from 'components/ui/IconButton';
import { Root } from './TriggerHeaderStyles';
import { AutomationEventDefinition, Op } from 'typings';
import SelectTrigger from '../../SelectTrigger';

interface TriggerHeaderProps {
  eventDefinition: AutomationEventDefinition | null;
  op: Op | undefined;
  isAllFindingsSelected: boolean;
  onSelectTrigger: OnSelectTriggerFunc;
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
}

function TriggerHeader(props: TriggerHeaderProps) {
  const { eventDefinition, op, isAllFindingsSelected, onSelectTrigger, setConfirmationModal } = props;

  const icon = eventDefinition ? (
    <CloudVendorIcon vendor={eventDefinition.vendor} />
  ) : (
    <DopeIcon name="TRIGGER" size={20} />
  );
  const existingProjectId = op?.projectId || null;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: existingProjectId || '*' }]
  });
  const isAuthorized = existingProjectId ? authz.isAuthorized : true;
  const editingTrigger = !eventDefinition && !isAllFindingsSelected;

  const triggerTitle = editingTrigger ? 'Select Trigger' : eventDefinition?.name;

  return (
    <Root>
      <div className="icon-wrap">{icon}</div>
      <div>
        <div className="trigger-label">Trigger</div>
        <div className="section-title-wrap">
          <span data-testid="event-name" className="section-title">
            {triggerTitle || 'All Findings'}
          </span>
          {isAuthorized && !editingTrigger && (
            <IconButton
              type="ghost"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                setConfirmationModal({
                  title: 'Clear Trigger',
                  description: 'This section will be cleared and any filter settings will be lost.',
                  onConfirm: () => {
                    onSelectTrigger({ eventDefinition: undefined, isAllFindingsSelected: false });
                    setConfirmationModal(null); // close modal
                  }
                });
              }}
            >
              <DopeIcon name="REMOVE" />
            </IconButton>
          )}
        </div>
        {editingTrigger && <SelectTrigger onSelect={onSelectTrigger} />}
      </div>
    </Root>
  );
}

export default TriggerHeader;

import React from 'react';
import { useQuery } from 'react-apollo';
import { Select } from 'antd';
import gql from 'graphql-tag';

const UNIQUE_EVENT_VENDOR_PRODUCTS_QUERY = gql`
  query UniqueEventVendorProducts {
    uniqueEventVendorProducts
  }
`;

interface EventVendorProductFilterProps {
  value?: string | null;
  onChange: (value: string) => void;
}

function EventVendorProductFilter(props: EventVendorProductFilterProps) {
  const { value, onChange } = props;

  const { data, error, loading } = useQuery(UNIQUE_EVENT_VENDOR_PRODUCTS_QUERY);

  return (
    <Select
      value={value || undefined}
      style={{ minWidth: '200px', maxWidth: '500px' }}
      onChange={onChange}
      dropdownMatchSelectWidth={false}
      placeholder="Select Vendor Product"
      maxTagCount={2}
      maxTagTextLength={48}
      maxTagPlaceholder={(extraValues) => {
        return <>+&nbsp;{extraValues.length}</>;
      }}
    >
      {error && (
        <Select.Option disabled key="error" value="Could not load products">
          Could not load vendors
        </Select.Option>
      )}

      <Select.Option key="all" value={'all'}>
        {'All Products'}
      </Select.Option>
      {!loading &&
        data?.uniqueEventVendorProducts?.map((o, idx) => {
          return (
            <Select.Option key={`${o}_${idx}`} value={o}>
              {o}
            </Select.Option>
          );
        })}
    </Select>
  );
}

export default EventVendorProductFilter;

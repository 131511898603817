import { DeleteOutlined, SettingFilled } from '@ant-design/icons';
import { Button } from 'ant4x';
import DetailPanel, {
  DetailPanelActions,
  DetailPanelScollable,
  DetailPanelScrollableSection
} from 'designSystem/DetailPanel/DetailPanel';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Op, Project } from 'typings';
import OpBanner from '../OpsCommon/OpBanner/OpBanner';
import OpDetailAction from './Action/Action';
import OpDetailActivity from './Activity/Activity';
import DeleteOpModal from './DeleteOpModal/DeleteOpModal';
import OpDetailGeneral from './General/General';
import OpDetailNotifications from './Notifications/Notifications';
import OpDetailTrigger from './Trigger/Trigger';

interface OpDetailProps {
  closeLink: string;
  contextData: any;
  editLink: string;
  onRefetchNeeded: () => void;
  op: Op;
  projects: Project[];
}

function OpDetail(props: OpDetailProps) {
  const { closeLink, contextData, editLink, onRefetchNeeded, op, projects } = props,
    history = useHistory(),
    [project, setProject] = useState<Project | undefined>(),
    [deleteOpModalVisible, setDeleteOpModalVisible] = useState<boolean>(false);

  useEffect(() => {
    const project = projects.find((project) => project.project_id === op.projectId);
    setProject(project);
  }, [op, projects]);

  return (
    <DetailPanel closeLink={closeLink}>
      {op && (
        <>
          <DeleteOpModal
            closeLink={closeLink}
            onClosed={() => setDeleteOpModalVisible(false)}
            onSuccess={() => onRefetchNeeded && onRefetchNeeded()}
            op={op}
            visible={deleteOpModalVisible}
          />
          <OpBanner isDetailPanel op={op} />

          <DetailPanelActions>
            <Button
              type="primary"
              icon={<SettingFilled />}
              onClick={() => {
                history.push(editLink);
              }}
            >
              Configure Op
            </Button>
            <Button icon={<DeleteOutlined />} onClick={() => setDeleteOpModalVisible(true)}>
              Delete Op
            </Button>
          </DetailPanelActions>

          <DetailPanelScollable>
            <DetailPanelScrollableSection title="General">
              <OpDetailGeneral op={op} project={project} />
            </DetailPanelScrollableSection>
            <DetailPanelScrollableSection title="Activity">
              <OpDetailActivity op={op} />
            </DetailPanelScrollableSection>
            <DetailPanelScrollableSection title="Trigger">
              <OpDetailTrigger op={op} contextData={contextData} />
            </DetailPanelScrollableSection>
            <DetailPanelScrollableSection title="Action">
              <OpDetailAction op={op} contextData={contextData} editLink={editLink} />
            </DetailPanelScrollableSection>
            <DetailPanelScrollableSection title="Notification">
              <OpDetailNotifications op={op} contextData={contextData} />
            </DetailPanelScrollableSection>
          </DetailPanelScollable>
        </>
      )}
    </DetailPanel>
  );
}

export default OpDetail;

import React from 'react';
import { Popover, Button, message } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import IssueExemptionForm from 'components/issue/IssueExemptionForm';
import styled from 'styled-components';

const UPDATE_ISSUE_EXEMPTIONS = gql`
  mutation UpdateIssueExemptions($issueIds: [String]!, $isExempted: Boolean!, $exemptedReason: String) {
    updateIssueExemptions(issueIds: $issueIds, isExempted: $isExempted, exemptedReason: $exemptedReason) {
      id
      isExempted
      exemptedReason
    }
  }
`;

const PopoverContentWrap = styled.div`
  .form-content {
    padding: 0;
    background-color: #fff;
  }
`;

export interface Props {
  issueId: string;
  onComplete?: () => any;
}

interface State {
  isOpen: boolean;
}

class MarkExemptButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.handleTogglePopover = this.handleTogglePopover.bind(this);
  }

  handleTogglePopover(isOpen?: boolean) {
    this.setState((prevState: State) => ({
      isOpen: isOpen === undefined ? !prevState.isOpen : isOpen
    }));
  }

  render() {
    const { issueId, onComplete } = this.props;
    const { isOpen } = this.state;

    return (
      <Mutation mutation={UPDATE_ISSUE_EXEMPTIONS}>
        {updateIssueExemptions => {
          return (
            <Popover
              placement="bottomRight"
              trigger="click"
              visible={isOpen}
              onVisibleChange={this.handleTogglePopover}
              content={
                <PopoverContentWrap>
                  <IssueExemptionForm
                    classes={{ formContent: 'form-content' }}
                    onSubmit={async formValues => {
                      const variables: {
                        issueIds: string[];
                        isExempted: boolean;
                        exemptedReason?: string;
                        // expirationDt?: string;
                      } = {
                        issueIds: [issueId],
                        isExempted: true
                      };

                      if (formValues.exemptExplanation) {
                        variables.exemptedReason = formValues.exemptExplanation;
                      }

                      const results: any = await updateIssueExemptions({ variables });

                      const issuesUpdatedLen = results.data.updateIssueExemptions.length;
                      message.success(
                        issuesUpdatedLen === 1 ? 'Issue marked exempt' : `${issuesUpdatedLen} issues marked exempt`
                      );

                      if (onComplete) onComplete();
                    }}
                  />
                </PopoverContentWrap>
              }
            >
              <Button>
                <DopeIcon name="STOP" />
                Mark exempt
              </Button>
            </Popover>
          );
        }}
      </Mutation>
    );
  }
}

export default MarkExemptButton;

import styled from 'styled-components';

export const UserNavDiv = styled.div`
  position: fixed;
  z-index: 100;
  top: 0.75rem;
  right: 1rem;
  display: flex;
`;

export const UserNavButton = styled.button`
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  background-color: transparent;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
  transition-property: border-color, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;

  &:first-child {
    margin-right: 0.5rem;
  }

  &:focus,
  &:hover {
    outline: none;

    .primary-icon-wrap {
      border-color: ${(p) => p.theme.primary};
    }

    .caret-down {
      color: ${(p) => p.theme.primary};
    }
  }

  .caret-down {
    font-size: 0.5rem;
    margin-left: 0.25rem;
    color: #aaa;
    transition: color 0.2s ease-in;
  }

  .primary-icon {
    color: ${(p) => p.theme.primary};
  }

  .primary-icon-wrap {
    padding: 3px;
    border: 1px solid #ddd;
    border-radius: 15px;
    background: white;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color 0.2s ease-in;
  }
`;

export const UserNavListDiv = styled.div`
  a {
    display: inline-block;
    margin-bottom: 0.25rem;
    transition: color 0.2s ease-in;
  }

  a:hover {
    color: ${(p) => p.theme.primary900};
  }
`;

import React, { ReactNode, useContext } from 'react';
import AuthContext, { AuthContextProps } from 'components/app/Auth/AuthContext';

import { IS_DEV, IS_STAGING } from 'constants/runtimeConfig';

// A FeatureFlag is a tool that allows us to de-couple
// deploying a Feature from releasing a Feature.
// If we aren't using it to manage the eventual release of a Feature,
// then a FeatureFlag is the wrong abstraction.
// Some of the below FeatureFlags do not meet that criteria as of 7/21/2020.

export enum Feature {
  ACTIVITY_HISTORY_PAGE = 'activity:history_page',
  ACTIVITY_SETTINGS_PAGE = 'activity:settings_page',
  ISSUE_EXEMPT_EXPIRATION = 'issues:exempt_expiration',
  DELAYED_MANUAL_ACTIONS = 'actions:delayed_manual',
  DELAYED_ENABLED_ACTIONS = 'actions:delayed_enabled',
  DESIGN_CONSISTENCY_REWRITE = 'app:design_consistency',
  INVENTORY_REFRESH = 'cloudaccounts:inventory:refresh',
  DYNAMIC_CONFIG_PROJECT_LEVEL = 'dynamic_config:project_level',
  GUARDRAIL_INPUTS_JSON_PATH = 'guardrails:input-jsonpath',
  GUARDRAILS_AUTOMATION_FUNCTION_ACTIONS = 'guardrails:automation-function-actions',
  GUARDRAILS_CUSTOM_EVENT_SOURCE = 'guardrails:custom-event-source',
  GUARDRAILS_SECURITY_HUB_EVENTS = 'guardrails:security-hub-events',
  ACTION_RESULT_UNDO = 'actionresults:undo',
  // PROVISION_AZURE_ACCOUNTS = 'cloud-accounts:provision:azure',
  GOVERNANCE_GUARDRAILS = 'governance:guadrails',
  OP_TRIGGER_CUSTOM_JSON_PATH = 'op-editor:trigger:custom-json-path',
  TEST_PROJECT_REGIONS = 'project:setttings:regions',
  OPS_TRIGGER_FILTER_REWRITE = 'op-editor:trigger-filter:rewrite',
  OPS_NEW_DESIGN = 'ops:new-design',
  EVENTS_STATS_DASHBOARD = 'events:stats-dashboard',
  DETECTORS_NEW_DESIGN = 'detectors:new-design',
  USE_CASE_NAV = 'use-case-nav'
}

const guardrailClients = [
  'e52a2259-e26e-4485-94d4-4cd1de6cdaf4',
  'f24d095b-7107-48fb-b59c-cd5f7a53131f',
  '1f37275e-8c11-4d69-8cbb-7c66edaf2aa1',
  'bc3fdc19-8402-4140-ab8c-36a9a6c0dbb9'
];

// This configuration object supports being given
// either a Boolean and a Function that returns a Boolean
// Setting a boolean without a Function means that Feature should be released to everyone.
// Usually, we will set a FeatureFlag to true to release it, and then sometime later
// come back and remove the FeatureFlag here altogether.

// IS_DEV = only true in the DEV environment
// isDisruptOpsUser = ships to prod, but is only enabled for internal users

const FEATURE_FLAGS: FlagsConfig = {
  [Feature.ACTIVITY_HISTORY_PAGE]: () => IS_DEV,
  [Feature.ACTIVITY_SETTINGS_PAGE]: () => IS_DEV,
  [Feature.DELAYED_ENABLED_ACTIONS]: () => IS_DEV,
  [Feature.DELAYED_MANUAL_ACTIONS]: () => IS_DEV,
  [Feature.DESIGN_CONSISTENCY_REWRITE]: isDisruptOpsUser,
  [Feature.ISSUE_EXEMPT_EXPIRATION]: () => false,
  [Feature.INVENTORY_REFRESH]: isDisruptOpsUser,
  [Feature.DYNAMIC_CONFIG_PROJECT_LEVEL]: () => false,
  [Feature.GUARDRAIL_INPUTS_JSON_PATH]: isDisruptOpsUser,
  [Feature.GUARDRAILS_AUTOMATION_FUNCTION_ACTIONS]: isDisruptOpsUser,
  [Feature.GUARDRAILS_CUSTOM_EVENT_SOURCE]: isDisruptOpsUser,
  [Feature.GUARDRAILS_SECURITY_HUB_EVENTS]: isDisruptOpsUser,
  [Feature.ACTION_RESULT_UNDO]: isDisruptOpsUser,
  [Feature.GOVERNANCE_GUARDRAILS]: doesClientHaveFeature(guardrailClients),
  [Feature.TEST_PROJECT_REGIONS]: isDisruptOpsUser,
  [Feature.OPS_TRIGGER_FILTER_REWRITE]: isDisruptOpsUser,
  [Feature.OPS_NEW_DESIGN]: true,
  [Feature.EVENTS_STATS_DASHBOARD]: isDisruptOpsUser,
  [Feature.DETECTORS_NEW_DESIGN]: isDisruptOpsUser,
  [Feature.USE_CASE_NAV]: isDisruptOpsUser
};

function isDisruptOpsUser(authContext?: AuthContextProps): boolean {
  return IS_DEV || authContext?.isDisruptOpsUser ? true : false;
}

function isFeatureFlaggingDisabled(authContext?: AuthContextProps) {
  if (!authContext) return false;
  if (!authContext.devSettings) return false;
  return authContext.devSettings.isFeatureFlaggingDisabled ? true : false;
}

function doesClientHaveFeature(featureClientList: string[]) {
  return (authContext?: AuthContextProps): boolean => {
    const isAllowed: boolean =
      IS_DEV ||
      IS_STAGING ||
      Boolean(
        authContext &&
          authContext.me &&
          authContext.me.client &&
          authContext.me.client.client_id &&
          featureClientList.includes(authContext.me.client.client_id)
      );

    return isAllowed;
  };
}

interface FlagsConfig {
  [key: string]: boolean | ((auth?: AuthContextProps) => boolean);
}

interface Flags {
  [key: string]: boolean;
}

interface Props {
  children: (flags: Flags) => ReactNode | null;
  feature?: Feature;
  features?: Feature[];
}

function FeatureFlag(props: Props) {
  const { children } = props;

  const features = props.feature ? [props.feature] : props.features || [];

  return (
    <AuthContext.Consumer>
      {(authContext) => {
        if (!authContext) return null;
        const flags = generateFlags(features, authContext);

        return children(flags);
      }}
    </AuthContext.Consumer>
  );
}

export function useFeatureFlag(features: string[]) {
  const authContext = useContext(AuthContext);

  if (!authContext) return {};

  return generateFlags(features, authContext);
}

interface FeatureFlagResult {
  [key: string]: boolean;
}

export function generateFlags(features: string[], auth: AuthContextProps) {
  const flags = features.reduce((flags: FeatureFlagResult, feature) => {
    const flag = FEATURE_FLAGS[feature];

    // Check if boolean first, this allows us
    // to continue setting a FeatureFlag to true
    // in order to release a feature to everyone
    if (typeof flag === 'boolean') {
      flags[feature] = flag;
    }
    // Next, check if Feature Flagging has been disabled globally in the App
    // Any FlagConfig that is not explicitly boolean will be set to false
    else if (isFeatureFlaggingDisabled(auth)) {
      flags[feature] = false;
    }
    // Next, if the FlagConfig is a function, we evaluate it.
    // This is the "normal" path for a FeatureFlag
    else if (typeof flag === 'function') {
      flags[feature] = flag(auth);
    }

    // Lastly, if we couldn't come up with value for the flag we set it to undefined.
    if (flags[feature] === undefined) {
      flags[feature] = false;
    }

    return flags;
  }, {});

  return flags;
}

export default FeatureFlag;

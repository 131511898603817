import React from 'react';
import { Tabs } from 'antd';
import styled from 'styled-components';

import ResourceIssueTable from './ResourceIssueTable';
import { TabLabel } from 'components/ui/Tabs';

const TabPane = Tabs.TabPane;

// pull this out to global components.
const Root = styled.div`
  margin-bottom: 24px;

  > .ant-tabs-card > .ant-tabs-content {
    margin-top: -16px;
  }

  > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
  }

  > .ant-tabs-card > .ant-tabs-bar {
    border-color: #fff;
  }

  > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: transparent;
    background: #dfdfdf;
  }

  > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
    color: #333;
  }
`;

export interface Props {
  dopsResourceId: string;
  onIssueSelected: (issueId: string) => any;
  resourceByIdRefetch: () => any;
}

interface State {
  activeKey: string;
}

class ResourceIssueTabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeKey: 'open-issues-tab'
    };

    this.handleKeyChange = this.handleKeyChange.bind(this);
  }

  handleKeyChange(activeKey: string) {
    this.setState({ activeKey });
  }

  render() {
    const { activeKey } = this.state;
    const { dopsResourceId, onIssueSelected, resourceByIdRefetch } = this.props;

    return (
      <Root>
        <Tabs type="card" activeKey={this.state.activeKey} onChange={this.handleKeyChange}>
          <TabPane key="open-issues-tab" tab={<TabLabel label="Open Issues" />}>
            {activeKey === 'open-issues-tab' && (
              <ResourceIssueTable
                dopsResourceId={dopsResourceId}
                status="open"
                onIssueSelected={onIssueSelected}
                resourceByIdRefetch={resourceByIdRefetch}
              />
            )}
          </TabPane>

          <TabPane key="resolved-issues-tab" tab={<TabLabel label="Resolved Issues" />}>
            {activeKey === 'resolved-issues-tab' && (
              <ResourceIssueTable
                dopsResourceId={dopsResourceId}
                status="resolved"
                onIssueSelected={onIssueSelected}
                resourceByIdRefetch={resourceByIdRefetch}
              />
            )}
          </TabPane>

          <TabPane key="exemptions-tab" tab={<TabLabel label="Exemptions" />}>
            {activeKey === 'exemptions-tab' && (
              <ResourceIssueTable
                dopsResourceId={dopsResourceId}
                status="exempted"
                onIssueSelected={onIssueSelected}
                resourceByIdRefetch={resourceByIdRefetch}
              />
            )}
          </TabPane>
        </Tabs>
      </Root>
    );
  }
}

export default ResourceIssueTabs;

import React from 'react';
import { RouteComponentProps } from 'react-router';

import AutomationActionResultList from 'components/activity/AutomationActionResultsList';

interface AutomationActivityListProps extends RouteComponentProps {}

function AutomationActivityList(props: AutomationActivityListProps) {
  // ideally this component wouldn't require routeComponentProps...
  return <AutomationActionResultList {...props} />;
}

export default AutomationActivityList;

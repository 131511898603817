import React from 'react';
import styled from 'styled-components';
import { FunctionReadViewProps } from '../FunctionReadView';
import { Tag } from 'antd';

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  > * {
    margin-right: 4px;
    margin-bottom: 4px;
  }
`;

function AWSTagsDisplay(props: FunctionReadViewProps) {
  const { value: tags } = props;

  return (
    <Root>
      {tags.map((t, idx) => {
        const key = t.key || t.Key || null;
        const value = t.value || t.Value || null;

        let str = `${key}`;
        if (value) str += `: ${value}`;

        return (
          <Tag key={idx} color="blue">
            {str}
          </Tag>
        );
      })}
    </Root>
  );
}

export default AWSTagsDisplay;

import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { useQuery } from 'react-apollo';

import { ExistingNotificationRecipient } from 'typings';
import QueryResult from 'components/util/QueryResult';
import RECIPIENT_LIST_QUERY from 'queries/recipientListQuery';

import MSTeamsRecipientListItem from './MSTeamsRecipientListItem';

const Root = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

interface Props {
  onSelect: (recipientId: string) => any | void;
}

function MSTeamsRecipientList(props: Props) {
  const { onSelect } = props;
  const results = useQuery(RECIPIENT_LIST_QUERY, { variables: { type: ['MS_TEAMS'] } });

  return (
    <QueryResult
      {...results}
      parseNodes={data => data?.recipients?.items || []}
      loadingCenterVertically={false}
      loadingHeight={300}
    >
      {(recipients: ExistingNotificationRecipient[]) => {
        return (
          <Root>
            {recipients.map(recipient => (
              <MSTeamsRecipientListItem
                key={recipient.id}
                recipient={recipient}
                action={
                  <Button onClick={() => onSelect(recipient.id)} type="primary">
                    {'Use this Recipient'}
                  </Button>
                }
              />
            ))}
          </Root>
        );
      }}
    </QueryResult>
  );
}

export default MSTeamsRecipientList;

import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { BreadcrumbDiv } from './PostureMonitoringBreadcrumbs.styles';

function PostureMonitoringBreadcrumbs() {
  const isDetails = useRouteMatch('/posture-monitoring/inventory/:dopsResourceType/:dopsResourceUid');

  return (
    <BreadcrumbDiv>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/posture-monitoring">Posture Monitoring</Link>
        </Breadcrumb.Item>
        {isDetails ? (
          <>
            <Breadcrumb.Item>
              <Link to="/posture-monitoring/inventory">Inventory</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Resource Details</Breadcrumb.Item>
          </>
        ) : (
          <>
            <Breadcrumb.Item>Inventory</Breadcrumb.Item>
          </>
        )}
      </Breadcrumb>
    </BreadcrumbDiv>
  );
}

export default PostureMonitoringBreadcrumbs;

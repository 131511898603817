import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { CLOUD_ACCOUNT_LIST_QUERY } from 'queries';
import { ModeOption } from 'antd/lib/select';

const Root = styled.div``;

export interface Props {
  value?: string | string[];
  externalAccountId?: boolean;
  onChange: (value: any) => any;
  mode?: ModeOption;
  placeholder?: string;
  onBlur?: (event: any) => any;
}

function CloudAccountFilter(props: Props) {
  const { value, onChange, onBlur, externalAccountId = false } = props;

  const mode = props.mode ? props.mode : 'multiple';
  const placeholder = props.placeholder ? props.placeholder : 'All cloud accounts';

  return (
    <Root>
      <Query query={CLOUD_ACCOUNT_LIST_QUERY} fetchPolicy="cache-and-network">
        {(results) => {
          const { loading, error, data } = results;

          return (
            <Select
              value={value === null ? undefined : value}
              style={{ width: '100%', minWidth: '200px' }}
              onChange={onChange}
              disabled={loading || !!error}
              placeholder={!error ? placeholder : 'Error loading cloud accounts'}
              loading={loading}
              mode={mode}
              onBlur={onBlur}
              filterOption={(inputValue, option) => {
                if (!option || !option.props || !option.props.title) {
                  return true;
                }
                return new RegExp(inputValue, 'i').test(option.props.title);
              }}
            >
              {!loading && data && data.accounts && data.accounts.items ? (
                data.accounts.items.map((cloudAccount) => {
                  let title =
                    cloudAccount.nickname ||
                    cloudAccount.name ||
                    cloudAccount.assumerole_account_id ||
                    cloudAccount.account_id;
                  return (
                    <Select.Option
                      key={externalAccountId ? cloudAccount.assumerole_account_id : cloudAccount.account_id}
                      title={title}
                    >
                      {title}
                    </Select.Option>
                  );
                })
              ) : (
                <Select.Option disabled value="">
                  Loading
                </Select.Option>
              )}
            </Select>
          );
        }}
      </Query>
    </Root>
  );
}

export default CloudAccountFilter;

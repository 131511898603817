import React, { ReactNode, MouseEvent } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

const Root = styled.button`
  width: 44px;
  height: 44px;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  background-color: ${(p) => p.theme.grey50};
  transition: background-color 0.25s ease;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  border: 1px solid;
  color: ${(p) => p.theme.grey600};
  border-color: ${(p) => p.theme.grey400};

  :focus {
    box-shadow: 0 0 3pt 2pt ${(p) => p.theme.primary500};
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
  }

  &:hover {
    background-color: ${(p) => p.theme.grey100};
  }

  // PRIMARY
  &.primary {
    color: ${(p) => p.theme.primary};

    &:hover {
      background-color: ${(p) => p.theme.primary50};
    }
    &.disabled {
      pointer-events: none;
      color: ${(p) => p.theme.primary300};
    }
  }

  // SMALL
  &.small {
    width: 32px;
    height: 32px;
  }

  // MEDIUM
  &.medium {
    font-size: 16px;
  }

  &.ghost {
    border: none;
    background-color: transparent;
    transition: all 0.2s ease;
    &:hover {
      background-color: ${(p) => p.theme.grey50};
      color: ${(p) => p.theme.grey800};
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
  }
`;

export interface Props {
  children: ReactNode;
  type?: 'default' | 'primary' | 'ghost';
  size?: 'small' | 'medium' | 'large';
  classes?: {
    root?: string;
  };
  onClick?: (e: MouseEvent) => any;
  disabled?: boolean;
  loading?: boolean;
  onMouseLeave?: (e: MouseEvent) => any | void;
  onMouseOver?: (e: MouseEvent) => any | void;
  title?: string;
  style?: any;
}

function IconButton(props: Props) {
  const {
    type = 'default',
    size,
    children,
    classes = {},
    onClick,
    disabled = false,
    loading = false,
    onMouseOver,
    onMouseLeave,
    title,
    style
  } = props;

  let rootClasses = classes.root || '';
  rootClasses += ` ${type}`;
  if (size) rootClasses += ` ${size}`;
  if (disabled) rootClasses += ' disabled';

  return (
    <Root
      disabled={disabled || false}
      onClick={!disabled ? onClick : (e) => null}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={rootClasses}
      title={title}
      style={style}
    >
      {loading && <Spin size="small" />}
      {!loading && children}
    </Root>
  );
}

export default IconButton;

import { Icon, List, Spin, Typography } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import theme from 'constants/theme';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const { Paragraph, Text, Title } = Typography;

const Root = styled.div`
  .ant-list-item-meta-avatar {
    margin-right: 8px;
    margin-top: 2px;
  }
`;

interface Step {
  title: string;
  description: string;
  videoUrl?: string;
  breadcrumb: string;
  to: string;
  // icon: string;
  required?: boolean;
  completed?: boolean;
  loading?: boolean;
  getCount: (data: any) => number;
  getCompleted: (count: number) => boolean;
  completedDescription: (count: number) => string;
}

// step definitions
const steps: Step[] = [
  {
    title: 'Connect DisruptOps to your cloud infrastructure',
    description:
      'DisruptOps connects into your cloud accounts and subscriptions to understand their current state and make changes as you prescribe.',
    breadcrumb: 'Cloud accounts > + Add Account',
    to: '/cloud-accounts/add',
    // icon: 'cloud',
    required: true,
    loading: true,
    getCount: (data) => data?.accounts?.count,
    getCompleted: (count) => count > 0,
    completedDescription: (count) => `You have connected ${count?.toLocaleString()} account${count === 1 ? '' : 's'}.`
  },
  {
    title: 'Build alert recipients',
    description:
      'DisruptOps can integrate ChatOps into your response operations providing an automated platform for team integration.',
    breadcrumb: 'Organization > Notifications > Recipients > Connect new Recipient',
    to: '/organization-settings/notifications/recipients',
    // icon: 'slack',
    loading: true,
    getCount: (data) => data?.recipients?.total,
    getCompleted: (count) => count > 0,
    completedDescription: (count) =>
      `You have built ${count?.toLocaleString()} alert recipient${count === 1 ? '' : 's'}.`
  },
  {
    title: 'Adopt Ops to improve your cloud operations',
    description: 'Use our playbooks to improve security functions, manage your costs better, and many other things.',
    videoUrl: 'https://vimeo.com/489488637/b81c746e80',
    breadcrumb: 'Ops > Create',
    to: '/ops/create',
    // icon: 'branches',
    loading: true,
    getCount: (data) => data?.ops?.pageInfo?.total,
    getCompleted: (count) => count > 0,
    completedDescription: (count) => `You have adopted ${count?.toLocaleString()} op${count === 1 ? '' : 's'}.`
  },
  {
    title: 'Bring your team along',
    description: 'Invite team members.',
    breadcrumb: 'Organizations > Team management > Add team member',
    to: '/organization-settings/team-management',
    // icon: 'mail',
    loading: true,
    getCount: (data) => data?.users?.pageInfo?.total,
    getCompleted: (count) => count > 1,
    completedDescription: (count) => `You have invited ${count?.toLocaleString()} user${count === 1 ? '' : 's'}.`
  }
];

// queries used to determine if steps are done
const ACCOUNTS_FOR_GETTING_STARTED_QUERY = gql`
  query accounts {
    accounts {
      count
    }
  }
`;

const RECIPIENTS_FOR_GETTING_STARTED_QUERY = gql`
  query recipients {
    recipients(size: 1) {
      total
    }
  }
`;

const OPS_FOR_GETTING_STARTED_QUERY = gql`
  query ops {
    ops(pageSize: 1) {
      pageInfo {
        total
      }
    }
  }
`;

const USERS_FOR_GETTING_STARTED_QUERY = gql`
  query users {
    users(pageSize: 1) {
      pageInfo {
        total
      }
    }
  }
`;

function Guide() {
  // queries used to determine if steps are done, collected into an array
  const queryResults = [
    useQuery(ACCOUNTS_FOR_GETTING_STARTED_QUERY),
    useQuery(RECIPIENTS_FOR_GETTING_STARTED_QUERY),
    useQuery(OPS_FOR_GETTING_STARTED_QUERY),
    useQuery(USERS_FOR_GETTING_STARTED_QUERY)
  ];

  // update the steps as the queries complete
  steps.forEach((step, index) => {
    const queryResult = queryResults[index];
    const { loading, data } = queryResult || {};

    step.loading = loading;
    const count = step.getCount(data);
    step.completed = step.getCompleted(count);

    // update the step description if it's completed
    step.description = step.completed ? step.completedDescription(count) : step.description;
  });

  // are all the steps complete?
  const allDone = steps.every((s) => s.completed);

  // if all the steps are complete, add an 'All done' item
  const stepsToRender = allDone
    ? [
        ...steps,
        {
          title: 'All done!',
          description: `Congratulations, we're all done for now! Check out the dashboard.`,
          content: 'Dashboard',
          to: '/dashboard',
          completed: true,
          getCount: (data) => 0,
          getCompleted: (count) => true,
          completedDescription: (count) => `Congratulations, we're all done for now! Check out the dashboard.`
        }
      ]
    : steps;

  const renderItem = (item) => {
    return (
      <List.Item>
        <List.Item.Meta
          avatar={
            item.loading ? (
              <Spin />
            ) : (
              <Link to={item.to}>
                <DopeIcon name={item.completed ? 'CHECKBOX_CHECKED' : 'CHECKBOX_EMPTY'} size={20} />
              </Link>
            )
          }
          title={
            <>
              <Link to={item.to}>
                <span style={{ fontSize: '20px', color: theme.primary, textDecoration: 'underline' }}>
                  {item.title}
                </span>
              </Link>
              {item.required && <span style={{ marginLeft: '16px', color: theme.danger }}>* Required</span>}
            </>
          }
          description={
            <>
              {item.breadcrumb && (
                <Link to={item.to}>
                  <div style={{ color: theme.primary }}>
                    {item.breadcrumb}
                    <br />
                  </div>
                </Link>
              )}
              {item.videoUrl && (
                <div>
                  <a href={item.videoUrl} target="_blank" rel="noreferrer noopener">
                    <Icon type="youtube" />
                    &nbsp;Watch Tutorial&nbsp;
                    <DopeIcon name="EXTERNAL_LINK" />
                  </a>
                </div>
              )}
              <span style={{ color: theme.grey650 }}>{item.description}</span>
            </>
          }
        />
      </List.Item>
    );
  };

  return (
    <Root>
      <Typography>
        <Title>Welcome to DistruptOps!</Title>
        <Title level={3}>Let's improve your cloud operations.</Title>
        <Title level={4}>Setup Is Quick</Title>
        <Paragraph>
          <Text>To get the most out of DisruptOps, you need to accomplish a few things. This won't take long.</Text>
        </Paragraph>
      </Typography>

      <List itemLayout="horizontal" dataSource={stepsToRender} renderItem={renderItem} />
    </Root>
  );
}

export default Guide;

import React from 'react';
import { Input, Form } from 'antd';
import validator from 'services/validator';

import { size, FunctionInputBaseProps } from 'typings';

const InputGroup = Input.Group;

interface Props extends FunctionInputBaseProps {
  name: string;
  title: string;
  value: any;
  setFieldTouched: (name: string, touched: boolean) => any | void;
  setFieldValue: (name: string, value: any) => any | void;
  validationError: any;
  touched: any;
  required?: boolean;
  description?: string;
  size?: size;
}

const schema = validator.object().shape({
  Key: validator.string().when('Value', (Value, schema) => (!Value || Value === '' ? schema : schema.required())),
  Value: validator.string()
});

const validTypes = ['object'];

export const inputDefinition = {
  schema,
  validTypes,
  defaultValue: {}
};

function AWSCreateTagInput(props: Props) {
  const {
    name,
    title,
    description,
    value = { Key: '', Value: '' },
    setFieldTouched,
    setFieldValue,
    validationError,
    touched,
    required = false,
    size = 'large'
  } = props;

  const handleChange = name => event => setFieldValue(name, event.target.value);
  const handleBlur = name => () => setFieldTouched(name, true);

  const inputKeyName = `${name}.Key`;
  const inputValueName = `${name}.Value`;

  const inputKeyTouched = touched && touched.Key ? touched.Key : false;
  const inputValueTouched = touched && touched.Value ? touched.Value : false;

  const inputKeyError = inputKeyTouched && validationError && validationError.Key ? validationError.Key : null;
  const inputValueError = inputValueTouched && validationError && validationError.Value ? validationError.Value : null;

  return (
    <Form.Item
      label={title}
      extra={description}
      hasFeedback={false}
      help={inputKeyError || inputValueError || ''}
      required={required}
      validateStatus={inputKeyError || inputValueError ? 'error' : 'success'}
    >
      <InputGroup compact>
        <Input
          title="Tag Key"
          name={inputKeyName}
          value={value.Key}
          onChange={handleChange(inputKeyName)}
          onBlur={handleBlur(inputKeyName)}
          className={inputKeyError ? 'has-error' : ''}
          size={size}
          placeholder="AWS Tag Key"
          style={{ width: '50%' }}
        />
        <Input
          title="Tag Value"
          name={inputValueName}
          value={value.Value}
          onChange={handleChange(inputValueName)}
          onBlur={handleBlur(inputKeyName)}
          className={inputValueError ? 'has-error' : ''}
          size="large"
          placeholder="AWS Tag Value"
          addonBefore=":"
          style={{ width: '50%' }}
        />
      </InputGroup>
    </Form.Item>
  );
}

export default AWSCreateTagInput;

import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { Alert, Button, Input, message, Typography } from 'antd';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import ButtonWithDisabledTooltip from 'components/buttons/ButtonWithDisabledTooltip';
import { RecipientTypeDefinition, RECIPIENT_TYPE_DEFINITIONS } from 'components/recipients/common';
import ErrorAlert from 'components/ui/ErrorAlert';
import Form, { FormField } from 'components/ui/Form';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Redirect, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Recipient } from 'typings';
import * as Yup from 'yup';

const RECIPIENT_QUERY = gql`
  query recipient($id: String) {
    recipients(id: $id) {
      items {
        id
        name
        type
        config
        notificationId
        createdAt
        createdBy
        updatedAt
        updatedBy
        isEnabled
        lastUsedAt
        lastErrorAt
        lastError
      }
    }
  }
`;

const SAVE_RECIPIENT = gql`
  mutation NewRecipient($id: String, $recipient: SaveRecipientInput!) {
    saveRecipient(id: $id, recipient: $recipient) {
      id
      name
      config
      type
    }
  }
`;

const TEST_RECIPIENT = gql`
  mutation TestRecipient($id: String!) {
    testRecipient(id: $id) {
      recipient {
        id
        name
        config
      }
      alert
      error
    }
  }
`;

const RecipientFormRoot = styled.div`
  padding-top: 16px;

  .recipient-form-body {
    margin-bottom: 32px;
  }
`;

interface RecipientFormProps
  extends RouteComponentProps<{
    recipientType?: string;
    id?: string;
  }> {}

interface AlertState {
  message?: string | null;
  description?: string | null;
  type?: 'error' | 'success';
}

function RecipientForm(props: RecipientFormProps) {
  const {
    match: {
      params: { id, recipientType }
    }
  } = props;

  // console.log({ id, recipientType });

  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_NOTIFICATIONS, '*');
  const [saveRecipient, { loading: isSaving, error: saveError }] = useMutation(SAVE_RECIPIENT);
  const [testRecipient, { loading: isTesting, error: testError }] = useMutation(TEST_RECIPIENT);
  const [alertState, setAlertState] = useState<AlertState | null>();
  const history = useHistory();

  const { loading: queryLoading, data: queryData, error: queryError } = useQuery(RECIPIENT_QUERY, {
    variables: {
      id
    },
    skip: !id
  });

  return (
    <QueryResult data={queryData} loading={queryLoading} error={queryError}>
      {() => {
        const recipient: Recipient = queryData?.recipients?.items[0] || {};

        // console.log({ id, recipientType, recipient });

        const recipientTypeDef =
          RECIPIENT_TYPE_DEFINITIONS.find((def) => {
            if (!recipientType && !recipient) return false;

            // console.log({ key: def.key });

            return (
              def.key.toLowerCase() === recipientType?.toLowerCase() ||
              def.key.toLowerCase() === recipient.type?.toLowerCase()
            );
          }) || null;

        if (!recipientTypeDef) return <Redirect to="/organization-settings/notifications/recipients/" />;

        const FormControls = recipientTypeDef.formControls;
        const validationSchema = buildRecipientValidationSchema(recipientTypeDef);

        return (
          <RecipientFormRoot>
            <Typography.Title level={3}>{`${id ? '' : 'New '}${recipientTypeDef.label} Recipient`}</Typography.Title>
            <Form
              initialValues={recipient}
              validationSchema={validationSchema}
              allowCleanSubmits={false}
              onSubmit={async (formValues) => {
                let showError = false;
                try {
                  const recipient = {
                    type: recipientTypeDef.key,
                    name: formValues.name,
                    config: formValues.config
                  };

                  setAlertState(null);

                  const response = await saveRecipient({
                    variables: {
                      id,
                      recipient
                    }
                  });

                  const {
                    errors,
                    data: {
                      saveRecipient: { id: recipientId }
                    }
                  } = response || {};

                  if (errors) {
                    showError = true;
                  } else {
                    const msg = 'The recipient was successfully saved.';
                    setAlertState({
                      message: msg,
                      type: 'success'
                    });
                    message.success(msg);
                    if (recipientId && !id) {
                      // redirect to edit page for newly created recipient
                      history.push(`/organization-settings/notifications/recipients/${recipientId}/edit`);
                    }
                  }
                } catch (e) {
                  showError = true;
                }

                if (showError) {
                  const msg = 'There was an error saving the recipient.';
                  setAlertState({
                    message: msg,
                    type: 'error'
                  });
                  message.error(msg);
                }
              }}
            >
              {(formRenderProps) => {
                const { canSubmit } = formRenderProps;

                // console.log({ isTesting, canSubmit, dirty });

                return (
                  <div>
                    <FormField name="name" label="Name">
                      {({ name, value, handleChange, handleBlur }) => (
                        <Input autoFocus name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                      )}
                    </FormField>

                    <div className="recipient-form-body">
                      <FormControls formRenderProps={formRenderProps} />
                    </div>

                    <ButtonWithDisabledTooltip
                      type="primary"
                      htmlType="submit"
                      disabled={!isAuthorized || !canSubmit || isSaving}
                      disabledMessage={authErrorMessage || ''}
                      loading={isSaving}
                    >
                      {'Save Recipient'}
                    </ButtonWithDisabledTooltip>

                    <>
                      <Button
                        icon="check"
                        style={{ marginLeft: '8px', marginRight: '8px', marginBottom: '16px' }}
                        disabled={isTesting || isSaving || !id}
                        loading={isTesting}
                        title="Send a test event to this notification recipient."
                        onClick={async (e) => {
                          const variables = { id };
                          setAlertState(null);
                          const result = await testRecipient({ variables });
                          // console.log({ result });
                          const error = result?.data?.testRecipient?.error || result?.errors?.[0]?.message;
                          const trimmed = error?.split('\n')?.[0];
                          if (trimmed) {
                            const msg = 'There was an error sending a test message to the notification recipient.';
                            setAlertState({
                              message: msg,
                              description: trimmed,
                              type: 'error'
                            });
                            message.error(msg);
                          } else {
                            const msg = 'A test message has been successfully sent to the notification recipient.';
                            setAlertState({
                              message: msg,
                              description: trimmed,
                              type: 'success'
                            });
                            message.success(msg);
                          }
                        }}
                      >
                        Test
                      </Button>
                      Note: please save any changes before testing.
                    </>

                    {saveError && <ErrorAlert error={saveError} />}
                    {testError && <ErrorAlert error={testError} />}
                    {alertState && (
                      <Alert
                        closable
                        type={alertState.type}
                        message={alertState.message}
                        description={alertState.description}
                      />
                    )}
                  </div>
                );
              }}
            </Form>
          </RecipientFormRoot>
        );
      }}
    </QueryResult>
  );
}

function buildRecipientValidationSchema(recipientTypeDef: RecipientTypeDefinition) {
  const shape: any = {};

  if (recipientTypeDef.key !== 'MS_TEAMS') {
    shape.name = Yup.string().required('Name is required');
  }

  if (recipientTypeDef.configValidationShape) {
    shape.config = recipientTypeDef.configValidationShape; // does this need to be required?
  } else {
    shape.config = Yup.object().required();
  }

  return Yup.object().shape(shape);
}

export default RecipientForm;

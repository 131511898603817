import React from 'react';
import { FunctionReadViewProps } from '../FunctionReadView';
import { Tag } from 'antd';

function AWSTagDisplay(props: FunctionReadViewProps) {
  const { value } = props;
  if (value === undefined || value === null) return '--';

  const { Key, Value } = value;

  if (typeof value !== 'object') {
    return typeof value === 'string' ? value : null;
  }

  let str = `${Key}`;
  if (Value) str += ` : ${Value}`;

  return <Tag color="blue">{str}</Tag>;
}

export default AWSTagDisplay;

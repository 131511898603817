import styled from 'styled-components';

export const MoreInfoDiv = styled.div`
  word-break: normal;

  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-top: 0;
    padding-bottom: 0;
  }

  .ant-collapse {
    border: none;
    background-color: #fff;
  }

  .ant-collapse > .ant-collapse-item {
    border-bottom: none;
  }

  .ant-collapse-content {
    border-top: none;
  }
`;

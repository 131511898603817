import React from 'react';
import { ResourceDetailTableProps } from 'components/cloudResources/cloudResourceTypesConfig';
import gql from 'graphql-tag';
import DetailTable, { DetailRowProps } from 'components/cloudResources/DetailTable';

const AWS_IAM_USER_DETAIL = gql`
  query AWSIAMUserDetail($dopsResourceUid: String!) {
    iam_users(dops_resource_uid: $dopsResourceUid) {
      from
      size
      total_count
      results {
        user_id
        user_name

        arn

        create_date
        groups {
          name
          arn
          create_date
          group_id
          group_name
          path
          attached_policies {
            policy_id
            policy_name
            description
          }
        }
      }
    }
  }
`;

const rows: DetailRowProps[] = [
  {
    key: 'user_id',
    dataIndex: 'user_id',
    label: 'User ID'
  },
  {
    key: 'user_name',
    dataIndex: 'user_name',
    label: 'User Name'
  },
  {
    key: 'user_arn',
    dataIndex: 'arn',
    label: 'User ARN'
  },
  {
    key: 'create_date',
    dataIndex: 'create_date',
    label: 'Creation time'
  },
  {
    key: 'groups',
    dataIndex: 'groups',
    label: 'Groups',
    render: groups => {
      const len = groups.length;

      return (
        <>
          {groups.map((item, idx) => {
            // should probably just be a link?
            return <span key={item.group_id}>{idx < len - 1 ? `${item.group_name}, ` : item.group_name}</span>;
          })}
        </>
      );
    }
  }
];

function AWSIAMUserTable(props: ResourceDetailTableProps) {
  const { dopsResourceUid } = props;

  return <DetailTable query={AWS_IAM_USER_DETAIL} queryName={'iam_users'} rows={rows} variables={{ dopsResourceUid }} />;
}

export default AWSIAMUserTable;

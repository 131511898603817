import styled from 'styled-components';

export const EditorSectionBanner = styled.div`
  padding: 0.5rem;
  background-color: ${(p) => p.theme.secondaryPale};
  line-height: 1;
  margin-bottom: 0.5rem;
  margin-top: -0.5rem;

  display: flex;
  align-items: center;

  & > * {
    margin-right: 0.5rem;
  }

  .title {
    font-size: 16px;
    font-weight: 400;
  }

  .cls-1 {
    fill: white;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Root = styled.div`
  margin-bottom: 2px;
  min-width: 100px;
`;
const Layout = styled.div`
  display: flex;
  padding: 6px 16px 4px 6px;

  .link &,
  .clickable & {
    color: ${p => p.theme.grey800};
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba(209, 196, 233, 0.4);
    }
  }
  .clickable.disabled & {
    background-color: ${p => p.theme.grey50};
  }
`;
const IconWrap = styled.div`
  width: 42px;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;
const LabelsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Primary = styled.div`
  color: ${p => p.theme.grey800};
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1em;

  .has-secondary {
    margin-top: 4px;
  }

  .clickable.disabled & {
    color: ${p => p.theme.grey400};
  }
`;
const Secondary = styled.div`
  color: ${p => p.theme.grey500};
`;
const Clickable = styled.div`
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }
`;

export interface Props {
  icon?: React.ReactNode;
  onClick?: (e: any) => any;
  link?: string;
  target?: '_blank';
  label: string;
  secondaryLabel?: string;
  thirdRowLabel?: string;
  disabled?: boolean;
  classes?: {
    root?: string;
    iconWrap?: string;
    labelsWrap?: string;
    primary?: string;
    secondary?: string;
  };
}

function PopoverMenuItem(props: Props) {
  const { icon, label, secondaryLabel, thirdRowLabel, link, target, onClick, classes = {}, disabled = false } = props;

  const Wrapper = link
    ? target
      ? ({ children }) => (
          <a className={'link'} href={link} target={target}>
            {children}
          </a>
        )
      : ({ children }) => (
          <Link className={'link'} to={link} onClick={onClick}>
            {children}
          </Link>
        )
    : ({ children }) => (
        <Clickable className={`${!!onClick ? 'clickable' : ''} ${disabled ? 'disabled' : ''}`} onClick={onClick}>
          {children}
        </Clickable>
      );

  return (
    <Root className={classes.root || ''}>
      <Wrapper>
        <Layout>
          {icon && <IconWrap>{icon}</IconWrap>}
          <LabelsWrap>
            <Primary
              className={(() => {
                let str = classes.primary || '';
                if (secondaryLabel) str += ' .has-secondary';

                return str;
              })()}
            >
              {label}
            </Primary>
            {secondaryLabel && <Secondary>{secondaryLabel}</Secondary>}
            {thirdRowLabel && <Secondary>{thirdRowLabel}</Secondary>}
          </LabelsWrap>
        </Layout>
      </Wrapper>
    </Root>
  );
}

export default PopoverMenuItem;

import React from 'react';
import ConfirmButton from './ConfirmButton';

export interface Props {
  id: string;

  disabled?: boolean;
  disabledMessage?: string;

  confirmMessage?: string;
  classes?: {
    root?: string;
    button?: string;
  };

  onRemove: (id: string) => any | void;
}

function RemoveButton(props: Props) {
  const { onRemove, ...confirmButtonProps } = props;

  return <ConfirmButton hideBorder={true} icon="minus-circle" onConfirm={onRemove} {...confirmButtonProps} />;
}

export default RemoveButton;

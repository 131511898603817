import gql from 'graphql-tag';

const AUTOMATION_EVENT_DETAIL = gql`
  query AutomationEventDetail($eventId: [String]) {
    automationEvents(id: $eventId) {
      pageInfo {
        total
        size
      }
      nodes {
        id
        eventDefinitionId
        eventDefinitionName
        clientId
        contextId
        eventType
        cloudAccountId
        region
        vendor
        vendorProduct
        createdAt
        eventData
      }
    }
  }
`;

export default AUTOMATION_EVENT_DETAIL;

import React, { useContext } from 'react';
import { Query, useQuery } from 'react-apollo';
import { Button } from 'antd';

import QueryResult from 'components/util/QueryResult';
import { SLACK_SETTINGS_QUERY } from 'app/sections/Authenticated/pages/OrganizationSettings/pages/Notifications/gql';
import SlackContext from 'components/slack/SlackContext';
import { authorize } from 'services/slack/auth';

interface SlackRecipientConfig {
  channel: string;
  url: string;
  username: string;
}

interface Props {
  onIntegrationComplete: (config: SlackRecipientConfig) => void;
}

function InitSlackAuthButton(props: Props) {
  const { onIntegrationComplete } = props;

  return (
    <Query query={SLACK_SETTINGS_QUERY}>
      {({ loading, data, error }) => (
        <QueryResult loading={loading} data={data} error={error}>
          {() => {
            return (
              <SlackContext.Consumer>
                {context => {
                  if (!context) return null;

                  return (
                    <Button
                      onClick={async () => {
                        const {
                          slackSettings: { appId, clientId }
                        } = data; // this always going to be there?
                        let config = {
                          appId,
                          clientId,
                          ...context.config
                        };

                        // open modal and authorize slack
                        const authData = await authorize({
                          incomingWebhook: true,
                          config
                        });

                        // wrap in try catch
                        if (
                          !authData.incoming_webhook ||
                          !authData.incoming_webhook.channel ||
                          !authData.incoming_webhook.url ||
                          !authData.team_name
                        )
                          throw new Error('Not all required fields were met');

                        const slackRecipientConfig: SlackRecipientConfig = {
                          channel: authData.incoming_webhook.channel,
                          url: authData.incoming_webhook.url,
                          username: authData.team_name
                        };

                        onIntegrationComplete(slackRecipientConfig);
                      }}
                    >
                      {'Setup Slack Integration'}
                    </Button>
                  );
                }}
              </SlackContext.Consumer>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

type InitSlackAuthFunc = () => Promise<SlackRecipientConfig>;

interface InitSlackAuthHookProps {
  initializing: boolean;
  initSlackAuth: InitSlackAuthFunc | null;
}

export function useInitSlackAuth(): InitSlackAuthHookProps {
  const slackSettingsQueryResult = useQuery(SLACK_SETTINGS_QUERY);
  const slackContext = useContext(SlackContext);
  const initializing = Boolean(slackSettingsQueryResult.loading || !slackContext);
  const initSlackAuth =
    !slackSettingsQueryResult.loading && slackContext
      ? async () => {
          const {
            data: {
              slackSettings: { appId, clientId }
            }
          } = slackSettingsQueryResult;
          const config = { appId, clientId, ...slackContext.config };

          // open modal
          const authData = await authorize({ incomingWebhook: true, config });

          if (
            !authData.incoming_webhook ||
            !authData.incoming_webhook.channel ||
            !authData.incoming_webhook.url ||
            !authData.team_name
          )
            throw new Error('Not all required fields were met');

          const slackRecipientConfig: SlackRecipientConfig = {
            channel: authData.incoming_webhook.channel,
            url: authData.incoming_webhook.url,
            username: authData.team_name
          };

          return slackRecipientConfig;
        }
      : null;

  return {
    initializing,
    initSlackAuth
  };
}

export default InitSlackAuthButton;

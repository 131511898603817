import gql from 'graphql-tag';

const ALL_S3_BUCKETS_QUERY = gql`
  query S3Buckets(
    $from: Int!
    $size: Int!
    $dops_full_text: String
    $dops_internal_account_ids: [String]
    $dops_is_public: Boolean
    $tags: [TagInput]
  ) {
    s3_all(
      from: $from
      size: $size
      dops_full_text: $dops_full_text
      dops_internal_account_ids: $dops_internal_account_ids
      dops_is_public: $dops_is_public
      tags: $tags
    ) {
      total_count
      results {
        id
        dops_resource_id
        dops_resource_type
        dops_resource_uid
        dops_internal_account_id
        dops_name
        name
        creation_date
        tags {
          key
          value
        }
        dops_issue_summary {
          open_count
        }
      }
    }
  }
`;

export default ALL_S3_BUCKETS_QUERY;

import React from 'react';
import { ResourceDetailTableProps } from 'components/cloudResources/cloudResourceTypesConfig';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import DetailTable, { DetailRowProps } from 'components/cloudResources/DetailTable';
import { getResourceUrl } from 'utilities/inventory';

const AWS_EC2_INSTANCE_DETAIL = gql`
  query AWSEC2InstanceDetail($dopsResourceUid: String!) {
    ec2_instances(dops_resource_uid: $dopsResourceUid) {
      size
      from
      results {
        id
        dops_resource_id
        dops_resource_uid
        state {
          code
          name
        }
        dops_region
        security_groups {
          group_name
          group_id
        }
        key_name
        dops_cloud_account_id
        dops_internal_account_id # could link to cloud account if we had a view?
        monitoring {
          state
        }
        private_ip_address
        private_dns_name
        network_interfaces {
          description
          groups {
            group_name
            group_id
          }
          subnet_id
          vpc_id
          network_interface_id
          vpc_id
          owner_id
          status # not sure what this is?
          attachment {
            device_index
            status
            attach_time
            delete_on_termination
            attachment_id
          }
          private_ip_address
          private_dns_name
          source_dest_check
        }
      }
    }
  }
`;

const rows: DetailRowProps[] = [
  {
    key: 'dops_resource_id',
    label: 'ID',
    dataIndex: 'dops_resource_id'
  },
  {
    key: 'instance_state',
    label: 'Instance state',
    dataIndex: 'state',
    render: state => state.name
  },
  {
    key: 'dops_region',
    label: 'Availability zone',
    dataIndex: 'dops_region'
  },
  {
    key: 'security_groups',
    label: 'Security groups',
    dataIndex: 'security_groups',
    render: (securityGroups, resource) => {
      const len = securityGroups.length;

      return (
        <>
          {securityGroups.map((item, idx) => {
            const itemUrl = getResourceUrl({
              accountId: resource.dops_internal_account_id,
              itemType: 'AWS::EC2::SecurityGroup',
              itemKey: item.group_id,
              region: resource.dops_region
            });

            return (
              <Link key={item.group_id || `security-group_${idx}`} to={itemUrl}>
                {idx < len - 1 ? `${item.group_name}, ` : item.group_name}
              </Link>
            );
          })}
        </>
      );
    }
  },
  {
    key: 'key_name',
    label: 'Key pair name',
    dataIndex: 'key_name'
  },
  {
    key: 'owner',
    label: 'Owner',
    dataIndex: 'dops_cloud_account_id'
  },
  {
    key: 'monitoring',
    label: 'Monitoring',
    dataIndex: 'monitoring',
    render: monitoring => monitoring.state
  },
  {
    key: 'private_ip_address',
    label: 'Private IPs', // singular or plural?
    dataIndex: 'private_ip_address'
  },
  {
    key: 'private_dns_name',
    label: 'Private DNS',
    dataIndex: 'private_dns_name'
  },
  {
    key: 'network_interfaces',
    label: 'Network interfaces',
    dataIndex: 'network_interfaces',
    render: (networkInterfaces, resource) => {
      const len = networkInterfaces.length;

      return (
        <>
          {networkInterfaces.map((item, idx) => {
            const itemUrl = getResourceUrl({
              accountId: resource.dops_internal_account_id,
              itemType: 'AWS::EC2::NetworkInterface',
              itemKey: item.network_interface_id,
              region: resource.dops_region
            });

            const name = `eth${item.attachment.device_index}`;

            return (
              <Link key={item.network_interface_id || `network_interface_${idx}`} to={itemUrl}>
                {idx < len - 1 ? `${name}, ` : name}
              </Link>
            );
          })}
        </>
      );
    }
  }
];

function AWSEC2InstanceDetailTable(props: ResourceDetailTableProps) {
  const { dopsResourceUid } = props;

  return (
    <DetailTable
      query={AWS_EC2_INSTANCE_DETAIL}
      queryName={'ec2_instances'}
      rows={rows}
      variables={{ dopsResourceUid }}
    />
  );
}

export default AWSEC2InstanceDetailTable;

import { message, Modal } from 'ant4x';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { Op } from 'typings';

interface Props {
  closeLink: string;
  onClosed: () => void;
  onSuccess: () => void;
  op: Op;
  visible: boolean;
}

const DELETE_OP_MUTATION = gql`
  mutation DeleteOp($id: String!) {
    deleteOp(id: $id)
  }
`;

export default function DeleteOpModal(props: Props) {
  const { closeLink, onClosed, onSuccess, op, visible } = props;

  const [deleteOp, deleteOpResults] = useMutation(DELETE_OP_MUTATION);
  const history = useHistory();

  async function handleOk() {
    try {
      const result = await deleteOp({
        variables: { id: op.id }
      });
      if (result.errors) {
        message.error(`There was an error deleting the op "${op.name}".`);
      } else {
        message.success(`The op "${op.name}" was successfully deleted.`);
        history.push(closeLink);
        onSuccess && onSuccess();
      }
    } catch (e) {
      message.error(`There was an error deleting the op "${op.name}".`);
    } finally {
      onClosed && onClosed();
    }
  }

  function handleCancel() {
    onClosed && onClosed();
  }

  return (
    <Modal
      title={`Are you sure you want to delete the op "${op.name}"?`}
      visible={visible}
      onOk={handleOk}
      confirmLoading={deleteOpResults?.loading}
      onCancel={handleCancel}
    >
      <p>
        {`Warning! Deleting the op "${op.name}" is an irreversible action. Continuing will delete this op and its related activity permanently.`}
      </p>
    </Modal>
  );
}

import gql from 'graphql-tag';
import ISSUE_FRAGMENT from './fragments/issue';

export const ISSUE_LIST_QUERY = gql`
  query AllIssues {
    issues @rest(type: "[Issue]", path: "/issues?status=open") {
      ...issueFields
    }
  }

  ${ISSUE_FRAGMENT}
`;

export default ISSUE_LIST_QUERY;

import { Progress } from 'antd';
import QueryResult from 'components/util/QueryResult';
import { getSeverityName, SEVERITY_NAME_MAP } from 'constants/ui';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';

const QUERY = gql`
  query openIssueCountByService {
    governanceStats {
      openIssueCountBySeverity {
        days
        start
        results {
          severity
          count
        }
      }
    }
  }
`;

function OpenIssuesBySeverity() {
  const { loading, error, data } = useQuery(QUERY);

  return (
    <QueryResult loading={loading} data={data} error={error} loadingCenterVertically={false}>
      {() => {
        const issueCounts = data?.governanceStats?.openIssueCountBySeverity?.results || [];

        // loop through the rows
        const severities = SEVERITY_NAME_MAP.map((name, index) => {
          const severity = index + 1;
          const row = {
            severity: severity,
            count: 0,
            text: getSeverityName(severity)
          };

          // for each row, find issue counts with matching severities
          // any issue counts with severity higher than 5, just assign to 5 (critical)
          // any issue counts with severity lower than 1, just assign to 1 (very low)
          const issues = issueCounts.filter((i) => {
            if (i.severity === row.severity) return true;
            else if (i.severity > 5 && row.severity === 5) return true;
            else if (i.severity < 1 && row.severity === 1) return true;
            return false;
          });

          if (issues?.length) {
            // add the sum of the issue counts to the the severity's count
            for (let issue of issues) {
              row.count += issue.count;
            }
          }

          return row;
        }).sort((a, b) => b.severity - a.severity);

        const strokeColors = ['#4b2d83', '#8487ee', '#4d93eb', '#5fbeed', '#ace5fc'];

        const total = severities.reduce((total, row) => total + row.count, 0);

        return (
          <table>
            <tbody>
              {severities.map((row, index) => {
                const percent = (row.count / total) * 100.0;

                const strokeColor = strokeColors[index];
                return (
                  <tr key={`row${index}`}>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      <Link
                        to={`/issues?status=open&severity=${row.severity}`}
                        style={{ color: '#5d94e4' }}
                        title={`${row.severity}`}
                      >
                        {`${getSeverityName(row.severity)}`}
                      </Link>
                    </td>
                    <td style={{ whiteSpace: 'nowrap', textAlign: 'right', paddingLeft: '8px' }}>
                      <Link to={`/issues?status=open&severity=${row.severity}`} style={{ color: '#5d94e4' }}>
                        {row.count.toLocaleString()}
                      </Link>
                    </td>
                    <td style={{ width: '99%', paddingLeft: '8px' }}>
                      <Link to={`/issues?status=open&severity=${row.severity}`} style={{ color: '#5d94e4' }}>
                        <Progress percent={percent} showInfo={false} strokeColor={strokeColor} />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      }}
    </QueryResult>
  );
}

export default OpenIssuesBySeverity;

import styled from 'styled-components';

export const IssuesDetail = styled.div`
  .issues-link {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    > *:first-child {
      margin-right: 0.5rem;
    }
  }
`;

import React from 'react';
import { Select } from 'antd';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

const ASSESSMENTS_QUERY = gql`
  query AssessmentsForSelect {
    assessments(pageSize: 200) {
      nodes {
        id
        name
      }
    }
  }
`;

export interface Props {
  onChange: (assessmentId?: string) => void;
  onBlur: () => void;
  value?: string;
}

function AssessmentSelector(props: Props) {
  const { onChange, value, onBlur } = props;

  return (
    <Query query={ASSESSMENTS_QUERY} fetchPolicy="cache-first">
      {(results) => {
        const { data, loading } = results;
        const items = data && data.assessments && data.assessments.nodes ? data.assessments.nodes : [];
        items?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), { sensitivity: 'base' }));
        return (
          <Select
            loading={loading}
            value={value || 'default'}
            style={{ width: '100%' }}
            onBlur={onBlur}
            onChange={(selected) => {
              const selectedStr = selected ? selected.toString() : undefined;

              onChange(selectedStr === 'default' ? undefined : selectedStr);
            }}
          >
            <Select.Option value="default">Select Detector</Select.Option>
            {items.map((item, index) => {
              return (
                <Select.Option key={`${item.id}-${index}`} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        );
      }}
    </Query>
  );
}

export default AssessmentSelector;

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { EventDefinitionFunction, Maybe } from 'typings';
import Paper from 'components/ui/Paper';

import { CollapsePanel } from 'components/ui/Collapse/Collapse';
import Markdown from 'components/ui/Markdown';

const OpEventDefinitionFunctionListItemRoot = styled.div`
  .event-definition-function-paper {
    padding: 12px;
    border: 1px solid ${p => p.theme.grey300};
    display: flex;
  }

  .event-definition-function-content {
    flex: 1;
  }

  .event-definition-function-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 1.025em;
    color: ${p => p.theme.grey700};
    margin-bottom: 8px;
  }

  .event-definition-function-desc {
    color: ${p => p.theme.grey400};
    line-height: 1.25em;
  }

  .event-definition-function-action {
    margin-left: 24px;
  }
`;

interface OpEventDefinitionFunctionListItemProps {
  eventDefinitionFunction: EventDefinitionFunction;
  action?: ReactNode;
}

function OpEventDefinitionFunctionListItem(props: OpEventDefinitionFunctionListItemProps) {
  const { eventDefinitionFunction, action } = props;
  const title = getNameFromEventDefinitionFunction(eventDefinitionFunction);
  const desc = getDescriptionFromEventDefinitionFunction(eventDefinitionFunction);
  const overview = getOverviewFromEventDefinitionFunction(eventDefinitionFunction);

  return (
    <OpEventDefinitionFunctionListItemRoot className="event-definition-function-li-root">
      <Paper classes={{ root: 'event-definition-function-paper' }} rounded elevation={0}>
        <div className="event-definition-function-content">
          <div className="event-definition-function-title">{title}</div>
          {desc && <div className="event-definition-function-desc">{desc}</div>}
          {overview && (
            <CollapsePanel
              defaultCollapsed
              maxHeight={400}
              collapsedTriggerContent={'More...'}
              openTriggerContent="Hide"
            >
              <Markdown source={overview} />
            </CollapsePanel>
          )}
        </div>
        {action && <div className="event-definition-function-action">{action}</div>}
      </Paper>
    </OpEventDefinitionFunctionListItemRoot>
  );
}

function getNameFromEventDefinitionFunction(eventDefinitionFunction: EventDefinitionFunction): string {
  const eventDefinitionName = eventDefinitionFunction.eventDefinition?.name || null;
  const functionName = eventDefinitionFunction?.function?.name || null;

  if (eventDefinitionFunction.name) return eventDefinitionFunction.name;

  return functionName && eventDefinitionName
    ? `${functionName} when event "${eventDefinitionName}" is discovered.`
    : functionName || '--';
}

function getDescriptionFromEventDefinitionFunction(eventDefinitionFunction: EventDefinitionFunction): Maybe<string> {
  const { description, function: func } = eventDefinitionFunction;

  if (description) return description;
  if (func && func.description) return func.description;

  return null;
}

function getOverviewFromEventDefinitionFunction(eventDefinitionFunction: EventDefinitionFunction): Maybe<string> {
  const { overview, function: func } = eventDefinitionFunction;

  if (overview) return overview;
  if (func && func.overview) return func.overview;

  return null;
}

export default OpEventDefinitionFunctionListItem;

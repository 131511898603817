import gql from 'graphql-tag';

const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on Notification {
    id
    clientId
    createdAt
    createdBy
    name
    description
    isEnabled
    scope
    eventTypes
    accountId
    assessmentId
    issueDefinitionId
    projectId
    updatedAt
    updatedBy
    recipients {
      id
      name
      createdAt
      createdBy
      isEnabled
      lastError
      lastErrorAt
      lastUsedAt
      type
      config
      updatedAt
      updatedBy
    }
  }
`;

export default NOTIFICATION_FRAGMENT;

import { ConfigSettingGroup } from 'typings';

const CONFIG_SETTINGS: ConfigSettingGroup[] = [
  {
    name: 'AWS',
    key: 'aws',
    configSettings: [
      {
        key: 'aws_trusted_accounts',
        name: 'Trusted AWS Accounts',
        inputCode: 'aws_trusted_accounts',
        type: 'string',
        many: true,
        description: 'Account IDs or ARNs of trusted AWS accounts'
      }
    ]
  },
  {
    name: 'Network',
    key: 'network',
    configSettings: [
      {
        key: 'known_cidrs',
        name: 'Known CIDRs',
        inputCode: 'cidr',
        type: 'string',
        many: true,
        description: 'CIDR Addresses of Known Networks'
      }
    ]
  },
  {
    key: 'cloudtrail',
    name: 'Cloudtrail',
    configSettings: [
      {
        key: 'cloudwatch_default_bucket',
        name: 'S3 Bucket for Cloudtrail logs',
        inputCode: 'aws_s3_bucket_select',
        type: 'string',
        many: false,
        description: 'Name of S3 bucket to send logs to.'
      },
      {
        key: 'cloudwatch_home_region',
        name: 'Home region for Cloudtrail logs',
        inputCode: 'aws_regions',
        type: 'string',
        many: false,
        description: 'Specify a home region for Cloudtrail and Cloudwatch logs.'
      },
      {
        key: 'central_logging_account_id',
        name: 'Central Account ID',
        description: 'The AWS Account ID (not ARN) for central log collection.',
        type: 'string',
        many: false
      }
    ]
  }
];

export default CONFIG_SETTINGS;

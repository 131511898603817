import React from 'react';

import { Link } from 'react-router-dom';

import { RESOURCE_TYPE_LIST_QUERY } from 'queries';

import { ResourceTypeConfigItem } from 'components/cloudResources/cloudResourceTypesConfig';
import { ResourceColumn, TagList } from 'components/cloudResources';
import IssueCountBadge from 'components/issue/IssueCountBadge';

import ALL_RESOURCES_QUERY from './query';

import CloudAccountLink from '../../components/CloudAccountLink';
import { rawToDashed } from 'utilities/resourceType';

const allResourceTypes: ResourceTypeConfigItem = {
  key: 'all-resources', // used to be all-aws-resources
  label: 'All Resources',
  labelPlural: 'Cloud resources',
  labelSingular: 'Cloud resource',
  tableQuery: () => ALL_RESOURCES_QUERY,
  tableQueryVariables: resourceType =>
    resourceType
      ? {
          dops_resource_type: resourceType
        }
      : {},
  filters: [
    {
      name: 'resourceType',
      label: 'Resource type',
      qsKey: 'rt',
      many: false,
      variableName: () => 'dops_resource_type',
      query: RESOURCE_TYPE_LIST_QUERY,
      clientFiltered: true,
      getListFromData: data => data.resource_types,
      transformDataToOption: dataItem => ({
        key: dataItem.key.replace(/::/g, '-'),
        label: dataItem.name
      }),
      transformValueToVariable: value => {
        if (!value) return value;

        return value.replace(/-/g, '::');
      },
      transformValueToLabel: lookupMap => value => {
        return value.replace(/-/g, '::');
      }
    }
  ],
  tableColumnsConfig: [
    {
      title: 'Resource',
      key: 'resource',
      render: (_, item) => <ResourceColumn resource={item} />
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      className: 'no-wrap',
      render: account => <CloudAccountLink account={account} />
    },
    {
      title: 'Type',
      dataIndex: 'dops_resource_type',
      key: 'dops_resource_type',
      className: 'no-wrap',
      render: type => (
        <Link
          to={`/inventory/resources/${rawToDashed(type)}`}
          onClick={(e: Event) => {
            e.stopPropagation();
          }}
        >
          {type}
        </Link>
      )
    },
    {
      title: 'Issues',
      dataIndex: 'dops_issue_summary.open_count',
      key: 'issue_summary_open',
      align: 'center' as 'right',
      className: 'no-wrap text-center',
      render: issueCount => <IssueCountBadge issueCount={issueCount} />
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      className: 'tags-col',
      width: '40%',
      render: tags => <TagList tags={tags} />
    }
  ]
};

export default allResourceTypes;

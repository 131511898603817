import React, { FunctionComponent, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { RouteComponentProps } from 'react-router';
import { Maybe } from 'typings';
import { Alert, Button, Collapse, Select, Typography } from 'antd';
import Form, { FormField } from 'components/ui/Form';
import { FormikValues } from 'formik';
import ProjectTree from 'components/project/ProjectTree';

import styled from 'styled-components';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

import { AWS_CLOUD_PROVIDER, AZURE_CLOUD_PROVIDER } from './providers';

const Root = styled.div`
  .ant-collapse {
    background-color: #fefefe;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 16px;
  }
`;

export enum CloudProvider {
  aws = 'aws',
  azure = 'azure'
}

export interface PanelDefinition {
  key: string;
  name: string;
  children: FunctionComponent<PanelChildrenProps>;
}

export interface CloudProviderDefinition {
  key: CloudProvider;
  name: string;
  panels: PanelDefinition[];
}

const CLOUD_PROVIDER_DEFINITIONS: CloudProviderDefinition[] = [AWS_CLOUD_PROVIDER, AZURE_CLOUD_PROVIDER];

const { Panel } = Collapse;

export interface CloudAccount {
  account_id?: Maybe<string>;
  provider?: Maybe<CloudProvider>;
  project_id?: Maybe<string>;
  provision_url?: Maybe<string>;
  nickname?: Maybe<string>;
  policy_code?: Maybe<string>;
  assumerole_account_id?: Maybe<string>;
}

interface Props
  extends RouteComponentProps<{
    step?: string;
  }> {
  existingCloudAccount: Maybe<CloudAccount>;
}

function CloudAccountNew(props: Props) {
  const {
    existingCloudAccount,
    match: {
      params: { step }
    },
    location: { search },
    history
  } = props;
  const searchParams = new URLSearchParams(search);
  const idFromSearchParams = searchParams.has('id') ? searchParams.get('id') : null;
  const [cloudAccountState, setCloudAccountState] = useState<CloudAccount>(existingCloudAccount || {});

  const isProvisioned = isCloudAccountProvisioned(existingCloudAccount);

  const activeStep = step || undefined;

  if (!step) {
    const initStep = getInitStepFromCloudAccount(cloudAccountState);
    if (initStep) return <Redirect to={`/cloud-accounts/add/${initStep}`} />;
  }

  const cloudProviderDefinition: CloudProviderDefinition | null = cloudAccountState.provider
    ? CLOUD_PROVIDER_DEFINITIONS.find((def) => def.key === cloudAccountState.provider) || null
    : null;

  const updateCloudAccountState = (updatedFields: CloudAccount) =>
    setCloudAccountState({ ...cloudAccountState, ...updatedFields });
  const resetCloudAccountState = () => setCloudAccountState({});

  const updateStep = (step: string | null, accountId?: string) => {
    // build path...
    let path = `/cloud-accounts/add/${step || ''}`;
    if (accountId || idFromSearchParams) path += `?id=${accountId || idFromSearchParams}`;

    history.push(path);
  };

  const collapseChildrenProps = {
    updateCloudAccountState,
    cloudAccount: cloudAccountState,
    updateStep,
    cloudProviderDefinition
  };

  if (existingCloudAccount && existingCloudAccount.provider && isProvisioned)
    return (
      <VerifiedAccountAlert
        cloudAccount={existingCloudAccount}
        provider={existingCloudAccount.provider}
        updateCloudAccountState={updateCloudAccountState}
        resetCloudAccountState={resetCloudAccountState}
      />
    );

  return (
    <Root>
      <Collapse
        activeKey={activeStep}
        onChange={(step) => {
          const newStep = Array.isArray(step) ? step.find((s) => s !== activeStep) || null : step;

          updateStep(newStep);
        }}
        destroyInactivePanel
      >
        <Panel
          header={
            cloudAccountState.provider
              ? `Cloud Provider: ${getProviderLabel(cloudAccountState.provider)}`
              : 'Cloud Provider'
          }
          key="cloud-provider"
        >
          <SelectCloudProvider {...collapseChildrenProps} key={'cloud-provider'} />
        </Panel>

        {cloudProviderDefinition && (
          <Panel header="Project" key="project">
            <SelectProject {...collapseChildrenProps} key={'project'} />
          </Panel>
        )}

        {cloudProviderDefinition &&
          cloudAccountState[PROJECT_ID] &&
          cloudProviderDefinition.panels.map((panelDefinition) => {
            const { name, key, children: PanelChildren } = panelDefinition;

            return (
              <Panel header={name} key={key}>
                <PanelChildren key={key} {...collapseChildrenProps} />
              </Panel>
            );
          })}
      </Collapse>
    </Root>
  );
}

function getInitStepFromCloudAccount(cloudAccount: CloudAccount): string | null {
  if (!cloudAccount.provider) return 'cloud-provider';
  if (!cloudAccount.project_id) return 'project';

  return null;
}

function getProviderLabel(provider: CloudProvider): string {
  switch (provider) {
    case 'aws':
      return 'AWS';

    case 'azure':
      return 'Azure';

    default:
      return 'Cloud';
  }
}

const VerifiedAccountAlertRoot = styled.div`
  .alert-actions {
    margin-top: 16px;

    & > * {
      margin-right: 8px;
    }
  }
`;

interface VerifiedAccountAlertProps {
  cloudAccount: CloudAccount;
  provider: CloudProvider;
  updateCloudAccountState: (updatedFields: CloudAccount) => void;
  resetCloudAccountState: () => void;
}

function VerifiedAccountAlert(props: VerifiedAccountAlertProps) {
  const { provider, cloudAccount, resetCloudAccountState } = props;
  const providerLabel = getProviderLabel(provider);

  return (
    <VerifiedAccountAlertRoot>
      <Alert
        type="success"
        message={`${providerLabel} account: "${cloudAccount.nickname}" has successfully been created.`}
        description={
          <div className="alert-description">
            <div className="alert-actions">
              <Link to={`/cloud-accounts`}>
                <Button>{'Back to Accounts'}</Button>
              </Link>
              <Link
                to={`/cloud-accounts/add`}
                onClick={() => {
                  resetCloudAccountState();
                }}
              >
                <Button>{'Add another Cloud Account'}</Button>
              </Link>
            </div>
          </div>
        }
      />
    </VerifiedAccountAlertRoot>
  );
}

function isCloudAccountProvisioned(cloudAccount: CloudAccount | null): boolean {
  if (!cloudAccount) return false;
  if (!cloudAccount.provider) return false;

  switch (cloudAccount.provider) {
    case 'aws': {
      // test for aws.
      return !Boolean(cloudAccount.provision_url);
    }
    case 'azure': {
      // not sure what else there is to check atm...
      return true;
    }

    default:
      return false;
  }
}

// SELECT CLOUD PROVIDER
const PROVIDER = 'provider';

export interface PanelChildrenProps {
  cloudAccount: CloudAccount;
  updateCloudAccountState: (updatedFields: CloudAccount) => void;
  key: string;
  updateStep: (step: string, accountId?: string) => void;
  cloudProviderDefinition: null | CloudProviderDefinition;
}

const selectCloudProviderFormSchema = Yup.object().shape({
  [PROVIDER]: Yup.string().required('isRequired')
});

function SelectCloudProvider(props: PanelChildrenProps) {
  const { updateCloudAccountState, cloudAccount, updateStep } = props;

  return (
    <Form
      initialValues={{
        provider: (cloudAccount && cloudAccount.provider) || undefined
      }}
      validationSchema={selectCloudProviderFormSchema}
      onSubmit={(values: FormikValues, actions) => {
        const provider: CloudProvider = values.provider;

        // update provider value saved in state.
        updateCloudAccountState({ provider });

        actions.setSubmitting(false);

        updateStep('project');
      }}
      allowCleanSubmits={false}
    >
      {(formValues) => {
        const { canSubmit, isSubmitting } = formValues;

        return (
          <>
            <FormField name={PROVIDER} label="Cloud Provider">
              {({ value, handleChange }) => {
                return (
                  <Select
                    value={value}
                    onChange={handleChange}
                    placeholder="Select Cloud Provider"
                    disabled={Boolean(cloudAccount.account_id && cloudAccount.provider)}
                  >
                    {CLOUD_PROVIDER_DEFINITIONS.map((def) => (
                      <Select.Option key={def.key} value={def.key}>
                        {def.name}
                      </Select.Option>
                    ))}
                  </Select>
                );
              }}
            </FormField>
            <Button htmlType="submit" type="primary" disabled={isSubmitting || !canSubmit}>
              {'Next'}
            </Button>
          </>
        );
      }}
    </Form>
  );
}

// SELECT PROJECT
const PROJECT_ID = 'project_id';
const selectProjectSchema = Yup.object().shape({
  [PROJECT_ID]: Yup.string().required('required')
});

function SelectProject(props: PanelChildrenProps) {
  const {
    cloudAccount: { account_id, project_id },
    updateCloudAccountState,
    cloudProviderDefinition,
    updateStep
  } = props;

  return (
    <>
      <Alert
        type="warning"
        showIcon
        message="Selecting a Project"
        description={
          <>
            <Typography.Paragraph>
              {`Projects are central to the DisruptOps platform and are used to determine what the platform executes against Cloud Accounts in the system as well as applying effective permissions. Projects are organized into a "Project Tree" with a single "Root" Project that is created by default. Cloud Accounts are assigned to a single Project, but Projects can have "children" Projects (much like files in a directory based file system.)`}
            </Typography.Paragraph>
            <Typography.Paragraph>
              {
                'Selecting the Project in which your Cloud Account lives within the DisruptOps platform is a critical step that should be done with utmost care. The Project that is chosen will determine the following:'
              }
            </Typography.Paragraph>
            <Typography.Paragraph>
              <ul>
                <li>
                  <strong>{'Detectors: '}</strong>
                  {
                    'Detectors can be scoped to one or many Projects and will assess ALL Cloud Accounts below the selected Project(s). Detectors on their own will not alter assessed resources but can create Issues. Issues, however, can trigger Guardrails which are meant to make changes on Cloud Resources. Read below for more information on how Projects affect Guardrail Behavior.'
                  }
                  <br />
                  <a href="/detectors" target={'_blank'}>{`View configured Detectors.`}</a>
                </li>
                <li>
                  <strong>{'Ops: '}</strong>
                  {`Ops are used to automate actions and remediations in response to Events that are configured as Triggers. Ops and their Triggers can be scoped to Projects. This will set the context in which the Op is allowed to act. Ops can only act upon Cloud Accounts below their assigned Project. Users can create, read, update and destroy Ops for which they have adequate Project permissions. Make sure you are aware of the Ops that are scoped for a given Project before placing a Cloud Account there.`}
                  <br />
                  <a href="/ops" target={'_blank'}>{`View configured Ops.`}</a>
                </li>
                <li>
                  <strong>{'Permissions: '}</strong>
                  {`The Project in which a Cloud Account lives will determine the permissions needed in order to edit, remove, or move said Cloud Account. Permissions are managed via User Groups and are meant to traverse down through the Project Tree. For example, a User that possesses a permission for "Project X" will have that same permission for All Cloud Accounts and Projects below "Project X".`}
                  <br />
                  <a href="/organization-settings/group-management">{`Manage groups.`}</a>
                </li>
              </ul>
            </Typography.Paragraph>
          </>
        }
        style={{ marginBottom: '24px' }}
      />
      <Form
        initialValues={{
          [PROJECT_ID]: project_id || undefined
        }}
        allowCleanSubmits={false}
        validationSchema={selectProjectSchema}
        onSubmit={(values, actions) => {
          const project_id: string = values[PROJECT_ID];

          updateCloudAccountState({ project_id });

          actions.setSubmitting(false);

          if (cloudProviderDefinition) {
            const nextPanel = cloudProviderDefinition.panels[0] || null;

            if (nextPanel) updateStep(nextPanel.key);
          }
        }}
      >
        {(formValues) => {
          const { setFieldValue, setFieldTouched, isSubmitting, canSubmit } = formValues;

          return (
            <>
              <FormField name={PROJECT_ID} label="Project">
                {({ value, name }) => {
                  return (
                    <ProjectTree
                      checkable
                      requiredPermissions={[Permissions.MODIFY_ACCOUNTS]}
                      disabled={Boolean(account_id && project_id)}
                      checkedProjectIds={value ? [value] : []}
                      onCheck={(checked) => {
                        // should only allow one node to be checked
                        const newChecked =
                          Array.isArray(checked) && checked.length > 0 ? checked.find((key) => key !== value) : [];

                        setFieldValue(name, newChecked);
                        setFieldTouched(name, true);
                      }}
                    />
                  );
                }}
              </FormField>
              <Button htmlType="submit" type="primary" disabled={isSubmitting || !canSubmit}>
                {'Next'}
              </Button>
            </>
          );
        }}
      </Form>
    </>
  );
}

export default CloudAccountNew;

import React from 'react';
import styled from 'styled-components';

import DopeIcon from 'components/ui/DopeIcon';

interface Props {
  issueStats?: {
    openIssueCount: number;
    exemptedIssueCount: number;
    resolvedIssueCount: number;
  };
}

const Root = styled.div`
  display: flex;
`;

const Badge = styled.div`
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 20px;
  margin-right: 4px;
  border-radius: 10px;
  font-weight: 600;
  color: #fff;
  background-color: ${p => p.theme.grey400};

  &:last-child {
    margin-right: 0;
  }

  &.success,
  &.failure,
  &.error {
    font-weight: 400;
  }

  &.scheduled {
    background-color: #42a5f5;
  }

  &.success {
    background-color: ${p => p.theme.success};
  }

  &.warning {
    background-color: ${p => p.theme.warning};
  }

  &.error {
    background-color: ${p => p.theme.error};
  }
`;

const badgeIconStyles = {
  width: '12px',
  height: '12px',
  marginRight: '3px'
};

function IssueStatsBadge(props: Props) {
  const { issueStats } = props;

  if (!issueStats) {
    return <span>N/A</span>;
  }

  return (
    <Root>
      {Boolean(issueStats && issueStats.openIssueCount && issueStats.openIssueCount > 0) && (
        <Badge className="warning">
          <DopeIcon name="ISSUE_OPEN2" style={badgeIconStyles} size={12} />
          {issueStats && issueStats.openIssueCount}
        </Badge>
      )}
      {Boolean(issueStats && issueStats.exemptedIssueCount && issueStats.exemptedIssueCount > 0) && (
        <Badge>
          <DopeIcon name="ISSUE_EXEMPT" style={badgeIconStyles} size={12} />
          {issueStats && issueStats.exemptedIssueCount}
        </Badge>
      )}
      {Boolean(issueStats && issueStats.resolvedIssueCount && issueStats.resolvedIssueCount > 0) && (
        <Badge className="success">
          <DopeIcon name="ISSUES" style={badgeIconStyles} size={12} />
          {issueStats && issueStats.resolvedIssueCount}
        </Badge>
      )}
    </Root>
  );
}

export default IssueStatsBadge;

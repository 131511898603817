import React from 'react';
import styled from 'styled-components';
import { useProjectTree } from './ProjectTree';
import { Spin } from 'antd';

interface CollapseTitleReadViewProps {
  value: any;
}

const CollapseTitleReadViewRoot = styled.div`
  display: flex;
  flex: 1;
`;

function ProjectScopeCollapseTitleReadView(props: CollapseTitleReadViewProps) {
  const { value: projectIds } = props;

  const { loading, allProjects = [] } = useProjectTree();

  if (loading) return <Spin size="small" />;
  
  if (!projectIds) return <>{'Any'}</>;

  if (!Array.isArray(projectIds)) return <>{''}</>; // safe catch. Should never happen?

  return (
    <CollapseTitleReadViewRoot>
      {projectIds.map((id) => {
        const project = allProjects.find((project) => project.project_id === id);
        if (!project) return null;
        return project.name;
      })}
    </CollapseTitleReadViewRoot>
  );
}

export default ProjectScopeCollapseTitleReadView;

import React, { ReactNode } from 'react';
import { Formik, FormikProps, FormikConfig, FormikActions, FormikValues } from 'formik';
import styled from 'styled-components';

const FullForm = styled.form`
  width: 100%;
`;

export interface FormRenderProps extends FormikProps<any> {
  canSubmit: boolean;
}

interface Props extends Partial<FormikConfig<any>> {
  className?: string;
  allowCleanSubmits?: boolean;
  children: (formikProps: FormRenderProps) => ReactNode;
  onSubmit?: (result: FormikValues, formikActions: FormikActions<FormikValues>) => any | void;
  initialValues: FormikValues;
  enableReinitialize?: boolean;
}

function Form(props: Props) {
  const {
    className,
    children,
    validationSchema,
    allowCleanSubmits = true,
    onSubmit,
    enableReinitialize = false
  } = props;

  const handleSubmit = (values, actions) => {
    if (onSubmit) return onSubmit(values, actions);
  };

  return (
    <Formik {...props} onSubmit={handleSubmit} enableReinitialize={enableReinitialize}>
      {(formikProps) => {
        const { handleSubmit, handleReset, errors, dirty, values } = formikProps;

        const schemaFields = (validationSchema ? validationSchema.fields : {}) || {};

        // console.log({ values, schemaFields });

        const canSubmit =
          (Object.keys(values).length === 0 && Object.keys(schemaFields).length === 0) || // allow forms with 0 fields to submit
          (Object.keys(errors).length === 0 && (allowCleanSubmits || dirty));

        return (
          <FullForm className={className} onReset={handleReset} onSubmit={handleSubmit} autoComplete="off">
            {children({ ...formikProps, canSubmit })}
          </FullForm>
        );
      }}
    </Formik>
  );
}

export default Form;

import React from 'react';
import ConfirmButton from './ConfirmButton';
import { TooltipPlacement } from 'antd/lib/tooltip';

export interface Props {
  id: string;
  disabled?: boolean;
  disabledMessage?: string;
  confirmMessage?: string;
  confirmPlacement?: string;
  classes?: {
    root?: string;
    button?: string;
  };
  onDelete: (id: string) => any | void;
  loading?: boolean;
  children?: string;
  mode?: 'default' | 'danger' | 'primary';
  tooltipPlacement?: TooltipPlacement;
}

function DeleteButton(props: Props) {
  const { onDelete, loading, children, mode = 'danger', tooltipPlacement, ...confirmButtonProps } = props;

  return (
    <ConfirmButton
      mode={mode}
      icon="delete"
      loading={loading}
      onConfirm={onDelete}
      tooltipPlacement={tooltipPlacement}
      {...confirmButtonProps}
    >
      {children}
    </ConfirmButton>
  );
}

export default DeleteButton;

import gql from 'graphql-tag';

const CLASSIFIED_EVENT_LIST_QUERY = gql`
  query ClassifiedEventList(
    $pageNumber: Int = 1
    $pageSize: Int
    $search: String
    $region: [String]
    $cloudAccountId: [String]
    $eventDefinitionId: [String]
    $source: String
    $eventName: String
  ) {
    classifiedEvents(
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      region: $region
      cloudAccountId: $cloudAccountId
      eventDefinitionId: $eventDefinitionId
      source: $source
      eventName: $eventName
    ) {
      pageInfo {
        current
        total
        size
      }
      nodes {
        receivedAt
        id
        eventId
        eventType
        vendor
        vendorProduct
        region
        cloudAccountId
        contextId
        clientId
        publisher
        eventDefinitionId
        eventDefinitionName
        eventData
      }
    }
  }
`;

export default CLASSIFIED_EVENT_LIST_QUERY;

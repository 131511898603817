import { signIn, getIdentity, createEmptyIdentity } from 'services/auth';
import { typeDef as issueTypes, resolvers as issueResolvers } from './issues';
import { typeDef as assessmentTypes, resolvers as assessmentResolvers } from './assessment';
import { typeDef as guardrailTypes, resolvers as guardrailResolvers } from './guardrails';

export const typeDefs = [
  `
  type Identity {
    user_id: ID
    user_pool_id: ID
    email: String
  }

  type CloudAccount {
    account_id
    name
  }

  type Query {
    identity: Identity
    registrationData: RegistrationData
    issues: [Issue]
    accounts: [CloudAccount]
  }

  type Mutation {
    signIn(username: String!, password: String!): Identity
  }

`,
  issueTypes,
  assessmentTypes,
  guardrailTypes
];

export const resolvers = [
  {
    Query: {
      identity: () => {
        return getIdentity();
      }
    },
    Mutation: {
      signIn: (_, { username, password }) => {
        return signIn({ username, password });
      }
    }
  },
  issueResolvers,
  assessmentResolvers,
  guardrailResolvers
];

const defaults = {
  identity: createEmptyIdentity()
};

export function getDefaults() {
  return { ...defaults };
}

import { Col, Row, Typography } from 'antd';
import { GridCard } from 'components/ui/Card';
import ErrorAlert from 'components/ui/ErrorAlert';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  exemptedResult;
  openResult;
  resolvedResult;
}

export default function GettingStartedKpi(props: Props) {
  const { exemptedResult, openResult, resolvedResult } = props;

  const { data: exemptedData, error: exemptedError, loading: exemptedLoading } = exemptedResult || {};
  const { data: openData, error: openError, loading: openLoading } = openResult || {};
  const { data: resolvedData, error: resolvedError, loading: resolvedLoading } = resolvedResult || {};

  const { governanceStats: { exemptedIssueCount = null } = {} } = exemptedData || {};
  const { governanceStats: { openIssueCount = null } = {} } = openData || {};
  const { governanceStats: { resolvedIssueCount = null } = {} } = resolvedData || {};

  const loading = exemptedLoading || openLoading || resolvedLoading;

  if (loading) return null;

  const error = exemptedError || openError || resolvedError;

  if (error)
    return (
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <ErrorAlert error={error} />
        </Col>
      </Row>
    );

  const hasAtLeastOneIssue = openIssueCount > 0 || resolvedIssueCount > 0 || exemptedIssueCount > 0;

  if (!hasAtLeastOneIssue) {
    return (
      <Row gutter={[12, 12]}>
        <Col xs={24}>
          <GridCard>
            <Typography.Paragraph className="kpi kpi1">
              DisruptOps has not found any Issues yet. If you've just added your first account, we should find issues
              within an hour. In the meantime you may want to continue your configuration:&nbsp;&nbsp;
              <Link to="/getting-started">Getting Started Guide</Link>
            </Typography.Paragraph>
          </GridCard>
        </Col>
      </Row>
    );
  }

  return <></>;
}

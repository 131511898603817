import React from 'react';

import { ResourceTypeConfigItem } from 'components/cloudResources/cloudResourceTypesConfig';
import { ResourceColumn, TagList } from 'components/cloudResources';
import IssueCountBadge from 'components/issue/IssueCountBadge';

import RegionLink from '../../components/RegionLink';
import CloudAccountLink from '../../components/CloudAccountLink';

import ALL_SECURITY_GROUPS_QUERY from './query';

import AWSEC2SecurityGroupDetailTable from './components/AWSEC2SecurityGroupDetailTable';

const awsEC2SecurityGroups: ResourceTypeConfigItem = {
  label: 'EC2 Security Groups',
  labelPlural: 'EC2 Security Groups',
  labelSingular: 'EC2 Security Group',
  key: 'AWS::EC2::SecurityGroup',
  tableQuery: () => ALL_SECURITY_GROUPS_QUERY,
  tableColumnsConfig: [
    {
      title: 'Resource',
      key: 'resource',
      className: 'no-wrap',
      render: (_, item) => <ResourceColumn resource={item} />
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      className: 'no-wrap',
      render: account => <CloudAccountLink account={account} />
    },
    {
      title: 'Region',
      dataIndex: 'dops_region',
      key: 'dops_region',
      className: 'no-wrap',
      render: region => <RegionLink region={region} />
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      className: 'no-wrap'
    },
    {
      title: 'Issues',
      dataIndex: 'dops_issue_summary.open_count',
      key: 'issue_summary_open',
      align: 'center' as 'right',
      className: 'no-wrap text-center',
      render: issueCount => <IssueCountBadge issueCount={issueCount} />
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      className: 'tags-col',
      width: '33%',
      render: tags => <TagList tags={tags} />
    }
  ],

  detailTable: AWSEC2SecurityGroupDetailTable
};

export default awsEC2SecurityGroups;

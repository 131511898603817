import React, { Component, ReactNode } from 'react';

import PushContext from './PushContext';
import { PUSH_URL } from 'constants/runtimeConfig';
import SSE from 'services/sse';
import { client } from 'services/graphql';
import ApolloClient from 'apollo-client';
import { ActiveTask } from 'typings';
import AuthContext from 'components/app/Auth/AuthContext';

interface Props {
  children: ReactNode;
}

class PushProvider extends Component<Props> {
  static contextType = AuthContext;

  eventSource?: SSE;
  graphqlClient: ApolloClient<any>;

  eventMap: {
    [key: string]: (task: ActiveTask) => any;
  };

  constructor(p: Props) {
    super(p);

    this.eventSource = new SSE({
      debug: this.context?.devSettings?.isSSEDebuggingEnabled ? true : false
    });
    this.graphqlClient = client;

    this.handleMessage = this.handleMessage.bind(this);
    this.handleAssessmentStarted = this.handleAssessmentStarted.bind(this);
    this.handleAssessmentCompleted = this.handleAssessmentCompleted.bind(this);
    this.handleActionStarted = this.handleActionStarted.bind(this);
    this.handleActionCompleted = this.handleActionCompleted.bind(this);

    this.eventMap = {
      assessmentTaskStarted: this.handleAssessmentStarted,
      assessmentTaskCompleted: this.handleAssessmentCompleted,
      actionTaskStarted: this.handleActionStarted,
      actionTaskCompleted: this.handleActionCompleted
    };
  }

  componentDidUpdate() {
    this.eventSource?.removeListener('message', this.handleMessage);
    this.eventSource?.close();

    this.eventSource = new SSE({
      debug: this.context?.devSettings?.isSSEDebuggingEnabled ? true : false
    });

    this.eventSource.on('message', this.handleMessage);
    this.eventSource.connect(PUSH_URL);
  }

  async componentDidMount() {
    this.eventSource = new SSE({
      debug: this.context?.devSettings?.isSSEDebuggingEnabled ? true : false
    });

    this.eventSource.on('message', this.handleMessage);
    this.eventSource.connect(PUSH_URL);
  }

  handleMessage(envelope: any) {
    this.updateCache(envelope);
  }

  render() {
    const { children } = this.props;
    if (!this.eventSource) return null;

    const ctx = { eventSource: this.eventSource };
    return <PushContext.Provider value={ctx}>{children}</PushContext.Provider>;
  }

  updateCache(envelope: any) {
    const task: ActiveTask = envelope.message.task;

    const handler = this.eventMap[envelope.eventType];

    if (handler) {
      handler(task);
    }
  }

  handleAssessmentStarted(task: ActiveTask) {
    const data = {
      id: task.subjectId,
      data: {
        isActive: true
      }
    };

    client.writeData(data);
  }

  handleAssessmentCompleted(task: ActiveTask) {
    const data = {
      id: task.subjectId,
      data: {
        isActive: false
      }
    };

    client.writeData(data);
  }

  handleActionStarted(task: ActiveTask) {
    const data = {
      id: task.subjectId,
      data: {
        isActing: true
      }
    };

    client.writeData(data);
  }

  handleActionCompleted(task: ActiveTask) {
    const data = {
      id: task.subjectId,
      data: {
        isActing: false
      }
    };

    client.writeData(data);
  }
}

export default PushProvider;

import React from 'react';
import styled from 'styled-components';

type ContainerSize = 'xl' | 'large' | 'lg' | 'medium' | 'md' | 'small' | 'sm' | 'xs' | 'fluid';

const Root = styled.div<{
  width?: number | null;
  gutterString: string;
}>`
  display: grid;
  grid-template-columns: ${p => p.gutterString} ${p => (p.width ? `minmax(min-content, ${p.width}px)` : 'auto')} ${p =>
      p.gutterString};
  grid-template-areas: '. main .';
  padding: 32px 0 100px;

  &.left-align {
    grid-template-columns: ${p => p.gutterString} minmax(min-content, ${p => p.width || `1400`}px) ${p =>
        p.gutterString};
  }
`;

const Main = styled.div`
  grid-area: main;
`;

interface Props {
  children: any;
  // size?: 'small' | 'sm' | 'medium' | 'md' | 'large' | 'lg' | 'xs' | 'fluid';
  size?: ContainerSize;
  gutterWidth?: number;
  classes?: {
    root?: string;
    main?: string;
  };
  leftAlign?: boolean;
  onBackgroundClick?: () => any;
}

function getWidthFromSize(size?: ContainerSize) {
  if (size === 'xl') return 1400;

  if (size === 'large' || size === 'lg') return 1280;

  if (size === 'medium' || size === 'md') return 936;

  if (size === 'small' || size === 'sm') return 765;

  if (size === 'xs') return 400;

  return null;
}

function CenteredContainer(props: Props) {
  const { children, size = 'fluid', gutterWidth, classes = {}, onBackgroundClick, leftAlign = false } = props;
  let rootClasses = `centered-container ${leftAlign ? 'left-align' : ''}`;

  const gutter = gutterWidth === undefined ? 24 : gutterWidth;
  const gutterString = size === 'fluid' || leftAlign ? `${gutter}px` : `minmax(${gutter}px, auto)`;

  if (classes.root) rootClasses += ` ${classes.root}`;

  const width = getWidthFromSize(size);

  return (
    <Root
      className={rootClasses}
      onClick={e => {
        if (e.target === e.currentTarget && onBackgroundClick) onBackgroundClick();
      }}
      width={width}
      gutterString={gutterString}
    >
      <Main className={classes.main || ''}>{children}</Main>
    </Root>
  );
}

export default CenteredContainer;

import React, { useState } from 'react';
import { Button, Modal, Typography } from 'antd';
import { Permissions } from '@disruptops/neo-core';

import ProjectTree from './ProjectTree';

interface Props {
  title: string;
  projectId?: string;
  visible: boolean;
  requireSelection?: boolean;
  permissionsExplanations: string;
  requiredPermissions: Permissions | Permissions[];
  onChange: (projectId: string) => void;
  onCancel: () => void;
  loading: boolean;
}

function ProjectOwnershipModal(props: Props) {
  const {
    title,
    visible,
    projectId,
    requireSelection = true,
    permissionsExplanations,
    requiredPermissions,
    loading = false,
    onCancel,
    onChange
  } = props;

  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined | null>(projectId);

  const allowCancel = !requireSelection || projectId;

  return (
    <Modal
      width={800}
      visible={visible}
      title={title}
      onCancel={onCancel}
      footer={
        <>
          {allowCancel && <Button onClick={onCancel}>{'Cancel'}</Button>}

          <Button
            type="primary"
            disabled={!selectedProjectId}
            onClick={() => selectedProjectId && onChange(selectedProjectId)}
            loading={loading}
          >
            {'OK'}
          </Button>
        </>
      }
    >
      <Typography.Paragraph>{permissionsExplanations}</Typography.Paragraph>

      <ProjectTree
        requiredPermissions={requiredPermissions}
        checkable
        checkedProjectIds={selectedProjectId ? [selectedProjectId] : []}
        allowMultipleNodes={false}
        cascade={false}
        onCheck={(checkedKeys) => {
          const newValue = checkedKeys[0] || undefined;

          setSelectedProjectId(newValue);
        }}
        maxHeight="540px"
      />
    </Modal>
  );
}

export default ProjectOwnershipModal;

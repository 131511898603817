export function buildAzureProvisionScriptString(subscriptionId: string, policyCode: string): string {
  // policyCode will be a set list from jeremy

  const role = getAzureRoleFromPolicyCode(policyCode);

  if (!role) throw new Error('Invalid Role selected');

  const str = `
  function Create-AesManagedObject($key, $IV) {
    $aesManaged = New-Object "System.Security.Cryptography.AesManaged"
    $aesManaged.Mode = [System.Security.Cryptography.CipherMode]::CBC
    $aesManaged.Padding = [System.Security.Cryptography.PaddingMode]::Zeros
    $aesManaged.BlockSize = 128
    $aesManaged.KeySize = 256
    if ($IV) {
        if ($IV.getType().Name -eq "String") {
            $aesManaged.IV = [System.Convert]::FromBase64String($IV)
        }
        else {
            $aesManaged.IV = $IV
        }
    }
    if ($key) {
        if ($key.getType().Name -eq "String") {
            $aesManaged.Key = [System.Convert]::FromBase64String($key)
        }
        else {
            $aesManaged.Key = $key
        }
    }
    $aesManaged
}
function Create-AesKey() {
    $aesManaged = Create-AesManagedObject
    $aesManaged.GenerateKey()
    [System.Convert]::ToBase64String($aesManaged.Key)
}
$appDisplayName = "DisruptOps"
$subscriptionId = "${subscriptionId}"
$homepageURL = "https://disruptops.com"
$appURL = "https://api.disruptops.com"
$startDate = Get-Date
$StartDate = $startDate
$EndDate = $startDate.AddYears(10)
$secret = Create-AesKey
write-host "Creating DisruptOps application in azure AD"
$App = az ad app create --display-name $appDisplayName --available-to-other-tenants false --end-date $EndDate --homepage $homepageURL --reply-urls $appURL --key-type Password --native-app false --oauth2-allow-implicit-flow false --password $secret --start-date $startDate | ConvertFrom-Json -AsHashtable
sleep 5
write-host "Creating service principal in azure AD"
While ($null -eq $NewSP -and $Retries -le 3)
{
    try
    {
        # Allow for retried due to latency with the creation of the ad app
        $checkSP = az ad sp show --id $App.appId
        if ($checkSP.length -gt 2)
        {
            $NewSP = "exit"
        }
        else
        {
            az ad sp create --id $App.appId
            sleep 5
        }
        $Retries++;
    }
    catch
    {
        $Retries++;
        write-host "retrying sp create";
    }
}
$tenant = az account show | ConvertFrom-Json -AsHashtable
# Add Owner role to new principal app to specified subscription ID
$scope = ("/subscriptions/" + $subscriptionId)
az role assignment create --assignee $App.appId --role ${role} --scope $scope
try
{
    Write-Host("Use the following values for DisruptOps Azure subscription onboarding:")
    Write-Host("Subscription ID:", $subscriptionId)
    Write-Host("Tenant ID:", $tenant.tenantId)
    Write-Host("Client ID:", $App.appId)
    Write-Host("Secret ID:", $secret)
}
catch
{
    Write-Host("Unable to provide all required values for provisioning.  Contact DisruptOps for further assistance.")
}

  `;

  return str;
}

function getAzureRoleFromPolicyCode(policyCode: string): string | null {
  switch (policyCode) {
    case 'ro':
      return 'Reader';
    case 'rw':
      return 'Owner';
    case 'rw_noiam':
      return 'Contributor';

    default:
      return null;
  }
}

import React, { useState, useMemo } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { Select } from 'antd';
import { FilterTypeProps } from '../FilterListing/FilterListing';
import { useQuery } from 'react-apollo';
import { CLOUD_ACCOUNT_LIST_QUERY } from 'queries';
import { CloudAccount } from 'typings';

function AccountLabelEditor(props: FilterTypeProps) {
  const { values, filterType } = props,
    [softEdit, setSoftEdit] = useState<SoftEdit | null>({
      key: filterType.valueKey,
      value: values[filterType.valueKey]
    }),
    { data, loading, error } = useQuery(CLOUD_ACCOUNT_LIST_QUERY, {
      fetchPolicy: 'cache-and-network'
    });

  const allCloudAccounts: CloudAccount[] = data?.accounts?.items || [];

  const allCloudAccountLabels = useMemo(() => {
    const allCloudAccountLabelsSet = allCloudAccounts.reduce((set, cloudAccount) => {
      const cloudAccountLabels = cloudAccount.labels || [];
      cloudAccountLabels.forEach((label) => set.add(label));

      return set;
    }, new Set<string>());

    return Array.from(allCloudAccountLabelsSet).sort();
  }, [allCloudAccounts]);

  function onChange(update) {
    setSoftEdit({
      key: filterType.valueKey,
      value: update
    });
  }

  const display = (
    <>
      {data && (
        <ul data-testid="account-list">
          {values[filterType.valueKey]?.map((label) => (
            <li data-testid="value-item" key={label}>
              {label}
            </li>
          ))}
        </ul>
      )}
    </>
  );

  const editor = (
    <Select
      onChange={onChange}
      mode="multiple"
      value={softEdit?.value}
      filterOption={(inputValue, option) => {
        if (!option || !option.props || !option.props.value) {
          return true;
        }
        return new RegExp(inputValue, 'i').test(option.props.value.toString());
      }}
    >
      {data &&
        allCloudAccountLabels.map((label, i) => (
          <Select.Option key={`${i}_${label}`} value={label}>
            {label}
          </Select.Option>
        ))}
    </Select>
  );

  return <FilterRow {...props} display={display} editor={editor} softEdit={softEdit} loading={loading} error={error} />;
}

export default AccountLabelEditor;

import React from 'react';
import { NavLink } from 'react-router-dom';

import styled from 'styled-components';

const Root = styled.div`
  &.inpage {
    margin-bottom: 16px;
    border-bottom: 1px solid ${p => p.theme.grey300};
  }

  &.tabs {
    margin-bottom: -1px;
    position: relative;
    z-index: 1;
  }
`;

const NavList = styled.div`
  display: flex;
`;

const NavItem = styled.div`
  pointer-events: ${(p: { disabled: boolean }) => (p.disabled ? 'none' : 'auto')};

  .nav-link {
    display: block;
    padding: 10px 0px;
    margin: 0px 16px;
    color: ${p => (p.disabled ? p.theme.grey500 : p.theme.grey100)};
    opacity: 0.8;
    position: relative;
    letter-spacing: 0.1em;
    font-weight: 200;
    &.active,
    &:hover {
      opacity: 1;

      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: ${p => p.theme.primaryLight};
        background-color: ${p => p.theme.grey100};
        background-color: #fff;
        height: 4px;
        bottom: 0;
        left: 0;
        width: 100%;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .inpage & .nav-link {
    color: ${p => (p.disabled ? p.theme.grey500 : p.theme.grey800)};
    font-weight: 400;
    margin: 0 24px;

    &:first-child {
      margin-left: 0;
    }

    &.active,
    &:hover {
      color: ${p => p.theme.primary600};
    }

    &:hover:after {
      background-color: transparent;
    }

    &.active:after,
    &.active:hover:after {
      background-color: ${p => p.theme.primary600};
    }
  }

  .tabs & .nav-link {
    opacity: 1;
    color: ${p => (p.disabled ? p.theme.grey500 : p.theme.grey800)};
    font-weight: 400;
    margin: 0 2px 0 0;
    padding: 0 24px;
    border-radius: 4px 4px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 34px;
    background-color: ${p => p.theme.grey300};
    border-style: solid;
    border-width: 1px 1px 0 1px;
    border-color: #e8e8e8;

    &.active,
    &.active:hover {
      overflow: hidden;
      background-color: #fff;
      color: ${p => p.theme.grey900};

      &:after {
        z-index: 1;
        background-color: ${p => p.theme.primary300};
        top: 0;
        bottom: initial;
        display: block;
      }
    }

    &:hover {
      background-color: ${p => p.theme.grey200};
      color: ${p => p.theme.primary500};
      &:after {
        display: none;
      }
    }
  }
`;

export interface NavItemType {
  label: string;
  to: string;
  exact?: boolean;
  disabled?: boolean;
  hidden?: boolean;
  isActive?: (match?: any, location?: any) => boolean;
}

type NavbarType = 'default' | 'inpage' | 'tabs';

interface Props {
  navConfig: NavItemType[];
  type?: NavbarType;
  classes?: {
    root?: string;
    navList?: string;
    navItem?: string;
    navLink?: string;
  };
}

function HorizontalNavbar(props: Props) {
  const { navConfig, type = 'default', classes = {} } = props;

  const navItems = navConfig.filter(navItem => navItem.hidden !== true);

  return (
    <Root className={`horizontal-navbar ${type} ${classes.root || ''}`}>
      <NavList className={`nav-list ${classes.navList || ''}`}>
        {navItems.map((item, idx) => {
          return (
            <NavItem
              className={`nav-item ${classes.navItem || ''}`}
              key={`hnav-${idx}`}
              disabled={item.disabled || false}
            >
              <NavLink
                className={`nav-link ${classes.navLink || ''}`}
                to={item.to}
                exact={item.exact}
                isActive={item.isActive || null}
              >
                {item.label}
              </NavLink>
            </NavItem>
          );
        })}
      </NavList>
    </Root>
  );
}

export default HorizontalNavbar;

import React from 'react';
import IconButton from 'components/ui/IconButton';
import DopeIcon from 'components/ui/DopeIcon';
import { Route } from 'react-router';
import { ConfiguredAssessment } from 'typings';
import { Tooltip } from 'antd';

interface Props {
  linkPrefix?: string;
  assessment: ConfiguredAssessment;
}

function AssessmentFiltersEditButton(props: Props) {
  const { assessment, linkPrefix = '' } = props;
  const { id, isReadOnly } = assessment;

  const editButton = (
    <span>
      <Route
        children={({ history }) => (
          <IconButton
            type="ghost"
            disabled={isReadOnly}
            onClick={() => history.push(`${linkPrefix}/detectors/${id}/settings/edit-filters`)}
            size={'small'}
          >
            {isReadOnly ? <DopeIcon name="LOCK" /> : <DopeIcon name="EDIT" />}
          </IconButton>
        )}
      />
    </span>
  );

  if (isReadOnly) {
    return (
      <Tooltip title="System detector is read only." placement="top">
        {editButton}
      </Tooltip>
    );
  }

  return editButton;
}

export default AssessmentFiltersEditButton;

import { useState, useEffect } from 'react';
import { Op } from 'typings';
import { ContextArgs } from './EditorUtils';
import { buildValidationSchema } from '../OpDetail/OpEditor';

export function useValidateCompleteOp(args: {
  op?: Op;
  contextArgs: ContextArgs;
  onComplete?: (isValid: boolean, op?: Op) => void;
}): {
  validating: boolean;
  isValid: boolean;
  errors: any;
} {
  const {
    op,
    contextArgs,
    contextArgs: { automationFunction, eventDefinition },
    onComplete
  } = args;
  const [validating, setValidating] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();

  useEffect(() => {
    if (!op) return; // guardrail must be saved before enabled?

    const asyncValidateFn = async () => {
      setValidating(true);

      const validationSchema = buildValidationSchema({
        contextArgs,
        strict: true
      });

      let isValid = false;

      try {
        await validationSchema.validate(op, { abortEarly: false });

        setErrors(null);
        setIsValid(true); // assuming if the above passes, op is valid...
        isValid = true;
      } catch (e) {
        setErrors(e);
        setIsValid(false);
      } finally {
        setValidating(false);

        if (onComplete) onComplete(isValid, op);
      }
    };

    asyncValidateFn();
  }, [
    op ? JSON.stringify(op) : '',
    automationFunction ? JSON.stringify(automationFunction) : '',
    eventDefinition ? JSON.stringify(eventDefinition) : ''
  ]);

  return {
    validating,
    isValid,
    errors
  };
}

import React, { ReactNode } from 'react';
import { Input } from 'antd';

import { size } from 'typings';
import { cidrRegex } from 'constants/regularExpressions';
import validator from 'services/validator';

const schema = validator.string().matches(cidrRegex, { message: 'CIDR is invalid' });

const overview = `
# CIDR Input

This OpInput allows a user to input a CIDR block. It validates that it is a valid CIDR (IP address + subnet mask in bits). This OpInput is commonly combined with the 'many' property to allow the user to specify multiple possible values.
`;

export const inputDefinition = {
  schema,
  defaultValue: '',
  overview
};

interface SimpleOpInputProps {
  placeholder?: string;

  name: string;
  value: string;
  size?: size;

  disabled?: boolean;
  addonAfter?: ReactNode;
  inputRef: (input: Input) => any | void;

  setFieldValue: (name: string, value: any) => any | void;
  setFieldTouched: (name: string, touched: boolean) => any;
}

function CIDRInput(props: SimpleOpInputProps) {
  const {
    name,
    value,
    placeholder,
    setFieldValue,
    setFieldTouched,
    disabled,
    addonAfter,
    inputRef,
    size = 'large'
  } = props;

  const handleChange = e => setFieldValue(name, e.target.value);
  const handleBlur = () => setFieldTouched(name, true);

  return (
    <Input
      ref={inputRef}
      placeholder={placeholder || '10.0.3.5/32'}
      name={name}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled === true}
      addonAfter={addonAfter ? addonAfter : null}
      size={size}
    />
  );
}

export default CIDRInput;

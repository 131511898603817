import React from 'react';
import { ConfigSettingGroup, Config } from 'typings';
import Paginator from 'components/app/Paginator';

import Table, { TableWrap } from 'components/ui/Table';
import IconButton from 'components/ui/IconButton';

import { DetailsColumn } from './components';
import { FunctionReadView } from 'components/function';
import DopeIcon from 'components/ui/DopeIcon';

import { DynamicConfigForm } from './components';
import { DataProxy } from 'apollo-cache';
import { FetchResult } from 'apollo-link';

interface Props {
  configSettingGroups: ConfigSettingGroup[];
  savedConfigs: Config[];
  entityId?: string;
  entityType: 'PROJECT' | 'ORGANIZATION';
  mutationUpdateFn?: (cache: DataProxy, context: FetchResult) => void;
  updateCacheOnDeleteFn: (cache: DataProxy, context: FetchResult) => void;
}

function DynamicConfigSettings(props: Props) {
  const { configSettingGroups, savedConfigs, entityId, entityType, mutationUpdateFn, updateCacheOnDeleteFn } = props;

  return (
    <Paginator
      filters={[
        {
          qsKey: 'editing',
          name: 'editing',
          many: false
        }
      ]}
    >
      {pageProps => {
        const editing = pageProps.filterValues.editing || null;

        return (
          <>
            {configSettingGroups.map((settingGroup, idx) => {
              const { name, key } = settingGroup;
              const settings = settingGroup.configSettings;

              return (
                <TableWrap key={`${key}_${idx}`} panelTitle={name}>
                  <Table
                    antTableProps={{
                      dataSource: settings,
                      pagination: false,
                      className: 'overflow-visible',
                      rowKey: 'key',
                      columns: [
                        {
                          className: 'vertical-align-top',
                          width: '50%',
                          key: 'setting-desc',
                          title: 'Setting',
                          dataIndex: 'key',
                          render: (key, setting) =>
                            key === editing ? (
                              {
                                children: (
                                  <DynamicConfigForm
                                    configSetting={setting}
                                    config={savedConfigs.find(config => config.key === key) || null}
                                    onCancel={() => pageProps.replaceFilters('editing')}
                                    onComplete={() => pageProps.replaceFilters('editing')}
                                    entityId={entityId || null}
                                    entityType={entityType}
                                    mutationUpdateFn={mutationUpdateFn}
                                    updateCacheOnDeleteFn={updateCacheOnDeleteFn}
                                  />
                                ),
                                props: { colSpan: 3 }
                              }
                            ) : (
                              <DetailsColumn title={setting.name} description={setting.description || ''} />
                            )
                        },
                        {
                          className: 'vertical-align-top',
                          width: '50%',
                          key: 'display',
                          title: 'Value',
                          dataIndex: 'key',
                          render: (key: string, setting) => {
                            if (key === editing)
                              return {
                                props: { colSpan: 0 }
                              };

                            const matchingConfig = savedConfigs.find(config => config.key === key) || null;

                            return matchingConfig ? (
                              <FunctionReadView functionParameter={setting} value={matchingConfig.value} />
                            ) : (
                              <div>{'--'}</div>
                            );
                          }
                        },
                        {
                          className: 'vertical-align-top',
                          width: '50px',
                          key: 'edit',
                          dataIndex: 'key',
                          render: key =>
                            key === editing ? (
                              {
                                props: { colSpan: 0 }
                              }
                            ) : (
                              <IconButton
                                size="small"
                                type="ghost"
                                classes={{ root: !editing ? 'reveal-on-row-hover' : 'hidden' }}
                                onClick={() => {
                                  pageProps.replaceFilters('editing', key);
                                }}
                              >
                                <DopeIcon name="EDIT" />
                              </IconButton>
                            )
                        }
                      ]
                    }}
                  />
                </TableWrap>
              );
            })}
          </>
        );
      }}
    </Paginator>
  );
}

export default DynamicConfigSettings;

import gql from 'graphql-tag';

const ISSUE_FRAGMENT = gql`
  fragment issueFields on Issue {
    issue_id
    scheduledActions @client {
      id #id of scheduled action
      actionMethod
      inputValuesJSON
    }

    exemptExpiration @client {
      id
      expirationDt
      issueId
    }

    issue_code
    title
    severity
    summary
    dry_run
    isExempt # boolean
    exemptedReason # string
    exemptedAt # datetime when ignored
    resolvedAt
    detail
    region
    op_code
    project_id
    run_id
    display_info {
      summary_explainer_text
      summary_header
    }
    action_results {
      method
      resolved
      result
      result_detail
      schema_version
      timestamp
      user
    }
    noncompliant_item_parent_type
    noncompliant_item_parent_id
    noncompliant_item_type
    noncompliant_item_id
    extra_detail_1_type
    extra_detail_1_list
    extra_detail_2_type
    extra_detail_2_list
    created
    updated
    resolved_date
    account_id
    aws_account_id
    configured_op_id @export(as: "configured_op_id")
  }
`;

export default ISSUE_FRAGMENT;

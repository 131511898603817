import axios, { Method } from 'axios';

export interface RequestArgs {
  accessToken?: string;
  url: string;
  method: string;
  payload?: any;
}

export async function restRequest(args: RequestArgs): Promise<any> {
  const { accessToken, method, url, payload } = args;
  const request = {
    url: url,
    method: method as Method,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: accessToken ? `Bearer ${accessToken}` : undefined
    },
    data: payload || {}
  };

  const response = await axios.request(request);
  if (response.status > 400)
    throw new Error(`Request failed to ${url}. Response => ${response.statusText}`);

  return response.data;
}

export default restRequest;
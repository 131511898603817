import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import React from 'react';
import { JiraConfig } from 'typings';

interface ComponentProps {
  jiraConfig: JiraConfig | null;
}

interface OutputIteration {
  label: string;
  property: string;
}

function JiraActionDetail(props: ComponentProps) {
  const { jiraConfig } = props,
    outputIterator: OutputIteration[] = [
      {
        label: 'Project',
        property: 'projectKey'
      },
      {
        label: 'Priority',
        property: 'priority'
      },
      {
        label: 'URL',
        property: 'url'
      }
    ];

  return (
    <div>
      Jira Integration
      {outputIterator.map((row) => (
        <HorizontalLabel label={row.label} key={row.property}>
          {jiraConfig ? jiraConfig[row.property] : ''}
        </HorizontalLabel>
      ))}
    </div>
  );
}

export default JiraActionDetail;

import React from 'react';
import styled from 'styled-components';
import { Maybe } from 'typings';
import { Typography } from 'antd';
import { CollapsePanel } from 'components/ui/Collapse/Collapse';

// THIS SHOULD BE MOVED TO ./components as it will be shared across sections.
// COMPONENT: AUTOMATION LIST ITEM
const OpSectionListItemRoot = styled.div`
  padding: 0;
  margin-bottom: 0.5rem;
  display: flex;

  &.clickable {
    cursor: pointer;
  }

  &:hover,
  &.selected {
    background-color: #f9f9f9;
  }

  .automation-list-item-icon {
    margin-right: 0.5rem;
    transform: translateY(6px);
  }

  .automation-list-item-main {
    flex: 1;
    margin-bottom: 0.5rem;
  }

  .automation-list-item-title {
    margin-bottom: 0;
  }

  .automation-list-item-meta-items {
    display: flex;
    flex-wrap: wrap;
  }

  .automation-list-item-meta-bottom {
    display: flex;
    flex-wrap: wrap;
    min-height: 20px;
  }

  .automation-list-item-desc {
    color: ${(p) => p.theme.grey600};
    display: block;
  }

  .collapse-panel-content {
    margin-top: 12px;
  }

  .cls-1 {
    fill: white;
  }
`;

interface AutomationListItemProps {
  title: string;
  description?: Maybe<string>;
  icon?: React.ReactNode;
  onClick?: () => void;
  meta?: React.ReactNode | React.ReactNodeArray;
  metaBottom?: React.ReactNode;
  selected?: boolean;
  extraContent?: React.ReactNode;
}

function OpSectionlistItem(props: AutomationListItemProps) {
  const { title, description, icon, onClick, meta, metaBottom, selected = false, extraContent } = props;

  return (
    <OpSectionListItemRoot
      className={`automation-list-item ${selected ? 'selected' : ''} ${onClick ? 'clickable' : ''}`}
      onClick={onClick}
    >
      {icon && <div className="automation-list-item-icon">{icon}</div>}
      <div className="automation-list-item-main">
        <h3 className="automation-list-item-title">{title}</h3>
        {description && <Typography.Text className="automation-list-item-desc">{description}</Typography.Text>}
        {metaBottom && <div className="automation-list-item-meta-bottom">{metaBottom}</div>}
        {meta && <div className="automation-list-item-meta-items">{meta}</div>}

        {extraContent && (
          <CollapsePanel defaultCollapsed collapsedTriggerContent="More" openTriggerContent="Hide" maxHeight={400}>
            {extraContent}
          </CollapsePanel>
        )}
      </div>
    </OpSectionListItemRoot>
  );
}

export default OpSectionlistItem;

import React from 'react';
import SectionHeading from 'designSystem/SectionHeading/SectionHeading';
import { ExclamationCircleFilled } from '@ant-design/icons';
import IssueList from 'components/issue/IssueList';
import { Route } from 'react-router-dom';

function Issues() {
  return (
    <div>
      <SectionHeading title="Issues" icon={<ExclamationCircleFilled />} />
      <div style={{ padding: '1rem' }}>
        <Route children={(routeProps) => <IssueList {...routeProps} />} />
      </div>
    </div>
  );
}

export default Issues;

import React from 'react';
import { ResourceTypeConfigItem } from 'components/cloudResources/cloudResourceTypesConfig';
import S3_BUCKETS_QUERY from './query';
import { TagList } from 'components/cloudResources';
import IssueCountBadge from 'components/issue/IssueCountBadge';
import CloudAccountLink from '../../components/CloudAccountLink';
import DateTime from 'components/ui/DateTime';

const s3Buckets: ResourceTypeConfigItem = {
  key: 'AWS::S3::Bucket',
  label: 'S3 Buckets',
  labelPlural: 'S3 Buckets',
  labelSingular: 'S3 Bucket',
  tableQuery: (qs: any) => S3_BUCKETS_QUERY,
  tableQueryVariables: (resourceType, qs) => {
    const isPublic = Array.isArray(qs.is_public)
      ? qs.is_public.find(isPublic => Boolean(isPublic))
      : Boolean(qs.is_public) || false;

    return {
      dops_is_public: isPublic
    };
  },
  filters: [
    {
      name: 'is_public',
      label: 'Public',
      qsKey: 'is_public',
      variableName: () => 'dops_is_public',
      many: false,
      options: [{ key: 'true', label: 'true' }, { key: 'false', label: 'false' }]
    }
  ],
  tableColumnsConfig: [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      className: 'no-wrap'
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      render: account => <CloudAccountLink account={account} />
    },
    {
      title: 'Issues',
      dataIndex: 'dops_issue_summary.open_count',
      key: 'issue_summary_open',
      align: 'center' as 'center',
      className: 'no-wrap text-center',
      render: issueCount => <IssueCountBadge issueCount={issueCount} />
    },
    {
      title: 'Creation date',
      dataIndex: 'creation_date',
      key: 'creation_date',
      className: 'no-wrap',
      render: creationDate => <DateTime format="local" dateTime={new Date(creationDate)} />
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      className: 'tags-col',
      width: '33%',
      render: tags => <TagList tags={tags} />
    }
  ]
};

export default s3Buckets;

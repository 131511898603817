import React, { useState } from 'react';
import { List } from 'antd';
import styled from 'styled-components';

import { UserGroup, UserGroupMember } from 'typings';

import RemoveMemberButton from './RemoveMemberButton';
import USER_GROUP_MEMBER_LIST_QUERY from '../gql/user-group-member-list-query';
import { useQuery } from 'react-apollo';
import ErrorAlert from 'components/ui/ErrorAlert';
import { parsePageData } from 'components/app/Paginator/Paginator';
import AddUsersModal from './AddUserModal';
import ButtonWithDisabledTooltip from 'components/buttons/ButtonWithDisabledTooltip';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  userGroup: UserGroup;
  onChange?: (userIds: string[]) => any | void;
}

function UserGroupUserList(props: Props) {
  const { userGroup } = props;

  const userGroupId = userGroup ? userGroup.id : '';

  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_USER_GROUPS);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const { loading, error, data, refetch } = useQuery(USER_GROUP_MEMBER_LIST_QUERY, { variables: { userGroupId } });

  if (error) {
    return <ErrorAlert message="Error loading member list" error={error} />;
  }

  const { items, pageSize, total, pageNumber } = parsePageData(data);

  const users = items.sort((a, b) => {
    const aName = `${a.first_name} ${a.last_name}` || a.username;
    const bName = `${b.first_name} ${b.last_name}` || b.username;
    return aName.toLowerCase().localeCompare(bName.toLowerCase(), undefined, { sensitivity: 'base' });
  });

  return (
    <>
      <List
        loading={loading}
        header={
          <ListHeader>
            <h3>Users</h3>

            <ButtonWithDisabledTooltip
              disabled={!isAuthorized}
              disabledMessage={authErrorMessage || ''}
              onClick={() => setIsAddingUser(!isAddingUser)}
              size="small"
            >
              Add user
            </ButtonWithDisabledTooltip>
          </ListHeader>
        }
        dataSource={users}
        renderItem={(userGroupMember: UserGroupMember) => {
          const fullname = `${userGroupMember.first_name} ${userGroupMember.last_name}` || userGroupMember.username;
          const label = `${fullname} | User: ${userGroupMember.username} | Email: ${userGroupMember.email}`;

          return (
            <List.Item
              key={userGroupMember.user_id}
              actions={[
                <RemoveMemberButton
                  key="remove"
                  member={userGroupMember}
                  userGroup={userGroup}
                  onRemove={() => refetch()}
                />
              ]}
            >
              <List.Item.Meta title={label} />
            </List.Item>
          );
        }}
        pagination={{
          pageSize: pageSize,
          total: total,
          current: pageNumber,
          hideOnSinglePage: true
        }}
      />

      <AddUsersModal
        userGroup={userGroup}
        members={users}
        visible={isAddingUser}
        onSuccess={() => {
          setIsAddingUser(false);
          refetch();
        }}
        onCancel={() => {
          setIsAddingUser(false);
        }}
      />
    </>
  );
}

export default UserGroupUserList;

import React from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { CenteredContainer } from 'components/ui/NeoPage';
import { PageInfo, ActionResult, FilterDefinition, Op, AutomationActionResult, FilterValues } from 'typings';
import { Query, useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import { ColumnProps } from 'antd/lib/table';
import Table from 'components/ui/Table';
import DateTime from 'components/ui/DateTime';
import FilterBar from 'components/app/FilterBar';
import { SearchFilter } from 'components/app/FilterBar/filters';
import DopeIcon from 'components/ui/DopeIcon';
import { Checkbox, Popover, Button, Icon } from 'antd';
import { PopoverMenuItem } from 'components/ui/Popover';
import { Props as PopoverMenuItemProps } from 'components/ui/Popover/PopoverMenuItem';
import AutomationActivityDetail from 'app/sections/Authenticated/pages/AutomationActivity/AutomationActivityDetail';
import usePaginator from 'components/app/Paginator/usePaginator';

const Root = styled.div`
  .action-result-status {
    &.success {
      color: ${(p) => p.theme.success};
    }
    &.noop {
      color: ${(p) => p.theme.success};
    }
    &.timeout {
      color: ${(p) => p.theme.warning};
    }
    &.error {
      color: ${(p) => p.theme.error};
    }
  }

  .filter-options {
    padding-bottom: 8px;
    display: flex;
    justify-content: flex-end;d
  }

  .op-name {
    &.archived {
      span {
        color: ${(p) => p.theme.grey300};
      }
    }
  }

`;

const SORT_BY_MAP = {
  name: 'name',
  isEnabled: 'is_enabled',
  createdAt: 'created_at'
};

const SORT_ORDER_MAP: {
  [key: string]: 'ASC' | 'DESC';
} = {
  ascend: 'ASC',
  descend: 'DESC'
};

const STATUS_OPTIONS = [
  {
    key: 'SUCCESS',
    label: 'Success'
  },
  {
    key: 'ERROR',
    label: 'Error'
  }
];

const SHOW_ARCHIVED_GUARDRAIL_ACTIVITY = 'showArchivedGuardrailActivity';
const FILTER_DEFINITIONS: FilterDefinition[] = [
  {
    name: 'status',
    label: 'Status',
    qsKey: 'status',
    many: false,
    variableName: () => 'status', //??
    options: STATUS_OPTIONS
  },
  {
    name: 'opId',
    label: 'Op',
    qsKey: 'opId',
    many: false,
    filterBrickReadView: (props) => {
      const { value } = props;

      return (
        <Query
          query={gql`
            query OpNames($id: [String]) {
              ops(id: $id) {
                nodes {
                  id
                  name
                }
              }
            }
          `}
          variables={{ id: value }}
          fetchPolicy={'cache-first'}
        >
          {({ loading, data, error }) => {
            if (!data || error) return value;
            const op: {
              id: string;
              name?: string | null;
            } = data.ops.nodes[0];

            if (!op) return value; //?

            return op.name || 'Untitled Op';
          }}
        </Query>
      );
    }
  },
  {
    name: 'functionId',
    label: 'Action', //??
    qsKey: 'functionId',
    many: false,
    filterBrickReadView: (props) => {
      const { value } = props;

      return (
        <Query
          query={gql`
            query AutomationFunctionForActionResults($id: [String]) {
              automationFunctions(id: $id) {
                nodes {
                  id
                  name
                }
              }
            }
          `}
          variables={{ id: [value] }}
          fetchPolicy={'cache-first'}
        >
          {({ loading, data, error }) => {
            if (!data || error) return value;
            const aFunc: {
              id: string;
              name?: string | null;
            } = data.automationFunctions.nodes[0];

            if (!aFunc) return value; //?

            return aFunc.name || '';
          }}
        </Query>
      );
    }
  },
  {
    label: 'Issue',
    name: 'issueId',
    qsKey: 'issueId',
    many: false
  }
];

export const ACTION_RESULT_FIELDS = gql`
  fragment AutomationActionResultFields on AutomationActionResult {
    id
    status
    issueId
    status
    detail # this contains taskName
    createdAt
    opId
    op {
      id
      name
      archivedAt
    }
    functionId
    function {
      id
      name
    }
    event {
      id
      eventData
    }
    triggerType
    invokedById
    invokedByUsername
    taskId
    functionInput
  }
`;

// THIS SHOULD FILTER GUARDDRAIL TRIGGERED ACTION RESULTS ONLY.
export const AUTOMATION_ACTION_RESULTS = gql`
  query AutomationActionResultsList(
    $id: [String]
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $status: AutomationActionResultStatus
    $opId: [String]
    $issueId: [String]
    $functionId: [String]
    $isOpArchived: Boolean
    $triggerType: AutomationActionResultTriggerType
  ) {
    automationActionResults(
      id: $id
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      status: $status
      opId: $opId
      issueId: $issueId
      functionId: $functionId
      triggerType: $triggerType
      isOpArchived: $isOpArchived
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        ...AutomationActionResultFields
      }
    }
  }
  ${ACTION_RESULT_FIELDS}
`;

interface Props extends RouteComponentProps {
  actionResultDetailRoutePrefix?: string; // defaults to '/activity/'
  defaultTriggerType?: 'OP' | 'USER';
}

function AutomationActivityList(props: Props) {
  const { history, location, defaultTriggerType, actionResultDetailRoutePrefix = '/activity/' } = props;

  const {
    search,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    updatePagination,
    replaceFilter,
    filters
  } = usePaginator({ defaultPageSize: 100, defaultSortDirection: 'DESC' });

  const showArchivedGuardrailActivity = filters[SHOW_ARCHIVED_GUARDRAIL_ACTIVITY]?.[0] === 'true';
  const variables = {
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    status: filters.status || null,
    opId: filters.opId ? filters.opId : null,
    issueId: filters.issueId ? filters.issueId : null,
    functionId: filters.functionId ? filters.functionId : null,
    isOpArchived: showArchivedGuardrailActivity ? undefined : false,
    triggerType: defaultTriggerType || undefined
  };

  const { loading, error, data, refetch } = useQuery(AUTOMATION_ACTION_RESULTS, {
    variables
  });

  return (
    <Root>
      <CenteredContainer>
        <QueryResult loading={loading} data={data} error={error}>
          {() => {
            const actionResults: ActionResult[] = data.automationActionResults.nodes;
            const pageInfo: PageInfo = data.automationActionResults.pageInfo;

            const columns: ColumnProps<ActionResult>[] = [
              {
                title: 'Date/Time',
                key: 'createdAt',
                dataIndex: 'createdAt',
                sorter: true,
                render: (createdAt, actionResult) => {
                  return createdAt ? (
                    <Link to={`${actionResultDetailRoutePrefix}${actionResult.id}`}>
                      <DateTime dateTime={createdAt} />
                    </Link>
                  ) : (
                    '--'
                  );
                }
              },
              {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                render: (status) => (
                  <div className={`action-result-status ${status.toLowerCase()}`}>
                    {status === 'NOOP' ? 'SUCCESS' : status}
                  </div>
                )
              },
              {
                title: 'Op',
                key: 'op',
                dataIndex: 'op',
                render: (op: Op, actionResult: AutomationActionResult) => {
                  if (op) {
                    return (
                      <>
                        <ColumnMoreVertOptions
                          content={
                            <div className={`op-name ${op.archivedAt ? 'archived' : ''}`}>
                              {op.archivedAt && '(ARCHIVED) - '}
                              <span>{op.name || 'Untitled'}</span>
                            </div>
                          }
                          menuItems={[
                            {
                              label: 'View Op',
                              link: `/ops/details/${op.id}`
                            },
                            {
                              label: 'Filter Activity by this Op',
                              onClick: () => {
                                if (op.id) replaceFilter('opId', op.id);
                              }
                            }
                          ]}
                        />
                      </>
                    );
                  }

                  const { triggerType, invokedByUsername } = actionResult;
                  if (triggerType === 'USER' && invokedByUsername) {
                    return `User: ${invokedByUsername}`;
                  }

                  return '--';
                }
              },
              {
                title: 'Source',
                key: 'event',
                dataIndex: 'event',
                render: (event, actionResult) => (
                  <>
                    {event?.id && event?.eventData?.context?.eventDefinitionName ? (
                      <Link
                        to={{
                          pathname: `/event/${event.id}`,
                          state: { event: event }
                        }}
                      >
                        {event.eventData.context.eventDefinitionName}
                      </Link>
                    ) : actionResult.issueId ? (
                      <Link to={`/issue/${actionResult.issueId}`}>Source Issue</Link>
                    ) : event?.id ? (
                      <Link to={`/event/${event.id}`}>Unrecognized Event</Link>
                    ) : (
                      '--'
                    )}
                  </>
                )
              },
              {
                title: 'Action',
                key: 'action',
                dataIndex: 'function',
                render: (func) => {
                  if (!func || !func.name) return '--';

                  return (
                    <ColumnMoreVertOptions
                      content={func.name}
                      menuItems={[
                        {
                          label: 'Filter Activity by this Action',
                          // does this work?
                          onClick: () => replaceFilter('functionId', func.id)
                        }
                      ]}
                    />
                  );
                }
              }
            ];

            return (
              <>
                <div className="filter-options">
                  <Button size="small" style={{ marginRight: '8px' }} onClick={() => refetch()}>
                    <Icon type="sync" />
                  </Button>
                  <Checkbox
                    checked={filters[SHOW_ARCHIVED_GUARDRAIL_ACTIVITY]?.[0] !== 'true'}
                    children={'Hide archived Op activity'}
                    onChange={(e) => {
                      const checked = e.target.checked;

                      replaceFilter(SHOW_ARCHIVED_GUARDRAIL_ACTIVITY, checked ? 'false' : 'true');
                    }}
                  />
                </div>

                <FilterBar
                  history={history}
                  location={location}
                  search={search || ''}
                  filters={FILTER_DEFINITIONS}
                  filterValues={filters as FilterValues}
                  onFilterChange={(name, value) => {
                    console.log('ON FILTER CHANGE', name, value);
                    replaceFilter(name.toString(), value ? (Array.isArray(value) ? value : [value]) : []);
                  }}
                >
                  {(filterBarRenderProps) => {
                    const { search, handleSearchChange } = filterBarRenderProps;

                    return (
                      <SearchFilter
                        placeholder="Filter Op Activity"
                        size="large"
                        filters={FILTER_DEFINITIONS}
                        search={search}
                        onSearchChange={handleSearchChange}
                      />
                    );
                  }}
                </FilterBar>

                <Table
                  antTableProps={{
                    rowKey: 'id',
                    size: 'small',
                    columns,
                    loading: loading,
                    dataSource: actionResults,
                    locale: {
                      emptyText: 'No action results found'
                    },
                    onChange: (pagination, filters, sorter, a) => {
                      updatePagination({
                        sortBy: SORT_BY_MAP[sorter.columnKey] || 'updated_at',
                        sortDirection: SORT_ORDER_MAP[sorter.order] || SORT_ORDER_MAP.descend,
                        pageNumber: pagination.current || 1
                      });
                    },
                    pagination: {
                      pageSize,
                      current: pageInfo && pageInfo.current,
                      total: pageInfo && pageInfo.total,
                      onChange: (pageNumber) => {
                        updatePagination({
                          pageNumber
                        });
                      }
                    },
                    expandedRowRender: (actionResult) => {
                      return <AutomationActivityDetail actionResult={actionResult} />;
                    }
                  }}
                />
              </>
            );
          }}
        </QueryResult>
      </CenteredContainer>
    </Root>
  );
}

const ColumnMoreVertOptionsRoot = styled.div`
  display: flex;
  padding-right: 20px;

  .column-with-menu-content {
    margin-right: 16px;
  }
  .column-with-menu-icon {
    display: block;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover .column-with-menu-icon {
    opacity: 1;
  }
`;

interface ColumnMoreVertOptionsProps {
  content: string | React.ReactNode;
  menuItems: PopoverMenuItemProps[];
}

function ColumnMoreVertOptions(props: ColumnMoreVertOptionsProps) {
  const { content, menuItems } = props;

  return (
    <ColumnMoreVertOptionsRoot className="column-with-menu">
      <div className="column-with-menu-content">{content}</div>
      <Popover
        content={
          <div>
            {menuItems.map((item, idx) => {
              return <PopoverMenuItem key={idx} {...item} />;
            })}
          </div>
        }
        placement="left"
        trigger="click"
      >
        <div className="column-with-menu-icon">
          <DopeIcon name="MORE_VERTICAL" size={14} />
        </div>
      </Popover>
    </ColumnMoreVertOptionsRoot>
  );
}

export default AutomationActivityList;

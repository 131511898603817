import React from 'react';
import { RouteComponentProps } from 'react-router';

import { ConfiguredAssessment } from 'typings';

import { GridCard } from 'components/ui/Card';
import DopeIcon from 'components/ui/DopeIcon';
import Table from 'components/ui/Table';

interface Props extends RouteComponentProps {
  assessment: ConfiguredAssessment;
}

function AssessmentIssueDefinitionsCard(props: Props) {
  const { assessment } = props;
  const issueDefinitions =
    assessment.assessor && assessment.assessor.issueDefinitions ? assessment.assessor.issueDefinitions : [];

  return (
    <GridCard title="Discoverable Issues" icon={<DopeIcon color="rgb(255, 152, 0)" name="ISSUE_OPEN" size={22} />}>
      <Table
        antTableProps={{
          dataSource: issueDefinitions,
          rowKey: 'id',
          columns: [
            {
              key: 'name',
              title: 'Name',
              dataIndex: 'name',
              render: (name) => <strong>{name}</strong>
            },
            {
              key: 'description',
              title: 'Description',
              dataIndex: 'description'
            },
            {
              key: 'severity',
              title: 'Severity',
              dataIndex: 'severity',
              align: 'center'
            }
          ]
        }}
      />
    </GridCard>
  );
}

export default AssessmentIssueDefinitionsCard;

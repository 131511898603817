import { Skeleton } from 'antd';
import QueryResult from 'components/util/QueryResult';
import * as d3 from 'd3';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const QUERY = gql`
  query issueAndEventCountByDate($days: Int, $start: DateTime) {
    eventCountByDate(days: $days, start: $start) {
      days
      start
      results {
        date
        count
      }
    }

    governanceStats {
      issueCountByDate(days: $days, start: $start) {
        days
        start
        results {
          date
          count
        }
      }
    }
  }
`;

function FindingsLine() {
  const { loading, error, data } = useQuery(QUERY, { variables: { days: 7 } });

  const history = useHistory();

  return (
    <QueryResult loading={loading} data={data} error={error} loadingCenterVertically={false}>
      {() => {
        const {
          governanceStats: {
            issueCountByDate: { results: issueCountByDateResults }
          },
          eventCountByDate: { results: eventCountByDateResults }
        } = data || {};

        // combine issue and event finding counts

        // console.log({ eventCountByDate });

        const chartData = issueCountByDateResults?.map((issue) => {
          const date = new Date(issue.date);

          // console.log({ date });

          let eventCount = 0;
          const eventFindings = eventCountByDateResults.find((e) => new Date(e.date).getTime() === date.getTime());
          if (eventFindings) eventCount = eventFindings.count;

          // console.log({ eventFindings, eventCount });

          return {
            x: date.getTime(), // convert datetime to tick count for time series data axis
            Total: issue.count + eventCount,
            Issues: issue.count,
            Events: eventCount
          };
        });

        // console.log({ timeData: chartData });

        if (!chartData || !chartData.length) {
          return (
            <>
              DisruptOps has not found any issues or observed any events yet.
              <Skeleton />
            </>
          );
        }

        const from = chartData[0]?.x;
        const to = chartData[chartData.length - 1].x;

        const domain = d3.scaleTime().domain([d3.timeDay.floor(from), d3.timeDay.ceil(to)]);
        const ticks = domain.ticks(d3.timeDay.every(1));

        const dateFormatter = (tick) => {
          return new Date(tick).toLocaleDateString();
        };

        const handleClick = (data, index) => {
          const { payload } = data;
          const { x } = payload;
          const startDate = new Date(x);
          const endDate = new Date(x);
          endDate.setDate(endDate.getDate() + 1);

          history.push(
            `/issues?status=all&createdAtStart=${startDate.toISOString()}&createdAtEnd=${endDate.toISOString()}`
          );
        };

        return (
          <ResponsiveContainer>
            <LineChart width={600} height={400} data={chartData} margin={{ top: 0, right: 4, bottom: 0, left: 0 }}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="x"
                domain={[d3.timeDay.floor(from).getTime(), d3.timeDay.ceil(to).getTime()]}
                scale="time"
                type="number"
                ticks={ticks}
                tickFormatter={dateFormatter}
              />
              <YAxis tickFormatter={(tick) => tick.toLocaleString()} />
              <Tooltip
                formatter={(value, name, props) => {
                  // console.log({ date });
                  return [value.toLocaleString(), name];
                }}
                labelFormatter={(label) => dateFormatter(label)}
              />
              <Line type="linear" dataKey="Total" stroke="#46327e" />
              <Line type="linear" dataKey="Issues" stroke="#848ae7" activeDot={{ onClick: handleClick }} />
              <Line type="linear" dataKey="Events" stroke="#5d94e4" />
            </LineChart>
          </ResponsiveContainer>
        );
      }}
    </QueryResult>
  );
}

export default FindingsLine;

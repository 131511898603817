import React, { ReactNode } from 'react';
import { History, Location, Match } from 'react-router-dom';

import QueryStringContext, { ReplaceURLSearchParamsOptions } from './QueryStringContext';

interface Props {
  children: ReactNode;
  location: Location;
  history: History;
  match?: Match;
}

interface QS {
  [key: string]: string[];
}

function QueryString(props: Props) {
  const {
    children,
    location,
    location: { search },
    history
  } = props;

  const urlSearchParams = new URLSearchParams(search) as any;
  const qs = search ? makeQueryString(urlSearchParams) : {};

  const cloneURLSearchParams = makeURLSearchParamsCloner(urlSearchParams);
  const replaceURLSearchParams = makeURLSearchParamsReplacer(history, location);

  const context = {
    search,
    qs,
    urlSearchParams: cloneURLSearchParams(),
    history,
    location,
    cloneURLSearchParams,
    replaceURLSearchParams: replaceURLSearchParams
  };

  return <QueryStringContext.Provider value={context}>{children}</QueryStringContext.Provider>;
}

function makeQueryString(urlSearchParams: any): QS {
  const keys: string[] = Array.from(urlSearchParams.keys());

  if (!keys || keys.length === 0) return {};

  const qs = keys.reduce((qs, key) => {
    const values = urlSearchParams.getAll(key);
    if (!values || values.length === 0) return qs;
    qs[key] = values;
    return qs;
  }, {});

  return qs;
}

function makeURLSearchParamsCloner(urlSearchParams: URLSearchParams) {
  return () => new URLSearchParams(urlSearchParams.toString());
}

function makeURLSearchParamsReplacer(history: History, location: Location) {
  return (updatedURLSearchParams, options: ReplaceURLSearchParamsOptions = {}) => {
    const locationMatchesOption = !options.pathname || location.pathname.indexOf(options.pathname) !== -1;
    const pathname = locationMatchesOption ? location.pathname : options.pathname;
    history.push(`${pathname}?${updatedURLSearchParams.toString()}`);
  };
}

export default QueryString;

import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Switch, message } from 'antd';

const SET_ASSESSMENT_ENABLED = gql`
  mutation EnableAssessment($assessmentId: ID!, $input: AssessmentUpdateInput!) {
    updateAssessment(id: $assessmentId, assessmentInput: $input) {
      id
      isEnabled
    }
  }
`;

interface Props {
  isEnabled: boolean; // assessment.isEnabled
  assessmentId: string;
  buttonDisabled?: boolean;
}

function AssessmentEnableSwitch(props: Props) {
  const { assessmentId, isEnabled, buttonDisabled = false } = props;

  return (
    <Mutation mutation={SET_ASSESSMENT_ENABLED}>
      {(setAssessment, { loading }) => (
        <Switch
          disabled={buttonDisabled}
          checked={isEnabled}
          loading={loading}
          onChange={async (isEnabled) => {
            try {
              const input = {
                isEnabled
              };

              await setAssessment({ variables: { assessmentId, input } });

              message.success(`Detector successfully ${isEnabled ? 'enabled' : 'disabled'}`);
            } catch (e) {
              message.error('There was an problem updating this detector');
            }
          }}
        />
      )}
    </Mutation>
  );
}

export default AssessmentEnableSwitch;

import React from 'react';
import { ResourceDetailTableProps } from 'components/cloudResources/cloudResourceTypesConfig';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import DetailTable, { DetailRowProps } from 'components/cloudResources/DetailTable';
import PopoverDetailTable from '../../../PopoverDetailTable';
import { getResourceUrl } from 'utilities/inventory';

const AWS_EC2_NETWORK_INTERFACE_DETAIL = gql`
  query AWSEC2NetworkInterfaceDetail($dopsResourceUid: String!) {
    ec2_network_interfaces(dops_resource_uid: $dopsResourceUid) {
      size
      from
      results {
        id
        dops_resource_id
        dops_resource_uid
        dops_cloud_account_id
        dops_internal_account_id # could link to cloud account if we had a view?
        dops_region
        dops_raw
        attachment {
          attach_time
          attachment_id
          delete_on_termination
          device_index
          instance_id
          instance_owner_id
          status
        }
        availability_zone
        description
        groups {
          group_id
          group_name
        }
        interface_type
        ipv6_addresses
        mac_address
        network_interface_id
        owner_id
        private_dns_name
        private_ip_address
        private_ip_addresses {
          primary
          private_dns_name
          private_ip_address
        }
        requester_managed
        source_dest_check
        status
        subnet_id
        tags {
          key
          value
        }
        vpc_id
      }
    }
  }
`;

const rows: DetailRowProps[] = [
  {
    key: 'dops_resource_id',
    label: 'ID',
    dataIndex: 'dops_resource_id'
  },
  {
    key: 'vpc_id',
    label: 'VPC ID',
    dataIndex: 'vpc_id'
  },
  {
    key: 'mac_address',
    label: 'MAC address',
    dataIndex: 'mac_address'
  },
  {
    key: 'groups',
    label: 'Security groups',
    dataIndex: 'groups',
    render: (groups, resource) => {
      const len = groups.length;

      return (
        <>
          {groups.map((item, idx) => {
            const itemUrl = getResourceUrl({
              accountId: resource.dops_internal_account_id,
              itemType: 'AWS::EC2::SecurityGroup',
              itemKey: item.group_id,
              region: resource.dops_region
            });

            return (
              <Link key={item.group_id || `group_${idx}`} to={itemUrl}>
                {idx < len - 1 ? `${item.group_name}, ` : item.group_name}
              </Link>
            );
          })}
        </>
      );
    }
  },
  {
    key: 'status',
    label: 'Status',
    dataIndex: 'status'
  },
  {
    key: 'private_dns_name',
    label: 'Private DNS',
    dataIndex: 'private_dns_name',
    render: value => value || '-'
  },
  {
    key: 'private_ip_addresses',
    label: 'Secondary private IPv4 IPs',
    dataIndex: 'private_ip_addresses',
    render: privateIpAddresses => {
      return privateIpAddresses
        .filter(item => !item.primary)
        .map((item, idx) => (
          <PopoverDetailTable
            key={`private_ip_address_${idx}`}
            dataSource={item}
            title={item.private_ip_address}
            rows={[
              {
                key: 'private_ip_address',
                label: 'Private IP address',
                dataIndex: 'private_ip_address'
              },
              {
                key: 'private_dns_name',
                label: 'Private DNS',
                dataIndex: 'private_dns_name'
              },
              {
                key: 'primary',
                label: 'Primary',
                dataIndex: 'primary'
              }
            ]}
          >
            {item.private_ip_address}
          </PopoverDetailTable>
        ));
    }
  },
  {
    key: 'source_dest_check',
    label: 'Source/dest. check',
    dataIndex: 'source_dest_check',
    render: value => (value ? 'true' : 'false')
  },
  {
    key: 'instance_id',
    label: 'Instance ID',
    dataIndex: 'attachment',
    render: attachment => attachment.instance_id || '-'
  },
  {
    key: 'device_index',
    label: 'Device index',
    dataIndex: 'attachment',
    render: attachment => attachment.device_index
  },
  {
    key: 'delete_on_termination',
    label: 'Delete on termination',
    dataIndex: 'attachment',
    render: attachment => (attachment.delete_on_termination ? 'true' : 'false')
  },
  {
    key: 'allocation_id',
    label: 'Allocation ID',
    dataIndex: 'association',
    render: association => association.allocation_id
  },
  {
    key: 'subnet_id',
    label: 'Subnet ID',
    dataIndex: 'subnet_id'
  },
  {
    key: 'dops_region',
    label: 'Availability zone',
    dataIndex: 'dops_region'
  },
  {
    key: 'description',
    label: 'Description',
    dataIndex: 'description'
  },
  {
    key: 'owner',
    label: 'Owner ID',
    dataIndex: 'dops_cloud_account_id'
  },
  {
    key: 'private_ip_address',
    label: 'Primary private IPv4 IP',
    dataIndex: 'private_ip_address'
  },
  {
    key: 'public_ip',
    label: 'IPv4 public IP',
    dataIndex: 'association',
    render: association => association.public_ip
  },
  {
    key: 'attachment_id',
    label: 'Attachment ID',
    dataIndex: 'attachment',
    render: attachment => attachment.attachment_id
  },
  {
    key: 'attachment_owner_id',
    label: 'Attachment owner',
    dataIndex: 'attachment',
    render: attachment => attachment.instance_owner_id
  },
  {
    key: 'attachment_status',
    label: 'Attachment status',
    dataIndex: 'attachment',
    render: attachment => attachment.status
  },
  {
    key: 'attachment_time',
    label: 'Attachment Time',
    dataIndex: 'attachment',
    render: attachment => attachment.attach_time
  },
  {
    key: 'association_id',
    label: 'Association ID',
    dataIndex: 'association',
    render: association => association.association_id
  }
];

function AWSEC2NetworkInterfaceDetailTable(props: ResourceDetailTableProps) {
  const { dopsResourceUid } = props;

  return (
    <DetailTable
      query={AWS_EC2_NETWORK_INTERFACE_DETAIL}
      queryName={'ec2_network_interfaces'}
      rows={rows}
      variables={{ dopsResourceUid }}
    />
  );
}

export default AWSEC2NetworkInterfaceDetailTable;

import distanceInWordsToNow from 'date-fns/distance_in_words_to_now';
import formatDateTime from 'date-fns/format';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';

interface Props {
  dateTime: Date | string;
  format?: string;
  darkBackground?: boolean;
  appendTitle?: string;
}

const DateTimeContainer = styled.span`
  color: ${(p: { darkBackground: boolean }) => (p.darkBackground === true ? 'white' : '#4a4a4a')};
  // cursor: pointer;

  &:active {
    text-decoration: none;
    color: #333;
  }

  &:focus {
    text-decoration: none;
    color: #888;
  }

  &:hover {
    text-decoration: none;
    color: #888;
  }
`;

function DateTime(props: Props) {
  const { format, darkBackground = false, appendTitle } = props;
  const [displayDateTime, setDisplayDateTime] = useState();

  const dateTime = ensureDateTimeIsDate(props.dateTime);

  useEffect(() => {
    setDisplayDateTime(convertDateTimeToDisplay(dateTime, format));
    if (!format) {
      const interval = setInterval(() => setDisplayDateTime(convertDateTimeToDisplay(dateTime, format)), 10000);
      return () => clearInterval(interval);
    }
    return () => {
      // no cleanup needed
    };
  }, [dateTime, format]);

  if (!validateDateTime(dateTime)) {
    return <DateTimeContainer darkBackground={darkBackground}>-</DateTimeContainer>;
  }

  const title = dateTime.toLocaleString() + (appendTitle ? appendTitle : '');

  return (
    <DateTimeContainer title={title} darkBackground={darkBackground}>
      <time dateTime={dateTime.toISOString()}>{displayDateTime}</time>
    </DateTimeContainer>
  );
}

function validateDateTime(dateTime: Date) {
  return dateTime && isNaN(dateTime.getTime()) === false;
}

function ensureDateTimeIsDate(dateTime: Date | string) {
  return typeof dateTime === 'string' ? new Date(dateTime) : dateTime;
}

function convertDateTimeToDisplay(dateTime: Date, format?: string) {
  if (!dateTime) return null;

  if (format === 'local') return dateTime.toLocaleString();
  if (format) return formatDateTime(dateTime, format);
  return distanceInWordsToNow(dateTime, { includeSeconds: true, addSuffix: true });
}

export default DateTime;

import React from 'react';
import { Checkbox } from 'antd';

function CheckboxWidget(props) {
  return (
    <Checkbox checked={props.value} disabled={props.disabled} onChange={() => props.onChange(!props.value)}>
      {props.label}
    </Checkbox>
  );
}

export default CheckboxWidget;

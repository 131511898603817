import React from 'react';
import { Checkbox } from 'antd';
import styled from 'styled-components';

const Root = styled.div`
  padding-top: 18px;

  .ant-checkbox-wrapper span:last-child {
    padding-right: 0;
  }
`;
const CheckboxWrap = styled.div`
  height: 32px;
  border: 1px solid ${p => p.theme.grey300};
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 6px 12px;
`;

export interface Props {
  checked: boolean;
  label: string;
  onChange: (checked: Boolean) => any;
  classes?: {
    root?: string;
  };
}

function CheckboxFilter(props: Props) {
  const { checked, label, onChange } = props;

  return (
    <Root>
      <CheckboxWrap>
        <Checkbox
          checked={checked}
          onChange={(e: any) => {
            onChange(e && e.target && e.target.checked);
          }}
        >
          {label}
        </Checkbox>
      </CheckboxWrap>
    </Root>
  );
}

export default CheckboxFilter;

import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const Root = styled.div`
  h1 {
    font-size: 28px;
  }

  ul {
    margin-bottom: 24px;
  }

  li {
    margin-bottom: 12px;
  }

  code {
    background-color: ${p => p.theme.primary50};
    padding: 0px 2px;
  }
`;

interface Props {
  source: string;
}

function Markdown(props: Props) {
  const { source } = props;

  return (
    <Root className="markdown">
      <ReactMarkdown source={source} />
    </Root>
  );
}

export default Markdown;

import React from 'react';
import styled from 'styled-components';
import { FieldProps } from 'formik';
import { Radio } from 'antd';
import { CloudAccountPolicyOption } from 'typings';
const RadioGroup = Radio.Group;

const Root = styled.div`
  .ant-radio-wrapper {
    .label {
      font-weight: 600;
    }

    .description {
      opacity: rgba(0, 0, 0, 0.45);
    }
  }
`;

export interface Props {
  policyOptions: CloudAccountPolicyOption[];
}

/**
 * buildPolicySelect
 * description: function that returns functional component to be used in Formik.Field component prop
 */
function buildPolicySelect(props: Props) {
  const { policyOptions } = props;

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
  };

  // returns formik compliant field component
  return (fieldProps: FieldProps) => {
    const { field } = fieldProps;

    return (
      <Root>
        <RadioGroup
          value={field.value}
          onChange={(e: any) => {
            fieldProps.form.setFieldValue(field.name, e.target.value);
          }}
        >
          {policyOptions.map(item => (
            <Radio key={item.policy_code} style={radioStyle} value={item.policy_code}>
              <span className="label">{`${item.label}: `}</span>
              <span className="description">{item.description}</span>
            </Radio>
          ))}
        </RadioGroup>
      </Root>
    );
  };
}

export function getInitialPolicyOptionFromList(options: CloudAccountPolicyOption[]) {
  return options.find(item => {
    const { selected } = item;
    if (!selected) return false;

    return true;
  });
}

export default buildPolicySelect;

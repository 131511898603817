import React from 'react';
import { FormField } from 'components/ui/Form';
import ProjectTree from 'components/project/ProjectTree';
import { GuardrailConfigurationInputProps } from '../../trigger/definitions';

function ProjectScopeFilterInput(props: GuardrailConfigurationInputProps) {
  const { name } = props;

  return (
    <FormField name={name} label={'Project Scope'}>
      {({ value, handleChange, handleBlur }) => {
        return (
          <ProjectTree
            checkable
            checkedProjectIds={value}
            cascade
            allowMultipleNodes
            onCheck={(checked) => {
              handleChange(checked);
              handleBlur();
            }}
          />
        );
      }}
    </FormField>
  );
}

export default ProjectScopeFilterInput;

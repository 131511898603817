import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import OpsList from '../List/OpsList';
import useOpPathname from '../OpsCommon/useOpPathname/useOpPathname';

function OpsSection() {
  const basepath = useOpPathname();

  return (
    <Switch>
      <Redirect from="/ops/details/:opId?" to={`${basepath}:opId?`} />
      <Route path={`${basepath}/create/:createStep?`} render={(props) => <OpsList create {...props} />} />
      <Route path={`${basepath}/:opId?/:editing?`} component={OpsList} />
      <Route path={`${basepath}`} component={OpsList} />
    </Switch>
  );
}

export default OpsSection;

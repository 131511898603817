import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { EVENT_DEFINITION_QUERY } from 'queries';
import { ModeOption } from 'antd/lib/select';

const Root = styled.div``;

export interface Props {
  value?: string | string[];
  onChange: (value: any) => any;
  mode?: ModeOption;
  placeholder?: string;
  onBlur?: (event: any) => any;
  minWidth?: number;
}

function EventDefinitionFilter(props: Props) {
  const { value, onChange, onBlur, minWidth = 300 } = props;

  const mode = props.mode ? props.mode : 'multiple';
  const placeholder = props.placeholder ? props.placeholder : 'All events';

  return (
    <Root>
      <Query query={EVENT_DEFINITION_QUERY} fetchPolicy="network-only">
        {(results) => {
          const { loading, error, data } = results;

          return (
            <Select
              value={value === null ? undefined : value}
              style={{ width: '100%', minWidth: `${minWidth}px` }}
              onChange={onChange}
              disabled={loading || !!error}
              placeholder={!error ? placeholder : 'Error loading event definitions'}
              loading={loading}
              mode={mode}
              onBlur={onBlur}
              filterOption={(inputValue, option) => {
                if (!option || !option.props || !option.props.title) {
                  return true;
                }
                return new RegExp(inputValue, 'i').test(option.props.title);
              }}
            >
              {!loading && data && data.eventDefinitions && data.eventDefinitions.nodes ? (
                data.eventDefinitions.nodes.map((eventDefinition) => {
                  return (
                    <Select.Option key={eventDefinition.id} title={eventDefinition.name} value={eventDefinition.id}>
                      {eventDefinition.name}
                    </Select.Option>
                  );
                })
              ) : (
                <Select.Option value="">Loading</Select.Option>
              )}
            </Select>
          );
        }}
      </Query>
    </Root>
  );
}

export default EventDefinitionFilter;

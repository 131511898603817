import React from 'react';
import styled from 'styled-components';
import { Alert, Form, Input } from 'antd';
import * as Yup from 'yup';

import OnboardingPanel from '../../components/OnboardingPanel';
import ButtonAndLink from '../../components/ButtonAndLink';
import { Formik, FormikActions, FormikValues } from 'formik';
import { Mutation, MutationFunction } from 'react-apollo';
import gql from 'graphql-tag';
import { RouteComponentProps } from 'react-router';
import ErrorAlert from 'components/ui/ErrorAlert';

const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  align-items: center;
  justify-content: space-around;

  form {
    width: 100%;
  }
`;

const CONFIRM_ACCOUNT_MUTATION = gql`
  mutation ConfirmAccount($input: AccountConfirmationData) {
    confirmAccount(input: $input) @rest(method: "POST", path: "/auth/confirm")
  }
`;

const FORM_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Email address must be a valid email')
    .required('Email address is required'),
  confirmationCode: Yup.string().required('Confirmation code is required')
});

interface Props extends RouteComponentProps {}

class NewAccountConfirmationPage extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { location } = this.props;

    const existingEmail = location.state && location.state.email;

    let initialValues = {
      email: existingEmail || '',
      confirmationCode: ''
    };

    return (
      <OnboardingPanel withLogo title="Confirm new account">
        <Mutation mutation={CONFIRM_ACCOUNT_MUTATION}>
          {(confirmAccount, results) => {
            const { error, loading } = results;
            return (
              <Formik
                initialValues={initialValues}
                onSubmit={(values, actions) => this.handleSubmit(confirmAccount, values, actions)}
                validationSchema={FORM_SCHEMA}
              >
                {({ handleChange, handleBlur, handleSubmit, values, touched, errors, isSubmitting }) => {
                  return (
                    <FormContainer>
                      <Form onSubmit={handleSubmit}>
                        {!error && existingEmail && (
                          <Alert message="We sent a confirmation code to your email!" type="info" showIcon />
                        )}
                        {error && <ErrorAlert message="Unable to confirm account" error={error} />}

                        <Form.Item
                          label="Email address"
                          required={true}
                          validateStatus={errors.email ? 'error' : 'success'}
                          hasFeedback={touched.email && errors.email ? true : false}
                          help={touched.email && errors.email ? errors.email : ''}
                        >
                          <Input
                            value={values.email}
                            placeholder="john@doe.com"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            type="email"
                            size="large"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Confirmation code"
                          required={true}
                          validateStatus={errors.confirmationCode ? 'error' : 'success'}
                          hasFeedback={touched.confirmationCode && errors.confirmationCode ? true : false}
                          help={touched.confirmationCode && errors.confirmationCode ? errors.confirmationCode : ''}
                        >
                          <Input
                            value={values.confirmationCode}
                            placeholder="1337"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="confirmationCode"
                            size="large"
                          />
                        </Form.Item>
                        <ButtonAndLink
                          buttonText="Confirm account"
                          linkText="Login to existing account"
                          linkLink="/login"
                          loading={isSubmitting || loading}
                        />
                      </Form>
                    </FormContainer>
                  );
                }}
              </Formik>
            );
          }}
        </Mutation>
      </OnboardingPanel>
    );
  }

  handleSubmit(confirmAccount: MutationFunction, values: FormikValues, actions: FormikActions<any>) {
    const { history } = this.props;
    const { email, confirmationCode } = values;
    const { setSubmitting } = actions;

    const variables = {
      input: {
        username: email,
        code: confirmationCode
      }
    };

    confirmAccount({ variables })
      .then(result => {
        setSubmitting(false);
        history.push('/login', { userWasConfirmed: true, email });
      })
      .catch(error => {
        setSubmitting(false);
      });
  }
}

export default NewAccountConfirmationPage;

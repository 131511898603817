import { useMutation, useQuery } from '@apollo/react-hooks';
import coerceObjectsNullsToUndefined from '@disruptops/neo-core/dist/coerce-object-nulls-to-undefined';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { Alert, Button, message, Modal } from 'antd';
import AuthContext from 'components/app/Auth/AuthContext';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { generateFieldSchema } from 'components/function/FunctionParameterInput';
import Form from 'components/ui/Form';
import { FormRenderProps } from 'components/ui/Form/Form';
import { DetailPanelScollable, DetailPanelScrollableSection } from 'designSystem/DetailPanel/DetailPanel';
import { SubmitButtonWrapDiv } from 'designSystem/EditorPanel/EditorPanel.styles';
import gql from 'graphql-tag';
import { OP_FIELDS } from 'queries/fragments/opFields';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { client } from 'services/graphql';
import Yup from 'services/validator';
import {
  AutomationEventDefinition,
  AutomationFunction,
  EventDefinitionFunction,
  Function as FunctionType,
  FunctionParameter,
  Op,
  OpConfiguration
} from 'typings';
import useOpPathname from '../../OpsCommon/useOpPathname/useOpPathname';
import {
  BuildValidationSchemaArgs,
  ContextArgs,
  OpEditorProps,
  OpState,
  AlertAction,
  OpFormValues
} from '../EditorUtils/EditorUtils';
import General from '../General/General';
import EnableSwitch from './components/EnableSwitch/EnableSwitch';
import OpEventModal from './components/OpEventModal';
import { AUTOMATION_EVENT_DEFINITIONS, AUTOMATION_FUNCTION_BY_ID } from './gql';
import { OpFormDiv } from './OpEditor.styles';
import { OpActionSection, OpNotificationSection, OpTriggerSection } from './sections';
import OP_DECISION_INTEGRATION_DEFINITIONS, {
  OpDecisionIntegrationDefinition
} from './sections/OpDecisionSection/definitions';
import { OP_NOTIFICATION_INTEGRATION_DEFINITIONS } from './sections/OpNotificationSection/definitions';
import { schema as tagsFilterSchema } from './sections/OpTriggerSection/filters/TagsFilter/TagsFilter';
// import set = Reflect.set;

export const SAVE_OP_MUTATION = gql`
  mutation SaveOpTrigger($id: String, $opInput: OpInput!) {
    saveOp(id: $id, opInput: $opInput) {
      ...OpFields
    }
  }
  ${OP_FIELDS}
`;

function OpEditor(props: OpEditorProps) {
  const {
    op,
    playbook,
    initTriggerId,
    allFindings,
    initialProjectId,
    eventSourceId,
    accountId,
    severity,
    regions,
    environments,
    resourceType,
    customFiltersPath,
    customFiltersPatterns,
    closeEditor,
    createMode
  } = props;

  const history = useHistory();
  const basepath = useOpPathname();
  const [confirmationModalState, setConfirmationModalState] = useState<ConfirmationModalState>(null);
  const [eventState, setEventState] = useState<{ event?: any }>({});
  const [opState, setOpState] = useState<OpState>(getInitialOpState({ op, playbook, initTriggerId }));
  const [isAllFindingsSelected, setIsAllFindingsSelected] = useState<boolean>(
    op?.eventDefinitionId // does the op have an eventDefinitionID set?  if so, then 'All Findings' is not selected
      ? false
      : allFindings !== null && allFindings !== undefined // is the allFindings prop specified? if so, use it (allFindings prop comes from a query string param from OpDetail)
      ? allFindings
      : true // otherwise, 'All Findings' is selected
  );
  const updateOpState = (updated: OpState) => {
    setOpState({
      ...opState,
      ...updated
    });
  };

  // FETCH NECESSARY ITEMS IN ORDER TO BUILD VALIDATION SCHEMA
  // this could all probably be moved into another custom react hook;
  // hook could be something like "useGetOpContextArgs";
  const { data: automationFunctionData } = useQuery(AUTOMATION_FUNCTION_BY_ID, {
    variables: {
      id: opState.functionId
    },
    skip: !opState.functionId,
    fetchPolicy: 'cache-first'
  });
  const automationFunction = automationFunctionData?.automationFunctions?.nodes?.[0] || null;

  const { data: eventDefinitionData } = useQuery(AUTOMATION_EVENT_DEFINITIONS, {
    variables: { id: opState.eventDefinitionId },
    skip: !opState.eventDefinitionId
  });
  const eventDefinition = eventDefinitionData?.eventDefinitions?.nodes?.[0] || null;

  const decisionIntegrationDefinition = opState.decisionIntegration
    ? OP_DECISION_INTEGRATION_DEFINITIONS.find((def) => def.key === opState.decisionIntegration) || null
    : null;

  const notificationIntegrationDefinition = opState.notificationIntegration
    ? OP_NOTIFICATION_INTEGRATION_DEFINITIONS.find((def) => def.key === opState.notificationIntegration) || null
    : null;

  const contextArgs: ContextArgs = {
    automationFunction,
    eventDefinition,
    isAllFindingsSelected: isAllFindingsSelected,
    playbook,
    decisionIntegrationDefinition,
    notificationIntegrationDefinition,
    opState,
    setOpState: updateOpState,
    op
  };
  const [saveOp] = useMutation(SAVE_OP_MUTATION);
  const validationSchema = buildValidationSchema({
    contextArgs,
    strict: false
  });
  const existingProjectId = op?.projectId || null;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: existingProjectId || '*' }]
  });
  const isAuthorized = existingProjectId ? authz.isAuthorized : true;
  const authContext = useContext(AuthContext);
  const defaultProjectId = authContext?.userRootProjectId;
  const initialValues = getInitialFormValuesFromOp({
    op,
    playbook,
    initTriggerId,
    projectId: initialProjectId || defaultProjectId,
    eventSourceId,
    accountId,
    severity,
    regions,
    environments,
    resourceType,
    customFiltersPath,
    customFiltersPatterns
  });

  async function onSaveOp(formValues, actions) {
    try {
      const opInput = transformFormValuesIntoOpToSave(formValues);

      const variables = {
        id: op?.id || undefined,
        opInput
      };

      const result = await saveOp({ variables });
      const savedOp: Op = result.data.saveOp;

      const location = {
        pathname: createMode ? basepath : `${basepath}/details/${savedOp.id}`,
        savedOp: true
      };

      history.push(location);

      // show success message
      message.success('Op was successfully saved');
    } catch (e) {
      message.error('There was an error saving this Op', e);
    }
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        allowCleanSubmits={false}
        onSubmit={onSaveOp}
      >
        {(formRenderProps) => {
          const { canSubmit, isSubmitting } = formRenderProps,
            saveButtonText = createMode ? 'Create Op With This Configuration' : 'Save Changes';

          return (
            <OpFormDiv>
              <SubmitButtonWrapDiv className={createMode ? 'fullscreen' : null}>
                <Button
                  disabled={!isAuthorized || !canSubmit || isSubmitting}
                  type="primary"
                  block
                  className="square"
                  loading={isSubmitting}
                  htmlType="submit"
                >
                  {saveButtonText}
                </Button>
                {closeEditor && (
                  <button className="cancel-btn" type="button" onClick={() => closeEditor()}>
                    cancel changes
                  </button>
                )}
              </SubmitButtonWrapDiv>

              {!isAuthorized && (
                <Alert
                  type="warning"
                  message={`You do not have effective permissions to edit this Op. You must have the "Modify Ops" permission for the Project of which this Op is owned by.`}
                  style={{ marginBottom: '24px' }}
                />
              )}

              <EnableSwitch contextArgs={contextArgs} formRenderProps={formRenderProps} opState={opState} />

              <DetailPanelScollable>
                <DetailPanelScrollableSection title="General">
                  <General op={op} authz={authz} />
                </DetailPanelScrollableSection>
                <DetailPanelScrollableSection title="Trigger">
                  <>
                    <OpTriggerSection
                      event={eventState.event}
                      isNewOp={!existingProjectId}
                      contextArgs={contextArgs}
                      onSelectTrigger={onSelectTrigger(formRenderProps, updateOpState, setIsAllFindingsSelected)}
                      formRenderProps={formRenderProps}
                      setConfirmationModal={setConfirmationModalState}
                    />
                  </>
                </DetailPanelScrollableSection>

                <DetailPanelScrollableSection title="Action">
                  <OpActionSection
                    contextArgs={contextArgs}
                    onSelectAction={onSelectAction(formRenderProps, updateOpState)}
                    formRenderProps={formRenderProps}
                  />
                </DetailPanelScrollableSection>
                <DetailPanelScrollableSection title="Notification">
                  <OpNotificationSection formRenderProps={formRenderProps} />
                </DetailPanelScrollableSection>
              </DetailPanelScollable>

              <OpEventModal
                opId={op?.id}
                eventDefinitionId={formRenderProps.values.eventDefinitionId}
                onSelect={(event: any) => {
                  setEventState({ event });
                }}
              />
            </OpFormDiv>
          );
        }}
      </Form>

      <Modal
        title={confirmationModalState?.title}
        children={confirmationModalState?.description}
        visible={Boolean(confirmationModalState)}
        onOk={confirmationModalState?.onConfirm}
        onCancel={() => {
          const onCancelCallback = confirmationModalState && confirmationModalState.onCancel;
          setConfirmationModalState(null);

          if (onCancelCallback) onCancelCallback();
        }}
        okText={confirmationModalState?.okText}
        cancelText={confirmationModalState?.cancelText}
      />
    </>
  );
}

function getInitialOpState(args: {
  op?: Op;
  playbook?: EventDefinitionFunction | null;
  initTriggerId?: string | null;
}): OpState {
  const { op, playbook, initTriggerId } = args;
  const state: OpState = {};

  if (!op) {
    if (playbook) {
      state.functionId = playbook.functionId;
      state.eventDefinitionId = playbook.eventDefinitionId;

      return state;
    } else if (initTriggerId) {
      state.eventDefinitionId = initTriggerId;
    }

    // no Op or Playbook.
    return state;
  }

  const { functionId, decisionIntegration, notificationIntegration, eventDefinitionId } = op;

  // AUTOMATION FUNCTION ID
  if (functionId) state.functionId = functionId;

  // EVENT DEFINITION ID
  if (eventDefinitionId) {
    state.eventDefinitionId = eventDefinitionId;
  }

  if (decisionIntegration) state.decisionIntegration = decisionIntegration;

  if (notificationIntegration) state.notificationIntegration = notificationIntegration;

  return state;
}

export function buildValidationSchema(args: BuildValidationSchemaArgs) {
  const { op, contextArgs, strict = false } = args;

  const automationFunction = (contextArgs && contextArgs.automationFunction) || (op && op.function) || null;

  let decisionIntegrationDefinition = (contextArgs && contextArgs.decisionIntegrationDefinition) || null;
  if (!decisionIntegrationDefinition && op && op.decisionIntegration) {
    decisionIntegrationDefinition =
      OP_DECISION_INTEGRATION_DEFINITIONS.find((def) => def.key === op.decisionIntegration) || null;
  }

  let shape: any = {
    projectId: Yup.string().required('Project is required'),
    name: Yup.string().required('Name is required'),

    description: Yup.string(),

    functionId: Yup.string().required(strict ? 'Action is required' : false),
    filtersConfiguration: Yup.object(),

    decisionIntegration: Yup.string(),

    // SLACK
    notificationIntegration: Yup.string(),

    // trigger Filter fields
    eventSourceId: Yup.string(),
    eventDefinitionId: Yup.string().nullable(),
    triggerProjectIds: Yup.array().of(Yup.string()),
    accountIds: Yup.array().of(Yup.string()),
    regions: Yup.array().of(Yup.string()),
    assessmentId: Yup.string(),
    environments: Yup.array().of(Yup.string()),
    accountLabels: Yup.array().of(Yup.string()),
    jsonPaths: Yup.array().of(
      Yup.object().shape({
        path: Yup.string().required(),
        patterns: Yup.array().min(1).of(Yup.string().required()),
        type: Yup.string().required()
      })
    ),
    severity: Yup.array().of(Yup.number()),
    resourceTypes: Yup.array().of(Yup.string()),
    tags: tagsFilterSchema
  };

  if (automationFunction && automationFunction.parameters) {
    shape.actionConfiguration = buildActionConfigurationShape(automationFunction.parameters);
  }

  if (decisionIntegrationDefinition && decisionIntegrationDefinition.validationShape) {
    shape = {
      ...shape,
      ...decisionIntegrationDefinition.validationShape
    };
  }

  return Yup.object().shape(shape);
}

function buildActionConfigurationShape(parameters: FunctionParameter[]) {
  const shape = parameters.reduce((acc, param) => {
    const fieldSchema = generateFieldSchema(param, true, {
      // jsonpathConfigPathPrefix: 'actionConfiguration.jsonpathConfiguration.',
      // dynamicConfigPathPrefix: 'actionConfiguration.dynamicConfiguration.'
    });

    acc[param.key] = fieldSchema;

    return acc;
  }, {});

  return Yup.object().shape(shape);
}

export type ConfirmationModalState = ConfirmationModalStateInterface | null;

interface ConfirmationModalStateInterface {
  title: string;
  description: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
}

type OnSelectTriggerArgs = {
  eventDefinition?: AutomationEventDefinition;
  isAllFindingsSelected?: boolean;
} | null;
export type OnSelectTriggerFunc = (args: OnSelectTriggerArgs) => void;

function onSelectTrigger(
  formRenderProps: FormRenderProps,
  updateOpState: (newState: OpState) => void,
  setIsAllFindingsSelected: (value: boolean) => void
): OnSelectTriggerFunc {
  const { setFieldValue, setFieldTouched } = formRenderProps;
  const EVENT_DEFINITION_ID = 'eventDefinitionId';

  return (args: OnSelectTriggerArgs) => {
    const eventDefinition = args?.eventDefinition || null;

    const eventDefinitionId = eventDefinition ? eventDefinition.id : null;

    setFieldValue(EVENT_DEFINITION_ID, eventDefinitionId);
    setFieldValue('eventSourceId', eventDefinition?.eventSource?.id);

    if (eventDefinition) {
      setFieldTouched(EVENT_DEFINITION_ID, true);
      setFieldTouched('eventSourceId', true);
      updateEventDefinitionInCache(eventDefinition);
    }

    updateOpState({
      eventDefinitionId
    });
    setIsAllFindingsSelected(args?.isAllFindingsSelected || false);
  };
}

// update cache so there isn't delay between selecting eventDefinition and querying it again.
function updateEventDefinitionInCache(eventDefinition: AutomationEventDefinition) {
  const query = AUTOMATION_EVENT_DEFINITIONS;
  const newData = {
    eventDefinitions: {
      nodes: [
        {
          ...eventDefinition,
          __typename: 'EventDefinition'
        }
      ],
      __typename: 'EventDefinitionResults'
    }
  };

  client.writeQuery({
    query,
    variables: { id: eventDefinition.id },
    data: newData
  });
}

export type OnSelectDecisionIntegrationFunc = (
  decisionIntegrationDefinition: OpDecisionIntegrationDefinition | null
) => void;

export type OnSelectActionArgs = { automationFunction?: any; actor?: FunctionType } | null;
export type OnSelectActionFunc = (args: OnSelectActionArgs) => void;

function onSelectAction(formRenderProps: FormRenderProps, updateOpState: (newState: OpState) => void) {
  const { setFieldValue, setFieldTouched } = formRenderProps;
  const FUNCTION_ID = 'functionId';

  return (args: OnSelectActionArgs) => {
    const automationFunction = args?.automationFunction || null;

    if (automationFunction) {
      const valuesToUpdate = parseFormValuesFromAutomationFunction(automationFunction);

      const updatedValues = {
        // ...values,
        ...valuesToUpdate,
        actorId: undefined
      };

      Object.keys(updatedValues).forEach((key) => {
        setFieldValue(key, updatedValues[key]);
      });

      setFieldValue(FUNCTION_ID, automationFunction.id);

      setFieldTouched(FUNCTION_ID, true);

      updateAutomationFunctionInCache(automationFunction);

      updateOpState({ functionId: automationFunction.id });
    } else {
      // clear action
      const updatedValues = {
        actorId: undefined,
        functionId: undefined
      };

      setFieldValue('actorId', undefined);
      setFieldValue('functionId', undefined);

      updateOpState(updatedValues);
    }
  };
}

function updateAutomationFunctionInCache(automationFunction: AutomationFunction) {
  const query = AUTOMATION_FUNCTION_BY_ID;
  const variables = { id: automationFunction.id };
  const data = {
    automationFunctions: {
      pageInfo: {
        total: 1,
        size: 1,
        __typename: 'PageInfo'
      },
      nodes: [automationFunction],
      __typename: 'AutomationFunctionResults'
    }
  };

  client.writeQuery({ query, variables, data });
}

export function parseFormValuesFromAutomationFunction(automationFunction: AutomationFunction): OpFormValues {
  const { id: functionId, eventFunctions } = automationFunction;
  const actionConfiguration = eventFunctions
    ? eventFunctions.reduce((acc: any, eventFunction) => {
        const { functionInputMappings } = eventFunction;

        if (functionInputMappings)
          functionInputMappings.forEach((inputMapping) => {
            const { key, valuePath } = inputMapping;

            if (valuePath) {
              // update jsonpathConfiguration
              if (!(acc.jsonpathConfiguration && acc.jsonpathConfiguration[key])) {
                acc.jsonpathConfiguration = acc.jsonpathConfiguration
                  ? { ...acc.jsonpathConfiguration, [key]: valuePath } // update object if exists
                  : { [key]: valuePath }; // create new object if none exists
              }
            }
          });

        return acc;
      }, {})
    : {};

  return {
    functionId,
    actionConfiguration
  };
}

export function getInitialFormValuesFromOp(args: {
  op?: Op;
  playbook?: EventDefinitionFunction | null;
  initTriggerId?: string | null;
  projectId?: string | undefined;
  eventSourceId?: string | undefined;
  accountId?: string | undefined;
  severity?: number | undefined;
  regions?: string | undefined;
  environments?: string | undefined;
  resourceType?: string | undefined;
  customFiltersPath?: string | undefined;
  customFiltersPatterns?: string | undefined;
}): OpFormValues {
  const {
    op,
    playbook,
    initTriggerId,
    projectId,
    eventSourceId,
    accountId,
    severity,
    regions,
    environments,
    resourceType,
    customFiltersPath,
    customFiltersPatterns
  } = args;

  if (!op)
    return playbook
      ? getInitialFormValuesFromPlaybook(
          playbook,
          projectId,
          projectId,
          eventSourceId,
          accountId,
          severity,
          regions,
          environments,
          resourceType,
          customFiltersPath,
          customFiltersPatterns
        )
      : {
          eventDefinitionId: initTriggerId || undefined,
          projectId: projectId || undefined,
          eventSourceId: eventSourceId || undefined,
          triggerProjectIds: projectId ? [projectId] : undefined,
          accountIds: accountId ? [accountId] : undefined,
          severity: severity ? [severity] : undefined,
          regions: regions ? [regions] : undefined,
          environments: environments ? [environments] : undefined,
          resourceType: resourceType ? [resourceType] : undefined,
          jsonPaths:
            customFiltersPatterns && customFiltersPath
              ? [
                  {
                    path: customFiltersPath,
                    patterns: [customFiltersPatterns],
                    type: 'STRICT_MATCH'
                  }
                ]
              : undefined
        };

  const opWithoutNulls = coerceObjectsNullsToUndefined(op);
  const {
    configuration,
    // fields not used in form
    id,
    clientId,
    createdById,
    createdByUsername,
    createdAt,
    updatedAt,
    archivedAt,
    deletedAt,
    function: _func, // cannot be named function
    eventDefinition,
    eventSource,
    // end fields not used in form.
    ...fieldsOkayAsIs
  } = opWithoutNulls;
  const actionConfiguration = configuration ? transformOpConfigsToActionConfig(configuration) : {};

  // console.log({ configuration, actionConfiguration });

  if (actionConfiguration?.alertActions) {
    actionConfiguration.alertActions = actionConfiguration.alertActions.map((alertAction) => {
      // console.log({ alertAction });
      return {
        ...alertAction,
        configuration: transformOpConfigsToActionConfig(alertAction.configuration)
      };
    });
    // console.log({alertActions: actionConfiguration.alertActions});
  }

  if (Array.isArray(actionConfiguration?.delayedAction?.configuration)) {
    // console.log({ delayedAction: actionConfiguration.delayedAction });
    actionConfiguration.delayedAction.configuration = transformOpConfigsToActionConfig(
      actionConfiguration.delayedAction.configuration
    );
  }

  // console.log({actionConfigurationAfter: actionConfiguration});

  const init: OpFormValues = {
    ...fieldsOkayAsIs,
    actionConfiguration
  };

  return init;
}

function getInitialFormValuesFromPlaybook(
  playbook: EventDefinitionFunction,
  projectId: string | undefined,
  triggerProjectIds: string | undefined,
  eventSourceId: string | undefined,
  accountId: string | undefined,
  severity: number | undefined,
  regions: string | undefined,
  environments: string | undefined,
  resourceType: string | undefined,
  customFiltersPath: string | undefined,
  customFiltersPatterns: string | undefined
): OpFormValues {
  const { eventDefinitionId, functionId, functionInputMappings } = playbook;
  const init: OpFormValues = {
    name: getNameFromPlaybook(playbook),
    eventDefinitionId,
    functionId: functionId,
    projectId: projectId,
    triggerProjectIds: projectId ? [projectId] : undefined,
    eventSourceId: eventSourceId,
    accountIds: accountId ? [accountId] : undefined,
    severity: severity ? [severity] : undefined,
    regions: regions ? [regions] : undefined,
    resourceType: resourceType ? [resourceType] : undefined,
    jsonPaths:
      customFiltersPatterns && customFiltersPath
        ? [
            {
              path: customFiltersPath,
              patterns: [customFiltersPatterns],
              type: 'STRICT_MATCH'
            }
          ]
        : undefined
  };

  if (functionInputMappings)
    init.actionConfiguration = {
      jsonpathConfiguration: functionInputMappings.reduce((acc, functionInputMapping) => {
        acc[functionInputMapping.key] = functionInputMapping.valuePath;

        return acc;
      }, {})
    };

  return init;
}

function getNameFromPlaybook(eventDefinitionFunction: EventDefinitionFunction): string {
  const eventDefinitionName = eventDefinitionFunction.eventDefinition?.name || null;
  const functionName = eventDefinitionFunction?.function?.name || null;

  if (eventDefinitionFunction.name) return eventDefinitionFunction.name;

  return functionName && eventDefinitionName
    ? `${functionName} when event "${eventDefinitionName}" is discovered.`
    : functionName || '--';
}

function transformFormValuesIntoOpToSave(formValues: OpFormValues): Op {
  const {
    decisionIntegration,
    decisionType,
    decisionRecipientId,
    notificationIntegration,
    successNotificationRecipientId,
    failureNotificationRecipientId,
    triggerProjectIds,
    regions,
    accountIds,
    actionConfiguration,
    eventSourceId,
    tags,
    ...fieldsOkayAsIs
  } = formValues;

  const configuration = transformActionConfigurationToOpConfigs(actionConfiguration);

  const toSave: Op = {
    decisionIntegration: decisionIntegration || null,
    decisionType: decisionType || null,
    decisionRecipientId: decisionRecipientId || null,
    notificationIntegration: notificationIntegration || null,
    successNotificationRecipientId: successNotificationRecipientId || null,
    failureNotificationRecipientId: failureNotificationRecipientId || null,
    triggerProjectIds: triggerProjectIds || null,
    regions: regions || null,
    accountIds: accountIds || null,
    eventSourceId: eventSourceId || null,
    tags: tags ? tags : [],
    configuration,
    ...fieldsOkayAsIs
  };

  return toSave;
}

function transformAlertActionConfiguration(alertAction: AlertAction): AlertAction {
  if (!alertAction) throw new Error(`Required parameter 'alertAction' is falsy.`);

  const settings: OpConfiguration[] = [];

  // the editors contain the full automation function, chomp it out from the final result
  const { function: func, configuration, ...otherProps } = alertAction;

  // separate the static settings from the jsonpath and dynamic settings
  const { jsonpathConfiguration, dynamicConfiguration, ...staticConfiguration } = configuration;

  // normalize the static settings
  if (staticConfiguration)
    Object.keys(staticConfiguration).forEach((key) => {
      settings.push({
        key,
        type: 'STATIC',
        value: staticConfiguration[key]
      });
    });

  // normalize the jsonpath settings
  if (jsonpathConfiguration)
    Object.keys(jsonpathConfiguration).forEach((key) => {
      settings.push({
        key,
        type: 'JSONPATH',
        value: jsonpathConfiguration[key]
      });
    });

  // normalize the dynamic config settings
  if (dynamicConfiguration)
    Object.keys(dynamicConfiguration).forEach((key) => {
      settings.push({
        key,
        type: 'DYNAMIC',
        value: dynamicConfiguration[key]
      });
    });

  const transformedAlertAction = {
    ...otherProps,
    configuration: [...settings]
  };

  return transformedAlertAction;
}

function transformActionConfigurationToOpConfigs(actionConfiguration: any): OpConfiguration[] {
  if (!actionConfiguration) return [];
  const { jsonpathConfiguration, alertActions = [], delayedAction, ...otherStaticConfiguration } = actionConfiguration;
  const configurations: OpConfiguration[] = [];

  // console.log({ alertActions, delayedAction });

  // remove the function property from alertActions
  const staticConfiguration = {
    ...otherStaticConfiguration,
    alertActions: alertActions.map((alertAction) => transformAlertActionConfiguration(alertAction)),
    delayedAction: delayedAction && transformAlertActionConfiguration(delayedAction)
  };

  // console.log({ staticConfiguration });

  if (jsonpathConfiguration) {
    Object.keys(jsonpathConfiguration).forEach((key) => {
      configurations.push({
        key,
        type: 'JSONPATH',
        value: jsonpathConfiguration[key]
      });
    });
  }

  Object.keys(staticConfiguration).forEach((key) => {
    configurations.push({
      key,
      type: 'STATIC',
      value: staticConfiguration[key]
    });
  });

  return configurations;
}

function transformOpConfigsToActionConfig(opConfigs: OpConfiguration[]) {
  const actionConfig =
    Array.isArray(opConfigs) &&
    opConfigs?.reduce(
      (acc: any, opConfig) => {
        if (opConfig.type === 'JSONPATH') {
          acc.jsonpathConfiguration[opConfig.key] = opConfig.value;
        } else if (opConfig.type === 'STATIC') {
          acc[opConfig.key] = opConfig.value;
        }

        return acc;
      },
      {
        jsonpathConfiguration: {}
      }
    );

  return actionConfig;
}

// async function validateOpAgainstSchema(schema: any, op: OpFormValues) {
//   try {
//     await schema.validate(op, { abortEarly: false });

//     return true;
//   } catch (err) {
//     return false;
//   }
// }

export async function validateRawOp(op: Op, strict?: boolean): Promise<false | Op> {
  // transform op into form values as that's what the schema is built around.
  const opToValidate = getInitialFormValuesFromOp({ op });

  const completeOpValidationSchema = buildValidationSchema({ op, strict: strict === false ? false : true });

  try {
    await completeOpValidationSchema.validate(opToValidate, { abortEarly: false });

    const opToSave = transformFormValuesIntoOpToSave(opToValidate);

    return opToSave;
  } catch (err) {
    return false;
  }
}

export default OpEditor;

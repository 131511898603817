import React, { RefObject, KeyboardEvent, ChangeEvent, FocusEvent } from 'react';
import { Button, Input, Spin } from 'antd';
import styled from 'styled-components';
import DopeIcon from './DopeIcon';

interface Props {
  initialValue: string;
  onSave: (updatedValue: string) => any;
  loading: boolean;
  disabled?: boolean;
}

interface State {
  value: string;
  isEditing: boolean;
}

const Root = styled.div`
  .click-to-edit-static-value {
    margin-top: 1px;
    margin-bottom: 2px;
  }

  .click-to-edit-field {
    margin-right: 12px;
  }
`;

class ClickToEditInput extends React.Component<Props, State> {
  static defaultProps = {
    loading: false
  };

  inputRef: RefObject<any>;

  constructor(p: Props) {
    super(p);

    const { initialValue } = p;

    this.state = {
      isEditing: false,
      value: initialValue
    };

    this.inputRef = React.createRef();

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handlePressEnter = this.handlePressEnter.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  render() {
    const { isEditing } = this.state;
    return <Root>{isEditing ? this.renderEditField() : this.renderStaticValue()}</Root>;
  }

  renderStaticValue() {
    const { initialValue, disabled = false } = this.props;

    return (
      <div className="click-to-edit-static-value">
        {initialValue}
        {!disabled && (
          <Button onClick={this.handleEditClick} type="link">
            <DopeIcon name="EDIT" />
          </Button>
        )}
      </div>
    );
  }

  renderEditField() {
    const { loading } = this.props;
    const { value } = this.state;

    return (
      <div className="click-to-edit-field">
        <Input
          disabled={loading}
          defaultValue={value}
          size="large"
          onPressEnter={this.handlePressEnter}
          onBlur={this.handleBlur}
          ref={this.inputRef}
          suffix={loading ? <Spin size="small" /> : null}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            this.setState({
              value: event.target.value
            });
          }}
        />
      </div>
    );
  }

  handleEditClick() {
    this.setState(
      {
        isEditing: true
      },
      () => {
        if (this.inputRef.current) {
          this.inputRef.current.focus();
        }
      }
    );
  }

  async handleBlur(event: FocusEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    const { onSave } = this.props;

    await onSave(value);

    this.setState({
      isEditing: false
    });
  }

  async handlePressEnter(event: KeyboardEvent<HTMLInputElement>) {
    const value = event.currentTarget.value;
    const { onSave } = this.props;

    await onSave(value);

    this.setState({
      isEditing: false
    });
  }
}

export default ClickToEditInput;

import React, { useEffect, useState } from 'react';
import { Op } from 'typings';
import { validateRawOp } from '../../Editor/OpDetail/OpEditor';
import { Root } from './OpBanner.styles';

interface OpBannerProps {
  op: Op;
  isDetailPanel?: boolean;
}

const pending = 'pending';

function OpBanner(props: OpBannerProps) {
  const { op, isDetailPanel } = props,
    [isComplete, setIsComplete] = useState<string | boolean | Op>(pending),
    [enableMessage, setEnableMessage] = useState<string | undefined>(),
    selectors = isDetailPanel ? 'op-name is-detail-panel' : 'op-name';

  useEffect(() => {
    validateRawOp(op, true).then((op) => setIsComplete(op));
  }, [op]);

  useEffect(() => {
    if (isComplete === false) {
      setEnableMessage('This op must be fully configured before it can be enabled.');
    } else if (!op.isEnabled) {
      setEnableMessage('This Op is currently disabled');
    } else {
      setEnableMessage(undefined);
    }
  }, [op, isComplete]);

  return (
    <Root>
      <span data-testid="op-name" className={selectors}>
        {op.name}
      </span>
      {enableMessage && <div className="disabled">{enableMessage}</div>}
    </Root>
  );
}

export default OpBanner;

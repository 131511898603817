import React from 'react';
import Context, { ContextInterface, GetRelevantSettingsParams } from './DynamicConfigContext';
import { ConfigSetting, Config, SettingRow } from 'typings';

import CONFIG_SETTINGS from 'fixtures/DynamicConfig';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';

// this seems like a sloppy hack.
export const ALL_SETTINGS = CONFIG_SETTINGS.reduce((acc: ConfigSetting[], group): ConfigSetting[] => {
  group.configSettings.forEach(setting => {
    acc.push(setting);
  });

  return acc;
}, []);

const ORGANIZATION_CONFIGS = gql`
  # query QueryDynamicConfigs($entityId: ID, $entityType: DynamicConfigurationEntityTypes) {
  # dynamicConfigurations(
  #   entityId: $entityId,
  #   entityType: $entityType
  #   pageSize: 50
  # ) {
  query QueryDynamicConfigs {
    dynamicConfigurations(
      # entityType: ORGANIZATION, # need to save root configs as ORGANIZATION?
      pageSize: 50
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        id
        entityId
        entityType
        key
        value
      }
    }
  }
`;

// definitely some repeated gql fields...
const ORGANIZATION_AND_PROJECT_CONFIGS = gql`
  query QueryDynamicConigsOrganizationAndProject($projectId: ID!) {
    dynamicConfigurations(pageSize: 50) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        id
        entityId
        entityType
        key
        value
      }
    }

    project(id: $projectId) {
      id
      dynamicConfigurations {
        nodes {
          id
          clientId
          entityType
          key
          value
        }
      }
    }
  }
`;

interface Props {
  projectId?: string;
  children: (configBag: ContextInterface) => React.ReactNode;
}

function DynamicConfigProvider(props: Props) {
  const { projectId } = props;

  return (
    <Query
      query={projectId ? ORGANIZATION_AND_PROJECT_CONFIGS : ORGANIZATION_CONFIGS}
      variables={projectId ? { projectId } : {}}
    >
      {({ loading, data, error }) => {
        // parse results
        const organizationConfigs: Config[] | null = !loading ? data.dynamicConfigurations.nodes : null;
        const configBag: ContextInterface = {
          initializing: loading,
          getRelevantSettings: (params: GetRelevantSettingsParams) => {
            const relevantSettingsByConfigCode = ALL_SETTINGS.filter(setting => {
              const settingConfigCode = setting.key;

              if (params.type !== setting.type) return false;
              if (params.many === false && setting.many === true) return false;
              if (params.configCode !== settingConfigCode) return false;

              return true;
            }).reduce((acc, item) => {
              acc[item.key] = item;

              return acc;
            }, {});

            const scopedDynamicConfig = [
              {
                scopeKey: 'organization',
                scopeLabel: 'Root Project',
                options: organizationConfigs
                  ? organizationConfigs.reduce((acc: SettingRow[], config) => {
                      if (relevantSettingsByConfigCode[config.key]) {
                        acc.push({
                          ...relevantSettingsByConfigCode[config.key],
                          savedItem: config
                        });
                      }
                      return acc;
                    }, [])
                  : []
              }
            ];

            return scopedDynamicConfig;
          }
        };

        return <Context.Provider value={configBag}>{props.children(configBag)}</Context.Provider>;
      }}
    </Query>
  );
}

export default DynamicConfigProvider;

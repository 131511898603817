import DelayedLoader from 'designSystem/DelayedLoader/DelayedLoader';
import React from 'react';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router-dom';
import { PLAYBOOK_BY_ID } from '../../Editor/OpDetail/gql';
import OpEditor from '../../Editor/OpDetail/OpEditor';
import ErrorAlert from 'components/ui/ErrorAlert';

function ConfirmEditor() {
  const location = useLocation(),
    querystringParams = Object.fromEntries(new URLSearchParams(location.search)),
    { data, error, loading } = useQuery(PLAYBOOK_BY_ID, {
      variables: { id: querystringParams.fromPlaybook },
      skip: !querystringParams.fromPlaybook
    });

  if (querystringParams.accountIds) {
    querystringParams.accountId = querystringParams.accountIds;
  }

  if (querystringParams.severity) {
    // TS doesn't want to recast this from string to number
    // @ts-ignore
    querystringParams.severity = parseInt(querystringParams.severity, 10);
  }

  return (
    <>
      {loading && <DelayedLoader />}
      {error && <ErrorAlert error={error} message="Could not load playbook" />}
      {data && <OpEditor {...querystringParams} playbook={data.playbooks.nodes[0]} createMode />}
      {!querystringParams.fromPlaybook && <OpEditor {...querystringParams} createMode />}
    </>
  );
}

export default ConfirmEditor;

import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import EventDetailPage from '../EventDetailPage';
import EventsPage from '../Events';
import EventsStatsPage from '../EventsStatsPage';
import EventTakeActionPage from '../EventTakeActionPage';

function EventsSection() {
  const featureFlags = useFeatureFlag([Feature.EVENTS_STATS_DASHBOARD]);

  return (
    <Switch>
      {featureFlags[Feature.EVENTS_STATS_DASHBOARD] ? (
        <Route path="/events/stats" component={EventsStatsPage} />
      ) : (
        <Route path="/events" component={EventsPage} />
      )}
      <Route path="/events/:eventId/actions/:functionId" component={EventTakeActionPage} />
      <Route path="/event/:eventId" component={EventDetailPage} />
      <Route path="/events/:eventId" component={EventDetailPage} />
      <Route path="/events:status?" component={EventsPage} />
      <Route render={() => <Redirect to="/events" />} />
    </Switch>
  );
}

export default EventsSection;

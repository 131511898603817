import { message, Modal } from 'ant4x';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { Detector } from 'typings';
import useDetectorVariants from '../../DetectorsCommon/useDetectorVariants/useDetectorVariants';
import useQueryString from '../../DetectorsCommon/useQueryString/useQueryString';

interface Props {
  onClosed: () => void;
  onSuccess: () => void;
  detector: Detector;
  visible: boolean;
}

const DELETE_ASSESSMENT = gql`
  mutation DeleteAssessment($id: ID!) {
    deleteAssessment(id: $id)
  }
`;

export default function DeleteDetectorModal(props: Props) {
  const { onClosed, onSuccess, detector, visible } = props,
    variants = useDetectorVariants(),
    queryString = useQueryString(),
    [deleteDetector, deleteDetectorResults] = useMutation(DELETE_ASSESSMENT),
    history = useHistory();

  async function handleOk() {
    try {
      const result = await deleteDetector({
        variables: { id: detector.id }
      });
      if (result.errors) {
        message.error(`There was an error deleting the detector "${detector.name}".`);
      } else {
        message.success(`The ${variants.variantName} "${detector.name}" was successfully deleted.`);
        history.push(`${variants.basePath}/${queryString}`);
        onSuccess && onSuccess();
      }
    } catch (e) {
      message.error(`There was an error deleting the ${variants.variantName} "${detector.name}".`);
    } finally {
      onClosed && onClosed();
    }
  }

  function handleCancel() {
    onClosed && onClosed();
  }

  return (
    <Modal
      title={`Are you sure you want to delete the ${variants.variantName} "${detector.name}"?`}
      visible={visible}
      onOk={handleOk}
      confirmLoading={deleteDetectorResults?.loading}
      onCancel={handleCancel}
    >
      <p>
        {`Warning! Deleting the ${variants.variantName} "${detector.name}" is an irreversible action. Continuing will delete this ${variants.variantName} and its related activity permanently.`}
      </p>
    </Modal>
  );
}

import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { Select, Typography } from 'antd';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { FilterItem, FilterRow } from 'components/app/FilterBar/components';
import { LabelsFilter, SearchFilter } from 'components/app/FilterBar/filters';
import { CloudVendorIcon } from 'components/cloudVendors';
import DopeIcon from 'components/ui/DopeIcon';
import { FormRenderProps } from 'components/ui/Form/Form';
import QueryResultComponent from 'components/util/QueryResult';
import { CloudLabelUl } from 'designSystem/CloudLabel/CloudLabel';
import { GhostButton } from 'designSystem/GhostButton/GhostButton';
import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import FunctionParameterInputSet from 'designSystem/LegacyModified/function/FunctionParameterInputSet';
import gql from 'graphql-tag';
import Maybe from 'graphql/tsutils/Maybe';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { AutomationFunction, FunctionParameter } from 'typings';
import { EditorSectionBanner } from '../../EditorSectionBanner';
import { ContextArgs } from '../../EditorUtils/EditorUtils';
import { OpSectionListItem } from '../components';
import { AUTOMATION_FUNCTION_FIELDS_FOR_OPS } from '../gql';
import { OnSelectActionFunc } from '../OpEditor';
import AlertActionEditor from './AlertActionEditor';
import AlertActionListItem, { AlertAction } from './AlertActionListItem';

// COMPONENT: ACTION SECTION
interface OpActionSectionProps {
  contextArgs: ContextArgs;
  onSelectAction: OnSelectActionFunc;
  formRenderProps: FormRenderProps;
}

const MemoizedSelectAction = React.memo(SelectAction, (p, n) => {
  // only update if event def changes?
  const prevEventDefId = p.contextArgs.eventDefinition?.id || null;
  const nextEventDefId = n.contextArgs.eventDefinition?.id || null;

  return prevEventDefId === nextEventDefId;
});

function OpActionSection(props: OpActionSectionProps) {
  const { contextArgs, formRenderProps, onSelectAction } = props;
  const { automationFunction, op } = contextArgs;

  const existingProjectId = op?.projectId || undefined;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: existingProjectId || '*' }]
  });
  const isAuthorized = existingProjectId ? authz.isAuthorized : true;
  const [editingAction, setEditingAction] = useState(automationFunction ? false : true);

  const icon = automationFunction ? <CloudVendorIcon vendor={automationFunction.cloudVendor} /> : null;

  useEffect(() => {
    setEditingAction(automationFunction ? false : true);
  }, [automationFunction]);

  function onEditClick() {
    setEditingAction(true);
    onSelectAction({}); // update the form value by clearing the previous action
  }

  return (
    <>
      {editingAction ? (
        <EditorSectionBanner>
          <div className="title">Select Action</div>
        </EditorSectionBanner>
      ) : (
        <div>
          <EditorSectionBanner>
            <div>{icon}</div>
            <div className="title">{automationFunction ? automationFunction.name : null}</div>
            {isAuthorized && <GhostButton onClick={onEditClick}>change action...</GhostButton>}
          </EditorSectionBanner>
        </div>
      )}

      {editingAction ? (
        <MemoizedSelectAction {...props} />
      ) : (
        <ConfigureAction
          formRenderProps={formRenderProps}
          parameters={automationFunction?.parameters}
          projectId={existingProjectId}
          automationFunction={automationFunction}
          contextArgs={contextArgs}
        />
      )}
    </>
  );
}

// CONFIGURE ACTION
interface ConfigureActionProps {
  parameters: Maybe<FunctionParameter[]>;
  projectId?: string;
  formRenderProps: FormRenderProps;
  automationFunction: Maybe<any>;
  contextArgs: ContextArgs;
}

export function ConfigureAction(props: ConfigureActionProps) {
  const { parameters, formRenderProps, automationFunction, projectId, contextArgs } = props;

  return (
    <>
      {automationFunction?.id === AlertAction.id ? (
        <AlertActionEditor
          formRenderProps={formRenderProps}
          parameters={parameters}
          projectId={projectId}
          contextArgs={contextArgs}
        />
      ) : parameters ? (
        <FunctionParameterInputSet
          {...formRenderProps}
          parameters={parameters}
          allowJSONPathInput
          staticConfigPathPrefix="actionConfiguration."
          jsonpathConfigPathPrefix="actionConfiguration.jsonpathConfiguration."
          dynamicConfigPathPrefix="actionConfiguration.staticConfiguration."
        />
      ) : (
        <div>No parameters to Configure</div>
      )}
    </>
  );
}

const UNIQUE_FUNCTION_LABELS_QUERY = gql`
  query UniqueFunctionLabelsForOpActions {
    uniqueFunctionLabels
  }
`;

// SELECT ACTION
const SelectActionRoot = styled.div`
  .list-wrapper {
    max-height: 400px;
    overflow-y: auto;

    & > div {
      margin-bottom: 1rem;
    }
  }

  .action-search-input {
    margin-bottom: 16px;
  }
`;

export interface SelectActionProps {
  onSelectAction: OnSelectActionFunc;
  contextArgs: ContextArgs;
  hideAlertAction?: Boolean;
}

export function SelectAction(props: SelectActionProps) {
  const { hideAlertAction } = props;
  const [actionSearchStr, setActionSearchStr] = useState<string | null>(null);
  const [cloudVendor, setCloudVendor] = useState<string[] | null>(null);
  const [actionLabels, setActionLabels] = useState<string[] | null>(null);

  const externalActionVendors = ['Jira'];

  return (
    <SelectActionRoot>
      <>
        <FilterRow>
          <FilterItem label="Search">
            <SearchFilter
              classes={{ root: 'action-search-input' }}
              search=""
              placeholder="Search available Actions"
              onSearchChange={(str) => setActionSearchStr(str && str.length > 0 ? str : null)}
            />
          </FilterItem>
          <FilterItem label="Cloud Vendor">
            <Select
              mode="multiple"
              value={(Array.isArray(cloudVendor) && cloudVendor) || []}
              style={{ minWidth: '200px', maxWidth: '500px' }}
              dropdownMatchSelectWidth={false}
              placeholder={'Select cloud vendor(s)'}
              maxTagCount={2}
              maxTagTextLength={48}
              maxTagPlaceholder={(extraValues) => {
                return <>+&nbsp;{extraValues.length}</>;
              }}
              onChange={(value) => {
                setCloudVendor(value);
              }}
            >
              {['AWS', 'Azure'].map((vendor) => {
                return (
                  <Select.Option key={vendor} title={vendor}>
                    {vendor}
                  </Select.Option>
                );
              })}
            </Select>
          </FilterItem>
          <FilterItem label="Labels">
            <LabelsFilter
              value={(Array.isArray(actionLabels) && actionLabels) || []}
              onChange={(value) => {
                setActionLabels(value);
              }}
              query={UNIQUE_FUNCTION_LABELS_QUERY}
              getLabelsRootFromQueryResult={(data) => data && data.uniqueFunctionLabels}
            />
          </FilterItem>
        </FilterRow>

        <div className="list-wrapper">
          {!hideAlertAction && (
            <HorizontalLabel label="Alert Actions">
              <AlertActionListItem {...props} />
            </HorizontalLabel>
          )}

          <HorizontalLabel label="Recommended Actions">
            <RecommendedActions {...props} searchString={actionSearchStr} />
          </HorizontalLabel>

          <HorizontalLabel label="External Actions">
            <AutomationFunctionList
              {...props}
              searchString={actionSearchStr}
              labels={actionLabels}
              cloudVendor={externalActionVendors}
              displayVendorTitle={false}
            />
          </HorizontalLabel>

          <HorizontalLabel label="All Actions">
            <AutomationFunctionList
              {...props}
              searchString={actionSearchStr}
              labels={actionLabels}
              cloudVendor={cloudVendor}
            />
          </HorizontalLabel>
        </div>
      </>
    </SelectActionRoot>
  );
}

// RECOMMENDED ACTIONS
// link table between functions and event_defs. Also, used for playbooks...
const EVENT_DEFINITION_FUNCTIONS = gql`
  query AutomationFunctionsByEventDefinitionForOpEditor($id: ID!) {
    eventDefinitionFunctions(eventDefinitionId: $id) {
      pageInfo {
        total
      }
      nodes {
        ...AutomationFunctionFieldsForOps
        eventFunctions {
          functionInputMappings {
            key
            valuePath
          }
        }
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_OPS}
`;
interface RecommendedActionsProps extends SelectActionProps {
  searchString: string | null;
}

function RecommendedActions(props: RecommendedActionsProps) {
  const {
    contextArgs: { eventDefinition },
    onSelectAction
  } = props;
  const skip = !eventDefinition;
  const eventDefinitionFunctionResults = useQuery(EVENT_DEFINITION_FUNCTIONS, {
    variables: { id: eventDefinition && eventDefinition.id },
    skip
  });

  return eventDefinition ? (
    <QueryResultComponent
      {...eventDefinitionFunctionResults}
      parseNodes={(data) => data.eventDefinitionFunctions.nodes}
      skip={skip}
    >
      {(nodes) => {
        const automationFunctions: AutomationFunction[] | null = nodes;

        return automationFunctions && automationFunctions.length > 0 ? (
          <>
            {automationFunctions.map((automationFunction) => (
              <OpSectionListItem
                key={automationFunction.id}
                title={automationFunction.name}
                description={automationFunction.description}
                icon={<DopeIcon name="AWS" size={30} />}
                onClick={() => onSelectAction({ automationFunction })}
              />
            ))}
          </>
        ) : (
          <Typography.Text>{'No Recommended Actions found'}</Typography.Text>
        );
      }}
    </QueryResultComponent>
  ) : (
    <Typography.Text>{'Select Trigger to view Recommended Actions'}</Typography.Text>
  );
}

//  SEARCH ALL AUTOMATION FUNCTIONS
const AUTOMATION_FUNCTIONS_QUERY = gql`
  query AutomationFunctionsQuery(
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $search: String
    $labels: [String]
    $cloudVendor: [String]
    $isDisabled: Boolean
  ) {
    automationFunctions(
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      labels: $labels
      cloudVendor: $cloudVendor
      isDisabled: $isDisabled
    ) {
      pageInfo {
        total
        size
      }
      nodes {
        ...AutomationFunctionFieldsForOps
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_OPS}
`;

interface AutomationFunctionListProps {
  onSelectAction: OnSelectActionFunc;
  searchString: string | null;
  labels: string[] | null;
  cloudVendor: string[] | null;
  displayVendorTitle?: boolean | true;
}

function AutomationFunctionList(props: AutomationFunctionListProps) {
  const { onSelectAction, searchString, labels, cloudVendor, displayVendorTitle } = props;
  const pageSize = 10;
  const results = useQuery(AUTOMATION_FUNCTIONS_QUERY, {
    variables: {
      search: searchString,
      labels: labels,
      cloudVendor: cloudVendor,
      pageSize: pageSize,
      sortBy: 'name',
      sortDirection: 'ASC',
      isDisabled: false
    }
  });
  const { data, ...otherResults } = results;
  const { automationFunctions: query } = data || {};
  const { nodes, pageInfo } = query || {};
  const { total, size } = pageInfo || {};

  return (
    <QueryResultComponent {...otherResults} loadingCenterVertically={false} loadingHeight={200}>
      {() => {
        return nodes && nodes.length > 0 ? (
          <>
            {nodes.map((automationFunction) => (
              <OpSectionListItem
                key={automationFunction.id}
                title={automationFunction.name}
                description={automationFunction.description}
                icon={<CloudVendorIcon vendor={automationFunction.cloudVendor} />}
                onClick={() => onSelectAction({ automationFunction })}
                meta={
                  <CloudLabelUl>
                    {automationFunction.labels &&
                      automationFunction.labels.map((label, idx) =>
                        displayVendorTitle === false ? null : <li key={`${idx}_${label}`}>{label}</li>
                      )}
                  </CloudLabelUl>
                }
              />
            ))}
            {total > size && (
              <Typography.Text>More Actions are available, please refine your search above.</Typography.Text>
            )}
          </>
        ) : (
          <Typography.Text>No matching Actions were found.</Typography.Text>
        );
      }}
    </QueryResultComponent>
  );
}

export default OpActionSection;

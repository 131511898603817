import React from 'react';
import { Form, FormField } from 'components/ui/Form';
import { FormRenderProps } from 'components/ui/Form/Form';
import { TitleBarButton } from 'components/ui/NeoPage';
import * as Yup from 'yup';
import { Checkbox, Select, Input, Switch, Divider, message } from 'antd';
import { FormikActions } from 'formik';

import { FieldArray } from 'formik';

import NotificationRow from './NotificationRow';
import DopeIcon from 'components/ui/DopeIcon';

import { NotificationFromQuery } from '../../NotificationDetailPage';
import styled from 'styled-components';

import EVENT_SCOPE_OPTIONS from '../../fixtures/EventScopeOptions';
import EVENT_TYPE_OPTIONS, { EventType } from '../../fixtures/EventTypeOptions';
import { RECIPIENT_TYPE_DEFINITIONS } from 'components/recipients/common';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import ButtonWithDisabledTooltip from 'components/buttons/ButtonWithDisabledTooltip';
import { useAuthorizor } from 'components/app/Auth/Authorizor';

export const Root = styled.div`
  .main-panel-content {
    min-height: 100vh;

    flex-direction: row-reverse;
  }

  .info-panel {
    background-color: #fff;
  }

  .main-panel .info-panel-container {
    border-right: 1px solid #cacaca;
    border-left: none;
  }

  .add-notification-row {
    display: flex;
    justify-content: center;
  }
`;

enum NotificationScope {
  NONE = 'NONE',
  ISSUE = 'ISSUE',
  ISSUE_AND_ASSESSMENT = 'ISSUE_AND_ASSESSMENT',
  ASSESSMENT = 'ASSESSMENT',
  PROJECT = 'PROJECT',
  ACCOUNT = 'ACCOUNT'
}

export interface NotificationConfigurationComponentProps {
  name: string;
  formProps: FormRenderProps;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name of feed is required'),

  isEnabled: Yup.boolean().required('Value for enabled/disabled is required'),

  description: Yup.string(),

  eventTypes: Yup.array()
    .of(Yup.string())
    .min(1, 'Must select at least 1 event type')
    .required('Must select at least 1 event type'),

  scopeType: Yup.string().oneOf(EVENT_SCOPE_OPTIONS.map((i) => i.key)),

  scope: Yup.object().when('scopeType', (scopeType) => {
    if (scopeType === 'none') return Yup.mixed();

    const eventScopeOption = EVENT_SCOPE_OPTIONS.find((option) => option.key === scopeType) || null;

    return eventScopeOption && eventScopeOption.validationSchema ? eventScopeOption.validationSchema : Yup.mixed();
  }),

  recipients: Yup.array()
    .of(
      Yup.object({
        type: Yup.string().required('Select notification type'),
        isEnabled: Yup.boolean(),
        config: Yup.mixed().when('type', (type) => {
          const option = RECIPIENT_TYPE_DEFINITIONS.find((i) => i.key === type) || null;

          return option ? option.configValidationShape : Yup.mixed();
        })
      })
    )
    .min(1, 'Must have at least 1 notification')
});

interface NotificationFormValues {
  name: string;
  isEnabled: boolean;
  description?: string | null;
  eventTypes: EventType[];
  recipients: {
    type: string;
    config: any;
    isEnabled: boolean;
  }[];
  scopeType: NotificationScope;
  scope?: {
    assessmentId?: string | null;
    projectId?: string | null;
    accountId?: string | null;
    issueDefinitionId?: string | null;
  };
}

export interface NotificationFormOutput {
  name: string;
  description?: string | null;
  isEnabled: boolean;
  eventTypes: EventType[];
  scope: NotificationScope;
  assessmentId?: string;
  projectId?: string;
  accountId?: string;
  issueDefinitionId?: string;
}

interface NotificationFeedFormProps {
  initialNotificationFeed: NotificationFromQuery | null;
  onSave: (values: NotificationFormOutput) => Promise<void>;
}

function transformFormValuesToNotification(values: NotificationFormValues): NotificationFormOutput {
  const { scope = {}, scopeType, ...everythingElse } = values;
  const { assessmentId, projectId, accountId, issueDefinitionId } = scope;

  let notification: NotificationFormOutput = { ...everythingElse, scope: scopeType };

  // probably a cleaner way to do this?
  if (assessmentId) notification.assessmentId = assessmentId;
  if (projectId) notification.projectId = projectId;
  if (accountId) notification.accountId = accountId;
  if (issueDefinitionId) notification.issueDefinitionId = issueDefinitionId;

  return notification;
}
function transformNotificationToFormValues(values: NotificationFromQuery): NotificationFormValues {
  const { scope, assessmentId, projectId, accountId, issueDefinitionId, description, ...everythingElse } = values;

  return {
    ...everythingElse,
    description: description || undefined,
    scope: {
      assessmentId,
      accountId,
      projectId,
      issueDefinitionId
    },
    scopeType: scope
  };
}

function NotificationForm(props: NotificationFeedFormProps) {
  const { initialNotificationFeed, onSave } = props;

  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_NOTIFICATIONS, '*');

  return (
    <Root>
      <Form
        initialValues={
          initialNotificationFeed
            ? transformNotificationToFormValues(initialNotificationFeed)
            : {
                isEnabled: false,
                eventTypes: EVENT_TYPE_OPTIONS.map((i) => i.key), // default to all?
                scopeType: NotificationScope.NONE,
                recipients: []
              }
        }
        validationSchema={validationSchema}
        allowCleanSubmits={false}
        onSubmit={async (values: Partial<NotificationFormValues>, actions: FormikActions<any>) => {
          try {
            const notification = transformFormValuesToNotification(values as NotificationFormValues);

            await onSave(notification);
          } catch (e) {
            message.error('There was a problem saving this Notification');
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(formRenderProps) => {
          const { values, setFieldValue, setFieldTouched, canSubmit, isSubmitting } = formRenderProps;
          const { scopeType } = values;
          const eventScopeOption = EVENT_SCOPE_OPTIONS.find((option) => scopeType === option.key) || null;
          const ScopeIdSelector = (eventScopeOption && eventScopeOption.scopeIdSelector) || null;

          return (
            <div className="form-contents">
              <FormField name="name" label="Name">
                {({ name, value, handleChange, handleBlur }) => (
                  <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                )}
              </FormField>

              <FormField name="isEnabled" label="Enabled">
                {({ name, value }) => (
                  <Switch
                    checked={value}
                    onChange={(checked) => {
                      setFieldValue(name, checked);
                      setFieldTouched(name, true);
                    }}
                  />
                )}
              </FormField>

              {/* DESCRIPTION FIELD */}
              <FormField name="description" label="Description">
                {({ name, value, handleChange, handleBlur }) => (
                  <Input.TextArea rows={4} name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                )}
              </FormField>

              <Divider />
              <FormField name="eventTypes" label="Event Types">
                {({ name, value }) => {
                  return (
                    <Checkbox.Group
                      onChange={(checkedValues) => {
                        // setFieldTouched(name, true);
                        setFieldValue(name, checkedValues);
                      }}
                      value={value}
                      options={EVENT_TYPE_OPTIONS.map((i) => ({
                        label: i.label,
                        value: i.key
                      }))}
                    />
                  );
                }}
              </FormField>

              <Divider />

              <div className="scope">
                <FormField name="scopeType" label="Scope">
                  {({ name, value, handleBlur }) => {
                    EVENT_SCOPE_OPTIONS.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
                    return (
                      <Select
                        value={value}
                        onChange={(selected) => {
                          const selectedStr = selected ? selected.toString() : null;

                          if (selectedStr === NotificationScope.NONE || selectedStr !== value) {
                            setFieldValue('scopeId', undefined);
                          }

                          setFieldValue(name, selected);

                          if (selected !== value) setFieldValue('scope', {}); // reset scope Object
                        }}
                        onBlur={handleBlur}
                      >
                        {EVENT_SCOPE_OPTIONS.map((option) => {
                          return (
                            <Select.Option
                              key={option.key}
                              value={option.key} // this might be optional
                            >
                              {option.label}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    );
                  }}
                </FormField>

                {ScopeIdSelector && (
                  <ScopeIdSelector
                    value={values.scope}
                    onBlur={() => {
                      // deprecate these...
                      setFieldTouched('scope', true);
                    }}
                    onChange={(value) => {
                      setFieldValue('scope', value);
                    }}
                    formRenderProps={formRenderProps}
                  />
                )}

                <Divider />
              </div>

              <FormField name="recipients" label="Recipients">
                {({ name, value }) => {
                  return (
                    <FieldArray name={name}>
                      {({ remove, push }) => {
                        return (
                          <>
                            {value.map((recipient, idx) => {
                              const iName = `${name}.${idx}`;

                              return (
                                <NotificationRow
                                  key={iName}
                                  index={idx}
                                  notification={recipient} // prop should be recipient not notification.
                                  name={iName}
                                  formProps={formRenderProps}
                                  onRemove={() => remove(idx)}
                                />
                              );
                            })}

                            <div className="add-notification-row">
                              <TitleBarButton onClick={() => push({})} icon={<DopeIcon name="ADD" />}>
                                {'Add recipient'}
                              </TitleBarButton>
                            </div>
                          </>
                        );
                      }}
                    </FieldArray>
                  );
                }}
              </FormField>

              <div className="actions-row">
                <ButtonWithDisabledTooltip
                  disabled={!isAuthorized || !canSubmit || isSubmitting}
                  disabledMessage={authErrorMessage || ''}
                  type="primary"
                  htmlType="submit"
                  loading={isSubmitting}
                >
                  {isSubmitting ? 'Saving' : 'Save'}
                </ButtonWithDisabledTooltip>
              </div>
            </div>
          );
        }}
      </Form>
    </Root>
  );
}

export default NotificationForm;

import React from 'react';

import { ISSUE_STATUS_DEFINITIONS, IssueStatusDefinition } from 'constants/issue';
import { Select } from 'antd';

interface Props {
  value: string;
  onChange: (selectedValue: string) => void;
  classes?: {
    select?: string;
  };
}

function IssueStatusSelect(props: Props) {
  const { value, onChange, classes = {} } = props;

  return (
    <Select
      value={value}
      onChange={onChange}
      size="large"
      dropdownMatchSelectWidth={false}
      className={`issue-status-select ${classes.select || ''}`}
    >
      {Object.keys(ISSUE_STATUS_DEFINITIONS).map(key => {
        const def: IssueStatusDefinition = ISSUE_STATUS_DEFINITIONS[key];

        return (
          <Select.Option key={key} value={def.qsValue}>
            {def.label}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default IssueStatusSelect;

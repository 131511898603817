import { Icon, Spin, Typography } from 'antd';
import { GridCard } from 'components/ui/Card';
import React from 'react';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

interface Props {
  openResult;
  exemptedResult;
}

export default function OpenIssuesKpi(props: Props) {
  const { exemptedResult, openResult } = props;

  const { data: exemptedData, error: exemptedError, loading: exemptedLoading } = exemptedResult || {};
  const { data: openData, error: openError, loading: openLoading } = openResult || {};

  const { governanceStats: { exemptedIssueCount = null } = {} } = exemptedData || {};
  const { governanceStats: { openIssueCount = null } = {} } = openData || {};

  const loading = exemptedLoading || openLoading;

  const error = exemptedError || openError;

  return (
    <GridCard>
      <Typography.Paragraph className="kpi kpi2">
        {error ? (
          <Icon type="exclamation-circle" title={error.message} />
        ) : loading ? (
          <Title level={2} style={{ marginBottom: '8px' }}>
            <Spin />
          </Title>
        ) : (
          <Link to={`/issues?status=open`}>
            <Title level={2} style={{ marginBottom: '8px' }}>
              {openIssueCount?.toLocaleString()}
            </Title>
          </Link>
        )}
        <Link to={`/issues?status=open`}>
          <Text>Open Issues</Text>
        </Link>
        <br />
        <Link to={`/issues?status=exempt`}>
          <Text type="secondary">
            {exemptedIssueCount ? <>{exemptedIssueCount.toLocaleString()} Exempted</> : <>&nbsp;</>}
          </Text>
        </Link>
      </Typography.Paragraph>
    </GridCard>
  );
}

import React from 'react';
import styled from 'styled-components';

const Root = styled.div``;

const Label = styled.div`
  > span {
    font-weight: 700;
  }
`;

export interface Props {
  resource: any; // ... we don't have a type for the return from all the different graphql queries.
}

function ResourceColumn(props: Props) {
  const { dops_name, dops_resource_id } = props.resource;

  return (
    <Root>
      {dops_name && (
        <Label>
          <span>Name: </span>
          {dops_name}
        </Label>
      )}

      {dops_resource_id && dops_resource_id !== dops_name && (
        <Label>
          <span>ID: </span>
          {dops_resource_id}
        </Label>
      )}
    </Root>
  );
}

export default ResourceColumn;

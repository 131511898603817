import styled from 'styled-components';

export const Root = styled.div`
  background-color: ${(p) => p.theme.grey50};
  border-bottom: 1px solid ${(p) => p.theme.border};

  .wrapper {
    height: 55px;
    display: flex;
    align-items: center;
    margin-left: 56px;
    padding-left: 0;
    transition: margin-left 0.5s, padding-left 0.5s;

    .page-icon {
      margin: 0 1rem 0 0;
    }

    h1 {
      font-size: 22px;
      line-height: 1;
      margin: 0 0.5rem 0 0;
    }

    .children {
      margin-left: 2rem;
    }
  }

  .sidenav-permanent & .wrapper {
    margin-left: 0;
    padding-left: 1rem;
  }
`;

import React from 'react';
import styled from 'styled-components';

import { Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';

const Root = styled.div`
  width: 100%;

  .ant-table-thead {
    background-color: ${p => p.theme.grey100};
    background-color: #e4e4e4;
  }

  tr.ant-table-row:nth-child(even) {
    background-color: #fff;
  }
`;

interface Props<T> {
  antTableProps: TableProps<T>;
}

function Table<T>(props: Props<T>) {
  const { antTableProps } = props;
  return (
    <Root>
      <AntTable {...antTableProps} />
    </Root>
  );
}

export default Table;

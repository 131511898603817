import React, { useEffect, useState } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { Select } from 'antd';
import { FilterTypeProps } from '../FilterListing/FilterListing';
import {
  eventSourceAwsCloudWatch,
  eventSourceAwsConfig,
  eventSourceAwsSecurityHub,
  eventSourceDisruptOpsGovernance
} from 'constants/eventSources';
import { awsAsffResourceTypes, awsCloudFormationResourceTypes } from 'constants/resourceTypes';

const awsAsffSources = [eventSourceAwsSecurityHub],
  awsCloudFormationSources = [eventSourceAwsCloudWatch, eventSourceAwsConfig, eventSourceDisruptOpsGovernance];

function ResourceTypeEditor(props: FilterTypeProps) {
  const { values, filterType } = props,
    eventSourceId = values.eventSourceId,
    [availableResourceTypes, setAvailableResourceTypes] = useState<any | null>([]),
    [softEdit, setSoftEdit] = useState<SoftEdit | null>({
      key: filterType.valueKey,
      value: values[filterType.valueKey]
    });

  function onChange(update) {
    setSoftEdit({
      key: filterType.valueKey,
      value: update
    });
  }

  useEffect(() => {
    let workingList: any[] = [];

    if (!eventSourceId || awsAsffSources.indexOf(eventSourceId) !== -1) {
      workingList.push(...awsAsffResourceTypes);
    }

    if (!eventSourceId || awsCloudFormationSources.indexOf(eventSourceId) !== -1) {
      workingList.push(...awsCloudFormationResourceTypes);
    }

    setAvailableResourceTypes(workingList);
  }, [eventSourceId]);

  const editor = (
    <Select
      onChange={onChange}
      mode="tags"
      value={softEdit?.value}
      filterOption={(inputValue, option) => {
        if (!option || !option.props || !option.props.title) {
          return true;
        }
        return new RegExp(inputValue, 'i').test(option.props.title);
      }}
    >
      {availableResourceTypes.map((resourceType) => (
        <Select.Option key={resourceType} value={resourceType} title={resourceType}>
          {resourceType}
        </Select.Option>
      ))}
    </Select>
  );

  return <FilterRow {...props} editor={editor} softEdit={softEdit} />;
}

export default ResourceTypeEditor;

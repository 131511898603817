import React, { useState, useRef, useEffect } from 'react';
import { FilterInputProps } from '../SearchBar';
import { InputBoxDiv } from './InputBox.styles';

let debounceTimer;

function InputBox(props: FilterInputProps) {
  const { filter, onChange, searchValues, acceptChanges } = props,
    inputEl = useRef<HTMLInputElement>(null),
    [value, setValue] = useState<string>(searchValues[filter.key] || '');

  function textChange(update) {
    setValue(update);
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      onChange(filter.key, update);
    }, 300);
  }

  function onKeyDown(event) {
    if (event.keyCode === 13) {
      acceptChanges && acceptChanges();
    }
  }

  useEffect(() => {
    inputEl?.current?.focus();
  }, []);

  return (
    <InputBoxDiv>
      <input
        ref={inputEl}
        value={value}
        type="text"
        onKeyDown={onKeyDown}
        onChange={(e) => textChange(e.target.value)}
      />
    </InputBoxDiv>
  );
}

export default InputBox;

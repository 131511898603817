import React from 'react';
import { RouteComponentProps } from 'react-router';

import gql from 'graphql-tag';
import { CloudAccount } from './CloudAccountNewForm';
import NeoPage, { TitleBar, CenteredContainer } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import { useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';

import CloudAccountNewForm from './CloudAccountNewForm';

interface Props extends RouteComponentProps {}

export const CLOUD_ACCOUNT_QUERY = gql`
  query CloudAccount($id: String!) {
    cloudAccount(id: $id) @rest(path: "/api/v2/accounts/{args.id}", type: "ClientAccount") {
      account_id
      assumerole_account_id
      provision_url
      name
      updated
      nickname
      provider
      project_id
    }
  }
`;

function CloudAccountNew(props: Props) {
  const {
    location: { search }
  } = props;

  const searchParams = new URLSearchParams(search);
  const idFromSearchParams = searchParams.has('id') ? searchParams.get('id') : null;
  const variables = { id: idFromSearchParams };

  const { loading, error, data } = useQuery(CLOUD_ACCOUNT_QUERY, {
    variables,
    skip: idFromSearchParams ? false : true
  });

  return (
    <NeoPage
      titleBar={
        <TitleBar
          icon={<DopeIcon name="CLOUD_ACCOUNT" size={20} />}
          sectionTitle="Cloud Accounts"
          sectionTitleLinkTo="/cloud-accounts"
          title="Add Cloud Account"
        />
      }
    >
      <CenteredContainer size="lg" leftAlign>
        <QueryResult loading={loading} data={data} error={error}>
          {() => {
            const existingCloudAccount: CloudAccount | null = (data && data.cloudAccount) || null;

            return <CloudAccountNewForm {...props} existingCloudAccount={existingCloudAccount} />;
          }}
        </QueryResult>
      </CenteredContainer>
    </NeoPage>
  );
}

export default CloudAccountNew;

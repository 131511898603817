import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';
import { ConfiguredAssessment } from 'typings';

import IssueList from 'components/issue/IssueList';

interface Props extends RouteComponentProps<{ assessmentId: string }> {
  assessment: ConfiguredAssessment;
}

function AssessmentIssuesTab(props: Props) {
  return (
    <Route
      children={routeProps => (
        <IssueList
          {...routeProps}
          queryVariables={{
            assessmentId: props.assessment.id
          }}
        />
      )}
    />
  );
}

export default AssessmentIssuesTab;

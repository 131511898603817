import React from 'react';
import gql from 'graphql-tag';
import Paginator from 'components/app/Paginator';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CenteredContainer } from 'components/ui/NeoPage';
import { PageInfo, ActionResult, FilterDefinition, Issue } from 'typings';
import { Query } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import { ColumnProps } from 'antd/lib/table';
import Table from 'components/ui/Table';
import DateTime from 'components/ui/DateTime';
import FilterBar from 'components/app/FilterBar';
import { SearchFilter } from 'components/app/FilterBar/filters';
import DopeIcon from 'components/ui/DopeIcon';
import { Checkbox, Popover } from 'antd';
import { PopoverMenuItem } from 'components/ui/Popover';
import { Props as PopoverMenuItemProps } from 'components/ui/Popover/PopoverMenuItem';
import ActivityDetail from './ActivityDetail';
import PushFetch from 'components/util/Push/PushFetch';

const Root = styled.div`
  .action-result-status {
    &.success {
      color: ${(p) => p.theme.success};
    }
    &.error {
      color: ${(p) => p.theme.error};
    }
  }

  .filter-options {
    padding-bottom: 8px;
    display: flex;
    justify-content: flex-end;d
  }

  .guardrail-name {
    &.archived {
      span {
        color: ${(p) => p.theme.grey300};
      }
    }
  }

`;

const SORT_BY_MAP = {
  name: 'name',
  createdAt: 'created_at'
};

const SORT_ORDER_MAP = {
  ascend: 'ASC',
  descend: 'DESC'
};

const STATUS_OPTIONS = [
  {
    key: 'SUCCESS',
    label: 'Success'
  },
  {
    key: 'ERROR',
    label: 'Error'
  }
];

const SHOW_ARCHIVED_GUARDRAIL_ACTIVITY = 'showArchivedGuardrailActivity';
const FILTER_DEFINITIONS: FilterDefinition[] = [
  {
    name: 'status',
    label: 'Status',
    qsKey: 'status',
    many: false,
    variableName: () => 'status', //??
    options: STATUS_OPTIONS
  },
  {
    name: 'guardrailId',
    label: 'Guardrail',
    qsKey: 'guardrailId',
    many: false,
    filterBrickReadView: (props) => {
      const { value } = props;

      return (
        <Query
          query={gql`
            query GuardrailNameForActivityList($id: [String]) {
              guardrails(id: $id) {
                nodes {
                  id
                  name
                }
              }
            }
          `}
          variables={{ id: value }}
          fetchPolicy={'cache-first'}
        >
          {({ loading, data, error }) => {
            if (!data || error) return value;
            const guardrail: {
              id: string;
              name?: string | null;
            } = data.guardrails.nodes[0];

            if (!guardrail) return value; //?

            return guardrail.name || 'Untitled Guardrail';
          }}
        </Query>
      );
    }
  },
  {
    name: 'automationFunctionId',
    label: 'Action', //??
    qsKey: 'automationFunctionId',
    many: false,
    filterBrickReadView: (props) => {
      const { value } = props;

      return (
        <Query
          query={gql`
            query AutomationFunctionForActivityList($id: [String]) {
              automationFunctions(id: $id) {
                nodes {
                  id
                  name
                }
              }
            }
          `}
          variables={{ id: [value] }}
          fetchPolicy={'cache-first'}
        >
          {({ loading, data, error }) => {
            if (!data || error) return value;
            const aFunc: {
              id: string;
              name?: string | null;
            } = data.automationFunctions.nodes[0];

            if (!aFunc) return value; //?

            return aFunc.name || '';
          }}
        </Query>
      );
    }
  },
  {
    name: 'actorId',
    label: 'Action', //??
    qsKey: 'actorId',
    many: false,
    filterBrickReadView: (props) => {
      const { value } = props;

      return (
        <Query
          query={gql`
            query FunctionById($id: ID) {
              functions(id: $id) {
                nodes {
                  id
                  name
                }
              }
            }
          `}
          variables={{ id: value }}
          fetchPolicy={'cache-first'}
        >
          {({ loading, data, error }) => {
            if (!data || error) return value;
            const func: {
              id: string;
              name?: string | null;
            } = data.functions.nodes[0];

            if (!func) return value; //?

            return func.name || '';
          }}
        </Query>
      );
    }
  },
  {
    label: 'Issue',
    name: 'issueId',
    qsKey: 'issueId',
    many: false
  },
  {
    clientFiltered: false, // ??
    qsKey: SHOW_ARCHIVED_GUARDRAIL_ACTIVITY,
    name: SHOW_ARCHIVED_GUARDRAIL_ACTIVITY, // not sure how this is different than qs key.
    label: 'Show Archived Guardrail Activity', // this shouldn't show up?
    many: false
  }
];

export const ACTION_RESULT_FIELDS = gql`
  fragment ActivityActionResultFields on ActionResult {
    id
    status
    issueId
    issue {
      id
      name
      accountId
      awsAccountId
      region
      assessment {
        id
        name
      }
    }
    status
    issueWasResolved
    detail # this contains taskName
    createdAt
    createdBy
    createdByUsername
    guardrailId
    guardrail {
      id
      name
      archivedAt
    }
    actorId
    actor {
      id
      name
    }
    automationFunctionId
    triggerType
  }
`;

const ACTION_RESULTS_QUERY = gql`
  query ActionResults(
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $status: ActionResultStatus
    $guardrailId: [String]
    $issueId: [String]
    $automationFunctionId: [String]
    $actorId: [String]
    $guardrailIsArchived: Boolean
  ) {
    actionResults(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      status: $status
      guardrailId: $guardrailId
      issueId: $issueId
      automationFunctionId: $automationFunctionId
      actorId: $actorId
      guardrailIsArchived: $guardrailIsArchived
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        ...ActivityActionResultFields
      }
    }
  }
  ${ACTION_RESULT_FIELDS}
`;

interface Props extends RouteComponentProps {}

function ActivityList(props: Props) {
  const { history, location } = props;

  return (
    <Paginator pageSize={100} filters={FILTER_DEFINITIONS}>
      {(paginatorRenderProps) => {
        const {
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          updateSearchParams,
          search,
          filterValues,
          pushFilters,
          updateFilter
        } = paginatorRenderProps;

        const showArchivedGuardrailActivity = filterValues[SHOW_ARCHIVED_GUARDRAIL_ACTIVITY] === 'true';
        const queryVariables = {
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          status: filterValues.status || null,
          guardrailId: filterValues.guardrailId ? [filterValues.guardrailId] : null,
          issueId: filterValues.issueId ? [filterValues.issueId] : null,
          actorId: filterValues.actorId ? [filterValues.actorId] : null,
          automationFunctionId: filterValues.automationFunctionId ? [filterValues.automationFunctionId] : null,
          guardrailIsArchived: showArchivedGuardrailActivity ? undefined : false
        };

        return (
          <Root>
            <CenteredContainer>
              <Query query={ACTION_RESULTS_QUERY} variables={queryVariables}>
                {({ loading, data, error, refetch }) => (
                  <QueryResult loading={loading} data={data} error={error}>
                    {() => {
                      const actionResults: ActionResult[] = data.actionResults.nodes;
                      const pageInfo: PageInfo = data.actionResults.pageInfo;

                      const columns: ColumnProps<ActionResult>[] = [
                        {
                          title: 'Date/Time',
                          key: 'createdAt',
                          dataIndex: 'createdAt',
                          sorter: true,
                          render: (createdAt, actionResult: ActionResult) => {
                            return (
                              <Link to={`/activity/${actionResult.id}`}>
                                {createdAt ? <DateTime dateTime={createdAt} /> : '--'}
                              </Link>
                            );
                          }
                        },
                        {
                          title: 'Status',
                          key: 'status',
                          dataIndex: 'status',
                          render: (status) => (
                            <div className={`action-result-status ${status.toLowerCase()}`}>{status}</div>
                          )
                        },
                        {
                          title: 'Trigger',
                          key: 'triggerType',
                          dataIndex: 'triggerType',
                          render: (triggerType, actionResult: ActionResult) => {
                            const { guardrail, issue, createdByUsername } = actionResult;
                            const { assessment } = issue || {};
                            if (triggerType === 'GUARDRAIL' && guardrail) {
                              return (
                                <ColumnMoreVertOptions
                                  content={
                                    <div className={`guardrail-name ${guardrail.archivedAt ? 'archived' : ''}`}>
                                      Guardrail: {guardrail.archivedAt && '(ARCHIVED) - '}
                                      <span>{guardrail.name || 'Untitled'}</span>
                                    </div>
                                  }
                                  menuItems={[
                                    {
                                      label: 'View Guardrail',
                                      link: `/guardrails/details/${guardrail.id}`
                                    },
                                    {
                                      label: 'Filter Activity by this Guardrail',
                                      onClick: () => {
                                        updateFilter('guardrailId', guardrail.id);
                                      }
                                    }
                                  ]}
                                />
                              );
                            } else if ((triggerType === 'USER' && assessment) || assessment) {
                              return (
                                <ColumnMoreVertOptions
                                  content={
                                    <>
                                      {createdByUsername || 'User'} from Detector: {assessment.name || 'Untitled'}
                                    </>
                                  }
                                  menuItems={[
                                    {
                                      label: 'View Detector',
                                      link: `/detectors/${assessment.id}`
                                    },
                                    {
                                      label: 'Filter Activity by this Detector',
                                      onClick: () => {
                                        updateFilter('assessmentId', assessment.id);
                                      }
                                    }
                                  ]}
                                />
                              );
                            } else if (triggerType === 'USER' && createdByUsername) {
                              return createdByUsername;
                            } else if (triggerType) {
                              return triggerType;
                            } else {
                              return <>--</>;
                            }
                          }
                        },
                        {
                          title: 'Action',
                          key: 'action',
                          dataIndex: 'id',
                          render: (_id, actionResult) => {
                            const { actor, detail } = actionResult;

                            if (actor && actor.name) {
                              return (
                                <ColumnMoreVertOptions
                                  content={actor.name}
                                  menuItems={[
                                    {
                                      label: 'Filter Activity by this Action',
                                      onClick: () => updateFilter('actorId', actor.id)
                                    }
                                  ]}
                                />
                              );
                            }

                            if (detail && detail.taskName && actionResult && actionResult.automationFunctionId) {
                              return (
                                <ColumnMoreVertOptions
                                  content={detail.taskName}
                                  menuItems={[
                                    {
                                      label: 'Filter Activity by this Action',
                                      onClick: () =>
                                        updateFilter(
                                          'automationFunctionId',
                                          actionResult.automationFunctionId ? actionResult.automationFunctionId : ''
                                        )
                                    }
                                  ]}
                                />
                              );
                            }

                            return actionResult.actor ? actionResult.actor.name || '--' : '--';
                          }
                        },
                        {
                          title: 'Issue',
                          key: 'issue',
                          dataIndex: 'issue',
                          render: (issue: Issue) => {
                            if (!issue) return 'N/A';
                            return (
                              <ColumnMoreVertOptions
                                content={issue.name} // replace with issue name some how
                                menuItems={[
                                  {
                                    label: 'View Issue',
                                    link: `/issue/${issue.id}`
                                  },
                                  {
                                    label: 'Filter Activity by this Issue',
                                    onClick: () => {
                                      updateFilter('issueId', issue.id);
                                    }
                                  }
                                ]}
                              />
                            );
                          }
                        }
                      ];

                      return (
                        <PushFetch
                          eventType="actionTaken"
                          refetchTest={(envelope: any) => {
                            if (!envelope || !envelope.message || !envelope.message.actionResult) return false;
                            const { actionResult } = envelope.message;

                            if (filterValues.status && filterValues.status !== actionResult.status) return false;
                            if (filterValues.guardrailId && filterValues.guardrailId !== actionResult.guardrailId)
                              return false;
                            if (filterValues.issueId && filterValues.issueId !== actionResult.issueId) return false;
                            if (filterValues.actorId && filterValues.actorId !== actionResult.actorId) return false;
                            if (
                              filterValues.automationFunctionId &&
                              filterValues.automationFunctionId !== actionResult.automationFunctionId
                            )
                              return false;
                            if (
                              filterValues.guardrailIsArchived &&
                              filterValues.guardrailIsArchived !== actionResult.guardrailIsArchived
                            )
                              return false;
                            return true;
                          }}
                          refetchFn={refetch}
                        >
                          <div className="filter-options">
                            <Checkbox
                              checked={filterValues[SHOW_ARCHIVED_GUARDRAIL_ACTIVITY] !== 'true'}
                              children={'Hide archived Guardrail activity'}
                              onChange={(e) => {
                                const checked = e.target.checked;

                                updateFilter(SHOW_ARCHIVED_GUARDRAIL_ACTIVITY, checked ? 'false' : 'true');
                              }}
                            />
                          </div>
                          <FilterBar
                            history={history}
                            location={location}
                            search={search}
                            filters={FILTER_DEFINITIONS}
                            filterValues={filterValues}
                            onFilterChange={pushFilters}
                          >
                            {(filterBarRenderProps) => {
                              const { search, handleSearchChange } = filterBarRenderProps;

                              return (
                                <SearchFilter
                                  placeholder="Filter Activity"
                                  size="large"
                                  filters={FILTER_DEFINITIONS}
                                  search={search}
                                  onSearchChange={handleSearchChange}
                                />
                              );
                            }}
                          </FilterBar>

                          <Table
                            antTableProps={{
                              rowKey: 'id',
                              size: 'small',
                              columns,
                              dataSource: actionResults,
                              loading: loading,
                              locale: {
                                emptyText: 'No action results found'
                              },
                              onChange: (pagination, filters, sorter, a) => {
                                updateSearchParams({
                                  sortBy: SORT_BY_MAP[sorter.columnKey] || 'updated_at',
                                  sortDirection: SORT_ORDER_MAP[sorter.order] || SORT_ORDER_MAP.descend,
                                  page: pagination.current || 1
                                });
                              },
                              pagination: {
                                pageSize,
                                current: pageInfo && pageInfo.current,
                                total: pageInfo && pageInfo.total,
                                onChange: (pageNumber) => {
                                  updateSearchParams({
                                    page: pageNumber
                                  });
                                }
                              },
                              expandedRowRender: (actionResult) => {
                                return <ActivityDetail actionResult={actionResult} />;
                              }
                            }}
                          />
                        </PushFetch>
                      );
                    }}
                  </QueryResult>
                )}
              </Query>
            </CenteredContainer>
          </Root>
        );
      }}
    </Paginator>
  );
}

const ColumnMoreVertOptionsRoot = styled.div`
  display: flex;
  padding-right: 20px;

  .column-with-menu-content {
    margin-right: 16px;
  }
  .column-with-menu-icon {
    display: block;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover .column-with-menu-icon {
    opacity: 1;
  }
`;

interface ColumnMoreVertOptionsProps {
  content: string | React.ReactNode;
  menuItems: PopoverMenuItemProps[];
}

function ColumnMoreVertOptions(props: ColumnMoreVertOptionsProps) {
  const { content, menuItems } = props;

  return (
    <ColumnMoreVertOptionsRoot className="column-with-menu">
      <div className="column-with-menu-content">{content}</div>
      <Popover
        content={
          <div>
            {menuItems.map((item, idx) => {
              return <PopoverMenuItem key={idx} {...item} />;
            })}
          </div>
        }
        placement="left"
        trigger="click"
      >
        <div className="column-with-menu-icon">
          <DopeIcon name="MORE_VERTICAL" size={14} />
        </div>
      </Popover>
    </ColumnMoreVertOptionsRoot>
  );
}

export default ActivityList;

import React from 'react';
import { Select } from 'antd';

interface EventTypeFilterProps {
  value?: string | null;
  onChange: (value: string) => void;
  minWidth?: number;
}

function EventTypeFilter(props: EventTypeFilterProps) {
  const { value, onChange, minWidth = 200 } = props;

  return (
    <Select
      value={value || undefined}
      style={{ minWidth: `${minWidth}px`, maxWidth: '500px' }}
      onChange={onChange}
      dropdownMatchSelectWidth={false}
      placeholder={'Select Event Type'}
      maxTagCount={2}
      maxTagTextLength={48}
      maxTagPlaceholder={(extraValues) => {
        return <>+&nbsp;{extraValues.length}</>;
      }}
    >
      <Select.Option key="op" value={'op'}>
        {'Triggered Events'}
      </Select.Option>
      <Select.Option key="all" value={'all'}>
        {'All Events'}
      </Select.Option>
    </Select>
  );
}

export default EventTypeFilter;

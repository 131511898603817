import { Icon, Spin, Typography } from 'antd';
import { GridCard } from 'components/ui/Card';
import React from 'react';
import { Link } from 'react-router-dom';

const { Text, Title } = Typography;

interface Props {
  result;
}

export default function ResolvedIssuesKpi(props: Props) {
  const { result } = props;
  const { data, error, loading } = result;
  const { governanceStats: { resolvedIssueCount = {} } = {} } = data || {};

  return (
    <GridCard>
      <Link to={`/issues?status=resolved`}>
        <Typography.Paragraph className="kpi kpi1">
          {error ? (
            <Icon type="exclamation-circle" title={error.message} />
          ) : loading ? (
            <Title level={2} style={{ marginBottom: '8px' }}>
              <Spin />
            </Title>
          ) : (
            <Title level={2} style={{ marginBottom: '8px' }}>
              {resolvedIssueCount.toLocaleString()}
            </Title>
          )}
          <Text>Resolved Issues</Text>
          <br />
          <Text type="secondary">&nbsp;</Text>
        </Typography.Paragraph>
      </Link>
    </GridCard>
  );
}

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Sidenav, TitleBar } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import { UpdateProfilePage, UpdatePasswordPage, AboutPage } from './pages';

import NeoPage from 'components/ui/NeoPage';

interface Props extends RouteComponentProps<any> {}

function SettingsScreen(props: Props) {
  return (
    <NeoPage
      titleBar={
        <TitleBar
          sectionTitle={'Settings'}
          sectionTitleLinkTo="/settings"
          icon={<DopeIcon size="22" name="SETTINGS" />}
        />
      }
      sidenav={
        <Sidenav
          width={140}
          navConfig={[
            { label: 'Your profile', to: '/settings/profile' },
            { label: 'Update password', to: '/settings/update-password' },
            { label: 'About', to: '/settings/about' }
          ]}
        />
      }
    >
      <Switch>
        <Route path="/settings/profile" component={UpdateProfilePage} />
        <Route path="/settings/update-password" component={UpdatePasswordPage} />
        <Route path="/settings/about" component={AboutPage} />
        <Route
          path="/settings"
          render={() => {
            return <Redirect to="/settings/profile" />;
          }}
        />
      </Switch>
    </NeoPage>
  );
}

export default SettingsScreen;

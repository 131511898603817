import styled from 'styled-components';

export const Root = styled.div`
  .op-name {
    display: block;
    font-weight: 600;
  }

  .op-name.is-detail-panel {
    font-size: 20px;
  }

  .disabled {
    font-style: italic;
    color: #666;
  }
`;

import { TeamMember } from 'typings';

export function combineFullName(record: TeamMember) {
  let name = '';

  if (record.first_name) name += record.first_name;
  if (record.last_name) name += ` ${record.last_name}`;

  return name;
}

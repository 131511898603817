export enum EventType {
  issueCreated = 'issueCreated',
  actionTaken = 'actionTaken',
  issuesCreated = 'issuesCreated',
  issueFound = 'issueFound'
}

export interface EventTypeOption {
  label: string;
  key: EventType;
}

const EVENT_TYPE_OPTIONS: EventTypeOption[] = [
  {
    label: 'Action Taken',
    key: EventType.actionTaken
  },
  {
    label: 'Issues Created',
    key: EventType.issuesCreated
  },
  {
    label: 'Issue Created',
    key: EventType.issueCreated
  },
  {
    label: 'Issue Found',
    key: EventType.issueFound
  }
];

export default EVENT_TYPE_OPTIONS;

import React from 'react';
import { message } from 'antd';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core';
import DeleteButton from 'components/buttons/DeleteButton';
import { CloudAccount } from 'typings';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { PureQueryOptions } from 'apollo-client';

const DELETE_ACCOUNT_MUTATION = gql`
  mutation DeleteAccount($id: String!) {
    deleteAccount(id: $id) @rest(path: "/api/v2/accounts/{args.id}", method: "DELETE")
  }
`;

interface Props {
  cloudAccount: CloudAccount;
  onDelete?: () => void;
  refetchQueries?: PureQueryOptions[];
  children?: string;
}

function DeleteCloudAccountButton({ cloudAccount, onDelete, refetchQueries, children, ...props }: Props) {
  const { isAuthorized, message: authorizationErrorMessage } = useAuthorizor(Permissions.MODIFY_ACCOUNTS, [
    cloudAccount.project_id || '*'
  ]);

  const [deleteCloudAccount, { loading }] = useMutation(DELETE_ACCOUNT_MUTATION, {
    variables: { id: cloudAccount.account_id },
    refetchQueries: refetchQueries,
    awaitRefetchQueries: true,
    onCompleted: (data: any) => {
      message.success('Account deleted');
      onDelete && onDelete();
    },
    onError: (error) => {
      message.error('Error deleting account');
    }
  });

  return (
    <DeleteButton
      {...props}
      id={cloudAccount.account_id}
      onDelete={() => deleteCloudAccount()}
      loading={loading}
      disabled={!isAuthorized}
      disabledMessage={authorizationErrorMessage || ''}
      confirmMessage={`Are you sure you want to delete this account (${cloudAccount.nickname})?`}
      mode="default"
    >
      {children ? children : undefined}
    </DeleteButton>
  );
}

export default DeleteCloudAccountButton;

import React, { Component, ReactNode } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import uuidv1 from 'uuid/v1';
import union from 'just-union';
import memoize from 'fast-memoize';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

import { getIdentity, signOut } from 'services/auth';
import { User, Project, EffectivePermission } from 'typings';
import { combineFullName } from 'utilities/user';
import { INTERCOM_APP_ID } from 'constants/runtimeConfig';

import QueryResult from 'components/util/QueryResult';
import AuthContext, { AuthContextProps, DevSettings } from './AuthContext';

const USER_CONTEXT_QUERY = gql`
  query UserIdentity {
    identity @client {
      user_id
      user_pool_id
      email
    }
    client {
      settings {
        complianceEnabled
      }
    }
    # Temp changing this to hit v1 endpoint as graphql resolvers don't return permission data...
    user @rest(type: "User", path: "/user") {
      id
      cognito_client_id
      cognito_user_pool_id
      email
      username
      first_name
      last_name
      client {
        client_id
        created
        name
        root_project_id
      }
      created
      updated

      # permission nodes

      authorized_accounts {
        account_id
      }
      effective_permissions {
        id
        name
        projects
      }
    }

    projects {
      items {
        project_id
        name
        description
        parent_id
      }
    }
  }
`;

interface Props {
  children: (authContext: AuthContextProps) => ReactNode;
}

interface State {
  sessionId: string;
  userId: string | null;
  devSettings: DevSettings;
}

class Authenticator extends Component<Props, State> {
  constructor(p: Props) {
    super(p);

    this.state = {
      sessionId: uuidv1(),
      userId: null,
      devSettings: this.parseDevSettingsFromLocalStorage()
    };

    this.persistDevSettingsToLocalStorage = this.persistDevSettingsToLocalStorage.bind(this);
    this.handleContextUpdate = this.handleContextUpdate.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  async componentDidMount() {
    this.setState({
      devSettings: this.parseDevSettingsFromLocalStorage()
    });
  }

  async componentDidUpdate() {
    const { userId } = this.state;
    const identity = await getIdentity();

    if (identity && userId !== identity.user_id) {
      this.setState({
        userId: identity.user_id
      });
    }
  }

  render() {
    const { children } = this.props;

    return (
      <Query query={USER_CONTEXT_QUERY} fetchPolicy="network-only" onCompleted={this.handleContextUpdate}>
        {(result) => {
          const { loading, data, refetch } = result;
          // console.log({ data });
          return (
            <QueryResult entityName="Identity" loading={loading} data={data} loadingCenterVertically>
              {() => {
                const identity = data && data.identity && data.identity.user_id ? data.identity : null;
                const me = data && data.user ? data.user : undefined;
                const projects: Project[] | null = (data && data.projects && data.projects.items) || null;
                if (me) me.client.settings = data.client?.settings;
                const getEffectivePermissions = memoize(
                  (permissions: Permissions | Permissions[]): EffectivePermission[] => {
                    return me.effective_permissions.filter((effectivePermission) =>
                      permissions.includes(effectivePermission.id)
                    );
                  }
                );

                const checkEffectivePermissionsAgainstProjectIds = memoize(
                  (permissions: Permissions | Permissions[], requiredProjectIds: string[]): string[] => {
                    permissions = Array.isArray(permissions) ? permissions : [permissions];

                    if (!permissions.length) return [];

                    const effectivePermissions = getEffectivePermissions(permissions);

                    if (!effectivePermissions.length) return requiredProjectIds;

                    const failedProjectIds = effectivePermissions.reduce((acc: string[], effectivePermission) => {
                      const failedProjectIds = requiredProjectIds.reduce(
                        (failedProjectIds: string[], requiredProjectId) => {
                          if (!effectivePermission.projects) return [...failedProjectIds, requiredProjectId];

                          if (effectivePermission.projects.includes('*')) return failedProjectIds; // user has global permission

                          // projectId is not in effective permission
                          if (!effectivePermission.projects.includes(requiredProjectId))
                            return [...failedProjectIds, requiredProjectId];

                          return failedProjectIds;
                        },
                        []
                      );

                      return union(acc, failedProjectIds);
                    }, []);

                    return failedProjectIds;
                  }
                );

                const isDisruptOpsUser = identity && identity.email && identity.email.includes('@disruptops.com');

                // Find the user's root project, which might not be the same as the client's root project.
                // The user, based on the groups they belong to, may not have permission to view the client's root project.
                // This really should be taken care of on the backend, and returned with the user identity query.
                // Anyway, find the first project with a null parent id (client root project),
                // or the first project that has a parent id that's not in the projects list (user root project).
                const userRootProject = projects?.find((p) => {
                  return p.parent_id === null || !projects?.find((c) => c.project_id === p.parent_id);
                });

                const authContext: AuthContextProps = {
                  identity,
                  isDisruptOpsUser,
                  persistDevSettingsToLocalStorage: this.persistDevSettingsToLocalStorage,
                  devSettings: this.state.devSettings,
                  me,
                  myUserGroups: [],
                  refetch: refetch,
                  signOut: this.signOut,
                  lookupProject: (id: string) => (projects ? projects.find((p) => p.project_id === id) : undefined),
                  rootProjectId: me?.client?.root_project_id,
                  userRootProjectId: userRootProject?.project_id,
                  getEffectivePermissions,
                  checkEffectivePermissionsAgainstProjectIds
                };

                return <AuthContext.Provider value={authContext}>{children(authContext)}</AuthContext.Provider>;
              }}
            </QueryResult>
          );
        }}
      </Query>
    );
  }

  parseDevSettingsFromLocalStorage() {
    const localStorageValue = localStorage.getItem('DEV_SETTINGS');

    if (localStorageValue === null) {
      return {
        isFeatureFlaggingDisabled: false,
        isSSEDebuggingEnabled: false
      };
    }

    try {
      const parsedValue = JSON.parse(localStorageValue);
      return parsedValue;
    } catch (err) {
      return {
        isFeatureFlaggingDisabled: false,
        isSSEDebuggingEnabled: false
      };
    }
  }

  persistDevSettingsToLocalStorage(devSettings: DevSettings) {
    localStorage.setItem('DEV_SETTINGS', JSON.stringify(devSettings));
    this.setState({ devSettings });
  }

  handleContextUpdate(result: any) {
    const { userId } = this.state;

    if (!result) return;

    const user = result.user;

    if (!user || !user.id) return;

    if (userId !== user.id) {
      this.identifyUserWithIntercom(user);
      this.identifyUserWithErrorTracking(user);
    }
  }

  getIntercom() {
    const { Intercom } = window as any;
    return Intercom;
  }

  identifyUserWithIntercom(user: User) {
    const intercom = this.getIntercom();

    const userCreatedAt = new Date(user.created);
    const clientCreatedAt = new Date(user.client.created);

    const isAdmin =
      user.effective_permissions?.every((p) => p.projects?.some((project) => project === '*')) &&
      user.effective_permissions?.some((p) => p.name.toLowerCase().includes('modify '));

    const intercomData = {
      app_id: INTERCOM_APP_ID,
      email: user.email,
      created_at: userCreatedAt,
      name: combineFullName(user),
      user_id: user.id,
      is_admin: isAdmin,
      company: {
        id: user.client.client_id,
        name: user.client.name,
        created_at: clientCreatedAt
      }
    };

    intercom('boot', intercomData);
  }

  identifyUserWithErrorTracking(user: User) {
    const { sessionId } = this.state;

    const trackJs = (window as any).trackJs;

    if (!trackJs) return;

    const clientId = user && user.client ? user.client.client_id : null;

    const trackJsData = {
      userId: user.id,
      sessionId,
      version: process.env.REACT_APP_VERSION || 'LOCAL'
    };

    trackJs.configure(trackJsData);
    trackJs.addMetadata('clientId', clientId);
  }

  signOut() {
    const intercom = this.getIntercom();
    intercom('shutdown');
    signOut();
  }
}

export default Authenticator;

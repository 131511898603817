import gql from 'graphql-tag';

export const ISSUE_LIST_QUERY = gql`
  query IssueList(
    $pageNumber: Int = 1
    $pageSize: Int
    $sortDirection: SortDirection
    $sortBy: IssueSortProperties
    $isResolved: Boolean
    $isExempted: Boolean
    $region: [String]
    $assessmentId: [String]
    $search: [String]
    $accountId: [String]
    $issueDefinitionId: [String]
    $projectId: [String]
    $severity: [Int]
    $labels: [String]
    $cloudVendor: [String]
    $cloudService: [String]
    $updatedAtStart: DateTime
    $updatedAtEnd: DateTime
    $createdAtStart: DateTime
    $createdAtEnd: DateTime
  ) {
    issues(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortDirection: $sortDirection
      sortBy: $sortBy
      isResolved: $isResolved
      isExempted: $isExempted
      region: $region
      assessmentId: $assessmentId
      search: $search
      accountId: $accountId
      issueDefinitionId: $issueDefinitionId
      projectId: $projectId
      severity: $severity
      labels: $labels
      cloudVendor: $cloudVendor
      cloudService: $cloudService
      updatedAtStart: $updatedAtStart
      updatedAtEnd: $updatedAtEnd
      createdAtStart: $createdAtStart
      createdAtEnd: $createdAtEnd
    ) {
      pageInfo {
        current
        total
        size
      }
      nodes {
        id
        clientId
        name
        description
        accountId
        awsAccountId
        itemKey
        itemType
        parentItemKey
        parentItemType
        createdAt
        updatedAt
        detail
        hashCode
        isExempted
        exemptedReason
        exemptedAt
        isResolved
        resolvedAt
        region
        severity
        issueDefinitionId
      }
    }
    resource_types {
      name
      key
    }
    accounts {
      items {
        account_id
        assumerole_account_id
        assumerole_arn
        assumerole_external_id
        client_id
        created
        name
        nickname
        policy_code
        provider
        provision_url
        provisioner_arn
        provisioner_external_id
        updated
      }
    }
  }
`;

export default ISSUE_LIST_QUERY;

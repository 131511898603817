import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CenteredContainer } from 'components/ui/NeoPage';
import PageHeader from 'components/ui/PageHeader';
import NotificationListPage from '../NotificationListPage';
// import NotificationRecipients from './NotificationRecipients';
import NewRecipientForm from './RecipientForm';

import HorizontalNavbar from 'components/app/HorizontalNavbar';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import AuthorizationErrorAlert from 'components/app/Auth/AuthorizationErrorAlert';
import RecipientsTable from './RecipientsTable';
import RecipientDetails from './RecipientDetails';
import RecipientForm from './RecipientForm';

function NotificationSection() {
  const { isAuthorized, failedPermissions } = useAuthorizor(Permissions.VIEW_NOTIFICATIONS, '*');

  return (
    <CenteredContainer size="lg">
      <PageHeader title="Notifications" titleLevel={2} />

      {!isAuthorized ? (
        <AuthorizationErrorAlert failedPermissions={failedPermissions} />
      ) : (
        <>
          <HorizontalNavbar
            navConfig={[
              { to: '/organization-settings/notifications', label: 'Notifications', exact: true },
              { to: '/organization-settings/notifications/recipients', label: 'Recipients' }
            ]}
            type="inpage"
          />

          <Switch>
            <Route
              path="/organization-settings/notifications/recipients/new/:recipientType"
              component={NewRecipientForm}
            />

            <Route path={'/organization-settings/notifications/recipients/:id/edit'} component={RecipientForm} />
            <Route path={'/organization-settings/notifications/recipients/:id'} component={RecipientDetails} />
            <Route path={'/organization-settings/notifications/recipients'} component={RecipientsTable} />

            <Route path="/organization-settings/notifications" component={NotificationListPage} />

            <Route render={() => <Redirect to="/organization-settings/notifications" />} />
          </Switch>
        </>
      )}
    </CenteredContainer>
  );
}

export default NotificationSection;

import React from 'react';
import { Form, FormField } from 'components/ui/Form';
import { Button, Input, Alert, DatePicker, Checkbox } from 'antd';
import styled from 'styled-components';
import FeatureFlag, { Feature } from 'components/app/FeatureFlag';
import * as Yup from 'yup';
import { ModalBody } from 'components/ui/Modal';

const ContentRow = styled.div`
  display: flex;
`;
const FieldsCol = styled.div`
  margin: 0 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .ant-checkbox-wrapper {
    margin-bottom: 8px;
  }

  @media (min-width: 1000px) {
    width: 400px;
    margin: 0 0 0 24px;
  }
`;
const InfoCol = styled.div`
  @media (min-width: 1000px) {
    width: 200px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > * {
    margin-left: 8px;
  }
`;

const DelayWrap = styled.div`
  margin-bottom: 24px;
`;

interface Props {
  onSubmit: (values: FormValues) => Promise<any>;
  submitLabel?: string;
  onCancel?: () => void;
  cancelLabel?: string;
  initialValues?: FormValues;

  classes?: {
    root?: string;
    formContent?: string;
  };
}

interface State {
  isExpirationChecked: boolean;
}

export interface FormValues {
  exemptExplanation?: string;
  expirationDate?: string;
}

class ConfigureExemptionForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isExpirationChecked: false
    };

    this.handleToggleExpirationCheckbox = this.handleToggleExpirationCheckbox.bind(this);
  }

  handleToggleExpirationCheckbox(checked?: boolean) {
    this.setState((prevState: State) => ({
      isExpirationChecked: checked === undefined ? !prevState.isExpirationChecked : checked
    }));
  }

  render() {
    const { isExpirationChecked } = this.state;
    const { submitLabel, onSubmit, initialValues, onCancel, cancelLabel, classes = {} } = this.props;

    return (
      <FeatureFlag features={[Feature.ISSUE_EXEMPT_EXPIRATION]}>
        {flags => {
          const schema = buildSchema({
            requireExpirationDate: isExpirationChecked && flags[Feature.ISSUE_EXEMPT_EXPIRATION]
          });

          return (
            <Form
              validationSchema={schema}
              initialValues={initialValues || {}}
              allowCleanSubmits={false}
              onSubmit={async (values, actions) => {
                try {
                  // actions.setSubmitting(false);
                  await onSubmit(values);
                } catch (e) {
                  return;
                } finally {
                  actions.setSubmitting(false);
                }
              }}
            >
              {({ isSubmitting, canSubmit, errors, validateField }) => {
                return (
                  <ModalBody classes={{ root: classes.formContent || '' }}>
                    <ContentRow>
                      <InfoCol>
                        <Alert
                          type="info"
                          message={
                            <p>
                              <strong>Exempt</strong> issues are issues that you have acknowledged to exist, but have
                              chosen not to take action on.
                            </p>
                          }
                        />
                      </InfoCol>

                      <FieldsCol>
                        <FormField label="Explanation" name="exemptExplanation">
                          {({ name, value, handleChange, handleBlur, required }) => (
                            <Input.TextArea
                              name={name}
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={required}
                              disabled={isSubmitting}
                              rows={4}
                              style={{ width: '100%' }}
                            />
                          )}
                        </FormField>

                        {flags[Feature.ISSUE_EXEMPT_EXPIRATION] && (
                          <DelayWrap>
                            <Checkbox
                              checked={isExpirationChecked}
                              onChange={e => {
                                const isChecked = e.target.checked;

                                this.handleToggleExpirationCheckbox(e.target.checked);
                                if (isChecked) {
                                  validateField('expirationDate');
                                }
                              }}
                            >
                              Remove exemption on specified date?
                            </Checkbox>
                            {isExpirationChecked && (
                              <div className="expiration-wrap">
                                <FormField name="expirationDate" label="Expiration Date">
                                  {({ handleChange, handleBlur }) => {
                                    return (
                                      <DatePicker
                                        showTime={{ format: 'HH:mm' }}
                                        format="MM/DD/YYYY h:mm a"
                                        onChange={(_date, dateString) => {
                                          handleChange(dateString);
                                          handleBlur();
                                        }}
                                        placeholder="Select Time"
                                        style={{ width: '100%' }}
                                      />
                                    );
                                  }}
                                </FormField>
                              </div>
                            )}
                          </DelayWrap>
                        )}

                        <Actions>
                          {onCancel && <Button onClick={onCancel}>{cancelLabel || 'Cancel'}</Button>}
                          <Button
                            type="primary"
                            htmlType="submit"
                            loading={isSubmitting}
                            disabled={isSubmitting || !canSubmit}
                          >
                            {submitLabel || 'Submit'}
                          </Button>
                        </Actions>
                      </FieldsCol>
                    </ContentRow>
                  </ModalBody>
                );
              }}
            </Form>
          );
        }}
      </FeatureFlag>
    );
  }
}

interface BuildSchemaInput {
  requireExpirationDate?: boolean;
}

function buildSchema(input: BuildSchemaInput) {
  let config: any = {
    exemptExplanation: Yup.string().required('Must add an explanation as to why you are making this issue exempt')
  };

  if (input.requireExpirationDate) {
    config.expirationDate = Yup.date()
      .min(new Date(), 'must be in the future')
      .required('Select date');
  }

  return Yup.object().shape(config);
}

export default ConfigureExemptionForm;

import React from 'react';
import awsSecurityHubFindingType from '../../filter/definitions/awsSecurityHubFindingType';

import { GuardrailTriggerEventSourceDefinition } from '.';
import { Feature } from 'components/app/FeatureFlag';

const awsEventSource: GuardrailTriggerEventSourceDefinition = {
  key: 'AWS',
  name: 'AWS Events',
  featureFlag: Feature.GUARDRAILS_SECURITY_HUB_EVENTS,
  // icon,
  eventTypes: [
    {
      key: 'AWS_SECURITY_HUB_FINDING',
      name: 'Security Hub Finding',
      sectionTitle: () => <>{'Security Hub Finding'}</>,
      filters: [awsSecurityHubFindingType]
    }
  ]
};

export default awsEventSource;

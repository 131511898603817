import { Button, Checkbox, Input, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import { RenderProps } from 'components/ui/Form/FormField';
import { Field, FieldArray, getIn } from 'formik';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import DateTime from '../ui/DateTime';
import { NotificationField } from './JiraRecipientFormControls';
import { firstBy } from 'thenby';

const Root = styled.div`
  th,
  td {
    padding: 8px;
  }
`;

const Option = Select.Option;

export interface JiraField {
  key: string;
  name: string;
  schemaType: string;
}

interface Props {
  jiraFields: JiraField[];
  formFieldProps: RenderProps;
  notificationFields: NotificationField[];
}

interface MappedNotificationField {
  jiraFieldKey: string;
  jiraFieldName: string;
  notificationFieldKey?: string;
  notificationFieldName?: string;
  type?: string;
  value?: any;
}

export const SupportedJiraFieldSchemaTypes = ['any', 'date', 'datetime', 'string', 'number'];

export default function JiraFields(props: Props) {
  const { jiraFields, formFieldProps, notificationFields } = props;
  const {
    name: formFieldName,
    value: mappedFields
  }: { name: string; value: MappedNotificationField[] } = formFieldProps;

  // console.log({ formFieldProps });

  // const [searchTemp, setSearchTemp] = useState('');
  const [search, setSearch] = useState('');

  jiraFields.sort(
    firstBy(
      (a: JiraField, b: JiraField) =>
        Number(SupportedJiraFieldSchemaTypes.includes(b.schemaType)) -
        Number(SupportedJiraFieldSchemaTypes.includes(a.schemaType))
    ).thenBy('name')
  );

  useEffect(() => {
    notificationFields.sort(firstBy('name'));
  }, [notificationFields]);

  const searchLower = search.toLowerCase();

  // console.log({ mappedFields });

  return (
    <FieldArray name={formFieldName}>
      {(arrayHelpers) => {
        const { form } = arrayHelpers;
        const { values } = form;

        // console.log({ values });

        return (
          <Root>
            <Search
              placeholder="Search Jira Fields"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSearch={(value) => setSearch(value)}
              style={{ width: 200 }}
              addonAfter={<Button type="link" size="small" icon="close" onClick={() => setSearch('')} />}
            />
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th>Jira Field</th>
                  <th>Mapped Field</th>
                  <th>Value/Example</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {jiraFields
                  ?.filter((f) => f.key !== 'priority') // filter out priority for now, since it's being handled separately
                  ?.filter((f) => !search || f.name?.toLowerCase().includes(searchLower))
                  .map((jiraField) => {
                    const { key: jiraFieldKey, name: jiraFieldName, schemaType: jiraFieldSchemaType } = jiraField;
                    const mappedField = mappedFields?.find((f) => f.jiraFieldKey === jiraFieldKey);
                    const mappedFieldIndex = mappedField ? mappedFields?.indexOf(mappedField) : -1;
                    const mappedFieldType = mappedField?.type;

                    const dataTypeSupported = SupportedJiraFieldSchemaTypes.includes(jiraFieldSchemaType);

                    const mappedFieldIndexPath = `${formFieldName}[${mappedFieldIndex}]`;

                    const notificationFieldKey = getIn(values, `${mappedFieldIndexPath}.notificationFieldKey`);

                    const notificationField = notificationFields.find((f) => f.key === notificationFieldKey);
                    const { example, dataType } = notificationField || {};

                    const displayValue =
                      example && dataType === 'datetime' ? <DateTime format="local" dateTime={example} /> : example;

                    // if (mappedFieldKey || disruptOpsField || sampleValue) {
                    // console.log({ mappedFieldKey, disruptOpsField, sampleValue, testIssue });
                    // }

                    return (
                      <tr key={jiraFieldKey}>
                        {/* Checkbox & Field Name */}
                        <td
                          title={
                            dataTypeSupported
                              ? `${jiraFieldSchemaType}`
                              : `${jiraFieldSchemaType} data type is not yet supported`
                          }
                        >
                          <Checkbox
                            checked={!!mappedField}
                            disabled={!dataTypeSupported}
                            onChange={(e) => {
                              const checked = e.target.checked;
                              // console.log({ checked, jiraFieldKey, jiraFieldName, formFieldName, mappedFieldIndex });
                              if (checked) {
                                arrayHelpers.push({
                                  jiraFieldKey,
                                  jiraFieldName,
                                  type: 'STATIC'
                                });
                              } else {
                                arrayHelpers.remove(mappedFieldIndex);
                              }
                            }}
                          >
                            {jiraFieldName}
                          </Checkbox>
                        </td>

                        {/* Mapped Field Select */}
                        <td>
                          {mappedField && (
                            <Field name={`${mappedFieldIndexPath}.notificationFieldName`}>
                              {({ field, form }) => {
                                const { name, value } = field;
                                const { setFieldValue } = form;
                                // console.log({ name });

                                const selectedOptionValue = value || 'STATIC';

                                return (
                                  <Select
                                    {...field}
                                    value={selectedOptionValue}
                                    showSearch
                                    onChange={(newValue) => {
                                      if (newValue === 'STATIC') {
                                        setFieldValue(name, undefined);
                                        setFieldValue(`${mappedFieldIndexPath}.notificationFieldKey`, undefined);
                                        setFieldValue(`${mappedFieldIndexPath}.type`, 'STATIC');
                                      } else {
                                        const notificationField = notificationFields.find((f) => f.name === newValue);
                                        setFieldValue(name, newValue);
                                        setFieldValue(
                                          `${mappedFieldIndexPath}.notificationFieldKey`,
                                          notificationField?.key
                                        );
                                        setFieldValue(`${mappedFieldIndexPath}.type`, undefined);
                                      }
                                    }}
                                    placeholder="Select a field value"
                                  >
                                    <Option value="STATIC">Static Value</Option>
                                    {notificationFields.map((disruptOpsField) => (
                                      <Option key={disruptOpsField.name} value={disruptOpsField.name}>
                                        {disruptOpsField.name}
                                      </Option>
                                    ))}
                                  </Select>
                                );
                              }}
                            </Field>
                          )}
                        </td>

                        {/* Static Value Input OR Sample Field Value */}
                        <td>
                          {mappedFieldType === 'STATIC' ? (
                            <Field name={`${mappedFieldIndexPath}.value`}>
                              {({ field, form }) => {
                                const { name } = field;
                                const { setFieldValue } = form;
                                return (
                                  <Input
                                    {...field}
                                    onChange={(e) => {
                                      setFieldValue(name, e.target.value);
                                    }}
                                  />
                                );
                              }}
                            </Field>
                          ) : (
                            displayValue
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Root>
        );
      }}
    </FieldArray>
  );
}

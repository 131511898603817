import { message, Switch } from 'ant4x';
import { useAssessmentValidator } from 'components/assessment';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation } from 'react-apollo';
import { Detector } from 'typings';
import useDetectorVariants from '../DetectorsCommon/useDetectorVariants/useDetectorVariants';

const SET_DETECTOR_ENABLED = gql`
  mutation SetDetectorEnabled($id: ID!, $assessmentInput: AssessmentUpdateInput!) {
    updateAssessment(id: $id, assessmentInput: $assessmentInput) {
      id
      isEnabled
    }
  }
`;

interface Props {
  detector: Detector;
}

export default function DetectorSwitch(props: Props) {
  const { detector } = props;
  const { isValidating, isValid } = useAssessmentValidator(detector);
  const { id, name, isEnabled } = detector;
  const [saveDetector, saveDetectorResults] = useMutation(SET_DETECTOR_ENABLED);
  const checked = isEnabled ?? false;
  const disabled = saveDetectorResults.loading || isValidating || !isValid;
  const variants = useDetectorVariants();
  const helpText = !isValid
    ? `${variants.variantName}'s filters must be configured before enabling.`
    : isValidating
    ? `Validating ${variants.variantName}...`
    : undefined;

  async function setDetectorIsEnabled(isEnabled: boolean) {
    // validate only if enabling
    // no validation required to disable
    if (isEnabled && !isValid && !isValidating) {
      // show validation failed message and bail
      message.warning(`The ${variants.variantName} '${name}' must be completed before enabling.`);
      return;
    }

    const variables = {
      id,
      assessmentInput: {
        isEnabled
      }
    };

    try {
      await saveDetector({ variables });

      message.success(`The ${variants.variantName} '${name}' was ${isEnabled ? 'enabled' : 'disabled'}.`);
    } catch (err) {
      message.error(`There was a problem updating the ${variants.variantName} '${name}'.`);
    }
  }

  return (
    <Switch
      size="small"
      checked={checked}
      disabled={disabled}
      loading={saveDetectorResults.loading}
      title={helpText}
      onChange={async (checked, event) => {
        event.stopPropagation();
        await setDetectorIsEnabled(checked);
      }}
    />
  );
}

import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import QueryResult from 'components/util/QueryResult';
import { EventDefinitionFunction, PageInfo } from 'typings';
import styled from 'styled-components';
import { Typography, Button, Pagination, Collapse } from 'antd';

import { EN_GUARDRAIL } from '../OpCreate';
import { FilterRow, FilterItem } from 'components/app/FilterBar/components';
import { PLAYBOOK_FIELDS } from '../../../Authenticated/pages/Ops/OpDetail/gql';
import EventVendorFilter from '../components/EventVendorFilter';
import usePaginator from 'components/app/Paginator/usePaginator';
import PlaybookLabelsFilter from '../components/PlaybookLabelsFilter';
import EventVendorProductFilter from '../components/EventVendorProductFilter';
import useOpPathname from '../../OpsCommon/useOpPathname/useOpPathname';

const PLAYBOOKS_QUERY = gql`
  query PlaybooksList(
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $labels: [String]
    $eventVendor: String
    $eventVendorProduct: String
  ) {
    playbooks(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      labels: $labels
      eventVendor: $eventVendor
      eventVendorProduct: $eventVendorProduct
    ) {
      pageInfo {
        total
        current
        size
        sortDirection
      }
      nodes {
        ...PlaybookFields
      }
    }
  }
  ${PLAYBOOK_FIELDS}
`;

const Root = styled.div`
  .ant-collapse-item {
    border: 1px solid ${(p) => p.theme.grey200};
    background-color: #fff;
    margin-bottom: 16px;
  }

  .ant-collapse-content {
    padding: 12px 16px;
  }

  .playbook-li-overview {
    margin-bottom: 24px;
  }

  .playbook-li-header {
    padding: 16px 24px;
    display: flex;
  }
  .playbook-li-main {
    flex: 1;
  }
  .playbook-li-title {
    font-size: 14px;
    font-weight: 600;
    color: ${(p) => p.theme.grey700};
  }
`;

function PlaybookList(props: RouteComponentProps) {
  const { filters, updatePagination, pageNumber, pageSize, replaceFilter } = usePaginator({
    defaultPageSize: 20,
    defaultSortDirection: 'ASC'
  });

  const variables: any = {
    sortBy: 'eventDefinition.name',
    sortDirection: 'ASC',
    pageNumber,
    pageSize,
    eventVendor: filters.vendor?.[0],
    eventVendorProduct: filters.vendorProduct?.[0]
  };
  if (filters.labels) variables.labels = filters.labels;

  const { loading, data, error } = useQuery(PLAYBOOKS_QUERY, { variables });

  return (
    <>
      <FilterRow>
        <FilterItem label="Labels">
          <PlaybookLabelsFilter
            onChange={(value) => replaceFilter('labels', value)}
            value={(Array.isArray(filters.labels) && filters.labels) || []}
          />
        </FilterItem>

        <FilterItem label="Vendor">
          <EventVendorFilter
            onChange={(value) => replaceFilter('vendor', value !== 'all' ? value : [])}
            value={filters.vendor?.[0] || 'all'}
          />
        </FilterItem>

        <FilterItem label="Vendor Product">
          <EventVendorProductFilter
            onChange={(value) => {
              replaceFilter('vendorProduct', value !== 'all' ? value : []);
            }}
            value={filters.vendorProduct?.[0] || 'all'}
          />
        </FilterItem>
      </FilterRow>

      <QueryResult loading={loading} data={data} error={error}>
        {() => {
          const playbooks: EventDefinitionFunction[] | null = (data && data.playbooks && data.playbooks.nodes) || null;
          const pageInfo: PageInfo | null = data && data.playbooks.pageInfo;

          return (
            <Root>
              <div className="playbook-list">
                {!playbooks || playbooks.length === 0 ? (
                  <Typography.Text
                    strong
                    style={{ marginBottom: '24px', display: 'inline-block' }}
                  >{`No matching ${EN_GUARDRAIL} Playbooks were found.`}</Typography.Text>
                ) : (
                  <Collapse bordered={false}>
                    {playbooks.map((playbook) => {
                      const { description, overview } = playbook;
                      const hasOverview = Boolean(overview);
                      const title = getNameFromPlaybook(playbook);

                      return (
                        <Collapse.Panel
                          key={playbook.id}
                          header={
                            <div className="playbook-li-header">
                              <div className="playbook-li-main">
                                <div className="playbook-li-title">{title}</div>
                                {description && (
                                  <Typography.Text className={'playbook-li-description'}>{description}</Typography.Text>
                                )}
                              </div>

                              <UseThisPlaybookButton playbook={playbook} />
                            </div>
                          }
                          showArrow={hasOverview}
                          disabled={!hasOverview}
                        >
                          {overview && <div className="playbook-li-overview">{overview}</div>}
                          <UseThisPlaybookButton playbook={playbook} />
                        </Collapse.Panel>
                      );
                    })}
                  </Collapse>
                )}
              </div>

              <Pagination
                current={(pageInfo && pageInfo.current) || undefined}
                pageSize={(pageInfo && pageInfo.size) || undefined}
                total={(pageInfo && pageInfo.total) || undefined}
                onChange={(pageNumber) => {
                  updatePagination({ pageNumber });
                }}
              />
            </Root>
          );
        }}
      </QueryResult>
    </>
  );
}

function getNameFromPlaybook(eventDefinitionFunction: EventDefinitionFunction): string {
  const eventDefinitionName = eventDefinitionFunction.eventDefinition?.name || null;
  const functionName = eventDefinitionFunction?.function?.name || null;

  if (eventDefinitionFunction.name) return eventDefinitionFunction.name;

  return functionName && eventDefinitionName
    ? `${functionName} when event "${eventDefinitionName}" is discovered.`
    : functionName || '--';
}

// USE THIS PLAYBOOK BUTTON
interface UseThisPlaybookButtonProps {
  playbook: EventDefinitionFunction;
}

function UseThisPlaybookButton(props: UseThisPlaybookButtonProps) {
  const { playbook } = props;
  const history = useHistory();
  const basepath = useOpPathname();

  return (
    <Button
      onClick={async () => {
        // `?fromPlaybooks${playbook.id}` will load playbook and init the form with playbook values.
        history.push(`${basepath}/create/confirm?fromPlaybook=${playbook.id}`);
      }}
      className="use-playbook-btn square secondary"
      type="primary"
    >
      {'Use this Playbook'}
    </Button>
  );
}

export default withRouter(PlaybookList);

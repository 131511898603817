import gql from 'graphql-tag';

export const AUTOMATION_FUNCTION_FIELDS_FOR_OPS = gql`
  fragment AutomationFunctionFieldsForOps on AutomationFunction {
    id
    name
    key
    description
    cloudVendor
    labels
    registration {
      arn
      method
    }
    parameters {
      name
      key
      type
      inputCode
      configCode
      defaultValue
      description
      placeholder
      many
      options
      required
    }
  }
`;

export const AUTOMATION_FUNCTION_BY_ID = gql`
  query AutomationFunctionQueryForOpDetail(
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $id: [String]
  ) {
    automationFunctions(pageSize: $pageSize, sortBy: $sortBy, sortDirection: $sortDirection, id: $id) {
      pageInfo {
        total
        size
      }
      nodes {
        ...AutomationFunctionFieldsForOps
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_OPS}
`;

export const FUNCTION_FIELDS = gql`
  fragment FunctionFields on Function {
    id
    type
    name
    key
    description
    overview
    effects
    parameters {
      name
      key
      type
      inputCode
      configCode
      defaultValue
      description
      placeholder
      many
      options
      required
    }
  }
`;

export const ISSUE_DEFINITION_FIELDS = gql`
  fragment IssueDefinitionFields on IssueDefinition {
    id
    name
    description
    actors {
      ...FunctionFields
    }
  }

  ${FUNCTION_FIELDS}
`;

export const ACTOR_BY_ID = gql`
  query ActorById($id: ID) {
    functions(id: $id) {
      nodes {
        ...FunctionFields
      }
    }
  }

  ${FUNCTION_FIELDS}
`;

export const ISSUE_DEFINITION_BY_ID = gql`
  query IssueDefinitionById($id: ID) {
    issueDefinitions(id: $id) {
      nodes {
        ...IssueDefinitionFields
      }
    }
  }
  ${ISSUE_DEFINITION_FIELDS}
`;

export const AUTOMATION_EVENT_DEFINITIONS = gql`
  query AutomationEventDefinitionById($id: [String], $pageNumber: Int, $pageSize: Int, $search: String) {
    eventDefinitions(id: $id, pageNumber: $pageNumber, pageSize: $pageSize, search: $search) {
      nodes {
        id
        name
        key
        description
        vendor
        vendorProduct
        filters {
          key
          type
          required
          name
          options {
            name
            path
            patterns
            foundByProduct
          }
        }
      }
    }
  }
`;

export const PLAYBOOK_FIELDS = gql`
  fragment PlaybookFields on EventDefinitionFunction {
    id
    name
    description
    overview
    functionId
    function {
      id
      name
    }
    eventDefinitionId
    eventDefinition {
      id
      name
      key
      description
      overview
      context
      filters {
        key
        type
        required
        name
        options {
          name
          path
          foundByProduct
          patterns
        }
      }
    }

    functionInputMappings {
      key
      valuePath
    }
  }
`;

export const PLAYBOOK_BY_ID = gql`
  query PlaybookById($id: ID) {
    playbooks(id: $id) {
      pageInfo {
        total
        current
        size
        sortDirection
      }
      nodes {
        ...PlaybookFields
      }
    }
  }
  ${PLAYBOOK_FIELDS}
`;

import React from 'react';
import DopeIcon from 'components/ui/DopeIcon';
import styled from 'styled-components';
import HorizontalNavbar from 'components/app/HorizontalNavbar';
import { PlaybookList, OpEventDefinitionsList } from './tabs';
import { withRouter } from 'react-router';
import { useHistory } from 'react-router-dom';
import ConfirmEditor from './ConfirmEditor/ConfirmEditor';
import useOpPathname from '../OpsCommon/useOpPathname/useOpPathname';

// EN for "ENglish"
export const EN_GUARDRAIL = 'Op';
export const EN_GUARDRAILS = 'Ops';

function OpCreate(props) {
  const {
      match: {
        params: { createStep }
      }
    } = props,
    basepath = useOpPathname(),
    triggerRoute = `${basepath}/create/event-definitions`,
    playbookRoute = `${basepath}/create/playbooks`,
    history = useHistory();

  return (
    <>
      {createStep === 'confirm' ? (
        <ConfirmEditor />
      ) : (
        <>
          <div style={{ display: 'flex', marginBottom: '48px' }}>
            <CreateBlankGuardrailButtonRoot
              onClick={() => {
                history.push(`${basepath}/create/confirm`);
              }}
            >
              <div className="create-blank-guardrail-btn-content">
                <div className="create-blank-guardrail-btn-title">{`Create Blank ${EN_GUARDRAIL}`}</div>
                <DopeIcon size={100} name="ADD" />
              </div>
            </CreateBlankGuardrailButtonRoot>
          </div>

          <HorizontalNavbar
            navConfig={[
              { label: 'Triggers', to: triggerRoute },
              { label: 'Playbooks', to: playbookRoute }
            ]}
            type="inpage"
          />

          {createStep === 'playbooks' && <PlaybookList />}
          {(createStep === 'event-definitions' || !createStep) && <OpEventDefinitionsList />}
        </>
      )}
    </>
  );
}

const CreateBlankGuardrailButtonRoot = styled.div`
  border: 1px solid ${(p) => p.theme.grey200};
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }

  .create-blank-guardrail-btn-content {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .create-blank-guardrail-btn-title {
    font-weight: 600;
  }
`;

export default withRouter(OpCreate);

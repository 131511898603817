import gql from 'graphql-tag';

const CLASSIFIED_EVENT_DETAIL = gql`
  query ClassifiedEventDetail($eventId: [String]) {
    classifiedEvents(eventId: $eventId) {
      pageInfo {
        total
        size
      }
      nodes {
        id
        eventId
        eventDefinitionId
        eventDefinitionName
        clientId
        contextId
        eventType
        cloudAccountId
        region
        vendor
        vendorProduct
        receivedAt
        eventData
      }
    }
  }
`;

export default CLASSIFIED_EVENT_DETAIL;

import React from 'react';
import styled from 'styled-components';

export const CondensedFieldWrap = styled.div`
  .many-field-group.has-action & {
    .ant-input,
    .ant-input-number,
    .ant-select-selection {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
    }
  }

  & + .many-action-wrap .ant-btn-lg {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const Root = styled.div`
  display: flex;

  > *:first-child {
    flex: 1;
  }

  .many-action-wrap {
    width: 40px;

    .ant-btn-lg {
      padding: 0 10px;
    }
  }
`;

interface Props {
  children: React.ReactNode;
  action?: React.ReactNode;
  classes?: {
    root?: string;
    fieldWrap?: string;
    actionWrap?: string;
  };
}

function ManyFieldGroup(props: Props) {
  const { children, action, classes = {} } = props;
  let rootClasses = 'many-field-group';
  if (!!action) rootClasses += ' has-action';
  if (classes.root) rootClasses += ` ${classes.root}`;

  return (
    <Root className={rootClasses}>
      {children}
      {action && (
        <div className={classes.actionWrap ? `many-action-wrap ${classes.actionWrap}` : 'many-action-wrap'}>
          {action}
        </div>
      )}
    </Root>
  );
}

export default ManyFieldGroup;

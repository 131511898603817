import React from 'react';
import NeoPage, { TitleBar, CenteredContainer } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import { EventsTable } from 'components/events/EventsTable';
import styled from 'styled-components';

const Root = styled.div`
  .centered-container {
    padding-bottom: 96px; // to get the intercom button out of the way of the pagination controls
    padding-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

function EventsPage() {
  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar icon={<DopeIcon name="EVENT" size={20} />} sectionTitle="Events" sectionTitleLinkTo="/events" />
        }
      >
        <CenteredContainer>
          <EventsTable />
        </CenteredContainer>
      </NeoPage>
    </Root>
  );
}

export default EventsPage;

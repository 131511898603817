import gql from 'graphql-tag';

export const POLICY_OPTIONS_QUERY = gql`
  query PolicyOptionsForAWS {
    policyOptions @rest(type: "CloudAccountPolicyOption", path: "/api/v2/accounts/policy-options") {
      description
      label
      policy_code
      selected
    }
  }
`;

export const ADD_ACCOUNT_MUTATION = gql`
  mutation NewAWSAccount($input: PostAccountInput!) {
    postAccount(input: $input) @rest(type: "ClientAccount", path: "/api/v2/accounts", method: "POST") {
      account_id
      assumerole_account_id
      provision_url
      nickname
      name
      updated
    }
  }
`;

import React from 'react';
import gql from 'graphql-tag';
import { Mutation, MutationFunction } from 'react-apollo';
import { IssueDefinition, Issue, ActiveTask, CloudAccount } from 'typings';
import { FormikValues } from 'formik';
import { SelectAction, ConfigureAction, ReviewAction } from './steps';
import { message } from 'antd';

const RUN_ACTION = gql`
  mutation RunAction($actionId: ID!, $issueId: ID!, $parameters: JSONObject!) {
    runAction(id: $actionId, issueId: $issueId, parameters: $parameters) {
      id
      clientId
      taskId
      subjectId
      functionId
      status
      createdAt
      updatedAt
    }
  }
`;

enum ActiveViewOption {
  Select = 'SELECT', // select action
  Configure = 'CONFIGURE',
  Review = 'REVIEW'
}

interface ViewConfig {
  // should be one of ModalStep enum
  title: string;
  render: () => React.ReactNode;
  precedingView: ActiveViewOption | null;
  modalWidth?: number;
  onBack?: () => any | void;
}

interface Props {
  issues: Issue[];
  account: CloudAccount;
  issueDefinition: IssueDefinition; // get issueDefinitions to get available
  onBack?: () => void;
  onBackLabel?: string;
  onComplete: () => void;
}

interface State {
  activeView: ActiveViewOption;
  prevView: ActiveViewOption;
  actionTaskId: string | null;
  savedConfigs: {
    [taskId: string]: any;
  };
}

class IssueActionStepper extends React.Component<Props, State> {
  viewConfigs: {
    [key: string]: ViewConfig;
  } = {
    SELECT: {
      title: 'Select Action',
      render: this.renderSelectAction.bind(this),
      precedingView: null,
      modalWidth: 800 // ??
    },
    CONFIGURE: {
      title: 'Configure',
      render: this.renderConfigure.bind(this),
      precedingView: ActiveViewOption.Select,
      modalWidth: 1200
    },
    REVIEW: {
      title: 'Review',
      render: this.renderReview.bind(this),
      precedingView: ActiveViewOption.Configure,
      modalWidth: 1200
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeView: ActiveViewOption.Select,
      prevView: ActiveViewOption.Select,
      actionTaskId: null,
      savedConfigs: {}
    };

    this.handleBack = this.handleBack.bind(this);
    this.handleRunAction = this.handleRunAction.bind(this);
  }

  renderSelectAction() {
    const { issueDefinition, issues, onBack, onBackLabel } = this.props;

    return (
      <SelectAction
        actionFunctions={issueDefinition.actors}
        issues={issues}
        onSelectAction={actionTaskId => {
          const { issueDefinition } = this.props;
          const selectedAction = issueDefinition.actors.find(a => a.id === actionTaskId);

          // if the action has no parameters, skip an empty Configure step
          if (selectedAction && (!selectedAction.parameters || selectedAction.parameters.length < 1)) {
            this.handleSaveConfig(actionTaskId)({});
            this.setState({ actionTaskId, activeView: ActiveViewOption.Review });
          } else {
            this.setState({ actionTaskId, activeView: ActiveViewOption.Configure });
          }
        }}
        onBack={onBack}
        onBackLabel={onBackLabel}
      />
    );
  }

  handleSaveConfig(actionTaskId: string) {
    return (values: FormikValues) => {
      const { executionDt, scheduleChoice, ...fieldValues } = values;

      if (scheduleChoice === 'delay' && executionDt) {
        fieldValues.executionDt = executionDt;
      }

      this.setState(prev => ({
        savedConfigs: {
          ...prev.savedConfigs,
          [actionTaskId]: fieldValues
        },
        prevView: ActiveViewOption.Configure,
        activeView: ActiveViewOption.Review
      }));
    };
  }

  renderConfigure() {
    const { actionTaskId, savedConfigs } = this.state;
    const { issueDefinition, issues, account } = this.props;
    if (!actionTaskId) return null;
    const savedFormResults = savedConfigs[actionTaskId];

    const selectedAction = issueDefinition.actors.find(a => a.id === actionTaskId);

    if (!selectedAction) return null;

    return (
      <ConfigureAction
        issues={issues}
        account={account}
        actionTask={selectedAction}
        savedFormResults={savedFormResults}
        onSubmit={this.handleSaveConfig(actionTaskId)}
        onBack={this.handleBack}
      />
    );
  }

  renderReview() {
    const { actionTaskId, savedConfigs } = this.state;
    const { issueDefinition, issues, account } = this.props;
    if (!actionTaskId) return null;

    const savedConfig = savedConfigs[actionTaskId];
    const selectedAction = issueDefinition.actors.find(a => a.id === actionTaskId);
    if (!selectedAction) return null;

    return (
      <Mutation mutation={RUN_ACTION}>
        {runAction => (
          <ReviewAction
            issues={issues}
            account={account}
            actionTask={selectedAction}
            configValues={savedConfig}
            onBack={this.handleBack}
            onRunAction={this.handleRunAction(runAction)}
          />
        )}
      </Mutation>
    );
  }

  handleBack() {
    this.setState((prevState: State) => {
      let newActiveStep = prevState.prevView;
      const config = this.viewConfigs[newActiveStep];
      let newPrevView = (config && config.precedingView) || ActiveViewOption.Select;

      // if the action has no parameters, skip an empty Configure step
      if (prevState.prevView === ActiveViewOption.Configure) {
        const { issueDefinition } = this.props;
        const { actionTaskId } = prevState;
        const selectedAction = issueDefinition.actors.find(a => a.id === actionTaskId);
        if (selectedAction && (!selectedAction.parameters || selectedAction.parameters.length < 1)) {
          newActiveStep = ActiveViewOption.Select;
          newPrevView = ActiveViewOption.Select;
        }
      }

      return {
        activeView: newActiveStep,
        prevView: newPrevView
      };
    });
  }

  handleRunAction(mutation: MutationFunction) {
    return async () => {
      const { savedConfigs, actionTaskId } = this.state;
      const { issues, onComplete } = this.props;

      const firstIssue = issues[0]; // this used to support multiple issues.

      if (!actionTaskId) throw new Error('No action has been selected');

      const savedConfig = savedConfigs[actionTaskId];

      const variables = {
        actionId: actionTaskId,
        issueId: firstIssue.id,
        parameters: savedConfig
      };

      const response: any = await mutation({ variables });
      if (response.errors) {
        const error = response.errors[0];
        message.error(error.message);
        return;
      }

      const task: ActiveTask = response.data.runAction;
      message.success(`Action started successfully. (Task: ${task.taskId}, Status: ${task.status})`);

      // navigate back to the issue details
      onComplete();
    };
  }

  render() {
    const { activeView } = this.state;
    const activeConfig: ViewConfig = this.viewConfigs[activeView];

    return activeConfig.render();
  }
}

export default IssueActionStepper;

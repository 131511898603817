import DopeIcon from 'components/ui/DopeIcon';
import React from 'react';

interface VendorIconProps {
  vendor: string;
}

function VendorIcon(props: VendorIconProps) {
  const { vendor } = props;

  const iconMap = {
    AWS: <DopeIcon name="AWS" className="vendor-icon aws" size={20} />,
    Azure: <DopeIcon name="AZURE" className="vendor-icon" size={20} />,
    DisruptOps: <DopeIcon name="DISRUPT_OPS_FAVICON_BLACK" className="vendor-icon" size={14} />
  };

  return iconMap[vendor];
}

export default VendorIcon;

import React, { useState } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { Select } from 'antd';
import { useQuery } from 'react-apollo';
import { FilterTypeProps } from '../FilterListing/FilterListing';
import { CLOUD_ACCOUNT_LIST_QUERY } from 'queries';

function AccountEditor(props: FilterTypeProps) {
  const { values, filterType } = props,
    [softEdit, setSoftEdit] = useState<SoftEdit | null>({
      key: filterType.valueKey,
      value: values[filterType.valueKey]
    }),
    { data, loading, error } = useQuery(CLOUD_ACCOUNT_LIST_QUERY, {
      fetchPolicy: 'cache-and-network'
    }),
    onChange = (update) => {
      setSoftEdit({
        key: filterType.valueKey,
        value: update
      });
    },
    getAccountName = (cloudAccount) => {
      return (
        cloudAccount.nickname || cloudAccount.name || cloudAccount.assumerole_account_id || cloudAccount.account_id
      );
    },
    getAccount = (accountId) => {
      return data?.accounts.items.find((account) => account.account_id === accountId);
    },
    display = (
      <>
        {data && (
          <ul data-testid="account-list">
            {values[filterType.valueKey]?.map((accountId) => (
              <li data-testid="value-item" key={accountId}>
                {getAccountName(getAccount(accountId))}
              </li>
            ))}
          </ul>
        )}
      </>
    ),
    editor = (
      <Select
        onChange={onChange}
        mode="multiple"
        value={softEdit?.value}
        filterOption={(inputValue, option) => {
          if (!option || !option.props || !option.props.title) {
            return true;
          }
          return new RegExp(inputValue, 'i').test(option.props.title);
        }}
      >
        {data &&
          data.accounts?.items?.map((cloudAccount) => {
            let title = getAccountName(cloudAccount);
            return (
              <Select.Option key={cloudAccount.account_id} title={title}>
                {title}
              </Select.Option>
            );
          })}
      </Select>
    );

  return <FilterRow {...props} display={display} editor={editor} softEdit={softEdit} loading={loading} error={error} />;
}

export default AccountEditor;

export function formatAccountValues(accountId, opContextData) {
  const accounts = opContextData.accounts.items;

  function getAccountName(cloudAccount) {
    return cloudAccount.nickname || cloudAccount.name || cloudAccount.assumerole_account_id || cloudAccount.account_id;
  }

  function getAccount(accountId) {
    return accounts.find((account) => account.account_id === accountId);
  }

  return getAccountName(getAccount(accountId));
}

import { User, UserGroup, EffectivePermission } from 'typings';
import React from 'react';

import { Project } from 'typings';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

export interface DevSettings {
  isFeatureFlaggingDisabled: boolean;
  isSSEDebuggingEnabled: boolean;
}

export interface AuthContextProps {
  me: User;
  identity: any;
  isDisruptOpsUser: boolean;
  devSettings: DevSettings;
  persistDevSettingsToLocalStorage: (devSettings: DevSettings) => void;
  myUserGroups: UserGroup[];
  refetch: (variables: any) => Promise<any>;
  signOut: () => void;
  lookupProject: (id: string) => Project | undefined;
  rootProjectId: string | undefined;
  userRootProjectId: string | undefined;
  getEffectivePermissions: (permission: Permissions | Permissions[]) => EffectivePermission[];
  checkEffectivePermissionsAgainstProjectIds: (
    permissions: Permissions | Permissions[],
    projectIds: string[]
  ) => string[];
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

export default AuthContext;

import React from 'react';
import styled from 'styled-components';

import HorizontalNavbar, { NavItemType } from 'components/app/HorizontalNavbar/HorizontalNavbar';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;

  background-color: #f5f5f5;

  .info-panel-header {
    box-shadow: 0 -4px 5px 1px rgba(0, 0, 0, 0.5);
    position: relative;
    z-index: 1;
  }

  .info-panel-title {
    padding: 12px 16px;
  }

  .horizontal-navbar {
    &.inpage {
      margin-bottom: 0;
    }

    .nav-link,
    .nav-link:first-child {
      &,
      &:hover {
        margin: 0 16px;
        color: ${(p) => p.theme.primary600};
      }
    }
  }

  .ip-scrollable {
    padding: 24px 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow-y: auto;
  }

  .ip-scrollable-inner {
    margin-bottom: 142px;
  }

  thead.ant-table-thead {
    background-color: #d4d4d4;

    > tr:first-child > th:first-child {
      border-radius: 0;
    }

    & > tr > th {
      padding: 8px 16px;
    }
  }
`;

export const InfoPanelTableActions = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 16px 8px;
`;

export const InfoPanelContent = styled.div`
  padding: 0 16px 32px;
`;

interface InfoPanelProps {
  title: string;
  navConfig?: NavItemType[];
  children: React.ReactNode;
  classes?: {
    root?: string;
    title?: string;
  };
}

function InfoPanel(props: InfoPanelProps) {
  const { classes = {}, title, navConfig, children } = props;

  return (
    <Root className={`info-panel ${classes.root || ''}`}>
      <div className="info-panel-header">
        <h2 className={`info-panel-title ${classes.title || ''}`}>{title}</h2>
        {navConfig && <HorizontalNavbar type="inpage" navConfig={navConfig} />}
      </div>
      <div className="ip-scrollable">
        <div className="ip-scrollable-inner">{children}</div>
      </div>
    </Root>
  );
}

export default InfoPanel;

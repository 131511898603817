import React from 'react';
import * as Yup from 'yup';
import AwsSecurityHubFindingFilterInput from '../components/AwsSecurityHubFindingFilterInput';
import { GuardrailFilterDefinition } from '.';

const awsSecurityHubFindingType: GuardrailFilterDefinition = {
  key: 'FINDING_TYPE', // issues are currently using lowercased keys...
  // schema: Yup.string(),
  schema: Yup.object().shape({
    eventDefinitionId: Yup.string().required(),
    path: Yup.string().required(),
    patterns: Yup.mixed().required()
  }),
  required: false,
  configurationInput: AwsSecurityHubFindingFilterInput,
  getCollapseTitle: _value => 'Filter by Finding Type:',
  collapseTitleReadView: ({ value }) => <>{(value && value.eventDefinitionName) || null}</>
};

export default awsSecurityHubFindingType;

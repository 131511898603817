import { Select } from 'antd';
import { ModeOption } from 'antd/lib/select';
import { eventSourceAwsCloudWatch, eventSourceAwsConfig, eventSourceAwsSecurityHub } from 'constants/eventSources';
import { awsAsffResourceTypes, awsCloudFormationResourceTypes } from 'constants/resourceTypes';
import React from 'react';

export interface Props {
  value?: string | string[];
  onChange: (value: any) => any;
  mode?: ModeOption;
  placeholder?: string;
  onBlur?: (event: any) => any;
  eventSourceId?: string;
}

function ResourceTypeFilter(props: Props) {
  const { value, onChange, onBlur, eventSourceId } = props;

  const mode = props.mode ? props.mode : 'tags';
  const placeholder = props.placeholder ? props.placeholder : 'Any Resource Type';

  return (
    <Select
      value={value === null ? undefined : value}
      style={{ width: '100%', minWidth: '200px' }}
      onChange={onChange}
      placeholder={placeholder}
      mode={mode}
      onBlur={onBlur}
      filterOption={(inputValue, option) => {
        if (!option || !option.props || !option.props.title) {
          return true;
        }
        return new RegExp(inputValue, 'i').test(option.props.title);
      }}
    >
      {(!eventSourceId || eventSourceId === eventSourceAwsSecurityHub) &&
        awsAsffResourceTypes.map((resourceType) => (
          <Select.Option key={resourceType} value={resourceType}>
            {resourceType}
          </Select.Option>
        ))}
      {(!eventSourceId || eventSourceId === eventSourceAwsCloudWatch || eventSourceId === eventSourceAwsConfig) &&
        awsCloudFormationResourceTypes.map((resourceType) => (
          <Select.Option key={resourceType} value={resourceType}>
            {resourceType}
          </Select.Option>
        ))}
    </Select>
  );
}

export default ResourceTypeFilter;

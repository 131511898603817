import styled from 'styled-components';

export const Root = styled.div`
  .activity-enter {
    opacity: 0.01;
  }

  .activity-enter.activity-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }

  .activity-leave {
    opacity: 1;
  }

  .activity-leave.activity-leave-active {
    opacity: 0.01;
    transition: opacity 100ms ease-in;
  }

  .height-wrapper {
    min-height: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .activity-link {
    display: flex;
    align-items: center;

    > *:first-child {
      margin-right: 0.5rem;
    }
  }
`;

import React, { useState } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { FilterTypeProps } from '../FilterListing/FilterListing';
import CustomJSONPathFilterInput from 'components/events/CustomJSONPathFilterInput';
import { AutomationEventDefinition } from 'typings';

interface AdvancedEditorProps extends FilterTypeProps {
  eventDefinition: AutomationEventDefinition;
  eventFromConsole: any;
}

function AdvancedEditor(props: AdvancedEditorProps) {
  const { values, filterType, eventDefinition, eventFromConsole } = props,
    [softEdit, setSoftEdit] = useState<SoftEdit | null>({
      key: filterType.valueKey,
      value: values[filterType.valueKey]
    }),
    onChange = (update) => {
      setSoftEdit({
        key: filterType.valueKey,
        value: update
      });
    },
    display = (
      <>
        <ul data-testid="advanced-list">
          {values[filterType.valueKey] &&
            values[filterType.valueKey].map((customFilter, index) => (
              <li key={index} title={`${customFilter.path}=${customFilter.patterns.join(', ')}`}>
                {customFilter.path}
              </li>
            ))}
        </ul>
      </>
    ),
    editor = (
      <>
        <CustomJSONPathFilterInput
          name={filterType.valueKey}
          value={values[filterType.valueKey]}
          eventFromEventConsole={eventFromConsole}
          onChange={onChange}
          eventDefinition={eventDefinition}
        />
      </>
    );

  return (
    <>
      <FilterRow {...props} display={display} editor={editor} softEdit={softEdit} />
    </>
  );
}

export default AdvancedEditor;

export function advancedValueFormatter(val) {
  const matchType = val.type === 'PARTIAL_MATCH' ? '(partial match)' : '(strict match)';

  return (
    <>
      <span className="mono">{val.path}</span> {matchType}:<br />
      <span className="mono">{val.patterns.join(', ')}</span>
    </>
  );
}

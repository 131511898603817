import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { Table } from 'antd';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

import { TeamMember } from 'typings';
import { USER_LIST_QUERY } from 'queries';
import { combineFullName } from 'utilities/user';

import { useAuthorizor } from 'components/app/Auth/Authorizor';
import AuthorizationErrorAlert from 'components/app/Auth/AuthorizationErrorAlert';
import DateTime from 'components/ui/DateTime';
import { CenteredContainer } from 'components/ui/NeoPage';
import { TableWrap, TableHeader, TableHeaderActions } from 'components/ui/Table';
import QueryResult from 'components/util/QueryResult';
import Paginator from 'components/app/Paginator';
import PageHeader from 'components/ui/PageHeader';

import { InviteForm, DeleteUserButton, AddTeamMemberButton } from './components';

function TeamManagementPage() {
  const { isAuthorized, failedPermissions } = useAuthorizor([Permissions.VIEW_USERS], []);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleOpenHeaderDrawer = () => setIsDrawerOpen(true);
  const handleCloseHeaderDrawer = () => setIsDrawerOpen(false);

  return (
    <CenteredContainer>
      <PageHeader title="Team management" titleLevel={2} />

      {!isAuthorized ? (
        <AuthorizationErrorAlert failedPermissions={failedPermissions} />
      ) : (
        <Paginator pageSize={20}>
          {({ pageSize, pageNumber, updatePage }) => (
            <Query query={USER_LIST_QUERY} variables={{ pageNumber, pageSize }}>
              {({ networkStatus, data, error, loading, refetch }) => (
                <QueryResult loading={networkStatus === 1} error={error} entityName="Team" data={data}>
                  {() => {
                    const queryVariables = { pageNumber, pageSize };
                    const dataSource = data?.userList?.items || [];
                    const total = data?.userList?.total || 0;

                    return (
                      <TableWrap>
                        <Table
                          className="overflow-visible"
                          loading={loading}
                          title={() => (
                            <TableHeader
                              headerDrawer={
                                <InviteForm
                                  onFinish={() => {
                                    handleCloseHeaderDrawer();
                                    refetch();
                                  }}
                                  onCancel={handleCloseHeaderDrawer}
                                />
                              }
                              headerDrawerOpen={isDrawerOpen}
                            >
                              <TableHeaderActions>
                                <AddTeamMemberButton onClick={handleOpenHeaderDrawer} />
                              </TableHeaderActions>
                            </TableHeader>
                          )}
                          rowKey="id"
                          columns={[
                            {
                              key: 'name',
                              title: 'Name',
                              dataIndex: 'first_name',
                              render: (_: any, teamMember: TeamMember) => combineFullName(teamMember)
                            },
                            {
                              key: 'username',
                              title: 'Username',
                              dataIndex: 'username'
                            },
                            {
                              key: 'email',
                              title: 'Email',
                              dataIndex: 'email'
                            },
                            {
                              key: 'created',
                              title: 'Created',
                              dataIndex: 'created',
                              render: (dateTime) => <DateTime dateTime={new Date(dateTime)} />
                            },
                            {
                              key: 'delete-user',
                              width: '50px',
                              align: 'right' as 'right',
                              dataIndex: 'username',
                              render: (username) => (
                                <DeleteUserButton
                                  className="reveal-on-row-hover"
                                  username={username}
                                  queryVariables={queryVariables}
                                />
                              )
                            }
                          ]}
                          dataSource={dataSource}
                          pagination={{
                            hideOnSinglePage: true,
                            current: pageNumber,
                            pageSize: pageSize,
                            total,
                            onChange: (page: number) => updatePage(page)
                          }}
                        />
                      </TableWrap>
                    );
                  }}
                </QueryResult>
              )}
            </Query>
          )}
        </Paginator>
      )}
    </CenteredContainer>
  );
}

export default TeamManagementPage;

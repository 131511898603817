import RecipientSelectFormItem from 'components/recipients/RecipientSelectFormItem';
import CheckboxFormItem from 'components/ui/Form/CheckboxFormItem';
import { FormRenderProps } from 'components/ui/Form/Form';
import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import RECIPIENTS_LIST_QUERY from 'queries/recipientListQuery';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';

const Root = styled.div`
  .r-table {
    display: table;
    width: 100%;
  }
  .r-row {
    display: table-row;
  }
  .r-cell {
    display: table-cell;
  }
  .r-body {
    display: table-row-group;
  }

  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item-control {
    line-height: 32px;
  }

  .section {
    padding-top: '16px;';
  }
`;

interface OpNotificationSectionProps {
  formRenderProps: FormRenderProps;
}

function OpNotificationSection(props: OpNotificationSectionProps) {
  const { formRenderProps } = props;
  const { values = {} } = formRenderProps;

  const {
    isSuccessNotificationEnabled,
    successNotificationRecipientSource,
    successNotificationRecipientId,

    isFailureNotificationEnabled,
    failureNotificationRecipientSource,
    failureNotificationRecipientId
  } = values;

  const recipientsResults = useQuery(RECIPIENTS_LIST_QUERY);
  const { data: recipientsData } = recipientsResults;

  const successRecipient =
    successNotificationRecipientSource === 'STATIC' &&
    recipientsData?.recipients?.items?.find((item) => item.id === successNotificationRecipientId);

  const failureRecipient =
    failureNotificationRecipientSource === 'STATIC' &&
    recipientsData?.recipients?.items?.find((item) => item.id === failureNotificationRecipientId);

  const recipientTypes = ['SLACK', 'MS_TEAMS', 'EMAIL'];

  return (
    <Root>
      <HorizontalLabel form label="Upon Success">
        <CheckboxFormItem
          name="isSuccessNotificationEnabled"
          extra={
            isSuccessNotificationEnabled && (
              <RecipientSelectFormItem
                idFieldName="successNotificationRecipientId"
                sourceFieldName="successNotificationRecipientSource"
                projectId={values?.projectId}
                recipientsResults={recipientsResults}
                recipient={successRecipient}
                recipientTypes={recipientTypes}
              />
            )
          }
        >
          Send notification {isSuccessNotificationEnabled && 'to '}
        </CheckboxFormItem>
      </HorizontalLabel>

      <HorizontalLabel form label="Upon Failure">
        <CheckboxFormItem
          name="isFailureNotificationEnabled"
          extra={
            isFailureNotificationEnabled && (
              <RecipientSelectFormItem
                idFieldName="failureNotificationRecipientId"
                sourceFieldName="failureNotificationRecipientSource"
                projectId={values?.projectId}
                recipientsResults={recipientsResults}
                recipient={failureRecipient}
                recipientTypes={recipientTypes}
              />
            )
          }
        >
          Send notification {isFailureNotificationEnabled && 'to '}
        </CheckboxFormItem>
      </HorizontalLabel>
    </Root>
  );
}

export default OpNotificationSection;

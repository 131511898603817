import React, { SFC } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

export interface Props {
  buttonLink?: string;
  linkLink?: string;
  buttonOnClick?: any;
  linkOnClick?: any;
  buttonText: string;
  linkText: string;
  loading?: boolean;
  disabled?: boolean;
}

const Outer = styled.div`
  width: 100%;
  height: ${(p: Props) => (p.linkText ? '100' : '50')}px;
  margin-top: 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
`;

const LinkText = styled.div`
  color: #2b84d2;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
`;

const ButtonAndLink: SFC<Props> = props => {
  const {
    buttonLink,
    linkLink,
    buttonOnClick,
    linkOnClick,
    buttonText,
    linkText,
    loading = false,
    disabled = false
  } = props;

  const button = (
    <Button type="primary" htmlType="submit" size="large" onClick={buttonOnClick} loading={loading} disabled={disabled}>
      {buttonText}
    </Button>
  );
  const link = <LinkText onClick={linkOnClick}>{linkText}</LinkText>;
  return (
    <Outer {...props}>
      {buttonLink ? <Link to={buttonLink}>{button}</Link> : button}
      {linkText && (linkLink ? <Link to={linkLink}>{link}</Link> : link)}
    </Outer>
  );
};

export default ButtonAndLink;

import { Spin } from 'antd';
import ErrorAlert from 'components/ui/ErrorAlert';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { firstBy } from 'thenby';
import { getChartColor } from '.';

const query = gql`
  query classifiedEventCountByAccountReceivedAt(
    $filters: ClassifiedEventStatFilters
    $interval: CalendarInterval
    $nextKey: String
    $nextDateTime: DateTime
    $size: Int
  ) {
    classifiedEventCountByAccountReceivedAt(
      filters: $filters
      interval: $interval
      nextKey: $nextKey
      nextDateTime: $nextDateTime
      size: $size
    ) {
      duration
      total
      nextKey
      nextDateTime
      items {
        key
        dateTime
        count
      }
    }
  }
`;

interface Props {
  accounts;
}

export default function EventCountByAccountLineChart(props: Props) {
  const { accounts } = props;

  const { data, error, loading } = useQuery(query, { variables: { interval: 'hour', size: 72 } });
  const { classifiedEventCountByAccountReceivedAt } = data || {};
  const { items } = classifiedEventCountByAccountReceivedAt || {};

  const [chartData, setChartData] = useState<any>([]);

  const filteredAccounts = accounts.filter((a) => items?.some((i) => i.key === a.account_id));

  useEffect(() => {
    const chartData: any = [];

    items?.forEach((item) => {
      const { count, dateTime, key: accountId } = item;
      const account = filteredAccounts?.find((a) => a.account_id === accountId);
      const { account_id, nickname, name, assumerole_account_id } = account || {};
      const accountTitle = nickname || name || assumerole_account_id || account_id || accountId;

      let entry = chartData.find((e) => e.dateTime === dateTime);
      if (!entry) {
        entry = {
          dateTime
        };
        filteredAccounts?.forEach((account) => {
          const accountTitle = account?.nickname || account?.name || account?.assumerole_account_id || accountId;
          entry[accountTitle] = 0;
        });
        chartData.push(entry);
      }
      entry[accountTitle] = count;
    });

    chartData?.sort(firstBy('dateTime'));

    setChartData([...chartData]);
  }, [items, accounts]);

  const timeFormatter = (tick) => {
    return new Date(tick).toLocaleTimeString();
  };

  const dateTimeFormatter = (tick) => {
    return new Date(tick).toLocaleString();
  };

  return (
    <>
      {loading && <Spin style={{ marginRight: '8px' }} />}

      {error && <ErrorAlert error={error} />}

      {!loading && chartData?.length > 0 && (
        <>
          {/* <b>Total Events Per Account Per Minute</b>: */}
          <LineChart width={600} height={400} data={chartData}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="dateTime" tickFormatter={timeFormatter} />
            <YAxis tickFormatter={(tick) => tick.toLocaleString()} />
            <Tooltip
              formatter={(value, name, _props) => {
                return [value.toLocaleString(), name];
              }}
              labelFormatter={(label) => dateTimeFormatter(label)}
            />
            {/* <Line type="monotone" dataKey="Total" stroke="#46327e" dot={false} isAnimationActive={false} /> */}
            {filteredAccounts.map((account, index) => {
              const { account_id, nickname, name, assumerole_account_id } = account;
              const accountTitle = nickname || name || assumerole_account_id || account_id;

              return (
                <Line
                  key={accountTitle}
                  type="monotone"
                  dataKey={accountTitle}
                  stroke={getChartColor(index)}
                  dot={false}
                  activeDot={{
                    onClick: (e) => {
                      const { dataKey: name } = e;
                      const account = accounts?.find((a) => a.nickname === name || a.id === name);
                      // console.log({ e, account });
                      if (!account) return;
                      window.open(`/events?eventType=all&accountId=${account.assumerole_account_id}`, '_blank');
                    }
                  }}
                  isAnimationActive={false}
                />
              );
            })}
          </LineChart>
        </>
      )}
    </>
  );
}

import { useContext } from 'react';
import QueryStringContext from 'components/util/QueryString/QueryStringContext';

type PaginationSortDirection = 'ASC' | 'DESC';

type Pagination = {
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  sortDirection?: PaginationSortDirection;
};

// These are the Query String keys that are controlled by Paginator
// The key is how it is referenced in Paginators API
// The value is what gets stored in the Query String
const QS_KEY_HASH = {
  search: 's',
  pageNumber: 'p',
  pageSize: 'size',
  sortBy: 'sortBy',
  sortDirection: 'direction'
};

interface UsePaginatorOptions {
  defaultPageSize: number;
  defaultSortBy?: string;
  defaultSortDirection: PaginationSortDirection;
}

function usePaginator(options: UsePaginatorOptions = { defaultPageSize: 25, defaultSortDirection: 'DESC' }) {
  const qsContext = useContext(QueryStringContext);

  // qs stands for Query String, and is an instance of URLSearchParams
  const qs = qsContext.cloneURLSearchParams();

  const search = qs.get(QS_KEY_HASH.search);
  const pageNumber = getIntegerValueFromURLSearchParams(qs, QS_KEY_HASH.pageNumber, 1);
  const pageSize = getIntegerValueFromURLSearchParams(qs, QS_KEY_HASH.pageSize, options.defaultPageSize);
  const sortBy = qs.get(QS_KEY_HASH.sortBy) || options.defaultSortBy;
  const sortDirection = qs.get(QS_KEY_HASH.sortDirection) || options.defaultSortDirection;

  const filters = convertURLSearchParamsToObject(qs, Object.values(QS_KEY_HASH));

  const scrollToTop = () => window.scrollTo(0, 0);

  const updateSearch = (search: string) => {
    if (search === '') {
      qs.delete(QS_KEY_HASH.search);
    } else {
      qs.set(QS_KEY_HASH.search, search);
    }

    qs.set(QS_KEY_HASH.pageNumber, '1');

    qsContext.replaceURLSearchParams(qs);
    scrollToTop();
  };

  const pushFilter = (name: string, value: string | string[]) => {
    const values = Array.isArray(value) ? value : [value];

    for (const value of values) {
      qs.append(name, value);
    }

    qs.set(QS_KEY_HASH.pageNumber, '1');

    qsContext.replaceURLSearchParams(qs);
    scrollToTop();
  };

  const replaceFilter = (name: string, value: string | string[]) => {
    qs.delete(name);
    pushFilter(name, value);
  };

  const updatePagination = (pagination: Pagination) => {
    const { pageNumber, pageSize, sortBy, sortDirection } = pagination;

    if (pageNumber) qs.set(QS_KEY_HASH.pageNumber, pageNumber.toString());
    if (pageSize) qs.set(QS_KEY_HASH.pageSize, pageSize.toString());
    if (sortBy) qs.set(QS_KEY_HASH.sortBy, sortBy);
    if (sortDirection) qs.set(QS_KEY_HASH.sortDirection, sortDirection);

    qsContext.replaceURLSearchParams(qs);
    scrollToTop();
  };

  return {
    search,
    filters,
    pageNumber,
    pageSize,
    sortBy,
    sortDirection,
    scrollToTop,
    updateSearch,
    pushFilter,
    replaceFilter,
    updatePagination
  };
}

function getIntegerValueFromURLSearchParams(qs: URLSearchParams, key: string, defaultValue: number) {
  const value = qs.get(key);
  if (!value) return defaultValue;
  return parseInt(value, 10);
}

function getKeysFromURLSearchParams(qs: URLSearchParams) {
  let keys: string[] = [];

  qs.forEach((value, key) => {
    keys.push(key);
  });

  return keys;
}

function convertURLSearchParamsToObject(qs: URLSearchParams, ignoredKeys: string[] = []) {
  const keys = getKeysFromURLSearchParams(qs).filter((key) => !ignoredKeys.includes(key));

  return keys.reduce((obj: { [key: string]: string[] }, key) => {
    const values = qs.getAll(key);

    obj[key] = values;

    return obj;
  }, {});
}

export default usePaginator;

import EventTakeAction from 'components/events/EventTakeAction';
import DopeIcon from 'components/ui/DopeIcon';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Location } from 'react-router-dom';
import styled from 'styled-components';
import {
  AUTOMATION_EVENT_DETAIL_QUERY,
  CLASSIFIED_EVENT_DETAIL_QUERY
} from '../../components/ModalsRouter/modals/EventDetailModal/gql';
import { AUTOMATION_FUNCTION_FIELDS_FOR_OPS } from '../Ops/OpDetail/gql';

const AUTOMATION_FUNCTION_BY_ID = gql`
  query AutomationFunctionQueryForOpDetail(
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $id: [String]
  ) {
    automationFunctions(pageSize: $pageSize, sortBy: $sortBy, sortDirection: $sortDirection, id: $id) {
      pageInfo {
        total
        size
      }
      nodes {
        ...AutomationFunctionFieldsForOps
        eventFunctions {
          eventDefinitionId
          functionInputMappings {
            key
            valuePath
          }
        }
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_OPS}
`;

const Root = styled.div`
  .centered-container {
    padding-bottom: 96px; // to get the intercom button out of the way of the pagination controls
    padding-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

interface Props
  extends RouteComponentProps<{
    eventId: string;
    functionId: string;
  }> {
  previousLocation: Location;
}

function EventTakeActionPage(props: Props) {
  const { location, match } = props;
  const {
    params: { eventId, functionId }
  } = match;

  const eventFromState =
    location?.state?.event?.eventId === eventId || location?.state?.event?.id === eventId ? location.state.event : null;

  const automationEventResults = useQuery(AUTOMATION_EVENT_DETAIL_QUERY, {
    variables: {
      eventId: eventId
    },
    skip: eventFromState ? true : false
  });

  const classifiedEventResults = useQuery(CLASSIFIED_EVENT_DETAIL_QUERY, {
    variables: {
      eventId: eventId
    },
    skip: eventFromState || automationEventResults?.loading || automationEventResults?.data?.nodes?.[0]
  });

  const event =
    eventFromState ||
    automationEventResults?.data?.automationEvents?.nodes?.find((n) => n.id === eventId) ||
    classifiedEventResults?.data?.classifiedEvents?.nodes?.find((n) => n.id === eventId || n.eventId === eventId);

  // const asffEvent = event?.eventData?.message?.detail || event?.eventData?.detail;
  // const asffTitle = asffEvent?.findings?.[0].Title || asffEvent?.findings?.[0].title;

  // const name = asffTitle || event?.eventDefinitionName || 'Event';

  const functionFromState = location?.state?.function?.id === functionId ? location.state.function : null;

  const functionResults = useQuery(AUTOMATION_FUNCTION_BY_ID, {
    variables: {
      id: functionId
    },
    skip: functionFromState
  });

  const action =
    functionFromState || functionResults?.data?.automationFunctions?.nodes?.find((n) => n.id === functionId);

  // don't set data until all have finished loading
  const data =
    !automationEventResults?.loading &&
    !classifiedEventResults?.loading &&
    !functionResults?.loading &&
    functionResults?.data &&
    (automationEventResults?.data || classifiedEventResults?.data);

  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            icon={<DopeIcon name="EVENT" size={20} />}
            sectionTitle="Events"
            sectionTitleLinkTo="/events"
            backLinkTo={`/events/${eventId}`}
            backLinkTooltipText="Back to event"
            title={`Take action on event`}
          />
        }
      >
        <CenteredContainer>
          {event ? (
            <EventTakeAction event={event} automationFunction={action} />
          ) : (
            <QueryResult
              data={data}
              loading={automationEventResults?.loading || classifiedEventResults?.loading}
              error={automationEventResults?.error || classifiedEventResults?.error}
            >
              {() => {
                return <EventTakeAction event={event} automationFunction={action} />;
              }}
            </QueryResult>
          )}
        </CenteredContainer>
      </NeoPage>
    </Root>
  );
}

export default EventTakeActionPage;

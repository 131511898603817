import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { useMutation } from 'react-apollo';
import { Form, FormField } from 'components/ui/Form';
import { Input, message, Alert, Button } from 'antd';
import * as Yup from 'yup';

import OnboardingPanel from '../../components/OnboardingPanel';
import gql from 'graphql-tag';
import ErrorAlert from 'components/ui/ErrorAlert';

const CREATE_CLIENT = gql`
  mutation CreateClient($clientId: String!, $input: FirstTimeSetupInput!) {
    createClient(input: $input) 
      @rest(path: "/api/v2/clients/", method: "POST") {
        client_id
      }
  }
`;

const Root = styled.div`
  width: 100%;

  .register-input {
    margin-bottom: 24px;
  }
`;

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  email: Yup.string()
    .strict(true)
    .trim('Leading or trailing whitespace characters are not allowed.')
    .email('Valid email address required.')
    .required('Required')
});

interface Props extends RouteComponentProps<any> {
  inviteUsername?: string;
  inviteCompanyName?: string;
}

function NewAccountPage(props: Props) {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const billingId = params.has('awsCustomerId') ? params.get('awsCustomerId') : undefined;
  const [registeredEmail, setRegisteredEmail] = useState<string | null>(null);
  const [createClient, { error, loading }] = useMutation(CREATE_CLIENT);

  if (registeredEmail) {
    return (
      <OnboardingPanel withLogo title="Registration Complete!">
        <Alert
          type="success"
          message={`An email has been sent to ${registeredEmail} with instructions to setup your new DisruptOps account.`}
        />
      </OnboardingPanel>
    );
  }

  return (
    <OnboardingPanel withLogo title="Register a new DisruptOps account.">
      <Root>
        {error && <ErrorAlert error={error} />}
        <Form
          validationSchema={validationSchema}
          initialValues={{
            companyName: '',
            firstName: '',
            lastName: '',
            username: '',
            email: ''
          }}
          onSubmit={async (values: any, actions: any) => {
            const { companyName, firstName, lastName, username, email } = values;

            try {
              const response = await createClient({
                variables: {
                  input: {
                    company_name: companyName,
                    first_name: firstName,
                    last_name: lastName,
                    username: username,
                    email: email,
                    billingId: billingId,
                    billingVendor: billingId ? 'aws' : undefined
                  }
                }
              });

              if (response.errors)
                throw new Error(response.errors[0].message);

              setRegisteredEmail(email);
            } catch (e) {
              message.error('There was a problem registering your new account.', e.message);
            } finally {
              actions.setSubmitting(false);
            }
          }}
        >
          {(formRenderProps) => {
            const { canSubmit, isSubmitting, submitForm } = formRenderProps;

            return (
              <div className="form-contents">

                <FormField name='companyName' label='Company Name'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>

                <FormField name='firstName' label='First Name'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>

                <FormField name='lastName' label='Last Name'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>

                <FormField name='username' label='Username'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>

                <FormField name='email' label='Email'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>

                <Button
                  onClick={submitForm}
                  type="primary"
                  disabled={!canSubmit || loading || isSubmitting}
                  loading={loading || isSubmitting}
                >
                  Register Client
                </Button>
              </div>
            );
          }}
        </Form>
      </Root>
    </OnboardingPanel>
  );
}

export default NewAccountPage;

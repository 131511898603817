import memoize from 'fast-memoize';
import React from 'react';
import { Query } from 'react-apollo';
import { Select } from 'antd';

import { size } from 'typings';
import { RESOURCE_TYPE_LIST_QUERY } from 'queries';
import styled from 'styled-components';

const Option = Select.Option;

const resourceTypeKeyToValue = memoize((resourceType = '') => resourceType.replace(/::/g, '-'));

const valueToResourceTypeKey = memoize((value = '') => value.replace(/-/g, '::'));

const Root = styled.div`
  .ant-select-selection {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

interface ResourceType {
  key: string;
  name: string;
}

export interface Props {
  onChange: (typeKey: string) => any;
  value: string | null;
  size?: size;
}

function ResourceTypeFilter(props: Props) {
  const { value = '', onChange, size = 'default' } = props;

  return (
    <Root>
      <Query query={RESOURCE_TYPE_LIST_QUERY}>
        {result => {
          const { loading, data, error } = result;
          // should we sort these?

          // how should we handle instances like this?
          if (error) return null;

          return (
            <Select
              showSearch
              size={size}
              value={value ? resourceTypeKeyToValue(value) : ''}
              style={{
                minWidth: '180px'
              }}
              onChange={value => onChange(valueToResourceTypeKey(value))}
              loading={loading}
            >
              <Option value="">All resources</Option>
              {data &&
                data.resource_types &&
                data.resource_types.map((item: ResourceType) => (
                  <Option value={resourceTypeKeyToValue(item.key)} key={item.key}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          );
        }}
      </Query>
    </Root>
  );
}

export default ResourceTypeFilter;

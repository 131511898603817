import React from 'react';
import styled from 'styled-components';

import { UserGroup } from 'typings';

import CloseButton from 'components/buttons/CloseButton';

import UserList from './UserList';
import UserGroupRoleList from './UserGroupRoleList';

const Root = styled.div`
  .user-group-detail-header {
    display: flex;
    justify-content: space-between;
  }

  .user-group-detail-body {
    display: grid;
    grid-template-columns: 2fr 40px 1fr;
    padding: 20px 0px;

    h3 {
      margin-bottom: 0;
    }

    .ant-list {
      color: ${(p) => p.theme.grey800};
    }

    .ant-list-empty-text {
      color: ${(p) => p.theme.grey700};
    }

    .ant-list-split .ant-list-header {
      border-color: ${(p) => p.theme.grey400};
    }

    .user-group-detail-body-role-list {
      grid-column-start: 3;
      grid-row-start: 1;
    }

    .user-group-detail-body-user-list {
      grid-column-start: 1;
      grid-row-start: 1;
    }
  }
`;

interface Props {
  userGroup: UserGroup;
  onClose?: () => any | void;
}

function UserGroupDetail(props: Props) {
  const { userGroup } = props;

  const handleClose = () => {
    const { onClose } = props;

    if (onClose) onClose();
  };

  return (
    <Root>
      <div className="user-group-detail-header">
        <h3>{userGroup.name}</h3>
        <CloseButton onClose={handleClose} />
      </div>

      {userGroup.description && <p>{userGroup.description}</p>}

      <div className="user-group-detail-body">
        <div className="user-group-detail-body-user-list">
          <UserList userGroup={userGroup} />
        </div>

        <div className="user-group-detail-body-role-list">
          <UserGroupRoleList userGroup={userGroup} />
        </div>
      </div>
    </Root>
  );
}

export default UserGroupDetail;

import React, { useState } from 'react';

import { CloudProviderDefinition, PanelChildrenProps } from '../CloudAccountNewForm';
import { CloudAccountPolicyOption } from 'typings';

import * as Yup from 'yup';
import { Alert, Button, Input, message, Typography } from 'antd';
import Form, { FormField } from 'components/ui/Form';
import buildPolicySelect from 'components/cloudAccounts/buildPolicySelect';
import { Field as FormikField } from 'formik';

import { useMutation, useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import { buildAzureProvisionScriptString } from '../helpers';
import Pre from 'components/ui/Pre';
import { refreshCognitoSession } from '../../../../../../services/auth';
import { ADD_ACCOUNT_MUTATION, POLICY_OPTIONS_QUERY } from './common';

export enum CloudProvider {
  aws = 'aws',
  azure = 'azure'
}

export const AZURE_CLOUD_PROVIDER: CloudProviderDefinition = {
  key: CloudProvider.azure,
  name: 'Microsoft Azure',
  panels: [
    {
      key: 'account_info',
      name: 'Account Info',
      children: AzureAccountInfo
    },
    {
      key: 'provision',
      name: 'Provision',
      children: AzureProvisionStep
    }
  ]
};



// Azure Account Info
const ASSUMEROLE_ACCOUNT_ID = 'assumerole_account_id';
const POLICY_CODE = 'policy_code';
const NICKNAME = 'nickname';
const uuidSchemaMessage = 'UUID must be 36 characters long including hyphens.';
const uuidSchema = Yup.string().length(36, uuidSchemaMessage);

// form schema
const azureAccountInfoSchema = Yup.object().shape({
  [ASSUMEROLE_ACCOUNT_ID]: uuidSchema.required(uuidSchemaMessage),

  // could add valid options
  [POLICY_CODE]: Yup.string().required(),

  // not sure what requirements are here...
  [NICKNAME]: Yup.string().required()
});

// Not sure if this will use the same Policy Options query that aws uses?
// Doesn't look like there is anything aws specific in those options at the moment...
function AzureAccountInfo(props: PanelChildrenProps) {
  const { cloudAccount, updateCloudAccountState, updateStep } = props;

  const { loading, error, data } = useQuery(POLICY_OPTIONS_QUERY);

  return (
    <QueryResult loading={loading} data={data} error={error} entityName="Policy options">
      {() => {
        // const { policyOptions } = data;
        const policyOptions: CloudAccountPolicyOption[] = data.policyOptions;
        const defaultPolicyOption = policyOptions.find((item) => item.selected) || null;

        return (
          <Form
            initialValues={{
              [POLICY_CODE]: defaultPolicyOption ? defaultPolicyOption.policy_code : undefined,
              [ASSUMEROLE_ACCOUNT_ID]: cloudAccount.assumerole_account_id || undefined,
              [NICKNAME]: cloudAccount.nickname || undefined
            }}
            onSubmit={(values, actions) => {
              const { assumerole_account_id, policy_code, nickname } = values;

              updateCloudAccountState({
                assumerole_account_id, // what will this be called?
                nickname,
                policy_code
              });

              actions.setSubmitting(false);

              updateStep('provision');
            }}
            validationSchema={azureAccountInfoSchema}
            allowCleanSubmits={false}
          >
            {(formValues) => {
              const { canSubmit, isSubmitting } = formValues;

              return (
                <>
                  <FormField name={ASSUMEROLE_ACCOUNT_ID} label="Azure Subscription ID">
                    {({ name, value, handleChange, handleBlur }) => (
                      <Input value={value} name={name} onChange={handleChange} onBlur={handleBlur} />
                    )}
                  </FormField>

                  <FormField name={POLICY_CODE} label="Select policy">
                    {({ name }) => <FormikField name={name} component={buildPolicySelect({ policyOptions })} />}
                  </FormField>

                  <FormField name={NICKNAME} label="Account Nickname">
                    {({ name, value, handleChange, handleBlur }) => (
                      <Input value={value} name={name} onChange={handleChange} onBlur={handleBlur} />
                    )}
                  </FormField>

                  <Button htmlType={'submit'} type="primary" disabled={!canSubmit || isSubmitting}>
                    {'Next'}
                  </Button>
                </>
              );
            }}
          </Form>
        );
      }}
    </QueryResult>
  );
}

// Provision Azure Account
const AZURE_TENANT_ID = 'azure_tenant_id';
const AZURE_CLIENT_ID = 'azure_client_id';
const PROJECT_ID = 'project_id';
const PROVIDER = 'provider';
const AZURE_CLIENT_SECRET = 'azure_client_secret';
const azureProvisionSchema = Yup.object().shape({
  [AZURE_TENANT_ID]: uuidSchema.required(),
  [AZURE_CLIENT_ID]: Yup.string().required(),
  [AZURE_CLIENT_SECRET]: Yup.string().required(),

  // from previous steps
  [ASSUMEROLE_ACCOUNT_ID]: Yup.string().required(),
  [NICKNAME]: Yup.string().required(),
  [POLICY_CODE]: Yup.string().required(),
  [PROJECT_ID]: Yup.string().required(),
  [PROVIDER]: Yup.string().required()
});

function AzureProvisionStep(props: PanelChildrenProps) {
  const { cloudAccount, updateStep } = props;
  const { assumerole_account_id, policy_code, nickname, project_id, provider } = cloudAccount;
  const [submitError, setSubmitError] = useState<any>(null);

  const [addAccount] = useMutation(ADD_ACCOUNT_MUTATION);

  if (!assumerole_account_id || !policy_code)
    return (
      <Alert
        type="warning"
        message={
          <div>
            <p>{'Subscription'}</p>
          </div>
        }
      />
    );

  const azureScriptStr = buildAzureProvisionScriptString(assumerole_account_id, policy_code);

  return (
    <div>
      <Typography.Title level={3}>{'Azure Subscription Provisioning'}</Typography.Title>
      <Typography.Paragraph>
        {`The following PowerShell script will provision the necessary Active Directory application and service principal that will be used for role-based access control.  This will be applied to the provided subscription ID with the selected role permissions from above.
The following steps will apply the PowerShell script for the provided subscription ID:`}
        <ol>
          <li>
            {
              'Click the "Open Power Shell link to open a new window into Cloud Shell.  This will require Azure authentication for the account which has access to the provided subscription ID.'
            }
          </li>
          <li>
            {
              'Click the "Copy to clipboard" to copy the contents of the PowerShell script, navigate to the Cloud Shell window and paste the script into the terminal.'
            }
          </li>
          <li>
            {`Once the script has been applied, provide the following values from the end of the Cloud Shell output:`}
            <ul>
              <li>{`Tenant ID`}</li>
              <li>{`Client ID`}</li>
              <li>{`Secret ID`}</li>
            </ul>
          </li>
        </ol>
      </Typography.Paragraph>

      <Pre
        actions={
          <a href="https://shell.azure.com/" target="_blank" rel="noopener noreferrer">
            <Button ghost>{'Open Azure Shell'}</Button>
          </a>
        }
      >
        {azureScriptStr}
      </Pre>

      <Form
        onSubmit={async (values, actions) => {
          setSubmitError(null);

          const {
            assumerole_account_id,
            azure_client_id,
            azure_client_secret,
            azure_tenant_id,
            nickname,
            policy_code,
            project_id,
            provider
          } = values;

          const input = {
            assumerole_account_id,
            azure_client_id,
            azure_client_secret,
            azure_tenant_id,
            nickname,
            policy_code,
            project_id,
            provider
          };

          try {
            const response: {
              data?: {
                postAccount: { account_id: string };
              };
            } = await addAccount({ variables: { input } });
            await refreshCognitoSession();

            const accountId = response.data?.postAccount.account_id;

            // this should add ?id= to the search params and cause a rerender/query
            updateStep('provision', accountId);

            actions.setSubmitting(false);
          } catch (e) {
            setSubmitError(e);
            message.error('There was an error saving Azure Account');
            actions.setSubmitting(false);
          }
        }}
        initialValues={{
          assumerole_account_id,
          policy_code,
          nickname,
          project_id,
          provider
        }}
        validationSchema={azureProvisionSchema}
        allowCleanSubmits={false}
      >
        {(formProps) => {
          const { canSubmit, isSubmitting } = formProps;

          return (
            <>
              <FormField name={AZURE_TENANT_ID} label="Azure Tenant ID">
                {({ name, value, handleChange, handleBlur }) => (
                  <Input value={value} name={name} onChange={handleChange} onBlur={handleBlur} />
                )}
              </FormField>

              <FormField name={AZURE_CLIENT_ID} label="Azure Client ID">
                {({ name, value, handleChange, handleBlur }) => (
                  <Input value={value} name={name} onChange={handleChange} onBlur={handleBlur} />
                )}
              </FormField>

              <FormField name={AZURE_CLIENT_SECRET} label="Azure Client Secret">
                {({ name, value, handleChange, handleBlur }) => (
                  <Input value={value} name={name} onChange={handleChange} onBlur={handleBlur} />
                )}
              </FormField>

              {submitError && (
                <Alert
                  message="There was an error creating this Azure Account."
                  description="Please make sure this account has not been added to DisruptOps previously."
                  type="error"
                  style={{ marginBottom: '16px' }}
                />
              )}

              <Button htmlType="submit" type="primary" disabled={!canSubmit || isSubmitting} loading={isSubmitting}>
                {'Submit'}
              </Button>
            </>
          );
        }}
      </Form>
    </div>
  );
}

export default AZURE_CLOUD_PROVIDER;

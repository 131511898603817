import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfiguredAssessment } from 'typings';
import IconButton from 'components/ui/IconButton';
import DopeIcon from 'components/ui/DopeIcon';
import ProjectTree from 'components/project/ProjectTree';
import AuthContext from 'components/app/Auth/AuthContext';

const Root = styled.div`
  background-color: rgba(0, 0, 0, 0.025);
  padding: 6px;
  position: relative;

  .edit-btn-wrap {
    position: absolute;
    top: 6px;
    right: 6px;
  }

  .ant-tree {
    &,
    & * {
      cursor: default; // user must open model to edit?
    }

    li {
      overflow: hidden;
    }
  }

  .ant-tree-treenode-checkbox-checked .ant-tree-child-tree .ant-tree-checkbox {
    .ant-tree-checkbox-inner {
      background-color: #fff;
      border-color: #d9d9d9;

      &:after {
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        background-color: #673ab7;
        border: 0;
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        content: ' ';
      }
    }
  }

  .cloud-account-tree-node .ant-tree-node-content-wrapper {
    padding: 0 5px 0 28px;
  }
`;

interface Props {
  assessment: ConfiguredAssessment;
  onEditScope: () => void;
}

function ProjectScope(props: Props) {
  const {
    assessment: { projectId },
    onEditScope
  } = props;

  const authContext = useContext(AuthContext);
  const rootProjectId = authContext?.rootProjectId || 'ROOT';

  const checkedProjectIds = [projectId || rootProjectId];

  return (
    <Root>
      <ProjectTree checkedProjectIds={checkedProjectIds} cascade allowMultipleNodes rootProjectId={projectId} />

      <div className="edit-btn-wrap">
        <IconButton onClick={onEditScope} type="ghost" size="small">
          <DopeIcon name="EDIT" />
        </IconButton>
      </div>
    </Root>
  );
}

export default ProjectScope;

import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { Button, Popconfirm, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

const Root = styled.div``;

const PopconfirmTitle = styled.div`
  max-width: 350px;
`;

export interface Props {
  id: string;
  icon?: string;
  disabled?: boolean;
  disabledMessage?: string;
  hideBorder?: boolean;
  confirmMessage?: string;
  confirmLabel?: string;
  confirmPlacement?: string;
  classes?: {
    root?: string;
    button?: string;
  };
  onConfirm: (id: string) => any | void;
  loading?: boolean;
  level?: string;
  children?: string | ReactNode;
  mode?: 'default' | 'primary' | 'danger';
  tooltipPlacement?: TooltipPlacement;
}

function ConfirmButton(props: Props) {
  const {
    id,
    icon,
    disabled,
    disabledMessage,
    confirmLabel,
    confirmMessage,
    confirmPlacement,
    classes = {},
    hideBorder = false,
    onConfirm,
    loading = false,
    children = null,
    mode = 'default',
    tooltipPlacement = 'top'
  } = props;

  let btnStyle: CSSProperties = {};

  if (hideBorder) {
    btnStyle.border = '0';
    btnStyle.padding = '0';
    btnStyle.height = 'auto';
  }

  if (disabled && disabledMessage) {
    return (
      <Tooltip trigger="hover" title={disabledMessage} placement={tooltipPlacement}>
        <Button type={mode} icon={icon} style={btnStyle} className={classes.button || ''} disabled>
          {children}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Root className={classes.root || ''}>
      <Popconfirm
        title={<PopconfirmTitle>{confirmMessage || `Are you sure?`}</PopconfirmTitle>}
        okText={confirmLabel || 'Ok'}
        onConfirm={() => {
          onConfirm(id);
        }}
        placement={(confirmPlacement as any) || 'right'}
      >
        <Button
          type={mode}
          icon={icon}
          className={classes.button || ''}
          loading={loading}
          disabled={disabled}
          style={btnStyle}
        >
          {children}
        </Button>
      </Popconfirm>
    </Root>
  );
}

export default ConfirmButton;

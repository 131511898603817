import React, { SFC } from 'react';
import styled from 'styled-components';

export interface Props {
  childen?: React.ReactNode;
  style?: object;
  color?: string;
}

const BodyTitleDiv = styled.div`
  color: ${(props: Props) => props.color || '#000'};
  font-size: 24px;
  font-weight: bold;
  line-height: 31px;
`;

const BodyNormalDiv = styled.div`
  color: ${(props: Props) => props.color || '#4A4A4A'};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`;

const CardTitleDiv = styled.div`
  color: ${(props: Props) => props.color || '#28384b'};
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
`;

const CardDetailDiv = styled.div`
  color: ${(props: Props) => props.color || '#28384b'};
  opacity: 0.5;
  font-size: 12px;
  font-weight: bold;
  line-height: 15px;
`;

const PanelTitleDiv = styled.div`
  color: ${(props: Props) => props.color || '#4A4A4A'};
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 18px;
  margin-top: 18px;
`;

const PanelSubtitleDiv = styled.div`
  color: ${(props: Props) => props.color || '#4A4A4A'};
  font-size: 17px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 18px;
  margin-top: 18px;
`;

const PanelNormalDiv = styled.div`
  color: ${(props: Props) => props.color || '#4A4A4A'};
  font-size: 19px;
  font-weight: 300;
  line-height: 24px;
`;

const StatusBadDiv = styled.div`
  color: #cc0000;
}

`;

const StatusGoodDiv = styled.div`
  color: #4BB543;
  &:after {
    content: '\u2713';
    font-size: 36px;
    height: 90px;
    padding-left: 36px;
    text-align: center;
    vertical-align: middle;
  }
}`;

const makeTextComponent = (
  StyledDiv: React.ComponentClass,
  Icon?: React.ComponentClass,
  iconSize?: number
): SFC<Props> => props => {
  let { children } = props;
  let icon = undefined as any;
  if (Icon) {
    icon = React.createElement(Icon, { size: iconSize ? iconSize : 36 } as any);
  }
  return (
    <StyledDiv {...props}>
      {children}
      {icon}
    </StyledDiv>
  );
};

export const BodyTitle = makeTextComponent(BodyTitleDiv);
export const CardTitle = makeTextComponent(CardTitleDiv);
export const CardDetail = makeTextComponent(CardDetailDiv);
export const BodyNormal = makeTextComponent(BodyNormalDiv);
export const PanelTitle = makeTextComponent(PanelTitleDiv);
export const PanelSubtitle = makeTextComponent(PanelSubtitleDiv);
export const PanelNormal = makeTextComponent(PanelNormalDiv);
export const StatusBad = makeTextComponent(StatusBadDiv);
export const StatusGood = makeTextComponent(StatusGoodDiv);

import { Button, Icon } from 'antd';
import ErrorAlert from 'components/ui/ErrorAlert';
import React, { useEffect } from 'react';
import DelayedLoader from 'designSystem/DelayedLoader/DelayedLoader';
import { FilterType } from '../FilterListing/FilterListing';
import { TriggerFilterEditorDiv } from './FilterRow.styles';
import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';

export interface SoftEdit {
  key: string;
  value: any;
}

interface FilterRowProps {
  filterType: FilterType;
  setFieldValue(field: string, value: any): void;
  editor: React.ReactFragment;
  softEdit: SoftEdit | null;
  onDelete?: Function;
  loading?: boolean;
  error?: any;
}

function FilterRow(props: FilterRowProps) {
  const { filterType, setFieldValue, editor, softEdit, onDelete, loading, error } = props;

  function makeChanges() {
    if (!softEdit) return;

    setFieldValue(softEdit.key, softEdit.value);
  }

  function clearFilter() {
    setFieldValue(filterType.valueKey, []);
    onDelete && onDelete(filterType);
  }

  useEffect(makeChanges, [softEdit]);

  return (
    <HorizontalLabel label={filterType.label} form>
      <TriggerFilterEditorDiv>
        <div className="main-contents">
          {error && <ErrorAlert showIcon={true} message={`Could not load ${filterType.label}`} error={error} />}
          {loading && <DelayedLoader />}
          {!loading && !error && (
            <div data-testid="editor-pane" className="editor-pane">
              {editor}
            </div>
          )}
        </div>

        <div className="actions">
          <Button data-testid="clear-filter" type="link" onClick={clearFilter}>
            <Icon type="delete" />
          </Button>
        </div>
      </TriggerFilterEditorDiv>
    </HorizontalLabel>
  );
}

export default FilterRow;

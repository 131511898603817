import React, { useEffect } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { SectionHeadingHeader } from './SectionHeading.styles';

interface PageHeadingProps {
  title: string;
  subTitle?: string | null;
  icon?: JSX.Element;
  helpText?: string;
  children?: any;
}

function SectionHeading(props: PageHeadingProps) {
  const { title, subTitle, helpText, children, icon } = props;

  const titles = ['DisruptOps'];
  if (title) titles.push(title);
  if (subTitle) titles.push(subTitle);
  const documentTitle = titles.join(' | ');

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return (
    <SectionHeadingHeader>
      {icon && (
        <div className="icon" data-testid="icon">
          {icon}
        </div>
      )}
      <h1>{title}</h1>
      {helpText && (
        <Tooltip placement="bottom" title={helpText} arrowPointAtCenter>
          <QuestionCircleOutlined />
        </Tooltip>
      )}
      {children && <div className="children">{children}</div>}
    </SectionHeadingHeader>
  );
}

export default SectionHeading;

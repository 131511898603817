import React from 'react';
import { message } from 'antd';

import { GuardrailActionsSectionProps } from '../GuardrailActionsSection';
import { GuardrailActionProvider } from '../definitions';
import { GuardrailEditorStep } from '../../../GuardrailEditor';

interface Props extends GuardrailActionsSectionProps {
  actionProviderDefinition: GuardrailActionProvider;
}

function GuardrailConfigureAction(props: Props) {
  const { guardrail, saveGuardrailMutationFn, actionProviderDefinition, setActiveStep } = props;

  // TEMP UNTIL WE SUPPORT MULTIPLE ACTIONS
  const { configurationInput: ConfigurationInput } = actionProviderDefinition;

  return (
    <ConfigurationInput
      guardrail={guardrail}
      onSubmit={async configuration => {
        const { staticConfiguration, dynamicConfiguration, jsonpathConfiguration } = configuration;

        try {
          const updatedGuardrail: any = {
            id: guardrail.id,
            staticConfiguration,
            dynamicConfiguration,
            jsonpathConfiguration
          };

          await saveGuardrailMutationFn({ variables: { guardrail: updatedGuardrail } });

          setActiveStep(GuardrailEditorStep.NONE);
        } catch (e) {
          message.error('There was a problem updating this Guardrail');
        }
      }}
    />
  );
}

export default GuardrailConfigureAction;

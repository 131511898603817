import AttachedDynamicConfiguration from 'components/dynamicConfig/AttachedDynamicConfiguration';
import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import React from 'react';
import { DetectorGeneralProps } from '../General/General';
import DetectorProject from './DetectorProject/DetectorProject';
import { FunctionParameter } from 'typings';
import { typedPropertyOrDefault } from 'utilities/helpers';
import { FunctionReadView } from 'components/function';
import { DetectorFiltersDiv } from './DetectorFilters.style';

function DetectorFilters(props: DetectorGeneralProps) {
  const { detector } = props,
    { staticConfiguration, dynamicConfiguration } = detector,
    emptyParameters: FunctionParameter[] = [],
    parameters = typedPropertyOrDefault(detector.assessor.parameters, emptyParameters);

  function formatFilterValue(parameter) {
    const { key } = parameter,
      matchingDynamicConfiguration = (dynamicConfiguration && dynamicConfiguration.find((i) => i.key === key)) || null;

    let value = null;

    if (staticConfiguration && staticConfiguration[key] !== undefined) {
      value = staticConfiguration[key];
    }

    if (matchingDynamicConfiguration && matchingDynamicConfiguration.node) {
      return <AttachedDynamicConfiguration attachedDynamicConfiguration={matchingDynamicConfiguration.node} />;
    } else if (value) {
      return <FunctionReadView functionParameter={parameter} value={value} />;
    } else {
      return <>--</>;
    }
  }

  return (
    <DetectorFiltersDiv>
      <HorizontalLabel label="Project Scope">
        <DetectorProject detector={detector} />
      </HorizontalLabel>
      {parameters.map((parameter, i) => (
        <HorizontalLabel key={i} label={parameter.name || '--'} helpText={parameter.description || undefined}>
          {formatFilterValue(parameter)}
        </HorizontalLabel>
      ))}
    </DetectorFiltersDiv>
  );
}

export default DetectorFilters;

import gql from 'graphql-tag';

export const GUARDRAIL_FIELDS = gql`
  fragment GuardrailFields on Guardrail {
    id
    projectId
    isEnabled
    name
    description
    trigger {
      eventType
      eventSource
      configuration
    }
    filtersConfiguration

    assessmentId
    issueDefinitionId

    functionId
    automationFunctionId

    staticConfiguration
    jsonpathConfiguration
    createdAt
    updatedAt
    archivedAt

    actor {
      id
      name
    }

    decisionIntegration
    decisionType
    decisionExpiration
    decisionRecipientId

    notificationIntegration
    notificationRecipientId

    dynamicConfigurations {
      id
      key
      node {
        id
        key
        value
      }
    }
  }
`;

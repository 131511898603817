import React from 'react';
import SectionHeading from 'designSystem/SectionHeading/SectionHeading';
import { BellFilled } from '@ant-design/icons';
import { OpsSection } from '../Ops';

function Alerts() {
  return (
    <div>
      <SectionHeading title="Alerts" icon={<BellFilled />} />
      <OpsSection />
    </div>
  );
}

export default Alerts;

import React, { useState } from 'react';
import { Maybe } from 'typings';
import { Button, Typography } from 'antd';
import ProjectTree from 'components/project/ProjectTree';
import DopeIcon from 'components/ui/DopeIcon';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import ProjectOwnershipModal from 'components/project/ProjectOwnershipModal';
import { useHistory } from 'react-router-dom';

interface OpContextControlProps {
  projectId?: Maybe<string>;
  onChange: (projectId: string) => void;
}

/**
 * Project determines permissions
 * Project should set the highest level scope that the Op acts within.
 * Triggered events should happen within this context
 */
function OpContextControl(props: OpContextControlProps) {
  const { projectId, onChange } = props;
  const [modalIsVisible, setModalIsVisible] = useState<boolean>(!Boolean(projectId)); // defaults to open if !projectId

  const history = useHistory();

  return (
    <>
      <div>
        {projectId ? (
          <div className="project-tree-wrap">
            <ProjectTree
              checkable={false}
              cascade={false}
              allowMultipleNodes={false}
              checkedProjectIds={[projectId]}
              rootProjectId={projectId}
              onSelect={(projectId) => history.push(`/projects/${projectId}`) }
            />
          </div>
        ) : (
          <div>
            <Typography.Text>{'Context Project has not been set.'}</Typography.Text>
          </div>
        )}

        <Button size="small" type="primary" ghost className="btn-with-icon" onClick={() => setModalIsVisible(true)}>
          <DopeIcon name="EDIT" size={10} />
          {'Change'}
        </Button>
      </div>

      <ProjectOwnershipModal
        title="Set Op Ownership"
        visible={modalIsVisible}
        projectId={projectId ? projectId : undefined}
        requireSelection={true}
        requiredPermissions={Permissions.MODIFY_ASSESSMENTS}
        loading={false}
        permissionsExplanations="Only users with permissions to this project or a parent project can view (View Op) or change (Modify Op) this Op."
        onChange={async (projectId) => {
          onChange(projectId);
          setModalIsVisible(false);
        }}
        onCancel={() => setModalIsVisible(false)}
      />
    </>
  );
}

export default OpContextControl;

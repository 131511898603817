import React from 'react';
import Paginator from 'components/app/Paginator';
import { Link } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import AssessmentValidator from './AssessmentValidator';
import { Assessment } from 'typings';
import AssessmentEnableSwitch from './AssessmentEnableSwitch';
import { Tooltip, Popover, message } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import DateTime from 'components/ui/DateTime';
import { Mutation, OperationVariables, Query } from 'react-apollo';
import gql from 'graphql-tag';
import { DocumentNode } from 'graphql';
import { PopoverMenuItem } from 'components/ui/Popover';
import IconButton from 'components/ui/IconButton';
import QueryResult from 'components/util/QueryResult';
import Table from 'components/ui/Table';
import IssueStatsBadge from 'components/issue/IssueStatsBadge';
import { DataProxy } from 'apollo-cache';
import { FilterRow, FilterItem } from 'components/app/FilterBar/components';
import { SearchFilter, LabelsFilter } from 'components/app/FilterBar/filters';
import cronToText from 'cron-to-text';

import Label from 'components/ui/Label';
import { FetchResult } from 'apollo-link';

const DELETE_ASSESSMENT = gql`
  mutation DeleteAssessment($id: ID!) {
    deleteAssessment(id: $id)
  }
`;

const SORT_BY_MAP = {
  name: 'name',
  isEnabled: 'is_enabled',
  lastRanAt: 'last_ran_at',
  updated_at: 'updated_at'
};

const SORT_ORDER_MAP = {
  ascend: 'ASC',
  descend: 'DESC'
};

interface Props {
  query: DocumentNode;
  onAssessmentDelete: (
    assessmentId: string,
    variables: OperationVariables
  ) => (cache: DataProxy, result: FetchResult<any>) => any;
  variables?: OperationVariables;
}

const FILTERS = [
  {
    name: 'labels',
    label: 'Label',
    qsKey: 'labels',
    many: true
  }
];

function AssessmentTable(props: Props) {
  const { query, onAssessmentDelete, variables = {} } = props;

  return (
    <Paginator pageSize={20} filters={FILTERS}>
      {(pageRenderProps) => {
        const {
          pageNumber,
          pageSize,
          sortBy,
          sortDirection,
          updateSearchParams,
          updateSearch,
          search,
          filterValues,
          pushFilters,
          replaceFilters
        } = pageRenderProps;

        const assessmentQueryVariables = {
          search,
          labels: filterValues.labels,
          ...variables,
          sortBy,
          sortDirection,
          pageNumber,
          pageSize
        };

        const allColumns = [
          {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            sorter: true,
            render: (text, item) => {
              const assessmentURL = `/detectors/${item.id}`;

              return (
                <div>
                  <Link to={assessmentURL}>{text}</Link>
                  <br />
                  <Text type="secondary">{item.assessor.description}</Text>
                </div>
              );
            }
          },
          {
            title: 'Cloud Vendor',
            key: 'cloudVendor',
            className: 'no-wrap',
            render: (_, assessment: Assessment) => {
              const cloudVendor = assessment?.assessor?.cloudVendor || '';

              return (
                <div>
                  {cloudVendor.toLowerCase() === 'aws' ? <DopeIcon name="AWS" size={40} /> : null}
                  {cloudVendor.toLowerCase() === 'azure' ? <DopeIcon name="AZURE" size={35} /> : null}
                </div>
              );
            }
          },
          {
            title: 'Enabled',
            key: 'isEnabled',
            dataIndex: 'isEnabled',
            sorter: true,
            render: (isEnabled, assessment: Assessment) => {
              const assessmentId = assessment?.id;
              if (!assessmentId) return null;

              return (
                <AssessmentValidator assessment={assessment}>
                  {({ isValid, isValidating }) => {
                    const button = (
                      <AssessmentEnableSwitch
                        isEnabled={isEnabled}
                        assessmentId={assessmentId}
                        buttonDisabled={isValidating || !isValid}
                      />
                    );
                    return isValid ? (
                      button
                    ) : (
                      <Tooltip title="Detector's filters must be configured before enabling.">
                        <div>{button}</div>
                      </Tooltip>
                    );
                  }}
                </AssessmentValidator>
              );
            }
          },
          {
            title: 'Labels',
            key: 'labels',
            dataIndex: 'labels',
            render: (labels, assessment: Assessment) => {
              if (!labels && !assessment?.assessor?.labels) return null;

              const assessorLabels = assessment?.assessor?.labels;
              const assessmentLabels = assessment?.labels?.filter((l) => !assessorLabels?.some((al) => al === l));

              return (
                <div>
                  {assessmentLabels &&
                    assessmentLabels.map((l, idx) => (
                      <Label
                        key={`${l}_${idx}`}
                        onClick={() => {
                          replaceFilters({
                            search: '',
                            s: '',
                            labels: l
                          });
                        }}
                        label={l}
                      />
                    ))}
                  {assessorLabels &&
                    assessorLabels.map((l, idx) => (
                      <Label
                        key={`${l}_${idx}`}
                        onClick={() => {
                          replaceFilters({
                            search: '',
                            s: '',
                            labels: l
                          });
                        }}
                        label={l}
                        color="blue"
                        title="System Label"
                      />
                    ))}
                </div>
              );
            }
          },
          {
            title: 'Issues',
            key: 'issues',
            className: 'no-wrap',
            render: (_, assessment: Assessment) => {
              const { issueStats, lastRanAt } = assessment;

              if (!lastRanAt) return 'N/A';

              return (
                <div className="issue-stats">
                  <IssueStatsBadge issueStats={issueStats} />
                </div>
              );
            }
          },
          {
            title: 'Last run',
            key: 'lastRanAt',
            sorter: true,
            dataIndex: 'lastRanAt',
            className: 'no-wrap',
            render: (lastRun, assessment: Assessment) => {
              const schedule = assessment.schedule;
              let next;
              try {
                next = cronToText(schedule);
              } catch (e) {
                // console.log({ e });
              }
              if (!lastRun) return <span title={next}>--</span>;

              return <DateTime dateTime={lastRun} appendTitle={` > ${next}`} />;
            }
          },
          {
            title: 'Version',
            key: 'version',
            dataIndex: 'assessor.version'
          },
          {
            key: 'isReadOnly',
            render: (value, item) =>
              item.isReadOnly === true && (
                <Tooltip title="System Detector" placement="top">
                  <DopeIcon name="LOCK" />
                </Tooltip>
              )
          },
          {
            key: 'options',
            render: (text, item) => (
              <Popover
                placement="rightBottom"
                content={
                  <div className="menu">
                    <Mutation
                      mutation={DELETE_ASSESSMENT}
                      update={onAssessmentDelete(item.id, assessmentQueryVariables)}
                    >
                      {(deleteAssessment, { loading }) => (
                        <PopoverMenuItem
                          onClick={async () => {
                            const variables = {
                              id: item.id
                            };
                            const result = await deleteAssessment({ variables });
                            if (result && result.errors) {
                              message.error('Failed to delete detector');
                            } else {
                              message.success('Successfully deleted detector');
                            }
                          }}
                          disabled={loading}
                          label={!loading ? 'Delete' : 'Deleting'}
                        />
                      )}
                    </Mutation>
                  </div>
                }
                trigger={'click'}
              >
                <IconButton type="ghost" size="small">
                  <DopeIcon name="MORE_VERTICAL" />
                </IconButton>
              </Popover>
            )
          }
        ];

        const columns = allColumns;

        return (
          <>
            <FilterRow>
              <FilterItem label="Search">
                <SearchFilter search={search} onSearchChange={updateSearch} />
              </FilterItem>
              <FilterItem label="Labels">
                <LabelsFilter
                  value={(Array.isArray(filterValues.labels) && filterValues.labels) || []}
                  onChange={(value) => {
                    pushFilters('labels', value);
                  }}
                />
              </FilterItem>
            </FilterRow>
            <Query query={query} variables={assessmentQueryVariables}>
              {({ loading, data, error }) => (
                <QueryResult loading={loading} data={data} error={error}>
                  {() => {
                    const { nodes: assessments, pageInfo } = data?.assessments || {};

                    return (
                      <Table
                        antTableProps={{
                          loading,
                          dataSource: assessments,
                          rowKey: 'id',
                          columns,
                          locale: {
                            emptyText: 'No matching Assessments'
                          },
                          onChange: (pagination, filters, sorter, a) => {
                            updateSearchParams({
                              sortBy: SORT_BY_MAP[sorter.columnKey] || 'updated_at',
                              sortDirection: SORT_ORDER_MAP[sorter.order] || SORT_ORDER_MAP.descend,
                              page: pagination.current || 1
                            });
                          },
                          pagination: {
                            pageSize,
                            current: pageInfo && pageInfo.current,
                            total: pageInfo && pageInfo.total,
                            onChange: (pageNumber) => {
                              updateSearchParams({
                                page: pageNumber
                              });
                            }
                          }
                        }}
                      />
                    );
                  }}
                </QueryResult>
              )}
            </Query>
          </>
        );
      }}
    </Paginator>
  );
}

export default AssessmentTable;

import React from 'react';
import styled from 'styled-components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button, message } from 'antd';
import DopeIcon from './DopeIcon';

const Root = styled.div`
  background-color: #2e2e2e;
  margin-bottom: 16px;

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 8px 16px;

    > * {
      margin-left: 8px;
    }
  }

  .text-block {
    color: ${p => p.theme.grey50};
    position: relative;

    .text-block-inner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }

  code {
    display: block;
  }

  pre {
    overflow: auto;
    display: block;
    padding: 16px;
  }
`;

interface TextBlockProps {
  height?: number; // deefault to 400;
}

interface Props {
  actions?: React.ReactNode;
  children: string;
  textBlockProps?: TextBlockProps;
}

function Pre(props: Props) {
  const { children, actions } = props;
  const textBlockProps = props.textBlockProps || {};

  return (
    <Root className="pre-root">
      <div className="actions">
        {actions || null}

        <CopyToClipboard text={children} onCopy={() => message.success('Copied to clipboard')}>
          <Button ghost className="btn-with-icon">
            <DopeIcon name="COPY" />
            {'Copy to clipboard'}
          </Button>
        </CopyToClipboard>
      </div>
      <div className="text-block" style={{ height: `${textBlockProps.height || 400}px` }}>
        <div className="text-block-inner">
          <pre>
            <code>{children}</code>
          </pre>
        </div>
      </div>
    </Root>
  );
}

export default Pre;

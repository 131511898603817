import { Button, Descriptions, message } from 'antd';
import { RecipientIcon, RecipientLabels, RecipientTypes } from 'components/recipients/common';
import DateTime from 'components/ui/DateTime';
import ErrorAlert from 'components/ui/ErrorAlert';
import JSONTree from 'components/ui/JSONTree';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Recipient } from 'typings';

const RECIPIENT_QUERY = gql`
  query recipient($id: String) {
    recipients(id: $id) {
      items {
        id
        name
        type
        config
        notificationId
        createdAt
        createdBy
        updatedAt
        updatedBy
        isEnabled
        lastUsedAt
        lastErrorAt
        lastError
      }
    }
  }
`;

const TEST_RECIPIENT = gql`
  mutation TestRecipient($id: String!) {
    testRecipient(id: $id) {
      recipient {
        id
        name
        config
      }
      alert
      error
    }
  }
`;

const StyleWrapper = styled.div`
  .top-margin {
    margin-top: 8px;
  }
  .left-margin {
    margin-left: 4px;
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

function RecipientDetails(props: Props) {
  const { match } = props;
  const {
    params: { id }
  } = match;

  const history = useHistory();

  const { loading: queryLoading, data: queryData, error: queryError } = useQuery(RECIPIENT_QUERY, {
    variables: {
      id
    }
  });

  const recipient: Recipient = queryData?.recipients?.items[0];

  const [testRecipient, { loading: isTesting, error: testError }] = useMutation(TEST_RECIPIENT);

  return (
    <QueryResult data={queryData} loading={queryLoading} error={queryError}>
      {() => {
        const { name, type = '', createdAt, updatedAt, lastUsedAt, lastErrorAt, lastError, config } = recipient || {};

        return (
          <StyleWrapper>
            <Descriptions column={1}>
              <Descriptions.Item label="Name">{name}</Descriptions.Item>
              <Descriptions.Item label="Type">
                <RecipientIcon recipient={recipient} size={12} />
                <span className="left-margin">{RecipientTypes[type]}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Details">
                {RecipientLabels[type] ? RecipientLabels[type](recipient) : 'unknown'}
              </Descriptions.Item>
              <Descriptions.Item label="Created">
                <DateTime dateTime={createdAt} />
              </Descriptions.Item>
              <Descriptions.Item label="Updated">
                <DateTime dateTime={updatedAt} />
              </Descriptions.Item>
              <Descriptions.Item label="Last Used">
                <DateTime dateTime={lastUsedAt} />
              </Descriptions.Item>
              <Descriptions.Item label="Last Failed">
                <DateTime dateTime={lastErrorAt} />
              </Descriptions.Item>
              <Descriptions.Item label="Last Error">{lastError}</Descriptions.Item>
            </Descriptions>

            <Button
              icon="check"
              disabled={isTesting}
              loading={isTesting}
              onClick={async (e) => {
                const variables = { id };
                await testRecipient({ variables });
                message.success(`Success! A test message has been sent to the recipient.`);
              }}
            >
              Test
            </Button>

            <Button
              icon="edit"
              style={{ marginLeft: '8px' }}
              onClick={() => {
                history.push(`/organization-settings/notifications/recipients/${id}/edit`);
              }}
            >
              Edit
            </Button>

            {testError && <ErrorAlert error={testError} />}

            <Descriptions column={1} style={{ marginTop: '16px' }}>
              <Descriptions.Item label="Raw Config">&nbsp;</Descriptions.Item>
            </Descriptions>
            <JSONTree data={config} depth={1} />
          </StyleWrapper>
        );
      }}
    </QueryResult>
  );
}

export default RecipientDetails;

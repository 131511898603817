import { FormatRecipient } from 'app/sections/Ops/OpsCommon/RecipientFormat/RecipientFormat';
import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import React from 'react';
import { Op } from 'typings';
import AlertActions from './AlertActions/AlertActions';
import AlertButtons from './AlertButtons/AlertButtons';
import Expiration from './Expiration/Expiration';
import ExpirationAction from './ExpirationAction/ExpirationAction';

interface ComponentProps {
  op: Op;
  indexedConfigs: any;
  contextData: any;
}

function DisruptOpsAlert(props: ComponentProps) {
  const {
    indexedConfigs,
    contextData: {
      recipients: { items: recipients }
    }
  } = props;

  // console.clear();
  // console.log('parameters', op.function);
  // console.log('configuration', op.configuration);

  const recipientId = indexedConfigs.alertRecipientId.value;

  return (
    <>
      <HorizontalLabel label="Alert Channel">
        <FormatRecipient recipientId={recipientId} recipients={recipients} />
      </HorizontalLabel>
      <HorizontalLabel label="Alert Buttons">
        <AlertButtons indexedConfigs={indexedConfigs} recipients={recipients} />
      </HorizontalLabel>
      <HorizontalLabel label="Custom Action Buttons">
        <AlertActions indexedConfigs={indexedConfigs} />
      </HorizontalLabel>
      <HorizontalLabel label="Expiration">
        <Expiration indexedConfigs={indexedConfigs} />
      </HorizontalLabel>
      <HorizontalLabel label="Expiration Action">
        <ExpirationAction indexedConfigs={indexedConfigs} />
      </HorizontalLabel>
    </>
  );
}

export default DisruptOpsAlert;

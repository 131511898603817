import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Card } from 'antd';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ClientProject } from 'typings';

import { DeleteButton } from 'components/buttons';
import ErrorAlert from 'components/ui/ErrorAlert';
import QueryResult from 'components/util/QueryResult';
import { useProjectTree, PROJECT_TREE_QUERY } from 'components/project/ProjectTree';
import { refreshCognitoSession } from '../../../../../services/auth';

const Root = styled.div`
  display: grid;
  grid-template-columns: auto 20px auto;

  .delete-project-status {
    justify-self: center;
  }

  .delete-project-status-button {
    overflow: hidden;
    grid-column: 3;
    align-self: center;
    justify-self: center;
  }
`;

const CAN_DELETE_PROJECT_QUERY = gql`
  query CanDeleteProject($projectId: String, $projectIds: [String]) {
    assessments(pageSize: 1, targetProjectIds: $projectIds) {
      pageInfo {
        total
      }
    }

    ops(pageSize: 1, projectId: $projectIds) {
      pageInfo {
        total
      }
    }

    accounts(projectId: $projectId) @rest(path: "/api/v2/projects/{args.projectId}/accounts", type: "ClientAccount") {
      account_id
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation DeleteProject($id: String!) {
    deleteProject(id: $id) @rest(type: "ClientProject", path: "/api/v2/projects/{args.id}", method: "DELETE") {
      project_id
      name
    }
  }
`;

interface Props {
  project: ClientProject;
}

function DeleteProjectCard(props: Props) {
  const { project } = props;
  const projectId = project.project_id;

  const history = useHistory();

  const [childProjectIds, setChildProjectIds] = useState<null | string[]>(null);

  const { getChildProjects } = useProjectTree({
    onProjectsLoaded: (projects) => {
      const childProjects = projectId ? getChildProjects(projectId) : [];
      setChildProjectIds(childProjects.map((project) => project.project_id));
    }
  });

  const { loading, error, data: canDeleteProjectData } = useQuery(CAN_DELETE_PROJECT_QUERY, {
    variables: { projectId, projectIds: [projectId] }
  });

  const opCount = canDeleteProjectData?.ops?.pageInfo?.total || 0;
  const assessmentCount = canDeleteProjectData?.assessments?.pageInfo?.total || 0;
  const cloudAccountCount = canDeleteProjectData?.accounts?.length || 0;
  const childProjectCount = childProjectIds?.length || 0;

  const [deleteProject, { loading: isDeletingProject, error: deleteError }] = useMutation(DELETE_PROJECT, {
    refetchQueries: [{ query: PROJECT_TREE_QUERY }]
  });

  const handleDelete = async (id) => {
    await deleteProject({
      variables: { id }
    });

    await refreshCognitoSession();
    history.push(`/projects/${project.parent_id || ''}`);
  };

  const disabledMessage =
    isProjectDeleteDisabled({
      isProjectSystemControlled: project.is_system_controlled ? true : false,
      opCount,
      assessmentCount,
      cloudAccountCount,
      childProjectCount,
      error,
      loading: loading || childProjectIds === null
    }) || '';

  const isDisabled = disabledMessage ? true : false;

  return (
    <Card title="Delete Project">
      {deleteError && <ErrorAlert error={deleteError} />}

      <QueryResult
        loading={loading}
        error={error}
        data={canDeleteProjectData}
        loadingCenterVertically={false}
        loadingHeight={100}
      >
        {() => (
          <Root>
            <div className="delete-project-status">
              {isDisabled ? (
                <>
                  <p>
                    Before you can delete this Project, you must manually cleanup the resources that are attached to it:
                  </p>

                  <ul>
                    {cloudAccountCount ? (
                      <li>
                        {cloudAccountCount} Cloud Account{simplePlural(cloudAccountCount)}
                      </li>
                    ) : null}

                    {opCount ? (
                      <li>
                        {opCount} Op{simplePlural(opCount)}
                      </li>
                    ) : null}

                    {assessmentCount ? (
                      <li>
                        {assessmentCount} Detector{simplePlural(assessmentCount)}
                      </li>
                    ) : null}

                    {childProjectCount ? (
                      <li>
                        {childProjectCount} Child Project{simplePlural(childProjectCount)}
                      </li>
                    ) : null}
                  </ul>
                </>
              ) : (
                <div>This project has no related resources, you can delete this project.</div>
              )}
            </div>

            <div className="delete-project-status-button">
              <DeleteButton
                id={project.project_id}
                disabled={isDisabled}
                loading={loading || isDeletingProject}
                disabledMessage={disabledMessage}
                confirmMessage="Are you sure you want to delete this Project?"
                onDelete={handleDelete}
              >
                Delete Project
              </DeleteButton>
            </div>
          </Root>
        )}
      </QueryResult>
    </Card>
  );
}

interface IsDeleteDisabledArgs {
  isProjectSystemControlled: boolean;
  opCount: number;
  assessmentCount: number;
  cloudAccountCount: number;
  childProjectCount: number;
  error: any;
  loading: boolean;
}

function simplePlural(n: number) {
  return n !== 1 ? 's' : '';
}

function isProjectDeleteDisabled(args: IsDeleteDisabledArgs) {
  const {
    isProjectSystemControlled,
    loading,
    error,
    cloudAccountCount,
    assessmentCount,
    opCount,
    childProjectCount
  } = args;

  if (isProjectSystemControlled) {
    return 'Cannot delete Root Project';
  }

  if (loading) {
    return 'Cannot delete project until information about related resources has been loaded.';
  }

  if (error) {
    return 'Error fetching related resources, cannot delete this Project';
  }

  if (cloudAccountCount) {
    return 'Move Cloud Accounts to a new project before deleting.';
  }

  if (assessmentCount) {
    return 'Delete or move Assessments associated with this project before deleting.';
  }

  if (opCount) {
    return 'Delete or move Ops associated with this project before deleting.';
  }

  if (childProjectCount) {
    return 'Move Child Projects before deleting.';
  }

  return false;
}

export default DeleteProjectCard;

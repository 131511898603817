import React from 'react';
import { Config } from 'typings';
import { CenteredContainer } from 'components/ui/NeoPage';

import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';

import CONFIG_SETTINGS from 'fixtures/DynamicConfig';

import DynamicConfigSettings from 'components/dynamicConfig/DynamicConfigSettings';

const ROOT_DYNAMIC_CONFIG = gql`
  query RootDynamicConfig {
    dynamicConfigurations(entityType: ORGANIZATION, pageSize: 100) {
      pageInfo {
        total
        current
        sortBy
      }
      nodes {
        id
        entityId
        entityType
        key
        value
      }
    }
  }
`;

function RootDynamicConfiguration() {
  const { loading, error, data } = useQuery(ROOT_DYNAMIC_CONFIG);

  const handleMutationUpdate = (cache, result) => {
    const savedConfig =
      (result.data && result.data.updateDynamicConfiguration) ||
      (result.data && result.data.createDynamicConfiguration);

    const data: any = cache.readQuery({ query: ROOT_DYNAMIC_CONFIG });

    const configExistsInCache = data.dynamicConfigurations.nodes.find((i) => i.id === savedConfig.id) || null;

    if (!configExistsInCache) {
      data.dynamicConfigurations.nodes.push(savedConfig);
    }

    cache.writeQuery({ query: ROOT_DYNAMIC_CONFIG, data });
  };

  const handleDelete = (cache, result) => {
    return;
    // console.log('update after delete', { result });
    // we can't remove
  };

  return (
    <QueryResult
      loading={loading}
      data={data}
      error={error}
      parseNodes={(data) => data?.dynamicConfigurations?.nodes || []}
    >
      {(savedConfigs: Config[]) => (
        <CenteredContainer size="fluid">
          <DynamicConfigSettings
            configSettingGroups={CONFIG_SETTINGS}
            savedConfigs={savedConfigs}
            entityType={'ORGANIZATION'}
            mutationUpdateFn={handleMutationUpdate}
            updateCacheOnDeleteFn={handleDelete}
          />
        </CenteredContainer>
      )}
    </QueryResult>
  );
}

export default RootDynamicConfiguration;

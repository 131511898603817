import { Assessment } from 'typings';

export const typeDef = `
  extend type Assessment {
    isActive: Boolean
  }
`;

export const resolvers = {
  Assessment: {
    isActive: async (assessment: Assessment) => false
  }
};

import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Mutation, MutationFunction } from 'react-apollo';
import OnboardingPanel from '../../components/OnboardingPanel';
import { Button, Input, Alert } from 'antd';
import ErrorAlert from 'components/ui/ErrorAlert';

import { Form, FormField } from 'components/ui/Form';

const FormWrap = styled.div`
  width: 100%;

  form {
    width: 100%;
  }

  .success-alert p {
    margin-bottom: 0;
  }

  .signin-link {
    display: inline-block;
    margin-left: 8px;
  }
`;

const ActionsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  flex-direction: column;

  .submit-btn {
    margin-bottom: 16px;
  }
`;

const SET_PASSWORD_MUTATION = gql`
  mutation SetPassword($input: ForcePasswordInput!) {
    setPassword(input: $input) @rest(type: "Post", path: "/auth/forcepw", method: "POST") {
      access_token
      id_token
      refresh_token
      trinity_data
    }
  }
`;

const FormSchema = Yup.object().shape({
  username: Yup.string().email(),
  newPassword: Yup.string()
    .min(6, 'Password must be at least 6 characters long')
    .matches(/\d/, 'Password must contain number')
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match') // must match 'newPassword' value
    .required('Required')
});

const inputHeight = '80px';

export interface Props extends RouteComponentProps<any> {}

interface State {
  username: string;
  code: string;
  error: any;
  activated: boolean;
}

interface FormValues {
  newPassword: string;
  confirmPassword: string;
  username: string;
}

class Invite extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      error: null,
      username: '',
      code: '',
      activated: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.checkSearchParams = this.checkSearchParams.bind(this);
    this.handleRemoveError = this.handleRemoveError.bind(this);
  }

  componentDidMount() {
    this.checkSearchParams();
  }

  checkSearchParams() {
    // url search params should have 'username' and 'code'(old password);
    const { username, code } = this.props.match.params;
    const { history } = this.props;

    if (!username || !code) {
      // redirect to signup? not sure what the behavior for this case should be.
      history.push('/new-account');
    } else {
      this.setState({
        username,
        code
      });
    }
  }

  handleSubmit(setPassword: MutationFunction) {
    return (formValues: Partial<FormValues>, actions: any) => {
      const { newPassword } = formValues;
      const { username, code } = this.state;

      const input = {
        username,
        requested: newPassword,
        current: code
      };

      setPassword({ variables: { input } })
        .then(result => {
          return this.setState({ activated: true });

          /*
          // This is meant to log the user in after they have set a password.
          // It currently does not work because the cookies sent back by the server are expired.
          // success response.data.description == 'success' ??
    
          const { refresh_token, access_token, id_token, trinity_data: ws_token } = response.data.setPassword;
    
          // if successful create session
          await createSession({ refresh_token, access_token, id_token, ws_token });
    
          // amplify...
          await getIdentity();
    
          //history.push('/');
          */
        })
        .catch(error => {
          actions.resetForm({});

          this.setState({ error });
        });
    };
  }

  handleRemoveError() {
    this.setState({ error: null });
  }

  render() {
    const { error, activated } = this.state;

    const initialValues: FormValues = {
      newPassword: '',
      confirmPassword: '',
      username: this.state.username
    };

    const username = this.state.username;

    return (
      <OnboardingPanel withLogo title="Activate invited user account">
        <Mutation mutation={SET_PASSWORD_MUTATION}>
          {setPassword => (
            <Form initialValues={initialValues} onSubmit={this.handleSubmit(setPassword)} validationSchema={FormSchema}>
              {({ canSubmit }) => {
                return (
                  <FormWrap id="form-wrap">
                    {error && (
                      <ErrorAlert
                        error={error}
                        onClose={this.handleRemoveError}
                        description={'This account could not be activated'}
                      />
                    )}

                    {!activated ? (
                      <>
                        <FormField name="username" label="Username">
                          {() => (
                            <Input
                              title="Username"
                              name="username"
                              height={inputHeight}
                              width="100%"
                              value={username}
                              placeholder=""
                              disabled
                            />
                          )}
                        </FormField>

                        <FormField name="newPassword" label="New password">
                          {({ handleChange, handleBlur, value, name }) => (
                            <Input
                              type="password"
                              name={name}
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              width="100%"
                            />
                          )}
                        </FormField>

                        <FormField name="confirmPassword" label="Confirm new password">
                          {({ handleChange, handleBlur, value, name }) => (
                            <Input
                              type="password"
                              name={name}
                              value={value}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                              width="100%"
                            />
                          )}
                        </FormField>

                        <ActionsWrap>
                          <Button
                            disabled={!canSubmit}
                            htmlType="submit"
                            loading={false}
                            type="primary"
                            size="large"
                            className="submit-btn"
                          >
                            Submit
                          </Button>
                          <Link to="/login">Login</Link>
                        </ActionsWrap>
                      </>
                    ) : (
                      <Alert
                        className="success-alert"
                        type="success"
                        showIcon
                        message={
                          <p>
                            {'Your account has been activated.'}
                            <Link
                              className="signin-link"
                              to={{
                                pathname: '/login',
                                state: {
                                  email: username
                                }
                              }}
                            >
                              {'Sign in'}
                            </Link>
                          </p>
                        }
                      />
                    )}
                  </FormWrap>
                );
              }}
            </Form>
          )}
        </Mutation>
      </OnboardingPanel>
    );
  }
}

export default Invite;

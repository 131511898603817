import styled from 'styled-components';

export const Root = styled.div`
  .actions {
    margin-top: 1rem;
  }

  .alert-wrapper {
    margin-top: 1rem;
  }

  .select-wrapper {
    margin-bottom: 1rem;
  }
`;

import React from 'react';
import styled from 'styled-components';
import { FormField } from 'components/ui/Form';
import { FormRenderProps } from 'components/ui/Form/Form';
import { Switch, Typography, Button } from 'antd';
import IconButton from 'components/ui/IconButton';
import DopeIcon from 'components/ui/DopeIcon';

import { NotificationRecipient } from '../../types';
// import { RECIPIENT_TYPE_DEFINITIONS } from 'components/recipients/common';

import RecipientTypeSelect from 'components/recipients/RecipientTypeSelect';
import RecipientList from 'components/recipients/RecipientList';
import JSONTree from 'components/ui/JSONTree';
import { getIn } from 'formik';

const Root = styled.div`
  margin-bottom: 24px;
  border: 1px solid #ddd;
  border-radius: 4px;

  .recipient-card-header {
    display: flex;
    align-items: center;
    line-height: 1em;
    padding: 6px 16px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 4px 4px 0 0;
  }

  .recipient-enabled-form-field {
    margin-bottom: 0;
    flex: 1;
    margin: 0 16px;
  }
  
  .recipient-enabled-wrapper {
    display: flex;
    align-items: center;

    > span {
      margin-right: 8px;
    }
  }

  .recipient-card-header-title {
    font-weight: 600;
  }

  .recipient-card-body {
    padding: 16px
    position: relative;
  }

  .recipient-list-wrap {
    max-height: 600px;
    overflow-y: auto;
  }
`;

interface Props {
  notification: NotificationRecipient;
  name: string;
  formProps: FormRenderProps;
  onRemove: () => void;
  index: number;
}

function RecipientRow(props: Props) {
  const {
    name,
    // notification: recipient,
    formProps: {
      setFieldValue,
      setFieldTouched,
      values
      // touched
    },
    onRemove,
    index
  } = props;

  // const option = RECIPIENT_TYPE_DEFINITIONS.find(o => o.key === recipient.type) || null;
  // const ConfigurationComponent = option ? option.formControls : null;

  const cardTitle = `Recipient #${index + 1}`;

  return (
    <Root>
      <div className="recipient-card-header">
        <div className="recipient-card-header-title">{cardTitle}</div>

        <FormField name={`${name}.isEnabled`} hideLabel className="recipient-enabled-form-field">
          {({ name, value }) => {
            return (
              <div className="recipient-enabled-wrapper">
                <span>
                  {`Notifications to this recipient are turned `}
                  <strong>{`${value ? 'ON' : 'OFF'}`}</strong>
                </span>
                <Switch
                  checked={value}
                  onChange={(checked) => {
                    // const isEnabled = getIn(values, name);
                    // console.log({name, isEnabled, value, checked, touched, values});
                    setFieldValue(name, checked);
                    setFieldTouched(name, true);
                  }}
                />
              </div>
            );
          }}
        </FormField>

        <div className="recipient-card-header-actions">
          <IconButton type="ghost" onClick={onRemove} size="small">
            <DopeIcon name="REMOVE" />
          </IconButton>
        </div>
      </div>
      <div className="recipient-card-body">
        <FormField name={`${name}.type`} label="Type" wrapInFormItem={false}>
          {({ value: recipientType, handleChange }) => {
            if (!recipientType)
              return (
                <>
                  <RecipientTypeSelect onSelect={(type) => handleChange(type)} />

                  <Typography.Text type="secondary">{'Use existing Recipient'}</Typography.Text>
                  <div className="recipient-list-wrap">
                    <RecipientList
                      actions={[
                        {
                          render: (recipient) => (
                            <Button
                              key="useRecipientButton"
                              onClick={() => {
                                setFieldValue(name, recipient);
                                // commenting this out fixed TypeError: Cannot create property 'isEnabled' on boolean 'true'
                                // https://app.clubhouse.io/disruptops/story/7181/notification-recipient-ui-errors
                                // https://github.com/formium/formik/issues/378
                                // setFieldTouched(name, true);
                              }}
                            >
                              {'Use Recipient'}
                            </Button>
                          )
                        }
                      ]}
                    />
                  </div>
                </>
              );

            const recipientConfig = getIn(values, `${name}.config`);
            return (
              // ConfigurationComponent && <ConfigurationComponent formRenderProps={formProps} prefix={`${name}.config`} />
              <JSONTree data={recipientConfig} depth={1} />
            );
          }}
        </FormField>
      </div>
    </Root>
  );
}

export default RecipientRow;

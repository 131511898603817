import gql from 'graphql-tag';

export const OP_FIELDS = gql`
  fragment OpFields on Op {
    id
    clientId
    functionId
    function {
      id
      name
      effects
    }

    projectId
    eventSourceId
    eventSource {
      id
      name
      key
    }
    eventDefinitionId
    eventDefinition {
      id
      name
      vendor
      vendorProduct
    }

    # new trigger filters
    triggerProjectIds
    accountIds
    regions
    assessmentId
    environments
    accountLabels
    jsonPaths {
      type
      path
      patterns
    }
    resourceType
    severity
    tags {
      key
      value
    }
    triggerFilters

    isEnabled
    name
    description
    configuration {
      key
      value
      type
    }
    decisionType
    decisionIntegration
    decisionExpiration
    decisionRecipientId
    isSuccessNotificationEnabled
    successNotificationRecipientSource
    successNotificationRecipientId
    isFailureNotificationEnabled
    failureNotificationRecipientSource
    failureNotificationRecipientId
    # actionResults

    createdById
    createdByUsername
    createdAt
    updatedAt
    archivedAt
    deletedAt
  }
`;

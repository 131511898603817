import { Permissions } from '@disruptops/neo-core';
import { message, Tooltip, Typography, Spin } from 'antd';
import { AssessmentEnableSwitch } from 'components/assessment';
import { AssessmentValidationState } from 'components/assessment/AssessmentValidator';
import ProjectOwnershipModal from 'components/project/ProjectOwnershipModal';
import DateTime from 'components/ui/DateTime';
import DopeIcon from 'components/ui/DopeIcon';
import Label from 'components/ui/Label';
import Meta, { MetaRow, MetaRowGroup } from 'components/ui/Meta';
import VerticalTable from 'components/ui/Table/VerticalTable';
import theme from 'constants/theme';
import cronToText from 'cron-to-text';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { ConfiguredAssessment } from 'typings';
import AddAssessmentLabelsButton from './AddAssessmentLabelsButton';
import ProjectScope from './ProjectScope';

const Root = styled.div`
  margin-top: 32px;

  .vt-table {
    margin-bottom: 32px;
  }

  .vt-label-col {
    width: 175px;
    padding-right: 16px;
  }

  .vt-value-col {
    padding: 16px;
    text-align: right;
  }

  .meta-row-group {
  }

  .meta-group-label {
    color: #888888;
    font-size: 14px;
    padding-left: 16px;
  }

  .issues-summary-meta {
    .meta-group-content {
      padding-left: 8px;
    }

    .meta-row {
      border: none;
    }
  }

  .issue-meta-label {
    font-size: 12px;
  }

  .assessment-labels-form,
  .labels-btn-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 8px;
  }

  .ui-label {
    margin: 0px 0px 2px 4px;
  }
`;

const UPDATED_ASSESSMENT_OWNERSHIP_MUTATION = gql`
  mutation UpdateAssessmentScope($id: ID!, $input: AssessmentUpdateInput) {
    updateAssessment(id: $id, assessmentInput: $input) {
      id
      projectId
    }
  }
`;

const ASSESSMENT_DETAIL_ISSUE_STATS_QUERY = gql`
  query AssessmentDetail($id: [String]!) {
    assessments(id: $id) {
      nodes {
        id
        issueStats {
          openIssueCount
          exemptedIssueCount
          resolvedIssueCount
        }
      }
    }
  }
`;

interface Props
  extends RouteComponentProps<{
    action?: string;
    assessmentTab?: string;
  }> {
  assessment: ConfiguredAssessment;
  validationBag: AssessmentValidationState;
  linkPrefix?: string;
}

function AssessmentMetaTable(props: Props) {
  const {
    linkPrefix = '',
    assessment,
    assessment: { id: assessmentId, lastRanAt, assessor },
    validationBag: { isValid, isValidating },
    match: {
      params: { action, assessmentTab }
    },
    history
  } = props;

  const { labels: assessorLabels } = assessor || {};

  const {
    data: issueStatsData,
    error: issueStatsError,
    loading: issueStatsLoading
    // refetch: issueStatsRefetch
  } = useQuery(ASSESSMENT_DETAIL_ISSUE_STATS_QUERY, {
    variables: { id: [assessmentId] }
  });

  const issueStats = issueStatsData?.assessments?.nodes?.[0]?.issueStats;

  const rootPath = `${linkPrefix}/detectors/${assessment.id}`;

  const ROWS = [
    {
      key: 'enabled',
      dataIndex: 'isEnabled',
      label: 'Enabled',
      render: (isEnabled) => {
        const button = (
          <AssessmentEnableSwitch
            isEnabled={isEnabled || false}
            assessmentId={assessment.id}
            buttonDisabled={isValidating || !isValid}
          />
        );

        return isValid ? (
          button
        ) : (
          <Tooltip title="Filters must be properly configured before enabling assessment.">
            <div>{button}</div>
          </Tooltip>
        );
      }
    },
    {
      key: 'labels',
      dataIndex: 'labels',
      label: 'Labels',
      render: (labels) => {
        return (
          <div>
            <div className="labels-btn-row">
              <AddAssessmentLabelsButton
                appliedLabels={labels}
                assessorLabels={assessorLabels}
                assessmentId={assessment.id}
              />
            </div>

            {labels &&
              labels.map((l, idx) => {
                return (
                  <Label
                    key={`${l}_${idx}`}
                    label={l}
                    onClick={() => {
                      const searchParams = new URLSearchParams();
                      searchParams.set('labels', l);

                      history.push({
                        pathname: '/detectors',
                        search: searchParams.toString()
                      });
                    }}
                  />
                );
              })}
          </div>
        );
      }
    },
    {
      key: 'lastRanAt',
      dataIndex: 'lastRanAt',
      label: 'Last run',
      render: () => {
        const schedule = assessment.schedule;
        let next;
        try {
          next = cronToText(schedule);
        } catch (e) {
          // console.log({ e });
        }

        return lastRanAt ? <DateTime dateTime={lastRanAt} appendTitle={next && ` > ${next}`} /> : '--';
      }
    }
  ];

  if (assessorLabels && assessor.labels.length) {
    ROWS.splice(ROWS.length - 1, 0, {
      key: 'assessor.labels',
      dataIndex: 'assessor',
      label: 'System Labels',
      render: (assessor) => {
        return (
          <div>
            {assessorLabels.map((l, idx) => {
              return (
                <Label
                  key={`${l}_${idx}`}
                  label={l}
                  color="blue"
                  onClick={() => {
                    const searchParams = new URLSearchParams();
                    searchParams.set('labels', l);

                    history.push({
                      pathname: '/detectors',
                      search: searchParams.toString()
                    });
                  }}
                />
              );
            })}
          </div>
        );
      }
    });
  }

  const [updateAssessment, { loading }] = useMutation(UPDATED_ASSESSMENT_OWNERSHIP_MUTATION);

  return (
    <Root>
      <ProjectOwnershipModal
        title="Set Detector Ownership"
        visible={action === 'edit-scope'}
        projectId={assessment.projectId}
        requireSelection={true}
        requiredPermissions={Permissions.MODIFY_ASSESSMENTS}
        loading={loading}
        permissionsExplanations="Only users with permissions to this project or a parent project can view (View Detector) or change (Modify Detector) this Detector."
        onChange={async (projectId) => {
          const variables = {
            id: assessment.id,
            input: {
              projectId: projectId
            }
          };

          try {
            const result = await updateAssessment({
              variables
            });
            const newAssessment: { id: string } = result.data.updateAssessment;
            message.success('Detector ownership was successfully updated.');
            history.push(`/detectors/${newAssessment.id}`);
          } catch (e) {
            message.error('There was an error updating Detector ownership.');
          }
        }}
        onCancel={() => {
          history.replace(`/detectors/${assessment.id}/${assessmentTab}`);
        }}
      />

      <VerticalTable dataSource={assessment} type="review" rows={ROWS} />

      <MetaRowGroup>
        <Meta
          label="Ownership"
          classes={{
            root: 'issues-summary-meta',
            label: 'meta-group-label',
            content: 'meta-group-content'
          }}
        >
          <ProjectScope
            assessment={assessment}
            onEditScope={() => {
              history.push(`/detectors/${assessment.id}/${assessmentTab}/edit-scope`);
            }}
          />
        </Meta>
      </MetaRowGroup>

      <MetaRowGroup>
        <Meta
          label="Issues summary"
          classes={{
            root: 'issues-summary-meta',
            label: 'meta-group-label',
            content: 'meta-group-content'
          }}
        >
          {issueStatsError?.message && issueStatsError.message}
          {issueStatsLoading && <Spin />}
          {lastRanAt && issueStats && (
            <>
              <MetaRow icon={<DopeIcon name="ISSUE_OPEN" color={theme.warning} />} to={`${rootPath}/issues`}>
                <Meta orientation="horizontal" label="Open" classes={{ label: 'issue-meta-label' }}>
                  {issueStats.openIssueCount}
                </Meta>
              </MetaRow>
              <MetaRow
                icon={<DopeIcon name="ISSUE_RESOLVED" color={theme.success} />}
                to={`${rootPath}/issues?status=resolved`}
              >
                <Meta orientation="horizontal" label="Resolved" classes={{ label: 'issue-meta-label' }}>
                  {issueStats.resolvedIssueCount}
                </Meta>
              </MetaRow>
              <MetaRow
                icon={<DopeIcon name="ISSUE_EXEMPT" color={theme.grey} />}
                to={`${rootPath}/issues?status=exempt`}
              >
                <Meta orientation="horizontal" label="Exempt" classes={{ label: 'issue-meta-label' }}>
                  {issueStats.exemptedIssueCount}
                </Meta>
              </MetaRow>
            </>
          )}
          {!lastRanAt && (
            <Typography style={{ paddingLeft: '8px' }}>
              {'Detector must run before in order to display issues'}
            </Typography>
          )}
        </Meta>
      </MetaRowGroup>
    </Root>
  );
}

export default AssessmentMetaTable;

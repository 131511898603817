import { Spin } from 'ant4x';
import DopeIcon from 'components/ui/DopeIcon';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import { Detector } from 'typings';
import IssuesSummary from '../../DetectorsCommon/IssuesSummary/IssuesSummary';
import { IssuesDetail } from './Issues.styles';

const DETECTOR_DETAIL_ISSUE_STATS_QUERY = gql`
  query DetectorDetailIssueStats($id: [String]!) {
    assessments(id: $id) {
      nodes {
        id
        issueStats {
          openIssueCount
          exemptedIssueCount
          resolvedIssueCount
        }
      }
    }
  }
`;
interface IssuesProps {
  detector: Detector;
}

function Issues(props: IssuesProps) {
  const { detector } = props;
  const { id } = detector || {};

  const { data: issueStatsData, error: issueStatsError, loading: issueStatsLoading } = useQuery(
    DETECTOR_DETAIL_ISSUE_STATS_QUERY,
    {
      variables: { id: [id] }
    }
  );

  const issueStats = issueStatsData?.assessments?.nodes?.[0]?.issueStats;

  return (
    <IssuesDetail>
      {issueStatsError && issueStatsError.message}
      {issueStatsLoading && <Spin />}
      {issueStats && <IssuesSummary summary={issueStats} />}

      <Link
        className="issues-link"
        to={{
          pathname: '/issues',
          search: `assessmentId=${detector.id}`
        }}
      >
        <DopeIcon name="ISSUES" size={16} /> View All Related Issues
      </Link>
    </IssuesDetail>
  );
}

export default Issues;

import React, { useState } from 'react';
import { DeleteFilled, SettingFilled } from '@ant-design/icons';
import { Button } from 'antd';
import DetectorBanner from '../DetectorsCommon/DetectorsBanner/DetectorsBanner';
import General from './General/General';
import Issues from './Issues/Issues';
import DetailPanel, {
  DetailPanelActions,
  DetailPanelScollable,
  DetailPanelScrollableSection
} from 'designSystem/DetailPanel/DetailPanel';
import { useHistory } from 'react-router-dom';
import DiscoverableIssues from './DiscoverableIssues/DiscoverableIssues';
import { Detector } from '../DetectorEditor/DetectorEditor';
import DetectorFilters from './Filters/DetectorFilters';
import DeleteDetectorModal from './DeleteDetectorModal/DeleteDetectorModal';
import useQueryString from '../DetectorsCommon/useQueryString/useQueryString';
import useDetectorVariants from '../DetectorsCommon/useDetectorVariants/useDetectorVariants';

interface DetailProps {
  detector?: Detector;
  onRefetchNeeded: () => void;
}

function DetectorDetail(props: DetailProps) {
  const { detector, onRefetchNeeded } = props,
    variants = useDetectorVariants(),
    querystring = useQueryString(),
    [deleteDetectorModalVisible, setDeleteDetectorModalVisible] = useState<boolean>(false),
    history = useHistory();

  return (
    <DetailPanel closeLink={`${variants.basePath}${querystring}`}>
      {detector && (
        <>
          <DeleteDetectorModal
            onClosed={() => setDeleteDetectorModalVisible(false)}
            onSuccess={() => onRefetchNeeded && onRefetchNeeded()}
            detector={detector}
            visible={deleteDetectorModalVisible}
          />

          <DetectorBanner detector={detector} />

          <DetailPanelActions>
            <Button
              onClick={() => {
                history.push(`${variants.basePath}/${detector.id}/edit${querystring}`);
              }}
              type="primary"
            >
              <SettingFilled />
              Configure {variants.variantName}
            </Button>

            <Button
              onClick={() => setDeleteDetectorModalVisible(true)}
              disabled={detector.isReadOnly}
              title={
                detector.isReadOnly
                  ? `This ${variants.variantName} was created automatically by DisruptOps and can be disabled, but cannot be deleted.`
                  : ''
              }
            >
              <DeleteFilled />
              Delete {variants.variantName}
            </Button>
          </DetailPanelActions>

          <DetailPanelScollable>
            <DetailPanelScrollableSection title="General">
              <General detector={detector} />
            </DetailPanelScrollableSection>
            <DetailPanelScrollableSection title="Issues">
              <Issues detector={detector} />
            </DetailPanelScrollableSection>
            <DetailPanelScrollableSection title="Filters">
              <DetectorFilters detector={detector} />
            </DetailPanelScrollableSection>
            <DetailPanelScrollableSection title="Discoverable Issues">
              <DiscoverableIssues detector={detector} />
            </DetailPanelScrollableSection>
          </DetailPanelScollable>
        </>
      )}
    </DetailPanel>
  );
}

export default DetectorDetail;

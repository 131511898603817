import React from 'react';
import { Collapse } from 'antd';

import { GridCard } from 'components/ui/Card';
import DopeIcon from 'components/ui/DopeIcon';

import { GuardrailEditorState, GuardrailEditorStep } from '../../GuardrailEditor';

import CollapseHeader from 'components/ui/Collapse/CollapseHeader';
import GuardrailSelectAction from './components/GuardrailSelectAction';
import GuardrailConfigureAction from './components/GuardrailConfigureAction';

import ACTION_PROVIDER_DEFINITIONS from './definitions';
import { NewGuardrail } from 'typings';

const { Panel } = Collapse;

export interface GuardrailActionsSectionProps extends GuardrailEditorState {}

/**
 *
 * This takes into account trigger definition.
 * if (!trigger def) return help text
 *
 * trigger:
 */

function getActionProviderFromGuardrail(guardrail: NewGuardrail): null | string {
  const { functionId, automationFunctionId } = guardrail;

  if (functionId !== null && functionId !== undefined && typeof functionId === 'string') {
    return 'DISRUPTOPS_ACTION';
  }

  if (automationFunctionId !== null && automationFunctionId !== undefined && typeof automationFunctionId === 'string') {
    return 'AUTOMATION_ACTION';
  }

  return null;
}

function GuardrailActionsSection(props: GuardrailActionsSectionProps) {
  const { guardrail, activeStep, setActiveStep } = props;

  const actionProviderKey = getActionProviderFromGuardrail(guardrail);
  const actionProviderDefinition = ACTION_PROVIDER_DEFINITIONS.find((item) => item.key === actionProviderKey) || null;
  const SelectActionReadView = (actionProviderDefinition && actionProviderDefinition.selectActionReadView) || null;

  const SectionReadView = (actionProviderDefinition && actionProviderDefinition.sectionReadView) || null;

  return (
    <GridCard
      secondaryTitle="Action"
      title={
        SectionReadView ? (
          <SectionReadView
            functionId={guardrail.functionId}
            automationFunctionId={guardrail.automationFunctionId || null}
          />
        ) : (
          'Select Action'
        )
      }
      icon={<DopeIcon name="ACTION" size={20} color={'#673AB7'} />}
      elevation={1}
      rounded
    >
      <Collapse
        activeKey={activeStep}
        bordered={false}
        accordion
        onChange={(selected) => {
          if (!selected) {
            return setActiveStep(GuardrailEditorStep.NONE);
          }

          const step = Object.values(GuardrailEditorStep).find((step) => step === selected);

          if (!step) {
            return setActiveStep(GuardrailEditorStep.NONE);
          }

          return setActiveStep(step);
        }}
      >
        <Panel
          key={GuardrailEditorStep.SELECT_ACTION}
          header={
            <CollapseHeader
              headerTitle="Action:"
              headerContent={
                SelectActionReadView ? (
                  <SelectActionReadView
                    functionId={guardrail.functionId || null}
                    automationFunctionId={guardrail.automationFunctionId || null}
                  />
                ) : null
              }
            />
          }
        >
          <GuardrailSelectAction {...props} />
        </Panel>
        {actionProviderDefinition && (
          <Panel key={GuardrailEditorStep.CONFIGURE_ACTION} header={<CollapseHeader headerTitle="Configure" />}>
            <GuardrailConfigureAction {...props} actionProviderDefinition={actionProviderDefinition} />
          </Panel>
        )}
      </Collapse>
    </GridCard>
  );
}

export default GuardrailActionsSection;

import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';

import { SlackConfig, ExistingNotificationRecipient, NotificationRecipient } from 'typings';
import { useInitSlackAuth } from 'app/sections/Authenticated/pages/OrganizationSettings/pages/Notifications/components/NotificationForm/SlackRecipient/InitSlackAuthButton';
import DopeIcon from 'components/ui/DopeIcon';
import QueryResult from 'components/util/QueryResult';
import RECIPIENT_FIELDS from 'queries/fragments/recipientFields';
import RECIPIENT_LIST_QUERY from 'queries/recipientListQuery';

const SAVE_RECIPIENT = gql`
  mutation SaveRecipient($recipient: SaveRecipientInput!) {
    saveRecipient(recipient: $recipient) {
      ...RecipientFields
    }
  }
  ${RECIPIENT_FIELDS}
`;

interface SlackRecipientControlProps {
  recipientId?: string;
  onSelect: (recipientId?: string) => void;
  onClear: () => void;
}

// move this to it's own file.
export function SlackRecipientControl(props: SlackRecipientControlProps) {
  const { recipientId, onSelect, onClear } = props;
  const results = useQuery(RECIPIENT_LIST_QUERY, {
    variables: { id: recipientId },
    skip: !recipientId
  });

  return recipientId ? (
    <QueryResult
      {...results}
      skip={!recipientId}
      parseNodes={data => data?.recipients?.items}
      expectSingleNode
      loadingCenterVertically={false}
      loadingHeight={70}
    >
      {(recipient: ExistingNotificationRecipient) => {
        return (
          <SlackRecipientListItem
            recipient={recipient}
            action={<Button onClick={() => onClear()}>{'Select different Recipient'}</Button>}
          />
        );
      }}
    </QueryResult>
  ) : (
    <SelectSlackRecipient onSelect={onSelect} />
  );
}

// COMPONENT: SELECT SLACK RECIPIENT
const SelectSlackRecipientRoot = styled.div`
  max-height: 300px;
  overflow-y: auto;
`;

interface SelectSlackRecipientProps {
  onSelect: (recipientId: string) => void;
}

function SelectSlackRecipient(props: SelectSlackRecipientProps) {
  const { onSelect } = props;
  const results = useQuery(RECIPIENT_LIST_QUERY, { variables: { type: ['SLACK'] } });

  const [saveRecipient] = useMutation(SAVE_RECIPIENT);
  const initSlackAuthProps = useInitSlackAuth();

  return (
    <QueryResult
      {...results}
      parseNodes={data => data?.recipients?.items || []}
      loadingCenterVertically={false}
      loadingHeight={300}
    >
      {(recipients: ExistingNotificationRecipient[]) => {
        return (
          <SelectSlackRecipientRoot>
            {recipients.map(recipient => (
              <SlackRecipientListItem
                key={recipient.id}
                recipient={recipient}
                action={
                  <Button onClick={() => onSelect(recipient.id)} type="primary">
                    {'Use this Recipient'}
                  </Button>
                }
              />
            ))}

            {!initSlackAuthProps.initializing && (
              <InitSlackConnectionRoot
                onClick={async () => {
                  if (!initSlackAuthProps.initSlackAuth) return;

                  const config = await initSlackAuthProps.initSlackAuth();
                  const recipientInput = {
                    type: 'SLACK',
                    config
                  };
                  const result = await saveRecipient({ variables: { recipient: recipientInput } });
                  const savedRecipient = result.data?.saveRecipient;

                  onSelect(savedRecipient.id);
                }}
              >
                <div className="init-slack-connection-label">{'+ Add Slack Recipient'}</div>
              </InitSlackConnectionRoot>
            )}
          </SelectSlackRecipientRoot>
        );
      }}
    </QueryResult>
  );
}

const InitSlackConnectionRoot = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }

  .init-slack-connection-label {
    color: ${p => p.theme.primary};
    font-weight: 600;
  }
`;

// COMPONENT: SLACK RECIPIENT LIST ITEM
const SlackRecipientListItemRoot = styled.div`
  margin-bottom: 24px;
  padding: 6px;
  display: flex;
  &:hover {
    background-color: #f9f9f9;
  }

  .slack-recipient-list-item-meta {
    flex: 1 1 auto;
  }

  .slack-recipient-list-item-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .slack-recipient-list-item-action {
    margin-left: 16px;
  }
`;

interface SlackRecipientListItemProps {
  recipient: ExistingNotificationRecipient | NotificationRecipient;
  action?: React.ReactNode;
}

export function SlackRecipientListItem(props: SlackRecipientListItemProps) {
  const { recipient, action } = props;
  const config: any = recipient.config; // funky...
  const slackConfig: SlackConfig = config;

  return (
    <SlackRecipientListItemRoot>
      <div className="slack-recipient-list-item-icon">
        <DopeIcon name="SLACK" size={20} />
      </div>
      <div className="slack-recipient-list-item-meta">
        <SlackRecipientListItemMetaItem label="Workspace:" value={slackConfig.username} />
        <SlackRecipientListItemMetaItem label="Channel:" value={slackConfig.channel} />
        {/* <SlackRecipientListItemMetaItem label="Webhook:" value={slackConfig.url} /> */}
      </div>
      {action && <div className="slack-recipient-list-item-action">{action}</div>}
    </SlackRecipientListItemRoot>
  );
}

// COMPONENT: META ITEM

const SlackRecipientListItemMetaItemRoot = styled.div`
  line-height: 1.25em;
  margin-bottom: 2px;

  .slack-recipient-meta-item-label {
    font-weight: 600;
    display: inline-block;
    margin-right: 4px;
    color: ${p => p.theme.grey800};
  }
`;

interface SlackRecipientListItemMetaItemProps {
  label: string;
  value: string;
}

function SlackRecipientListItemMetaItem(props: SlackRecipientListItemMetaItemProps) {
  const { label, value } = props;
  return (
    <SlackRecipientListItemMetaItemRoot className="slack-recipient-meta-item">
      <span className="slack-recipient-meta-item-label">{label}</span>
      <span>{value}</span>
    </SlackRecipientListItemMetaItemRoot>
  );
}

export default SlackRecipientControl;

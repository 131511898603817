import React from 'react';
import { Query } from 'react-apollo';
import styled from 'styled-components';

import VerticalTable, { VerticalRowProps } from 'components/ui/Table/VerticalTable';
import QueryResult from 'components/util/QueryResult';

const Root = styled.div`
  .cloud-resources_detail-table {
    width: 100%;
  }
`;

export interface DetailRowProps extends VerticalRowProps {} // there's probably a better way to do this?

export interface Props {
  rows: DetailRowProps[];
  query: any; // ??? gql query
  queryName: string;
  variables?: any;
}

function DetailTable(props: Props) {
  const { query, queryName, variables, rows } = props;

  return (
    <Query query={query} variables={variables}>
      {({ data, loading, error }) => (
        <QueryResult data={data} loading={loading} error={error}>
          {() => {
            // how can we do this well?
            const dataSource = data[queryName].results[0] || {};

            return (
              <Root>
                <VerticalTable
                  classes={{
                    table: 'cloud-resources_detail-table'
                  }}
                  dataSource={dataSource}
                  rows={rows}
                />
              </Root>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

export default DetailTable;

import { Table } from 'ant4x';
import { ColumnProps } from 'ant4x/lib/table';
import { HighlightSelectedRow } from 'designSystem/ContentWithDetailPane/ContentWithDetailPane';
import { useFixedHeight } from 'designSystem/DesignSystemUtils/DesignSystemUtils';
import React, { useEffect, useRef } from 'react';
import { Detector } from 'typings';
import DetectorBanner from '../DetectorsCommon/DetectorsBanner/DetectorsBanner';
import IssuesSummary from '../DetectorsCommon/IssuesSummary/IssuesSummary';
import LastRun from '../DetectorsCommon/LastRun/LastRun';
import DetectorLabels from './DetectorLabels/DetectorLabels';
import { Root } from './DetectorsTableStyles';
import DetectorSwitch from './DetectorSwitch';
import DopeIcon from 'components/ui/DopeIcon';

interface DetectorsTableProps {
  data: any;
  selectedId: string;
  loading: boolean;
  onPageChange?: (page: number, pageSize?: number) => void;
  onRow?: (detector: Detector) => void;
}

function DetectorsTable(props: DetectorsTableProps) {
  const { data, loading, onPageChange, onRow, selectedId } = props,
    { assessments: assessmentsData } = data || {},
    { nodes: assessments, pageInfo } = assessmentsData || {},
    { current, size, total } = pageInfo || {},
    tableWrapper = useRef(null),
    tableHeight = useFixedHeight(tableWrapper, 140),
    columns: ColumnProps<Detector>[] = [
      {
        title: 'Enabled',
        key: 'isEnabled',
        dataIndex: 'isEnabled',
        width: '80px',
        render: (value, detector) => {
          return (
            <div>
              <DetectorSwitch detector={detector} />
              {detector.isReadOnly && (
                <DopeIcon
                  name="COMPLIANCE"
                  size="12px"
                  style={{ marginLeft: '4px' }}
                  title="This detector was created automatically by DisruptOps and can be disabled, but cannot be deleted."
                />
              )}
            </div>
          );
        }
      },
      {
        title: 'Name',
        key: 'name',
        width: '30%',
        dataIndex: 'name',
        render: (value, detector) => {
          return <DetectorBanner detector={detector} />;
        }
      },
      {
        title: 'Labels',
        key: 'labels',
        dataIndex: 'labels',
        render: (value, detector) => {
          if (!detector) return;
          return <DetectorLabels detector={detector} />;
        }
      },
      {
        title: 'Last Run',
        key: 'lastRanAt',
        dataIndex: 'lastRanAt',
        render: (lastRun, detector: Detector) => {
          return <LastRun detector={detector} />;
        }
      },
      {
        title: 'Issues',
        key: 'issueStats',
        dataIndex: 'issueStats',
        className: 'issue-stats',
        width: '340px',
        render: (summary) => {
          return <IssuesSummary summary={summary} />;
        }
      }
    ];

  useEffect(() => {
    HighlightSelectedRow(selectedId);
  }, [selectedId, loading]);

  function rowHandler(detector) {
    return {
      onClick: () => {
        onRow && onRow(detector);
      }
    };
  }

  return (
    <Root ref={tableWrapper}>
      {data && (
        <Table
          onRow={rowHandler}
          dataSource={assessments}
          columns={columns}
          rowKey="id"
          scroll={{ y: tableHeight, x: 900 }}
          loading={loading}
          pagination={{
            current: current,
            onChange: onPageChange,
            pageSize: size,
            total: total,
            showTitle: false,
            showTotal: (total, range) =>
              `${range[0]?.toLocaleString()}-${range[1]?.toLocaleString()} of ${total?.toLocaleString()} items`,
            showSizeChanger: false,
            size: 'small'
          }}
        />
      )}
    </Root>
  );
}

export default DetectorsTable;

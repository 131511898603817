import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';
import { FormRenderProps } from 'components/ui/Form/Form';
import { useValidateCompleteOp } from '../../../EditorUtils/ValidateOp';
import { ContextArgs } from '../../../EditorUtils/EditorUtils';
import { Op } from 'typings';
import { EnableSwitchDiv } from './EnableSwitch.styles';

interface EnableSwitchProps {
  formRenderProps: FormRenderProps;
  contextArgs: ContextArgs;
  opState: any;
}

function EnableSwitch(props: EnableSwitchProps) {
  const {
      formRenderProps,
      formRenderProps: { setFieldValue },
      contextArgs
    } = props,
    values = formRenderProps.values as Op,
    [helpText, setHelpText] = useState<string | React.ReactElement | undefined>(),
    [softEdit, setSoftEdit] = useState<boolean>(values.isEnabled ? true : false);

  const { isValid, validating, errors } = useValidateCompleteOp({
    op: values,
    contextArgs
  });

  function onChange() {
    const newVal = !softEdit;

    setFieldValue('isEnabled', newVal);
    setSoftEdit(newVal);
  }

  function renderErrorDetails(errors) {
    return (
      <ul>
        {errors.errors.map((message, i) => (
          <li key={i}>{message}</li>
        ))}
      </ul>
    );
  }

  useEffect(() => {
    if (errors) {
      const details = renderErrorDetails(errors);

      setHelpText(<>Please complete these items before enabling this Op:{details}</>);
    } else if (validating) {
      setHelpText('please wait');
    } else {
      setHelpText(<>This Op will be {softEdit ? 'enabled' : 'disabled'}</>);
    }
  }, [isValid, validating, errors]);

  return (
    <EnableSwitchDiv>
      <Switch
        disabled={!isValid}
        checked={softEdit}
        size="small"
        onChange={() => {
          onChange();
        }}
      />
      <div className="message">{helpText}</div>
    </EnableSwitchDiv>
  );
}

export default EnableSwitch;

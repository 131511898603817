import React from 'react';
import styled from 'styled-components';
import { Button, Modal, Tooltip } from 'antd';
import DynamicConfigList from './DynamicConfigList';
import { ScopedDynamicConfig } from 'typings/index.d';
import { ModalHeader } from 'components/ui/Modal';
import DopeIcon from 'components/ui/DopeIcon';

interface Props {
  onSelect: (Config: any) => void;
  scopedDynamicConfigs?: ScopedDynamicConfig[] | null;
  type?: 'button' | 'icon';
  onInitCustomJSONPath?: () => void;
}

interface State {
  modalVisible: boolean;
}

class DynamicConfigSelectButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      modalVisible: false
    };

    this.handleSelectedValue = this.handleSelectedValue.bind(this);
  }

  handleToggleModal(visible?: boolean) {
    this.setState(prev => ({
      modalVisible: visible === undefined ? !prev.modalVisible : visible
    }));
  }

  // value: Config
  handleSelectedValue(value: any) {
    const { onSelect } = this.props;
    this.handleToggleModal(false);

    onSelect(value);
  }

  render() {
    const { scopedDynamicConfigs, type = 'icon', onInitCustomJSONPath } = this.props;
    const { modalVisible } = this.state;

    return (
      <>
        {type === 'icon' ? (
          <Tooltip title="Use Dynamic Value" placement="left">
            <Button size="large" onClick={() => this.handleToggleModal(true)}>
              <DopeIcon name="DYNAMIC_CONFIG_SELECT" />
            </Button>
          </Tooltip>
        ) : (
          <Button onClick={() => this.handleToggleModal(true)}>
            <DopeIcon name="DYNAMIC_CONFIG_SELECT" />
            {'Use Dynamic Value'}
          </Button>
        )}
        <Modal
          visible={modalVisible}
          onCancel={() => this.handleToggleModal(false)}
          footer={false}
          bodyStyle={{ padding: 0 }}
        >
          <ModalHeader title="Use Dynamic Value" />
          {onInitCustomJSONPath && (
            <ListOption
              primaryLabel="Custom JSON path"
              secondaryLabel="$.path.to.desired.value"
              onSelect={() => {
                onInitCustomJSONPath();
                this.handleToggleModal(false);
              }}
            />
          )}
          {scopedDynamicConfigs && (
            <DynamicConfigList scopedDynamicConfigs={scopedDynamicConfigs} onSelect={this.handleSelectedValue} />
          )}
        </Modal>
      </>
    );
  }
}

// LIST OPTION COMPONENT

const ListOptionRoot = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  padding: 12px 24px;
  cursor: pointer;
  &:hover {
    background-color: rgba(224, 209, 254, 0.41);
  }

  .primary-label {
    font-weight: 600;
    margin-right: 24px;
  }

  .secondary-label {
    color: ${p => p.theme.grey400};
  }
`;

interface ListOptionProps {
  onSelect: () => void;
  primaryLabel: string;
  secondaryLabel?: string | null;
}

function ListOption(props: ListOptionProps) {
  const { primaryLabel, secondaryLabel, onSelect } = props;
  return (
    <ListOptionRoot onClick={onSelect}>
      <div className="primary-label">{primaryLabel}</div>
      {secondaryLabel && <div className="secondary-label">{secondaryLabel}</div>}
    </ListOptionRoot>
  );
}

export default DynamicConfigSelectButton;

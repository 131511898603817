import gql from 'graphql-tag';

export const GROUP_FRAGMENT = gql`
  fragment groupFields on UserGroup {
    id
    name
    description
    user_ids
    is_system_controlled
    key
    member_count
    role_count
    roles {
      id
      name
      description
      key
      role_id
      project_id
      user_group_id
      added
      created
      updated
    }
  }
`;

export default GROUP_FRAGMENT;

import React from 'react';
import ActionDetailRow from '../ActionDetailRow/ActionDetailRow';

interface ComponentProps {
  parameters: any;
  indexedConfigs: any;
  contextData: any;
}

function DefaultActionDetail(props: ComponentProps) {
  const { parameters, indexedConfigs, contextData } = props;

  return (
    <div>
      {parameters?.map((param, i) => (
        <React.Fragment key={i}>
          <ActionDetailRow
            parameter={param}
            config={indexedConfigs ? indexedConfigs[param.key] : undefined}
            contextData={contextData}
          />
        </React.Fragment>
      ))}
    </div>
  );
}

export default DefaultActionDetail;

import * as Yup from 'yup';
import { FormRenderProps } from 'components/ui/Form/Form';

// scope selectors
import IssueTypeSelector from '../components/NotificationForm/IssueTypeSelector';
import ProjectSelector from '../components/NotificationForm/ProjectSelector';
import AccountSelector from '../components/NotificationForm/AccountSelector';
import AssessmentIssueSelector from '../components/NotificationForm/AssessmentIssueSelector';
import ScopeSelectorAssessment from '../components/NotificationForm/ScopeSelectorAssessment';

enum NotificationScope {
  NONE = 'NONE',
  ISSUE = 'ISSUE',
  ISSUE_AND_ASSESSMENT = 'ISSUE_AND_ASSESSMENT',
  ASSESSMENT = 'ASSESSMENT',
  PROJECT = 'PROJECT',
  ACCOUNT = 'ACCOUNT'
}

export interface ScopeIdSelectorProps {
  value: any;
  onChange: (value: any) => void;
  onBlur: () => void;
  formRenderProps: FormRenderProps;
}

export interface EventScopeOption {
  key: NotificationScope;
  label: string;

  // this will be rendered if this scope is chosen.
  scopeIdSelector?: (props: ScopeIdSelectorProps) => JSX.Element;

  validationSchema?: any;
}

const EVENT_SCOPE_OPTIONS: EventScopeOption[] = [
  {
    key: NotificationScope.NONE,
    label: 'None'
  },
  {
    key: NotificationScope.ISSUE,
    label: 'Issue',
    scopeIdSelector: IssueTypeSelector,
    validationSchema: Yup.object({
      issueDefinitionId: Yup.string().required('Please select an Issue Type')
    }).required('Please select a Scope')
  },
  {
    key: NotificationScope.ASSESSMENT,
    label: 'Detector',
    scopeIdSelector: ScopeSelectorAssessment,
    validationSchema: Yup.object({
      assessmentId: Yup.string().required('Please select an Detector')
    }).required('Please select a Scope')
  },
  {
    key: NotificationScope.ISSUE_AND_ASSESSMENT,
    label: 'Issue from Detector',
    scopeIdSelector: AssessmentIssueSelector,
    validationSchema: Yup.object({
      assessmentId: Yup.string().required('Please select an Detector'),
      issueDefinitionId: Yup.string().required('Please select an Issue Type')
    }).required('Please select a Scope')
  },
  {
    key: NotificationScope.PROJECT,
    label: 'Project',
    scopeIdSelector: ProjectSelector,
    validationSchema: Yup.object({
      projectId: Yup.string().required('Please select a Project')
    }).required('Please select a Scope')
  },
  {
    key: NotificationScope.ACCOUNT,
    label: 'Account',
    validationSchema: Yup.object({
      accountId: Yup.string().required('Please select an Account')
    }).required(),
    scopeIdSelector: AccountSelector
  }
];

export default EVENT_SCOPE_OPTIONS;

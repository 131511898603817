import React, { useState } from 'react';
import styled from 'styled-components';
import { FormField } from 'components/ui/Form';
import { Input, Button, Select } from 'antd';
import { FieldArray } from 'formik';
import EventJSONPathSelectionModal from './EventJSONPathSelectionModal';
import { AutomationEventDefinition } from 'typings';

const Root = styled.div`
  .filter-root {
    background-color: #f0f0f0;
    padding: 16px 16px 4px 16px;
    margin-bottom: 8px;
    border-radius: 4px;
    position: relative;

    h5 {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .close-button-container {
      position: absolute;
      top: 8px;
      right: 16px;
      z-index: 2;
    }
  }

  .path-root {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 8px;
    margin-top: 8px;
  }

  .pattern-root {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 8px;
  }
`;

interface RemoveFieldButtonProps {
  index: number;
  onRemove: (index: number) => any | void;
  ghost?: boolean;
}

function RemoveFieldButton(props: RemoveFieldButtonProps) {
  const { index, onRemove, ghost = false } = props;
  return (
    <Button
      icon="close"
      size="small"
      onClick={() => {
        onRemove(index);
      }}
      shape="circle"
      type={ghost ? 'ghost' : 'default'}
    ></Button>
  );
}

interface CustomFilter {
  path: string;
  type: string;
  patterns: string[];
}

interface Props {
  name: string;
  eventFromEventConsole?: any;
  value: CustomFilter[];
  onChange: (value: CustomFilter[]) => void;
  eventDefinition: AutomationEventDefinition | null;
}

function CustomJSONPathFilterInput(props: Props) {
  const { name, eventFromEventConsole, value = [], onChange, eventDefinition } = props;

  const [modalIndexOpen, setModalIndexOpen] = useState<number | undefined>(undefined);

  return (
    <Root>
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <div>
            {value.map((fieldValue, filterIndex) => (
              <div key={filterIndex}>
                <div className="filter-root">
                  <div className="close-button-container">
                    <RemoveFieldButton index={filterIndex} onRemove={arrayHelpers.remove} ghost />
                  </div>

                  <FormField name={`${name}.${filterIndex}`} label={`Custom Filter ${filterIndex + 1}`}>
                    {({ name }) => (
                      <div>
                        <div className="path-root">
                          <FormField name={`${name}.path`} label="Path">
                            {({ name, value, handleChange, handleBlur }) => {
                              return (
                                <Input
                                  name={name}
                                  value={value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  style={{ flexBasis: '60%' }}
                                  addonAfter={
                                    <>
                                      <button
                                        className="link-button"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();

                                          setModalIndexOpen(filterIndex);
                                        }}
                                      >
                                        $.jsonpath
                                      </button>
                                    </>
                                  }
                                ></Input>
                              );
                            }}
                          </FormField>

                          <FormField name={`${name}.type`} label="Type">
                            {({ value, handleChange, handleBlur }) => {
                              return (
                                <Select value={value} onChange={handleChange} onBlur={handleBlur}>
                                  <Select.Option value="STRICT_MATCH">Strict Match</Select.Option>
                                  <Select.Option value="PARTIAL_MATCH">Partial Match</Select.Option>
                                </Select>
                              );
                            }}
                          </FormField>
                        </div>

                        <div>
                          <FormField name={`${name}.patterns`} label="Patterns">
                            {({ name, value = [], handleChange, handleBlur }) => {
                              return (
                                <FieldArray
                                  name={name}
                                  render={(arrayHelpers) => (
                                    <div>
                                      {value.map((fieldValue, patternIndex) => {
                                        return (
                                          <div className="pattern-root" key={patternIndex}>
                                            <FormField name={`${name}.${patternIndex}`} hideLabel>
                                              {({ value, handleChange, handleBlur }) => {
                                                return (
                                                  <Input
                                                    name={name}
                                                    value={value}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    addonBefore={patternIndex + 1}
                                                  />
                                                );
                                              }}
                                            </FormField>

                                            <div>
                                              <RemoveFieldButton index={patternIndex} onRemove={arrayHelpers.remove} />
                                            </div>
                                          </div>
                                        );
                                      })}
                                      <Button icon="plus" onClick={() => arrayHelpers.push('')}>
                                        Add new pattern
                                      </Button>
                                    </div>
                                  )}
                                />
                              );
                            }}
                          </FormField>
                        </div>
                      </div>
                    )}
                  </FormField>
                </div>
              </div>
            ))}

            <Button
              icon="plus"
              onClick={() =>
                arrayHelpers.push({
                  path: '',
                  type: 'STRICT_MATCH',
                  patterns: ['']
                })
              }
            >
              Add new path
            </Button>
          </div>
        )}
      />

      {/* Modal is located here so that it maintains the state of which event was selected. */}
      <EventJSONPathSelectionModal
        eventFromEventConsole={eventFromEventConsole}
        selectedEventDefinition={eventDefinition}
        isOpen={modalIndexOpen !== undefined}
        onPathChange={(path) => {
          const updatedValue = value.map((customFilter, i) => {
            if (i !== modalIndexOpen) return customFilter;

            return {
              ...customFilter,
              path
            };
          });

          onChange(updatedValue);
        }}
        onPatternChange={(path, pattern) => {
          if (modalIndexOpen === undefined) return;
          const filterValue = value[modalIndexOpen];
          const patterns = filterValue.patterns;
          const existingPatterns = patterns[patterns.length - 1] === '' ? patterns.slice(0, -1) : [...patterns];

          const updatedValue = value.map((customFilter, i) => {
            if (i !== modalIndexOpen) return customFilter;

            return {
              ...customFilter,
              path,
              patterns: customFilter.path === path ? [...existingPatterns, pattern] : [pattern]
            };
          });

          onChange(updatedValue);
        }}
        onClose={() => setModalIndexOpen(undefined)}
      />
    </Root>
  );
}

export default CustomJSONPathFilterInput;

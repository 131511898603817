import EventsStats from 'components/events/EventsStats/EventsStats';
import DopeIcon from 'components/ui/DopeIcon';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  .centered-container {
    padding-bottom: 96px; // to get the intercom button out of the way of the pagination controls
    padding-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export default function EventsStatsPage() {
  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            icon={<DopeIcon name="EVENT" size={20} />}
            sectionTitle="Events Stats"
            sectionTitleLinkTo="/events"
          />
        }
      >
        <CenteredContainer>
          <EventsStats />
        </CenteredContainer>
      </NeoPage>
    </Root>
  );
}

import EventEmitter from 'events';
import { EventSourcePolyfill } from 'event-source-polyfill';
import ReconnectingEventSource from '@disruptops/reconnecting-eventsource';
import { getAccessToken, signOut } from 'services/auth';

interface Options {
  debug: boolean;
}

class SSE extends EventEmitter {
  public isConnected: boolean;

  private debug: boolean = false;
  private es?: ReconnectingEventSource;
  private esPromise?: Promise<ReconnectingEventSource>;

  constructor(options: Options) {
    super();

    this.debug = options.debug ? true : false;

    this.isConnected = false;
  }

  async connect(url: string) {
    if (this.es) {
      return this.es;
    }

    if (this.esPromise) {
      return this.esPromise;
    }

    (window as any).EventSource = EventSourcePolyfill;
    (global as any).EventSource = EventSourcePolyfill;

    const esPromise = new Promise((resolve) => {
      const es = new ReconnectingEventSource(url, {
        headers: async () => {
          const authorizationToken = await this.getAuthorizationToken();

          if (!authorizationToken) {
            await signOut();
            return {};
          }

          return {
            Authorization: `Bearer ${authorizationToken}`
          };
        }
      });

      es.onerror = (err) => this.handleError(err);

      es.onopen = () => {
        this.handleOpen();
        resolve(es);
      };

      es.addEventListener('message', (e: any) => this.handleMessage(e), false);

      this.es = es;
    });

    this.esPromise = esPromise;

    return esPromise;
  }

  async getAuthorizationToken() {
    const accessToken = await getAccessToken();

    return accessToken.getJwtToken();
  }

  handleOpen() {
    this.esPromise = undefined;
    this.isConnected = true;

    if (this.debug) {
      // tslint:disable-next-line:no-console
      console.log('SSE connection open');
    }
  }

  handleMessage(message: any) {
    let data;

    try {
      data = JSON.parse(message.data);
    } catch (e) {
      data = {};
    }

    if (this.debug) {
      // tslint:disable-next-line:no-console
      console.log('SSE message', data);
    }

    this.emit('message', data);
  }

  handleError(error: MessageEvent) {
    // tslint:disable-next-line:no-console
    console.error('SSE Error', error);
  }

  close() {
    if (this.es) {
      this.es.close();
    }

    this.removeAllListeners();

    this.es = undefined;
    this.isConnected = false;

    if (this.debug) {
      // tslint:disable-next-line:no-console
      console.log('SSE connection closed');
    }
  }
}

export default SSE;

import React, { ReactNodeArray, ReactNode } from 'react';

import styled from 'styled-components';

const Root = styled.div`
  flex: 1;
  display: flex;

  margin-bottom: 20px;
`;

const Content = styled.div`
  width: 100%;
  display: block;

  @media (min-width: 500px) {
    display: flex;
  }

  .ant-btn {
    border-radius: 4px;
  }

  .filter-item-field {
    border-right: 1px solid ${(p) => p.theme.grey300}
    margin-right: 8px;
    padding-right: 8px;
  }

  > *:last-child .filter-item-field {
    border-right: 0;
  }

  > * .ant-input {
    border-radius: 0;
  }

  > *:first-child .ant-select-selection {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  > *:last-child.ant-btn {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

`;

const ActionsWrap = styled.div`
  display: flex;
  padding-top: 18px;
`;

export interface Props {
  children: ReactNodeArray | ReactNode;
  actions?: ReactNodeArray | ReactNode;
  classes?: {
    root?: string;
    content?: string;
  };
}

// Maybe call something else?
// add actions to me
function FilterRow(props: Props) {
  const { classes = {}, actions } = props;

  return (
    <Root className={`filter-row ${classes.root || ''}`}>
      <Content className={classes.content || ''}>{props.children}</Content>
      {actions && <ActionsWrap>{actions}</ActionsWrap>}
    </Root>
  );
}

export default FilterRow;

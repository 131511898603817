import React from 'react';
import { Select } from 'antd';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import ProgressCircular from 'components/ui/ProgressCircular';

import { ScopeIdSelectorProps } from '../../types';
import { FormField } from 'components/ui/Form';

const PROJECTS_QUERY = gql`
  query ProjectsForSelect {
    projects {
      items {
        project_id
        name
      }
    }
  }
`;

function ProjectSelector(props: ScopeIdSelectorProps) {
  const {
    formRenderProps: { setFieldValue }
  } = props;

  return (
    <Query query={PROJECTS_QUERY} fetchPolicy="cache-first">
      {results => {
        const { data, loading } = results;

        if (loading) return <ProgressCircular />;

        return (
          <FormField name="scope.projectId" label="Project">
            {({ name, value, handleBlur, error }) => (
              <Select
                value={value || 'default'}
                style={{ width: '100%' }}
                onBlur={handleBlur}
                onChange={selected => {
                  const selectedStr = selected ? selected.toString() : undefined;

                  setFieldValue(name, selectedStr !== 'default' ? selectedStr : undefined);
                }}
              >
                <Select.Option value="default">Select Project</Select.Option>
                {data.projects.items.map((item, index) => {
                  return (
                    <Select.Option key={`${item.project_id}-${index}`} value={item.project_id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </FormField>
        );
      }}
    </Query>
  );
}

export default ProjectSelector;

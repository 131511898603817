import { YoutubeOutlined } from '@ant-design/icons';
import DopeIcon from 'components/ui/DopeIcon';
import ErrorAlert from 'components/ui/ErrorAlert';
import ContentWithDetailPane, { CreateButton } from 'designSystem/ContentWithDetailPane/ContentWithDetailPane';
import CreatePanel from 'designSystem/CreatePanel/CreatePanel';
import PageHeading from 'designSystem/PageHeading/PageHeading';
import SearchBar, { SearchBarConfig, searchParamsToObject } from 'designSystem/SearchBar/SearchBar';
import gql from 'graphql-tag';
import { OP_FIELDS } from 'queries/fragments/opFields';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import { CSSTransitionGroup } from 'react-transition-group';
import { Op } from 'typings';
import Editor from '../Editor/Editor';
import OpCreate from '../OpCreate';
import OpDetail from '../OpDetail/OpDetail';
import useOpPathname from '../OpsCommon/useOpPathname/useOpPathname';
import { Root } from './OpsList.styles';
import OpsTable from './OpsTable/OpsTable';
import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';

const OP_LIST_QUERY = gql`
  query OpList(
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $isArchived: Boolean
    $search: String
    $isEnabled: Boolean
  ) {
    ops(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      isArchived: $isArchived
      search: $search
      isEnabled: $isEnabled
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        ...OpFields
        function {
          cloudVendor
          parameters {
            type
            inputCode
            configCode
            name
            key
          }
        }
      }
    }

    projects {
      items {
        name
        project_id
      }
    }

    recipients {
      items {
        id
        type
        config
      }
    }

    accounts {
      items {
        account_id
        nickname
        name
        assumerole_account_id
        account_id
      }
    }
  }
  ${OP_FIELDS}
`;

const OP_DETAIL_QUERY = gql`
  query OpById($id: [String]) {
    ops(id: $id) {
      nodes {
        ...OpFields
      }
    }
  }
  ${OP_FIELDS}
`;

const helpText =
  'Ops respond to real-time events by taking a configured action. An action performed by an Op is logged under Activity';

const searchConfig: SearchBarConfig = [
  {
    key: 'search',
    label: 'Op Name',
    shortcut: true
  },
  {
    key: 'isEnabled',
    label: 'Is Enabled?',
    control: 'boolean'
  }
];

function OpsList(props) {
  const {
      match: {
        params: { opId, editing }
      },
      location,
      create,
      history
    } = props,
    basepath = useOpPathname(),
    featureFlags = useFeatureFlag([Feature.USE_CASE_NAV]),
    [selectedOp, setSelectedOp] = useState<Op | undefined>(),
    [detailPane, setDetailPane] = useState<JSX.Element | undefined>(),
    [fullscreenEdit, setFullscreenEdit] = useState(false),
    params = new URLSearchParams(location.search),
    queryString = params.toString(),
    page = params.get('page') || undefined,
    searchObject = searchParamsToObject(params),
    { data, error, loading, refetch } = useQuery(OP_LIST_QUERY, {
      variables: { ...searchObject, isArchived: false, pageSize: 10, pageNumber: page && parseInt(page, 10) }
    }),
    [queryOp] = useLazyQuery(OP_DETAIL_QUERY, {
      variables: { id: opId },
      onCompleted: (opData) => {
        setSelectedOp(opData?.ops?.nodes?.[0]);
      },
      onError: (error) => setDetailPane(<ErrorAlert message={'Could not load Op'} error={error} />)
    });

  function onRefetchNeeded() {
    refetch();
  }

  function activateDetail() {
    if (selectedOp) {
      setFullscreenEdit(false);
      setDetailPane(
        <OpDetail
          closeLink={`${basepath}?${queryString}`}
          contextData={data}
          editLink={`${basepath}/${opId}/edit?${queryString}`}
          op={selectedOp}
          onRefetchNeeded={onRefetchNeeded}
          projects={data.projects.items}
        />
      );
    }
  }

  function activateEdit() {
    if (selectedOp) {
      setDetailPane(<Editor closeLink={`${basepath}/${selectedOp.id}?${queryString}`} op={selectedOp} />);
      setFullscreenEdit(true);
    }
  }

  function resetPanels() {
    setDetailPane(undefined);
    setFullscreenEdit(false);
  }

  function onCreateOp() {
    history.push(`${basepath}/create/event-definitions/`);
  }

  function onPageChange(page: number) {
    if (page === 1) {
      params.delete('page');
    } else {
      params.set('page', page.toString());
    }

    history.push(`${basepath}?${params.toString()}`);
  }

  function onRow(op: Op) {
    history.push(`${basepath}/${op.id}?${queryString}`);
  }

  useEffect(() => {
    if (data && opId) {
      let op = data.ops.nodes.find((op) => op.id === opId);
      if (!op) {
        queryOp({ variables: { id: opId } });
      }
      setSelectedOp(op);
    }

    if (!opId) {
      setSelectedOp(undefined);
      resetPanels();
    }
  }, [opId, data]);

  useEffect(() => {
    if (selectedOp && editing) {
      activateEdit();
    } else if (selectedOp) {
      activateDetail();
    }
  }, [selectedOp, editing]);

  if (location.savedOp) {
    onRefetchNeeded();
    history.replace(`${basepath}`);
  }

  return (
    <Root>
      {!featureFlags[Feature.USE_CASE_NAV] && (
        <PageHeading title="Ops" subTitle={selectedOp?.name} icon="OP" helpText={helpText}>
          <div className="video-link">
            <a href="https://vimeo.com/489488637/b81c746e80" target="_blank" rel="noreferrer noopener">
              <YoutubeOutlined />
              <span>Watch Tutorial</span>
              <DopeIcon className="external-link" name="EXTERNAL_LINK" />
            </a>
          </div>
        </PageHeading>
      )}

      <ContentWithDetailPane detailPane={detailPane} fullscreen={fullscreenEdit}>
        <CSSTransitionGroup transitionName="create" transitionEnterTimeout={200} transitionLeaveTimeout={100}>
          {create && (
            <CreatePanel title="Create Op" closeLink={`${basepath}`}>
              <OpCreate />
            </CreatePanel>
          )}
          <CreateButton handler={onCreateOp}>Create Op</CreateButton>
          {error ? (
            <ErrorAlert error={error} message={'Could not load Ops'} onClose={() => refetch()} />
          ) : (
            <>
              <SearchBar config={searchConfig} />
              <OpsTable selectedId={opId} data={data} loading={loading} onPageChange={onPageChange} onRow={onRow} />
            </>
          )}
        </CSSTransitionGroup>
      </ContentWithDetailPane>
    </Root>
  );
}

export default OpsList;

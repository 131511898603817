import React, { useState } from 'react';
import styled from 'styled-components';
import { Function, Issue, CloudAccount } from 'typings';
import { Button, Card } from 'antd';

import { ModalHeader, ModalFooter, ModalBody } from 'components/ui/Modal';
import VerticalTable, { VerticalRowProps } from 'components/ui/Table/VerticalTable';
import IssuesTable from './IssuesTable';
import ActionParametersTable from './ActionParametersTable';
import DopeIcon from 'components/ui/DopeIcon';

import Label from 'components/ui/Label';

const Meta = Card.Meta;

const Root = styled.div`
  .review-label-col {
    padding: 16px;
    vertical-align: top;
    text-align: ;
  }
  .review-value-col {
    padding-bottom: 24px;
    padding-top: 16px;
    padding: 16px 0 24px 24px;
  }
  .review-table-row {
    border-bottom: 1px solid #e6e6e6;
    &:last-child {
      border-bottom: none;
    }

    &:nth-child(even) {
      background-color: transparent;
    }
  }
  .action-inputs-table {
    margin-bottom: 0;
  }

  .ui-label {
    margin: 8px;
  }
`;

interface Props {
  actionTask: Function;
  issues: Issue[]; // could support multiple issues some day;
  account: CloudAccount;
  configValues: any;
  onBack: () => void;
  onRunAction: () => Promise<void>;
}

function ReviewAction(props: Props) {
  const { actionTask, issues, account, onRunAction, configValues, onBack } = props;
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  let reviewTableRows: VerticalRowProps[] = [
    {
      key: 'issues',
      label: issues.length > 1 ? 'Issues' : 'Issue',
      dataIndex: '',
      render: () => <IssuesTable issues={issues} account={account} />
    },
    {
      key: 'action',
      label: 'Action',
      dataIndex: '',
      render: () => {
        return (
          <>
            <Meta title={actionTask.name} description={actionTask.description} />
            {actionTask.effects && (
              <p>
                Effects:
                {actionTask.effects.map((l, idx) => (
                  <Label key={`${l}_${idx}`} label={l} />
                ))}
              </p>
            )}
          </>
        );
      }
    }
  ];

  if (actionTask.parameters && actionTask.parameters.length > 0) {
    reviewTableRows.push({
      key: 'parameters',
      label: 'Parameters',
      dataIndex: '',
      render: () => (
        <ActionParametersTable
          hideHeader
          actionTask={actionTask}
          configValues={configValues}
          classes={{ root: 'action-inputs-table' }}
        />
      )
    });
  }

  return (
    <Root>
      <ModalHeader title="Review action" />

      <ModalBody>
        <VerticalTable
          classes={{
            labelCol: 'review-label-col',
            valueCol: 'review-value-col',
            row: 'review-table-row'
          }}
          rows={reviewTableRows}
          dataSource={{}}
        />
      </ModalBody>

      <ModalFooter>
        <Button onClick={onBack} className="btn-with-icon">
          <DopeIcon style={{ marginTop: '-2px' }} name="ARROW_BACK" />
          Back
        </Button>
        <Button
          disabled={isSubmitting}
          loading={isSubmitting}
          onClick={async () => {
            setSubmitting(true);

            await onRunAction();
          }}
          type="primary"
        >
          {!configValues.executionDt ? 'Run' : 'Schedule action'}
        </Button>
      </ModalFooter>
    </Root>
  );
}

export default ReviewAction;

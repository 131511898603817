import React from 'react';
import { BadgeDiv } from './Badge.styles';

type ModifierTypes = 'success' | 'warning' | 'error' | 'pending';

interface BadgeProps {
  count: number;
  modifier?: ModifierTypes;
}

function Badge(props: BadgeProps) {
  return <BadgeDiv className={props.modifier}>{props.count}</BadgeDiv>;
}

export default Badge;

import { Button, Form, Icon, Input, Select, Spin } from 'antd';
import ErrorAlert from 'components/ui/ErrorAlert';
import { getIn } from 'formik';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { firstBy } from 'thenby';
import { RecipientFormControlProps } from '../recipients/common';
import { FormField } from '../ui/Form';
import notificationFields from './fixtures/notificationFields.json';
import JiraFields from './JiraFields';

const JIRA_PROJECTS_QUERY = gql`
  query jiraProjects($config: JiraConfigInput!) {
    jiraProjects(config: $config) {
      id
      key
      name
      issueTypes {
        id
        name
        fields {
          key
          name
          required
          schemaType
          schemaItems
          allowedValues {
            key
            name
            value
          }
        }
      }
      requestTypes {
        id
        issueTypeId
        name
        fields {
          key
          name
          required
          schemaType
          schemaItems
          allowedValues {
            key
            name
            value
          }
        }
      }
    }
  }
`;

function JiraRecipientFormControls(props: RecipientFormControlProps) {
  const [getJiraProjects, { loading, data, error }] = useLazyQuery(JIRA_PROJECTS_QUERY, {
    fetchPolicy: 'no-cache'
  });

  // console.log({ called, loading, data, error, variables, networkStatus });

  return (
    <InnerJiraRecipientFormControls
      getJiraProjects={getJiraProjects}
      loading={loading}
      data={data}
      error={error}
      recipientFormControlProps={props}
      notificationFields={notificationFields as NotificationField[]} // TODO: replace with notificationFields gql query response
    />
  );
}

export interface NotificationField {
  key: string;
  name: string;
  type: 'JSONPATH' | 'LOOKUP';
  dataType?: string;
  value?: string;
  example: any;
}

interface Props {
  getJiraProjects: any;
  loading: boolean;
  data: any;
  error: any;
  recipientFormControlProps: RecipientFormControlProps;
  notificationFields: NotificationField[];
}

export function InnerJiraRecipientFormControls(props: Props) {
  const { getJiraProjects, loading, data, error, recipientFormControlProps, notificationFields } = props;

  const [projectsFetched, setProjectsFetched] = useState<boolean>(!!data);

  // form values
  const { prefix = 'config', formRenderProps } = recipientFormControlProps;
  const { values, setFieldValue } = formRenderProps;

  const url = getIn(values, `${prefix}.url`);
  const user = getIn(values, `${prefix}.user`);
  const apiToken = getIn(values, `${prefix}.apiToken`);
  const projectType = getIn(values, `${prefix}.projectType`);
  const projectKey = getIn(values, `${prefix}.projectKey`);
  const issueTypeName = getIn(values, `${prefix}.issueTypeName`);
  const requestTypeId = getIn(values, `${prefix}.requestTypeId`, null);
  // const mappedFields = getIn(values, `${prefix}.mappedFields`, null);
  // const priority = getIn(values, `${prefix}.priority`);

  // gql query data
  const { jiraProjects = [] } = data || {};

  // console.log({ mappedFields });

  // sort the projects
  useEffect(() => {
    jiraProjects?.sort((a, b) => a.name.localeCompare(b.name));
  }, [jiraProjects]);

  // if there's only one project, select it
  useEffect(() => {
    if (!projectKey && jiraProjects?.length === 1) {
      setFieldValue(`${prefix}.projectKey`, jiraProjects[0].key);
    }
  }, [jiraProjects, prefix, projectKey, setFieldValue]);

  // get the selected project
  const selectedProject = projectKey && jiraProjects?.find((project) => project.key === projectKey);

  // get the selected issue type, if software project type is selected
  const selectedIssueType =
    issueTypeName && selectedProject?.issueTypes?.find((issueType) => issueType.name === issueTypeName);

  // get the selected request type, if service desk project type is selected
  const selectedRequestType =
    requestTypeId && selectedProject?.requestTypes?.find((requestType) => requestType.id === requestTypeId);

  useEffect(() => {
    selectedProject?.issueTypes?.sort(firstBy('name'));
  }, [selectedProject]);

  useEffect(() => {
    selectedProject?.requestTypes?.sort(firstBy('name'));
  }, [selectedProject]);

  const jiraFields =
    projectType === 'software'
      ? selectedIssueType?.fields
      : projectType === 'serviceDesk'
      ? selectedRequestType?.fields
      : null;

  const priorityField = jiraFields?.find((field) => field.key === 'priority');

  // console.log({ values, data, jiraProjects, selectedProject, selectedIssueType, selectedRequestType, fields });

  return (
    <>
      <FormField name={`${prefix}.url`} label="Jira URL">
        {(formFieldProps) => {
          const { value, handleChange, handleBlur } = formFieldProps;

          return (
            <Input
              placeholder="Jira URL ex: https://example.atlassian.net"
              autoComplete="url"
              value={value}
              disabled={loading}
              onChange={(e) => {
                const value = e.target.value;
                handleChange(value);
              }}
              onBlur={handleBlur}
            />
          );
        }}
      </FormField>

      <FormField name={`${prefix}.user`} label="Jira Username">
        {(formFieldProps) => {
          const { value, handleChange, handleBlur } = formFieldProps;

          return (
            <Input
              placeholder="Jira Username"
              autoComplete="username"
              value={value}
              disabled={loading}
              onChange={(e) => {
                const value = e.target.value;
                handleChange(value);
              }}
              onBlur={handleBlur}
            />
          );
        }}
      </FormField>

      <FormField name={`${prefix}.apiToken`} label="Jira API Token">
        {(formFieldProps) => {
          const { value, handleChange, handleBlur } = formFieldProps;

          return (
            <Input
              placeholder="Jira API Token"
              autoComplete="current-password"
              value={value}
              type="password"
              disabled={loading}
              onChange={(e) => {
                const value = e.target.value;
                handleChange(value);
              }}
              onBlur={handleBlur}
            />
          );
        }}
      </FormField>

      <FormField name={`${prefix}.projectType`} label="Jira Project Type">
        {(formFieldProps) => {
          const { value, handleChange } = formFieldProps;

          return (
            <Select
              value={value}
              placeholder="Select a Jira Project Type"
              disabled={loading}
              onChange={(e) => {
                setFieldValue(`${prefix}.projectKey`, undefined);
                setFieldValue(`${prefix}.issueTypeName`, undefined);
                setFieldValue(`${prefix}.requestTypeId`, undefined);
                setFieldValue(`${prefix}.priority`, undefined);
                setProjectsFetched(false);
                handleChange(e);
              }}
            >
              <Select.Option key="serviceDesk" value="serviceDesk">
                Service Desk Project
              </Select.Option>
              <Select.Option key="software" value="software">
                Software Project
              </Select.Option>
            </Select>
          );
        }}
      </FormField>

      <Form.Item>
        <Button
          htmlType="button"
          disabled={loading || !url || !user || !apiToken || !projectType}
          onClick={() => {
            setProjectsFetched(true);
            getJiraProjects({
              variables: {
                config: {
                  url,
                  user,
                  apiToken,
                  projectType
                }
              }
            });
          }}
        >
          Next
          <Icon type="right" />
        </Button>
      </Form.Item>

      {loading && <Spin />}

      {error && <ErrorAlert error={error} />}

      {!loading && data && projectsFetched && (
        <>
          <FormField name={`${prefix}.projectKey`} label="Jira Project">
            {(formFieldProps) => {
              const { value, handleChange } = formFieldProps;

              // console.log({ jiraProjects });

              return (
                <Select
                  value={value}
                  placeholder="Select a Jira Project"
                  onChange={(e) => {
                    setFieldValue(`${prefix}.issueTypeName`, undefined);
                    setFieldValue(`${prefix}.requestTypeId`, undefined);
                    setFieldValue(`${prefix}.priority`, undefined);
                    handleChange(e);
                  }}
                >
                  {jiraProjects?.map((jiraProject) => (
                    <Select.Option key={jiraProject.key} value={jiraProject.key}>
                      {jiraProject.name}
                    </Select.Option>
                  ))}
                </Select>
              );
            }}
          </FormField>

          {projectType === 'software' && selectedProject && (
            <FormField name={`${prefix}.issueTypeName`} label="Jira Issue Type">
              {(formFieldProps) => {
                const { value, handleChange } = formFieldProps;

                return (
                  <Select
                    value={value}
                    placeholder="Select an Issue Type"
                    onChange={(e) => {
                      setFieldValue(`${prefix}.priority`, undefined);
                      handleChange(e);
                    }}
                  >
                    {selectedProject?.issueTypes?.map((issueType) => (
                      <Select.Option key={issueType.name} value={issueType.name}>
                        {issueType.name}
                      </Select.Option>
                    ))}
                  </Select>
                );
              }}
            </FormField>
          )}

          {projectType === 'serviceDesk' && selectedProject && (
            <FormField name={`${prefix}.requestTypeId`} label="Jira Service Desk Request Type">
              {(formFieldProps) => {
                const { value, handleChange } = formFieldProps;

                return (
                  <Select
                    value={value}
                    placeholder="Select a Service Desk Request Type"
                    onChange={(e) => {
                      setFieldValue(`${prefix}.priority`, undefined);
                      handleChange(e);
                    }}
                  >
                    {selectedProject?.requestTypes?.map((requestType) => (
                      <Select.Option key={requestType.id} value={requestType.id}>
                        {requestType.name}
                      </Select.Option>
                    ))}
                  </Select>
                );
              }}
            </FormField>
          )}

          {/* Note in case priority field is not supported by the selected issue or request type */}
          {jiraFields && !priorityField && (
            <FormField
              name={`${prefix}.priority`}
              label="Jira Priority"
              extra={`The Priority field is not supported by the selected project and ${
                projectType === 'software' ? 'issue type' : 'request type'
              }.`}
            >
              {(formFieldProps) => {
                return <Select disabled={true} />;
              }}
            </FormField>
          )}

          {priorityField && (
            <FormField name={`${prefix}.priority`} label="Jira Priority">
              {(formFieldProps) => {
                const { value, handleChange } = formFieldProps;

                return (
                  <Select value={value} placeholder="Select a Priority" onChange={handleChange}>
                    {priorityField.allowedValues?.map((allowedValue) => (
                      <Select.Option key={allowedValue.name} value={allowedValue.name}>
                        {allowedValue.name}
                      </Select.Option>
                    ))}
                  </Select>
                );
              }}
            </FormField>
          )}

          {selectedProject && jiraFields && (
            <FormField name={`${prefix}.mappedFields`} label="Jira Fields Map">
              {(formFieldProps) => {
                return (
                  <JiraFields
                    formFieldProps={formFieldProps}
                    jiraFields={jiraFields}
                    notificationFields={notificationFields}
                  />
                );
              }}
            </FormField>
          )}
        </>
      )}
    </>
  );
}

export default JiraRecipientFormControls;

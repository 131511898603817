import React, { useState } from 'react';
import FilterListing from '../FilterListing/FilterListing';
import SourceEditor from '../SourceEditor/SourceEditor';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { AutomationEventDefinition } from 'typings';
import styled from 'styled-components';
import { FormikValues } from 'formik';
import { ConfirmationModalState } from '../../../../OpEditor';
import { Collapse } from 'antd';
import { Root } from './TriggerContentsStyles';
import SourceLabel from '../SourceLabel/SourceLabel';

export const SectionLabel = styled.div`
  text-transform: uppercase;
  color: ${(p) => p.theme.grey400};
`;

export interface EventSource {
  id: string;
  name: string;
}

export interface FilterValuesAndSetter {
  values: FormikValues;
  setFieldValue(field: string, value: any): void;
}

export const AUTOMATION_EVENT_SOURCES_LIST_QUERY = gql`
  query eventSources($id: [String], $pageNumber: Int, $pageSize: Int, $sortBy: String, $sortDirection: String) {
    eventSources(
      id: $id
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

interface TriggerContentsProps extends FilterValuesAndSetter {
  eventDefinition: AutomationEventDefinition | null;
  eventFromEventConsole: any | null;
  isAllFindingsSelected: boolean;
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
}

function TriggerContents(props: TriggerContentsProps) {
  const { data } = useQuery(AUTOMATION_EVENT_SOURCES_LIST_QUERY, {
      fetchPolicy: 'cache-and-network',
      variables: {
        pageNumber: 1,
        pageSize: 100,
        sortBy: 'name',
        sortDirection: 'ASC'
      }
    }),
    [isEditing, setIsEditing] = useState(true);

  return (
    <Root>
      <Collapse bordered={false} defaultActiveKey="faux" onChange={() => setIsEditing(!isEditing)}>
        <Collapse.Panel key="faux" header="Configure Filters"></Collapse.Panel>
      </Collapse>
      <SectionLabel>Source</SectionLabel>
      {isEditing ? (
        <SourceEditor {...props} sources={data?.eventSources.nodes} />
      ) : (
        <SourceLabel sourceId={props.values.eventSourceId} sources={data?.eventSources.nodes} />
      )}
      <FilterListing {...props} inEditMode={isEditing} />
    </Root>
  );
}

export default TriggerContents;

import Text from 'antd/lib/typography/Text';
import React from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';

const QUERY = gql`
  query openIssueCountByProject {
    governanceStats {
      openIssueCountByProject {
        days
        start
        results {
          projectName
          projectId
          count
        }
      }
    }
  }
`;

function OpenIssuesByProject() {
  const { loading, error, data } = useQuery(QUERY);

  return (
    <QueryResult loading={loading} data={data} error={error} loadingCenterVertically={false}>
      {() => {
        const results = data?.governanceStats?.openIssueCountByProject?.results || [];

        return (
          <table>
            <thead>
              <tr>
                <th>
                  <Text type="secondary">PROJECT</Text>
                </th>
                <th style={{ float: 'right' }}>
                  <Text type="secondary">ISSUES</Text>
                </th>
              </tr>
            </thead>
            <tbody>
              {results.map((row, index) => {
                return (
                  <tr key={`row${index}`}>
                    <td style={{ width: '80%' }}>
                      <Link to={`/issues?status=open&projectId=${row.projectId}`} style={{ color: '#5d94e4' }}>
                        {row.projectName}
                      </Link>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Link to={`/issues?status=open&projectId=${row.projectId}`} style={{ color: '#5d94e4' }}>
                        {row.count.toLocaleString()}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        );
      }}
    </QueryResult>
  );
}

export default OpenIssuesByProject;

import React from 'react';
import uiConstants from 'constants/ui';
import throttle from 'just-throttle';

const SIDENAV_IS_OPEN = 'sidenav-is-open';
const OPEN = 'open';
const CLOSED = 'closed';

function getNavToggleStateFromLocalStorage() {
  const savedItem = localStorage.getItem(SIDENAV_IS_OPEN);

  // default to open for first time users;
  if (!savedItem) return true;

  if (savedItem === CLOSED) return false;

  return true;
}

interface Props {
  children: (renderProps: SidenavRenderProps) => React.ReactNode | React.ReactNodeArray;
}

interface State {
  open: boolean;
  permanent: boolean;
}

export interface SidenavRenderProps extends State {
  toggleSidenav: (open?: boolean) => void;
}

const breakpointWidth = uiConstants.navBreakpointWidth;

class SidenavController extends React.Component<Props, State> {
  handleResizeThrottled: () => any;

  constructor(props: Props) {
    super(props);

    this.state = {
      open: getNavToggleStateFromLocalStorage(),
      permanent: true
    };

    this.handleToggleSidenav = this.handleToggleSidenav.bind(this);
    this.handleResizeThrottled = throttle(() => {
      this.handleWindowResize();
    }, 500);
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  async componentDidMount() {
    this.handleWindowResize();

    window.addEventListener('resize', this.handleResizeThrottled);
  }

  handleWindowResize() {
    const { permanent: prevPermanent, open: prevOpen } = this.state;

    // permanent menu is always visible.
    const permanent = this.checkIsDesktop();

    // if mobile, collapse.
    const open = permanent === false ? false : prevOpen;

    if (permanent !== prevPermanent) {
      this.setState(
        {
          permanent,
          open
        },
        () => {
          this.saveNavToggleStateToLocalStorage();
        }
      );
    }
  }

  saveNavToggleStateToLocalStorage() {
    const { permanent, open } = this.state;

    if (!permanent) return;

    localStorage.setItem(SIDENAV_IS_OPEN, open ? OPEN : CLOSED);
  }

  checkIsDesktop() {
    return window.matchMedia(`(min-width: ${breakpointWidth}px)`).matches;
  }

  handleToggleSidenav(open?: boolean) {
    this.setState(
      prev => ({
        open: open === undefined ? !prev.open : open
      }),
      () => {
        this.saveNavToggleStateToLocalStorage();
      }
    );
  }

  render() {
    const { open, permanent } = this.state;
    const { children } = this.props;

    const renderProps: SidenavRenderProps = {
      open,
      permanent,
      toggleSidenav: this.handleToggleSidenav
    };

    return children(renderProps);
  }
}

export default SidenavController;

import { Icon } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import NeoPage, { TitleBar, TitleBarButton } from 'components/ui/NeoPage';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import OpCreate from '../OpCreate';
import OpDetail from '../OpDetail';
import OpsActivity from './OpsActivity';
import OpsList from './OpsList';

interface GuardrailsSectionProps
  extends RouteComponentProps<{
    tab?: string;
  }> {}

function OpsSection(props: GuardrailsSectionProps) {
  return (
    <NeoPage
      titleBarHasNav
      titleBar={
        <TitleBar
          sectionTitle="Ops"
          icon={<DopeIcon name="OP" size={20} />}
          actions={[
            <TitleBarButton icon={<DopeIcon name="ADD" />} linkTo="/ops1/create">
              {'Create'}
            </TitleBarButton>,
            <a href="https://vimeo.com/489488637/b81c746e80" target="_blank" rel="noreferrer noopener">
              <Icon type="youtube" />
              &nbsp;Watch Tutorial&nbsp;
              <DopeIcon name="EXTERNAL_LINK" />
            </a>
          ]}
          navConfig={[
            {
              label: 'Ops',
              to: '/ops1'
            },
            // OpsActivity component needs to point to automation not governance before this is turned back on.
            {
              label: 'Activity',
              to: '/ops1/activity'
            }
          ]}
        />
      }
    >
      <Switch>
        <Route path="/ops1/details/:id?" component={OpDetail} />
        <Route path="/ops1/create" component={OpCreate} />
        <Route path="/ops1/activity" component={OpsActivity} />
        <Route path="/ops1" component={OpsList} />

        <Route render={() => <Redirect to="/ops1" />} />
      </Switch>
    </NeoPage>
  );
}

export default OpsSection;

import gql from 'graphql-tag';

export const AWS_EC2_NETWORK_INTERFACES = gql`
  query AWSEc2NetworkInterfaces(
    $dops_regions: [String]
    $from: Int!
    $size: Int!
    $dops_full_text: String
    $dops_internal_account_ids: [String]
    $tags: [TagInput]
  ) {
    ec2_network_interfaces(
      dops_regions: $dops_regions
      from: $from
      size: $size
      dops_full_text: $dops_full_text
      dops_internal_account_ids: $dops_internal_account_ids
      tags: $tags
    ) {
      from
      size
      total_count
      results {
        id
        dops_resource_id
        dops_resource_uid
        dops_internal_account_id
        dops_name
        dops_resource_type
        dops_region
        network_interface_id
        interface_type
        tags {
          key
          value
        }
        dops_issue_summary {
          open_count
        }
      }
    }
  }
`;

import styled from 'styled-components';

export const WrapperDiv = styled.div`
  padding: 1rem;
  position: relative;
  display: flex;

  .detail {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    transition: width 0.3s ease-in, right 0.3s ease-in;
  }

  .detail__inner {
    margin: 1rem;
    box-sizing: border-box;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);
    background-color: white;
  }

  .main-content__primary-action {
    border-radius: 4px !important;
    margin-bottom: 1rem;
  }

  .table {
    width: calc(100% - 60px - 1rem);
    transition: width 0.3s ease-in;
  }

  .create-button {
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  .ant-tabs-nav .ant-tabs-tab {
    margin-right: 0;
  }

  .selected-row,
  .selected-row > td,
  .selected-row:hover,
  .selected-row:hover > td {
    background-color: ${(p) => p.theme.secondaryHighlight} !important;
  }

  @media only screen and (min-width: 1200px) {
    &.detail-active .table {
      width: 60%;
    }

    .detail {
      width: 40%;
      position: absolute;
      right: -40%;
    }

    &.detail-active .detail {
      right: 0;
      &.detail--expanded {
        width: 100%;
      }
    }
  }
`;

import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Popover } from 'antd';

import VerticalTable, { VerticalRowProps } from 'components/ui/Table/VerticalTable';

const TitleWrap = styled.h4`
  font-weight: 600;
  margin-bottom: 0;
`;

const TriggerWrap = styled.div`
  color: ${p => p.theme.primary500};
  cursor: pointer;
  display: inline-block;
  &:hover {
    color: ${p => p.theme.primary400};
    text-decoration: underline;
  }
`;

export interface Props {
  rows: VerticalRowProps[];
  dataSource: any;
  title: string;
  children: ReactNode;
  trigger?: 'hover' | 'focus' | 'click';
  classes?: {
    triggerWrap?: string;
  };
}

function PopoverDetailTable(props: Props) {
  const { rows, dataSource, children, title, trigger = 'click', classes = {} } = props;

  return (
    <Popover
      title={<TitleWrap>{title}</TitleWrap>}
      content={<VerticalTable rows={rows} dataSource={dataSource} />}
      trigger={trigger}
    >
      <TriggerWrap className={classes.triggerWrap || ''}>{children}</TriggerWrap>
    </Popover>
  );
}

export default PopoverDetailTable;

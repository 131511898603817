import React, { useContext } from 'react';
import styled from 'styled-components';
import { Tree } from 'antd';
import { Permissions } from '@disruptops/neo-core';

import QueryResult from 'components/util/QueryResult';
import { NodeType } from './types';
import useProjectTree, { ProjectTreeNode } from './useProjectTree';
import ProjectTreeNodeTitle from './ProjectTreeNodeTitle';
import { ClientProject } from 'typings';
import AuthContext from 'components/app/Auth/AuthContext';

export interface TreeNodeNormal {
  title?: React.ReactNode;
  checked: boolean;
  key: string;
  isLeaf?: boolean;
  disabled?: boolean;
  disableCheckbox?: boolean; // what is setting this?
  selectable?: boolean;
  children: TreeNodeNormal[];
}

const Root = styled.div`
  position: relative;

  .not-selectable {
    &,
    & * {
      cursor: default;

      background-color: transparent !important;
    }
  }

  .ant-tree {
    li {
      overflow: hidden;
    }
  }

  &.fill-checkbox-of-children-with-checked-parent
    .ant-tree-treenode-checkbox-checked
    .ant-tree-child-tree
    .ant-tree-checkbox {
    &.ant-tree-checkbox-disabled {
      cursor: help;
    }

    .ant-tree-checkbox-inner {
      background-color: #fff;
      border-color: #d9d9d9;

      &:after {
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        background-color: #673ab7;
        border: 0;
        -webkit-transform: translate(-50%, -50%) scale(1);
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        content: ' ';
      }
    }
  }
`;

function convertProjectTreeNodeToAntTreeNode(
  projectTreeNode: ProjectTreeNode,
  checkedProjectIds: string[],
  hasPermission: (node: ProjectTreeNode) => boolean
): TreeNodeNormal {
  const disabled = hasPermission ? !hasPermission(projectTreeNode) : false;

  const node: TreeNodeNormal = {
    key: projectTreeNode.project_id,
    title: <ProjectTreeNodeTitle nodeType={NodeType.project}>{projectTreeNode.name}</ProjectTreeNodeTitle>,
    selectable: true,
    checked: checkedProjectIds ? checkedProjectIds.includes(projectTreeNode.project_id) : false,
    children: [],
    disabled: disabled
  };

  if (projectTreeNode.accounts_list) {
    node.children.push(
      ...projectTreeNode.accounts_list.map((a) => ({
        key: a.account_id,
        title: <ProjectTreeNodeTitle nodeType={NodeType.cloudAccount}>{a.nickname || a.name}</ProjectTreeNodeTitle>,
        selectable: false,
        checked: checkedProjectIds ? checkedProjectIds.includes(projectTreeNode.project_id) : false,
        children: [],
        checkable: false,
        disabled
      }))
    );
  }

  if (projectTreeNode.childProjects) {
    node.children.push(
      ...projectTreeNode.childProjects.map((p) =>
        convertProjectTreeNodeToAntTreeNode(p, checkedProjectIds, hasPermission)
      )
    );
  }

  return node;
}

interface Props {
  checkable?: boolean;
  requiredPermissions?: Permissions | Permissions[]; // will check each node and disable it if permission is not there
  onCheck?: (checked: string[]) => void;
  checkedProjectIds?: string[];
  onSelect?: (selectedProjectIds: string[]) => void;
  selectedProjectIds?: string[];
  disabled?: boolean;
  rootProjectId?: string; // if present: should only return branch of tree starting at this Id.
  onProjectsLoaded?: (projects: ClientProject[]) => any | void;

  // if true: only the selected key will be returned rather than all selected keys.
  allowMultipleNodes?: boolean;
  cascade?: boolean;
  maxHeight?: string;
}

function ProjectTree(props: Props) {
  const {
    checkable = false,
    onCheck,
    checkedProjectIds = [],
    onSelect,
    selectedProjectIds,
    disabled = false,
    rootProjectId,
    allowMultipleNodes = true,
    cascade = false,
    onProjectsLoaded,
    requiredPermissions = [],
    maxHeight
  } = props;

  const authContext = useContext(AuthContext);

  const rootClassNames = cascade ? 'fill-checkbox-of-children-with-checked-parent' : '';

  const { loading, error, projectTree, getParentProjects } = useProjectTree({
    rootProjectId,
    onProjectsLoaded: (allProjects) => {
      if (!onProjectsLoaded) return;
      onProjectsLoaded(allProjects);
    }
  });

  const projectTreeJSON = JSON.stringify(projectTree);

  const treeNodes = projectTree.map((projectTreeRootNode) =>
    convertProjectTreeNodeToAntTreeNode(projectTreeRootNode, checkedProjectIds, (projectTreeNode: ProjectTreeNode) => {
      if (!authContext) return true;

      return (
        authContext?.checkEffectivePermissionsAgainstProjectIds(requiredPermissions, [projectTreeNode.project_id])
          .length === 0
      );
    })
  );

  const handleCheck = (checkedState: string[] | { checked: string[]; halfChecked: string[] }) => {
    const checked = Array.isArray(checkedState) ? checkedState : checkedState.checked;

    if (checked.length === 0) {
      return onCheck && onCheck(checked);
    }

    const newlyChecked = checked[checked.length - 1];

    if (!allowMultipleNodes) {
      return onCheck && onCheck([newlyChecked]);
    }

    if (!cascade || checked.length < checkedProjectIds.length) {
      return onCheck && onCheck(checked);
    }

    const previouslyCheckedParentProjectIds = checkedProjectIds.map((checkedProjectId) =>
      getParentProjects(checkedProjectId).map((project) => project.project_id)
    );

    const previouslyCheckedProjectIdsWithoutChildren = checkedProjectIds.filter((checkedProjectId, i) =>
      previouslyCheckedParentProjectIds[i].includes(newlyChecked) ? false : true
    );

    const newlyCheckedParentProjects = getParentProjects(newlyChecked);

    const newlyCheckedIsChildOfPreviouslyCheckedProjects = newlyCheckedParentProjects.find((newlyCheckedProject) => {
      return previouslyCheckedProjectIdsWithoutChildren.includes(newlyCheckedProject.project_id);
    })
      ? true
      : false;

    return (
      onCheck &&
      onCheck(
        newlyCheckedIsChildOfPreviouslyCheckedProjects
          ? previouslyCheckedProjectIdsWithoutChildren
          : [...previouslyCheckedProjectIdsWithoutChildren, newlyChecked]
      )
    );
  };

  const rootStyle = maxHeight ? { maxHeight, overflow: 'scroll'} : { };

  return (
    <QueryResult loading={loading && projectTree.length === 0} error={error} entityName="Project Tree">
      {() => (
        <Root className={rootClassNames} style={rootStyle}>
          <Tree
            key={`${rootProjectId}_${projectTreeJSON}`}
            disabled={disabled}
            defaultExpandAll
            defaultExpandParent
            selectedKeys={selectedProjectIds}
            onSelect={onSelect}
            checkStrictly={true}
            checkable={checkable}
            onCheck={handleCheck}
            treeData={treeNodes}
            checkedKeys={checkedProjectIds}
          />
        </Root>
      )}
    </QueryResult>
  );
}

/**
 * nodes cannot be checked if their ancestors are already checked.
 * need a better name for this.
 */
/*
function filterCheckedKeysOfCheckedAncestors(checkedProjectIds: string[], treeNodes: TreeNodeNormal[]): string[] {
  const filteredKeys = treeNodes.reduce((filteredKeys: string[], treeNode) => {
    if (checkedProjectIds.includes(treeNode.key) && !treeNode.hasCheckedAncestor) {
      // current node is checked and does not have checked node above.
      filteredKeys.push(treeNode.key);
      return filteredKeys;
    }

    if (treeNode.children) {
      return filteredKeys.concat(filterCheckedKeysOfCheckedAncestors(checkedProjectIds, treeNode.children));
    }

    return filteredKeys;
  }, []);

  return filteredKeys;
}
*/

//type StrictlyCheckedKeys = { checked: string[]; halfChecked: string[] };
//type NonStrictlyCheckedKeys = string[];

export default ProjectTree;

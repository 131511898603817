import React, { useEffect, useState } from 'react';
import { Heading } from './SourceLabelStyles';
import { EventSource } from '../TriggerContents/TriggerContents';

interface SourceLabelProps {
  sourceId?: string;
  sources: EventSource[];
}

function SourceLabel(props: SourceLabelProps) {
  const { sourceId, sources } = props,
    [sourceName, setSourceName] = useState(''),
    determineSourceName = () => {
      if (!sourceId) return 'All Sources';

      const record = sources?.find((row) => row.id === sourceId);
      return record ? record.name : 'Source not found';
    };

  useEffect(() => {
    if (sources?.length) {
      setSourceName(determineSourceName);
    }
  }, [sources]);

  return <Heading data-testid="heading">{sourceName}</Heading>;
}

export default SourceLabel;

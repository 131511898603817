import React, { useContext } from 'react';
import axios from 'axios';
import { RouteComponentProps } from 'react-router';

import { COLLECTOR_URL } from 'constants/runtimeConfig';
import NeoPage, { TitleBar, CenteredContainer } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import { GridCard } from 'components/ui/Card';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';
import { client } from 'services/graphql';
import AuthContext from 'components/app/Auth/AuthContext';
import ProgressCircular from 'components/ui/ProgressCircular';
import { AUTOMATION_EVENT_DEFINITION_QUERY } from './EventDetail';
import Form, { FormField } from 'components/ui/Form';
import { Button } from 'antd';

const CLIENT_API_KEYS_QUERY = gql`
  query clientAPIKeys {
    client {
      api_keys
    }
  }
`;

const Root = styled.div`
  .payload-textarea {
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

function EventSend(props: Props) {
  const eventId = props.match.params.id;
  const clientId = useContext(AuthContext)?.me?.client?.client_id;

  return (
    <Query query={AUTOMATION_EVENT_DEFINITION_QUERY} variables={{ id: [eventId] }}>
      {({ data, loading, queryError }) => (
        <QueryResult
          data={data}
          loading={loading}
          loadingMessage="Loading Event"
          error={queryError}
          expectSingleNode={true}
          parseNodes={(data) => data.eventDefinitions.nodes}
          entityName="AutomationEventDefinition"
        >
          {(item) => {
            const { name, example } = item;

            let formattedPayload = example;
            try {
              const exampleObj = JSON.parse(example);
              formattedPayload = JSON.stringify(exampleObj, null, 2);
            } catch (e) {
              // console.error({e});
            }

            return (
              <Root>
                <NeoPage
                  titleBar={
                    <TitleBar
                      icon={<DopeIcon name="EVENT" size={20} />}
                      sectionTitle="Events"
                      sectionTitleLinkTo="/dev/events"
                      backLinkTo={`/dev/events/${eventId}`}
                      backLinkTooltipText="Back to event definition"
                      title={`Send Event '${name}'`}
                    />
                  }
                >
                  <CenteredContainer>
                    <GridCard>
                      {formattedPayload && (
                        <Form
                          initialValues={{
                            name,
                            payload: formattedPayload,
                            responseData: []
                          }}
                          onSubmit={async (values, actions) => {
                            const { payload } = values;

                            try {
                              const payloadObj = JSON.parse(payload);

                              const result = await client.query({
                                query: CLIENT_API_KEYS_QUERY,
                                fetchPolicy: 'cache-first'
                              });
                              if (result.errors)
                                actions.setFieldValue(
                                  'responseData',
                                  result.errors.map((e) => JSON.stringify(e, null, 2))
                                );
                              const keys: string[] = result.data.client.api_keys;

                              const url = `${COLLECTOR_URL}/event`;

                              const response = await axios.post(url, payloadObj, {
                                auth: {
                                  username: clientId || '',
                                  password: keys[0]
                                }
                              });

                              actions.setFieldValue('responseData', [JSON.stringify(response.data, null, 2)]);
                            } catch (e) {
                              actions.setFieldValue('responseData', [JSON.stringify(e, null, 2)]);
                            } finally {
                              actions.setSubmitting(false);
                            }
                          }}
                        >
                          {(formValues) => {
                            const { canSubmit, isSubmitting } = formValues;

                            return (
                              <>
                                <FormField name="name" label="Name">
                                  {({ name, value }) => <>{value}</>}
                                </FormField>
                                <FormField name="payload" label="Payload">
                                  {({ name, value, handleChange, handleBlur }) => (
                                    <TextArea
                                      className="payload-textarea"
                                      value={value}
                                      autoSize={true}
                                      onChange={handleChange}
                                    />
                                  )}
                                </FormField>
                                <FormField name="responseData" label="Response Data">
                                  {({ name, value }) => {
                                    return isSubmitting ? (
                                      <ProgressCircular />
                                    ) : value ? (
                                      <TextArea className="payload-textarea" value={value} autoSize={true} />
                                    ) : null;
                                  }}
                                </FormField>
                                <Button type="primary" htmlType="submit" disabled={!canSubmit || isSubmitting}>
                                  Send To Collector
                                </Button>
                              </>
                            );
                          }}
                        </Form>
                      )}
                    </GridCard>
                  </CenteredContainer>
                </NeoPage>
              </Root>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

export default EventSend;

import { DownOutlined, QuestionOutlined, ExportOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';
import { UserNavButton, UserNavListDiv } from '../UserNav.styles';

function HelpMenu() {
  const menuContent = (
    <UserNavListDiv>
      <ul className="no-bullets">
        <li>
          <a href="https://help.disruptops.com/help-center" target="_blank" rel="noopener noreferrer">
            Help <ExportOutlined />
          </a>
        </li>
        <li>
          <a
            href="https://help.disruptops.com/articles/4248794-release-notes"
            target="_blank"
            rel="noopener noreferrer"
          >
            What's New <ExportOutlined />
          </a>
        </li>
        <li>
          <a onClick={sendFeedback}>Send Feedback</a>
        </li>
      </ul>
    </UserNavListDiv>
  );

  function sendFeedback(e) {
    const { Intercom } = window as any;
    e.preventDefault();
    Intercom('showNewMessage', 'Feedback: \n\n');
  }

  return (
    <Popover placement="bottomRight" trigger="click" content={menuContent}>
      <UserNavButton>
        <div className="primary-icon-wrap">
          <QuestionOutlined className="primary-icon" />
        </div>
        <DownOutlined className="caret-down" />
      </UserNavButton>
    </Popover>
  );
}

export default HelpMenu;

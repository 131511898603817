import ErrorAlert from 'components/ui/ErrorAlert';
import ContentWithDetailPane, { CreateButton } from 'designSystem/ContentWithDetailPane/ContentWithDetailPane';
import CreatePanel from 'designSystem/CreatePanel/CreatePanel';
import PageHeading from 'designSystem/PageHeading/PageHeading';
import SearchBar, { SearchBarConfig, searchParamsToObject } from 'designSystem/SearchBar/SearchBar';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import { useHistory, Link } from 'react-router-dom';
import { Assessment } from 'typings';
import AddDetector from '../AddDetector/AddDetector';
import DetectorDetail from '../DetectorDetail/DetectorDetail';
import { Detector } from '../DetectorEditor/DetectorEditor';
import DetectorEditorPane from '../DetectorEditor/DetectorEditorPane';
import useQueryString from '../DetectorsCommon/useQueryString/useQueryString';
import DetectorsTable from '../DetectorsTable/DetectorsTable';
import ASSESSMENT_DETAIL_QUERY from '../gql/assessmentDetailQuery';
import { DetectorsPage } from './DetectorsController.styles';
import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';
import useDetectorVariants from '../DetectorsCommon/useDetectorVariants/useDetectorVariants';
import { CSSTransitionGroup } from 'react-transition-group';

const ASSESSMENTS_QUERY = gql`
  query AssessmentList(
    $search: String
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $labels: [String]
    $isEnabled: Boolean
  ) {
    assessments(
      search: $search
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      labels: $labels
      isEnabled: $isEnabled
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        id
        labels
        name
        projectId
        isEnabled
        isReadOnly
        staticConfiguration
        schedule
        dynamicConfiguration {
          id
          key
          node {
            id
            entityType
            entityId
            key
            value
          }
        }
        assessor {
          id
          name
          cloudVendor
          overview
          labels
          issueDefinitions {
            id
            name
            description
            severity
            key
            actors {
              id
              name
              key
              description
              parameters {
                name
                key
                type
                inputCode
                configCode
                defaultValue
                description
                placeholder
                many
                options
                required
              }
            }
          }
          parameters {
            type
            name
            key
            name
            inputCode
            configCode
            defaultValue
            defaultValue
            description
            placeholder
            many
            options
            required
          }
          eventTriggers {
            criteria {
              eventType
            }
          }
        }
        lastRanAt
        issueStats {
          openIssueCount
          exemptedIssueCount
          resolvedIssueCount
        }
        installedGuardrails {
          id
          isEnabled
        }
      }
    }
  }
`;

const helpText = 'Detectors find gaps in security posture. Running a detector produces Issues';

function DetectorsController(props) {
  const {
      match: {
        params: { id: detectorId, editing }
      },
      location,
      addDetector
    } = props,
    history = useHistory(),
    variants = useDetectorVariants(),
    isPostureMonitoring = variants.id === 'posture-monitoring',
    // don't keep re-initializing this
    [searchConfig] = useState<SearchBarConfig>([
      {
        key: 'search',
        label: `${variants.variantName} Name`,
        shortcut: true
      },
      {
        key: 'labels',
        label: 'Label'
      },
      {
        key: 'isEnabled',
        label: 'Is Enabled?',
        control: 'boolean'
      }
    ]),
    featureFlags = useFeatureFlag([Feature.USE_CASE_NAV]),
    [selectedDetector, setSelectedDetector] = useState<Detector | undefined>(),
    [detailPane, setDetailPane] = useState<JSX.Element | undefined>(),
    [fullscreenEdit, setFullscreenEdit] = useState(false),
    params = new URLSearchParams(location.search),
    queryString = useQueryString(),
    page = params.get('page') || undefined,
    searchObject = searchParamsToObject(params),
    { data, loading, error, refetch } = useQuery(ASSESSMENTS_QUERY, {
      variables: { ...searchObject, pageSize: 10, pageNumber: page && parseInt(page, 10) }
    }),
    [queryDetector] = useLazyQuery(ASSESSMENT_DETAIL_QUERY, {
      variables: { id: detectorId },
      onCompleted: (detectorData) => {
        setSelectedDetector(detectorData?.assessments?.nodes?.[0]);
      }
    });

  function onRefetchNeeded() {
    refetch();
  }

  function activateDetail() {
    if (selectedDetector) {
      setFullscreenEdit(false);
      setDetailPane(<DetectorDetail detector={selectedDetector} onRefetchNeeded={onRefetchNeeded} />);
    }
  }

  function activateEdit() {
    if (selectedDetector) {
      setDetailPane(<DetectorEditorPane detector={selectedDetector} />);
      setFullscreenEdit(true);
    }
  }

  function resetPanels() {
    setDetailPane(undefined);
    setFullscreenEdit(false);
  }

  function onAddDetector() {
    history.push(`${variants.basePath}/add/${queryString}`);
  }

  function onPageChange(page: number) {
    if (page === 1) {
      params.delete('page');
    } else {
      params.set('page', page.toString());
    }

    const newQueryString = params.toString();
    if (newQueryString !== queryString) {
      history.push(`${location.pathname}?${newQueryString}`);
    }
  }

  function onRow(detector: Assessment) {
    history.push(`${variants.basePath}/${detector.id}${queryString}`);
  }

  useEffect(() => {
    if (data && detectorId) {
      const detector = data.assessments.nodes.find((detector) => detector.id === detectorId);

      if (!detector) {
        queryDetector({ variables: { id: detectorId } });
      }
      setSelectedDetector(detector);
    }

    if (!detectorId) {
      setSelectedDetector(undefined);
      resetPanels();
    }
  }, [detectorId, data]);

  useEffect(() => {
    if (selectedDetector && editing) {
      activateEdit();
    } else if (selectedDetector) {
      activateDetail();
    }
  }, [selectedDetector, editing]);

  return (
    <DetectorsPage>
      {(!featureFlags[Feature.USE_CASE_NAV] || variants.basePath === '/detectors') && (
        <PageHeading title="Detectors" subTitle={selectedDetector?.name} icon="DETECTOR" helpText={helpText} />
      )}

      <ContentWithDetailPane detailPane={detailPane} fullscreen={fullscreenEdit}>
        <CSSTransitionGroup transitionName="create" transitionEnterTimeout={200} transitionLeaveTimeout={100}>
          {addDetector && (
            <CreatePanel title={`Add ${variants.variantName}`} closeLink={`${variants.basePath}/${queryString}`}>
              <AddDetector />
            </CreatePanel>
          )}

          <div className="valign-center">
            <CreateButton handler={onAddDetector}>{`Add ${variants.variantName}`}</CreateButton>
            {isPostureMonitoring && <Link to={`${variants.basePath}/inventory`}>View Inventory</Link>}
          </div>

          {error ? (
            <ErrorAlert message={`Could not load ${variants.variantName}s`} error={error} onClose={() => refetch()} />
          ) : (
            <>
              <SearchBar config={searchConfig} />
              <DetectorsTable
                data={data}
                loading={loading}
                selectedId={detectorId}
                onPageChange={onPageChange}
                onRow={onRow}
              />
            </>
          )}
        </CSSTransitionGroup>
      </ContentWithDetailPane>
    </DetectorsPage>
  );
}

export default DetectorsController;

import gql from 'graphql-tag';

export const ASSESSORS_QUERY = gql`
  query Assessors($pageNumber: Int!, $pageSize: Int!, $search: String, $labels: [String]) {
    assessors(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: "name"
      sortDirection: ASC
      search: $search
      labels: $labels
    ) {
      pageInfo {
        total
        current
        size
      }
      nodes {
        id
        name
        key
        labels
        cloudVendor
        description
        overview
        parameters {
          name
          key
          type
          inputCode
          configCode
          defaultValue
          description
          placeholder
          many
          options
          required
        }
      }
    }
  }
`;

export const UNIQUE_ASSESSOR_LABELS = gql`
  query AssessmentLabelsForAddAssessment {
    uniqueAssessorLabels
  }
`;

export const SAVE_DETECTOR_MUTATION = gql`
  mutation UpdateAssessmentStaticConfiguration($id: ID!, $assessmentInput: AssessmentUpdateInput!) {
    updateAssessment(id: $id, assessmentInput: $assessmentInput) {
      id
      projectId
      name
      labels
      targetProjectIds
      staticConfiguration
      dynamicConfiguration {
        id
        key
        node {
          id
          clientId
          entityType
          entityId
          key
          value
        }
      }
    }
  }
`;

export const ADD_DETECTOR_MUTATION = gql`
  mutation AddAssessment($input: AssessmentCreateInput!) {
    createAssessment(assessmentInput: $input) {
      id
    }
  }
`;

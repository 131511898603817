import gql from 'graphql-tag';

const EVENT_SOURCE_LIST_QUERY = gql`
  query EventSources {
    eventSources(pageSize: 1000) {
      nodes {
        id
        name
      }
    }
  }
`;

export default EVENT_SOURCE_LIST_QUERY;

import styled from 'styled-components';

export const TriggerFilterEditorDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: top;
  margin-bottom: 0.5rem;

  .main-contents {
    width: 95%;
  }

  .actions {
    width: 5%;
    height: 30px;
    display: flex;
    align-items: center;
  }
`;

import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { UserNavButton, UserNavListDiv } from '../UserNav.styles';

function UserMenu() {
  const menuContent = (
    <UserNavListDiv>
      <ul className="no-bullets">
        <li>
          <Link to="/settings">User Settings</Link>
        </li>
        <li>
          <Link to="/logout">Sign Out</Link>
        </li>
      </ul>
    </UserNavListDiv>
  );

  return (
    <Popover placement="bottomRight" trigger="click" content={menuContent}>
      <UserNavButton>
        <div className="primary-icon-wrap">
          <UserOutlined className="primary-icon" />
        </div>
        <DownOutlined className="caret-down" />
      </UserNavButton>
    </Popover>
  );
}

export default UserMenu;

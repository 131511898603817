import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AssessmentsPage, { AddAssessment } from '.';
import { AssessmentDetailPage } from '..';

function AssessmentsSection() {
  return (
    <Switch>
      {/* old assessment link redirects */}
      <Redirect from="/assessments/add" to="/detectors/add" />
      <Redirect from="/assessments/:id" to="/detectors/:id" />
      <Redirect from="/assessments" to="/detectors" />

      {/* section routes */}
      <Route path="/detectors/add" component={AddAssessment} />
      <Route path="/detectors/:id" component={AssessmentDetailPage} />
      <Route path="/detectors" component={AssessmentsPage} />

      {/* catch-all, fallthrough redirect */}
      <Route render={() => <Redirect to="/detectors" />} />
    </Switch>
  );
}

export default AssessmentsSection;

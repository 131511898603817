import React from 'react';
import { client } from 'services/graphql';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { GuardrailActionProvider, SelectActionCollapseReadViewProps } from './index';
import { NewGuardrail, Function as DopsFunction, IssueDefinition } from 'typings';
import { Query } from 'react-apollo';
import { generateFieldSchema } from 'components/function/FunctionParameterInput';
import { Typography } from 'antd';
import IssueActorConfigurationInput from '../components/IssueActorConfigurationInput';
import ACTOR_FUNCTION_QUERY from '../../../gql/actorFunctionQuery';

export function buildSchemaFromActor(actor: DopsFunction) {
  if (!actor.parameters) return Yup.mixed();

  const shape = actor.parameters.reduce((acc, parameter) => {
    const schema = generateFieldSchema(parameter, true);

    return schema ? { ...acc, [parameter.key]: schema } : acc;
  }, {});

  return Yup.object().shape(shape);
}

function IssueActorSelectActionReadView(props: SelectActionCollapseReadViewProps) {
  const { functionId } = props;

  if (!functionId) return <Typography.Text>Select Action</Typography.Text>;

  return (
    <Query query={ACTOR_FUNCTION_QUERY} variables={{ id: functionId }}>
      {({ loading, error, data }) => {
        if (loading || error) return null;

        const actor: DopsFunction = data.functions.nodes[0];
        if (!actor) return null;

        return <Typography.Text>{actor.name}</Typography.Text>;
      }}
    </Query>
  );
}

function IssueActorSectionReadView(props: SelectActionCollapseReadViewProps) {
  const { functionId } = props;

  if (!functionId) return <Typography.Text>Select Action</Typography.Text>;

  return (
    <Query query={ACTOR_FUNCTION_QUERY} variables={{ id: functionId }}>
      {({ loading, error, data }) => {
        if (data && data.functions && data.functions.nodes[0]) {
          const actor = data.functions.nodes[0];

          return <>{actor.name}</>;
        }

        return <>{'Action'}</>;
      }}
    </Query>
  );
}

const disruptopsActionProvider: GuardrailActionProvider = {
  key: 'DISRUPTOPS_ACTION',
  label: 'Take Action on Issue',
  usesAutomationFunctions: false,
  getValidationSchema: async (guardrail: NewGuardrail) => {
    if (!guardrail || !guardrail.functionId) return Yup.mixed();

    const result = await client.query({
      query: ACTOR_FUNCTION_QUERY,
      variables: { id: guardrail.functionId }
    });

    const actor: DopsFunction = result.data.functions.nodes[0];
    if (!actor) return Yup.mixed();

    const { parameters } = actor;
    if (!parameters) return Yup.mixed();

    const schema = buildSchemaFromActor(actor);

    return schema;
  },
  getAvailableActions: async (guardrail: NewGuardrail) => {
    if (
      !guardrail ||
      !guardrail.trigger ||
      guardrail.trigger.eventType !== 'ISSUE_CREATED' ||
      !guardrail.issueDefinitionId
    ) {
      return [];
    }

    // EVENTUALLY WE WILL SUPPORT SCHEDULED ACTIONS THAT DON'T REQUIRE AN ISSUE

    const issueDefinitionId: string = guardrail.issueDefinitionId;

    const result = await client.query({
      query: gql`
        query IssueDefinitionActors($id: ID) {
          issueDefinitions(id: $id) {
            nodes {
              id
              actors {
                id
                name
                description
              }
            }
          }
        }
      `,
      variables: { id: issueDefinitionId }
    });

    if (result.errors) throw new Error(`Errors fetching issue def actors: ${JSON.stringify(result.errors)}`);
    if (!result.data.issueDefinitions.nodes) throw new Error('Could not find issue definition');

    const issueDefinition: IssueDefinition = result.data.issueDefinitions.nodes[0];

    return issueDefinition.actors
      ? issueDefinition.actors.map(actor => ({
          id: actor.id,
          key: actor.id,
          label: actor.name,
          description: actor.description
        }))
      : [];
  },
  sectionReadView: IssueActorSectionReadView,
  selectActionReadView: IssueActorSelectActionReadView,
  configurationInput: IssueActorConfigurationInput
};

export default disruptopsActionProvider;

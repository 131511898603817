import gql from 'graphql-tag';
import ISSUE_FRAGMENT from 'queries/fragments/issue';

const ISSUES_QUERY = gql`
  query ConfiguredOpIssueList($configuredOpId: String!) {
    issues(configuredOpId: $configuredOpId)
      @rest(type: "[Issue]", path: "/configured-ops/{args.configuredOpId}/issues?status=open") {
      ...issueFields
    }
  }

  ${ISSUE_FRAGMENT}
`;

export default ISSUES_QUERY;

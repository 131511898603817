import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import InvitePage from './pages/Invite';
import LoginPage from './pages/Login';
import RecoverAccountPage from './pages/RecoverAccount';
import ResetPasswordPage from './pages/ResetPassword';
import {
  NewAccountConfirmationPage,
  NewAccountPage,
  NewAccountPageEmbeddedLong,
  NewAccountPageEmbeddedShort
} from './pages/NewAccountPages';

interface Props {}

class UnauthenticatedSection extends Component<Props> {
  render() {
    return (
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/new-account" component={NewAccountPage} />
        <Route path="/new-account-embedded-long" component={NewAccountPageEmbeddedLong} />
        <Route path="/new-account-embedded-short" component={NewAccountPageEmbeddedShort} />
        <Route path="/recover-account" component={RecoverAccountPage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/confirm-new-account" component={NewAccountConfirmationPage} />
        <Route path="/invite/:username/:code" component={InvitePage} />
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    );
  }
}

export default UnauthenticatedSection;

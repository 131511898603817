import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  border: 1px solid ${p => p.theme.grey400};
  border-radius: 4px;
  overflow: hidden;

  margin-bottom: 16px;
`;

const Header = styled.div`
  padding: 8px 16px;
  background-color: ${p => p.theme.grey100};
  display: flex;
`;

const Title = styled.div`
  color: ${p => p.theme.grey700};
  font-weight: 600;
  display: flex;
  align-items: center;
  flex: 1;
`;

const IconWrap = styled.div`
  margin-right: 8px;

  svg {
    display: block;
  }
`;

const Content = styled.div`
  padding: 8px 16px;
  padding: 16px;
`;

const Action = styled.div``;

interface Props {
  icon?: React.ReactNode;
  title?: string | null;
  action?: React.ReactNode;
  children: React.ReactNode;
  classes?: {
    root?: string;
    header?: string;
  };
}

function ChildCard(props: Props) {
  const { title, classes = {}, children, icon, action } = props;

  return (
    <Root className={classes.root || ''}>
      {Boolean(title || action) && (
        <Header className={classes.header || ''}>
          {title && (
            <Title>
              {icon && <IconWrap>{icon}</IconWrap>}
              <div>{title}</div>
            </Title>
          )}
          {action && <Action>{action}</Action>}
        </Header>
      )}
      <Content>{children}</Content>
    </Root>
  );
}

export default ChildCard;

import gql from 'graphql-tag';

const ACTOR_FUNCTION_QUERY = gql`
  query ActorParameters($id: ID) {
    functions(id: $id) {
      nodes {
        id
        name
        key
        description
        parameters {
          name
          key
          type
          inputCode
          configCode
          defaultValue
          description
          placeholder
          many
          options
          required
        }
      }
    }
  }
`;

export default ACTOR_FUNCTION_QUERY;

import React from 'react';
import Button, { ButtonProps } from 'antd/lib/button';
import Tooltip, { TooltipPlacement } from 'antd/lib/tooltip';
import DopeIcon from 'components/ui/DopeIcon';
import { IconNames } from 'components/ui/DopeIcon/DopeIcon';

const roundButtonStyle = { padding: '2px 0 0 0' };
const roundIconStyle = { margin: '0px', paddingTop: '3px', paddingLeft: '1px' };

interface Props extends ButtonProps {
  icon: IconNames;
  iconSize?: number;
  text: string;
  tooltipPlacement: TooltipPlacement;
}

function RoundButton({ icon, text, iconSize = 20, tooltipPlacement, loading = false, ...props }: Props) {
  return (
    <Tooltip title={text} placement="left">
      <Button {...props} shape="circle-outline" style={roundButtonStyle} loading={loading}>
        {loading ? <>&nbsp;</> : <DopeIcon name={icon} size={iconSize} style={roundIconStyle} />}
      </Button>
    </Tooltip>
  );
}

export default RoundButton;

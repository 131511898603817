import React, { SyntheticEvent, useState } from 'react';
import { useQuery } from 'react-apollo';
import { Collapse, Icon, Typography, Button, Pagination } from 'antd';
import PageHeader from 'components/ui/PageHeader';
import QueryResult from 'components/util/QueryResult';
import { FilterItem, FilterRow } from 'components/app/FilterBar/components';
import { LabelsFilter, SearchFilter } from 'components/app/FilterBar/filters';
import Markdown from 'components/ui/Markdown';
import usePaginator from 'components/app/Paginator/usePaginator';
import { CloudVendorIcon } from 'components/cloudVendors';
import { CloudVendor, FunctionParameter } from 'typings';
import { AddDetectorDiv, AssessorCardHeaderRoot } from './AddDetector.styles';
import { ASSESSORS_QUERY, UNIQUE_ASSESSOR_LABELS } from '../DetectorsCommon/DetectorsGql/DetectorsGql';
import DetectorEditor, { Detector } from '../DetectorEditor/DetectorEditor';
import useDetectorVariants from '../DetectorsCommon/useDetectorVariants/useDetectorVariants';

interface AssessorFromQuery {
  id: string;
  cloudVendor: CloudVendor;
  name: string;
  key: string;
  description: string;
  overview: string;
  labels: string[];
  parameters: FunctionParameter[];
}

function AddDetector() {
  const { pageNumber, pageSize, search, filters, updateSearch, replaceFilter, updatePagination } = usePaginator(),
    [newDetector, setNewDetector] = useState<Detector>(),
    variants = useDetectorVariants(),
    { networkStatus, error, data } = useQuery(ASSESSORS_QUERY, {
      variables: {
        search,
        labels: filters.labels,
        pageNumber,
        pageSize
      }
    });

  if (newDetector) {
    const detectorToConfirm = {
      ...newDetector,
      dynamicConfiguration: [],
      staticConfiguration: [],
      assessor: newDetector
    };

    delete detectorToConfirm.labels;

    // @ts-ignore
    return <DetectorEditor detector={detectorToConfirm} createMode />;
  }

  return (
    <AddDetectorDiv>
      <PageHeader title={`Select ${variants.variantName}`} titleLevel={2} />

      <FilterRow>
        <FilterItem label="Search">
          <SearchFilter search={search || ''} onSearchChange={updateSearch} />
        </FilterItem>

        <FilterItem label="Labels">
          <LabelsFilter
            value={(Array.isArray(filters.labels) && filters.labels) || []}
            query={UNIQUE_ASSESSOR_LABELS}
            getLabelsRootFromQueryResult={(data) => data && data.uniqueAssessorLabels}
            onChange={(value) => {
              replaceFilter('labels', value);
            }}
          />
        </FilterItem>
      </FilterRow>

      <QueryResult
        loading={networkStatus <= 2}
        data={networkStatus <= 2 ? null : data}
        error={error}
        parseNodes={(data) => data?.assessors?.nodes}
      >
        {(assessors: AssessorFromQuery[]) => (
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
          >
            {assessors.map((assessor) => {
              const handleAddAssessor = (e: SyntheticEvent) => {
                e.stopPropagation();
                setNewDetector((assessor as unknown) as Detector);
              };

              return (
                <Collapse.Panel
                  key={assessor.id}
                  header={
                    <AssessorCardHeaderRoot>
                      <div className="assessment-icon-wrap">
                        <CloudVendorIcon vendor={assessor.cloudVendor} />
                      </div>

                      <Typography.Title level={4} className="assessor-card-header-title">
                        {assessor.name}
                      </Typography.Title>

                      <Typography.Text className="assessor-card-header-desc">{assessor.description}</Typography.Text>

                      <Button onClick={handleAddAssessor}>Add</Button>
                    </AssessorCardHeaderRoot>
                  }
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                    marginBottom: '16px',
                    border: '1px solid #ddd'
                  }}
                >
                  <Markdown source={assessor.overview || ''} />

                  <Button type={'primary'} onClick={handleAddAssessor}>
                    {`Add ${variants.variantName}`}
                  </Button>
                </Collapse.Panel>
              );
            })}
          </Collapse>
        )}
      </QueryResult>

      <Pagination
        current={pageNumber}
        pageSize={pageSize}
        total={data?.assessors?.pageInfo?.total}
        onChange={(page) => updatePagination({ pageNumber: page })}
        style={{ float: 'right' }}
      />
    </AddDetectorDiv>
  );
}

export default AddDetector;

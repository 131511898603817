import { Select } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { RECIPIENT_TYPE_DEFINITIONS } from './common';

interface RecipientTypeSelectProps {
  onSelect: (type: string) => void;
  placeholder?: string;
}
const RecipientOption = styled.div`
  display: flex;
  align-items: center;

  .recipient-select-icon-wrap {
    margin-right: 8px;
  }
`;
function RecipientTypeSelect(props: RecipientTypeSelectProps) {
  const { placeholder = 'Connect new Recipient', onSelect } = props;

  const supportedRecipientTypeDefinitions = RECIPIENT_TYPE_DEFINITIONS;

  supportedRecipientTypeDefinitions.sort((a, b) => {
    return a.label?.localeCompare(b.label, undefined, { sensitivity: 'base' });
  });

  return (
    <Select
      placeholder={placeholder}
      style={{ width: '100%', marginBottom: '16px' }}
      size="large"
      onChange={(key) => {
        const type = key as string;

        onSelect(type);
      }}
    >
      {supportedRecipientTypeDefinitions.map((def) => {
        return (
          <Select.Option key={def.key} value={def.key}>
            <RecipientOption className="recipient-option">
              <div className="recipient-select-icon-wrap">{def.icon}</div>
              <div className="recipient-option-label">{def.label}</div>
            </RecipientOption>
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default RecipientTypeSelect;

import React from 'react';
import SectionHeading from 'designSystem/SectionHeading/SectionHeading';
import { EyeFilled } from '@ant-design/icons';
import { DetectorsSection } from '../Detectors';

function ThreatMonitoring() {
  return (
    <div>
      <SectionHeading title="Threat Monitoring" icon={<EyeFilled />} />
      <DetectorsSection />
    </div>
  );
}

export default ThreatMonitoring;

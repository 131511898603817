import gql from 'graphql-tag';
import { TeamMember } from 'typings';

export interface QueryData {
  userList: {
    page: number;
    total: number;
    items: TeamMember[];
  };
}

export const USER_LIST_QUERY = gql`
  query TeamList($pageNumber: Number = 1, $pageSize: Number = 20) {
    userList(pageNumber: $pageNumber, pageSize: $pageSize)
      @rest(type: "UserListPayload", path: "/users?page={args.pageNumber}&per_page={args.pageSize}", method: "GET") {
      page
      total
      per_page
      items {
        id
        email
        first_name
        last_name
        target_username
        created
        updated
        cognito_client_id
        cognito_user_pool_id
        username
      }
    }
  }
`;

export default USER_LIST_QUERY;

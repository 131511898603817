import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { FormikActions } from 'formik';
import { Modal, Button } from 'antd';
import * as Yup from 'yup';
import styled from 'styled-components';

import FormField from 'components/ui/Form/FormField';
import Form from 'components/ui/Form/Form';
import UserSelect from 'components/user/UserSelect';
import { UserGroup, UserGroupMember } from 'typings';
import ErrorAlert from 'components/ui/ErrorAlert';
import Space from 'components/ui/Space';

const ActionsWrap = styled.div`
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 16px;
  }
`;

const formSchema = Yup.object().shape({
  userIds: Yup.array().of(Yup.string().required('A user is required'))
});

export interface Props {
  userGroup: UserGroup;
  members: UserGroupMember[];
  visible: boolean;
  onCancel: () => any;
  onSuccess: (userIds: string[]) => any;
}

interface FormValues {
  userIds?: string[];
}

const ADD_USERS_TO_GROUP = gql`
  mutation AddUsersToGroup($userGroupId: String!, $input: [String]) {
    addUserToGroup(userGroupId: $userGroupId, input: $input)
      @rest(type: "Policy", path: "/user-groups/{args.userGroupId}/members", method: "PUT") {
      id
    }
  }
`;

function AddUsersModal(props: Props) {
  const { userGroup, members, visible, onCancel, onSuccess } = props;

  const [addUsers, { error }] = useMutation(ADD_USERS_TO_GROUP);

  return (
    <Modal title="Add users" visible={visible} footer={false} onCancel={onCancel} width={700}>
      <Form
        initialValues={{
          userIds: []
        }}
        onSubmit={async (formValues: FormValues, actions: FormikActions<any>) => {
          const { userIds = [] } = formValues;

          await addUsers({
            variables: {
              userGroupId: userGroup.id,
              input: userIds.map((user_id) => ({ user_id }))
            }
          });

          actions.resetForm();
          onSuccess(userIds);
        }}
        validationSchema={formSchema}
      >
        {(formikProps) => {
          const { isSubmitting, canSubmit } = formikProps;

          return (
            <Space direction="vertical" size={12}>
              {error && <ErrorAlert error={error} />}

              <FormField name="userIds" label="Users">
                {({ value, handleBlur, handleChange }) => {
                  return (
                    <UserSelect
                      filterUserIds={members.map((member) => member.user_id)}
                      value={value}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      many={true}
                    />
                  );
                }}
              </FormField>

              <ActionsWrap>
                <Button type="primary" htmlType="submit" loading={isSubmitting} disabled={!canSubmit}>
                  Add user(s)
                </Button>
              </ActionsWrap>
            </Space>
          );
        }}
      </Form>
    </Modal>
  );
}

export default AddUsersModal;

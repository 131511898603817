import { NewGuardrail } from 'typings';

import disruptopsIssuesEventSource from './disruptopsIssuesEventSource';
import customEventSource from './customEventSource';
import awsEventSource from './awsEventSource';
import { GuardrailFilterDefinition } from '../../filter/definitions';
import { Feature } from 'components/app/FeatureFlag';

export const TRIGGER_EVENT_SOURCE_DEFINITIONS: GuardrailTriggerEventSourceDefinition[] = [
  disruptopsIssuesEventSource,
  customEventSource,
  awsEventSource
  // USE WHEN WE ARE READY TO START SUPPORTING UNSCHEDULED EVENTS.
  // {
  //   key: 'DISRUPTOPS_SCHEDULE',
  //   name: 'Schedule',
  //   triggers: [{ key: 'DAILY', name: 'Daily' }]
  // }
];

export interface TriggerSectionTitleReadViewProps {
  guardrail: NewGuardrail;
}

export interface ActionSourceDefinition {
  key: string; // 'SLACK', 'TAKE_ACTION', 'EMAIL',
}

export interface GuardrailConfigurationInputProps {
  name: string;
  event?: any;
  guardrail: NewGuardrail;
}

export interface GuardrailTriggerEventSourceDefinition {
  key: string; // unique should match governance
  name: string; // human readable
  eventTypes: GuardrailTriggerEventTypeDefinition[];
  featureFlag?: Feature; // feature flag.
}

export interface GuardrailTriggerEventTypeDefinition {
  key: string;
  name: string;
  schema?: any; // Yup schema have types?

  sectionTitle: (props: TriggerSectionTitleReadViewProps) => JSX.Element;

  // input used to configure Event Source;
  configurationInput?: (props: GuardrailConfigurationInputProps) => JSX.Element;

  getConfigureCollapseTitle?: () => string;
  configureCollapseReadView?: (props: GuardrailConfigurationInputProps) => JSX.Element;

  filters?: GuardrailFilterDefinition[];

  getInitialConfigurationValuesFromGuardrail?: (guardrail: NewGuardrail) => any;
  transformGuardrailBeforeSaving?: (guardrail: NewGuardrail) => NewGuardrail;
}

export function findTriggerEventTypeDefinition(
  eventSourceKey: string | null,
  eventTypeKey: string | null
): GuardrailTriggerEventTypeDefinition | null {
  if (!eventSourceKey || !eventTypeKey) return null;

  const eventSourceDefinition = eventSourceKey
    ? TRIGGER_EVENT_SOURCE_DEFINITIONS.find(item => item.key === eventSourceKey)
    : null;

  if (!eventSourceDefinition) return null;

  const eventTypeDefinition = eventTypeKey
    ? eventSourceDefinition.eventTypes.find(eventType => eventType.key === eventTypeKey) || null
    : null;

  return eventTypeDefinition;
}

export default TRIGGER_EVENT_SOURCE_DEFINITIONS;

export function getCloudAccountProvider(account) {
  let provider = account && account.provider;
  return getProvider(provider);
}

export function getProvider(provider) {
  if (provider === 'aws') provider = 'AWS';
  else if (provider === 'azure') provider = 'Azure';
  return provider;
}

import { Button, Input, Form } from 'antd';
import React, { useState } from 'react';
import validator from 'services/validator';

export const schema = validator.array().of(
  validator.object().shape({
    key: validator.string().when('value', (value, schema) => (!value || value === '' ? schema : schema.required())),
    value: validator.string()
  })
);

type Tag = {
  key: string;
  value: string;
};

interface Props {
  name: string;
  value?: Tag[];
  onChange: (value: Tag[]) => any;
  onBlur?: () => void;
}

function TagsFilter(props: Props) {
  const { value = [], onChange, onBlur } = props;

  return (
    <>
      {value.map((tag, index) => {
        return (
          <div
            key={index}
            style={{
              marginTop: '2px',
              display: 'grid',
              gridTemplateColumns: '1fr 8px auto',
              marginBottom: '2px'
            }}
          >
            <TagInput
              value={tag}
              onChange={(changedTag) => {
                const tags = value.map((tag, i) => {
                  if (i !== index) return tag;
                  return changedTag;
                });

                onChange(tags);
              }}
              onBlur={onBlur}
            />

            <span />

            <Button
              title="Remove tag"
              icon="minus"
              onClick={() => {
                onChange(value.filter((_, i) => i !== index));
              }}
              style={{ borderRadius: '4px' }}
            />
          </div>
        );
      })}

      <Button
        title="Add tag"
        icon="plus"
        style={{ marginTop: '4px' }}
        onClick={() => {
          onChange([
            ...value,
            {
              key: '',
              value: ''
            }
          ]);
        }}
      >
        Add Tag
      </Button>
    </>
  );
}

interface TagInputProps {
  value: Tag;
  onChange: (value: Tag) => void;
  onBlur?: () => void;
}

function TagInput(props: TagInputProps) {
  const { value, onChange, onBlur } = props;

  const tagKey = value.key;
  const tagValue = value.value;

  const [touched, setTouched] = useState<{ key: boolean; value: boolean }>({ key: false, value: false });

  const hasKey = tagKey ? true : false;
  const hasValue = tagValue ? true : false;
  const missingKeyOrValue = hasKey !== hasValue;
  const bothTouched = touched.key && touched.value;

  const error = missingKeyOrValue && bothTouched ? 'Both a tag key and value are required.' : undefined;

  return (
    <Form.Item
      style={{ marginBottom: 0 }}
      validateStatus={missingKeyOrValue && bothTouched ? 'error' : undefined}
      help={error}
    >
      <div style={{ marginTop: '2px', display: 'grid', gridTemplateColumns: '1fr auto 1fr', marginBottom: '2px' }}>
        <Input
          value={tagKey}
          onChange={(event) => {
            const tagKey = event.target.value;
            onChange({ key: tagKey, value: tagValue });
          }}
          onBlur={() => {
            const newTouched = {
              ...touched,
              key: true
            };
            setTouched(newTouched);

            const bothTouched = newTouched.key && newTouched.value;
            if (bothTouched && onBlur) onBlur();
          }}
          placeholder="Tag Key"
        />

        <span style={{ padding: '0px 8px', lineHeight: '32.5px' }}>:</span>

        <Input
          value={tagValue}
          placeholder="Tag Value"
          onChange={(event) => {
            const tagValue = event.target.value;
            onChange({ key: tagKey, value: tagValue });
          }}
          onBlur={() => {
            const newTouched = {
              ...touched,
              value: true
            };
            setTouched(newTouched);

            const bothTouched = newTouched.key && newTouched.value;
            if (bothTouched && onBlur) onBlur();
          }}
        />
      </div>
    </Form.Item>
  );
}

export default TagsFilter;

import React from 'react';
import { Tag, Popover } from 'antd';
import styled from 'styled-components';

import DateTime from 'components/ui/DateTime';
import { ResourceColumn, TableTagWrap } from 'components/cloudResources';
import IssueCountBadge from 'components/issue/IssueCountBadge';
import { ResourceTypeConfigItem } from 'components/cloudResources/cloudResourceTypesConfig';

import IAM_USERS_QUERY from './query';

import AWSIAMUserTable from './components/AWSIAMUserTable';
import CloudAccountLink from '../../components/CloudAccountLink';

const PopoverContent = styled.div``;

const PopoverTitle = styled.div`
  font-weight: 600;
`;

const PopoverItem = styled.div`
  > span:first-child {
    font-weight: 600;
  }
`;

const IAMUsers: ResourceTypeConfigItem = {
  key: 'AWS::IAM::User',
  label: 'IAM Users',
  labelPlural: 'IAM Users',
  labelSingular: 'IAM User',
  rowKey: 'user_id',
  tableQuery: () => IAM_USERS_QUERY,
  tableQueryVariables: () => ({}),
  tableColumnsConfig: [
    {
      title: 'User',
      key: 'user',
      className: 'no-wrap',
      render: (_, item) => <ResourceColumn resource={item} />
    },
    {
      title: 'Account',
      key: 'account',
      dataIndex: 'account',
      className: 'no-wrap',
      render: account => <CloudAccountLink account={account} />
    },

    {
      title: 'Groups',
      key: 'groups',
      dataIndex: 'groups',
      width: '33%',
      render: groups => {
        return !groups ? (
          '-'
        ) : (
          <>
            {groups.map((item, idx) => (
              <Popover
                key={`group_${idx}`}
                trigger="click"
                title={<PopoverTitle>{item.name}</PopoverTitle>}
                content={
                  <PopoverContent>
                    <PopoverItem>
                      <span>arn: </span>
                      {item.arn}
                    </PopoverItem>
                    <PopoverItem>
                      <span>ID: </span>
                      {item.group_id}
                    </PopoverItem>
                    {item.create_date && (
                      <PopoverItem>
                        <span>Created: </span>
                        <DateTime dateTime={new Date(item.create_date)} format="local" />
                      </PopoverItem>
                    )}
                  </PopoverContent>
                }
              >
                <TableTagWrap key={`group_${idx}`}>
                  <Tag color="blue">{item.name}</Tag>
                </TableTagWrap>
              </Popover>
            ))}
          </>
        );
      }
    },
    {
      title: 'Issues',
      dataIndex: 'dops_issue_summary.open_count',
      key: 'issue_summary_open',
      align: 'center' as 'right',
      className: 'no-wrap text-center',
      render: issueCount => <IssueCountBadge issueCount={issueCount} />
    },
    {
      title: 'Password last used',
      key: 'password_last_used',
      dataIndex: 'password_last_used',
      className: 'no-wrap',
      render: dt => {
        return !dt ? 'never' : <DateTime dateTime={new Date(dt)} />;
      }
    },
    {
      title: 'Access Keys',
      key: 'access_keys',
      dataIndex: 'access_keys',
      width: '33%',
      render: accessKeys => {
        return !accessKeys ? (
          '-'
        ) : (
          <>
            {accessKeys.map((item, idx) => (
              <Popover
                key={item.access_key_id}
                trigger="click"
                content={
                  <PopoverContent>
                    <PopoverItem>
                      <span>Status: </span>
                      {item.status}
                    </PopoverItem>
                    <PopoverItem>
                      <span>Created: </span>
                      <DateTime dateTime={new Date(item.create_date)} format="local" />
                    </PopoverItem>
                  </PopoverContent>
                }
              >
                <TableTagWrap>
                  <Tag color="blue">{item.id}</Tag>
                </TableTagWrap>
              </Popover>
            ))}
          </>
        );
      }
    },
    {
      title: 'MFA Devices',
      key: 'mfa_devices',
      dataIndex: 'mfa_devices',
      width: '33%',
      render: devices => {
        return !devices || !devices.length ? (
          '-'
        ) : (
          <>
            {devices.map(item => (
              <Popover
                key={item.serial_number}
                trigger="click"
                content={
                  <PopoverContent>
                    <PopoverItem>
                      <span>User Name: </span>
                      {item.user_name}
                    </PopoverItem>
                    <PopoverItem>
                      <span>Date Enabled: </span>
                      <DateTime dateTime={new Date(item.enable_date)} format="local" />
                    </PopoverItem>
                    <PopoverItem>
                      <span>Serial Number: </span>
                      {item.serial_number}
                    </PopoverItem>
                  </PopoverContent>
                }
              >
                <TableTagWrap>
                  <Tag color="blue">{item.user_name}</Tag>
                </TableTagWrap>
              </Popover>
            ))}
          </>
        );
      }
    }
  ],

  detailTable: AWSIAMUserTable
};

export default IAMUsers;

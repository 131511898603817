import React from 'react';

import VerticalTable, { VerticalRowProps } from 'components/ui/Table/VerticalTable';
import { CloudAccount, Issue } from 'typings';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { rawToDashed } from 'utilities/resourceType';
import { getResourceUrl, ResourceType } from 'utilities/inventory';
import { getCloudAccountProvider } from 'utilities/cloudAccount';

import IssueStatusLabel from 'components/issue/IssueStatusLabel';
import DateTime from 'components/ui/DateTime';

const Root = styled.div`
  border: 1px solid ${(p) => p.theme.grey300};
  margin-bottom: 20px;

  > * {
    border-bottom: 1px solid ${(p) => p.theme.grey300};
  }

  .vt-label-column {
    width: 175px;
    padding-right: 16px;
    text-align: right;
  }

  .table-wrap {
    padding: 12px 0;
  }

  .break-word {
    word-break: break-all;
  }
`;

interface Props {
  issue: Issue;
  account: CloudAccount;
  resourceTypes: ResourceType[];
}

function buildTable2Rows(
  issue: Issue,
  resourceTypesHash: {
    [key: string]: ResourceType;
  }
): VerticalRowProps[] {
  const { isExempted, exemptedReason, createdAt, updatedAt, parentItemKey, parentItemType } = issue;

  let vertTableRowProps: VerticalRowProps[] = [
    {
      key: 'status',
      dataIndex: 'resolved',
      label: 'Status',
      // render: resolved => (resolved ? 'Resolved' : 'Open')
      render: (_, issue) => <IssueStatusLabel issue={issue} />
    }
  ];

  if (createdAt) {
    vertTableRowProps = vertTableRowProps.concat([
      {
        key: 'createdAt',
        dataIndex: 'createdAt',
        label: 'Found',
        render: (dt) => <DateTime dateTime={dt} format="local" />
      }
    ]);
  }

  if (updatedAt) {
    vertTableRowProps = vertTableRowProps.concat([
      {
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        label: 'Last observed',
        render: (dt) => <DateTime dateTime={dt} format="local" />
      }
    ]);
  }

  if (isExempted) {
    vertTableRowProps = vertTableRowProps.concat([
      {
        key: 'exempt',
        dataIndex: 'exemptedAt',
        label: 'Date exempt',
        render: (dt) => <DateTime dateTime={dt} format="local" />
      }
    ]);
  }

  if (exemptedReason) {
    vertTableRowProps = vertTableRowProps.concat([
      {
        key: 'exempted-reason',
        dataIndex: 'exemptedReason',
        label: 'Exempt reason'
      }
    ]);
  }

  vertTableRowProps = vertTableRowProps.concat([
    {
      key: 'severity',
      dataIndex: 'severity',
      label: 'Severity',
      render: (severity) => severity || '-'
    },
    {
      key: 'resource_item',
      label: 'Resource item',
      dataIndex: 'itemKey',
      render: (itemKey: string, issue: Issue) => {
        const { accountId, itemType, region } = issue;

        if (!accountId || !itemType || !region) return itemKey;

        const itemUrl = getResourceUrl({
          accountId,
          itemType,
          itemKey,
          region
        });

        return (
          <div className="break-word">
            {resourceTypesHash[itemType] ? <Link to={itemUrl}>{itemKey}</Link> : itemKey}
          </div>
        );
      }
    },
    {
      key: 'item-type',
      dataIndex: 'itemType',
      label: 'Resource type',
      render: (itemType) => (
        <div className="break-word">
          {resourceTypesHash[itemType] ? (
            <Link to={`/inventory/resources/${rawToDashed(itemType)}`}>
              {resourceTypesHash[itemType].name || itemType}
            </Link>
          ) : (
            itemType
          )}
        </div>
      )
    }
  ]);

  if (parentItemKey && parentItemType) {
    vertTableRowProps = vertTableRowProps.concat([
      {
        key: 'parentItemKey',
        dataIndex: 'parentItemKey',
        label: 'Parent resource',
        render: (parentItemKey: string, issue: Issue) => {
          const { accountId, parentItemType, region } = issue;

          if (!accountId || !parentItemType || !region) return parentItemKey;

          const itemUrl = getResourceUrl({
            accountId,
            itemType: parentItemType,
            itemKey: parentItemKey,
            region
          });

          return (
            <div className="break-word">
              {resourceTypesHash[parentItemType] ? <Link to={itemUrl}>{parentItemKey}</Link> : parentItemKey}
            </div>
          );
        }
      },
      {
        key: 'parentItemType',
        dataIndex: 'parentItemType',
        label: 'Parent resource type',
        render: (itemType) => (
          <div className="break-word">
            {resourceTypesHash[itemType] ? (
              <Link to={`/inventory/resources/${rawToDashed(parentItemType)}`}>
                {resourceTypesHash[parentItemType].name || parentItemType}
              </Link>
            ) : (
              parentItemType
            )}
          </div>
        )
      }
    ]);
  }

  return vertTableRowProps;
}

function IssueSummaryTable(props: Props) {
  const { issue, account, resourceTypes } = props;
  const resourceTypesHash: {
    [key: string]: ResourceType;
  } = resourceTypes.reduce((acc, item) => {
    acc[item.key] = item;

    return acc;
  }, {});

  let provider = getCloudAccountProvider(account);

  return (
    <Root id="issue-summary-table">
      <div className="table-wrap">
        <VerticalTable
          dataSource={issue}
          rows={[
            {
              key: 'assessment',
              label: 'Detector',
              dataIndex: 'assessment',
              render: (assessment) => {
                return <Link to={`/detectors/${assessment.id}`}>{assessment.name}</Link>;
              }
            }
          ]}
          classes={{ labelCol: 'vt-label-column' }}
        />
      </div>

      <div className="table-wrap">
        <VerticalTable
          dataSource={issue}
          rows={buildTable2Rows(issue, resourceTypesHash)}
          classes={{ labelCol: 'vt-label-column' }}
        />
      </div>

      <div className="table-wrap">
        <VerticalTable
          dataSource={issue}
          rows={[
            {
              key: 'accountProvider',
              label: 'Account Provider',
              dataIndex: 'accountId',
              render: (accountId) => {
                return provider;
              }
            },
            {
              key: 'awsAccountId',
              dataIndex: 'awsAccountId',
              label: 'Account ID'
            },
            {
              key: 'accountNickame',
              label: 'Account Nickname',
              dataIndex: 'accountId',
              render: (accountId) => {
                return account && (account.nickname || account.name);
              }
            },
            {
              key: 'region',
              dataIndex: 'region',
              label: 'Region',
              render: (region) => region || '--'
            }
          ]}
          classes={{ labelCol: 'vt-label-column' }}
        />
      </div>
    </Root>
  );
}

export default IssueSummaryTable;

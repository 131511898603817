import { Alert, Button, Col, Collapse, Row, Tooltip, Typography, Menu, Dropdown, Icon, message } from 'antd';
import DateTime from 'components/ui/DateTime';
import DopeIcon from 'components/ui/DopeIcon';
import JSONTree from 'components/ui/JSONTree';
import Markdown from 'components/ui/Markdown';
import gql from 'graphql-tag';
import React from 'react';
import { useMutation } from 'react-apollo';
import styled from 'styled-components';
import { CloudAccount, Issue } from 'typings';
import { ResourceType } from 'utilities/inventory';
import MarkExemptButton from './MarkExemptButton';
import RemoveExemptButton from './RemoveExemptButton';
import SummaryTable from './SummaryTable';

const { Title } = Typography;

const Root = styled.div`
  .issue-detail-header {
    display: flex;
    justify-content: space-between;
    padding: 16px 52px 16px 32px;

    .issue-title {
      margin-bottom: 0;
    }
  }

  .issue-detail-actions {
    display: flex;

    > * {
      margin-left: 8px;
    }
  }

  .issue-detail-body {
    background-color: #f7f7f7;
    padding: 16px 32px;
  }

  .section {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    .section-title {
      color: ${(p) => p.theme.grey500};
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
  .json-tree-wrap {
    background-color: rgb(238, 238, 238);
    padding: 4px 16px;
    max-height: 400px;
    overflow-y: auto;
  }

  .action-results-collapse-panel .ant-collapse-arrow {
    display: none !important;
  }
`;

export const ArrowWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.25s ease;
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  margin-left: 16px;

  .ant-collapse-item-active & {
    transform: rotate(180deg);
  }
`;

export const ActionResultPanelHeader = styled.div`
  padding: 0 16px;
  display: flex;
  flex-wrap: no-wrap;
`;

export const ActionResultTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  flex: 1;
`;

export const ActionResultIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 20px;
  margin-right: 14px;

  &.success {
    color: ${(p) => p.theme.success};
  }

  &.error {
    color: ${(p) => p.theme.error};
  }
`;

export const ActionResultContent = styled.div`
  padding: 16px;
`;

export const SubsectionTitle = styled.h5`
  color: ${(p) => p.theme.grey500};
  margin-bottom: 2px;
`;

const JSONTreeWrap = styled.div`
  background-color: rgb(238, 238, 238);
  padding: 4px 16px;
  max-height: 400px;
  overflow-y: auto;
`;

const BROADCAST_ISSUE_CREATED_EVENT = gql`
  mutation BroadcastIssueCreatedEvent($issueId: String!) {
    broadcastIssueCreatedEvent(issueId: $issueId)
  }
`;

interface Props {
  showActions?: boolean;
  issue: Issue;
  account: CloudAccount;
  resourceTypes: ResourceType[];
  onClickTakeAction: () => void;
}

function IssueDetail(props: Props) {
  const { issue, account, resourceTypes, showActions = true, onClickTakeAction } = props;

  const [broadcast, { loading: isBroadcasting }] = useMutation(BROADCAST_ISSUE_CREATED_EVENT);

  if (!issue) {
    return (
      <Alert
        type="warning"
        message="No matching Issue found"
        description={<Typography>{`There was no matching issue found.`}</Typography>}
      />
    );
  }

  const {
    name,
    description,
    overview,
    detail,
    issueDefinition,
    isExempted,
    isResolved,
    isActing,
    actionResults
  } = issue;
  const issueHasActors = issueDefinition && issueDefinition.actors && issueDefinition.actors.length > 0;

  const takeActionButton = (
    <Button
      onClick={onClickTakeAction}
      disabled={!issueHasActors}
      className="btn-with-icon"
      type={'primary'}
      loading={isActing ? true : false}
    >
      <DopeIcon name="ISSUE_TAKE_ACTION" />
      {'Take action'}
    </Button>
  );

  return (
    <Root className="issue-detail">
      <div className="issue-detail-header">
        {name && (
          <Title level={3} className="issue-title">
            {name}
          </Title>
        )}

        {showActions && (
          <div className="issue-detail-actions">
            {!isResolved && !isExempted && (
              <>
                <MarkExemptButton issueId={issue.id} />
                {issueHasActors ? takeActionButton : <Tooltip title="No actions available">{takeActionButton}</Tooltip>}
              </>
            )}

            {!isResolved && isExempted && <RemoveExemptButton issueId={issue.id} />}
            <Dropdown
              overlay={
                <Menu
                  onClick={async () => {
                    const { data: broadcastResponse } = await broadcast({
                      variables: {
                        issueId: issue.id
                      }
                    });
                    console.log({ broadcastResponse });
                    message.success('This issue was successfully replayed.');
                  }}
                >
                  <Menu.Item key="1" disabled={isBroadcasting}>
                    Replay
                  </Menu.Item>
                </Menu>
              }
            >
              <Button>
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        )}
      </div>

      <div className="issue-detail-body">
        <Row type="flex" style={{ marginBottom: '32px' }}>
          <Col xs={{ span: 24, order: 2 }} lg={13}>
            {description && (
              <div className="section">
                <h4 className="section-title">{'Description'}</h4>
                <div className="summary-description">
                  <Markdown source={description} />
                </div>
              </div>
            )}

            {overview && (
              <div className="section">
                <h4 className="section-title">{'Overview'}</h4>
                <div className="summary-overview">
                  <Markdown source={overview} />
                </div>
              </div>
            )}

            {detail && (
              <div className="section">
                <h4 className="section-title">{'Issue details'}</h4>
                <div className="json-tree-wrap">
                  <JSONTree data={detail} depth={2} />
                </div>
              </div>
            )}

            {actionResults && actionResults.length > 0 && (
              <div className="section">
                <h4 className="section-title">{`${actionResults.length === 1 ? 'Action' : 'Actions'} Taken`}</h4>

                <Collapse className="action-results-collapse">
                  {actionResults.map((actionResult, idx) => {
                    const actionTemplate = actionResult.actor
                      ? actionResult.actor
                      : {
                          name: 'Unknown Action',
                          description: 'Something caused this action to not be able to be found.'
                        };

                    return (
                      <Collapse.Panel
                        key={`action-result_${idx}`}
                        className="action-results-collapse-panel"
                        header={
                          <ActionResultPanelHeader>
                            <ActionResultIcon className={actionResult.status === 'SUCCESS' ? 'success' : 'error'}>
                              {actionResult.status === 'SUCCESS' ? (
                                <DopeIcon name="SUCCESS" />
                              ) : (
                                <DopeIcon name="ERROR" />
                              )}
                            </ActionResultIcon>
                            <ActionResultTitle>{actionTemplate.name}</ActionResultTitle>
                            <DateTime dateTime={new Date(actionResult.createdAt)} />
                            <ArrowWrap>
                              <DopeIcon name="EXPAND_MORE" />
                            </ArrowWrap>
                          </ActionResultPanelHeader>
                        }
                      >
                        <ActionResultContent>
                          {actionTemplate.description && (
                            <div className="section">
                              <SubsectionTitle>Description</SubsectionTitle>
                              <div>{actionTemplate.description}</div>
                            </div>
                          )}
                          {actionResult.detail && (
                            <div className="section">
                              <SubsectionTitle>Results</SubsectionTitle>
                              <JSONTreeWrap>
                                <JSONTree data={actionResult.detail} />
                              </JSONTreeWrap>
                            </div>
                          )}
                        </ActionResultContent>
                      </Collapse.Panel>
                    );
                  })}
                </Collapse>
              </div>
            )}
          </Col>

          <Col xs={{ span: 24, order: 1 }} lg={{ offset: 1, span: 10, order: 2 }}>
            <SummaryTable issue={issue} account={account} resourceTypes={resourceTypes} />
          </Col>
        </Row>
      </div>
    </Root>
  );
}

export default IssueDetail;

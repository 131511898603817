import React from 'react';
import NeoPage, { TitleBar, CenteredContainer } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import gql from 'graphql-tag';
import EventsTable from './EventsTable';
import styled from 'styled-components';
import { AUTOMATION_EVENT_DEFINITION_FIELDS } from './EventDetail';
import { RouteComponentProps } from 'react-router';

const AUTOMATION_EVENT_DEFINITIONS_QUERY = gql`
  query EventDefinitionsForDevEventList(
    $id: [String]
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $search: String
  ) {
    eventDefinitions(
      id: $id
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
    ) {
      pageInfo {
        total
        current
        size
        sortBy
        sortDirection
      }
      nodes {
        ...AutomationEventDefinitionFields
      }
    }
  }
  ${AUTOMATION_EVENT_DEFINITION_FIELDS}
`;

const Root = styled.div`
  .centered-container {
    padding-bottom: 96px; // to get the intercom button out of the way of the pagination controls
    padding-top: 16px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

function EventsPage(props: RouteComponentProps) {
  return (
    <Root>
      <NeoPage
        titleBar={
          <TitleBar
            icon={<DopeIcon name="EVENT" size={20} />}
            sectionTitle="Events"
            sectionTitleLinkTo="/dev/events"
            backLinkTo="/dev"
            backLinkTooltipText="Back to dev section"
          />
        }
      >
        <CenteredContainer>
          <EventsTable
            query={AUTOMATION_EVENT_DEFINITIONS_QUERY}
            getEventsRootFromQueryResult={(data) => data && data.eventDefinitions}
          />
        </CenteredContainer>
      </NeoPage>
    </Root>
  );
}

export default EventsPage;

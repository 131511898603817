import React, { ReactNode } from 'react';

import styled from 'styled-components';

const Root = styled.div`
  padding: 30px 40px 40px;
  width: 100%;
`;

interface Props {
  children: ReactNode;
  classes?: {
    root?: string;
  };
}

function PanelContent(props: Props) {
  const { classes } = props;

  return <Root className={classes && classes.root ? classes.root : ''}>{props.children}</Root>;
}

export default PanelContent;

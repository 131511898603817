import { Issue } from 'typings';

export const typeDef = `
  extend type Issue {
    isActing: Boolean
  }
`;

export const resolvers = {
  Issue: {
    isActing: (issue: Issue) => false
  }
};

import React from 'react';
import { Input, Form } from 'antd';

function TextWidget(props) {
  const { autofocus, readonly, required, options, schema, formContext, registry, rawErrors, label, errors, ...inputProps } = props;

  // If options.inputType is set use that as the input type
  if (options.inputType) {
    inputProps.type = options.inputType;
  } else if (!inputProps.type) {
    // If the schema is of type number or integer, set the input type to number
    if (schema.type === 'number') {
      inputProps.type = 'number';
      // Setting step to 'any' fixes a bug in Safari where decimals are not
      // allowed in number inputs
      inputProps.step = 'any';
    } else if (schema.type === 'integer') {
      inputProps.type = 'number';
      // Since this is integer, you always want to step up or down in multiples
      // of 1
      inputProps.step = '1';
    } else {
      inputProps.type = 'text';
    }
  }

  // If multipleOf is defined, use this as the step value. This mainly improves
  // the experience for keyboard users (who can use the up/down KB arrows).
  if (schema.multipleOf) {
    inputProps.step = schema.multipleOf;
  }

  if (typeof schema.minimum !== 'undefined') {
    inputProps.min = schema.minimum;
  }

  if (typeof schema.maximum !== 'undefined') {
    inputProps.max = schema.maximum;
  }

  // console.log({rawErrors});

  return (
    <Form.Item label={label} validateStatus={errors || rawErrors ? 'error' : ''} required={required} help={rawErrors && `${label} ${rawErrors[0]}`}>
      <Input
        autoFocus={autofocus}
        readOnly={readonly}
        required={required}
        {...inputProps}
        onChange={({ target: { value } }) => props.onChange(value === '' ? options.emptyValue : value)}
      />
    </Form.Item>
  );
}

export default TextWidget;

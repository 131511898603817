import React from 'react';
import { Link } from 'react-router-dom';

import { FilterValues } from 'typings';

import EmptyState from 'components/ui/EmptyState';
import MailTo from 'components/ui/MailTo';

interface Props {
  search?: string;
  filterValues?: FilterValues;
}

function ResourcesEmptyState(props: Props) {
  const { search, filterValues = {} } = props;

  const filters = Object.keys(filterValues);

  const hasSearchOrFilters = search || filters.length > 0 ? true : false;
  const match = search ? `'${search}'` : 'the filters provided';

  const { pathname } = window.location;

  return (
    <EmptyState
      title={hasSearchOrFilters ? `No resources found matching ${match}.` : 'No cloud resources found... yet.'}
      message={
        hasSearchOrFilters ? null : (
          <>
            <p>
              Please make sure you have properly provisioned at least one cloud account. It can take up to 30 minutes
              for our system to update your inventory with your resouces. Take a coffee break and check back later.
            </p>
            <p>
              If you still don't see your resources, contact{' '}
              <MailTo emailAddress="support@disruptops.com" subject="Help with cloud account provisioning" />.
            </p>
          </>
        )
      }
      actions={
        hasSearchOrFilters ? <Link to={pathname}>Reset filters</Link> : <Link to="/cloud-accounts">Cloud accounts</Link>
      }
    />
  );
}

export default ResourcesEmptyState;

import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { ApolloProvider } from 'react-apollo';

import dopsTheme from 'constants/theme';

import { client } from '../services/graphql';

import { BrowserRouter as Router } from 'react-router-dom';
import Authenticator from 'components/app/Auth/Authenticator';
import AuthenticatedSection from './sections/Authenticated/Authenticated';
import UnauthenticatedSection from './sections/Unauthenticated';

const AppContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={dopsTheme}>
        <AppContainer>
          <ApolloProvider client={client}>
            <ConfigProvider locale={enUS}>
              <Router>
                <Authenticator>
                  {({ identity, me }) => {
                    if (identity && me) return <AuthenticatedSection user={me} />;
                    return <UnauthenticatedSection />;
                  }}
                </Authenticator>
              </Router>
            </ConfigProvider>
          </ApolloProvider>
        </AppContainer>
      </ThemeProvider>
    );
  }
}

export default App;

import { Table } from 'ant4x';
import { HighlightSelectedRow } from 'designSystem/ContentWithDetailPane/ContentWithDetailPane';
import { useFixedHeight } from 'designSystem/DesignSystemUtils/DesignSystemUtils';
import ProjectChip from 'designSystem/ProjectChip/ProjectChip';
import React, { useEffect, useRef } from 'react';
import { Op } from 'typings';
import TriggerBanner from '../../OpsCommon/TriggerBanner/TriggerBanner';
import ActionSummary from './ActionSummary/ActionSummary';
import { Root } from './OpsTable.styles';
import OpSwitch from './OpSwitch';

interface Props {
  data;
  selectedId: string;
  loading: boolean;
  onPageChange?: (page: number, pageSize?: number) => void;
  onRow: (op: Op) => void;
}

function OpsTable(props: Props) {
  const { data = {}, loading, onPageChange, onRow, selectedId } = props;
  const { ops: opsData, projects: projectsData } = data || {};
  const { nodes: ops, pageInfo } = opsData || {};
  const { current, size, total } = pageInfo || {};
  const { items: projects } = projectsData || {};

  const tableWrapper = useRef(null),
    tableHeight = useFixedHeight(tableWrapper, 140),
    columns = [
      {
        title: 'Enabled',
        key: 'isEnabled',
        dataIndex: 'isEnabled',
        width: '12%',
        render: (_isEnabled, op) => {
          return <OpSwitch op={op} />;
        }
      },
      {
        title: 'Name / Owning Project',
        key: 'name',
        width: '30%',
        dataIndex: 'name',
        render: (_value, op) => {
          const project = projects?.find((p) => p.project_id === op.projectId);
          return (
            <>
              <div>{op.name}</div>
              <span title="Owning Project">
                <ProjectChip project={project} />
              </span>
            </>
          );
        }
      },
      {
        title: 'Trigger',
        key: 'trigger',
        width: '45%',
        dataIndex: 'eventDefinition',
        render: (_value, op) => {
          return <TriggerBanner op={op} withFilter />;
        }
      },
      {
        title: 'Actions',
        key: 'actions',
        width: '13%',
        dataIndex: 'function',
        render: (value, op) => {
          return <ActionSummary func={value} op={op} recipients={data.recipients.items} />;
        }
      }
    ];

  useEffect(() => {
    HighlightSelectedRow(selectedId);
  }, [selectedId, loading]);

  function rowHandler(op) {
    return {
      onClick: () => {
        onRow && onRow(op);
      }
    };
  }

  return (
    <Root ref={tableWrapper}>
      <Table
        onRow={rowHandler}
        dataSource={ops}
        columns={columns}
        rowKey="id"
        scroll={{ y: tableHeight, x: 600 }}
        loading={loading}
        pagination={{
          current: current,
          onChange: onPageChange,
          pageSize: size,
          total: total,
          showTitle: false,
          showTotal: (total, range) =>
            `${range[0]?.toLocaleString()}-${range[1]?.toLocaleString()} of ${total?.toLocaleString()} items`,
          showSizeChanger: false,
          size: 'small'
        }}
      />
    </Root>
  );
}

export default OpsTable;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Divider } from 'antd';

import { ConfirmationModalState, OnSelectDecisionIntegrationFunc } from '../../OpEditor';
import { OpSectionListItem } from '../../components';

import { FormRenderProps } from 'components/ui/Form/Form';
import DopeIcon from 'components/ui/DopeIcon';
import { GridCard } from 'components/ui/Card';
import IconButton from 'components/ui/IconButton';
import OP_DECISION_INTEGRATION_DEFINITIONS from './definitions';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { ContextArgs } from '../../../EditorUtils/EditorUtils';

// COMPONENT: DECISION BLOCK
interface OpDecisionSectionProps {
  contextArgs: ContextArgs;
  onSelectDecisionIntegration: OnSelectDecisionIntegrationFunc;
  formRenderProps: FormRenderProps;
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
}

function OpDecisionSection(props: OpDecisionSectionProps) {
  const {
    contextArgs: { decisionIntegrationDefinition, op },
    onSelectDecisionIntegration,
    setConfirmationModal
  } = props;

  const FormComponent = (decisionIntegrationDefinition && decisionIntegrationDefinition.formComponent) || null;

  const existingProjectId = op?.projectId || null;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: existingProjectId || '*' }]
  });
  const isAuthorized = existingProjectId ? authz.isAuthorized : true;

  return (
    <GridCard
      classes={{ root: 'has-arrow' }}
      secondaryTitle="Decision"
      icon={
        (decisionIntegrationDefinition && decisionIntegrationDefinition.icon) || (
          <DopeIcon name="OP_DECISION" size={20} />
        )
      }
      title={
        decisionIntegrationDefinition ? (
          <div className="section-title-wrap">
            <span className="section-title">{decisionIntegrationDefinition.title}</span>
            {isAuthorized && (
              <IconButton
                type="ghost"
                size="small"
                onClick={(e) => {
                  e.preventDefault();

                  setConfirmationModal({
                    title: 'Clear Op Decision',
                    description: 'This section will be cleared and any settings will be lost once saved.',
                    onConfirm: () => {
                      onSelectDecisionIntegration(null);
                      setConfirmationModal(null);
                    }
                  });
                }}
              >
                <DopeIcon name="REMOVE" />
              </IconButton>
            )}
          </div>
        ) : (
          // add component to onSelectDecision
          'Auto'
        )
      }
      elevation={1}
      rounded
    >
      <Divider style={{ margin: '2px 0px 0px' }} />

      {decisionIntegrationDefinition ? (
        FormComponent ? (
          <>
            <FormComponent />
          </>
        ) : null
      ) : isAuthorized ? (
        <SelectDecisionIntegration {...props} />
      ) : null}
    </GridCard>
  );
}

// COMPONENT: SELECT DECISION INTEGRATION
interface SelectDecisionProps extends OpDecisionSectionProps {}

const SelectDecisionRoot = styled.div`
  .select-decision-options-list {
    transition: max-height 0.5s ease;
    height: initial;
    max-height: 400px;
    overflow: hidden;
  }

  &.collapsed .select-decision-options-list {
    max-height: 0px;
  }

  .select-decision-collapse-trigger {
    padding: 3px;
    display: flex;
    justify-content: center;
    color: ${(p) => p.theme.primary500};
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }
  }
`;

function SelectDecisionIntegration(props: SelectDecisionProps) {
  const {
    onSelectDecisionIntegration,
    contextArgs: { decisionIntegrationDefinition },
    formRenderProps
  } = props;
  const selectedKey: string | null = decisionIntegrationDefinition ? decisionIntegrationDefinition.key : null;
  const [collapsed, setCollapsed] = useState<boolean>(Boolean(formRenderProps.values?.name)); // default to collapsed if op exists.

  return (
    <SelectDecisionRoot className={`select-decision ${collapsed ? 'collapsed' : 'in'}`}>
      <div className="select-decision-options-list">
        <>
          <OpSectionListItem
            key="auto"
            title="Auto"
            description="Op will automatically run configured Action(s) if an event matching the criteria of the Trigger is discovered."
            icon={<DopeIcon name="OP_DECISION" size={20} />}
            onClick={() => onSelectDecisionIntegration(null)}
            selected={selectedKey === null}
          />
          <>
            {OP_DECISION_INTEGRATION_DEFINITIONS.map((def) => {
              return (
                <OpSectionListItem
                  key={def.key}
                  title={def.title}
                  description={def.description}
                  onClick={() => onSelectDecisionIntegration(def)}
                  icon={def.icon}
                />
              );
            })}
          </>
        </>
      </div>
      <div className="select-decision-collapse-trigger" onClick={() => setCollapsed(!collapsed)}>
        <div>{collapsed ? 'Show Options' : 'Hide Options'}</div>
      </div>
    </SelectDecisionRoot>
  );
}

export default OpDecisionSection;

import { Select } from 'antd';
import React from 'react';
import countriesAndStates from './CountriesAndStates.json';

interface StateSelectProps {
  name: string;
  value: any;
  onChange: (value: any) => void;
  onBlur: () => void;
}

function StateSelect(props: StateSelectProps) {
  const { onChange, value, onBlur } = props;
  const { states } = countriesAndStates as any;

  return (
    <Select
      value={value || 'default'}
      style={{ width: '100%' }}
      onBlur={onBlur}
      onChange={selected => {
        const selectedStr = selected ? selected.toString() : undefined;
        onChange(selectedStr === 'default' ? undefined : selectedStr);
      }}
    >
      <Select.Option value="default">Select State</Select.Option>
      {states.map((item, _index) => {
        return (
          <Select.Option key={`${item.abbreviation}`} value={item.name}>
            {item.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default StateSelect;

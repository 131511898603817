import { Button, Form, Icon, Input } from 'antd';
import { RecipientFormControlProps } from 'components/recipients/common';
import { Field, FieldArray, getIn } from 'formik';
import React from 'react';

function EmailRecipientFormControls(props: RecipientFormControlProps) {
  const { formRenderProps, prefix = 'config' } = props;

  const { values, setFieldValue } = formRenderProps;

  const fieldName = `${prefix}.toAddresses`;

  const toAddresses = getIn(values, fieldName) || [''];

  return (
    <Form.Item label="To Email Addresses">
      <FieldArray
        name={fieldName}
        render={(arrayHelpers) => (
          <div>
            {toAddresses?.map((_toAddress, index) => (
              <div key={index}>
                <Field name={`${fieldName}[${index}]`}>
                  {({ field }) => {
                    return (
                      <Input
                        type="email"
                        {...field}
                        addonAfter={<Icon type="close" title="Remove" onClick={() => arrayHelpers.remove(index)} />}
                      />
                    );
                  }}
                </Field>
              </div>
            ))}
            <Button
              icon="check"
              style={{ marginRight: '4px' }}
              onClick={() => {
                const splitAddresses = toAddresses.reduce((accumulator, currentValue) => {
                  currentValue
                    .split(';')
                    .filter((s) => !!s)
                    .map((s) => accumulator.push(s));
                  return accumulator;
                }, []);
                setFieldValue(fieldName, splitAddresses);
              }}
            >
              Split email addresses
            </Button>
            <Button icon="plus" onClick={() => arrayHelpers.push('')}>
              Add an email address
            </Button>
          </div>
        )}
      />
    </Form.Item>
  );
}

export default EmailRecipientFormControls;

import { Permissions } from '@disruptops/neo-core/dist/permissions';
import Avatar from 'app/sections/Authenticated/components/Avatar';
import collapsedLogo from 'assets/images/dops_logo_collapsed.png';
import { AuthContext } from 'components/app/Auth/AuthContext';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import FeatureFlag, { Feature } from 'components/app/FeatureFlag';
import DopeIcon from 'components/ui/DopeIcon';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { User } from 'typings';
import { SidenavRenderProps } from '../SidenavController';
import NavItem from './NavItem';
import SettingsPopover from './SettingsPopover';
import { LogoCollapsed, Root } from './styledComponents';

const Outer = styled.span``;

const HighlightedIcon = styled.span`
  border-radius: 100px;
  padding: 3px 3px;
  vertical-align: middle;
  line-height: 12px;
  display: inline-block;
  font-size: ${(p: { size: string }) => (p.size === 'large' ? '24px' : 'auto')};
  background-color: ${(p) => p.theme.primary};
  color: ${(p) => p.theme.grey200};
`;

interface MainSidenavProps {
  sidenavRenderProps: SidenavRenderProps;
  user: User;
}

function MainSidenav(props: MainSidenavProps) {
  const {
    sidenavRenderProps: { open, permanent, toggleSidenav },
    user
  } = props;
  let rootClasses = '';

  const srp = { ...props.sidenavRenderProps };

  if (open) rootClasses += ' open';
  if (permanent) rootClasses += ' permanent';

  const authContext = useContext(AuthContext);

  const authorizePermissionResults = useAuthorizor(Permissions.MODIFY_ACCOUNTS, [authContext?.rootProjectId ?? '*']);

  const { isAuthorized: canModifyAccounts } = authorizePermissionResults;

  return (
    <Root className={rootClasses}>
      <div className="main-sidenav-drawer">
        <Link className="branding" to="/">
          <LogoCollapsed backgroundImage={collapsedLogo} />
          <div className="company-name">{'DisruptOps'}</div>
        </Link>

        <FeatureFlag features={[Feature.GOVERNANCE_GUARDRAILS]}>
          {(resolvedFlags) => (
            <div className="main-sidenav-menu">
              {authContext?.me?.client?.settings?.complianceEnabled && (
                <NavItem
                  {...srp}
                  link="/compliance"
                  icon={<DopeIcon name="COMPLIANCE" size={18} />}
                  label={'Compliance'}
                />
              )}
              <NavItem {...srp} link="/detectors" icon={<DopeIcon name="ASSESSMENT" size={18} />} label={'Detectors'} />

              <NavItem {...srp} link="/ops" icon={<DopeIcon name="OP" size={18} />} label={'Ops'} />

              {resolvedFlags[Feature.GOVERNANCE_GUARDRAILS] && (
                <NavItem
                  {...srp}
                  link="/guardrails"
                  icon={<DopeIcon name="GUARDRAIL" size={18} />}
                  label={'Guardrails (Legacy)'}
                />
              )}
              <NavItem {...srp} link="/issues" icon={<DopeIcon name="ISSUES" size={18} />} label={'Issues'} />
              <NavItem {...srp} link="/events" icon={<DopeIcon name="EVENT" size={18} />} label={'Events'} />
              <NavItem {...srp} link="/projects" icon={<DopeIcon name="PROJECT" size={18} />} label={'Projects'} />
              <NavItem
                {...srp}
                link="/inventory/resources"
                icon={<DopeIcon name="INVENTORY" size={18} />}
                label={'Inventory'}
              />
              <NavItem
                {...srp}
                link="/cloud-accounts"
                icon={<DopeIcon name="CLOUD_ACCOUNT" size={18} />}
                label={'Cloud accounts'}
              />
              <NavItem {...srp} link="/activity" icon={<DopeIcon name="ACTIVITY" size={18} />} label={'Activity'} />
            </div>
          )}
        </FeatureFlag>

        <div className="sidenav-toggle-trigger" onClick={() => toggleSidenav()}>
          <div className="sidenav-toggle-trigger-icon">
            <DopeIcon name="CHEVRON_RIGHT" />
          </div>
        </div>

        <div className="main-sidenav-menu">
          {canModifyAccounts && (
            <NavItem
              {...srp}
              icon={
                <Outer>
                  <HighlightedIcon>
                    <DopeIcon name="STAR" size={18} />
                  </HighlightedIcon>
                </Outer>
              }
              label="Getting Started"
              link="/getting-started"
            />
          )}
          <a
            href="https://help.disruptops.com"
            className="main-nav-item-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="main-nav-item-body">
              <div className="nav-item-icon-wrap">
                <div className="nav-item-icon">
                  <DopeIcon name="QUESTION" size={18} />
                </div>
              </div>

              <div className="nav-item-label">Help</div>
            </div>
          </a>
          <NavItem
            {...srp}
            icon={<DopeIcon name="ORGANIZATION" size={18} />}
            label="Organization"
            link="/organization-settings/"
          />
          <SettingsPopover user={user}>
            <NavItem {...srp} icon={<Avatar />} label="User settings" />
          </SettingsPopover>
        </div>
      </div>
      {!permanent && open && <div className="drawer-mask" onClick={(e) => toggleSidenav(false)} />}
    </Root>
  );
}

export default MainSidenav;

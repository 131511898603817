import React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { Table } from 'antd';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

import { UserGroup } from 'typings';

import { parsePageData } from 'components/app/Paginator/Paginator';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import AuthorizationErrorAlert from 'components/app/Auth/AuthorizationErrorAlert';
import ButtonWithDisabledTooltip from 'components/buttons/ButtonWithDisabledTooltip';
import DopeIcon from 'components/ui/DopeIcon';
import { CenteredContainer } from 'components/ui/NeoPage';
import QueryResult from 'components/util/QueryResult';
import { TableWrap, TableHeader, TableHeaderActions, TableExpandedRow } from 'components/ui/Table';
import PageHeader from 'components/ui/PageHeader';

import USER_GROUP_LIST_QUERY from '../../gql/queries/userGroupList';
import UserGroupForm from './components/UserGroupForm';
import UserGroupDetail from './components/UserGroupDetail';

const TABLE_COLUMNS = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name'
  },
  {
    key: 'roles',
    title: '# of roles',
    dataIndex: 'role_count',
    align: 'center' as 'center',
    render: (roleCount) => roleCount.toLocaleString()
  },
  {
    key: 'user-ids',
    title: '# of users',
    dataIndex: 'member_count',
    align: 'center' as 'center',
    render: (memberCount) => memberCount.toLocaleString()
  },
  {
    key: 'edit-user',
    width: '50px',
    align: 'right' as 'right',
    dataIndex: 'id',
    render: (userGroupId) => {
      return (
        <Link className="reveal-on-row-hover" to={`/organization-settings/group-management/${userGroupId}`}>
          <DopeIcon name="EDIT" />
        </Link>
      );
    }
  }
];

function GroupManagementPage(props: RouteComponentProps<{ groupId?: string }>) {
  const { history, match } = props;
  const { groupId } = match.params;

  const viewAuthResult = useAuthorizor(Permissions.VIEW_USER_GROUPS);
  const modifyAuthResult = useAuthorizor(Permissions.MODIFY_USER_GROUPS);

  const { loading, error, data, refetch } = useQuery(USER_GROUP_LIST_QUERY, { skip: !viewAuthResult.isAuthorized });

  const handleCreateGroup = () => {
    const to = `/organization-settings/group-management${groupId !== 'create' ? '/create' : ''}`;
    history.push(to);
  };

  const handleGroupClose = () => {
    const to = `/organization-settings/group-management`;
    history.push(to);
  };

  const expandedRowKeys = groupId && groupId !== 'create' ? [groupId] : [];

  return (
    <QueryResult loading={loading} error={error} entityName="user groups" data={data}>
      {() => {
        const { items: userGroupList, pageSize, pageNumber, total } = parsePageData(data, 'userGroupList');

        const selectedGroup = userGroupList.find((group) => group.id === groupId);

        return (
          <CenteredContainer>
            <PageHeader title="Group management" titleLevel={2} />

            {!viewAuthResult.isAuthorized ? (
              <AuthorizationErrorAlert failedPermissions={viewAuthResult.failedPermissions} />
            ) : (
              <TableWrap>
                <Table
                  className="overflow-visible hide-expansion-icon"
                  title={() => (
                    <TableHeader
                      headerDrawer={
                        <UserGroupForm
                          userGroup={selectedGroup}
                          onCancel={() => history.push('/organization-settings/group-management')}
                          onFinish={async (id) => {
                            await refetch();
                            history.push(`/organization-settings/group-management/${id ? id : ''}`);
                          }}
                        />
                      }
                      headerDrawerOpen={groupId === 'create' ? true : false}
                    >
                      <TableHeaderActions>
                        <ButtonWithDisabledTooltip
                          disabled={!modifyAuthResult.isAuthorized}
                          disabledMessage={modifyAuthResult.message || ''}
                          onClick={handleCreateGroup}
                          tooltipPlacement="left"
                        >
                          Create group
                        </ButtonWithDisabledTooltip>
                      </TableHeaderActions>
                    </TableHeader>
                  )}
                  loading={loading}
                  rowKey="id"
                  columns={TABLE_COLUMNS}
                  dataSource={userGroupList}
                  pagination={{
                    hideOnSinglePage: true,
                    total: total,
                    current: pageNumber,
                    pageSize: pageSize
                  }}
                  expandedRowKeys={expandedRowKeys}
                  expandedRowRender={(item) => (
                    <TableExpandedRow>
                      <Switch>
                        <Route
                          path="/organization-settings/group-management/:userGroupId/edit"
                          render={() => (
                            <UserGroupForm
                              userGroup={item}
                              onCancel={() => history.push('/organization-settings/group-management')}
                              onFinish={async (id) => {
                                await refetch();
                                history.push(`/organization-settings/group-management/${id ? id : ''}`);
                              }}
                            />
                          )}
                        />

                        <Route
                          path="/organization-settings/group-management/:userGroupId"
                          render={() => <UserGroupDetail userGroup={item} onClose={handleGroupClose} />}
                        />
                      </Switch>
                    </TableExpandedRow>
                  )}
                  onRow={(item: UserGroup) => ({
                    onClick: (e: any) => {
                      if (e.target.closest('a')) return;

                      const groupId = selectedGroup && selectedGroup.id === item.id ? '' : item.id;
                      history.push(`/organization-settings/group-management/${groupId}`);
                    }
                  })}
                />
              </TableWrap>
            )}
          </CenteredContainer>
        );
      }}
    </QueryResult>
  );
}

export default GroupManagementPage;

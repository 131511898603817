import React, { useState } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { Select } from 'antd';
import { FilterTypeProps } from '../FilterListing/FilterListing';
import { SEVERITY_NAME_MAP } from 'constants/ui';

function SeverityEditor(props: FilterTypeProps) {
  const { values, filterType } = props,
    [softEdit, setSoftEdit] = useState<SoftEdit>({
      key: filterType.valueKey,
      value: values[filterType.valueKey]
    }),
    onChange = (changes) => {
      setSoftEdit({
        key: filterType.valueKey,
        value: changes
      });
    },
    formatSeverityLabel = (severityNumber) => {
      return `${SEVERITY_NAME_MAP[severityNumber - 1]} (${severityNumber})`;
    },
    editor = (
      <Select
        mode="multiple"
        onChange={onChange}
        value={softEdit.value}
        filterOption={(inputValue, option) => {
          if (!option || !option.props || !option.props.title) {
            return true;
          }
          return new RegExp(inputValue, 'i').test(option.props.title);
        }}
      >
        {SEVERITY_NAME_MAP.map((severity, index) => (
          <Select.Option key={severity} value={index + 1}>
            {formatSeverityLabel(index + 1)}
          </Select.Option>
        ))}
      </Select>
    );

  return <FilterRow {...props} editor={editor} softEdit={softEdit} />;
}

export default SeverityEditor;

import styled from 'styled-components';

export const OpFormDiv = styled.div`
  .header-has-icon .grid-card-content {
    padding-left: 16px;
  }

  .section-title-wrap {
    display: flex;

    .trigger-icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .section-title {
      margin-right: 16px;
    }
  }

  .grid-card {
    position: relative;

    .grid-card-content {
      padding: 0;
    }

    &.has-arrow {
      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        height: 19px;
        width: 2px;
        background-color: ${(p) => p.theme.grey500};
      }

      &:after {
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid ${(p) => p.theme.grey500};
        top: calc(100% + 18px);
        margin-left: -5px;
      }
    }
  }

  .op-form-grid {
    display: grid;
    grid-template-columns: 300px 20px auto;
    grid-template-areas: 'opformsider opformcollapse opformmain';
  }

  .op-form-grid-sider {
    grid-area: opformsider;
    background-color: #fefefe;
    border-right: 1px solid #cacaca;
  }

  .op-form-grid-sider-collapse {
    grid-area: opformcollapse;
  }

  .op-form-grid-main {
    grid-area: opformmain;
  }

  .ant-collapse-item {
    border-top: 1px solid ${(p) => p.theme.grey200};
  }
`;

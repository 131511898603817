import { FunctionParameter } from 'typings';
import { generateFieldSchema } from 'components/function/FunctionParameterInput';
import validator from 'services/validator';
import { FormikValues } from 'formik';

export function generateFormSchema(parameters: FunctionParameter[], initialRules) {
  let formShape = initialRules;

  if (parameters && parameters.length > 0) {
    formShape = parameters.reduce((shape, item) => {
      const schema = generateFieldSchema(item, true);

      if (!schema) return shape;

      shape[item.key] = schema;

      return shape;
    }, initialRules);
  }

  return validator.object().shape(formShape);
}

export function initializeFunctionParametersFormValues(parameters: FunctionParameter[], values: any) {
  const passedValues = values || {};

  return parameters.reduce((acc: FormikValues, parameter) => {
    const { key } = parameter;
    const value = passedValues[key];

    acc[key] = value;
    if (!value && parameter.many) acc[key] = [];

    return acc;
  }, {});
}

export function cleanFields(obj) {
  const whitelist = [
    'assessorId',
    'name',
    'labels',
    'projectId',
    'targetProjectIds',
    'staticConfiguration',
    'dynamicConfiguration'
  ];

  Object.keys(obj).forEach((k) => {
    if (!whitelist.includes(k)) {
      delete obj[k];
    }
  });

  return obj;
}

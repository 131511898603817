import React from 'react';
import { FormikValues, FormikErrors, FormikTouched, getIn } from 'formik';

import { FunctionParameter } from 'typings';
import validator from 'services/validator';
import FunctionParameterInput, { generateFieldSchema } from './FunctionParameterInput/';

interface Props {
  parameters: FunctionParameter[];
  values: FormikValues;
  touched: FormikTouched<any>;
  errors: FormikErrors<any>;
  setFieldValue: (inputName: string, inputValue: any) => any | void;
  setFieldTouched: (inputName: string, inputTouched: boolean, shouldValidate?: boolean) => any | void;
  setFieldError: (inputName: string, message: string) => any | void;
  showDynamicConfigOptions?: boolean;
  allowJSONPathInput?: boolean;

  staticConfigPathPrefix?: string; // 'staticConfiguration.'
  jsonpathConfigPathPrefix?: string; // 'jsonpathConfiguration.'
  dynamicConfigPathPrefix?: string; // 'dynamicConfiguration.'
}

function FunctionParameterInputSet(props: Props) {
  const {
    parameters,
    values,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    errors,
    touched,
    showDynamicConfigOptions = false,
    allowJSONPathInput = false,
    staticConfigPathPrefix = '',
    jsonpathConfigPathPrefix = 'jsonpathConfiguration.',
    dynamicConfigPathPrefix = 'dynamicConfiguration.'
  } = props;

  return (
    <>
      {parameters.map((parameter) => {
        const { key, name } = parameter;
        const staticConfigurationPath = `${staticConfigPathPrefix}${key}`;
        const value = getIn(values, staticConfigurationPath);
        const jsonpathConfiguration = getIn(values, `${jsonpathConfigPathPrefix}${key}`);

        return (
          <FunctionParameterInput
            key={key}
            value={value}
            parameter={{
              ...parameter
            }}
            fieldName={name || key}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            setFieldError={setFieldError}
            validationError={errors[key] || null}
            touched={(touched[key] as boolean) || false}
            showDynamicConfigOptions={showDynamicConfigOptions}
            attachedDynamicConfiguration={
              (values.dynamicConfiguration && values.dynamicConfiguration[parameter.key]) || null
            }
            allowJSONPathInput={allowJSONPathInput}
            jsonpathConfiguration={jsonpathConfiguration}
            staticConfigPathPrefix={staticConfigPathPrefix}
            jsonpathConfigPathPrefix={jsonpathConfigPathPrefix}
            dynamicConfigPathPrefix={dynamicConfigPathPrefix}
          />
        );
      })}
    </>
  );
}

export function buildOpSetShape(inputs: FunctionParameter[], enforceRequired?: boolean) {
  const setShape = inputs.reduce((shape, input) => {
    const fieldSchema = generateFieldSchema(input, enforceRequired);
    if (!fieldSchema) return shape;
    shape[input.key] = fieldSchema;
    return shape;
  }, {});

  return setShape;
}

export function generateSetSchema(inputs: FunctionParameter[], enforceRequired?: boolean) {
  const setShape = buildOpSetShape(inputs, enforceRequired);

  return validator.object().shape(setShape);
}

export default FunctionParameterInputSet;

import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  margin-bottom: 16px;
`;

interface Props {
  children: React.ReactNode | React.ReactNodeArray;
  classes?: {
    root?: string;
  };
}

function MetaRowGroup(props: Props) {
  const { classes = {}, children } = props;

  return <Root className={`meta-row-group ${classes.root}`}>{children}</Root>;
}

export default MetaRowGroup;

import axios, { AxiosError, AxiosResponse } from 'axios';
import { API_URL } from 'constants/runtimeConfig';
import handleUnauthorized from 'utilities/handleUnauthorized';

const axiosInstance = axios.create({
  withCredentials: true
});

// Add a response interceptor for redirecting
axiosInstance.interceptors.response.use(
  function(response: AxiosResponse) {
    // Redirect to login if the response is 401 (unauthorized)
    if (response.status === 401) {
      handleUnauthorized();
    }
    return response;
  },
  function(error: AxiosError) {
    if (error && error.response && error.response.status === 401) {
      handleUnauthorized();
    }
    return Promise.reject(error);
  }
);

export async function makeRequest(url: string, method: string, opts: any = {}) {
  let requestConfig = {
    url,
    method,
    ...opts
  };

  // while the axios instance supports credentials, the rest apis no longer support cookies
  // send a bearer token if we have one available
  const accessToken = localStorage.getItem('access_token') || null;
  if (accessToken) {
    requestConfig = {
      ...requestConfig,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  }

  return await axiosInstance.request(requestConfig);
}

async function signIn(username: string, password: string) {
  const url = `${API_URL}/auth/login`;

  const data = {
    username,
    password
  };

  return makeRequest(url, 'POST', { data });
}

async function signOut() {
  const url = `${API_URL}/auth/logout`;

  return makeRequest(url, 'POST');
}

async function updatePassword(oldPw: string, newPw: string) {
  const url = `${API_URL}/auth/pw`;

  const data = { current: oldPw, requested: newPw };

  return makeRequest(url, 'POST', { data });
}

export default {
  signIn,
  signOut,
  updatePassword
};

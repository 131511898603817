import React from 'react';
import JSONTree from 'components/ui/JSONTree';
import styled from 'styled-components';
import { FunctionParameter } from 'typings';
import memoize from 'fast-memoize';
import { CIDRListDisplay, AWSTagDisplay, AWSTagsDisplay } from './components';

const UndefinedLabel = styled.div`
  color: ${p => p.theme.grey500};
`;

const JSONContainer = styled.div`
  padding: 12px;
  margin: 10px 0px 30px;
  font-size: 14px;
  background: #eee;
`;

const Root = styled.div`
  color: ${p => p.theme.grey800};
`;

export interface FunctionReadViewProps {
  functionParameter: FunctionParameter;
  value?: any;
}

interface InputReadViewMapItem {
  test: (functionParameter: FunctionParameter, value: any) => boolean;
  render: (props: FunctionReadViewProps) => React.ReactNode;
}

const INPUT_READ_VIEW_MAP: InputReadViewMapItem[] = [
  {
    test: ({ inputCode = '' }) => typeof inputCode === 'string' && ['cidr_list'].includes(inputCode),
    render: CIDRListDisplay
  },
  {
    test: ({ inputCode = '' }) => typeof inputCode === 'string' && ['aws_create_tag'].includes(inputCode),
    render: AWSTagDisplay
  },
  {
    test: ({ inputCode = '' }, value) =>
      typeof inputCode === 'string' && Array.isArray(value) && ['aws_tags'].includes(inputCode),
    render: AWSTagsDisplay
  }
];

const selectReadType = memoize((functionParameter: FunctionParameter, value: any) =>
  INPUT_READ_VIEW_MAP.find(item => item.test(functionParameter, value))
);

function FunctionReadView(props: FunctionReadViewProps) {
  if (props.value === undefined) return <UndefinedLabel>{'undefined'}</UndefinedLabel>;

  const selectedView = selectReadType(props.functionParameter, props.value);

  let content;

  if (!selectedView) {
    content = <DefaultView {...props} />;
  } else {
    content = selectedView.render(props);
  }

  return <Root>{content}</Root>;
}

function DefaultTreeView(props: FunctionReadViewProps) {
  return (
    <JSONContainer>
      <JSONTree data={props.value} showExpandButton={false} showCopyButton={false} />
    </JSONContainer>
  );
}

function DefaultView(props: FunctionReadViewProps) {
  const { value } = props;
  const typeOf = typeof value;

  let content: React.ReactNode = null;

  if (value === null) return null;

  switch (typeOf) {
    case 'object':
      content = <DefaultTreeView {...props} />;
      break;

    case 'string':
      content = <p>{value}</p>;
      break;

    case 'boolean': {
      if (value === true) {
        content = 'true';
      } else {
        content = 'false';
      }

      break;
    }

    case 'number':
      content = <p>{`${value}`}</p>;
      break;

    default:
      content = null;
      break;
  }

  return <>{content}</>;
}

export default FunctionReadView;

import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';

import AssessorDetailsCard from './AssessorDetailsCard';
import AssessmentFiltersCard from './AssessmentFiltersCard';
import AssessmentIssueDefinitionsCard from './AssessmentIssueDefinitionsCard';

import { ConfiguredAssessment } from 'typings';

const Root = styled.div``;

interface Props extends RouteComponentProps<{ assessmentId: string }> {
  assessment: ConfiguredAssessment;
  linkPrefix?: string;
}

function AssessmentOverviewTab(props: Props) {
  const {
    assessment,
    assessment: { assessor },
    linkPrefix
  } = props;

  return (
    <Root>
      <AssessorDetailsCard assessor={assessor} />
      <AssessmentFiltersCard assessment={assessment} linkPrefix={linkPrefix} />
      <AssessmentIssueDefinitionsCard {...props} />
    </Root>
  );
}

export default AssessmentOverviewTab;

import { useMutation, useQuery } from '@apollo/react-hooks';
import coerceObjectsNullsToUndefined from '@disruptops/neo-core/dist/coerce-object-nulls-to-undefined';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { Alert, Button, Divider, Input, message, Modal, Switch, Typography } from 'antd';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { generateFieldSchema } from 'components/function/FunctionParameterInput';
import ArchiveOpModal from 'components/ops/ArchiveOpModal';
import DeleteOpModal from 'components/ops/DeleteOpModal';
import DopeIcon from 'components/ui/DopeIcon';
import Form, { FormField } from 'components/ui/Form';
import { FormRenderProps } from 'components/ui/Form/Form';
import NeoPage, { CenteredContainer, TitleBar, TitleBarButton } from 'components/ui/NeoPage';
import gql from 'graphql-tag';
import { OP_FIELDS } from 'queries/fragments/opFields';
import React, { useEffect, useState } from 'react';
import { MutationFunction, MutationResult } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import { client } from 'services/graphql';
import styled from 'styled-components';
import {
  AutomationEventDefinition,
  AutomationFunction,
  EventDefinitionFunction,
  Function as FunctionType,
  FunctionParameter,
  Op,
  OpConfiguration
} from 'typings';
import Yup from 'services/validator';
import { OpContextControl } from './components';
import OpEventModal from './components/OpEventModal';
import { AUTOMATION_EVENT_DEFINITIONS, AUTOMATION_FUNCTION_BY_ID } from './gql';
import { OpActionSection, OpDecisionSection, OpNotificationSection, OpTriggerSection } from './sections';
import { OpTriggerSectionLegacy } from './sections/OpTriggerSectionLegacy';
import OP_DECISION_INTEGRATION_DEFINITIONS, {
  OpDecisionIntegrationDefinition
} from './sections/OpDecisionSection/definitions';
import {
  OpNotificationIntegrationDefinition,
  OP_NOTIFICATION_INTEGRATION_DEFINITIONS
} from './sections/OpNotificationSection/definitions';
import { schema as tagsFilterSchema } from './sections/OpTriggerSection/filters/TagsFilter/TagsFilter';
import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';
// import set = Reflect.set;

export const SAVE_OP_MUTATION = gql`
  mutation SaveOpTrigger($id: String, $opInput: OpInput!) {
    saveOp(id: $id, opInput: $opInput) {
      ...OpFields
    }
  }
  ${OP_FIELDS}
`;

const OpFormRoot = styled.div`
  .header-has-icon .grid-card-content {
    padding-left: 16px;
  }

  .section-title-wrap {
    display: flex;

    .trigger-icon {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .section-title {
      margin-right: 16px;
    }
  }

  .grid-card {
    position: relative;

    .grid-card-content {
      padding: 0;
    }

    &.has-arrow {
      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        height: 19px;
        width: 2px;
        background-color: ${(p) => p.theme.grey500};
      }

      &:after {
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid ${(p) => p.theme.grey500};
        top: calc(100% + 18px);
        margin-left: -5px;
      }
    }
  }

  .op-form-grid {
    display: grid;
    grid-template-columns: 300px 20px auto;
    grid-template-areas: 'opformsider opformcollapse opformmain';
  }

  .op-form-grid-sider {
    grid-area: opformsider;
    background-color: #fefefe;
    border-right: 1px solid #cacaca;
  }

  .op-form-grid-sider-collapse {
    grid-area: opformcollapse;
  }

  .op-form-grid-main {
    grid-area: opformmain;
  }

  .ant-collapse-item {
    border-top: 1px solid ${(p) => p.theme.grey200};
  }
`;

interface OpEditorProps {
  op?: Op;
  playbook?: EventDefinitionFunction | null;
  initTriggerId?: string | null;
  allFindings?: boolean | null;
  initialProjectId?: string | undefined;
  eventSourceId?: string | undefined;
  accountId?: string | undefined;
  severity?: number | undefined;
  regions?: string | undefined;
  environments?: string | undefined;
  resourceType?: string | undefined;
  customFiltersPath?: string | undefined;
  customFiltersPatterns?: string | undefined;
}

interface OpState {
  // trigger
  eventDefinitionId?: string | null;

  // action
  functionId?: string | null;

  decisionIntegration?: string | null; // SLACK
  notificationIntegration?: string | null; // SLACK
}

function OpEditor(props: OpEditorProps) {
  const {
    op,
    playbook,
    initTriggerId,
    allFindings,
    initialProjectId,
    eventSourceId,
    accountId,
    severity,
    regions,
    environments,
    resourceType,
    customFiltersPath,
    customFiltersPatterns
  } = props;

  const history = useHistory();

  const featureFlags = useFeatureFlag([Feature.OPS_TRIGGER_FILTER_REWRITE]);

  const [eventState, setEventState] = useState<{ event?: any }>({});
  const [isAllFindingsSelected, setIsAllFindingsSelected] = useState<boolean>(
    op?.eventDefinitionId // does the op have an eventDefinitionID set?  if so, then 'All Findings' is not selected
      ? false
      : allFindings !== null && allFindings !== undefined // is the allFindings prop specified? if so, use it (allFindings prop comes from a query string param from OpDetail)
      ? allFindings
      : true // otherwise, 'All Findings' is selected
  );
  const [opState, setOpState] = useState<OpState>(getInitialOpState({ op, playbook, initTriggerId }));
  const updateOpState = (updated: OpState) => {
    setOpState({
      ...opState,
      ...updated
    });
  };

  // FETCH NECESSARY ITEMS IN ORDER TO BUILD VALIDATION SCHEMA
  // this could all probably be moved into another custom react hook;
  // hook could be something like "useGetOpContextArgs";
  const automationFunctionResults = useQuery(AUTOMATION_FUNCTION_BY_ID, {
    variables: {
      id: opState.functionId
    },
    skip: !opState.functionId,
    fetchPolicy: 'cache-first'
  });
  const automationFunction = automationFunctionResults?.data?.automationFunctions?.nodes?.[0] || null;

  const eventDefinitionResults = useQuery(AUTOMATION_EVENT_DEFINITIONS, {
    variables: { id: opState.eventDefinitionId },
    skip: !opState.eventDefinitionId
  });
  const eventDefinition = eventDefinitionResults?.data?.eventDefinitions?.nodes?.[0] || null;

  const decisionIntegrationDefinition = opState.decisionIntegration
    ? OP_DECISION_INTEGRATION_DEFINITIONS.find((def) => def.key === opState.decisionIntegration) || null
    : null;

  const notificationIntegrationDefinition = opState.notificationIntegration
    ? OP_NOTIFICATION_INTEGRATION_DEFINITIONS.find((def) => def.key === opState.notificationIntegration) || null
    : null;

  const contextArgs: ContextArgs = {
    automationFunction,
    eventDefinition,
    isAllFindingsSelected: isAllFindingsSelected,

    playbook,

    decisionIntegrationDefinition,
    notificationIntegrationDefinition,
    opState,
    setOpState: updateOpState,
    op
  };

  const [confirmationModalState, setConfirmationModalState] = useState<ConfirmationModalState>(null);
  const [userHasBeenPromptedToEnable, setUserHasBeenPromptedToEnable] = useState<boolean>(false);

  const [saveOp, saveOpResults] = useMutation(SAVE_OP_MUTATION);

  const validationSchema = buildValidationSchema({
    contextArgs,
    strict: false
  });

  const existingProjectId = op?.projectId || null;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: existingProjectId || '*' }]
  });
  const isAuthorized = existingProjectId ? authz.isAuthorized : true;

  const initialValues = getInitialFormValuesFromOp({
    op,
    playbook,
    initTriggerId,
    projectId: initialProjectId,
    eventSourceId,
    accountId,
    severity,
    regions,
    environments,
    resourceType,
    customFiltersPath,
    customFiltersPatterns
  });

  return (
    <>
      <Form
        initialValues={initialValues} // change
        validationSchema={validationSchema}
        allowCleanSubmits={false}
        onSubmit={async (formValues, actions) => {
          try {
            const opInput = transformFormValuesIntoOpToSave(formValues);

            const variables = {
              id: op?.id || undefined,
              opInput
            };

            const result = await saveOp({ variables });
            const savedOp: Op = result.data.saveOp;
            const opToValidate = getInitialFormValuesFromOp({ op: savedOp });
            const completeOpValidationSchema = buildValidationSchema({
              contextArgs,
              strict: true
            });

            const forwardToDetailView = () => {
              if (!op) history.push(`/ops1/details/${savedOp.id}`);
            };

            // show success message
            message.success('Op was successfully saved');

            const opIsComplete = await validateOpAgainstSchema(completeOpValidationSchema, opToValidate);
            if (opIsComplete && !savedOp.isEnabled && !userHasBeenPromptedToEnable) {
              setUserHasBeenPromptedToEnable(true);
              setConfirmationModalState({
                title: 'Would you like to turn this Op on?',
                description:
                  'This Op is ready to be turned on. Alternatively, you can keep working on this Op and turn it on whenever you are ready. You can turn Ops on or off with the switch in the top left of the page header.',
                okText: 'Turn On',
                onConfirm: async () => {
                  try {
                    const variables = {
                      id: savedOp.id,
                      opInput: {
                        isEnabled: true
                      }
                    };

                    await saveOp({ variables });

                    message.success('This Op has been turned on.');

                    forwardToDetailView();
                  } catch (e) {
                    message.error('There was a problem turning this Op on.');

                    forwardToDetailView();
                  }
                },
                cancelText: 'No',
                onCancel: async () => {
                  forwardToDetailView();
                }
              });
            } else {
              forwardToDetailView();
            }

            actions.resetForm();
          } catch (e) {
            message.error('There was an error saving this Op', e);
          }
        }}
      >
        {(formRenderProps) => {
          return (
            <OpFormRoot>
              <NeoPage
                titleBarHasNav
                titleBar={
                  <TitleBar
                    title="Op Details"
                    sectionTitleLinkTo="/ops1"
                    sectionTitle="Ops"
                    icon={<DopeIcon name="OP" size={20} />}
                    actionsInSecondRow
                    actions={
                      <>
                        <OpStatusControl
                          op={op}
                          contextArgs={contextArgs}
                          savedOpMutationFn={saveOp}
                          saveOpMutationResult={saveOpResults}
                        />

                        <OpArchiveDeleteControl {...props} />

                        {op && (
                          <TitleBarButton
                            children="View Activity"
                            icon={<DopeIcon name="ACTIVITY" />}
                            linkTo={`/ops1/activity?opId=${op.id}`}
                          />
                        )}

                        <TitleBarButton
                          children="Event Console"
                          icon={<DopeIcon name="EVENT" />}
                          linkTo={op ? `/ops1/details/${op.id}/events` : `/ops1/events`}
                        />
                      </>
                    }
                  />
                }
              >
                <div className="op-form-grid">
                  <div className="op-form-grid-sider">
                    <OpSider formRenderProps={formRenderProps} op={op} />
                  </div>

                  <div className="op-form-grid-main">
                    <CenteredContainer size="md" leftAlign>
                      {!isAuthorized && (
                        <Alert
                          type="warning"
                          message={`You do not have effective permissions to edit this Op. You must have the "Modify Ops" permission for the Project of which this Op is owned by.`}
                          style={{ marginBottom: '24px' }}
                        />
                      )}

                      {featureFlags[Feature.OPS_TRIGGER_FILTER_REWRITE] ? (
                        <OpTriggerSection
                          event={eventState.event}
                          isNewOp={!existingProjectId}
                          contextArgs={contextArgs}
                          onSelectTrigger={onSelectTrigger(formRenderProps, updateOpState, setIsAllFindingsSelected)}
                          formRenderProps={formRenderProps}
                          setConfirmationModal={setConfirmationModalState}
                        />
                      ) : (
                        <OpTriggerSectionLegacy
                          event={eventState.event}
                          contextArgs={contextArgs}
                          onSelectTrigger={onSelectTrigger(formRenderProps, updateOpState, setIsAllFindingsSelected)}
                          formRenderProps={formRenderProps}
                          setConfirmationModal={setConfirmationModalState}
                        />
                      )}

                      {opState.decisionIntegration && (
                        <OpDecisionSection
                          contextArgs={contextArgs}
                          onSelectDecisionIntegration={onSelectDecisionIntegration(formRenderProps, updateOpState)}
                          formRenderProps={formRenderProps}
                          setConfirmationModal={setConfirmationModalState}
                        />
                      )}

                      <OpActionSection
                        contextArgs={contextArgs}
                        onSelectAction={onSelectAction(formRenderProps, updateOpState)}
                        formRenderProps={formRenderProps}
                        setConfirmationModal={setConfirmationModalState}
                      />

                      <OpNotificationSection formRenderProps={formRenderProps} />
                    </CenteredContainer>
                  </div>
                </div>
              </NeoPage>

              <OpEventModal
                opId={op?.id}
                eventDefinitionId={formRenderProps.values.eventDefinitionId}
                onSelect={(event: any) => {
                  setEventState({ event });
                }}
              />
            </OpFormRoot>
          );
        }}
      </Form>

      <Modal
        title={confirmationModalState?.title}
        children={confirmationModalState?.description}
        visible={Boolean(confirmationModalState)}
        onOk={confirmationModalState?.onConfirm}
        onCancel={() => {
          const onCancelCallback = confirmationModalState && confirmationModalState.onCancel;
          setConfirmationModalState(null);

          if (onCancelCallback) onCancelCallback();
        }}
        okText={confirmationModalState?.okText}
        cancelText={confirmationModalState?.cancelText}
      />
    </>
  );
}

function getInitialOpState(args: {
  op?: Op;
  playbook?: EventDefinitionFunction | null;
  initTriggerId?: string | null;
}): OpState {
  const { op, playbook, initTriggerId } = args;
  const state: OpState = {};

  if (!op) {
    if (playbook) {
      state.functionId = playbook.functionId;
      state.eventDefinitionId = playbook.eventDefinitionId;

      return state;
    } else if (initTriggerId) {
      state.eventDefinitionId = initTriggerId;
    }

    // no Op or Playbook.
    return state;
  }

  const { functionId, decisionIntegration, notificationIntegration, eventDefinitionId } = op;

  // AUTOMATION FUNCTION ID
  if (functionId) state.functionId = functionId;

  // EVENT DEFINITION ID
  if (eventDefinitionId) {
    state.eventDefinitionId = eventDefinitionId;
  }

  if (decisionIntegration) state.decisionIntegration = decisionIntegration;

  if (notificationIntegration) state.notificationIntegration = notificationIntegration;

  return state;
}

export interface ContextArgs {
  eventDefinition: AutomationEventDefinition | null;
  isAllFindingsSelected: boolean;

  automationFunction: AutomationFunction | null;

  // if present, use as template to start Op
  playbook?: EventDefinitionFunction | null;

  decisionIntegrationDefinition: OpDecisionIntegrationDefinition | null;
  notificationIntegrationDefinition: OpNotificationIntegrationDefinition | null;

  opState: OpState;
  setOpState: (update: OpState) => void;

  op?: Op;
}

// This validation schema is meant to grow as the user configures the Op
// It should allow the user to save as they complete various sections.
// The user should not have to complete the entire guardrail before saving progress.
// Each section started must be valid before saving.
interface BuildValidationSchemaArgs {
  contextArgs?: ContextArgs; // use with form
  op?: Op; // use to validate against a raw Op
  strict?: boolean; // set to true to validate "Completed Op"
  allowAllFindings?: boolean;
}

function buildValidationSchema(args: BuildValidationSchemaArgs) {
  const { op, contextArgs, strict = false } = args;

  const automationFunction = (contextArgs && contextArgs.automationFunction) || (op && op.function) || null;

  let decisionIntegrationDefinition = (contextArgs && contextArgs.decisionIntegrationDefinition) || null;
  if (!decisionIntegrationDefinition && op && op.decisionIntegration) {
    decisionIntegrationDefinition =
      OP_DECISION_INTEGRATION_DEFINITIONS.find((def) => def.key === op.decisionIntegration) || null;
  }

  let shape: any = {
    projectId: Yup.string().required('Project is required'),
    name: Yup.string().required('Name is required'),

    description: Yup.string(),

    functionId: Yup.string().required(strict ? 'Action is required' : false),
    filtersConfiguration: Yup.object(),

    decisionIntegration: Yup.string(),

    // SLACK
    notificationIntegration: Yup.string(),

    // trigger Filter fields
    eventSourceId: Yup.string(),
    eventDefinitionId: Yup.string().nullable(),
    triggerProjectIds: Yup.array().of(Yup.string()),
    accountIds: Yup.array().of(Yup.string()),
    regions: Yup.array().of(Yup.string()),
    assessmentId: Yup.string(),
    environments: Yup.array().of(Yup.string()),
    accountLabels: Yup.array().of(Yup.string()),
    jsonPaths: Yup.array().of(
      Yup.object().shape({
        path: Yup.string().required(),
        patterns: Yup.array().min(1).of(Yup.string().required()),
        type: Yup.string().required()
      })
    ),
    severity: Yup.array().of(Yup.number()),
    resourceTypes: Yup.array().of(Yup.string()),
    tags: tagsFilterSchema
  };

  if (automationFunction && automationFunction.parameters) {
    shape.actionConfiguration = buildActionConfigurationShape(automationFunction.parameters);
  }

  if (decisionIntegrationDefinition && decisionIntegrationDefinition.validationShape) {
    shape = {
      ...shape,
      ...decisionIntegrationDefinition.validationShape
    };
  }

  return Yup.object().shape(shape);
}

function buildActionConfigurationShape(parameters: FunctionParameter[]) {
  const shape = parameters.reduce((acc, param) => {
    const fieldSchema = generateFieldSchema(param, true, {
      // jsonpathConfigPathPrefix: 'actionConfiguration.jsonpathConfiguration.',
      // dynamicConfigPathPrefix: 'actionConfiguration.dynamicConfiguration.'
    });

    acc[param.key] = fieldSchema;

    return acc;
  }, {});

  return Yup.object().shape(shape);
}

export const GridCardContentPadding = styled.div`
  padding: 4px 16px 4px;
`;

interface OpFormValues {
  projectId?: string;
  name?: string;
  description?: string;
  eventSourceId?: string;
  eventDefinitionId?: string;
  issueDefinitionId?: string;
  functionId?: string;
  actorId?: string;
  decisionIntegration?: string;
  decisionType?: string;
  decisionExpiration?: number;
  decisionRecipientId?: string;

  notificationIntegration?: string;

  successNotificationRecipientId?: string;
  failureNotificationRecipientId?: string;

  triggerProjectIds?: string[];
  accountIds?: string[];
  regions?: string[];
  assessmentId?: string;
  severity?: number[];
  environments?: string[];
  resourceType?: string[];

  filtersConfiguration?: {
    [key: string]: any;
  };

  tags?: {
    key: string;
    value: string;
  }[];

  jsonPaths?: [
    {
      path: string;
      patterns: string[];
      type: string;
    }
  ];

  actionConfiguration?: { [key: string]: any };
}

export type ConfirmationModalState = ConfirmationModalStateInterface | null;

interface ConfirmationModalStateInterface {
  title: string;
  description: React.ReactNode;
  onConfirm: () => void;
  onCancel?: () => void;
  okText?: string;
  cancelText?: string;
}

type OnSelectTriggerArgs = {
  eventDefinition?: AutomationEventDefinition;
  isAllFindingsSelected?: boolean;
} | null;
export type OnSelectTriggerFunc = (args: OnSelectTriggerArgs) => void;

function onSelectTrigger(
  formRenderProps: FormRenderProps,
  updateOpState: (newState: OpState) => void,
  setIsAllFindingsSelected: (value: boolean) => void
): OnSelectTriggerFunc {
  const { setFieldValue, setFieldTouched } = formRenderProps;
  const EVENT_DEFINITION_ID = 'eventDefinitionId';

  return (args: OnSelectTriggerArgs) => {
    const eventDefinition = args?.eventDefinition || null;

    const eventDefinitionId = eventDefinition ? eventDefinition.id : null;

    setFieldValue(EVENT_DEFINITION_ID, eventDefinitionId);
    setFieldValue('eventSourceId', eventDefinition?.eventSource?.id);

    if (eventDefinition) {
      setFieldTouched(EVENT_DEFINITION_ID, true);
      setFieldTouched('eventSourceId', true);
      updateEventDefinitionInCache(eventDefinition);
    }

    updateOpState({
      eventDefinitionId
    });
    setIsAllFindingsSelected(args?.isAllFindingsSelected || false);
  };
}

// update cache so there isn't delay between selecting eventDefinition and querying it again.
function updateEventDefinitionInCache(eventDefinition: AutomationEventDefinition) {
  const query = AUTOMATION_EVENT_DEFINITIONS;
  const newData = {
    eventDefinitions: {
      nodes: [
        {
          ...eventDefinition,
          __typename: 'EventDefinition'
        }
      ],
      __typename: 'EventDefinitionResults'
    }
  };

  client.writeQuery({
    query,
    variables: { id: eventDefinition.id },
    data: newData
  });
}

export type OnSelectDecisionIntegrationFunc = (
  decisionIntegrationDefinition: OpDecisionIntegrationDefinition | null
) => void;

function onSelectDecisionIntegration(
  formRenderProps: FormRenderProps,
  setOpState: (newState: OpState) => void
): OnSelectDecisionIntegrationFunc {
  const { values, setValues, setFieldTouched, setFieldValue } = formRenderProps;

  return (decisionIntegrationDefinition: OpDecisionIntegrationDefinition | null) => {
    const decisionIntegration = decisionIntegrationDefinition ? decisionIntegrationDefinition.key : undefined;
    const updatedValues: any = {
      decisionIntegration,
      decisionType: undefined,
      decisionExpiration: undefined,
      decisionRecipientId: undefined
    };

    setValues({
      ...values,
      ...updatedValues
    });

    if (decisionIntegrationDefinition && decisionIntegrationDefinition.defaultValues) {
      Object.keys(decisionIntegrationDefinition.defaultValues).forEach((key) => {
        const value =
          decisionIntegrationDefinition && decisionIntegrationDefinition.defaultValues
            ? decisionIntegrationDefinition.defaultValues[key]
            : null;

        if (value) {
          setFieldValue(key, value);
        }
      });
    }

    setFieldTouched('decisionIntegration', true);
    setFieldTouched('decisionRecipientId', false);

    setOpState({ decisionIntegration });
  };
}

export type OnSelectActionArgs = { automationFunction?: any; actor?: FunctionType } | null;
export type OnSelectActionFunc = (args: OnSelectActionArgs) => void;

function onSelectAction(formRenderProps: FormRenderProps, updateOpState: (newState: OpState) => void) {
  const { setFieldValue, setFieldTouched } = formRenderProps;
  const FUNCTION_ID = 'functionId';

  return (args: OnSelectActionArgs) => {
    const automationFunction = args?.automationFunction || null;

    if (automationFunction) {
      const valuesToUpdate = parseFormValuesFromAutomationFunction(automationFunction);

      const updatedValues = {
        // ...values,
        ...valuesToUpdate,
        actorId: undefined
      };

      Object.keys(updatedValues).forEach((key) => {
        setFieldValue(key, updatedValues[key]);
      });

      setFieldTouched(FUNCTION_ID, true);

      updateAutomationFunctionInCache(automationFunction);

      updateOpState({ functionId: automationFunction.id });
    } else {
      // clear action

      const updatedValues = {
        actorId: undefined,
        functionId: undefined
      };

      Object.keys(updatedValues).forEach((key) => {
        setFieldValue(key, updatedValues[key]);
      });

      updateOpState(updatedValues);
    }
  };
}

function updateAutomationFunctionInCache(automationFunction: AutomationFunction) {
  const query = AUTOMATION_FUNCTION_BY_ID;
  const variables = { id: automationFunction.id };
  const data = {
    automationFunctions: {
      pageInfo: {
        total: 1,
        size: 1,
        __typename: 'PageInfo'
      },
      nodes: [automationFunction],
      __typename: 'AutomationFunctionResults'
    }
  };

  client.writeQuery({ query, variables, data });
}

export function parseFormValuesFromAutomationFunction(automationFunction: AutomationFunction): OpFormValues {
  const { id: functionId, eventFunctions } = automationFunction;
  const actionConfiguration = eventFunctions
    ? eventFunctions.reduce((acc: any, eventFunction) => {
        const { functionInputMappings } = eventFunction;

        if (functionInputMappings)
          functionInputMappings.forEach((inputMapping) => {
            const { key, valuePath } = inputMapping;

            if (valuePath) {
              // update jsonpathConfiguration
              if (!(acc.jsonpathConfiguration && acc.jsonpathConfiguration[key])) {
                acc.jsonpathConfiguration = acc.jsonpathConfiguration
                  ? { ...acc.jsonpathConfiguration, [key]: valuePath } // update object if exists
                  : { [key]: valuePath }; // create new object if none exists
              }
            }
          });

        return acc;
      }, {})
    : {};

  return {
    functionId,
    actionConfiguration
  };
}

function getInitialFormValuesFromOp(args: {
  op?: Op;
  playbook?: EventDefinitionFunction | null;
  initTriggerId?: string | null;
  projectId?: string | undefined;
  eventSourceId?: string | undefined;
  accountId?: string | undefined;
  severity?: number | undefined;
  regions?: string | undefined;
  environments?: string | undefined;
  resourceType?: string | undefined;
  customFiltersPath?: string | undefined;
  customFiltersPatterns?: string | undefined;
}): OpFormValues {
  const {
    op,
    playbook,
    initTriggerId,
    projectId,
    eventSourceId,
    accountId,
    severity,
    regions,
    environments,
    resourceType,
    customFiltersPath,
    customFiltersPatterns
  } = args;

  if (!op)
    return playbook
      ? getInitialFormValuesFromPlaybook(
          playbook,
          projectId,
          projectId,
          eventSourceId,
          accountId,
          severity,
          regions,
          environments,
          resourceType,
          customFiltersPath,
          customFiltersPatterns
        )
      : {
          eventDefinitionId: initTriggerId || undefined,
          projectId: projectId || undefined,
          eventSourceId: eventSourceId || undefined,
          triggerProjectIds: projectId ? [projectId] : undefined,
          accountIds: accountId ? [accountId] : undefined,
          severity: severity ? [severity] : undefined,
          regions: regions ? [regions] : undefined,
          environments: environments ? [environments] : undefined,
          resourceType: resourceType ? [resourceType] : undefined,
          jsonPaths:
            customFiltersPatterns && customFiltersPath
              ? [
                  {
                    path: customFiltersPath,
                    patterns: [customFiltersPatterns],
                    type: 'STRICT_MATCH'
                  }
                ]
              : undefined
        };

  const opWithoutNulls = coerceObjectsNullsToUndefined(op);
  const {
    configuration,
    // fields not used in form
    id,
    clientId,
    createdById,
    createdByUsername,
    createdAt,
    updatedAt,
    archivedAt,
    deletedAt,
    function: _func, // cannot be named function
    eventDefinition,
    eventSource,
    // end fields not used in form.
    ...fieldsOkayAsIs
  } = opWithoutNulls;
  const actionConfiguration = configuration ? transformOpConfigsToActionConfig(configuration) : {};

  // console.log({ configuration, actionConfiguration });

  if (actionConfiguration?.alertActions) {
    actionConfiguration.alertActions = actionConfiguration.alertActions.map((alertAction) => {
      // console.log({ alertAction });
      return {
        ...alertAction,
        configuration: transformOpConfigsToActionConfig(alertAction.configuration)
      };
    });
    // console.log({alertActions: actionConfiguration.alertActions});
  }

  if (Array.isArray(actionConfiguration?.delayedAction?.configuration)) {
    // console.log({ delayedAction: actionConfiguration.delayedAction });
    actionConfiguration.delayedAction.configuration = transformOpConfigsToActionConfig(
      actionConfiguration.delayedAction.configuration
    );
  }

  // console.log({actionConfigurationAfter: actionConfiguration});

  const init: OpFormValues = {
    ...fieldsOkayAsIs,
    actionConfiguration
  };

  return init;
}

function getInitialFormValuesFromPlaybook(
  playbook: EventDefinitionFunction,
  projectId: string | undefined,
  triggerProjectIds: string | undefined,
  eventSourceId: string | undefined,
  accountId: string | undefined,
  severity: number | undefined,
  regions: string | undefined,
  environments: string | undefined,
  resourceType: string | undefined,
  customFiltersPath: string | undefined,
  customFiltersPatterns: string | undefined
): OpFormValues {
  const { eventDefinitionId, functionId, functionInputMappings } = playbook;
  const init: OpFormValues = {
    name: getNameFromPlaybook(playbook),
    eventDefinitionId,
    functionId: functionId,
    projectId: projectId,
    triggerProjectIds: projectId ? [projectId] : undefined,
    eventSourceId: eventSourceId,
    accountIds: accountId ? [accountId] : undefined,
    severity: severity ? [severity] : undefined,
    regions: regions ? [regions] : undefined,
    resourceType: resourceType ? [resourceType] : undefined,
    jsonPaths:
      customFiltersPatterns && customFiltersPath
        ? [
            {
              path: customFiltersPath,
              patterns: [customFiltersPatterns],
              type: 'STRICT_MATCH'
            }
          ]
        : undefined
  };

  if (functionInputMappings)
    init.actionConfiguration = {
      jsonpathConfiguration: functionInputMappings.reduce((acc, functionInputMapping) => {
        acc[functionInputMapping.key] = functionInputMapping.valuePath;

        return acc;
      }, {})
    };

  return init;
}

function getNameFromPlaybook(eventDefinitionFunction: EventDefinitionFunction): string {
  const eventDefinitionName = eventDefinitionFunction.eventDefinition?.name || null;
  const functionName = eventDefinitionFunction?.function?.name || null;

  if (eventDefinitionFunction.name) return eventDefinitionFunction.name;

  return functionName && eventDefinitionName
    ? `${functionName} when event "${eventDefinitionName}" is discovered.`
    : functionName || '--';
}

function transformFormValuesIntoOpToSave(formValues: OpFormValues): Op {
  const {
    decisionIntegration,
    decisionType,
    decisionRecipientId,
    notificationIntegration,
    successNotificationRecipientId,
    failureNotificationRecipientId,
    triggerProjectIds,
    regions,
    accountIds,
    actionConfiguration,
    eventSourceId,
    tags,
    ...fieldsOkayAsIs
  } = formValues;

  const configuration = transformActionConfigurationToOpConfigs(actionConfiguration);

  const toSave: Op = {
    decisionIntegration: decisionIntegration || null,
    decisionType: decisionType || null,
    decisionRecipientId: decisionRecipientId || null,
    notificationIntegration: notificationIntegration || null,
    successNotificationRecipientId: successNotificationRecipientId || null,
    failureNotificationRecipientId: failureNotificationRecipientId || null,
    triggerProjectIds: triggerProjectIds || null,
    regions: regions || null,
    accountIds: accountIds || null,
    eventSourceId: eventSourceId || null,
    tags: tags ? tags : [],
    configuration,
    ...fieldsOkayAsIs
  };

  return toSave;
}

interface AlertAction {
  id: string;
  name: string;
  functionId: string;
  configuration: any;
  function?: any;
}

function transformAlertActionConfiguration(alertAction: AlertAction): AlertAction {
  if (!alertAction) throw new Error(`Required parameter 'alertAction' is falsy.`);

  const settings: OpConfiguration[] = [];

  // the editors contain the full automation function, chomp it out from the final result
  const { function: func, configuration, ...otherProps } = alertAction;

  // separate the static settings from the jsonpath and dynamic settings
  const { jsonpathConfiguration, dynamicConfiguration, ...staticConfiguration } = configuration;

  // normalize the static settings
  if (staticConfiguration)
    Object.keys(staticConfiguration).forEach((key) => {
      settings.push({
        key,
        type: 'STATIC',
        value: staticConfiguration[key]
      });
    });

  // normalize the jsonpath settings
  if (jsonpathConfiguration)
    Object.keys(jsonpathConfiguration).forEach((key) => {
      settings.push({
        key,
        type: 'JSONPATH',
        value: jsonpathConfiguration[key]
      });
    });

  // normalize the dynamic config settings
  if (dynamicConfiguration)
    Object.keys(dynamicConfiguration).forEach((key) => {
      settings.push({
        key,
        type: 'DYNAMIC',
        value: dynamicConfiguration[key]
      });
    });

  const transformedAlertAction = {
    ...otherProps,
    configuration: [...settings]
  };

  return transformedAlertAction;
}

function transformActionConfigurationToOpConfigs(actionConfiguration: any): OpConfiguration[] {
  if (!actionConfiguration) return [];
  const { jsonpathConfiguration, alertActions = [], delayedAction, ...otherStaticConfiguration } = actionConfiguration;
  const configurations: OpConfiguration[] = [];

  // console.log({ alertActions, delayedAction });

  // remove the function property from alertActions
  const staticConfiguration = {
    ...otherStaticConfiguration,
    alertActions: alertActions.map((alertAction) => transformAlertActionConfiguration(alertAction)),
    delayedAction: delayedAction && transformAlertActionConfiguration(delayedAction)
  };

  // console.log({ staticConfiguration });

  if (jsonpathConfiguration) {
    Object.keys(jsonpathConfiguration).forEach((key) => {
      configurations.push({
        key,
        type: 'JSONPATH',
        value: jsonpathConfiguration[key]
      });
    });
  }

  Object.keys(staticConfiguration).forEach((key) => {
    configurations.push({
      key,
      type: 'STATIC',
      value: staticConfiguration[key]
    });
  });

  return configurations;
}

function transformOpConfigsToActionConfig(opConfigs: OpConfiguration[]) {
  const actionConfig =
    Array.isArray(opConfigs) &&
    opConfigs?.reduce(
      (acc: any, opConfig) => {
        if (opConfig.type === 'JSONPATH') {
          acc.jsonpathConfiguration[opConfig.key] = opConfig.value;
        } else if (opConfig.type === 'STATIC') {
          acc[opConfig.key] = opConfig.value;
        }

        return acc;
      },
      {
        jsonpathConfiguration: {}
      }
    );

  return actionConfig;
}

// COMPONENT: ENABLE GUARDRAIL CONTROL
const EnableOpControlRoot = styled.div`
  display: flex;
  align-items: center;

  .op-status-message {
    margin-right: 8px;
  }
`;

interface OpStatusControlProps {
  op?: Op;
  contextArgs: ContextArgs;
  savedOpMutationFn: MutationFunction;
  saveOpMutationResult: MutationResult;
}

function OpStatusControl(props: OpStatusControlProps) {
  const {
    op,
    savedOpMutationFn,
    saveOpMutationResult: { loading: isSaving },
    contextArgs
  } = props;

  const completeOpValidation = useValidateCompleteOp({
    op,
    contextArgs
  }); // react hook must be at top of component
  const { isValid, validating } = completeOpValidation;

  const isEnabled = op?.isEnabled || false;
  const isArchived = op ? Boolean(op.archivedAt) : false;

  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [
      {
        permissionId: Permissions.MODIFY_GUARDRAILS,
        projectIds: op && op.projectId ? [op.projectId] : '*'
      }
    ]
  });

  return (
    <EnableOpControlRoot>
      <Typography.Text className="op-status-message">
        {`This Op ${isArchived ? 'has been ' : 'is '}`}
        <strong>{isArchived ? 'ARCHIVED' : isEnabled ? 'ON' : 'OFF'}</strong>
      </Typography.Text>

      {!isArchived && (
        <Switch
          checked={isEnabled}
          loading={isSaving}
          onChange={async (checked) => {
            try {
              if (!op) return;

              const opFormValues = getInitialFormValuesFromOp({ op });
              const opInput = transformFormValuesIntoOpToSave(opFormValues);
              opInput.isEnabled = checked;
              const variables = {
                id: op.id,
                opInput
              };
              await savedOpMutationFn({ variables });

              message.success(`Op has been ${checked ? 'enabled' : 'disabled'}`);
            } catch (e) {
              message.error('There was a problem enablabling this Op');
            }
          }}
          disabled={!authz.isAuthorized || isSaving || validating || (!isValid && !isEnabled)}
        />
      )}
    </EnableOpControlRoot>
  );
}

// custom react hook to validate complete Op
function useValidateCompleteOp(args: {
  op?: Op;
  contextArgs: ContextArgs;
  onComplete?: (isValid: boolean, op?: Op) => void;
}): {
  validating: boolean;
  isValid: boolean;
} {
  const {
    op,
    contextArgs,
    contextArgs: { automationFunction, eventDefinition },
    onComplete
  } = args;
  const [validating, setValidating] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    if (!op) return; // guardrail must be saved before enabled?

    const asyncValidateFn = async () => {
      setValidating(true);

      const opToValidate = getInitialFormValuesFromOp({ op });
      const validationSchema = buildValidationSchema({
        contextArgs,
        strict: true
      });

      let isValid = false;

      try {
        await validationSchema.validate(opToValidate, { abortEarly: false });

        setIsValid(true); // assuming if the above passes, op is valid...
        isValid = true;
      } catch (e) {
        setIsValid(false);
      } finally {
        setValidating(false);

        if (onComplete) onComplete(isValid, op);
      }
    };

    asyncValidateFn();
  }, [
    op ? JSON.stringify(op) : '',
    automationFunction ? JSON.stringify(automationFunction) : '',
    eventDefinition ? JSON.stringify(eventDefinition) : ''
  ]);

  return {
    validating,
    isValid
  };
}

async function validateOpAgainstSchema(schema: any, op: OpFormValues) {
  try {
    await schema.validate(op, { abortEarly: false });

    return true;
  } catch (err) {
    return false;
  }
}

export async function validateRawOp(op: Op, strict?: boolean): Promise<false | Op> {
  // transform op into form values as that's what the schema is built around.
  const opToValidate = getInitialFormValuesFromOp({ op });

  const completeOpValidationSchema = buildValidationSchema({ op, strict: strict === false ? false : true });

  try {
    await completeOpValidationSchema.validate(opToValidate, { abortEarly: false });

    const opToSave = transformFormValuesIntoOpToSave(opToValidate);

    return opToSave;
  } catch (err) {
    return false;
  }
}

interface ArchiveDeleteControlProps {
  op?: Op;
}

const UNARCHIVE_OP_MUTATION = gql`
  mutation UnarchiveOpFromOpEditor($id: String) {
    unarchiveOp(id: $id) {
      id
      archivedAt
    }
  }
`;
enum ModalName {
  DELETE = 'DELETE',
  ARCHIVE = 'ARCHIVE'
}

function OpArchiveDeleteControl(props: ArchiveDeleteControlProps) {
  const { op } = props;
  const history = useHistory();
  const [activeModal, setActiveModal] = useState<ModalName | null>(null);
  const [unarchive, { loading: unarchiving }] = useMutation(UNARCHIVE_OP_MUTATION);
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [
      {
        permissionId: Permissions.MODIFY_GUARDRAILS,
        projectIds: op && op.projectId ? [op.projectId] : '*'
      }
    ]
  });
  const isArchived = op ? Boolean(op.archivedAt) : false;

  if (!op) return null;

  return (
    <>
      {isArchived ? (
        <>
          <TitleBarButton
            icon={<DopeIcon name="UNARCHIVE" />}
            children={'Unarchive'}
            onClick={async () => {
              await unarchive({ variables: { id: op.id } });

              message.success('Op has been unarchived.');
            }}
            antButtonProps={{
              disabled: unarchiving
            }}
          />
          <TitleBarButton
            antButtonProps={{
              type: 'danger',
              disabled: !authz.isAuthorized
            }}
            icon={<DopeIcon name="DELETE" />}
            children={'Delete'}
            onClick={() => setActiveModal(ModalName.DELETE)}
          />
        </>
      ) : (
        <TitleBarButton
          icon={<DopeIcon name="ARCHIVE" />}
          children={'Archive'}
          onClick={() => setActiveModal(ModalName.ARCHIVE)}
          antButtonProps={{
            disabled: !authz.isAuthorized
          }}
        />
      )}

      {/* MODALS */}
      <ArchiveOpModal
        opToArchive={activeModal === ModalName.ARCHIVE ? op : null}
        onComplete={() => setActiveModal(null)}
        onCancel={() => setActiveModal(null)}
        entityName="Op"
      />

      <DeleteOpModal
        opToDelete={activeModal === ModalName.DELETE ? op : null}
        onComplete={() => {
          setActiveModal(null);
          history.push('/ops1');
        }}
        onCancel={() => {
          setActiveModal(null);
        }}
        entityName="Op"
      />
    </>
  );
}

// COMPONENT: OP SIDER
const OpSiderRoot = styled.div`
  padding: 24px 12px;

  .op-sider-save-actions {
    margin-bottom: 24px;
  }
`;

interface OpSiderProps {
  op?: Op;
  formRenderProps: FormRenderProps;
}

function OpSider(props: OpSiderProps) {
  const {
    formRenderProps: { canSubmit, isSubmitting },
    op
  } = props;
  const existingProjectId = op?.projectId || null;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [
      {
        permissionId: Permissions.MODIFY_GUARDRAILS,
        projectIds: existingProjectId || '*'
      }
    ]
  });

  const isAuthorized = existingProjectId
    ? authz.isAuthorized // existing op
    : true; // new op.

  return (
    <OpSiderRoot>
      <div className="op-sider-save-actions">
        <Button
          disabled={!isAuthorized || !canSubmit || isSubmitting}
          type="primary"
          block
          className="square"
          loading={isSubmitting}
          htmlType="submit"
        >
          {'Save Changes'}
        </Button>
      </div>

      <Divider />

      <FormField name="name" label="Op name">
        {({ name, value, handleChange, handleBlur }) => (
          <Input.TextArea
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!isAuthorized}
          />
        )}
      </FormField>

      <FormField name="description" label="Description">
        {({ name, value, handleChange, handleBlur }) => (
          <Input.TextArea
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={!isAuthorized}
          />
        )}
      </FormField>

      <Divider />

      <FormField
        name="projectId"
        label="Ownership"
        extra={'Determines permissions and the overall context for this Op to act within.'}
        labelToolTipText={`The Project selected will determine the permissions required for users to view and/or edit this Op. User's Permissions for a given Project will apply to all Cloud Accounts and Projects that are anywhere below the assigned project. This means that a User will need the "Modify Op" Permission for either the Project selected OR a parent/ancestor Project above.`}
      >
        {({ value, handleChange, handleBlur, formikContext }) => {
          const { setFieldValue } = formikContext;
          return (
            <OpContextControl
              projectId={value}
              onChange={(id) => {
                handleChange(id);
                handleBlur();

                // if op.projectId changes, then so should the root of the filtering in the trigger....
                setFieldValue(`triggerProjectIds`, [id]);
              }}
            />
          );
        }}
      </FormField>
    </OpSiderRoot>
  );
}

export default OpEditor;

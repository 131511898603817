import React from 'react';
import { useMutation } from 'react-apollo';
import { TAKE_ACTION_MUTATION } from '../gql';
import DopeIcon from 'components/ui/DopeIcon';
import { Button, message } from 'antd';

interface Props {
  actionResultId: string;
  actionFunctionName: string;
}

function TakeActionOnEventButton(props: Props) {
  const { actionResultId, actionFunctionName } = props;
  const [takeAction, { loading, called }] = useMutation(TAKE_ACTION_MUTATION, {
    variables: {
      id: actionResultId
    }
  });

  return (
    <Button
      className="btn-with-icon"
      type="primary"
      disabled={called}
      loading={loading}
      onClick={async () => {
        const response: any = await takeAction();

        if (response.errors) {
          const errorMessages = response.errors.map(error => error.message);
          const messages = errorMessages.join('<br />');
          message.error(`Take action failed: ${messages}`);
          return;
        } else {
          const action = response.data.runPendingAction;
          message.success(`Action started successfully. Task: ${action.taskId}`);
        }
      }}
    >
      <DopeIcon name="ISSUE_TAKE_ACTION" />
      {actionFunctionName}
    </Button>
  );
}

export default TakeActionOnEventButton;

import React, { useState, useContext } from 'react';
import { Select as AntSelect } from 'antd';
import { size, ClientProject } from 'typings';
import { useProjectTree } from './ProjectTree';
import { Permissions } from '@disruptops/neo-core/dist/permissions';
import AuthContext from 'components/app/Auth/AuthContext';

interface Props {
  name?: string;
  hideDefault?: boolean;
  value: string | string[];
  onChange?: (value: any) => any | void;
  onBlur?: () => any | void;
  setFieldTouched?: (name: string, touched: boolean) => any;
  setFieldValue?: (name: string, value: any) => any;
  disabled?: boolean;
  size?: size;
  many?: boolean;
  projectId?: string;
  permissions?: Permissions | Permissions[];
}

function ProjectSelect(props: Props) {
  const {
    name = '',
    value,
    setFieldTouched,
    setFieldValue,
    onChange,
    onBlur,
    disabled = false,
    size = 'large',
    hideDefault = false,
    many = false,
    projectId,
    permissions = []
  } = props;

  const handleChange = (value: string | string[]) => {
    if (onChange) {
      return onChange(value);
    }
    if (setFieldValue && name) {
      setFieldValue(name, value);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      return onBlur();
    }
    if (setFieldTouched && name) {
      setFieldTouched(name, true);
    }
  };

  const [childProjectIds, setChildProjectIds] = useState<null | string[]>(projectId ? null : []);
  const authContext = useContext(AuthContext);

  const { loading, error, allProjects = [], getChildProjects } = useProjectTree({
    onProjectsLoaded: (projects) => {
      const childProjects = projectId ? getChildProjects(projectId) : [];
      setChildProjectIds(childProjects.map((project) => project.project_id));

      const onlyProjectId = getOnlyProjectId(allProjects);

      if (onlyProjectId && !value) {
        handleChange(onlyProjectId);
      }
    }
  });

  const onlyProjectId = getOnlyProjectId(allProjects);
  const disallowedProjectIds = projectId && childProjectIds ? [projectId, ...childProjectIds] : [];
  const projectIds = allProjects.map((project) => project.project_id);
  const failedProjectIds = authContext?.checkEffectivePermissionsAgainstProjectIds(permissions, projectIds);

  const projects =
    allProjects?.filter((project) => {
      if (disallowedProjectIds.includes(project.project_id)) return false;
      if (hideDefault && project.name !== 'default') return false;
      if (failedProjectIds?.includes(project.project_id)) return false;

      return true;
    }) || [];

  return (
    <AntSelect<string | string[]>
      size={size}
      value={value || onlyProjectId || ''}
      style={{ width: '100%' }}
      onBlur={handleBlur}
      onChange={handleChange}
      disabled={loading || !childProjectIds || !!error || disabled}
      placeholder={!error ? 'All projects' : 'Error loading projects'}
      loading={loading || !childProjectIds}
      mode={many === true ? 'multiple' : 'default'}
    >
      {projects.map((project) => {
        return <AntSelect.Option key={project.project_id}>{project.name}</AntSelect.Option>;
      })}
    </AntSelect>
  );
}

function getOnlyProjectId(projects: ClientProject[]) {
  return projects.length === 1 ? projects[0].project_id : null;
}

export default ProjectSelect;

import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  margin-bottom: 16px;

  &.meta-horizontal {
    flex: 1;
    display: flex;
    align-items: center;

    .meta-label {
      flex: 1;
    }
  }

  .meta-label {
    font-size: 11px;
    font-weight: 400;
    color: #777;
    margin-bottom: 1px;
  }

  .meta-row & {
    margin-bottom: 0;
  }
`;

interface Props {
  label?: string;
  children: React.ReactNode | React.ReactNodeArray;
  orientation?: 'vertical' | 'horizontal';
  classes?: {
    root?: string;
    label?: string;
    content?: string;
  };
}

function Meta(props: Props) {
  const { label, children, classes = {}, orientation = 'vertical' } = props;

  return (
    <Root className={`meta meta-${orientation} ${classes.root || ''}`}>
      {label && <h6 className={`meta-label ${classes.label || ''}`}>{label}</h6>}
      <div className={`meta-content ${classes.content || ''}`}>{children}</div>
    </Root>
  );
}

export default Meta;

import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Modal, Button, message } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { ConfiguredAssessment, FunctionParameter } from 'typings';
import { Form, FormField } from 'components/ui/Form';
import { FunctionParameterInputSet } from 'components/function';
import { initializeFunctionParametersFormValues, generateFormSchema } from 'utilities/function';
import { FormikValues, FormikActions } from 'formik';

import { parseFormValuesIntoFunctionConfiguration, initFormDynamicConfigs } from 'utilities/function/formTransforms';
import { typedPropertyOrDefault } from 'utilities/helpers';
import ProjectTree from 'components/project/ProjectTree';

const UPDATE_ASSESSMENT_STATIC_CONFIGURATION = gql`
  mutation UpdateAssessmentStaticConfiguration($id: ID!, $assessmentInput: AssessmentUpdateInput!) {
    updateAssessment(id: $id, assessmentInput: $assessmentInput) {
      id
      targetProjectIds
      staticConfiguration
      dynamicConfiguration {
        id
        key
        node {
          id
          clientId
          entityType
          entityId
          key
          value
        }
      }
    }
  }
`;

interface Props {
  visible: boolean;
  assessment: ConfiguredAssessment;
  onCloseModal: () => void;
}

const antModalProps: ModalProps = {
  title: 'Edit filters',
  width: 1000
};

function EditAssessmentFiltersModal(props: Props) {
  const { visible, assessment, onCloseModal } = props;
  const { staticConfiguration, dynamicConfiguration } = assessment;

  const emptyParameters: FunctionParameter[] = [];
  const parameters = typedPropertyOrDefault(assessment.assessor.parameters, emptyParameters);

  const initialStaticConfigs = initializeFunctionParametersFormValues(parameters, staticConfiguration);
  const initialValues = {
    targetProjectIds: assessment.targetProjectIds || [],
    ...initialStaticConfigs,
    dynamicConfiguration: initFormDynamicConfigs(dynamicConfiguration)
  };
  const formSchema = generateFormSchema(parameters);

  const [updateAssessmentFilters] = useMutation(UPDATE_ASSESSMENT_STATIC_CONFIGURATION);

  return (
    <Form
      initialValues={initialValues}
      allowCleanSubmits={false}
      validationSchema={formSchema}
      onSubmit={async ({ targetProjectIds = [], ...formValues }: FormikValues, formikActions: FormikActions<any>) => {
        try {
          const assessmentInput = {
            targetProjectIds,
            ...parseFormValuesIntoFunctionConfiguration(formValues, parameters)
          };

          await updateAssessmentFilters({ variables: { id: assessment.id, assessmentInput } });

          formikActions.resetForm();

          onCloseModal();

          message.success('Filters successfuly updated');
        } catch (e) {
          message.error('There was an error updating filters');
        }
      }}
    >
      {(formRenderProps) => {
        const { canSubmit, isSubmitting, submitForm } = formRenderProps;

        return (
          <Modal
            {...antModalProps}
            visible={visible}
            onCancel={onCloseModal}
            closable
            footer={
              <>
                <Button onClick={onCloseModal} disabled={isSubmitting}>
                  Cancel
                </Button>

                <Button
                  onClick={submitForm}
                  type="primary"
                  disabled={!canSubmit || isSubmitting}
                  loading={isSubmitting}
                >
                  {'Save'}
                </Button>
              </>
            }
          >
            <FormField name="targetProjectIds" label="Project Scope:">
              {({ value, handleChange, handleBlur }) => {
                return (
                  <ProjectTree
                    rootProjectId={assessment.projectId}
                    checkable
                    checkedProjectIds={value || []}
                    cascade
                    allowMultipleNodes
                    onCheck={(checked) => {
                      // if checked === opprojectId. this should be undefined?
                      if (!Array.isArray(checked)) return;

                      handleChange(checked.length > 0 ? checked : undefined);
                      handleBlur();
                    }}
                  />
                );
              }}
            </FormField>

            <FunctionParameterInputSet parameters={parameters} {...formRenderProps} showDynamicConfigOptions />
          </Modal>
        );
      }}
    </Form>
  );
}

export default EditAssessmentFiltersModal;

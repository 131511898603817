import { cidrRegex, awsAccountRegex } from '../constants/regularExpressions';

export const objectIsEmpty = (obj: any) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const formNotSubmittable = (errors: any): boolean => {
  const hasErrors = !objectIsEmpty(errors);
  return hasErrors;
};

export const isValidCIDR = (stringToValidate: string) => cidrRegex.test(stringToValidate);
export const isValidAWSAccount = (stringToValidate: string) => awsAccountRegex.test(stringToValidate);

// parse search params in URL (ie /path?searchParams=values&another=Something)
export const parseSearchParams = (search: string): any => {
  return new URLSearchParams(search);
};

export const propertyOrDefault = (obj: any, defaultValue: any) => {
  if (obj === undefined || obj === null) {
    obj = defaultValue;
  }
  return obj;
};

export function typedPropertyOrDefault<O, D>(obj: O, defaultValue: D): O | D {
  if (obj === undefined || obj === null) {
    return defaultValue;
  }

  return obj;
}

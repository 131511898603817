import React from 'react';
import { MutationFunction } from 'react-apollo';

import { NewGuardrail } from 'typings';

import styled from 'styled-components';
import PageHeader from 'components/ui/PageHeader';
import ClickToEditInput from 'components/ui/ClickToEditInput';

import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

const Root = styled.div``;

interface Props {
  guardrail: NewGuardrail;
  saveGuardrailMutationFn: MutationFunction;
}

function GuardrailTitle(props: Props) {
  const { guardrail } = props;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: '*' }]
  });

  return (
    <Root>
      <PageHeader
        titleLevel={2}
        title={
          <ClickToEditInput
            initialValue={guardrail.name || 'untitled'}
            disabled={!authz.isAuthorized}
            onSave={async value => {
              const variables = {
                guardrail: {
                  id: guardrail.id,
                  name: value
                }
              };

              await props.saveGuardrailMutationFn({ variables });
            }}
          />
        }
      />
    </Root>
  );
}

export default GuardrailTitle;

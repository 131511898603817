import React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import NavItem from './NavItem';

const Sider = styled(Layout.Sider)`
  position: fixed;
  position: relative;
  height: 100%;
  overflow-y: auto;
  background-color: #333;
  padding: 0;

  .has-titlebar & {
    top: 56px;
  }

  &.primary-theme {
    background-color: #432287;
  }

  .sidenav-title-wrap {
    height: 56px;
    padding: 0 16px;
    border-bottom: 1px solid #656565;
  }
  .nav-items {
    padding: 8px;
  }

  &.light-theme {
    background-color: #f5f5f5;
    border-right: 1px solid #cacaca;

    .nav-item-label,
    .icon-wrap {
      color: ${(p) => p.theme.grey600};
    }

    .nav-item.is-title .nav-item-label {
      color: ${(p) => p.theme.grey900};
    }

    .sidenav-title-wrap {
      border-bottom-color: #cacaca;
    }
  }
`;

interface NavConfigItem {
  to: string;
  label: string;
  exact?: boolean;
  icon?: React.ReactNode | null;
  hidden?: boolean;
}

export enum ThemeOption {
  primary = 'primary',
  default = 'default',
  light = 'light'
}

interface Props {
  title?: string | null;
  titleLinkTo?: string;
  titleIcon?: React.ReactNode | null;
  navConfig?: NavConfigItem[];
  nav?: React.ReactNode | null;
  theme?: ThemeOption;
  width?: number | null;
}

function Sidenav(props: Props) {
  const { navConfig, nav, title, titleIcon, titleLinkTo, theme = ThemeOption.light, width = 150 } = props;

  return (
    <Sider width={width} className={`side-nav ${theme}-theme`}>
      {title && titleLinkTo && (
        <div className="sidenav-title-wrap">
          <NavItem isSidenavTitle label={title} to={titleLinkTo} icon={titleIcon} />
        </div>
      )}

      <div className="nav-items">
        {navConfig &&
          navConfig.map((item, idx) => {
            const { hidden = false, exact = false } = item;
            if (hidden) return null;

            return <NavItem key={`nav_item_${idx}`} label={item.label} to={item.to} icon={item.icon} exact={exact} />;
          })}

        {nav && nav}
      </div>
    </Sider>
  );
}

export default Sidenav;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import IconButton from 'components/ui/IconButton';
import DopeIcon from 'components/ui/DopeIcon';
import { Route } from 'react-router-dom';
import { Tooltip } from 'antd';
import HorizontalNavbar, { NavItemType } from 'components/app/HorizontalNavbar/HorizontalNavbar';
import { useEffect } from 'react';

const Root = styled.div`
  background-color: ${(p) => p.theme.grey200};
  background-color: #fafafa;
  z-index: 1;

  .title-bar-content {
    border-bottom: 1px solid #cacaca;
    height: 56px; // this will need to change
    display: flex;
    align-items: center;
    position: relative;
    transition: padding 0.2s ease;
    padding-left: 56px;
  }

  .sidenav-permanent & .title-bar-content {
    padding-left: 0;
  }

  .section-title-link {
    color: inherit;
  }

  .section-title-wrap {
    display: flex;
    align-items: center;
    padding-left: 20px;
    width: 240px;
  }

  &.has-more .section-title-wrap {
    border-right: 1px solid #cacaca;
  }

  .section-title {
    padding-left: 4px;
    margin-bottom: 0;
    line-height: 40px;
    font-size: 18px;
  }

  .back-btn {
    margin-left: 16px;
  }
  .title-bar-title {
    margin-bottom: 0;
    margin-right: 48px;
    padding-left: 24px;
  }
  .title-bar-title-wrap {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .title-bar-actions {
    display: flex;
    align-items: center;
    margin-left: 24px;
    > * {
      margin-right: 8px;
    }
  }
  .title-bar-icon {
    margin-right: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-bar-nav,
  .title-bar-actions-row {
    border-bottom: 1px solid #cacaca;
  }

  .title-bar-nav {
    padding: 0 24px;
    border-bottom: 1px solid #cacaca;

    .horizontal-navbar.inpage {
      margin-bottom: 0;
      border-bottom: none;
    }
  }
  .title-bar-actions-row {
    padding: 6px 24px;
    min-height: 45px;

    .title-bar-actions {
      margin-left: 0;
    }
  }
`;

interface TitleBarProps {
  icon?: React.ReactNode | null;
  sectionTitle?: string;
  sectionTitleLinkTo?: string;

  title?: React.ReactNode | React.ReactNodeArray;
  actions?: React.ReactNode | React.ReactNodeArray | null;
  actionsInSecondRow?: boolean;
  classes?: {
    root?: string;
    title?: string;
    sectionTitle?: string;
    actions?: string;
    icon?: string;
  };
  backLinkTo?: string | null;
  backLinkTooltipText?: string | null;

  navConfig?: NavItemType[] | null;
}

function TitleBar(props: TitleBarProps) {
  const {
    sectionTitle,
    sectionTitleLinkTo,
    icon,
    title,
    actions,
    actionsInSecondRow = false,
    backLinkTo,
    backLinkTooltipText,
    classes = {},
    navConfig
  } = props;

  const sectionTitleJSX = sectionTitle ? (
    <div className="section-title-wrap">
      {icon && <div className={`title-bar-icon ${classes.icon || ''}`}>{icon}</div>}
      <h3 className={`section-title ${classes.sectionTitle || ''}`}>{sectionTitle}</h3>
    </div>
  ) : null;

  let rootClassNames = 'title-bar';
  if (classes.root) rootClassNames += ` ${classes.root}`;
  if (title || actions || backLinkTo) rootClassNames += ' has-more';

  const actionsJSX = actions ? <div className={`title-bar-actions ${classes.actions || ''}`}>{actions}</div> : null;

  const titles = ['DisruptOps'];
  if (sectionTitle) titles.push(sectionTitle);
  if (title && typeof title === 'string') titles.push(title);
  const documentTitle = titles.join(' | ');

  useEffect(() => {
    document.title = documentTitle;
  }, [documentTitle]);

  return (
    <Root className={rootClassNames}>
      <div className="title-bar-content">
        {sectionTitleJSX ? (
          !sectionTitleLinkTo ? (
            sectionTitleJSX
          ) : (
            <Link className="section-title-link" to={sectionTitleLinkTo}>
              {sectionTitleJSX}
            </Link>
          )
        ) : null}

        {backLinkTo && (
          <Route
            children={({ history }) => {
              const btn = (
                <IconButton onClick={() => history.push(backLinkTo)} type="ghost" classes={{ root: 'back-btn' }}>
                  <DopeIcon name="ARROW_BACK" size={20} />
                </IconButton>
              );

              if (!backLinkTooltipText) return btn;

              return (
                <Tooltip placement="bottom" title={backLinkTooltipText}>
                  <div>{btn}</div>
                </Tooltip>
              );
            }}
          />
        )}

        {title && <h2 className={`title-bar-title ${classes.title || ''}`}>{title}</h2>}

        {!actionsInSecondRow && actionsJSX}
      </div>
      {navConfig && (
        <div className="title-bar-nav">
          <HorizontalNavbar navConfig={navConfig} type="inpage" />
        </div>
      )}
      {actionsInSecondRow && actionsJSX && <div className="title-bar-actions-row">{actionsJSX}</div>}
    </Root>
  );
}

export default TitleBar;

import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import ProjectChip from 'designSystem/ProjectChip/ProjectChip';
import React from 'react';
import { Project, Op } from 'typings';

interface OpDetailGeneralProps {
  op: Op;
  project: Project | undefined;
}

function OpDetailGeneral(props: OpDetailGeneralProps) {
  const { op, project } = props;

  return (
    <>
      <HorizontalLabel label="Project">{project ? <ProjectChip project={project} /> : <>--</>}</HorizontalLabel>
      <HorizontalLabel label="Description">{op.description || '--'}</HorizontalLabel>
      <HorizontalLabel label="Enabled">{op.isEnabled ? "Yes" : "No"}</HorizontalLabel>
    </>
  );
}

export default OpDetailGeneral;

import DopeIcon from 'components/ui/DopeIcon';
import React from 'react';
import { Op, Recipient, Function, CloudVendor } from 'typings';
import { Root } from './ActionSummary.styles';

export interface WithVendor extends Function {
  cloudVendor: CloudVendor;
}

interface ActionSummaryProps {
  func?: WithVendor;
  op: Op;
  recipients: Recipient[];
}

function ActionSummary(props: ActionSummaryProps) {
  const { func, op, recipients } = props,
    iconSize = 16,
    { configuration } = op,
    alertRecipientId = configuration?.find((c) => c.key === 'alertRecipientId')?.value,
    recipientType = recipients?.find((r) => r.id === alertRecipientId)?.type,
    isAlert = func?.name === 'DisruptOps Alert',
    actionName = isAlert ? 'Alert' : func?.name,
    isAWS = func?.cloudVendor === 'AWS',
    isAzure = func?.cloudVendor === 'Azure',
    isJira = recipientType === 'JIRA' || /jira/i.test(actionName || ''),
    isTeams = recipientType === 'MS_TEAMS',
    isSlack = recipientType === 'SLACK',
    isSNS = recipientType === 'SNS',
    isEmail = recipientType === 'EMAIL';

  return (
    <Root>
      {isAWS && <DopeIcon name="AWS" size={iconSize} title="AWS" />}
      {isAzure && <DopeIcon name="AZURE" size={iconSize} title="Azure" />}
      {isAlert && <DopeIcon name="DISRUPT_OPS_FAVICON_BLACK" size={iconSize} title="Disrupt Ops Alert" />}
      {isJira && <DopeIcon name="JIRA" size={iconSize} title="Jira" />}
      {isTeams && <DopeIcon name="MICROSOFT_TEAMS" size={iconSize} title="Microsoft Teams" />}
      {isSlack && <DopeIcon name="SLACK" size={iconSize} title="Slack" />}
      {isSNS && <DopeIcon name="AWS_SNS" size={iconSize} title="AWS SNS" />}
      {isEmail && <DopeIcon name="ENVELOPE" size={iconSize} title="Email" />}
    </Root>
  );
}

export default ActionSummary;

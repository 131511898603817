import React, { SFC } from 'react';
import styled from 'styled-components';

export interface Props {
  justify?: 'left' | 'center' | 'right';
  children: React.ReactNode;
  horizontalPadding?: string;
  verticalPadding?: string;
  transparent?: boolean;
  height?: string;
}

const StyledDiv = styled.div`
  width: 100%;
  height: ${(p: Props) => p.height || 'auto'};
  padding-top: ${p => p.verticalPadding || '16px'};
  padding-right: ${p => p.horizontalPadding || '10%'};
  padding-bottom: ${p => p.verticalPadding || '16px'};
  padding-left: ${p => p.horizontalPadding || '10%'};
  display: flex;
  flex-flow: column nowrap;
  align-items: ${p => p.justify || 'center'};
  background-color: ${p => (p.transparent ? 'none' : 'background-color: #f6f8fb')};
`;

const Pane: SFC<Props> = props => {
  const { children } = props;
  return <StyledDiv {...props}>{children}</StyledDiv>;
};

export default Pane;

import React from 'react';
import { ScopedDynamicConfig } from 'typings';

export interface GetRelevantSettingsParams {
  type: string;
  many: boolean;
  inputCode?: string;
  configCode?: string;
}

export interface ContextInterface {
  // getValues: (params: { type: string; many: boolean; inputCode?: string }) => ScopedDynamicConfig;
  getRelevantSettings: (params: GetRelevantSettingsParams) => ScopedDynamicConfig[];
  initializing: boolean;
}

export default React.createContext<ContextInterface | undefined>(undefined);

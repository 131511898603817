import memoize from 'fast-memoize';

function createMap<T = any>(
  arrayOfObjects: T[],
  idField: string,
  addToArray: true
): {
  [key: string]: T[];
};
function createMap<T = any>(
  arrayOfObjects: T[],
  idField: string,
  addToArray?: false
): {
  [key: string]: T;
};
function createMap<T = any>(
  arrayOfObjects: T[] = [],
  idField: string | number,
  addToArray: boolean = false
): {
  [key: string]: T;
} {
  return arrayOfObjects.reduce((acc, item: any) => {
    const id = item[idField];

    if (!addToArray) {
      acc[id] = item;
      return acc;
    }

    if (!acc[id]) {
      acc[id] = [];
    }

    acc[id].push(item);

    return acc;
  }, {});
}

const createObjectMap = memoize(createMap);

export default createObjectMap;

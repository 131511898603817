import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CloudAccountNew from '../../CloudAccountNew/CloudAccountNewForm';
import CloudAccounts from '../CloudAccounts';

function CloudAccountsSection() {
  return (
    <Switch>
      <Route path="/cloud-accounts/add/:step?" component={CloudAccountNew} />
      <Route path="/cloud-accounts" component={CloudAccounts} />
      <Route render={() => <Redirect to="/cloud-accounts" />} />
    </Switch>
  );
}

export default CloudAccountsSection;

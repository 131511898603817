import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { SearchBarConfig, SearchBarFilter } from '../SearchBar';
import { AddFilterDiv } from './AddFilter.styles';

interface AddFilterProps {
  config: SearchBarConfig;
  activateFilter: Function;
  shortcutConfig?: SearchBarFilter;
  activateShortcut: Function;
}

const keyBlackList = ['Enter'];

function AddFilter(props: AddFilterProps) {
  const { config, activateFilter, shortcutConfig, activateShortcut } = props,
    toggleTextOptions = {
      ready: 'add a filter...',
      shortcut: `Start typing to search by ${shortcutConfig?.label}`
    },
    selections = useRef<HTMLDivElement>(null),
    [toggleText, setToggleText] = useState<string>(toggleTextOptions.ready),
    [active, setActive] = useState<boolean>();

  let focusIndex = 0;

  function toggleActive() {
    setActive(!active);

    if (!active) {
      focusIndex = 0;
    }
  }

  function onSelectFilter(filter) {
    activateFilter(filter);
    setActive(false);
  }

  function setButtonFocus(reverse: boolean) {
    const buttons = selections.current?.querySelectorAll('button'),
      max = buttons ? buttons.length - 1 : 0,
      incrementer = reverse ? -1 : +1;

    if (buttons?.length) {
      buttons[focusIndex].focus();
      focusIndex += incrementer;
      focusIndex = focusIndex > max ? 0 : focusIndex;
      focusIndex = focusIndex < 0 ? max : focusIndex;
    }
  }

  function onKeyPress(e) {
    const key = e.key;

    if (keyBlackList.includes(key)) {
      return;
    } else if (key === 'ArrowDown' || key === 'ArrowUp') {
      setButtonFocus(key === 'ArrowUp');
    } else if (shortcutConfig) {
      onSelectFilter(shortcutConfig);
      activateShortcut();
    }
  }

  function onBlur(e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setActive(false);
    }
  }

  useEffect(() => {
    if (active && shortcutConfig && config.includes(shortcutConfig)) {
      setToggleText(toggleTextOptions.shortcut);
    } else {
      setToggleText(toggleTextOptions.ready);
    }
  }, [active, config]);

  if (config.length === 0) {
    return null;
  }

  return (
    <AddFilterDiv onBlur={onBlur}>
      <button
        tabIndex={1}
        className="trigger"
        onKeyDown={onKeyPress}
        onClick={() => {
          toggleActive();
        }}
      >
        {toggleText}
      </button>
      {active && (
        <div className="selections" ref={selections}>
          {config.map((filter, i) => (
            <Button
              className="selection"
              key={i}
              tabIndex={i + 2}
              onKeyDown={onKeyPress}
              onClick={(e) => {
                onSelectFilter(filter);
              }}
            >
              {filter.label}
            </Button>
          ))}
        </div>
      )}
    </AddFilterDiv>
  );
}

export default AddFilter;

import React, { Fragment } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Alert, Typography } from 'antd';
import { Resource } from 'typings';

import cloudResourceTypesConfig from 'components/cloudResources/cloudResourceTypesConfig';
import QueryResult from 'components/util/QueryResult';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';

import { ResourceSummaryPanel, ResourceIssueTabs, ResourceDetailsCard } from './components';
import { dashedToRaw } from 'utilities/resourceType';
import PageHeader from 'components/ui/PageHeader';
import DopeIcon from 'components/ui/DopeIcon';
import { getSearchUrl } from 'utilities/inventory';
import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';

const GridContainer = styled.div`
  max-width: 1700px;
  display: grid;
  grid-template-columns: 330px auto;
  grid-template-areas: 'sidebar main';
  grid-column-gap: 24px;

  @media (min-width: 1200px) {
    grid-template-columns: 400px auto;
  }
`;

const MainWrap = styled.div`
  grid-area: main;
`;
const SidebarWrap = styled.div`
  grid-area: sidebar;
`;

export const RESOURCE_BY_UID = gql`
  query ResourceByUid($dopsResourceType: String!, $dopsResourceUid: String!) {
    resources(dops_resource_type: $dopsResourceType, dops_resource_uid: $dopsResourceUid) {
      results {
        dops_resource_uid
        dops_name
        dops_raw
        dops_resource_type
        dops_cloud_account_id
        dops_resource_id
        dops_internal_account_id
        tags {
          key
          value
        }

        # use for tabs
        dops_issue_summary {
          open_count
          exception_count
          resolved_count
        }
      }
    }
  }
`;

export interface Props
  extends RouteComponentProps<{
    dopsResourceUid: string;
    dopsResourceType: string;
  }> {}

function ResourceDetail(props: Props) {
  const {
    match,
    location: { search, pathname }
  } = props;

  const { dopsResourceUid } = match.params;
  const dopsResourceType = dashedToRaw(match.params.dopsResourceType);
  const featureFlags = useFeatureFlag([Feature.USE_CASE_NAV]);
  const searchParams = new URLSearchParams(search);
  const accountName = searchParams.get('account');
  const accountId = searchParams.get('accountId');
  const itemType = searchParams.get('itemType');
  const itemKey = searchParams.get('itemKey');
  const region = searchParams.get('region');
  const titleBar = featureFlags[Feature.USE_CASE_NAV] ? null : (
    <TitleBar
      sectionTitle={'Inventory'}
      sectionTitleLinkTo={'/inventory'}
      title="Resource details"
      icon={<DopeIcon name={'INVENTORY'} size="22" />}
    />
  );

  return (
    <NeoPage titleBar={titleBar}>
      <Query
        query={RESOURCE_BY_UID}
        variables={{
          dopsResourceType,
          dopsResourceUid
        }}
      >
        {(results) => {
          const { data, loading, error, refetch: resourceByIdRefetch } = results;

          return (
            <QueryResult data={data} loading={loading} error={error} entityName="resource">
              {() => {
                if (!data.resources || data.resources.results.length === 0) {
                  let searchUrl = getSearchUrl({
                    accountId,
                    region,
                    itemType,
                    itemKey
                  });

                  // console.log({ data });

                  return (
                    <CenteredContainer>
                      <Alert
                        message="Resource not found"
                        description={
                          <Fragment>
                            <Typography>
                              This resource item was not found in your inventory and may have been deleted or removed.
                              <br />
                              <br />
                            </Typography>
                            {itemKey && (
                              <Typography>
                                ID: <Link to={getSearchUrl({ itemKey })}>{itemKey}</Link>
                              </Typography>
                            )}
                            {itemType && (
                              <Typography>
                                Type: <Link to={getSearchUrl({ itemType })}>{itemType}</Link>
                              </Typography>
                            )}
                            {accountId && (
                              <Typography>
                                Account: <Link to={getSearchUrl({ accountId })}>{accountName || accountId}</Link>
                              </Typography>
                            )}
                            {region && (
                              <Typography>
                                Region: <Link to={getSearchUrl({ region })}>{region}</Link>
                              </Typography>
                            )}
                            {searchUrl && (
                              <Fragment>
                                <br />
                                <Link to={searchUrl}>{'Search for this resource'}</Link>
                                <br />
                              </Fragment>
                            )}
                            <Link to="/inventory/resources">{'Back to resources'}</Link>
                          </Fragment>
                        }
                        type="warning"
                        showIcon
                      />
                    </CenteredContainer>
                  );
                }

                const resource: Resource = data.resources.results[0];
                const resourceTypeConfig = cloudResourceTypesConfig[dopsResourceType] || null;
                const ResourceDetailTable = (resourceTypeConfig && resourceTypeConfig.detailTable) || null;

                return (
                  <CenteredContainer size="fluid">
                    <PageHeader title={resource.dops_name || ''} />

                    <GridContainer>
                      <SidebarWrap>
                        <ResourceSummaryPanel resource={resource} />
                      </SidebarWrap>
                      <MainWrap>
                        {resource.dops_resource_id && resource.dops_issue_summary && (
                          <ResourceIssueTabs
                            dopsResourceId={resource.dops_resource_uid || ''}
                            resourceByIdRefetch={resourceByIdRefetch}
                            onIssueSelected={(issueId: string) => {
                              props.history.push(`/issue/${issueId}`, { onModalClosepath: pathname });
                            }}
                          />
                        )}
                        <ResourceDetailsCard
                          dopsRawJSON={resource.dops_raw}
                          detailTable={
                            resource.dops_resource_uid && ResourceDetailTable ? (
                              <ResourceDetailTable dopsResourceUid={resource.dops_resource_uid} />
                            ) : null
                          }
                        />
                      </MainWrap>
                    </GridContainer>
                  </CenteredContainer>
                );
              }}
            </QueryResult>
          );
        }}
      </Query>
    </NeoPage>
  );
}

export default ResourceDetail;

import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.h3`
  margin-bottom: 0;
  color: ${p => p.theme.grey900};
`;

const Label = styled.div`
  color: ${p => p.theme.grey500};
`;

const Description = styled.div`
  color: ${p => p.theme.grey800};

  h3 + & {
    margin-top: 8px;
  }
`;

interface Props {
  title?: string;
  label?: string;
  description?: string | React.ReactNode;
  classes?: {
    root?: string;
    title?: string;
    label?: string;
    description?: string;
  };
}

function CardSection(props: Props) {
  const { title, label, description, classes = {} } = props;

  return (
    <Root className={`card-section ${classes.root || ''}`}>
      {title && <Title className={classes.title || ''}>{title}</Title>}
      {label && <Label className={`card-section-label ${classes.label || ''}`}>{label}</Label>}
      {description && <Description className={classes.description || ''}>{description}</Description>}
    </Root>
  );
}

export default CardSection;

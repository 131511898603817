import { Icon } from 'antd';
import Button from 'antd/es/button';
import { FilterItem, FilterRow } from 'components/app/FilterBar/components';
import { SearchFilter } from 'components/app/FilterBar/filters';
import CloudAccountFilter from 'components/app/FilterBar/filters/CloudAccount';
import EventDefinitionFilter from 'components/app/FilterBar/filters/EventDefinition';
import EventSourceFilter from 'components/app/FilterBar/filters/EventSourceFilter';
import RegionFilter from 'components/app/FilterBar/filters/Region';
import Paginator from 'components/app/Paginator';
import CloudVendorIcon from 'components/cloudVendors/CloudVendorIcon';
import DateTime from 'components/ui/DateTime';
import DopeIcon from 'components/ui/DopeIcon';
import Table from 'components/ui/Table';
import useSSE from 'components/util/Push/useSSE';
import QueryResult from 'components/util/QueryResult';
import { PUSH_URL } from 'constants/runtimeConfig';
import React, { useState } from 'react';
import { Query, useMutation } from 'react-apollo';
import { Link, useHistory } from 'react-router-dom';
import { Event, FilterDefinition, FilterValues } from 'typings';
import uuid from 'uuid/v4';
import EventDescription from '../EventDetail/EventDescription';
import EventTypeFilter from './components/EventTypeFilter';
import {
  AUTOMATION_EVENT_LIST_QUERY,
  CLASSIFIED_EVENT_LIST_QUERY,
  START_EVENT_DEBUGGING_MUTATION,
  STOP_EVENT_DEBUGGING_MUTATION
} from './gql';

enum EventFilters {
  eventType = 'eventType',
  accountId = 'accountId',
  regionName = 'regionName',
  eventDefinitionId = 'eventDefinitionId',
  eventSource = 'eventSource',
  source = 'source',
  eventName = 'eventName'
}

const FILTERS: FilterDefinition<EventFilters>[] = [
  {
    name: 'eventType',
    label: 'Event Type',
    qsKey: EventFilters.eventType,
    many: false
  },
  {
    name: 'accountId',
    label: 'Cloud Account',
    qsKey: EventFilters.accountId,
    many: true
  },
  {
    name: 'regionName',
    label: 'Region',
    qsKey: EventFilters.regionName,
    many: true
  },
  {
    name: 'eventDefinitionId',
    label: 'Event Definition',
    qsKey: EventFilters.eventDefinitionId,
    many: true
  },
  {
    name: EventFilters.eventSource,
    label: 'Source',
    qsKey: EventFilters.eventSource,
    many: false
  },
  {
    name: EventFilters.source,
    label: 'Source Name',
    qsKey: EventFilters.source,
    many: false
  },
  {
    name: EventFilters.eventName,
    label: 'Event Name',
    qsKey: EventFilters.eventName,
    many: false
  }
];

const getColumns = (onEventSelected) => {
  return [
    {
      title: 'Received',
      key: 'receivedAt',
      sorter: false,
      dataIndex: 'receivedAt',
      className: 'no-wrap',
      render: (text, event) => {
        const issueId = event?.eventData?.message?.issue?.id;

        if (onEventSelected) {
          return (
            <div>
              <DateTime dateTime={event.receivedAt || event.createdAt} />
            </div>
          );
        } else if (
          issueId &&
          (event?.eventData?.eventType === 'issueFound' || event?.eventData?.eventType === 'issueCreated')
        ) {
          return (
            <Link to={`/issues/${issueId}`}>
              <DateTime dateTime={event.receivedAt || event.createdAt} />
            </Link>
          );
        } else {
          return (
            <Link to={`/events/${event.id || event.eventId}`}>
              <DateTime dateTime={event.receivedAt || event.createdAt} />
            </Link>
          );
        }
      }
    },
    {
      key: 'eventDefinitionName',
      dataIndex: 'eventDefinitionName',
      title: 'Event Name',
      sorter: false,
      render: (eventDefinitionName, event) => {
        // Handle event structure from handled and unhandled events
        const { eventData } = event;
        const { message } = eventData || {};

        const asffEvent = message?.detail || eventData?.detail;
        const { findings } = asffEvent || {};
        const asffTitle = findings?.[0].Title || findings?.[0].title;

        const { detail, source } = message || {};
        const { eventName, eventSource, eventType } = detail || {};

        const eventTypeSourceAndName =
          eventType && (source || eventSource) && eventName
            ? `${eventType} - ${source || eventSource} ${eventName}`
            : null;

        const title =
          asffTitle ||
          eventDefinitionName ||
          eventTypeSourceAndName ||
          eventType ||
          (event.eventSource ? `Unknown ${event.eventSource} Event` : 'Unknown Event');

        if (onEventSelected) {
          return <div>{title}</div>;
        } else {
          return <Link to={`/events/${event.id || event.eventId}`}>{title}</Link>;
        }
      }
    },
    {
      title: 'Vendor',
      key: 'vendor',
      dataIndex: 'vendor',
      align: 'center' as 'center',
      sorter: false,
      render: (vendor) => <CloudVendorIcon vendor={vendor} />
    },
    {
      key: 'eventSource',
      dataIndex: 'eventSource',
      title: 'Source',
      align: 'center' as 'center',
      sorter: false,
      render: (eventSource, event) => {
        const product = event.eventData?.source === 'aws.securityhub' ? 'AWS Security Hub' : null;
        const vendorProduct = event.vendorProduct === 'SecurityHub' ? 'AWS Security Hub' : event.vendorProduct;
        return eventSource?.name || product || vendorProduct || '--';
      }
    },
    {
      title: 'Account ID',
      key: 'cloudAccountId',
      sorter: false,
      dataIndex: 'cloudAccountId',
      className: 'no-wrap',
      render: (cloudAccountId, event) => {
        const eventAccountId = event.eventData?.account;
        return cloudAccountId || eventAccountId || '--';
      }
    },
    {
      title: 'Region',
      key: 'region',
      sorter: false,
      dataIndex: 'region',
      className: 'no-wrap',
      render: (region) => region || '--'
    }
  ];
};

function filterLiveEvent(filters: FilterValues, event: Event | null) {
  if (!event) return false;
  if (filters.accountId && !filters.accountId.includes(event.cloudAccountId)) return false;
  if (filters.regionName && !filters.regionName.includes(event.region)) return false;
  if (
    filters.eventDefinitionId &&
    (!event.eventDefinitionId || !filters.eventDefinitionId.includes(event.eventDefinitionId || ''))
  ) {
    return false;
  }

  return true;
}

interface Props {
  eventType?: 'all' | 'triggered';
  onEventSelected?: (event: Event) => any | void;
  eventDefinitionId?: string;
}

function EventsTable(props: Props) {
  const { onEventSelected, eventDefinitionId } = props;
  const [isConnected, setIsConnected] = useState<Boolean>(false);
  const [liveEvents, updateLiveEvents] = useState<Event[]>([]);

  let allEvents: Event[] = [];

  const sse = useSSE((message) => {
    if (message.eventType !== 'eventClassified') return;

    const updatedLiveEvents = liveEvents.slice(0, 1000 - 1);

    const liveEvent: Event = {
      receivedAt: new Date().toISOString(),
      eventType: message.EventType,
      id: uuid(),
      vendor: message.context.vendor || null,
      eventSourceId: message.context.eventSourceId || null,
      eventSourceName: message.context.eventSourceId || null,
      contextId: message.context.contextId,
      clientId: message.context.clientId,
      publisher: message.context.publisher || null,
      cloudAccountId: message.context.cloudAccountId,
      region: message.context.region,
      eventDefinitionId: message.context.eventDefinitionId || null,
      eventDefinitionName: message.context.eventDefinitionName || null,
      eventDefinitionKey: message.context.eventDefinitionKey || null,
      eventData: message || null
    };

    updatedLiveEvents.unshift(liveEvent);

    updateLiveEvents(updatedLiveEvents);
  });

  const history = useHistory();

  const [startEventDebugging, startResults] = useMutation(START_EVENT_DEBUGGING_MUTATION);
  const [stopEventDebugging, stopResults] = useMutation(STOP_EVENT_DEBUGGING_MUTATION);

  return (
    <Paginator pageSize={20} filters={FILTERS}>
      {(pageRenderProps) => {
        const {
          pageNumber,
          pageSize,
          updateSearchParams,
          updateSearch,
          updateFilter,
          search,
          filterValues,
          pushFilters,
          replaceFilters
        } = pageRenderProps;

        const functionQueryVariables = {
          search,
          cloudAccountId: filterValues.accountId,
          region: filterValues.regionName,
          eventType: filterValues.eventType,
          eventSourceId: filterValues.eventSource,
          eventDefinitionId: eventDefinitionId || filterValues.eventDefinitionId,
          source: filterValues.source,
          eventName: filterValues.eventName,
          pageNumber,
          pageSize
        };

        return (
          <Query
            query={filterValues.eventType === 'all' ? CLASSIFIED_EVENT_LIST_QUERY : AUTOMATION_EVENT_LIST_QUERY}
            variables={functionQueryVariables}
          >
            {({ loading, data, error, refetch }) => (
              <QueryResult loading={loading} data={data} error={error} entityName="Events">
                {() => {
                  const events = filterValues.eventType === 'all' ? data?.classifiedEvents : data?.automationEvents;

                  const { nodes = [], pageInfo = {} } = events || {};

                  // Filter live events based on current filter criteria
                  const liveFilteredEvents: Event[] = liveEvents.filter((event) =>
                    filterLiveEvent(filterValues, event)
                  );

                  allEvents = liveFilteredEvents.concat(nodes)?.filter((event) => !!event);

                  return (
                    <>
                      <FilterRow>
                        {filterValues.eventType === 'all' ? (
                          <FilterItem label="Live">
                            <Button
                              loading={startResults.loading || stopResults.loading}
                              onClick={async () => {
                                if (!isConnected) {
                                  await (sse && sse.connect(`${PUSH_URL}?debug=true`));
                                  await startEventDebugging();
                                  await setIsConnected(true);
                                } else {
                                  liveEvents.splice(0, liveEvents.length);
                                  sse?.close();
                                  await stopEventDebugging();
                                  await setIsConnected(false);
                                }
                              }}
                            >
                              <DopeIcon name={isConnected ? 'PAUSE' : 'PLAY'} style={{ marginTop: '5px' }} />
                            </Button>
                          </FilterItem>
                        ) : (
                          <FilterItem label="Refresh">
                            <Button onClick={() => refetch()}>
                              <Icon type="sync" />
                            </Button>
                          </FilterItem>
                        )}

                        {!props.eventType && (
                          <FilterItem label="Event Type">
                            <EventTypeFilter
                              minWidth={120}
                              onChange={(value) => updateFilter('eventType', value !== 'op' ? value : [])}
                              value={(!Array.isArray(filterValues.eventType) && filterValues.eventType) || 'op'}
                            />
                          </FilterItem>
                        )}

                        <FilterItem label="Cloud Account">
                          <CloudAccountFilter
                            onChange={(value) => value && pushFilters('accountId', value)}
                            value={filterValues.accountId}
                            externalAccountId={true}
                          />
                        </FilterItem>

                        <FilterItem label="Region">
                          <RegionFilter
                            minWidth={150}
                            onChange={(value) => value && pushFilters('regionName', value)}
                            value={filterValues.regionName}
                          />
                        </FilterItem>

                        {!eventDefinitionId && (
                          <FilterItem label="Source">
                            <EventSourceFilter
                              minWidth={170}
                              onChange={(value) => replaceFilters('eventSource', value)}
                              value={filterValues.eventSource && `${filterValues.eventSource}`}
                            />
                          </FilterItem>
                        )}
                        {!eventDefinitionId && (
                          <FilterItem label="Event Definition">
                            <EventDefinitionFilter
                              minWidth={180}
                              onChange={(value) => value && pushFilters('eventDefinitionId', value)}
                              value={filterValues.eventDefinitionId}
                            />
                          </FilterItem>
                        )}
                        <FilterItem label="Full Event Search">
                          <SearchFilter search={search} onSearchChange={updateSearch} minWidth={200} />
                        </FilterItem>
                      </FilterRow>
                      <Table
                        antTableProps={{
                          loading,
                          dataSource: allEvents,
                          rowKey: 'id',
                          columns: getColumns(onEventSelected),
                          locale: {
                            emptyText: 'No matching Events'
                          },
                          onChange: (pagination, filters, sorter) => {
                            updateSearchParams({
                              page: pagination.current || 1
                            });
                          },
                          onRow: (row) => ({
                            onClick: () => {
                              const rowId = row.id || row.eventId;
                              const event = allEvents.find(
                                (message) => message.id === rowId || message.eventId === rowId
                              );

                              if (!event) return;

                              const issueId = event?.eventData?.message?.issue?.id;

                              if (onEventSelected) {
                                return onEventSelected(event);
                              } else if (issueId && event?.eventData?.eventType === 'issueFound') {
                                return history.push({
                                  pathname: `/issues/${issueId}`
                                });
                              } else {
                                return history.push({
                                  pathname: `/events/${rowId}`,
                                  state: { event: event }
                                });
                              }
                            }
                          }),
                          pagination: {
                            pageSize,
                            current: pageInfo && pageInfo.current,
                            total: pageInfo && pageInfo.total,
                            onChange: (pageNumber) => {
                              updateSearchParams({
                                page: pageNumber
                              });
                            }
                          },
                          expandedRowRender: (event) => {
                            return <EventDescription event={event} />;
                          }
                        }}
                      />
                    </>
                  );
                }}
              </QueryResult>
            )}
          </Query>
        );
      }}
    </Paginator>
  );
}

export default EventsTable;

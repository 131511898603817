import React, { useContext } from 'react';
import { Main, CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import Form, { FormField } from 'components/ui/Form';
import { Switch, Button } from 'antd';
import AuthContext, { DevSettings } from 'components/app/Auth/AuthContext';

function DevSettingsPage() {
  const authContext = useContext(AuthContext);

  const isFeatureFlaggingDisabled = authContext?.devSettings?.isFeatureFlaggingDisabled;
  const isSSEDebuggingEnabled = authContext?.devSettings?.isSSEDebuggingEnabled;

  return (
    <Main titleBar={<TitleBar title="Dev Settings" />}>
      <CenteredContainer>
        <Form
          initialValues={
            {
              isFeatureFlaggingDisabled: isFeatureFlaggingDisabled,
              isSSEDebuggingEnabled: isSSEDebuggingEnabled
            } as DevSettings
          }
          allowCleanSubmits={false}
          onSubmit={(formValues, formActions) => {
            const devSettings = formValues as DevSettings;
            authContext?.persistDevSettingsToLocalStorage(devSettings);
            formActions.setSubmitting(false);
            formActions.resetForm(formValues);
          }}
        >
          {(formikProps) => {
            const { isSubmitting, canSubmit } = formikProps;
            return (
              <>
                <FormField label="Is Feature Flagging Disabled?" name="isFeatureFlaggingDisabled">
                  {({ value, handleChange, handleBlur }) => {
                    return <Switch checked={value} onChange={handleChange} onClick={handleBlur}></Switch>;
                  }}
                </FormField>

                <FormField label="Is SSE Debugging Enabled?" name="isSSEDebuggingEnabled">
                  {({ value, handleChange, handleBlur }) => {
                    return <Switch checked={value} onChange={handleChange} onClick={handleBlur} />;
                  }}
                </FormField>

                <Button loading={isSubmitting} disabled={!canSubmit} htmlType="submit">
                  Save
                </Button>
              </>
            );
          }}
        </Form>
      </CenteredContainer>
    </Main>
  );
}

export default DevSettingsPage;

import React from 'react';
import humanizeDuration from 'humanize-duration';

interface ComponentProps {
  indexedConfigs: any;
}

function Expiration(props: ComponentProps) {
  const { indexedConfigs } = props;

  return indexedConfigs.isAlertExpirationEnabled ? (
    <>Prevent actions from buttons after {humanizeDuration(indexedConfigs.alertExpirationMinutes.value * 60 * 1000)}</>
  ) : null;
}

export default Expiration;

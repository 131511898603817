import { ReactNode } from 'react';
import SLACK_DECISION_INTEGRATION_DEFINITION from './slack';
import MS_TEAMS_DECISION_INTEGRATION_DEFINITION from './ms-teams';

const OP_DECISION_INTEGRATION_DEFINITIONS: OpDecisionIntegrationDefinition[] = [
  SLACK_DECISION_INTEGRATION_DEFINITION,
  MS_TEAMS_DECISION_INTEGRATION_DEFINITION
];

export interface DecisionIntegrationFormProps {}

export interface OpDecisionIntegrationDefinition {
  key: string;
  name: string;
  title: string;
  description: string;
  icon?: ReactNode;
  // form?: ReactNode;
  formComponent?: (props: DecisionIntegrationFormProps) => JSX.Element;
  validationShape?: {
    [key: string]: any;
  };

  // use to autopopulate certain fields in form.
  defaultValues?: {
    [key: string]: any;
  };
}

export default OP_DECISION_INTEGRATION_DEFINITIONS;

import React from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as Yup from 'yup';
import { FormikActions, FormikValues } from 'formik';
import { Modal, Button, message } from 'antd';
import { ModalProps } from 'antd/lib/modal';

import { Form, FormField } from 'components/ui/Form';
import ProjectTree from 'components/project/ProjectTree';
import ErrorAlert from 'components/ui/ErrorAlert';
import { PROJECT_MANAGER_QUERY } from './ProjectManager';
import { ClientProject } from 'typings';
import { refreshCognitoSession } from '../../../../../services/auth';

const MOVE_CLOUD_ACCOUNTS_MUTATION = gql`
  mutation MoveCloudAccounts($projectId: string, $input: MoveCloudAccountsInputs!) {
    moveCloudAccountsToProject(projectId: $projectId, input: $input)
      @rest(path: "/api/v2/projects/{args.projectId}/accounts", type: "ClientProject", method: "PUT") {
      name
      accounts
      project_id
      created
      updated
      parent_id
      accounts_list @type(name: "ClientAccount") {
        assumerole_account_id
        account_id
        name
        nickname
        provider
      }
    }
  }
`;

const antModalProps: ModalProps = {
  title: 'Move Cloud Accounts',
  width: 1000
};

const schema = Yup.object().shape({
  checkedKeys: Yup.array()
    .of(Yup.string())
    .min(1, 'Must select Project')
    .max(1, 'Must select Project')
    .required('Must select Project')
});

interface Props {
  visible: boolean;
  accountIds: string[];
  onCloseModal: () => void;
  onAfterSave: () => void;
  project: ClientProject;
}

function MoveCloudAccountsModal(props: Props) {
  const { project, visible, onCloseModal, accountIds, onAfterSave } = props;

  const [moveCloudAccounts, { error }] = useMutation(MOVE_CLOUD_ACCOUNTS_MUTATION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PROJECT_MANAGER_QUERY,
        variables: {
          id: project.project_id
        }
      }
    ]
  });

  if (!visible) return null;

  return (
    <Form
      allowCleanSubmits={false}
      validationSchema={schema}
      initialValues={{
        checkedKeys: []
      }}
      onSubmit={async (formFields: FormikValues, actions: FormikActions<any>) => {
        try {
          const variables = {
            projectId: formFields.checkedKeys[0],
            input: { account_ids: accountIds }
          };

          await moveCloudAccounts({ variables });
          message.success('Cloud Accounts have been successfully moved.');

          await refreshCognitoSession();
          onAfterSave();
        } catch (e) {
          message.error('There was an error');
        }
      }}
    >
      {(formProps) => {
        const { setFieldTouched, setFieldValue, canSubmit, isSubmitting, submitForm } = formProps;
        return (
          <Modal
            visible
            {...antModalProps}
            closable
            onCancel={onCloseModal}
            footer={
              <>
                <Button onClick={onCloseModal} disabled={isSubmitting}>
                  {'Cancel'}
                </Button>
                <Button
                  onClick={submitForm}
                  type="primary"
                  disabled={!canSubmit || isSubmitting}
                  loading={isSubmitting}
                >
                  {'Move Cloud Accounts'}
                </Button>
              </>
            }
          >
            {error && <ErrorAlert error={error} />}

            <FormField name="checkedKeys" label="Projects">
              {(formFieldValues) => {
                const { value, name } = formFieldValues;

                return (
                  <ProjectTree
                    checkedProjectIds={value}
                    checkable
                    cascade={false}
                    allowMultipleNodes={false}
                    onCheck={(checked) => {
                      // should only allow one node to be checked
                      const newChecked =
                        Array.isArray(checked) && checked.length > 0
                          ? checked.filter((key) => !value.includes(key))
                          : [];

                      setFieldValue(name, newChecked);
                      setFieldTouched(name, true);
                    }}
                  />
                );
              }}
            </FormField>
          </Modal>
        );
      }}
    </Form>
  );
}

export default MoveCloudAccountsModal;

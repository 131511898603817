import React from 'react';
import { Button, Collapse, Select } from 'antd';
import { getIn, FormikActions, FormikValues } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { NewGuardrail, Maybe } from 'typings';

import { GridCard } from 'components/ui/Card';
import DopeIcon from 'components/ui/DopeIcon';
import Form, { FormField } from 'components/ui/Form';

import TRIGGER_EVENT_SOURCE_DEFINITIONS, {
  findTriggerEventTypeDefinition,
  GuardrailTriggerEventSourceDefinition
} from './definitions';
import { GuardrailEditorState, GuardrailEditorStep } from '../../GuardrailEditor';
import CollapseHeader from 'components/ui/Collapse/CollapseHeader';
import FeatureFlag, { Feature } from 'components/app/FeatureFlag';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { Permissions } from '@disruptops/neo-core/dist/permissions';

const { Panel } = Collapse;

const schema = Yup.object().shape({
  eventSource: Yup.string().required('Must select source'),
  eventType: Yup.string().required('Must select type'),
  configuration: Yup.mixed().when(['eventSource', 'eventType'], (eventSource, eventType) => {
    const selectedEventType = findTriggerEventTypeDefinition(eventSource, eventType);

    if (selectedEventType && selectedEventType.schema) return selectedEventType.schema;

    return Yup.object();
  })
});

function getDefaultTriggerEventSourceDefinition(
  triggerDefinitions: GuardrailTriggerEventSourceDefinition[]
): string | undefined {
  if (triggerDefinitions.length > 1) return undefined;

  if (!triggerDefinitions.length) throw new Error('No Trigger Event Sources. Needs at least one');

  return triggerDefinitions[0].key;
}

function getDefaultTriggerType(
  triggerDefinitions: GuardrailTriggerEventSourceDefinition[],
  eventSourceName: string | undefined
): string | undefined {
  if (!eventSourceName) return undefined;

  const eventSource = triggerDefinitions.find((i) => i.key === eventSourceName);
  if (!eventSource) throw new Error(`Could not find eventSource with name ${eventSourceName}`);
  if (!eventSource.eventTypes.length) throw new Error('eventSource requires eventTypes');

  if (eventSource.eventTypes.length === 1) {
    return eventSource.eventTypes[0].key;
  }

  return undefined;
}

export interface InitialTriggerFormValues {
  eventSource?: Maybe<string>;
  eventType?: Maybe<string>;
  configuration?: Maybe<any>;
}

function getInitialValuesFromGuardrail(
  guardrail: NewGuardrail,
  triggerDefinitions: GuardrailTriggerEventSourceDefinition[]
): InitialTriggerFormValues {
  const trigger = guardrail.trigger;
  const eventSourceKey = (trigger && trigger.eventSource) || getDefaultTriggerEventSourceDefinition(triggerDefinitions);
  const eventTypeKey = (trigger && trigger.eventType) || getDefaultTriggerType(triggerDefinitions, eventSourceKey);

  const eventTypeDefinition =
    eventSourceKey && eventTypeKey ? findTriggerEventTypeDefinition(eventSourceKey, eventTypeKey) : null;

  const configuration =
    eventTypeDefinition && eventTypeDefinition.getInitialConfigurationValuesFromGuardrail
      ? eventTypeDefinition.getInitialConfigurationValuesFromGuardrail(guardrail)
      : (trigger && trigger.configuration) || {};

  const initialValues = {
    eventSource: eventSourceKey,
    eventType: eventTypeKey,
    configuration
  };

  return initialValues;
}

const Root = styled.div``;

interface GuardrailTriggerSectionProps extends GuardrailEditorState {
  onTriggerSectionSubmitSuccess: (updatedGuardrail: NewGuardrail) => void;
}

function GuardrailTriggerSection(props: GuardrailTriggerSectionProps) {
  const { activeStep, guardrail, onTriggerSectionSubmitSuccess, setActiveStep, saveGuardrailMutationFn } = props;
  const featureList = TRIGGER_EVENT_SOURCE_DEFINITIONS.reduce((acc: Feature[], item) => {
    if (item.featureFlag) acc.push(item.featureFlag);

    return acc;
  }, []);
  const uniqueFeatures: Feature[] = Array.from(new Set(featureList));

  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ projectIds: '*', permissionId: Permissions.MODIFY_GUARDRAILS }]
  });

  return (
    <FeatureFlag features={uniqueFeatures}>
      {(resolvedFlags) => {
        const availableEventSources: GuardrailTriggerEventSourceDefinition[] = TRIGGER_EVENT_SOURCE_DEFINITIONS.filter(
          (item) => (item.featureFlag ? resolvedFlags[item.featureFlag] : true)
        );

        return (
          <Form
            initialValues={getInitialValuesFromGuardrail(guardrail, availableEventSources)}
            validationSchema={schema}
            onSubmit={async (formValues: FormikValues, formActions: FormikActions<any>) => {
              const { eventSource, eventType, configuration } = formValues;

              // FKCHANGE
              let guardrailToSave: NewGuardrail = {
                id: guardrail.id,
                isEnabled: false,
                trigger: {
                  eventSource,
                  eventType,
                  configuration
                }
              };

              const eventTypeDefinition =
                eventSource && eventType ? findTriggerEventTypeDefinition(eventSource, eventType) : null;

              if (eventTypeDefinition && eventTypeDefinition.transformGuardrailBeforeSaving)
                guardrailToSave = eventTypeDefinition.transformGuardrailBeforeSaving(guardrailToSave);

              // SAVE GUARDRAIL
              const variables = {
                guardrail: guardrailToSave
              };

              const result = await saveGuardrailMutationFn({ variables });
              const updatedGuardrail: NewGuardrail = result.data.saveGuardrail;

              // SET SUBMITTING FALSE
              formActions.setSubmitting(false);

              // ON TRIGGER SECTION SUBMIT SUCCESS
              if (!result.errors) onTriggerSectionSubmitSuccess(updatedGuardrail);
            }}
          >
            {(formRenderProps) => {
              const { canSubmit, isSubmitting, values } = formRenderProps;
              const eventSourceKey = getIn(values, 'eventSource') || null;
              const eventTypeKey = getIn(values, 'eventType') || null;

              const selectedEventSourceDefinition = eventSourceKey
                ? TRIGGER_EVENT_SOURCE_DEFINITIONS.find((item) => item.key === eventSourceKey)
                : null;

              const selectedEventType = selectedEventSourceDefinition
                ? eventTypeKey
                  ? selectedEventSourceDefinition.eventTypes.find((eventType) => eventType.key === eventTypeKey)
                  : null
                : null;

              const TriggerConfigurationInput = (selectedEventType && selectedEventType.configurationInput) || null;

              const ConfigureCollapseReadView =
                (selectedEventType && selectedEventType.configureCollapseReadView) || null;

              const SectionTitle = selectedEventType ? selectedEventType.sectionTitle : null;

              const submitButton = (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!authz.isAuthorized || !canSubmit || isSubmitting}
                  block
                  loading={isSubmitting}
                >
                  {'Save and Continue'}
                </Button>
              );

              return (
                <Root>
                  <GridCard
                    secondaryTitle="Trigger"
                    title={SectionTitle ? <SectionTitle guardrail={guardrail} /> : 'Select Trigger'}
                    icon={<DopeIcon name="ISSUE_OPEN" color="rgb(255, 152, 0)" size={20} />}
                    classes={{
                      root: 'has-arrow'
                    }}
                    elevation={1}
                    rounded
                  >
                    <div>
                      <Collapse
                        bordered={false}
                        activeKey={activeStep}
                        accordion
                        onChange={(selected) => {
                          const selectedStep = Object.values(GuardrailEditorStep).find((step) => step === selected);
                          setActiveStep(selectedStep ? selectedStep : GuardrailEditorStep.NONE);
                        }}
                      >
                        <Panel
                          key={GuardrailEditorStep.SELECT_EVENT_TRIGGER}
                          header={
                            <CollapseHeader
                              headerTitle="Trigger Type:"
                              headerContent={(selectedEventType && selectedEventType.name) || null}
                            />
                          }
                        >
                          <FormField name="eventSource" label="Event Source">
                            {({ value, handleChange, handleBlur }) => {
                              return (
                                <Select
                                  value={value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder={'Select Event Source'}
                                >
                                  {availableEventSources.map((item, idx) => {
                                    return (
                                      <Select.Option key={item.key} value={item.key}>
                                        {item.name}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              );
                            }}
                          </FormField>

                          {selectedEventSourceDefinition && selectedEventSourceDefinition.eventTypes.length > 0 && (
                            <FormField name="eventType" label="Trigger Type">
                              {({ value, handleChange, handleBlur }) => (
                                <Select
                                  value={value}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder={'Select Trigger'}
                                >
                                  {selectedEventSourceDefinition.eventTypes.map((item) => (
                                    <Select.Option key={item.key} value={item.key}>
                                      {item.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </FormField>
                          )}

                          {selectedEventType && selectedEventType.configurationInput ? (
                            <Button
                              type="primary"
                              disabled={!authz.isAuthorized || isSubmitting}
                              block
                              loading={isSubmitting}
                              onClick={() => setActiveStep(GuardrailEditorStep.CONFIGURE_TRIGGER)}
                            >
                              {'Continue'}
                            </Button>
                          ) : (
                            submitButton
                          )}
                        </Panel>

                        {TriggerConfigurationInput && (
                          <Panel
                            key={GuardrailEditorStep.CONFIGURE_TRIGGER}
                            header={
                              <CollapseHeader
                                headerTitle={
                                  selectedEventType && selectedEventType.getConfigureCollapseTitle
                                    ? selectedEventType.getConfigureCollapseTitle()
                                    : 'Configure Trigger:'
                                }
                                headerContent={
                                  ConfigureCollapseReadView ? (
                                    <ConfigureCollapseReadView name="configuration" guardrail={guardrail} />
                                  ) : null
                                }
                              />
                            }
                          >
                            <>
                              <TriggerConfigurationInput name="configuration" guardrail={guardrail} />
                              {submitButton}
                            </>
                          </Panel>
                        )}
                      </Collapse>
                    </div>
                  </GridCard>
                </Root>
              );
            }}
          </Form>
        );
      }}
    </FeatureFlag>
  );
}

export default GuardrailTriggerSection;

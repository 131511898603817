import React from 'react';
import styled from 'styled-components';
import DopeIcon from 'components/ui/DopeIcon';
import { Typography } from 'antd';
import { NodeType } from './types';

const Root = styled.div`
  display: flex;

  .tree-node-icon {
    display: inline-block;
    margin: 4px 4px 0;
    opacity: 0.5;

    &.project {
      color: ${(p) => p.theme.grey800};
    }

    &.cloud-account {
      color: ${(p) => p.theme.grey400};
    }
  }
`;

interface Props {
  children?: string;
  nodeType: NodeType;
}

function ProjectTreeNodeTitle(props: Props) {
  const { children, nodeType } = props;

  let iconClassNames = 'tree-node-icon';
  if (nodeType === NodeType.project) iconClassNames += ' project';
  if (nodeType === NodeType.cloudAccount) iconClassNames += ' cloud-account';

  return (
    <Root>
      <DopeIcon
        size=".9em"
        className={iconClassNames}
        name={nodeType === NodeType.project ? 'PROJECT' : 'CLOUD_ACCOUNT'}
      />
      {children && <Typography.Text>{children}</Typography.Text>}
    </Root>
  );
}

export default ProjectTreeNodeTitle;

import React from 'react';
import { Select } from 'antd';
import { useQuery } from 'react-apollo';
import { ModeOption } from 'antd/lib/select';
import gql from 'graphql-tag';

export interface Props {
  value?: string | string[];
  onChange: (value: any) => any;
  mode?: ModeOption;
  placeholder?: string;
  onBlur?: (event: any) => any;
}

const AUTOMATION_EVENT_SOURCES_LIST_QUERY = gql`
  query eventSources($id: [String], $pageNumber: Int, $pageSize: Int, $labels: [String]) {
    eventSources(id: $id, pageNumber: $pageNumber, pageSize: $pageSize, labels: $labels) {
      pageInfo {
        total
        current
        size
        sortBy
        sortDirection
      }
      nodes {
        id
        key
        name
        description
        overview
        cloudVendor
        labels
        stages
        context
        criteria {
          path
          patterns
        }
        createdAt
        updatedAt
      }
    }
  }
`;

function EventSourceFilter(props: Props) {
  const { value, onChange, onBlur } = props;

  const mode = props.mode ? props.mode : 'default';
  const placeholder = props.placeholder ? props.placeholder : 'Any Source';

  const { loading, error, data } = useQuery(AUTOMATION_EVENT_SOURCES_LIST_QUERY, {
    fetchPolicy: 'cache-first'
  });

  return (
    <Select
      value={value === null ? undefined : value}
      style={{ width: '100%', minWidth: '200px' }}
      onChange={onChange}
      disabled={loading || !!error}
      placeholder={!error ? placeholder : 'Error loading sources'}
      loading={loading}
      mode={mode}
      onBlur={onBlur}
      filterOption={(inputValue, option) => {
        if (!option || !option.props || !option.props.title) {
          return true;
        }
        return new RegExp(inputValue, 'i').test(option.props.title);
      }}
    >
      <Select.Option key="any" value="">
        Any Source
      </Select.Option>
      {!loading && data?.eventSources?.nodes ? (
        data.eventSources.nodes
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((eventSource) => {
            const { id, name } = eventSource;
            return (
              <Select.Option key={id} title={name}>
                {name}
              </Select.Option>
            );
          })
      ) : (
        <Select.Option disabled value="">
          Loading
        </Select.Option>
      )}
    </Select>
  );
}

export default EventSourceFilter;

import { Button, Collapse, Divider, Icon, Pagination, Select, Typography } from 'antd';
import { AUTOMATION_FUNCTION_FIELDS_FOR_OPS } from 'app/sections/Authenticated/pages/Ops/OpDetail/gql';
import { FilterItem, FilterRow } from 'components/app/FilterBar/components';
import { LabelsFilter, SearchFilter } from 'components/app/FilterBar/filters';
import { CloudVendorIcon } from 'components/cloudVendors';
import { TYPE_SCHEMAS } from 'components/function/FunctionParameterInput/FunctionParameterInput';
import Markdown from 'components/ui/Markdown';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { AutomationFunction } from 'typings';

const { Text } = Typography;

const UNIQUE_FUNCTION_LABELS_QUERY = gql`
  query UniqueFunctionLabelsForSelectFunction {
    uniqueFunctionLabels
  }
`;

const EVENT_DETAIL_FUNCTIONS_BY_ID = gql`
  query eventDefinitionFunctions($eventDefinitionId: ID!) {
    eventDefinitionFunctions(eventDefinitionId: $eventDefinitionId) {
      nodes {
        id
        key
        name
        description
        overview
        cloudVendor
        eventFunctions {
          functionInputMappings {
            key
            valuePath
          }
        }
      }
    }
  }
`;

const AUTOMATION_FUNCTIONS_QUERY = gql`
  query AutomationFunctionsQueryForEventDetail(
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $search: String
    $labels: [String]
    $cloudVendor: [String]
    $isDisabled: Boolean
  ) {
    automationFunctions(
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      labels: $labels
      cloudVendor: $cloudVendor
      isDisabled: $isDisabled
    ) {
      pageInfo {
        total
        size
        current
      }
      nodes {
        ...AutomationFunctionFieldsForOps
        overview
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_OPS}
`;

export type OnSelectFunctionFunc = (automationFunction: AutomationFunction) => void;

interface Props {
  onSelectAction: OnSelectFunctionFunc;
  eventDefinitionId?: string | null;
}

function automationFunctionCollapse(automationFunction: any, key: string, onSelectAction: OnSelectFunctionFunc) {
  const disabled = automationFunction.parameters?.some((p) => !TYPE_SCHEMAS[p.type]);

  return (
    <Collapse.Panel
      key={key}
      disabled={disabled}
      header={
        <>
          <CloudVendorIcon vendor={automationFunction.cloudVendor} size={20} />
          <span style={{ marginLeft: '4px' }}>{automationFunction.name}</span>
        </>
      }
      extra={
        <>
          {automationFunction.recommended && (
            <span style={{ marginRight: '8px' }}>
              <Text type="secondary">* Recommended</Text>
            </span>
          )}
          {disabled && (
            <span style={{ marginRight: '8px' }}>
              <Text type="secondary">Not yet supported</Text>
            </span>
          )}
          {
            <Button
              onClick={() => onSelectAction(automationFunction)}
              title="Take Action"
              size="small"
              disabled={disabled}
            >
              <Icon type="caret-right" />
            </Button>
          }
        </>
      }
    >
      <div style={{ padding: '16px' }}>
        {automationFunction.description && <p>{automationFunction.description}</p>}
        {automationFunction.overview && <Markdown source={automationFunction.overview} />}

        {!disabled && <Button onClick={() => onSelectAction(automationFunction)}>Take Action</Button>}
      </div>
    </Collapse.Panel>
  );
}

const Root = styled.div`
  .ant-divider {
    margin-top: 0px;
    margin-bottom: 0px;
    height: 4px;
  }
`;

function SelectFunction(props: Props) {
  const { onSelectAction, eventDefinitionId } = props;

  const [searchString, setSearchString] = useState<string | null>(null);
  const [cloudVendors, setCloudVendors] = useState<string[] | null>(null);
  const [labels, setLabels] = useState<string[] | null>(null);
  const [pageNumber, setPageNumber] = useState();

  const pageSize = 5;

  // query event definition functions for the event, if it has an event definition id
  const { data: recommendedActionsData, loading: recommendedActionsLoading } = useQuery(EVENT_DETAIL_FUNCTIONS_BY_ID, {
    variables: { eventDefinitionId },
    skip: !eventDefinitionId
  });
  const { eventDefinitionFunctions } = recommendedActionsData || {};
  const { nodes: recommendedActionNodes } = eventDefinitionFunctions || {};
  const recommendedActions = recommendedActionNodes?.map((a) => {
    return { ...a, recommended: true };
  });

  // query "external actions" (jira)
  const { data: externalActionsData, loading: externalActionsLoading } = useQuery(AUTOMATION_FUNCTIONS_QUERY, {
    variables: {
      search: searchString,
      labels: labels,
      cloudVendor: ['Jira'],
      pageSize: pageSize,
      sortBy: 'name',
      sortDirection: 'ASC',
      isDisabled: false
    },
    skip: !(!pageNumber || pageNumber === 1)
  });
  const { automationFunctions: externalActionsResults } = externalActionsData || {};
  const { nodes: externalActionNodes } = externalActionsResults || {};
  const externalActions = externalActionNodes?.map((a) => {
    return { ...a, recommended: true };
  });

  // query "all actions" (jira)
  const { data: allActionsData, loading: allActionsLoading } = useQuery(AUTOMATION_FUNCTIONS_QUERY, {
    variables: {
      search: searchString,
      labels: labels,
      cloudVendor: cloudVendors,
      pageSize: pageSize,
      pageNumber: pageNumber,
      sortBy: 'name',
      sortDirection: 'ASC',
      isDisabled: false
    }
  });
  const { automationFunctions: allActionsResults } = allActionsData || {};
  const { nodes: allActionNodes, pageInfo } = allActionsResults || {};
  const { total, current, size } = pageInfo || {};
  const allActions = allActionNodes?.map((a) => {
    return {
      ...a,
      recommended: recommendedActions?.some((f) => f.id === a.id) || externalActions?.some((f) => f.id === a.id)
    };
  });

  // const nodes = [
  //   ...((pageNumber === 1 && recommendedActions) || []),
  //   ...((pageNumber === 1 && externalActions) || []),
  //   ...(allActions || [])
  // ];
  // .filter((f) => f.name.toLowerCase().includes(searchStringLower) && !f.parameters?.some((p) => !TYPE_SCHEMAS[p.type]));

  return (
    <Root>
      <FilterRow>
        <FilterItem label="Search">
          <SearchFilter
            classes={{ root: 'action-search-input' }}
            search={searchString || ''}
            placeholder="Search available Actions"
            onSearchChange={(str) => {
              setSearchString(str && str.length > 0 ? str : null);
              setPageNumber(null);
            }}
          />
        </FilterItem>
        <FilterItem label="Cloud Vendor">
          <Select
            mode="multiple"
            value={(Array.isArray(cloudVendors) && cloudVendors) || []}
            style={{ minWidth: '200px', maxWidth: '500px' }}
            dropdownMatchSelectWidth={false}
            placeholder={'Select cloud vendor(s)'}
            maxTagCount={2}
            maxTagTextLength={48}
            maxTagPlaceholder={(extraValues) => {
              return <>+&nbsp;{extraValues.length}</>;
            }}
            onChange={(value) => {
              setCloudVendors(value);
            }}
          >
            {['AWS', 'Azure'].map((vendor) => {
              return (
                <Select.Option key={vendor} title={vendor}>
                  {vendor}
                </Select.Option>
              );
            })}
          </Select>
        </FilterItem>
        <FilterItem label="Labels">
          <LabelsFilter
            value={(Array.isArray(labels) && labels) || []}
            onChange={(value) => {
              setLabels(value);
            }}
            query={UNIQUE_FUNCTION_LABELS_QUERY}
            getLabelsRootFromQueryResult={(data) => data && data.uniqueFunctionLabels}
          />
        </FilterItem>
      </FilterRow>

      <QueryResult
        loading={recommendedActionsLoading || externalActionsLoading || allActionsLoading}
        loadingCenterVertically={false}
        loadingHeight={200}
      >
        {() => {
          return (
            <>
              <Collapse style={{ marginBottom: '16px' }}>
                {(!pageNumber || pageNumber === 1) &&
                  recommendedActions?.map((automationFunction, idx) => {
                    return automationFunctionCollapse(automationFunction, `recommended${idx}`, onSelectAction);
                  })}
                {(!pageNumber || pageNumber === 1) && (
                  <>
                    {externalActions?.map((automationFunction, idx) => {
                      return automationFunctionCollapse(automationFunction, `external${idx}`, onSelectAction);
                    })}
                    <Divider />
                  </>
                )}
                {allActions?.map((automationFunction, idx) => {
                  return automationFunctionCollapse(automationFunction, `all${idx}`, onSelectAction);
                })}
              </Collapse>

              <Pagination
                style={{ marginBottom: '16px' }}
                hideOnSinglePage
                current={current}
                pageSize={size}
                total={total}
                onChange={(pageNumber) => {
                  setPageNumber(pageNumber);
                }}
              />
            </>
          );
        }}
      </QueryResult>
    </Root>
  );
}

export default SelectFunction;

import styled from 'styled-components';

export const EnableSwitchDiv = styled.div`
  display: flex;
  align-content: flex-start;

  .message {
    margin-left: 0.5rem;
    transform: translateY(-3px);
  }
`;

import React, { useEffect, useState } from 'react';
import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';
import { User } from 'typings';
import PushProvider from 'components/util/Push/PushProvider';
import AuthenticatedRouter from './AuthenticatedRouter';
import { Redirect, RouteComponentProps } from 'react-router';
import MainNav from 'components/app/MainNav/MainNav';
import { AppMain } from './AppMain.styles';
import OpsSection from '../Ops/OpsSection/OpsSection';
import { Route, Switch } from 'react-router-dom';
import { AutomationActivity, CompliancePage, ProjectManagerPage } from '../Authenticated/pages';
import DevScreen from './pages/Dev';
import { DetectorsSection } from '../Detectors';
import { GuardrailsSection } from './pages/Guardrails';
import SignOutPage from './pages/SignOut';
import OrganizationSettings from './pages/OrganizationSettings';
import QueryString from 'components/util/QueryString';
import { testModalPath, setAnalytics } from './AppMain.helpers';
import { useLocation, useHistory } from 'react-router-dom';
import ModalsRouter from './components/ModalsRouter';
import { EventsSection } from './pages/Events';
import InventorySection from './pages/Inventory/InventorySection/InventorySection';
import CloudAccountsSection from './pages/CloudAccounts/CloudAccountsSection/CloudAccountsSection';
import UserNav from 'components/app/UserNav/UserNav';
import Settings from './pages/Settings';
import Dashboard from '../Dashboard/Dashboard';
import Issues from '../Issues/Issues';
import PostureMonitoring from '../PostureMonitoring/PostureMonitoring';
import ThreatMonitoring from '../ThreatMonitoring/ThreatMonitoring';
import Alerts from '../Alerts/Alerts';
import Automation from '../Automation/Automation';

interface Props {
  user: User;
}

function AuthenticatedSection(props: Props) {
  const { user } = props,
    location = useLocation(),
    history = useHistory(),
    isModal = testModalPath(location.pathname),
    [previousLocation, setPreviousLocation] = useState(''),
    featureFlags = useFeatureFlag([Feature.USE_CASE_NAV, Feature.GOVERNANCE_GUARDRAILS]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (testModalPath(location.pathname)) {
      setPreviousLocation({
        ...location,
        // set background view to dashboard if modal opens with fresh page.
        pathname: '/'
      });
    }

    setAnalytics(location);
  }, []);

  // TODO: translate functionality from AuthenticatedRouter's componentWillUpdate

  return (
    <PushProvider>
      {featureFlags[Feature.USE_CASE_NAV] ? (
        <AppMain>
          <MainNav user={user} />
          <UserNav />
          <div className="page-main">
            <QueryString history={history} location={isModal ? previousLocation : location}>
              <Switch>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/issues:status?" component={Issues} />
                <Route path="/posture-monitoring" component={PostureMonitoring} />
                <Route path="/threat-monitoring" component={ThreatMonitoring} />
                <Route path="/alerts" component={Alerts} />
                <Route path="/automation" component={Automation} />

                {/* TODO: display getting started when needed */}
                {/* TODO: restrict this route to isDisruptOpsUser */}
                <Route path="/dev/:screen?" component={DevScreen} />

                <Route
                  path="/compliance"
                  render={() => (user.client?.settings?.complianceEnabled ? <CompliancePage /> : <Redirect to="/" />)}
                />
                <Route path="/detectors" component={DetectorsSection} />
                <Route path="/ops" component={OpsSection} />

                {featureFlags[Feature.GOVERNANCE_GUARDRAILS] && (
                  <Route path="/guardrails/:tab?" component={GuardrailsSection} />
                )}

                <Route path="/events" component={EventsSection} />
                <Route path="/projects/:id?" component={ProjectManagerPage} />
                <Route path="/inventory" component={InventorySection} />
                <Route path="/cloud-accounts" component={CloudAccountsSection} />
                <Route path="/activity" component={AutomationActivity} />
                <Route path="/organization-settings/:section?" component={OrganizationSettings} />
                <Route path="/settings" component={Settings} />
                <Route path="/login" render={() => <Redirect to="/" />} />
                <Route path="/logout" component={SignOutPage} />
                <Route path="/" component={Dashboard} />
              </Switch>
            </QueryString>

            {isModal && (
              <Route children={(routeProps) => <ModalsRouter {...routeProps} previousLocation={previousLocation} />} />
            )}
          </div>
        </AppMain>
      ) : (
        <Route children={(routeProps: RouteComponentProps) => <AuthenticatedRouter {...routeProps} user={user} />} />
      )}
    </PushProvider>
  );
}

export default AuthenticatedSection;

import React from 'react';
import { Select } from 'antd';
import { ROLE_LIST_QUERY } from '../../../gql';
import { useQuery } from 'react-apollo';

interface Props {
  value: string;
  onChange: (e: string) => any | void;
  onBlur: (e: any) => any | void;
}

function RoleSelect(props: Props) {
  const { value, onChange, onBlur } = props;

  const { loading, error, data } = useQuery(ROLE_LIST_QUERY, {
    variables: {
      perPage: 50
    }
  });

  const items = data?.roleList?.items || [];

  return (
    <Select
      value={value}
      placeholder="Select a role"
      disabled={error ? true : false}
      onChange={onChange}
      onBlur={onBlur}
      size="large"
      loading={loading}
    >
      {items.map((role) => {
        return (
          <Select.Option key={role.id} value={role.id}>
            {role.name}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default RoleSelect;

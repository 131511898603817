import * as Yup from 'yup';
import { GuardrailFilterDefinition } from '.';
import ProjectScopeFilterInput from '../components/ProjectScopeFilterInput';
import ProjectScopeCollapseTitleReadView from 'components/project/ProjectScopeCollapseTitleReadView';

const issueProjectPathMatchesScope: GuardrailFilterDefinition = {
  key: 'issue_project_path_includes_project_ids', // This needs to match what's in the governance eventSources file.
  schema: Yup.array().of(Yup.string()).min(1),
  required: false,
  configurationInput: ProjectScopeFilterInput,
  getCollapseTitle: (value: any) => 'Filter Issues by Project(s):',
  collapseTitleReadView: ProjectScopeCollapseTitleReadView
};

export default issueProjectPathMatchesScope;

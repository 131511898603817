import { FormRenderProps } from 'components/ui/Form/Form';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ConfirmationModalState, ContextArgs, OnSelectTriggerFunc } from '../../../OpEditor';
import TriggerContents from './TriggerContents/TriggerContents';
import TriggerHeader from './TriggerHeader/TriggerHeader';

const Wrapper = styled.div`
  border-radius: 4px;
  margin-bottom: 1.5rem;
  background-color: #fff;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);

  .wrapper-padding {
    padding: 1rem;
  }

  .with-border-bottom {
    border-bottom: 1px solid ${(p) => p.theme.grey200};
  }
`;

interface OpTriggerSectionProps {
  event: any;
  contextArgs: ContextArgs;
  onSelectTrigger: OnSelectTriggerFunc;
  isNewOp?: boolean;
  formRenderProps: FormRenderProps;
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
}

function OpTriggerSection(props: OpTriggerSectionProps) {
  const {
      contextArgs: { eventDefinition, isAllFindingsSelected, op },
      onSelectTrigger,
      formRenderProps,
      setConfirmationModal,
      event
    } = props,
    { values, setFieldValue } = formRenderProps,
    [isEditingTrigger, setIsEditingTrigger] = useState(!eventDefinition && !isAllFindingsSelected);

  useEffect(() => {
    setIsEditingTrigger(!eventDefinition && !isAllFindingsSelected);
  }, [eventDefinition, isAllFindingsSelected]);

  return (
    <Wrapper>
      <div className="wrapper-padding with-border-bottom">
        <TriggerHeader
          setConfirmationModal={setConfirmationModal}
          onSelectTrigger={onSelectTrigger}
          isAllFindingsSelected={isAllFindingsSelected}
          eventDefinition={eventDefinition}
          op={op}
        />
      </div>
      {!isEditingTrigger && (
        <div className="wrapper-padding" data-testid="trigger-contents">
          <TriggerContents
            values={values}
            setFieldValue={setFieldValue}
            eventDefinition={eventDefinition}
            eventFromEventConsole={event}
            isAllFindingsSelected={isAllFindingsSelected}
            setConfirmationModal={setConfirmationModal}
          />
        </div>
      )}
    </Wrapper>
  );
}

export default OpTriggerSection;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DopeIcon from 'components/ui/DopeIcon';

import Paper from 'components/ui/Paper';
import { Maybe } from 'typings';

const Root = styled.div`
  margin-bottom: 24px;

  &.header-has-icon {
    .grid-card-header {
      padding-left: 0;
    }
    .grid-card-content {
      padding-left: 44px;
    }
  }

  .grid-card-header {
    min-height: 40px;
    padding: 16px 16px 8px;
    align-items: center;
    display: flex;
  }

  .grid-card-titles-wrap {
  }

  .grid-card-title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
    flex: 1 0 auto;
  }

  .grid-card-title-secondary {
    text-transform: uppercase;
    color: ${p => p.theme.grey400};
  }

  .grid-card-paper {
    border: 1px solid #e8e8e8;
  }

  .grid-card-content {
    padding: 8px 16px 16px;
  }
`;

const IconWrap = styled.div`
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    display: block;
    color: ${p => p.theme.grey400};
  }
`;

const FooterLink = styled(Link)`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e8e8e8;

  svg {
    display: block;
    margin-right: 8px;

    color: ${p => p.theme.grey600};
  }

  &,
  &:hover {
    color: ${p => p.theme.grey800};
  }

  &:hover {
    background-color: ${p => p.theme.grey50};
  }
`;

interface Props {
  title?: React.ReactNode | null;
  secondaryTitle?: Maybe<React.ReactNode>;
  icon?: React.ReactNode;
  action?: React.ReactNode;
  children: React.ReactNode | React.ReactNodeArray;
  footerLink?: string;
  footerLinkLabel?: string;
  elevation?: 0 | 1 | 2 | 3 | 4 | 5;
  rounded?: boolean;

  classes?: {
    root?: string;
    header?: string;
    title?: string;
    body?: string;
    content?: string;
    headerAction?: string;
  };
}

function GridCard(props: Props) {
  const {
    title,
    secondaryTitle,
    icon,
    children,
    action,
    footerLink,
    footerLinkLabel,
    classes = {},
    elevation,
    rounded
  } = props;

  return (
    <Root className={`grid-card ${icon ? 'header-has-icon' : ''} ${classes.root || ''}`}>
      <Paper
        classes={{ root: `grid-card-paper grid-card-body ${classes.body || ''}` }}
        elevation={elevation}
        rounded={rounded}
      >
        {(title || action) && (
          <div className={`grid-card-header ${classes.header || ''}`}>
            {icon && <IconWrap>{icon}</IconWrap>}

            {title && (
              <div className="grid-card-titles-wrap">
                {secondaryTitle && <div className="grid-card-title-secondary">{secondaryTitle}</div>}
                <h2 className={`grid-card-title ${classes.title || ''}`}>{title}</h2>
              </div>
            )}
            {action && <div className={`grid-card-header-action ${classes.headerAction || ''}`}>{action}</div>}
          </div>
        )}
        <div className={`grid-card-content ${classes.content || ''}`}>{children}</div>
      </Paper>

      {footerLink && footerLinkLabel && (
        <FooterLink to={footerLink}>
          <DopeIcon name="ARROW_FORWARD" size={22} />
          <span>{footerLinkLabel}</span>
        </FooterLink>
      )}
    </Root>
  );
}

export default GridCard;

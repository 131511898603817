import React, { useMemo } from 'react';
import { TreeSelect } from 'antd';
import useProjectTree, { ProjectTreeNode } from './useProjectTree';
import { TreeNodeSimpleMode } from 'antd/lib/tree-select/interface';
import ProjectTreeNodeTitle from './ProjectTreeNodeTitle';
import { NodeType } from './types';

interface Props {
  rootProjectId?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
  onBlur?: () => void;
  multiple?: boolean;
  cascade?: boolean;
  placeholder?: string;
}

function ProjectTreeSelect(props: Props) {
  const {
    value,
    onBlur,
    onChange,
    rootProjectId,
    multiple = true,
    cascade = true,
    placeholder = 'Any project'
  } = props;
  const { projectTree, loading, error, getProject } = useProjectTree({
    rootProjectId
  });

  const projectTreeJSON = useMemo(() => JSON.stringify(projectTree), [projectTree]);

  const treeData = useMemo(() => projectTree.map((node) => convertProjectTreeNodeToAntTreeNode(node)), [
    projectTreeJSON
  ]);

  const fullValue = useMemo(
    () =>
      value
        ? (value
            ?.map((projectId) => {
              const project = getProject(projectId);

              if (!project) return;

              return {
                value: projectId,
                label: <ProjectTreeNodeTitle nodeType={NodeType.project}>{project.name}</ProjectTreeNodeTitle>
              };
            })
            .filter((value) => (value ? true : false)) as any[])
        : [],
    [value, projectTreeJSON]
  );

  if (error) {
    return <>Error</>;
  }

  return (
    <TreeSelect
      placeholder={loading ? 'Loading' : placeholder}
      style={{ minWidth: '100px', width: '100%' }}
      dropdownClassName={cascade ? 'cascade-checks' : undefined}
      key={projectTreeJSON}
      treeDefaultExpandAll={true}
      treeData={treeData}
      loading={loading}
      disabled={loading}
      multiple={multiple}
      labelInValue={true}
      value={fullValue}
      onChange={(projectIds: any[]) => {
        onChange && onChange(projectIds.map(({ value }) => value));
      }}
      onBlur={onBlur}
      treeCheckable
      showCheckedStrategy={TreeSelect.SHOW_ALL}
      treeCheckStrictly={true}
    ></TreeSelect>
  );
}

function convertProjectTreeNodeToAntTreeNode(
  projectTreeNode: ProjectTreeNode,
  hasPermission?: (node: ProjectTreeNode) => boolean
): TreeNodeSimpleMode {
  const disabled = hasPermission ? !hasPermission(projectTreeNode) : false;
  const children: TreeNodeSimpleMode[] = [];

  const node: TreeNodeSimpleMode = {
    key: projectTreeNode.project_id,
    title: <ProjectTreeNodeTitle nodeType={NodeType.project}>{projectTreeNode.name}</ProjectTreeNodeTitle>,
    value: projectTreeNode.project_id,
    selectable: false,
    children: children,
    disabled: disabled
  };

  if (projectTreeNode.accounts_list) {
    children.push(
      ...projectTreeNode.accounts_list.map((a) => ({
        key: a.account_id,
        value: a.account_id,
        title: <ProjectTreeNodeTitle nodeType={NodeType.cloudAccount}>{a.nickname || a.name}</ProjectTreeNodeTitle>,
        selectable: false,
        children: [],
        checkable: false,
        disabled
      }))
    );
  }

  if (projectTreeNode.childProjects) {
    children.push(...projectTreeNode.childProjects.map((p) => convertProjectTreeNodeToAntTreeNode(p, hasPermission)));
  }

  return node;
}

export default ProjectTreeSelect;

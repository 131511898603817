import React, { useEffect, useState } from 'react';
import { WrapperDiv } from './ContentWithDetailPane.styles';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';

function ContentWithDetailPane(props) {
  const [detailActive, setDetailActive] = useState(false);

  useEffect(() => {
    if (props.detailPane) {
      setDetailActive(true);
    } else {
      setDetailActive(false);
    }
  }, [props.detailPane]);

  return (
    <WrapperDiv className={detailActive ? 'detail-active' : ''}>
      <div className="table">{props.children}</div>
      <div className={props.fullscreen ? 'detail detail--expanded' : 'detail'}>
        <div className="detail__inner">{props.detailPane}</div>
      </div>
    </WrapperDiv>
  );
}

export default ContentWithDetailPane;

interface CreateButtonProps {
  children: string;
  handler: Function;
}

export function CreateButton(props: CreateButtonProps) {
  return (
    <Button type="primary" className="create-button" onClick={() => props.handler()}>
      <PlusOutlined />
      {props.children}
    </Button>
  );
}

export function HighlightSelectedRow(selectedId) {
  const selectedClassName = 'selected-row',
    element = document.querySelector(`[data-row-key="${selectedId}"]`);

  document.querySelectorAll(`.${selectedClassName}`).forEach((row) => {
    row.classList.remove(selectedClassName);
  });

  element?.classList.add(selectedClassName);
}

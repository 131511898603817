import { Progress, Spin } from 'antd';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-apollo';
import { ProgressColor } from '.';
import ErrorAlert from 'components/ui/ErrorAlert';

const query = gql`
  query classifiedEventCountByEventName($filters: ClassifiedEventStatFilters, $nextKey: String) {
    classifiedEventCountByEventName(filters: $filters, nextKey: $nextKey) {
      duration
      total
      nextKey
      items {
        key
        count
      }
    }
  }
`;

interface Props {}

export default function EventCountByName(props: Props) {
  const { data, error, loading } = useQuery(query);
  const { classifiedEventCountByEventName } = data || {};
  const { items } = classifiedEventCountByEventName || {};

  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const total = items
      ?.sort((a, b) => b.count - a.count)
      .reduce((accumulator, item) => {
        const { count = 0 } = item;
        return accumulator + count;
      }, 0);
    setTotal(total);
  }, [items]);

  return (
    <>
      {loading && <Spin style={{ marginRight: '8px' }} />}

      {error && <ErrorAlert error={error} />}

      {!loading && items?.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Percent</th>
              <th>Count</th>
              <th>Region</th>
            </tr>
          </thead>
          <tbody>
            {items?.map((item) => {
              const { key, count } = item;
              const percent = Number(((count / total) * 100).toFixed(1));

              return (
                <tr key={key}>
                  <td style={{ width: 170 }}>
                    <Progress percent={percent} strokeColor={ProgressColor} status="normal" />
                  </td>
                  <td>{count?.toLocaleString()}</td>
                  <td>{key}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </>
  );
}

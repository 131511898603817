import React, { useState } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { Select } from 'antd';
import { FilterTypeProps } from '../FilterListing/FilterListing';

export enum Environment {
  production = 'Production',
  staging = 'Staging',
  development = 'Development',
  testing = 'Testing/QA'
}

function EnvironmentEditor(props: FilterTypeProps) {
  const { values, filterType } = props,
    [softEdit, setSoftEdit] = useState<SoftEdit>({
      key: filterType.valueKey,
      value: values[filterType.valueKey]
    }),
    onChange = (changes) => {
      setSoftEdit({
        key: filterType.valueKey,
        value: changes
      });
    },
    editor = (
      <Select mode="multiple" onChange={onChange} value={softEdit.value}>
        {Object.entries(Environment).map(([key, value]) => (
          <Select.Option key={key} value={key}>
            {value}
          </Select.Option>
        ))}
      </Select>
    );

  return <FilterRow {...props} softEdit={softEdit} editor={editor} />;
}

export default EnvironmentEditor;

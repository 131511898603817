import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  margin-top: 8px;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  width: 100%;

  > * {
    width: 100%;
  }

  @media (min-width: 600px) {
    width: auto;

    > * {
      width: auto;
    }
  }
`;

const Label = styled.div`
  color: ${p => p.theme.grey500};
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.025em;
`;

export interface Props {
  children: ReactNode;
  label?: string;
  classes?: {
    root?: string;
    label?: string;
    divider?: string;
    content?: string;
  };
}

function FilterItem(props: Props) {
  const { classes = {}, children, label } = props;

  const rootClassess = classes.root || '';
  const contentClasses = classes.content || '';

  return (
    <Root className={`${rootClassess} filter-item`}>
      {label && <Label className={classes.label || ''}>{label}</Label>}
      <Content className={`filter-item-field ${contentClasses}`}>{children}</Content>
    </Root>
  );
}

export default FilterItem;

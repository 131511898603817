import gql from 'graphql-tag';

const REGION_LIST_QUERY = gql`
  query AWSRegionList {
    regions {
      name
    }
  }
`;

export default REGION_LIST_QUERY;

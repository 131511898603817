import gql from 'graphql-tag';

const PROJECT_LIST_QUERY = gql`
  query ProjectList {
    projects {
      items {
        name
        project_id
        client_id
        created
        updated
      }
    }
  }
`;

export default PROJECT_LIST_QUERY;

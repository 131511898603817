import ProjectTreeSelect from 'components/project/ProjectTree/ProjectTreeSelect';
import React from 'react';
import { Maybe } from 'typings';

interface OpContextControlProps {
  projectId?: Maybe<string>;
  onChange: (projectId: string) => void;
}

/**
 * Project determines permissions
 * Project should set the highest level scope that the Op acts within.
 * Triggered events should happen within this context
 */
function OpContextControl(props: OpContextControlProps) {
  const { projectId, onChange } = props;

  const value = projectId ? [projectId] : undefined;
  const rootProjectId = projectId ? projectId : undefined;

  return (
    <div className="project-tree-wrap">
      <ProjectTreeSelect
        cascade={false}
        multiple={false}
        value={value}
        rootProjectId={rootProjectId}
        onChange={(projectIds: string[]) => onChange(projectIds?.length > 0 ? projectIds[0] : '')}
        placeholder="Please select a project"
      />
    </div>
  );
}

export default OpContextControl;

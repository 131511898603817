import styled from 'styled-components';
import { focusShadow } from '../MainNav/MainNav.styles';

export const AdminNavDiv = styled.div`
  color: white;
  position: relative;

  .toggle-trigger {
    border: none;
    border-radius: 0;
    background: transparent;
    cursor: pointer;
    padding: 0;
    width: 100%;

    &:focus {
      outline: none;
      box-shadow: ${focusShadow};
    }
  }

  .contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .caret-up {
    font-size: 0.75rem;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in;
  }

  &.open .caret-up {
    transform: rotate(180deg);
  }

  .admin-links {
    transform: scaleY(0);
    transform-origin: bottom;
    opacity: 0;
    max-height: 0;
    overflow-y: hidden;
    transition: transform 0.2s ease-in, opacity 0.5s ease-in, max-height 0.2s ease-in;

    &.collapsed {
      background-color: #313131;
      position: absolute;
      bottom: 40px;
      left: 0.5rem;
      width: 200px;
      border-radius: 2px;
    }

    a {
      color: ${(p) => p.theme.grey300};
      display: block;
      padding: 0.5rem 1rem;
      &:hover {
        background-color: #3c3c3c;
        color: white;
      }
    }
  }

  &.open .admin-links {
    transform: scaleY(1);
    opacity: 1;
    max-height: 500px;
    &.collapsed {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    }
  }
`;

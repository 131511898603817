import React from 'react';
import styled from 'styled-components';
import { generateSetSchema } from 'components/function/FunctionParameterInputSet';
import { ConfigSetting, Config } from 'typings';
import { Form } from 'components/ui/Form';
import { Button, Popconfirm, message } from 'antd';
import DetailsColumn from './DetailsColumn';

import FunctionParameterInput from 'components/function/FunctionParameterInput';

import { adopt } from 'react-adopt';
import gql from 'graphql-tag';
import { Mutation, MutationFunction } from 'react-apollo';
import { DataProxy } from 'apollo-cache';
import { FetchResult } from 'apollo-link';

const Root = styled.div``;
const Row = styled.div`
  display: flex;
`;
const Column = styled.div`
  flex: 1;
`;
const FormFooter = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 8px;
  }
`;

const CREATE_CONFIG = gql`
  mutation CreateDynamicConfig($input: DynamicConfigurationCreateInput!) {
    createDynamicConfiguration(input: $input) {
      id
      entityId
      entityType
      key
      value
    }
  }
`;

const UPDATE_CONFIG = gql`
  mutation UpdateDynamicConfig($id: ID!, $input: DynamicConfigurationUpdateInput!) {
    updateDynamicConfiguration(dynamicConfigurationId: $id, input: $input) {
      id
      entityId
      entityType
      key
      value
    }
  }
`;

const DELETE_CONFIG = gql`
  mutation DeleteDynamicConfig($id: ID!) {
    deleteDynamicConfiguration(dynamicConfigurationId: $id)
  }
`;

type EntityType = 'PROJECT' | 'ORGANIZATION';

interface Props {
  configSetting: ConfigSetting;
  config: Config | null;
  onCancel: () => void;
  onComplete: () => void;
  entityId: string | null;
  entityType: EntityType;
  mutationUpdateFn?: (cache: DataProxy, context: FetchResult) => void;
  updateCacheOnDeleteFn: (cache: DataProxy, context: FetchResult) => void;
}

interface CreateDynamicConfigInput {
  entityType: EntityType;
  entityId?: string;
  key: string;
  value: any;
}

function DynamicConfigForm(props: Props) {
  const { configSetting, config, entityId: entityIdFromArgs, entityType, onComplete, mutationUpdateFn } = props;
  const { key, description, name } = configSetting;
  const schema = generateSetSchema([configSetting]);
  const init = {};

  const Mutations = adopt({
    createConfig: ({ render }) => (
      <Mutation mutation={CREATE_CONFIG} update={mutationUpdateFn}>
        {fn => render(fn)}
      </Mutation>
    ),
    updateConfig: ({ render }) => (
      <Mutation mutation={UPDATE_CONFIG} update={mutationUpdateFn}>
        {fn => render(fn)}
      </Mutation>
    )
  });

  if (config) init[key] = config.value;

  return (
    <Mutations>
      {(mutations: { [key: string]: MutationFunction }) => (
        <Form
          initialValues={init}
          allowCleanSubmits={false}
          onSubmit={async (values, actions) => {
            try {
              if (config && config.id) {
                const input = {
                  value: values[key]
                };

                await mutations.updateConfig({ variables: { id: config.id, input } });
              } else {
                const entityId = entityIdFromArgs;

                if (!entityId && entityType !== 'ORGANIZATION')
                  throw new Error('A valid value for `entityId` was not provided.');

                const input: CreateDynamicConfigInput = {
                  entityType,
                  key,
                  value: values[key]
                };

                if (entityId) input.entityId = entityId;

                await mutations.createConfig({ variables: { input } });
              }

              message.success('Dynamic Configuration has been updated');

              onComplete();
            } catch (e) {
              message.error('There was a problem updating this Dynamic Configuration');
              actions.setSubmitting(false);
            }
          }}
          validationSchema={schema}
        >
          {formProps => {
            const {
              values,
              setFieldValue,
              setFieldTouched,
              setFieldError,
              errors,
              touched,
              canSubmit,
              isSubmitting
            } = formProps;
            const typedErrors: any = errors;

            return (
              <Root>
                <Row>
                  <Column>
                    <DetailsColumn title={name} description={description || ''} />
                  </Column>
                  <Column>
                    <FunctionParameterInput
                      value={values[key]}
                      parameter={configSetting}
                      fieldName={key}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      setFieldError={setFieldError}
                      validationError={typedErrors[key] !== undefined ? typedErrors[key] : ''}
                      touched={Boolean(touched[key])}
                    />
                  </Column>
                </Row>
                <FormFooter>
                  {config && config.id && (
                    <Mutation mutation={DELETE_CONFIG}>
                      {(deleteConfig, { loading }) => (
                        <Popconfirm
                          title="Clear settings?"
                          onConfirm={async () => {
                            try {
                              await deleteConfig({ variables: { id: config.id } });
                            } catch (e) {
                              message.error('There was an issue removing this dynamic configuration');
                            }
                          }}
                        >
                          <Button type="danger">Clear</Button>
                        </Popconfirm>
                      )}
                    </Mutation>
                  )}
                  <Button onClick={props.onCancel}>Cancel</Button>
                  <Button htmlType="submit" type="primary" disabled={!canSubmit || isSubmitting}>
                    Save
                  </Button>
                </FormFooter>
              </Root>
            );
          }}
        </Form>
      )}
    </Mutations>
  );
}

export default DynamicConfigForm;

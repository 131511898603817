import { Button, message, Table } from 'antd';
import CloudAccountFilter from 'components/app/FilterBar/filters/CloudAccount';
import RegionFilter from 'components/app/FilterBar/filters/Region';
import { FunctionParameterInputSet } from 'components/function';
import { buildSchemaFromAutomationFunction } from 'components/guardrails/GuardrailEditor/sections/action/components/AutomationActionConfigurationInput';
import { CardSection, GridCard } from 'components/ui/Card';
import DopeIcon from 'components/ui/DopeIcon';
import ErrorAlert from 'components/ui/ErrorAlert';
import Form, { FormField } from 'components/ui/Form';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React, { useState, useContext } from 'react';
import { Mutation, Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { ActionResult, ActiveTask } from 'typings';
import { AUTOMATION_FUNCTION_QUERY } from './FunctionDetail';
import FunctionInputSchema from './FunctionInputSchema';
import { useEffect } from 'react';
import ProgressCircular from 'components/ui/ProgressCircular';
import PushContext from 'components/util/Push/PushContext';
import { PUSH_URL } from 'constants/runtimeConfig';

const RUN_AUTOMATION_FUNCTION = gql`
  mutation runFunction($id: String!, $accountId: ID, $regionName: String, $configuration: [OpConfigurationInput]) {
    runFunction(id: $id, accountId: $accountId, regionName: $regionName, configuration: $configuration) {
      taskId
    }
  }
`;

const Root = styled.div`
  .payload-textarea {
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
  }
`;

interface Props extends RouteComponentProps<{ id: string }> {}

interface FunctionTargetState {
  accountId?: string;
  regionName?: string;
  parameters: object;
}

interface TaskResult {
  task: ActiveTask;
  result?: ActionResult;
}

interface TaskResultsState {
  taskResults: Array<TaskResult>;
}

function FunctionRun(props: Props) {
  const automationFunctionId = props.match.params.id;

  const [functionTargetState, setFunctionTargetState] = useState<FunctionTargetState>({
    parameters: {}
  });
  const { accountId, regionName, parameters = {} } = functionTargetState;

  const [taskResultsState, setTaskResultsState] = useState<TaskResultsState>();
  const pushContext = useContext(PushContext);

  useEffect(() => {
    function handleEvent(event: any) {
      const { eventType, message } = event;
      if (eventType !== 'actionTaken') {
        console.log(`Skipping event type ${eventType}, not actionTaken`);
        return;
      }

      const { actionResult } = message;
      const { taskId } = actionResult;
      const { taskResults = [] } = taskResultsState || {};
      const matchingTaskResult = taskResults.find((result) => result.task.taskId === taskId);

      if (!matchingTaskResult) {
        console.log(`No matching task result for taskId: ${taskId}`);
        return;
      }

      console.log('Applying action result to the task result:', { actionResult });
      matchingTaskResult.result = actionResult;

      setTaskResultsState({
        taskResults: [...taskResults]
      });
    }

    pushContext?.eventSource.on('message', handleEvent);
    pushContext?.eventSource.connect(PUSH_URL);

    return function cleanup() {
      pushContext?.eventSource.removeListener('message', handleEvent);
      pushContext?.eventSource.close();
    };
  }, [taskResultsState]);

  const { taskResults = [] } = taskResultsState || {};

  return (
    <Query query={AUTOMATION_FUNCTION_QUERY} variables={{ id: [automationFunctionId] }}>
      {({ data, loading, queryError }) => (
        <QueryResult
          data={data}
          loading={loading}
          loadingMessage="Loading Function"
          error={queryError}
          expectSingleNode={true}
          parseNodes={(data) => data.automationFunctions.nodes}
          entityName="AutomationFunction"
        >
          {(automationFunction) => {
            const { id, name, inputSchema, parameters: inputParameters } = automationFunction;
            return (
              <Root>
                <NeoPage
                  titleBar={
                    <TitleBar
                      icon={<DopeIcon name="FUNCTION" size={20} />}
                      sectionTitle="Functions"
                      sectionTitleLinkTo="/dev/functions"
                      backLinkTo={`/dev/functions/${id}`}
                      backLinkTooltipText="Back to function"
                      title={`Run Function '${name}'`}
                    />
                  }
                >
                  <CenteredContainer>
                    <GridCard>
                      <CardSection label="Target" />
                      <Form initialValues={{}}>
                        {(formValues) => {
                          return (
                            <>
                              <FormField name="accountId" label="Account">
                                {({ name, value, handleChange, handleBlur }) => (
                                  <CloudAccountFilter
                                    onChange={(value) => {
                                      if (accountId !== 'Loading') {
                                        setFunctionTargetState({
                                          accountId: value,
                                          regionName: regionName,
                                          parameters: parameters
                                        });
                                      }
                                    }}
                                    value={accountId}
                                    mode="default"
                                    placeholder="Select an account"
                                  />
                                )}
                              </FormField>
                              <FormField name="regionName" label="Region">
                                {({ name, value, handleChange, handleBlur }) => (
                                  <RegionFilter
                                    onChange={(value) => {
                                      setFunctionTargetState({
                                        accountId: accountId,
                                        regionName: value,
                                        parameters: parameters
                                      });
                                    }}
                                    value={regionName}
                                    mode="default"
                                    placeholder="Select a region"
                                  />
                                )}
                              </FormField>
                            </>
                          );
                        }}
                      </Form>
                    </GridCard>
                    {inputParameters && (
                      <GridCard>
                        <CardSection label="Input Parameters" />
                        <Mutation mutation={RUN_AUTOMATION_FUNCTION}>
                          {(runFunction, { loading: mutationLoading, error: mutationError }) => {
                            const schema = buildSchemaFromAutomationFunction(automationFunction);
                            return (
                              <>
                                {mutationError && <ErrorAlert error={mutationError} />}
                                <Form
                                  enableReinitialize
                                  validationSchema={schema}
                                  initialValues={parameters}
                                  allowCleanSubmits={false}
                                  onSubmit={async (values, actions) => {
                                    try {
                                      if (!accountId && !regionName) {
                                        message.warn('Target account and region are required.');
                                        return;
                                      } else if (!accountId) {
                                        message.warn('Target account is required.');
                                        return;
                                      } else if (!regionName) {
                                        message.warn('Target region is required.');
                                        return;
                                      }

                                      const configuration = Object.entries(values).map(([key, value]) => {
                                        return {
                                          key,
                                          value,
                                          type: 'STATIC'
                                        };
                                      });

                                      const variables = {
                                        id,
                                        accountId: accountId,
                                        regionName: regionName,
                                        configuration: configuration
                                      };

                                      const response: any = await runFunction({ variables });
                                      const activeTask: ActiveTask = response.data.runFunction;
                                      message.success(
                                        `Function started successfully. (Function: ${activeTask.taskId}, Status: ${activeTask.status})`
                                      );
                                      const result: TaskResult = {
                                        task: activeTask
                                      };
                                      taskResults.push(result);
                                      // console.log({ result });
                                      setTaskResultsState({
                                        taskResults
                                      });
                                    } catch (e) {
                                      console.error(e);
                                    } finally {
                                      actions.setSubmitting(false);
                                    }
                                  }}
                                >
                                  {(formRenderProps) => {
                                    const { canSubmit, isSubmitting } = formRenderProps;

                                    return (
                                      <div>
                                        <FunctionParameterInputSet
                                          {...formRenderProps}
                                          showDynamicConfigOptions
                                          parameters={inputParameters}
                                          allowJSONPathInput={false}
                                        />
                                        <div>
                                          <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={!canSubmit || isSubmitting}
                                            loading={isSubmitting}
                                          >
                                            Run Function
                                          </Button>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Form>
                              </>
                            );
                          }}
                        </Mutation>
                      </GridCard>
                    )}
                    {inputSchema && (
                      <GridCard>
                        <CardSection label="Input Schema" />
                        <Mutation mutation={RUN_AUTOMATION_FUNCTION}>
                          {(runFunction, { loading: mutationLoading, error: mutationError }) => {
                            return (
                              <>
                                {mutationError && <ErrorAlert error={mutationError} />}

                                {inputSchema && (
                                  <FunctionInputSchema
                                    disabled={mutationLoading}
                                    submitDisabled={!accountId || !regionName}
                                    inputSchema={inputSchema || {}}
                                    parameters={parameters}
                                    onChange={(value) => {
                                      // console.log({ formData: value.formData });
                                      setFunctionTargetState({
                                        accountId: accountId,
                                        regionName: regionName,
                                        parameters: value.formData
                                      });
                                    }}
                                    onSubmit={async (value) => {
                                      try {
                                        if (!accountId && !regionName) {
                                          message.warn('Target account and region are required.');
                                          return;
                                        } else if (!accountId) {
                                          message.warn('Target account is required.');
                                          return;
                                        } else if (!regionName) {
                                          message.warn('Target region is required.');
                                          return;
                                        }

                                        const variables = {
                                          id,
                                          accountIds: [accountId],
                                          regionNames: [regionName],
                                          parameters: value.formData
                                        };

                                        const response: any = await runFunction({ variables });
                                        const activeTask: ActiveTask = response.data.runAutomationFunction;
                                        message.success(
                                          `Function started successfully. (Function: ${activeTask.taskId}, Status: ${activeTask.status})`
                                        );
                                        const result: TaskResult = {
                                          task: activeTask
                                        };
                                        taskResults.push(result);
                                        // console.log({ result });
                                        setTaskResultsState({
                                          taskResults
                                        });
                                      } catch {
                                        // console.log({ mutationError });
                                      }
                                    }}
                                  />
                                )}
                              </>
                            );
                          }}
                        </Mutation>
                      </GridCard>
                    )}
                    {taskResults && taskResults.length > 0 && (
                      <GridCard>
                        <CardSection label="Results" />
                        <Table
                          className="function-results"
                          bordered
                          dataSource={taskResults}
                          rowKey={(v) => v.task.taskId}
                          columns={[
                            {
                              title: 'Task',
                              dataIndex: 'task',
                              key: 'task',
                              render: (task) => {
                                const { taskId, status = 'Success', createdAt = new Date() } = task;
                                const date = createdAt instanceof Date ? createdAt : new Date(createdAt);
                                const formattedStatus =
                                  status &&
                                  status.toUpperCase().substring(0, 1) + status.toLocaleLowerCase().substring(1);
                                return (
                                  <a target="_blank" rel="noopener noreferrer" href={`/dev/tasks/${taskId}`}>
                                    {formattedStatus} @ {date.toLocaleString()}
                                  </a>
                                );
                              }
                            },
                            {
                              title: 'Result',
                              dataIndex: 'result',
                              key: 'result',
                              render: (result) => {
                                const { createdAt, status, id: actionResultId } = result || {};
                                const date = createdAt && createdAt instanceof Date ? createdAt : new Date(createdAt);
                                const formattedStatus =
                                  status &&
                                  status.toUpperCase().substring(0, 1) + status.toLocaleLowerCase().substring(1);
                                const url = actionResultId
                                  ? `/activity/${actionResultId}`
                                  : automationFunctionId
                                  ? `/activity?automationFunctionId=${automationFunctionId}`
                                  : '/activity';
                                return result ? (
                                  <a target="_blank" rel="noopener noreferrer" href={url}>
                                    {formattedStatus} @ {date.toLocaleString()}
                                  </a>
                                ) : (
                                  <ProgressCircular size="small" />
                                );
                              }
                            }
                          ]}
                        />
                      </GridCard>
                    )}
                  </CenteredContainer>
                </NeoPage>
              </Root>
            );
          }}
        </QueryResult>
      )}
    </Query>
  );
}

export default FunctionRun;

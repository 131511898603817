import { CloudLabelUl } from 'designSystem/CloudLabel/CloudLabel';
import React from 'react';
import { Detector } from 'typings';

interface DetectorLabelProps {
  detector: Detector;
}

function DetectorLabels(props: DetectorLabelProps) {
  const { detector } = props,
    labels: string[] = [];

  if (detector.labels) {
    labels.push(...detector.labels);
  }

  if (detector.assessor?.labels) {
    labels.push(...detector.assessor?.labels);
  }

  const uniqueSortedLabels = labels
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a.localeCompare(b));

  return (
    <CloudLabelUl>
      {uniqueSortedLabels.map((label, i) => (
        <li key={i}>{label}</li>
      ))}
    </CloudLabelUl>
  );
}

export default DetectorLabels;

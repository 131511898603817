import React from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import { SidenavRenderProps } from '../SidenavController';

interface NavItemProps extends SidenavRenderProps {
  icon: React.ReactNode;
  label: string;
  link?: string;
  onClick?: () => void;
}

function NavItem(props: NavItemProps) {
  const { icon, label, link, toggleSidenav, permanent, open, onClick } = props;

  let item = (
    <div className="main-nav-item-body" onClick={onClick}>
      <div className="nav-item-icon-wrap">
        <div className="nav-item-icon">{icon}</div>
      </div>

      <div className="nav-item-label">{label}</div>
    </div>
  );

  if (link) {
    item = (
      <NavLink
        to={link}
        activeClassName="active"
        className="main-nav-item-link"
        onClick={(e) => {
          if (!permanent) toggleSidenav(false);
        }}
      >
        {item}
      </NavLink>
    );
  }

  if (!permanent || (permanent && open)) return item;

  return (
    <Tooltip placement="right" title={label}>
      {item}
    </Tooltip>
  );
}

export default NavItem;

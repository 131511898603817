import React, { useContext } from 'react';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { AutomationActionResult, AutomationFunction, CloudAccount, User, ActiveTask } from 'typings';
import { Query, Mutation } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import JSONTree from 'components/ui/JSONTree';
import { Button, message } from 'antd';
import VerticalTable from 'components/ui/Table/VerticalTable';
import { getProvider } from 'utilities/cloudAccount';
import FeatureFlag, { Feature } from 'components/app/FeatureFlag';
import USER_BY_ID_QUERY from 'queries/userById';
import AuthContext from 'components/app/Auth/AuthContext';

const Root = styled.div`
  border: 1px solid ${(p) => p.theme.grey300};
  margin-bottom: 20px;

  > * {
    border-bottom: 1px solid ${(p) => p.theme.grey300};
  }

  .vt-label-column {
    width: 175px;
    padding-right: 16px;
    text-align: right;
  }

  .table-wrap {
    padding: 12px 0;
  }

  .json-tree-wrap {
    background-color: rgb(238, 238, 238);
    padding: 4px 16px;
    max-height: 400px;
    overflow-y: auto;
  }
`;

const ACCOUNT = gql`
  query AccountForAutomationActivity($id: ID!) {
    account(id: $id) {
      account_id
      name
      nickname
      provider
    }
  }
`;

const AUTOMATION_FUNCTION = gql`
  query AutomationFunctionsForAutomationActivityDetail($id: [String]!) {
    automationFunctions(id: $id) {
      nodes {
        id
        key
        name
        description
        effects
        parameters {
          name
          key
          type
        }
        revertFunction {
          functionId
        }
      }
    }
  }
`;

const REVERT_ACTION = gql`
  mutation revertAutomationActionResult($id: String!) {
    revertAutomationActionResult(id: $id) {
      taskId
    }
  }
`;

interface Props {
  actionResult: AutomationActionResult;
}

function AutomationActivityDetail(props: Props) {
  const { actionResult } = props;

  const authContext = useContext(AuthContext);

  const { functionId, detail, invokedByUsername, issue, taskId } = actionResult;
  const { detailResponse, error, itemKey, sourceEvent } = detail || {};
  const { context } = sourceEvent || {};
  let { region, vendor, accountId, awsAccountId } = context || {};

  if (issue && !sourceEvent) {
    awsAccountId = issue.awsAccountId;
    accountId = issue.accountId;
    region = issue.region;
  }

  let provider = getProvider(vendor);

  let rows: any[] = [];

  if (detailResponse) {
    rows = rows.concat([
      {
        key: 'detailResponse',
        label: 'Response',
        dataIndex: 'detailResponse',
        render: () => {
          return detailResponse;
        }
      }
    ]);
  }

  if (error) {
    rows = rows.concat([
      {
        key: 'error',
        label: 'Error',
        dataIndex: 'error',
        render: () => {
          return JSON.stringify(error);
        }
      }
    ]);
  }

  if (provider || awsAccountId) {
    rows = rows.concat([
      {
        key: 'awsAccountId',
        label: 'Account',
        dataIndex: 'awsAccountId',
        render: () => {
          return (
            <Query query={ACCOUNT} variables={{ id: accountId }} fetchPolicy={'cache-first'}>
              {({ data, loading, error }) => (
                <QueryResult
                  data={data}
                  loading={loading}
                  error={error}
                  loadingHeight="8"
                  loadingCenterVertically={false}
                  size="small"
                >
                  {() => {
                    if (error || !data) {
                      return <>{`${provider} ${awsAccountId}`}</>;
                    }
                    const account: CloudAccount = data.account;
                    if (!account) {
                      return <>{`${provider} ${awsAccountId}`}</>;
                    }
                    const { nickname } = account;
                    provider = account.provider;
                    return <>{`${provider} ${awsAccountId} ${nickname}`}</>;
                  }}
                </QueryResult>
              )}
            </Query>
          );
        }
      }
    ]);
  }

  if (taskId) {
    rows = rows.concat([
      {
        key: 'taskId',
        label: 'Task',
        dataIndex: 'taskId',
        render: () => {
          if (authContext?.isDisruptOpsUser) {
            return (
              <a target="_blank" rel="noopener noreferrer" href={`/dev/tasks/${taskId}`}>
                {taskId}
              </a>
            );
          } else {
            return taskId;
          }
        }
      }
    ]);
  }

  if (invokedByUsername) {
    rows = rows.concat([
      {
        key: 'user',
        label: 'User',
        dataIndex: 'invokedByUsername'
      }
    ]);
  } else if (invokedByUsername) {
    rows = rows.concat([
      {
        key: 'user',
        label: 'User',
        dataIndex: 'createdBy',
        render: () => {
          return (
            <Query query={USER_BY_ID_QUERY} variables={{ id: invokedByUsername }} fetchPolicy={'cache-first'}>
              {({ data, loading, error }) => (
                <QueryResult
                  data={data}
                  loading={loading}
                  error={error}
                  loadingHeight="8"
                  loadingCenterVertically={false}
                  size="small"
                >
                  {() => {
                    if (error || !data) {
                      return <>{invokedByUsername}</>;
                    }
                    const user: User = data.userById;
                    if (!user) {
                      return <>{invokedByUsername}</>;
                    }
                    actionResult.invokedByUsername = user.username;
                    return <>{user.username}</>;
                  }}
                </QueryResult>
              )}
            </Query>
          );
        }
      }
    ]);
  }

  if (region) {
    rows = rows.concat([
      {
        key: 'region',
        label: 'Region',
        dataIndex: 'region',
        render: () => {
          return region;
        }
      }
    ]);
  }

  if (itemKey) {
    rows = rows.concat([
      {
        key: 'itemKey',
        label: 'Item',
        dataIndex: 'itemKey',
        render: () => {
          return itemKey;
        }
      }
    ]);
  }

  const jsonTreeRow = {
    key: 'detail',
    label: 'Detail',
    dataIndex: 'detail',
    render: () => {
      return (
        <div className="json-tree-wrap">
          <JSONTree data={{ taskId, ...actionResult.detail }} depth={1} />
        </div>
      );
    }
  };

  if (!functionId) {
    rows = rows.concat([jsonTreeRow]);

    return (
      <Root>
        <div className="table-wrap">
          <VerticalTable dataSource={actionResult} rows={rows} classes={{ labelCol: 'vt-label-column' }} />
        </div>
      </Root>
    );
  }

  return (
    <Root>
      <FeatureFlag features={[Feature.ACTION_RESULT_UNDO]}>
        {(flags) => (
          <Query query={AUTOMATION_FUNCTION} variables={{ id: [functionId] }} fetchPolicy={'cache-first'}>
            {({ loading, data, error }) => (
              <QueryResult
                loading={loading}
                data={data}
                error={error}
                loadingHeight="8"
                loadingCenterVertically={false}
                size="small"
              >
                {() => {
                  if (!data || error) return null;
                  const func: AutomationFunction = data.automationFunctions.nodes[0];

                  if (!func || !func.revertFunction || !func.revertFunction.functionId) {
                    rows = rows.concat([jsonTreeRow]);

                    return (
                      <div className="table-wrap">
                        <VerticalTable
                          dataSource={actionResult}
                          rows={rows}
                          classes={{ labelCol: 'vt-label-column' }}
                        />
                      </div>
                    );
                  }

                  return (
                    <Query
                      query={AUTOMATION_FUNCTION}
                      variables={{ id: [func.revertFunction.functionId] }}
                      fetchPolicy={'cache-first'}
                    >
                      {({ loading, data, error }) => (
                        <QueryResult
                          loading={loading}
                          data={data}
                          error={error}
                          loadingHeight="8"
                          loadingCenterVertically={false}
                          size="small"
                        >
                          {() => {
                            if (!data || error) return null;
                            const revertFunction: AutomationFunction = data.automationFunctions.nodes[0];

                            if (!revertFunction) return null;

                            const { name: revertFunctionName } = revertFunction;

                            if (flags[Feature.ACTION_RESULT_UNDO]) {
                              rows = rows.concat([
                                {
                                  key: 'undo',
                                  label: 'Undo',
                                  dataIndex: 'undo',
                                  render: (val) => {
                                    return (
                                      <Mutation mutation={REVERT_ACTION}>
                                        {(revertAutomationActionResult, { loading }) => {
                                          return (
                                            <Button
                                              size="small"
                                              disabled={loading}
                                              loading={loading}
                                              onClick={async () => {
                                                const variables = {
                                                  id: actionResult.id
                                                };
                                                const response: any = await revertAutomationActionResult({ variables });
                                                if (response.errors) {
                                                  const errorMessages = response.errors.map((error) => error.message);
                                                  const messages = errorMessages.join('<br />');
                                                  message.error(`Undo action failed with error: ${messages}`);
                                                  return;
                                                } else {
                                                  const dfunction: ActiveTask =
                                                    response.data.revertAutomationActionResult;
                                                  if (authContext?.isDisruptOpsUser) {
                                                    message.success(
                                                      <>
                                                        Action started successfully.{' '}
                                                        <a
                                                          target="_blank"
                                                          rel="noopener noreferrer"
                                                          href={`/dev/tasks/${dfunction.taskId}`}
                                                        >
                                                          Task: {dfunction.taskId}
                                                        </a>
                                                      </>
                                                    );
                                                  } else {
                                                    message.success(
                                                      `Action started successfully. (Task: ${dfunction.taskId})`
                                                    );
                                                  }
                                                }
                                              }}
                                            >
                                              {revertFunctionName}
                                            </Button>
                                          );
                                        }}
                                      </Mutation>
                                    );
                                  }
                                },
                                jsonTreeRow
                              ]);
                            }

                            return (
                              <div className="table-wrap">
                                <VerticalTable
                                  dataSource={actionResult}
                                  rows={rows}
                                  classes={{ labelCol: 'vt-label-column' }}
                                />
                              </div>
                            );
                          }}
                        </QueryResult>
                      )}
                    </Query>
                  );
                }}
              </QueryResult>
            )}
          </Query>
        )}
      </FeatureFlag>
    </Root>
  );
}

export default AutomationActivityDetail;

import React, { useState } from 'react';
import styled from 'styled-components';
import { FormField } from 'components/ui/Form';
import { GuardrailConfigurationInputProps } from '../../trigger/definitions';
import { Input, Button, Modal, Select } from 'antd';
import { FieldArray } from 'formik';
import JSONTree from 'components/ui/JSONTree';

const FilterRoot = styled.div`
  background-color: #f0f0f0;
  padding: 16px 16px 4px 16px;
  margin-bottom: 8px;
  border-radius: 4px;

  h5 {
    font-size: 18px;
    margin-bottom: 16px;
  }
`;

const PathRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
`;

const PatternRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 8px;
`;

interface RemoveFieldButtonProps {
  index: number;
  onRemove: (index: number) => any | void;
  ghost?: boolean;
}

function RemoveFieldButton(props: RemoveFieldButtonProps) {
  const { index, onRemove, ghost = false } = props;
  return (
    <Button
      icon="close"
      style={{ marginTop: '4px' }}
      onClick={() => {
        onRemove(index);
      }}
      type={ghost ? 'ghost' : 'default'}
    />
  );
}

function CustomJSONPathFilterInput(props: GuardrailConfigurationInputProps) {
  const { name, event } = props;

  const [modalState, setModalState] = useState<{
    open: boolean;
    pathIndex: number;
  }>({
    open: false,
    pathIndex: 0
  });

  return (
    <FormField name={name} label="Custom Filters">
      {({ name, value = [] }) => (
        <FieldArray
          name={name}
          render={(arrayHelpers) => (
            <div>
              {value.map((fieldValue, filterIndex) => (
                <div key={filterIndex}>
                  <FilterRoot>
                    <FormField name={`${name}.${filterIndex}`} label="Custom Filters">
                      {({ name, value: filterValue = {}, handleChange: handleFilterChange, handleBlur }) => (
                        <div>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <h5>{`Custom Filter ${filterIndex + 1}`}</h5>
                            <RemoveFieldButton index={filterIndex} onRemove={arrayHelpers.remove} ghost />
                          </div>
                          <PathRoot>
                            <FormField name={`${name}.path`} label="Path">
                              {({ name, value, handleChange, handleBlur }) => {
                                return (
                                  <Input
                                    name={name}
                                    value={value}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ flexBasis: '60%' }}
                                    addonAfter={
                                      event ? (
                                        <>
                                          <button
                                            className="link-button"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();

                                              setModalState({
                                                open: true,
                                                pathIndex: filterIndex
                                              });
                                            }}
                                          >
                                            $.jsonpath
                                          </button>
                                          <Modal
                                            visible={modalState.open ? true : false}
                                            onCancel={() => setModalState({ open: false, pathIndex: 0 })}
                                          >
                                            {event && (
                                              <JSONTree
                                                data={event}
                                                onKeyClick={(path) => {
                                                  handleChange(path.join('.').replace(/^root\./, '$.'));
                                                  setModalState({
                                                    open: false,
                                                    pathIndex: 0
                                                  });
                                                }}
                                                onValueClick={(value, pathArray) => {
                                                  const patterns = filterValue.patterns;
                                                  const updatedPatterns =
                                                    patterns[patterns.length - 1] === ''
                                                      ? patterns.slice(0, -1)
                                                      : [...patterns];

                                                  const updatedFilter = {
                                                    ...filterValue,
                                                    path: pathArray.join('.').replace(/^root\./, '$.'),
                                                    patterns: [...updatedPatterns, value, '']
                                                  };

                                                  handleFilterChange(updatedFilter);
                                                  setModalState({
                                                    open: false,
                                                    pathIndex: 0
                                                  });
                                                }}
                                              />
                                            )}
                                          </Modal>
                                        </>
                                      ) : null
                                    }
                                  />
                                );
                              }}
                            </FormField>
                            <FormField name={`${name}.type`} label="Type">
                              {({ value, handleChange, handleBlur }) => {
                                return (
                                  <Select value={value} onChange={handleChange} onBlur={handleBlur}>
                                    <Select.Option value="STRICT_MATCH">Strict Match</Select.Option>
                                    <Select.Option value="PARTIAL_MATCH">Partial Match</Select.Option>
                                  </Select>
                                );
                              }}
                            </FormField>
                          </PathRoot>
                          <div>
                            <FormField name={`${name}.patterns`} label="Patterns">
                              {({ name, value = [], handleChange, handleBlur }) => {
                                // console.log(value, Array(value.length + 1));
                                return (
                                  <FieldArray
                                    name={name}
                                    render={(arrayHelpers) => (
                                      <div>
                                        {value.map((fieldValue, patternIndex) => {
                                          return (
                                            <PatternRoot key={patternIndex}>
                                              <FormField name={`${name}.${patternIndex}`} label="">
                                                {({ value, handleChange, handleBlur }) => {
                                                  return (
                                                    <Input
                                                      name={name}
                                                      value={value}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      addonBefore={patternIndex + 1}
                                                    />
                                                  );
                                                }}
                                              </FormField>
                                              <RemoveFieldButton index={patternIndex} onRemove={arrayHelpers.remove} />
                                            </PatternRoot>
                                          );
                                        })}
                                        <Button icon="plus" onClick={() => arrayHelpers.push('')}>
                                          Add new pattern
                                        </Button>
                                      </div>
                                    )}
                                  />
                                );
                              }}
                            </FormField>
                          </div>
                        </div>
                      )}
                    </FormField>
                  </FilterRoot>
                </div>
              ))}
              <Button
                icon="plus"
                onClick={() =>
                  arrayHelpers.push({
                    path: '',
                    type: 'STRICT_MATCH',
                    patterns: []
                  })
                }
              >
                Add new path
              </Button>
            </div>
          )}
        />
      )}
    </FormField>
  );
}

export default CustomJSONPathFilterInput;

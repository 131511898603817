import { ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { Op } from 'typings';
import AccountLabelEditor from './AccountLabelEditor/AccountLabelEditor';
import AccountEditor, { formatAccountValues } from './AccoutEditor/AccountEditor';
import AdvancedEditor, { advancedValueFormatter } from './AdvancedEditor/AdvancedEditor';
import EnvironmentEditor from './EnvironmentEditor/EnvironmentEditor';
import ProjectEditor, { getProjectName } from './ProjectEditor/ProjectEditor';
import RegionEditor, { regionDisplay } from './RegionEditor/RegionEditor';
import ResourceTagsEditor from './ResourceTagsEditor/ResourceTagsEditor';
import ResourceTypeEditor from './ResourceTypeEditor/ResourceTypeEditor';
import SeverityEditor from './SeverityEditor/SeverityEditor';

interface FilterOutputProps {
  op: Op;
  filterListing: FilterListing;
  contextData: any;
}

const DisplayList = styled.ul`
  padding-left: 0;
  margin-bottom: 0;

  li {
    list-style-type: none;
  }
`;

export function FilterDisplay(props: FilterOutputProps) {
  const { op, filterListing, contextData } = props;

  function formatValue(val) {
    if (filterListing.valueFormatter) {
      try {
        return filterListing.valueFormatter(val, contextData, op);
      } catch (e) {
        console.log(e);
        return (
          <>
            <ExclamationCircleOutlined /> {e.message}
          </>
        );
      }
    }
    return val;
  }

  return (
    <DisplayList>
      {op[filterListing.valueKey].map((val, i) => (
        <li key={i}>{formatValue(val)}</li>
      ))}
    </DisplayList>
  );
}

export interface FilterType {
  valueKey: string;
  label: string;
}

export interface FilterListing extends FilterType {
  editorComponent: (props) => JSX.Element;
  valueFormatter?: Function;
  sourceWhitelist?: string[];
}

const sourceIds = {
  disruptOpsGovernance: '7cc01081-2260-4176-86ae-092f98cb6aa0',
  cloudWatch: 'eaa12aa4-46e9-4f3c-98a2-2a43671a316b',
  securityHub: '5a228e04-b7b5-4cf1-82c7-ab657ddea0ae',
  awsfilterType: '137e8fe9-c3bb-4e11-8023-708893eeda57'
};

export const FilterEditors: FilterListing[] = [
  {
    valueKey: 'environments',
    label: 'Environments',
    editorComponent: EnvironmentEditor
  },
  {
    valueKey: 'regions',
    label: 'Regions',
    editorComponent: RegionEditor,
    valueFormatter: regionDisplay
  },
  {
    valueKey: 'triggerProjectIds',
    label: 'Projects',
    editorComponent: ProjectEditor,
    valueFormatter: getProjectName
  },
  {
    valueKey: 'accountIds',
    label: 'Accounts',
    editorComponent: AccountEditor,
    valueFormatter: formatAccountValues
  },
  {
    valueKey: 'accountLabels',
    label: 'Account Label',
    editorComponent: AccountLabelEditor
  },
  {
    valueKey: 'severity',
    label: 'Severity',
    editorComponent: SeverityEditor,
    sourceWhitelist: [sourceIds.securityHub, sourceIds.disruptOpsGovernance]
  },
  {
    valueKey: 'resourceType',
    label: 'Resource Type',
    editorComponent: ResourceTypeEditor,
    sourceWhitelist: [
      sourceIds.securityHub,
      sourceIds.disruptOpsGovernance,
      sourceIds.awsfilterType,
      sourceIds.cloudWatch
    ]
  },
  {
    valueKey: 'tags',
    label: 'Resource Tags',
    editorComponent: ResourceTagsEditor,
    sourceWhitelist: [sourceIds.securityHub],
    valueFormatter: (tag) => `${tag?.key}=${tag?.value}`
  },
  {
    valueKey: 'jsonPaths',
    label: 'Advanced',
    editorComponent: AdvancedEditor,
    valueFormatter: advancedValueFormatter
  }
];

export function shouldShowEditor(this: Op, filterType: FilterListing): boolean {
  const values = this;
  // TODO: adapt for edit mode
  //if (inEditMode && newFilterTypes.includes(filterType)) return true; // only show new filter types in edit mode

  return values[filterType.valueKey] && values[filterType.valueKey].length !== 0;
}

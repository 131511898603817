import { useProjectTree } from 'components/project/ProjectTree';
import ProjectChip from 'designSystem/ProjectChip/ProjectChip';
import React, { ReactElement, useState } from 'react';
import { DetectorGeneralProps } from '../../General/General';

function DetectorProject(props: DetectorGeneralProps) {
  const { detector } = props,
    [project, setProject] = useState<ReactElement | undefined>(),
    targetProjectIds = detector.targetProjectIds,
    { allProjects, getProject } = useProjectTree({ onProjectsLoaded });

  function displayTargetProjects() {
    const targetProjects = targetProjectIds?.map(getProject).filter((project) => (project ? true : false)),
      chips = targetProjects?.map((project, i) => {
        return (
          <li key={i}>
            <ProjectChip key={project.project_id} project={project} />
          </li>
        );
      });

    setProject(<ul className="no-bullets">{chips}</ul>);
  }

  function displayOwnerProject() {
    const ownerProject = getProject(detector.projectId);

    setProject(
      <>
        All Projects under <ProjectChip project={ownerProject} />
      </>
    );
  }

  function onProjectsLoaded() {
    if (!allProjects) {
      return;
    }

    if (targetProjectIds && targetProjectIds?.length > 0) {
      displayTargetProjects();
    } else {
      displayOwnerProject();
    }
  }

  return <span>{project}</span>;
}

export default DetectorProject;

import React from 'react';

import { Button, Card, Icon } from 'antd';

function ArrayFieldTemplate(props) {
  const { canAdd, items, onAddClick, title, schema, disabled } = props;
  const { items: schemaItems } = schema || {};

  let itemTitle = schemaItems && schemaItems['$ref'];
  if (itemTitle && itemTitle.startsWith('#/definitions/')) {
    itemTitle = itemTitle.substring('#/definitions/'.length);
  }

  return (
    <Card
      key={props.key}
      size="small"
      title={title}
      extra={
        canAdd && (
          <Button disabled={disabled} size="small" onClick={onAddClick} title={`Add ${itemTitle}`}>
            <Icon type="plus" />
          </Button>
        )
      }
    >
      {items &&
        items.map(element => (
          <Card
            key={element.key}
            size="small"
            title={itemTitle}
            extra={
              <Button
                disabled={disabled}
                size="small"
                title={`Delete ${itemTitle}`}
                onClick={element.onDropIndexClick(element.index)}
              >
                <Icon type="delete" />
              </Button>
            }
          >
            {element.children}
          </Card>
        ))}
    </Card>
  );
}

export default ArrayFieldTemplate;

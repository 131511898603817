import React from 'react';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Table, Tooltip } from 'antd';

import { CloudAccount } from 'typings';

import { TableWrap } from 'components/ui/Table';
import { ProvisionExistingCloudAccount } from './expanded-rows';
import DopeIcon from 'components/ui/DopeIcon';
import { Typography } from 'antd';
import { CloudVendorIcon } from 'components/cloudVendors';
import { IconNames } from 'components/ui/DopeIcon/DopeIcon';
import { useQuery } from 'react-apollo';
import DateTime from 'components/ui/DateTime';
import ErrorAlert from 'components/ui/ErrorAlert';
import EditCloudAccount from './expanded-rows/EditCloudAccount';
import Space from 'components/ui/Space';
import RefreshCloudAccountInventoryButton from './RefreshCloudAccountInventoryButton';
import RoundButton from './RoundButton';

const { Text } = Typography;

export const CLOUD_ACCOUNT_ENVIRONMENT_MAP = {
  production: 'Production',
  staging: 'Staging',
  development: 'Development',
  testing: 'Testing/QA'
};

enum InventoryHealth {
  Healthy = '1',
  HealthyUpdating = '2',
  Unhealthy = '3',
  UnhealthyUpdating = '4',
  Unknown = '5'
}

interface AccountInventoryStatus {
  account_id: string;
  account_name: string;
  last_refresh_started_date: string;
  last_refresh_success_date: string;
  last_refresh_errored_date: string;
  latest_date: Date;
  latest_status: string;
  latest_success: Date;
  latest_failure: Date;
  status: string; // let's try to combine this with latest_status; redundant
  health: InventoryHealth;
}

const INVENTORY_HEALTH_TEMPLATES: {
  [key: string]: {
    tooltipTitle: (status: AccountInventoryStatus) => string;
    color: string;
    icon: IconNames;
  };
} = {
  [InventoryHealth.Healthy]: {
    tooltipTitle: (status) => `Healthy. Last finished ${status?.latest_success}`,
    color: '#FFC300',
    icon: 'INVENTORY_HEALTHY'
  },
  [InventoryHealth.HealthyUpdating]: {
    tooltipTitle: (status) => `Healthy and Updating. Last finished ${status?.latest_success}`,
    color: '#FFC300',
    icon: 'INVENTORY_HEALTHY'
  },
  [InventoryHealth.Unhealthy]: {
    tooltipTitle: (status) => `Unhealthy. Last failed ${status?.latest_failure}`,
    color: '#FFC300',
    icon: 'INVENTORY_UNHEALTHY'
  },
  [InventoryHealth.UnhealthyUpdating]: {
    tooltipTitle: (status) => `Unhealthy and Updating. Last failed ${status?.latest_failure}`,
    color: '#FFC300',
    icon: 'INVENTORY_UNHEALTHY'
  }
};

const CLOUD_ACCOUNT_LIST_QUERY = gql`
  query CloudAccountQuery {
    accounts {
      items {
        account_id
        assumerole_account_id
        assumerole_arn
        assumerole_external_id
        client_id
        created
        name
        nickname
        policy_code
        provider
        provision_url
        provisioner_arn
        provisioner_external_id
        updated
        project_id
        environment
        labels
        project {
          project_id
          name
          description
        }
      }
    }

    account_inventory_status {
      size
      from
      results {
        account_id
        account_name
        last_refresh_started_date
        last_refresh_success_date
        last_refresh_errored_date
        status
      }
    }
  }
`;

const ENVIRONMENTS_QUERY = gql`
  query ClientEnvironments {
    ClientEnvironments @rest(type: "ClientEnvironments", path: "/api/v2/accounts/environments/", method: "GET") {
      environments
    }
  }
`;

interface Props {
  expandedRowKeys: string[];
  expandedRowType: 'provision' | 'edit';
  onProvisionCloudAccount: (cloudAccountId: string) => void;
  onEditCloudAccount: (cloudAccountId: string) => void;
  onCloseAll: () => void;
}

function CloudAccountsTable({
  expandedRowKeys,
  expandedRowType,
  onProvisionCloudAccount,
  onEditCloudAccount,
  onCloseAll
}: Props) {
  const { loading: accountsLoading, error: accountsError, data: accountsData } = useQuery(CLOUD_ACCOUNT_LIST_QUERY);
  const accountInventoryStatuses = accountsData?.account_inventory_status?.results ?? [];
  sortStatuses(accountInventoryStatuses);

  const { loading: environmentsLoading, error: environmentsError, data: environmentsData } = useQuery(
    ENVIRONMENTS_QUERY
  );
  const environments = environmentsData?.ClientEnvironments?.environments ?? [];

  const clientEnvironments =
    environmentsData &&
    environments.reduce((acc, val) => {
      acc[val] = val;
      return acc;
    }, {});

  const allEnvironments = { ...clientEnvironments, ...CLOUD_ACCOUNT_ENVIRONMENT_MAP };

  const accounts = accountsData?.accounts?.items?.sort((a, b) => {
    const aName = `${a.nickname} ${a.assumerole_account_id}`;
    const bName = `${b.nickname} ${b.assumerole_account_id}`;
    return aName.toLowerCase().localeCompare(bName.toLowerCase(), undefined, { sensitivity: 'base' });
  });

  return accountsError || environmentsError ? (
    <ErrorAlert error={accountsError || environmentsError} />
  ) : (
    <TableWrap>
      <Table
        pagination={false}
        locale={{ emptyText: 'No cloud accounts have been registered' }}
        loading={accountsLoading || environmentsLoading}
        dataSource={accounts?.map((a) => ({ ...a, key: a.account_id })) ?? []}
        columns={[
          {
            key: 'provider',
            dataIndex: 'provider',
            title: 'Provider',
            render: (_, account: CloudAccount) => {
              return <CloudVendorIcon vendor={account.provider || ''} />;
            }
          },
          {
            key: 'account',
            dataIndex: 'account_id',
            title: 'Account',
            render: (_, account: CloudAccount) => {
              return (
                <div>
                  <div>{account.nickname}</div>
                  <div>
                    <Text type="secondary">{account.assumerole_account_id}</Text>
                  </div>
                </div>
              );
            }
          },
          {
            key: 'environment',
            dataIndex: 'environment',
            title: 'Environment',
            render: (environment) => (environment && allEnvironments[environment]) || '--'
          },
          {
            key: 'project',
            dataIndex: 'project_id',
            title: 'Project',
            render: (_, account: CloudAccount) => {
              if (!account.project) return null;

              return (
                <Link to={`/projects/${account.project_id}`}>
                  {account.project.name || `Project: ${account.project.project_id}`}
                </Link>
              );
            }
          },
          {
            key: 'policy_code',
            dataIndex: 'policy_code',
            title: 'Policy',
            align: 'center'
          },
          {
            key: 'current-health',
            dataIndex: 'account_id',
            title: 'Health',
            align: 'center',
            render: (accountId, account: CloudAccount) => {
              const status = accountInventoryStatuses?.find((i) => i.account_id === accountId) || undefined;
              if (!status) return null;

              if (account.provision_url) {
                return (
                  <RoundButton
                    text="This account still needs to be provisioned"
                    tooltipPlacement="top"
                    icon="WARNING"
                    onClick={() => {
                      if (account.account_id) {
                        onProvisionCloudAccount(account.account_id);
                      }
                    }}
                  />
                );
              }

              const healthTemplates = status?.health && INVENTORY_HEALTH_TEMPLATES[status.health];
              if (!healthTemplates) return null;

              return (
                <Tooltip title={healthTemplates.tooltipTitle(status)} placement="left">
                  <DopeIcon name={healthTemplates.icon} size={20} color={healthTemplates.color} />

                  {[InventoryHealth.HealthyUpdating, InventoryHealth.UnhealthyUpdating].includes(status.health) && (
                    <DopeIcon name="INVENTORY_UPDATING" color="#229954" size={20} style={{ marginLeft: '4px' }} />
                  )}
                </Tooltip>
              );
            }
          },
          {
            key: 'last-refreshed',
            dataIndex: 'account_id',
            title: 'Freshness',
            render: (account_id) => {
              const status = accountInventoryStatuses?.find((i) => i.account_id === account_id) || undefined;

              if (!status) return null;

              const lastStarted = status.last_refresh_started_date ? new Date(status.last_refresh_started_date) : null;
              const lastFinished = status.last_refresh_success_date ? new Date(status.last_refresh_success_date) : null;
              const lastFailed = status.last_refresh_errored_date ? new Date(status.last_refresh_errored_date) : null;

              const lastStartedTooltip = lastStarted
                ? `Inventory refresh last started: ${lastStarted.toLocaleString()}`
                : 'Inventory refresh has never started.';
              const lastFinishedTooltip = lastFinished
                ? `Inventory refresh last finished: ${lastFinished.toLocaleString()}`
                : 'Inventory refresh has never finished.';
              const lastFailedTooltip = lastFailed
                ? `Inventory refresh last failed: ${lastFailed.toLocaleString()}`
                : 'Inventory refresh has never failed.';

              return (
                <div>
                  {lastStarted && (
                    <Tooltip title={lastStartedTooltip} placement="left">
                      <div>
                        Last Started: <DateTime dateTime={lastStarted || ''} />
                      </div>
                    </Tooltip>
                  )}

                  {lastFinished && (
                    <Tooltip title={lastFinishedTooltip} placement="left">
                      <div>
                        Last Finished: <DateTime dateTime={lastFinished || ''} />
                      </div>
                    </Tooltip>
                  )}

                  {lastFailed && (
                    <Tooltip title={lastFailedTooltip} placement="left">
                      <div>
                        Last Failed: <DateTime dateTime={lastFailed || ''} />
                      </div>
                    </Tooltip>
                  )}
                </div>
              );
            }
          },
          {
            key: 'actions',
            dataIndex: 'account_id',
            title: 'Actions',
            render: (_account_id, account: CloudAccount) => {
              return (
                <Space direction="horizontal" size={8}>
                  {account.provision_url && (
                    <RoundButton
                      text="Provision this account."
                      tooltipPlacement="left"
                      icon="PROVISION"
                      iconSize={18}
                      onClick={() => {
                        if (account.account_id) {
                          onProvisionCloudAccount(account.account_id);
                        }
                      }}
                    />
                  )}

                  <RefreshCloudAccountInventoryButton cloudAccount={account} />

                  <RoundButton
                    text="Edit this account."
                    tooltipPlacement="left"
                    icon="EDIT"
                    iconSize={16}
                    onClick={() => {
                      onEditCloudAccount(account.account_id);
                    }}
                  />
                </Space>
              );
            }
          }
        ]}
        expandedRowKeys={expandedRowKeys}
        expandedRowRender={(item, idx) => {
          if (expandedRowType === 'provision')
            return <ProvisionExistingCloudAccount onComplete={onCloseAll} cloudAccount={item} />;
          if (expandedRowType === 'edit')
            return (
              <EditCloudAccount
                cloudAccount={item}
                environments={allEnvironments}
                allCloudAccounts={accountsData?.accounts?.items || []}
                onClose={onCloseAll}
              />
            );
          return null;
        }}
      />
    </TableWrap>
  );
}

function getLatestDate(status: AccountInventoryStatus): Date {
  const FAILED = 'Failed';
  const STARTED = 'Started';
  const FINISHED = 'Finished';

  const statusDates = [
    {
      date: new Date(status.last_refresh_errored_date),
      status: FAILED
    },
    {
      date: new Date(status.last_refresh_started_date),
      status: STARTED
    },
    {
      date: new Date(status.last_refresh_success_date),
      status: FINISHED
    }
  ];

  statusDates.sort((a, b) => {
    return b.date.getTime() - a.date.getTime();
  });

  const current = statusDates[0];
  const previous = statusDates[1];

  status.latest_date = new Date(current.date);
  status.latest_status = current.status;
  status.latest_success = new Date(status.last_refresh_success_date);
  status.latest_failure = new Date(status.last_refresh_errored_date);

  if (current.status === FAILED) {
    status.health = InventoryHealth.Unhealthy;
  } else if (current.status === STARTED) {
    status.health = previous.status === FINISHED ? InventoryHealth.HealthyUpdating : InventoryHealth.UnhealthyUpdating;
  } else if (current.status === FINISHED) {
    status.health = InventoryHealth.Healthy;
  }

  return new Date(current.date);
}

function sortStatuses(statuses: AccountInventoryStatus[]) {
  statuses.sort((a, b) => {
    return getLatestDate(a).getTime() - getLatestDate(b).getTime();
  });
}

export default CloudAccountsTable;

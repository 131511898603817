import styled from 'styled-components';

export const SearchBarDiv = styled.div`
  display: flex;
  align-items: flex-start;
  border: 1px solid ${(p) => p.theme.grey200};
  background: white;
  padding: 0.25rem;
  border-radius: 4px;
  margin-bottom: 1.5rem;

  & > * {
    margin-right: 1rem;
  }

  .ant-btn {
    border-radius: 4px;
  }

  input {
    border: 1px solid ${(p) => p.theme.grey400};
    border-radius: 2px;
  }

  .filter-frame {
    position: relative;
    min-width: 150px;
  }
`;

export const FilterWrapperSection = styled.section`
  border: 1px solid ${(p) => p.theme.grey400};
  border-radius: 4px;
  padding: 0.5rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fafafa;

  .header {
    display: flex;
    justify-content: space-between;
  }

  h1 {
    font-size: 16px;
  }

  .cancel {
    border: none;
    background: none;
    cursor: pointer;
    transform: translateY(-2px);
  }

  .footer {
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
  }

  .ok {
    border: none;
    background: none;
    cursor: pointer;
    color: green;
  }
`;

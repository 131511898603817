import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { Button, Dropdown, Icon, Menu, message, Modal } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import { useAuthorizor } from 'components/app/Auth/Authorizor';
import usePaginator from 'components/app/Paginator/usePaginator';
import ButtonWithDisabledTooltip from 'components/buttons/ButtonWithDisabledTooltip';
import DateTime from 'components/ui/DateTime';
import Panel from 'components/ui/Panel';
import Space from 'components/ui/Space';
import { TableHeader, TableHeaderActions } from 'components/ui/Table';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { PageInfo, Recipient } from 'typings';
import {
  RecipientIcon,
  RecipientLabels,
  RecipientTypes,
  RECIPIENT_TYPE_DEFINITIONS
} from '../../../../../../../../components/recipients/common';
import { ConfirmationModalState, RecipientUsage } from './RecipientUsage';

const Root = styled.div`
  .link {
    color: #4a4a4a;
  }

  .table-header-actions {
    flex: 1;

    &_content {
      justify-content: flex-end;
    }
  }

  .ant-pagination {
    padding: 16px;
    margin: 0;
    display: flex;
    justify-content: flex-end;
  }

  tr.ant-table-row:nth-child(even) {
    background-color: transparent;
  }

  .ant-table-title {
    padding: 0;
  }
`;

const RecipientOption = styled.div`
  display: flex;
  align-items: center;

  .recipient-select-icon-wrap {
    margin-right: 8px;
  }
`;

const RECIPIENTS_QUERY = gql`
  query RecipientsForNotifications(
    $type: [RecipientTypes]
    $id: String
    $search: String
    $from: Int = 0
    $size: Int = 25
  ) {
    recipients(type: $type, id: $id, search: $search, from: $from, size: $size) {
      from
      size
      total
      items {
        id
        name
        type
        config
        notificationId
        createdAt
        createdBy
        updatedAt
        updatedBy
        isEnabled
        lastUsedAt
        lastErrorAt
        lastError
      }
    }
  }
`;

const DELETE_RECIPIENT = gql`
  mutation DeleteRecipient($id: String!) {
    deleteRecipient(id: $id)
  }
`;

interface Props extends RouteComponentProps {
  recipientDetailRoutePrefix?: string;
}

function RecipientsTable(props: Props) {
  const { history } = props;

  const { isAuthorized, message: authErrorMessage } = useAuthorizor(Permissions.MODIFY_NOTIFICATIONS, '*');
  const [deleteRecipient] = useMutation(DELETE_RECIPIENT);
  const [confirmationModalState, setConfirmationModalState] = useState<ConfirmationModalState | null>(null);
  const [canDelete, setCanDelete] = useState<boolean>(false);

  const { search, pageNumber, pageSize, updatePagination } = usePaginator({
    defaultPageSize: 100,
    defaultSortDirection: 'ASC'
  });

  const from = (pageNumber - 1) * pageSize;
  const variables = { search, from, size: pageSize };

  const { loading, error, data, refetch } = useQuery(RECIPIENTS_QUERY, { variables });

  function handleMenuClick(e) {
    const { key } = e;
    history.push(`/organization-settings/notifications/recipients/new/${key}`);
  }

  const supportedRecipientTypeDefinitions = RECIPIENT_TYPE_DEFINITIONS.sort((a, b) => {
    return a.label?.localeCompare(b.label, undefined, { sensitivity: 'base' });
  });

  return (
    <Root>
      <QueryResult loading={loading} data={data} error={error}>
        {() => {
          const { recipients: recipientsData } = data;
          const { size, total, items } = recipientsData;
          const pageInfo: PageInfo = {
            current: from / pageSize + 1,
            size,
            total
          };

          const recipients = items
            .filter((item) => supportedRecipientTypeDefinitions.findIndex((s) => s.key === item.type) !== -1)
            .sort((a, b) => {
              const labelA =
                RecipientTypes[a.type] &&
                RecipientLabels[a.type] &&
                `${RecipientTypes[a.type]} ${RecipientLabels[a.type](a)} ${a.id}`;
              const labelB =
                RecipientTypes[b.type] &&
                RecipientLabels[b.type] &&
                `${RecipientTypes[b.type]} ${RecipientLabels[b.type](b)} ${b.id}`;
              if (labelA < labelB) {
                return -1;
              }
              if (labelA > labelB) {
                return 1;
              }
              return 0;
            });

          const columns: ColumnProps<Recipient>[] = [
            {
              title: 'Type',
              key: 'type',
              dataIndex: 'type',
              render: (type, recipient) => {
                return (
                  <Link className="link" to={`/organization-settings/notifications/recipients/${recipient.id}/edit`}>
                    <RecipientIcon recipient={recipient} size={12} /> {RecipientTypes[type]}
                  </Link>
                );
              }
            },
            {
              title: 'Name',
              key: 'name',
              dataIndex: 'name',
              render: (name, recipient) => {
                // trim down the nasty, automatically generated names
                const regexes = [
                  / \[\S{36}\]/gm,
                  /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/gm,
                  /,*\s*https:\/\/outlook\.office\.com\/webhook\S*\//gm,
                  /,*\s*https:\/\/hooks\.slack\.com\/services\S*\/\S*/gm,
                  /^jira: /gm,
                  /^slack: /gm,
                  /^ms_teams: /gm,
                  /^sns: /gm
                ];
                let trimmed = name;
                regexes.map((regex) => (trimmed = trimmed.replace(regex, '')));
                return (
                  <Link className="link" to={`/organization-settings/notifications/recipients/${recipient.id}/edit`}>
                    {trimmed}
                  </Link>
                );
              }
            },
            {
              title: 'Details',
              key: 'config',
              dataIndex: 'config',
              render: (_config, recipient) => {
                const { type } = recipient;
                return (
                  <Link className="link" to={`/organization-settings/notifications/recipients/${recipient.id}/edit`}>
                    {type && RecipientLabels[type] && RecipientLabels[type](recipient)}
                  </Link>
                );
              }
            },
            {
              title: 'Created',
              key: 'createdAt',
              dataIndex: 'createdAt',
              render: (createdAt) => {
                return <DateTime dateTime={createdAt} />;
              }
            },
            {
              title: 'Updated',
              key: 'updatedAt',
              dataIndex: 'updatedAt',
              render: (updatedAt) => {
                return <DateTime dateTime={updatedAt} />;
              }
            },
            {
              title: 'Last Used',
              key: 'lastUsedAt',
              dataIndex: 'lastUsedAt',
              render: (lastUsedAt) => {
                return <DateTime dateTime={lastUsedAt} />;
              }
            },
            {
              title: 'Last Error',
              key: 'lastErrorAt',
              dataIndex: 'lastErrorAt',
              render: (lastErrorAt, recipient) => {
                return <DateTime dateTime={lastErrorAt} appendTitle={` - ${recipient.lastError}`} />;
              }
            },
            {
              title: '',
              key: 'actions',
              render: (_, recipient) => {
                const { id } = recipient;
                return (
                  <Space direction="horizontal" size={8}>
                    <ButtonWithDisabledTooltip
                      title="Delete Recipient"
                      disabled={!isAuthorized}
                      disabledMessage={authErrorMessage || ''}
                      tooltipPlacement="left"
                      icon="delete"
                      onClick={() => {
                        setCanDelete(false);
                        setConfirmationModalState({
                          title: 'Delete Recipient',
                          children: <RecipientUsage recipientId={recipient.id} setCanDeleteFunc={setCanDelete} />,
                          onConfirm: async () => {
                            try {
                              const result = await deleteRecipient({
                                variables: { id: recipient.id }
                              });

                              if (result.data?.deleteRecipient === true) {
                                message.success('Recipient was successfully deleted');
                              } else {
                                throw new Error('Could not delete Recipient');
                              }
                            } catch (e) {
                              message.error('There was a problem deleting Recipient');
                            } finally {
                              setConfirmationModalState(null);
                              refetch(variables);
                            }
                          }
                        });
                      }}
                    />

                    <Button
                      icon="edit"
                      title="Edit Recipient"
                      onClick={() => {
                        history.push(`/organization-settings/notifications/recipients/${id}/edit`);
                      }}
                    />
                  </Space>
                );
              }
            }
          ];

          return (
            <Panel rounded>
              <Table
                rowKey="id"
                size="small"
                columns={columns}
                loading={loading}
                dataSource={recipients}
                locale={{ emptyText: 'No recipients found' }}
                rowClassName={() => 'table-row'}
                title={() => (
                  <TableHeader>
                    <TableHeaderActions
                      classes={{ root: 'table-header-actions', content: 'table-header-actions_content' }}
                    >
                      <Dropdown
                        overlay={
                          <Menu onClick={handleMenuClick}>
                            {supportedRecipientTypeDefinitions?.map((recipientType) => {
                              return (
                                <Menu.Item key={recipientType.key}>
                                  <RecipientOption className="recipient-option">
                                    <span className="recipient-select-icon-wrap">
                                      <RecipientIcon recipient={{ type: recipientType.key }} size={12} />{' '}
                                      {recipientType.label}
                                    </span>
                                  </RecipientOption>
                                </Menu.Item>
                              );
                            })}
                          </Menu>
                        }
                      >
                        <Button style={{ marginLeft: '8px', marginBottom: '8px' }}>
                          Create Recipient Connection <Icon type="down" />
                        </Button>
                      </Dropdown>
                    </TableHeaderActions>
                  </TableHeader>
                )}
                onChange={(pagination, filters, sorter, a) => {
                  updatePagination({
                    // sortBy: SORT_BY_MAP[sorter.columnKey] || 'updated_at',
                    // sortDirection: SORT_ORDER_MAP[sorter.order] || SORT_ORDER_MAP.descend,
                    pageNumber: pagination.current || 1
                  });
                }}
                pagination={{
                  pageSize,
                  current: pageInfo && pageInfo.current,
                  total: pageInfo && pageInfo.total,
                  onChange: (pageNumber) => {
                    updatePagination({
                      pageNumber
                    });
                  }
                }}
              />
            </Panel>
          );
        }}
      </QueryResult>
      <Modal
        visible={Boolean(confirmationModalState)}
        onOk={confirmationModalState ? confirmationModalState.onConfirm : undefined}
        okButtonProps={{ disabled: !canDelete }}
        title={confirmationModalState ? confirmationModalState.title : null}
        children={confirmationModalState ? confirmationModalState.children : null}
        onCancel={() => setConfirmationModalState(null)}
      />
    </Root>
  );
}

export default RecipientsTable;

import React, { SFC } from 'react';
import styled from 'styled-components';
import { Select as AntSelect } from 'antd';

import { InputOuter, ValidationErrorMessage } from 'components/ui/Input';

import SelectSubTitle from './components/SelectSubTitle';
import SelectTitle from './components/SelectTitle';

const Option = AntSelect.Option;

const Upper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
`;

type SelectModes = 'default' | 'multiple' | 'tags' | 'combobox';

interface SelectProps {
  title?: string;
  subTitle?: string;
  name: string;
  selectOptions: any[];
  placeholder?: string;
  mode?: SelectModes;
  value?: string | string[];
  keyed?: boolean;
  marginTop?: string;
  height?: string;
  setFieldValue: (fieldName: string, value: any) => any;
  setFieldTouched: (fieldName: string, isTouched: boolean) => any;
  allowClear?: boolean;
  error?: string;
}

const Select: SFC<SelectProps> = ({
  title,
  subTitle,
  placeholder,
  selectOptions,
  mode = 'default',
  name,
  value,
  setFieldValue,
  setFieldTouched,
  keyed,
  marginTop = '30px',
  height,
  allowClear,
  error,
  ...rest
}) => {
  return (
    <InputOuter marginTop={marginTop} height={height}>
      <Upper>
        <SelectTitle>{title}</SelectTitle>
        <ValidationErrorMessage>{error}</ValidationErrorMessage>
      </Upper>
      <AntSelect
        style={{ width: '100%' }}
        placeholder={placeholder}
        size="large"
        disabled={!selectOptions}
        mode={mode}
        className={name}
        notFoundContent={''}
        allowClear={allowClear}
        defaultValue={value as any}
        onChange={(newValueArray: any[]) => {
          setFieldValue(name, newValueArray);
        }}
        value={value as any}
        onBlur={() => setFieldTouched(name, true)}
      >
        {selectOptions &&
          selectOptions.map((item: any, n: number) => {
            if (keyed) {
              return (
                <Option key={item.key} value={item.key}>
                  {item.value}
                </Option>
              );
            }
            return (
              <Option key={n.toString()} value={item}>
                {item}
              </Option>
            );
          })}
      </AntSelect>
      <SelectSubTitle>{subTitle}</SelectSubTitle>
    </InputOuter>
  );
};

export default Select;

import { History, Location } from 'react-router-dom';
import React from 'react';

export interface ReplaceURLSearchParamsOptions {
  pathname?: string;
}

interface Context {
  search: string;
  urlSearchParams?: URLSearchParams;
  cloneURLSearchParams: () => URLSearchParams;
  replaceURLSearchParams: (
    updatedURLSearchParams: URLSearchParams,
    options?: ReplaceURLSearchParamsOptions
  ) => any | void;
  qs: {
    [key: string]: string[];
  };
  location?: Location;
  history?: History;
}

const QueryStringContext = React.createContext<Context>({
  cloneURLSearchParams: () => new URLSearchParams(),
  replaceURLSearchParams: () => void 0,
  search: '',
  qs: {}
});

export default QueryStringContext;

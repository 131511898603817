import React, { useEffect, useState } from 'react';
import { Op } from 'typings';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import Badge from 'designSystem/Badge/Badge';
import { Root } from './Activity.styles';
import DopeIcon from 'components/ui/DopeIcon';
import { subWeeks } from 'date-fns';
import DelayedLoader from 'designSystem/DelayedLoader/DelayedLoader';
import { CSSTransitionGroup } from 'react-transition-group';
import { BadgeGroupDiv } from 'designSystem/Badge/Badge.styles';

export const AUTOMATION_ACTION_RESULTS = gql`
  query OpActivtyStatusCount($opId: String, $start: DateTime) {
    opActivityStatusCount(opId: $opId, start: $start) {
      start
      results {
        status
        count
      }
    }
  }
`;

interface ActivityProps {
  op: Op;
}

interface Tally {
  success: number;
  error: number;
  pending: number;
  timeout: number;
}

function OpDetailActivity(props: ActivityProps) {
  const {
      op: { id }
    } = props,
    aWeekAgo = subWeeks(new Date().toDateString(), 1),
    [tally, setTally] = useState<Tally>({} as Tally),
    [noActivity, setNoActivity] = useState(false),
    [showBadges, setShowBadges] = useState(false),
    { error, loading } = useQuery(AUTOMATION_ACTION_RESULTS, {
      variables: { opId: id, startDateTime: aWeekAgo },
      onCompleted: countActivities
    });

  function addSuccess(a = 0, b = 0) {
    return a + b;
  }

  function countActivities(data) {
    if (data.opActivityStatusCount.results.length === 0) {
      setNoActivity(true);
      return;
    }

    const indexed = data.opActivityStatusCount.results.reduce((prev, current) => {
        prev[current.status] = current.count;
        return prev;
      }, {}),
      updatedTally: Tally = {
        success: 0,
        error: 0,
        timeout: 0,
        pending: 0
      };

    updatedTally.success = addSuccess(indexed.SUCCESS, indexed.NOOP);
    updatedTally.error = indexed.ERROR || 0;
    updatedTally.timeout = indexed.TIMEOUT || 0;
    updatedTally.pending = indexed.PENDING || 0;

    setTally(updatedTally);
    setShowBadges(true);
  }

  useEffect(() => {
    setShowBadges(false);
    setNoActivity(false);
    setTally({} as Tally);
  }, [id]);

  return (
    <Root>
      <div className="height-wrapper">
        {loading && <DelayedLoader />}
        {error && <div>Could not load activity</div>}
        <CSSTransitionGroup transitionName="activity" transitionEnterTimeout={200} transitionLeaveTimeout={100}>
          {noActivity && <div key="no-activity">no activity</div>}
          {showBadges && (
            <BadgeGroupDiv>
              <div className="badge-group__item" key="success">
                <Badge modifier="success" count={tally.success} />
                <div className="badge-group__item-label">success</div>
              </div>
              <div className="badge-group__item" key="error">
                <Badge modifier="error" count={tally.error} />
                <div className="badge-group__item-label">error</div>
              </div>
              <div className="badge-group__item" key="timeout">
                <Badge modifier="warning" count={tally.timeout} />
                <div className="badge-group__item-label">timeout</div>
              </div>
              <div className="badge-group__item" key="pending">
                <Badge modifier="pending" count={tally.pending} />
                <div className="badge-group__item-label">pending</div>
              </div>
            </BadgeGroupDiv>
          )}
        </CSSTransitionGroup>
      </div>

      <Link
        className="activity-link"
        to={{
          pathname: '/activity',
          search: `opId=${id}`
        }}
      >
        <DopeIcon name="ACTIVITY" size={16} /> View All Related Activity
      </Link>
    </Root>
  );
}

export default OpDetailActivity;

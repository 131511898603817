export { default as EventsStats } from './EventsStats';

function isOdd(num) {
  return num % 2;
}

export const ProgressColor = '#673ab7';

// purple color palette from https://ant.design/docs/spec/colors
export const ChartColors = ['#b37feb', '#9254de', '#722ed1', '#531dab', '#391085', '#22075e'].reverse();

export function getChartColor(index: number) {
  const length = ChartColors.length;

  // constrain index to ChartColors count
  const constrainedIndex = index % (length - 1);

  if (isOdd(constrainedIndex)) {
    return ChartColors[length - constrainedIndex];
  } else {
    return ChartColors[constrainedIndex];
  }
}

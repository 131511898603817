import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Root = styled.div`
  padding: 4px 8px;
  background-color: ${p => p.theme.grey50};
  border: 1px solid ${p => p.theme.grey300};

  &,
  .meta-row-link {
    display: flex;
    flex: 1;
    color: ${p => p.theme.grey600};

    &:focus {
      text-decoration: none;
    }
  }

  &.clickable {
    &:hover {
      background-color: #fff;
      border-color: ${p => p.theme.grey500};
      border-radius: 3px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }
  }

  & + .meta-row {
    margin-top: -1px;
  }

  .meta-row-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }
`;

interface Props {
  icon?: React.ReactNode;
  children: React.ReactNode;
  to?: string;
  onClick?: () => void;
  classes?: {
    root?: string;
    icon?: string;
  };
}

function MetaRow(props: Props) {
  const { icon, children, classes = {}, onClick, to } = props;
  const rootClasses = `meta-row ${onClick || to ? 'clickable' : ''} ${classes.root || ''}`;

  const content = (
    <>
      {icon && <div className={`meta-row-icon ${classes.icon}`}>{icon}</div>}

      {children}
    </>
  );

  return (
    <Root className={rootClasses} onClick={onClick}>
      {to ? (
        <Link className="meta-row-link" to={to}>
          {content}
        </Link>
      ) : (
        content
      )}
    </Root>
  );
}

export default MetaRow;

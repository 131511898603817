import memoize from 'fast-memoize';
import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Table, Button } from 'antd';
import { CloudAccount, Project, FilterDefinition } from 'typings';
import createObjectMap from 'utilities/createObjectMap';
import { getResultPropertyNameFromQuery } from 'utilities/gql';
import cloudResourceTypesConfig, { ResourceTypeConfigItem } from 'components/cloudResources/cloudResourceTypesConfig';
import EmptyState from 'components/ui/EmptyState';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import { TableWrap } from 'components/ui/Table';
import QueryResult from 'components/util/QueryResult';
import FilterBar from 'components/app/FilterBar';
import Paginator from 'components/app/Paginator';
import { Root } from './styledComponents';
import ResourcesEmptyState from './components/EmptyState';
import ResourceTypeFilter from './components/ResourceTypeFilter';
import SearchFilter from 'components/app/FilterBar/filters/Search';
import { PROJECT_LIST_QUERY, CLOUD_ACCOUNT_LIST_QUERY, AWS_REGION_LIST_QUERY, AWS_TAG_LIST_QUERY } from 'queries';
import { uuidRegex } from 'constants/regularExpressions';
import DopeIcon from 'components/ui/DopeIcon';
import { rawToDashed } from 'utilities/resourceType';

const DEFAULT_PAGE_SIZE = 20;

export const FILTERS: FilterDefinition[] = [
  {
    name: 'projectId',
    label: 'Project',
    qsKey: 'project',
    many: true,
    query: PROJECT_LIST_QUERY,
    getListFromData: (data) => data.projects.items,
    transformDataToOption: (dataItem) => ({
      key: dataItem.project_id,
      label: dataItem.name
    }),
    transformValueToLabel: (lookupMap) => (value) => {
      const project = lookupMap[value] || {};
      return project.name || value;
    },
    validateFilterTextValue: (value) => value.length === 36 && uuidRegex.test(value)
  },
  {
    name: 'accountId',
    label: 'Account',
    qsKey: 'account',
    many: true,
    query: CLOUD_ACCOUNT_LIST_QUERY,
    getListFromData: (data) => data.accounts.items,
    transformDataToOption: (dataItem) => ({
      key: dataItem.account_id,
      label: dataItem.nickname || dataItem.name
    }),
    transformValueToLabel: (lookupMap) => (value) => {
      const account = lookupMap[value] || {};

      return account.nickname || account.name || value;
    },
    validateFilterTextValue: (value) => value.length === 36 && uuidRegex.test(value)
  },
  {
    name: 'region',
    label: 'Region',
    qsKey: 'region',
    many: true,
    variableName: () => 'dops_regions',
    query: AWS_REGION_LIST_QUERY,
    getListFromData: (data) => data.regions,
    transformDataToOption: (dataItem) => ({
      key: dataItem.name,
      label: dataItem.name
    }),
    transformValueToLabel: (lookupMap) => (value) => {
      return value;
    }
  },
  {
    name: 'tag',
    label: 'Tag',
    qsKey: 'tag',
    many: true,
    variableName: () => 'tags',
    query: AWS_TAG_LIST_QUERY,
    clientFiltered: true,
    getListFromData: (data) => data.tags,
    transformDataToOption: (dataItem) => ({
      key: btoa(JSON.stringify([dataItem.key, dataItem.value])),
      label: `${dataItem.key}:${dataItem.value}`
    }),
    transformValueToVariable: (value) => {
      if (!value) return value;

      try {
        const tagArr = JSON.parse(atob(value));
        return {
          key: tagArr[0],
          value: tagArr[1]
        };
      } catch (e) {
        return {};
      }
    },
    transformValueToLabel: (lookupMap) => (value) => {
      const json = atob(value);
      const tagArray = JSON.parse(json);

      return `${tagArray[0]}:${tagArray[1]}`;
    },
    validateFilterTextValue: memoize((value) => {
      try {
        const jsonStr = atob(value);
        const tagArr = JSON.parse(jsonStr);

        return tagArr && Array.isArray(tagArr) && tagArr.length === 2 ? true : false;
      } catch (e) {
        return false;
      }
    })
  }
];

export const QUERY = gql`
  query ProjectsAndAccounts {
    projects {
      items {
        name
        project_id
        accounts_list {
          account_id
        }
        created
        updated
      }
    }

    accounts {
      items {
        account_id
        assumerole_account_id
        provision_url
        name
        nickname
        updated
      }
    }
  }
`;

interface ResourceConfigBag {
  resourceSpecificVars: any;
  queryName: string;
  query: any;
}

interface Props extends RouteComponentProps<any> {}

class Inventory extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.getResourceBagFromConfig = this.getResourceBagFromConfig.bind(this);
    this.getAccountIdsFromProjects = this.getAccountIdsFromProjects.bind(this);
  }

  getResourceType() {
    const { match } = this.props;

    const qsResourceType = match && match.params && match.params.resourceType ? match.params.resourceType : '';

    const resourceType = qsResourceType ? qsResourceType.replace(/-/g, '::') : '';

    return resourceType;
  }

  getResourcesConfig(type?: string): ResourceTypeConfigItem {
    if (!type) {
      //Only use base filters if we don't specify allResources explicitly
      return { ...cloudResourceTypesConfig.allResources, filters: [] };
    }

    return type && cloudResourceTypesConfig[type]
      ? cloudResourceTypesConfig[type]
      : cloudResourceTypesConfig.allResources;
  }

  getResourceBagFromConfig(type: string, filterValues: any): ResourceConfigBag {
    const resourceConfig = this.getResourcesConfig(type);

    const resourceSpecificVars = resourceConfig.tableQueryVariables
      ? resourceConfig.tableQueryVariables(type, filterValues)
      : {};

    const query = resourceConfig.tableQuery(type);

    const queryName = getResultPropertyNameFromQuery(query);

    return {
      resourceSpecificVars,
      queryName,
      query // graphql query
    };
  }

  getFilters(resourceFilters: FilterDefinition[] = []) {
    const filters = FILTERS.concat(resourceFilters);
    return filters;
  }

  getAccountIdsFromProjects(projectIds: string[], projectsHash: any) {
    const accountIds: string[] = [];
    for (const projectId of projectIds) {
      if (!projectsHash[projectId]) continue;

      const project = projectsHash[projectId];
      const projectAccountIds = project.accounts_list?.map((item) => item.account_id) || [];
      if (projectAccountIds) {
        for (const accountId of projectAccountIds) {
          accountIds.push(accountId);
        }
      }
    }
    return accountIds;
  }

  getVariables({ search, pageFrom, pageNumber, pageSize, resourceType, filters, filterValues, projectsHash }: any) {
    const resourceQueryDefinition = this.getResourceBagFromConfig(resourceType, filterValues);

    const projectIds = Array.isArray(filterValues.projectId) ? filterValues.projectId : [filterValues.projectId];

    // build an array of all of the account ids assigned to each project in the filter bar
    const accountIdsFromProjects: string[] = this.getAccountIdsFromProjects(projectIds, projectsHash);

    const accountIds = Array.isArray(filterValues.accountId) ? filterValues.accountId : [filterValues.accountId];

    const allAccountIds = filterValues.accountId ? accountIdsFromProjects.concat(accountIds) : accountIdsFromProjects;

    const filterVariables = filters.reduce((filterVariables, filter: FilterDefinition) => {
      const filterValue = filterValues[filter.name];

      if (!filterValue) return filterVariables;
      const filterValueArray = Array.isArray(filterValue) ? filterValue : [filterValue];

      if (!filter.variableName) return filterVariables;
      const variableName = filter.variableName();
      const variableValue = filter.transformValueToVariable
        ? filterValueArray.map(filter.transformValueToVariable)
        : filterValueArray;

      filterVariables[variableName] = filter.many ? variableValue : variableValue[0];

      return filterVariables;
    }, {});

    const variables = {
      from: pageFrom,
      size: pageSize,
      page: pageNumber,
      dops_full_text: search,
      ...filterVariables,
      dops_internal_account_ids: allAccountIds.length === 0 ? null : allAccountIds,
      ...resourceQueryDefinition.resourceSpecificVars
    };

    return variables;
  }

  render() {
    const { history, location } = this.props;
    const resourceType = this.getResourceType();
    const isOriginalInventoryPath = location.pathname.match(/^\/inventory/) ? true : false;
    const inventoryPath = isOriginalInventoryPath ? '/inventory/resources' : '/posture-monitoring/inventory';
    const titleBar = isOriginalInventoryPath ? (
      <TitleBar
        sectionTitle={'Inventory'}
        sectionTitleLinkTo={'/inventory'}
        title="Resources"
        icon={<DopeIcon name={'INVENTORY'} size="22" />}
      />
    ) : null;

    return (
      <NeoPage titleBar={titleBar}>
        <Root>
          <CenteredContainer size="fluid">
            <Query query={QUERY} fetchPolicy="cache-and-network">
              {({ loading, error, data }) => {
                return (
                  <QueryResult loading={loading} error={error}>
                    {() => {
                      if (!data.accounts || !data.accounts.items || !data.accounts.items.length) {
                        return (
                          <EmptyState
                            title="It appears you don't have any cloud accounts registered"
                            message="In order to view your cloud resources you will need to provision a cloud account."
                            actions={<Link to="/cloud-accounts/provision-account">Provision cloud accounts</Link>}
                          />
                        );
                      }

                      const projectsHash: {
                        [id: string]: Project;
                      } = createObjectMap(data.projects.items, 'project_id');

                      const cloudAccounts: CloudAccount[] = data.accounts.items;
                      const accountsHash: {
                        [id: string]: CloudAccount;
                      } = createObjectMap(cloudAccounts, 'account_id');

                      const resourceConfig = this.getResourcesConfig(resourceType);

                      return (
                        <Paginator filters={this.getFilters(resourceConfig.filters)} pageSize={DEFAULT_PAGE_SIZE}>
                          {({
                            search,
                            filterValues,
                            filters,
                            pageNumber,
                            pageFrom,
                            pageSize,
                            updatePage,
                            pushFilters
                          }) => {
                            const resourceQueryDefinition = this.getResourceBagFromConfig(resourceType, filterValues);

                            const variables = this.getVariables({
                              search,
                              pageNumber,
                              pageFrom,
                              pageSize,
                              projectsHash,
                              resourceType,
                              filters,
                              filterValues
                            });

                            return (
                              <>
                                <FilterBar
                                  history={history}
                                  location={location}
                                  search={search}
                                  filters={filters}
                                  projectsHash={projectsHash}
                                  accountsHash={accountsHash}
                                  filterValues={filterValues}
                                  onFilterChange={(filterName, filterValue) => {
                                    pushFilters(filterName, filterValue);
                                  }}
                                >
                                  {({ search, filterValues, handleFilterChange, handleSearchChange }) => (
                                    <>
                                      <ResourceTypeFilter
                                        size="large"
                                        value={resourceType}
                                        onChange={(resourceType) => {
                                          history.push(
                                            `${inventoryPath}/${rawToDashed(resourceType)}${location.search}`
                                          );
                                        }}
                                      />

                                      <SearchFilter
                                        size="large"
                                        filters={filters}
                                        search={search}
                                        onSearchChange={(searchString) => {
                                          handleSearchChange(searchString);
                                        }}
                                      />

                                      <Button
                                        size="large"
                                        type="primary"
                                        style={{
                                          borderTopLeftRadius: 0,
                                          borderBottomLeftRadius: 0
                                        }}
                                      >
                                        Search
                                      </Button>
                                    </>
                                  )}
                                </FilterBar>

                                <TableWrap>
                                  <Query
                                    query={resourceQueryDefinition.query}
                                    variables={variables}
                                    fetchPolicy="network-only"
                                  >
                                    {({ networkStatus, loading, error, data }) => {
                                      return (
                                        <QueryResult loading={networkStatus === 1} error={error} data={data}>
                                          {() => {
                                            const queryResults =
                                              data[resourceQueryDefinition.queryName] ||
                                              data[Object.keys(data)[0]] ||
                                              {};

                                            const { results: resources, total_count } = queryResults;

                                            // I have no way of knowing if any accounts are provisioned
                                            return !resources || !resources.length ? (
                                              <ResourcesEmptyState search={search} filterValues={filterValues} />
                                            ) : (
                                              <Table
                                                loading={loading}
                                                columns={resourceConfig.tableColumnsConfig}
                                                dataSource={resources.map((resource) => ({
                                                  ...resource,
                                                  account: accountsHash[resource.dops_internal_account_id] // map to cloud account.
                                                }))}
                                                rowKey={(resource) => {
                                                  if (resourceConfig && resourceConfig.rowKey) {
                                                    return resource[resourceConfig.rowKey];
                                                  }

                                                  const {
                                                    dops_internal_account_id,
                                                    dops_resource_type,
                                                    dops_resource_id,
                                                    dops_resource_uid
                                                  } = resource;

                                                  if (dops_resource_uid) {
                                                    return dops_resource_uid;
                                                  }

                                                  return `${dops_internal_account_id}.${dops_resource_type}.${dops_resource_id}`;
                                                }}
                                                rowClassName={() => 'cursor-pointer'}
                                                pagination={{
                                                  current: pageNumber,
                                                  total: total_count,
                                                  pageSize: pageSize,
                                                  hideOnSinglePage: true,
                                                  onChange: (page: number) => updatePage(page)
                                                }}
                                                onRow={(item) => {
                                                  return {
                                                    onClick: (e: any) => {
                                                      const resourceType = item.dops_resource_type.replace(/::/g, '-');
                                                      const resourceUid = item.dops_resource_uid;
                                                      const url = `${inventoryPath}/${resourceType}/${resourceUid}`;
                                                      history.push(url);
                                                    }
                                                  };
                                                }}
                                              />
                                            );
                                          }}
                                        </QueryResult>
                                      );
                                    }}
                                  </Query>
                                </TableWrap>
                              </>
                            );
                          }}
                        </Paginator>
                      );
                    }}
                  </QueryResult>
                );
              }}
            </Query>
          </CenteredContainer>
        </Root>
      </NeoPage>
    );
  }
}

export default Inventory;

import React, { useState } from 'react';
import styled from 'styled-components';
import { MutationFunction } from 'react-apollo';

import { NewGuardrail } from 'typings';

import DynamicConfigProvider from 'components/dynamicConfig/DynamicConfigProvider';

import TRIGGER_EVENT_SOURCE_DEFINITIONS, {
  GuardrailTriggerEventTypeDefinition,
  findTriggerEventTypeDefinition
} from './sections/trigger/definitions';
import GuardrailTriggerSection from './sections/trigger/GuardrailTriggerSection';
import GuardrailFiltersSection from './sections/filter/GuardrailFiltersSection';
import GuardrailActionsSection from './sections/action/GuardrailActionsSection';

const Root = styled.div`
  .grid-card {
    position: relative;

    &.has-arrow {
      &:before {
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        height: 19px;
        width: 2px;
        background-color: ${p => p.theme.grey500};
      }

      &:after {
        display: block;
        content: '';
        position: absolute;
        left: 50%;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid ${p => p.theme.grey500};
        top: calc(100% + 18px);
        margin-left: -5px;
      }
    }
  }

  .grid-card-header {
    margin-bottom: 8px;
  }

  .grid-card-content {
    padding: 0;
  }

  .ant-collapse-content,
  .grid-card-empty-content {
    padding: 8px 16px 16px;
  }

  .ant-collapse-item {
    border-bottom: 1px solid ${p => p.theme.grey200};

    &:first-child {
      border-top: 1px solid ${p => p.theme.grey200};
    }
    &:last-child {
      border-bottom: none;
    }
  }
  .header-has-icon .grid-card-content {
    padding-left: 0;
  }
`;

/**
 * 1. select event source
 */

export enum GuardrailEditorStep {
  NONE = 'NONE',
  SELECT_EVENT_SOURCE = 'SELECT_EVENT_SOURCE',
  SELECT_EVENT_TRIGGER = 'SELECT_EVENT_TRIGGER',
  CONFIGURE_TRIGGER = 'CONFIGURE_TRIGGER',
  CONFIGURE_FILTERS = 'CONFIGURE_FILTERS',
  SELECT_ACTION = 'SELECT_ACTION',
  CONFIGURE_ACTION = 'CONFIGURE_ACTION'
}

function getInitialStepFromGuardrail(guardrail: NewGuardrail): GuardrailEditorStep {
  // Does guardrail have trigger?
  if (!guardrail.trigger) {
    if (TRIGGER_EVENT_SOURCE_DEFINITIONS.length > 1) return GuardrailEditorStep.SELECT_EVENT_TRIGGER;

    // default to DISRUPTOPS_ISSUES
    const eventSourceDefinition = TRIGGER_EVENT_SOURCE_DEFINITIONS[0];

    if (eventSourceDefinition.eventTypes.length > 1) return GuardrailEditorStep.SELECT_EVENT_TRIGGER;

    // does trigger have... an input?
    return GuardrailEditorStep.CONFIGURE_TRIGGER;
  }

  if (!guardrail.functionId && !guardrail.automationFunctionId) return GuardrailEditorStep.SELECT_ACTION;

  return GuardrailEditorStep.NONE;
}

function getInitialExpandedFilterKey(
  activeStep: GuardrailEditorStep,
  guardrail: NewGuardrail,
  eventTypeDefinition: GuardrailTriggerEventTypeDefinition | null
): string | null {
  if (activeStep !== GuardrailEditorStep.CONFIGURE_FILTERS) return null;
  if (!eventTypeDefinition) return null;
  if (!eventTypeDefinition.filters) return null;

  const requiredFilter = eventTypeDefinition.filters.find(filter => {
    if (filter.required) {
      if (!guardrail.filtersConfiguration || !guardrail.filtersConfiguration[filter.key]) return true;
    }

    return false;
  });

  return requiredFilter ? requiredFilter.key : null;
}

interface GuardrailEditorProps {
  guardrail: NewGuardrail;
  saveGuardrailMutationFn: MutationFunction;
  triggeringEvent?: any;
}

export interface GuardrailEditorState extends GuardrailEditorProps {
  activeStep: GuardrailEditorStep;
  setActiveStep: (step: GuardrailEditorStep) => void;
  eventTypeDefinition: GuardrailTriggerEventTypeDefinition | null;
}

function GuardrailEditor(props: GuardrailEditorProps) {
  const {
    guardrail,
    guardrail: { trigger },
    saveGuardrailMutationFn,
    triggeringEvent
  } = props;

  const [activeStep, setActiveStep] = useState<GuardrailEditorStep>(getInitialStepFromGuardrail(guardrail));
  const eventTypeDefinition = trigger ? findTriggerEventTypeDefinition(trigger.eventSource, trigger.eventType) : null;
  const [expandedFilterKey, setExpandedFilterKey] = useState<string | null>(
    getInitialExpandedFilterKey(activeStep, guardrail, eventTypeDefinition)
  );

  const guardrailEditorState: GuardrailEditorState = {
    guardrail,
    activeStep,
    setActiveStep,
    saveGuardrailMutationFn,
    eventTypeDefinition
  };

  return (
    <DynamicConfigProvider>
      {() => (
        <Root className="guardrail-editor">
          <GuardrailTriggerSection
            {...guardrailEditorState}
            onTriggerSectionSubmitSuccess={updatedGuardrail => {
              if (!updatedGuardrail.functionId) {
                return setActiveStep(GuardrailEditorStep.SELECT_ACTION);
              }

              setActiveStep(GuardrailEditorStep.NONE);
            }}
          />

          <GuardrailFiltersSection
            {...guardrailEditorState}
            setExpandedFilterKey={selected => {
              setActiveStep(selected ? GuardrailEditorStep.CONFIGURE_FILTERS : GuardrailEditorStep.NONE);
              setExpandedFilterKey(selected);
            }}
            expandedFilterKey={expandedFilterKey}
            onSectionSubmitSuccess={updatedGuardrail => {
              // if user has not configured action go there.
              if (!updatedGuardrail.functionId) {
                setActiveStep(GuardrailEditorStep.SELECT_ACTION);
                setExpandedFilterKey(null);
                return;
              }

              setActiveStep(GuardrailEditorStep.NONE);
              setExpandedFilterKey(null);
              return;
            }}
            exampleTriggerEvent={triggeringEvent}
          />

          <GuardrailActionsSection {...guardrailEditorState} />
        </Root>
      )}
    </DynamicConfigProvider>
  );
}

export default GuardrailEditor;

import styled from 'styled-components';
import uiConstants from 'constants/ui';

const breakpoint = uiConstants.navBreakpointWidth,
  fullMenuWidth = '240px';

export const focusShadow = 'inset 0 0 2px 2px rgba(255, 255, 255, 0.7)';

export const MainNavNav = styled.nav`
  height: 100vh;
  background-color: #212121;
  z-index: 101;
  width: ${fullMenuWidth};
  transition-property: width, left;
  transition-duration: 0.2s;
  transition-timing-function: ease;

  a {
    display: block;
    box-shadow: none;
    transition: box-shadow 0.3s ease-in;
  }

  a.active {
    display: block;
    box-shadow: inset 5px 0 0 0 ${(p) => p.theme.primary};

    &:focus {
      box-shadow: inset 5px 0 0 0 ${(p) => p.theme.primary}, ${focusShadow};
    }
  }

  a:focus {
    box-shadow: ${focusShadow};
    display: block;
  }

  .nav-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .toggle-collapse {
    display: none;

    &:focus {
      outline: none;
      box-shadow: ${focusShadow};
    }
  }

  .mobile-menu-trigger {
    position: fixed;
    top: 15px;
    left: 1rem;
    z-index: 100;
    cursor: pointer;
    background: transparent;
    border: none;
    height: 26px;
    padding: 0;
  }

  .mobile-menu-close {
    font-size: 20px;
    background: transparent;
    border: none;
    padding: 0;
    color: #cccccc;
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  .collapse-icon {
    transform: rotate(0deg);
    transition: transform 0.2s ease;
  }

  .nav-logo {
    flex: 0 0 auto;
    width: 240px;
    overflow: hidden;

    a {
      padding: 0.75rem 1rem 1rem;
      display: flex;
      align-items: center;
      min-height: 34px;
      position: relative;

      &.active {
        box-shadow: none;
      }
    }

    .logo-container {
      width: 32px;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    .company-name {
      color: #fff;
      font-size: 22px;
      margin-bottom: 0px;
      margin-left: 0.5rem;
      white-space: nowrap;
      font-weight: 400;
      letter-spacing: 0.025em;
    }
  }

  .nav-main {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  .nav-admin {
    flex: 0 0 auto;
  }

  /* desktop-only rules */
  @media only screen and (min-width: ${breakpoint}px) {
    &.collapsed {
      width: 62px;
      .icon {
        margin-right: 0;
      }
      .collapse-content {
        display: none;
      }
      .collapse-icon {
        transform: rotate(180deg);
      }
      .toggle-collapse {
        justify-content: center;
      }
      .collapse-wrapper {
        width: 0;
      }
      .nav-logo {
        max-width: 100%;
      }
    }

    .toggle-collapse {
      border: none;
      background: transparent;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      color: ${(p) => p.theme.grey300};
      padding: 0.5rem 1.25rem;
      width: 100%;
    }

    .mobile-menu-trigger,
    .mobile-menu-close {
      display: none;
    }
  }

  /* mobile-only rules */
  @media only screen and (max-width: ${breakpoint - 1}px) {
    position: absolute;
    left: -${fullMenuWidth};

    &.mobile-active {
      left: 0;

      .mobile-menu-trigger {
        left: -100%;
      }
    }
  }
`;

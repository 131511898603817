import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { AutomationEventDefinition, PageInfo, Maybe } from 'typings';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import { Typography, Button, Pagination } from 'antd';
import { AUTOMATION_FUNCTION_FIELDS_FOR_OPS } from '../../OpDetail/gql';
import OpEventDefinitionFunctionListItem from '../components/OpEventDefinitionFunctionListItem';
import { FilterRow, FilterItem } from 'components/app/FilterBar/components';
import { SearchFilter } from 'components/app/FilterBar/filters';
import usePaginator from 'components/app/Paginator/usePaginator';
import EventVendorFilter from '../components/EventVendorFilter';
import EventVendorProductFilter from '../components/EventVendorProductFilter';
import PlaybookLabelsFilter from '../components/PlaybookLabelsFilter';
import { OpSectionListItem, OpSectionListItemMeta } from '../../OpDetail/components';
import { CloudVendorIcon } from 'components/cloudVendors';
import Markdown from 'components/ui/Markdown';

const Root = styled.div`
  .filter-row {
    margin-bottom: 36px;
  }
`;

const EVENT_DEFINITIONS_QUERY = gql`
  query EventDefinitionsWithJoinedFunctions(
    $search: String
    $pageNumber: Int
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $vendor: String
    $vendorProduct: String
    $labels: [String]
  ) {
    eventDefinitions(
      search: $search
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      vendor: $vendor
      vendorProduct: $vendorProduct
      labels: $labels
    ) {
      pageInfo {
        total
        current
        size
        sortDirection
        sortBy
      }
      nodes {
        id
        name
        description
        overview
        vendor
        vendorProduct
        labels
        severity
        eventDefinitionFunctions {
          id
          name
          description
          overview
          functionInputMappings {
            key
            valuePath
          }

          function {
            ...AutomationFunctionFieldsForOps
          }
        }
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_OPS}
`;

function OpEventDefinitionsList(props: RouteComponentProps) {
  const {
    pageNumber,
    pageSize,
    updatePagination,
    filters,
    search,
    pushFilter,
    updateSearch,
    replaceFilter
  } = usePaginator();

  const queryVariables: any = {
    search: search || undefined,
    sortBy: 'name',
    sortDirection: 'ASC',
    pageNumber,
    pageSize,
    labels: filters.labels || [],
    vendor: filters.vendor?.[0],
    vendorProduct: filters.vendorProduct?.[0]
  };

  const { data, loading, error } = useQuery(EVENT_DEFINITIONS_QUERY, { variables: queryVariables });
  const pageInfo: Maybe<PageInfo> = data?.eventDefinitions?.pageInfo || null;

  return (
    <Root>
      <FilterRow>
        <FilterItem label="Search">
          <SearchFilter search={search || ''} onSearchChange={(search) => updateSearch(search)} />
        </FilterItem>

        <FilterItem label="Labels">
          <PlaybookLabelsFilter
            onChange={(value) => pushFilter('labels', value)}
            value={(Array.isArray(filters.labels) && filters.labels) || []}
          />
        </FilterItem>

        <FilterItem label="Vendor">
          <EventVendorFilter
            onChange={(value) => replaceFilter('vendor', value !== 'all' ? value : [])}
            value={filters.vendor?.[0] || 'all'}
          />
        </FilterItem>

        <FilterItem label="Vendor Product">
          <EventVendorProductFilter
            onChange={(value) => {
              replaceFilter('vendorProduct', value !== 'all' ? value : []);
            }}
            value={filters.vendorProduct?.[0] || 'all'}
          />
        </FilterItem>
      </FilterRow>

      {/* Query and View of Results */}
      <QueryResult loading={loading} error={error}>
        {() => {
          const eventDefinitions: AutomationEventDefinition[] = data?.eventDefinitions?.nodes || []; // cast type

          if (eventDefinitions.length === 0) return <Typography>{'No matching Triggers'}</Typography>;

          return (
            <>
              {eventDefinitions.map((eventDefinition, idx) => {
                const { vendor, vendorProduct, severity } = eventDefinition;

                const eventDefinitionFunctions = eventDefinition.eventDefinitionFunctions || [];
                const labels = eventDefinition.labels || [];
                const hasActions = eventDefinitionFunctions && eventDefinitionFunctions.length > 0;

                return (
                  <EventDefinitionListItemRoot key={idx}>
                    <div className="trigger-list-item-col">
                      <OpSectionListItem
                        key={eventDefinition.id}
                        title={eventDefinition.name}
                        description={eventDefinition.description}
                        icon={<CloudVendorIcon vendor={eventDefinition.vendor} />}
                        meta={
                          <>
                            {labels.map((label, idx) => (
                              <OpSectionListItemMeta key={`${idx}_${label}`} value={label} />
                            ))}
                          </>
                        }
                        metaBottom={
                          <>
                            {vendor && vendorProduct && (
                              <OpSectionListItemMeta
                                size="small"
                                label="Event Source:"
                                value={`${vendor}:${vendorProduct}`}
                              />
                            )}
                            {severity !== null && severity !== undefined && (
                              <OpSectionListItemMeta size="small" label="Severity:" value={`${severity}`} />
                            )}
                          </>
                        }
                        extraContent={
                          eventDefinition.overview ? <Markdown source={eventDefinition.overview} /> : undefined
                        }
                      />

                      <Link to={`/ops1/details?initTriggerId=${eventDefinition.id}`}>
                        <Button type="primary" className="square secondary" size="small">
                          {'Create Op with this Trigger'}
                        </Button>
                      </Link>
                    </div>

                    <div className="event-definition-functions-col">
                      <div className="event-definition-functions-col-label">
                        {hasActions ? 'Recommended Actions' : 'No recommended Actions found'}
                      </div>

                      {eventDefinitionFunctions.map((eventDefinitionFunction, idx) => {
                        return (
                          <OpEventDefinitionFunctionListItem
                            key={`${eventDefinitionFunction.id}_${idx}`}
                            eventDefinitionFunction={eventDefinitionFunction}
                            action={
                              <Link to={`/ops1/details?fromPlaybook=${eventDefinitionFunction.id}`}>
                                <Button type="primary" className="use-playbook-btn square secondary" size="small">
                                  {'Use this Action'}
                                </Button>
                              </Link>
                            }
                          />
                        );
                      })}
                    </div>
                  </EventDefinitionListItemRoot>
                );
              })}

              <Pagination
                current={(pageInfo && pageInfo.current) || undefined}
                pageSize={(pageInfo && pageInfo.size) || undefined}
                total={(pageInfo && pageInfo.total) || undefined}
                onChange={(pageNumber) => {
                  updatePagination({ pageNumber });
                }}
              />
            </>
          );
        }}
      </QueryResult>
    </Root>
  );
}

const EventDefinitionListItemRoot = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.grey300};
  padding-bottom: 16px;
  margin-bottom: 32px;

  .trigger-list-item-col {
    margin-bottom: 24px;
  }

  @media (min-width: 1086px) {
    display: flex;

    .trigger-list-item-col,
    .event-definition-functions-col {
      width: 50%;
    }

    .trigger-list-item-col {
      padding-right: 32px;
    }
  }

  .event-definition-functions-col-label {
    font-weight: 600;
    margin-bottom: 24px;
    padding-top: 8px;
  }

  .event-definition-function-li-root {
    margin-bottom: 16px;
  }

  .automation-list-item {
    padding-left: 0;
  }
`;

export default OpEventDefinitionsList;

import React from 'react';
import { Alert } from 'antd';
import { APP_ENV } from 'constants/runtimeConfig';

type Props = {
  message?: string | null;
  description?: string;
  error?: any;
  type?: any; // should be string?
  showIcon?: boolean; // use message for large icon, description only for small icon
  onClose?: (e?: any) => any;
  closeable?: boolean;
  className?: string;
};

type State = {
  hidden: boolean;
};

class ErrorAlert extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hidden: false
    };

    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const { trackJs } = window as any;
    const { error, message } = this.props;

    if (trackJs) {
      trackJs.track(error || message);
    }
  }

  // if user sets to closeable but doesn't set onClose cb. this is called.
  handleClose() {
    this.setState({ hidden: true });
  }

  getMessage() {
    const { message } = this.props;

    if (message) return message;

    return 'An error occurred.';
  }

  getDescription() {
    const { description, error } = this.props;
    if (description) return description;

    if (error && error.message && notProduction()) return error.message;

    return '';
  }

  render() {
    const { type, showIcon, onClose, closeable, className } = this.props;

    const message = this.getMessage();

    if (this.state.hidden) return null;

    return (
      <Alert
        message={message}
        description={this.getDescription()}
        type={type || 'error'}
        onClose={onClose || this.handleClose}
        closable={!!onClose || closeable}
        showIcon={message ? true : showIcon}
        className={className}
      />
    );
  }
}

function notProduction() {
  return APP_ENV !== 'prod';
}

export default ErrorAlert;

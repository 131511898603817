import React from 'react';
import { Select as AntSelect } from 'antd';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { USER_LIST_QUERY } from 'queries';
import { parsePageData } from 'components/app/Paginator/Paginator';

const InputWrap = styled.div`
  position: relative;
`;

interface Props {
  filterUserIds?: string[];
  value?: string;
  onBlur?: (e: any) => any | void;
  onChange: (value: any) => any | void;
  many?: boolean;
}

function UserSelect(props: Props) {
  const { value, onChange, onBlur, filterUserIds = [], many = false } = props;
  const { loading, error, data } = useQuery(USER_LIST_QUERY);
  const { items } = parsePageData(data, 'userList');

  // One could easily make the argument in favor of isolating the comparison logic
  // in a UserComparator function to consolidate this logic. Leaving this inline for now.
  const users = items
    .filter((user) => !filterUserIds.includes(user.id))
    .sort((a, b) => {
      const aName = `${a.first_name} ${a.last_name}` || a.username;
      const bName = `${b.first_name} ${b.last_name}` || b.username;
      return aName.toLowerCase().localeCompare(bName.toLowerCase(), undefined, { sensitivity: 'base' });
    });

  return (
    <InputWrap>
      <AntSelect
        showSearch
        size="large"
        loading={loading}
        value={value}
        style={{ width: '100%' }}
        onBlur={onBlur}
        onChange={onChange}
        filterOption={(input, option) => {
          const title = option?.props?.title?.toLowerCase();
          return (title?.indexOf(input?.toLowerCase()) ?? -1) >= 0;
        }}
        disabled={loading || !!error}
        placeholder={error ? 'Error loading users' : 'Select user'}
        mode={many ? 'multiple' : 'default'}
      >
        {users.map((user) => {
          const fullname = `${user.first_name} ${user.last_name}` || user.username;
          const label = `${fullname} | User: ${user.username} | Email: ${user.email}`;
          return (
            <AntSelect.Option key={user.id} title={label}>
              {label}
            </AntSelect.Option>
          );
        })}
      </AntSelect>
    </InputWrap>
  );
}

export default UserSelect;

import React from 'react';
import { Op } from 'typings';
import { Input } from 'antd';
import { OpContextControl } from '../OpDetail/components';
import FormField from 'designSystem/LegacyModified/FormField';
import { OpGeneralEditorDiv } from './General.styles';

interface GeneralProps {
  op?: Op;
  authz: any;
}

function General(props: GeneralProps) {
  const { op, authz } = props;
  const existingProjectId = op?.projectId || null;

  const isAuthorized = existingProjectId ? authz.isAuthorized : true;

  return (
    <OpGeneralEditorDiv>
      <FormField name="name" label="Op name" required>
        {({ name, value, handleChange, handleBlur }) => (
          <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} disabled={!isAuthorized} />
        )}
      </FormField>

      <FormField name="description" label="Description">
        {({ name, value, handleChange, handleBlur }) => (
          <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} disabled={!isAuthorized} />
        )}
      </FormField>

      <FormField
        required
        name="projectId"
        label="Ownership"
        labelToolTipText={`The Project selected will determine the permissions required for users to view and/or edit this Op. User's Permissions for a given Project will apply to all Cloud Accounts and Projects that are anywhere below the assigned project. This means that a User will need the "Modify Op" Permission for either the Project selected OR a parent/ancestor Project above.`}
      >
        {({ value, handleChange, handleBlur, formikContext }) => {
          const { setFieldValue } = formikContext;
          return (
            <OpContextControl
              projectId={value}
              onChange={(id) => {
                handleChange(id);
                handleBlur();

                // if op.projectId changes, then so should the root of the filtering in the trigger....
                setFieldValue(`triggerProjectIds`, [id]);
              }}
            />
          );
        }}
      </FormField>
    </OpGeneralEditorDiv>
  );
}

export default General;

import styled from 'styled-components';

export const NodeTitleDiv = styled.div`
  display: flex;

  .tree-node-icon {
    display: inline-block;
    margin: 4px 4px 0;
    opacity: 0.5;

    &.project {
      color: ${(p) => p.theme.grey800};
    }

    &.cloud-account {
      color: ${(p) => p.theme.grey400};
    }
  }

  .cloud-account-name:after {
    content: ', ';
  }

  .cloud-account-name:last-child:after {
    content: '';
  }

  .cloud-account-list {
    display: inline-block;
    color: ${(p) => p.theme.grey500};
    margin-left: 1rem;
    font-style: italic;
  }
`;

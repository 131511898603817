import React, { useState } from 'react';
import styled from 'styled-components';
import { Alert, Button, Input, message, Typography } from 'antd';

import { CloudProviderDefinition, PanelChildrenProps } from '../CloudAccountNewForm';
import gql from 'graphql-tag';

import * as Yup from 'yup';
import Form, { FormField } from 'components/ui/Form';
import buildPolicySelect from 'components/cloudAccounts/buildPolicySelect';
import { Field as FormikField } from 'formik';

import { useMutation, useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import ProgressCircular from 'components/ui/ProgressCircular';
import { CLOUD_ACCOUNT_QUERY } from '../CloudAccountNew';
import ProvisionInstructions from 'components/cloudAccounts/ProvisionInstructions';
import { refreshCognitoSession } from '../../../../../../services/auth';
import { ADD_ACCOUNT_MUTATION, POLICY_OPTIONS_QUERY } from './common';

export enum CloudProvider {
  aws = 'aws',
  azure = 'azure'
}
const { Paragraph } = Typography;

const Root = styled.div`
  margin: 8px 0 16px;
`;

export const AWS_CLOUD_PROVIDER: CloudProviderDefinition = {
  key: CloudProvider.aws,
  name: 'Amazon AWS',
  panels: [
    {
      key: 'account_info',
      name: 'Account Info',
      children: AwsAccountInfo
    },
    {
      key: 'provision',
      name: 'Provision',
      children: AwsProvisionStep
    },
    {
      key: 'verify',
      name: 'Verify Account',
      children: AwsVerifyAccount
    }
  ]
};

interface FormValues {
  assumerole_account_id: string;
  policy_code: null | string;
  nickname?: string | null;
}

const AccountInfoFormSchema = Yup.object().shape({
  assumerole_account_id: Yup.string()
    .matches(/^\w{12}$/, 'Account number must be 12 digits long')
    .required('Required'),
  policy_code: Yup.string().required('Required'),
  nickname: Yup.string().max(128, 'Too long').required('Required (ie: "Dev Product Account"')
});

function AwsAccountInfo(props: PanelChildrenProps) {
  const [submitError, setSubmitError] = useState<any>(null);
  const { updateStep, cloudAccount } = props;

  // disable if already saved
  const disabled = Boolean(cloudAccount && cloudAccount.account_id);

  const { loading, error, data } = useQuery(POLICY_OPTIONS_QUERY);

  const [addAccount, { loading: isAddingAccount }] = useMutation(ADD_ACCOUNT_MUTATION);

  return (
    <QueryResult loading={loading} error={error} entityName={'Policy options'}>
      {() => {
        const { policyOptions } = data;
        const initialValues = {
          assumerole_account_id: cloudAccount.assumerole_account_id || undefined,
          policy_code: !cloudAccount.policy_code
            ? policyOptions.find((item) => item.selected).policy_code || undefined
            : cloudAccount.policy_code, // find first policyOption.selected === true and set as default.
          nickname: cloudAccount.nickname || undefined
        };

        return (
          <Form
            allowCleanSubmits={false}
            validationSchema={AccountInfoFormSchema}
            initialValues={initialValues}
            onSubmit={async (formValues: Partial<FormValues>, actions) => {
              setSubmitError(null);
              const { assumerole_account_id, policy_code, nickname } = formValues;

              try {
                const input = {
                  assumerole_account_id,
                  policy_code: policy_code, // assuming this is what the new API will want?
                  nickname: nickname && nickname.length > 0 ? nickname : null,
                  provider: CloudProvider.aws,
                  project_id: cloudAccount?.project_id
                };

                const response: any = await addAccount({ variables: { input } });
                await refreshCognitoSession();

                const account_id = response.data.postAccount.account_id;

                updateStep('provision', account_id);

                // onComplete(response.data.postAccount);
              } catch (e) {
                setSubmitError(e);
                return;
              }
            }}
          >
            {(formikProps) => {
              const { canSubmit } = formikProps;
              return (
                <>
                  <FormField label="Account number" name="assumerole_account_id">
                    {({ name, value, required, handleChange, handleBlur }) => {
                      return (
                        <Input
                          disabled={disabled}
                          name={name}
                          value={value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={required}
                        />
                      );
                    }}
                  </FormField>

                  <FormField label="Select policy" name="policy_code">
                    {({ name }) => <FormikField name={name} component={buildPolicySelect({ policyOptions })} />}
                  </FormField>

                  <FormField label="Nickname" name="nickname">
                    {({ name, value, required, handleChange, handleBlur }) => {
                      return (
                        <Input
                          disabled={disabled}
                          name={name}
                          placeholder="Staging"
                          value={value}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={required}
                        />
                      );
                    }}
                  </FormField>

                  {submitError && (
                    <Alert
                      message="There was an error creating this AWS Account."
                      description="Please make sure this account has not been added to DisruptOps previously."
                      type="error"
                      style={{ marginBottom: '16px' }}
                    />
                  )}

                  <div className="actions-row">
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={disabled || !canSubmit || isAddingAccount}
                      loading={isAddingAccount}
                    >
                      Add account
                    </Button>
                  </div>
                </>
              );
            }}
          </Form>
        );
      }}
    </QueryResult>
  );
}

function AwsProvisionStep(props: PanelChildrenProps) {
  const { cloudAccount, updateStep } = props;
  const provisionURL = cloudAccount.provision_url || '';

  return (
    <Root>
      <ProvisionInstructions provisionUrl={provisionURL} />
      <Paragraph>{'Wait for the CloudFormation stack to complete before moving on to verification.'}</Paragraph>
      <Button
        onClick={() => {
          updateStep('verify');
        }}
        size="small"
        type="primary"
      >
        {'Continute to verification'}
      </Button>
    </Root>
  );
}

// VERIFY ACCOUNT
const VERIFY_CLOUD_ACCOUNT = gql`
  mutation VerifyAWSCloudAccount($accountId: String!) {
    verifyCloudAccount(accountId: $accountId, input: {})
      @rest(type: "Put", method: "PUT", path: "/api/v2/accounts/{args.accountId}/verify")
  }
`;

const AwsVerificationRoot = styled.div`
  .actions {
    display: flex;

    > * {
      margin-right: 8px;
    }
  }
`;

function AwsVerifyAccount(props: PanelChildrenProps) {
  const { cloudAccount, updateStep } = props;

  const variables = { accountId: cloudAccount.account_id };

  const [verifyCloudAccount, { loading, error }] = useMutation(VERIFY_CLOUD_ACCOUNT, {
    update: (proxy) => {
      try {
        const query = CLOUD_ACCOUNT_QUERY;
        const variables = { id: cloudAccount.account_id };
        const data: any = proxy.readQuery({ query, variables });

        // if this succeeds, it should push to third step
        const updatedCloudAccount = {
          ...data.cloudAccount,
          provision_url: null
        };

        proxy.writeQuery({ query, data: { cloudAccount: updatedCloudAccount }, variables });
      } catch (e) {
        return;
      }
    }
  });

  return (
    <AwsVerificationRoot>
      {loading ? (
        <ProgressCircular />
      ) : error ? (
        <Alert
          type="warning"
          style={{ marginBottom: '24px' }}
          message="Account verification failed"
          description="Make sure the CloudFormation stack has successfully been created and try again"
        />
      ) : (
        <Paragraph>
          {'Once you have completed the steps above, use the button below to verify the CloudFormation Stack creation.'}
        </Paragraph>
      )}
      <div className="actions">
        <Button
          disabled={loading}
          loading={loading}
          type="primary"
          size="small"
          ghost
          onClick={async () => {
            try {
              await verifyCloudAccount({ variables });

              message.success('Account has successfully been provisioned.');
            } catch (e) {
              return;
            }
          }}
        >
          {loading ? 'Verifying' : !error ? 'Verify' : 'Attempt verification again'}
        </Button>
        <Button size="small" onClick={() => updateStep('provision')}>
          {'Back'}
        </Button>
      </div>
    </AwsVerificationRoot>
  );
}

import React from 'react';
import { Select } from 'antd';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { AWS_REGION_LIST_QUERY } from 'queries';
import { ModeOption } from 'antd/lib/select';

const Root = styled.div``;

export interface Props {
  value?: string | string[];
  onChange: (value: any) => any;
  mode?: ModeOption;
  placeholder?: string;
  onBlur?: (event: any) => any;
  minWidth?: number;
}

function RegionFilter(props: Props) {
  const { value, onChange, onBlur, minWidth = 160 } = props;

  const mode = props.mode ? props.mode : 'multiple';
  const placeholder = props.placeholder ? props.placeholder : 'All regions';

  return (
    <Root>
      <Query query={AWS_REGION_LIST_QUERY} fetchPolicy="network-only">
        {(results) => {
          const { loading, error, data } = results;

          return (
            <Select
              value={value === null ? undefined : value}
              style={{ width: '100%', minWidth: `${minWidth}px` }}
              onChange={onChange}
              disabled={loading || !!error}
              placeholder={!error ? placeholder : 'Error loading regions'}
              loading={loading}
              mode={mode}
              onBlur={onBlur}
            >
              {!loading && data && data.regions ? (
                data.regions.map((region) => {
                  return (
                    <Select.Option key={region.name} title={region.name}>
                      {region.name}
                    </Select.Option>
                  );
                })
              ) : (
                <Select.Option value="">Loading</Select.Option>
              )}
            </Select>
          );
        }}
      </Query>
    </Root>
  );
}

export default RegionFilter;

import React from 'react';
import FilterListing from '../FilterListing/FilterListing';
import SourceEditor from '../SourceEditor/SourceEditor';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { AutomationEventDefinition } from 'typings';
import styled from 'styled-components';
import { FormikValues } from 'formik';
import { ConfirmationModalState } from '../../../../OpEditor';
import { Root } from './TriggerContentsStyles';

export const SectionLabel = styled.div`
  text-transform: uppercase;
  color: ${(p) => p.theme.grey400};
`;

export interface EventSource {
  id: string;
  name: string;
}

export interface FilterValuesAndSetter {
  values: FormikValues;
  setFieldValue(field: string, value: any): void;
}

export const AUTOMATION_EVENT_SOURCES_LIST_QUERY = gql`
  query eventSources($id: [String], $pageNumber: Int, $pageSize: Int, $sortBy: String, $sortDirection: String) {
    eventSources(
      id: $id
      pageNumber: $pageNumber
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      nodes {
        id
        name
      }
    }
  }
`;

interface TriggerContentsProps extends FilterValuesAndSetter {
  eventDefinition: AutomationEventDefinition | null;
  eventFromEventConsole: any | null;
  isAllFindingsSelected: boolean;
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
}

function TriggerContents(props: TriggerContentsProps) {
  const { data, error, loading } = useQuery(AUTOMATION_EVENT_SOURCES_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageNumber: 1,
      pageSize: 100,
      sortBy: 'name',
      sortDirection: 'ASC'
    }
  });

  return (
    <Root>
      <SectionLabel>Source</SectionLabel>
      <SourceEditor {...props} sources={data?.eventSources.nodes} error={error} loading={loading} />
      {/* SourceLabel will be useful for an upcoming requirement to make Source read-only under certain circumstances */}
      {/* <SourceLabel sourceId={props.values.eventSourceId} sources={data?.eventSources.nodes} /> */}
      <FilterListing {...props} />
    </Root>
  );
}

export default TriggerContents;

import styled from 'styled-components';

export const HorizontalLabelDiv = styled.div`
  display: flex;

  .label {
    width: 120px;
    text-align: right;
    margin-right: 1rem;
    color: ${(p) => p.theme.gray600};
  }

  .value {
    width: calc(100% - 120px - 1rem);
    word-break: break-all;
  }

  &.form-mode .label {
    margin-top: 6px;
  }

  &.has-help .label {
    display: flex;
    justify-content: flex-end;

    .trigger {
      margin-left: 0.25rem;
    }
  }
`;

import { ISSUE_SEVERITY } from 'constants/issue';
import React from 'react';
import { Detector } from '../../DetectorEditor/DetectorEditor';
import { DiscoverableIssuesDiv } from './DiscoverableIssues.style';
import { Tooltip } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import useDetectorVariants from '../../DetectorsCommon/useDetectorVariants/useDetectorVariants';

interface DiscoverableIssuesProps {
  detector: Detector;
}

function DiscoverableIssues(props: DiscoverableIssuesProps) {
  const { detector } = props,
    variants = useDetectorVariants(),
    issueDefinitions =
      detector.assessor && detector.assessor.issueDefinitions ? detector.assessor.issueDefinitions : [];

  return (
    <DiscoverableIssuesDiv>
      {issueDefinitions.length ? (
        <table>
          <thead>
            <tr>
              <th>Issue</th>
              <th>Severity</th>
            </tr>
          </thead>
          <tbody>
            {issueDefinitions.map((definition, i) => (
              <tr key={i}>
                <td>
                  {definition.name}{' '}
                  {definition.description && (
                    <Tooltip title={definition.description}>
                      <span>
                        <DopeIcon name="QUESTION" size="12" />
                      </span>
                    </Tooltip>
                  )}
                </td>
                <td>{definition.severity ? ISSUE_SEVERITY[definition.severity] : '--'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <span>This ${variants.variantName} has no discoverable issues.</span>
      )}
    </DiscoverableIssuesDiv>
  );
}

export default DiscoverableIssues;

import React, { ReactNode, ReactNodeArray } from 'react';
import styled from 'styled-components';

export const MainPanel = styled.div`
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;

  &.with-titlebar {
    padding-top: 56px;
  }

  .neo-page-title-bar-has-nav & {
    padding-top: 100px;
  }

  .main-panel-content {
    flex: 1 0 auto;
    display: flex;
  }

  .info-panel-container {
    background-color: #f5f5f5;
    width: 546px;
    border-left: 1px solid #cacaca;
    min-height: 100vh;
  }
`;

export const TitleBarWrap = styled.div`
  position: fixed;
  top: 0;
  z-index: 99;
  left: 0;
  right: 0;
  transition: left 0.2s ease;

  .sidenav-permanent & {
    left: 68px;
  }

  .sidenav-permanent.sidenav-open & {
    left: 240px;
  }
`;

export const MainScrollableContainer = styled.div`
  flex: 1;
  position: relative;
`;

export const MainScrollable = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

interface MainProps {
  titleBar?: null | ReactNode;
  children: ReactNode | ReactNodeArray;
  infoPanel?: ReactNode;

  classes?: {
    root?: string;
    content?: string;
    titleBar?: string;
    mainScrollableContainer?: string;
    mainScrollable?: string;
  };
}

function Main(props: MainProps) {
  const { titleBar, children, infoPanel, classes = {} } = props;

  return (
    <MainPanel className={`main-panel ${titleBar ? ' with-titlebar ' : ''} ${classes.root || ''}`}>
      {titleBar && (
        <header>
          <TitleBarWrap className="neo-title-bar">{titleBar}</TitleBarWrap>
        </header>
      )}

      <main className={`main-panel-content ${classes.content || ''}`}>
        <MainScrollableContainer className="main-panel-container">{children}</MainScrollableContainer>
      </main>

      {infoPanel && <div className="info-panel-container">{infoPanel}</div>}
    </MainPanel>
  );
}

export default Main;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { ClientProject } from 'typings';
import QueryResult from 'components/util/QueryResult';
import OpSectionTitle from '../Ops/OpDetail/components/OpSectionTitle';
import { GridCard } from 'components/ui/Card';
import { Card } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import { FormField } from 'components/ui/Form';
import RecipientSelection from 'components/recipients/recipientSelectList';
import RegionSelection from 'components/regions/regionSelectList';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';

const PROJECT_SETTINGS = gql`
  query ProjectSettings($id: String!) {
    projectSettings(id: $id)
      @rest(type: "ProjectSettings", path: "/api/v2/projects/{args.id}/settings", method: "GET") {
      settings
      project_id
      name
    }
  }
`;

const SET_PROJECT_SETTING = gql`
  mutation SaveSetting($id: String!) {
    saveSetting(id: $id, input: $input)
      @rest(type: "NewProjectSetting", path: "/api/v2/projects/{args.id}/settings", method: "PUT") {
      settings
      project_id
      name
    }
  }
`;

const DELETE_PROJECT_SETTING = gql`
  mutation SaveSetting($id: String!) {
    saveSetting(id: $id, name: $name)
      @rest(
        type: "DeleteProjectSetting"
        path: "/api/v2/projects/{args.id}/settings?name={args.name}"
        method: "DELETE"
      ) {
      settings
      project_id
      name
    }
  }
`;

interface Props {
  project: ClientProject;
  isSubmitting: boolean;
}

interface ProjectSetting {
  name: string;
  value: any;
  json_value: any;
  projectId?: string;
  projectName?: string;
}

function ProjectSettingsCard(props: Props) {
  const { project, isSubmitting } = props;
  const projectId = project.project_id;
  const [initialAlertSet, setInitialAlert] = useState<boolean>(false);
  const [initialSuccessAlertSet, setInitialSuccessAlert] = useState<boolean>(false);
  const [initialFailureAlertSet, setInitialFailureAlert] = useState<boolean>(false);
  const [initialBugSet, setInitialBug] = useState<boolean>(false);
  const [initialRegionsSet, setInitialRegions] = useState<boolean>(false);

  const { loading: loadingSettings, error, data: projectSettings, refetch: refetchSettings } = useQuery(
    PROJECT_SETTINGS,
    {
      variables: { id: projectId },
      onCompleted: () => {
        setInitialAlert(false);
        setInitialSuccessAlert(false);
        setInitialFailureAlert(false);
        setInitialBug(false);
        setInitialRegions(false);
      }
    }
  );

  const features = useFeatureFlag([Feature.TEST_PROJECT_REGIONS]);
  const testingEnabled = features[Feature.TEST_PROJECT_REGIONS];

  // Refetch settings when moving a project
  if (isSubmitting && !loadingSettings) {
    refetchSettings();
  }

  return (
    <>
      <QueryResult
        loading={loadingSettings}
        error={error}
        data={projectSettings}
        loadingCenterVertically={false}
        loadingHeight={100}
      >
        {() => {
          const alertChannel: ProjectSetting = projectSettings.projectSettings.settings.find(
            (setting) => setting.name === 'alert_channel'
          );
          const alertInherited = alertChannel?.projectId && alertChannel?.projectId !== projectId;

          const successChannel: ProjectSetting = projectSettings.projectSettings.settings.find(
            (setting) => setting.name === 'success_channel'
          );
          const successInherited = successChannel?.projectId && successChannel?.projectId !== projectId;

          const failureChannel: ProjectSetting = projectSettings.projectSettings.settings.find(
            (setting) => setting.name === 'failure_channel'
          );
          const failureInherited = failureChannel?.projectId && failureChannel?.projectId !== projectId;

          const bugProject: ProjectSetting = projectSettings.projectSettings.settings.find(
            (setting) => setting.name === 'bug_project'
          );

          const bugProjectInherited = bugProject?.projectId && bugProject?.projectId !== projectId;
          const setRegions: ProjectSetting = projectSettings.projectSettings.settings.find(
            (setting) => setting.name === 'regions'
          );

          const LabelRoot = styled.div`
            display: inline-block;

            .label-flex {
              display: flex;
            }

            .label-text {
              margin-right: 4px;
            }

            .label-tooltip {
              margin-top: 1dpx;
            }
          `;

          const regionsConfigCardTitle = (
            <LabelRoot>
              <div className="label-flex">
                <div className="label-text">Regions Configuration</div>
                <div className="label-tooltip">
                  <Tooltip
                    title="This setting defines which regions (for AWS Cloud Accounts) are assessed by default by DisruptOps.  If you have certain regions shut off via Service Control Policy, or have disabled-by-default regions shut off, you should remove those regions from this list.
                           Turning off a region in DisruptOps will stop our systems' monitoring, detection and response capabilities"
                    placement="right"
                  >
                    <div className="tooltip-icon-wrap">
                      <DopeIcon name="QUESTION" size="14" />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </LabelRoot>
          );

          const initialRegionsInherited = setRegions?.projectId && setRegions?.projectId !== projectId;
          return (
            <>
              <Card title="Default Alert Settings">
                <GridCard
                  icon={<DopeIcon name="ACTIVITY_ALERTS_ON" size={20} />}
                  title={<OpSectionTitle title={'Alert Channel'} />}
                  action={
                    alertInherited ? (
                      <Link to={`/projects/${alertChannel?.projectId}/settings`}>
                        Inherited from {alertChannel?.projectName}
                      </Link>
                    ) : (
                      ''
                    )
                  }
                  elevation={1}
                  rounded
                >
                  <ProjectRecipientSelection
                    fieldName={'alert_channel'}
                    initialValueSet={initialAlertSet}
                    setInitialValueFunc={setInitialAlert}
                    projectSetting={alertChannel}
                    projectId={projectId}
                    canDelete={!alertInherited}
                    refetchSettings={refetchSettings}
                    typeFilter={['SLACK', 'MS_TEAMS']}
                  />
                </GridCard>
                <GridCard
                  icon={<DopeIcon name="SUCCESS" size={20} />}
                  title={<OpSectionTitle title={'Successful Action Channel'} />}
                  action={
                    successInherited ? (
                      <Link to={`/projects/${successChannel?.projectId}/settings`}>
                        Inherited from {successChannel?.projectName}
                      </Link>
                    ) : (
                      ''
                    )
                  }
                  elevation={1}
                  rounded
                >
                  <ProjectRecipientSelection
                    fieldName={'success_channel'}
                    initialValueSet={initialSuccessAlertSet}
                    setInitialValueFunc={setInitialSuccessAlert}
                    projectSetting={successChannel}
                    projectId={projectId}
                    canDelete={!successInherited}
                    refetchSettings={refetchSettings}
                    typeFilter={['SLACK', 'MS_TEAMS']}
                  />
                </GridCard>
                <GridCard
                  icon={<DopeIcon name="ERROR" size={20} />}
                  title={<OpSectionTitle title={'Failure Action Channel'} />}
                  action={
                    failureInherited ? (
                      <Link to={`/projects/${failureChannel?.projectId}/settings`}>
                        Inherited from {failureChannel?.projectName}
                      </Link>
                    ) : (
                      ''
                    )
                  }
                  elevation={1}
                  rounded
                >
                  <ProjectRecipientSelection
                    fieldName={'failure_channel'}
                    initialValueSet={initialFailureAlertSet}
                    setInitialValueFunc={setInitialFailureAlert}
                    projectSetting={failureChannel}
                    projectId={projectId}
                    canDelete={!failureInherited}
                    refetchSettings={refetchSettings}
                    typeFilter={['SLACK', 'MS_TEAMS']}
                  />
                </GridCard>
                <GridCard
                  icon={<DopeIcon name="ISSUE_TAKE_ACTION" size={20} />}
                  title={<OpSectionTitle title={'Bug Ticket Project'} />}
                  action={
                    bugProjectInherited ? (
                      <Link to={`/projects/${bugProject?.projectId}/settings`}>
                        Inherited from {bugProject?.projectName}
                      </Link>
                    ) : (
                      ''
                    )
                  }
                  elevation={1}
                  rounded
                >
                  <ProjectRecipientSelection
                    fieldName={'bug_project'}
                    initialValueSet={initialBugSet}
                    setInitialValueFunc={setInitialBug}
                    projectSetting={bugProject}
                    projectId={projectId}
                    canDelete={!bugProjectInherited}
                    refetchSettings={refetchSettings}
                    typeFilter={['JIRA']}
                  />
                </GridCard>
              </Card>
              <br></br>
              {testingEnabled && (
                <Card title={regionsConfigCardTitle}>
                  <GridCard
                    icon={<DopeIcon name="REGION" size={20} />}
                    title={<OpSectionTitle title={'Select Regions'} />}
                    action={
                      initialRegionsInherited ? (
                        <Link to={`/projects/${setRegions?.projectId}/settings`}>
                          Inherited from {setRegions?.projectName}
                        </Link>
                      ) : (
                        ''
                      )
                    }
                    elevation={1}
                    rounded
                  >
                    <ProjectRegionSelection
                      fieldName={'regions'}
                      initialValueSet={initialRegionsSet}
                      setInitialValueFunc={setInitialRegions}
                      projectSetting={setRegions}
                      projectId={projectId}
                      canDelete={!initialRegionsInherited}
                      refetchSettings={refetchSettings}
                    />
                  </GridCard>
                </Card>
              )}
            </>
          );
        }}
      </QueryResult>
    </>
  );
}

interface RegionSelectionProps {
  fieldName: string;
  initialValueSet: boolean;
  setInitialValueFunc;
  projectSetting: ProjectSetting;
  projectId: string;
  canDelete?: boolean;
  refetchSettings;
}

function ProjectRegionSelection(props: RegionSelectionProps) {
  const {
    fieldName,
    initialValueSet,
    setInitialValueFunc,
    projectSetting,
    projectId,
    canDelete,
    refetchSettings
  } = props;

  const [newSetting] = useMutation(SET_PROJECT_SETTING);
  const [deleteSetting] = useMutation(DELETE_PROJECT_SETTING);

  return (
    <FormField name={fieldName} hideLabel={true}>
      {(fieldRenderProps) => {
        const {
          name,
          value,
          formikContext: { setFieldValue, setFieldTouched }
        } = fieldRenderProps;
        if (!initialValueSet && projectSetting?.json_value) {
          setInitialValueFunc(true);
          setFieldValue(name, projectSetting?.json_value);
        }
        // Clear value when moving projects and value was set from an inherited project
        else if (initialValueSet && !projectSetting?.json_value) {
          setInitialValueFunc(false);
          setFieldTouched(name, false);
          setFieldValue(name, undefined);
        }

        return (
          <RegionSelection
            regionsList={value}
            onSelect={async (regionList) => {
              setFieldValue(name, regionList);
              setFieldTouched(name, true);

              const variables = {
                id: projectId,
                input: {
                  settings: [
                    {
                      name: name,
                      json_value: regionList
                    }
                  ]
                }
              };

              await newSetting({ variables });
              refetchSettings();
            }}
            onClear={() => {
              setFieldTouched(name, false);
              setFieldValue(name, undefined);
            }}
            canDelete={canDelete}
            onDelete={async () => {
              setFieldTouched(name, false);
              setFieldValue(name, undefined);

              const variables = { id: projectId, name: name };
              await deleteSetting({ variables });

              refetchSettings();
            }}
          />
        );
      }}
    </FormField>
  );
}

interface RecipientSelectionProps {
  fieldName: string;
  initialValueSet: boolean;
  setInitialValueFunc;
  projectSetting: ProjectSetting;
  projectId: string;
  canDelete?: boolean;
  refetchSettings;
  typeFilter?: string[] | undefined;
}

function ProjectRecipientSelection(props: RecipientSelectionProps) {
  const {
    fieldName,
    initialValueSet,
    setInitialValueFunc,
    projectSetting,
    projectId,
    canDelete,
    refetchSettings,
    typeFilter
  } = props;

  const [newSetting] = useMutation(SET_PROJECT_SETTING);
  const [deleteSetting] = useMutation(DELETE_PROJECT_SETTING);

  return (
    <FormField name={fieldName} hideLabel={true}>
      {(fieldRenderProps) => {
        const {
          name,
          value,
          formikContext: { setFieldValue, setFieldTouched }
        } = fieldRenderProps;

        if (!initialValueSet && projectSetting?.value) {
          setInitialValueFunc(true);
          setFieldValue(name, projectSetting?.value);
        }
        // Clear value when moving projects and value was set from an inherited project
        else if (initialValueSet && !projectSetting?.value) {
          setInitialValueFunc(false);
          setFieldTouched(name, false);
          setFieldValue(name, undefined);
        }
        return (
          <RecipientSelection
            type={typeFilter}
            recipientId={value}
            onSelect={async (recipientId) => {
              setFieldValue(name, recipientId);
              setFieldTouched(name, true);

              const variables = {
                id: projectId,
                input: {
                  settings: [
                    {
                      name: name,
                      value: recipientId
                    }
                  ]
                }
              };

              await newSetting({ variables });
              refetchSettings();
            }}
            onClear={() => {
              setFieldTouched(name, false);
              setFieldValue(name, undefined);
            }}
            canDelete={canDelete}
            onDelete={async () => {
              setFieldTouched(name, false);
              setFieldValue(name, undefined);

              const variables = { id: projectId, name: name };
              await deleteSetting({ variables });

              refetchSettings();
            }}
          />
        );
      }}
    </FormField>
  );
}

export default ProjectSettingsCard;

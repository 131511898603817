import { Form, Icon, Select, Spin } from 'antd';
import DopeIcon from 'components/ui/DopeIcon';
import { Field } from 'formik';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { RecipientIcon, RecipientLabels, RecipientTypes } from './common';

const PROJECT_SETTINGS = gql`
  query ProjectSettings($id: String!) {
    projectSettings(id: $id)
      @rest(type: "ProjectSettings", path: "/api/v2/projects/{args.id}/settings", method: "GET") {
      settings
      project_id
      name
    }
  }
`;

const fieldNameToSetting = {
  'actionConfiguration.alertRecipientSource': 'alert_channel',
  successNotificationRecipientSource: 'success_channel',
  failureNotificationRecipientSource: 'failure_channel',
  'actionConfiguration.ticketRecipientSource': 'bug_project'
};

const RecipientSelectOptionRoot = styled.div`
  display: flex;

  .recipient-select-option-meta {
    flex: 1 1 auto;
  }

  .recipient-select-option-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
`;

interface RecipientSelectFormItemProps {
  idFieldName: string;
  sourceFieldName: string;
  projectId?: string;
  recipientsResults: any;
  recipient: any;
  recipientTypes: string[];
}

function RecipientSelectFormItem(props: RecipientSelectFormItemProps) {
  const { idFieldName, sourceFieldName, projectId, recipientsResults, recipient, recipientTypes } = props;
  const { loading: recipientsLoading, error: recipientsError, data: recipientsData } = recipientsResults;

  const { loading: settingsLoading, error: settingsError, data: settingsData } = useQuery(PROJECT_SETTINGS, {
    variables: { id: projectId },
    skip: projectId === undefined
  });

  const missingProjectDefault =
    projectId !== undefined
      ? settingsData?.projectSettings?.settings.find(
          (setting) => setting.name === fieldNameToSetting[sourceFieldName]
        ) === undefined
      : false;

  return recipientsLoading || settingsLoading ? (
    <Spin />
  ) : recipientsError || settingsError ? (
    <>Error loading recipients</>
  ) : (
    <Form.Item>
      <RecipientSelectOptionRoot>
        <div className="recipient-select-option-icon">
          <RecipientIcon recipient={recipient} missingProjectDefault={missingProjectDefault} projectId={projectId} />
        </div>
        <Field name={idFieldName}>
          {({ field, form }) => {
            const { setFieldValue } = form;
            const { name, value = '', other } = field;
            return (
              <>
                <Select
                  value={value || ''}
                  onChange={(value) => {
                    setFieldValue(name, value);
                    setFieldValue(sourceFieldName, value ? 'STATIC' : 'PROJECT');
                  }}
                  {...other}
                  optionLabelProp="label"
                >
                  <Select.Option value="" label="Project Default">
                    <RecipientSelectOptionRoot>
                      <div className="recipient-select-option-icon">
                        <DopeIcon name="PROJECT" size={20} title="Project Default" />
                      </div>
                      <div className="recipient-select-option-meta">Project Default</div>
                    </RecipientSelectOptionRoot>
                  </Select.Option>
                  {recipientsData?.recipients?.items
                    ?.filter((item) => recipientTypes.includes(item.type) && RecipientLabels[item.type])
                    .sort((a, b) => {
                      const labelA = getTrimmedRecipientLabel(a);
                      const labelB = getTrimmedRecipientLabel(b);
                      if (labelA < labelB) {
                        return -1;
                      }
                      if (labelA > labelB) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((item) => {
                      const label = getTrimmedRecipientLabel(item);
                      return (
                        <Select.Option key={item.id} value={item.id} label={label}>
                          <RecipientSelectOptionRoot>
                            <div className="recipient-select-option-icon">
                              <RecipientIcon recipient={item} />
                            </div>
                            <div className="recipient-select-option-meta">{label}</div>
                          </RecipientSelectOptionRoot>
                        </Select.Option>
                      );
                    })}
                </Select>
                <a href="/organization-settings/notifications/recipients" rel="noopener noreferrer" target="_blank">
                  <Icon type="plus" title="Add Recipient" style={{ marginLeft: '4px' }} />
                </a>
              </>
            );
          }}
        </Field>
      </RecipientSelectOptionRoot>
    </Form.Item>
  );
}

function getTrimmedRecipientLabel(recipient) {
  const { name } = recipient;
  const label = RecipientLabels[recipient.type](recipient);
  const type = RecipientTypes[recipient.type];

  // trim down the nasty, automatically generated names
  const regexes = [
    / \[\S{36}\]/gm,
    /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/gm,
    /,*\s*https:\/\/outlook\.office\.com\/webhook\S*\//gm,
    /,*\s*https:\/\/hooks\.slack\.com\/services\S*\/\S*/gm,
    /^jira: /gm,
    /^slack: /gm,
    /^ms_teams: /gm,
    /^sns: /gm
  ];
  let trimmed = name;
  regexes.map((regex) => (trimmed = trimmed.replace(regex, '')));

  if (!trimmed.includes(label)) trimmed += ` (${label}}`;
  if (!trimmed?.toLowerCase().includes(type?.toLowerCase())) trimmed = `${type}: ${trimmed}`;

  return trimmed;
}

export default RecipientSelectFormItem;

import React from 'react';
import { Select } from 'antd';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import ProgressCircular from 'components/ui/ProgressCircular';
import { ScopeIdSelectorProps } from '../../types';

import { FormField } from 'components/ui/Form';

const ISSUE_DEFINITIONS_QUERY = gql`
  query IssueDefinitionsForNotificationSelect {
    issueDefinitions {
      nodes {
        id
        name
      }
    }
  }
`;

function IssueTypeSelector(props: ScopeIdSelectorProps) {
  const {
    formRenderProps: { setFieldValue }
  } = props;

  return (
    <Query query={ISSUE_DEFINITIONS_QUERY} fetchPolicy="cache-first">
      {results => {
        const { data, loading } = results;

        if (loading) return <ProgressCircular />;

        return (
          <FormField name="scope.issueDefinitionId" label="Issue Type">
            {({ name, value, handleBlur }) => (
              <Select
                value={value || 'default'}
                style={{ width: '100%' }}
                onChange={selected => {
                  const selectedStr = selected ? selected.toString() : undefined;

                  setFieldValue(name, selectedStr === 'default' ? undefined : selectedStr);
                }}
                onBlur={handleBlur}
              >
                <Select.Option value="default">Select Issue Type</Select.Option>
                {data.issueDefinitions.nodes.map((item, index) => {
                  return (
                    <Select.Option key={`${item.id}-${index}`} value={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </FormField>
        );
      }}
    </Query>
  );
}

export default IssueTypeSelector;

import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
`;

const TableBody = styled.tbody``;

const Row = styled.tr`
  min-height: 28px;

  &:nth-child(even) {
    background: ${(p) => p.theme.grey100};
  }

  .review-vertical-table & {
    border-bottom: 1px solid #e6e6e6;
    &:last-child {
      border-bottom: none;
    }

    &:nth-child(even) {
      background-color: transparent;
    }
  }
`;

const LabelCol = styled.td`
  padding: 6px 32px 6px 6px;
  color: #888888;

  .review-vertical-table & {
    padding: 16px;
    vertical-align: top;
  }
`;

const ValueCol = styled.td`
  padding: 6px;
  color: #333;
  word-break: break-word;

  .review-vertical-table & {
    padding-bottom: 24px;
    padding-top: 16px;
    padding: 16px 0 24px 24px;
  }
`;

export interface VerticalRowProps {
  key: string;
  label: string | ReactNode; // should allow ReactNode for custom labels.
  dataIndex: string;
  render?: (val: any, dataSource?: any) => ReactNode; // mimicking ant Table
}

export interface Props {
  type?: 'review';
  rows: VerticalRowProps[];
  dataSource: any;
  classes?: {
    table?: string;
    tableBody?: string;
    row?: string;
    labelCol?: string;
    valueCol?: string;
  };
}

function VerticalTable(props: Props) {
  const { dataSource, rows, classes = {}, type } = props;

  let tableClasses = classes.table || '';
  if (type === 'review') tableClasses += ` review-vertical-table`;

  return (
    <Table className={`vt-table ${tableClasses}`}>
      <TableBody className={`vt-table-body ${classes.tableBody || ''}`}>
        {rows.map((row) => {
          const val = dataSource[row.dataIndex];

          return (
            <Row key={row.key} className={`vt-row ${classes.row || ''}`}>
              <LabelCol className={`vt-label-col ${classes.labelCol || ''}`}>{row.label}</LabelCol>
              <ValueCol className={`vt-value-col ${classes.valueCol || ''}`}>
                {row.render
                  ? renderItem(row.render, val, dataSource)
                  : val !== null && val !== undefined
                  ? val.toString()
                  : ''}
              </ValueCol>
            </Row>
          );
        })}
      </TableBody>
    </Table>
  );
}

function renderItem(render: (val: any, dataSource?: any) => ReactNode, val: any, dataSource: any) {
  // catch errors if render function tries to use value that doesn't exist or something...
  try {
    const rendered = render(val, dataSource);

    return rendered;
  } catch (error) {
    return '';
  }
}

export default VerticalTable;

import { Select } from 'antd';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';

const UNIQUE_PLAYBOOK_LABELS_QUERY = gql`
  query UniquePlaybookLabels {
    uniquePlaybookLabels
  }
`;
// LABELS FILTER
interface PlaybookLabelsFilterProps {
  value?: string[] | null;
  onChange: (value: string[]) => void;
}

function PlaybookLabelsFilter(props: PlaybookLabelsFilterProps) {
  const { value, onChange } = props;

  const { data, error, loading } = useQuery(UNIQUE_PLAYBOOK_LABELS_QUERY);

  return (
    <Select
      mode="multiple"
      value={value || undefined}
      style={{ minWidth: '200px', maxWidth: '500px' }}
      onChange={onChange}
      dropdownMatchSelectWidth={false}
      placeholder={error ? 'Could not load labels' : 'Select label(s)'}
      maxTagCount={2}
      maxTagTextLength={48}
      maxTagPlaceholder={(extraValues) => {
        return <>+&nbsp;{extraValues.length}</>;
      }}
      loading={loading}
    >
      {error && (
        <Select.Option disabled key="error" value="Could not load labels">
          Could not load labels
        </Select.Option>
      )}

      {!loading &&
        data?.uniquePlaybookLabels?.map((o, idx) => {
          return (
            <Select.Option key={`${o}_${idx}`} value={o}>
              {o}
            </Select.Option>
          );
        })}
    </Select>
  );
}

export default PlaybookLabelsFilter;

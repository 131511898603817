import React from 'react';
import { TriggerSectionTitleReadViewProps } from '../definitions';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

function IssueCreatedSectionTitle(props: TriggerSectionTitleReadViewProps) {
  const {
    guardrail: { trigger }
  } = props;

  if (!trigger || !trigger.eventSource) return <>{'Select Issue'}</>;
  if (!trigger.configuration || !trigger.configuration.issueDefinitionId) return <>{'Select Issue'}</>;

  return (
    <Query
      fetchPolicy="cache-first"
      query={gql`
        query IssueDefinitionForGuardrailEditor($id: ID) {
          issueDefinitions(id: $id) {
            nodes {
              id
              name
            }
          }
        }
      `}
      variables={{ id: trigger.configuration.issueDefinitionId }}
    >
      {({ loading, error, data }) => {
        if (data) return <> {`Issue type: "${data.issueDefinitions.nodes[0].name}"`} </>;

        return <>{'When Issue type: "..." is found...'}</>;
      }}
    </Query>
  );
}

export default IssueCreatedSectionTitle;

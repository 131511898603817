import React, { PropsWithChildren } from 'react';
import { CloseButton } from 'components/buttons';
import { Typography } from 'antd';
import SpaceBetween from 'components/ui/SpaceBetween';
import Space from 'components/ui/Space';

interface Props {
  title?: string;
  onClose: () => void;
}

function ExpandedRow(props: PropsWithChildren<Props>) {
  const { title, onClose, children } = props;

  return (
    <Space direction="vertical" size={16}>
      <SpaceBetween>
        <Typography.Title level={3}>{title}</Typography.Title>
        <CloseButton onClose={onClose} delayDuration={0} />
      </SpaceBetween>

      <div>{children}</div>
    </Space>
  );
}

export default ExpandedRow;

import EditorPanel from 'designSystem/EditorPanel/EditorPanel';
import React from 'react';
import { Op } from 'typings';
import OpEditor from './OpDetail/OpEditor';
import { useHistory } from 'react-router-dom';

interface OpEditorProps {
  closeLink: string;
  op: Op;
}

function Editor(props: OpEditorProps) {
  const { closeLink, op } = props,
    history = useHistory(),
    panelTitle = `Configuring Op: ${op.name}`;

  function onClose() {
    history.push(closeLink);
  }

  return (
    <EditorPanel panelTitle={panelTitle} onClose={onClose}>
      <OpEditor op={op} closeEditor={onClose} />
    </EditorPanel>
  );
}

export default Editor;

import React from 'react';
import { OpNotificationIntegrationDefinition } from '.';
import DopeIcon from 'components/ui/DopeIcon';
import { FormField } from 'components/ui/Form';
import { SlackRecipientControl } from 'components/slack';

export const SLACK_NOTIFICATION_INTEGRATION_DEFINITION: OpNotificationIntegrationDefinition = {
  key: 'SLACK',
  title: 'Send Slack message',
  description: 'Send Slack message when an action is taken.',
  icon: <DopeIcon name="SLACK" size={20} />,
  formComponent: SlackNotificationIntegrationForm
};

function SlackNotificationIntegrationForm() {
  return (
    <FormField name="notificationRecipientId" label="Recipient">
      {fieldRenderProps => {
        const {
          value,
          name,
          formikContext: { setFieldValue, setFieldTouched }
        } = fieldRenderProps;

        return (
          <SlackRecipientControl
            recipientId={value}
            onSelect={recipientId => {
              setFieldValue(name, recipientId);
              setFieldTouched(name, true);
            }}
            onClear={() => {
              setFieldTouched(name, false);
              setFieldValue(name, undefined);
            }}
          />
        );
      }}
    </FormField>
  );
}

export default SLACK_NOTIFICATION_INTEGRATION_DEFINITION;

import React from 'react';
import { ResourceTypeConfigItem } from 'components/cloudResources/cloudResourceTypesConfig';
import { AWS_EC2_NETWORK_INTERFACES } from './queries';
import { ResourceColumn, TagList } from 'components/cloudResources';
import IssueCountBadge from 'components/issue/IssueCountBadge';
import { AWSEC2NetworkInterfaceDetailTable } from './components';

const awsEc2NetworkInterfaces: ResourceTypeConfigItem = {
  key: 'AWS::EC2::NetworkInterface', // used for routing
  label: 'EC2 Network Interfaces',
  labelPlural: 'EC2 Network Interfaces',
  labelSingular: 'EC2 Network Interface',

  tableQuery: () => AWS_EC2_NETWORK_INTERFACES, // resources table.
  tableQueryVariables: (resourceType, qs) => ({}),

  // used in detail view
  detailTable: AWSEC2NetworkInterfaceDetailTable,

  // used for inventory/resources list table
  tableColumnsConfig: [
    {
      title: 'Resource',
      key: 'resource',
      render: (_, item) => <ResourceColumn resource={item} />
    },
    {
      title: 'Account',
      key: 'account',
      className: 'no-wrap',
      dataIndex: 'account.name'
    },
    {
      title: 'Interface Type',
      dataIndex: 'interface_type',
      key: 'interface_type',
      className: 'no-wrap'
    },
    {
      title: 'Regions',
      dataIndex: 'dops_region',
      key: 'dops_region',
      className: 'no-wrap'
    },
    {
      title: 'Issues',
      dataIndex: 'dops_issue_summary.open_count',
      key: 'issue_summary_open',
      align: 'center' as 'right',
      className: 'no-wrap text-center',
      render: issueCount => <IssueCountBadge issueCount={issueCount} />
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      className: 'tags-col',
      width: '33%',
      render: tags => <TagList tags={tags} />
    }
  ]
};

export default awsEc2NetworkInterfaces;

import React from 'react';
import HelpMenu from './HelpMenu/HelpMenu';
import UserMenu from './UserMenu/UserMenu';
import { UserNavDiv } from './UserNav.styles';

function UserNav() {
  return (
    <UserNavDiv>
      <UserMenu />
      <HelpMenu />
    </UserNavDiv>
  );
}

export default UserNav;

import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { useQuery } from 'react-apollo';

import { JiraConfig, ExistingNotificationRecipient, NotificationRecipient } from 'typings';
import DopeIcon from 'components/ui/DopeIcon';
import QueryResult from 'components/util/QueryResult';
import RECIPIENT_LIST_QUERY from 'queries/recipientListQuery';
import { useHistory } from 'react-router-dom';

interface JiraRecipientControlProps {
  recipientId?: string;
  onSelect: (recipientId?: string) => void;
  onClear: () => void;
}

export default function JiraRecipientControl(props: JiraRecipientControlProps) {
  const { recipientId, onSelect, onClear } = props;
  const results = useQuery(RECIPIENT_LIST_QUERY, {
    variables: { id: recipientId },
    skip: !recipientId
  });

  return recipientId ? (
    <QueryResult
      {...results}
      skip={!recipientId}
      parseNodes={(data) => data?.recipients?.items}
      expectSingleNode
      loadingCenterVertically={false}
      loadingHeight={70}
    >
      {(recipient: ExistingNotificationRecipient) => {
        return (
          <RecipientListItem
            recipient={recipient}
            action={<Button onClick={() => onClear()}>{'Select different Recipient'}</Button>}
          />
        );
      }}
    </QueryResult>
  ) : (
    <SelectJiraRecipient onSelect={onSelect} />
  );
}

const SelectJiraRecipientRoot = styled.div`
  max-height: 900px;
  overflow-y: auto;
`;

interface SelectJiraRecipientProps {
  onSelect: (recipientId: string) => void;
}

function SelectJiraRecipient(props: SelectJiraRecipientProps) {
  const { onSelect } = props;
  const history = useHistory();

  const results = useQuery(RECIPIENT_LIST_QUERY, { variables: { type: ['JIRA'] } });

  return (
    <QueryResult
      {...results}
      parseNodes={(data) => data?.recipients?.items || []}
      loadingCenterVertically={false}
      loadingHeight={300}
    >
      {(recipients: ExistingNotificationRecipient[]) => {
        return (
          <SelectJiraRecipientRoot>
            {recipients.map((recipient) => (
              <RecipientListItem
                key={recipient.id}
                recipient={recipient}
                action={
                  <Button onClick={() => onSelect(recipient.id)} type="primary">
                    {'Use this Recipient'}
                  </Button>
                }
              />
            ))}
            {
              <AddJiraRecipientRoot
                onClick={async () => {
                  history.push(`/organization-settings/notifications/recipients/new/JIRA`);
                }}
              >
                <div className="init-connection-label">{'+ Add Jira Recipient'}</div>
              </AddJiraRecipientRoot>
            }
          </SelectJiraRecipientRoot>
        );
      }}
    </QueryResult>
  );
}

const AddJiraRecipientRoot = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background-color: #f9f9f9;
  }

  .init-connection-label {
    color: ${(p) => p.theme.primary};
    font-weight: 600;
  }
`;

const RecipientListItemRoot = styled.div`
  margin-bottom: 24px;
  padding: 6px;
  display: flex;
  &:hover {
    background-color: #f9f9f9;
  }

  .recipient-list-item-meta {
    flex: 1 1 auto;
  }

  .recipient-list-item-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .recipient-list-item-action {
    margin-left: 16px;
  }
`;

interface RecipientListItemProps {
  recipient: ExistingNotificationRecipient | NotificationRecipient;
  action?: React.ReactNode;
}

export function RecipientListItem(props: RecipientListItemProps) {
  const { recipient, action } = props;
  const { name = '' } = recipient;
  const config: any = recipient.config;
  const jiraConfig: JiraConfig = config;

  // trim down the nasty, automatically generated names
  const regexes = [/ \[\S{36}\]/gm, /\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/gm];
  let trimmed = name;
  regexes.map((regex) => (trimmed = trimmed.replace(regex, '')));

  return (
    <RecipientListItemRoot>
      <div className="recipient-list-item-icon">
        <DopeIcon name="JIRA" size={20} />
      </div>
      <div className="recipient-list-item-meta">
        {trimmed && <RecipientListItemMetaItem label="Name:" value={trimmed} />}
        <RecipientListItemMetaItem label="URL:" value={jiraConfig.url} />
        <RecipientListItemMetaItem label="Project:" value={jiraConfig.projectKey} />
        <RecipientListItemMetaItem label="Priority:" value={jiraConfig.priority} />
      </div>
      {action && <div className="recipient-list-item-action">{action}</div>}
    </RecipientListItemRoot>
  );
}

// COMPONENT: META ITEM

const RecipientListItemMetaItemRoot = styled.div`
  line-height: 1.25em;
  margin-bottom: 2px;

  .recipient-meta-item-label {
    font-weight: 600;
    display: inline-block;
    margin-right: 4px;
    color: ${(p) => p.theme.grey800};
  }
`;

interface RecipientListItemMetaItemProps {
  label: string;
  value: string;
}

function RecipientListItemMetaItem(props: RecipientListItemMetaItemProps) {
  const { label, value } = props;
  return (
    <RecipientListItemMetaItemRoot className="recipient-meta-item">
      <span className="recipient-meta-item-label">{label}</span>
      <span>{value}</span>
    </RecipientListItemMetaItemRoot>
  );
}

import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import React from 'react';
import { Op } from 'typings';
import RecipientFormat from '../../OpsCommon/RecipientFormat/RecipientFormat';

interface NotificationsProps {
  op: Op;
  contextData: any;
}

function OpDetailNotifications(props: NotificationsProps) {
  const {
    op,
    contextData: {
      recipients: { items: recipients }
    }
  } = props;

  function lookUpRecipient(id) {
    return recipients.find((r) => r.id === id);
  }

  function getDisplayElements(scenario) {
    const config = {
        success: {
          enabled: op.isSuccessNotificationEnabled,
          recipientId: op.successNotificationRecipientId,
          recipientSource: op.successNotificationRecipientSource
        },
        failure: {
          enabled: op.isFailureNotificationEnabled,
          recipientId: op.failureNotificationRecipientId,
          recipientSource: op.failureNotificationRecipientSource
        }
      },
      { enabled, recipientId, recipientSource } = config[scenario],
      recipient = recipientId ? lookUpRecipient(recipientId) : null;

    if (recipientSource === 'PROJECT') {
      return 'Project Default';
    }

    if (enabled) {
      return <RecipientFormat recipient={recipient} />;
    }

    return 'do nothing';
  }

  return (
    <>
      <HorizontalLabel label="Upon Success">{getDisplayElements('success')}</HorizontalLabel>
      <HorizontalLabel label="Upon Failure">{getDisplayElements('failure')}</HorizontalLabel>
    </>
  );
}

export default OpDetailNotifications;

import React from 'react';
import { Query } from 'react-apollo';
import { client } from 'services/graphql';
import gql from 'graphql-tag';
import { GuardrailActionProvider, SelectActionCollapseReadViewProps } from './index';
import { NewGuardrail, AutomationFunction, FunctionInputMapping } from 'typings';
import { Function } from 'typings';
import AutomationActionConfigurationInput from '../components/AutomationActionConfigurationInput';
import { Typography } from 'antd';

const AUTOMATION_FUNCTION_FIELDS_FOR_GUARDRAILS = gql`
  fragment AutomationFunctionFieldsForGuardrails on AutomationFunction {
    id
    name
    key
    description
    registration {
      arn
      method
    }
    releaseChannel
    parameters {
      name
      key
      type
      inputCode
      configCode
      defaultValue
      description
      placeholder
      many
      options
      required
    }
  }
`;

const AUTOMATION_FUNCTION_QUERY = gql`
  query AutomationFunctionQueryForGuardrailEditor(
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $releaseChannel: [String]
    $isDisabled: Boolean
  ) {
    automationFunctions(
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      releaseChannel: $releaseChannel
      isDisabled: $isDisabled
    ) {
      pageInfo {
        total
        size
      }
      nodes {
        ...AutomationFunctionFieldsForGuardrails
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_GUARDRAILS}
`;

const EVENT_DEFINITION_FUNCTIONS = gql`
  query AutomationFunctionsByEventDefinitionForGuardrailEditor($id: ID!) {
    eventDefinitionFunctions(eventDefinitionId: $id) {
      pageInfo {
        total
      }
      nodes {
        ...AutomationFunctionFieldsForGuardrails
        eventFunctions {
          functionInputMappings {
            key
            valuePath
          }
        }
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_GUARDRAILS}
`;

const automationActionApp: GuardrailActionProvider = {
  key: 'AUTOMATION_ACTION',
  label: 'Custom Action',
  usesAutomationFunctions: true,
  getAvailableActions: async (guardrail: NewGuardrail) => {
    const result = await client.query({
      query: AUTOMATION_FUNCTION_QUERY,
      variables: {
        pageSize: 1000,
        sortBy: 'name',
        sortDirection: 'ASC',
        releaseChannel: [null, 'beta', 'stable'],
        isDisabled: false
      },
      fetchPolicy: 'cache-first'
    });

    if (result.errors) throw new Error('Error fetching custom actions');

    const automationFunctions: Function[] = result.data.automationFunctions.nodes;

    return automationFunctions.map(item => ({
      id: item.id,
      key: item.id,
      label: item.name,
      description: item.description
    }));
  },
  getRecommendedActions: async (guardrail: NewGuardrail) => {
    const { filtersConfiguration } = guardrail;

    if (
      !filtersConfiguration ||
      !filtersConfiguration.FINDING_TYPE ||
      !filtersConfiguration.FINDING_TYPE.eventDefinitionId
    )
      return [];

    const eventDefinitionId = filtersConfiguration.FINDING_TYPE.eventDefinitionId;
    const result = await client.query({ query: EVENT_DEFINITION_FUNCTIONS, variables: { id: eventDefinitionId } });
    const automationFunctions: AutomationFunction[] = result.data.eventDefinitionFunctions.nodes;

    return automationFunctions.map(item => ({
      id: item.id,
      key: item.id,
      label: item.name,
      description: item.description,
      functionInputMappings: item.eventFunctions
        ? item.eventFunctions.reduce((acc: FunctionInputMapping[], func) => {
            if (func.functionInputMappings) {
              acc = acc.concat(func.functionInputMappings);
            }

            return acc;
          }, [])
        : null
    }));
  },
  configurationInput: AutomationActionConfigurationInput,
  sectionReadView: AutomationActionReadView,
  selectActionReadView: AutomationActionReadView
};

function AutomationActionReadView(props: SelectActionCollapseReadViewProps) {
  const { automationFunctionId } = props;

  if (!automationFunctionId) return <Typography.Text>{'Select Action'}</Typography.Text>;

  return (
    <Query
      query={gql`
        query AutomationFunctionById($id: [String]) {
          automationFunctions(id: $id) {
            nodes {
              ...AutomationFunctionFieldsForGuardrails
            }
          }
        }
        ${AUTOMATION_FUNCTION_FIELDS_FOR_GUARDRAILS}
      `}
      variables={{ id: [automationFunctionId] }}
    >
      {({ loading, data, error }) => {
        if (!data) return <Typography.Text>{'Select Action'}</Typography.Text>;

        const func: {
          id: string;
          name: string;
        } | null = data.automationFunctions.nodes[0] || null;

        return <>{func ? func.name : 'Select Action'}</>;
      }}
    </Query>
  );
}

export default automationActionApp;

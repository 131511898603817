import React from 'react';
import { TableWrap } from 'components/ui/Table';
import styled from 'styled-components';

const Root = styled.div`
  .ant-table-tbody > tr:last-child > td {
    border-bottom: 0;
  }
  .table-wrap {
    margin-bottom: 0;
    border: none;
    tr.ant-table-row:nth-child(even),
    tr.ant-table-row:nth-child(odd) {
      background-color: transparent;
    }
  }
`;

interface Props {
  children: React.ReactNode;
}

function ReviewTableWrap(props: Props) {
  return (
    <Root>
      <TableWrap classes={{ root: 'table-wrap' }}>{props.children}</TableWrap>
    </Root>
  );
}

export default ReviewTableWrap;

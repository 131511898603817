import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { Function, Issue } from 'typings';
import { ModalFooter, ModalHeader, ModalBody } from 'components/ui/Modal';
import CardNext from 'components/ui/CardNext';
import DopeIcon from 'components/ui/DopeIcon';

const Root = styled.div`
  .divider {
    border-bottom-color: ${p => p.theme.grey200};
  }

  .actions-list {
    //
  }
`;

interface Props {
  actionFunctions: Function[];
  issues?: Issue[];
  onCancel?: () => any;
  onCancelLabel?: string;
  onSelectAction: (method: string) => any;
  onBack?: () => any | void;
  onBackLabel?: string;
}

function SelectAction(props: Props) {
  const { actionFunctions, issues, onSelectAction, onCancel, onBack, onCancelLabel, onBackLabel } = props;

  return (
    <Root>
      <ModalHeader title="Select action" />
      <ModalBody>
        {issues && issues.length === 1 && (
          <p>
            Select an action to take on the issue <b>{issues[0].name}</b>
          </p>
        )}
        <div className="actions-list">
          {actionFunctions.map((task, idx) => {
            return (
              <CardNext
                key={`${task.id}_${idx}`}
                title={task.name}
                description={task.description}
                labels={task.effects}
                onClick={() => {
                  onSelectAction(task.id);
                }}
              />
            );
          })}
        </div>
      </ModalBody>
      <ModalFooter>
        {onCancel && (
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            {onCancelLabel || 'Cancel'}
          </Button>
        )}
        {onBack && (
          <Button
            onClick={() => {
              onBack();
            }}
            className="btn-with-icon"
          >
            <DopeIcon style={{ marginTop: '-2px' }} name="ARROW_BACK" />
            {onBackLabel || 'Back'}
          </Button>
        )}
      </ModalFooter>
    </Root>
  );
}

export default SelectAction;

import React from 'react';
import { Azure } from 'azure-react-icons';
import { AzureProps } from 'azure-react-icons/dist/components/Azure';
import { Icon as AntIcon } from 'antd';
import { MdNotifications, MdDelete } from 'react-icons/md';
import { MdNotificationsNone } from 'react-icons/md';
import { IconProps as AntIconProps } from 'antd/lib/icon';

// CUSTOM ICONS
import { ReactComponent as DopsFaviconWhite } from 'assets/svg/dops_favicon_white.svg';
import { ReactComponent as DopsFaviconBlack } from 'assets/svg/dops_favicon_black.svg';
import { ReactComponent as AWSLogo } from 'assets/svg/aws_logo.svg';
import { ReactComponent as AWSSNS } from 'assets/svg/aws_sns.svg';
import { ReactComponent as JiraLogo } from 'assets/svg/jira.svg';

// MATERIAL DESIGN
import { MdAccessTime } from 'react-icons/md';
import { MdAdd } from 'react-icons/md';
import { MdAlarm } from 'react-icons/md';
import { MdArchive } from 'react-icons/md';
import { MdArrowBack } from 'react-icons/md';
import { MdArrowDropDown } from 'react-icons/md';
import { MdArrowForward } from 'react-icons/md';
import { MdBlock } from 'react-icons/md';
import { MdBuild } from 'react-icons/md';
import { MdBusiness } from 'react-icons/md';
import { MdCancel } from 'react-icons/md';
import { MdCheckCircle } from 'react-icons/md';
import { MdCloud } from 'react-icons/md';
import { MdCloudDownload } from 'react-icons/md';
import { MdContentCopy } from 'react-icons/md';
import { MdCopyright } from 'react-icons/md';
import { MdError } from 'react-icons/md';
import { MdExpandMore } from 'react-icons/md';
import { MdFilterList } from 'react-icons/md';
import { MdFlashOff } from 'react-icons/md';
import { MdFlashOn } from 'react-icons/md';
import { MdFolder } from 'react-icons/md';
import { MdFunctions } from 'react-icons/md';
import { MdGpsFixed } from 'react-icons/md';
import { MdInfo } from 'react-icons/md';
import { MdInfoOutline } from 'react-icons/md';
import { MdLabelOutline } from 'react-icons/md';
import { MdLayers } from 'react-icons/md';
import { MdLock } from 'react-icons/md';
import { MdMenu } from 'react-icons/md';
import { MdMessage } from 'react-icons/md';
import { MdMoreVert } from 'react-icons/md';
import { MdRefresh } from 'react-icons/md';
import { MdSave } from 'react-icons/md';
import { MdStar } from 'react-icons/md';
import { MdUnarchive } from 'react-icons/md';
import { MdUndo } from 'react-icons/md';
import { MdWarning } from 'react-icons/md';

// Github Opticons
import { GoGear } from 'react-icons/go';

// IONIC?
import { IoIosArrowForward } from 'react-icons/io';

// FONT AWESOME
import { FaCube, FaCubes, FaExclamation, FaExclamationCircle, FaCheckCircle, FaPlay } from 'react-icons/fa';
import { FaArrowsAlt } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { FaExternalLinkSquareAlt } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import { FaMicrosoft } from 'react-icons/fa';
import { FaPause } from 'react-icons/fa';
import { FaPencilAlt } from 'react-icons/fa';
import { FaRegEnvelope } from 'react-icons/fa';
import { FaRoad } from 'react-icons/fa';
import { FaServer } from 'react-icons/fa';
import { FaShieldAlt } from 'react-icons/fa';
import { FaSlack } from 'react-icons/fa';
import { FaStethoscope } from 'react-icons/fa';
import { FaUser } from 'react-icons/fa';
import { FaSun, FaCloudRain, FaClipboardCheck, FaRegSquare, FaRegCheckSquare } from 'react-icons/fa';

import { FiActivity } from 'react-icons/fi';
import { FiCloudLightning } from 'react-icons/fi';

import { IoIosGitNetwork } from 'react-icons/io';

export type IconNames =
  | 'ACTION'
  | 'ACTIVITY_ALERTS_OFF'
  | 'ACTIVITY_ALERTS_ON'
  | 'ACTIVITY'
  | 'ADD'
  | 'ALARM'
  | 'ARCHIVE'
  | 'ARROW_BACK'
  | 'ARROW_DOWN'
  | 'ARROW_FORWARD'
  | 'ASSESSMENT'
  | 'AWS'
  | 'AWS_SNS'
  | 'AZURE'
  | 'CANCEL'
  | 'CHECKBOX_EMPTY'
  | 'CHECKBOX_CHECKED'
  | 'CHEVRON_RIGHT'
  | 'CLOUD_ACCOUNT'
  | 'COMPLIANCE'
  | 'COPY'
  | 'COPYRIGHT'
  | 'DEBUG'
  | 'DELETE'
  | 'DETECTOR'
  | 'DISRUPT_OPS_FAVICON_BLACK'
  | 'DISRUPT_OPS_FAVICON_WHITE'
  | 'DOWNLOAD'
  | 'DYNAMIC_CONFIG_SELECT'
  | 'EDIT'
  | 'ENVELOPE'
  | 'ERROR'
  | 'EVENT'
  | 'EXPAND_MORE'
  | 'EXTERNAL_LINK'
  | 'FILTER'
  | 'FUNCTION'
  | 'GUARDRAIL'
  | 'HOME'
  | 'INFO'
  | 'INFO_OUTLINE'
  | 'INVENTORY'
  | 'INVENTORY_HEALTHY'
  | 'INVENTORY_UPDATING'
  | 'INVENTORY_UNHEALTHY'
  | 'ISSUE_EXEMPT'
  | 'ISSUE_MARK_EXEMPT'
  | 'ISSUE_OPEN'
  | 'ISSUE_OPEN2'
  | 'ISSUE_REMOVE_EXEMPT'
  | 'ISSUE_RESOLVED'
  | 'ISSUE_TAKE_ACTION'
  | 'ISSUES'
  | 'JIRA'
  | 'LABEL'
  | 'LOCK'
  | 'MENU'
  | 'MESSAGE'
  | 'MICROSOFT_TEAMS'
  | 'MORE_VERTICAL'
  | 'NEXT_ARROW'
  | 'NOOP'
  | 'NO_ACTIONS'
  | 'OP_DECISION'
  | 'OP_RUN_NOW'
  | 'OP_RUNNING'
  | 'OP_TEMPLATE'
  | 'OP_TEMPLATES'
  | 'OP'
  | 'ORGANIZATION'
  | 'PAUSE'
  | 'PENDING'
  | 'PLAY'
  | 'PROJECT'
  | 'PROVISION'
  | 'QUESTION'
  | 'REGION'
  | 'REMOVE'
  | 'RESOURCE_ID'
  | 'RESOURCE_TYPE'
  | 'SAVE'
  | 'SETTINGS'
  | 'SLACK'
  | 'STAR'
  | 'STOP'
  | 'SUCCESS'
  | 'TIME'
  | 'TRIGGER'
  | 'UNARCHIVE'
  | 'USER'
  | 'WARNING';

export interface IconProps {
  color?: string;
  size?: string | number;
  className?: string;
  style?: any;
  name: IconNames;
  title?: string;
}

const IconConfigs: {
  [name: string]: (props: IconProps) => React.ReactNode;
} = {
  ACTION: MdFlashOn,
  ACTIVITY_ALERTS_OFF: MdNotificationsNone,
  ACTIVITY_ALERTS_ON: MdNotifications,
  ACTIVITY: FiActivity,
  ADD: MdAdd,
  ALARM: MdAlarm,
  ARCHIVE: MdArchive,
  ARROW_BACK: MdArrowBack,
  ARROW_DOWN: MdArrowDropDown,
  ARROW_FORWARD: MdArrowForward,
  ASSESSMENT: FaCube,
  AWS: (p) => <AWSLogo {...transformCustomIconProps(p)} />,
  AWS_SNS: (p) => <AWSSNS {...transformCustomIconProps(p)} />,
  AZURE: (p) => <Azure {...transformAzureIconProps(p)} />,
  CANCEL: MdCancel,
  CHECKBOX_CHECKED: FaRegCheckSquare,
  CHECKBOX_EMPTY: FaRegSquare,
  CHEVRON_RIGHT: IoIosArrowForward,
  CLOUD_ACCOUNT: MdCloud,
  COMPLIANCE: FaShieldAlt,
  COPY: MdContentCopy,
  COPYRIGHT: MdCopyright,
  DEBUG: FaStethoscope,
  DELETE: MdDelete,
  DETECTOR: FaCube,
  DISRUPT_OPS_FAVICON_BLACK: (p) => <DopsFaviconBlack {...transformCustomIconProps(p)} />,
  DISRUPT_OPS_FAVICON_WHITE: (p) => <DopsFaviconWhite {...transformCustomIconProps(p)} />,
  DOWNLOAD: MdCloudDownload,
  DYNAMIC_CONFIG_SELECT: (p) => <AntIcon {...transformAntIconProps(p)} type="code" />,
  EDIT: FaPencilAlt,
  ENVELOPE: FaRegEnvelope,
  ERROR: MdError,
  EVENT: FiCloudLightning,
  EXPAND_MORE: MdExpandMore,
  EXTERNAL_LINK: FaExternalLinkSquareAlt,
  FILTER: MdFilterList,
  FUNCTION: MdFunctions,
  GUARDRAIL: FaRoad,
  HOME: (p) => <AntIcon {...transformAntIconProps(p)} type="home" />,
  INFO_OUTLINE: MdInfoOutline,
  INFO: MdInfo,
  INVENTORY_HEALTHY: FaSun,
  INVENTORY_UNHEALTHY: FaCloudRain,
  INVENTORY_UPDATING: MdRefresh,
  INVENTORY: FaServer,
  ISSUE_EXEMPT: MdBlock,
  ISSUE_MARK_EXEMPT: MdBlock,
  ISSUE_OPEN: FaExclamationCircle,
  ISSUE_OPEN2: FaExclamation,
  ISSUE_REMOVE_EXEMPT: MdUndo,
  ISSUE_RESOLVED: FaCheckCircle,
  ISSUE_TAKE_ACTION: MdBuild,
  ISSUES: FaCheck,
  JIRA: (p) => <JiraLogo {...transformCustomIconProps(p)} />,
  LABEL: MdLabelOutline,
  LOCK: MdLock,
  MENU: MdMenu,
  MESSAGE: MdMessage,
  MICROSOFT_TEAMS: FaMicrosoft,
  MORE_VERTICAL: MdMoreVert,
  NEXT_ARROW: (p) => <AntIcon {...transformAntIconProps(p)} type="right-circle-o" />,
  NOOP: MdCheckCircle,
  NO_ACTIONS: MdFlashOff,
  OP_DECISION: FaArrowsAlt,
  OP_RUN_NOW: (p) => <AntIcon {...transformAntIconProps(p)} type="play-circle" />,
  OP_RUNNING: (p) => <AntIcon {...transformAntIconProps(p)} type="check-circle" />,
  OP_TEMPLATE: MdLayers,
  OP_TEMPLATES: FaCubes,
  OP: IoIosGitNetwork,
  ORGANIZATION: MdBusiness,
  PAUSE: FaPause,
  PENDING: MdAccessTime,
  PLAY: FaPlay,
  PROJECT: MdFolder,
  PROVISION: FaClipboardCheck,
  QUESTION: (p) => <AntIcon {...transformAntIconProps(p)} type="question-circle" />,
  REGION: FaGlobe,
  REMOVE: (p) => <AntIcon {...transformAntIconProps(p)} type="minus-circle" />,
  SAVE: MdSave,
  SETTINGS: GoGear,
  SLACK: FaSlack,
  STAR: MdStar,
  STOP: (p) => <AntIcon {...transformAntIconProps(p)} type="stop" />,
  SUCCESS: MdCheckCircle,
  TIME: MdAccessTime,
  TRIGGER: MdGpsFixed,
  UNARCHIVE: MdUnarchive,
  USER: FaUser,
  WARNING: MdWarning
};

function transformCustomIconProps(props: IconProps) {
  const computedSize = props.size || '1em';

  return {
    width: computedSize,
    height: computedSize,
    ...props
  };
}

function DopeIcon(props: IconProps) {
  const { name } = props;

  const icon = IconConfigs[name];

  return <>{icon(props)}</>;
}

function transformAzureIconProps(iconProps: IconProps): AzureProps {
  let { className, size } = iconProps;

  let azureSize = '20';
  if (size) {
    azureSize = typeof size === 'string' ? size : `${size}`;
  }

  return {
    className,
    size: azureSize
  };
}

function transformAntIconProps(iconProps: IconProps): AntIconProps {
  const { className, size, color, style } = iconProps;
  let styles = { ...style };

  if (size) {
    styles.fontSize = typeof size === 'string' ? size : `${size}px`;
  }

  if (color) {
    styles.color = color;
  }

  return {
    className,
    style: styles
  };
}

export default DopeIcon;

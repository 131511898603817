import React, { useEffect, useState } from 'react';
import { Op } from 'typings';
import VendorIcon from '../VendorIcon/VendorIcon';
import { VendorLabelRow } from '../VendorLabelRow.styles';

interface TriggerBannerProps {
  op: Op;
  withFilter?: boolean;
}

const filterKeys = [
  'triggerProjectIds',
  'accountIds',
  'accountLabels',
  'environments',
  'regions',
  'eventSourceId',
  'severity',
  'resourceType',
  'tags',
  'jsonPaths'
];

function TriggerBanner(props: TriggerBannerProps) {
  const { op, withFilter = false } = props,
    eventDefinition = op?.eventDefinition,
    [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    if (op && withFilter) {
      const count = filterKeys.reduce((acc, key) => {
        if (op[key]) {
          return (acc += 1);
        }

        return acc;
      }, 0);

      setFilterCount(count);
    }
  }, [op, withFilter]);

  return (
    <>
      <VendorLabelRow className="trigger-row">
        {eventDefinition?.vendor && (
          <div className="vendor-icon">
            <VendorIcon vendor={eventDefinition.vendor} />
          </div>
        )}
        <div className="trigger-name" data-testid="trigger-name">
          {eventDefinition?.name || 'All Findings'}
        </div>
      </VendorLabelRow>
      {filterCount > 0 && withFilter && (
        <div data-testid="filter-count" style={{ fontSize: '12px' }}>
          <span data-testid="filter-count-badge">{filterCount}</span> Filters
        </div>
      )}
    </>
  );
}

export default TriggerBanner;

import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useMutation } from 'react-apollo';
import { Form, FormField } from 'components/ui/Form';
import { Input, message, Alert, Button, Row, Col } from 'antd';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import ErrorAlert from 'components/ui/ErrorAlert';
import CountrySelect from './CountrySelect';
import { restRequest } from '../../../../../utilities/rest';
import StateSelect from './StateSelect';

const CREATE_CLIENT = gql`
  mutation CreateClient($clientId: String!, $input: FirstTimeSetupInput!) {
    createClient(input: $input) 
      @rest(path: "/api/v2/clients/", method: "POST") {
        client_id
      }
  }
`;

interface IFormFields {
  companyName: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  phone: string;
  country: string;
  state?: string;
}

function getHubspotPayload(values: IFormFields) : any {
  const { firstName, lastName, companyName, email, phone, country, state } = values || {} as any;

  return {
    fields: [
      {
        name: 'firstname',
        value: firstName
      },
      {
        name: 'lastname',
        value: lastName
      },
      {
        name: 'company',
        value: companyName
      },
      {
        name: 'email',
        value: email
      },
      {
        name: 'phone',
        value: phone
      },
      {
        name: 'country',
        value: country
      },
      {
        name: 'state',
        value: country === 'United States' ? state : undefined
      },
    ]
  };
}

interface Props extends RouteComponentProps<any> {
  inviteUsername?: string;
  inviteCompanyName?: string;
}

function NewAccountPageEmbeddedLong(props: Props) {
  const { location } = props;
  const params = new URLSearchParams(location.search);
  const billingId = params.has('awsCustomerId') ? params.get('awsCustomerId') : undefined;
  const [registeredEmail, setRegisteredEmail] = useState<string | null>(null);
  const [createClient, { error, loading }] = useMutation(CREATE_CLIENT);

  if (registeredEmail) {
    return (
      <Alert
        type="success"
        message={`An email has been sent to ${registeredEmail} with instructions to setup your new DisruptOps account.`}
      />
    );
  }

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required('Required'),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    username: Yup.string().required('Required'),
    email: Yup.string()
      .strict(true)
      .trim('Leading or trailing whitespace characters are not allowed.')
      .email('Valid email address required.')
      .required('Required'),
    phone: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    state: Yup.string()
      .when('country', {
        is: value => value && value === 'United States',
        then: Yup.string().required('Required for United States residents'),
        otherwise: Yup.string()
      })
  });

  return (
    <>
      {error && <ErrorAlert error={error} />}
      <Form
        allowCleanSubmits={false}
        validationSchema={validationSchema}
        initialValues={{
          companyName: '',
          firstName: '',
          lastName: '',
          username: '',
          email: '',
          phone: '',
          country: 'United States',
          state: ''
        }}
        onSubmit={async (values: any, actions: any) => {
          const { companyName, firstName, lastName, username, email } = values;

          try {
            const response = await createClient({
              variables: {
                input: {
                  company_name: companyName,
                  first_name: firstName,
                  last_name: lastName,
                  username: username,
                  email: email,
                  billingId: billingId,
                  billingVendor: billingId ? 'aws' : undefined
                }
              }
            });

            if (response.errors)
              throw new Error(response.errors[0].message);

            setRegisteredEmail(email);

            const portalId = '4832527';
            const formId = '946c3c86-0817-4e51-a952-c5ee091513da';
            await restRequest({
              url: `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
              method: 'POST',
              payload: getHubspotPayload(values)
            });
          } catch (e) {
            message.error('There was a problem registering your new account.', e.message);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(formRenderProps) => {
          const { canSubmit, isSubmitting, submitForm, setFieldValue, values } = formRenderProps;
          const { country } = values;
          const stateEnabled = country === 'United States';
          const gutter = 20;
          const span = 12;

          return (
            <div>
              <Row gutter={gutter}>
                <Col span={span}>
                  <FormField name='firstName' label='First Name' className='.firstName'>
                    {({ name, value, handleChange, handleBlur }) => (
                      <Input autoFocus name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                    )}
                  </FormField>
                </Col>
                <Col span={span}>
                  <FormField name='lastName' label='Last Name' className='.lastName'>
                    {({ name, value, handleChange, handleBlur }) => (
                      <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                    )}
                  </FormField>
                </Col>
              </Row>

              <Row>
                <FormField name='companyName' label='Company Name' className='.companyName'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>
              </Row>

              <Row gutter={gutter}>
                <Col span={span}>
                  <FormField name='email' label='Email' className='.email'>
                    {({ name, value, handleChange, handleBlur }) => (
                      <Input
                        name={name}
                        value={value}
                        onChange={(e: any) => {
                          const { target } = e;
                          setFieldValue(name, target.value);
                          setFieldValue('username', target.value);
                        }}
                        onBlur={handleBlur} />
                    )}
                  </FormField>
                </Col>
                <Col span={span}>
                  <FormField name='username' label='Username' className='.username'>
                    {({ name, value, handleChange, handleBlur }) => (
                      <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                    )}
                  </FormField>
                </Col>
              </Row>

              <Row>
                <FormField name='phone' label='Phone' className='.phone'>
                  {({ name, value, handleChange, handleBlur }) => (
                    <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                  )}
                </FormField>
              </Row>

              <Row gutter={gutter}>
                <Col span={span}>
                  <FormField name="country" label="Country" className='.country'>
                    {({ name, value, handleChange, handleBlur }) => (
                      <CountrySelect name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                    )}
                  </FormField>
                </Col>
                <Col span={span}>
                  {stateEnabled ?
                    <FormField name='state' label='State/Region' className='.state'>
                      {({ name, value, handleChange, handleBlur }) => (
                        <StateSelect name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
                      )}
                    </FormField>
                    : null
                  }
                </Col>
              </Row>

              <Button
                className='.submitButton'
                onClick={submitForm}
                type="primary"
                disabled={!canSubmit || loading || isSubmitting}
                loading={loading || isSubmitting}
              >
                Submit
              </Button>
            </div>
          );
        }}
      </Form>
    </>
  );
}

export default NewAccountPageEmbeddedLong;

import styled from 'styled-components';

export const CreatePanelDiv = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: calc(100% - 2rem);
  border-radius: 3px;
  background-color: white;
  z-index: 1;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.4);

  .header {
    border-bottom: 1px solid ${(p) => p.theme.border};
    padding: 1rem;
  }

  .header__title {
    font-size: 20px;
  }

  .main-content {
    padding: 1rem;
  }
`;

import React, { useState, useEffect, useMemo } from 'react';
import ACTION_PROVIDER_DEFINITIONS, { GuardrailActionProvider, ActionDefinition } from '../definitions';
import { NewGuardrail } from 'typings';
import ProgressCircular from 'components/ui/ProgressCircular';
import { Typography, Select } from 'antd';
import { FormField } from 'components/ui/Form';
import { AuthorizePermissionsResults } from 'components/app/Auth/Authorizor';

interface Props {
  actionProviderKey: string | null;
  guardrail: NewGuardrail;
  authZ: AuthorizePermissionsResults;
}

function GuardrailSelectActionFunction(props: Props) {
  const { guardrail, actionProviderKey, authZ } = props;

  const [fetchingAvailableActions, setFetching] = useState<boolean>(false);
  const [availableActions, setAvailableActions] = useState<ActionDefinition[] | null>(null);
  const [recommendedActions, setRecommendedActions] = useState<ActionDefinition[] | null>(null);
  const [filteredAvailableActions, setFilteredAvailableActions] = useState<ActionDefinition[] | null>(null);
  const selectedActionProvider: GuardrailActionProvider | null = useMemo(() => {
    return actionProviderKey ? ACTION_PROVIDER_DEFINITIONS.find(item => item.key === actionProviderKey) || null : null;
  }, [actionProviderKey]);

  useEffect(() => {
    const asyncFn = async () => {
      if (!selectedActionProvider) {
        return;
      }
      setFetching(true);
      const [availableActions, recommendedActions] = await Promise.all([
        selectedActionProvider.getAvailableActions(guardrail),
        selectedActionProvider.getRecommendedActions ? selectedActionProvider.getRecommendedActions(guardrail) : []
      ]);

      // this the best way to do this?
      setRecommendedActions(recommendedActions);
      setAvailableActions(availableActions);
      setFilteredAvailableActions(availableActions);
      setFetching(false);
    };

    asyncFn();
  }, [guardrail, selectedActionProvider]);

  if (!selectedActionProvider) return null;
  if (fetchingAvailableActions) return <ProgressCircular height={100} />;

  const recommendedIds =
    recommendedActions && recommendedActions.length > 0 ? recommendedActions.map(item => item.key) : null;

  const otherActions = recommendedIds
    ? (filteredAvailableActions && filteredAvailableActions.filter(item => !recommendedIds.includes(item.key))) ||
      new Array<ActionDefinition>()
    : filteredAvailableActions;

  if (!recommendedIds && !availableActions)
    return <Typography.Text>{'There are no available actions for this action type.'}</Typography.Text>;

  return (
    <FormField
      name={selectedActionProvider.usesAutomationFunctions ? 'automationFunctionId' : 'functionId'}
      label={'Action'}
    >
      {({ name, value, handleBlur, formikContext: { setFieldValue } }) => {
        return (
          <Select
            disabled={!authZ.isAuthorized}
            value={value || undefined}
            onChange={value => {
              setFieldValue(name, value);

              const matchingRecommendedAction = recommendedActions
                ? recommendedActions.find(item => item.key === value) || null
                : null;

              if (!matchingRecommendedAction) return;

              if (matchingRecommendedAction && matchingRecommendedAction.functionInputMappings) {
                setFieldValue(
                  'jsonpathConfiguration',
                  matchingRecommendedAction.functionInputMappings.reduce((acc, item) => {
                    acc[item.key] = item.valuePath;

                    return acc;
                  }, {})
                );
              } else {
                setFieldValue('jsonpathConfiguration', undefined);
              }
            }}
            onBlur={handleBlur}
            placeholder="Select Action"
            filterOption={false}
            showSearch={true}
            onSearch={searchValue => {
              const searchValueLower = searchValue.toLowerCase();
              const filteredActions = !searchValue
                ? availableActions
                : availableActions?.filter(action => action.label.toLowerCase().includes(searchValueLower)) ||
                  new Array<ActionDefinition>();
              setFilteredAvailableActions(filteredActions);
            }}
          >
            {recommendedActions && recommendedActions.length > 0 && (
              <Select.OptGroup label="Recommended Actions">
                {recommendedActions.map(action => {
                  return (
                    <Select.Option key={action.key} value={action.key}>
                      {action.label}
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            )}
            {otherActions && otherActions.length > 0 && (
              <Select.OptGroup label="Available Actions">
                {otherActions.map(action => {
                  return (
                    <Select.Option key={action.key} value={action.key}>
                      {action.label}
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            )}
          </Select>
        );
      }}
    </FormField>
  );
}

export default GuardrailSelectActionFunction;

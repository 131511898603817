import React, { ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  emailAddress: string;
  subject?: string;
  body?: string;
}

function MailTo(props: Props) {
  const { emailAddress, children } = props;

  const qsFields = ['subject', 'body'];

  const qs = qsFields.reduce((queryString, key) => {
    const value = props[key];
    if (!key || !value) return qs;
    return `${queryString}&${key}=${encodeURI(value)}`;
  }, '');

  const queryString = qs.length > 0 ? `?${qs.join('&')}` : '';

  const href = `mailto:${emailAddress}${queryString}`;

  return <a href={href}>{children || emailAddress}</a>;
}

export default MailTo;

import React from 'react';
import { OpNotificationIntegrationDefinition } from '.';
import DopeIcon from 'components/ui/DopeIcon';
import { FormField } from 'components/ui/Form';
import { MSTeamsRecipientControl } from 'components/msTeams/MSTeamsRecipientControl';

export const MS_TEAMS_NOTIFICATION_INTEGRATION_DEFINITION: OpNotificationIntegrationDefinition = {
  key: 'MS_TEAMS',
  title: 'Send Microsoft Teams message',
  description: 'Send Microsoft Teams message when an action is taken.',
  icon: <DopeIcon name="MICROSOFT_TEAMS" size={20} />,
  formComponent: MSTeamsNotificationForm
};

function MSTeamsNotificationForm() {
  return (
    <FormField name="notificationRecipientId" label="Recipient">
      {fieldRenderProps => {
        const {
          value,
          name,
          formikContext: { setFieldValue, setFieldTouched }
        } = fieldRenderProps;

        return (
          <MSTeamsRecipientControl
            recipientId={value}
            onClear={() => {
              setFieldTouched(name, false);
              setFieldValue(name, undefined);
            }}
            onSelect={recipientId => {
              setFieldValue(name, recipientId);
              setTimeout(() => setFieldTouched(name, true), 1);
            }}
          />
        );
      }}
    </FormField>
  );
}

export default MS_TEAMS_NOTIFICATION_INTEGRATION_DEFINITION;

import React from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Row, Col, message, Input } from 'antd';
import ErrorAlert from 'components/ui/ErrorAlert';
import { CenteredContainer } from 'components/ui/NeoPage';

const Root = styled.div`
  .centered-container {
    padding: 0;
  }
`;

const FormItem = Form.Item;

const ActionsWrap = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-left: 16px;
  }
`;

const INVITE_MUTATION = gql`
  mutation Invite($input: InviteInput!) {
    postInvite(input: $input) @rest(type: "TeamMember", path: "/invite", method: "POST") {
      created
      invite_id
      first_name
      last_name
      redeemed_at
      username
      target_username
      status
    }
  }
`;

interface FormInput {
  first_name: string;
  last_name: string;
  username: string;
  email: string;
}

const formSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  last_name: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  email: Yup.string()
    .strict(true)
    .trim('Leading or trailing whitespace characters are not allowed.')
    .email('Must use valid email')
    .required('Required') // MAKE EMAIL
});

export interface Props {
  onCancel: () => any;
  onFinish: () => any;
}

function InviteForm(props: Props) {
  const { onCancel, onFinish } = props;

  const [postInvite, { error }] = useMutation(INVITE_MUTATION);

  return (
    <Root>
      <Formik
        validationSchema={formSchema}
        initialValues={{
          first_name: '',
          last_name: '',
          email: '',
          username: ''
        }}
        onSubmit={async (values: FormInput, actions: any) => {
          const { first_name, last_name, email, username } = values;

          try {
            await postInvite({
              variables: {
                input: {
                  first_name,
                  last_name,
                  target_email: email,
                  target_username: username
                }
              }
            });

            message.success(
              `${first_name} ${last_name} was successfully added to the team as a read-only user. An invite will be sent to ${email}. Visit the "Group Management" section to manage this user's access.`,
              5
            );

            onFinish();
          } catch (e) {
            message.error(`An occurred when inviting ${first_name} ${last_name}`);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          errors: validationErrors,
          touched,
          dirty
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              {error && <ErrorAlert error={error} />}

              <CenteredContainer size="sm" leftAlign>
                <Row type="flex" gutter={24}>
                  <Col xs={24} lg={12}>
                    <FormItem
                      label="First name"
                      validateStatus={touched.first_name && validationErrors.first_name ? 'warning' : 'success'}
                      help={touched.first_name && validationErrors.first_name}
                    >
                      <Input
                        onChange={handleChange}
                        name="first_name"
                        width="100%"
                        value={values.first_name}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                      />
                    </FormItem>
                  </Col>

                  <Col xs={24} lg={12}>
                    <FormItem
                      label="Last name"
                      validateStatus={touched.last_name && validationErrors.last_name ? 'warning' : 'success'}
                      help={touched.last_name && validationErrors.last_name}
                    >
                      <Input
                        title="Last name"
                        onChange={handleChange}
                        name="last_name"
                        width="100%"
                        value={values.last_name}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row type="flex" gutter={24}>
                  <Col xs={24} lg={12}>
                    <FormItem
                      label="Username"
                      validateStatus={touched.username && validationErrors.username ? 'warning' : 'success'}
                      help={touched.username && validationErrors.username}
                    >
                      <Input
                        title="Username"
                        onChange={handleChange}
                        name="username"
                        width="100%"
                        value={values.username}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                      />
                    </FormItem>
                  </Col>

                  <Col xs={24} lg={12}>
                    <FormItem
                      label="Email"
                      validateStatus={touched.email && validationErrors.email ? 'warning' : 'success'}
                      help={touched.email && validationErrors.email}
                    >
                      <Input
                        title="Email"
                        onChange={handleChange}
                        name="email"
                        width="100%"
                        value={values.email}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                      />
                    </FormItem>
                  </Col>
                </Row>

                <ActionsWrap>
                  <Button
                    disabled={!dirty || isSubmitting || Object.keys(validationErrors).length > 0}
                    loading={isSubmitting}
                    type="primary"
                    htmlType="submit"
                  >
                    Send invite
                  </Button>

                  <Button disabled={isSubmitting} onClick={onCancel}>
                    Cancel
                  </Button>
                </ActionsWrap>
              </CenteredContainer>
            </Form>
          );
        }}
      </Formik>
    </Root>
  );
}

export default InviteForm;

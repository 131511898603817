import DopeIcon from 'components/ui/DopeIcon';
import React from 'react';
import { Recipient } from 'typings';

interface RecipientFormatProps {
  recipient: Recipient;
}

interface FormatRecipientProps {
  recipientId: string;
  recipients: Recipient[];
}

export function FormatRecipient(props: FormatRecipientProps) {
  const { recipientId, recipients } = props;
  const recipient = recipientId ? recipients.find((r) => r.id === recipientId) : null;

  if (!recipient) {
    return <>{recipientId || 'project default'}</>;
  }

  return <RecipientFormat recipient={recipient} />;
}

function RecipientFormat(props: RecipientFormatProps) {
  const { recipient } = props,
    size = 16;

  let icon, label;

  switch (recipient.type) {
    case 'JIRA':
      icon = <DopeIcon className="icon" name="JIRA" size={size} title="Jira" />;
      label = `Project: ${recipient.config?.projectKey}${
        recipient.config?.priority ? `, Priority: ${recipient.config?.priority}` : ''
      }`;
      break;
    case 'MS_TEAMS':
      icon = <DopeIcon className="icon" name="MICROSOFT_TEAMS" size={size} title="Microsoft Teams" />;
      label = recipient.config?.context?.channelName;
      break;
    case 'SLACK':
      icon = <DopeIcon className="icon" name="SLACK" size={size} title="Slack" />;
      label = recipient.config.channel;
      break;
    case 'SNS':
      icon = <DopeIcon className="icon" name="AWS_SNS" size={size} title="AWS SNS" />;
      label = recipient.config.topicArn;
      break;
    case 'EMAIL':
      icon = <DopeIcon className="icon" name="ENVELOPE" size={size} title="EMAIL" />;
      label = recipient.config?.toAddresses?.join('; ');
      break;
  }

  return (
    <div>
      <span style={{ transform: 'translateY(3px)', display: 'inline-block' }}>{icon}</span> {label}
    </div>
  );
}

export default RecipientFormat;

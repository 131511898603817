import React from 'react';
import { Select } from 'antd';
import { ModeOption } from 'antd/lib/select';
import { SEVERITY_NAME_MAP } from 'constants/ui';

export interface Props {
  value?: string | string[];
  onChange: (value: any) => any;
  mode?: ModeOption;
  placeholder?: string;
  onBlur?: (event: any) => any;
}

function SeverityFilter(props: Props) {
  const { value, onChange, onBlur } = props;

  const mode = props.mode ? props.mode : 'multiple';
  const placeholder = props.placeholder ? props.placeholder : 'Any Severity';

  return (
    <Select
      value={value === null ? undefined : value}
      style={{ width: '100%', minWidth: '200px' }}
      onChange={(newValue: string | string[]) => {
        if (!newValue) {
          onChange(newValue);
          return;
        }
        const newValues: string[] = Array.isArray(newValue) ? newValue : [newValue];
        const convertedValues = newValues?.map(s => parseInt(s, 10));
        onChange(convertedValues);
      }}
      placeholder={placeholder}
      mode={mode}
      onBlur={onBlur}
      filterOption={(inputValue, option) => {
        if (!option || !option.props || !option.props.title) {
          return true;
        }
        return new RegExp(inputValue, 'i').test(option.props.title);
      }}
    >
      {SEVERITY_NAME_MAP.map((severity, index) => (
        <Select.Option key={severity} value={index + 1}>
          {severity} ({index + 1})
        </Select.Option>
      ))}
    </Select>
  );
}

export default SeverityFilter;

import gql from 'graphql-tag';

const CLOUD_ACCOUNT_LIST_QUERY = gql`
  query CloudAccountList {
    accounts {
      items {
        account_id
        assumerole_account_id
        assumerole_arn
        assumerole_external_id
        client_id
        created
        name
        nickname
        policy_code
        provider
        provision_url
        provisioner_arn
        provisioner_external_id
        updated
        environment
        labels
      }
    }
  }
`;

export default CLOUD_ACCOUNT_LIST_QUERY;

import React from 'react';
import { Button } from 'antd';

interface Props {
  onClose: () => any | void;
  delayDuration?: number;
}

function CloseButton(props: Props) {
  const { onClose, delayDuration = 300 } = props;

  return (
    <Button
      type="ghost"
      icon="close"
      style={{ border: 'none', height: '20px', padding: '0 2px' }}
      onClick={() => setTimeout(() => onClose(), delayDuration)}
    />
  );
}

export default CloseButton;

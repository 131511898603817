import React, { PropsWithChildren, Children } from 'react';
import styled from 'styled-components';

interface Props {
  direction: 'vertical' | 'horizontal';
  size: 2 | 4 | 6 | 8 | 12 | 16 | 32 | 48;
}

const Root = styled.div`
  &.vertical > * {
    margin-bottom: ${(p) => p.size}px;
    overflow: hidden;
  }

  &.vertical > *:last-child {
    margin-bottom: 0;
  }

  &.horizontal {
    display: flex;
  }

  &.horizontal > * {
    margin-right: ${(p) => p.size}px;
  }

  &.horizontal > *:empty,
  &.horizontal > *:last-child {
    margin-right: 0;
  }
`;

function Space(props: PropsWithChildren<Props>) {
  const { direction, children, size } = props;

  return (
    <Root className={direction} size={size}>
      {Children.map(children, (child, i) => child && <div>{child}</div>)}
    </Root>
  );
}

export default Space;

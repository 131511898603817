import styled from 'styled-components';

export const DetectorFiltersDiv = styled.div`
  div:not(.value):not(.label) {
    margin: 0 !important;
    padding: 0 !important;
    background: transparent !important;
  }

  .json-tree-container ul {
    background: transparent !important;
    margin-top: 0 !important;
    padding-top: 0 !important;
  }

  .config-meta {
    svg {
      fill: #333 !important;
    }

    * {
      color: #333 !important;
    }
  }
`;

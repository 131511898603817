import React, { ReactNode, ReactNodeArray } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  background-color: #fff;

  &.elevation- {
    &1 {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }

    &2 {
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
    }

    &3 {
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 3px 3px -2px rgba(0, 0, 0, 0.12);
    }

    &4 {
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    &5 {
      box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
        0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    }
  }

  &.rounded {
    border-radius: 4px;
  }
`;

export interface Props {
  elevation?: 0 | 1 | 2 | 3 | 4 | 5;
  children: ReactNode | ReactNodeArray;
  rounded?: boolean;
  classes?: {
    root?: string;
  };
}

function Paper(props: Props) {
  const { elevation = 0, children, classes = {}, rounded = false } = props;

  let rootClasses = `paper ${classes.root || ''}`;
  rootClasses += ` elevation-${elevation}`;
  if (rounded) rootClasses += ' rounded';

  return <Root className={rootClasses}>{children}</Root>;
}

export default Paper;

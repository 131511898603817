import React, { Component } from 'react';
import { Select, InputNumber } from 'antd';
import styled from 'styled-components';

import { size } from 'typings';
import validator from 'services/validator';

import { FormField } from 'components/ui/Form';

const IPServiceWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  column-gap: 10px;
  margin-right: 10px;

  .ant-input-number,
  .ant-select {
    width: 100%;
  }
`;

const IP_PROTOCOLS = ['tcp', 'udp', 'icmp'];

const PREDEFINED_SERVICES = [
  {
    serviceCode: 'ssh',
    label: 'SSH',
    ipProtocol: 'tcp',
    fromPort: 22,
    toPort: 22
  },
  {
    serviceCode: 'smtp',
    label: 'SMTP',
    ipProtocol: 'tcp',
    fromPort: 25,
    toPort: 25
  },
  {
    serviceCode: 'dns',
    label: 'DNS (TCP)',
    ipProtocol: 'tcp',
    fromPort: 53,
    toPort: 53
  },
  {
    serviceCode: 'dns_udp',
    label: 'DNS (UDP)',
    ipProtocol: 'udp',
    fromPort: 53,
    toPort: 53
  },
  {
    serviceCode: 'http',
    label: 'HTTP',
    ipProtocol: 'tcp',
    fromPort: 80,
    toPort: 80
  },
  {
    serviceCode: 'pop3',
    label: 'POP3',
    ipProtocol: 'tcp',
    fromPort: 110,
    toPort: 110
  },
  {
    serviceCode: 'imap',
    label: 'IMAP',
    ipProtocol: 'tcp',
    fromPort: 143,
    toPort: 143
  },
  {
    serviceCode: 'ldap',
    label: 'LDAP',
    ipProtocol: 'tcp',
    fromPort: 389,
    toPort: 389
  },
  {
    serviceCode: 'https',
    label: 'HTTPs',
    ipProtocol: 'tcp',
    fromPort: 443,
    toPort: 443
  },
  {
    serviceCode: 'smb',
    label: 'SMB',
    ipProtocol: 'tcp',
    fromPort: 445,
    toPort: 445
  },
  {
    serviceCode: 'smtps',
    label: 'SMTPS',
    ipProtocol: 'tcp',
    fromPort: 465,
    toPort: 465
  },
  {
    serviceCode: 'imaps',
    label: 'IMAPS',
    ipProtocol: 'tcp',
    fromPort: 993,
    toPort: 993
  },
  {
    serviceCode: 'pop3s',
    label: 'POP3S',
    ipProtocol: 'tcp',
    fromPort: 995,
    toPort: 995
  },
  {
    serviceCode: 'mssql',
    label: 'MSSQL',
    ipProtocol: 'tcp',
    fromPort: 1433,
    toPort: 1433
  },
  {
    serviceCode: 'nfs',
    label: 'NFS',
    ipProtocol: 'tcp',
    fromPort: 2049,
    toPort: 2049
  },
  {
    serviceCode: 'mysql',
    label: 'MySQL',
    ipProtocol: 'tcp',
    fromPort: 3306,
    toPort: 3306
  },
  {
    serviceCode: 'rdp',
    label: 'RDP',
    ipProtocol: 'tcp',
    fromPort: 3389,
    toPort: 3389
  },
  {
    serviceCode: 'redshift',
    label: 'Redshift',
    ipProtocol: 'tcp',
    fromPort: 5439,
    toPort: 5439
  },
  {
    serviceCode: 'postgres',
    label: 'PostgreSQL',
    ipProtocol: 'tcp',
    fromPort: 5432,
    toPort: 5432
  },
  {
    serviceCode: 'redis',
    label: 'Redis',
    ipProtocol: 'tcp',
    fromPort: 6379,
    toPort: 6380
  }
];

const MIN_PORT_NUMBER = 0;
const MAX_PORT_NUMBER = 65535;

// can the op handle 'custom'? or should we return undefined in that case?
const ipServiceCodeOptions = PREDEFINED_SERVICES.map(s => s.serviceCode);

const schema = validator.object().shape({
  ip_service: validator.string().oneOf(ipServiceCodeOptions), // I don't believe this is required unless we add .required()? I'd like to test with an assessment though.
  ip_protocol: validator.string().oneOf(IP_PROTOCOLS),
  from_port: validator
    .number()
    .integer()
    .min(MIN_PORT_NUMBER)
    .max(MAX_PORT_NUMBER),
  to_port: validator
    .number()
    .integer()
    .min(MIN_PORT_NUMBER)
    .max(MAX_PORT_NUMBER)
});

const overview = `
# IP Service

This OpInput allows a user to input a single IP Service (ip_protocol, from_port, to_port), it allows the user to specify a custom definition, or select from predefined IP services such as HTTP, HTTPs, SSH, etc.
`;

export const inputDefinition = {
  schema,
  defaultValue: {
    ip_service: undefined,
    ip_protocol: undefined,
    from_port: undefined,
    to_port: undefined
  },
  overview,
  firstRowLabels: true
};

interface SimpleOpInputProps {
  placeholder?: string;

  name: string;
  value: any;
  size?: size;

  disabled?: boolean;
  manyIndex?: number;

  setFieldValue: (name: string, value: any) => any | void;
  setFieldTouched: (name: string, touched: boolean) => any;
}

interface State {
  ip_service?: string;
  ip_protocol?: string;
  from_port?: number;
  to_port?: number;
  toPortSelected?: boolean;
}

class IPService extends Component<SimpleOpInputProps, State> {
  constructor(p: SimpleOpInputProps) {
    super(p);

    this.state = {
      ...inputDefinition.defaultValue,
      toPortSelected: false
    };

    this.onServiceSelect = this.onServiceSelect.bind(this);
    this.onProtocolSelect = this.onProtocolSelect.bind(this);
    this.onFromPortSelect = this.onFromPortSelect.bind(this);
    this.onToPortSelect = this.onToPortSelect.bind(this);
  }

  render() {
    const { name, size = 'large', manyIndex = 0 } = this.props;

    return (
      <IPServiceWrapper>
        <FormField name={`${name}.ip_service`} label={manyIndex === 0 ? 'IP service' : ''}>
          {({ name, value }) => {
            return (
              <Select size={size} placeholder="IP service" onChange={this.onServiceSelect} value={value || 'custom'}>
                <Select.Option value="custom">Custom</Select.Option>
                {PREDEFINED_SERVICES.map(({ serviceCode, label }) => {
                  return (
                    <Select.Option key={serviceCode} value={serviceCode}>
                      {label}
                    </Select.Option>
                  );
                })}
              </Select>
            );
          }}
        </FormField>

        <FormField name={`${name}.ip_protocol`} label={manyIndex === 0 ? 'IP protocol' : ''}>
          {({ name, value }) => {
            return (
              <Select size={size} placeholder="IP protocol" onChange={this.onProtocolSelect} value={value}>
                {IP_PROTOCOLS.map(ipProtocol => {
                  return (
                    <Select.Option key={ipProtocol} value={ipProtocol}>
                      {ipProtocol.toUpperCase()}
                    </Select.Option>
                  );
                })}
              </Select>
            );
          }}
        </FormField>

        <FormField name={`${name}.from_port`} label={manyIndex === 0 ? 'From port' : ''}>
          {({ name, value }) => {
            return (
              <InputNumber
                placeholder={'From port'}
                name={name}
                onChange={this.onFromPortSelect}
                size={size}
                value={value}
                min={MIN_PORT_NUMBER}
                max={MAX_PORT_NUMBER}
              />
            );
          }}
        </FormField>
        <FormField name={`${name}.to_port`} label={manyIndex === 0 ? 'To port' : ''}>
          {({ name, value }) => {
            return (
              <InputNumber
                placeholder={'To port'}
                name={name}
                onChange={this.onToPortSelect}
                size={size}
                value={value}
                min={MIN_PORT_NUMBER}
                max={MAX_PORT_NUMBER}
              />
            );
          }}
        </FormField>
      </IPServiceWrapper>
    );
  }

  getPredefinedServiceMatch(newState: State) {
    const matchedService = PREDEFINED_SERVICES.find(
      service =>
        service.ipProtocol === newState.ip_protocol &&
        service.fromPort === newState.from_port &&
        service.toPort === newState.to_port
    );

    if (matchedService) return matchedService.serviceCode;

    return undefined;
  }

  onServiceSelect(ipService: string) {
    const { name, setFieldValue } = this.props;
    const service = PREDEFINED_SERVICES.find(service => service.serviceCode === ipService);

    if (service) {
      setFieldValue(name, {
        ip_service: service.serviceCode,
        ip_protocol: service.ipProtocol,
        from_port: service.fromPort,
        to_port: service.toPort
      });
    } else {
      setFieldValue(`${name}.ip_service`, undefined);
    }
  }

  onProtocolSelect(ipProtocol: string) {
    const { name, value, setFieldValue } = this.props;
    const { from_port, to_port } = value;

    const serviceCode = this.getPredefinedServiceMatch({
      ip_protocol: ipProtocol,
      from_port,
      to_port
    });

    setFieldValue(name, {
      ip_protocol: ipProtocol,
      from_port,
      to_port,
      ip_service: serviceCode
    });
  }

  onFromPortSelect(fromPort?: number) {
    const { name, value, setFieldValue } = this.props;
    const { toPortSelected } = this.state;
    const { ipProtocol } = value;

    const toPort = toPortSelected ? value.to_port : fromPort;

    const serviceCode = this.getPredefinedServiceMatch({
      ip_protocol: ipProtocol,
      from_port: fromPort,
      to_port: toPort
    });

    setFieldValue(name, {
      ip_protocol: ipProtocol,
      from_port: fromPort,
      to_port: toPort,
      ip_service: serviceCode
    });
  }

  onToPortSelect(toPort?: number) {
    const { name, value, setFieldValue } = this.props;
    const { ipProtocol, from_port } = value;

    const serviceCode = this.getPredefinedServiceMatch({
      ip_protocol: ipProtocol,
      from_port,
      to_port: toPort
    });

    this.setState({
      toPortSelected: true
    });

    setFieldValue(name, {
      ip_protocol: ipProtocol,
      from_port,
      to_port: toPort,
      ip_service: serviceCode
    });
  }
}
export default IPService;

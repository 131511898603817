import gql from 'graphql-tag';

const USER_GROUP_MEMBER_LIST_QUERY = gql`
  query UserGroupMemberListQuery($userGroupId: String!) {
    userGroupMemberList(userGroupId: $userGroupId)
      @rest(type: "UserGroupMemberListPayload", path: "/user-groups/{args.userGroupId}/members") {
      items {
        user_id
        username
        email
        first_name
        last_name
        added
        created
        updated
      }
      page
      per_page
      total
    }
  }
`;

export default USER_GROUP_MEMBER_LIST_QUERY;

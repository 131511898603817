import React from 'react';
import { Table } from 'antd';
import styled from 'styled-components';
import FunctionReadView from 'components/function/FunctionReadView';
import { Function } from 'typings';
import ReviewTableWrap from './components/ReviewTableWrap';

const Root = styled.div`
  &.hide-header {
    .ant-table-body thead {
      display: none;
    }
  }

  h5 {
    margin-bottom: 0;
    color: ${p => p.theme.grey800};
    font-weight: 500;
    font-size: 14px;
  }
`;
const InputTableLeftCol = styled.div`
  padding-right: 24px;

  .description {
    color: ${p => p.theme.grey500};
  }
`;

const InputTableValueCol = styled.div`
  position: relative;
  padding-right: 32px;
`;

interface Props {
  configValues: any;
  actionTask: Function;
  hideHeader?: boolean;
  classes?: {
    root?: string;
  };
}

function ActionInputsTable(props: Props) {
  const { configValues, actionTask, hideHeader = false, classes = {} } = props;
  let rootClasses = classes.root || '';
  if (hideHeader) rootClasses += ' hide-header';

  return (
    <Root className={rootClasses}>
      <ReviewTableWrap>
        <Table
          className="table-action-inputs"
          rowKey="id"
          dataSource={actionTask.parameters || []}
          pagination={false}
          columns={[
            {
              width: '50%',
              key: 'input',
              dataIndex: 'name',
              title: 'Inputs',
              render: (name, action) => {
                return (
                  <InputTableLeftCol>
                    <h5>{name}</h5>
                    {action.description && <div className="description">{action.description}</div>}
                  </InputTableLeftCol>
                );
              }
            },
            {
              width: '50%',
              key: 'read-view',
              dataIndex: 'key',
              title: 'Value',
              render: (key, parameter) => {
                return (
                  <InputTableValueCol>
                    <FunctionReadView functionParameter={parameter} value={configValues[key]} />
                  </InputTableValueCol>
                );
              }
            }
          ]}
        />
      </ReviewTableWrap>
    </Root>
  );
}

export default ActionInputsTable;

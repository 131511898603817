import { Col, Row, Typography } from 'antd';
import { GridCard } from 'components/ui/Card';
import EmptyState from 'components/ui/EmptyState';
import ErrorAlert from 'components/ui/ErrorAlert';
import ErrorBoundary from 'components/ui/ErrorBoundary';
import NeoPage, { CenteredContainer, TitleBar } from 'components/ui/NeoPage';
import QueryResult from 'components/util/QueryResult';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AccountsAndProjectsKpi from './AccountsAndProjectsKpi';
import FindingsLine from './FindingsLine';
import OpenIssueRiskByAssessment from './OpenIssueRiskByAssessment';
import OpenIssuesByProject from './OpenIssuesByProject';
import OpenIssuesByService from './OpenIssuesByService';
import OpenIssuesBySeverity from './OpenIssuesBySeverity';
import OpenIssuesKpi from './OpenIssuesKpi';
import ResolvedIssuesKpi from './ResolvedIssuesKpi';
import ResourcesAndOpsKpi from './ResourcesAndOpsKpi';
import GettingStartedKpi from './GettingStartedKpi';
import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';

const Root = styled.div`
  .metric-wrapper {
    display: flex;
    justify-content: center;
  }

  .grid-card-content.no-padding {
    padding: 8px 0 16px;
  }

  .grid-card {
    margin-bottom: 0px;
  }

  .grid-card-header {
    padding: 8px;
    padding-left: 16px;
    border-bottom: 1px solid #e8e8e8;
  }

  .kpi {
    border-left: 4px solid #46327e;
    padding-left: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .kpi1 {
    border-left-color: #46327e;
  }

  .kpi2 {
    border-left-color: #848ae7;
  }

  .kpi3 {
    border-left-color: #5d94e4;
  }

  .kpi4 {
    border-left-color: #9fdbf8;
  }

  .title-bar-actions-right {
    margin-left: auto;
    margin-right: 12px;
  }
`;

const { Paragraph } = Typography;

const DASHBOARD_DAYS = 30;

const DASHBOARD_QUERY = gql`
  query accounts {
    accounts {
      items {
        account_id
        provision_url
      }
    }
  }
`;

const openIssueCountQuery = gql`
  query openIssueCount {
    governanceStats {
      openIssueCount
    }
  }
`;

const exemptedIssueCountQuery = gql`
  query exemptedIssueCount {
    governanceStats {
      exemptedIssueCount
    }
  }
`;

const resolvedIssueCountQuery = gql`
  query resolvedIssueCount($days: Int, $start: DateTime) {
    governanceStats {
      resolvedIssueCount(days: $days, start: $start)
    }
  }
`;

function Dashboard() {
  const { loading, data, error } = useQuery(DASHBOARD_QUERY, {
    variables: { days: DASHBOARD_DAYS }
  });
  const featureFlags = useFeatureFlag([Feature.USE_CASE_NAV]);
  const exemptedResult = useQuery(exemptedIssueCountQuery);
  const openResult = useQuery(openIssueCountQuery);
  const resolvedResult = useQuery(resolvedIssueCountQuery, {
    variables: { days: DASHBOARD_DAYS }
  });

  const actions = featureFlags[Feature.USE_CASE_NAV] ? null : (
    <a href="https://help.disruptops.com/articles/4248794-release-notes" target="_blank" rel="noopener noreferrer">
      What's New
    </a>
  );

  const titleBar = featureFlags[Feature.USE_CASE_NAV] ? null : (
    <TitleBar title={'Dashboard'} classes={{ actions: 'title-bar-actions-right' }} actions={actions} />
  );

  return (
    <ErrorBoundary>
      <Root>
        <NeoPage titleBar={titleBar}>
          <QueryResult loading={loading} data={data} error={error} loadingMessage="Loading accounts">
            {() => {
              const { accounts: { items: accounts = [] } = {} } = data || {};

              const hasAtLeastOneProvisionedAccount = accounts?.some((i) => !i.provision_url);

              const now = new Date(); // now local
              const startOfTodayUtcMillis = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()); // now utc

              let start = new Date(startOfTodayUtcMillis);
              start.setDate(start.getDate() - DASHBOARD_DAYS); // subtract days

              if (error) {
                return <ErrorAlert error={error} />;
              }

              if (!loading && !hasAtLeastOneProvisionedAccount) {
                return (
                  <EmptyState
                    title="Welcome to DisruptOps"
                    message={<Paragraph>Let's improve your cloud operations.</Paragraph>}
                    actions={<Link to="/getting-started">Getting Started Guide</Link>}
                  />
                );
              }

              return (
                <CenteredContainer leftAlign>
                  <GettingStartedKpi
                    exemptedResult={exemptedResult}
                    openResult={openResult}
                    resolvedResult={resolvedResult}
                  />

                  <Row gutter={[12, 12]}>
                    <Col md={6} sm={12} xs={24}>
                      <ResolvedIssuesKpi result={resolvedResult} />
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                      <OpenIssuesKpi exemptedResult={exemptedResult} openResult={openResult} />
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                      <ResourcesAndOpsKpi />
                    </Col>

                    <Col md={6} sm={12} xs={24}>
                      <AccountsAndProjectsKpi />
                    </Col>
                  </Row>

                  <Row gutter={[12, 12]}>
                    <Col sm={24} md={12}>
                      <GridCard title={'Findings'}>
                        <div style={{ height: '380px' }}>
                          <FindingsLine />
                        </div>
                      </GridCard>
                    </Col>

                    <Col sm={12} md={6}>
                      <GridCard title={'Open Issues by Project'}>
                        <div style={{ height: '380px' }}>
                          <OpenIssuesByProject />
                        </div>
                      </GridCard>
                    </Col>

                    <Col sm={12} md={6}>
                      <GridCard title={`Open Issues by Service`}>
                        <div style={{ height: '380px' }}>
                          <OpenIssuesByService />
                        </div>
                      </GridCard>
                    </Col>
                  </Row>

                  <Row gutter={[12, 12]}>
                    <Col sm={18}>
                      <GridCard title={'Open Issue Risk by Detector'}>
                        <OpenIssueRiskByAssessment />
                      </GridCard>
                    </Col>

                    <Col sm={6}>
                      <GridCard title={`Open Issues by Severity`}>
                        <OpenIssuesBySeverity />
                      </GridCard>
                    </Col>
                  </Row>
                </CenteredContainer>
              );
            }}
          </QueryResult>
        </NeoPage>
      </Root>
    </ErrorBoundary>
  );
}

export default Dashboard;

import React from 'react';
import IssueList from 'components/issue/IssueList';

import { RouteComponentProps } from 'react-router';

import { CenteredContainer } from 'components/ui/NeoPage';
import NeoPage, { TitleBar } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';

interface RouteParams {
  mode: string;
  status: string;
}

interface Props extends RouteComponentProps<RouteParams> {}

function IssuesPage(props: Props) {
  return (
    <NeoPage
      titleBar={
        <TitleBar icon={<DopeIcon name="ISSUES" size={20} />} sectionTitle="Issues" sectionTitleLinkTo="/issues" />
      }
    >
      <CenteredContainer>
        <IssueList {...props} />
      </CenteredContainer>
    </NeoPage>
  );
}

export default IssuesPage;

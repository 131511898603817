import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  padding-right: 32px;
  margin-bottom: 8px;
`;
const Title = styled.div`
  font-size: 16px;
  color: ${p => p.theme.grey800};
`;
const Description = styled.div`
  font-size: 14px;
  color: ${p => p.theme.grey500};
`;

interface Props {
  title: string;
  description?: string;
  classes?: {
    root?: string;
    title?: string;
    description?: string;
  };
}

function DetailsColumn(props: Props) {
  const { title, description, classes = {} } = props;

  return (
    <Root className={classes.root || ''}>
      <Title className={classes.title || ''}>{title}</Title>
      {description && <Description className={classes.description || ''}>{description}</Description>}
    </Root>
  );
}

export default DetailsColumn;

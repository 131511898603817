import styled from 'styled-components';

export const CloudLabelUl = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding: 0 6px;
    margin: 2px 2px 2px 0;
    border: 1px solid ${(p) => p.theme.grey200};
    border-radius: 3px;
  }
`;

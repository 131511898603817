import gql from 'graphql-tag';

const EVENT_ACTION_RESULTS_QUERY = gql`
  query automationActionResults($eventId: [String]) {
    automationActionResults(eventId: $eventId) {
      pageInfo {
        total
      }
      nodes {
        id
        status
        createdAt
        updatedAt
        op {
          id
          name
        }
        function {
          id
          name
          description
        }
        taskId
        functionInput
        detail
      }
    }
    automationEvents(id: $eventId) {
      nodes {
        id
      }
    }
  }
`;

export default EVENT_ACTION_RESULTS_QUERY;

import { Popover } from 'antd';
import Text from 'antd/lib/typography/Text';
import DopeIcon from 'components/ui/DopeIcon';
import QueryResult from 'components/util/QueryResult';
import { getSeverityName } from 'constants/ui';
import gql from 'graphql-tag';
import React from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const QUERY = gql`
  query openIssueRiskByAssessment {
    governanceStats {
      openIssueRiskByAssessment {
        assessmentId
        assessmentName
        assessorDescription
        projectId
        issueDefinitionId
        issueDefinitionName
        issueDefinitionDescription
        severity
        count
        risk
      }
    }

    projects {
      items {
        project_id
        name
      }
    }
  }
`;

const Root = styled.div`
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th:not(:first-child) {
    padding-left: 8px;
  }

  td:not(:first-child) {
    padding-left: 8px;
  }

  .numberColumn {
    text-align: right;
  }
`;

function OpenIssueRiskByAssessment() {
  const { loading, error, data } = useQuery(QUERY);

  return (
    <QueryResult loading={loading} data={data} error={error} loadingCenterVertically={false}>
      {() => {
        const results = data?.governanceStats?.openIssueRiskByAssessment || [];
        const projects = data?.projects?.items || [];

        return (
          <Root>
            <table>
              <thead>
                <tr>
                  <th>
                    <Text type="secondary">DETECTOR</Text>
                  </th>
                  <th>
                    <Text type="secondary">PROJECT</Text>
                  </th>
                  <th>
                    <Text type="secondary">ISSUE</Text>
                  </th>
                  <th>
                    <Text type="secondary">SEVERITY</Text>
                  </th>
                  <th>
                    <Text type="secondary">COUNT</Text>
                  </th>
                  <th>
                    <Popover content="Risk is calculated as issue count multiplied by severity (1-5).">
                      <Text type="secondary">RISK</Text>
                    </Popover>
                  </th>
                  <th>
                    <Text type="secondary">FIX</Text>
                  </th>
                </tr>
              </thead>
              <tbody>
                {results?.map((row, index) => {
                  const project = projects?.find((p) => p.project_id === row.projectId);
                  const assessmentPopoverContent = (
                    <>
                      {row.assessorDescription && (
                        <>
                          <b>Detector Description: </b>
                          {row.assessorDescription}
                          <br />
                          <br />
                        </>
                      )}
                      Click to view all of this detector's open issues of this type.
                    </>
                  );

                  const projectPopoverContent = (
                    <>
                      {project?.name && (
                        <>
                          <b>Project Name: </b>
                          {project?.name}
                          <br />
                          <br />
                        </>
                      )}
                      Click to view all open issues in this project.
                    </>
                  );

                  const issuePopoverContent = (
                    <>
                      {row.issueDefinitionDescription && (
                        <>
                          <b>Issue Description: </b>
                          {row.issueDefinitionDescription}
                          <br />
                          <br />
                        </>
                      )}
                      Click to view all of this detector's open issues of this type.
                    </>
                  );

                  const riskPopoverContent = (
                    <>
                      Risk is calculated as issue count multiplied by severity (1-5): <br />
                      {row.count.toLocaleString()} issues x {row.severity.toLocaleString()} severity ={' '}
                      {row.risk.toLocaleString()} risk
                    </>
                  );

                  return (
                    <tr key={`row${index}`}>
                      <td>
                        <Popover content={assessmentPopoverContent}>
                          <Link
                            to={`/issues?status=open&assessmentId=${row.assessmentId}`}
                            style={{ color: '#5d94e4' }}
                          >
                            {row.assessmentName}
                          </Link>
                        </Popover>
                      </td>
                      <td style={{ paddingLeft: '8px' }}>
                        <Popover content={projectPopoverContent}>
                          <Link to={`/issues?status=open&projectId=${row.projectId}`}>{project?.name || 'Root'}</Link>
                        </Popover>
                      </td>
                      <td>
                        <Popover content={issuePopoverContent}>
                          <Link
                            to={`/issues?status=open&issueDefinitionId=${row.issueDefinitionId}&assessmentId=${row.assessmentId}`}
                          >
                            {row.issueDefinitionName}
                          </Link>
                        </Popover>
                      </td>
                      <td title={row.severity.toLocaleString()}>{getSeverityName(row.severity)}</td>
                      <td className="numberColumn">{row.count.toLocaleString()}</td>
                      <td className="numberColumn">
                        <Popover content={riskPopoverContent}>{row.risk.toLocaleString()}</Popover>
                      </td>
                      <td>
                        <Popover content="Create an Op to automatically resolve all issues of this type.">
                          <Link to={`/ops/create/event-definitions?s=${row.issueDefinitionName}`}>
                            <DopeIcon name="OP" />
                          </Link>
                        </Popover>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Root>
        );
      }}
    </QueryResult>
  );
}

export default OpenIssueRiskByAssessment;

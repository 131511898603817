import styled from 'styled-components';

export const EditingRegions = styled.div`
  display: flex;

  .negator-wrap {
    width: 180px;
    margin-right: 1rem;
  }
`;

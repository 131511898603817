import gql from 'graphql-tag';
import NOTIFICATION_FRAGMENT from './notification-fragment';

const NOTIFICATION_LIST_QUERY = gql`
  query notifications($search: String, $from: Int, $size: Int) {
    notifications(search: $search, from: $from, size: $size) {
      from
      size
      total
      items {
        ...NotificationFragment
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

export default NOTIFICATION_LIST_QUERY;

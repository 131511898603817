const ignoredRedirectPaths = ['/login', '/new-account', '/recover-account', '/invite', '/settings/profile', '/settings/update-password'];

async function handleUnauthorized() {
  const path = window.location.pathname;
  if (!ignoredRedirectPaths.includes(path)) {
    localStorage.setItem('redirectAfterLogin', path);
  }
}

export default handleUnauthorized;

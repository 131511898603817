import React from 'react';

import QueryStringContext from 'components/util/QueryString/QueryStringContext';

interface Props {
  region: string;
}

function RegionLink(props: Props) {
  const { region } = props;

  return (
    <QueryStringContext.Consumer>
      {({ cloneURLSearchParams, replaceURLSearchParams }) => (
        <button
          className="link-button"
          onClick={(e) => {
            e.stopPropagation();

            const urlSearchParams = cloneURLSearchParams();

            urlSearchParams.append('region', region);
            urlSearchParams.delete('p');

            replaceURLSearchParams(urlSearchParams);
          }}
        >
          {region}
        </button>
      )}
    </QueryStringContext.Consumer>
  );
}

export default RegionLink;

import { Role, UserGroup, User, UserGroupMember, UserGroupRole } from 'typings/index.d';
import { RestLink } from 'apollo-link-rest';
import { API_URL } from '../../../constants/runtimeConfig';
import { TeamMember } from 'typings';

interface PagedPayload {
  items: any[];
  page: Number;
  total: Number;
  per_page: Number;
}

const typePatcher = {
  ConfiguredOp: configuredOpTypePatcher,
  Op: configuredOpTypePatcher,
  Issue: issueTypePatcher,
  OpIssue: opIssueTypePatcher,
  UserListPayload: userListPayloadTypePatcher,
  User: userTypePatcher,
  ConfigsResponse: configsResponseTypePatcher,
  RoleListPayload: roleListPayloadTypePatcher,
  UserGroupListPayload: userGroupListPayloadTypePatcher,
  UserGroupMemberListPayload: userGroupMemberListPayloadTypePatcher,
  UserGroupRoleListPayload: userGroupRoleListPayloadTypePatcher
};

const restLink = new RestLink({
  uri: API_URL,
  credentials: 'include',
  defaultSerializer,
  typePatcher,
  headers: {},
  customFetch: customFetch
});

async function customFetch(input: RequestInfo, init: RequestInit) {
  const result = await fetch(input, init);

  if (result.status >= 400) {
    const defaultErrorMessage = `Failed to fetch, status code ${result.status} received.`;

    const data = await result.json();

    throw new Error(data.message || data.description || defaultErrorMessage);
  }

  return result;
}

function defaultSerializer(body: any, headers: Headers) {
  if (body.__typename) delete body.__typename;
  headers.set('Content-Type', 'application/json');
  return {
    body: JSON.stringify(body),
    headers
  };
}

function configuredOpTypePatcher(data: any, outerType: string) {
  if (data.tags && data.checks.length > 0) {
    data.tags = data.tags.map(tag => ({ ...tag, __typename: 'OpTag' }));
  }

  if (data.checks && data.checks.length > 0) {
    data.checks = data.checks.map(check => {
      let input_values = { ...check.input_values };
      if (check.input_values && check.input_values.tags && Array.isArray(check.input_values.tags.length)) {
        input_values.tags = check.input_values.tags.map(tag => {
          return {
            ...tag,
            __typename: 'AWSTag'
          };
        });
      }

      let inputs = [];

      if (check.inputs && check.inputs.length > 0) {
        inputs = check.inputs.map(input => {
          return {
            ...input,
            __typename: 'OpInput'
          };
        });
      }

      return {
        __typename: 'OpCheck',
        ...check,
        input_values,
        inputs
      };
    });
  }

  if (data.issues && data.issues.length > 0) {
    data.issues = data.issues.map(issue => {
      const actions = issue.actions.map(action => {
        const actionInputs = action.inputs && action.inputs.length ? action.inputs : [];

        const inputs = actionInputs.map(input => {
          return {
            ...input,
            __typename: 'OpInput'
          };
        });

        return { ...action, __typename: 'OpAction', inputs };
      });

      return {
        __typename: 'OpIssueTemplate',
        ...issue,
        actions,
        display_info: { ...issue.display_info, __typename: 'OpIssueTemplateDisplayInformation' }
      };
    });
  }

  if (data.issue_status) {
    data.issue_status = {
      ...data.issue_status,
      __typename: 'OpIssueStatuses'
    };
  }

  return data;
}

export function issueTypePatcher(data: any) {
  const { display_info, action_results } = data;

  const patchedDisplayInfo = { ...display_info, __typename: 'IssueDisplayInfo' };

  const patchedActionResults =
    !action_results || !Array.isArray(action_results)
      ? []
      : action_results.map(actionResult => {
          return {
            ...actionResult,
            __typename: 'IssueActionResult'
          };
        });

  return { ...data, display_info: patchedDisplayInfo, action_results: patchedActionResults };
}

function opIssueTypePatcher(data: any) {
  const { display_info, action_results } = data;

  const patchedDisplayInfo = { ...display_info, __typename: 'IssueDisplayInfo' };

  const patchedActionResults =
    !action_results || !Array.isArray(action_results)
      ? []
      : action_results.map(actionResult => {
          return {
            ...actionResult,
            __typename: 'IssueActionResult'
          };
        });

  return { ...data, display_info: patchedDisplayInfo, action_results: patchedActionResults };
}

interface UserListPayload extends PagedPayload {
  items: User[];
}

function userListPayloadTypePatcher(data: UserListPayload) {
  return {
    ...data,
    items: data.items.map(user => ({ ...user, __typename: 'User' })),
    __typename: 'UserListPayload'
  };
}

function userTypePatcher(data: any): TeamMember {
  return {
    ...data,
    authorized_accounts: data.authorized_accounts.map(i => ({ ...i, __typename: 'AuthorizedAccount' })),
    effective_permissions: data.effective_permissions.map(i => ({ ...i, __typename: 'EffectivePermission' })),
    client: {
      ...data.client,
      __typename: 'UserClient'
    }
  };
}

function configsResponseTypePatcher(data: any) {
  const typedData = {
    ...data,
    items: data.items.map(item => ({
      ...item,
      __typename: 'Config'
    }))
  };

  return typedData;
}

interface RoleListPayload extends PagedPayload {
  items: Role[];
}

function roleListPayloadTypePatcher(data: RoleListPayload) {
  const typedData = {
    ...data,
    items: data.items.map(role => ({ ...role, __typename: 'Role' })),
    __typename: 'RoleListPayload'
  };

  return typedData;
}

interface UserGroupListPayload extends PagedPayload {
  items: UserGroup[];
}

function userGroupListPayloadTypePatcher(data: UserGroupListPayload) {
  const typedData = {
    ...data,
    items: data.items.map(userGroup => ({
      ...userGroup,
      roles: userGroup.roles.map(role => ({ ...role, __typename: 'UserGroupRole' })),
      __typename: 'UserGroup'
    })),
    __typename: 'UserGroupListPayload'
  };

  return typedData;
}

interface UserGroupMemberListPayload extends PagedPayload {
  items: UserGroupMember[];
}

function userGroupMemberListPayloadTypePatcher(data: UserGroupMemberListPayload) {
  const typedData = {
    ...data,
    items: data.items.map(member => ({ ...member, __typename: 'UserGroupMember' })),
    __typename: 'UserGroupMemberListPayload'
  };

  return typedData;
}

interface UserGroupRoleListPayload extends PagedPayload {
  items: UserGroupRole[];
}

function userGroupRoleListPayloadTypePatcher(data: UserGroupRoleListPayload) {
  const typedData = {
    ...data,
    items: data.items.map(userGroupRole => ({ ...userGroupRole, __typename: 'UserGroupRole' })),
    __typename: 'UserGroupRoleListPayload'
  };

  return typedData;
}

export default restLink;

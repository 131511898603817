import { EmailConfig, ExistingNotificationRecipient, JiraConfig, NotificationRecipient, SlackConfig, TeamsConfig } from 'typings';
import styled from 'styled-components';
import DopeIcon from '../ui/DopeIcon';
import React from 'react';
import { truncate } from './common';

export const recipientListTypes = {
  JIRA: JiraRecipientListItem,
  MS_TEAMS: MSTeamsRecipientListItem,
  PROJECT: ProjectDefaultListItem,
  SLACK: SlackRecipientListItem,
  EMAIL: EmailRecipientListItem
};

export const DEFAULT_PROJECT_UUID = '00000000-0000-0000-0000-000000000000';

const RecipientListItemRoot = styled.div`
  margin-bottom: 24px;
  padding: 6px;
  display: flex;
  &:hover {
    background-color: #f9f9f9;
  }

  .recipient-list-item-meta {
    flex: 1 1 auto;
  }

  .recipient-list-item-icon {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }

  .recipient-list-item-action {
    margin-left: 16px;
  }
`;

const NAME_NOT_SET = 'Not Set';
const NAME_MAX_LENGTH = 25; // NOTE: Due to a one-time data generation, some older recipients may have long names.

interface RecipientListItemProps {
  recipient: ExistingNotificationRecipient | NotificationRecipient;
  action?: React.ReactNode;
}

function JiraRecipientListItem(props: RecipientListItemProps) {
  const { recipient, action } = props;
  const config: JiraConfig = recipient.config as JiraConfig;
  const name = truncate(recipient?.name || NAME_NOT_SET, { maxLength: NAME_MAX_LENGTH });

  return (
    <RecipientListItemRoot>
      <div className="recipient-list-item-icon">
        <DopeIcon name="JIRA" size={20} />
      </div>
      <div className="recipient-list-item-meta">
        <RecipientListItemMetaItem label="Name:" value={name} tooltip={recipient?.name} />
        <RecipientListItemMetaItem label="URL:" value={config.url} />
        <RecipientListItemMetaItem label="Project:" value={config.projectKey} />
        <RecipientListItemMetaItem label="Priority:" value={config.priority} />
      </div>
      {action && <div className="recipient-list-item-action">{action}</div>}
    </RecipientListItemRoot>
  );
}

function MSTeamsRecipientListItem(props: RecipientListItemProps) {
  const { recipient, action } = props;
  const config: TeamsConfig = recipient.config as TeamsConfig;
  const name = truncate(recipient?.name || NAME_NOT_SET, { maxLength: NAME_MAX_LENGTH });

  return (
    <RecipientListItemRoot>
      <div className="recipient-list-item-icon">
        <DopeIcon name="MICROSOFT_TEAMS" size={20} />
      </div>
      <div className="recipient-list-item-meta">
        <RecipientListItemMetaItem label="Name:" value={name} tooltip={recipient?.name} />
        <RecipientListItemMetaItem label="Team Name:" value={config?.context?.teamName} />
        <RecipientListItemMetaItem label="Channel Name:" value={config?.context?.channelName} />
        <RecipientListItemMetaItem label="Created By:" value={config?.context?.upn} />
      </div>
      {action && <div className="recipient-list-item-action">{action}</div>}
    </RecipientListItemRoot>
  );
}

function ProjectDefaultListItem(props: RecipientListItemProps) {
  const { action } = props;

  return (
    <RecipientListItemRoot>
      <div className="recipient-list-item-icon">
        <DopeIcon name="PROJECT" size={20} />
      </div>
      <div className="recipient-list-item-meta">
        <RecipientListItemMetaItem value="Project Default" />
        <RecipientListItemMetaItem value="Channel will be based on project ownership of the account when the event is received." />
      </div>
      {action && <div className="recipient-list-item-action">{action}</div>}
    </RecipientListItemRoot>
  );
}

function SlackRecipientListItem(props: RecipientListItemProps) {
  const { recipient, action } = props;
  const config: SlackConfig = recipient.config as SlackConfig;
  const name = truncate(recipient?.name || NAME_NOT_SET, { maxLength: NAME_MAX_LENGTH });

  return (
    <RecipientListItemRoot>
      <div className="recipient-list-item-icon">
        <DopeIcon name="SLACK" size={20} />
      </div>
      <div className="recipient-list-item-meta">
        <RecipientListItemMetaItem label="Name:" value={name} tooltip={recipient?.name} />
        <RecipientListItemMetaItem label="Workspace:" value={config.username} />
        <RecipientListItemMetaItem label="Channel:" value={config.channel} />
      </div>
      {action && <div className="recipient-list-item-action">{action}</div>}
    </RecipientListItemRoot>
  );
}

function EmailRecipientListItem(props: RecipientListItemProps) {
  const { recipient, action } = props;
  const config: EmailConfig = recipient.config as EmailConfig;
  const name = truncate(recipient?.name || NAME_NOT_SET, { maxLength: NAME_MAX_LENGTH });

  return (
    <RecipientListItemRoot>
      <div className="recipient-list-item-icon">
        <DopeIcon name="ENVELOPE" size={20} />
      </div>
      <div className="recipient-list-item-meta">
        <RecipientListItemMetaItem label="Name:" value={name} tooltip={recipient?.name} />
        <RecipientListItemMetaItem label="To Emails:" value={config.toAddresses.join('; ')} />
      </div>
      {action && <div className="recipient-list-item-action">{action}</div>}
    </RecipientListItemRoot>
  );
}

const RecipientListItemMetaItemRoot = styled.div`
  line-height: 1.25em;
  margin-bottom: 2px;

  .recipient-meta-item-label {
    font-weight: 600;
    display: inline-block;
    margin-right: 4px;
    color: ${(p) => p.theme.grey800};
  }
`;

interface RecipientListItemMetaItemProps {
  label?: string;
  value: any;
  tooltip?: string;
}

function RecipientListItemMetaItem(props: RecipientListItemMetaItemProps) {
  const { label, value, tooltip } = props;
  return (
    <RecipientListItemMetaItemRoot className="recipient-meta-item">
      {label && <span className="recipient-meta-item-label">{label}</span>}
      <span title={tooltip}>{value}</span>
    </RecipientListItemMetaItemRoot>
  );
}

import React from 'react';
import { RouteComponentProps } from 'react-router';

import NotificationForm from './components/NotificationForm';

import { NOTIFICATION_FRAGMENT } from './gql';

import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { PageTitle } from 'components/ui/PageHeader';
import { CenteredContainer } from 'components/ui/NeoPage';
import { message } from 'antd';

const CREATE_NOTIFICATION_MUTATION = gql`
  mutation createNotification($notification: NotificationInput!) {
    createNotification(notification: $notification) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

interface Props extends RouteComponentProps {}

function NewNotification(props: Props) {
  return (
    <Mutation mutation={CREATE_NOTIFICATION_MUTATION}>
      {(createNotification) => (
        <CenteredContainer size="md" leftAlign>
          <PageTitle title={'Create Notification'} />
          <NotificationForm
            onSave={async (notification) => {
              const result = await createNotification({
                variables: { notification }
              });

              const {
                createNotification: { id }
              } = result.data;

              message.success('Notification was successfully created.');

              props.history.push(`/organization-settings/notifications/${id}`);
            }}
            initialNotificationFeed={null}
          />
        </CenteredContainer>
      )}
    </Mutation>
  );
}

export default NewNotification;

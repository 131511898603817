import { Permissions } from '@disruptops/neo-core/dist/permissions';
import { Collapse, Divider, Select, Typography } from 'antd';
import { useAuthorizeRequiredPermissions } from 'components/app/Auth/Authorizor';
import { FilterItem, FilterRow } from 'components/app/FilterBar/components';
import { LabelsFilter, SearchFilter } from 'components/app/FilterBar/filters';
import { CloudVendorIcon } from 'components/cloudVendors';
import { FunctionParameterInputSet } from 'components/function';
import { GridCard } from 'components/ui/Card';
import DopeIcon from 'components/ui/DopeIcon';
import { FormRenderProps } from 'components/ui/Form/Form';
import IconButton from 'components/ui/IconButton';
import QueryResultComponent from 'components/util/QueryResult';
import gql from 'graphql-tag';
import Maybe from 'graphql/tsutils/Maybe';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { AutomationFunction, FunctionParameter } from 'typings';
import { OpSectionListItem, OpSectionListItemMeta } from '../components';
import { AUTOMATION_FUNCTION_FIELDS_FOR_OPS } from '../gql';
import { ConfirmationModalState, ContextArgs, GridCardContentPadding, OnSelectActionFunc } from '../OpEditor';
import AlertActionEditor from './AlertActionEditor';
import AlertActionListItem, { AlertAction } from './AlertActionListItem';

const CollapsePanel = Collapse.Panel;

// COMPONENT: ACTION SECTION
interface OpActionSectionProps {
  contextArgs: ContextArgs;
  onSelectAction: OnSelectActionFunc;
  formRenderProps: FormRenderProps;
  setConfirmationModal: (confirmationModalState: ConfirmationModalState) => void;
}

const MemoizedSelectAction = React.memo(SelectAction, (p, n) => {
  // only update if event def changes?
  const prevEventDefId = p.contextArgs.eventDefinition?.id || null;
  const nextEventDefId = n.contextArgs.eventDefinition?.id || null;

  return prevEventDefId === nextEventDefId;
});

function OpActionSection(props: OpActionSectionProps) {
  const { contextArgs, onSelectAction, formRenderProps, setConfirmationModal } = props;

  const { automationFunction, op } = contextArgs;

  const existingProjectId = op?.projectId || undefined;
  const authz = useAuthorizeRequiredPermissions({
    requiredPermissions: [{ permissionId: Permissions.MODIFY_GUARDRAILS, projectIds: existingProjectId || '*' }]
  });
  const isAuthorized = existingProjectId ? authz.isAuthorized : true;

  return (
    <GridCard
      classes={{ root: 'has-arrow' }}
      secondaryTitle="Action"
      title={
        automationFunction ? (
          <div className="section-title-wrap">
            <span className="section-title">{automationFunction ? automationFunction.name : null}</span>
            {isAuthorized && (
              <IconButton
                type="ghost"
                size="small"
                onClick={(e) => {
                  e.preventDefault();

                  setConfirmationModal({
                    title: 'Clear Action Section',
                    description: 'This section will be cleared and any settings will be lost once saved.',
                    onConfirm: () => {
                      onSelectAction(null);
                      setConfirmationModal(null);
                    }
                  });
                }}
              >
                <DopeIcon name="REMOVE" />
              </IconButton>
            )}
          </div>
        ) : (
          'Select Action'
        )
      }
      icon={
        automationFunction ? (
          <CloudVendorIcon vendor={automationFunction.cloudVendor} />
        ) : (
          <DopeIcon name="ACTION" size={20} />
        )
      }
      elevation={1}
      rounded
    >
      {automationFunction ? (
        <ConfigureAction
          formRenderProps={formRenderProps}
          parameters={automationFunction.parameters}
          projectId={existingProjectId}
          automationFunction={automationFunction}
          contextArgs={contextArgs}
        />
      ) : (
        <MemoizedSelectAction {...props} />
      )}
    </GridCard>
  );
}

// CONFIGURE ACTION
interface ConfigureActionProps {
  parameters: Maybe<FunctionParameter[]>;
  projectId?: string;
  formRenderProps: FormRenderProps;
  automationFunction: Maybe<any>;
  contextArgs: ContextArgs;
}

export function ConfigureAction(props: ConfigureActionProps) {
  const { parameters, formRenderProps, automationFunction, projectId, contextArgs } = props;

  return (
    <Collapse defaultActiveKey="configure-action" bordered={false} accordion>
      <CollapsePanel key="configure-action" header="Configure Action">
        <GridCardContentPadding>
          {automationFunction?.id === AlertAction.id ? (
            <AlertActionEditor
              formRenderProps={formRenderProps}
              parameters={parameters}
              projectId={projectId}
              contextArgs={contextArgs}
            />
          ) : parameters ? (
            <FunctionParameterInputSet
              {...formRenderProps}
              parameters={parameters}
              allowJSONPathInput
              staticConfigPathPrefix="actionConfiguration."
              jsonpathConfigPathPrefix="actionConfiguration.jsonpathConfiguration."
              dynamicConfigPathPrefix="actionConfiguration.staticConfiguration."
            />
          ) : (
            <div>No parameters to Configure</div>
          )}
        </GridCardContentPadding>
      </CollapsePanel>
    </Collapse>
  );
}

const UNIQUE_FUNCTION_LABELS_QUERY = gql`
  query UniqueFunctionLabelsForOpActions {
    uniqueFunctionLabels
  }
`;

// SELECT ACTION
const SelectActionRoot = styled.div`
  .list-wrapper {
    max-height: 400px;
    overflow-y: auto;
  }

  .action-search-input {
    margin-bottom: 16px;
  }
`;

export interface SelectActionProps {
  onSelectAction: OnSelectActionFunc;
  contextArgs: ContextArgs;
  hideAlertAction?: Boolean;
}

export function SelectAction(props: SelectActionProps) {
  const { hideAlertAction } = props;
  const [actionSearchStr, setActionSearchStr] = useState<string | null>(null);
  const [cloudVendor, setCloudVendor] = useState<string[] | null>(null);
  const [actionLabels, setActionLabels] = useState<string[] | null>(null);

  const externalActionVendors = ['Jira'];

  return (
    <SelectActionRoot>
      <GridCardContentPadding>
        <FilterRow>
          <FilterItem label="Search">
            <SearchFilter
              classes={{ root: 'action-search-input' }}
              search=""
              placeholder="Search available Actions"
              onSearchChange={(str) => setActionSearchStr(str && str.length > 0 ? str : null)}
            />
          </FilterItem>
          <FilterItem label="Cloud Vendor">
            <Select
              mode="multiple"
              value={(Array.isArray(cloudVendor) && cloudVendor) || []}
              style={{ minWidth: '200px', maxWidth: '500px' }}
              dropdownMatchSelectWidth={false}
              placeholder={'Select cloud vendor(s)'}
              maxTagCount={2}
              maxTagTextLength={48}
              maxTagPlaceholder={(extraValues) => {
                return <>+&nbsp;{extraValues.length}</>;
              }}
              onChange={(value) => {
                setCloudVendor(value);
              }}
            >
              {['AWS', 'Azure'].map((vendor) => {
                return (
                  <Select.Option key={vendor} title={vendor}>
                    {vendor}
                  </Select.Option>
                );
              })}
            </Select>
          </FilterItem>
          <FilterItem label="Labels">
            <LabelsFilter
              value={(Array.isArray(actionLabels) && actionLabels) || []}
              onChange={(value) => {
                setActionLabels(value);
              }}
              query={UNIQUE_FUNCTION_LABELS_QUERY}
              getLabelsRootFromQueryResult={(data) => data && data.uniqueFunctionLabels}
            />
          </FilterItem>
        </FilterRow>

        <Divider />

        <div className="list-wrapper">
          {!hideAlertAction && (
            <ActionListGroup title="Alert Actions">
              <AlertActionListItem {...props} />
            </ActionListGroup>
          )}

          <ActionListGroup title="Recommended Actions">
            <RecommendedActions {...props} searchString={actionSearchStr} />
          </ActionListGroup>

          <ActionListGroup title="External Actions">
            <AutomationFunctionList
              {...props}
              searchString={actionSearchStr}
              labels={actionLabels}
              cloudVendor={externalActionVendors}
              displayVendorTitle={false}
            />
          </ActionListGroup>

          <ActionListGroup title="All Actions">
            <AutomationFunctionList
              {...props}
              searchString={actionSearchStr}
              labels={actionLabels}
              cloudVendor={cloudVendor}
            />
          </ActionListGroup>
        </div>
      </GridCardContentPadding>
    </SelectActionRoot>
  );
}

// ActionListGroup
const ActionListGroupRoot = styled.div`
  margin-bottom: 24px;

  .action-list-group-title {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 8px;
    color: #bbbbbb;
    letter-spacing: 0.04em;
    font-weight: 100;s
  }
`;

interface ActionListGroupProps {
  title: string;
  children: React.ReactNode | React.ReactNodeArray;
}

function ActionListGroup(props: ActionListGroupProps) {
  const { title, children } = props;

  return (
    <ActionListGroupRoot className="action-list-group">
      <div className="action-list-group-title">{title}</div>
      <div className="action-list-group-items">{children}</div>
    </ActionListGroupRoot>
  );
}

// RECOMMENDED ACTIONS
// link table between functions and event_defs. Also, used for playbooks...
const EVENT_DEFINITION_FUNCTIONS = gql`
  query AutomationFunctionsByEventDefinitionForOpEditor($id: ID!) {
    eventDefinitionFunctions(eventDefinitionId: $id) {
      pageInfo {
        total
      }
      nodes {
        ...AutomationFunctionFieldsForOps
        eventFunctions {
          functionInputMappings {
            key
            valuePath
          }
        }
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_OPS}
`;
interface RecommendedActionsProps extends SelectActionProps {
  searchString: string | null;
}

function RecommendedActions(props: RecommendedActionsProps) {
  const {
    contextArgs: { eventDefinition },
    onSelectAction
  } = props;
  const skip = !eventDefinition;
  const eventDefinitionFunctionResults = useQuery(EVENT_DEFINITION_FUNCTIONS, {
    variables: { id: eventDefinition && eventDefinition.id },
    skip
  });

  return eventDefinition ? (
    <QueryResultComponent
      {...eventDefinitionFunctionResults}
      parseNodes={(data) => data.eventDefinitionFunctions.nodes}
      skip={skip}
    >
      {(nodes) => {
        const automationFunctions: AutomationFunction[] | null = nodes;

        return automationFunctions && automationFunctions.length > 0 ? (
          <>
            {automationFunctions.map((automationFunction) => (
              <OpSectionListItem
                key={automationFunction.id}
                title={automationFunction.name}
                description={automationFunction.description}
                icon={<DopeIcon name="AWS" size={30} />}
                onClick={() => onSelectAction({ automationFunction })}
              />
            ))}
          </>
        ) : (
          <Typography.Text>{'No Recommended Actions found'}</Typography.Text>
        );
      }}
    </QueryResultComponent>
  ) : (
    <Typography.Text>{'Select Trigger to view Recommended Actions'}</Typography.Text>
  );
}

//  SEARCH ALL AUTOMATION FUNCTIONS
const AUTOMATION_FUNCTIONS_QUERY = gql`
  query AutomationFunctionsQuery(
    $pageSize: Int
    $sortBy: String
    $sortDirection: SortDirection
    $search: String
    $labels: [String]
    $cloudVendor: [String]
    $isDisabled: Boolean
  ) {
    automationFunctions(
      pageSize: $pageSize
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      labels: $labels
      cloudVendor: $cloudVendor
      isDisabled: $isDisabled
    ) {
      pageInfo {
        total
        size
      }
      nodes {
        ...AutomationFunctionFieldsForOps
      }
    }
  }
  ${AUTOMATION_FUNCTION_FIELDS_FOR_OPS}
`;

interface AutomationFunctionListProps {
  onSelectAction: OnSelectActionFunc;
  searchString: string | null;
  labels: string[] | null;
  cloudVendor: string[] | null;
  displayVendorTitle?: boolean | true;
}

function AutomationFunctionList(props: AutomationFunctionListProps) {
  const { onSelectAction, searchString, labels, cloudVendor, displayVendorTitle } = props;
  const pageSize = 10;
  const results = useQuery(AUTOMATION_FUNCTIONS_QUERY, {
    variables: {
      search: searchString,
      labels: labels,
      cloudVendor: cloudVendor,
      pageSize: pageSize,
      sortBy: 'name',
      sortDirection: 'ASC',
      isDisabled: false
    }
  });
  const { data, ...otherResults } = results;
  const { automationFunctions: query } = data || {};
  const { nodes, pageInfo } = query || {};
  const { total, size } = pageInfo || {};

  return (
    <QueryResultComponent {...otherResults} loadingCenterVertically={false} loadingHeight={200}>
      {() => {
        return nodes && nodes.length > 0 ? (
          <>
            {nodes.map((automationFunction) => (
              <OpSectionListItem
                key={automationFunction.id}
                title={automationFunction.name}
                description={automationFunction.description}
                icon={<CloudVendorIcon vendor={automationFunction.cloudVendor} />}
                onClick={() => onSelectAction({ automationFunction })}
                meta={
                  <>
                    {automationFunction.labels &&
                      automationFunction.labels.map((label, idx) => (
                        <OpSectionListItemMeta
                          key={`${idx}_${label}`}
                          value={displayVendorTitle === false ? '' : label}
                        />
                      ))}
                  </>
                }
              />
            ))}
            {total > size && (
              <Typography.Text>More Actions are available, please refine your search above.</Typography.Text>
            )}
          </>
        ) : (
          <Typography.Text>No matching Actions were found.</Typography.Text>
        );
      }}
    </QueryResultComponent>
  );
}

export default OpActionSection;

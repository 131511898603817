import styled from 'styled-components';
import uiConstants from 'constants/ui';

const breakpoint = uiConstants.navBreakpointWidth;

export const SectionHeadingHeader = styled.header`
  background-color: ${(p) => p.theme.grey50};
  border-bottom: 1px solid ${(p) => p.theme.border};
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: calc(2rem + 26px);
  transition: padding-left 0.2s;

  .icon {
    font-size: 22px;
    display: flex;
    margin: 0 0.75rem 0 0;
  }

  h1 {
    font-size: 22px;
    line-height: 1;
    margin: 0;
    color: ${(p) => p.theme.grey700};
  }

  .children {
    margin-left: 2rem;
  }

  /* desktop-only rules */
  @media only screen and (min-width: ${breakpoint}px) {
    padding-left: 1rem;
  }
`;

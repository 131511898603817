import React from 'react';
import { SearchBarFilter } from '../SearchBar';
import { ValueDisplayDiv } from './ValueDisplay.styles';

interface ValueDisplayProps {
  filter: SearchBarFilter;
  searchValues: object;
  clearFilter: Function;
  enableEdit: Function;
}

function ValueDisplay(props: ValueDisplayProps) {
  const { filter, searchValues, clearFilter, enableEdit } = props,
    currentValue = searchValues[filter.key]?.toString();

  return (
    <ValueDisplayDiv>
      <button
        className="enable-edit"
        onClick={() => {
          enableEdit();
        }}
      >
        {filter.label}: {currentValue}
      </button>
      <button
        onClick={() => {
          clearFilter();
        }}
      >
        x
      </button>
    </ValueDisplayDiv>
  );
}

export default ValueDisplay;

import React, { useState, useMemo } from 'react';
import FilterRow, { SoftEdit } from '../FilterRow/FilterRow';
import { FilterTypeProps } from '../FilterListing/FilterListing';
import ProjectTreeNodeTitle from 'components/project/ProjectTree/ProjectTreeNodeTitle';
import { TreeSelect } from 'antd';
import useProjectTree, { ProjectTreeNode } from 'components/project/ProjectTree/useProjectTree';
import { NodeType } from 'components/project/ProjectTree/types';
import { TreeNodeSimpleMode } from 'antd/lib/tree-select/interface';

function ProjectEditor(props: FilterTypeProps) {
  const { values, filterType } = props,
    [softEdit, setSoftEdit] = useState<SoftEdit | null>({
      key: filterType.valueKey,
      value: values[filterType.valueKey]
    }),
    rootProjectId: string = values?.projectId,
    { projectTree, loading, getProject, error } = useProjectTree({
      rootProjectId
    }),
    projectTreeJSON = useMemo(() => JSON.stringify(projectTree), [projectTree]),
    treeData = useMemo(() => projectTree.map((node) => convertProjectTreeNodeToAntTreeNode(node)), [projectTreeJSON]),
    onChange = (change) => {
      setSoftEdit({
        key: filterType.valueKey,
        value: change.map((c) => c.value)
      });
    };

  const fullValue = useMemo(() => {
    const value = softEdit?.value;

    return value
      ? (value
          .map((projectId) => {
            const project = getProject(projectId);

            if (!project) return null;

            return {
              value: projectId,
              label: <ProjectTreeNodeTitle nodeType={NodeType.project}>{project.name}</ProjectTreeNodeTitle>
            };
          })
          .filter((value) => (value ? true : false)) as any[])
      : [];
  }, [softEdit, projectTreeJSON]);

  const editor = (
    <TreeSelect
      placeholder={loading ? 'Loading' : 'Any project'}
      dropdownClassName="cascade-checks"
      key={projectTreeJSON}
      treeDefaultExpandAll={true}
      treeData={treeData}
      loading={loading}
      disabled={loading}
      multiple={true}
      labelInValue={true}
      value={fullValue}
      onChange={onChange}
      treeCheckable
      showCheckedStrategy={TreeSelect.SHOW_ALL}
      treeCheckStrictly={true}
    ></TreeSelect>
  );

  return <FilterRow {...props} editor={editor} softEdit={softEdit} loading={loading} error={error} />;
}

function convertProjectTreeNodeToAntTreeNode(
  projectTreeNode: ProjectTreeNode,
  hasPermission?: (node: ProjectTreeNode) => boolean
): TreeNodeSimpleMode {
  const disabled = hasPermission ? !hasPermission(projectTreeNode) : false;
  const children: TreeNodeSimpleMode[] = [];

  const node: TreeNodeSimpleMode = {
    key: projectTreeNode.project_id,
    title: <ProjectTreeNodeTitle nodeType={NodeType.project}>{projectTreeNode.name}</ProjectTreeNodeTitle>,
    value: projectTreeNode.project_id,
    selectable: false,
    children: children,
    disabled: disabled
  };

  if (projectTreeNode.accounts_list) {
    children.push(
      ...projectTreeNode.accounts_list.map((a) => ({
        key: a.account_id,
        value: a.account_id,
        title: <ProjectTreeNodeTitle nodeType={NodeType.cloudAccount}>{a.nickname || a.name}</ProjectTreeNodeTitle>,
        selectable: false,
        children: [],
        checkable: false,
        disabled
      }))
    );
  }

  if (projectTreeNode.childProjects) {
    children.push(...projectTreeNode.childProjects.map((p) => convertProjectTreeNodeToAntTreeNode(p, hasPermission)));
  }

  return node;
}

export default ProjectEditor;

import { Tabs } from 'antd';
import { useFixedHeight } from 'designSystem/DesignSystemUtils/DesignSystemUtils';
import React, { useRef } from 'react';
import {
  DetailPanelDiv,
  DetailPanelActionsDiv,
  DetailPanelScrollableDiv,
  DetailPanelSectionDiv
} from './DetailPanel.styles';
import { onTabChange, titleToId } from './DetailPanelUtils';
import { useHistory } from 'react-router-dom';
import CloseButton from 'designSystem/Badge/CloseButton/CloseButton';

const { TabPane } = Tabs;

interface DetailPanelProps {
  children: any;
  closeLink: string;
}

function DetailPanel(props: DetailPanelProps) {
  const history = useHistory();

  function onClose() {
    history.push(props.closeLink);
  }

  return (
    <DetailPanelDiv>
      <CloseButton onClose={onClose} />
      {props.children}
    </DetailPanelDiv>
  );
}

export default DetailPanel;

export function DetailPanelActions(props) {
  return <DetailPanelActionsDiv>{props.children}</DetailPanelActionsDiv>;
}

interface ScrollableProps {
  children: React.ReactElement[];
}

export function DetailPanelScollable(props: ScrollableProps) {
  const { children } = props,
    scrollable = useRef<HTMLDivElement>(null),
    scrollHeight = useFixedHeight(scrollable, 4 * 16),
    tabItems = children?.map((child) => <TabPane key={titleToId(child.props.title)} tab={child.props.title} />);

  return (
    <DetailPanelScrollableDiv>
      <Tabs onChange={onTabChange}>{tabItems}</Tabs>
      <div className="scroll-area" ref={scrollable} style={{ height: scrollHeight }}>
        {props.children}
      </div>
    </DetailPanelScrollableDiv>
  );
}

interface SectionProps {
  title: string;
  children: React.ReactNode;
}

export function DetailPanelScrollableSection(props: SectionProps) {
  const { title, children } = props;
  return (
    <DetailPanelSectionDiv id={titleToId(title)}>
      <h2 className="heading">{title}</h2>
      {children}
    </DetailPanelSectionDiv>
  );
}

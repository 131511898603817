import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { MainNavNav } from './MainNav.styles';
import AdminNav from '../AdminNav/AdminNav';
import { User } from 'typings';
import { useLocation } from 'react-router-dom';
import { Feature, useFeatureFlag } from 'components/app/FeatureFlag';
import {
  ExclamationCircleFilled,
  CloudFilled,
  EyeFilled,
  BellFilled,
  PlayCircleFilled,
  LeftOutlined,
  CloseOutlined
} from '@ant-design/icons';
import DopeIcon from 'components/ui/DopeIcon';
import MainNavItem from './MainNavItem/MainNavItem';

interface MainNavProps {
  user: User;
}

function MainNav(props: MainNavProps) {
  const { user } = props,
    sidenavLocalProp = 'sidenav-is-open',
    localStorageState = localStorage.getItem(sidenavLocalProp),
    location = useLocation(),
    [collapsed, setCollapsed] = useState<boolean>(localStorageState === 'closed'),
    [mobileActive, setMobileActive] = useState<boolean>(false),
    [classList, setClassList] = useState<string>(),
    featureFlags = useFeatureFlag([Feature.GOVERNANCE_GUARDRAILS]);

  function toggleCollapse(e) {
    setCollapsed(!collapsed);
    localStorage.setItem(sidenavLocalProp, collapsed ? 'open' : 'closed');
    e.currentTarget && e.currentTarget.blur();
  }

  function openMobile() {
    setMobileActive(true);
  }

  function closeMobile() {
    setMobileActive(false);
  }

  function checkCloseMobile(e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      closeMobile();
    }
  }

  function preventFocus(e) {
    e.preventDefault();
  }

  useEffect(() => {
    const list: string[] = [];

    collapsed && list.push('collapsed');
    mobileActive && list.push('mobile-active');

    setClassList(list.join(' '));
  }, [collapsed, mobileActive]);

  useEffect(closeMobile, [location]);

  return (
    <MainNavNav className={classList} onBlur={checkCloseMobile}>
      <button className="mobile-menu-trigger" onClick={openMobile}>
        <DopeIcon name="MENU" size={26} />
      </button>

      <div className="nav-wrap">
        <div className="nav-logo">
          <NavLink to="/">
            <div className="logo-container">
              <img src="/dops_logo_collapsed.png" alt="Disrupt Ops Logo" />
            </div>
            <div className="company-name collapse-content">{'DisruptOps'}</div>
            <button className="mobile-menu-close" onClick={closeMobile}>
              <CloseOutlined />
            </button>
          </NavLink>
        </div>

        <div className="nav-main">
          <NavLink to="/issues" onMouseDown={preventFocus}>
            <MainNavItem collapsed={collapsed} icon={<ExclamationCircleFilled />} title="Issues">
              Issues
            </MainNavItem>
          </NavLink>
          <NavLink to="/posture-monitoring" onMouseDown={preventFocus}>
            <MainNavItem collapsed={collapsed} icon={<CloudFilled />} title="Posture Monitoring">
              Posture Monitoring
            </MainNavItem>
          </NavLink>
          <NavLink to="/threat-monitoring" onMouseDown={preventFocus}>
            <MainNavItem collapsed={collapsed} icon={<EyeFilled />} title="Threat Monitoring">
              Threat Monitoring
            </MainNavItem>
          </NavLink>
          <NavLink to="/alerts" onMouseDown={preventFocus}>
            <MainNavItem collapsed={collapsed} icon={<BellFilled />} title="Alerts">
              Alerts
            </MainNavItem>
          </NavLink>
          <NavLink to="/automation" onMouseDown={preventFocus}>
            <MainNavItem collapsed={collapsed} icon={<PlayCircleFilled />} title="Automation">
              Automation
            </MainNavItem>
          </NavLink>

          {featureFlags[Feature.GOVERNANCE_GUARDRAILS] && (
            <NavLink to="/guardrails" onMouseDown={preventFocus}>
              <MainNavItem collapsed={collapsed} icon={<DopeIcon name="GUARDRAIL" size={20} />} title="Guardrails">
                Guardrails
              </MainNavItem>
            </NavLink>
          )}
          {user.client?.settings?.complianceEnabled && (
            <NavLink to="/compliance" onMouseDown={preventFocus}>
              <MainNavItem collapsed={collapsed} icon={<DopeIcon name="COMPLIANCE" size={20} />} title="Compliance">
                Compliance
              </MainNavItem>
            </NavLink>
          )}

          <button className="toggle-collapse" onClick={toggleCollapse}>
            <LeftOutlined className="collapse-icon" />
          </button>
        </div>
        <div className="nav-admin">
          <AdminNav collapsed={collapsed} />
        </div>
      </div>
    </MainNavNav>
  );
}

export default MainNav;

import React from 'react';

import { GuardrailTriggerEventSourceDefinition, TriggerSectionTitleReadViewProps } from '../definitions';
import CustomEventConfigurationReadView from '../components/CustomEventConfigurationReadView';
import customFilterDefinition from '../../filter/definitions/custom';
import { Feature } from 'components/app/FeatureFlag';

const customEventSource: GuardrailTriggerEventSourceDefinition = {
  featureFlag: Feature.GUARDRAILS_CUSTOM_EVENT_SOURCE,
  key: 'CUSTOM_EVENT',
  name: 'Custom Event',
  eventTypes: [
    {
      key: 'EVENT_TYPE',
      name: 'Event Type',
      sectionTitle: (props: TriggerSectionTitleReadViewProps) => {
        return <>Custom Event</>;
      },
      filters: [customFilterDefinition],
      getInitialConfigurationValuesFromGuardrail: guardrail => {
        return {};
      },
      getConfigureCollapseTitle: () => 'Event Type',
      configureCollapseReadView: CustomEventConfigurationReadView
    }
  ]
};

export default customEventSource;

import React from 'react';
import styled from 'styled-components';
import { CloudAccount } from 'typings';
import { Alert, Button, Steps } from 'antd';
import { graphql } from 'react-apollo';
import compose from 'just-compose';
import { CLOUD_ACCOUNT_LIST_QUERY } from 'queries';

import gql from 'graphql-tag';
import ProgressCircular from 'components/ui/ProgressCircular';
import { GridCard } from 'components/ui/Card';
import ProvisionInstructions from 'components/cloudAccounts/ProvisionInstructions';
import ErrorAlert from 'components/ui/ErrorAlert';
import ExpandedRow from '../ExpandedRow';

const Step = Steps.Step;

const DescriptionWrap = styled.div``;

// should become a global component
const ActionWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;

  > * {
    margin-left: 16px;
  }
`;

interface Props {
  cloudAccount: CloudAccount;
  onComplete: () => any;
  verifyCloudAccount: (accountId: string) => any;
}

interface State {
  recentlyCopied: boolean;
  activeStep: number;
  verifying: boolean;
  verificationSuccess: boolean | null;
  verificationError: null | any;
}

class ProvisionExistingCloudAccount extends React.Component<Props, State> {
  state = {
    recentlyCopied: false,
    activeStep: 0,
    verifying: false,
    verificationSuccess: null,
    verificationError: null
  };

  recentlyCopiedTimeout: any = null;

  constructor(props: Props) {
    super(props);

    this.handleCopied = this.handleCopied.bind(this);
    this.handleMouseLeaveCopyIcon = this.handleMouseLeaveCopyIcon.bind(this);
  }

  componentWillUnmount() {
    if (this.recentlyCopiedTimeout) {
      clearTimeout(this.recentlyCopiedTimeout);
    }
  }

  handleCopied() {
    this.setState({ recentlyCopied: true });
  }

  handleMouseLeaveCopyIcon() {
    this.recentlyCopiedTimeout = setTimeout(() => {
      this.setState({ recentlyCopied: false });
    }, 500);
  }

  // seems hacky
  async handleVerifyCloudAccount() {
    const { verifyCloudAccount } = this.props;
    this.setState({ verifying: true, activeStep: 1 });

    const { cloudAccount } = this.props;

    try {
      if (!cloudAccount.account_id) throw new Error('error');

      await verifyCloudAccount(cloudAccount.account_id);

      this.setState({
        verifying: false,
        verificationSuccess: true,
        activeStep: 2
      });
    } catch (err) {
      this.setState({
        verifying: false,
        verificationSuccess: false,
        activeStep: 0,
        verificationError: err
      });
    }
  }

  render() {
    const { verificationSuccess, verifying } = this.state;
    const { cloudAccount, onComplete } = this.props;
    const { provision_url: provisionUrl } = cloudAccount;

    return (
      <ExpandedRow onClose={onComplete}>
        <Steps direction="vertical" current={this.state.activeStep}>
          <Step
            title="Provision DisruptOps"
            description={
              <GridCard>
                {provisionUrl ? (
                  <ProvisionInstructions provisionUrl={provisionUrl} />
                ) : (
                  <ErrorAlert message="No provisioning URL found" />
                )}
              </GridCard>
            }
          />

          <Step
            description={
              <Alert
                message="Verify"
                type={verificationSuccess !== null ? (verificationSuccess ? 'success' : 'warning') : 'info'}
                description={
                  <DescriptionWrap>
                    {verifying ? (
                      <ProgressCircular />
                    ) : verificationSuccess === null ? (
                      <p>
                        Once you have ran the CloudFormation template using the link above, use the button below to
                        verify the CloudFormation Stack creation.
                      </p>
                    ) : verificationSuccess ? (
                      <p>Account Verification Successful</p>
                    ) : (
                      <p>Account Verification Failed</p>
                    )}

                    <ActionWrap>
                      <Button onClick={onComplete}>{verificationSuccess === true ? 'Done' : 'Cancel'}</Button>
                      {verificationSuccess !== true && (
                        <Button
                          disabled={verificationSuccess || false}
                          loading={verifying}
                          type="primary"
                          onClick={async () => {
                            this.handleVerifyCloudAccount();
                          }}
                        >
                          {verifying
                            ? 'Verifying'
                            : verificationSuccess === null || verificationSuccess
                            ? 'Verify'
                            : 'Try Verification Again'}
                        </Button>
                      )}
                    </ActionWrap>
                  </DescriptionWrap>
                }
              />
            }
          />
        </Steps>
      </ExpandedRow>
    );
  }
}

const VERIFY_CLOUD_ACCOUNT = gql`
  mutation VerifyCloudAccount($accountId: String!) {
    verifyCloudAccount(accountId: $accountId, input: {})
      @rest(type: "Put", method: "PUT", path: "/api/v2/accounts/{args.accountId}/verify")
  }
`;

export default compose(
  graphql(VERIFY_CLOUD_ACCOUNT, {
    props: (props) => ({
      verifyCloudAccount: (accountId: String) => {
        if (props.mutate) {
          return props.mutate({
            variables: {
              accountId
            }
          });
        }

        return null;
      }
    }),
    options: (props: any) => ({
      update: (proxy, _) => {
        if (!props.cloudAccount || !props.cloudAccount.account_id) return;

        // update cache on complete
        const query = CLOUD_ACCOUNT_LIST_QUERY;
        proxy.readQuery({ query });
      }
    })
  })
)(ProvisionExistingCloudAccount);

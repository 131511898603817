import React, { ReactElement, useState, useEffect } from 'react';
import { useProjectTree } from 'components/project/ProjectTree';
import ProjectChip from 'designSystem/ProjectChip/ProjectChip';
import DelayedLoader from 'designSystem/DelayedLoader/DelayedLoader';
import ProjectOwnershipModal from 'components/project/ProjectOwnershipModal';
import { Permissions } from '@disruptops/neo-core';
import { ProjectEditorDiv } from './ProjectEditor.style';
import useDetectorVariants from '../useDetectorVariants/useDetectorVariants';

interface ProjectEditorProps {
  projectId: string;
  onChange: Function;
}

function ProjectEditor(props: ProjectEditorProps) {
  const { projectId, onChange } = props,
    { getProject, loading } = useProjectTree({ onProjectsLoaded }),
    variants = useDetectorVariants(),
    [enableEdit, setEnableEdit] = useState(false),
    [owner, setOwner] = useState<ReactElement>(<DelayedLoader />);

  function onProjectsLoaded() {
    const project = getProject(projectId);
    if (project) {
      setOwner(<ProjectChip project={project} />);
    }
  }

  function onChangeOwner(projectId) {
    setEnableEdit(false);
    onChange(projectId);
  }

  function onCancel() {
    setEnableEdit(false);
  }

  useEffect(() => {
    onProjectsLoaded();
  }, [projectId]);

  return (
    <ProjectEditorDiv>
      {owner}{' '}
      <button type="button" className="change-owner" onClick={() => setEnableEdit(true)}>
        change
      </button>
      <ProjectOwnershipModal
        title="Set Owner"
        visible={enableEdit}
        projectId={projectId}
        requireSelection={true}
        requiredPermissions={Permissions.MODIFY_ASSESSMENTS}
        loading={loading}
        permissionsExplanations={`Only users with permissions to this project or a parent project can view (View ${variants.variantName}) or change (Modify ${variants.variantName}) this ${variants.variantName}.`}
        onChange={onChangeOwner}
        onCancel={onCancel}
      />
    </ProjectEditorDiv>
  );
}

export default ProjectEditor;

import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import NeoPage, { TitleBar, CenteredContainer } from 'components/ui/NeoPage';
import DopeIcon from 'components/ui/DopeIcon';
import { Skeleton } from 'antd';
import PageHeader from 'components/ui/PageHeader';

import styled from 'styled-components';
import Paper from 'components/ui/Paper';
import HorizontalNavbar from 'components/app/HorizontalNavbar';
import { PlaybookList, OpEventDefinitionsList } from './tabs';

// EN for "ENglish"
export const EN_GUARDRAIL = 'Op';
export const EN_GUARDRAILS = 'Ops';

function OpCreate(props: RouteComponentProps) {
  const { history } = props;

  return (
    <NeoPage
      titleBar={
        <TitleBar
          icon={<DopeIcon name="OP" size={20} />}
          sectionTitle={EN_GUARDRAILS}
          sectionTitleLinkTo="/ops1"
          title="Create"
        />
      }
    >
      <CenteredContainer size="xl">
        <PageHeader title={`Create new ${EN_GUARDRAIL}`} titleLevel={1} />

        <div style={{ marginBottom: '32px' }}>
          <Skeleton title={false} />
        </div>

        <div style={{ display: 'flex', marginBottom: '48px' }}>
          <CreateBlankGuardrailButtonRoot
            onClick={() => {
              history.push(`/ops1/details`);
            }}
          >
            <Paper>
              <div className="create-blank-guardrail-btn-content">
                <div className="create-blank-guardrail-btn-title">{`Create Blank ${EN_GUARDRAIL}`}</div>
                <DopeIcon size={100} name="ADD" />
              </div>
            </Paper>
          </CreateBlankGuardrailButtonRoot>
        </div>

        <HorizontalNavbar
          navConfig={[
            { label: 'Triggers', to: '/ops1/create/event-definitions' },
            { label: 'Playbooks', to: '/ops1/create/playbooks' }
          ]}
          type="inpage"
        />

        <Switch>
          <Route path="/ops1/create/event-definitions" component={OpEventDefinitionsList} />
          <Route path="/ops1/create/playbooks" component={PlaybookList} />
          <Redirect to="/ops1/create/event-definitions" />
        </Switch>
      </CenteredContainer>
    </NeoPage>
  );
}

const CreateBlankGuardrailButtonRoot = styled.div`
  border: 1px solid ${(p) => p.theme.grey200};
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }

  .create-blank-guardrail-btn-content {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .create-blank-guardrail-btn-title {
    font-weight: 600;
  }
`;

export default OpCreate;

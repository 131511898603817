export default {
  navBreakpointWidth: 1024, // breakpoint at which navigation style changes.
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600
  }
};

export const SORT_ORDER_MAP = {
  ascend: 'ASC',
  descend: 'DESC'
};

export const SEVERITY_NAME_MAP = ['Very Low', 'Low', 'Medium', 'High', 'Critical'];

export function getSeverityFromName(name: string): number | undefined {
  const index = SEVERITY_NAME_MAP.findIndex((i) => i === name);
  if (index === -1) return undefined;
  return index + 1;
}

export function constrainSeverity(n: number): number {
  return Math.min(5, Math.max(1, n));
}

export function getSeverityName(severity: number): string {
  return SEVERITY_NAME_MAP[constrainSeverity(severity) - 1];
}

export function getSeverityFromNormalized(normalized: number): number {
  if (normalized <= 0) return 1;
  else if (normalized < 40) return 2;
  else if (normalized < 70) return 3;
  else if (normalized < 100) return 4;
  else return 5; // normalized > 100
}

export function getSeverityNameFromNormalized(normalized: number): string {
  return SEVERITY_NAME_MAP[getSeverityFromNormalized(normalized)];
}

export function getSeverityPercent(severity: number): string {
  return (severity / 5.0).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: 0
  });
}

export type SeverityStatus = 'success' | 'warning' | 'error';
/** Returns a status for a severity, to be used with Ant Design Badges,
 *  for example: https://3x.ant.design/components/badge/#components-badge-demo-status
 */
export function getSeverityStatus(severity: number): SeverityStatus {
  const s = constrainSeverity(severity);

  if (s === 1 || s === 2) return 'success';
  else if (s === 3) return 'warning';
  else return 'error';
}

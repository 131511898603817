import HorizontalLabel from 'designSystem/HorizontalLabel/HorizontalLabel';
import React from 'react';
import { Op } from 'typings';
import TriggerBanner from '../../OpsCommon/TriggerBanner/TriggerBanner';
import { FilterEditors, shouldShowEditor, FilterDisplay } from '../../OpsCommon/TriggerFilters/FilterTypeUtils';

interface SectionProps {
  op: Op;
  contextData: any;
}

function OpDetailTrigger(props: SectionProps) {
  const { op, contextData } = props;

  return (
    <>
      <TriggerBanner op={op} />

      <div>
        {FilterEditors.filter(shouldShowEditor, op).map((filterEditor, i) => (
          <HorizontalLabel key={i} label={filterEditor.label}>
            <FilterDisplay op={op} filterListing={filterEditor} contextData={contextData} />
          </HorizontalLabel>
        ))}
      </div>
    </>
  );
}

export default OpDetailTrigger;

import { Typography } from 'antd';
import { SearchFilter } from 'components/app/FilterBar/filters';
import { CloudVendorIcon } from 'components/cloudVendors';
import DopeIcon from 'components/ui/DopeIcon';
import QueryResult from 'components/util/QueryResult';
import { CloudLabelUl } from 'designSystem/CloudLabel/CloudLabel';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import styled from 'styled-components';
import { AutomationEventDefinition } from 'typings';
import { OpSectionListItem, OpSectionListItemMeta } from '../../components';
import { OnSelectTriggerFunc } from '../../OpEditor';

const OP_TRIGGERS_QUERY = gql`
  query OpTriggers(
    $eventDefinitionId: [String]
    $pageNumber: Int
    $pageSize: Int
    $search: String
    $sortBy: String
    $sortDirection: SortDirection
  ) {
    eventDefinitions(
      id: $eventDefinitionId
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      pageInfo {
        total
        current
        size
        sortBy
        sortDirection
      }
      nodes {
        id
        name
        key
        description
        vendor
        vendorProduct
        severity
        filters {
          key
          type
          required
          name
          options {
            name
            path
            patterns
            foundByProduct
          }
        }
        context
        labels
        eventSource {
          id
        }
      }
    }
  }
`;

const TriggerListRoot = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

interface Props {
  onSelect: OnSelectTriggerFunc;
}

function SelectTrigger(props: Props) {
  const { onSelect } = props;

  const [triggerSearchStr, setTriggerSearchStr] = useState<string | null>(null);
  const { loading, error, data } = useQuery(OP_TRIGGERS_QUERY, {
    variables: { pageSize: 10, search: triggerSearchStr, sortBy: 'name', sortDirection: 'ASC' }
  });

  const eventDefinitions: AutomationEventDefinition[] = data?.eventDefinitions?.nodes || [];
  const { pageInfo } = data?.eventDefinitions || {};
  const { total, size } = pageInfo || {};

  return (
    <>
      <SearchFilter
        classes={{ root: 'search-input' }}
        search={triggerSearchStr || ''}
        onSearchChange={(str) => setTriggerSearchStr(str && str.length > 0 ? str : null)}
        placeholder="Search available Triggers"
      />

      <QueryResult
        loading={loading}
        error={error}
        data={data}
        loadingHeight={400}
        loadingCenterVertically={false}
        entityName="Triggers"
      >
        {() => {
          return (
            <TriggerListRoot>
              <OpSectionListItem
                key="all-findings"
                title="All Findings"
                description="All finding events."
                icon={<DopeIcon name="EVENT" size={30} />}
                onClick={() => onSelect({ eventDefinition: undefined, isAllFindingsSelected: true })}
              />

              {eventDefinitions.map((eventDefinition) => {
                const { vendor, vendorProduct, severity } = eventDefinition;
                const labels = eventDefinition.labels || [];

                return (
                  <OpSectionListItem
                    key={eventDefinition.id}
                    title={eventDefinition.name}
                    description={eventDefinition.description}
                    icon={<CloudVendorIcon vendor={eventDefinition.vendor} />}
                    onClick={onSelect ? () => onSelect({ eventDefinition }) : undefined}
                    meta={
                      <CloudLabelUl>
                        {labels.map((label, idx) => (
                          <li key={`${idx}_${label}`}>{label}</li>
                        ))}
                      </CloudLabelUl>
                    }
                    metaBottom={
                      <>
                        {vendor && vendorProduct && (
                          <OpSectionListItemMeta
                            size="small"
                            label="Event Source:"
                            value={`${vendor}:${vendorProduct}`}
                          />
                        )}
                        {severity !== null && severity !== undefined && (
                          <OpSectionListItemMeta size="small" label="Severity:" value={`${severity}`} />
                        )}
                      </>
                    }
                  />
                );
              })}
              {total > size && (
                <Typography.Text>More Triggers are available, please refine your search above.</Typography.Text>
              )}
            </TriggerListRoot>
          );
        }}
      </QueryResult>
    </>
  );
}

export default SelectTrigger;

import React from 'react';
import { Select as AntSelect } from 'antd';
import validator from 'services/validator';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { size } from 'typings';

const TAGS_QUERY = gql`
  query AWSTagListForSelect($DopsTagText: String) {
    tags(dops_tag_text: $DopsTagText) {
      key
      value
    }
  }
`;

const schema = validator.object().shape({
  key: validator.string(),
  value: validator.string()
});

export const inputDefinition = {
  schema
};

let throttleTimeout;
const THROTTLE_DELAY = 300;

function throttleSearch(callback: () => void) {
  if (throttleTimeout) {
    clearTimeout(throttleTimeout);
    throttleTimeout = null;
  }

  throttleTimeout = setTimeout(callback, THROTTLE_DELAY);
}

interface Props {
  name: string;
  value: AWSTag[];
  setFieldTouched: (name: string, touched: boolean) => any;
  setFieldValue: (name: string, value: AWSTag) => any;
  multiple?: boolean;
  placeholder?: string;
  size?: size;
}

interface State {
  searchString: string | null; //
}

class AWSTagSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      searchString: null
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleSearch(searchString: string) {
    throttleSearch(() => {
      this.setState({ searchString: searchString.length > 0 ? searchString : null });
    });
  }

  handleBlur() {
    const { setFieldTouched, name } = this.props;

    this.setState({ searchString: null }, () => setFieldTouched(name, true));
  }

  render() {
    const { name, value, setFieldValue, multiple = false, size = 'large', placeholder } = this.props;
    const { searchString } = this.state;

    let defaultValue;
    if (Array.isArray(value)) {
      defaultValue = value.map(tag => tagToString(tag));
    } else if (value) {
      defaultValue = [tagToString(value)];
    }

    return (
      <Query query={TAGS_QUERY} variables={{ DopsTagText: searchString }}>
        {results => {
          const { loading, error, data } = results;

          const handleChange = selectedValues => {
            if (!Array.isArray(selectedValues)) selectedValues = [selectedValues];

            const allTags = (data && data.tags) || [];

            const selectedTags = selectedValues
              .map(value => {
                const tag = allTags.find(tag => tagToString(tag) === value);
                if (!tag) return undefined;
                return { ...tag };
              })
              .filter(value => !!value);

            setFieldValue(name, selectedTags);
          };

          return (
            <AntSelect
              allowClear
              mode={multiple ? 'multiple' : 'default'}
              size={size}
              defaultValue={defaultValue}
              style={{ width: '100%' }}
              onChange={handleChange}
              placeholder={!error ? placeholder || 'All tags' : 'Error loading AWS tags'}
              onBlur={this.handleBlur}
              disabled={!!error}
              loading={loading}
              onSearch={this.handleSearch}
              filterOption={false}
              autoClearSearchValue={false}
            >
              {data &&
                data.tags &&
                data.tags.map(tag => {
                  const tagValue = tagToString(tag);
                  return <AntSelect.Option key={tagValue}>{tagValue}</AntSelect.Option>;
                })}
            </AntSelect>
          );
        }}
      </Query>
    );
  }
}

interface AWSTag {
  key: string;
  value: string;
}

function tagToString(tag: AWSTag) {
  return `${tag.key}:${tag.value}`;
}

export default AWSTagSelect;

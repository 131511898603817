import styled from 'styled-components';

export const DetailPanelSectionDiv = styled.div`
  margin: 0 0 1.5rem;

  .heading {
    font-weight: 500;
    font-size: 16px;
    margin: 0.5rem 0;
    border-bottom: 2px solid #e0d1fe;
    color: #333;
  }
`;

export const DetailPanelDiv = styled.div`
  top: 0;
  right: 0;
  padding: 1rem;
  position: relative;
  color: #333;

  .detail-panel__actions {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .ant-btn {
    border-radius: 4px;
  }
`;

export const DetailPanelActionsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
`;

export const DetailPanelScrollableDiv = styled.div`
  .scroll-area {
    overflow-y: auto;
  }
`;

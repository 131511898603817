import styled from 'styled-components';

export const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const Heading = styled.h2`
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-size: 16px;
`;

import { Button, Col, List, message, Row, Spin, Table } from 'antd';
import AuthContext from 'components/app/Auth/AuthContext';
import FilterBar from 'components/app/FilterBar';
import { SearchFilter } from 'components/app/FilterBar/filters';
import Paginator from 'components/app/Paginator';
import DateTime from 'components/ui/DateTime';
import DopeIcon from 'components/ui/DopeIcon';
import ErrorAlert from 'components/ui/ErrorAlert';
import { TableWrap } from 'components/ui/Table';
import TableLink from 'components/ui/Table/TableLink';
import PushFetch from 'components/util/Push/PushFetch';
import { getStatusFromIssue, ISSUE_STATUS_DEFINITIONS } from 'constants/issue';
import { uuidRegex } from 'constants/regularExpressions';
import ui, { getSeverityName, SEVERITY_NAME_MAP } from 'constants/ui';
import { saveAs } from 'file-saver';
import gql from 'graphql-tag';
import { AWS_REGION_LIST_QUERY, CLOUD_ACCOUNT_LIST_QUERY } from 'queries';
import React, { Component, MouseEvent, SyntheticEvent } from 'react';
import { Mutation, Query } from 'react-apollo';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { CloudAccount, FilterDefinition, Issue } from 'typings';
import createObjectMap from 'utilities/createObjectMap';
import { getResourceUrl, ResourceType } from 'utilities/inventory';
import { rawToDashed } from 'utilities/resourceType';
import ISSUE_LIST_QUERY from './issueListQuery';
import IssueStatusSelect from './IssueStatusSelect';

export const EXPORT_ISSUES_MUTATION = gql`
  mutation ExportIssueList(
    $isResolved: Boolean
    $isExempted: Boolean
    $severity: [Int]
    $region: [String]
    $assessmentId: [String]
    $accountId: [String]
    $updatedAtStart: DateTime
    $updatedAtEnd: DateTime
    $createdAtStart: DateTime
    $createdAtEnd: DateTime
    $cloudVendor: [String]
    $cloudService: [String]
    $projectId: [String]
  ) {
    exportIssues(
      isResolved: $isResolved
      isExempted: $isExempted
      severity: $severity
      region: $region
      assessmentId: $assessmentId
      accountId: $accountId
      updatedAtStart: $updatedAtStart
      updatedAtEnd: $updatedAtEnd
      createdAtStart: $createdAtStart
      createdAtEnd: $createdAtEnd
      cloudVendor: $cloudVendor
      projectId: $projectId
      cloudService: $cloudService
    ) {
      url
    }
  }
`;

const Root = styled.div`
  .issue-status-select .ant-select-selection {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .action-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;

    .export-btn.ant-btn.btn-with-icon > svg:first-child {
      margin-right: 6px;
    }
  }

  .issues-table-header {
    padding: 0 8px;
  }

  .filter-row-content {
    display: block;

    > * > * {
      width: 100%;
    }
  }

  .issues-table-header-filter-row {
    margin-bottom: 0px;
  }

  .filter-row-header {
    display: block;
    width: 100%;
    margin-bottom: 0px;
  }

  .ant-row .filter-item-border-right {
    border-right: 0;
  }

  @media (min-width: ${ui.breakpoints.sm}px) {
    .filter-row-content {
      display: flex;

      > * > * {
        width: inherit;
      }
    }

    .ant-row .filter-item-border-right {
      border-right: 1px solid #ccc;
    }
  }

  .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
    cursor: pointer;
    padding: 16px;
  }

  .list-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 0px 12px 16px;
  }

  .issue-item-content {
    display: flex;
    flex: 1;
    justify-content: space-between;

    .timing {
      text-align: right;
    }
  }

  .issue-name {
    vertical-align: middle;
    font-weight: 500;
    font-size: 17px;
    color: ${(p) => p.theme.grey800};
    margin-bottom: 4px;

    .issue-name-icon {
      padding-right: 6px;
    }

    .issue-name-label {
      vertical-align: text-bottom;
    }
  }

  .issue-meta {
    font-size: 13px;

    .issue-meta-icon {
      padding-right: 4px;
    }

    .issue-meta-value {
      vertical-align: text-bottom;
      padding-right: 4px;
    }
  }

  .issue-context-time {
    color: ${(p) => p.theme.grey500};
    font-size: 11px;
    margin: 0px 0px 10px;
  }

  .ant-list-pagination {
    margin-top: 0px;
  }

  .list-item {
    padding: 20px;
    border-bottom: 1px solid ${(p) => p.theme.grey300};
  }
`;

const ISSUE_DEFINITIONS_FOR_ISSUE_LIST_QUERY = gql`
  query issueDefinitions {
    issueDefinitions(pageSize: 1000, sortBy: "name", sortDirection: ASC) {
      nodes {
        id
        name
      }
    }
  }
`;

const ASSESSMENTS_FOR_ISSUE_LIST_QUERY = gql`
  query assessments {
    assessments(pageSize: 1000, sortBy: "name", sortDirection: ASC) {
      nodes {
        id
        name
      }
    }
  }
`;

// const LABELS_FOR_ISSUE_LIST_QUERY = gql`
//   query uniqueIssueLabels {
//     uniqueIssueLabels
//   }
// `;

const CLOUD_SERVICES_FOR_ISSUE_LIST_QUERY = gql`
  query uniqueIssueCloudServices {
    uniqueIssueCloudServices
  }
`;

const CLOUD_VENDORS_FOR_ISSUE_LIST_QUERY = gql`
  query uniqueIssueCloudVendors {
    uniqueIssueCloudVendors
  }
`;

const PROJECTS_FOR_ISSUES_LIST_QUERY = gql`
  query projectsForIssueList {
    projects {
      items {
        project_id
        name
      }
    }
  }
`;

const ISSUE_LIST_FILTERS: FilterDefinition[] = [
  // assessment
  {
    name: 'assessmentId',
    label: 'Detector',
    qsKey: 'assessmentId',
    many: true,
    query: ASSESSMENTS_FOR_ISSUE_LIST_QUERY,
    getListFromData: (data) => data.assessments.nodes,
    transformDataToOption: (dataItem) => ({
      key: dataItem.id,
      label: dataItem.name
    }),
    transformValueToLabel: (lookupMap) => (value) => {
      const item = (lookupMap && lookupMap[value]) || {};
      return item.name || value;
    },
    validateFilterTextValue: (value) => value.length === 36 && uuidRegex.test(value),
    filterBrickReadView: (props) => {
      const { value } = props;

      return (
        <Query
          query={gql`
            query AssessmentForIssueList($id: [String]) {
              assessments(id: $id) {
                nodes {
                  id
                  name
                }
              }
            }
          `}
          variables={{ id: value }}
          fetchPolicy={'cache-first'}
        >
          {({ loading, data, error }) => {
            if (loading) return <Spin size="small" />;
            if (!data || error) return value;
            const item: {
              id: string;
              name?: string | null;
            } = data.assessments.nodes[0];

            if (!item) return value;

            return item.name || value;
          }}
        </Query>
      );
    }
  },

  // cloud account
  {
    name: 'accountId',
    label: 'Cloud Account',
    qsKey: 'accountId',
    many: true,
    // variableName: () => 'accountId',
    query: CLOUD_ACCOUNT_LIST_QUERY,
    getListFromData: (data) =>
      data.accounts.items.sort((a, b) => {
        var x = a?.nickname?.toUpperCase() || a?.name?.toUpperCase();
        var y = b?.nickname?.toUpperCase() || a?.name?.toUpperCase();
        if (x < y) return -1;
        else if (x > y) return 1;
        return 0;
      }),
    transformDataToOption: (dataItem) => ({
      key: dataItem.account_id,
      label: dataItem.nickname || dataItem.name || dataItem.assumerole_account_id || dataItem.account_id
    }),
    transformValueToLabel: (lookupMap) => (value) => {
      const item = (lookupMap && lookupMap[value]) || {};
      return item.nickname || item.name || value;
    },
    validateFilterTextValue: (value) => value.length === 36 && uuidRegex.test(value)
  },

  // cloud service
  {
    name: 'cloudService',
    label: 'Cloud Service',
    qsKey: 'cloudService',
    many: true,
    query: CLOUD_SERVICES_FOR_ISSUE_LIST_QUERY,
    getListFromData: (data) => data.uniqueIssueCloudServices,
    transformDataToOption: (dataItem) => ({
      key: dataItem,
      label: dataItem
    }),
    transformValueToLabel: (lookupMap) => (value) => value
  },

  // cloud vendor
  {
    name: 'cloudVendor',
    label: 'Cloud Vendor',
    qsKey: 'cloudVendor',
    many: true,
    query: CLOUD_VENDORS_FOR_ISSUE_LIST_QUERY,
    getListFromData: (data) => data.uniqueIssueCloudVendors,
    transformDataToOption: (dataItem) => ({
      key: dataItem,
      label: dataItem
    }),
    transformValueToLabel: (lookupMap) => (value) => value
  },

  // created start date
  {
    name: 'createdAtStart',
    label: 'Created Start Date',
    qsKey: 'createdAtStart',
    many: false,
    filterBrickReadView: (props) => {
      const { value } = props;
      return new Date(value)?.toLocaleString() || value;
    }
  },

  // created end date
  {
    name: 'createdAtEnd',
    label: 'Created End Date',
    qsKey: 'createdAtEnd',
    many: false,
    filterBrickReadView: (props) => {
      const { value } = props;
      return new Date(value)?.toLocaleString() || value;
    }
  },

  // issue type
  {
    name: 'issueDefinitionId',
    label: 'Issue Type',
    qsKey: 'issueDefinitionId',
    many: true,
    query: ISSUE_DEFINITIONS_FOR_ISSUE_LIST_QUERY,
    getListFromData: (data) => data.issueDefinitions.nodes,
    transformDataToOption: (dataItem) => ({
      key: dataItem.id,
      label: dataItem.name
    }),
    transformValueToLabel: (lookupMap) => (value) => {
      const item = (lookupMap && lookupMap[value]) || {};
      return item.name || value;
    },
    validateFilterTextValue: (value) => value.length === 36 && uuidRegex.test(value),
    filterBrickReadView: (props) => {
      const { value } = props;

      return (
        <Query
          query={gql`
            query IssueDefinitionForIssueList($id: ID) {
              issueDefinitions(id: $id) {
                nodes {
                  id
                  name
                  key
                }
              }
            }
          `}
          variables={{ id: value }}
          fetchPolicy={'cache-first'}
        >
          {({ loading, data, error }) => {
            if (loading) return <Spin size="small" />;
            if (!data || error) return value;
            const item: {
              id: string;
              name?: string | null;
              key?: string | null;
            } = data.issueDefinitions.nodes[0];

            if (!item) return value;

            return item.name || item.key || value;
          }}
        </Query>
      );
    }
  },

  // // labels
  // {
  //   name: 'labels',
  //   label: 'Labels',
  //   qsKey: 'labels',
  //   many: true,
  //   query: LABELS_FOR_ISSUE_LIST_QUERY,
  //   getListFromData: (data) => data.uniqueIssueLabels,
  //   transformDataToOption: (dataItem) => ({
  //     key: dataItem,
  //     label: dataItem
  //   }),
  //   transformValueToLabel: (lookupMap) => (value) => value,
  //   validateFilterTextValue: (value) => value.length === 36 && uuidRegex.test(value),
  //   filterBrickReadView: (props) => props.value
  // },

  // project
  {
    name: 'projectId',
    label: 'Project',
    qsKey: 'projectId',
    many: true,
    query: PROJECTS_FOR_ISSUES_LIST_QUERY,
    getListFromData: (data) =>
      data.projects.items.sort((a, b) => {
        var x = a?.name?.toUpperCase();
        var y = b?.name?.toUpperCase();
        if (x < y) return -1;
        else if (x > y) return 1;
        return 0;
      }),
    transformDataToOption: (dataItem) => ({
      key: dataItem.project_id,
      label: dataItem.name
    }),
    transformValueToLabel: (lookupMap) => (value) => {
      const item = (lookupMap && lookupMap[value]) || {};
      return item.name || value;
    },
    validateFilterTextValue: (value) => value.length === 36 && uuidRegex.test(value),
    filterBrickReadView: (props) => {
      const { value } = props;

      return (
        <Query query={PROJECTS_FOR_ISSUES_LIST_QUERY} variables={{ id: value }} fetchPolicy={'cache-first'}>
          {({ loading, data, error }) => {
            if (loading) return <Spin size="small" />;
            if (!data || error) return value;
            const item: {
              id: string;
              name?: string | null;
            } = data.projects.items.find((p) => p.project_id === value);

            if (!item) return value;

            return item.name || value;
          }}
        </Query>
      );
    }
  },

  // region
  {
    name: 'region',
    label: 'Region',
    qsKey: 'region',
    many: true,
    variableName: () => 'dops_regions',
    query: AWS_REGION_LIST_QUERY,
    getListFromData: (data) => data.regions,
    transformDataToOption: (dataItem) => ({
      key: dataItem.name,
      label: dataItem.name
    }),
    transformValueToLabel: (lookupMap) => (value) => {
      return value;
    }
  },

  // severity
  {
    name: 'severity',
    label: 'Severity',
    qsKey: 'severity',
    many: true,
    options: SEVERITY_NAME_MAP.map((name, index) => {
      return {
        key: `${index + 1}`,
        label: name
      };
    }),
    filterBrickReadView: (props) => {
      return <>{getSeverityName(props.value)}</>;
    }
  },

  // updated start date
  {
    name: 'updatedAtStart',
    label: 'Updated Start Date',
    qsKey: 'updatedAtStart',
    many: false,
    filterBrickReadView: (props) => {
      const { value } = props;
      return new Date(value)?.toLocaleString() || value;
    }
  },

  // updated end date
  {
    name: 'updatedAtEnd',
    label: 'Updated End Date',
    qsKey: 'updatedAtEnd',
    many: false,
    filterBrickReadView: (props) => {
      const { value } = props;
      return new Date(value)?.toLocaleString() || value;
    }
  }
];

const SORT_BY_MAP = {
  name: 'NAME',
  severity: 'SEVERITY',
  region: 'REGION',
  updated_at: 'UPDATED_AT'
};

const SORT_ORDER_MAP = {
  ascend: 'ASC',
  descend: 'DESC'
};

interface Props extends RouteComponentProps {
  queryVariables?: {
    assessmentId?: string;
  };
}

interface State {}

class IssueList extends Component<Props, State> {
  static contextType = AuthContext;

  render() {
    const { history, location, queryVariables = {} } = this.props;

    return (
      <Paginator filters={ISSUE_LIST_FILTERS} pageSize={10}>
        {({ search, filterValues, pageNumber, pageSize, pushFilters, sortBy, sortDirection, updateSearchParams }) => {
          const searchParams = new URLSearchParams(location.search);
          const issueStatus: string = searchParams.has('status') ? searchParams.get('status') || 'open' : 'open';

          const statusVariables: any =
            issueStatus === 'all'
              ? {}
              : {
                  isResolved: issueStatus === 'resolved' ? true : false,
                  isExempted: issueStatus === 'exempt' ? true : false
                };

          const severities =
            filterValues.severity instanceof Array
              ? filterValues.severity.map((s) => parseInt(s, 10))
              : filterValues.severity;

          const issueListVariables = {
            ...statusVariables,
            accountId: filterValues.accountId,
            assessmentId: filterValues.assessmentId,
            cloudService: filterValues.cloudService,
            cloudVendor: filterValues.cloudVendor,
            createdAtStart: filterValues.createdAtStart,
            createdAtEnd: filterValues.createdAtEnd,
            issueDefinitionId: filterValues.issueDefinitionId,
            labels: filterValues.labels,
            projectId: filterValues.projectId,
            region: filterValues.region,
            search: search,
            severity: severities,
            updatedAtStart: filterValues.updatedAtStart,
            updatedAtEnd: filterValues.updatedAtEnd,
            ...queryVariables
          };

          return (
            <Query
              query={ISSUE_LIST_QUERY}
              variables={{
                ...issueListVariables,
                pageNumber,
                pageSize,
                sortDirection: sortDirection || SORT_ORDER_MAP.descend,
                sortBy: sortBy || 'UPDATED_AT'
              }}
            >
              {(results) => {
                const { loading, error, data, refetch } = results;

                if (error) {
                  return <ErrorAlert message="Error loading issues" error={error} />;
                }

                const resourceTypes: ResourceType[] = data && data.resource_types;
                const cloudAccounts: CloudAccount[] = data && data.accounts && data.accounts.items;
                const accountsHash: {
                  [id: string]: CloudAccount;
                } = createObjectMap(cloudAccounts, 'account_id');

                const total = data?.issues?.pageInfo?.total;
                // const current = data?.issues?.pageInfo?.current;
                // const size = data?.issues?.pageInfo?.size;

                const pagination = {
                  total: total,
                  size: 'small' as 'small',
                  pageSize,
                  current: pageNumber,
                  position: 'bottom' as 'bottom',
                  showTotal: (total, range) =>
                    `${range[0]?.toLocaleString()}-${range[1]?.toLocaleString()} of ${total?.toLocaleString()} items`
                };

                const filteredIssues: Issue[] =
                  data && data.issues && data.issues.nodes
                    ? data.issues.nodes.filter((i: Issue) => {
                        if (!issueStatus || issueStatus === 'open') return !i.isExempted && !i.isResolved;
                        if (issueStatus === 'resolved') return i.isResolved;
                        if (issueStatus === 'exempt') return i.isExempted && !i.isResolved;

                        return true; // all;
                      })
                    : [];

                return (
                  <PushFetch
                    refetchTest={({ eventType, message }) => {
                      switch (eventType) {
                        case 'issueCreated': {
                          if (
                            message.issue &&
                            message.issue.assessmentId &&
                            queryVariables &&
                            queryVariables.assessmentId &&
                            queryVariables.assessmentId === message.issue.assessmentId
                          ) {
                            return true;
                          }

                          if (
                            message.issue &&
                            message.issue.id &&
                            Boolean(filteredIssues.find((i) => i.id === message.issue.id))
                          ) {
                            // duplicate issues can be found and updated. Issue.id remains the same.
                            return true;
                          }

                          return false;
                        }

                        case 'actionTaskCompleted': {
                          return message.issueId && Boolean(filteredIssues.find((i) => i.id === message.issueId));
                        }

                        default:
                          return false;
                      }
                    }}
                    refetchFn={refetch}
                  >
                    <Root>
                      <div className="action-row">
                        <Mutation mutation={EXPORT_ISSUES_MUTATION}>
                          {(exportIssues, { loading }) => {
                            return (
                              <Button
                                type="ghost"
                                className="square btn-with-icon export-btn"
                                loading={loading}
                                onClick={async () => {
                                  const result = await exportIssues({ variables: issueListVariables });
                                  if (result.error) {
                                    return message.error('Error generating CSV');
                                  }
                                  if (!result.data || !result.data.exportIssues || !result.data.exportIssues.url) {
                                    return message.error('Error starting CSV download.');
                                  }

                                  const url = result.data.exportIssues.url;
                                  saveAs(url, 'export.csv');
                                }}
                              >
                                {!loading && <DopeIcon name="DOWNLOAD" />}
                                Export
                              </Button>
                            );
                          }}
                        </Mutation>
                      </div>

                      <FilterBar
                        history={history}
                        location={location}
                        search={search}
                        filters={ISSUE_LIST_FILTERS}
                        filterValues={filterValues}
                        onFilterChange={pushFilters}
                        accountsHash={accountsHash} // map?
                      >
                        {({ search, handleSearchChange, handleFilterChange }) => {
                          return (
                            <>
                              <IssueStatusSelect
                                value={issueStatus}
                                onChange={(selected) => {
                                  const updatedSearchParams = new URLSearchParams(location.search);
                                  updatedSearchParams.set('status', selected);

                                  updatedSearchParams.delete('p');

                                  history.push({
                                    search: updatedSearchParams.toString()
                                  });
                                }}
                              />
                              <SearchFilter
                                size="large"
                                filters={ISSUE_LIST_FILTERS}
                                search={search}
                                onSearchChange={handleSearchChange}
                              />
                            </>
                          );
                        }}
                      </FilterBar>

                      <Row>
                        <Col span={24} lg={0}>
                          <TableWrap>
                            <List
                              loading={loading}
                              pagination={pagination}
                              dataSource={(data && data.issues && data.issues.nodes) || []}
                              renderItem={(issue: Issue) => {
                                return (
                                  <div className="list-item">
                                    {this.renderIssueDescription(issue, resourceTypes, cloudAccounts)}
                                  </div>
                                );
                              }}
                            />
                          </TableWrap>
                        </Col>
                        <Col span={0} lg={24}>
                          <TableWrap>
                            <Table
                              size="small"
                              loading={loading}
                              pagination={pagination}
                              locale={{
                                emptyText: 'No matching issues'
                              }}
                              rowKey="id"
                              columns={this.getTableColumns(resourceTypes, cloudAccounts)}
                              onChange={(pagination, filters, sorter, a) => {
                                updateSearchParams({
                                  sortBy: SORT_BY_MAP[sorter.columnKey] || 'UPDATED_AT',
                                  sortDirection: SORT_ORDER_MAP[sorter.order] || SORT_ORDER_MAP.descend,
                                  page: pagination.current || 1
                                });
                              }}
                              dataSource={filteredIssues || []}
                              onRow={(item: Issue) => {
                                return {
                                  onClick: (e: SyntheticEvent) => {
                                    history.push(`/issue/${item.id}`, { onModalClosepath: location.pathname });
                                  }
                                };
                              }}
                            />
                          </TableWrap>
                        </Col>
                      </Row>
                    </Root>
                  </PushFetch>
                );
              }}
            </Query>
          );
        }}
      </Paginator>
    );
  }

  renderIssueName(issue: Issue) {
    const status = getStatusFromIssue(issue);

    const statusDefinition = ISSUE_STATUS_DEFINITIONS[status];

    return (
      <div className="issue-name">
        <span className="issue-name-icon" style={{ color: statusDefinition.color }}>
          <DopeIcon name={statusDefinition.icon} />
        </span>
        <span className="issue-name-label">{issue.name}</span>
      </div>
    );
  }

  renderIssueDescription(issue: Issue, resourceTypes: ResourceType[], cloudAccounts: CloudAccount[]) {
    const { history, location } = this.props;
    const accountId = issue.accountId || '';
    const itemType = issue.itemType || '';
    const itemKey = issue.itemKey || '';
    const region = issue.region || undefined;

    let resourceType = resourceTypes.find((item) => item.key === itemType);
    let cloudAccount = cloudAccounts.find((item) => item.account_id === accountId) || null;
    let accountName =
      cloudAccount && cloudAccount.nickname ? cloudAccount.nickname : `aws-${issue.awsAccountId || issue.accountId}`;

    const itemUrl = getResourceUrl(
      {
        accountId,
        itemType,
        itemKey,
        region
      },
      accountName
    );

    return (
      <div>
        <div className="issue-context-time">
          {issue.createdAt && (
            <span>
              Found <DateTime dateTime={issue.createdAt} />
            </span>
          )}
          {this.renderIssueContextTime(issue)}
        </div>
        <a
          href={`/issue/${issue.id}`}
          onClick={(e: MouseEvent) => {
            e.preventDefault();
            history.push(`/issue/${issue.id}`, { onModalClosepath: location.pathname });
          }}
        >
          {this.renderIssueName(issue)}
        </a>
        <div className="issue-meta">
          <span className="cloud-info-account">
            <span className="issue-meta-value">{accountName}</span>
          </span>
          {issue.region && (
            <span className="cloud-info-region">
              <span className="issue-meta-icon">
                <DopeIcon name="CHEVRON_RIGHT" />
              </span>
              <span className="issue-meta-value">{issue.region}</span>
            </span>
          )}
          {itemType && (
            <span className="cloud-info-item-type">
              <span className="issue-meta-icon">
                <DopeIcon name="CHEVRON_RIGHT" />
              </span>
              <span className="issue-meta-value">
                {resourceType ? (
                  <TableLink href={`/inventory/resources/${rawToDashed(itemType)}`}>
                    {resourceType.name || itemType}
                  </TableLink>
                ) : (
                  itemType
                )}
              </span>
            </span>
          )}
          <span className="cloud-info-item-id">
            <span className="issue-meta-icon">
              <DopeIcon name="CHEVRON_RIGHT" />
            </span>
            <span className="issue-meta-value">
              {resourceType ? <TableLink href={itemUrl}>{itemKey}</TableLink> : itemKey}
            </span>
          </span>
        </div>
      </div>
    );
  }

  renderIssueContextTime(issue: Issue) {
    if (issue.isResolved && issue.resolvedAt) {
      return (
        <span>
          , resolved <DateTime dateTime={issue.resolvedAt} /> by Unknown
        </span>
      );
    }

    if (issue.isExempted && issue.exemptedAt) {
      return (
        <span>
          , marked as exempt <DateTime dateTime={issue.exemptedAt} />
        </span>
      );
    }

    return null;
  }

  renderIssueContent(issue: Issue) {
    return (
      <div className="issue-item-content">
        <div className="timing">{this.renderDateTime(issue)}</div>
      </div>
    );
  }

  renderDateTime(issue: Issue) {
    return <DateTime dateTime={issue.updatedAt || issue.createdAt} />;
  }

  getTableColumns(resourceTypes: ResourceType[], cloudAccounts: CloudAccount[]) {
    return [
      {
        key: 'name',
        dataIndex: 'name',
        title: 'Issue',
        sorter: true,
        render: (name, issue) => {
          return <div>{this.renderIssueDescription(issue, resourceTypes, cloudAccounts)}</div>;
        }
      },
      {
        key: 'severity',
        dataIndex: 'severity',
        title: 'Severity',
        align: 'center' as 'center',
        sorter: true,
        render: (severity, issue) => (severity ? <span title={severity}>{getSeverityName(severity)}</span> : severity)
      },
      {
        key: 'region',
        dataIndex: 'region',
        title: 'Region',
        align: 'left' as 'left',
        sorter: true
      },
      {
        key: 'updatedAt',
        dataIndex: 'updatedAt',
        title: 'Last observed',
        align: 'right' as 'right',
        className: 'no-wrap',
        render: (updatedAt, issue) => this.renderDateTime(issue),
        sorter: true
      }
    ];
  }
}

export default IssueList;

import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ClientProject } from 'typings';
import createObjectMap from 'utilities/createObjectMap';

export interface ProjectTreeNode extends ClientProject {
  childProjects: ProjectTreeNode[];
}

export const PROJECT_TREE_QUERY = gql`
  query ProjectTree($rootProjectId: String!) {
    projects @rest(type: "ClientProject", path: "/api/v2/projects", method: "GET") {
      client_id
      project_id

      parent_id
      is_system_controlled

      name
      description

      accounts_list @type(name: "ClientAccount") {
        account_id
        client_id

        project_id
        project_path

        name
        nickname

        provider
        assumerole_account_id
        assumerole_arn
        assumerole_external_id

        policy_code
        provision_url

        created
        updated
      }

      created
      updated
    }
  }
`;

interface UseProjectTreeOptions {
  rootProjectId?: string | undefined;
  onProjectsLoaded?: (allProjects: ClientProject[]) => any;
}

function useProjectTree(options?: UseProjectTreeOptions) {
  const { loading, error, data } = useQuery(PROJECT_TREE_QUERY, {
    onCompleted: (data) => {
      if (!options?.onProjectsLoaded) return;
      const allProjects: ClientProject[] = sortProjects(data?.projects || []);
      options?.onProjectsLoaded(allProjects);
    }
  });

  const allProjects: ClientProject[] = sortProjects(data?.projects || []);

  const projectMapByProjectId = createObjectMap(allProjects, 'project_id');
  const projectMapByParentId = createObjectMap(allProjects, 'parent_id', true);

  const rootProjects = getRootProjectsFromProjectMap(projectMapByProjectId, options?.rootProjectId);

  const projectTree: ProjectTreeNode[] = rootProjects.map((rootProject) =>
    createProjectTree(rootProject, projectMapByParentId)
  );

  return {
    loading,
    error,
    projectTree,
    allProjects,
    getChildProjects: (projectId: string) => getChildProjectsRecursively(projectId, projectMapByParentId),
    getParentProjects: (projectId: string) => getParentProjectsRecursively(projectId, projectMapByProjectId),
    getProject: (projectId: string) => projectMapByProjectId[projectId]
  };
}

type ProjectMapByParentId = {
  [key: string]: ClientProject[];
};

type ProjectMapByProjectId = {
  [key: string]: ClientProject;
};

function getRootProjectsFromProjectMap(
  projectMapByProjectId: ProjectMapByProjectId,
  rootProjectId?: string
): ClientProject[] {
  if (rootProjectId && projectMapByProjectId[rootProjectId]) return [projectMapByProjectId[rootProjectId]];

  return Object.values(projectMapByProjectId).filter((project) => {
    const parentProjectId = project.parent_id;
    if (!parentProjectId) return true;
    if (!projectMapByProjectId[parentProjectId]) return true;
    return false;
  });
}

function createProjectTree(project: ClientProject, projectMapByParentId: ProjectMapByParentId): ProjectTreeNode {
  const childProjects = getChildProjects(project.project_id, projectMapByParentId).map((childProject) => {
    return createProjectTree(childProject, projectMapByParentId);
  });

  return {
    ...project,
    childProjects: childProjects
  };
}

function getChildProjects(projectId: string, projectMapByParentId: ProjectMapByParentId): ClientProject[] {
  const childProjects = projectMapByParentId[projectId] || [];

  return childProjects;
}

function sortProjects(projects: ClientProject[]) {
  return [...projects].sort((a, b) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;

    const aCreatedTime = new Date(a.created).getTime();
    const bCreatedTime = new Date(b.created).getTime();
    if (aCreatedTime > bCreatedTime) return 1;
    if (aCreatedTime < bCreatedTime) return -1;

    return 0;
  });
}

function getParentProjectsRecursively(
  projectId: string,
  projectMapByProjectId: { [key: string]: ClientProject },
  projectParents: ClientProject[] = []
): ClientProject[] {
  const project = projectMapByProjectId[projectId];

  if (!project) {
    return projectParents;
  }

  projectParents.push(project);

  if (project.parent_id !== null) {
    return getParentProjectsRecursively(project.parent_id, projectMapByProjectId, projectParents);
  }

  return projectParents;
}

function getChildProjectsRecursively(
  projectId: string,
  projectMapByParentId: {
    [key: string]: ClientProject[];
  },
  projectChildren: ClientProject[] = []
): ClientProject[] {
  const thisLevelsChildProjects = getChildProjects(projectId, projectMapByParentId);
  projectChildren.push(...thisLevelsChildProjects);

  thisLevelsChildProjects.forEach((parentProject) => {
    getChildProjectsRecursively(parentProject.project_id, projectMapByParentId, projectChildren);
  });

  return projectChildren;
}

export default useProjectTree;

import { modalPaths } from './components/ModalsRouter';

export function testModalPath(path: string) {
  const l = modalPaths.length;
  let foundMatch = false;

  for (let i = 0; !foundMatch && i < l; i++) {
    const item = modalPaths[i];

    if (path.startsWith(item)) foundMatch = true;
  }

  return foundMatch;
}

export function setAnalytics(location) {
  if (!window.gtag) return;

  try {
    window.gtag('config', 'G-FKBMJNBM43', { page_path: location.pathname });
  } catch (err) {
    const { trackJs } = window as any;

    if (trackJs) {
      trackJs.track(err);
    }
  }
}

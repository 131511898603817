import React, { useState, useEffect } from 'react';
import { Modal, Select } from 'antd';
import JSONTree from 'components/ui/JSONTree';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import Space from 'components/ui/Space';
import { AutomationEventDefinition } from 'typings';
import useDebounce from 'components/util/useDebounce';
import GenericASFFEvent from 'fixtures/GenericASFFEvent.json';

const EVENT_DEFINITIONS_QUERY = gql`
  query EventDefinitionsForEventSelection($search: String) {
    eventDefinitions(hasExample: true, pageSize: 100, search: $search) {
      nodes {
        id
        name
        example
      }
    }
  }
`;

interface Props {
  eventFromEventConsole?: any;
  selectedEventDefinition: AutomationEventDefinition | null;
  isOpen: boolean;
  onClose: () => void;
  onPathChange: (path: string) => void;
  onPatternChange: (path: string, value: string) => void;
}

export function pathArrayToPathString(pathArray: string[]) {
  return pathArray
    .join('.')
    .replace(/\.(\d+)\./g, '[$1].') // convert all `array.0.item` to `array[0].item` jsonpath syntax
    .replace(/^root\./, '$.message.'); // replace
}

function calculateInitialEventFrom(
  eventFromEventConsole: any,
  selectedEventDefinition: AutomationEventDefinition | null
) {
  if (eventFromEventConsole) return 'console';
  if (selectedEventDefinition) return selectedEventDefinition.id;
  return 'generic-asff';
}

function EventJSONPathSelectionModal(props: Props) {
  const { eventFromEventConsole, selectedEventDefinition, isOpen, onPathChange, onPatternChange, onClose } = props;

  const initialEventFrom = calculateInitialEventFrom(eventFromEventConsole, selectedEventDefinition);
  const [eventFrom, setEventFrom] = useState<string>(initialEventFrom);
  const [event, setEvent] = useState<any | undefined>(eventFromEventConsole?.message || GenericASFFEvent);
  const [eventSearch, setEventSearch] = useState<string | undefined>();

  const debouncedEventSearch = useDebounce(eventSearch, 500);

  useEffect(() => {
    setEventFrom(calculateInitialEventFrom(eventFromEventConsole, selectedEventDefinition));
  }, [eventFromEventConsole, selectedEventDefinition]);

  const { loading, data } = useQuery(EVENT_DEFINITIONS_QUERY, {
    onCompleted: (data) => {
      if (!selectedEventDefinition) return;
      const eventDefinitions = data?.eventDefinitions?.nodes || [];
      const eventDefinition = eventDefinitions.find(
        (eventDefinition) => eventDefinition.id === selectedEventDefinition.id
      );
      try {
        const example = JSON.parse(eventDefinition.example);
        setEvent(example);
      } catch (err) {
        return;
      }
    },
    variables: {
      search: debouncedEventSearch
    }
  });
  const eventDefinitions = data?.eventDefinitions?.nodes || [];

  eventDefinitions.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'base' }));

  const handleSelect = (value: string) => {
    setEventFrom(value);
    if (value === 'custom') {
      setEvent(undefined);
    } else if (value === 'console') {
      setEvent(eventFromEventConsole?.message);
    } else if (value === 'generic-asff') {
      setEvent(GenericASFFEvent);
    } else {
      const eventDefinition = eventDefinitions.find((eventDefinition) => eventDefinition.id === value);

      try {
        const example = JSON.parse(eventDefinition.example);
        setEvent(example);
      } catch (err) {
        setEvent(undefined);
      }
    }
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={() => onClose()}
      onOk={() => onClose()}
      width={800}
      title="Select Key or Value from Event JSON"
    >
      <Space direction="vertical" size={8}>
        <Select<string>
          loading={loading}
          style={{ width: '100%' }}
          onSelect={handleSelect}
          value={eventFrom}
          showSearch
          onSearch={(search) => setEventSearch(search)}
        >
          {eventFromEventConsole && <Select.Option value="console">Event from Event Console</Select.Option>}

          <Select.Option value="custom">Custom Event</Select.Option>

          <Select.Option value="generic-asff">Generic ASFF Event via CloudWatch</Select.Option>

          {eventDefinitions.map((eventDefinition) => {
            return <Select.Option key={eventDefinition.id}>{eventDefinition.name}</Select.Option>;
          })}
        </Select>

        <JSONTree
          key={eventFrom}
          isEditable
          isEditingInitially={eventFrom === 'custom'}
          data={event}
          onKeyClick={(pathArray) => {
            if (pathArray.length === 1 && pathArray[0] === 'root') return;
            onPathChange(pathArrayToPathString(pathArray));
            onClose();
          }}
          onValueClick={(value, pathArray) => {
            onPatternChange(pathArrayToPathString(pathArray), value);
            onClose();
          }}
        />
      </Space>
    </Modal>
  );
}

export default EventJSONPathSelectionModal;

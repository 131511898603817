import React, { Component } from 'react';

import { FieldArray } from 'formik';
import validator from 'services/validator';
import { objectIsEmpty } from 'utilities/helpers';
import { Alert, Button, Form, Icon } from 'antd';
import styled from 'styled-components';

import { size, FunctionInputBaseProps } from 'typings';
import { cidrRegex } from 'constants/regularExpressions';
import CIDRInput from './CIDRInput';

import DynamicConfigSelectButton from 'components/function/DynamicConfigSelectButton';
import { ScopedDynamicConfig } from 'typings';

const schema = validator.string().matches(cidrRegex, { message: 'CIDR is invalid' });

export const inputDefinition = {
  schema,
  defaultValue: []
};

const CIDRListContainer = styled.div`
  padding: 10px 0px 40px;
`;

interface Props extends FunctionInputBaseProps {
  title: string;
  subTitle?: string;
  placeholder?: string;

  name: string;
  value: string[];
  touched: boolean | boolean[];
  error: string | string[];

  disabled?: boolean;
  required?: boolean;
  size?: size;

  setFieldValue: (name: string, value: any) => any | void;
  setFieldTouched: (name: string, touched: boolean) => any;

  dynamicConfigValues?: ScopedDynamicConfig[];
}

class CIDRListInput extends Component<Props> {
  inputRefs: {
    [key: string]: any;
  };

  constructor(p: Props) {
    super(p);
    this.inputRefs = [];
  }

  componentDidUpdate(prevProps: Props) {
    const { value, name } = this.props;
    if (!value) return;
    if ((!prevProps.value && value.length) || value.length > prevProps.value.length) {
      this.inputRefs[`${name}.${value.length - 1}`].focus();
    }
  }

  render() {
    const {
      title,
      subTitle,
      required = false,
      value,
      name,
      placeholder,
      touched,
      error,
      disabled = false,
      setFieldValue,
      setFieldTouched,
      size = 'large',
      dynamicConfigValues,
      onSelectDynamicConfig
    } = this.props;

    return (
      <Form.Item label={title} extra={subTitle} hasFeedback={false} required={required}>
        <FieldArray
          name={name}
          render={arrayHelpers => (
            <CIDRListContainer>
              {value &&
                value.length > 0 &&
                value.map((item, index) => {
                  const currentError =
                    touched && !objectIsEmpty(touched) && error && Array.isArray(error) && error[index]
                      ? error[index]
                      : undefined;

                  const fieldName = `${name}.${index}`;

                  return (
                    <Form.Item validateStatus={currentError ? 'error' : undefined} help={currentError} key={index}>
                      <CIDRInput
                        inputRef={el => (this.inputRefs[fieldName] = el)}
                        name={fieldName}
                        value={value[index]}
                        placeholder={placeholder}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        disabled={disabled === true}
                        size={size}
                        addonAfter={
                          <Icon className="cidrRemoveButton" type="close" onClick={() => arrayHelpers.remove(index)} />
                        }
                      />
                    </Form.Item>
                  );
                })}
              {(!value || value.length < 1) && (
                <Alert
                  message="No IP Ranges Defined"
                  description="Use the 'Add IP Ranges' button to define a new IP Range"
                  type="info"
                  showIcon
                  style={{ width: '80%', margin: '0 auto 30px' }}
                />
              )}
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                {dynamicConfigValues && dynamicConfigValues.length > 0 && (
                  <>
                    <DynamicConfigSelectButton
                      scopedDynamicConfigs={dynamicConfigValues}
                      onSelect={onSelectDynamicConfig}
                      type="button"
                    />

                    <span style={{ marginLeft: '20px' }} />
                  </>
                )}
                <Button
                  className="altButton"
                  onClick={() => {
                    arrayHelpers.push('');
                  }}
                >
                  Add IP Range
                </Button>
              </div>
            </CIDRListContainer>
          )}
        />
      </Form.Item>
    );
  }
}

export default CIDRListInput;

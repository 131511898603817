import DateTime from 'components/ui/DateTime';
import cronToText from 'cron-to-text';
import React from 'react';
import { Detector } from 'typings';

interface LastRunProps {
  detector: Detector;
}

function LastRun(props: LastRunProps) {
  const {
    detector: { lastRanAt, schedule }
  } = props;

  let next;

  try {
    next = cronToText(schedule);
  } catch (e) {
    // console.log({ e });
  }

  if (!lastRanAt) return <span title={next}>--</span>;

  return <DateTime dateTime={lastRanAt} appendTitle={` > ${next}`} />;
  // return <div>last</div>;
}

export default LastRun;

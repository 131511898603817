import styled from 'styled-components';

export const SelectLabelsDiv = styled.div`
  .system-label-wrap {
    margin-bottom: 0.5rem;
  }

  .sub-label {
    font-style: italic;
  }
`;

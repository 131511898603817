import memoize from 'fast-memoize';
import React, { Component, ReactNode } from 'react';
import validator from 'services/validator';
import { Select, Input, Popover } from 'antd';
import { FunctionParameter, FunctionInputBaseProps, Config } from 'typings';
import { FormField } from 'components/ui/Form';
import FeatureFlag, { Feature } from 'components/app/FeatureFlag';

import IntegerInput, { inputDefinition as integerInputDefinition } from './inputs/IntegerInput';
import AWSTagSelect, { inputDefinition as awsTagSelectDefinition } from './inputs/AWSTagSelect';
import AWSCreateTagInput, { inputDefinition as awsCreateTagDefintion } from './inputs/AWSCreateTagInput';
import AWSRegionSelect from './inputs/AWSRegionSelect';
import CIDRListInput, { inputDefinition as cidrListDefinition } from './inputs/CIDRListInput';
import JiraNotificationRecipient from './inputs/JiraNotificationRecipient';
import BooleanInput, { inputDefinition as booleanInputDefinition } from './inputs/BooleanInput';
import ShortSelect from './inputs/ShortSelect';
import CIDRInput, { inputDefinition as cidrInputDefinition } from './inputs/CIDRInput';
import IPServiceInput, { inputDefinition as ipServiceInputDefinition } from './inputs/IPService';

import OpInputMany from 'components/function/OpInputMany';
import ManyFieldGroup, { CondensedFieldWrap } from 'components/function/ManyFieldGroup';
import DynamicConfigSelectButton from 'components/function/DynamicConfigSelectButton';
import DynamicConfigContext, {
  ContextInterface as ConfigContextInterface
} from 'components/dynamicConfig/DynamicConfigContext';
import { FormikErrors } from 'formik';
import AttachedDynamicConfiguration from 'components/dynamicConfig/AttachedDynamicConfiguration';
import DopeIcon from 'components/ui/DopeIcon';
import { PopoverMenuItem } from 'components/ui/Popover';

export const schemas = {
  aws_create_tag: awsCreateTagDefintion.schema,
  aws_tags: awsTagSelectDefinition.schema,
  cidr_list: cidrListDefinition.schema,
  cidr: cidrInputDefinition.schema,
  ip_service: ipServiceInputDefinition.schema
};

export const TYPE_SCHEMAS = {
  boolean: validator.boolean(),
  number: validator.number(),
  integer: validator.number(),
  string: validator.string(),
  array: validator.array()
};

const INPUT_CODE_SCHEMAS = {
  ...schemas
};

interface GenerateFieldSchemaArgs {
  staticConfigPathPrefix?: string; // 'staticConfiguration.'
  jsonpathConfigPathPrefix?: string; // 'jsonpathConfiguration.'
  dynamicConfigPathPrefix?: string; // 'dynamicConfiguration.'
}

// we should probably move this to utils...
export function generateFieldSchema(
  parameter: FunctionParameter,
  enforceRequired: boolean = false,
  args?: GenerateFieldSchemaArgs
) {
  let fieldType;

  const jsonpathConfigPathPrefix = args?.jsonpathConfigPathPrefix || 'jsonpathConfiguration.';
  const dynamicConfigPathPrefix = args?.dynamicConfigPathPrefix || 'dynamicConfiguration.';

  if (parameter.inputCode && parameter.type === 'array' && INPUT_CODE_SCHEMAS[parameter.inputCode]) {
    fieldType = validator.array().of(INPUT_CODE_SCHEMAS[parameter.inputCode]);
  } else if (parameter.inputCode && INPUT_CODE_SCHEMAS[parameter.inputCode]) {
    fieldType = INPUT_CODE_SCHEMAS[parameter.inputCode];
  } else {
    fieldType = TYPE_SCHEMAS[parameter.type];
  }

  if (!fieldType) throw new Error(`Could not find field Type for parameter: ${parameter.key}`);

  if (parameter.required && enforceRequired) {
    fieldType = fieldType.when(
      [`${dynamicConfigPathPrefix}${parameter.key}`, `${jsonpathConfigPathPrefix}${parameter.key}`],
      (dynamicConfig, jsonpathConfig, schema) => {
        if (dynamicConfig || (jsonpathConfig && jsonpathConfig.length > 0)) return schema;

        return schema.required(`${parameter.name || parameter.key} is required`);
      }
    );
  }

  if (parameter.many) {
    fieldType = validator.array().of(fieldType);

    if (parameter.required) {
      fieldType = fieldType.min(1, `At least one is required`);
    }
  }

  return fieldType;
}

interface Props {
  parameter: FunctionParameter;
  fieldName?: string;

  staticConfigPathPrefix?: string; // 'staticConfiguration.'
  jsonpathConfigPathPrefix?: string; // 'jsonpathConfiguration.'
  dynamicConfigPathPrefix?: string; // 'dynamicConfiguration.'

  value: any;
  title?: string;

  validationError?: string | FormikErrors<any> | null;
  touched: boolean | boolean[];

  setFieldValue: (name: string, value: any) => any | void;
  setFieldTouched: (name: string, touched: boolean, shouldValidate?: boolean) => any;
  setFieldError: (name: string, message: string) => any | void;

  showDynamicConfigOptions?: boolean;
  attachedDynamicConfiguration?: Config | null;

  jsonpathConfiguration?: string | null;
  allowJSONPathInput?: boolean;
}

interface State {
  initialValue?: any;
  howManyFields: number;
  isMounted: boolean;
}

interface OpInputRenderTest {
  test: (parameter: FunctionParameter) => boolean;
  render: (baseProps: any) => ReactNode;
  validTypes?: string[];
  simple?: boolean;
  useDynamicConfig?: boolean;
  includeTitle?: boolean;
  includeDescription?: boolean;
  inputDefinition?: any;
}

class FunctionParameterInput extends Component<Props, State> {
  pickInputType = memoize((parameter) => {
    const inputType = this.inputTypes.find((inputType) => inputType.test(parameter)) || this.defaultInputType;

    return inputType;
  });

  private defaultInputType: OpInputRenderTest = {
    simple: true,
    test: () => true,
    render: this.renderDefaultInput
  };

  private inputTypes: OpInputRenderTest[] = [
    {
      simple: true,
      inputDefinition: awsTagSelectDefinition,
      test: ({ inputCode = '' }) => inputCode === 'aws_tags',
      render: this.renderAWSTagSelect
    },
    {
      simple: true,
      test: ({ inputCode = '' }) => inputCode === 'aws_regions',
      render: this.renderAWSRegionSelect
    },
    {
      simple: true,
      test: ({ inputCode = '' }) => inputCode === 'cidr',
      render: this.renderCIDRInput
    },
    {
      inputDefinition: cidrListDefinition,
      test: ({ inputCode = '' }) => typeof inputCode === 'string' && ['cidr_list', 'known_cidrs'].includes(inputCode),
      render: this.renderCIDRList
    },
    {
      simple: true,
      inputDefinition: ipServiceInputDefinition,
      test: ({ inputCode = '' }) => inputCode === 'ip_service',
      render: this.renderIPServiceInput
    },
    {
      simple: true,
      inputDefinition: awsCreateTagDefintion,
      test: ({ inputCode = '' }) => inputCode === 'aws_create_tag',
      validTypes: awsCreateTagDefintion.validTypes,
      render: this.renderAWSCreateTagInput
    },
    {
      simple: true,
      test: ({ options, inputCode = '' }) =>
        inputCode !== 'select' && options && options.length > 1 && options.length < 5 ? true : false,
      render: this.renderShortSelect
    },
    {
      simple: true,
      test: ({ options }) => (options && options.length > 1 ? true : false),
      render: this.renderSelect
    },
    {
      simple: true,
      inputDefinition: booleanInputDefinition,
      includeDescription: false,
      test: ({ type }) => type === 'boolean',
      render: this.renderBooleanInput
    },
    {
      inputDefinition: integerInputDefinition,
      simple: true,
      test: ({ type }) => type === 'integer',
      render: this.renderIntegerInput
    },
    {
      simple: true,
      test: ({ inputCode = '' }) => inputCode === 'jira_recipient',
      useDynamicConfig: false,
      includeTitle: false,
      includeDescription: false,
      render: this.renderJiraNotificationRecipient
    },
    this.defaultInputType
  ];

  constructor(p: Props) {
    super(p);

    this.state = {
      isMounted: false,
      initialValue: undefined,
      howManyFields: 1
    };
  }

  componentDidMount() {
    const { howManyFields } = this.state;
    const { setFieldValue, parameter, staticConfigPathPrefix = '' } = this.props;
    const { many } = parameter;
    const initialValue = this.getInitialFieldValue();
    const staticConfigPath = `${staticConfigPathPrefix}${parameter.key}`;

    this.setState({
      initialValue,
      howManyFields: many && Array.isArray(initialValue) ? initialValue.length : howManyFields
    });

    setTimeout(() => {
      setFieldValue(staticConfigPath, initialValue);
      this.setState({
        isMounted: true
      });
    }, 100);
  }

  getInitialFieldValue() {
    // set if matching config_code ?

    const { parameter, value } = this.props;
    const { many } = parameter;
    const { inputDefinition = {} } = this.pickInputType(parameter);

    const inputDefaultValue = parameter.defaultValue || inputDefinition.defaultValue;
    const defaultValueIsArray = Array.isArray(inputDefaultValue);

    const defaultValue =
      !many || (many && defaultValueIsArray && parameter.type !== 'array') ? inputDefaultValue : [inputDefaultValue];

    if (!isUndefinedOrArrayOfUndefined(value)) return value;
    if (!isUndefinedOrArrayOfUndefined(defaultValue)) {
      return defaultValue;
    } else if (many === true) {
      return [];
    }
  }

  render() {
    const {
      setFieldValue,
      setFieldTouched,
      setFieldError,
      parameter,
      value,
      fieldName,
      validationError,
      touched,
      showDynamicConfigOptions = false,
      attachedDynamicConfiguration,
      allowJSONPathInput,
      staticConfigPathPrefix = '',
      jsonpathConfiguration,
      jsonpathConfigPathPrefix = 'jsonpathConfiguration.',
      dynamicConfigPathPrefix = 'dynamicConfiguration.'
    } = this.props;

    const configCode = parameter.configCode;

    return (
      <FeatureFlag features={[Feature.GUARDRAIL_INPUTS_JSON_PATH]}>
        {(flags) => (
          <DynamicConfigContext.Consumer>
            {(configBag: ConfigContextInterface | undefined) => {
              const configValues =
                configBag && !configBag.initializing && showDynamicConfigOptions && configCode
                  ? configBag.getRelevantSettings({
                      configCode,
                      type: parameter.type,
                      many: parameter.many || false,
                      inputCode: parameter.inputCode ? parameter.inputCode : undefined
                    })
                  : undefined;

              const { initialValue, isMounted } = this.state;

              const { placeholder = '', description, defaultValue, required = false, many = false } = parameter;

              const name = parameter.key;
              const staticConfigPath = `${staticConfigPathPrefix}${parameter.key}`;
              const jsonConfigPath = `${jsonpathConfigPathPrefix}${name}`;
              const dynamicConfigPath = `${dynamicConfigPathPrefix}${name}`;

              const onSelectDynamicConfig = (dynamicConfig) => {
                setFieldValue(dynamicConfigPath, dynamicConfig);
                setFieldValue(staticConfigPath, undefined);
                setFieldValue(jsonConfigPath, undefined);
                setFieldTouched(staticConfigPath, true, true);
              };

              const onInitCustomJSONPath =
                flags[Feature.GUARDRAIL_INPUTS_JSON_PATH] && allowJSONPathInput
                  ? () => {
                      setFieldValue(staticConfigPath, undefined);
                      setFieldValue(dynamicConfigPath, undefined);
                      setFieldValue(jsonConfigPath, ''); // hopefully this is enough to render UI.
                      setFieldTouched(staticConfigPath, true, true);
                    }
                  : undefined;

              const inputTitle = fieldName || parameter.name || '';

              const error = touched && validationError ? validationError : null;

              const baseProps = {
                name: staticConfigPath,
                value,
                description: description || '',
                error,
                placeholder: placeholder || '',
                touched,
                setFieldValue: (name: string, value: any) => {
                  setFieldValue(dynamicConfigPath, undefined);
                  setFieldValue(jsonConfigPath, undefined);
                  setFieldValue(name, value);
                },
                setFieldTouched,

                defaultValue: defaultValue,
                size: 'large' as 'large',
                dynamicConfigValues: configValues,
                onSelectDynamicConfig,
                onInitCustomJSONPath
              };

              const {
                render,
                simple = false,
                validTypes = [],
                inputDefinition = {},
                useDynamicConfig = true,
                includeTitle = true,
                includeDescription = true
              } = this.pickInputType(parameter);

              if (jsonpathConfiguration !== undefined && jsonpathConfiguration !== null) {
                return (
                  <FormField name={jsonConfigPath} label={inputTitle}>
                    {({ name, value, handleChange, handleBlur }) => {
                      return (
                        <Input
                          value={value}
                          name={name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          addonBefore={'{ : } JSON Path:'}
                          placeholder="$.path.to.value"
                          data-lpignore="true"
                          addonAfter={
                            <Popover
                              trigger="click"
                              placement="bottomLeft"
                              content={
                                <PopoverMenuItem
                                  label="Reset Field"
                                  onClick={() => {
                                    setFieldValue(name, undefined);
                                  }}
                                />
                              }
                            >
                              <DopeIcon name="ARROW_DOWN" size={18} />
                            </Popover>
                          }
                        />
                      );
                    }}
                  </FormField>
                );
              }

              if (attachedDynamicConfiguration) {
                return (
                  <FormField
                    name={name}
                    label={inputTitle}
                    hideLabel={!includeTitle}
                    extra={includeDescription && description ? description : undefined}
                  >
                    {() => {
                      return (
                        <AttachedDynamicConfiguration
                          attachedDynamicConfiguration={attachedDynamicConfiguration}
                          showActionsMenu
                          onRemove={() => {
                            setFieldValue(dynamicConfigPath, undefined);
                          }}
                        />
                      );
                    }}
                  </FormField>
                );
              }

              if (validTypes.length > 0 && !validTypes.includes(parameter.type)) {
                return this.renderInvalidType(parameter.type, validTypes);
              }

              if (!isMounted) {
                //This allows the FunctionParameterInput to be in control of setting its own initial value
                return null;
              }

              if (!simple) {
                const complexProps = {
                  ...baseProps,
                  validationError,
                  required,
                  title: inputTitle
                };

                return render.bind(this)(complexProps);
              }

              if (many) {
                const inputDefaultValue = parameter.defaultValue || inputDefinition.defaultValue;
                const initialValueSingle =
                  Array.isArray(inputDefaultValue) && parameter.type !== 'array'
                    ? inputDefaultValue[0]
                    : inputDefaultValue;

                return (
                  <OpInputMany
                    name={staticConfigPath}
                    title={inputTitle}
                    opInputBaseProps={baseProps}
                    fieldRender={render}
                    initialValue={initialValueSingle}
                    onCancelChanges={() => {
                      setFieldTouched(staticConfigPath, false);
                      setFieldError(staticConfigPath, '');
                      setFieldValue(staticConfigPath, initialValue);
                    }}
                    extra={includeDescription && description ? description : undefined}
                  />
                );
              }
              return (
                <FormField
                  name={staticConfigPath}
                  label={inputTitle}
                  hideLabel={!includeTitle}
                  extra={includeDescription && description ? description : undefined}
                >
                  {() => {
                    return (
                      <ManyFieldGroup
                        action={
                          (baseProps.dynamicConfigValues && baseProps.dynamicConfigValues.length > 0) ||
                          (baseProps.onInitCustomJSONPath && useDynamicConfig) ? (
                            <DynamicConfigSelectButton
                              scopedDynamicConfigs={baseProps.dynamicConfigValues}
                              onSelect={onSelectDynamicConfig}
                              onInitCustomJSONPath={baseProps.onInitCustomJSONPath}
                            />
                          ) : undefined
                        }
                      >
                        {render.bind(this)(baseProps)}
                      </ManyFieldGroup>
                    );
                  }}
                </FormField>
              );
            }}
          </DynamicConfigContext.Consumer>
        )}
      </FeatureFlag>
    );
  }

  renderAWSTagSelect(baseProps: any) {
    return <AWSTagSelect {...baseProps} multiple={this.getSelectMode() === 'multiple'} />;
  }

  renderAWSRegionSelect(baseProps: any) {
    return <AWSRegionSelect {...baseProps} allowClear={true} multiple={this.getSelectMode() === 'multiple'} />;
  }

  renderCIDRList(baseProps: any) {
    return <CIDRListInput {...baseProps} />;
  }

  renderCIDRInput(baseProps: any) {
    return <CIDRInput {...baseProps} />;
  }

  renderJiraNotificationRecipient(baseProps: any) {
    return <JiraNotificationRecipient {...baseProps} />;
  }

  renderIPServiceInput(baseProps: any) {
    return <IPServiceInput {...baseProps} />;
  }

  renderAWSCreateTagInput(baseProps: any) {
    return <AWSCreateTagInput {...baseProps} />;
  }

  renderSelect(baseProps: any) {
    const { parameter } = this.props;
    const { setFieldTouched, setFieldValue } = baseProps;
    const { key } = parameter;
    const options = parameter.options || [];

    const onBlur = () => setFieldTouched(key, true);
    const onChange = (value) => setFieldValue(key, value);

    return (
      <CondensedFieldWrap>
        <Select
          style={{ width: '100%' }}
          size="large"
          disabled={options.length === 0}
          mode={this.getSelectMode()}
          notFoundContent={''}
          onBlur={onBlur}
          onChange={onChange}
        >
          {options.map((item: any, n: number) => (
            <Select.Option key={n.toString()} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </CondensedFieldWrap>
    );
  }

  renderShortSelect(baseProps: any) {
    const { parameter } = this.props;
    const { options } = parameter;

    return <ShortSelect {...baseProps} options={options} multiple={this.getSelectMode() === 'multiple'} />;
  }

  renderBooleanInput(baseProps: any) {
    const { parameter } = this.props;
    const { description } = parameter;

    return <BooleanInput {...baseProps} description={description} />;
  }

  renderIntegerInput(baseProps: any) {
    return (
      <CondensedFieldWrap>
        <IntegerInput {...baseProps} />
      </CondensedFieldWrap>
    );
  }

  renderDefaultInput(baseProps: FunctionInputBaseProps) {
    const { name, value, setFieldValue, setFieldTouched } = baseProps;
    const handleChange = (event) => {
      setFieldValue(name, event.target.value);
    };
    const handleBlur = () => setFieldTouched(name, true);
    return (
      <CondensedFieldWrap>
        <Input
          name={name}
          value={value}
          size="large"
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
          data-lpignore="true"
        />
      </CondensedFieldWrap>
    );
  }

  renderInvalidType(type: string, validTypes: string[]) {
    const validTypeString = validTypes.join(', ');
    return `Error rendering Op Input: The type specified (${type}) does not match a valid type (${validTypeString}) for this inputCode.`;
  }

  getSelectMode() {
    const { parameter } = this.props;
    const { type } = parameter;

    if (type === 'array') return 'multiple';

    return 'default';
  }

  addManyField(add: (value: any) => any | void) {
    const { parameter } = this.props;
    const { many } = parameter;

    const { inputDefinition = {} } = this.pickInputType(parameter);

    const inputDefaultValue = parameter.defaultValue || inputDefinition.defaultValue;
    const defaultValue =
      many && Array.isArray(inputDefaultValue) && parameter.type !== 'array' ? inputDefaultValue[0] : inputDefaultValue;

    add(defaultValue);

    this.setState((prevState) => ({
      howManyFields: prevState.howManyFields + 1
    }));
  }

  removeManyField(index: number, remove: (index: number) => any | void) {
    remove(index);

    this.setState((prevState) => ({
      howManyFields: prevState.howManyFields - 1
    }));
  }
}

function isUndefinedOrArrayOfUndefined(value: any) {
  return value === undefined || (Array.isArray(value) && value.every((entry) => entry === undefined));
}

export default FunctionParameterInput;

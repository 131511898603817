import React from 'react';
import { RouteComponentProps } from 'react-router';

import NotificationForm from './components/NotificationForm';

import { NOTIFICATION_FRAGMENT } from './gql';

import gql from 'graphql-tag';
import { Mutation, useQuery } from 'react-apollo';
import QueryResult from 'components/util/QueryResult';
import { PageTitle } from 'components/ui/PageHeader';
import { CenteredContainer } from 'components/ui/NeoPage';
import { message } from 'antd';

import { EventType } from './fixtures/EventTypeOptions';

const NOTIFICATION_DETAIL_QUERY = gql`
  query getNotification($id: ID!) {
    notification(id: $id) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation updateNotification($id: ID!, $notification: NotificationInput!) {
    updateNotification(id: $id, notification: $notification) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`;

interface Props
  extends RouteComponentProps<{
    id?: string;
  }> {}

enum NotificationScope {
  NONE = 'NONE',
  ISSUE = 'ISSUE',
  ISSUE_AND_ASSESSMENT = 'ISSUE_AND_ASSESSMENT',
  ASSESSMENT = 'ASSESSMENT',
  PROJECT = 'PROJECT',
  ACCOUNT = 'ACCOUNT'
}

export interface NotificationRecipient {
  id: string;
  type: string;
  config: any;
  isEnabled: boolean;
  // all the other fields...
}

export interface NotificationFromQuery {
  accountId?: string | null;
  assessmentId?: string | null;
  projectId?: string | null;
  issueDefinitionId?: string | null;
  clientId: string;
  createdAt: string;
  description?: string | null;
  eventTypes: EventType[];
  id: string;
  isEnabled: boolean;
  name: string;
  recipients: NotificationRecipient[];
  scope: NotificationScope;
}

function NotificationDetail(props: Props) {
  const {
    match: {
      params: { id }
    }
  } = props;

  const { loading, data, error } = useQuery(NOTIFICATION_DETAIL_QUERY, { variables: { id } });

  return (
    <QueryResult loading={loading} data={data} error={error}>
      {() => {
        const notification: NotificationFromQuery = data.notification;

        return (
          <CenteredContainer size="md" leftAlign>
            <PageTitle title="Edit Notification" />
            <Mutation mutation={UPDATE_NOTIFICATION_MUTATION}>
              {(updateNotification) => (
                <NotificationForm
                  initialNotificationFeed={notification}
                  onSave={async (notification) => {
                    await updateNotification({ variables: { id, notification } });

                    message.success('Notification was successfully updated.');
                  }}
                />
              )}
            </Mutation>
          </CenteredContainer>
        );
      }}
    </QueryResult>
  );
}

export default NotificationDetail;

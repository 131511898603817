import React, { ReactNode } from 'react';
import styled from 'styled-components';

const Root = styled.div`
  margin-bottom: 64px;

  .ant-table-thead {
    background-color: ${p => p.theme.grey50};
  }

  .ant-pagination {
    padding: 16px;
    margin: 0;
    display: flex;
    background-color: ${p => p.theme.grey100};
    justify-content: flex-end;
    width: 100%;
    // border-radius: 0 0 8px 8px;
  }

  tr.ant-table-row:nth-child(even) {
    background-color: #fff;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-body > table > .ant-table-thead > tr > th:last-child,
  .ant-table-body > table > .ant-table-tbody > tr > td:last-child {
    padding-right: 20px;
  }

  .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-middle > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-middle > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th {
    &:first-child {
      padding: 12px;
      padding-left: 24px;
    }

    &:last-child {
      padding: 12px;
      padding-right: 24px;
    }

    &:only-child {
      padding: 12px 24px;
    }
  }

  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th:last-child,
  .ant-table-middle
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td:last-child {
    padding-right: 12px;
  }

  .ant-table-wrapper {
    // border-radius: 8px;
    // overflow: hidden;
  }

  .ant-table-title {
    border-radius: 0;

    & + .ant-table-content {
      overflow: visible;
    }
  }

  .reveal-on-row-hover {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .ant-table-tbody tr:hover {
    .reveal-on-row-hover {
      opacity: 1;
    }
  }
`;

const Panel = styled.div`
  background-color: ${p => p.theme.grey50};
  border: 1px solid ${p => p.theme.grey300};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const PanelTitle = styled.div`
  margin-bottom: 8px;
`;

export interface Props {
  classes?: {
    root?: string;
    panelTitle?: string;
    panel?: string;
  };
  panelTitle?: string;
  children: ReactNode;
}

function TableWrap(props: Props) {
  const { children, panelTitle, classes = {} } = props;

  return (
    <Root className={`table-wrap ${classes.root}`}>
      {panelTitle && <PanelTitle className={classes.panelTitle || ''}>{panelTitle}</PanelTitle>}
      <Panel className={classes.panel || ''}>{children}</Panel>
    </Root>
  );
}

export default TableWrap;

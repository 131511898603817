import gql from 'graphql-tag';

const TAKE_ACTION_MUTATION = gql`
  mutation runPendingAction($id: String!) {
    runPendingAction(id: $id) {
      id
      taskId
      status
    }
  }
`;

export default TAKE_ACTION_MUTATION;

import React from 'react';

export interface SlackAuthData {
  access_token?: string;
  bot?: {
    bot_access_token: string;
    bot_user_id: string;
  };
  ok?: boolean;
  scope?: string;
  team_id?: string;
  team_name?: string;
  user_id?: string;
}

export interface SlackConfig {
  name?: string;
  appId?: string;
  clientId?: string;
  clientSecret?: string;
  scopes: string[];
}

export interface Context {
  authData?: SlackAuthData;
  config: SlackConfig;
  setAuthData?: (authData: SlackAuthData) => any | void;
  setConfig?: (config: SlackConfig) => any | void;
  isStarted: boolean;
  isStarting: boolean;
  isAuthorized: boolean;
  startBot: (authData: SlackAuthData) => any | void;
  stopBot: () => any | void;
}

export const SlackContext = React.createContext<Context | undefined>({
  authData: {},
  config: {
    scopes: ['incoming-webhook']
  },
  setAuthData: noop(),
  setConfig: noop(),
  isStarted: false,
  isStarting: false,
  isAuthorized: false,
  startBot: noop(),
  stopBot: noop()
});

function noop() {
  return () => {};
}

export default SlackContext;

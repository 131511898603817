import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { ConfiguredAssessment } from 'typings';

import { CollapseTitleReadViewProps } from '../../../types';

const ASSESSMENT_NAME_QUERY = gql`
  query AssessmentNameQuery($id: [String]!) {
    assessments(id: $id) {
      nodes {
        id
        name
      }
    }
  }
`;

function CollapseTitleReadView(props: CollapseTitleReadViewProps) {
  const { value } = props;

  if (!value) return <>{'All Detectors'}</>;

  return (
    <Query query={ASSESSMENT_NAME_QUERY} variables={{ id: value }}>
      {({ error, data }) => {
        if (!data || error) return null;

        const assessment: ConfiguredAssessment | null = data.assessments.nodes[0];

        return <>{assessment ? assessment.name : 'Could not find detector'}</>;
      }}
    </Query>
  );
}

export default CollapseTitleReadView;
